import i18n from "i18n"
import { fromJS } from "immutable"
import { submitEventRsvpActionTypes } from "QuorumGrassroots/widgets/Event/action-types"

import { successfullySubmittedWidget, setWidgetPostSubmitData } from "QuorumGrassroots/widgets/action-creators"

import { sendAction } from "shared/djangio/action-creators"

export const validate = (immutableValues, props) => {
    const values = immutableValues.toJS()
    const errors = {}

    if (props.shouldSelfRsvp && !values.rsvpStatus) {
        errors.rsvpStatus = i18n.t("event.form.rsvp.rsvp_status.error")
    }

    if (props.shouldBossRsvp && !values.bossStatus) {
        errors.bossStatus = i18n.t("event.form.rsvp.rsvp_status.error")
    }

    return errors
}

export const submitEventRsvp = (immutableValues, props) => (dispatch, getState) => {
    const { event, uniqueWidgetId } = props

    const { rsvpStatus, bossStatus, selfTagDict, bossTagDict } = immutableValues.toJS()

    const kwargs = {
        rsvpStatus,
        bossStatus,
        tagDict: selfTagDict,
        bossTagDict,
        eventId: event.id,
    }

    // If previewing an event, skip post request and just 'submit' widget
    if (props.canPreviewEvent) {
        dispatch(setWidgetPostSubmitData(uniqueWidgetId, kwargs))
        return dispatch(successfullySubmittedWidget(uniqueWidgetId))
    }

    return dispatch(
        sendAction(DjangIO.app.custom_event.models.GrassrootsCustomEvent, submitEventRsvpActionTypes, {
            action: "custom_event_rsvp",
            method: "post",
            kwargs,
        }),
    ).then((response) => {
        dispatch(setWidgetPostSubmitData(uniqueWidgetId, kwargs))
        return dispatch(successfullySubmittedWidget(uniqueWidgetId))
    })
}

export const rsvpYesAndSubmit = (eventPassThroughProps) => (dispatch) => {
    const values = fromJS({ rsvpStatus: DjangIO.app.custom_event.types.RSVPStatus.yes.value })

    dispatch(successfullySubmittedWidget(eventPassThroughProps.uniqueWidgetId))
    return dispatch(submitEventRsvp(values, eventPassThroughProps))
}

export const actionTypes = {
    POST_INTERACTION_NOTE_START: "POST_INTERACTION_NOTE_START",
    POST_INTERACTION_NOTE_SUCCESS: "POST_INTERACTION_NOTE_SUCCESS",
    POST_INTERACTION_NOTE_FAIL: "POST_INTERACTION_NOTE_FAIL",

    UPDATE_INTERACTION_NOTE_START: "UPDATE_INTERACTION_NOTE_START",
    UPDATE_INTERACTION_NOTE_SUCCESS: "UPDATE_INTERACTION_NOTE_SUCCESS",
    UPDATE_INTERACTION_NOTE_FAIL: "UPDATE_INTERACTION_NOTE_FAIL",

    LOAD_EXISTING_INTERACTION_NOTE_START: "LOAD_EXISTING_INTERACTION_NOTE_START",
    LOAD_EXISTING_INTERACTION_NOTE_SUCCESS: "LOAD_EXISTING_INTERACTION_NOTE_SUCCESS",
    LOAD_EXISTING_INTERACTION_NOTE_FAIL: "LOAD_EXISTING_INTERACTION_NOTE_FAIL",

    LOAD_EXISTING_COMMENT_NOTE_START: "LOAD_EXISTING_COMMENT_NOTE_START",
    LOAD_EXISTING_COMMENT_NOTE_SUCCESS: "LOAD_EXISTING_COMMENT_NOTE_SUCCESS",
    LOAD_EXISTING_COMMENT_NOTE_FAIL: "LOAD_EXISTING_COMMENT_NOTE_FAIL",

    LOAD_EXISTING_RELATIONSHIP_NOTE_START: "LOAD_EXISTING_RELATIONSHIP_NOTE_START",
    LOAD_EXISTING_RELATIONSHIP_NOTE_SUCCESS: "LOAD_EXISTING_RELATIONSHIP_NOTE_SUCCESS",
    LOAD_EXISTING_RELATIONSHIP_NOTE_FAIL: "LOAD_EXISTING_RELATIONSHIP_NOTE_FAIL",

    GET_TEAMS_START: "GET_TEAMS_START",
    GET_TEAMS_SUCCESS: "GET_TEAMS_SUCCESS",
    GET_TEAMS_FAIL: "GET_TEAMS_FAIL",

    GET_INTERACTION_PROJECTS_START: "GET_INTERACTION_PROJECTS_START",
    GET_INTERACTION_PROJECTS_SUCCESS: "GET_INTERACTION_PROJECTS_SUCCESS",
    GET_INTERACTION_PROJECTS_FAIL: "GET_INTERACTION_PROJECTS_FAIL",

    CLEAR_LOADED_NOTE: "CLEAR_LOADED_NOTE",
    CLEAR: "CLEAR",

    SET_NOTE_IS_EDITING: "SET_NOTE_IS_EDITING",
    DISABLE_NOTE_IS_EDITING: "DISABLE_NOTE_IS_EDITING",
}

// submit/update a internation type note
export const createInteractionTypes = {
    start: actionTypes.POST_INTERACTION_NOTE_START,
    success: actionTypes.POST_INTERACTION_NOTE_SUCCESS,
    fail: actionTypes.POST_INTERACTION_NOTE_FAIL,
}

export const updateInteractionTypes = {
    start: actionTypes.UPDATE_INTERACTION_NOTE_START,
    success: actionTypes.UPDATE_INTERACTION_NOTE_SUCCESS,
    fail: actionTypes.UPDATE_INTERACTION_NOTE_FAIL,
}

export const loadInteractionTypes = {
    start: actionTypes.LOAD_EXISTING_INTERACTION_NOTE_START,
    success: actionTypes.LOAD_EXISTING_INTERACTION_NOTE_SUCCESS,
    fail: actionTypes.LOAD_EXISTING_INTERACTION_NOTE_FAIL,
}

export const loadRelationshipTypes = {
    start: actionTypes.LOAD_EXISTING_RELATIONSHIP_NOTE_START,
    success: actionTypes.LOAD_EXISTING_RELATIONSHIP_NOTE_SUCCESS,
    fail: actionTypes.LOAD_EXISTING_RELATIONSHIP_NOTE_FAIL,
}

export const loadCommentTypes = {
    start: actionTypes.LOAD_EXISTING_COMMENT_NOTE_START,
    success: actionTypes.LOAD_EXISTING_COMMENT_NOTE_SUCCESS,
    fail: actionTypes.LOAD_EXISTING_COMMENT_NOTE_FAIL,
}

// load projects to display as choices
export const getProjectsTypes = {
    start: actionTypes.GET_INTERACTION_PROJECTS_START,
    success: actionTypes.GET_INTERACTION_PROJECTS_SUCCESS,
    fail: actionTypes.GET_INTERACTION_PROJECTS_FAIL,
}

export default actionTypes

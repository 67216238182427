import React, { Component } from "react"
import PropTypes from "prop-types"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import { ElectionInfoIframe } from "QuorumGrassroots/campaign-forms/components/ElectionInfoForm/style"

const votingProjectIframeHTML = `
<!DOCTYPE html>
<html>
    <head>
        <style>
            body {margin: 0px;}
            .footer {max-width: 100% !important;}
            .vit-modal {min-width: 100%;}
            #_vit {border: none !important;}
        </style>
        <script type="text/javascript" src="https://tool.votinginfoproject.org/app.js"></script>
    </head>
    <body>
        <div id="_vit"></div>
        <script>
            window.vit.load({
                language: "en",
                width: "100%",
                height: "900px"
            })
        </script>
    </body>
</html>
`

class ElectionInfoForm extends Component {
    constructor(props) {
        super(props)
        this.iframeRef = React.createRef()
    }

    submit = () => {
        return this.props.postGrassrootsSupporterAction({
            uniqueWidgetId: this.props.uniqueWidgetId,
            campaign: this.props.campaign,
            shouldCompleteCampaign: true,
        })
    }

    componentDidMount() {
        this.iframeRef.current.contentWindow.document.open()
        this.iframeRef.current.contentWindow.document.write(votingProjectIframeHTML)
        this.iframeRef.current.contentWindow.document.close()
    }

    render() {
        return (
            <div className="election-info_campaign-main">
                <ElectionInfoIframe ref={this.iframeRef} />
                <StyledButton
                    onClick={this.submit}
                    disabled={this.props.isSubmitting}
                    data-cy="sign"
                    isCampaignPage
                    activateNGGTheme
                >
                    {this.props.styledButtonText}
                </StyledButton>
            </div>
        )
    }
}

ElectionInfoForm.propTypes = {
    campaign: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    postGrassrootsSupporterAction: PropTypes.func.isRequired,
    styledButtonText: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    uniqueWidgetId: PropTypes.string.isRequired,
}

export default ElectionInfoForm

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Atoms/CounterMessage/style"
import Icon from "Components/Atoms/Icon"

const CounterMessage = ({
    children,
    dataCy,
    icon,
    iconFamily,
}) => {
    return (
        <S.CounterMessage
            data-auto-cy="AtomCounterMessage"
            data-cy={dataCy}
        >
            <S.MessageContainer>
                <Icon
                    fixedWidth={false}
                    icon={icon}
                    iconFamily={iconFamily}
                />
                {children}
            </S.MessageContainer>
        </S.CounterMessage>
    )
}

CounterMessage.defaultProps = {
}

CounterMessage.propTypes = {
    children: PropTypes.any,
    dataCy: PropTypes.string,
    icon: PropTypes.string,
    iconFamily: PropTypes.string,
}

export default CounterMessage

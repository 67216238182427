import React from "react"
import PropTypes from "prop-types"

import * as rules from "Components/rules.js"
import * as S from "Components/Compounds/NavigationalTabs/style"

import Icon from "Components/Atoms/Icon"
import DropdownGeneric from "Components/Molecules/DropdownGeneric"

// spacing between tabs, in px
const tabSpacing = rules.NavigationalTabSpacing

// the space between tabs is split equally between each pair of tabs;
// formerly calculated as CSS var but IE doesn't support var so its
// calculated once here and passed to the styled components to adhere to DRY
const tabPadding = tabSpacing / 2

// size of the additional dropdown tab for hidden tabs, in px
// does not live in rules because it is specific to this component
const moreTabSize = 60

const UnwrappedDropdownTab = (props) => (
    <S.TabContainer>
        <S.Tab
            isSelected={props.isSelected}
            isFirst={props.isFirst}
            menuOptions={props.menuOptions}
            data-measurement={"tab-tab"}
            data-cy={props.dataCy}
        >
            {props.text}
            <Icon iconFamily="far" icon={props.expanded ? "angle-up" : "angle-down"}/>
        </S.Tab>
    </S.TabContainer>
)

const DropdownTab = (props) => (
    <DropdownGeneric
        buttonComponent={UnwrappedDropdownTab}

        // move the dropdown to completely beneath the tab bar
        dropdownBoxOffsetVertical={"5px"}

        // line up the left edge of the dropdown box with
        // the left edge of the tab highlight area
        dropdownBoxOffsetHorizontal={`-${tabPadding}px`}

        isOverflow
        overflowHeightOffset={120}
        {...props}
    />
)

class NavigationalTabs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hiddenTabs: 0,
            measuredTabSizes: null,
        }

        // bind this function to allow it to be mocked in tests
        this.calculateTabs = this.calculateTabs.bind(this)
    }

    calculateTabs() {
        // measure how much space there is to render tabs
        const tabBarWidth = document.querySelector(`*[data-measurement="tab-bar"]`) && document.querySelector(`*[data-measurement="tab-bar"]`).offsetWidth

        if (!tabBarWidth) {
            // TODO update smoke tests to work with document.querySelector so we don't have to return early here
            return
        }

        // subtract the space where the "More" tab would need to be rendered
        const widthWithMoreTab = tabBarWidth - (moreTabSize + tabSpacing)
        // get all the tabs on the bar
        const allTabs = [...document.querySelectorAll(`*[data-measurement="tab-tab"]`)]

        // The first time we render, all tabs will be rendered and are therefore
        // present in allTabs; in subsequent renders, we may have hidden some of
        // those tabs within the "More" tab and therefore won't see them with
        // `document.querySelectorAll()`
        // Save the tab sizes on the first render so that we can make new calculations
        // when the window is resized with the original measurements including all tabs
        const allTabSizes = this.state.measuredTabSizes || allTabs.map((node) => node.offsetWidth)

        // count how many tabs fit without overrunning the space allowed
        let { totalTabWidth, extraTabs } = allTabSizes.reduce((acc, nodeWidth) => {
            // add the next tab's width (and its spacing) to the count
            const totalTabWidth = acc.totalTabWidth + nodeWidth + tabSpacing
            // if the count has exceeded the allowed space, increase the extra tabs count
            if (totalTabWidth >= widthWithMoreTab) {
                acc.extraTabs += 1
            }
            const extraTabs = acc.extraTabs
            return Object.assign({}, acc, { totalTabWidth, extraTabs })
        }, { totalTabWidth: 0, extraTabs: 0})

        // check if the only extra tab would fit without the "More" tab
        if ((extraTabs === 1) && (totalTabWidth < tabBarWidth)) {
            extraTabs = 0;
        }

        if (extraTabs !== this.state.hiddenTabs) {
            const newState = {
                hiddenTabs: extraTabs
            }

            if (!this.state.measuredTabSizes) {
                // Only save the measured tabs sizes after the first render when
                // all tabs were present; this is true when we had no
                // measuredTabSizes in our state
                newState.measuredTabSizes = allTabSizes
            }

            this.setState(newState)
        }
    }

    componentDidUpdate() {
        if (!this.state.measuredTabSizes) {
            // If the component has updated and there are no measuredTabSizes,
            // it means the state was cleared (so we must have received new props)
            // We now need to calculate the hidden tabs with the new props
            this.calculateTabs()
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps !== this.props) {
            // When we get new props, we should reset the state in order to
            // freshly render the whole component; this allows us to correctly
            // calculate the size of all tabs and which should be hidden
            this.setState({hiddenTabs: 0, measuredTabSizes: null})
        }
    }

    componentDidMount() {
        // calculate tabs now to determine if we should hide any and re-render
        this.calculateTabs()

        // we will also want to recalculate the tabs when the window resizes
        window.addEventListener("resize", this.calculateTabs)
    }

    componentWillUnmount() {
        // clean up the listener we added when we unmount
        window.removeEventListener("resize", this.checkDimensions)
    }

    render() {
        const tabsToRender = this.props.tabOptions ? this.props.tabOptions.length - this.state.hiddenTabs : 0

        const extraTabOptions = []
        const extraTabs = this.props.tabOptions && this.props.tabOptions.slice(tabsToRender)
        if (extraTabs) {
            // convert all the hidden tabs into options for the "More" tab dropdown
            for (let i = 0; i < extraTabs.length; i++) {
                extraTabOptions.push({
                    text: extraTabs[i].text,
                    onClick: extraTabs[i].onClick,
                    dataCy: extraTabs[i].dataCy,
                })
                // convert this tab's dropdown options into their own options on the "More" dropdown
                extraTabs[i].dropdownOptions && extraTabs[i].dropdownOptions.map((option) => {
                    extraTabOptions.push({
                        text: "\t" + option.text,
                        onClick: option.onClick,
                        dataCy: option.dataCy,
                    })
                    return null
                })
            }
        }

        return (
            <S.TabBar
                data-auto-cy="CompoundNavigationalTabs"
                data-measurement={"tab-bar"}
                tabPadding={tabPadding + "px"}
            >
                {this.props.tabOptions && this.props.tabOptions.map(
                    (option, idx) => {
                        return idx >= tabsToRender ? null :
                        option.dropdownOptions ? (
                            <DropdownTab
                                dataCy={option.dataCy}
                                isSelected={this.props.selectedTab === idx}
                                text={option.text}
                                menuOptions={option.dropdownOptions}
                                key={option.text + option.dataCy}
                                buttonDropdownFixedHeight={this.props.buttonDropdownFixedHeight}
                                buttonDropdownFixedWidth={this.props.buttonDropdownFixedWidth}
                            />
                        ) : (
                            <S.TabContainer key={option.text + option.dataCy}>
                                <S.Tab
                                    data-measurement={"tab-tab"}
                                    data-cy={option.dataCy}
                                    isSelected={this.props.selectedTab === idx}
                                    onClick={option.onClick}
                                >
                                    {option.icon && <Icon iconFamily={option.fa5_icon_family} icon={option.icon} />}
                                    {option.text}
                                </S.Tab>
                            </S.TabContainer>
                        )
                    }
                )}
                { Boolean(extraTabOptions.length) &&
                    <DropdownTab
                        isSelected={this.props.selectedTab === tabsToRender}
                        text={"More"}
                        menuOptions={extraTabOptions}
                        key={"more-tab"}
                        buttonDropdownFixedHeight={this.props.buttonDropdownFixedHeight}
                        buttonDropdownFixedWidth={this.props.buttonDropdownFixedWidth}
                    />
                }
            </S.TabBar>
        )
    }
}

NavigationalTabs.propTypes = {
    // index of the currently selected tab
    selectedTab: PropTypes.number,
    // array of tabs that will be shown, in order from left to right
    tabOptions: PropTypes.arrayOf(
        PropTypes.shape({
            dataCy: PropTypes.string,

            // a tab can be a dropdown menu with more "tabs" if this array is provided
            dropdownOptions: PropTypes.arrayOf(
                PropTypes.shape({
                    dataCy: PropTypes.string,
                    onClick: PropTypes.func,
                    text: PropTypes.string,
                })
            ),

            // callback for when the tab is clicked
            onClick: PropTypes.func,
            // text to show on a tab
            text: PropTypes.string,
        })
    ),
    buttonDropdownFixedHeight: PropTypes.number,
    buttonDropdownFixedWidth: PropTypes.number,
}

export default NavigationalTabs


import React from "react"
import PropTypes from "prop-types"
import { withNamespaces } from "react-i18next"
import { StyledFooterWrapper, StyledFooterLink } from "QuorumGrassroots/framework/components/Footer/style"

const Footer = (props) => (
    <div>
        <StyledFooterWrapper className="footer-wrapper" isTiny={props.isTiny}>
            <StyledFooterLink className="quorum-link" href="https://www.quorum.us" target="_blank">
                {props.t("footer.powered_by_quorum")}
            </StyledFooterLink>
            <StyledFooterLink
                className="privacy-policy-link"
                href="https://www.quorum.us/privacy-policy/"
                target="_blank"
            >
                {props.t("footer.privacy_policy")}
            </StyledFooterLink>
        </StyledFooterWrapper>
    </div>
)

Footer.propTypes = {
    isTiny: PropTypes.bool,
    t: PropTypes.func,
}

export default withNamespaces()(Footer)

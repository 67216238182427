import React from "react"
import PropTypes from "prop-types"

import * as colors from "Components/colors"

import * as S from "Components/Molecules/DTVSectionHeader/style"
import Icon from "Components/Atoms/Icon"

const DTVSectionHeader = ({
    collapsible,
    dataCy,
    expanded,
    headerButtons,
    headerComponent,
    headerText,
    icon,
    toggleExpanded,
}) => (
    <S.SectionHeader
        data-auto-cy="MoleculeDTVSectionHeader"
        data-cy={dataCy}
        smallMargin={headerComponent}
    >
        {icon && (
            <Icon
                icon={icon}
                iconFamily="fas"
                color={colors.QuorumBlue}
                fixedWidth={false}
            />
        )}
        {headerText &&
                <S.HeaderText>{headerText}</S.HeaderText>
        }
        {headerComponent}
        <S.AccessoryArea>
            { // show the expand/collapse button only if collapsible
                collapsible &&
                    <S.Expand onClick={toggleExpanded}>
                        {expanded ? "See Less" : "See More"}
                        <S.Icon expanded={expanded}>
                            <Icon icon="angle-down" iconFamily="fal"/>
                        </S.Icon>
                    </S.Expand>
            }
            { headerButtons }
        </S.AccessoryArea>
    </S.SectionHeader>
)

DTVSectionHeader.defaultProps = {}

DTVSectionHeader.propTypes = {
    // whether the see more / see less funcitonality should be used
    collapsible: PropTypes.bool,
    dataCy: PropTypes.string,
    // should show see more or see less
    expanded: PropTypes.bool,
    // buttons to show in grid header
    headerButtons: PropTypes.arrayOf(PropTypes.element),
    // optional component as the header
    headerComponent: PropTypes.element,
    // title of the DTV grid
    headerText: PropTypes.string,
    // icon rendered to left of headerText
    icon: PropTypes.string,
    // the onClick function for see more / see less
    toggleExpanded: PropTypes.func,
}

export default DTVSectionHeader

import styled from "styled-components"

import {
    ButtonContainer,
    Text,
} from "Components/Molecules/Button/style"

export const QuicklinkWidget = styled.div`
    ${ButtonContainer} {
        width: 100%;
        button {
            padding-top: unset;
            padding-bottom: unset;
            ${({ editForm }) => editForm ? 'margin: 0 auto' : 'width: 100%'};

            ${Text} {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
`

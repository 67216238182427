/* eslint-disable prettier/prettier, @typescript-eslint/ban-types, @typescript-eslint/no-unused-vars */
//      
import { fromJS, Map } from "immutable";

import searchifyActionTypes from "shared/search/searchify/action-types";
import searchActionTypes from "shared/search/action-types";
import {
    convertFilterKeysToFilterIds,
    registerPermanentFilters,
    resetFilters,
    updateFilter,
} from "shared/search/action-creators";

import { selectAllActiveDeserializedFilters } from "shared/search/selectors";

export const updateSearchifyProperty = (uniqueFlvId, key, value) => ({
    type: searchifyActionTypes.UPDATE_SEARCHIFY_PROPERTY,
    uniqueFlvId,
    key,
    value,
})

/** ****************************************************************************
 *
 * Starting / ending searchify.
 *
 ****************************************************************************** */

// function that creates a searchifyFLVId for every instance of searchify. Uses
// position in stack as an identifier; throw and error if there's a repeat, because
// that means there's an error in our searchify stack.
export function generateUniqueSearchifyId(origins) {
    const flvId = `Searchify-${origins.size}`
    if (origins.includes(flvId)) {
        throw new Error(
            "Searchify ID Generator is not unique; are you sure you properly deleted the previous Searchify?"
        )
    }
    return flvId
}

export function initializeSearchifySlice(uniqueFlvId) {
    return { type: searchifyActionTypes.INITIALIZE_SEARCHIFY_SLICE, uniqueFlvId }
}

export function startSearchify(uniqueFlvId, searchifyDetails) {
    return { type: searchifyActionTypes.SEARCHIFY_START, uniqueFlvId, searchifyDetails }
}
export function pushSearchifyLabel(uniqueFlvId, labelPlural) {
    return {
        type: searchifyActionTypes.PUSH_SEARCHIFY_LABEL,
        uniqueFlvId,
        labelPlural,
    }
}

export function resetAllSearchify() {
    return {
        type: searchifyActionTypes.RESET_ALL_SEARCHIFY,
    }
}

/** ****************************************************************************
 *
 * Function that occurs once "submit" is hit in Searchify. This checks to make sure
 * that all criteria are satisfied (namely, the selections are under the maxSearchifySelections).
 *
 * Returns an object that will contain the necessary message to display; this is
 * because we want to make the processSubmitSearchify function platform agnostic.
 *
 ****************************************************************************** */
export const processSubmitSearchify =
    (uniqueFlvId, callbackAction, callbackFunction) =>
        (dispatch, getState) => {
            let searchState = getState().search

            const {
                searchifyFilters,
                searchifySubmitParams,
                maxSearchifySelections,
                singleSelectSearchify,
                filterOnlySearchify,
                selectAllDisabled,
            } = searchState.getIn(["filterableListViews", uniqueFlvId, "searchify"]).toJS()

            const currentCount = searchState.getIn(["filterableListViews", uniqueFlvId, "currentCount"])
            const currentFilters = searchState.getIn(["filterableListViews", uniqueFlvId, "filters"]) // eslint-disable-line

            // specify the alert message, in case we need to yell at them for trying
            // to submit too many things
            const alertMessage = `Please submit fewer than ${maxSearchifySelections} items to continue.`

            // when the previous filters has 0 result, user has no selections to clear,
            // which means searchifyFilters will not be cleared. We should still update the filters.
            const updateFilterForNoSelection =
                (searchifyFilters.querysetList.length || searchifyFilters.addedIds.length) &&
                searchifyFilters.searchifyCount === 0
            // if we haven't specified any filters yet, then we are submitting all
            // and need to add those current filters as well as the count these funcitons,
            // OR if this is filterOnlySearchify

            // this if statement determines if we are trying to submit all (because they
            // haven't selected either a category to select or added any IDs)
            if (
                (!searchifyFilters.querysetList.length && !searchifyFilters.addedIds.length) ||
                updateFilterForNoSelection ||
                filterOnlySearchify
            ) {
                // if the select all functionality is disabled, prompt the user to
                // manually select an item in order to submit.
                if (selectAllDisabled) {
                    const selectOneOrMoreMessage = singleSelectSearchify
                        ? "Please submit 1 item to continue."
                        : `Please submit 1 or more items (max ${maxSearchifySelections}) to continue.`
                    return { headerMessage: "No items selected", alertMessage: selectOneOrMoreMessage, validated: false }
                }

                if (filterOnlySearchify || updateFilterForNoSelection) {
                    dispatch({
                        type: searchifyActionTypes.SEARCHIFY_CLEAR_FILTERS,
                        uniqueFlvId,
                    })
                }

                // let's first check our currentCount to make sure it's
                // under our number, otherwise swal and return
                if (currentCount > maxSearchifySelections) {
                    return { headerMessage: "Sorry, too many items", alertMessage, validated: false }
                } else {
                    // otherwise we need to submit all elements that are displayed.
                    // gets all the filters currently applied.
                    dispatch(searchifyAddCurrentFilter(uniqueFlvId))
                    dispatch({
                        type: searchifyActionTypes.UPDATE_SEARCHIFY_COUNT,
                        uniqueFlvId,
                        searchifyCount: currentCount,
                    })
                }
            }

            searchState = getState().search
            const updatedSearchifyFilters = searchState
                .getIn(["filterableListViews", uniqueFlvId, "searchify", "searchifyFilters"])
                .toJS()
            // want to submit only if it comes in under our threshold for number of items
            // this happens after checking whether or not we are submitting all
            if (updatedSearchifyFilters.searchifyCount > maxSearchifySelections) {
                return { headerMessage: "Sorry, too many selections", alertMessage, validated: false }
            }

            if (searchState?.toJS()?.advancedSearch?.currentQDT === DjangIO.app.models.QuorumDataType.receipt.value
                || searchState?.toJS()?.advancedSearch?.currentQDT === DjangIO.app.models.QuorumDataType.disbursement.value) {
                if (searchState.toJS().searchifyOriginDetails.searchifyHeaderName === "Dashboards") {
                    const lastLoadedFilters = searchState.getIn(["filterableListViews", uniqueFlvId, "lastLoadedFilters"]).toJS()

                    if (lastLoadedFilters && Object.keys(lastLoadedFilters).length !== 0) {
                        let newFilters = {}

                        Object.keys(lastLoadedFilters).forEach(function (key) {
                            try {
                                newFilters[key] = JSON.parse(lastLoadedFilters[key])
                            } catch {
                                newFilters[key] = lastLoadedFilters[key]
                            }
                        })

                        updatedSearchifyFilters.querysetList[0] = newFilters
                    }
                }
            }

            let actionResult
            if (callbackAction) {
                // now we can execute our searchify function if we haven't already returned!
                // must get the most recent version of searchifyFilters for this!

                // this is async to accommodate swals that might appear. We want the value of the swal
                // to return null if cancelled.
                actionResult = dispatch(
                    callbackAction({ searchifyFilters: updatedSearchifyFilters, uniqueFlvId, ...searchifySubmitParams })
                )
            }
            if (callbackFunction) {
                // A function, not an action, to dispatch on callback
                callbackFunction({ searchifyFilters: updatedSearchifyFilters, ...searchifySubmitParams })
            }

            // if it's a promise, then make sure we wait until the promise resolves
            // before returning anything.
            if (actionResult && actionResult.then) {
                return actionResult.then((result) =>
                    result !== null ? { validated: true } : { validated: false, submitAsyncCancelled: true }
                )
            } else {
                // if it's undefined, then it's a simple action that's already been dispatched.
                return { validated: true }
            }
        }

/** ****************************************************************************
 *
 * Configuration-oriented action creators! Configuration makes sure that we can
 * customize Searchify to our heart's content (initial filters, max selection,
 * submit function, etc).
 *
 ****************************************************************************** */

export function configureSearchify(uniqueFlvId, config) {
    return (dispatch, getState) => {
        // handle all advanced searchify stuff
        const {
            searchifyInitialFilters, // { addedIds: [...], deletedIds: [...], querysetList: [...], searchifyCount: 0 }
            searchifyInitialFilter,
            searchifyPermanentFilter, // an object: filter you always want applied for this searchify session, restricts possible options
            searchifyInitialAddedIds, // [id1, id2, ...] -> use this if you just have some starting ids
            submitSearchifyFunc, // an action type (string) from the relevant feature, corresponds to a function in submit-searchify-functions
            callBack, // a simple callBack to be executed, accepts the return value of searchify
            // if you want your submitSearchifyFunc to be called with additional named arguments specify them here
            // by default your function will always be called with { searchifyFilters: {...} } as an argument
            searchifySubmitParams,
            singleSelectSearchify, // user can only select one item a time
            filterOnlySearchify, // user can only use filters, cannot individually select items
            // users can only individually select items,
            // cannot use "Select All" button (app/static/frontend/search-new/components/FilterableListView/SearchifyActionButtons)
            // nor "Apply All" (app/static/frontend/search-new/components/FilterableListView/SearchifyFooter)
            disableSelectAllSearchify,
            maxSearchifySelections, // default 10000 (in reducer), most number of items someone can submit
            searchifyHeaderName, // where we launched searchify from, default is Search
            // searchifySubmitLanguage, // object with submission language, defaults to { verb: 'Submit', noun: 'Selection' }. use { samePhraseAlways: 'blah' } to always show the same thing
            immediatelyShowCurrentSelection = false, // show the current seletion immediately, by default we don't
            // id, // unique identifier for the instance of searchify
            // users can use the 'Select All' button
            // app/static/frontend/search-new/components/FilterableListView/SearchifyActionButtons
            // if disableSelectAllSearchify is true, enableSelectAllActionButtonSearchify will be ignored
            enableSelectAllActionButtonSearchify,
            canOverlayAddedAndDeleteIds = false, // Allows to have same IDs simultaneously on both addedIds and deletedIds searchfy filter lists.
            // Necessary if you need to set initiate the searchify instance with pre-selected items (searchifyInitialAddedIds) and initial filters applied (searchifyInitialFilter) at the seme time.
        } = config

        // if we have initial filters for searchify (and not for
        // filterOnlySearchify mode), meaning things already
        // selected in addedIds, deletedIds, queysetList
        // this should be an object of the form
        // {
        //     addedIds: [...],
        //     deletedIds: [...],
        //     querysetList: [...],
        //     searchifyCount: num,
        // }

        if (canOverlayAddedAndDeleteIds) {
            dispatch({
                type: searchifyActionTypes.SEARCHIFY_CAN_ADD_AND_DELETE_IDS_OVERLAY,
                uniqueFlvId,
                canOverlayAddedAndDeleteIds: true,
            })
        }
        if (searchifyInitialFilter && canOverlayAddedAndDeleteIds) {
            dispatch({
                type: searchifyActionTypes.STORE_SEARCHIFY_FLV_INITIAL_FILTER,
                uniqueFlvId,
                filters: searchifyInitialFilter[0],
            })
        }
        if (searchifyInitialFilters && Object.keys(searchifyInitialFilters).length) {
            dispatch({
                type: searchifyActionTypes.SEARCHIFY_INITIAL_FILTERS,
                searchifyFilters: searchifyInitialFilters,
                uniqueFlvId,
            })

            if (
                searchifyInitialFilters.querysetList &&
                searchifyInitialFilters.querysetList.length === 1 &&
                (!searchifyInitialFilters.addedIds || searchifyInitialFilters.addedIds.length === 0) &&
                (!searchifyInitialFilters.deletedIds || searchifyInitialFilters.deletedIds.length === 0)
            ) {
                dispatch({
                    type: searchifyActionTypes.STORE_SEARCHIFY_FLV_INITIAL_FILTER,
                    uniqueFlvId,
                    filters: searchifyInitialFilters.querysetList[0],
                })
            }

            // show existing filters immediately if desired
            if (immediatelyShowCurrentSelection) {
                // apply existing search filter immediately
                dispatch(showCurrentSelection(uniqueFlvId))
            }

            // if we come in without a searchifyCount, we should retrieve that
            if (!searchifyInitialFilters.searchifyCount) {
                dispatch({ type: searchifyActionTypes.NEED_SEARCHIFY_COUNT, uniqueFlvId })
            }
        } else if (searchifyInitialAddedIds) {
            // if we only have an initial addedIds for Searchify (see: PieSelect.jsx)
            // add them to our initial state.
            // only want to add IDs if there are no searchifyInitialFilters, though!

            dispatch({
                type: searchifyActionTypes.SEARCHIFY_INITIAL_FILTERS,
                searchifyFilters: {
                    addedIds: searchifyInitialAddedIds,
                    deletedIds: [],
                    querysetList: searchifyInitialFilter || [],
                    searchifyCount: searchifyInitialAddedIds.length,
                },
                uniqueFlvId,
            })
        }

        // if there are permanent filters, add them. This will be placed directly on the
        // FLV slice, not the Searchify sub store.
        if (searchifyPermanentFilter) {
            dispatch(registerPermanentFilters(uniqueFlvId, searchifyPermanentFilter))
        }

        if (searchifySubmitParams) {
            dispatch({
                type: searchifyActionTypes.STORE_SEARCHIFY_SUBMIT_PARAMS,
                uniqueFlvId,
                searchifySubmitParams,
            })

            // show existing filters immediately if desired
            if (immediatelyShowCurrentSelection) {
                // apply existing search filter immediately
                dispatch(showCurrentSelection(uniqueFlvId))
            }
        }

        // submit function, which is required
        dispatch(updateSearchifyProperty(uniqueFlvId, "submitSearchifyFunc", submitSearchifyFunc))
        // callback for submitting
        dispatch(updateSearchifyProperty(uniqueFlvId, "callBack", callBack))

        // if we want to enter searchify with a custom max for submission
        // obviously don't do this if its single select
        if (maxSearchifySelections && !singleSelectSearchify) {
            dispatch(updateSearchifyProperty(uniqueFlvId, "maxSearchifySelections", maxSearchifySelections))
        }

        // single select logic here. Will also disable the submit-all feature of
        // the submit button.
        if (singleSelectSearchify) {
            dispatch({
                type: searchifyActionTypes.SINGLE_SELECT_SEARCHIFY,
                uniqueFlvId,
            })
        }

        if (filterOnlySearchify) {
            dispatch(updateSearchifyProperty(uniqueFlvId, "filterOnlySearchify", true))
            dispatch(updateSearchifyProperty(uniqueFlvId, "maxSearchifySelections", Infinity))
        }

        // if we want to disable the select all ability in Searchify.
        if (disableSelectAllSearchify) {
            dispatch({
                type: searchifyActionTypes.DISABLE_SELECT_ALL_SEARCHIFY,
                uniqueFlvId,
            })
        }

        // if we want to enable the "Select All" button in Searchify
        if (
            // do not enable the "Select All" button if the
            // "Apply All" footer button functionality is disabled
            !disableSelectAllSearchify &&
            enableSelectAllActionButtonSearchify
        ) {
            dispatch(updateSearchifyProperty(uniqueFlvId, "selectAllActionButtonEnabled", true))
        }

        // this displays the root of searchify, meaning where we originally
        // launched it from. for example, if we are coming from outbox, this
        // will be Outbox and will show up in the header for searchify
        if (searchifyHeaderName) {
            dispatch(updateSearchifyProperty(uniqueFlvId, "searchifyHeaderName", searchifyHeaderName))
        }

        // // always update searchify language, if we aren't passed anything then
        // // it will still clear the previous one
        // dispatch({
        //     type: searchifyActionTypes.SEARCHIFY_SUBMIT_LANGUAGE,
        //     searchifySubmitLanguage: searchifySubmitLanguage || {},
        // })
    }
}

export function applySearchifyInitialFilters(uniqueFlvId) {
    return (dispatch, getState) => {
        const searchState = getState().search

        const flvSlice = searchState.getIn(["filterableListViews", uniqueFlvId])
        const initialFilters = flvSlice.getIn(["searchify", "searchifyFlvInitialFilter"], Map())

        // because the querysetList must store information about the default and permanent
        // filters so as to fully recreate a saved search, we must here differentiate
        // between 'background' filters (permanent, default) and 'foreground' filters.
        const resourceDefaultFilters = fromJS(DjangIO.modelAtPath(flvSlice.get("resourcePath")).default_filters)
        const permanentFilters = flvSlice.get("permanentFilters")

        const backgroundFilters = resourceDefaultFilters.merge(permanentFilters)

        // initial filters not <key, string>, they're <key, any>!!!! Things go into
        // searchify's querysetList as objects / arrays / booleans, NOT strings! This is
        // to make the connection between backend and frontend not terrible. (nested searchify
        // querysets in the backend that are strings are basically illegible.)
        const foregroundFilters = initialFilters?.filter((value, key) =>
            value.equals ? !value.equals(backgroundFilters.get(key)) : value !== backgroundFilters.get(key)
        )

        if (foregroundFilters?.size) {
            const processedFilters = convertFilterKeysToFilterIds(searchState, uniqueFlvId, foregroundFilters)
            dispatch({
                type: searchActionTypes.APPLY_EXTERNAL_FILTERS,
                uniqueFlvId,
                filters: processedFilters,
            })

            // indicate that there were initial filters applied, so don't
            // apply any default filters.
            return true
        }
        return null
    }
}

/** ****************************************************************************
 *
 * Interacting and updating searchify filters! These correspond to clicking something
 * on the frontend.
 *
 ****************************************************************************** */

export function toggleSearchifyInlineSelectedStatus(
    uniqueFlvId,
    dataId,
    isInlineSelected,
    activeInCurrentQueryset
) {
    return isInlineSelected
        ? // deselect ID if currently selected
        {
            type: searchifyActionTypes.SEARCHIFY_DELETE_ID,
            id: dataId,
            uniqueFlvId,
        }
        : // if the datum is marked 'activeInCurrentQueryset', then we won't
        // add the id to the addedIds list -- instead, we'll remove it from
        // the deletedIds list!
        {
            type: searchifyActionTypes.SEARCHIFY_ADD_IDS,
            idList: [dataId],
            uniqueFlvId,
            activeInCurrentQueryset,
        }
}

export function searchifyAddCurrentFilter(uniqueFlvId) {
    return (dispatch, getState) => {
        const searchifyAppliedFilter = selectAllActiveDeserializedFilters(getState(), { uniqueFlvId })

        return dispatch({
            type: searchifyActionTypes.SEARCHIFY_APPLY_FILTER,
            uniqueFlvId,
            searchifyAppliedFilter,
        })
    }
}

export function updateSearchifyCount(uniqueFlvId, searchifyCount) {
    return {
        type: searchifyActionTypes.UPDATE_SEARCHIFY_COUNT,
        uniqueFlvId,
        searchifyCount,
    }
}

export function clearSearchifySelections(uniqueFlvId) {
    return (dispatch, getState) => {
        const searchState = getState().search

        dispatch({ type: searchifyActionTypes.CLEAR_SEARCHIFY_SELECTIONS, uniqueFlvId })

        if (
            searchState.getIn(
                [
                    "filterableListViews",
                    uniqueFlvId,
                    "searchify",
                    "appearanceValues",
                    "searchifyShowingCurrentSelection",
                ],
                false
            )
        ) {
            dispatch(toggleShowSearchifyCurrentSelection(uniqueFlvId))
        }
    }
}

/** ****************************************************************************
 *
 * Logic for the "show selected" button in Searchify
 *
 ****************************************************************************** */

export function showCurrentSelection(uniqueFlvId) {
    return (dispatch, getState) => {
        // we tell the database to give us all the values that are currently selected.
        // we do this by passing the query { current_searchify_selection: searchifyFilters } to the backend.
        // the api does some parsing with 'current_searchify_selection'.

        // get current searchify filters
        const searchState = getState().search

        const searchifyFilters = searchState.getIn([
            "filterableListViews",
            uniqueFlvId,
            "searchify",
            "searchifyFilters",
        ])

        // store current filter state, clear filters because "show selected" shows all
        dispatch({
            type: searchifyActionTypes.STORE_FILTER_SLICE,
            uniqueFlvId,
        })
        // clear current filter state
        dispatch(resetFilters(uniqueFlvId))

        // modify the 'current_searchify_selection' filter, which gets automatically
        // registered in filterablelistviews/helperFunctions generateProcessedFilterObjs
        // upon a searchify FLV mount
        dispatch(updateFilter("currentSearchifySelectionFilter", uniqueFlvId, JSON.stringify(searchifyFilters), true))
    }
}

export function hideCurrentSelection(uniqueFlvId) {
    return (dispatch, getState) => {
        dispatch(updateFilter("currentSearchifySelectionFilter", uniqueFlvId, "", false))

        // clear all filters, in the case there's been some filtering on the searchifySelection,
        // making sure to override clear all of them!
        dispatch(resetFilters(uniqueFlvId, true))
        dispatch({ type: searchifyActionTypes.APPLY_FILTER_SLICE, uniqueFlvId })
        dispatch({ type: searchifyActionTypes.CLEAR_FILTER_SLICE, uniqueFlvId })
    }
}

// logic for showing/hiding only selected filters in Searchify.
export function toggleShowSearchifyCurrentSelection(uniqueFlvId) {
    return (dispatch, getState) => {
        dispatch({
            type: searchifyActionTypes.TOGGLE_SEARCHIFY_SHOW_CURRENT_SELECTION,
            uniqueFlvId,
        })

        const searchState = getState().search

        const searchifyShowingCurrentSelection = searchState.getIn([
            "filterableListViews",
            uniqueFlvId,
            "searchify",
            "appearanceValues",
            "searchifyShowingCurrentSelection",
        ])

        if (searchifyShowingCurrentSelection) {
            dispatch(showCurrentSelection(uniqueFlvId))
        } else {
            dispatch(hideCurrentSelection(uniqueFlvId))
        }
    }
}

import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"
import { flexGap } from "utils/cssHelpers"

export const FormFooter = styled.div`
    background-color: ${colors.White};
    border-top: 2px solid ${colors.Heather};
    box-shadow: 0 -2px 4px 0 rgba(0,0,0,0.1);
    width: 100%;

    ${props => props.isFixed && css`
        position: fixed;
        left: ${rules.sidebarWidth};
        bottom: 0;
        /**
         * Need to bump up the z-index to compete with other components on the OutboxForm
         * Hopefully we can eliminate the need for this once we're further along the redesign.
        */
        z-index: 3;
    `}
`

export const SecondLine = styled.div`
    ${flexGap({ gap: 20 })}
    align-items: center;
    display: flex;
    justify-content: center;
`

export const FirstLine = styled(SecondLine)`
    padding: 12px 0;
`

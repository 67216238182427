import { createSelector, createStructuredSelector } from "reselect"
import i18n from "i18n"

import {
    selectCurrentTier,
    selectCurrentTierIcon,
    selectGamificationAchievementIcon,
    selectGamificationEnabled,
    selectGamificationRanks,
    selectGamificationTiers,
    selectShowGamificationRanks,
    selectShowGamificationTiers,
    selectSupporterPoints,
} from "QuorumGrassroots/framework/selectors"

export const selectSupporterPointsReached = createSelector(
    selectSupporterPoints,
    (points) => Boolean(points), // Any amount of points greater than 0 returns true
)

export const selectPointText = createSelector(
    selectSupporterPointsReached,
    selectSupporterPoints,
    (pointsReached, points) => (pointsReached ? points : i18n.t("gamification.stats.take_action")),
)

export const selectCurrentRank = createSelector(selectSupporterPoints, selectGamificationRanks, (points, ranks) => {
    const sortedRanks = Object.entries(ranks).sort(([nameA, pointsA], [nameB, pointsB]) => pointsB - pointsA)

    const currentRankArray = sortedRanks.find(([rankType, targetPoints]) => points >= targetPoints)

    const gamificationRank =
        currentRankArray && DjangIO.app.grassroots.types.GamificationRankType.by_value(currentRankArray[0])

    return gamificationRank ? gamificationRank.label : false
})

export const selectRankPointsReached = createSelector(
    selectCurrentRank,
    selectSupporterPointsReached,
    (rank, supporterPointsReached) => Boolean(rank && supporterPointsReached),
)

export const selectCurrentRankText = createSelector(
    selectRankPointsReached,
    selectSupporterPointsReached,
    selectCurrentRank,
    selectGamificationRanks,
    selectSupporterPoints,
    (rankPointsReached, supporterPointsReached, rank, ranks, points) => {
        if (rankPointsReached) {
            return rank
        } else if (rank && !supporterPointsReached) {
            return i18n.t("gamification.stats.take_action")
        }

        // Sort by lowest amount of points needed to reach a rank
        const sortedRanks = Object.entries(ranks).sort(([nameA, pointsA], [nameB, pointsB]) => pointsA - pointsB)

        // Grabs the first item (lowest rank). If sortedRanks is empty, returns undefined
        const currentRankArray = sortedRanks.find((item) => item)

        if (currentRankArray) {
            const pointsToReach = currentRankArray[1] - points
            return i18n.t(
                parseInt(pointsToReach) === 1
                    ? "gamification.stats.point_to_unlock"
                    : "gamification.stats.points_to_unlock",
                { points: pointsToReach },
            )
        } else {
            return i18n.t("gamification.stats.ranks_updating")
        }
    },
)

export const selectTierPointsReached = createSelector(
    selectCurrentTier,
    selectSupporterPointsReached,
    (tier, supporterPointsReached) => Boolean(tier && supporterPointsReached),
)

export const selectCurrentTierText = createSelector(
    selectTierPointsReached,
    selectSupporterPointsReached,
    selectCurrentTier,
    selectGamificationTiers,
    selectSupporterPoints,
    (tierPointsReached, supporterPointsReached, tier, tiers, points) => {
        if (tierPointsReached) {
            return tier
        } else if (tier && !supporterPointsReached) {
            return i18n.t("gamification.stats.take_action")
        }

        // Sort by lowest amount of points needed to reach a rank
        const sortedTiers = Object.entries(tiers).sort(
            ([nameA, tierPropsA], [nameB, tierPropsB]) => tierPropsA.points - tierPropsB.points,
        )

        // Grabs the first item (lowest rank). If sortedRanks is empty, returns undefined
        const currentTierArray = sortedTiers.find((item) => item)

        if (currentTierArray && currentTierArray.length > 1) {
            const pointsToEach = currentTierArray[1].points - points
            return i18n.t(
                parseInt(pointsToEach) === 1
                    ? "gamification.stats.point_to_unlock"
                    : "gamification.stats.points_to_unlock",
                { points: pointsToEach },
            )
        } else {
            return i18n.t("gamification.stats.tiers_updating")
        }
    },
)

export const selectMultipleStats = createSelector(
    selectShowGamificationTiers,
    selectShowGamificationRanks,
    (tiers, ranks) => tiers || ranks,
)

export const gamificationStatsProps = createStructuredSelector({
    // From framework selectors
    achievementIcon: selectGamificationAchievementIcon,
    gamificationEnabled: selectGamificationEnabled,
    showGamificationTiers: selectShowGamificationTiers,
    showGamificationRanks: selectShowGamificationRanks,

    // GamificationStats specific selectors
    currentRankText: selectCurrentRankText,
    currentTierIcon: selectCurrentTierIcon,
    currentTierText: selectCurrentTierText,
    multipleStats: selectMultipleStats,
    pointText: selectPointText,
    rankPointsReached: selectRankPointsReached,
    supporterPointsReached: selectSupporterPointsReached,
    tierPointsReached: selectTierPointsReached,
})

// https://projects.invisionapp.com/d/main/#/console/21044500/456272284/preview

import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"

import * as colors from "Components/colors"
import * as S from "Components/Compounds/TopBarSearchify/style"
import Tooltip from "Components/Molecules/Tooltip"

import { formatNumberForDisplay } from "utils/helperFunctions"

import Icon from "Components/Atoms/Icon"

const TopBarSearchify = ({
    cancelSearchify,
    currentCount,
    currentCountLoading,
    dataTypeLabel,
    initialCount,
    labelStack,
    selectedCount,
    isMobile,
}) => {
    const [initialCountInitialized, setInitialCountInitialized] = useState(false);
    const [currentCountInitialized, setCurrentCountInitialized] = useState(false);

    useEffect(() => {
        if (!initialCountInitialized && initialCount !== "0") {
            setInitialCountInitialized(true);
        }
    }, [initialCount, initialCountInitialized]);

    useEffect(() => {
        if (!currentCountInitialized && currentCount !== "0") {
            setCurrentCountInitialized(true);
        }
    }, [currentCount, currentCountInitialized]);

    return (
        <S.TopBarSearchify
            data-auto-cy="CompoundTopBarSearchify"
            isMobile={isMobile}
        >
            {
                Boolean(labelStack && labelStack.length) &&
                    <S.TopBarSearchifyLabelsContainer
                        onClick={cancelSearchify}
                        isMobile={isMobile}
                    >
                        <Icon
                            color={colors.gray7}
                            icon="arrow-left"
                            iconFamily="far"
                            size="2x"
                            style={{
                                marginRight: "15px",
                                ...isMobile ? {"fontSize": "1.5rem"} : {},
                            }}
                        />
                        {
                            labelStack.map((label, i) => (
                                <S.TopBarSearchifyMediumTitle>
                                    {label.substring(0, label.indexOf("-"))}
                                    {
                                        (i !== labelStack.length - 1) &&
                                            <Icon
                                                color={colors.gray7}
                                                icon="circle"
                                                iconFamily="fas"
                                                size="1x"
                                                style={{
                                                    fontSize: "5px",
                                                    margin: "0 10px",
                                                }}
                                            />
                                    }
                                </S.TopBarSearchifyMediumTitle>
                            ))
                        }
                    </S.TopBarSearchifyLabelsContainer>
            }
            <S.TopBarSearchifyCountWrapper>
                <S.TopBarSearchifyMediumTitle>
                    {dataTypeLabel}:
                </S.TopBarSearchifyMediumTitle>
                {
                    <S.TopBarSearchifyRegularTitle>
                        {
                            (!initialCountInitialized || !initialCount || currentCountLoading)
                            ? "Counting..."
                            : (
                                <React.Fragment>
                                    <S.TooltipContainer>
                                        <Tooltip
                                            placement="right"
                                            isTiny
                                            text={"Too many results to display exact count."}
                                            >
                                        </Tooltip>
                                    </S.TooltipContainer>
                                    ~{initialCountInitialized && formatNumberForDisplay(parseInt(initialCount.replace(/,/g, ""), 10))} Total
                                </React.Fragment>
                            )
                        }
                    </S.TopBarSearchifyRegularTitle>
                }
            </S.TopBarSearchifyCountWrapper>
            {
                (
                    initialCount && (
                        // if we have applied a filter and the query has not resolved yet
                        currentCountLoading ||
                        // if we have filtered the FilterableListView's QuerySet
                        (initialCount !== currentCount)
                    )
                ) &&
                <>
                    <Icon
                        color={colors.gray7}
                        icon="chevron-right"
                        iconFamily="far"
                        size="1x"
                        style={
                            {fontSize: "16px"}
                        }
                    />
                    <S.TopBarSearchifyCountWrapper>
                        <S.TopBarSearchifyMediumTitle>
                            Filtered:
                        </S.TopBarSearchifyMediumTitle>
                        <S.TopBarSearchifyRegularTitle>
                            {
                                (!currentCountInitialized || currentCountLoading)
                                    ? "Counting..."
                                    : (
                                        <React.Fragment>
                                            <S.TooltipContainer>
                                                <Tooltip
                                                    placement="right"
                                                    isTiny
                                                    text={"Too many results to display exact count."}
                                                    >
                                                </Tooltip>
                                            </S.TooltipContainer>
                                            ~{currentCountInitialized && formatNumberForDisplay(parseInt(currentCount.replace(/,/g, ""), 10))}
                                        </React.Fragment>
                                    )
                            }
                        </S.TopBarSearchifyRegularTitle>
                    </S.TopBarSearchifyCountWrapper>
                </>
            }
            {
                // if we have filtered the FilterableListView's QuerySet
                selectedCount &&
                <>
                    <Icon
                        color={colors.gray7}
                        icon="chevron-right"
                        iconFamily="far"
                        size="1x"
                        style={
                            {fontSize: "16px"}
                        }
                    />
                    <S.TopBarSearchifyCountWrapper>
                        <S.TopBarSearchifyMediumTitle>
                            Selected:
                        </S.TopBarSearchifyMediumTitle>
                        <S.TopBarSearchifyRegularTitle>
                            {selectedCount}
                        </S.TopBarSearchifyRegularTitle>
                    </S.TopBarSearchifyCountWrapper>
                </>
            }
        </S.TopBarSearchify>
    )
}

TopBarSearchify.propTypes = {
    cancelSearchify: PropTypes.func,
    currentCount: PropTypes.number,
    currentCountLoading: PropTypes.bool,
    dataTypeLabel: PropTypes.string,
    initialCount: PropTypes.number,
    selectedCount: PropTypes.number,
}

export default TopBarSearchify

import React from "react"
import PropTypes from "prop-types"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"

const TwitterTimeline = (props) => (
    <WidgetStyleWrapper md={props.colWidth} useWidgetStyling={props.useWidgetStyling} title={props.title}>
        <a className="twitter-timeline" href={`https://twitter.com/${props.content}`}>
            Tweets By {props.content}
        </a>
    </WidgetStyleWrapper>
)

TwitterTimeline.propTypes = {
    colWidth: PropTypes.number,
    content: PropTypes.string.isRequired,
    title: PropTypes.string,
    useWidgetStyling: PropTypes.bool,
}

TwitterTimeline.defaultProps = {
    useWidgetStyling: true,
}

export default TwitterTimeline

import React, { useEffect, useState } from "react"
import styled, { keyframes, css } from "styled-components"

const slideOutLeft = keyframes`
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
`

const slideInRight = keyframes`
    0% { transform: translateX(100%); }
    75% { transform: translateX(-5%); }
    100% { transform: translateX(0); }
`

const ANIMATION_OUT_DURATION = 100
const ANIMATION_IN_DURATION = 500

const AnimatedComponent = styled.div`
    width: 100%;
    height: 100%;
    ${(props) =>
        !props.isFirstRender &&
        (props.transitioning
            ? css`
                  animation: ${slideOutLeft} ${ANIMATION_OUT_DURATION}ms forwards;
              `
            : css`
                  animation: ${slideInRight} ${ANIMATION_IN_DURATION}ms forwards;
              `)}
`

export const AnimationContainer = ({ children, shouldAnimate, ...props }) => {
    const isFirstRender = React.useRef(true)

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
        }
    }, [])

    return (
        <>
            <AnimatedComponent transitioning={shouldAnimate} isFirstRender={isFirstRender.current}>
                {children}
            </AnimatedComponent>
        </>
    )
}
export const useAnimation = () => {
    const [shouldAnimate, setShouldAnimate] = useState(false)

    const animate = () => {
        setShouldAnimate(true)
        setTimeout(() => {
            setShouldAnimate(false)
        }, ANIMATION_OUT_DURATION)
    }

    return { animate, shouldAnimate }
}

import Enum from "./Enum"
import Model from "./Model"
import Manager from "./Manager"
import { getMoneyballModels, initMoneyballObject } from "./moneyballHelpers"

export default class DjangIOInstance {
    constructor(data) {
        this.Manager = Manager
        this.Model = Model
        this._defaultModel = Model
        this._defaultManager = Manager
        this.Enum = Enum
        this._defaultEnum = Enum
        Object.entries(data).map(this.traverse(data))
        Object.assign(this, data)
        // We are gonna go ahead and inject widget engine here, as it behaves a little different
        if (this.app.widgets && this.app.widgets.models) {
            this.app.widgets.models.WidgetEngine = new Model({
                endpoint: "/api/widget_engine/",
                fields: [],
                __name__: "WidgetEngine",
                default_filters: {},
                default_exclude_filters: {},
            })
        }

        this.app.moneyball = initMoneyballObject()

        if (this.app.moneyball && this.app.moneyball.models) {
            getMoneyballModels(this.app.moneyball.models)
        }
    }
    traverse(prev) {
        return ([key, value]) =>
            value.__is_enum || value.__is_model
                ? (value.__is_enum && (prev[key] = new Enum(value))) ||
                  (value.__is_model && (prev[key] = new Model(value)))
                : Object.entries(value).map(this.traverse(value))
    }
    enumFromField(field) {
        return field.choices.__module__.split(".").reduce((acc, nextIndex) => acc[nextIndex], this)[
            field.choices.__name__
        ]
    }

    modelAtPath(modelPath) {
        return modelPath
            ? modelPath.split(".").reduce((acc, nextLookup) => (acc ? acc[nextLookup] : acc), this)
            : undefined
    }
}

import React from "react"
import PropTypes from "prop-types"
import * as S from "Components/Molecules/EmptyStateSearch/style"

import Icon from "Components/Atoms/Icon"

const EmptyStateSearch = (props) => (
    <S.EmptyStateSearch
        data-auto-cy="MoleculeEmptyStateSearch"
    >
        {
            props.icon &&
                <S.IconWrapper>
                    <Icon
                        icon={props.icon}
                        iconFamily={props.iconFamily}
                        size="1x"
                        style={{
                            fontSize: "6vw",
                        }}
                    />
                </S.IconWrapper>
        }
        <S.Content>
            <S.Title>{props.title}</S.Title>
            <S.Tip>{props.tip}</S.Tip>
            <S.Text>{props.text}</S.Text>
        </S.Content>
    </S.EmptyStateSearch>
)

EmptyStateSearch.defaultProps = {
    tip: "Tip:"
}
EmptyStateSearch.propTypes = {
    icon: PropTypes.string,
    iconFamily: PropTypes.string,
    text: PropTypes.string,
    tip: PropTypes.string,
    title: PropTypes.string,
}

export default EmptyStateSearch

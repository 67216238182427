import React from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form/immutable"
import { Link } from "react-router-dom"

import { requiredFieldValidation } from "QuorumGrassroots/helperFunctions"

import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import { FlexRow } from "QuorumGrassroots/widgets/styled-components/styles"
import { InputField } from "app/static/frontend/forms/components/InputField"
import { StyledContrastText } from "QuorumGrassroots/styled-components/components/StyledContrastText"

const LoginForm = ({
    handleSubmit,
    invalid,
    loginPagePasswordLabel,
    loginPagePreText,
    loginPagePostText,
    loginPageUsernameLabel,
    ssoUrl,
    submitting,
    t,
    isCampaignPage,
}) => (
    <div className="login-form">
        {loginPagePreText && (
            <StyledContrastText
                as="div"
                isCampaignPage={isCampaignPage}
                className="login-form-pre-text"
                dangerouslySetInnerHTML={{ __html: loginPagePreText }}
            />
        )}
        {ssoUrl ? (
            <StyledButton href={ssoUrl} disabled={submitting} isCampaignPage={isCampaignPage}>
                {t("registration.sso.login")}
            </StyledButton>
        ) : (
            <form className="login-form" onSubmit={handleSubmit}>
                <Field
                    name="username"
                    accessibilityId="username"
                    label={loginPageUsernameLabel || t("registration.username.label")}
                    dataCy="username"
                    component={InputField}
                    placeholder={loginPageUsernameLabel || t("registration.username.placeholder")}
                    displayErrorWithoutTouch={false}
                    validate={requiredFieldValidation}
                />
                <Field
                    name="password"
                    accessibilityId="password"
                    label={loginPagePasswordLabel || t("registration.password.label")}
                    dataCy="password"
                    component={InputField}
                    placeholder={loginPagePasswordLabel || t("registration.password.placeholder")}
                    type="password"
                    displayErrorWithoutTouch={false}
                    validate={requiredFieldValidation}
                />
                <StyledButton
                    onClick={handleSubmit}
                    disabled={invalid || submitting}
                    type="submit"
                    isCampaignPage={isCampaignPage}
                    activateNGGTheme
                >
                    {submitting ? t("form.submitting") : t("form.submit_form")}
                </StyledButton>
                <Link to={DjangIO.app.grassroots.types.GrassrootsFoundationalRoute.forgot_password.path}>
                    <FlexRow justifyContent="center" margin="10px 0px 0px 0px" data-cy="forgot">
                        {t("registration.password.forgot.question")}
                    </FlexRow>
                </Link>
            </form>
        )}
        {loginPagePostText && (
            <StyledContrastText
                as="div"
                isCampaignPage={isCampaignPage}
                className="login-form-post-text"
                dangerouslySetInnerHTML={{ __html: loginPagePostText }}
            />
        )}
    </div>
)

LoginForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    loginPagePasswordLabel: PropTypes.string,
    loginPagePreText: PropTypes.string,
    loginPageUsernameLabel: PropTypes.string,
    ssoUrl: PropTypes.string,
    submitting: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    isCampaignPage: PropTypes.bool,
}

export default LoginForm

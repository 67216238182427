import React from "react"
import PropTypes from "prop-types"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import { renderButtonLink } from "QuorumGrassroots/widgets/styled-components/helperFunctions"
import {
    StyledFullBleedImageWidget,
    StyledFullBleedImageText,
    StyledFullBleedImageButton,
    OpacityLayer,
    StyledFullBleedImg,
    StyledButtonWrapper,
} from "QuorumGrassroots/widgets/FullBleedImage/components/style"

const FullBleedImageWidget = (props) => (
    <WidgetStyleWrapper className="full-bleed-image-widget" md={props.colWidth} useWidgetStyling={false}>
        <StyledFullBleedImageWidget
            className="full-bleed-image"
            imagePath={props.imageUrl}
            isLeftAligned={props.isLeftAligned}
            isFirst={props.isFirst}
            isLast={props.isLast}
            nextWidget={props.nextWidget}
            navBarIsTransparent={props.navBarIsTransparent}
            navBarStyle={props.navBarStyle}
            imageContain={props.imageContain}
            isCampaignPage={props.isCampaignPage}
        >
            {props.imageContain && (
                <StyledFullBleedImg
                    src={props.imageUrl}
                    imageContain={props.imageContain}
                    isCampaignPage={props.isCampaignPage}
                />
            )}

            <OpacityLayer imageContain={props.imageContain} includeText={props.includeText}>
                {props.includeText && (
                    <StyledFullBleedImageText
                        className="full-bleed-image-text"
                        isLeftAligned={props.isLeftAligned}
                        imageContain={props.imageContain}
                    >
                        {props.text}
                    </StyledFullBleedImageText>
                )}
                {props.includeButton && (
                    <StyledButtonWrapper imageContain={props.imageContain} isLeftAligned={props.isLeftAligned}>
                        <StyledFullBleedImageButton
                            className="full-bleed-image-button"
                            isLeftAligned={props.isLeftAligned}
                            imageContain={props.imageContain}
                        >
                            {renderButtonLink(props.buttonUrl, props.buttonText)}
                        </StyledFullBleedImageButton>
                    </StyledButtonWrapper>
                )}
            </OpacityLayer>
        </StyledFullBleedImageWidget>
    </WidgetStyleWrapper>
)

FullBleedImageWidget.propTypes = {
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
    colWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageContain: PropTypes.bool.isRequired,
    imageUrl: PropTypes.string.isRequired,
    includeButton: PropTypes.bool.isRequired,
    includeText: PropTypes.bool.isRequired,
    isFirst: PropTypes.string.isRequired,
    isLast: PropTypes.bool.isRequired,
    isLeftAligned: PropTypes.bool.isRequired,
    navBarIsTransparent: PropTypes.bool,
    navBarStyle: PropTypes.number,
    nextWidget: PropTypes.number,
    text: PropTypes.string,
}

export default FullBleedImageWidget

import React from "react"
import PropTypes from "prop-types"

import * as colors from "Components/colors"
import * as S from "Components/Atoms/ColorPicker/style"

export const ColorPicker = (props) => (
    <S.ColorPicker data-auto-cy="AtomColorPicker">
        <S.ColorPickerContainer
            multi={props.multi}
        >
            <S.ColorPickerTextInputContainer
                multi={props.multi}
            >
                {props.textInput}
                {
                    (props.multi && props.renderRemoveButton) &&
                        <S.ColorPickerRemoveButton
                            color={colors.QuorumBlue}
                            icon="trash-alt"
                            iconFamily="fas"
                            onClick={props.removeOnClick}
                            size="1x"
                        />
                }
            </S.ColorPickerTextInputContainer>

            {
                props.colorLabel &&
                    <S.Label>
                        {props.colorLabel}
                    </S.Label>
            }
            <S.ColorPickerColorInputContainer
                multi={props.multi}
            >
                {props.colorInput}
            </S.ColorPickerColorInputContainer>
        </S.ColorPickerContainer>
    </S.ColorPicker>
)

ColorPicker.propTypes = {
    colorInput: PropTypes.element,
    multi: PropTypes.bool,
    renderRemoveButton: PropTypes.bool,
    removeOnClick: PropTypes.func,
    textInput: PropTypes.element,
}

export default ColorPicker

import styled from "styled-components"

export const InputFileButton = styled.div`
`

export const Input = styled.input.attrs((props) => ({
    type: "file",
    disabled: props.disabled,
    multiple: props.multiple,
    onChange: props.onChange,
    name: props.id,
    id: props.id,
}))`
    /* Disable default styling */
    display: none;

    &:disabled {
        display: none;
    }
`

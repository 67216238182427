import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Atoms/FormTextareaField/style"

export const FormInputField = props => (
    <S.FormInputFieldContainer data-auto-cy="AtomTextareaInputField">
        {
            (!props.meta.error && props.label) &&
                <S.Label>{props.label}</S.Label>
        }
        {
            props.meta.error &&
                <S.LabelError>{props.meta.error}</S.LabelError>
        }
        <S.FormInputField
            data-cy={props.dataCy}
            disabled={props.disabled}
            error={props.meta.error}
            maxLength={props.maxLength}
            placeholder={props.placeholder}
            // necessary to get this working with redux-form
            // https://redux-form.com/8.3.0/docs/faq/customcomponent.md/
            {...props.input}
        />
    </S.FormInputFieldContainer>
)

FormInputField.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),

    // from redux-form Field
    input: PropTypes.object,
    // error state implementation based off of
    // https://redux-form.com/8.3.0/examples/syncvalidation/
    meta: PropTypes.object,

    // props
    dataCy: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
}

export default FormInputField

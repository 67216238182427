import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const DropdownWrapper = styled.div`
    // this allows the dropdown box to take the width of the dropdown button
    position: relative;
    display: inline-block;

    // prevents the button from collapsing when the flex container shrinks
    flex-shrink: 0;

    // allow the wrapped component to properly inherit its height from the
    // wrapper of this dropdown, in case the wrapped component inherits its height
    height: inherit;
`

export const DropdownBox = styled.div`
    // style
    padding: .25rem 0 .5rem;
    background-color: ${colors.White};
    border: 1px solid ${colors.gray2};
    border-radius: ${rules.BorderRadius};
    box-shadow: 0px 12px 40px -12px #00000033;

    // flexible container
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    // space between the button that opens the dropdown
    margin-top: ${({verticalOffset}) => verticalOffset ? verticalOffset : "7px"};
    margin-left: ${({horizontalOffset}) => horizontalOffset ? horizontalOffset : "0px"};

    // make the box appear without stretching its containing flexbox
    // and without being hidden behind other elements
    position: absolute;
    // must be less than SearchActionButtons (currently z-index: 3)
    z-index: 2;

    // set the right edge of the box to the right edge of the parent if offscreen
    ${({forceOnScreen, isRightAlign, rightEdgeOffset}) => {
        if (isRightAlign && !forceOnScreen) {
            return css`right: 0;`
        } else if (forceOnScreen) {
            return css`right: ${rightEdgeOffset};`
        }
    }}

    ${props => (props.isOverflow) && css`
        max-height: calc(100vh - ${props.overflowHeightOffset}px);
        overflow-y: auto;
    `}

    // override the box's max-height set above
    ${props => (props.buttonDropdownFixedHeight) && css`
        max-height: ${props.buttonDropdownFixedHeight}px;
    `}

    // make the box take the width of the dropdown button,
    // or stretch to fit its options if those are wider
    min-width: fit-content;
    width: 100%;

    // override the box's width set above
    ${props => (props.buttonDropdownFixedWidth) && css`
        width: ${props.buttonDropdownFixedWidth}px;
    `}

    // "pre" or "nowrap" is needed to make min-width work because the parent has "display: inline"
    // pre also allows rendering preceding whitespace
    white-space: pre;

`

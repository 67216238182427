import styled from "styled-components"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"

import { hexCodetoRGBA } from "imports/desktopHelperFunctions"
import { styleInDesktopScreenSize, styleInMobileScreenSize } from "QuorumGrassroots/styled-components/helperFunctions"

const StatSectionWidthHalfPageMultipleStat = 50
const StatSectionWidthHalfPageSingleStat = 29

const statSectionWidthPercentage = (multipleStats) =>
    multipleStats ? StatSectionWidthHalfPageMultipleStat : StatSectionWidthHalfPageSingleStat

export const StyledWidgetContents = styled.div`
    display: flex;

    ${styleInMobileScreenSize("flex-direction: column;")}
`

export const StyledTitleDescriptionSection = styled.div`
    width: ${(props) => 100 - statSectionWidthPercentage(props.multipleStats)}%;
    display: inline-block;
    padding: 15px;

    ${styleInMobileScreenSize("width: 100%;")}
`

export const StyledStatSection = styleWithOrgDesign(styled.div`
    width: ${(props) => statSectionWidthPercentage(props.multipleStats)}%;
    display: flex;
    justify-content: space-around;
    background-color: ${(props) => props.organizationDesign.light_grey};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    ${styleInMobileScreenSize("width: 100%;")}
`)

export const StyledIndividualStatBox = styleWithOrgDesign(styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: ${(props) =>
        props.unlocked ? props.organizationDesign.light_grey : hexCodetoRGBA(props.organizationDesign.light_grey, 0.6)};
    padding-top: 20px;
    padding-bottom: 18px;
    padding-left: 10px;
    padding-right: 10px;

    &:not(:last-child) {
        border-right: 1px solid ${(props) => props.organizationDesign.light_grey};
    }
`)

export const StyledStatBoxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${(props) => props.fullWidth && styleInDesktopScreenSize("flex-direction: row;")}
`

export const StyledStatIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    align-items: center;

    ${(props) =>
        props.fullWidth &&
        styleInDesktopScreenSize(`
        flex-direction: row;
        margin-bottom: 0px;
    `)}
`

export const StyledCurrentTierIcon = styled.div`
    background-image: url("${(props) => props.currentTierIcon}");
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
`

export const StyledStatIcon = styleWithOrgDesign(styled.i`
    color: ${(props) =>
        props.unlocked
            ? props.organizationDesign.secondary_color
            : hexCodetoRGBA(props.organizationDesign.secondary_color, 0.4)};
    font-size: 32px;

    ${(props) =>
        props.fullWidth &&
        styleInDesktopScreenSize(`
            height: 50px;
            margin-right: 10px;
            font-size: 60px;
            position: relative;
            bottom: 5px;
    `)}
`)

export const StyledStatValue = styleWithOrgDesign(styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    color: ${(props) => props.organizationDesign.primary_color};

    ${(props) =>
        styleInDesktopScreenSize(`
        ${props.responsiveFont && "font-size: calc(1vw + 5px);"}
        ${props.lockedFont && "font-size: 13px;"}
        ${props.fullWidth && `text-align: left;`}
    `)}

    ${(props) => props.lockedFont && "font-size: 13px;"}
`)

export const StyledStatHr = styleWithOrgDesign(styled.hr`
    border-color: ${(props) => props.organizationDesign.secondary_color};
    margin-top: 5px;
    margin-bottom: 5px;
`)

export const StyledStatType = styleWithOrgDesign(styled.div`
    color: ${(props) => props.organizationDesign.primary_color};
    font-size: 13px;
    text-align: center;

    ${(props) => props.fullWidth && styleInDesktopScreenSize("text-align: left;")}
`)

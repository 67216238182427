import React from "react"
import styled from "styled-components"

const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    order: 3;
`

export const SearchActionsWrapper = ({ theme, isSearchify, children }) => {
    if (theme==="primary" && !isSearchify) {
        return <ActionsWrapper>{children}</ActionsWrapper>
    }

    return children
}

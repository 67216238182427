import i18n from "i18n"
import { createSelector, createStructuredSelector } from "reselect"

import { selectOrganization } from "QuorumGrassroots/framework/selectors"
import { selectWidgetContent } from "QuorumGrassroots/widgets/selectors"
import { socialMediaShareMap } from "QuorumGrassroots/helperFunctions"

export const selectSocialLinks = createSelector([selectWidgetContent, selectOrganization], (campaign, org) => {
    if (!Object.keys(campaign).length) {
        return
    }

    const socialPlatforms = campaign.social_media_platforms

    return socialPlatforms.map((platformType) => {
        const platform = DjangIO.app.grassroots.enums.SocialMediaType.by_value(platformType)

        const submitFunc = socialMediaShareMap[platformType]

        const url = campaign.share_url
        const media = campaign.social_image_url || campaign.background_image || org.logo

        const text = campaign.pos_message

        return {
            onClick: () => submitFunc({ url, media, text }),
            label: i18n.t("campaign.thanks.share.button", { platform: platform.label }),
            icon: platform.icon,
            color: platform.color,
            socialType: platformType,
            platform: platformType,
        }
    })
})

export const shareLinkSelectors = createStructuredSelector({
    socialLinks: selectSocialLinks,
})

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/Comment/style"
import * as colors from "Components/colors"

import CircleImage from "Components/Molecules/CircleImage"

const Comment = ({circleColor, circleText, dataCy, date, name, text}) => (
    <S.Comment
        data-auto-cy="MoleculeComment"
        data-cy={dataCy}
    >
        <S.CommentInfoContainer>
            <S.CircleArea>
                <CircleImage
                    text={circleText}
                    fontColor={colors.White}
                    circleColor={circleColor}
                    fill
                    diameter="26px"
                    fontSize="14px"
                />
            </S.CircleArea>
            <S.InfoArea>
                <S.Name>{name}</S.Name>
                <S.Date>{date}</S.Date>
            </S.InfoArea>
        </S.CommentInfoContainer>
        <S.CommentTextContainer>
            <S.TextSpan>{text}</S.TextSpan>
        </S.CommentTextContainer>
    </S.Comment>
)

Comment.defaultProps = {}

Comment.propTypes = {
    // color of the circle
    circleColor: PropTypes.string,
    // text to show in the circle (commenter's initials)
    circleText: PropTypes.string,
    dataCy: PropTypes.string,
    // date of the comment
    date: PropTypes.string,
    // name of the commenter
    name: PropTypes.string,
    // can be a string of text to render in the text section, or
    // an array of react elements, such as those created from
    // text marked up in HTML
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
}

export default Comment

import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"

import { RegionListWidget } from "QuorumGrassroots/widgets/RegionList/components/index"
import { initializer } from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/InitializeStoreWrapper"
import { regionsListSelector } from "QuorumGrassroots/widgets/RegionList/selectors"

const connectedRegionList = connect(regionsListSelector, null)(RegionListWidget)
const initializedRegionList = initializer(connectedRegionList)

export default withNamespaces()(initializedRegionList)

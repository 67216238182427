import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/OnboardingScreen/style"
import BigBoiSelects from "Components/Molecules/BigBoiSelects"
import Button from "Components/Molecules/Button"
import * as rules from "Components/rules"
import { useWindowSize } from "utils/hooks"

const OnboardingScreen = ({
    handleSubmit,
    onChangePersona,
    onChangeTerms,
    passwordPrompt,
    personaHeader,
    personas,
    selectedPersona,
    termsAndConditionsLink,
    text,
    title,
    submitDisabled
}) => {

    const { windowHeight } = useWindowSize()

    return (
        <S.OnboardingScreen data-auto-cy="MoleculeOnboardingScreen">
            <S.Circle>
                <svg height="100%" width="100%" viewBox="0 0 100 100">
                    <circle cx="50%" cy="50%" r="50%"/>
                </svg>
            </S.Circle>
            <S.Title>{title}</S.Title>
            <S.Text>{text}</S.Text>
            {passwordPrompt && <S.PasswordPrompt>{passwordPrompt}</S.PasswordPrompt>}
            {personas && personas.length > 1 &&
                <React.Fragment>
                    <S.PersonaHeader>{personaHeader}</S.PersonaHeader>
                    <S.PersonaSelect>
                        {personas.map(persona => (
                            <BigBoiSelects
                                key={persona.value}
                                children={persona.label}
                                icon={persona.icon}
                                iconFamily={persona.iconFamily}
                                isSelected={persona.value === selectedPersona}
                                onClick={() => onChangePersona(persona.value)}
                            />
                        ))}
                    </S.PersonaSelect>
                </React.Fragment>
            }
            <S.Terms>
                <label>
                    <input type="checkbox" name="terms" onChange={(value) => onChangeTerms(value)} data-cy="terms"/>
                    I Accept the&nbsp;
                    <a
                        href={termsAndConditionsLink}
                        className="registration-form-terms-link"
                        target={rules.aTarget}
                        rel={rules.aRel}
                    >
                        Terms and Conditions
                    </a>
                </label>
            </S.Terms>
            <Button
                text="Get Started!"
                onClick={handleSubmit}
                isGiant={windowHeight >= 900}
                dataCy="registration-submit"
                disabled={submitDisabled}
            />
        </S.OnboardingScreen>
    )
}

OnboardingScreen.defaultProps = {}

OnboardingScreen.propTypes = {
    // Function to call when submitting the form
    handleSubmit: PropTypes.func.isRequired,
    // Function to call when selecting one of the Personas
    onChangePersona: PropTypes.func.isRequired,
    // Function to call when checking/unchecking the Terms
    onChangeTerms: PropTypes.func.isRequired,
    // Fields to render for the Password
    passwordPrompt: PropTypes.element.isRequired,
    // Header to display over the available Personas
    personaHeader: PropTypes.string.isRequired,
    // Array of Personas objects available to the current User
    personas: PropTypes.array.isRequired,
    // Value of the currently selected Persona
    selectedPersona: PropTypes.number,
    // URL of the Terms and Conditions document
    termsAndConditionsLink: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    // Whether the Submit button should be disabled
    submitDisabled: PropTypes.bool,
}

export default OnboardingScreen
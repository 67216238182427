import React, { useEffect, useState } from "react"
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"

/**
 * Renders a Stripe CardElement component that uses hooks to handle validation
 * and form submission errors
 *
 * @return {Component}
 */
const CheckoutForm = ({ onChange }) => {
    const [error, setError] = useState(null)
    const stripe = useStripe()
    const elements = useElements()

    if (elements) {
        onChange({
            stripeCard: elements.getElement(CardElement),
            stripeObject: stripe,
        })
    }

    /**
     * Handle real-time validation errors as the user types into the CardElement component/
     * If there is a corresponding erorr, call 'setError' with the error
     *
     * @param  {object} event - Event taking place within CardElement
     */
    const handleChange = (event) => {
        if (event.error) {
            setError(event.error.message)
        } else if (!event.complete) {
            setError("Please fill in all fields")
        } else {
            setError(null)
        }

        onChange({
            complete: event.complete,
            error: event.error,
        })
    }

    return (
        <form>
            <div>
                <CardElement id="card-element" onChange={handleChange} />
                <div>{error}</div>
            </div>
        </form>
    )
}

let loadStripe
const ffPacEnabled = isFeatureEnabled("ff_pac")
if (ffPacEnabled) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const stripejs = require("@stripe/stripe-js")
    // eslint-disable-next-line prefer-destructuring
    loadStripe = stripejs.loadStripe
}

const CreditCardForm = ({ stripeApiKey, ...props }) => {
    const [stripePromise, setStripePromise] = useState(null)

    useEffect(() => {
        if (!loadStripe || !stripeApiKey) {
            throw "Cannot initialize CreditCardForm: no Stripe API Key"
        }

        const promise = loadStripe(stripeApiKey)
        setStripePromise(promise)
    }, [])

    return ffPacEnabled ? (
        <Elements stripe={stripePromise}>
            <CheckoutForm onChange={props.input.onChange} />
        </Elements>
    ) : null
}

export default CreditCardForm

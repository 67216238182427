import styled from "styled-components"
import * as colors from "Components/colors"
import * as rules from "Components/rules"

import {
    DatePickerWrapper,
    DatePickerOuterWrapper,
} from "Components/Molecules/DatePicker/style"

export const DatePickerRangeWrapper = styled(DatePickerWrapper)`
    &.react-datepicker {
        border: 0;
        box-shadow: none;
        padding: 0;
    }

    .react-datepicker__header {
        padding-top: 17px;
    }

    .react-datepicker__input-container {
        display: flex;
    }

    .react-datepicker__day, .react-datepicker__day-name {
        align-items: center;
        display: flex;
        height: 40px;
        justify-content: center;
        margin: initial !important;
        width: 40px;
    }

    .react-datepicker__day-names, .react-datepicker__week {
        justify-content: initial;
    }

    .react-datepicker__day--in-range,
    .react-datepicker__day--in-selecting-range {
        :not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end):not(.react-datepicker__day--selecting-range-start):not(.react-datepicker__day--selecting-range-end) {
            border-radius: 0px;
        }
    }
`

export const DatePickerRangeOuterWrapper = styled(DatePickerOuterWrapper)`
    ${rules.CardStyle}
    background-color: ${colors.White};
    margin-top: 3px;
    min-width: 320px;
    padding: 15px;
    position: absolute;
    right: 0;
    z-index: 1;

    .react-datepicker-popper {
        padding: 0;
        // i have no idea how to properly style the absolutely positioned popper.js wrapper
        // https://stackoverflow.com/a/67321850/6201291
        // https://github.com/Hacker0x01/react-datepicker/blob/master/src/popper_component.jsx
        // https://github.com/Hacker0x01/react-datepicker/blob/master/docs/popper_component.md
        inset: initial !important;
        transform: initial !important;
        position: initial !important;
    }
`


import React from "react"
import PropTypes from "prop-types"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import { renderStyledVideo } from "QuorumGrassroots/widgets/styled-components/helperFunctions"

const VideoWidget = (props) => (
    <WidgetStyleWrapper
        className="video-widget"
        md={props.colWidth}
        useWidgetStyling={props.useWidgetStyling}
        title={props.title}
    >
        {renderStyledVideo(props.content, props.title)}
    </WidgetStyleWrapper>
)

VideoWidget.propTypes = {
    colWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    content: PropTypes.string.isRequired,
    title: PropTypes.string,
    useWidgetStyling: PropTypes.bool,
}

VideoWidget.defaultProps = {
    title: "",
    useWidgetStyling: true,
}

export default VideoWidget

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/CircleCounter/style"
import * as colors from "Components/colors"
import * as helperFunctions from "utils/helperFunctions"

const CircleCounter = ({
    completedTasks,
    decimal,
    percentage,
    primaryColor,
    secondaryColor,
    completeColor,
    totalTasks,
    counterLabel,
}) => {
    // Make sure we aren't dividing by zero, grading extra credit or gaining negative knowledge
    totalTasks = totalTasks > 0 ? totalTasks : 1
    if (completedTasks < 0) {
        completedTasks = 0
    } else if (completedTasks > totalTasks) {
        completedTasks = totalTasks
    }

    /* Calculate the size of the circles and the offset of the stroke
        Check out this article that does a good job walking through the SVG Progress Circle:
        https://codepen.io/xgad/post/svg-radial-progress-meters
    */
    const radius = 50
    const circumference = 2 * 3.14159 * radius
    const taskProgress = completedTasks / totalTasks
    const offset = circumference * (1 - taskProgress)

    const decimalConstant = Math.pow(10, decimal)
    const roundedToDecimalPlace = Math.round(taskProgress * 100 * decimalConstant) / decimalConstant
    const isCompleted = roundedToDecimalPlace === 100

    return (
        <S.OuterWrapper data-auto-cy="MoleculeCircleCounter">
            {counterLabel && (
                <S.StyledCounterLabel>
                    {counterLabel}
                </S.StyledCounterLabel>
            )}
            <S.Wrapper>
                <S.Counter>
                    <svg width="100%" height="100%" viewBox="0 0 107 107">
                        {/* Full Circle */}
                        <circle cx="53.5" cy="53.5" r={radius}
                            strokeWidth="1"
                            stroke={primaryColor}
                        />
                        {/* Progress Circle */}
                        <circle cx="53.5" cy="53.5" r={radius}
                            strokeWidth="7"
                            strokeDasharray={circumference}
                            strokeDashoffset={offset}
                            strokeLinecap="round"
                            stroke={helperFunctions.counterStrokeColor({
                                isCompleted,
                                completeColor,
                                primaryColor,
                                secondaryColor
                            })}
                        />
                    </svg>
                </S.Counter>
                <S.Fraction>
                    {
                        percentage
                            ? (
                                <S.CompletedTasks
                                    primaryColor={primaryColor}
                                    completeColor={completeColor}
                                    isCompleted={isCompleted}
                                >
                                    {`${roundedToDecimalPlace}%`}
                                </S.CompletedTasks>
                            )
                            : (
                                <React.Fragment>
                                    <S.CompletedTasks primaryColor={primaryColor} secondaryColor={secondaryColor}>
                                        {completedTasks}
                                    </S.CompletedTasks>
                                    <S.TotalTasks primaryColor={primaryColor}>
                                        {totalTasks}
                                    </S.TotalTasks>
                                </React.Fragment>
                            )
                    }
                </S.Fraction>
            </S.Wrapper>
        </S.OuterWrapper>
    )
}

CircleCounter.defaultProps = {
    decimal: 1,
    percentage: false,
    primaryColor: colors.DarkGrey,
}

CircleCounter.propTypes = {
    completedTasks: PropTypes.number.isRequired,
    decimal: PropTypes.number,
    percentage: PropTypes.bool,
    primaryColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string,
    totalTasks: PropTypes.number.isRequired,
}

export default CircleCounter

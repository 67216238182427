import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const PageTitle = styled.div`
    display: flex;
    line-height: 1.75rem;
    ${({ hasImage }) => hasImage && css`
        align-items: center;
    `}
    font-size: 1.25rem;
    font-weight: 500;
`

export const TitleText = styled.span`
    color: ${colors.gray9};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const SubTitleText = styled.span`
    ${rules.SmallTitleMediumWeight}
    color: ${colors.QuorumBlue};
    overflow: hidden;
    padding-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const PageTitleImageContainer = styled.div`
    align-items: center;
    border-right: 1px solid ${colors.gray2};
    display: flex;
    height: 32px;
    justify-content: center;
    margin-right: 18px;
    padding-right: 18px;
`

export const PageTitleImage = styled.img`
    max-height: 32px;
    max-width: 160px;
    padding: 0;
`

export const Prefix = styled.span`
    color: ${colors.violet9};
    white-space: nowrap;
    // We have to ignore whitespace to prevent newlines in the title,
    // So we can't append a space to the prefix; use 0.25em, the width
    // of a "normal" space (https://www.w3schools.com/cssref/pr_text_word-spacing.asp)
    padding-right: 0.25em;
`

export const Tooltip = styled.div`
    display: flex;
    position: absolute;
    z-index: 1000;

    ${rules.CardStyle}
    box-shadow: ${rules.DropShadow};
    ${rules.SmallTextRegularWeight}
    color: ${colors.Black};
    background-color: ${colors.White};
    padding: ${({isTiny}) => isTiny ? "8px 11px" : "11px"};

    max-width: ${({maxWidth}) => maxWidth};
    transform: translateY(-100%);

    // TODO: resolve when quorum site can be tested on IE
    // (currently borrowed from DTV Grid component)
    white-space: normal !important;

    // allow breaking and wrapping text that has no spaces, etc.
    word-break: break-all;
    overflow-wrap: anywhere;

    visibility: hidden;
    opacity: 0%;

    // triangle
    &:before {
        content: '';
        position: absolute;
        top: 100%;
        border-top: 6px solid ${colors.White};
        left: calc(50% - 12px / 2);
        border-right: calc(12px / 2) solid transparent;
        border-left: calc(12px / 2) solid transparent;
    }
`

export const TooltipPositioner = styled.div`
    & > :first-child {
        left: 100%;
        visibility: hidden;
        opacity: 0%;
        transition-duration: ${rules.TransitionShort};
        ${({tooltipPlacement, offset}) => {
            if (tooltipPlacement === "top" || (tooltipPlacement === "right" && offset < 0)) {
                return css`transform: translate(-100%, -100%);`
            } else if (tooltipPlacement === "right") {
                return css`transform: translate(10px, 0);`
            } else {
                // when there's no placement passed in, default to "top"
                return css`transform: translate(-100%, -100%);`
            }
        }}
    }
`

export const Container = styled.div`
    display: flex;
    position: relative;
    // need this to allow SubTitleText/TitleText to be overflow: hidden
    min-width: 0;

    ${({useIETooltip}) => useIETooltip && css`
        &:hover {
            // show a tooltip with a delay when hovered over
            ${Tooltip} {
                transition: opacity 1.5s;
                transition-delay: 0.5s;
                visibility: visible;
                opacity: 100%;
            }
        }
    `}

    &:hover {
        ${TooltipPositioner} {
            & > :first-child {
                visibility: visible;
                opacity: 100%;
                transition-duration: ${rules.TransitionShort};
            }
        }
    }
`

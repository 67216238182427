import React from "react"
import PropTypes from "prop-types"

import Icon from "Components/Atoms/Icon"
import * as S from "Components/Molecules/NavigationProgress/style"
import * as colors from "Components/colors"

const NavigationProgress = ({icon, iconFamily, stepsCompleted, stepsTotal, title}) => {
    const steps = () => {
        const combinedSteps = []
        for (var i = 1; i <= stepsCompleted; i++) {
            combinedSteps.push({
                isCompleted: true,
                number: i,
            })
        }
        for (var j = i; j <= stepsTotal; j++) {
            combinedSteps.push({
                isCompleted: false,
                number: j,
            })
        }
        return combinedSteps
    }

    return (
        <S.NavigationProgress data-auto-cy="MoleculeNavigationProgress">
            {icon && <S.Icon><Icon icon={icon} iconFamily={iconFamily} /></S.Icon>}
            <S.Title>{title}</S.Title>
            <S.Steps>
                {steps().map(step => (
                    <S.Step key={step.number}>
                        <svg
                            width={22}
                            height={22}
                        >
                            <circle
                                cx={11}
                                cy={11}
                                r={11}
                                strokeWidth={0}
                                fill={step.isCompleted ? colors.MidPurple : colors.QuorumBlue}
                            />
                        </svg>
                        {step.isCompleted
                            ? <Icon icon="check" iconFamily="fas" />
                            : <span>{step.number}</span>
                        }
                    </S.Step>
                ))}
            </S.Steps>
        </S.NavigationProgress>
    )
}

NavigationProgress.defaultProps = {
    iconFamily: "fad",
}

NavigationProgress.propTypes = {
    icon: PropTypes.string,
    iconFamily: PropTypes.string,
    stepsCompleted: PropTypes.number.isRequired,
    stepsTotal: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
}

export default NavigationProgress

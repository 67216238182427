//

/**
 * NOTES ABOUT THE REGION ENUM:
 *
 * The Region enum currently has these boolean fields to help distinguish which category a region is in:
 *      is_state_region
 *      is_local_region
 *      is_international_region
 *      is_eu_member_state
 * Only one of these can be True for each Region.
 * The only special snowflake region is Region.eu which is False for all of these fields.
 */
/* eslint-disable */


import { checkHasOnlyRegionsWithin, checkHasPermission, checkPermission, regionsFromDataTypePermission } from "shared/imports/permissionFunctions"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"

// only the main 50 states (excludes virign islands, puerto rice, etc unlike stateRegions above)
export const singleStateRegions                = DjangIO.app.models.Region.items()
    .filter(region => region.is_single_us_state)
    .map(region => region.value)

export const federalRegions                = [DjangIO.app.models.Region.federal.value]

export const stateRegions                = DjangIO.app.models.SupportedRegion.states.child_regions

export const localRegions                = DjangIO.app.models.SupportedRegion.local.child_regions

export const stateAndLocalRegions                = DjangIO.app.models.SupportedRegion.states_and_local.child_regions

export const federalAndSingleStates                = federalRegions.concat(singleStateRegions)

export const federalAndSingleStatesDCPR                = federalAndSingleStates.concat([
    DjangIO.app.models.Region.district_of_columbia.value,
    DjangIO.app.models.Region.puerto_rico.value,
])

export const regionsWithSponsors                = (
    DjangIO.app.models.Region.items()
    .filter(region => region.has_sponsors)
    .map(region => region.value)
)

export const federalAndStates                = federalRegions.concat(stateRegions)

export const federalStatesAndLocal                = federalRegions.concat(stateAndLocalRegions)

export const usRegions                = DjangIO.app.models.SupportedRegion.all_regions.child_regions

export const usAndCanadaRegions                = usRegions.concat([DjangIO.app.models.SupportedRegion.canada.value])

export const usEURegion                = usRegions.concat([DjangIO.app.models.Region.eu.value])

export const usCanadaEURegion                = usRegions.concat([DjangIO.app.models.Region.eu.value, DjangIO.app.models.SupportedRegion.canada.value])

// all EU regions are comprised of (Region.eu + all individual EU member states)
export const euRegion                = [DjangIO.app.models.Region.eu.value]

export const euRegions                = DjangIO.app.models.SupportedRegion.eu_member_states.child_regions
    .concat([DjangIO.app.models.Region.eu.value])

export const internationalRegions                = DjangIO.app.models.SupportedRegion.international.child_regions

export const allRegionsExceptUS                = internationalRegions.concat([DjangIO.app.models.Region.eu.value])

export const allRegionsExceptEU                = internationalRegions.concat(usRegions)

export const globalRegions                = DjangIO.app.models.SupportedRegion.user_global.child_regions

export const internationalNoEURegion                = internationalRegions.filter(value =>
    value !== DjangIO.app.models.Region.eu.value)

export const internationalWithoutBillsRegions                = internationalRegions.filter(value => {
    const region = DjangIO.app.models.SupportedRegion.by_value(value)

    if (region.has_bills) {
        return false;
    }
    const isEuMember = region.is_eu_member_state
    const isEuRegion = value === DjangIO.app.models.Region.eu.value

    return !isEuMember && !isEuRegion
})

export const notInternationalEURegions                = DjangIO.app.models.Region
    .items()
    .filter(region => !region.is_international_region || region === DjangIO.app.models.Region.eu)
    .map(region => region.value)

export const hasFederal          = federalRegions
    .findIndex(region => Userdata.current_regions_values.includes(region)) > -1

export const hasStates          = stateRegions
    .findIndex(region => Userdata.current_regions_values.includes(region)) > -1

export const hasLocal          = localRegions
    .findIndex(region => Userdata.current_regions_values.includes(region)) > -1

export const isUS          = usRegions
    .findIndex(region => Userdata.current_regions_values.includes(region)) > -1

export const isEURegion          = DjangIO.app.models.Region
    .items()
    .filter(region => region.value === DjangIO.app.models.Region.eu.value)
    .findIndex(region => Userdata.current_regions_values.includes(region.value)) > -1

export const isInternational          = DjangIO.app.models.Region
    .items()
    .filter(region => region.is_international_region)
    .filter(region => region.international_region_ready)
    .findIndex(region => Userdata.current_regions_values.includes(region.value)) > -1

export const isInternationalSingleRegion          = isInternational && Userdata.current_regions_values.length === 1

export const isInternationalMultiRegions          = isInternational && Userdata.current_regions_values.length > 1

export const isCanadaRegion          = checkHasOnlyRegionsWithin([DjangIO.app.models.SupportedRegion.canada.value])

export const isAllRegionsExceptUS          = allRegionsExceptUS
    .findIndex(region => Userdata.current_regions_values.includes(region)) > -1

export const allRegionsExceptLocal                = internationalRegions.concat(federalAndStates).concat(euRegion)

export const isSingleCountry          = Userdata.current_regions_values.every((r) => usRegions.includes(r))
|| (Userdata.current_regions_values.length === 1 && Userdata.current_regions_values[0] !== DjangIO.app.models.Region.eu.value)

export const isAllEURegions          = Userdata.current_regions_values.every((r) => euRegions.includes(r))

// For checking if user should have access to Global Region
// User should have access to more than one country or a Global Product
// To have access to Global Region client must attend at least one of following conditions:
// - Client has Global Communications Product
// - Client has Global News Add-on Product
// - Client has EU AND (US Federal, US State, AND/OR US Local)
// - Client has EU AND any International country
// - Client has US Fed, State, AND/OR Local AND any International country 
// - Client has more than one International country
export const hasAccessToMultipleCountries = () => {

    const hasAccessUS = checkPermission(DjangIO.app.models.PermissionType.federal) || 
    // checkPermission for non boolean permissions also check the User's current region, so it doesn't apply
    Userdata.Permissions.qp_states?.length > 0 ||
    Userdata.Permissions.qp_local?.length > 0
    const hasAccessEU = checkPermission(DjangIO.app.models.PermissionType.eu)
    // checkPermission for non boolean permissions also check the User's current region, so it doesn't apply
    const hasAccessInternational = Userdata.Permissions.qp_international?.length > 0 

    const value  = checkHasPermission([DjangIO.app.models.PermissionType.media_relations]) ||
    checkHasPermission([DjangIO.app.models.PermissionType.media_monitoring]) ||
    (hasAccessEU && hasAccessUS) ||
    (hasAccessEU && hasAccessInternational) ||
    (hasAccessUS  && hasAccessInternational) ||
    (hasAccessInternational && Userdata.Permissions.qp_international.length > 1)

    return value
}

// use helperfunctions from /shared/imports/CookieJar.js to identify the standard region combinations
// if not in the standard regions, use the raw Userdata.current_regions_values and map each included region
export const getSupportedRegions = () => {
    const supportedRegions = []
    // check for all possible standard region combinations
    if (Userdata.isGlobalMode()) {
        // 9999
        supportedRegions.push(DjangIO.app.models.SupportedRegion.user_global.value)
    } else if (Userdata.isOnlyAllMode()) {
        // 10000
        supportedRegions.push(DjangIO.app.models.SupportedRegion.all_regions.value)
    } else if (Userdata.isAllStateAndLocalMode()) {
        // 10004
        supportedRegions.push(DjangIO.app.models.SupportedRegion.states_and_local.value)
    } else if (Userdata.isOnlyFederalMode()) {
        // 1
        supportedRegions.push(DjangIO.app.models.SupportedRegion.federal.value)
    } else if (Userdata.isAllStatesMode()) {
        // 10001
        supportedRegions.push(DjangIO.app.models.SupportedRegion.states.value)
    } else if (Userdata.isAllLocalMode()) {
        // 10002
        supportedRegions.push(DjangIO.app.models.SupportedRegion.local.value)
    } else if (Userdata.isInternationalMode()) {
        // 10003
        supportedRegions.push(DjangIO.app.models.SupportedRegion.international.value)
    } else if (Userdata.isOnlyEUMode()) {
        // 106
        supportedRegions.push(DjangIO.app.models.SupportedRegion.eu.value)
    } else {
        // Userdata.current_regions and Userdata.current_regions_keys are untrustworthy
        // when the user enters into a group of custom regions
        // for this reason, we need to rely on the raw Userdata.current_regions_values
        // Region enum values and map them to their respective SupportedRegion (all us, eu, international, etc)
        // check for all possible individual combinations
        // https://www.quorum.us/switch_region/
        // https://www.quorum.us/edit_custom_region/
        if (hasFederal) {
            // 1
            supportedRegions.push(DjangIO.app.models.SupportedRegion.federal.value)
        }
        if (hasStates) {
            // 10001
            supportedRegions.push(DjangIO.app.models.SupportedRegion.states.value)
        }
        if (hasLocal) {
            // 10002
            supportedRegions.push(DjangIO.app.models.SupportedRegion.local.value)
        }
        if (isInternational) {
            // 10003
            supportedRegions.push(DjangIO.app.models.SupportedRegion.international.value)
        }
        if (isEURegion) {
            // 106
            supportedRegions.push(DjangIO.app.models.SupportedRegion.eu.value)
        }
        // we will eventually support the "Global" region (which includes us, eu, and international),
        // but for now, we will usually use supportedRegions[0]
    }

    return supportedRegions
}

/* eslint-enable */

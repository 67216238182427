import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/BigBoiSelects/style"

import Icon from "Components/Atoms/Icon"

const BigBoiSelects = ({ children, icon, iconFamily, isSelected, onClick}) => (
    <S.BigBoiSelects
        data-auto-cy="MoleculeBigBoiSelects"
        onClick={onClick}
        isSelected={isSelected}
    >
        { icon &&
            <S.Icon isSelected={isSelected}>
                <Icon icon={icon} iconFamily={iconFamily} size="2x"/>
            </S.Icon>
        }
        { children &&
            <S.Text icon={!!icon} isSelected={isSelected}>{children}</S.Text>
        }
    </S.BigBoiSelects>
)

BigBoiSelects.defaultProps = {}

BigBoiSelects.propTypes = {
    children: PropTypes.string,
    icon: PropTypes.string,
    iconFamily: PropTypes.string,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
}

export default BigBoiSelects

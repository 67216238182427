import isFeatureEnabled from "shared/featureflags/helperFunctions"

const { CustomEventFieldType } = DjangIO.app.userdata.types

export const oobCustomEventJson = Userdata.custom_event_json?.[0]?.["form"]

export const userHasAccessToOOBCustomEventForm = () => {
    return isFeatureEnabled("ff_oob_event_management") && oobCustomEventJson && oobCustomEventJson.length > 0
}

/**
 * Given a field name (string), if the field name exist as on the custom_event_json on the org,
 * Return the field object in the json
 * @name customEventJsonHasField
 * @function
 * @param {string} fieldName - name of the field to check
 * @returns {Object} field object of the field name in JSON or empty object if the field is not found
 */
export const customEventJsonHasField = (fieldName) => {
    let field = {}
    oobCustomEventJson.forEach((group) => {
        group["fields"].forEach((fieldObj) => {
            if (fieldObj.field_type === CustomEventFieldType[fieldName].value) {
                field = fieldObj
            }
        })
    })
    return field
}

export const fieldIsRequiredValidator = (value) => {
    try {
        // false is a valid choice so shouldn't return error
        // size is for immutable list and length is for regular list when an onChange happened on an input
        if (value === null || value === undefined || value.size < 1 || value.length < 1) {
            return "Please fill out all the required fields."
        }
    } catch (error) {
        console.error(error, "Custom Event Form", { value })
        return "An unknown error occurred while validating required fields"
    }

    return undefined
}

/**
 * Return the current active sequence number from the active transcript. For example, #s-38 would return 38.
 * location_sequence represent initial timeline
 * sequenceNumber represent timeline for rewound video
 * @function
 * @returns {number} the number from the active transcript hash
 */
export const extractSequenceNumberFromURL = (location) => {
    const hash = location.hash
    const sequenceNumber = hash.substring(3)
    const location_sequence = location.query.sequence

    if (sequenceNumber) {
        return Number(sequenceNumber)
    } else if (location_sequence) {
        return parseInt(location_sequence)
    } else {
        return null
    }
}

/*
 * Quorum Typeahead Search
 * - searchbox for typeahead
 *  - TODO: fix debounce
 *
 */
import React from "react"
import PropTypes from "prop-types"

const propTypes = {
    placeholder: PropTypes.string.isRequired,
    inputRefFunc: PropTypes.func.isRequired,
    onUserInput: PropTypes.func.isRequired,
    debounceRate: PropTypes.number.isRequired,
}
const defaultProps = {
    placeholder: "Search...",
}

export default class QuorumTypeAheadSearch extends React.Component {
    static debounce(func, wait) {
        // we need to save these in the closure
        let timeout
        let context
        let timestamp
        return function debounceMain(...args) {
            // save details of latest call
            context = this
            timestamp = new Date()
            // this is where the magic happens

            function later() {
                // how long ago was the last call
                const last = new Date() - timestamp
                // if the latest call was less that the wait period ago
                // then we reset the timeout to wait for the difference
                if (last < wait) {
                    timeout = setTimeout(later, wait - last)
                    // or if not we can null out the timer and run the latest
                } else {
                    timeout = null
                    func.apply(context, args)
                }
            }

            // we only need to set the timer now if one isn't already running
            if (!timeout) {
                timeout = setTimeout(later, wait)
            }
        }
    }

    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
    }


    handleChange() {
        return this.props.onUserInput()
    }

    render() {
        return (
            <input
                className="quorum-tag-obj-search"
                type="text"
                placeholder={this.props.placeholder}
                ref={this.props.inputRefFunc}
                onChange={QuorumTypeAheadSearch.debounce(
                    this.handleChange,
                    this.props.debounceRate
                )}
                data-cy="quorum-type-ahead-search-input"
                // don't save searches for when typing in outbox
                autoComplete="new-password"
            />
        )
    }
}

QuorumTypeAheadSearch.propTypes = propTypes
QuorumTypeAheadSearch.defaultProps = defaultProps

import { connect } from "react-redux"
import { reduxForm } from "redux-form/immutable"

import { CampaignMessagePreview } from "QuorumGrassroots/campaign-forms/components/CampaignMessagePreview/index"
import { campaignMessagePreviewConnection } from "QuorumGrassroots/campaign-forms/selectors/campaign-message-preview-selectors"

import { changeMessageGroup } from "QuorumGrassroots/campaign-forms/action-creators"

import { constants } from "QuorumGrassroots/constants"

const ReduxCampaignMessagePreview = reduxForm({
    form: constants.campaignMessagePreviewReduxFormKey,
    enableReinitialize: true,
})(CampaignMessagePreview)

export default connect(campaignMessagePreviewConnection, { changeMessageGroup })(ReduxCampaignMessagePreview)

import React, { useState } from "react"
import PropTypes from "prop-types"

import * as colors from "Components/colors"
import * as S from "Components/Layouts/PopdownOverlay/style"

import Icon from "Components/Atoms/Icon"
import Button from "Components/Molecules/Button"

import AiSearchLoadingGif from 'Components/Atoms/AiSearchLoadingGif'

const POPDOWN_DEFAULT_ITEMS = 12
const AI_SEARCH_ERROR_IMG_URL = "https://static.quorum.us/global/common/images/alert-illustration.svg"
const AI_SEARCH_NOT_FOUND_IMG_URL = "https://static.quorum.us/global/common/images/search-illustration.svg"

const PopdownOverlay = ({
    aiSearch,
    aiSearchCancelOnClick,
    aiSearchExamples,
    aiSearchExampleOnClick,
    aiSearchPopdownVisible,
    aiSearchStatus,
    aiSearchToggle,
    children,
    currentSupportedRegions,
    dataCy,
    defaultSearchDataType,
    gridItemOnClick,
    options,
    popdownAdvanced,
    popdownAdvancedImg,
    popdownAdvancedOnClick,
    segueLinkProps,
    popdownSeeMore,
    popDownSeeMoreOnClick,
    searchSettingsUrl,
    SegueLinkComponent,
}) => {
    const [aiSearchInfoOpen, setAiSearchInfoOpen] = useState(false)

    const renderPopdownButtons = () => (
        <Button
            leftIcon="boolean"
            leftIconFamily="q"
            onClick={popdownAdvancedOnClick}
            text={
                popdownAdvanced
                    ? "Hide Advanced Search Tips"
                    : "See Advanced Search Tips"
            }
            type={
                popdownAdvanced
                    ? "primary"
                    : "outlined"
            }
            isTiny
        />
    )

    const renderCardLabel = (option) => {
        const label = (
            currentSupportedRegions
                .map( supportedRegion => (
                    option.search_description
                        ? option.search_description[supportedRegion]
                        : ""
                ))
                // removes all "", undefined, null, false, etc
                .filter(Boolean)
                .join(" ")
        )
        return <S.PopDownGridItemSecondLineLabel>{label}</S.PopDownGridItemSecondLineLabel>
    }

    const renderAiSearchExamples = () => (
        <div>
            {
                aiSearchExamples.examples.map((example, index) => (
                    <S.AiSearchBodyExampleItem
                        key={`ai-search-example-${index}`}
                    >
                        <S.AiSearchExampleDescription>
                            <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.8" d="M8.00141 3.9927C8.00135 3.93077 7.9841 3.87007 7.95158 3.81736C7.91907 3.76465 7.87256 3.72199 7.81724 3.69415L5.47194 2.51931L4.29939 0.16985C4.18606 -0.0566167 3.81625 -0.0566167 3.70312 0.16985L2.53058 2.51931L0.18528 3.69415C0.129644 3.72173 0.0828187 3.76431 0.0500823 3.81708C0.0173458 3.86985 0 3.93071 0 3.99281C0 4.05491 0.0173458 4.11577 0.0500823 4.16854C0.0828187 4.22131 0.129644 4.26388 0.18528 4.29147L2.53058 5.4663L3.70312 7.81577C3.73081 7.87113 3.77337 7.91769 3.82602 7.95023C3.87868 7.98277 3.93936 8 4.00126 8C4.06316 8 4.12384 7.98277 4.17649 7.95023C4.22915 7.91769 4.27171 7.87113 4.29939 7.81577L5.47194 5.4663L7.81724 4.29188C7.87264 4.26397 7.9192 4.22122 7.95172 4.16839C7.98424 4.11556 8.00144 4.05474 8.00141 3.9927Z" fill={colors.violet3}/>
                            </svg>
                            {example.initialFilter.ai_search}
                        </S.AiSearchExampleDescription>
                        <Button
                            id={`ai-search-example-${index}`}
                            leftIcon="sparkles"
                            leftIconFamily="fas"
                            leftIconSize="12px"
                            onClick={() => {
                                aiSearchExampleOnClick(example.initialFilter, example.qdt)
                            }}
                            text="Search"
                            type="ai-search-purple-inverse"
                        />
                    </S.AiSearchBodyExampleItem>
                ))
            }
        </div>
    )

    const renderAiSearchMain = () => (
        <S.AiSearchMain>
            <S.AiSearchHeader>
                <S.AiSearchHeaderFirstRow>
                    <S.AiSearchTitle>
                        Searching with AI-powered engine
                    </S.AiSearchTitle>
                    <SegueLinkComponent
                        to={searchSettingsUrl}
                        optionalPreSegueFunction={segueLinkProps.optionalPreSegueFunction}
                    >
                        <Button
                            leftIcon="cog"
                            leftIconFamily="far"
                            leftIconSize="14px"
                            type="ghost-purple-small"
                        />
                    </SegueLinkComponent>
                </S.AiSearchHeaderFirstRow>
                <Button
                    onClick={() => setAiSearchInfoOpen(!aiSearchInfoOpen)}
                    rightIcon={aiSearchInfoOpen ? "angle-up" : "angle-down"}
                    rightIconFamily="far"
                    rightIconSize="12px"
                    text="Learn more"
                    type="no-effect"
                />
                {
                    aiSearchInfoOpen &&
                    <div>
                        {"AI search is now available for select datasets! You can create a search as quickly as typing a few words - no need to select multiple filters or insert boolean operators."
                        + "\n\n" +
                        "Get started by typing in a search or selecting an example below."}
                    </div>
                }
            </S.AiSearchHeader>
            <S.AiSearchBody>
                {renderAiSearchExamples()}
                <S.AiSearchBodyInfo>
                    <Icon
                        color={colors.violet5}
                        icon="info-circle"
                        iconFamily="fas"
                        style={{
                            marginRight: "12px",
                        }}
                        fixedWidth={false}
                    />
                    Available for <b>{aiSearchExamples.datasets.join(", ")}</b>
                </S.AiSearchBodyInfo>
                <S.AiSearchBodyToggle>
                    <Button
                        id="popdown-toggle-regular-search"
                        leftIcon="search"
                        leftIconFamily="far"
                        onClick={aiSearchToggle}
                        text="Go to Regular Search"
                        type="outlined"
                    />
                </S.AiSearchBodyToggle>
            </S.AiSearchBody>
        </S.AiSearchMain>
    )

    const renderAiSearchLoading = () => (
        <S.AiSearchLoadingContainer>
            <AiSearchLoadingGif />
            <S.AiSearchLoadingText>
                Generating results...
            </S.AiSearchLoadingText>
        </S.AiSearchLoadingContainer>
    )

    const getAiSearchAlertObj = (status) => {
        const obj = {
            imageUrl: null,
            text: null,
            buttons: null,
        }

        if (status === "error") {
            obj.imageUrl = AI_SEARCH_ERROR_IMG_URL
            obj.text = (
                <div>
                    {
                        "Your search has exceeded the 150 character limit for our AI search system."
                        + "\n\n" +
                        "Please rephrase your search or try regular search."
                    }
                </div>
            )
            obj.buttons = (
                <S.AiSearchAlertButtons>
                    <Button
                        id="popdown-toggle-regular-search"
                        leftIcon="search"
                        leftIconFamily="far"
                        onClick={aiSearchToggle}
                        text="Go to regular search"
                        type="outlined"
                    />
                    <Button
                        onClick={aiSearchCancelOnClick}
                        text="Cancel"
                        type="ghost"
                    />
                </S.AiSearchAlertButtons>
            )
        }

        if (status === "not found") {
            obj.imageUrl = AI_SEARCH_NOT_FOUND_IMG_URL
            obj.text = (
                <div>
                    {
                        "Our AI Search could not understand what you were trying to find. This could be because:\n\n"
                    }
                    <li>You were trying to search a dataset or filter that is not supported in our AI search yet</li>
                    <li>The search text was not specific enough</li>
                    <li>Something else</li>
                </div>
            )
            obj.buttons = (
                <S.AiSearchAlertButtons>
                    <Button
                        id="popdown-toggle-regular-search"
                        leftIcon="search"
                        leftIconFamily="far"
                        onClick={aiSearchToggle}
                        text="Go to regular search"
                        type="outlined"
                    />
                    <div>
                        <Button
                            id="ai-search-thumbs-up"
                            leftIcon="thumbs-up"
                            leftIconFamily="far"
                            type="ghost"
                        />
                        <Button
                            id="ai-search-thumbs-down"
                            leftIcon="thumbs-down"
                            leftIconFamily="far"
                            type="ghost"
                        />
                    </div>
                </S.AiSearchAlertButtons>
            )
        }

        return obj
    }

    const renderAiSearchAlert = () => {
        const alertObj = getAiSearchAlertObj(aiSearchStatus)

        return (
            <div>
                <S.AiSearchAlertBody>
                    <S.AiSearchAlertImage
                        src={alertObj.imageUrl}
                    />
                    <S.AiSearchAlertText>
                        <S.AiSearchTitle>
                            Uh-Oh!
                        </S.AiSearchTitle>
                        {alertObj.text}
                        {alertObj.buttons}
                    </S.AiSearchAlertText>
                </S.AiSearchAlertBody>
            </div>
        )
    }

    const renderRegularSearchPopdown = () => {
        return (
            <S.Popdown
                data-auto-cy="LayoutPopdown"
                data-cy={dataCy}
            >
                {
                    popdownAdvanced &&
                        (
                            <React.Fragment>
                                <S.PopdownTopBarContainer>
                                    <S.PopdownTitle>
                                        Advanced Search Tips & Boolean
                                    </S.PopdownTitle>
                                    {renderPopdownButtons()}
                                </S.PopdownTopBarContainer>
                                <S.AdvancedSearch>
                                    <S.AdvancedSearchObject data={popdownAdvancedImg} type="image/svg+xml">
                                        <S.AdvancedSearchImage src={popdownAdvancedImg} alt="search" />
                                    </S.AdvancedSearchObject>
                                    <S.AdvancedSearchCenterButtonWrapper>
                                        {
                                            SegueLinkComponent &&
                                            <SegueLinkComponent
                                                to={segueLinkProps.to}
                                                optionalPreSegueFunction={segueLinkProps.optionalPreSegueFunction}
                                            >
                                                <Button
                                                    isGiant
                                                    text="Learn More"
                                                    type="tertiary"
                                                />
                                            </SegueLinkComponent>
                                        }
                                    </S.AdvancedSearchCenterButtonWrapper>
                                </S.AdvancedSearch>
                            </React.Fragment>
                        )
                }
                <S.PopdownTopBarContainer
                    popdownAdvanced={popdownAdvanced}
                >
                    <S.PopdownTitle>
                        Datasets
                    </S.PopdownTitle>
                    {
                        !popdownAdvanced &&
                            renderPopdownButtons()
                    }
                </S.PopdownTopBarContainer>
                <S.PopdownGridContainer>
                    <S.PopdownGrid>
                        {
                            options.slice(
                                0,
                                popdownSeeMore
                                    ? options.length
                                    : POPDOWN_DEFAULT_ITEMS
                            ).map(option => (
                                <S.PopdownGridItem
                                    data-cy={`LayoutPopdownGridItem-${option.label}`}
                                    key={option.value}
                                    onClick={(event) => {
                                        gridItemOnClick(event, option.value)
                                    }}
                                >
                                    <S.PopdownGridItemFirstLine>
                                        <S.PopdownGridItemFirstLineIconLabel>
                                            <Icon
                                                color={colors.violet7}
                                                icon={option.fa5_icon || "question"}
                                                iconFamily={option.fa5_icon_family || "far"}
                                                style={{fontSize: "1rem" }}
                                                fixedWidth={false}
                                                size="1x"
                                            />
                                            <S.PopDownGridItemFirstLineLabel>
                                               {option.qdtLabel || option.plural}
                                            </S.PopDownGridItemFirstLineLabel>
                                        </S.PopdownGridItemFirstLineIconLabel>
                                        {
                                            (
                                                option.value ===
                                                defaultSearchDataType
                                            ) &&
                                                <S.PopDownGridItemFirstLineDefault>
                                                    <Icon
                                                        color={colors.gray7}
                                                        icon="star"
                                                        iconFamily="far"
                                                    />
                                                    <S.PopDownGridItemFirstLineDefaultLabel>
                                                        Default
                                                    </S.PopDownGridItemFirstLineDefaultLabel>
                                                </S.PopDownGridItemFirstLineDefault>
                                        }
                                    </S.PopdownGridItemFirstLine>
                                    <S.PopdownGridItemSecondLine>
                                        {renderCardLabel(option)}
                                    </S.PopdownGridItemSecondLine>
                                    <S.PopDownGridItemSearch>
                                        <S.PopDownGridItemSearchLabel>
                                            Search
                                        </S.PopDownGridItemSearchLabel>
                                        <Icon
                                            color={colors.QuorumBlue}
                                            icon="long-arrow-right"
                                            iconFamily="fal"
                                            size="2x"
                                        />
                                    </S.PopDownGridItemSearch>
                                </S.PopdownGridItem>
                            ))
                        }
                    </S.PopdownGrid>
                    <S.PopdownSeeMore
                        onClick={popDownSeeMoreOnClick}
                    >
                    {
                        (options.length > POPDOWN_DEFAULT_ITEMS) &&
                            <React.Fragment>
                                <S.PopdownSeeMoreLabel>
                                    {
                                        popdownSeeMore
                                            ? "See Less"
                                            : "See More"
                                    }
                                </S.PopdownSeeMoreLabel>
                                <Icon
                                    color={colors.violet7}
                                    icon={
                                        popdownSeeMore
                                            ? "chevron-up"
                                            : "chevron-down"
                                    }
                                    iconFamily="far"
                                />
                            </React.Fragment>
                    }
                    </S.PopdownSeeMore>
                </S.PopdownGridContainer>
                { children }
                {
                    aiSearchPopdownVisible &&
                    <S.AiSearchBodyToggle>
                        <Button
                            id="popdown-toggle-ai-search"
                            leftIcon="sparkles"
                            leftIconFamily="fas"
                            leftIconSize="14px"
                            onClick={aiSearchToggle}
                            text="Use AI Search"
                            type="ai-search-purple"
                        />
                    </S.AiSearchBodyToggle>
                }
            </S.Popdown>
        )
    }

    const renderAiSearchPopdown = () => {
        let component = renderAiSearchMain()

        switch (aiSearchStatus) {
            case "main":
                component = renderAiSearchMain()
                break
            case "loading":
                component = renderAiSearchLoading()
                break
            case "not found":
            case "error":
                component = renderAiSearchAlert(aiSearchStatus)
                break
            default:
                component = renderAiSearchMain()
                break
        }

        return (
            <S.AiSearchPopdown>
                {component}
            </S.AiSearchPopdown>
        )
    }

    return aiSearch && aiSearchPopdownVisible ? renderAiSearchPopdown() : renderRegularSearchPopdown()
}

PopdownOverlay.propTypes = {
    aiSearch: PropTypes.bool,
    aiSearchExamples: PropTypes.object,
    aiSearchPopdownVisible: PropTypes.bool,
    aiSearchStatus: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.element),
    currentSupportedRegions: PropTypes.array,
    dataCy: PropTypes.string,
    defaultSearchDataType: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.shape({
        quorumDataType: PropTypes.objectOf(
            PropTypes.shape({
                label: PropTypes.string
            })
        ),
    })),
    popdownAdvanced: PropTypes.bool,
    popdownAdvancedImg: PropTypes.string,
    popdownSeeMore: PropTypes.bool,
    searchSettingsUrl: PropTypes.string,
    segueLinkProps: PropTypes.object,
    SegueLinkComponent: PropTypes.elementType,

    // action-creators
    aiSearchCancelOnClick: PropTypes.func,
    aiSearchExampleOnClick: PropTypes.func,
    aiSearchToggle: PropTypes.func,
    gridItemOnClick: PropTypes.func,
    popdownAdvancedOnClick: PropTypes.func,
    popDownSeeMoreOnClick: PropTypes.func,
}

export default PopdownOverlay

import React from "react"
import PropTypes from "prop-types"

import Waypoint from "react-waypoint"
import StyledColumnWidget, { StyledWidgetHeader } from "QuorumGrassroots/widgets/styled-components/StyledWidget"
import { StyledCampaignNoneText } from "QuorumGrassroots/widgets/CampaignList/components/style"
import CampaignInline from "QuorumGrassroots/widgets/CampaignList/components/CampaignInline/index"

const CampaignList = (props) => {
    if (props.loading) {
        return (
            <div>
                <CampaignInline className="campaign-inline" key="skeleton1" loading />
            </div>
        )
    }

    return (
        <div className="campaign-list">
            {props.title && (
                <StyledColumnWidget className="campaign-header-widget">
                    <StyledWidgetHeader removeBottomBorder>{props.title}</StyledWidgetHeader>
                </StyledColumnWidget>
            )}
            {props.campaignList.length ? (
                props.campaignList.map((campaign, index, arr) => (
                    <CampaignInline
                        key={campaign.id}
                        campaign={campaign}
                        isFirst={index === 0}
                        isLast={index === arr.length - 1}
                    />
                ))
            ) : (
                <StyledColumnWidget>
                    <StyledCampaignNoneText>
                        <span dangerouslySetInnerHTML={{ __html: props.campaignNoneText }} />
                    </StyledCampaignNoneText>
                </StyledColumnWidget>
            )}

            {
                // If Waypoint is rendered, loadAdditionalCampaigns will be called
                props.shouldLoadAdditionalCampaigns && <Waypoint onEnter={props.loadAdditionalCampaigns} />
            }
            {props.campaignListLoading && <CampaignInline className="campaign-inline" key="skeleton1" loading />}
        </div>
    )
}

CampaignList.defaultProps = {
    campaignList: [],
    loading: false,
}

CampaignList.propTypes = {
    campaignList: PropTypes.array,
    campaignListLoading: PropTypes.bool.isRequired,
    campaignNoneText: PropTypes.string.isRequired,
    loadAdditionalCampaigns: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    shouldLoadAdditionalCampaigns: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}

export default CampaignList

import i18n from "i18n"

import { withNamespaces } from "react-i18next"
import { reduxForm } from "redux-form/immutable"

import { resetPassword } from "QuorumGrassroots/framework/action-creators"

import ResetPasswordPage from "QuorumGrassroots/framework/components/Foundations/ResetPasswordPage"
import { withRouter } from "QuorumGrassroots/withRouter"

export const validate = (immutableValues) => {
    const errors = {}
    const values = immutableValues.toJS()

    if (values.password && values.passwordConfirm && values.password !== values.passwordConfirm) {
        errors.passwordConfirm = i18n.t("registration.password.reset.do_not_match")
    }

    return errors
}

const submit = (values, dispatch, props) => {
    return dispatch(resetPassword(values, props))
}

const form = reduxForm({
    form: "reset_password",
    onSubmit: submit,
    validate,
})(withRouter(ResetPasswordPage))

export default withNamespaces()(form)

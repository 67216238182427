// https://projects.invisionapp.com/d/main/#/console/18861190/444895171/inspect
// https://projects.invisionapp.com/share/H4102MKAXEYZ#/screens/444515231

import React from "react"
import PropTypes from "prop-types"

import * as colors from "Components/colors"

import * as S from "Components/Molecules/SearchTextarea/style"

import Icon from "Components/Atoms/Icon"

import Button from "Components/Molecules/Button"
import Tooltip from "Components/Molecules/Tooltip"

import { SearchActionsWrapper } from "Components/Molecules/SearchTextarea/SearchActionsWrapper"

const SearchTextarea = ({
    theme,
    loggedInAsUser,

    dataCy,

    // SearchTextareaContainer
    hasBorder,
    isPrimary,

    // SearchTextarea
    aiSearch,
    aiSearchButtonIsVisible,
    aiSearchToggle,
    isSearchify,
    searchDisabled,
    searchInputDefaultValue,
    searchInputOnChange,
    searchInputOnClick,
    searchInputOnInput,
    searchInputOnKeyDown,
    searchInputOnKeyUp,
    searchInputPlaceholder,
    searchTextareaRef,

    searchInputValue,

    // S.SearchTextareaClear
    clearButtonOnClick,

    // Button favorite
    favoriteButtonDisabled,
    favoriteButtonHidden,
    favoriteButtonOnClick,
    favoriteButtonTooltip,
    filtersSelected,

    // Button search
    searchButtonOnClick,

    // app/static/frontend/search-new/components/FilterSet/index.js
    isSearchFiltersTextarea,

    // app/static/frontend/search-new/containers/FilterableListView/ActionButtons.js
    isSearchProfileTextarea,
}) => {
    const renderButton = (hasV2Styles) => (
        hasV2Styles ? !aiSearchButtonIsVisible && (
            <S.SearchButton isPrimary={isPrimary} type="button" onClick={searchButtonOnClick}>
                <Icon
                    icon="search"
                    iconFamily="far"
                    style={{ fontSize: "1rem"}}
                    color={isPrimary ? colors.violet7 : loggedInAsUser ? colors.violet9 : colors.White}
                />
            </S.SearchButton>
        ) : (
            <Button
                disabled={searchDisabled}
                hoverable={!isSearchFiltersTextarea}
                isTiny
                {
                    ...(
                        isPrimary
                            ? {
                                leftIcon: "search",
                                leftIconFamily: "fas",
                                leftIconSize: "lg",
                            }
                            : {
                                rightIcon: "search",
                                rightIconFamily: "fas",
                                rightIconSize: "lg",
                            }
                    )
                }
                rightIconStyle={
                    isSearchFiltersTextarea && {
                        color: colors.QuorumGrey4,
                    }
                }
                onClick={searchButtonOnClick}
                text={
                    isPrimary
                        ? "Search"
                        : undefined
                }
                type={
                    isPrimary
                        ? "primary"
                        : "borderless"
                }
                style={
                    isPrimary
                        ? undefined
                        : {padding: "5px"}
                }
            />
        )
    )

    const getFavoriteButtonType = () => {
        if (theme === "primary") {
            if (loggedInAsUser) return "top-nav-as-user"

            if (aiSearchButtonIsVisible) {
                return isPrimary ? "search-button-active" : "search-button"
            }

            return "top-nav"
        }

        return favoriteButtonDisabled ? "primary" : "tertiary"
    }

    return (
        <S.SearchTextareaContainer
            data-auto-cy="MoleculeSearchTextareaContainer"
            isSearchFiltersTextarea={isSearchFiltersTextarea}
            isSearchProfileTextarea={isSearchProfileTextarea}
            hasBorder={hasBorder}
            isPrimary={isPrimary}
            theme = {theme}
            searchInputValue={searchInputValue}
            isSearchify={isSearchify}
            loggedInAsUser={loggedInAsUser}
            aiSearchButtonIsVisible={aiSearchButtonIsVisible}
        >
            {
                aiSearchButtonIsVisible &&
                <Button
                    id="searchbar-toggle-ai-search"
                    leftIcon="sparkles"
                    leftIconFamily={aiSearch ? "fas" : "fal"}
                    onClick={aiSearchToggle}
                    type={isPrimary ? "ai-search-active" : "ai-search"}
                />
            }
            {isSearchFiltersTextarea && renderButton()}
            <S.SearchTextarea
                aiSearchButtonIsVisible={aiSearchButtonIsVisible}
                data-cy={dataCy || "MoleculeSearchTextarea"}
                defaultValue={searchInputDefaultValue}
                isSearchFiltersTextarea={isSearchFiltersTextarea}
                isSearchify={isSearchify}
                onChange={searchInputOnChange}
                onClick={searchInputOnClick}
                onInput={searchInputOnInput}
                onKeyDown={searchInputOnKeyDown}
                onKeyUp={searchInputOnKeyUp}
                placeholder={searchInputPlaceholder}
                ref={searchTextareaRef}
                rows="1"
                theme={theme}
                isPrimary={isPrimary}
                loggedInAsUser={loggedInAsUser}
            />
            <SearchActionsWrapper theme={theme} isSearchify={isSearchify}>
                {
                    // if the user has entered text into the SearchTextarea input,
                    // display the "Clear" text
                    searchInputValue &&
                        <React.Fragment>
                            {
                                !isSearchFiltersTextarea &&
                                    <S.SearchTextareaClear
                                        data-cy="clear-search-text"
                                        onClick={clearButtonOnClick}
                                        theme={theme}
                                        isSearchify={isSearchify}
                                        role="button"
                                        tabIndex="0"
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter" || event.key === " ") {
                                                event.preventDefault()
                                                clearButtonOnClick()
                                            }
                                        }}
                                        loggedInAsUser={loggedInAsUser}
                                        isPrimary={isPrimary}
                                        aiSearchButtonIsVisible={aiSearchButtonIsVisible}
                                    >
                                        {aiSearchButtonIsVisible ? (
                                            <Icon
                                                icon="times"
                                                iconFamily="far"
                                                style={{ fontSize: "1rem" }}
                                                color={isPrimary ? colors.gray7 : colors.violet1}
                                            />
                                        ) : "Clear"}
                                    </S.SearchTextareaClear>
                            }
                            {
                                isSearchFiltersTextarea &&
                                    <Button
                                        isTiny
                                        leftIcon="times"
                                        leftIconFamily="fas"
                                        leftIconSize="lg"
                                        onClick={clearButtonOnClick}
                                        type={"borderless"}
                                        style={{
                                            padding: "5px 0"
                                        }}
                                    />
                            }
                        </React.Fragment>
                }
                {
                    // if the user has entered text into the SearchTextarea input,
                    // or updated the filters,
                    // display the "Favorite" button
                    (searchInputValue || filtersSelected) &&
                    !isSearchFiltersTextarea &&
                        <S.TooltipContainer
                            isHidden={favoriteButtonHidden}
                        >
                            <S.TooltipPositioner>
                                <Tooltip
                                    isTiny
                                    key={favoriteButtonTooltip}
                                    placement="bottom"
                                    text={favoriteButtonTooltip}
                                />
                            </S.TooltipPositioner>
                            <Button
                                data-cy="topnav-favorite-button"
                                isTiny
                                key={`favorite-button-${favoriteButtonDisabled}`}
                                leftIcon="star"
                                leftIconFamily="far"
                                onClick={favoriteButtonOnClick}
                                text={aiSearchButtonIsVisible ? "" : "Favorite"}
                                type={getFavoriteButtonType()}
                                style={theme==="primary" ? { padding: "12px 8px" } : null}
                            />
                        </S.TooltipContainer>
                }
                {aiSearchButtonIsVisible && (
                    <S.BetaBadge isPrimary={isPrimary}>Beta</S.BetaBadge>
                )}
            </SearchActionsWrapper>
            {!isSearchFiltersTextarea && (
                renderButton( theme==="primary" && !isSearchify)
            )}
        </S.SearchTextareaContainer>
    )
}

SearchTextarea.propTypes = {
    theme: PropTypes.string,
    loggedInAsUser: PropTypes.bool,

    dataCy: PropTypes.string,
    // whether or not we are rendering the
    // app/static/frontend/search-new/containers/Filter/SearchFiltersTextareaFilter.js
    // https://projects.invisionapp.com/d/main/#/console/21044500/444626890/preview
    isSearchFiltersTextarea: PropTypes.bool,
    isSearchProfileTextarea: PropTypes.bool,

    // SearchTextareaContainer
    isPrimary: PropTypes.bool,

    // SearchTextarea
    aiSearch: PropTypes.bool,
    aiSearchButtonIsVisible: PropTypes.bool,
    aiSearchToggle: PropTypes.func,
    isSearchify: PropTypes.bool,
    searchDisabled: PropTypes.bool,
    searchTextareaRef: PropTypes.func,
    searchInputDefaultValue: PropTypes.string,
    searchInputOnChange: PropTypes.func,
    searchInputOnClick: PropTypes.func,
    searchInputOnInput: PropTypes.func,
    searchInputOnKeyDown: PropTypes.func,
    searchInputOnKeyUp: PropTypes.func,
    searchInputPlaceholder: PropTypes.string,
    searchInputValue: PropTypes.string,

    // SearchTextareaClear
    clearButtonOnClick: PropTypes.func,

    // Button favorite
    favoriteButtonOnClick: PropTypes.func,
    filtersSelected: PropTypes.bool,

    // Button search
    searchButtonOnClick: PropTypes.func,
}

export default SearchTextarea

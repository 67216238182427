import React from 'react'
import PropTypes from 'prop-types'

import { useOffsetWidth } from 'utils/hooks'
import * as S from 'Components/Molecules/ModalBody/style'
import { templateWidth } from 'Components/Compounds/TemplateOptions/style'

const ModalBody = ({text, children, modalBodyRef, modalRef, clampWidth, rightSideComponent}) => {
    const [ref, width] = useOffsetWidth()

    const rightSideWidth = (width/(Math.floor(width/(templateWidth + 26))))
    return <S.Body
        data-auto-cy="MoleculeModalBody"
        ref={modalRef}
        clampWidth={clampWidth}
    >
        <S.TopBar ref={ref}>
            <S.Text hasRightSideComponent={!!rightSideComponent}>
                { text }
            </S.Text>
            {
                rightSideComponent &&
                <S.RightSideComponent width={rightSideWidth}>
                    { rightSideComponent }
                </S.RightSideComponent>
            }
        </S.TopBar>
        <S.Children ref={modalBodyRef}>
            { children }
        </S.Children>
    </S.Body>
}

ModalBody.defaultProps = {
    clampWidth: true,
}

ModalBody.propTypes = {
    text: PropTypes.string,
    children: PropTypes.element,
    rightSideComponent: PropTypes.element,
    clampWidth: PropTypes.bool,
}

export default ModalBody

import { makePieActionObjs } from "shared/imports/pieActionGenerators"
import { prependAppName } from "shared/imports/sharedHelperFunctions"

const appName = "@@pac/"

const getLedgerSettingsObjs = makePieActionObjs("get", "LedgerSettings")
const createTransactionEventObjs = makePieActionObjs("post", "TransactionEvent")

const getTransactionObjs = makePieActionObjs("load", "Transaction")
const getTransactionsCSVObjs = makePieActionObjs("get", "Transaction_CSV")
export const getTransactionsCSVActionDict = prependAppName(appName, getTransactionsCSVObjs.pieDict)
const getPacFileObjs = makePieActionObjs("get", "pac_file")
const postPacFileObjs = makePieActionObjs("post", "pac_file")
const getLatestPacFileObjs = makePieActionObjs("get", "latest_pac_file")
const getGeneralElectionsObjs = makePieActionObjs("get", "general_elections")
const patchForm3XSubmission = makePieActionObjs("patch", "fec_form_3x_submission")
const patchPacFileObjs = makePieActionObjs("patch", "pac_file")
const generateDotFecFileAndPdfObjs = makePieActionObjs("patch", "fec_file")
const getPdfUrlObjs = makePieActionObjs("get", "pac_file_pdf")
const getDotFecUrlObjs = makePieActionObjs("get", "pac_file_dot_fec")
const getFecCheckMsgUrlObjs = makePieActionObjs("get", "fec_check_msg")
const loadPrintChecksObjs = makePieActionObjs("load", "printChecks")
const downloadChecksObjs = makePieActionObjs("load", "downloadChecks")
const loadNextCheckNumberObjs = makePieActionObjs("load", "NextCheckNumber")
const patchPacFinancialsSettingsObjs = makePieActionObjs("patch", "pac_financials_settings")
const postPacFinancialsSettingsObjs = makePieActionObjs("post", "pac_financials_settings")
const patchPacForm1SettingsObjs = makePieActionObjs("patch", "pac_form1_settings")
const getPacForm1SettingsObjs = makePieActionObjs("get", "pac_form1_settings")
const putLedgerAffiliatedEntityObjs = makePieActionObjs("put", "ledger_affiliated_entity")
const loadNextAmendmentNumberObjs = makePieActionObjs("load", "next_amendment_number")
const loadFecForm1InitialFieldsObjs = makePieActionObjs("load", "fec_form1_initial_fields")
const submitSettingsObjs = makePieActionObjs("submit", "pac_settings")
const submitForm1HistoricalObjs = makePieActionObjs("submit", "form1_historical")
const getJurisdictionObjs = makePieActionObjs("get", "ledger_reporting_jurisdiction")

export const getLedgerSettingsActionDict = prependAppName(appName, getLedgerSettingsObjs.pieDict)
export const createTransactionEventActionDict = prependAppName(appName, createTransactionEventObjs.pieDict)
export const getPacFileActionDict = prependAppName(appName, getPacFileObjs.pieDict)
export const getLatestPacFileActionDict = prependAppName(appName, getLatestPacFileObjs.pieDict)
export const getGeneralElectionActionDict = prependAppName(appName, getGeneralElectionsObjs.pieDict)
export const patchPacFinancialsSettingsActionDict = prependAppName(appName, patchPacFinancialsSettingsObjs.pieDict)
export const postPacFinancialsSettingsActionDict = prependAppName(appName, postPacFinancialsSettingsObjs.pieDict)
export const patchForm3XSubmissionActionDict = prependAppName(appName, patchForm3XSubmission.pieDict)
export const patchPacForm1SettingsActionDict = prependAppName(appName, patchPacForm1SettingsObjs.pieDict)
export const getPacForm1SettingsActionDict = prependAppName(appName, getPacForm1SettingsObjs.pieDict)
export const putLedgerAffiliatedEntityActionDict = prependAppName(appName, putLedgerAffiliatedEntityObjs.pieDict)
export const postPacFileActionDict = prependAppName(appName, postPacFileObjs.pieDict)
export const patchPacFileActionDict = prependAppName(appName, patchPacFileObjs.pieDict)
export const generateDotFecFileAndPdfActionDict = prependAppName(appName, generateDotFecFileAndPdfObjs.pieDict)
export const getPdfUrlActionDict = prependAppName(appName, getPdfUrlObjs.pieDict)
export const getDotFecUrlActionDict = prependAppName(appName, getDotFecUrlObjs.pieDict)
export const getFecCheckMsgUrlActionDict = prependAppName(appName, getFecCheckMsgUrlObjs.pieDict)
export const loadPrintChecksActionDict = prependAppName(appName, loadPrintChecksObjs.pieDict)
export const downloadChecksActionDict = prependAppName(appName, downloadChecksObjs.pieDict)
export const loadNextCheckNumberActionDict = prependAppName(appName, loadNextCheckNumberObjs.pieDict)
export const loadNextAmendmentNumberActionDict = prependAppName(appName, loadNextAmendmentNumberObjs.pieDict)
export const loadFecForm1InitialFieldsActionDict = prependAppName(appName, loadFecForm1InitialFieldsObjs.pieDict)
export const submitSettingsActionDict = prependAppName(appName, submitSettingsObjs.pieDict)
export const submitForm1HistoricalActionDict = prependAppName(appName, submitForm1HistoricalObjs.pieDict)
export const getJurisdictionsActionDict = prependAppName(appName, getJurisdictionObjs.pieDict)

export const getTransactionActionDict = prependAppName(appName, getTransactionObjs.pieDict)

const loadBankAccountObjs = makePieActionObjs("load", "BankAccount")
export const loadBankAccountActionDict = prependAppName(appName, loadBankAccountObjs.pieDict)

export const getRecentReconciliationDetailObj = makePieActionObjs("get", "most_recent_reconciliation")
export const getReconciliationDetailObj = makePieActionObjs("get", "reconciliation")
export const patchReconciliationDetailObj = makePieActionObjs("patch", "reconciliation")
export const postReconciliationDetailObj = makePieActionObjs("post", "reconciliation")
export const saveReconciliationActionObj = makePieActionObjs("finish", "reconciliation")

export const getRecentReconciliationDetailActionDict = prependAppName(appName, getRecentReconciliationDetailObj.pieDict)
export const getReconciliationDetailActionDict = prependAppName(appName, getReconciliationDetailObj.pieDict)
export const patchReconciliationDetailActionDict = prependAppName(appName, patchReconciliationDetailObj.pieDict)
export const postReconciliationActionDict = prependAppName(appName, postReconciliationDetailObj.pieDict)
export const saveReconciliationActionDict = prependAppName(appName, saveReconciliationActionObj.pieDict)

export const LOAD_INITIAL_MATCH_SET_FROM_RESPONSE_STR = "LOAD_INITIAL_MATCH_SET_FROM_RESPONSE"
export const CLEAR_INITIAL_MATCH_SET_STR = "CLEAR_INITIAL_MATCH_SET_STR"
export const CLEAR_CHECK_DATA = "CLEAR_CHECK_DATA"
export const TOGGLE_CHECK_ENABLED_STR = "TOGGLE_CHECK_ENABLED"
export const UPDATE_ALL_CHECK_NUMBERS_STR = "UPDATE_ALL_CHECK_NUMBERS"
export const UPDATE_INVALID_CHECK_NUMBER_STR = "UPDATE_INVALID_CHECK_NUMBER"
export const UPDATE_CHECK_FORM_MODAL_STR = "UPDATE_CHECK_FORM_MODAL"
export const SET_DEFAULT_REFERENCE_NUMBER_STR = "SET_DEFAULT_REFERENCE_NUMBER"
export const SET_CONTINUE_TO_CREATE_FILING_STR = "CONTINUE_TO_CREATE_FILING"

export const LOAD_INITIAL_MATCH_SET_FROM_RESPONSE = prependAppName(appName, [
    LOAD_INITIAL_MATCH_SET_FROM_RESPONSE_STR,
])[0]
export const CLEAR_INITIAL_MATCH_SET = prependAppName(appName, [CLEAR_INITIAL_MATCH_SET_STR])[0]

export const UPDATE_MATCHED_TRANSACTIONS_STR = "UPDATE_MATCHED_TRANSACTIONS"
export const UPDATE_MATCHED_TRANSACTIONS = prependAppName(appName, [UPDATE_MATCHED_TRANSACTIONS_STR])[0]
export const UPDATE_MATCHED_TRANSACTION_BATCHES_STR = "UPDATE_MATCHED_TRANSACTION_BATCHES"
export const UPDATE_MATCHED_TRANSACTION_BATCHES = prependAppName(appName, [UPDATE_MATCHED_TRANSACTION_BATCHES_STR])[0]
export const TOGGLE_CHECK_ENABLED = prependAppName(appName, [TOGGLE_CHECK_ENABLED_STR])[0]
export const UPDATE_ALL_CHECK_NUMBERS = prependAppName(appName, [UPDATE_ALL_CHECK_NUMBERS_STR])[0]
export const UPDATE_INVALID_CHECK_NUMBER = prependAppName(appName, [UPDATE_INVALID_CHECK_NUMBER_STR])[0]
export const UPDATE_CHECK_FORM_MODAL = prependAppName(appName, [UPDATE_CHECK_FORM_MODAL_STR])[0]
export const SET_DEFAULT_REFERENCE_NUMBER = prependAppName(appName, [SET_DEFAULT_REFERENCE_NUMBER_STR])[0]
export const SET_CONTINUE_TO_CREATE_FILING = prependAppName(appName, [SET_CONTINUE_TO_CREATE_FILING_STR])[0]

export const POP_AFTER_TRANSACTION_FORM_SUBMISSION_STR = "POP_AFTER_TRANSACTION_FORM_SUBMISSION"
export const POP_AFTER_TRANSACTION_FORM_SUBMISSION = prependAppName(appName, [
    POP_AFTER_TRANSACTION_FORM_SUBMISSION_STR,
])[0]

export const START_NEW_TRANSACTION_AFTER_TRANSACTION_FORM_SUBMISSION_STR =
    "START_NEW_TRANSACTION_AFTER_TRANSACTION_FORM_SUBMISSION"
export const START_NEW_TRANSACTION_AFTER_TRANSACTION_FORM_SUBMISSION = prependAppName(appName, [
    START_NEW_TRANSACTION_AFTER_TRANSACTION_FORM_SUBMISSION_STR,
])[0]

export const APPEND_OR_UPDATE_ACCOUNT_FOR_LEDGER_SETTINGS_STR = "APPEND_OR_UPDATE_ACCOUNT_FOR_LEDGER_SETTINGS"
export const APPEND_OR_UPDATE_ACCOUNT_FOR_LEDGER_SETTINGS = prependAppName(appName, [
    APPEND_OR_UPDATE_ACCOUNT_FOR_LEDGER_SETTINGS_STR,
])[0]

export const TRANSACTION_LIST_CHANGE_FILTER_TYPE = `${appName}/TRANSACTION_LIST_CHANGE_FILTER_TYPE`

export const CREATE_SPECIAL_FORM_3X_STR = "CREATE_SPECIAL_FORM_3X"
export const CREATE_SPECIAL_FORM_3X = prependAppName(appName, [CREATE_SPECIAL_FORM_3X_STR])[0]

export const TRANSACTION_FORM_POPULATE_FIELD_FROM_EXTERNAL = `${appName}/TRANSACTION_FORM_POPULATE_FIELD_FROM_EXTERNAL`

const actionTypes = {
    ...createTransactionEventActionDict,
    ...getLedgerSettingsActionDict,
    ...getTransactionActionDict,
    ...getRecentReconciliationDetailActionDict,
    ...getReconciliationDetailActionDict,
    ...getTransactionsCSVActionDict,
    ...patchReconciliationDetailObj,
    ...saveReconciliationActionDict,
    ...loadPrintChecksActionDict,
    ...loadNextCheckNumberActionDict,
    ...loadNextAmendmentNumberActionDict,
    ...getPacForm1SettingsActionDict,
    ...patchPacFinancialsSettingsActionDict,
    LOAD_INITIAL_MATCH_SET_FROM_RESPONSE,
    SET_DEFAULT_REFERENCE_NUMBER,
    TOGGLE_CHECK_ENABLED,
    UPDATE_ALL_CHECK_NUMBERS,
    UPDATE_CHECK_FORM_MODAL,
    UPDATE_INVALID_CHECK_NUMBER,
    UPDATE_MATCHED_TRANSACTION_BATCHES,
    UPDATE_MATCHED_TRANSACTIONS,
    APPEND_OR_UPDATE_ACCOUNT_FOR_LEDGER_SETTINGS,
    TRANSACTION_LIST_CHANGE_FILTER_TYPE,
    TRANSACTION_FORM_POPULATE_FIELD_FROM_EXTERNAL,
    CLEAR_CHECK_DATA,
}

export default actionTypes

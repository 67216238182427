import styled from "styled-components"
import { getFontColorForBackgroundContrast } from "QuorumGrassroots/styled-components/helperFunctions"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"

export const StyledText = styleWithOrgDesign(styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${(props) => getFontColorForBackgroundContrast(props.organizationDesign.primary_color)};
    background-color: transparent;
    margin: 0;
`)

import React from "react"
import { Redirect } from "react-router-dom"

import PageTemplate from "QuorumGrassroots/framework/components/Foundations/PageTemplate"

/*
 This component should only ever fire when the user is logged in.
 See framework/index for the pathing.
 */
export const LogoutPage = (props) => {
    props.logout(props.indexRoute)

    return <PageTemplate title={props.t("navbar.logout")}>{props.t("navbar.logout_message")}</PageTemplate>
}

export default LogoutPage

import { HeaderWrapper } from "QuorumGrassroots/framework/components/Header/style"
import {
    selectCampaignNavigationBarIsLogoOnly,
    selectIsCampaignPage,
    selectNavBarStyleIsNone,
    selectNavigationBarIsTransparent,
} from "QuorumGrassroots/framework/selectors"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"

import NavBar from "QuorumGrassroots/framework/components/NavigationBar/index"

const Header = (props) => (
    <HeaderWrapper
        className="framework-header-main"
        isTransparent={props.navBarIsTransparent}
        logoOnly={props.logoOnly}
        shouldNotDisplay={props.shouldNotDisplay}
        isCampaignPage={props.isCampaignPage}
        navbarStyleIsNone={props.navbarStyleIsNone}
    >
        <NavBar {...props} />
    </HeaderWrapper>
)

Header.propTypes = {
    logoOnly: PropTypes.bool,
    navBarIsTransparent: PropTypes.bool,
    shouldNotDisplay: PropTypes.bool,
    isCampaignPage: PropTypes.bool,
}

const mapStateToProps = (state, props) => ({
    logoOnly: selectCampaignNavigationBarIsLogoOnly(state, props),
    navBarIsTransparent: selectNavigationBarIsTransparent(state, props),
    isCampaignPage: selectIsCampaignPage(state, props),
    navbarStyleIsNone: selectNavBarStyleIsNone(state, props),
})

export default connect(mapStateToProps, null)(Header)

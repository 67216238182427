/**
* @param {array[object]} stanceOptions - array of LegislativeStanceType objects
* @param {number} value - value pertaining to a LegislativeStanceType
* This functions gets the stance Enum object which matches the value argument from a list of stance Enum objects
* @name stanceWithCustomLabelByValue
* @function
*/
export const stanceWithCustomLabelByValue = (stanceOptions, value) => (
    stanceOptions.find(stanceEnum => stanceEnum.value === value)
)

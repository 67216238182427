import React from "react"
import PropTypes from "prop-types"

import * as colors from "Components/colors"
import * as S from "Components/Compounds/Widget/style"

import Icon from "Components/Atoms/Icon"

const Widget = ({
    children,

    // passed from ReactGridLayout
    className,

    // required
    emptyPlaceholder,
    id,
    onScroll,
    widget,

    // not required
    bottomDescription,
    count,
    countHasFilters,
    countLabel,
    countLabelLoading,
    description,
    descriptionFull,
    editing,
    forbiddenMessage,
    gradientHeader,
    headerIcons,
    headerTitle,
    isForbidden,
    noBorder,
    noHeader,
    showInitialCount,
    visualization,
    widgetContentIsLoading,
    widgetFallback,
}) => (
    <S.Widget
        data-auto-cy="CompoundWidget"
        className={className}
        editing={editing}
        gradientHeader={gradientHeader}
        id={id}
        noBorder={noBorder}
        noHeader={noHeader}
        widget={widget}
    >
        {
            ((headerIcons || headerTitle) &&
                <S.WidgetHeader
                    data-cy="widget-header"
                    editing={editing}
                    noHeader={noHeader}
                    headerTitle={headerTitle}
                    gradientHeader={gradientHeader}
                >
                    {
                        headerTitle && (
                            <S.WidgetHeaderTitle
                                data-cy="widget-header-title"
                                title={headerTitle}
                            >
                                {headerTitle}
                            </S.WidgetHeaderTitle>
                        )
                    }
                    {
                        headerIcons && (
                            <S.WidgetHeaderIcons
                                editing={editing}
                                headerTitle={headerTitle}
                                gradientHeader={gradientHeader}
                            >
                                {
                                    headerIcons?.map((item, i) =>
                                        <Icon
                                            className={`${item.icon}-widget-edit-button`}
                                            dataCy={item.dataCy}
                                            flip={item.flip}
                                            id={item.id}
                                            icon={item.icon}
                                            iconFamily={item.iconFamily}
                                            key={item.icon}
                                            onClick={item.onClick}
                                            size={item.size}
                                            spin={item.spin}
                                            style={
                                                {
                                                    // on bar graphs, we want the margin to account for the load all button to avoid weird breakpoints
                                                    marginLeft:
                                                        i === 0
                                                        && widget?.props?.children?.props?.widget?.meta?.vis_subtype === DjangIO.app.widgets.types.VisualizationSubtype.bar.value
                                                        && !editing
                                                        ? "120px" : null,
                                                    // if Widget is not a quicklink and does not have a header
                                                    ...((!gradientHeader && noHeader) &&
                                                    // https://projects.invisionapp.com/share/NUZKV22TZP3#/screens
                                                    {
                                                        background: `${colors.White}`,
                                                        "box-shadow": `0px 0px 10px 10px ${colors.White}`,
                                                        filter: `drop-shadow(0px 0px 5px ${colors.White})`,
                                                    })
                                                }
                                            }
                                        />
                                )}
                            </S.WidgetHeaderIcons>
                        )
                    }
                </S.WidgetHeader>
            )
        }
        {
            // if we are actively editing the widget
            // (which can only occur from app/static/frontend/dashboards/components/Dashboard.jsx at the moment),
            // render the resize icon in the bottom right of the Widget
            editing &&
                <S.WidgetResizeIcon
                    gradientHeader={gradientHeader}
                    noHeader={noHeader}
                >
                    <Icon
                        icon="drag-handle"
                        iconFamily="q"
                        size="2x"
                    />
                </S.WidgetResizeIcon>
        }
        {
            (description && !bottomDescription) &&
                <S.WidgetDescription
                    data-cy="widget-description"
                    descriptionFull={descriptionFull}
                >
                    {description}
                </S.WidgetDescription>
        }
        {
            // if we have passed a valid Widget Design System component (ListWidget, etc.), show it
            // this usually happens in app/static/frontend/widgets/components/{DashboardWidgetType}Widget.jsx files
            widget &&
                <S.WidgetContent
                    onScroll={onScroll}
                    visualization={visualization}
                    widgetContentIsLoading={widgetContentIsLoading}
                    widget={widget}
                >
                    {widget}
                </S.WidgetContent>
        }
        {
            (description && bottomDescription) &&
                <S.WidgetDescription
                    data-cy="widget-description"
                    descriptionFull={descriptionFull}
                >
                    {description}
                </S.WidgetDescription>
        }
        {
            // in cases where we have a widget to load but the widgetEngine request has not resolved yet,
            // we want to provide a fallback component that we will show in its place
            // for now, this will usually be the LoadingSpinner
            widgetFallback &&
                <S.WidgetFallback
                    data-cy="widget-fallback"
                >
                    {widgetFallback}
                </S.WidgetFallback>
        }
        {
            // in cases where we have no valid Widget schema and no fallback component defined
            // (such as when we are creating a new widget in the WidgetEditForm)
            // we want to show some placeholder text centered in the Widget
            (emptyPlaceholder && !widget && !widgetFallback) &&
                <S.WidgetEmpty
                    data-cy="widget-empty"
                >
                    { emptyPlaceholder }
                </S.WidgetEmpty>
        }
        {
            // for Widgets that have a count, if passed down as a prop,
            // we want to be able to show that count in the Widget footer
            // TODO: this might need to be re-factored at some point depending
            // on how commonly we want to display this information
            (showInitialCount || count >= 0)
                ? (
                    <S.WidgetFooter>
                        {
                            (countHasFilters || showInitialCount) &&
                                <Icon
                                    icon={"magic"}
                                    iconFamily={"fas"}
                                    size={"1x"}
                                />
                        }
                        {
                            <S.WidgetFooterText>
                                {
                                    countLabelLoading
                                        ? countLabelLoading
                                        : `${count?.toLocaleString()} ${countLabel}`
                                }
                            </S.WidgetFooterText>
                        }
                    </S.WidgetFooter>
                )
                : undefined
        }
        {isForbidden && (
            <S.ForbiddenLayer>
                <S.ForbiddenIconContainer>
                    <Icon icon="exclamation-circle" iconFamily="far" />
                </S.ForbiddenIconContainer>
                {forbiddenMessage}
            </S.ForbiddenLayer>
        )}
    </S.Widget>
)

Widget.defaultProps = {
    bottomDescription: false,
    countLabel: "Items",
    noBorder: false,
    noHeader: false,
}

Widget.propTypes = {
    bottomDescription: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.element),
    className: PropTypes.string,
    count: PropTypes.number,
    countHasFilters: PropTypes.bool,
    countLabel: PropTypes.string,
    countLabelLoading: PropTypes.string,
    description: PropTypes.string,
    descriptionFull: PropTypes.bool,
    editing: PropTypes.bool,
    emptyPlaceholder: PropTypes.string,
    forbiddenMessage: PropTypes.string,
    gradientHeader: PropTypes.bool,
    headerIcons: PropTypes.arrayOf(PropTypes.element),
    headerTitle: PropTypes.string,
    id: PropTypes.string,
    isForbidden: PropTypes.bool,
    noBorder: PropTypes.bool,
    noHeader: PropTypes.bool,
    onScroll: PropTypes.func,
    showInitialCount: PropTypes.bool,
    widget: PropTypes.element,
    widgetContentIsLoading: PropTypes.bool,
    widgetFallback: PropTypes.element,
}

export default Widget

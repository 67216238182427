import React from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form/immutable"

import classNames from "classnames"

import PhoneInput from "react-phone-input-2"
// multiple default styles are available
// list of those and other customization options:
// https://github.com/bl00mber/react-phone-input-2
import "react-phone-input-2/lib/bootstrap.css"

import ReduxFormFieldWrapper from "forms/components/ReduxFormFieldWrapper"
import regexes from "shared/imports/regex"

export const cleanPhoneNumber = (phoneNumber) => {
    // If it's just a country code ("+" followed by 1-3 chars), don't try to validate and submit that later.
    if (phoneNumber && phoneNumber.length <= 4) {
        return ""
    }
    return phoneNumber.replace(regexes.phoneNumberReplacerRegex, "")
}

export const renderPhoneInput = ({
    input,
    meta,
    country,
    countryCodeEditable,

    accessibilityId,
    dataCy,
    displayErrorWithoutTouch,
    label,
    labelStyle,
    style,
    tooltipText,
    showAsterisk,
    isCampaignPage,
    disabled,
}) => {
    const formWrapperClassName = classNames("form-input")
    const phoneInputClassName = classNames(isCampaignPage ? "ngg-input-field" : "input-field", {
        "ngg-input-error-outline": meta.error && (displayErrorWithoutTouch || meta.touched) && isCampaignPage,
    })
    return (
        <ReduxFormFieldWrapper
            className={classNames("input", formWrapperClassName)}
            dataCy={dataCy}
            displayErrorWithoutTouch={displayErrorWithoutTouch}
            error={meta.error}
            label={label}
            labelStyle={labelStyle}
            style={style}
            tooltipText={tooltipText}
            touched={meta.touched}
            warning={meta.warning}
            accessibilityId={accessibilityId}
            showAsterisk={showAsterisk}
        >
            <PhoneInput
                country={country}
                countryCodeEditable={countryCodeEditable}
                onBlur={input.onBlur}
                onChange={input.onChange}
                onFocus={input.onFocus}
                value={input.value}
                disabled={disabled}
                // these settings are needed for the style to play nice in grassroots
                inputClass={phoneInputClassName}
                inputProps={{
                    "data-cy": dataCy,
                }}
                inputStyle={{
                    width: "100%",
                    backgroundColor: disabled && "#ecf0f1",
                }}
                // prevent the creation of the special-label element
                specialLabel={null}
                isCampaignPage={isCampaignPage}
            />
        </ReduxFormFieldWrapper>
    )
}

export const PhoneField = ({
    accessibilityId,
    countryCodeEditable,
    dataCy,
    defaultCountry,
    disabled,
    displayErrorWithoutTouch,
    label,
    labelStyle,
    name,
    shouldCleanInput,
    style,
    tooltipText,
    validate,
    warn,
    showAsterisk,
    isCampaignPage,
}) => (
    <Field
        component={renderPhoneInput}
        field={name}
        name={name}
        accessibilityId={accessibilityId}
        dataCy={dataCy}
        disabled={disabled}
        displayErrorWithoutTouch={displayErrorWithoutTouch}
        label={label}
        labelStyle={labelStyle}
        style={style}
        tooltipText={tooltipText}
        validate={validate}
        warn={warn}
        country={defaultCountry}
        countryCodeEditable={countryCodeEditable}
        normalize={shouldCleanInput && cleanPhoneNumber}
        showAsterisk={showAsterisk}
        isCampaignPage={isCampaignPage}
    />
)

PhoneField.propTypes = {
    // whether or not the country code ("+1") can be changed by typing, or
    // is only changeable by using the flag dropdown
    countryCodeEditable: PropTypes.bool,
    // string indicating the country that the country code should initialize as
    // can always be changed by the user; options as listed here:
    // https://github.com/bl00mber/react-phone-input-2/blob/master/src/rawCountries.js
    defaultCountry: PropTypes.string,
    // field name for redux form
    name: PropTypes.string,
    // true if the field should store numbers as just numeric digits ("18003334444")
    // or false if the field should store numbers as they appear ("+1 (800) 333-444")
    shouldCleanInput: PropTypes.bool,
}

export default PhoneField

/* eslint-disable quote-props */
import styled, { css } from "styled-components"

import * as designConstants from "app/static/frontend/design-constants"

export const Label = styled.label`
    font-size: 16px;
    font-weight: 400;
    line-height: revert;
    color: #000;
    margin-bottom: 8px;
    ${(props) =>
        props.disabled &&
        css`
            color: #aaa;
        `}

    svg {
        margin-left: 8px;
        color: #7b8a8b;
        cursor: pointer;
    }
`

export const Description = styled.span`
    display: inline-block;
    margin-top: 5px;

    ${(props) => {
        if (props.hasError) {
            return css`
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                color: ${designConstants.ErrorRed};
            `
        } else if (props.hasWarning) {
            return css`
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                color: ${designConstants.WarningMarigold};
            `
        }

        return css`
            font-size: 13px;
            color: ${designConstants.QuorumGrey4};
        `
    }}

    svg {
        margin-right: 5px;
    }
`

export const SelectStyle = {
    menu: (provided, _state) => ({
        ...provided,
        marginTop: "4px",
        width: "100%",
        borderRadius: "4px",
        overflow: "clip",
        backgroundColor: "#FFF",
        boxShadow: "0 2px 7px 0 rgba(0,0,0,0.3)",
        zIndex: 3,
    }),
    menuList: (provided, _state) => ({
        ...provided,
        padding: 0,
        borderRadius: "4px",
    }),
    control: (provided, state) => ({
        ...provided,
        boxSizing: "content-box",
        borderRadius: "4px",
        border: "1px solid rgba(173, 181, 189, 1)",
        transition: `border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out`,

        minHeight: "40px",
        maxHeight: "85px",
        overflowY: "auto",
        overflowX: "hidden",

        "&:hover": {
            border: `1px solid ${designConstants.QuorumBlue}`,
        },
        "&:focus-within": {
            outline: "none",
        },
        /* Nesting under these selectors to boost specificity without using !important */
        "&, &:focus-within, &:hover": {
            ...(state.menuIsOpen && {
                border: "1px solid transparent",
                maxHeight: "80px",
                overflowY: "auto",
                overflowX: "hidden",
            }),
            ...(state.isDisabled && {
                border: `1px solid ${designConstants.FormControlBorderColor}`,
                backgroundColor: designConstants.QuorumGrey8,
            }),
            ...(state.selectProps.hasError && {
                border: `1px solid ${designConstants.ErrorRed}`,
            }),
        },
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: "#ADB5BD",
        fontSize: "16px",
        fontWeight: "400",
        ...(state.selectProps.hasError && {
            color: designConstants.ErrorRed,
        }),
        marginLeft: "8px",
    }),
    indicatorsContainer: (provided) => ({
        alignItems: "center",
        alignSelf: "flex-start",
        display: "flex",
        padding: "8px",
        gap: "12px",
        position: "sticky",
        top: "2px",
        right: 0,
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        padding: 0,
        color: state.selectProps.menuIsOpen ? designConstants.QuorumBlue : designConstants.HelpCenterSilverChalice,
        ":hover": {
            color: designConstants.CloudBurst,
        },
        minWidth: "20px",
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        transition: "all .2s ease",
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        cursor: "pointer",
        padding: 0,
        color: state.selectProps.menuIsOpen ? designConstants.QuorumBlue : designConstants.HelpCenterSilverChalice,
        ":hover": {
            color: designConstants.CloudBurst,
        },
    }),
    input: (provided, _state) => ({
        ...provided,
        fontSize: "14px",
        fontWeight: "400",
    }),
    // if we pass isHidden to an item in the select options array,
    // do not show it
    multiValue: (provided, state) => ({
        ...provided,
        padding: "3px 8px",
        backgroundColor: designConstants.QuorumTheme.colors.gray[1],
        borderRadius: "4px",
        alignItems: "center",
        display: state.data.isHidden ? "none" : "flex",
        maxWidth: "300px",
        gap: "8px",
        height: "30px",
        margin: 0,
    }),
    // once we hide the remove button we need to update the
    // right padding of the multi select
    multiValueLabel: (provided, state) => ({
        ...provided,
        color: "#495057",
        fontSize: "14px",
        fontWeight: "400",
        padding: "0px",
        paddingRight: state.data.isFixed ? "6px" : "unset",
        paddingLeft: "0px",
        gap: "8px",
    }),
    multiValueRemove: (provided, state) => {
        if (state.data.isFixed) {
            return {
                ...provided,
                display: "none",
                width: "0.75rem",
                height: "0.75rem",
            }
        }
        return {
            padding: "0px",
            color: "#495057",
            ":hover": { cursor: "pointer" },
            width: "0.75rem",
            height: "0.75rem",
            display: "flex",
            alignItems: "center",
        }
    },
    singleValue: (provided) => ({
        ...provided,
        color: "#000",
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "400",
    }),
    group: (provided, _state) => ({
        ...provided,
        padding: 0,
    }),
    groupHeading: (provided, _state) => ({
        ...provided,
        color: designConstants.QuorumGrey3,
        fontSize: "14px",
        fontWeight: "400",
        marginTop: ".5em",
        paddingLeft: "8px",
    }),
    option: (provided, state) => ({
        ...provided,
        color: designConstants.QuorumBlue,
        fontSize: "14px",
        cursor: "pointer",
        padding: "8px",
        ...(state.isSelected && {
            backgroundColor: designConstants.QuorumBlue,
            color: "#FFF",
        }),
        ...(state.isFocused &&
            !state.isSelected && {
                backgroundColor: designConstants.HelpCenterAquaHaze,
                color: designConstants.QuorumBlue,
            }),
    }),
    noOptionsMessage: (provided) => ({
        ...provided,
        fontSize: "14px",
        fontWeight: "400",
        color: designConstants.SilverChalice,
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: "5px",
        gap: "4px",
        paddingRight: provided.hasValue ? "4rem" : "5px",
    }),
}

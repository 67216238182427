import styled, { css } from 'styled-components'

import * as rules from "Components/rules"
import * as colors from "Components/colors"

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const Overlay = styled.div`
    background-color: ${props => props.color};
    color: ${colors.White};
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    visibility: hidden;

    & > svg {
        margin-left: 8px;
    }
`

export const SocialUserButton = styled.button`
    background-color: ${colors.White};
    border: 1px solid ${props => props.color};
    padding: 8px;
    border-radius: 4px;
    display: flex;
    position: relative;
    transition: min-width .2s ease-out;

    & > svg {
        font-size: 34px;
    }

    // On :hover, toggle the visibility of the overlay, and force the whole component to take up it's entire parent container
    ${props => props.hasDetails
        ? css`min-width: 100%;`
        : css`min-width: 0;`
    }

    &:hover ${Overlay} {
        visibility: visible;
    }

    &:hover {
        min-width: 100%;
    }
`

export const Datum = styled.div`
    text-align: start;
    display: flex;
    flex-direction: column;
    margin-left: 8px;

    & + & {
        margin-left: 16px;
    }
`

export const Value = styled.span`
    ${rules.MediumTextMediumWeight}
    line-height: 18px;
`

export const Label = styled.span`
    ${rules.SmallText}
    line-height: 15px;
`

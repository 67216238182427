import React from "react"
import styled from "styled-components"
import DatePicker from "react-datepicker"

import { flexGap } from "utils/cssHelpers"
import * as colors from "Components/colors"

export const DatePickerWrapper = styled(({ className, ...props }) => (
    <DatePicker
        {...props}
        calendarClassName={className}
        timeClassName={className}
        wrapperClassName={className}
    />
))`
    &.react-datepicker {
        display: flex;
        flex-direction: column;
        padding: 16px;
        box-shadow: 0 2px 7px 0 rgba(0,0,0,0.1);
        border: 1px solid ${colors.FormControlBorderColor};
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker__input-time-container {

        .react-datepicker-time__caption {
            /* The timeCaption prop doesn't seem to do anything */
            display: none;
        }
    }

    .react-datepicker__week {
        display: flex;
        justify-content: space-between;
        ${flexGap({ gap: 12 })}
    }

    .react-datepicker__month-container {
        font-size: 14px;
        color: ${colors.QuorumBlue};
    }

    .react-datepicker__month {
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 12px;
    }

    .react-datepicker__day-names {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
    }

    .react-datepicker__day-name {
        color: ${colors.gray7};
    }

    .react-datepicker__day {
        height: 23px;
        width: 23px;
        line-height: unset;
        font-weight: 400;
        color: ${colors.gray7};
    }

    .react-datepicker__day--in-range,
    .react-datepicker__day--in-selecting-range {
        background-color: ${colors.LightBlue};
        color: ${colors.QuorumBlue};
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--selecting-range-end,
    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end {
        background-color: ${colors.QuorumBlue};
        color: ${colors.White};
        font-weight: 400;
    }

    .react-datepicker__day--outside-month,
    .react-datepicker__day--disabled {
        color: ${colors.RainDance};
    }

    .react-datepicker__current-month {
        color: ${colors.gray9};
        font-weight: 400;
        font-size: 14px;
    }

    .react-datepicker__header {
        border-bottom: none;
        background-color: ${colors.White};
        padding: 0;
        padding-bottom: 8px;
    }

    .react-datepicker__navigation-icon::before {
        border-color: ${colors.QuorumBlue};
        top: 11px;
        left: -4px;
        height: 10px;
        width: 10px;
    }
    .react-datepicker__navigation--previous {
        left: 12px;
    }

    .react-datepicker__navigation--next {
        right: 12px;
    }

    .react-datepicker__navigation {
        top: 16px;
        height: 24px;
        width: 24px;
        border-radius: 4px;

        &:hover {
            background-color: ${colors.AquaHaze};
        }

        &:hover ::before {
            border-color: ${colors.QuorumBlue};
        }

        &:focus {
            outline: none;
        }
    }

    .react-datepicker__input-time-container {
        margin: 0;

        .react-datepicker-time__input-container {
            width: 100%;

            .react-datepicker-time__input {
                width: 100%;
                margin-left: 0;
            }
        }
    }
`

export const DatePickerOuterWrapper = styled.div`
    width: fit-content;

    .react-datepicker-popper {
        padding-top: 5px;
    }
`

export const ButtonWrapper = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
`

export const HR = styled.hr`
    margin-top: 16px;
    border-top: 1px solid #979797;
`

export const TimePickerWrapper = styled.div`
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    & > div {
        width: 64px;
    }
`

import React from "react"
import PropTypes from "prop-types"


import * as S from "Components/Compounds/Banner/style"

const Banner = ({ backgroundColor, backgroundImage, height, children }) => (
    <S.Banner
        backgroundColor={backgroundColor}
        backgroundImage={backgroundImage}
        data-auto-cy="CompoundBanner"
        height={height}
    >
        {children}
    </S.Banner>
)

Banner.propTypes = {
    backgroundColor: PropTypes.string,
    backgroundImage: PropTypes.string,
    height: PropTypes.number,
}

export default Banner

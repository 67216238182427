import styled, { css } from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"


export const SwitchContainer = styled.div`
    // flexible container
    display: flex;
    align-items: stretch;
    justify-content: center;
    height: ${({ isTiny }) => isTiny
        ? rules.TinyButtonHeightV2
        : rules.ButtonHeight
    };
    width: fit-content;

    // style
    padding: 2px;
    background-color: ${colors.DarkerPorcelain};
    font-size: ${rules.ButtonFontSize};
    color: ${colors.QuorumBlue};
    border-radius: ${rules.BorderRadius};
    line-height: ${rules.ButtonFontLineHeight};
    background-color: ${colors.gray1};
`

export const SwitchOption = styled.div`
    transition: all 0.25s;

    background-color: ${colors.DarkerPorcelain};

    // spacing
    padding-right: ${({ isTiny }) => isTiny ? "7px" : "11px"};
    padding-left: ${({ isTiny }) => isTiny ? "7px" : "11px"};

    // flexible container
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;

    // border style
    border: ${rules.Line};
    border-radius: ${rules.BorderRadius};

    &:hover {
        cursor: pointer;
    }

    // change the look of the option if it is selected
    ${({isSelected}) => {
        if (isSelected) {
                return css`
                    color: ${colors.violet7};
                    background-color: ${colors.White};
                    border-color: ${colors.gray1};
                }
            `
        } else {
            return css`
                color: ${colors.gray7};
                background-color: ${colors.gray1};
                border-color: ${colors.gray1};

                &:hover {
                    background-color: ${colors.gray2};
                    border-color: ${colors.gray1};
                    // the children on the ends should round their end borders
                    :first-child {
                        border-radius: ${rules.BorderRadiusLeft};
                    }
                    :last-child {
                        border-radius: ${rules.BorderRadiusRight};
                    }
                }
            `
        }
    }}
`

export const SwitchText = styled.span`
    ${({ textBreakpoint }) => textBreakpoint && css`
        @media (max-width: ${textBreakpoint}px) {
            display: none;
        }
    `}
`

export const SwitchIconSpan = styled.span`
    ${({padRight}) => {
        if (padRight)
            return css`
                padding-right: 7px;
            `
    }}

    ${({ iconBreakpoint, textBreakpoint }) => (iconBreakpoint && textBreakpoint) && css`
        @media (max-width: ${iconBreakpoint}px) and (min-width: ${textBreakpoint + 1}px) {
            display: none;
        }
    `}

    ${({ textBreakpoint }) => textBreakpoint && css`
        @media (max-width: ${textBreakpoint}px) {
            padding-right: 0;
        }
    `}
`

import React, { useState } from "react"
import PropTypes from "prop-types"

import { useFitsOnScreen } from "utils/hooks"

import * as S from "Components/Molecules/Tooltip/style"
import * as rules from "Components/rules"

const Tooltip = ({
    isTiny,
    maxdWidth,
    fixedWidth,
    offsetCallback,
    offsetMargin,
    placement,
    text,
}) => {
    // measure how far off screen the tooltip is (documentRightEdge - boxRightEdge)
    const [onScreenRef, measuredOffset] = useFitsOnScreen()
    const [storedOffset, updateOffset] = useState(undefined)

    const offsetPadding = parseInt(rules.TopBarItemPaddingSmall, 10)
    const paddedOffset = measuredOffset - offsetPadding + offsetMargin

    // if this is the first render and the offset is less than the minimum,
    // the tooltip is off screen or too close to the edge; store how far
    // we need to adjust it
    if (storedOffset === undefined && measuredOffset < offsetPadding) {
        updateOffset(paddedOffset)
        offsetCallback && offsetCallback(paddedOffset)
    }

    return (
        <S.Container
            data-auto-cy="MoleculeTooltip"
            ref={onScreenRef}
        >
            <S.Tooltip
                isTiny={isTiny}
                fixedWidth={fixedWidth}
                maxWidth={maxdWidth}
                // pass the opposite offset into this component so we can
                // move the arrow the same amount in the opposite direction
                // to restore its original position
                offset={-1 * storedOffset}
                placement={placement}
            >
                {text}
            </S.Tooltip>
        </S.Container>
    )
}

Tooltip.defaultProps = {
    isTiny: false,
    fixedWidth: "",
    maxdWidth: "200px",
    offsetMargin: 0,
    placement: "top",
}

Tooltip.propTypes = {
    isTiny: PropTypes.bool,
    fixedWidth: PropTypes.string,
    maxdWidth: PropTypes.string,
    offsetCallback: PropTypes.func,
    // margin of error for the measuredOffset:
    // if the right of the tooltip and the right of the window are within offsetMargin then
    // no need to updated the tooltip
    offsetMargin: PropTypes.number,
    placement: PropTypes.oneOf(["bottom", "right", "top"]),
    // text to display in the tooltip
    text: PropTypes.string,
}

export default Tooltip

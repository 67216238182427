import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Atoms/Back/style"
import { ICON_SIZES } from "utils/useFontAwesome"

import Icon from "Components/Atoms/Icon"


const Back = ({
    dataCy,
    icon,
    iconFamily,
    onClick,
    size,
    to,
    }) => (
    <S.Back
        data-auto-cy="AtomBack"
        data-cy={dataCy}
        onClick={onClick}
    >
        <Icon
            icon={icon}
            iconFamily={iconFamily}
            size={size}
            fixedWidth={false}
        />
    </S.Back>
)

Back.propTypes = {
    dataCy: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    to: PropTypes.string,
    size: PropTypes.oneOf(ICON_SIZES),
}

Back.defaultProps = {
    icon: "arrow-left",
    size: "2x",
    to: "/",
}

export default Back

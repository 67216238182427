import styled, { css } from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"
import * as transitions from "Components/transitions"


export const Faq = styled.div`
    display: flex;
    flex-direction: row;
    color: ${colors.QuorumBlue};
    ${rules.LargeText}
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
`

export const AboveFold = styled.div`
    cursor: pointer;

    svg {
        margin-top: 2px;
    }
`

export const Caret = styled.span`
    margin-left: 5px;
    svg {
        transition: transform .1s linear;
        ${props => props.isOpen &&
            css`transform: rotate(-180deg);`
        }
    }
`

export const BelowFold = styled.div`
    margin-top: 20px;
    ${props => transitions.foldDown(props)}
`

import React from "react"
import PropTypes from "prop-types"

import CustomEventAddToCalendar from "customEvents/CustomEventAddToCalendar"
import { decodeHtmlEntities, parseEstDateToTimezone } from "imports/desktopHelperFunctions"

import "quorum/static/frontend/custom-event/stylus/CustomEventDetails.styl"

const CustomEventDetails = ({
    addToCalendar,
    addToCalendarButtonText,

    headerLabel,
    locationLabel,
    addressLabel,
    startTimeLabel,
    endTimeLabel,
    linkLabel,

    eventName,
    eventDescription,
    eventVenueName,
    eventAddress,
    eventDate,
    eventEndTime,
    eventTimezone,
    eventLink,

    primaryColor,
}) => {
    const { Timezones } = DjangIO.app.userdata.types
    const timezoneEnum = Timezones.by_value(eventTimezone) || Timezones.eastern

    // 'LLL' formats the date/time in 'Month name, day of month, year, time', ex: 'July 24, 2019, 2:28 PM
    // 'z' gives event's selected timezone
    const parseDateString = (dateString) => parseEstDateToTimezone(dateString, timezoneEnum.pytz).format("LLL z")

    const detailItems = []

    if (eventVenueName) {
        detailItems.push({ label: locationLabel, text: eventVenueName })
    }

    if (eventAddress) {
        detailItems.push({ label: addressLabel, text: eventAddress })
    }

    if (eventLink) {
        detailItems.push({ label: linkLabel, text: eventLink, link: eventLink })
    }

    if (eventDate) {
        detailItems.push({ label: startTimeLabel, text: parseDateString(eventDate) })
    }

    if (eventEndTime) {
        detailItems.push({ label: endTimeLabel, text: parseDateString(eventEndTime) })
    }

    if (!detailItems.length) {
        // If none of these fields are defined, do not render component
        return <div />
    }

    // If eventLink is defined, first line of the calendar description should be 'Link: https://www ...'
    // Otherwise, just set the description as normal
    const calendarDescription = `${eventLink && `Link: ${eventLink}\n`}${eventDescription}`

    return (
        <div
            className="custom-event-details-main"
            style={{
                border: `1px solid ${primaryColor}`,
            }}
        >
            <div
                className="custom-event-details-header"
                style={{
                    backgroundColor: primaryColor,
                }}
            >
                {headerLabel}
            </div>
            {detailItems.map((item) => (
                <div
                    className="custom-event-details-item"
                    style={{
                        color: primaryColor,
                    }}
                >
                    <span className="custom-event-details-item-label">{`${item.label}: `}</span>
                    {item.link ? (
                        <a href={item.link} target="_blank" rel="noreferrer">
                            {item.text}
                        </a>
                    ) : (
                        <div
                            className="custom-event-details-item-text"
                            dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(item.text) }}
                        />
                    )}
                </div>
            ))}
            {addToCalendar && (
                <div>
                    <hr className="add-to-calendar-hr" />
                    <CustomEventAddToCalendar
                        title={eventName}
                        description={calendarDescription}
                        address={eventAddress}
                        venueName={eventVenueName}
                        startTime={eventDate}
                        endTime={eventEndTime}
                        buttonLabel={addToCalendarButtonText}
                        dropdownClass="details-calendar-menu-item"
                        buttonClassClosed="details-calendar-button"
                        buttonWrapperClass="details-calendar-wrapper"
                        // use the non-design system version of the addtocalendar component
                        useLegacyComponent
                    />
                </div>
            )}
        </div>
    )
}

CustomEventDetails.defaultProps = {
    addToCalendar: false,
    addToCalendarButtonText: "Add To Calendar",

    headerLabel: "Event Details",
    locationLabel: "Location",
    addressLabel: "Address",
    startTimeLabel: "Start",
    endTimeLabel: "End",
    linkLabel: "Link",

    eventName: "",
    eventDescription: "",
    eventVenueName: "",
    eventAddress: "",
    eventDate: "",
    eventLink: "",
    primaryColor: "#503ABD",
    backgroundColor: "#ffffff",
}

CustomEventDetails.propTypes = {
    addToCalendar: PropTypes.bool,
    addToCalendarButtonText: PropTypes.string,

    headerLabel: PropTypes.string,
    locationLabel: PropTypes.string,
    addressLabel: PropTypes.string,
    startTimeLabel: PropTypes.string,
    endTimeLabel: PropTypes.string,
    linkLabel: PropTypes.string,

    eventVenueName: PropTypes.string,
    eventAddress: PropTypes.string,
    eventDate: PropTypes.string,
    eventEndTime: PropTypes.string,
    eventLink: PropTypes.string,
    primaryColor: PropTypes.string,
    backgroundColor: PropTypes.string,
}

export default CustomEventDetails

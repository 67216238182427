import React from "react"
import { connect } from "react-redux"
import { selectFieldConnection } from "frontend/forms/selectors"
import PropTypes from "prop-types"
import classNames from "classnames"

// components
import ReduxFormFieldWrapper from "app/static/frontend/forms/components/ReduxFormFieldWrapper"

// styles
import "app/static/stylus/FormStyles.styl"
import "frontend/forms/stylus/InputGroupField.styl"

export const InputGroupField = (props) => {
    const {
        input,
        meta: { error, warning, touched },
        accessibilityId,
        choices,
        className,
        dataCy,
        displayErrorWithoutTouch,
        label,
        labelStyle,
        multi,
        style,
        onChange,
        tooltipText,
        value,
    } = props
    const shouldDisplayError = displayErrorWithoutTouch || touched
    const handleChange = (event) => {
        const changeFunc = props.input.onChange ?? onChange
        if (multi) {
            // retrieve a list of checked options in the group
            const optionGroup = event.target.parentNode.closest(".form-input-group-field")
            const checkedOptions = Array.from(optionGroup.querySelectorAll("input[type='checkbox']:checked"))
            changeFunc(checkedOptions)
        } else {
            changeFunc(event.target)
        }
    }

    const isChecked = (selectedValue, possibleChoice) => {
        if (selectedValue instanceof Array) {
            return selectedValue.includes(possibleChoice)
        }

        return selectedValue === possibleChoice
    }

    const inputType = multi ? "checkbox" : "radio"
    const inputGroupItems = choices.map((choice) => (
        <label key={choice.value} className="form-input-group-field-option">
            <input
                type={inputType}
                name={input.name}
                value={choice.value}
                onChange={handleChange}
                checked={isChecked(value, choice.value)}
            />
            {choice.label}
        </label>
    ))

    return (
        <ReduxFormFieldWrapper
            additionalClassNames={className}
            className="input-group"
            dataCy={dataCy}
            displayErrorWithoutTouch={displayErrorWithoutTouch}
            error={error}
            label={label}
            labelStyle={labelStyle}
            style={style}
            tooltipText={tooltipText}
            touched={touched}
            warning={warning}
            accessibilityId={accessibilityId}
        >
            <div
                className={classNames({
                    "select-error-outline": error && shouldDisplayError,
                    "select-warning-outline": warning && shouldDisplayError,
                })}
            >
                {inputGroupItems}
            </div>
        </ReduxFormFieldWrapper>
    )
}

InputGroupField.defaultProps = {
    accessibilityId: "",
    clearable: true,
    dataCy: "",
    displayErrorWithoutTouch: true,
    style: {},
}

InputGroupField.propTypes = {
    accessibilityId: PropTypes.string,
    choices: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            label: PropTypes.string.isRequired,
        }),
    ).isRequired,
    className: PropTypes.string,
    clearable: PropTypes.bool,
    dataCy: PropTypes.string,
    defaultLabel: PropTypes.string,
    displayErrorWithoutTouch: PropTypes.bool,
    dropUp: PropTypes.bool,
    input: PropTypes.object,
    isLoading: PropTypes.bool,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    meta: PropTypes.object,
    multi: PropTypes.bool,
    noResultsText: PropTypes.string,
    onMenuOpen: PropTypes.func,
    selectLabel: PropTypes.string,
    shouldRenderNewSelect: PropTypes.bool,
    shouldNotShowClearButton: PropTypes.bool,
    style: PropTypes.object,
    tooltipText: PropTypes.string,
}

export default connect(selectFieldConnection, {})(InputGroupField)

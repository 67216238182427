import React from "react"
import PropTypes from "prop-types"
import debounce from "lodash.debounce"

import * as S from "Components/Compounds/CheckFormTopBar/style"

const DEBOUNCE_DELAY_TIME = 400

export class CheckFormTopBar extends React.Component {

    constructor(props) {
        super(props)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.debounceUpdate = debounce(this.handleUpdate, DEBOUNCE_DELAY_TIME)
        this.state = { validNextCheckInput: true }
    }

    saveTextAndDebounce = (e) => {
        this.debounceUpdate(e.target.value)
    }

    handleUpdate = (value) => {
        const cleanString = value.replace(/\D/g,'')
        if (value !== cleanString) {
            this.setState({ validNextCheckInput: false })
        }
        else if (Number(cleanString) < this.props.defaultReferenceNumber) {
            this.setState({ validNextCheckInput: false })
        }
        else {
            for (let i = 0; i < this.props.numChecks; i++) {
                this.props.change(
                    `checkData[${i}.referenceNumber`,
                    String(Number(value) + i),
                )
            }
            this.setState({ validNextCheckInput: true })
        }
    }

    render() {
        const {
            checksTotalAmount,
            bankAccountAmount,
            defaultReferenceNumber,
        } = this.props
        return (
            <S.CheckFormTopBar>
                <S.StyledRow>
                    <S.StyledContainer>
                        {checksTotalAmount &&
                            <React.Fragment>
                                <S.StyledLabel>
                                    Next Check Number
                                </S.StyledLabel>
                                <S.StyledInput
                                    defaultValue={defaultReferenceNumber}
                                    disabled
                                    onChange={this.saveTextAndDebounce}
                                    placeholder={`e.g. "245"`}
                                >
                                </S.StyledInput>
                                {!this.state.validNextCheckInput && (
                                    <div>
                                        Next Check Number is invalid
                                    </div>
                                )}
                            </React.Fragment>
                        }
                    </S.StyledContainer>
                    <S.StyledContainer>
                        {checksTotalAmount &&
                            <React.Fragment>
                                <S.StyledLabel>
                                    Checks Total Amount
                                </S.StyledLabel>
                                <S.StyledColoredValue> 
                                    {checksTotalAmount}
                                </S.StyledColoredValue>
                            </React.Fragment>
                        }
                    </S.StyledContainer>
                    <S.StyledContainer>
                        <S.StyledLabel>
                            Bank Account Balance
                        </S.StyledLabel>
                        <S.StyledColoredValue>
                            { bankAccountAmount }
                        </S.StyledColoredValue>
                    </S.StyledContainer>
                </S.StyledRow>
            </S.CheckFormTopBar>
        )
    }
}

CheckFormTopBar.defaultProps = {
    checksTotalAmount: "$4,750",
    bankAccountAmount: "$457,632.76"
}

CheckFormTopBar.propTypes = {
    checksTotalAmount: PropTypes.string.isRequired,
    bankAccountAmount: PropTypes.string.isRequired,
}

export default CheckFormTopBar

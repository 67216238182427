import { Link } from "react-router-dom"
import styled from "styled-components"

// helpers
import { hexCodetoRGBA } from "app/static/frontend/imports/desktopHelperFunctions"
import { styleWithOrgDesignHelper } from "QuorumGrassroots/styled-components/helperFunctions"
import { overrideLinkHoverColor } from "QuorumGrassroots/widgets/styled-components/helperFunctions"

// constants
import { constants } from "QuorumGrassroots/constants"

const NoTextDecorationLinkComponent = styled(Link)`
    text-decoration: none;

    &:hover {
        text-decoration: none !important;
        background-color: ${hexCodetoRGBA(constants.defaultLightGrey, 0.25)};
        ${(props) => overrideLinkHoverColor(props)}
    }
`
export const NoTextDecorationLink = styleWithOrgDesignHelper(NoTextDecorationLinkComponent)

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/SummaryStatusBar/style"

const SummaryStatusBar = ({
    leftChildren,
    rightChildren,
}) => (
    <S.SummaryStatusBar data-auto-cy="CompoundSummaryStatusBar">
        { leftChildren }
        <S.RightContainer>
            { rightChildren }
        </S.RightContainer>
    </S.SummaryStatusBar>
)

SummaryStatusBar.defaultProps = {}

SummaryStatusBar.propTypes = {
    rightChildren: PropTypes.array,
    leftChildren: PropTypes.array,
}

export default SummaryStatusBar

import React, { useEffect, useRef, useState } from "react"
import { Loader } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"

import { Campaign } from "@/types/djangio"
import { useShareStoryVideo } from "QuorumGrassroots/services"
import * as S from "QuorumGrassroots/campaign-forms/components/StoryForm/VideoPreview/VideoPreview.styles"
import { VideoModal } from "QuorumGrassroots/campaign-forms/components/StoryForm/VideoModal/VideoModal"
import { RemoveVideoModal } from "QuorumGrassroots/campaign-forms/components/StoryForm/RemoveVideoModal/RemoveVideoModal"

interface VideoPreviewProps {
    gatherVoicesVideoId: string
    gatherVoicesUserId: string
    resetRecording: () => void
    campaign: Campaign
    t: (text: string) => string
}

export const POLLING_INTERVAL = 20000

export const VideoPreview = (props: VideoPreviewProps) => {
    const videoQuery = useShareStoryVideo(
        props.gatherVoicesVideoId,
        props.gatherVoicesUserId,
        props.campaign?.id.toString(),
    )
    const [isLoadingThumbnail, setIsLoadingThumbnail] = useState(true)

    const [isVideoModelOpen, videoModelActions] = useDisclosure()
    const [isRemoveVideoModalOpen, removeVideoModalActions] = useDisclosure()

    const pollingRef = useRef<NodeJS.Timeout | null>(null)

    const isThumbLoaded = !!videoQuery?.data?.thumbnail_path

    useEffect(() => {
        if (videoQuery.isError) {
            return
        }

        //Polls the endpoints until the video finishes processing
        if (videoQuery.isSuccess && !isThumbLoaded && !pollingRef.current) {
            pollingRef.current = setInterval(() => {
                videoQuery.refetch()
            }, POLLING_INTERVAL)
        } else if (!isThumbLoaded && pollingRef.current) {
            clearInterval(pollingRef.current)
            pollingRef.current = null
        }

        return () => {
            if (pollingRef.current) {
                clearInterval(pollingRef.current)
                pollingRef.current = null
            }
        }
    }, [isThumbLoaded, videoQuery])

    const handlePlay = () => videoModelActions.open()

    if (videoQuery.isLoading) {
        return <span>Loading...</span>
    }

    if (videoQuery.isError) {
        return <span>Error loading video</span>
    }

    return (
        <S.StyledVideoPreviewContainer>
            {isThumbLoaded ? (
                <S.StyledVideoPreview>
                    {isLoadingThumbnail ? (
                        <Loader type="Oval" color="gray" />
                    ) : (
                        <>
                            <S.PlayButton type="button" onClick={handlePlay}>
                                <i className="fa fa-play play-icon" aria-hidden="true" />
                            </S.PlayButton>
                            <S.CloseButton onClick={() => removeVideoModalActions.open()} size="lg" iconSize={20} />
                        </>
                    )}
                    <S.StyledThumbnail
                        src={videoQuery.data.thumbnail_path}
                        alt="Video thumbnail"
                        onLoad={() => setIsLoadingThumbnail(false)}
                    />
                </S.StyledVideoPreview>
            ) : (
                <S.StyledLoadingContainer>
                    <S.StyledIconContainer>
                        <i className="fa fa-video-camera" aria-hidden="true" />
                    </S.StyledIconContainer>
                    <S.StyledVideoDescription>
                        {props.t("campaign.grassroots_story.processing_video")}
                    </S.StyledVideoDescription>
                    <S.StyledSubtitle>{props.t("campaign.grassroots_story.submit_anyway")}</S.StyledSubtitle>
                </S.StyledLoadingContainer>
            )}

            <VideoModal
                isOpened={isVideoModelOpen}
                onClose={videoModelActions.close}
                videoURL={videoQuery.data.video_url}
            />

            <RemoveVideoModal
                isOpened={isRemoveVideoModalOpen}
                onClose={removeVideoModalActions.close}
                campaignId={props.campaign?.id?.toString()}
                onRemove={props.resetRecording}
                gatherVoiceVideoId={props.gatherVoicesVideoId}
                gatherVoiceUserId={props.gatherVoicesUserId}
                t={props.t}
            />
        </S.StyledVideoPreviewContainer>
    )
}

import React, { useState } from "react"
import PropTypes from "prop-types"

import Icon from "Components/Atoms/Icon"
import * as S from "Components/Molecules/Faq/style"

const Faq = ({content, title}) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <S.Faq data-auto-cy="MoleculeFaq">
            <Icon icon="question" iconFamily="fas" />
            <S.Content>
                <S.AboveFold onClick={() => setIsOpen(!isOpen)}>
                    {title}
                    <S.Caret isOpen={isOpen}>
                        <Icon icon="angle-down" iconFamily="far" />
                    </S.Caret>
                </S.AboveFold>
                <S.BelowFold
                    isVisible={isOpen}
                    dangerouslySetInnerHTML={{__html: content }}
                />
            </S.Content>
        </S.Faq>
    )
}

Faq.defaultProps = {}

Faq.propTypes = {
    content: PropTypes.string,
    title: PropTypes.string,
}

export default Faq

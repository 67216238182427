import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/SplashScreen/style"
import MediumCard from "Components/Molecules/MediumCard"
import Button from "Components/Molecules/Button"

const SplashScreen = ({splashTitle, splashParagraph, splashCards, primaryAction, secondaryAction}) => (
    <S.SplashScreen data-auto-cy="MoleculeSplashScreen">
        <S.Circle>
            <svg height="100%" width="100%" viewBox="0 0 100 100">
                <circle cx="50%" cy="50%" r="50%"/>
            </svg>
        </S.Circle>
        <S.Title>{splashTitle}</S.Title>
        <S.Text>{splashParagraph}</S.Text>
        <S.Cards>
            { splashCards && splashCards.map((card, index) => <MediumCard key={index} {...card}>{card.text}</MediumCard>)}
        </S.Cards>
        <S.Buttons>
            <S.SecondaryButton>
                <Button
                    type="secondary"
                    text="Learn More"
                    isGiant
                    onClick={secondaryAction}
                />
            </S.SecondaryButton>
            <S.PrimaryButton>
                <Button
                    text="Start Exploring"
                    isGiant
                    onClick={primaryAction}
                />
            </S.PrimaryButton>
        </S.Buttons>
    </S.SplashScreen>
)

SplashScreen.defaultProps = {}

SplashScreen.propTypes = {
    splashTitle: PropTypes.string,
    splashParagraph: PropTypes.string,
    splashCards: PropTypes.array,
    primaryAction: PropTypes.func,
    secondaryAction: PropTypes.func,
}

export default SplashScreen
import {
    getFontColorForBackgroundContrast,
    overrideRegistrationFormSpanBackground,
    styleInDesktopScreenSize,
} from "QuorumGrassroots/styled-components/helperFunctions"
import React from "react"
import styled from "styled-components"

import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"

export const StyledTextOverlaidHrWrapper = styleWithOrgDesign(styled.div`
    width: 100%;
    text-align: center;
    border-bottom: 2px solid ${(props) => props.organizationDesign.secondary_color};
    ${styleInDesktopScreenSize(`
        line-height: 0.1em;
    `)}
    margin: 25px 0 25px;

    span {
        background-color: ${(props) => props.organizationDesign.light_grey};
        font-size: 17px;
        padding: 0px ${(props) => (props.text ? 5 : 0)}px;
        ${(props) => overrideRegistrationFormSpanBackground(props)}
    }

    ${(props) =>
        props.isCampaignPage &&
        `
        margin-bottom: 9px;
        border-bottom: 2px solid ${getFontColorForBackgroundContrast(props.organizationDesign.primary_color)};


        span {
            font-size: 14px;
            color: ${getFontColorForBackgroundContrast(props.organizationDesign.primary_color)};
            background-color: ${props.organizationDesign.primary_color};
        }
    `}
`)

export const StyledTextOverlaidHr = ({ text, isCampaignPage }) => (
    <StyledTextOverlaidHrWrapper className="text-overlaid-hr-wrapper" text={text} isCampaignPage={isCampaignPage}>
        <span>{text}</span>
    </StyledTextOverlaidHrWrapper>
)

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/Dashboard/style"

const Dashboard = ({
    children,

    className,
    dataCy,
    editing,
    id,
})=> (
    <S.Dashboard
        data-auto-cy="MoleculeDashboard"
        data-cy={dataCy}
        id={id}
        editing={editing}
    >
        { children }
    </S.Dashboard>
)

Dashboard.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),

    className: PropTypes.string,
    dataCy: PropTypes.string,
    editing: PropTypes.bool,
    id: PropTypes.string,
}

export default Dashboard

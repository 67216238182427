import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"

import { Field } from "redux-form/immutable"
import { Col, Row } from "react-bootstrap"
import { createNumberMask } from "redux-form-input-masks"
import styled from "styled-components"

import { isFeatureEnabled } from "shared/featureflags/helperFunctions"
import { runUserJavascript, updateOrCreateStyleTagWithId } from "QuorumGrassroots/helperFunctions"
import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import UserInfoFormSection from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/index"
import CreditCardForm from "QuorumGrassroots/widgets/DonationForm/components/CreditCardForm"
import GridToggleField from "app/static/frontend/forms/components/GridToggleField"
import InputField from "app/static/frontend/forms/components/InputField"
import CheckBoxField from "app/static/frontend/forms/components/CheckBoxField"
import InputGroupField from "app/static/frontend/forms/components/InputGroupField"
import Page405 from "QuorumGrassroots/framework/components/405"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import { getCharityByEin } from "@/services/charities"
import { CharitySelect, charityApiToOption } from "QuorumGrassroots/widgets/DonationForm/components/CharitySelect"
import DonationTierLabel from "QuorumGrassroots/widgets/DonationForm/components/DonationTierLabel"
import { CheckMailingInstructions } from "QuorumGrassroots/widgets/DonationForm/components/CheckMailingInstructions"

import { AddressField } from "shared-web/forms/fields/AddressField"
import { StyledUpdateInfoAlert } from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/style"

const { TransactionMethodType } = DjangIO.app.ledger.types
const { PayrollDonationType } = DjangIO.app.grassroots.types

const PacCharityTitle = styled.p`
    font-size: 18px;
    font-weight: 500 !important;
    color: #000;
    margin: 0;
`

const DisclaimerTextContainer = styled.div`
    margin-top: 12px;
`

// validate that the credit card info is both complete and error free
const creditCardFormValidation = (value) => {
    if (value && value.error) {
        return value.error
    } else if (value && !value.complete) {
        return "Credit card information not complete"
    } else {
        return undefined
    }
}

// validate that additional information is no more than 255 characters
const additionalInfoValidation = (value) => {
    if (value && value.length > 255) {
        return "Additional information content cannot be more than 255 characters."
    }
    return undefined
}

// we want to merge new values into the credit card field's value, not overwrite
// this prevents needing to constantly get the Stripe and Card objects
const creditCardFormNormalize = (value, previousValue) => ({ ...previousValue, ...value })

const GridToggleSwitchField = (props) => (
    <GridToggleField
        allowNull={false}
        displayErrorWithoutTouch={false}
        pillWidth={6}
        choices={[
            { label: "Yes", key: "yes", value: true },
            { label: "No", key: "no", value: false },
        ]}
        {...props}
    />
)

export const DonationFormWidget = ({
    t,
    canAccess,
    conditionalGivingLevelsEnabled,
    userConditionalGivingLevel,
    amounts,
    initialValues,
    enabledPacMatch,
    pacMatchSelectLabel,
    pacMatchDescription,
    shouldDisplayPayrollDonationTypesField,
    donationMethod,
    allowedPayrollDonationTypesChoices,
    shouldDisplayPacMatchFields,
    donationPercentagesPayroll,
    isPayrollByPercentageDonation,
    organizationDesign,
    payrollFrequencyTypeObj,
    ...props
}) => {
    useEffect(() => {
        const { customJavascript, customCSS } = props

        if (customJavascript) {
            setTimeout(runUserJavascript(customJavascript))
        }

        if (customCSS) {
            updateOrCreateStyleTagWithId("CustomDonationFormCSS", customCSS)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const isConditionalGivingLevelValid =
            conditionalGivingLevelsEnabled &&
            userConditionalGivingLevel &&
            amounts &&
            isFeatureEnabled("ff_pac_conditional_giving")

        if (!isConditionalGivingLevelValid) return

        const amountMatchedGivingLevel = amounts?.find((amount) => {
            return amount.label?.trim().toLowerCase() === userConditionalGivingLevel.trim().toLowerCase()
        })

        const percentageMatchedGivingLevel = donationPercentagesPayroll?.find((donationPercentage) => {
            return donationPercentage.label?.trim().toLowerCase() === userConditionalGivingLevel.trim().toLowerCase()
        })

        if (amountMatchedGivingLevel && !isPayrollByPercentageDonation) {
            if (donationMethod === TransactionMethodType.payroll.value) {
                props.change("donationAmount", amountMatchedGivingLevel.value.value)
                props.change("donationAmountPerYear", amountMatchedGivingLevel.value.originalValue)
            } else {
                props.change("donationAmount", amountMatchedGivingLevel.value)
                props.change("donationAmountPerYear", amountMatchedGivingLevel.value)
            }
        }

        if (percentageMatchedGivingLevel) {
            props.change("donation_percentage_payroll", percentageMatchedGivingLevel)
            props.change("percentage_label", percentageMatchedGivingLevel.percentage_label)
            props.change("payroll_decimal_value", percentageMatchedGivingLevel.percentage_decimal_value)
        }
    }, [
        conditionalGivingLevelsEnabled,
        userConditionalGivingLevel,
        amounts,
        donationPercentagesPayroll,
        isPayrollByPercentageDonation,
        donationMethod,
    ])

    const [isInitialCharityLoading, setIsInitialCharityLoading] = useState(true)

    useEffect(() => {
        const charityEin = initialValues.get("pac_charity_ein")
        const charityName = initialValues.get("pac_charity_name")
        const additionalInfo = initialValues.get("pac_charity_additional_info")

        if (enabledPacMatch && isInitialCharityLoading) {
            if (charityEin) {
                getCharityByEin(charityEin).then((ein) => {
                    props.change(
                        "charity",
                        ein
                            ? charityApiToOption(ein)
                            : {
                                  label: `${charityName || "Unknown Charity"} (${charityEin})`,
                                  value: charityEin,
                                  invalid: true,
                              },
                    )
                    setIsInitialCharityLoading(false)
                })
            }

            if (additionalInfo) {
                props.change("pac_charity_additional_info", additionalInfo)
            }
        } else {
            setIsInitialCharityLoading(false)
        }
    }, [initialValues, enabledPacMatch, isInitialCharityLoading])

    useEffect(() => {
        if (donationMethod !== TransactionMethodType.payroll.value) {
            props.change("payrollDonationType", null)
            return
        }

        if (
            !shouldDisplayPayrollDonationTypesField &&
            allowedPayrollDonationTypesChoices &&
            allowedPayrollDonationTypesChoices.length
        ) {
            props.change("payrollDonationType", allowedPayrollDonationTypesChoices[0].value)
            return
        }

        props.change("payrollDonationType", PayrollDonationType.amount.value)
    }, [donationMethod, shouldDisplayPayrollDonationTypesField, allowedPayrollDonationTypesChoices])

    const renderBillingAddressFields = () => (
        <Row>
            <Col md={12}>
                <Field component={AddressField} name="input_address" placeholder="Billing Address" />
            </Col>
        </Row>
    )

    const renderPacMatchDescription = (description) =>
        description && (
            <div>
                <div className="pac-match-description-text" dangerouslySetInnerHTML={{ __html: description }} />
            </div>
        )

    const normalizePayrollDonationTypeChoice = (formValue) => {
        if (!formValue || !formValue.value || isNaN(formValue.value)) return null

        return Number(formValue.value)
    }

    const amountChoices = useMemo(() => {
        if (!amounts) return []

        return amounts.map((amount, index) => ({
            value: amount.value,
            label: (
                <DonationTierLabel
                    donationMethod={donationMethod}
                    conditionalGivingLevelLabel={amount.label}
                    value={donationMethod === TransactionMethodType.payroll.value ? amount.value.value : amount.value}
                    index={index}
                    tierType="amount"
                    frequencyLabel={payrollFrequencyTypeObj.label}
                />
            ),
        }))
    }, [amounts, donationMethod])

    const donationPercentagesPayrollChoices = useMemo(() => {
        if (!donationPercentagesPayroll) return []

        return donationPercentagesPayroll.map((donationPercentage, index) => ({
            value: donationPercentage,
            label: (
                <DonationTierLabel
                    conditionalGivingLevelLabel={donationPercentage.label}
                    value={donationPercentage.percentage_label}
                    index={index}
                    frequencyLabel={payrollFrequencyTypeObj.label}
                />
            ),
        }))
    }, [donationPercentagesPayroll])

    const appendNewActionCenterClass = (className) =>
        isFeatureEnabled("ff_pac_action_center_new_design") ? className : ""

    const isSingleChoice = props.allowedDonationMethodChoices && props.allowedDonationMethodChoices.length === 1

    const renderMain = () => (
        <WidgetStyleWrapper
            className={"donation-form-widget " + appendNewActionCenterClass("new-action-center")}
            md={props.colWidth}
            useWidgetStyling={props.useWidgetStyling}
            title={props.title}
        >
            <UserInfoFormSection registrationPageIds={props.listDonationFormId} t={t} showOnlyUnfilledFields={false} />
            <Field
                component={GridToggleField}
                name="donationMethod"
                label={props.donateMethodPrompt}
                choices={props.allowedDonationMethodChoices}
                className={
                    appendNewActionCenterClass("new-action-center__toggle-button--inline") +
                    (isSingleChoice
                        ? " " + appendNewActionCenterClass("new-action-center__toggle-button--single-option")
                        : "")
                }
                pillWidth={6}
                allowNull={false}
            />
            {shouldDisplayPayrollDonationTypesField && (
                <Field
                    component={InputGroupField}
                    name="payrollDonationType"
                    choices={allowedPayrollDonationTypesChoices}
                    className={appendNewActionCenterClass("new-action-center__radio-button-group")}
                    normalize={normalizePayrollDonationTypeChoice}
                    pillWidth={6}
                    allowNull={false}
                />
            )}
            {isPayrollByPercentageDonation &&
                donationPercentagesPayrollChoices &&
                donationPercentagesPayrollChoices.length && (
                    <Field
                        component={GridToggleField}
                        name="donation_percentage_payroll"
                        label={props.donateAmountPrompt}
                        choices={donationPercentagesPayrollChoices}
                        customOnChange={(donationPercentagePayroll) => {
                            props.change("donation_percentage_payroll", donationPercentagePayroll)
                            props.change("percentage_label", donationPercentagePayroll.percentage_label)
                            props.change("payroll_decimal_value", donationPercentagePayroll.percentage_decimal_value)
                        }}
                        className={appendNewActionCenterClass("new-action-center__toggle-button--block")}
                        pillWidth={3}
                        allowNull={false}
                    />
                )}
            {!isPayrollByPercentageDonation && (
                <>
                    <Field
                        component={GridToggleField}
                        name="donationAmount"
                        customOnChange={(amount) => {
                            if (donationMethod === TransactionMethodType.payroll.value) {
                                props.change("donationAmount", amount.value)
                                props.change("donationAmountPerYear", amount.originalValue)
                            } else {
                                props.change("donationAmount", amount)
                                props.change("donationAmountPerYear", amount)
                            }
                        }}
                        label={props.donateAmountPrompt}
                        organizationDesign={organizationDesign}
                        className={appendNewActionCenterClass("new-action-center__toggle-button--block")}
                        choices={amountChoices}
                        pillWidth={3}
                        allowNull={false}
                        displayErrorWithoutTouch={false}
                    />
                    {props.donationAmountOther && (
                        <Field
                            component={InputField}
                            name="donationAmountCustom"
                            placeholder="$"
                            dataCy="donation-amount-other"
                            displayErrorWithoutTouch={false}
                            {...createNumberMask({
                                decimalPlaces: 2,
                                multiplier: 100,
                                prefix: "$",
                            })}
                        />
                    )}
                </>
            )}

            {props.shouldDisplayCreditCardFields && props.stripeApiKey && (
                <div>
                    <br />
                    {renderBillingAddressFields()}
                    <br />
                    <Field
                        component={CreditCardForm}
                        field="creditCardForm"
                        name="creditCardForm"
                        normalize={creditCardFormNormalize}
                        validate={creditCardFormValidation}
                        stripeApiKey={props.stripeApiKey}
                    />
                    <br />
                </div>
            )}

            {props.shouldDisplayCheckMailingInstructions && (
                <CheckMailingInstructions donationFormId={props.donationFormId} />
            )}

            {shouldDisplayPacMatchFields && (
                <div className={appendNewActionCenterClass("new-action-center--pac-charitable-match")}>
                    <PacCharityTitle>PAC charitable match</PacCharityTitle>
                    <div className={appendNewActionCenterClass("new-action-center__section")}>
                        {renderPacMatchDescription(pacMatchDescription)}
                        {!isFeatureEnabled("ff_pac_action_center_new_design") && <br />}
                    </div>
                    <Field
                        name="charity"
                        label={pacMatchSelectLabel || "PAC Match Charity"}
                        placeholder="Enter at least 3 characters of the charity name"
                        component={CharitySelect}
                        organizationDesign={organizationDesign}
                        className={appendNewActionCenterClass("new-action-center__select")}
                        validate={validateCharity}
                        isLoading={isInitialCharityLoading}
                        isClearable
                    />
                    <Field
                        name="pac_charity_additional_info"
                        label="Additional Information"
                        placeholder="E.g. name a specific program to receive charity"
                        component={InputField}
                        validate={additionalInfoValidation}
                    />
                    <Field
                        name="pac_charity_anonymous"
                        component={CheckBoxField}
                        organizationDesign={organizationDesign}
                        className={appendNewActionCenterClass("new-action-center__checkbox")}
                        buttonText="I want to remain anonymous"
                        dataCy="donation-form-anonymous"
                    />
                </div>
            )}
            <div
                className={appendNewActionCenterClass(
                    "new-action-center__section new-action-center__terms-and-conditions",
                )}
            >
                {isFeatureEnabled("ff_pac_action_center_new_design") && (
                    <div class="control-label form-label new-action-center">Terms and conditions</div>
                )}
                {props.disclaimerText && (
                    <DisclaimerTextContainer className="disclaimer">
                        <div className="disclaimer-text" dangerouslySetInnerHTML={{ __html: props.disclaimerText }} />
                        {!isFeatureEnabled("ff_pac_action_center_new_design") && <br />}
                    </DisclaimerTextContainer>
                )}
                {!isFeatureEnabled("ff_pac_action_center_enhancements_terms_and_conditions") ? (
                    <Field
                        name="agreeTermsConditions"
                        component={CheckBoxField}
                        organizationDesign={organizationDesign}
                        className={appendNewActionCenterClass("new-action-center__checkbox")}
                        buttonText="I agree to terms and conditions" // TODO: i18n
                        dataCy="donation-form-terms-agree"
                    />
                ) : (
                    isFeatureEnabled("ff_pac_action_center_enhancements_terms_and_conditions") &&
                    props.termsAndConditionsSwitch && (
                        <Field
                            name="agreeTermsConditions"
                            component={CheckBoxField}
                            organizationDesign={organizationDesign}
                            className={appendNewActionCenterClass("new-action-center__checkbox")}
                            buttonText="I agree to Terms and Conditions" // TODO: i18n
                            dataCy="donation-form-terms-agree"
                        />
                    )
                )}
            </div>
            {!isFeatureEnabled("ff_pac_action_center_new_design") && <br />}
            <div className={appendNewActionCenterClass("new-action-center__button-wrapper")}>
                <StyledButton
                    onClick={props.handleSubmit}
                    disabled={props.submitting}
                    className={appendNewActionCenterClass("new-action-center__button")}
                    type="submit"
                    data-cy="donation-form-submit"
                    shouldUseAutoColor
                >
                    {
                        props.submitting ? t("form.submitting") : props.submitButtonText || "Donate Now" // TODO: i18n
                    }
                </StyledButton>
            </div>
        </WidgetStyleWrapper>
    )

    if (canAccess) {
        return renderMain()
    } else {
        return <Page405 errorHtml={props.cannotAccessMessage} compact />
    }
}

DonationFormWidget.propTypes = {
    donationFormId: PropTypes.number.isRequired,
    allowedDonationMethodChoices: PropTypes.array.isRequired,
    allowedPayrollDonationTypesChoices: PropTypes.array.isRequired,
    cannotAccessMessage: PropTypes.string,
    donateMethodPrompt: PropTypes.string.isRequired,
    donateAmountPrompt: PropTypes.string.isRequired,
    amounts: PropTypes.object,
    colWidth: PropTypes.number,
    donationAmountOther: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    listDonationFormId: PropTypes.array.isRequired,
    shouldDisplayCreditCardFields: PropTypes.bool.isRequired,
    shouldDisplayPayrollDonationTypesField: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    title: PropTypes.string,
    useWidgetStyling: PropTypes.bool,
    conditionalGivingLevelsEnabled: PropTypes.bool,
    change: PropTypes.func.isRequired,
    selectShouldDisplayPacMatchFields: PropTypes.bool.isRequired,
    shouldDisplayCheckMailingInstructions: PropTypes.bool.isRequired,
    customJavascript: PropTypes.string,
    customCSS: PropTypes.string,
    termsAndConditionsSwitch: PropTypes.bool,
    customAfterRegistrationJavascript: PropTypes.string,
    organizationDesign: PropTypes.object,
}

DonationFormWidget.defaultProps = {
    useWidgetStyling: true,
}

const validateCharity = (charity) =>
    charity?.invalid
        ? "This Charity is no longer available for a match. Please choose a new charity to receive the match."
        : null

export default DonationFormWidget

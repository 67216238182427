import axios from "axios"
import Immutable from "immutable"

/*
This is all of the code for djangio that is specific to
quorum-site, such as our "action" layer
 */
import Manager from "shared/djangio/Manager"
import Instance from "shared/djangio/Instance"
import Model from "shared/djangio/Model"
import { traverseAndMatch } from "shared/imports/sharedHelperFunctions"

Manager.prototype.bulk_update = function (kwargs) {
    const { objects, deleted_objects } = kwargs

    if (deleted_objects) {
        this.updateKwargs = { objects, deleted_objects }
    } else {
        this.updateKwargs = { objects }
    }
    return this
}

Manager.prototype.all = function () {
    return this.limit(0)
}

Manager.prototype.delete = function (ids) {
    return this.bulk_update({
        objects: [],
        deleted_objects: ids.map((id) => this.model.resourceUriFromId(id)),
    })
}

Manager.prototype.DELETE = function (...args) {
    return this.PATCH(...args)
}

Manager.prototype.action = function action(someAction, params = {}) {
    return this.filter(params).filter({ [someAction]: true })
}

Manager.prototype.frequency = function frequency(someFrequency) {
    return this.action("frequency").action(someFrequency)
}

Manager.prototype.count = function count(only = false) {
    return only ? this.action("count_only") : this.action("count")
}

Manager.prototype.clone = function clone() {
    return (
        this.resource.objects // generate a new manager
            // and apply all filters.
            .get(this.primaryKey)
            .filter(this.filterKwargs)
            .create(this.createKwargs)
            .update(this.updateKwargs)
    )
}

Manager.prototype.csv = function csv() {
    return this.filter({ format: "csv" })
}
Manager.prototype.pdf = function pdf() {
    return this.filter({ format: "pdf" })
}
Manager.prototype.docx = function docx() {
    return this.filter({ format: "docx" })
}
// invokes excel_download in app/api/classes.py
Manager.prototype.xlsx = function xlsx() {
    return this.filter({ format: "xlsx" })
}
/**
 * Download a file
 * @param {Object} optionalArgs - optionalArgs to pass to the backend, accessible via request.GET
 * @returns {Promise} - a promise indicating resolution of the request
 * Note that while this function returns a promise, it is a little different than typical promises.
 * handling this promise in a try / catch with await will work as normal, but if you intend to use
 * .then/.catch on the promise, that will fail. This promise only accepts .done()/.fail() as promise
 * resolvers
 */
Manager.prototype.download = function download(optionalArgs = {}) {
    const url = this.buildUrl({ stripUndefined: true, useCustomParams: true }) // tells it to strip out all undefined values
    return $.fileDownload(url, { data: optionalArgs })
}

Manager.prototype.estimated_count = function estimated_count() {
    return this.action("estimated_count")
}

// only serialize the supplied fields
Manager.prototype.fields = function fields(only_fields = []) {
    return only_fields.length ? this.filter({ only_fields }) : this
}

// do a full dehydrate of the field with the specified key. If key unspecified
// will full dehydrate all fields.
Manager.prototype.full_dehydrate = function (key = false) {
    if (this.filterKwargs.only_fields) {
        delete this.filterKwargs.only_fields
    }
    return key ? this.action(`full_dehydrate_${key}`) : this.action("full_dehydrate")
}

Model.prototype.idFromResourceUri = function idFromResourceUri(uri) {
    const id = uri && uri.replace ? uri.replace(this.endpoint, "").replace("/", "") : uri

    return Number.isNaN(Number(id)) ? id : parseInt(id)
}

Model.prototype.resourceUriFromId = function resourceUriFromId(id) {
    // convert an id to a resource uri
    return id === undefined || // required by campaign uri`s handled in donation_form_spec
        (id.includes && id.includes("/")) // required by the interaction logger form, which
        ? id // passes in an endpoint as an id (perhaps erroneously but for backwards compatibility)
        : `${this.endpoint}${id}/`
}

Model.prototype.foreignKey = function foreignKey(id) {
    // since POST and PATCH requests return a "mismatched type" error if
    // you supply the id instead of the resource uri for a foreign key field,
    // support creating objects like {person: DjangIO.Person.objects.foreignKey(personId)}
    return this.resourceUriFromId(id)
}

Model.prototype.isInstance = function isInstance(obj) {
    // is the provided object an
    // instance of this model (checks resource_uri)
    return obj.resource_uri ? obj.resource_uri.includes(this.endpoint) : false
}

Model.prototype.associatedQDT = function associatedQDT() {
    return DjangIO.app.models.QuorumDataType.items().find((item) => item.data_model === this.data_model)
}

/**
 * This function returns whether this model is archivable
 * @param {Type} argument - a description
 * @returns {Type} - Something
 */
Model.prototype.isArchivable = function isArchivable() {
    return Boolean(this._meta.get_field("archived"))
}

Instance.prototype.resourceFromDataType = function resourceFromDataType(dataType) {
    return this.modelAtPath(this.app.models.QuorumDataType.by_value(dataType).data_model)
}

/**
 * This function returns the new resource (from newapi.py) corresponding
 * to a datatype and returns the old resource if new resource not available.
 * @param  {Number} dataType [QDT value]
 * @return {Object}          [resource]
 */
Instance.prototype.newResourceFromDataType = function newResourceFromDataType(dataType) {
    const qdt = this.app.models.QuorumDataType.by_value(dataType)
    // check if qdt has a proxy to the new api, o.w. default to the old behavior
    const path = qdt.proxy_resource_data_model || qdt.data_model
    return this.modelAtPath(path)
}

Instance.prototype.resourceFromRelatedModel = function resourceFromRelatedModel(relatedModel) {
    return this.modelAtPath([relatedModel.__module__, relatedModel.__name__].join("."))
}

Instance.prototype.resourceFromUrl = function (url) {
    // TODO: MAKE THIS ACTUALLY WORK with regex
    console.log("resourceFromUrl!")
    console.log(url)
    if (typeof url === "string") {
        const fakeTestThisShit = `${url
            .split("/")
            .splice(0, url.split("/").length - 2)
            .join("/")}/`
        return traverseAndMatch(this, fakeTestThisShit)
    }
    return undefined
}

// comparator for sorting objects by a key
// if the first character is a - sign, reverse the sort
Instance.prototype.sortByKey = function sortByKey(key) {
    let sortOrder = 1
    if (key.startsWith("-")) {
        sortOrder = -1
        key = key.substr(1)
    }
    return function (a, b) {
        const result = a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0
        return result * sortOrder
    }
}

/**
 * compute the length of either an immutable list or JavaScript array, depending on the type
 *
 * @param {List or Array} listOrArray - an Immutable List or JavaScript Array
 * @returns {integer} returns the size of the list or the length of an Array
 * or -1 if it is neither
 */
Instance.listOrArrayLength = function (listOrArray) {
    switch (listOrArray.constructor) {
        case Array:
            return listOrArray.length
        case Immutable.List:
            return listOrArray.size
        default:
            return -1
    }
}

/*
Convenient function to sort enum items
*/
Instance.prototype.valueSort = function valueSort(a, b) {
    return a.value - b.value
}

Instance.prototype.valueSortDescending = function valueSort(a, b) {
    return b.value - a.value
}

// Sort on the value key in the case of strings
// ex a.value and b.value are strings
Instance.prototype.stringValueSort = function stringValueSort(a, b) {
    const nameA = a.label.toUpperCase() // ignore upper and lowercase
    const nameB = b.label.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
        return -1
    }
    if (nameA > nameB) {
        return 1
    }

    // names must be equal
    return 0
}

Instance.prototype.fieldValueSort = ({ a, b, field }) => {
    const nameA = a[field].toUpperCase() // ignore upper and lowercase
    const nameB = b[field].toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
        return -1
    }
    if (nameA > nameB) {
        return 1
    }

    // names must be equal
    return 0
}

Instance.prototype.orderSort = function orderSort(a, b) {
    if (a.order != null && b.order != null) {
        return a.order - b.order
    }

    if (a.order != null) {
        return -1
    }

    return 1
}

Instance.prototype.labelSort = function labelSort(a, b) {
    return a.label.localeCompare(b.label)
}

Instance.prototype.findField = function findField(field, dataType) {
    if (field) {
        const resource = this.resourceFromDataType(dataType)
        if (field.includes("__") && !resource._meta.get_field(field)) {
            const [relatedField, actualField, ...badNestedLookups] = field.split("__")
            if (badNestedLookups.length) {
                console.warn(`Nested lookups are not supported by Pie publication system. Improper field ${field}`)
            }
            return this.resourceFromRelatedModel(resource._meta.get_field(relatedField).related_model)._meta.get_field(
                actualField,
            )
        }
        return resource._meta.get_field(field)
    }
}

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
axios.defaults.xsrfCookieName = "csrftoken"

axios.interceptors.request.use((config) => {
    // in order to maintain the proper function
    // of axios, which can contain just a url and
    // not a config, we need to make some adjustments
    // to this interceptor
    const params = config.params || {}
    const excludeKwargs = params.exclude || {}

    // // remove any entries that are undefined
    Object.entries(params)
        // also delete exclude if it is there
        .concat([["exclude", undefined]])
        .map(([key, value]) => value === undefined && delete params[key])

    // // add decode enums and count
    return {
        ...config,
        params: { decode_enums: false, count: false, exclude: JSON.stringify(excludeKwargs), ...params },
    }
})

import { reduxForm } from "redux-form/immutable"

import { submitLogin, submitLoginSuccess } from "QuorumGrassroots/widgets/action-creators"

import LoginForm from "QuorumGrassroots/widgets/LoginRegistration/components/LoginForm"
import { withRouter } from "QuorumGrassroots/withRouter"

const submit = (values, dispatch) => {
    return dispatch(submitLogin(values))
}

const ReduxLoginForm = reduxForm({
    form: "login",
    onSubmit: submit,
    onSubmitSuccess: submitLoginSuccess,
})(withRouter(LoginForm))

export default ReduxLoginForm

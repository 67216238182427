import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Atoms/Input/style"

class Input extends React.Component {
    state = {
        hasSearched: false,
        value: ""
    }

    render() {
        return (
            <S.InputContainer
                // necessary to style this in the ListWidget
                // https://styled-components.com/docs/advanced#styling-normal-react-components
                className={this.props.className}
                data-auto-cy="AtomInput"
            >
                {
                    this.props.label &&
                        <S.Label>{this.props.label}:</S.Label>
                }
                <S.Input>
                    <S.InputText
                        disabled={this.props.disabled}
                        onChange={event => {
                            // if the user has previously searched
                            // and they have now deleted their search with backspace,
                            // we want to automatically invoke the onClick event passed to the DS input
                            // by passing the empty string to it

                            // in Dashboards, this means that we will automatically refresh the ListWidget
                            // once the search term has been deleted from the input
                            // (otherwise, the user would need to press enter again,
                            // which is a UX antipattern according to Grace)
                            if (
                                this.state.hasSearched &&
                                event.target.value === "" && this.state.value !== ""
                            ) {
                                this.props.onClick(
                                    event,
                                    event.target.value,
                                )
                                this.setState({
                                    hasSearched: false
                                })
                            }

                            this.setState({
                                value: event.target.value
                            })
                        }}
                        onKeyPress={event => {
                            if (
                                event.key === "Enter" &&
                                this.props.onClick &&
                                !this.props.disabled
                            ) {
                                this.props.onClick(
                                    event,
                                    this.state.value,
                                )
                                this.setState({
                                    hasSearched: true
                                })
                            }
                        }}
                        placeholder={this.props.placeholder}
                        type="text"
                    />
                    {
                        this.props.submitText &&
                            <S.InputSubmit
                                onClick={event => {
                                    if (
                                        this.props.onClick &&
                                        !this.props.disabled
                                    ) {
                                        this.props.onClick(
                                            event,
                                            this.state.value,
                                        )
                                    }
                                }}
                            >
                                {this.props.submitText}
                            </S.InputSubmit>
                    }
                </S.Input>
            </S.InputContainer>
        )
    }
}

Input.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),

    disabled: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    placeholder: PropTypes.string,
    submitText: PropTypes.string,
}

export default Input

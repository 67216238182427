import styled, { css } from "styled-components"

import * as colors from "Components/colors"

import {
    ButtonContainer,
    Text,
} from "Components/Molecules/Button/style"
import { EmptyState } from "Components/Molecules/EmptyState/style"
import { OptionsSection } from "Components/Compounds/TopBar/style"
import {
    WidgetDescription,
    WidgetContent,
    WidgetFallback,
    WidgetEmpty,
    WidgetFooter,
} from "Components/Compounds/Widget/style"

export const Dashboard = styled.div`
    background-color: white;

    ${EmptyState} {
        @media only screen and (max-width: 850px) {
            zoom: 0.9;
        }

        @media only screen and (max-width: 700px) {
            zoom: 0.75;
        }
    }

    ${props => (props.editing) && css`
        // rendered in Dashboard => TopBar
        ${OptionsSection} {
            @media only screen and (max-width: 1200px) {
                ${ButtonContainer} ${Text} {
                    display: none;
                }
            }
        }
    `}

    // not calc(100vh - 60px) because we want there to be some overflow on the bottom
    // this lets the user move/resize widgets outside of the
    // standard window on initial mount of a new/empty Dashboard
    height: 100vh;
    position: relative;

    // https://github.com/STRML/react-grid-layout/blob/master/examples/example-styles.css
    // styling the react-grid-layout (RGL) component in app/static/frontend/dashboards/components/Dashboard
    .react-grid-layout {
        background-color: white;
        position: relative;
        transition: height 100ms ease;

        .react-grid-item {
            box-sizing: border-box;
            transition: all 100ms ease;
            transition-property: left, top;
            border-radius: 4px;

            .react-resizable-handle {
                display: none;
            }

            ${props => (props.editing) && css`
                .react-resizable-handle {
                    display: initial;
                }

                &:hover {
                    cursor: pointer;
                }

                &:active {
                    background: #dadada;
                    cursor: move;
                }
            `}

            &.static {
                background: ${colors.QuorumGrey1};
            }

            &.cssTransforms {
                transition-property: transform;
            }

            &.resizing {
                will-change: width, height;
                z-index: 1;
            }

            &.react-draggable-dragging {
                transition: none;
                z-index: 3;
                will-change: transform;
            }

            &.editing {
                ${WidgetDescription},
                ${WidgetContent},
                ${WidgetFallback},
                ${WidgetEmpty},
                ${WidgetFooter} {
                    opacity: 0.5;
                }
            }

            &.dropping {
                visibility: hidden;
            }

            &.react-grid-placeholder {
                background: ${colors.QuorumBlue};
                opacity: 0.2;
                transition-duration: 100ms;
                z-index: 2;
                user-select: none;
            }

            & > .react-resizable-handle {
                cursor: se-resize;
                position: absolute;
                width: 50px;
                height: 50px;
                bottom: 0;
                right: 0;
                z-index: 1;
            }

            .minMax {
                font-size: 12px;
            }

            .add {
                cursor: pointer;
            }
        }
    }
`

import { createSelector, createStructuredSelector } from "reselect"
import { formValueSelector } from "redux-form/immutable"
import { selectIsLimitedToTeamsEnabled } from "shared/userdata/userdata-selectors"
import { selectLedgerSettingsListIds } from "shared/pac/selectors"
import { CONTACT_FORM_KEY, ORGANIZATION_FORM_KEY } from "shared/imports/sharedConstants"
import { idFromResourceUri } from "shared/imports/sharedHelperFunctions"

export const selectCustomRegions = (state) => state.formData.get("customRegions").toJS()
export const selectCustomRegionsLoading = (state) => state.formData.get("isLoading")

export const regionFieldConnection = createStructuredSelector({
    customRegions: selectCustomRegions,
    isLoading: selectCustomRegionsLoading,
})

// SelectField selectors

const selectInputValueFromProps = (_, props) => props.input.value

// this selector is responsible for formatting inputValue for SelectField, depending on a set of conditions
// The type of value can dictate where in the application SelectField is being rendered as well
// as if the value is being passed to prefill a formvalue
// for example, the inputValue passed to populate the Teams Select when initializing the
// Interaction Logger will be an Immutable List, but when option is clicked from the select,
// the inputValue passed will be an Array
export const selectValueForSelectField = createSelector(selectInputValueFromProps, (inputValue) => {
    // short-circuit if inputValue is falsey
    if (!inputValue) {
        return inputValue
    }

    // handle the case where inputValue is an Immutable List or Map
    if (inputValue.toJS) {
        // if:
        // - the contents of inputValue are URI strings, assume they are resource_uri's
        // - the contents of inputValue are ids. For example, the Project selector in the Interaction Logger form
        // initially loads with
        // - inputValue is a Map, and should be converted to an Object
        return inputValue.toJS()
    }

    // if the inputValue is not Immutable, no transformations are necessary
    // This case occurs when the Select is rendering enums or inputValue is already
    // an Object or Array
    return inputValue
})

export const selectFieldConnection = createStructuredSelector({
    value: selectValueForSelectField,
})

// ------ Begin PublicAffairsForm Selectors  ----- //
const selectTagOwnerFromProps = (_, props) => props.tagOwner

export const selectIsContactForm = createSelector(
    selectTagOwnerFromProps,
    (tagOwner) => tagOwner === DjangIO.app.models.QuorumDataType.supporter.value,
)

export const selectIsPublicOrganizationForm = createSelector(
    selectTagOwnerFromProps,
    (tagOwner) => tagOwner === DjangIO.app.models.QuorumDataType.public_organization.value,
)

export const selectOrganizationFormOrgSlice = (state) => state[ORGANIZATION_FORM_KEY].get("organization")
export const selectPacEntityType = createSelector(
    selectOrganizationFormOrgSlice,
    (organization) => organization && organization.size > 0 && organization.get("pac_org_type"),
)

export const selectContactFormSupporterSlice = (state) => state[CONTACT_FORM_KEY].get("supporter")
export const selectPacEligibilitiesIds = createSelector(
    selectContactFormSupporterSlice,
    (supporter) =>
        supporter &&
        supporter.size > 0 &&
        supporter.get("pac_eligibilities") &&
        supporter
            .get("pac_eligibilities")
            .map((uri) => idFromResourceUri(uri))
            .toJS(),
)

export const publicAffairsConnection = createStructuredSelector({
    dataType: selectTagOwnerFromProps,
    isContactForm: selectIsContactForm,
    isLimitedToTeamsEnabled: selectIsLimitedToTeamsEnabled,
    isPublicOrganizationForm: selectIsPublicOrganizationForm,
    ledgerSettingsList: selectLedgerSettingsListIds,
    pacEligibilitiesIds: selectPacEligibilitiesIds,
    pacOrgType: selectPacEntityType,
})
// ------ End PublicAffairsForm Form Selectors  ----- //

export const selectReduxFormSearchifySelectFieldValue = createSelector(
    (state, props) => formValueSelector(props.formKey)(state, props.name),
    (searchifyValue) =>
        searchifyValue && // mutli select
        ((searchifyValue.toJS && searchifyValue.toJS()) ||
            // single select
            searchifyValue),
)

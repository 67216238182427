import styled from 'styled-components'

import * as rules from "Components/rules"
import * as colors from "Components/colors"

export const OuterWrapper = styled.div`
    height: 100px;
    width: 100px;
    position: relative;
`

export const Wrapper = styled.div`
    height: 100px;
    width: 100px;
    position: relative;
`

export const Counter = styled.div`
    transform: rotate(-90deg);
    fill: none;
    height: 100%;
    width: 100%;
`

export const CircleTotal = styled.div`
    stroke: ${({ primaryColor }) => primaryColor};
`

export const CircleProgress = styled.div`
    stroke: ${({ primaryColor, secondaryColor }) => secondaryColor ? secondaryColor : primaryColor};
`

export const StyledCounterLabel = styled.div`
    color: ${colors.QuorumBlue};
    font-size: 16px;
    line-height: 22px;
    font-weight: 350;
    text-align: center;
    margin-bottom: 10px;
`

export const Fraction = styled.div`
    display: inline-block;
    text-align: center;
    padding: 0 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const CompletedTasks = styled.span`
    ${rules.SmallTitleMediumWeight}
    color: ${({ primaryColor, completeColor, isCompleted }) => isCompleted ? completeColor : primaryColor};
`

export const TotalTasks = styled.span`
    ${rules.SmallTitleThinWeight}
    color: ${({ primaryColor }) => primaryColor};
    border-top: ${({ primaryColor }) => `1px solid ${primaryColor};`};
    display: block;
`

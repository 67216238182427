import Immutable from "immutable"
import PropTypes from "prop-types"
import React from "react"
import { Col, Row } from "react-bootstrap"

import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import { StyledHorizontalRule } from "QuorumGrassroots/styled-components/components/StyledHorizontalRule"
import { StyledSubmitButton } from "QuorumGrassroots/styled-components/components/StyledSubmitButton"
import { StyledTextOverlaidHr } from "QuorumGrassroots/styled-components/components/StyledTextOverlaidHr"
import { FacebookLoginButton } from "QuorumGrassroots/widgets/LoginRegistration/components/FacebookLoginButton/index"
import { StyledFacebookButtonWrapper } from "QuorumGrassroots/widgets/LoginRegistration/components/FacebookLoginButton/style"
import { StyledSSOButton } from "QuorumGrassroots/widgets/LoginRegistration/components/style"
import UserInfoFormSection from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/index"

// we're keeping the form tag in order to easily be able to submit on pressing
// Enter.
const RegistrationForm = ({ t, ...props }) => (
    <div className="registration-form">
        {(props.canLoginWithFacebook || props.ssoUrl) && (
            <Row>
                {props.canLoginWithFacebook && (
                    <Col md={props.ssoUrl ? 6 : 12}>
                        <StyledFacebookButtonWrapper isCampaignPage={props.isCampaignPage}>
                            <FacebookLoginButton
                                disabled={props.submitting}
                                onLogin={(userData) => {
                                    props.onSubmit(Immutable.fromJS(userData), props.dispatch, props)
                                }}
                                buttonText={t("facebook.login.text")}
                                isCampaignPage={props.isCampaignPage}
                            />
                        </StyledFacebookButtonWrapper>
                    </Col>
                )}
                {props.ssoUrl && (
                    <Col md={props.canLoginWithFacebook ? 6 : 12}>
                        <StyledSSOButton
                            href={props.ssoUrl}
                            disabled={props.submitting}
                            isCampaignPage={props.isCampaignPage}
                        >
                            <i className="fa fa-sign-in" />
                            {t("registration.sso.login")}
                        </StyledSSOButton>
                    </Col>
                )}
                <Col md={12}>
                    <StyledTextOverlaidHr text={t("facebook.login.register")} isCampaignPage={props.isCampaignPage} />
                </Col>
            </Row>
        )}
        <form onSubmit={props.handleSubmit}>
            <UserInfoFormSection
                className="user-info-form-section"
                registrationPageIds={props.registrationPageIds}
                additionalSimpleFields={props.additionalSimpleFields}
                isCampaignPage={props.isCampaignPage}
                campaign={props.campaign}
                t={t}
            />
            {!props.isCampaignPage ? (
                <>
                    <StyledHorizontalRule />
                    <StyledButton
                        className="submit-button"
                        onClick={props.handleSubmit}
                        disabled={props.submitting}
                        type="submit"
                    >
                        {props.registrationSubmitButtonText}
                    </StyledButton>
                </>
            ) : (
                // Hide the Submit button for Multi-Message since it has it's own Submit All button
                !props.campaignIsOneClickRegistrationEnabled && (
                    <>
                        <StyledHorizontalRule isCampaignPage />
                        <StyledSubmitButton
                            className="ngg-submit-button"
                            onClick={props.handleSubmit}
                            disabled={props.submitting}
                            type="submit"
                            isCampaignPage
                            activateNGGTheme
                        >
                            <i className={"fa fa-pencil"} />
                            {props.registrationSubmitButtonText}
                        </StyledSubmitButton>
                    </>
                )
            )}
        </form>
    </div>
)

RegistrationForm.propTypes = {
    canLoginWithFacebook: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    registrationPageIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    colWidth: PropTypes.number,
    additionalSimpleFields: PropTypes.arrayOf(PropTypes.number),
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    ssoUrl: PropTypes.string,
    registrationSubmitButtonText: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    isCampaignPage: PropTypes.bool.isRequired,
}

RegistrationForm.defaultProps = {
    colWidth: undefined,
    ssoUrl: "",
}

export default RegistrationForm

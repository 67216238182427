import styled, { css } from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"


export const BigBoiSelects = styled.div`
    ${rules.CardStyle}
    ${rules.SmallText}

    width: 160px;
    height: 78px;
    padding: 10px;

    background-color: ${colors.White};
    border: none;

    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: ${colors.violet1};
        ${props => props.isSelected &&
            css`background-color: ${colors.violet2};`
        }
        cursor: pointer;
    }

    ${props => props.isSelected &&
        css`background-color: ${colors.violet2};`
    }
`

export const Text = styled.span`
    margin-left: ${({ icon }) => icon ? "12px" : "0px"};
    color: ${colors.unnamed222222};

    ${props => props.isSelected &&
        css`
            color: ${colors.violet8};
        `
    }
`

export const Icon = styled.span`
    color: ${colors.violet5};
    --fa-primary-color: ${colors.violet7};
    --fa-secondary-color: ${colors.violet5};

    ${props => props.isSelected &&
        css`
            color: ${colors.violet8};
            --fa-primary-color: ${colors.violet8};
            --fa-secondary-color: ${colors.violet8};
        `
    }
`

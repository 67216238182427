import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/DTVElement/style"
import * as rules from "Components/rules"
import * as helperFunctions from "utils/helperFunctions"
import { useTruncated, useWindowSize } from "utils/hooks"

import Button from "Components/Molecules/Button"
import Tooltip from "Components/Molecules/Tooltip"

// these constants are specific to this component and are manually
// derived estimates for a good number of characters to fit in the
// space given the style of the component
const MAX_SMALL_ELEMENT_CHARS = 72
const MAX_MEDIUM_ELEMENT_CHARS = 146

const SMALL_ELEMENT_INCREMENTS = 1
const MEDIUM_ELEMENT_INCREMENTS = 2
const MAX_ELEMENT_INCREMENTS = 4

const ELEMENT_CONTENT_BREAKPOINTS = [
    { characters: MAX_SMALL_ELEMENT_CHARS, increments: SMALL_ELEMENT_INCREMENTS },
    { characters: MAX_MEDIUM_ELEMENT_CHARS, increments: MEDIUM_ELEMENT_INCREMENTS },
    { characters: Number.MAX_SAFE_INTEGER, increments: MAX_ELEMENT_INCREMENTS },
]

const DTVElement= ({ button, collapseEmptySpace, isMinimal, gridWidth, subtitle, text, title, style, elementId, truncateText }) => {
    // Number of CSS grid columns that each element must be a multiple of
    // e.g.: Grids can hold a maximum of 4 minimal elements across a row, so
    //       if the grid has 13 columns, each minimal element should occupy 4
    //       grid columns
    const elementsPerRow = isMinimal ? rules.DTVMinimalElementsPerRow : rules.DTVElementsPerRow
    const gridIncrement = Math.floor(gridWidth / elementsPerRow)

    // Choose a width, relative to the width of the parent grid, for
    // this element to occupy; this width is chosen with a rough
    // approximation for the size of the content of the element
    // If we choose a width that is too small to contain the text when
    // rendered, the text will be truncated
    const increments = helperFunctions.breakpointEvaluator({
        breakpointsArray: ELEMENT_CONTENT_BREAKPOINTS,
        comparator: helperFunctions.textBreakpointComparator,
        item: text,
    }).increments

    const elementWidthInColumns = increments * gridIncrement

    // only minimal elements are allowed to grow to take more columns
    const columnSpan = isMinimal ? elementWidthInColumns : gridIncrement

    const isIE = helperFunctions.isUserIE11()

    // elements of the max size should not be truncated; all others should be
    // also truncate on Internet Explorer 11
    // LEG-1477: Add ability to opt-out of truncating text by supplying truncateText={false}.
    // TODO: Use babel/@preset-react instead of babel to allow `??`
    const shouldTruncateText = typeof truncateText !== "undefined" ? truncateText : (isIE || !isMinimal || (increments < MAX_ELEMENT_INCREMENTS))

    // "shouldTruncateText" differs from "isTruncated" in that "isTruncated"
    // will always be true when there is hidden overflow
    const [truncationRef, isTruncated] = useTruncated()

    const useIETooltip = isTruncated && isIE

    // Use the window size hook to force re-rendering when the window changes size
    // Allows the truncation of text to be re-measured on window resize, which often
    // causes elements to become smaller and truncated and therefore need tooltips
    useWindowSize()

    return (
        <S.Container
            data-auto-cy="MoleculeDTVElement"
            className={`dlc-parent-id-${elementId}`}
            isMinimal={isMinimal}
            columnSpan={columnSpan}
            useIETooltip={useIETooltip}
            collapseEmptySpace={collapseEmptySpace}
            style={style}
        >
            { isTruncated && !isIE &&
                <S.TooltipPositioner>
                    <Tooltip text={text}/>
                </S.TooltipPositioner>
            }
            { text && isIE &&
                <div>
                    <S.Tooltip>{text}</S.Tooltip>
                </div>
            }
            { title && <S.Title>{title}</S.Title> }
            { subtitle && <S.Subtitle>{subtitle}</S.Subtitle> }
            { text &&
                <S.Text
                    ref={truncationRef}
                    truncateText={shouldTruncateText}
                >
                        {text}
                </S.Text>
            }
            { button &&
                <S.ButtonContainer>
                    <Button
                        dataCy={button.dataCy}
                        leftIcon={button.leftIcon}
                        leftIconFamily={button.leftIconFamily}
                        onClick={button.onClickFn}
                        text={button.text}
                        type="secondary"
                    />
                </S.ButtonContainer>
            }
        </S.Container>
    )
}

DTVElement.defaultProps = {}

DTVElement.propTypes = {
    // props to for the button; no button will appear if this prop is absent
    button: PropTypes.shape({
        dataCy: PropTypes.string,
        leftIcon: PropTypes.string,
        leftIconFamily: PropTypes.string,
        onClickFn: PropTypes.func,
        text: PropTypes.string,
    }),
    // Removes minimum container height
    collapseEmptySpace: PropTypes.bool,
    // true if member of "minimal DTV" (removes border)
    isMinimal: PropTypes.bool,
    // the number of CSS columns in the containing grid, if contained by a DTVGrid
    gridWidth: PropTypes.number,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
}

export default DTVElement

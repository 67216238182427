import React, { Component } from "react"
import PropTypes from "prop-types"

import { writeSectionConfigs, minimumMessagesToShow } from "QuorumGrassroots/campaign-forms/write-form-constants"

import { StyledHorizontalRule } from "QuorumGrassroots/styled-components/components/StyledHorizontalRule"
import { StyledBox, StyledLargeBoldText, StyledSubheaderText } from "QuorumGrassroots/widgets/styled-components/styles"

import WriteMessageFormSection from "QuorumGrassroots/campaign-forms/components/WriteMessageFormSection"
import { GenericWriteForm } from "QuorumGrassroots/campaign-forms/components/ReusableForms/GenericWriteForm"

class CombinedCampaignMessageLayout extends Component {
    static propTypes = {
        uniqueWidgetId: PropTypes.string.isRequired,
        formName: PropTypes.string.isRequired,
        campaign: PropTypes.object.isRequired,
        combinedCampaignMessages: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                campaignType: PropTypes.number,
                messages: PropTypes.arrayOf(
                    PropTypes.shape({
                        message_id: PropTypes.string,
                    }),
                ),
            }),
        ),
        submitting: PropTypes.bool.isRequired,
    }

    state = { showAll: this.props.messages.size <= minimumMessagesToShow }

    toggleShowAll = () => this.setState({ showAll: !this.state.showAll })

    eligibleCampaigns = this.props.combinedCampaignMessages.filter((obj) => Boolean(obj.messages.length))

    render() {
        return (
            <div className="combined-campaign-message-layout">
                {this.eligibleCampaigns.map((messageObj, index) => (
                    <div className="campaign-message" key={messageObj.label}>
                        <StyledLargeBoldText className="message-header">{messageObj.label}</StyledLargeBoldText>
                        {messageObj.messages.map((message) => [
                            message.target && message.target.name && (
                                <StyledSubheaderText className="message-subheader" key={`${message.message_id}_title`}>
                                    {message.target.name}
                                </StyledSubheaderText>
                            ),
                            <WriteMessageFormSection
                                key={`${message.message_id}_form`}
                                activeId={message.message_id}
                                uniqueWidgetId={this.props.uniqueWidgetId}
                                formName={this.props.formName}
                                writeSectionConfigs={writeSectionConfigs[messageObj.campaignType]}
                                // Use the draft requirements from the individual campaign
                                // If draft requirements not set on that campaign, use draft requirements from combined campaign
                                draftOption={
                                    message.group?.advocate_editing_permission ??
                                    messageObj.draftRequirements ??
                                    this.props.campaign.draft_requirements
                                }
                                // internationalize
                                t={this.props.t}
                                // Redux form prop
                                submitting={this.props.submitting}
                            />,
                        ])}
                        {index !== this.eligibleCampaigns.length - 1 && <StyledHorizontalRule />}
                    </div>
                ))}
            </div>
        )
    }
}

export const CombinedCampaignForm = (props) => (
    <div className="combined-campaign-form">
        <GenericWriteForm {...props}>
            <StyledBox className="combined-campaign-form-box" padding="10px" margin="20px 0px 20px 0px">
                {props.t("campaign.combined_campaign.text")}
            </StyledBox>
            <CombinedCampaignMessageLayout {...props} />
        </GenericWriteForm>
    </div>
)

import React from "react"
import PropTypes from "prop-types"

// Our loading spinner component
// If you would like to overwrite the styles, such as remove the top=-150px that is loading from loading_new.styl
// pass the id prop a different tag to match on, such as 'page-loading-no-top'
export class LoadingSpinner extends React.Component {
    static initClass() {
        this.propTypes = { id: PropTypes.string.isRequired }
        this.defaultProps = { id: "spinner" }
    }

    render() {
        return (
            <div id={this.props.id || "spinner"} className="loading loading-new">
                <div className="spinner-container">
                    <span/>
                </div>
            </div>
        )
    }
}
LoadingSpinner.initClass()

window.LoadingSpinner = LoadingSpinner

export default LoadingSpinner

import styled from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const SelectableDate = styled.div`
    align-items: center;
    background-color: ${colors.White};
    border-radius: ${rules.BorderRadius};
    border: ${rules.Line} ${colors.FormControlBorderColor};
    box-shadow: 0px 2px 7px rgba(0,0,0,0.1);
    display: flex;
    height: 44px;
    margin: 4px 0px;
    width: 100%;

    &:hover {
        border: ${rules.Line} ${colors.QuorumBlue};
    }

    &:active, &:focus-within, &:focus {
        border: ${rules.ThickerLine} ${colors.QuorumBlue};
        outline: none;
    }
`

export const Input = styled.input`
    background-clip: padding-box;
    border-radius: ${rules.BorderRadius};
    border: none;
    font-size: ${rules.ButtonFontSize};
    height: ${rules.InputHeight};
    padding: 0px 11px;
    width: 100%;

    &:focus {
        outline: none;
    }

    &::selection {
        color: ${colors.White};
        background: ${colors.QuorumBlue};
    }

    &::placeholder {
        ${rules.weight.light}
        ${rules.style.italic}
        color: ${colors.QuorumGrey3};

        &::selection {
            color: ${colors.White};
            background: ${colors.QuorumBlue};
        }
    }
`

export const ClearIconContainer = styled.div`
    color: ${colors.QuorumBlue};
    cursor: pointer;
    font-size: ${rules.LargeIconFontSize};
    padding: 3px 11px 0;

    &:hover {
        color: ${colors.QuorumGrey3};
    }
`

import LogoutPage from "QuorumGrassroots/framework/containers/Foundations/LogoutPage"
import ForgotPasswordPage from "QuorumGrassroots/framework/containers/Foundations/ForgotPasswordPage"
import ResetPasswordPage from "QuorumGrassroots/framework/containers/Foundations/ResetPasswordPage"

const { GrassrootsFoundationalRoute } = DjangIO.app.grassroots.types

export const FoundationalRouteMap = {
    [GrassrootsFoundationalRoute.logout.value]: LogoutPage,
    [GrassrootsFoundationalRoute.forgot_password.value]: ForgotPasswordPage,
    [GrassrootsFoundationalRoute.reset_password.value]: ResetPasswordPage,
}

export default FoundationalRouteMap

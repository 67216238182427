import { connect } from "react-redux"

import ElectionInfoForm from "QuorumGrassroots/campaign-forms/components/ElectionInfoForm"

import { postGrassrootsSupporterAction, completeCampaign } from "QuorumGrassroots/campaign-forms/action-creators"
import { electionInfoFormConnection } from "QuorumGrassroots/campaign-forms/selectors/election-info-selectors"

const actions = { postGrassrootsSupporterAction, completeCampaign }

export default connect(electionInfoFormConnection, actions)(ElectionInfoForm)

import React from "react"
import PropTypes from "prop-types"

import DropdownGeneric from "Components/Molecules/DropdownGeneric"

import { InternalButton } from "Components/Molecules/Button"

const DropdownButtonComponent = props => (
    <InternalButton
        dataCy={props.dataCy}
        disabled={props.disabled}
        isGridButton={props.isGridButton}
        isTiny={props.isTiny}
        leftIcon={props.leftIcon}
        leftIconBreakpoint={props.leftIconBreakpoint}
        leftIconBreakpointAtMinWidth={props.leftIconBreakpointAtMinWidth}
        leftIconFamily={props.leftIconFamily}
        leftIconSize={props.leftIconSize}
        leftIconStyle={props.leftIconStyle}
        leftMargin={props.leftMargin}
        rightMargin={props.rightMargin}
        text={props.text}
        textBreakpoint={props.textBreakpoint}
        type={props.type}

        // we don't want to pass along all props because
        // the philosophy of the design system is to explicitly
        // allow individually modifiable properties, rather
        // than explicitly disallow only those that cannot be changed
        // {...this.props}

        forceActive={props.expanded}
        key={props.key || "dropdownButton"}
        rightIcon={props.hasRightIcon && (props.expanded ? "angle-up" : "angle-down")}
        rightIconFamily={"far"}

        // use the internal button to coerce the right icon to break the design rules (to match the mock)
        rightIconFixedWidth={false}
        rightIconHorizontalOffset={props.isGridButton ? "auto" : props.rightIconHorizontalOffset}
        rightIconSize={props.rightIconSize || "16px"}
        rightIconVerticalOffset={"1px"}
    />
)

const DropdownButton = (props) => (
    <DropdownGeneric
        data-auto-cy="MoleculeDropdownButton"
        // need to pass all the props the dropdown button takes to the generic dropdown
        // so that it can pass them to the dropdown button component when it renders it
        {...props}

        // give the generic dropdown a button component to render
        buttonComponent={DropdownButtonComponent}
    />
)

DropdownButton.defaultProps = {
    type: "primary",
    menuOptions: [],
    rightIconHorizontalOffset: "4px",
    hasRightIcon: true,
}

DropdownButton.propTypes = {
    dataCy: PropTypes.string,
    isOverflow: PropTypes.bool,
    isRightAlign: PropTypes.bool,
    isTiny: PropTypes.bool,
    key: PropTypes.string,
    leftIcon: PropTypes.string,
    leftIconBreakpoint: PropTypes.number,
    leftIconBreakpointAtMinWidth: PropTypes.number,
    leftIconFamily: PropTypes.string,
    leftIconSize: PropTypes.string,
    leftIconStyle: PropTypes.object,
    leftMargin: PropTypes.string,
    menuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            dataCy: PropTypes.string,
            href: PropTypes.string,
            icon: PropTypes.string,
            iconFamily: PropTypes.string,
            onClick: PropTypes.func,
            SegueLinkComponent: PropTypes.elementType,
            SegueLinkProps: PropTypes.object,
            text: PropTypes.string,
            type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
        })
    ),
    menuOptionsChildren: PropTypes.arrayOf(PropTypes.element),
    overflowHeightOffset: PropTypes.number,
    buttonDropdownFixedHeight: PropTypes.number,
    buttonDropdownFixedWidth: PropTypes.number,
    rightMargin: PropTypes.string,
    hasRightIcon: PropTypes.bool,
    rightIconHorizontalOffset: PropTypes.string,
    text: PropTypes.string,
    textBreakpoint: PropTypes.number,
    type: PropTypes.oneOf(["primary", "secondary", "tertiary", "large-borderless-blue"]),
}

export default DropdownButton

import styled from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const SectionHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: ${({smallMargin}) => smallMargin ? "11px" : "18px"};
    align-items: baseline;

    svg {
        margin-right: 7px;
        font-size: 16px;
    }
`

export const HeaderText = styled.div`
    color: ${colors.gray9};
    ${rules.GiantTextMediumWeight}
    font-size: 1.25rem;
    line-height: 1.5;
    margin-top: 5px;
    margin-right: 24px;
`

export const AccessoryArea = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: auto;
    align-self: center;
    & > :not(:first-child) {
        margin-left: 18px;
    }
`

export const Expand = styled.span`
    user-select: none;
    flex-shrink: 0;
    align-self: center;
    ${rules.SmallTextRegularWeight}
    color: ${colors.MidPurple};
    &:hover {
        background-color: ${colors.AquaHaze};
        cursor: pointer;
    }
`

export const Icon = styled.span`
    & > * {
        transform: ${({expanded}) => expanded ? "rotate(-180deg)" : null};
    }
`

module.exports = {
    // outbox regexes
    // validateBcc: /(([\w\.\+\-]+@([\w\+\-]{2,})\.[\w\+\-]{2,}(\.{1}[\w\+\-]+)*,[ ]))*([\w\.\+\-]+@([\w\+\-]{2,})\.[\w\+\-]{2,}(\.{1}[\w\+\-]+)*)/,
    validateBcc:
        /^(([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+(\s*[,.]\s*(([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/,
    commaSeparatedBcc: /([\w.+-]+@([\w+-]{2,})\.[\w+.-]{2,}(\.{1}[\w+-]+)*)/,
    grabInsideStyle: /<style[^]*<\/style>/,
    isValidEmailName: /^([\w.+-])+$/,
    slashInsidePlaceholder: /\{[/\\]}/,
    spaceInsidePlaceholder: /\{ | \}/,
    isValidHexNumber: /^#(?:[0-9a-fA-F]{3}){1,2}$/i,
    splitEmailAddress: /[^@]+/,
    documentLineBreaks: /\r?\n([ \t]*\r?\n)+/g,
    stringValidationRegex: /^[a-zA-Z0-9(),.\-&'\u00C0-\u024F ]+$/,
    titleValidationRegex: /^[a-zA-Z0-9(),\-'\u00C0-\u024F!?: ]+$/,
    // https://emailregex.com/
    // w3c html5 email input regex
    emailValidationRegex: /^[a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    isLoginUrl: /\/\/[a-zA-Z0-9_.+-]+(:[a-zA-Z0-9_.+-]*)?@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/,
    decimalOrIntFrom1To10NumberValidationRegex: /^([0-9]?(\.[0-9])*|(10(\.[0]+)?))$/,
    numberValidationRegex: /^-?\d+$/,
    positiveNumberValidationRegex: /^\d+$/,
    phoneNumberReplacerRegex: /[()\-+ ]/g,
    phoneNumberValidationRegex: /^\d{7,13}$/,
    phoneNumberWithShortCodeValidationRegex: /^\d{5,13}$/,
    urlRegex: /^(ftp|http|https):\/\/[^ "]+$/,
    twitterHandleRegex: /^[A-Za-z]+[A-Za-z0-9]+$/,
    xHandleRegex: /^@[A-Za-z][A-Za-z0-9_]{0,14}$/,
    isBillNotesTabRegex: /^\/(bill|document|amendment)\/\d+\/notes\/?$/,
    isPersonNotesTabRegex: /^\/(contact|official|organization)\/\d+\/notes\/?$/,
    removeQuotes: /"/g,
    // bulk email
    // group 2 is the link, group 3 is the other content
    linksAndContentRegex: /<a [^>]* ?href=('|")([^>'"]*)\1[^>]*>((?:(?!<\/a>)(.|\s))*)<\/a>/g,
    multipleSpacesRegex: /\s{2,}/g,
    removeSpaces: /\s/g,
    ratingOneToTenValidationRegex: /^([0-9]|10)$/,
    removeHTML: /(<([^>]+)>)/gi,
    subdomainValidationRegex: /^([a-z0-9-])+$/, // https://stackoverflow.com/questions/16468309/can-domain-name-have-two-continuous-hyphens
    domainValidationRegex: /^([a-z0-9.])+(-[a-z0-9]+(\.[a-z0-9]+)*)*$/,
    removeEndCommaAndSpaces: /,?([ ]+)?$/g,
    // https://stackoverflow.com/a/50777192
    vimeoVideoIdRegex:
        /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/,
    // https://stackoverflow.com/questions/3717115/regular-expression-for-youtube-links
    youtubeVideoIdRegex:
        /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?/,
    htmlQuoteRegex: /&quot;/g,
    // Ex, Hello "{{target_firstname}}"
    targetPlaceholderRegex: /{{(target|person)_[_a-z]{1,}}}/g,
    // Ex, Foo {{ placeholder }}
    textHasPlaceholder: new RegExp(/\{\{[^{}]+\}\}/),
    hasHTMLTags: /<.*?>/gm,
    emptySummernote: /^(\s|<br>)*$/,
}

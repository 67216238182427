import { unregisterField } from "redux-form/immutable"
import ActionTypes, * as ActionGroups from "shared/customFields/action-types"
import * as djangioActionCreators from "shared/djangio/action-creators"
import { TEXT_URL_DELIMITER } from "app/static/frontend/profiles-new/constants"

export const getSupporterCustomFields = () =>
    djangioActionCreators.resourceGetList(
        DjangIO.app.grassroots.models.CustomTag.objects.filter({
            dehydrate_extra: ["group_dict"],
        }),
        ActionGroups.getSupporterCustomFieldsTypes,
        {
            organization: Userdata.organization_id,
            tag_owner: DjangIO.app.models.QuorumDataType.supporter.value,
            limit: 1000,
        },
    )

export const getOrganizationCustomFields = () =>
    djangioActionCreators.resourceGetList(
        DjangIO.app.grassroots.models.CustomTag.objects.filter({
            dehydrate_extra: ["group_dict"],
        }),
        ActionGroups.getOrganizationCustomFieldsTypes,
        {
            organization: Userdata.organization_id,
            tag_owner: DjangIO.app.models.QuorumDataType.public_organization.value,
            limit: 1000,
        },
    )

export const getOfficialCustomFields = () =>
    djangioActionCreators.resourceGetList(
        DjangIO.app.grassroots.models.CustomTag.objects.filter({
            dehydrate_extra: ["group_dict"],
        }),
        ActionGroups.getOfficialCustomFieldsTypes,
        {
            organization: Userdata.organization_id,
            tag_owner: DjangIO.app.models.QuorumDataType.person.value,
            limit: 1000,
        },
        {},
        true,
    )

export const changeCurrentTab = (currentTab) => ({
    type: ActionTypes.CHANGE_CURRENT_TAB,
    currentTab,
})

export const loadCustomFields = (organization) =>
    djangioActionCreators.resourceGetList(
        DjangIO.app.grassroots.models.CustomTag.objects.filter({
            dehydrate_extra: ["group_dict"],
        }),
        ActionGroups.getCustomFieldsTypes,
        {
            organization,
            limit: 0,
        },
        {},
        true,
    )

export const changeCustomField = (formField, formValue) => ({
    type: ActionTypes.CHANGE_CUSTOM_FIELD,
    formField,
    formValue,
})

export const clearCustomField = () => ({ type: ActionTypes.CLEAR_CUSTOM_FIELD })

export const deleteOptionField = (optionKey) => ({
    type: ActionTypes.DELETE_OPTION_FIELD,
    optionKey,
})

export const reorderOptionField = (optionKey, direction = "up") => ({
    type: ActionTypes.REORDER_OPTION_FIELD,
    optionKey,
    direction,
})

export const alphabetizeOptionField = () => ({
    type: ActionTypes.ALPHABETIZE_OPTION_FIELD,
})

export const updateOptionValue = (optionKey, optionValue) => (dispatch) => {
    dispatch({
        type: ActionTypes.UPDATE_OPTION_VALUE,
        optionKey,
        optionValue,
    })
    return Promise.resolve()
}

export const updateCustomField = (tagId, kwargs) =>
    djangioActionCreators.resourcePatchDetail(
        DjangIO.app.grassroots.models.CustomTag,
        ActionGroups.updateCustomFields,
        tagId,
        kwargs,
    )

export const loadCustomField = (fieldId) =>
    djangioActionCreators.resourceGetDetail(
        DjangIO.app.grassroots.models.CustomTag.objects.filter({
            dehydrate_extra: [
                "conditional_parent_options",
                "conditional_parent_type",
                "exists_in_action_center_reg_forms",
                "group_dict",
                "has_conditional_children",
            ],
        }),
        ActionGroups.loadCustomField,
        fieldId,
        {},
        false,
    )

export const createCustomField = (kwargs) =>
    djangioActionCreators.resourcePostList(DjangIO.app.grassroots.models.CustomTag, ActionGroups.createCustomField, {
        organization: DjangIO.app.userdata.models.Organization.foreignKey(Userdata.organization_id),
        ...kwargs,
    })

export const createTagDict = (customFields, formValues) =>
    customFields.reduce((dict, field) => {
        const val = formValues[field.slug]

        if (field.tag_type === DjangIO.app.person.types.TagType.multi_options_list.value && val) {
            // If the tag type is a multi options list, 'val' is an array
            // Make sure only to add this custom field to the tag dict if there are elements in the array
            return val.length > 0 ? { ...dict, [field.slug]: val } : dict
        } else if (field.tag_type === DjangIO.app.person.types.TagType.number.value && (val || val === 0)) {
            return { ...dict, [field.slug]: parseInt(val) }
        } else if (field.tag_type === DjangIO.app.person.types.TagType.string.value && val) {
            const urlInputValue = formValues[`${field.slug}_url`]
            if (urlInputValue) {
                return { ...dict, [field.slug]: `${val}${TEXT_URL_DELIMITER}${urlInputValue}` }
            }
            const stringTagDict = { ...dict, [field.slug]: val }
            return stringTagDict
        } else if (val || val === false) {
            // If the tag type is a boolean, add the value (true/false/null) to the tag dict
            // Otherwise, for all other tag types, add val to the tag dict if val exists
            return { ...dict, [field.slug]: val }
        } else {
            return dict
        }
    }, {})

/**
 * This function takes redux form name and field to unregister.
 * Returns an action, which will unregister a field from the Redux Form's registered fields
 * @param {String} formName - Redux Form name
 * @param {Object} fieldName - Name of the field to unregister from the Redux Form
 * @returns {Object}
 */
export const unregisterCustomField = (formName, fieldName) => unregisterField(formName, fieldName)

import { vimeoVideoIdRegex, youtubeVideoIdRegex } from "shared/imports/regex"

export const getVimeoLink = (link) => {
    const groups = vimeoVideoIdRegex.exec(link)

    // Return index 4 because the fifth capture group from the regex is the Vimeo video ID
    // ex: ["https://player.vimeo.com/1234", "https", "player.", undefined, "1234", index: 0, input: "https://player.vimeo.com/23434/", groups: undefined]
    const vimeoLinkId = groups ? groups[4] : ""
    return vimeoLinkId && `https://player.vimeo.com/video/${vimeoLinkId}`
}

export const getYoutubeVideoLinkId = (link) => {
    const groups = youtubeVideoIdRegex.exec(link)

    // Return index 1 because the second capture group from the regex is the Youtube video Id
    // ex: ["https://www.youtube.com/watch?v=asdfa", "asdfa", index: 0, input: "https://www.youtube.com/watch?v=asdfa", groups: undefined]
    return groups ? groups[1] : ""
}

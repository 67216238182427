import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const ModalBodyNoScroll = styled.div`
    padding: 11px 45px 24px;
`
export const Text = styled.p`
    ${rules.MediumTextRegularWeight}
    color: ${colors.QuorumBlue};
    margin-bottom: 18px;
`

export const Content = styled.div`
    object {
        width: 100%;
    }
`

export const ButtonWrapper = styled.div`
    display: flex;
    overflow: visible;
    padding-top: 18px;
    justify-content: center;

    & > *:not(:last-child) {
        margin-right: 24px;
    }
`

import styled, { css } from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"
import { MainContainer } from "Components/Molecules/WorkflowStrip/style"

// contained by the main container
export const ClickablesContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-left: auto;
    padding-right: 11px;
    padding-left: 100px;

    ${MainContainer}:hover & {
        /*
        * bring the clickables container to front and apply a
        * gradient background to cover potentially overrunning text
        */
        z-index: 1;
        background-image: linear-gradient(
            to left,
            ${({checked}) => checked ? colors.QuorumBlue : colors.DarkerPorcelain},
            ${({checked}) => checked ? colors.QuorumBlue : colors.DarkerPorcelain} calc(100% - 90px),
            #FFFFFF00
        );
    }
`

export const HoverActions = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const Row = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 24px;
`

// contained by a row in the row container
export const ClickableWrapper = styled.div`
    display: flex;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    visibility: hidden;
    ${MainContainer}:hover & {
        color: ${colors.violet7};
        visibility: visible;
    }
    height: 100%;
`

export const HoverActionIcon = styled.div`
    margin-right: 3px;
    svg {
        font-size: 14px;
    }
`

export const AltHoverActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    margin-right: 11px;
`

export const AltHoverActionIcon = styled.div`
    display: flex;
    align-items: center;

    ${({isActive}) => isActive
        ? css`
            color: ${colors.White};
            background-color: ${colors.QuorumBlue};
        `
        : css`
            &:hover {
                background-color: ${colors.White};
            }

            color: ${colors.QuorumBlue};
        `
    }

    svg {
        font-size: 24px;
    }
`

export const TextSpan = styled.div`
    align-self: center;
    margin-right: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    ${rules.SmallTextRegularWeight}
`

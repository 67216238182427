import React from "react"
import styled from "styled-components"

const RightWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const NavRight = ({ children }) => {
    return <RightWrapper>{children}</RightWrapper>
}

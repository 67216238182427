import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/ContactCard/style"
import * as colors from "Components/colors"
import * as rules from "Components/rules"
import * as helperFunctions from "utils/helperFunctions"

import CircleImage from "Components/Molecules/CircleImage"

const ContactCard = ({
    circleColor,
    circleFillColor,
    circleFontColor,
    circleIcon,
    circleIconFamily,
    circleImgUrl,
    circleText,
    detailItems,
    infoItems,
    name,
    subtitle,
}) => {
    const isIE11 = helperFunctions.isUserIE11()

    // calculate these values to be used several places in the css
    const circleDiameter = parseInt(rules.LargeAvatarCircleDiameter, 10)
    const circleAreaPadding = 18
    const circleAreaWidth = circleDiameter + circleAreaPadding

    return (
        <S.ContactCard data-auto-cy="CompoundContactCard">
            <S.MainInfoContainer rightMargin={`-${circleAreaWidth}px`}>
                <S.CircleContainer
                    isUserIE11={isIE11}
                    rightPadding={`${circleAreaPadding}px`}
                >
                    <CircleImage
                        circleColor={circleColor}
                        diameter={`${circleDiameter}px`}
                        fillColor={circleFillColor}
                        fontColor={circleFontColor}
                        fontSize="40px"
                        icon={circleIcon}
                        iconFamily={circleIconFamily}
                        imgUrl={circleImgUrl}
                        text={circleText}
                    />
                </S.CircleContainer>
                <S.InfoList isUserIE11={isIE11}>
                    { name && <S.Name>{name}</S.Name> }
                    { subtitle && <S.Subtitle>{subtitle}</S.Subtitle> }
                    {
                        infoItems && infoItems.map(item => ( item &&
                            <S.InfoItem key={item.key || `${item.label}-${item.text}`} styling={item.textType}>
                                {item.label && (item.label + ":")} {item.text && <b>{item.text}</b>}
                            </S.InfoItem>
                        ))
                    }
                </S.InfoList>
            </S.MainInfoContainer>
            <S.DetailsContainer leftPadding={`${circleAreaWidth}px`}>
                {
                    detailItems && detailItems.map(item => ( item.text &&
                        <S.DetailItem key={item.key || `${item.label}-${item.text}`}>
                            {item.label && <S.DetailLabel>{item.label}</S.DetailLabel>}
                            {item.text && <S.DetailText>{item.text}</S.DetailText>}
                        </S.DetailItem>
                    ))
                }
            </S.DetailsContainer>
        </S.ContactCard>
    )
}

ContactCard.defaultProps = {
    circleColor: colors.gray0,
    circleFillColor: colors.gray0,
    circleFontColor: colors.gray5,
}

ContactCard.propTypes = {
    // props for the CircleImage on the left side of the contact card
    circleColor: PropTypes.string,
    circleFillColor: PropTypes.string,
    circleFontColor: PropTypes.string,
    circleIcon: PropTypes.string,
    circleIconFamily: PropTypes.string,
    circleImgUrl: PropTypes.string,
    circleText: PropTypes.string,
    // items to display on the right side of the card
    detailItems: PropTypes.arrayOf(PropTypes.shape({
        // name of the detail field
        label: PropTypes.string,
        // text for the detail field
        text: PropTypes.string,
    })),
    // items to display under the name, right of the circle
    infoItems: PropTypes.arrayOf(PropTypes.shape({
        // name of the info field
        label: PropTypes.string,
        // text for the info field
        text: PropTypes.string,
        // style of the text for this info field
        textType: PropTypes.oneOf(["primary", "secondary"])
    })),
    // name of the contact
    name: PropTypes.string,
    // Subtitle of the contact
    subtitle: PropTypes.string,
}

export default ContactCard

/**
 * Function to help make the actions object [for pie requests
 *
 * @param {String} key prefix for the resource actions
 * @returns {Object} Object that works for jake's resource methods
 *                    (contains start, success, and fail)
 */
export const makePieActionDict = key => ({
    start: `${key}_START`,
    success: `${key}_SUCCESS`,
    fail: `${key}_FAIL`,
})

/**
 * Function that make the actions object and the action types for pie requests
 *
 * @param {String} action Something like "get" or "post" or "patch"
 * @param {String} model probably the logical name of the model ie "bulk_email"
 * @returns {Object} returns object of 2 objects:
 *                actionTypes -> the action types dict,
 *                pieDict is the action object for the pie request
 */
export const makePieActionObjs = (action, model) => {
    const key = `${action}_${model}`.toUpperCase()
    // make the mapping for pie requests
    const pieActionObject = makePieActionDict(key)
    const pieActionTypes = {
        [pieActionObject.start]: pieActionObject.start,
        [pieActionObject.success]: pieActionObject.success,
        [pieActionObject.fail]: pieActionObject.fail,
    }
    // return the two objects
    return { actionTypes: pieActionTypes, pieDict: pieActionObject }
}

const generatePieActionTypes = makePieActionObjs

export default generatePieActionTypes

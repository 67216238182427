import styled, { css } from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

import { cssHelper } from "utils/cssHelpers"

export const Button = styled.button`
    ${({
        disabled,
        forceActive,
        hoverable,
        isForm,
        isGiant,
        isTiny,
        leftMargin,
        leftPadding,
        rightMargin,
        rightPadding,
        style,
        type,
    }) => {
        let defaultProperties = {}
        let hoverProperties = {}
        let focusProperties = {}
        let activeProperties = {}

        // define the top level CSS properties
        defaultProperties["height"] = rules.ButtonHeight
        defaultProperties["font-size"] = rules.ButtonFontSize
        defaultProperties["line-height"] = rules.ButtonFontLineHeight
        defaultProperties["border-radius"] = rules.BorderRadius
        defaultProperties["border"] = rules.Line
        defaultProperties["padding-left"] = leftPadding ? leftPadding : "9px";
        defaultProperties["padding-right"] = rightPadding ? rightPadding : "10px";
        defaultProperties["margin-right"] = rightMargin ? rightMargin : "0px";
        defaultProperties["margin-left"] = leftMargin ? leftMargin : "0px";
        defaultProperties["display"] = "flex"
        defaultProperties["align-items"] = "center"
        defaultProperties["justify-content"] = "center"
        defaultProperties["white-space"] = "nowrap"
        // need to add these to prevent label element override
        defaultProperties["cursor"] = "pointer"
        defaultProperties["margin-bottom"] = "0px"
        defaultProperties["font-weight"] = "normal"

        if (isGiant) {
            defaultProperties["height"] = rules.GiantButtonHeight
            defaultProperties["font-size"] = rules.GiantButtonFontSize
            defaultProperties["line-height"] = rules.GiantButtonFontLineHeight
            defaultProperties["padding-left"] = "14px"
            defaultProperties["padding-right"] = "14px"
        } else if (isTiny) {
            defaultProperties["height"] = rules.TinyButtonHeight
            defaultProperties["font-size"] = rules.TinyButtonFontSize
            defaultProperties["line-height"] = rules.TinyButtonFontLineHeight
            defaultProperties["padding-left"] = "7px"
            defaultProperties["padding-right"] = "7px"
        }

        // do not let buttons be compressed in flex containers
        defaultProperties["flex-shrink"] = "0";

        // necessary to remove the outline from buttons in the focus state,
        // for some reason it can't be in the top level CSS
        focusProperties["outline"] = "none";

        // base properties for action-idle
        const actionIdleBase = () => {
            defaultProperties["background-color"] = "transparent"
            defaultProperties["border-color"] = "transparent"
            defaultProperties["height"] = "30px"
            defaultProperties["padding-left"] = leftPadding ? leftPadding : "6px";
            defaultProperties["padding-right"] = rightPadding ? rightPadding : "8px";
        }

        const setLargeBorderless = () => {
            defaultProperties["border-radius"] = "0px"
            defaultProperties["border-style"] = "unset"
            defaultProperties["height"] = "60px"
            defaultProperties["padding"] = "0 13px"
            defaultProperties["font-size"] = "18px"
        }

        const setV2Defaults = () => {
            defaultProperties["gap"] = "6px"
            defaultProperties["transition"] = "background-color .3s ease-in-out, color .3s ease-in-out, box-shadow .3s ease-in-out, border .3s ease-in-out"

            if (isTiny) {
                defaultProperties["padding"] = "8px 12px"
                defaultProperties["height"] = rules.TinyButtonHeightV2
                defaultProperties["font-size"] = rules.TinyButtonFontSizeV2
                defaultProperties["line-height"] = rules.TinyButtonFontLineHeightV2
            }
        }

        // change some properties according to the button type
        switch (type) {
            case "large-borderless-purple":
                setLargeBorderless()
                defaultProperties["background-color"] = colors.QuorumViolet
                defaultProperties["color"] = colors.White
                hoverProperties["background-color"] = colors.Violet6
                focusProperties["background-color"] = colors.Violet6
                activeProperties["background-color"] = colors.Violet6
                break
            case "large-borderless-blue":
                setLargeBorderless()
                defaultProperties["background-color"] = colors.QuorumViolet
                defaultProperties["color"] = colors.White
                hoverProperties["background-color"] = colors.Violet6
                focusProperties["background-color"] = colors.Violet6
                activeProperties["background-color"] = colors.Violet6
                break
            case "borderless":
                defaultProperties["background-color"] = "transparent"
                defaultProperties["color"] = colors.violet8
                defaultProperties["border-style"] = "unset"
                defaultProperties["height"] = "auto"
                defaultProperties["line-height"] = "15px"
                defaultProperties["padding"] = "8px 12px"
                defaultProperties["border-radius"] = "2px"
                // specify some properties for the active state
                hoverProperties["background-color"] = hoverable && colors.violet2
                focusProperties["background-color"] = hoverable && colors.violet2
                break
            case "tertiary":
                setV2Defaults()
                defaultProperties["background-color"] = colors.White
                defaultProperties["color"] = colors.gray7
                defaultProperties["border"] = "0"

                hoverProperties["background-color"] = colors.violet0
                hoverProperties["color"] = colors.violet7

                focusProperties["background-color"] = colors.violet0
                focusProperties["color"] = colors.violet7
                focusProperties["box-shadow"] = rules.VioletShadow

                activeProperties["background-color"] = colors.violet0
                activeProperties["box-shadow"] = rules.VioletShadow
                break
            case "anchor":
                defaultProperties["background-color"] = colors.White
                defaultProperties["color"] = colors.LinkPurple
                defaultProperties["border-color"] = colors.SilverChalice
                defaultProperties["padding-right"] = "0px"
                // specify background-color for the active state
                hoverProperties["background-color"] = colors.DarkerPorcelain
                focusProperties["background-color"] = colors.DarkerPorcelain
                break
            case "secondary":
                defaultProperties["background-color"] = colors.White
                defaultProperties["color"] = colors.NewGrey7
                defaultProperties["border-color"] = colors.NewGrey3
                defaultProperties["border-width"] ="1px"
                hoverProperties["background-color"] = colors.AquaHaze
                focusProperties["background-color"] = colors.AquaHaze
                break
            case "secondary-outlined":
                defaultProperties["background-color"] = colors.White
                defaultProperties["color"] = colors.NewGrey7
                defaultProperties["border-color"] =  colors.NewGrey3
                hoverProperties["background-color"] = colors.AquaHaze
                focusProperties["background-color"] = colors.AquaHaze
                activeProperties["background-color"] = colors.AquaHaze
                activeProperties["box-shadow"] =  `0 0 0 1px ${colors.NewGrey3}`
                break
            case "action-idle":
                actionIdleBase()
                defaultProperties["color"] = colors.QuorumGrey4
                break
            case "action-idle-compressed-dark":
            case "action-idle-dark":
                actionIdleBase()
                defaultProperties["color"] = ({ theme }) => theme.colors.primary[5];
                break
            case "action-idle-compressed":
                defaultProperties["background-color"] = "transparent"
                defaultProperties["color"] = colors.QuorumGrey4
                defaultProperties["border-color"] = "transparent"
                defaultProperties["height"] = "30px"
                defaultProperties["padding-left"] = leftPadding ? leftPadding : "5px";
                defaultProperties["padding-right"] = rightPadding ? rightPadding : "5px";
                defaultProperties["font-size"] = "16px";
                break
            case "action-active":
                defaultProperties["background-color"] = "transparent"
                defaultProperties["color"] = colors.QuorumBlue
                defaultProperties["border-color"] = "transparent"
                defaultProperties["height"] = "30px"
                defaultProperties["padding-left"] = leftPadding ? leftPadding : "6px";
                defaultProperties["padding-right"] = rightPadding ? rightPadding : "8px";
                break
            case "resource-table-dropdown":
                defaultProperties["background-color"] = "transparent"
                defaultProperties["border-color"] = "transparent"
                defaultProperties["height"] = "30px"
                defaultProperties["padding-left"] = leftPadding ? leftPadding : "6px";
                defaultProperties["padding-right"] = rightPadding ? rightPadding : "8px";
                break
            case "primary-purple":
                defaultProperties["background-color"] = colors.LightPurple
                defaultProperties["color"] = colors.White
                defaultProperties["border"] = "none"
                hoverProperties["background-color"] = colors.DarkPurple
                focusProperties["background-color"] = colors.DarkPurple
                break
            case "ai-suggestion":
                defaultProperties["background-color"] = colors.Ultraviolet
                defaultProperties["color"] = colors.White
                defaultProperties["border"] = "none"
                defaultProperties["border-radius"] = "16px"
                defaultProperties["padding-left"] = leftPadding ? leftPadding : "8px";
                defaultProperties["padding-right"] = rightPadding ? rightPadding : "8px";
                hoverProperties["background-color"] = colors.DarkViolet
                focusProperties["background-color"] = colors.DarkViolet
                break
            case "ai-search-purple":
                defaultProperties["background-color"] = colors.White
                defaultProperties["border"] = `1px solid ${colors.Ultraviolet}`
                defaultProperties["border-radius"] = "24px"
                defaultProperties["color"] = colors.Ultraviolet
                defaultProperties["font-size"] = "14px"
                defaultProperties["height"] = "33px"
                defaultProperties["padding"] = "8px 12px"
                hoverProperties["background-color"] = colors.AquaHaze
                break
            case "ai-search-purple-inverse":
                defaultProperties["background-color"] = colors.Ultraviolet
                defaultProperties["border-color"] = colors.Ultraviolet
                defaultProperties["border-radius"] = "32px"
                defaultProperties["color"] = colors.White
                defaultProperties["font-size"] = "12px"
                defaultProperties["height"] = "24px"
                defaultProperties["padding"] = "4px 8px"
                break
            case "no-effect":
                defaultProperties["background-color"] = colors.White
                defaultProperties["border-color"] = colors.White
                defaultProperties["border-radius"] = "4px"
                defaultProperties["color"] = colors.QuorumBlue
                defaultProperties["font-weight"] = "400"
                defaultProperties["line-height"] = "18px"
                defaultProperties["height"] = "auto"
                defaultProperties["padding"] = "0px"
                break
            case "ghost-blue":
                defaultProperties["background-color"] = colors.White
                defaultProperties["border-color"] = colors.White
                defaultProperties["border-radius"] = "4px"
                defaultProperties["color"] = colors.QuorumBlue
                defaultProperties["min-width"] = "40px"
                defaultProperties["padding"] = "8px"
                defaultProperties["transition"] = "background-color .1s"
                hoverProperties["background-color"] = colors.LightQuorumBlue
                focusProperties["background-color"] = colors.LightQuorumBlue
                break
            case "ghost-purple":
                defaultProperties["background-color"] = colors.White
                defaultProperties["border-color"] = colors.White
                defaultProperties["border-radius"] = "4px"
                defaultProperties["color"] = colors.Ultraviolet
                defaultProperties["font-size"] = "14px"
                defaultProperties["padding"] = "8px 12px"
                defaultProperties["transition"] = "background-color .1s"
                hoverProperties["background-color"] = colors.LightUltraviolet
                focusProperties["background-color"] = colors.LightUltraviolet
                break
            case "ghost-purple-small":
                defaultProperties["background-color"] = colors.White
                defaultProperties["border-color"] = colors.White
                defaultProperties["border-radius"] = "32px"
                defaultProperties["color"] = colors.Ultraviolet
                defaultProperties["font-size"] = "12px"
                defaultProperties["height"] = "25px"
                defaultProperties["padding"] = "4px 8px"
                defaultProperties["transition"] = "background-color .1s"
                hoverProperties["background-color"] = colors.LightUltraviolet
                focusProperties["background-color"] = colors.LightUltraviolet
                break
            case "filled":
                setV2Defaults()

                defaultProperties["background-color"] = colors.violet7
                defaultProperties["color"] = colors.White
                defaultProperties["border"] = "0"

                hoverProperties["background-color"] = colors.violet9

                activeProperties["background-color"] = colors.violet7
                activeProperties["box-shadow"] = rules.VioletShadow

                focusProperties["background-color"] = colors.violet9
                focusProperties["box-shadow"] = rules.VioletShadow
                break
            case "outlined":
                setV2Defaults()

                defaultProperties["background-color"] = colors.White
                defaultProperties["color"] = colors.gray7
                defaultProperties["border-color"] = colors.gray3

                hoverProperties["background-color"] = colors.violet1
                hoverProperties["color"] = colors.violet8
                hoverProperties["border-color"] = forceActive ? colors.violet7 : colors.violet1

                activeProperties["background-color"] = forceActive ? colors.violet1 : colors.White
                activeProperties["border-color"] = forceActive ? colors.violet7 : colors.gray3
                activeProperties["box-shadow"] = forceActive ? "none" : rules.VioletShadow

                focusProperties["background-color"] = colors.violet2
                focusProperties["color"] = colors.violet8
                focusProperties["border-color"] = colors.violet2
                focusProperties["box-shadow"] = rules.VioletShadow
                break
            case "ghost":
                setV2Defaults()

                defaultProperties["background-color"] = colors.White
                defaultProperties["color"] = colors.gray7
                defaultProperties["border"] = "0"

                hoverProperties["background-color"] = colors.violet0
                hoverProperties["color"] = colors.violet7

                focusProperties["background-color"] = colors.violet0
                focusProperties["color"] = colors.violet7
                focusProperties["box-shadow"] = rules.VioletShadow

                activeProperties["background-color"] = colors.violet0
                activeProperties["box-shadow"] = rules.VioletShadow
                break
            case "top-nav":
                defaultProperties["background-color"] = colors.violet1
                defaultProperties["color"] = colors.violet8
                defaultProperties["padding"] = "12px 16px"
                defaultProperties["border"] = "0"
                defaultProperties["font-size"] = ".875rem"
                defaultProperties["line-height"] = "1rem"
                defaultProperties["font-weight"] = "500"
                defaultProperties["transition"] = "background-color .3s ease-in-out"

                hoverProperties["background-color"] = colors.violet2
                focusProperties["background-color"] = colors.violet2
                break
            case "top-nav-as-user":
                defaultProperties["background-color"] = colors.violet7
                defaultProperties["color"] = colors.White
                defaultProperties["padding"] = "12px 16px"
                defaultProperties["border"] = "0"
                defaultProperties["font-size"] = ".875rem"
                defaultProperties["line-height"] = "1rem"
                defaultProperties["font-weight"] = "500"
                defaultProperties["transition"] = "background-color .3s ease-in-out, box-shadow .3s ease-in-out"

                hoverProperties["background-color"] = colors.violet9

                activeProperties["background-color"] = colors.violet7
                activeProperties["box-shadow"] = rules.VioletShadow

                focusProperties["background-color"] = colors.violet9
                focusProperties["box-shadow"] = rules.VioletShadow
                break
            case "top-nav-round":
                defaultProperties["width"] = "40px"
                defaultProperties["height"] = "40px"
                defaultProperties["border-radius"] = "2rem"
                defaultProperties["padding"] = "12px"
                defaultProperties["color"] = colors.White
                defaultProperties["background-color"] = colors.violet7
                defaultProperties["border"] = "0"
                defaultProperties["transition"] = "background-color .3s ease-in-out"

                hoverProperties["background-color"] = colors.violet9
                focusProperties["background-color"] = colors.violet9
                break
            case "top-nav-round-as-user":
                defaultProperties["width"] = "40px"
                defaultProperties["height"] = "40px"
                defaultProperties["border-radius"] = "2rem"
                defaultProperties["padding"] = "12px"
                defaultProperties["color"] = colors.violet9
                defaultProperties["background-color"] = colors.violet2
                defaultProperties["border"] = "0"
                defaultProperties["transition"] = "background-color .3s ease-in-out"

                hoverProperties["background-color"] = colors.violet3
                focusProperties["background-color"] = colors.violet3
                break
            case "ai-search":
                defaultProperties["border-radius"] = "32px"
                defaultProperties["background-color"] = "transparent"
                defaultProperties["border"] = "none"
                defaultProperties["color"] = "white"
                defaultProperties["font-size"] = "16px"
                defaultProperties["padding"] = "0"
                defaultProperties["transition"] = "background-color .1s"
                defaultProperties["width"] = "32px"
                defaultProperties["height"] = "32px"
                defaultProperties["margin-left"] = "5px"

                hoverProperties["background-color"] = colors.violet1
                hoverProperties["color"] = colors.violet5

                focusProperties["background-color"] = colors.violet1
                focusProperties["color"] = colors.violet5
                break
            case "ai-search-active":
                defaultProperties["border-radius"] = "32px"
                defaultProperties["background-color"] = "transparent"
                defaultProperties["border"] = "none"
                defaultProperties["color"] = colors.violet5
                defaultProperties["font-size"] = "16px"
                defaultProperties["padding"] = "0"
                defaultProperties["transition"] = "background-color .1s"
                defaultProperties["width"] = "32px"
                defaultProperties["height"] = "32px"
                defaultProperties["margin-left"] = "5px"

                hoverProperties["background-color"] = colors.violet1
                focusProperties["background-color"] = colors.violet1
                break
            case "search-button":
                defaultProperties["width"] = "32px"
                defaultProperties["height"] = "32px"
                defaultProperties["color"] = colors.violet1
                defaultProperties["background-color"] = "transparent"
                defaultProperties["border-radius"] = "32px"
                defaultProperties["border"] = "none"
                defaultProperties["padding"] = "0"

                hoverProperties["background-color"] = "rgba(255, 255, 255, .2)"
                hoverProperties["color"] = "white"

                focusProperties["background-color"] = "rgba(255, 255, 255, .2)"
                focusProperties["color"] = "white"
                break
            case "search-button-active":
                defaultProperties["width"] = "32px"
                defaultProperties["height"] = "32px"
                defaultProperties["color"] = colors.gray7
                defaultProperties["background-color"] = "transparent"
                defaultProperties["border-radius"] = "32px"
                defaultProperties["border"] = "none"
                defaultProperties["padding"] = "0"

                hoverProperties["background-color"] = "rgba(255, 255, 255, .4)"
                hoverProperties["color"] = colors.violet5

                focusProperties["background-color"] = "rgba(255, 255, 255, .4)"
                focusProperties["color"] = colors.violet5
                break
            default:
                defaultProperties["background-color"] = colors.QuorumViolet
                defaultProperties["color"] = colors.White
                defaultProperties["border"] = "none"
                hoverProperties["background-color"] = colors.Violet6
                focusProperties["background-color"] = colors.Violet6
                break
        }

        // Customize "disabled" button styles and overwrite style changes on hover or focus states
        if (disabled) {
            switch (type) {
                case "primary":
                    defaultProperties["background-color"] = colors.SilverChalice
                    defaultProperties["border-color"] = colors.SilverChalice
                    defaultProperties["color"] = colors.White
                    hoverProperties = {...hoverProperties, ...defaultProperties}
                    focusProperties = {...focusProperties, ...defaultProperties}
                    break
                case "secondary":
                    defaultProperties["background-color"] = colors.unnamedF8F9F9
                    defaultProperties["border-color"] = colors.SilverChalice
                    defaultProperties["color"] = colors.SilverChalice
                    hoverProperties = {...hoverProperties, ...defaultProperties}
                    focusProperties = {...focusProperties, ...defaultProperties}
                    break
                case "tertiary":
                    defaultProperties["background-color"] = colors.White
                    defaultProperties["border-color"] = colors.SilverChalice
                    defaultProperties["color"] = colors.SilverChalice
                    hoverProperties = {...hoverProperties, ...defaultProperties}
                    focusProperties = {...focusProperties, ...defaultProperties}
                    break
                case "filled":
                    defaultProperties["background-color"] = colors.violet1
                    defaultProperties["color"] = colors.violet3
                    hoverProperties = {...hoverProperties, ...defaultProperties}
                    focusProperties = {...focusProperties, ...defaultProperties}
                    break
                case "outlined":
                    defaultProperties["background-color"] = colors.gray0
                    defaultProperties["border-color"] = colors.gray3
                    defaultProperties["color"] = colors.gray5
                    hoverProperties = {...hoverProperties, ...defaultProperties}
                    focusProperties = {...focusProperties, ...defaultProperties}
                    break
                case "ghost":
                    defaultProperties["background-color"] = colors.gray0
                    defaultProperties["border-color"] = colors.gray5
                    defaultProperties["color"] = colors.gray5
                    hoverProperties = {...hoverProperties, ...defaultProperties}
                    focusProperties = {...focusProperties, ...defaultProperties}
                    break
                //non-primary/secondary/tertiary types default to lowered opacity
                default:
                    defaultProperties["opacity"] = ".5"
                    hoverProperties = {...hoverProperties, ...defaultProperties}
                    focusProperties = {...focusProperties, ...defaultProperties}
                    break
            }
        }

        // force default properties to match active state properties
        // (while still inheriting all already set default properties)
        // if the 'force active' prop is true
        if (forceActive) {
            defaultProperties = {...defaultProperties, ...hoverProperties, ...activeProperties}
        }

        // format the special extra dictionary with the CSS pseduo-class names
        // make the hover state a copy of the pressed state by reusing the active object
        const extrasObj = {
            "&:active": {...hoverProperties, ...activeProperties},
            "&:hover": hoverProperties,
            "&:focus": focusProperties,

            // lame workaround to stop the children inheriting the 'a' styles
            "span": {color: defaultProperties.color},

            "&:active span": { color: hoverProperties.color },
            "&:hover span": { color: hoverProperties.color },
            "&:focus span": { color: focusProperties.color },
        }

        // if this is a redux-form button,
        // make it full width and center the text
        if (isForm) {
            defaultProperties["justify-content"] = "center"
            defaultProperties["width"] = "inherit"
        }

        if (style) {
            defaultProperties = {
                ...defaultProperties,
                ...style,
            }
            // The extrasObj above's "span": {color: defaultProperties.color} change doesn't
            // take into account the "color" property getting updated here with this `style` prop.
            // We want to update the extrasObj.span with the new defaultProperties.color,
            // just for "borderless" aka "just text" buttons' color.
            if (style.color && type === "borderless") {
                extrasObj["span"] = {color: defaultProperties.color}
            }
        }

        // the CSS helper will format and combine the default and extra CSS objects into
        // a valid CSS string
        return cssHelper(
            defaultProperties,
            !disabled ? extrasObj : {}
        )
    }}

    // if we have 2 icons next to each other, give them some space
    span#ds-button-lefticon + span#ds-button-righticon {
        margin-left: 0.5em;
    }
`

export const Text = styled.span`
    ${({type}) => {
        if(type === "filled" || type === "outlined" || type === "ghost") {
            return
        }

        return css`
            margin-left: ${({ leftIcon }) => leftIcon ? "5px" : "0px"};
            margin-right: ${({ rightIcon }) => rightIcon ? "5px" : "0px"};
        `
    }}

    ${({type}) => {
        if (type === 'action-active') {
            return css`
                font-weight: 500;
            `
        }
        if (type === 'anchor') {
            return css`
                margin-right: 0px;
            `
        }
    }}
    font-style: ${({ fontStyle }) => fontStyle};
    ${({ maxTextWidth }) => {
        if (maxTextWidth) {
            return css`
                max-width: ${maxTextWidth};
                overflow: hidden;
            `
        }
    }}
    ${({textOverflow}) => {
        if (textOverflow) {
            return css`
                text-overflow: ${textOverflow};
            `
        }
    }}

    ${({ textBreakpoint }) => textBreakpoint && css`
        @media (max-width: ${textBreakpoint}px) {
            display: none;
        }
    `}
`

export const Icon = styled.span`
    font-size: ${({ iconSize }) => iconSize ? iconSize : rules.IconFontSize};
    ${({verticalOffset}) => {
        if (verticalOffset) {
            return css`
                position: relative;
                top: ${verticalOffset};
            `
        }
    }}

    & > .svg-inline--fa {
        display: block;
    }

    ${({type, id}) => {
        if (type === "resource-table-dropdown" && id === "ds-button-righticon") {
            return css`
                color: transparent;
            `
        }
        else if (type === 'action-idle-compressed') {
            return css`
                font-size: 17px;
                padding-top: 1px;
            `
        }
    }}

    ${({horizontalOffset}) => {
        if (horizontalOffset) {
            return css`
                margin-left: ${horizontalOffset};
            `
        }
    }}

    ${({ leftIconBreakpoint, textBreakpoint }) => (leftIconBreakpoint && textBreakpoint) && css`
        @media (max-width: ${leftIconBreakpoint}px) and (min-width: ${textBreakpoint + 1}px) {
            display: none;
        }
    `}

    ${({ leftIconBreakpointAtMinWidth }) => leftIconBreakpointAtMinWidth && css`
        @media (min-width: ${leftIconBreakpointAtMinWidth}px) {
            display: none;
        }
    `}
`

export const ButtonContainer = styled.div`
    ${props => (props.isForm) && css`
        width: 100%;
    `}

    display: ${({isGridButton}) => isGridButton ? "block" : "inline-block"};
    a {
        text-decoration: none;
    }

    ${({ textBreakpoint, rightIcon }) => (textBreakpoint && !rightIcon) && css`
        @media (max-width: ${textBreakpoint}px) {
            & > :first-child {
                justify-content: center;
                padding: 0;
                width: 30px;
            }
        }
    `}

    ${({ leftIconBreakpoint, textBreakpoint }) => (leftIconBreakpoint && textBreakpoint) && css`
        @media (max-width: ${leftIconBreakpoint}px) and (min-width: ${textBreakpoint + 1}px) {
            ${Text} {
                margin-left: 0;
            }
        }
    `}

    ${({ leftIconBreakpointAtMinWidth }) => leftIconBreakpointAtMinWidth && css`
        @media (min-width: ${leftIconBreakpointAtMinWidth}px) {
            ${Text} {
                margin-left: 0;
            }
        }
    `}
`

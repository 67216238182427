const cancel = "cancel"

/**
 * normalizeActions
 *
 * This function accepts an Object that should match one of several supported formats.
 * It then normalizes those actions into a single format to be consumed by the Alert.
 *
 * @param {Object} actions - An object of actions passed in to the alert.
 * Supports several formats to maintain backwards compatibility with sweetalert.
 *
 * @returns {Array} - An array of action objects in a standardized format that our Alert can consume.
 */
export const normalizeActions = (actions) => {
    if (actions === undefined || actions === null) {
        return []
    }

    return Object.entries(actions)
        // Check for a cancel key, and if found, bring it to the front, so it renders on the far-left
        .sort(([a, _a], [b, _b]) => a.toLowerCase() === cancel ? -1 : b.toLowerCase() === "cancel" ? 1 : 0)
        // Now run through each action and normalize them in order
        .reduce((accumulator, [actionKey, actionValue], index, array) => {
            const isLastButton = index === array.length - 1
            const buttonType = isLastButton ? "primary" : "secondary"

            /** If actionValue is a boolean, and the boolean is true:
             *
             * input:
             *  {
             *      confirm: true
             *  }
             *
             * output:
             *  {
             *      text: "Confirm",
             *      value: "confirm",
             *      type: "secondary" || "primary"
             *  }
             */
            if (typeof actionValue === "boolean" && actionValue) {
                // Handle the special case for { cancel: true }
                if (actionKey === cancel) {
                    return accumulator.concat([{
                        text: "Cancel",
                        value: null,
                        type: "secondary"
                    }])
                }
                return accumulator.concat([{
                    text: actionKey[0].toUpperCase() + actionKey.slice(1),
                    value: actionKey,
                    type: buttonType,
                }])
            }

            /** If actionValue is a string:
             *
             * input:
             *  {
             *      confirm: "Send Email"
             *  }
             *
             * output:
             *  {
             *      text: "Send Email",
             *      value: "confirm",
             *      type: "secondary" || "primary"
             *  }
             */
            if (typeof actionValue === "string") {
                // Handle the special case for { cancel: "Cancel" }
                if (actionKey === cancel) {
                    return accumulator.concat([{
                        text: actionValue,
                        value: null,
                        type: "secondary"
                    }])
                }
                return accumulator.concat([{
                    text: actionValue,
                    value: actionKey,
                    type: buttonType,
                }])
            }

            /** If actionValue is an object:
             *
             * input:
             *  {
             *      confirm: {
             *          text: "Send Email",
             *          value: true,
             *          type: "tertiary"
             *          closeAlert: false
             *      }
             *  }
             *
             * output:
             *  {
             *      text: "Send Email",
             *      value: true,
             *      type: "tertiary",
             *      closeAlert: false
             *  }
             */
            if (typeof actionValue === "object" && actionValue !== null && !Array.isArray(actionValue)) {
                return accumulator.concat([{
                    text: actionValue.text,
                    value: actionValue.value !== undefined ? actionValue.value : actionKey,
                    type: actionValue.type || buttonType,
                    ...(actionValue.hasOwnProperty("closeAlert") && { closeAlert: actionValue.closeAlert})
                }])
            }

            // If they passed in an array, undefined, etc we'll just ignore it
            return accumulator
        }, [])
}

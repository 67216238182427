import "QuorumGrassroots/widgets/DistrictList/components/style.styl"

import React, { useEffect, useState, useMemo } from "react"
import queryString from "query-string"
import PropTypes from "prop-types"

import { isFeatureEnabled } from "shared/featureflags/helperFunctions"

import DistrictList from "QuorumGrassroots/widgets/DistrictList/components/DistrictList"

function DistrictListWidget({
    title,
    districtsPagePath,
    location,
    loadWidgetContent,
    uniqueWidgetId,
    districtsList,
    loading,
}) {
    const [state, setState] = useState(null)

    useEffect(() => {
        const params = queryString.parse(location.search)

        setState(params.state?.toUpperCase() || null)
    }, [location.search])

    useEffect(() => {
        if (state) {
            loadWidgetContent({
                resource: DjangIO.app.geography.models.District,
                uniqueWidgetId,
                filter: { state__abbrev: state, limit: 1000 },
            })
        }
    }, [state])

    const isCurrentlyShowingADistrict = useMemo(() => {
        const params = queryString.parse(location.search)
        return params.cd !== undefined
    }, [location.search])

    if (!isFeatureEnabled("ff_gr_region_enhancements") || isCurrentlyShowingADistrict || !state) {
        return null
    }

    return (
        <DistrictList
            title={title}
            districtsPagePath={districtsPagePath}
            districtsList={districtsList}
            location={location}
            loading={loading}
        />
    )
}

DistrictListWidget.propTypes = {
    title: PropTypes.string,
    districtsPagePath: PropTypes.string,
    location: PropTypes.object,
    loadWidgetContent: PropTypes.func,
    uniqueWidgetId: PropTypes.string,
    districtsList: PropTypes.array,
    loading: PropTypes.bool,
}

export default DistrictListWidget

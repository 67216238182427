import { sendActions } from "shared/user-actions/user-actions-action-types"
import * as djangioActionCreators from "shared/djangio/action-creators"
import axios from "axios"

/**
 * An action creator that creates a sends a user action to the backend
 * This action creator needs to have the component's state mapped to dispatch
 * @param {object} userActionType - The UserActionType enum that corresponds to the user action we want to send
 * @param {string} description - Description of the useraction. What makes it unique, for legacy user actions, often have the QuorumDataType label
 * @param {string} query - For search bars, this is the string users entered into search
 * @param {number} quorumDataType - The corresponding QuorumDataType enum value
 * @param {number} forceSendAction - regardless of the value of logged_in_as_user, activated, or debug_mode Userdata
 *  values, send the action. This is useful for systems that rely on the user action system for the feature to work properly.
 * @returns {Promise} - A promise, with the response from posting an action, or a resolved promise
 * TODO refactor to take an object of arguments
 */
export const sendUserAction =
    (
        userActionType,
        description,
        query = null,
        quorumDataType = null,
        uri = null,
        is_favorited = false,
        forceSendAction = false,
    ) =>
    (dispatch) => {
        // Only send user action if a Quorum employee is not logged in as user, not working on dev, and user has been activated
        // In some cases, we use user actions to favorite items. In this case, we want to send the action regardless of the
        // environment
        if ((!Userdata.logged_in_as_user && Userdata.activated && !Userdata.debug_mode) || forceSendAction) {
            return dispatch(
                djangioActionCreators.resourcePostList(DjangIO.app.useractions.models.UserAction, sendActions, {
                    action_type: userActionType.value,
                    user: DjangIO.app.userdata.models.QuorumUser.foreignKey(Userdata.id),
                    current_url: (window && window.location && window.location.href) || "mobile",
                    description,
                    query,
                    data_type: quorumDataType,
                    ...(uri ? { [DjangIO.app.models.QuorumDataType.by_value(quorumDataType).related_field]: uri } : {}),
                    is_favorited,
                }),
            )
        }
        return Promise.resolve("No Action sent due to Userdata conditions.")
    }

/**
 * A method that creates a sends a user action to the backend
 * This method achieves the same results as 'sendUserAction' above,
 * but this method can be exported in a file and called,
 * no need to connect this method to the redux store.
 * @param {object} userActionType - The UserActionType enum that corresponds to the user action we want to send
 * @param {string} description - Description of the useraction. What makes it unique, for legacy user actions, often have the QuorumDataType label
 * @param {string} query - For search bars, this is the string users entered into search
 * @param {number} quorumDataType - The corresponding QuorumDataType enum value
 * @param {number} forceSendAction - regardless of the value of logged_in_as_user, activated, or debug_mode Userdata
 */
export const sendUserActionPost = ({
    userActionType,
    description = null,
    query = null,
    quorumDataType = null,
    regions = null,
    uri = null,
    forceSendAction = false,
} = {}) => {
    // Only send user action if a Quorum employee is not logged in as user, not working on dev, and user has been activated
    if ((!Userdata.logged_in_as_user && Userdata.activated && !Userdata.debug_mode) || forceSendAction) {
        const user = DjangIO.app.userdata.models.QuorumUser.resourceUriFromId(Userdata.id)
        DjangIO.app.useractions.models.UserAction.objects
            .create({
                user,
                action_type: userActionType.value,
                current_url: (window && window.location && window.location.href) || "mobile",
                description,
                query,
                data_type: quorumDataType,
                object_regions: regions && (Array.isArray(regions) ? regions : [regions]),
                ...(uri ? { [DjangIO.app.models.QuorumDataType.by_value(quorumDataType).related_field]: uri } : {}),
            })
            .POST()
    }
}

/**
 * This function takes in a list of UserAction kwargs and sends them to the backend, where one UserAction per object in userActions is created
 * @param {object} userActionType - The UserActionType enum that corresponds to the user actions we want to send
 * @param {array} userActions - A list of objects that contain UserAction fields (ex: data_type)
 * @param {number} forceSendAction - regardless of the value of logged_in_as_user, activated, or debug_mode Userdata
 */
export const bulkCreateUserActionPost = ({ userActionType, userActions = [], forceSendAction = false } = {}) => {
    // Only send user actions if a Quorum employee is not logged in as user, not working on dev, and user has been activated
    if ((!Userdata.logged_in_as_user && Userdata.activated && !Userdata.debug_mode) || forceSendAction) {
        if (userActions.length) {
            DjangIO.app.useractions.models.UserAction.objects
                .use_action("bulk_create")
                .create({
                    action_type: userActionType.value,
                    current_url: (window && window.location && window.location.href) || "mobile",
                    user_actions: userActions,
                })
                .POST()
        }
    }
}

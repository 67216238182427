import queryString from "query-string"
import React from "react"
import { Col } from "react-bootstrap"
import styled from "styled-components"

import { constants } from "QuorumGrassroots/constants"
import { NoTextDecorationLink } from "QuorumGrassroots/styled-components/components/NoTextDecorationLink"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import {
    getDynamicColorHex,
    getFontColorForBackgroundContrast,
    styleInDesktopScreenSize,
    styleInMobileScreenSize,
    styleWithOrgDesignHelper,
} from "QuorumGrassroots/styled-components/helperFunctions"
import {
    overrideCampaignInlineColumnStyling,
    overrideColumnStyling,
    overrideFullWidthBackgroundStyling,
    overrideHoverColor,
    overrideWidgetBackgroundColor,
    overrideWidgetBorderStyle,
} from "QuorumGrassroots/widgets/styled-components/helperFunctions"
import { hexCodetoRGBA, shadeColor } from "imports/desktopHelperFunctions"

const frameless = queryString.parse(window.location.search).frameless

const StyledMarginWrapper = styleWithOrgDesign(styled.div`
    background-color: ${constants.defaultBackgroundGrey};
    ${(props) => overrideWidgetBackgroundColor(props)}
    font-size: 16px;
    border-radius: 4px;

    ${(props) =>
        !props.paddingless &&
        `
        padding: 15px;
    `}

    ${(props) =>
        props.isCampaignPage &&
        props.activateNGGTheme &&
        `
        padding: 0;
    `}

    ${(props) =>
        !frameless &&
        `
        box-shadow: 0 7.5px 12.5px -4px rgba(64,64,64,0.2);
        border: 1px solid ${constants.defaultBorderColor};
        ${overrideWidgetBorderStyle(props)}
    `}

    ${(props) =>
        props.backgroundImage &&
        `
        border-top: none;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    `}

    ${(props) =>
        props.inline &&
        `
        border: 1px solid ${constants.defaultBorderColor};
        ${overrideWidgetBorderStyle(props)}

        &:hover {
            background-color: ${shadeColor(constants.defaultLightGrey, -10)};
            ${overrideHoverColor(props)}
        }
    `}
    ${(props) =>
        props.isCampaignDescription &&
        props.layout === DjangIO.app.grassroots.campaign.types.CampaignPageLayout.two_rows.value &&
        `${styleInDesktopScreenSize()}`}

    ${(props) =>
        props.isCampaignPage &&
        props.activateNGGTheme &&
        props.isForm &&
        `
            .control-label {
                color: ${getFontColorForBackgroundContrast(props.organizationDesign.primary_color)};
                margin-bottom: 0px;
                font-weight: 500;
            }

            .form-input-field:first-of-type {
                margin-top: 0px;
            }

            .form-input-field:not(:first-of-type):has(.mantine-MultiSelect-root) {
                margin-top: 0;
            }

            .form-input-field:not(:first-of-type) {
                margin-top: 16px;
            }

            .mantine-Stack-root > .form-input-field {
                margin-top: 0;
            }

            .input-field-wrapper {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
            }

            .col-md-6 .form-input-field {
                margin-top: 16px;
            }

            .col-md-12 .form-input-field {
                margin-top: 16px;
            }

            .mantine-InputWrapper-label {
                color: ${getFontColorForBackgroundContrast(props.organizationDesign.primary_color)};
                margin-bottom: 0px;
            }
            .flag-dropdown .arrow {
                left: 10px;
            }

            .form-input-field .form-input-header {
                display flex;
                align-items flex-start;
                flex-direction column;
            }

            .grid-toggle-button .btn-primary {
                color: ${getFontColorForBackgroundContrast(
                    getDynamicColorHex(props.organizationDesign.primary_color),
                    constants.freshDarkGrey,
                )};
                background-color: ${getDynamicColorHex(props.organizationDesign.primary_color)};
            }

            .form-input-group-field-option {
                color: ${getFontColorForBackgroundContrast(props.organizationDesign.primary_color)};
            }

            background-color: ${props.organizationDesign.primary_color};
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
            gap: 24px;
            flex: 1 0 0;
            border-radius: 8px;
            padding: 24px;
            @media(min-width: 1280px) {
                padding: 48px;
            }
    `}

    ${(props) =>
        props.isCampaignPage &&
        props.activateNGGTheme &&
        props.layout === DjangIO.app.grassroots.campaign.types.CampaignPageLayout.two_columns.value &&
        `
        height: 100%;
        justify-content: flex-start;
    `}
`)

const StyledCustomHeaderImage = styleWithOrgDesign(styled.img`
    width: 100%;
    @media (min-width: ${constants.colMdWidth}px) {
        width: ${(props) =>
            props.layout === DjangIO.app.grassroots.campaign.types.CampaignPageLayout.two_rows.value ? "50%" : "100%"};
    }
    background-color: ${(props) => props.organizationDesign.light_grey};
    border: 1px solid ${(props) => props.organizationDesign.primary_color};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-size: cover;
    ${(props) =>
        props.isCampaignPage && props.activateNGGTheme && `border-radius: 8px; border: none; margin-bottom: 24px;`}
`)

const StyledColumnComponent = styled(Col)`
    ${(props) => (!frameless || props.campaignInline) && "margin-bottom: 30px;"}
    ${(props) => !frameless && overrideColumnStyling(props)}
    ${(props) => props.inline && overrideCampaignInlineColumnStyling(props)}
    ${(props) =>
        props.isCampaignDescription &&
        props.layout === DjangIO.app.grassroots.campaign.types.CampaignPageLayout.two_rows.value &&
        `
            @media (min-width: ${constants.colMdWidth}px) {
                display: flex;
                align-items: flex-start;
                gap: 24px;
            }
            width: 100% !important;
        `}
    ${(props) => props.isCampaignPage && props.activateNGGTheme && `margin-bottom: 0; padding: 0;`}
    @media (max-width: ${constants.colMdWidth}px) {
        margin-bottom: 20px;
        padding-left: 5px;
        padding-right: 5px;
    }
    ${(props) =>
        props.isCampaignPage &&
        props.activateNGGTheme &&
        `
            margin-bottom: 0px;
            padding: 0px;
    `}
    ${(props) =>
        props.isCampaignPage &&
        props.activateNGGTheme &&
        props.isForm &&
        `@media (max-width: ${constants.mobileWidth}px) {
        margin-bottom: 0px;
        padding: 0px;
    }`}
    ${(props) =>
        props.isCampaignPage &&
        props.activateNGGTheme &&
        props.isCampaignDescription &&
        props.actionCenterSettings &&
        props.backgroundStyle === DjangIO.app.grassroots.enums.ActionCenterBackgroundStyle.color.value &&
        `color: ${getFontColorForBackgroundContrast(props.campaignBackgroundColor)};`}
`
const StyledColumn = styleWithOrgDesignHelper(StyledColumnComponent)

export const StyledColumnWidget = ({ children, backgroundImage, isForm, ...props }) => (
    <StyledColumn className="widget-col" isForm={isForm} {...Object.assign({ lg: 12, md: 12, sm: 12, xs: 12 }, props)}>
        {backgroundImage && (
            <StyledCustomHeaderImage
                src={backgroundImage}
                isCampaignPage={props.isCampaignPage}
                activateNGGTheme={props.activateNGGTheme}
                layout={props.layout}
            />
        )}
        <StyledMarginWrapper layout={props.layout} backgroundImage={backgroundImage} isForm={isForm} {...props}>
            {children}
        </StyledMarginWrapper>
    </StyledColumn>
)

export const StyledLinkColumnWidget = ({ children, to, isForm, ...props }) => (
    <StyledColumn
        className="link-widget-col"
        {...Object.assign({ lg: 12, md: 12, sm: 12, xs: 12 }, props, { "data-cy": undefined, "data-test": undefined })}
    >
        <NoTextDecorationLink to={to}>
            <StyledMarginWrapper isForm={isForm} {...props}>
                {children}
            </StyledMarginWrapper>
        </NoTextDecorationLink>
    </StyledColumn>
)

export const StyledWidgetHeader = styleWithOrgDesign(styled.h1`
    display: flex;
    align-items: center;
    font-size: 32px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 5px;

    color: ${(props) => props.organizationDesign.secondary_color};
    font-weight: 300;

    ${(props) =>
        props.headerColored &&
        `
        margin: -15px;
        padding: 15px;
        background-color: ${hexCodetoRGBA(props.organizationDesign.primary_color, 0.2)};
        margin-bottom: 0px;
    `}

    @media (max-width: ${constants.mobileWidth}px) {
        font-size: 24px;
    }

    ${(props) =>
        props.isCampaignPage &&
        props.activateNGGTheme &&
        `
        width: 100%;
        justify-content: center;
        font-size: 24px;
        padding-bottom: 0px;
        font-weight: bold;
        color: ${getFontColorForBackgroundContrast(props.organizationDesign.primary_color)};
        text-transform: ${props.shouldCapitalize ? "capitalize" : "none"};
    `}
    ${(props) =>
        props.isCampaignPage &&
        props.activateNGGTheme &&
        props.isCampaignTitle &&
        `
        font-size: 32px;
        justify-content: start;
        color: ${constants.freshDarkGrey};
        line-height: 1.4;

        ${styleInMobileScreenSize(`
            text-align: center;
        `)}

    `}

    ${(props) =>
        props.isCampaignPage &&
        props.activateNGGTheme &&
        props.isCampaignTitle &&
        props.actionCenterSettings &&
        props.backgroundStyle === DjangIO.app.grassroots.enums.ActionCenterBackgroundStyle.color.value &&
        //If we have a background color for the campaign, the dynamic color is being set on the parent according to this color
        `color: inherit;`}
`)

export const StyledHeaderLogoImage = styled.img`
    border-radius: 50%;
    margin-right: 10px;
    max-height: 100px;

    ${(props) => (props.logoImgBorderColor ? `border: 3px solid ${props.logoImgBorderColor};` : "")}
`

export const StyledTitleSection = styleWithOrgDesign(styled.div`
    color: ${(props) => props.organizationDesign.primary_color};
    display: inline-block;
    font-weight: bold;
`)

export const StyledSubtitle = styled.div`
    margin-top: 10px;
    color: black;
    font-size: 24px;

    @media (max-width: ${constants.mobileWidth}px) {
        font-size: 20px;
    }
`

export const StyledBackground = styleWithOrgDesign(styled.div`
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
    ${styleInMobileScreenSize(`
        margin-left: 5px;
        margin-right: 5px;
        ${(props) => !props.isLast && "margin-bottom: 20px;"}
    `)}
    ${(props) =>
        props.useBackgroundColor ? `background-color: ${props.backgroundColor};` : "background-color: white;"}
    ${(props) => overrideFullWidthBackgroundStyling(props)}
`)

export default StyledColumnWidget

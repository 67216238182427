import styled, { css } from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"


export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 35px;
`

export const Label = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 10px;
    color: ${colors.gray9};

    h2 {
        ${rules.GiantTextMediumWeight}
        margin: 0;
        margin-right: auto;
    }

    span {
        ${rules.SmallTextRegularWeight}
        cursor: pointer;
    }
`

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.numColumns}, 1fr);
    grid-gap: 18px;
    margin-bottom: 18px;

    &:last-child {
        /* Transition the collapsed content smoothly into view */
        visibility: ${props => props.isVisible ? "visible" : "collapse"};
        /* We need to set the height to 0, because chrome only partially implemented visibility: collapse */
        height: ${props => props.isVisible ? "auto" : "0px"};
        position: relative;
        top:${props => props.isVisible ? "0px" : "-15px"};
        opacity: ${props => props.isVisible ? "100%" : "50%"};
        transition: top .1s, opacity .1s;
    }
`

export const Caret = styled.span`
    svg {
        ${props => props.isOpen &&
            css`transform: rotate(-180deg);`
        }
    }
`

import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

import Button from "Components/Molecules/Button"
import TextInput from "Components/Molecules/TextInput"
import TimePicker from "Components/Molecules/DatePicker/TimePicker"

import { useOnClickOutside } from "utils/hooks"

import * as S from "Components/Molecules/DatePicker/style"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

const DatePicker = ({
    dataCy,
    dateFormat,
    description,
    disabled,
    errorMessage,
    is12Hour,
    isClearable,
    label,
    maxDate,
    minDate,
    onChange,
    placeholder,
    showTimeInput,
    value
}) => {
    const [selectedDate, setSelectedDate] = useState(value)
    const [isOpen, setIsOpen] = useState(false)
    const inputWrapperRef = useRef()

    const WrappedTimePicker = (props) => (
        <React.Fragment>
            <S.HR />
            <TimePicker
                is12Hour={is12Hour}
                timezone={dayjs().format("z")}
                {...props}
            />
        </React.Fragment>
    )

    const resetTime = () => {
        if (isOpen) {
            setSelectedDate(value)
            setIsOpen(false)
        }
    }

    const persistTime = () => {
        onChange(selectedDate)
        setIsOpen(false)
    }

    useOnClickOutside(inputWrapperRef, resetTime)

    const dateToString = (date) => (
        dayjs(date)
        .format(showTimeInput
            ? `${dateFormat} | ${is12Hour ?"h:mma z" : "HH:mm z"}`
            : dateFormat
        )
    )

    useEffect(() => {
        setSelectedDate(value)
    }, [value])

    const CustomInput = React.forwardRef(({ value, onFocus }, ref) => (
        <TextInput
            dataCy="datepicker-input"
            description={description}
            disabled={disabled}
            errorMessage={errorMessage}
            ref={ref}
            isActive={isOpen}
            label={label}
            leftIcon={showTimeInput ? "clock" : "calendar-alt"}
            leftIconFamily="far"
            onFocus={onFocus}
            placeholder={placeholder || dateToString(new Date())}
            value={value && dateToString(value)}
            isCentered
            wrapperStyle={{
                paddingLeft: "12px",
                width: showTimeInput ? "240px" : "130px"
            }}
        />
    ))

    return (
        <S.DatePickerOuterWrapper
            data-cy={dataCy}
            data-auto-cy="MoleculeDatePicker"
            ref={inputWrapperRef}
        >
            <S.DatePickerWrapper
                customInput={<CustomInput />}
                customTimeInput={<WrappedTimePicker />}
                dateFormat="yyyy-MM-dd'T'HH:mm:ss.SSSXXX"
                isClearable={isClearable}
                maxDate={maxDate}
                minDate={minDate}
                onChange={setSelectedDate}
                onFocus={() => setIsOpen(true)}
                open={isOpen}
                selected={selectedDate}
                showTimeInput={showTimeInput}
                timeCaption=""
            >
                <S.ButtonWrapper>
                    <Button
                        dataCy="datepicker-cancel"
                        onClick={resetTime}
                        text="Cancel"
                        type="secondary"
                    />
                    <Button
                        dataCy="datepicker-apply"
                        onClick={persistTime}
                        text="Apply"
                    />
                </S.ButtonWrapper>
            </S.DatePickerWrapper>
        </S.DatePickerOuterWrapper>
    )
}

DatePicker.defaultProps = {
    dateFormat: "MM/D/YYYY",
    is12Hour: true
}

DatePicker.propTypes = {
    dataCy: PropTypes.string,
    dateFormat: PropTypes.string,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
    is12Hour: PropTypes.bool,
    isClearable: PropTypes.bool,
    label: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    showTimeInput: PropTypes.bool,
    value: PropTypes.instanceOf(Date),
}

export default DatePicker

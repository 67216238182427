import styled from "styled-components"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"

import { Button, Modal } from "react-bootstrap"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"

import { constants } from "QuorumGrassroots/constants"

// TODO: Get Actor font family for StyledPointsMessage and StyledThanksMessage
// const fontFamily = 'actor'

const modalWidth = 800

export const StyledTierModal = styled(Modal)`
    display: flex !important;
    align-items: center;

    .modal-dialog {
        width: ${modalWidth}px;
    }

    .modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (min-width: ${constants.colMdWidth + 1}px) {
            width: ${modalWidth}px;
            padding-left: 60px;
            padding-right: 60px;
        }

        @media (max-width: ${constants.colMdWidth + 1}px) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
`

export const StyledExitButton = styled(Button)`
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
`

export const StyledTierHeader = styleWithOrgDesign(styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.organizationDesign.primary_color};
    margin-top: 30px;
    margin-bottom: 20px;

    i {
        font-size: 93px;
        color: ${(props) => props.organizationDesign.secondary_color};

        @media (max-width: ${constants.mobileWidth + 1}px) {
            display: none;
        }
    }

    span {
        margin-left: 35px;
        margin-right: 35px;
        font-size: 60px;
        text-align: center;
        word-break: break-word;

        @media (max-width: ${constants.mobileWidth + 1}px) {
            font-size: 55px;
        }
    }
`)

export const StyledTierImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledTierImage = styled.div`
    height: 240px;
    background-image: url("${(props) => props.imagePath}");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
`

export const StyledPointsMessage = styleWithOrgDesign(styled.div`
    color: ${(props) => props.organizationDesign.secondary_color};
    font-size: 28px;
    text-align: center;
    margin-bottom: 5px;
`)

export const StyledThanksMessage = styled.div`
    font-size: 22px;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
`

export const StyledAcknowledgeButton = styled(StyledButton)`
    width: 57%;
    font-size: 17px;
    font-weight: 300;
    height: 58px;
    margin-top: 45px;
    margin-bottom: 55px;
`

import React from "react"
import { connect } from "react-redux"
import { selectFieldConnection } from "frontend/forms/selectors"
import PropTypes from "prop-types"
import classNames from "classnames"

// components
import DiscreteSelect from "app/static/frontend/selects/components/DiscreteSelect"
import ReduxFormFieldWrapper from "app/static/frontend/forms/components/ReduxFormFieldWrapper"

// styles
import "app/static/stylus/FormStyles.styl"
import "frontend/forms/stylus/SelectField.styl"

export const SelectField = (props) => {
    const {
        accessibilityId,
        choices,
        className,
        clearable,
        dataCy,
        defaultLabel,
        defaultValue,
        displayErrorWithoutTouch,
        dropUp,
        isDisabled,
        isLoading,
        label,
        labelStyle,
        menuPortalTarget,
        meta: { error, warning, touched },
        multi,
        noResultsText,
        onChange,
        onMenuOpen,
        selectLabel,
        shouldNotShowClearButton,
        shouldRenderNewSelect,
        showAsterisk,
        style,
        tooltipText,
        value,
    } = props
    const shouldDisplayError = displayErrorWithoutTouch || touched

    return (
        <ReduxFormFieldWrapper
            accessibilityId={accessibilityId}
            additionalClassNames={className}
            className="select"
            dataCy={dataCy}
            displayErrorWithoutTouch={displayErrorWithoutTouch}
            error={error}
            label={label}
            labelStyle={labelStyle}
            showAsterisk={showAsterisk}
            style={style}
            tooltipText={tooltipText}
            touched={touched}
            warning={warning}
        >
            <div
                className={classNames({
                    "select-error-outline": error && shouldDisplayError,
                    "select-warning-outline": warning && shouldDisplayError,
                })}
            >
                <DiscreteSelect
                    async={false}
                    dataCy={dataCy}
                    value={value ? value : props.input.value ? props.input.value : defaultValue}
                    getItems={() => choices}
                    onChange={props.input.onChange ?? onChange}
                    onMenuOpen={onMenuOpen}
                    placeholder={defaultLabel}
                    isLoading={isLoading}
                    noResultsText={noResultsText}
                    multi={multi}
                    backspaceRemoves={false}
                    menuPortalTarget={menuPortalTarget}
                    className={dropUp ? "drop-up" : ""}
                    clearable={clearable && !shouldNotShowClearButton}
                    shouldRenderNewSelect={shouldRenderNewSelect}
                    selectLabel={selectLabel}
                    isDisabled={isDisabled}
                    renderOption={props.renderOption}
                />
            </div>
        </ReduxFormFieldWrapper>
    )
}

SelectField.defaultProps = {
    accessibilityId: "",
    clearable: true,
    dataCy: "",
    displayErrorWithoutTouch: true,
    style: {},
}

SelectField.propTypes = {
    accessibilityId: PropTypes.string,
    choices: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            label: PropTypes.string.isRequired,
        }),
    ).isRequired,
    className: PropTypes.string,
    clearable: PropTypes.bool,
    dataCy: PropTypes.string,
    defaultLabel: PropTypes.string,
    displayErrorWithoutTouch: PropTypes.bool,
    dropUp: PropTypes.bool,
    input: PropTypes.object,
    isLoading: PropTypes.bool,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    meta: PropTypes.object,
    multi: PropTypes.bool,
    noResultsText: PropTypes.string,
    onMenuOpen: PropTypes.func,
    selectLabel: PropTypes.string,
    shouldRenderNewSelect: PropTypes.bool,
    shouldNotShowClearButton: PropTypes.bool,
    style: PropTypes.object,
    tooltipText: PropTypes.string,
}

export default connect(selectFieldConnection, {})(SelectField)

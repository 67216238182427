import { connect } from "react-redux"

import {
    completeCampaign,
    postGrassrootsSupporterAction,
    userCompletedCampaign,
} from "QuorumGrassroots/campaign-forms/action-creators"
import { TweetForm } from "QuorumGrassroots/campaign-forms/components/TweetForm/index"

const actions = {
    completeCampaign,
    postGrassrootsSupporterAction,
    userCompletedCampaign,
}

export default connect(null, actions)(TweetForm)

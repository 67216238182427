import styled, { css } from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

import Icon from "Components/Atoms/Icon"


export const SelectWithRemovableValues = styled.div``

export const ValueStripsContainer = styled.div`
    max-height: 105px;
    max-width: 400px;
    overflow-y: auto;
    border: 1px solid ${colors.IconBorder};
    border-bottom: none;
`

export const ValueStrip = styled.div`
    background-color: ${colors.White};
    color: ${colors.InputTextColor};
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 5px;
    align-items: center;
    height: 30px;
    &:first-of-type {
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }
    &:last-of-type {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }
`

export const ValueStripText =  styled.span`
    ${rules.SmallTextSmallHeightRegularWeight}
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 90%;
    padding-right: 10px;

    ${({ cannotClick }) => {
        if (!cannotClick) {
            return css`
                &:hover {
                    cursor: pointer;
                }
            `
        }
    }}
`

export const RemoveIcon = styled(Icon)`
    &:hover {
        cursor: pointer;
    }
`

import { css } from "styled-components"

/**
 * Takes an object representing pairs of CSS properties and values
 * and returns the CSS as a string
 * @param   {Object} obj    an object with KVPs of <css property name, property value>
 *
 * @returns {String} a string of valid CSS created from the object
 */
export const objToCSS = (obj) => {
    // handle properties with multiple values separated by commas
    // by only replacing commas not enclosed by quotes
    // e.g. defaultProperties["transition"] = "background-color .3s ease-in-out, color .3s ease-in-out"
    return JSON.stringify(obj).replace(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/g, ';\n')
        .replace(/["{]/g, '')
        .replace(/}/g, ';\n')
}

/**
 * Takes an object for the top-level CSS and an object holding extra CSS objects and
 * returns the CSS as a string
 * @param   {Object} obj        an object with KVPs of <css property name, property value> which represents the top-level CSS properties
 * @param   {Object} extrasObj  an object with KVPs of <pseudo class selector, cssObj>; each pair should have a singly-nested CSS
 *                                  pseudo class selector and an object of the same form as obj holding it's CSS properties
 *
 * @returns {String} a string of valid CSS created from the given objects
 */
export const cssHelper = (obj, extrasObj) => {
    const extrasCSS = Object.keys(extrasObj).map((key, idx) => (
        "\n" + key + "{\n" + objToCSS(extrasObj[key]) + "}"
    )).join("\n")

    const defaultCSS = objToCSS(obj)

    return defaultCSS + "\n" + extrasCSS
}

/**
 * @name flexGap
 *
 * Approximates the behavior of the flex-gap rule.
 * Should only be called from a parent component with the display: flex rule.
 *
 * @param {Number} gap The size of the gap
 * @param {Boolean} isColumn Whether the parent flexbox has flex-direction: column. Defaults to false
 * @returns A css rule that applies the given margin to all but the last child.
 */
export const flexGap = ({ gap, isColumn = false }) => {
    const marginRule = isColumn ? "margin-bottom" : "margin-right"

    return css`
        & > :not(:last-child) {
            ${marginRule}: ${gap}px;
        }
    `
}

/**
 * @function discreteColorGradient
 * Create a linear-gradient CSS argument for a band where the colors are
 * in discrete segments, rather than blended gradients.
 *
 * @param {Array} values - percentage breakpoints; where in the gradient to end
 *                          each color and begin the next one
 * @param {Array} colors - CSS colors to use for each segment of the gradient;
 *                          must be at least as long as 1 + values.length
 * @returns {String} - CSS for a linear-gradient rule (e.g. the argument for background)
 */
export const discreteColorGradient = (values, colors) => {
    const doubledValues = values.map((v) => [v, v]).flat()
    const adjustedValues = [0, ...doubledValues, 100]
    const colorStrings = adjustedValues.map((value, idx) => {
        return `${colors[Math.floor(idx / 2)]} ${value}%`
    })
    return `linear-gradient(to right, ${colorStrings.join(", ")})`
}

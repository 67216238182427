/*
 * Quorum Select tool
 *
 * This is the quorum object selector it makes a searchable list with
 * a function for determining
 *  - inputs:
 *       objArray - list of objects
 *       rowLimits - list of rows
 *       args for typeahead - see typeahead coffee
 *       stateKey - what state should be updated
 *       handleSelectionChange - method that given a key, and value will update
 *       handleOnAddObject - method called before object is added -
 *           allow for changes
 *   optional:
         handle object delete - method to run to handle delete
         handle object add - method to run to handle object addition,
             (be sure to close select when you do it)
 *
*/

import React from "react"
import QuorumTypeAhead from "./QuorumTypeAhead"
import SelectedObjects from "./SelectedObjects"
import PropTypes from "prop-types"
import swal from 'sweetalert'


import "app/static/stylus/reusable_components/quorum_obj_selector.styl"

const propTypes = {
    objectArray: PropTypes.arrayOf(PropTypes.object).isRequired,
    rowLimits: PropTypes.objectOf(PropTypes.number).isRequired,
    typeAheadArgs: PropTypes.arrayOf(PropTypes.object).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    stateKey: PropTypes.string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    handleSelectionChange: PropTypes.func.isRequired,
    handleObjDelete: PropTypes.func,
    handleNewSelection: PropTypes.func,
    handleOnAddObject: PropTypes.func,
    handleOnClickObj: PropTypes.func,
    determinePosition: PropTypes.func,
    determineStyle: PropTypes.func,
    placeholder: PropTypes.string,
    hideTypeAhead: PropTypes.bool,
    debounceRate: PropTypes.number,
    // eslint-disable-next-line react/no-unused-prop-types
    objsAreEqual: PropTypes.func,
}

const defaultProps = {
    debounceRate: 300,
    hideTypeAhead: false,
    /*
     * Compare function
     *    - returns true if they are the same
     */
    // default method to determine ordering for objects
    determinePosition(quorumObj) {
        if (!("quorumObjType" in quorumObj)) {
            // eslint-disable-next-line no-param-reassign
            quorumObj.quorumObjType = "other"
        }
        return 10
    },

    // this is used to determine the styling of the tag
    determineStyle(quorumObj) {
        if (!("quorumObjType" in quorumObj)) {
            // eslint-disable-next-line no-param-reassign
            quorumObj.quorumObjType = "other"
        }
        return "purple-tag-1"
    },

    customIdFunc(quorumObj) {
        return quorumObj.id
    },

    objsAreEqual(obj1, obj2) {
        return obj1.id === obj2.id && obj1.quorumObjType === obj2.quorumObjType
    },

    // this function is necessary for the EventParticipant use case. Event Participants
    // can be users, teams, etc, but are converted into EventParticipant objects upon
    // form submission and when they are reloaded. Because an EventParticipant object
    // compared with a User object is automatically different by objsAreEqual standards,
    // this method is here to compensate. We assume that obj2 will always be a
    // non-EventParticipant object.

    // the function compares resource_uris with the eventParticipant's foreign key URI.
    eventParticipantsAreEqual(obj1, obj2) {
        if (obj1.participant_type) {
            const participantType = DjangIO.app.models.QuorumDataType.by_value(obj1.participant_type)
            const participantKey = participantType.event_participant_key
            const participantUri = obj1[participantKey]
            return participantUri === obj2.resource_uri
        }
        return false
    },

    // method that returns when and object is clicked
    handleOnClickObj(quorumObj) {
        // eslint-disable-next-line no-console
        console.info("Clicked", quorumObj)
    },

    handleOnAddObject(quorumObj) {
        // eslint-disable-next-line no-console
        console.info("Added", quorumObj)
        return true
    },

    // handle object delete - remove if id doesn't match
    handleObjDelete(quorumObj) {
        this.props.handleDebouncedSelectionDelete
            // Use the debounced selection delete callback if available
            // It's a little clumsy that the two callbacks have different signatures,
            // but the debounced version cannot work with the old signature
            ? this.props.handleDebouncedSelectionDelete({key: this.props.stateKey, quorumObj})
            : this.props.handleSelectionChange({
                [`${this.props.stateKey}`]: this.props.objectArray.filter(d =>
                    !this.props.objsAreEqual(d, quorumObj)
                ),
            })
    },

    // handles object selection (can execute clears too)
    handleNewSelection(quorumObj, clearStateFunc) {
        // set fields
        // eslint-disable-next-line no-param-reassign
        quorumObj.position = this.props.determinePosition(quorumObj)
        // eslint-disable-next-line no-param-reassign
        quorumObj.styling = this.props.determineStyle(quorumObj)

        // method to call when we are adding the object
        if (this.props.handleOnAddObject(quorumObj) === false) {
            clearStateFunc()
            return
        }

        // make list of duplicates
        const duplicates = this.props.objectArray.filter(d =>
            this.props.objsAreEqual(d, quorumObj) ||
            this.props.eventParticipantsAreEqual(d, quorumObj)
        )

        // if the duplicates don't exist
        if (duplicates.length === 0) {
            this.props.handleDebouncedSelectionChange
                ? this.props.handleDebouncedSelectionChange({key: this.props.stateKey, quorumObj})
                : this.props.handleSelectionChange({
                    [`${this.props.stateKey}`]: this.props.objectArray.concat(quorumObj),
                })
        } else {
            swal("Sorry", "You have already added that item - try again", "warning")
        }
        clearStateFunc()

    },
}

export default class QuorumObjSelector extends React.Component {

    // method to set min and max rows
    static calculateHeights(numRows) {
        return (numRows * 53) + 8
    }

    constructor(props) {
        super(props)
        this.handleObjDelete = this.props.handleObjDelete.bind(this)
        this.handleNewSelection = this.props.handleNewSelection.bind(this)
        this.handleOnClickObj = this.props.handleOnClickObj.bind(this)
        this.handleOnAddObject = this.props.handleOnAddObject.bind(this)
    }

    render() {
        // format the object array
        const objects = this.props.objectArray.map((quorumObj) => {
            // We want to mutate these just incase
            // eslint-disable-next-line no-param-reassign
            quorumObj.position = this.props.determinePosition(quorumObj)
            // eslint-disable-next-line no-param-reassign
            quorumObj.styling = this.props.determineStyle(quorumObj)
            return quorumObj
        })
        return (
            <div className="quorum-obj-selector">
                <SelectedObjects
                    tagObjects={objects}
                    onObjDelete={this.handleObjDelete}
                    onClickObj={this.props.handleOnClickObj}
                    minHeight={QuorumObjSelector.calculateHeights(this.props.rowLimits.min)}
                    maxHeight={QuorumObjSelector.calculateHeights(this.props.rowLimits.max)}
                />
                <QuorumTypeAhead
                    onUserSelection={this.handleNewSelection}
                    settings={this.props.typeAheadArgs}
                    placeholder={this.props.placeholder}
                    debounceRate={this.props.debounceRate}
                    hideTypeAhead={this.props.hideTypeAhead}
                />
            </div>
        )
    }
}

QuorumObjSelector.propTypes = propTypes
QuorumObjSelector.defaultProps = defaultProps

import { RefObject, useEffect, useState, useRef } from "react"

/**
 * Stubbed for now, since the Quorum app is pretty desktop-centric.
 * Could move this to utils and do a device sniff.
 */
const isHandheldDevice = () => false

/**
 * Autofocus an input, via a ref. Uncomment types for TypeScript conversion.
 *
 * Usage:
 *
 *     const inputRef = useFocusInput()
 *     return <input ref={inputRef} />
 */
export const useFocusInput = (autofocusMobile = true) /*<HTMLInputElement>*/ => {
    const inputRef = useRef(/*<HTMLInputElement>*/ null)
    const [focused, setFocused] = useState(false)
    useEffect(() => {
        const id = setTimeout(() => {
            const wantFocus = !focused && (autofocusMobile || !isHandheldDevice())
            if (inputRef.current !== null && wantFocus) {
                inputRef.current.focus()
                setFocused(true)
            }
        }, 75)
        return () => clearTimeout(id)
    })
    return inputRef
}

import React from "react"
import PropTypes from "prop-types"

import DropdownButton from "Components/Molecules/DropdownButton"
import Icon from "Components/Atoms/Icon"

import * as S from "Components/Molecules/FilterButton/style"

const MAX_ITEMS_TO_RENDER = 3

class FilterButton extends React.Component {

    renderSwitch() {
        return (
            <S.SwitchContainer
                data-auto-cy="MoleculeFilterButton"
                isTiny={this.props.isTiny}
            >
            {
                this.props.menuOptions.map((option, idx) => (
                    <S.SwitchOption
                        isSelected={this.props.selectedIndex === idx}
                        onClick={option.onClick}
                        key={`${option.icon}-${option.text}`}
                        data-cy={option.dataCy}
                        isTiny={this.props.isTiny}
                    >
                        {
                            option.icon && (
                                <S.SwitchIconSpan
                                    iconBreakpoint={this.props.leftIconBreakpoint}
                                    padRight={Boolean(option.text)}
                                    textBreakpoint={this.props.textBreakpoint}
                                >
                                    <Icon
                                        icon={option.icon}
                                        iconFamily={option.iconFamily}
                                        fixedWidth={this.props.leftIconFixedWidth}
                                    />
                                </S.SwitchIconSpan>
                            )
                        }
                        <S.SwitchText textBreakpoint={this.props.textBreakpoint}>
                            {option.text}
                        </S.SwitchText>
                    </S.SwitchOption>
                ))
            }
            </S.SwitchContainer>
        )
    }

    renderDropdown() {
        const selectedOption = this.props.menuOptions[(this.props.selectedIndex ? this.props.selectedIndex : 0)]
        return (
            <DropdownButton
                dataCy="MoleculeFilterButton"
                isTiny={this.props.isTiny}
                // wrap the onclicks for each option in the change selected method
                // so that the dropdown calls the change selected method when the
                // option is clicked
                menuOptions={this.props.menuOptions.map((option, idx) => ({
                    onClick: option.onClick,
                    ...option
                }))}
                leftIcon={selectedOption && selectedOption.icon}
                text={selectedOption && selectedOption.text}
                type="outlined"
            />
        )
    }

    render() {
        // if there are fewer items than the max, use the switch style layout
        // return switch option components wrapped in a container
        // if there are more items than the max, use a tertiary dropdown instead
        return this.props.menuOptions.length <= MAX_ITEMS_TO_RENDER
            ? this.renderSwitch()
            : this.renderDropdown()
    }
}


FilterButton.defaultProps = {
    leftIconFixedWidth: true,
}

FilterButton.propTypes = {
    isTiny: PropTypes.bool,
    leftIconBreakpoint: PropTypes.number,
    leftIconFixedWidth: PropTypes.bool,
    menuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            dataCy: PropTypes.string,
            text: PropTypes.string,
            icon: PropTypes.string,
            iconFamily: PropTypes.string,
            onClick: PropTypes.func,
        })
    ),
    selectedIndex: PropTypes.number,
    textBreakpoint: PropTypes.number,
}

export default FilterButton

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/ModalHeader/style"

import Icon from "Components/Atoms/Icon"
import TopBar from "Components/Compounds/TopBar"

const ModalHeader = ({
    boldTitle,
    hideCloseButton,
    onClickBack,
    onClose,
    title,
}) => {

    return (
        <S.Header data-auto-cy="MoleculeModalHeader" boldTitle={boldTitle}>
            <TopBar
                noLine={true}
                onClickBack={onClickBack}
                subtitle={boldTitle}
                title={title}
            >
                {hideCloseButton ? null : (
                    <S.Icon
                        boldTitle
                        onClick={onClose}
                    >
                        <Icon icon="times" iconFamily='far' fixedWidth={false}/>
                    </S.Icon>
                )}
            </TopBar>
        </S.Header>
    )
}

ModalHeader.propTypes = {
    boldTitle: PropTypes.string,
    hideCloseButton: PropTypes.bool,
    onClickBack: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
}

export default ModalHeader

// https://projects.invisionapp.com/d/main/#/console/18861190/444895171/inspect
// https://projects.invisionapp.com/share/H4102MKAXEYZ#/screens/444515231

import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

import {
    ButtonContainer,
    Text,
} from "Components/Molecules/Button/style"

export const TopNavPopdown = styled.div`
    background-color: ${colors.White};
    max-height: 100vh;
    position: fixed;
    width: 100%;
    z-index: 11;

    ${(props) => props.popdownIsOpen && css`
        ${(props) => props.isSearchify && css`
            border-radius: 4px;
            position: unset;
        `}
        ${(props) => !props.isSearchify && css`
            border-bottom: 1px solid ${colors.gray2};
            box-shadow: 0px 12px 40px -12px rgba(0, 0, 0, 0.20);
        `}
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: auto;
    `}
`

export const TopNav = styled.div`
    align-items: flex-start;
    background-color: ${colors.QuorumBlue};
    display: flex;
    padding-right: 11.5px;

    ${(props) => props.isSearchify && css`
        padding-bottom: 10px;
        justify-content: space-between;
    `}

    ${(props) => props.loggedInAsUser && css`
        background-color: ${colors.LightPurple};
    `}

    ${(props) => {
        if (props.popdownIsOpen) {
            return css`
                background-color: ${colors.White};
            `
        } else {
            return css`
                height: 60px;
            `
        }
    }}

& > ${ButtonContainer} {
        margin: 10px 0 0 11px;
    }

    ${(props) => !props.isSearchify && css`
        gap: 24px;
        padding: 10px 16px;
        background-color: ${colors.violet7};
        justify-content: space-between;
        height: 60px;
        align-items: center;

        ${(props) => props.loggedInAsUser && css`
            background-color: ${colors.violet2};
        `}

        ${(props) => props.popdownIsOpen && css`
            background-color: ${colors.White};
        `}
    `}
`

export const QuorumHomeButton = styled.span`
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
    transition: background-color .3s ease-in-out;

    ${(props) => props.popdownIsOpen && css`
        &:hover {
            background-color: ${colors.White};
        }
    `}

    ${(props) => !props.popdownIsOpen && css`
        &:hover {
            background-color: ${props.loggedInAsUser ? colors.DarkPurple : colors.CloudBurst};
        }
    `}

    ${(props) => props.loggedInAsUser && css`
        &:hover {
            background-color: ${colors.violet2};
        }
    `}

    &:hover {
        background-color: ${colors.violet7};
    }
`

export const TopNavSearchifyTitle = styled.span`
    ${rules.SmallTitleMediumWeight}
    color: ${colors.gray9};
    font-size: 24px;
    margin-top: 10px;
    padding: 0 22px;
`

export const RegionButton = styled.button`
    padding: 8px 12px 8px 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${colors.White};
    background-color: ${colors.violet7};
    border: 0;
    border-radius: 4px;

    &:hover {
        background-color: ${colors.violet9};
    }

    ${(props) => props.loggedInAsUser && css`
        color: ${colors.violet9};
        background-color: ${colors.violet2};

        &:hover {
            background-color: ${colors.violet3};
        }
    `}

    ${(props) => props.popdownIsOpen && css`
        color: ${colors.gray9};
        background-color: ${colors.White};

        &:hover {
            background-color: ${colors.violet1};
        }
    `}

    ${(props) => props.isInRegion && css`
        color: ${colors.White};
        background-color: ${colors.violet6};

        &:hover {
            background-color: ${colors.violet9};
        }
    `}

    .region-button--content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .region-button--label {
        font-size: .625rem;
        text-transform: uppercase;
    }

    .region-button--name {
        font-size: .875rem;
        font-weight: 500;
    }
`

export const TopNavRegionSelectorButton = styled.div`
    align-items: center;
    display: flex;
    font-size: ${rules.GiantButtonFontSize};
    height: 60px;
    margin-right: 11px;
    padding-left: 17px;
    padding-right: 14px;
    white-space: nowrap;

    ${Text} {
        font-size: 16px;
    }

    ${(props) => props.popdownIsOpen && css`
        background-color: ${colors.White};
        color: ${colors.QuorumBlue};
    `}

    ${(props) => !props.popdownIsOpen && css`
        color: ${colors.White};
    `}
`
export const TopNavRegionSelectorButtonCurrentRegionIconContainer = styled.div`
    fill: ${colors.White};
    font-size: 16px;
    line-height: 30px;
    padding: 0px 4px;

    ${(props) => props.popdownIsOpen && css`
        fill: ${colors.QuorumBlue};
    `}
`
export const TopNavRegionSelectorButtonText = styled.div`
    padding: 0 4px;
`

export const TooltipPositioner = styled.div`
    & > :first-child {
        left: calc(-100% - 19px);
        opacity: 0%;
        top: 40px;
        transition-duration: ${rules.TransitionShort};
        visibility: hidden;
    }
`

export const TooltipContainer = styled.div`
    margin: 0;
    position: relative;

    :not(&:hover) {
        ${TooltipPositioner} {
            & > :first-child {
                opacity: 0%;
                transition-duration: ${rules.TransitionShort};
                visibility: hidden;
                width: 150px;
            }
        }
    }

    &:hover {
        ${TooltipPositioner} {
            & > :first-child {
                opacity: 100%;
                transition-duration: ${rules.TransitionShort};
                visibility: visible;
                width: 150px;
            }
        }
    }
`

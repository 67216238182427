import styled, { css } from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"


export const LinkGuide = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border: 1px solid ${colors.gray2};
    border-radius: 4px;
    padding: 1rem 2rem;

    &:hover {
        cursor: pointer;
    }
`

export const GuideStep = styled.div`
    ${rules.SmallText}
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${colors.QuorumBlue};
    color: ${colors.White};

    display: flex;
    justify-content: center;
    align-self: flex-start;
    align-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 6px;
    margin-top: 2px;
`

export const IconWrapper = styled.div`
    display: flex;
    padding: 4px 0;
    ${props => props.isFeatured ?
        css`
            ${colors.duotonePurple}

            & > svg {
                height: 40px;
                width: 40px;
                margin-right: 18px;
                align-self: center;
            }
        ` :
        css`
            color: ${props => props.isRead ? colors.violet7 : colors.QuorumBlue};
            align-self: flex-start;

            & > svg {
                height: 20px;
                width: 20px;
                margin-right: 6px;
            }
        `
    }
`

export const VideoIcon = styled.div`
    margin-right: 6px;
    width: 18px;
    color: ${colors.QuorumBlue};
    align-self: flex-start;
`

export const Headline = styled.p`
    & > :first-child {
        ${props => props.isFeatured ? rules.GiantText : rules.LargeText}
        line-height: 27px;
        color: ${colors.QuorumBlue};
        padding: 4px 0;
        border-radius: 4px;
    }

    ${LinkGuide}:hover & > :first-child {
        background-color: ${colors.violet1};
        box-shadow: 4px 0 0 ${colors.violet1}, -4px 0 0${colors.violet1};
        -webkit-box-decoration-break: clone;
    }
`

export const Status = styled.span`
    ${rules.SmallText}
    color: ${colors.violet7};
    margin-left: 18px;
`

import styled from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const InputContainer = styled.div`
`

export const Label = styled.span`
    ${rules.TinyText}
    color: ${colors.QuorumBlue};
`

export const Input = styled.div`
    border: 1px solid ${colors.QuorumGrey2};
    border-radius: 3px;
    display: flex;
    min-height: 30px;
`

export const InputText = styled.input`
    background-color: transparent;
    border: none;
    border-radius: 3px;
    flex-grow: 1;
    letter-spacing: -0.25px;
    min-width: 0;
    padding-left: 11px;
    ${rules.MinisculeText}

    &::placeholder {
        color: ${colors.QuorumGrey2};
    }

    &:focus {
        color: ${colors.Black};
        outline: 0;
    }
`

export const InputSubmit = styled.button`
    background: none;
    border: 0;
    color: ${colors.violet7};
    letter-spacing: -0.25px;
    ${rules.MinisculeText}

    &:focus {
        outline: 0;
    }
`

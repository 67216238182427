import React, { ReactNode, createContext, useCallback, useContext, useMemo, useState } from "react"

export interface StepProps {
    name: string
    title: string
    Icon: ReactNode
    successIcon: ReactNode
    isSubmitted?: boolean
}

interface StepperContextValue {
    changeStep: (step: string) => void
    currentStep: StepProps
    setSteps: (steps: StepProps[]) => void
    steps: StepProps[]
    submitStep: (step: string) => void
    isCurrentStepLoading: boolean
    setCurrentStepLoading: (value: boolean) => void
}
interface StepperContextProviderProps {
    onChangeStep: () => void
    children: ReactNode
    initialSteps?: StepProps[]
    disableAfterSubmit?: boolean
}

const StepperContext = createContext<StepperContextValue>({} as StepperContextValue)

const generateSteps = (steps: StepProps[]) => steps.map((step) => ({ isSubmitted: false, ...step }))

const getNextStep = (steps: StepProps[], currentStepName: string) => {
    const currentStepIndex = steps.findIndex((step) => step.name === currentStepName)
    const nextStepIndex = (currentStepIndex + 1) % steps.length
    return steps[nextStepIndex]
}

export const StepperContextProvider = ({ children, initialSteps = [], onChangeStep }: StepperContextProviderProps) => {
    const formattedSteps = useMemo(() => generateSteps(initialSteps), [initialSteps])

    const [currentStep, setCurrentStep] = useState<StepProps>(formattedSteps[0])
    const [isCurrentStepLoading, setCurrentStepLoading] = useState(false)
    const [steps, setSteps] = useState(formattedSteps)

    const changeStep = (stepName: string) => {
        const step = steps.find((step) => step.name === stepName)
        if (step && !step.isSubmitted) {
            onChangeStep()
            setCurrentStep(step)
        }
    }

    const submitStep = (stepName: string) => {
        const stepIndex = steps.findIndex((step) => step.name === stepName)
        if (stepIndex !== -1) {
            const incompleteSteps = steps.filter((step) => !step.isSubmitted)
            if (incompleteSteps.length > 1) {
                const nextStep = getNextStep(incompleteSteps, stepName)
                changeStep(nextStep.name)
            }
            steps[stepIndex].isSubmitted = true
            setSteps([...steps])
        }
    }

    const setStepsCustom = useCallback((newSteps: StepProps[]) => {
        const generatedSteps = generateSteps(newSteps)

        setSteps(generatedSteps)
        setCurrentStep(generatedSteps[0])
    }, [])

    return (
        <StepperContext.Provider
            value={{
                steps,
                setSteps: setStepsCustom,
                currentStep,
                changeStep,
                submitStep,
                isCurrentStepLoading,
                setCurrentStepLoading,
            }}
        >
            {children}
        </StepperContext.Provider>
    )
}

export const useStepperContext = () => {
    return useContext(StepperContext)
}

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/DTVCard/style"
import * as colors from "Components/colors"
import * as rules from "Components/rules"

import { useTruncated, useWindowSize } from "utils/hooks"

import Icon from "Components/Atoms/Icon"
import CircleImage from "Components/Molecules/CircleImage"

import Tooltip from "Components/Molecules/Tooltip"

const DTVCard = ({
    circleColor,
    dataCy,
    gridWidth,
    icon,
    iconFamily,
    imgUrl,
    onClick,
    text,
    textIcon,
    textIconFamily,
    textType,
    title,
}) => {
    const [textTruncationRef, textIsTruncated] = useTruncated()
    const [titleTruncationRef, titleIsTruncated] = useTruncated()

    // Use the window size hook to force re-rendering when the window changes size
    // Allows the truncation of text to be re-measured on window resize, which often
    // causes elements to become smaller and truncated and therefore need tooltips
    useWindowSize()

    return (
        <S.Container
            onClick={onClick}
            columnSpan={gridWidth / rules.DTVCardsPerRow}
            data-auto-cy="MoleculeDTVCard"
            data-cy={dataCy}
        >
            <CircleImage
                circleColor={colors.violet1}
                fill
                fontColor={colors.violet5}
                fontSize="32px"
                icon={icon}
                iconFamily={iconFamily}
                imgUrl={imgUrl}
                diameter="65px"
            />
            <S.TextArea>
                <S.Title ref={titleTruncationRef}>
                    { titleIsTruncated &&
                        <S.TooltipPositioner>
                            <Tooltip text={title} />
                        </S.TooltipPositioner>
                    }
                    {title}
                </S.Title>
                <S.LabelSection>
                    { textIcon &&
                        <S.Icon>
                            <Icon icon={textIcon} iconFamily={textIconFamily} size="sm"/>
                        </S.Icon>
                    }
                    <S.Text
                        ref={textTruncationRef}
                        alternateColor={textType === "secondary"}
                    >
                        { textIsTruncated &&
                            <S.TooltipPositioner>
                                <Tooltip text={text} />
                            </S.TooltipPositioner>
                        }
                        {text}
                    </S.Text>
                </S.LabelSection>
            </S.TextArea>
        </S.Container>
    )
}

DTVCard.defaultProps = {
    circleColor: colors.QuorumBlue,
    textType: "primary",
}

DTVCard.propTypes = {
    // color of the circle surrounding the image or icon
    circleColor: PropTypes.string,
    dataCy: PropTypes.string,
    // the number of CSS columns in the containing grid, if contained by a DTVGrid
    gridWidth: PropTypes.number,
    // icon to place in the circle; will not be used if an image URL is supplied
    icon: PropTypes.string,
    // family of the icon in the circle
    iconFamily: PropTypes.string,
    // a URL at which to find an image to display in the circle
    imgUrl: PropTypes.string,
    // callback for when the card is clicked
    onClick: PropTypes.func,
    // detail text beneath the title text
    text: PropTypes.string,
    // icon to display to the left of the detail text
    textIcon: PropTypes.string,
    // family of the icon to display left of the detail text
    textIconFamily: PropTypes.string,
    // one of the DTV card's two supported text types
    textType: PropTypes.oneOf(["primary", "secondary"]),
    // main text to display on the card
    title: PropTypes.string,
}

export default DTVCard

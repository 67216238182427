import styled from "styled-components"
import { styleWithOrgDesignHelper } from "QuorumGrassroots/styled-components/helperFunctions"

const StyledInputComponent = styled.input`
    border-radius: 10px;
    margin-bottom: 10px;
    width: 40%;
`

export const StyledInput = styleWithOrgDesignHelper(StyledInputComponent)

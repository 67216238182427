import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const TooltipPositioner = styled.div`
    & > :first-child {
        transform: translate(0px, calc(-5px + -100%));
        visibility: hidden;
        opacity: 0%;
        transition-duration: ${rules.TransitionShort};
    }
`

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 11px;
    height: 90px;
    ${rules.CardStyle}
    border: 1px solid ${colors.gray2};

    &:hover {
        background-color: ${colors.violet0};
    }

    cursor: ${({onClick}) => onClick ? "pointer" : "default"};

    // size self in a grid
    grid-column: span ${({columnSpan}) => columnSpan};
`

export const TextArea = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 11px;
    color: ${colors.QuorumBlue};
`

export const Title = styled.div`
    ${rules.LargeTextMediumWeight}
    color: ${colors.QuorumBlue};
    font-size: 1rem;

    // limit the title text to two lines
    ${rules.multiLineTruncatedText(2)}

    // slightly raise title text to accound for lineheight
    margin-top: -2px;

    padding-right: 6px;
    margin-bottom: 3px;

    &:hover {
        & > ${TooltipPositioner} {
            & > :first-child {
                visibility: visible;
                opacity: 100%;
                transition-duration: ${rules.TransitionShort};
            }
        }
    }
`

export const LabelSection = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${colors.gray7};
    svg {
        margin-right: 7px;
        transform: translateY(2px);
    }
`

export const Text = styled.span`
    ${rules.SmallTextRegularWeight}
    color: ${({alternateColor}) => alternateColor ? colors.violet7 : colors.gray7};

    padding-right: 6px;

    // limit the text to two lines
    ${rules.multiLineTruncatedText(2)}

    &:hover {
        & > ${TooltipPositioner} {
            & > :first-child {
                visibility: visible;
                opacity: 100%;
                transition-duration: ${rules.TransitionShort};
            }
        }
    }
`

export const Icon = styled.span`
    svg {
        font-size: 20px;
    }
`

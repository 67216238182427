import React from "react"
import { connect } from "react-redux"
import { dispatchGamificationLevelUpModal } from "QuorumGrassroots/campaign-forms/action-creators"
import { getPointsForCampaign } from "QuorumGrassroots/campaign-forms/helpers"
import { Campaign } from "@/types/djangio"

interface Props {
    campaign: Campaign
    dispatch: any
}

export const withGamificationModal = (Campaign: React.FC<any>) => {
    const GamificationModalHOC = (props: Props) => {
        const showGamificationLevelUpModalIfEnabled = (pointsEarned?: number) => {
            const points = pointsEarned ?? getPointsForCampaign(props.campaign)
            if (window.action_center_settings.gamification_enabled) {
                dispatchGamificationLevelUpModal(points, props.dispatch, window.store.getState)
            }
        }

        return <Campaign {...props} showGamificationLevelUpModalIfEnabled={showGamificationLevelUpModalIfEnabled} />
    }

    return connect()(GamificationModalHOC)
}

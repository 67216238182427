import React from "react"
import PropTypes from "prop-types"

import Icon from "Components/Atoms/Icon"

import * as S from "Components/Molecules/Alert/style"

const severityMap = {
    error: { icon: "exclamation-triangle", iconFamily: "fas", primaryColor: "#5F2120", secondaryColor: "#FDEDED", borderColor: "#ef5350" },
    warning: { icon: "exclamation-square", iconFamily: "fas", primaryColor: "#663C00", secondaryColor: "#FFF4E5", borderColor: "#ff9800" },
    info: { icon: "info-circle", iconFamily: "fas", primaryColor: "#014361", secondaryColor: "#E5F6FD", borderColor: "#03a9f4" },
    success: { icon: "check", iconFamily: "fas", primaryColor: "#155724", secondaryColor: "#E5FAEA", borderColor: "#8CC99A" },
}

const Alert = ({
    children,
    dataCy,
    severity,
    title,
}) => {
    const {
        primaryColor,
        secondaryColor,
        borderColor,
        icon,
        iconFamily
    } = severityMap[severity]

    return (
        <S.Alert
            data-auto-cy="MoleculeAlert"
            data-cy={dataCy}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            borderColor={borderColor}
        >
            <S.Icon>
                <Icon icon={icon} iconFamily={iconFamily} />
            </S.Icon>
            <S.Message>
                {title && <S.Title>{title}</S.Title>}
                {children}
            </S.Message>
        </S.Alert>
    )
}

Alert.defaultProps = {
    severity: "info",
}

Alert.propTypes = {
    children: PropTypes.string,
    dataCy: PropTypes.string,
    severity: PropTypes.oneOf([
        "error",
        "warning",
        "info",
        "success",
    ]),
    title: PropTypes.string,
}

export default Alert

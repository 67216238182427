import i18n from "i18n"
import { createSelector, createStructuredSelector } from "reselect"
import { selectWidgetIsSubmitting } from "QuorumGrassroots/widgets/selectors"

const makeCampaignSelector = (fieldName) =>
    createSelector(
        (_, props) => props.campaign,
        (campaign) => campaign && campaign[fieldName],
    )

export const selectStyledButtonText = createSelector(
    makeCampaignSelector("custom_call_to_action_button_text"),
    (customCTAButtonText) => customCTAButtonText || i18n.t("campaign.gotv.verb"),
)

export const gotvFormConnection = createStructuredSelector({
    isSubmitting: selectWidgetIsSubmitting,
    styledButtonText: selectStyledButtonText,
})

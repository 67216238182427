import React from "react"

import { constants } from "QuorumGrassroots/constants"
import styled from "styled-components"
import { styleWithOrgDesignHelper } from "QuorumGrassroots/styled-components/helperFunctions"

const StyledAlertComponent = styled.div`
    border-radius: 4px;
    color: ${(props) =>
        props.isCampaignPage && props.activateNGGTheme
            ? props.organizationDesign.black
            : props.organizationDesign.lighter_primary};
    background-color: ${(props) =>
        props.isCampaignPage && props.activateNGGTheme
            ? `${constants.whiteColor}99` // 60% opacity
            : `${props.organizationDesign.secondary_color}40`}; //25% opacity on the old UI
    border-color: ${(props) => props.organizationDesign.lighter_primary};
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 16px;
    font-size: 14px;
    margin-bottom: 20px;
`

export const StyledAlert = styleWithOrgDesignHelper(StyledAlertComponent)

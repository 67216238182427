import React from "react"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"

export class EmbedWidget extends React.Component {
    constructor(props) {
        super(props);
        this.embed = React.createRef()
    }

    componentDidMount() {
        this.embed.current.innerHTML = this.props.content

        // Some embed codes (like Twitter or GA) have script tags, which won't execute just by setting innerHTML, so
        // we manually look for script tags and use (0, eval) which executes the script in a global context, or for
        // external scripts add the script to head.
        // This doesn't give up any security since malicious code could just as well be put in event attributes, ex
        // <img onload='runJS()'/> which isn't prevented by innerHTML. If the embed code uses document.write it will
        // either silently fail or break the page.
        const scripts = this.embed.current.getElementsByTagName("script")
        for (let i=0; i<scripts.length; i++) {
            if (scripts[i].src) {
                // External Script
                const scriptTag = document.createElement("script")
                scriptTag.src = scripts[i].src
                scriptTag.type = scripts[i].type || "text/javascript"
                scriptTag.async = true
                document.head.appendChild(scriptTag)
            } else {
                // Inline script
                (0, eval)(scripts[i].innerHTML)
            }
        }
    }

    render() {
        return (
            <WidgetStyleWrapper
                md={this.props.colWidth}
                title={this.props.title}
            >
                <div className="embed-widget" ref={this.embed}>
                    Loading...
                </div>
            </WidgetStyleWrapper>
        );
    }
}

export default EmbedWidget

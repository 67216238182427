import styled from 'styled-components'
import * as rules from "Components/rules"

export const Body = styled.div`
    height: calc(100vh - 300px);
    ${({ clampWidth }) => clampWidth ? "max-width: 1336px;" : ""};
    max-height: 654px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
`

export const TopBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 35px;
    padding: 0 45px;
    flex: 0 0 auto;
    position: relative;

    @media (max-width: 768px) {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        grid-template-areas:
            "text"
            "rightSideComponent";
    }
`

export const Text = styled.div`
    flex: 0 1 ${({ hasRightSideComponent }) => hasRightSideComponent ? '50%' : 'auto'};
    grid-area: text;
    ${rules.LargeTextLightWeight}
    @media (max-width: 768px) {
        padding-bottom: 10px;
    }
`

export const RightSideComponent = styled.div`
    grid-area: rightSideComponent;
    position: absolute;
    right: 45px;
    bottom: 0;
    width: min(${({ width }) => (width || 100) - 45}px, 50%);
    @media (max-width: 768px) {
        right: 0;
        width: 100%;
        position: relative;
    }

    display: flex;
    flex-direction: row-reverse;
    & > :not(:first-child) {
        margin-right: ${rules.TopBarItemPadding};
    }
`

export const Children = styled.div`
    overflow: auto;
    overflow-y: overlay;
    flex: 1;
`

import Enum from "./Enum"

/**
 * Rebuild the DjangIO.app.userdata.types.InteractionType enum with CustomInteractions
 * alongside the existing InteractionType enum values.
 * @name mapCustomInteractionTypesToDjangIO
 * @function
 * @param {number} attempts - The number of attempts already made to check for the presence of Userdata
 */
export const mapCustomInteractionTypesToDjangIO = (attempts = 0) => {
    if (window.Userdata && window.Userdata.custom_interaction_types) {
        const interactionTypes = window.DjangIO.app.userdata.types.InteractionType.items().reduce((acc, item) => {
            acc[item.key] = item
            return acc
        }, {})

        const customInteractionTypes = window.Userdata.custom_interaction_types.map((type) => {
            const { id: value, title: label, ..._type } = type
            return {
                value,
                label,
                editable: true,
                loggable: true,
                mobile_order: _type.order,
                ..._type,
            }
        })
        const filterGroups = window.DjangIO.app.userdata.types.InteractionType.filter_groups
        const combinedInteractionTypes = new Enum({
            ...interactionTypes,
            ...customInteractionTypes,
            filter_groups: filterGroups,
        })
        window.DjangIO.app.userdata.types.InteractionType = combinedInteractionTypes
        global.DjangIO.app.userdata.types.InteractionType = combinedInteractionTypes
    } else if (attempts <= 3) {
        setTimeout(mapCustomInteractionTypesToDjangIO, 400 * attempts, attempts + 1)
    }
}

/**
 * Inserts or updates the given customInteractionType into Userdata, then calls mapCustomInteractionTypesToDjangIO
 * to update the values in DjangIO and allow logging the new or updated type without refreshing.
 * @name updateOrCreateCustomInteractionType
 * @function
 * @param {object} newCustomInteractionType - The interaction type to be added
 */
export const updateOrCreateCustomInteractionType = (newCustomInteractionType) => {
    const customInteractionTypes = [...window.Userdata.custom_interaction_types]
    const i = customInteractionTypes.findIndex(
        (customInteractionType) => customInteractionType.id === newCustomInteractionType.id,
    )
    if (i >= 0) {
        customInteractionTypes[i] = newCustomInteractionType
    } else {
        customInteractionTypes.push(newCustomInteractionType)
    }
    window.Userdata.custom_interaction_types = customInteractionTypes
    mapCustomInteractionTypesToDjangIO()
}

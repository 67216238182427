import { makePieActionObjs } from "shared/imports/pieActionGenerators"

const loginActions = makePieActionObjs("login", "user")
const logoutActions = makePieActionObjs("logout", "user")
const updateUserActions = makePieActionObjs("update", "user")
const loadFrameworkTypes = makePieActionObjs("load", "framework")
const postGrassrootsVisit = makePieActionObjs("post", "visit")
const loadAdditionalCampaignsActions = makePieActionObjs("load", "additional_campaigns")
const loadPageRegionFilters = makePieActionObjs("load", "page_region_filters")

const recoverAccountActions = makePieActionObjs("recover", "account")
const resetPasswordActions = makePieActionObjs("reset", "password")

export const actionTypes = {
    ...loginActions.actionTypes,
    ...logoutActions.actionTypes,
    ...updateUserActions.actionTypes,
    ...loadFrameworkTypes.actionTypes,
    ...recoverAccountActions.actionTypes,
    ...resetPasswordActions.actionTypes,
    ...postGrassrootsVisit.actionTypes,
    ...loadAdditionalCampaignsActions.actionTypes,
    ...loadPageRegionFilters.actionTypes,
    UPDATE_USERDATA_SLICE: "UPDATE_USERDATA_SLICE",
    UPDATE_SUPPORTER_POINTS: "UPDATE_SUPPORTER_POINTS",
    UPDATE_POINTS_EARNED: "UPDATE_POINTS_EARNED",

    SHOW_GAMIFICATION_TIER_MODAL: "SHOW_GAMIFICATION_TIER_MODAL",
    HIDE_GAMIFICATION_TIER_MODAL: "HIDE_GAMIFICATION_TIER_MODAL",

    ENABLE_ONE_CLICK_REGISTRATION: "ENABLE_ONE_CLICK_REGISTRATION",
    DISABLE_ONE_CLICK_REGISTRATION: "DISABLE_ONE_CLICK_REGISTRATION",
    STORE_ONE_CLICK_EDITS: "STORE_ONE_CLICK_EDITS",

    CLEAR_USERDATA: "CLEAR_USERDATA",

    UPDATE_SIGN_UP_REDIRECT_URL: "UPDATE_SIGN_UP_REDIRECT_URL",
    UPDATE_PAC_USERDATA_SLICE: "UPDATE_PAC_USERDATA_SLICE",
    UPDATE_PAGES_SLICE: "UPDATE_PAGES_SLICE",
}

export const loginActionTypes = loginActions.pieDict
export const logoutActionTypes = logoutActions.pieDict
export const updateInfoActionTypes = updateUserActions.pieDict
export const loadFrameworkActionTypes = loadFrameworkTypes.pieDict
export const postGrassrootsVisitActionTypes = postGrassrootsVisit.pieDict
export const recoverAccountActionTypes = recoverAccountActions.pieDict
export const resetPasswordActionTypes = resetPasswordActions.pieDict
export const loadAdditionalCampaignsTypes = loadAdditionalCampaignsActions.pieDict
export const loadPageRegionFilterTypes = loadPageRegionFilters.pieDict

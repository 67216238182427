import React from "react"
import PropTypes from "prop-types"

import SelectDropdown from "Components/Molecules/SelectDropdown"

import {
    HOURS12,
    HOURS24,
    MINUTES60,
    AM,
    PM,
    AMPM
} from "Components/Molecules/DatePicker/constants"
import { parseDateToParts } from "Components/Molecules/DatePicker/utils"

import * as colors from "Components/colors"
import * as S from "Components/Molecules/DatePicker/style"

const TimePicker = ({
    date,
    is12Hour,
    onChange,
    timezone,
    value,
}) => {
    const { hours, minutes, isPM } = parseDateToParts(date, is12Hour)

    const updateHour = ({ value }) =>  {
        const newDate = new Date(date)

        if (is12Hour) {
            newDate.setHours((value % 12) + (isPM && 12))
        } else {
            newDate.setHours(value)
        }

        onChange(newDate.toTimeString())
    }

    const updateMinute = ({ value }) => {
        const newDate = new Date(date)
        newDate.setMinutes(value)

        onChange(newDate.toTimeString())
    }

    const updateIsPM = ({ value }) => {
        const newDate = new Date(date)
        newDate.setHours((hours % 12) + (value && 12))

        onChange(newDate.toTimeString())
    }

    return (
        <S.TimePickerWrapper>
            <SelectDropdown
                options={is12Hour ? HOURS12 : HOURS24}
                icon="sort"
                iconFamily="fas"
                iconColor={colors.QuorumBlue}
                placeholder=""
                value={{
                    label: String(hours).padStart(is12Hour || 2, "0"),
                    value: hours,
                }}
                onChange={updateHour}
                id="datepicker-hour"
                isSmall
            />
            <span>
                :
            </span>
            <SelectDropdown
                options={MINUTES60}
                icon="sort"
                iconFamily="fas"
                iconColor={colors.QuorumBlue}
                placeholder=""
                value={{label: String(minutes).padStart(2, "0"), value: minutes}}
                onChange={updateMinute}
                id="datepicker-minute"
                isSmall
            />
            {is12Hour && (
                <SelectDropdown
                    options={AMPM}
                    icon="sort"
                    iconFamily="fas"
                    iconColor={colors.QuorumBlue}
                    placeholder=""
                    value={{
                        label: isPM ? PM : AM,
                        value: isPM
                    }}
                    onChange={updateIsPM}
                    id="datepicker-ampm"
                    isSmall
                />
            )}
            {timezone}
        </S.TimePickerWrapper>
    )
}

TimePicker.defaultProps = {
    is12Hour: true
}

TimePicker.propTypes = {
    is12Hour: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
}

export default TimePicker

// https://www.figma.com/file/Hog2l1xYkkgVDPrPpdXYfH/CRM-86---Global-Date-Filter?node-id=25%3A172
import React, { useRef, useState, useCallback } from "react"
import PropTypes from "prop-types"
import debounce from "lodash.debounce"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

import Button from "Components/Molecules/Button"
import TextInput from "Components/Molecules/TextInput"
import TimePicker from "Components/Molecules/DatePicker/TimePicker"

import { useOnClickOutside } from "utils/hooks"

import * as S from "Components/Molecules/DatePicker/style"
import * as SFromTo from "Components/Molecules/DatePickerRange/style"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

const DatePickerRange = ({
    applyOnClick,
    cancelOnClick,
    dataCy,
    dateFormat,
    description,
    errorMessage,
    hasError,
    is12Hour,
    isClearable,
    isControlled,
    isOpen,
    isRange,
    labelFrom,
    labelTo,
    maxDate,
    minDate,
    onChange,
    onClickOutside,
    showTimeInput,
    valueFrom,
    valueTo,
}) => {
    const [valueFromState, setValueFromState] = useState(
        (!isControlled && valueFrom) ||
        Date.now()
    )
    const [valueToState, setValueToState] = useState(
        (!isControlled && valueTo) ||
        null
    )
    const [selectedFocus, setSelectedFocus] = useState("to")
    const datePickerRangeOuterWrapperRef = useRef()

    const hasErrorStateApplyButton = Boolean(
        !isControlled &&
        !valueToState
    )
    const hasErrorStateTextInput = Boolean(
        hasErrorStateApplyButton &&
        selectedFocus !== "to"
    )
    const valueFromInternal = (
        isControlled
            ? valueFrom
            : valueFromState
    )
    const valueToInternal = (
        isControlled
            ? valueTo
            : valueToState
    )
    const textInputOnChangeDebounced = useCallback(
        debounce((focus, onFocus, value, valueFromInternal) => {
            if (dayjs(value).isValid()) {
                // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#sect1
                const date = new Date(value)

                if (focus === "from") {
                    setValueFromState(date)
                } else {
                    // only set "To" (valueToState) if it comes after the "From" date
                    if (dayjs(date).isAfter(dayjs(valueFromInternal))) {
                        setValueToState(date)
                    }
                }

                onFocus(event)
            }
        }, 500),
        []
    )

    const WrappedTimePicker = (props) => (
        <React.Fragment>
            <S.HR />
            <TimePicker
                is12Hour={is12Hour}
                timezone={dayjs().format("z")}
                {...props}
            />
        </React.Fragment>
    )

    useOnClickOutside(datePickerRangeOuterWrapperRef, onClickOutside)

    const CustomInputs = React.forwardRef(({ value, onFocus }, ref) => {
        const createTextInput = (focus) => {
            const valueFocus = dayjs(
                focus === "from"
                    ? valueFromInternal
                    : valueToInternal
            )
            const defaultValue = (
                valueFocus.isValid()
                    ? (
                        valueFocus
                        .format(
                            showTimeInput
                                ? `${dateFormat} | ${is12Hour ? "h:mma z" : "HH:mm z"}`
                                : dateFormat
                        )
                    )
                    : ""
            )

            return (
                <TextInput
                    dataCy="datepicker-input"
                    defaultValue={defaultValue}
                    description={description}
                    disableActiveShadow
                    hasError={
                        focus === "to" &&
                        (
                            hasError ||
                            hasErrorStateTextInput
                        )
                    }
                    ref={ref}
                    inputStyle={{ fontStyle: "italic" }}
                    isActive={selectedFocus === focus}
                    isCentered
                    label={
                        focus === "from"
                            ? labelFrom
                            : labelTo
                    }
                    leftIcon={
                        showTimeInput
                            ? "clock"
                            : "calendar-alt"
                    }
                    leftIconFamily="far"
                    onFocus={(event) => {
                        setSelectedFocus(focus)
                        onFocus(event)
                    }}
                    onChange={(event) => {
                        if (!isControlled) {
                            textInputOnChangeDebounced(focus, onFocus, event.target.value, valueFromInternal)
                        }
                    }}
                    placeholder="mm/dd/yyyy"
                    wrapperStyle={{
                        paddingLeft: "12px",
                        width: showTimeInput ? "240px" : "130px",
                    }}
                />
            )
        }

        return (
            <React.Fragment>
                {createTextInput("from")}
                {createTextInput("to")}
            </React.Fragment>
        )
    })

    return (
        <SFromTo.DatePickerRangeOuterWrapper
            data-cy={dataCy}
            data-auto-cy="MoleculeDatePicker"
            ref={datePickerRangeOuterWrapperRef}
        >
            <SFromTo.DatePickerRangeWrapper
                customInput={<CustomInputs />}
                customTimeInput={<WrappedTimePicker />}
                dateFormat="yyyy-MM-dd'T'HH:mm:ss.SSSXXX"
                isClearable={isClearable}
                maxDate={maxDate}
                minDate={minDate}
                open={isOpen}
                openToDate={(
                    selectedFocus === "from"
                        ? valueFromInternal
                        : valueToInternal
                )}
                selected={(
                    selectedFocus === "from"
                        ? valueFromInternal
                        : valueToInternal
                )}
                showTimeInput={showTimeInput}
                timeCaption=""
                {
                    ...(
                        // https://reactdatepicker.com/#example-date-range-for-one-datepicker
                        isRange
                            ? {
                                // disabledKeyboardNavigation is necessary in order to ensure
                                // that the react-datepicker does not set the tentatively selected
                                // endDate to the current date as you switch between different months
                                // https://github.com/Hacker0x01/react-datepicker/issues/2376
                                // https://github.com/Hacker0x01/react-datepicker/issues/2872#issuecomment-1069319499
                                // https://github.com/Hacker0x01/react-datepicker/issues/2930
                                disabledKeyboardNavigation: true,
                                endDate: valueToInternal,
                                onChange: (dates) => {
                                    const [start, end] = dates

                                    if (isControlled) {
                                        onChange(start, end)
                                    } else {
                                        if (
                                            selectedFocus === "to" &&
                                            !end &&
                                            (start > valueFromInternal)
                                        ) {
                                            setValueToState(start)
                                        } else if (
                                            (
                                                (selectedFocus === "to") &&
                                                (start < valueFromInternal)
                                            ) ||
                                            (
                                                selectedFocus === "from" &&
                                                (start < valueToInternal)
                                            )
                                        ) {
                                            setValueFromState(start)

                                            if (selectedFocus === "from") {
                                                setSelectedFocus("to")
                                            }
                                        } else {
                                            setValueFromState(start)
                                            setValueToState(end)
                                            setSelectedFocus("to")
                                        }
                                    }
                                },
                                selectsRange: true,
                                startDate: valueFromInternal,
                            }
                            : {
                                onChange: (date) => {
                                    onChange(selectedFocus, date)
                                },
                            }
                    )
                }
            >
                <S.ButtonWrapper>
                    <Button
                        dataCy="datepicker-cancel"
                        onClick={cancelOnClick}
                        text="Cancel"
                        type="outlined"
                    />
                    <Button
                        dataCy="datepicker-apply"
                        disabled={
                            hasError ||
                            hasErrorStateApplyButton
                        }
                        onClick={(event) => {
                            if (isControlled) {
                                applyOnClick(event)
                            } else {
                                if (dayjs(valueFromState).isBefore(dayjs(valueToState))) {
                                    applyOnClick(valueFromState, valueToState)
                                }
                            }
                        }}
                        text="Apply"
                    />
                </S.ButtonWrapper>
            </SFromTo.DatePickerRangeWrapper>
        </SFromTo.DatePickerRangeOuterWrapper>
    )
}

DatePickerRange.defaultProps = {
    dateFormat: "MM/D/YYYY",
    is12Hour: true,
    isControlled: false,
    isOpen: false,
}

DatePickerRange.propTypes = {
    applyOnClick: PropTypes.func.isRequired,
    cancelOnClick: PropTypes.func,
    dataCy: PropTypes.string,
    dateFormat: PropTypes.string,
    description: PropTypes.string,
    errorMessage: PropTypes.string,
    hasError: PropTypes.bool,
    is12Hour: PropTypes.bool,
    isClearable: PropTypes.bool,
    isControlled: PropTypes.bool,
    isOpen: PropTypes.bool,
    isRange: PropTypes.bool,
    labelFrom: PropTypes.string,
    labelTo: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired,
    onClickOutside: PropTypes.func,
    showTimeInput: PropTypes.bool,
    valueFrom: PropTypes.instanceOf(Date),
    valueTo: PropTypes.instanceOf(Date),
}

export default DatePickerRange

import React from "react"

import { Loader, Popover, Text } from "@mantine/core"
import { InputField } from "app/static/frontend/forms/components/InputField"
import {
    MessagePreview,
    EditVisualizationBar,
    VisualizationMode,
    EditMode,
    SaveButton,
} from "QuorumGrassroots/framework/components/EditableMessagePreview/style"
import { useFetchPlaceholdersFromMessage } from "QuorumGrassroots/services/grassrootsCampaign"
import { generateTargetedMessage, validateMessageHasPlaceholders } from "./helper"
import { reinsertPlaceholders } from "QuorumGrassroots/helperFunctions"

export const EditableMessagePreview = (props) => {
    const { t, currentTargetedMessage, selectedTargetId, isLoading } = props

    const inputValue = props.isVisualizationMode ? currentTargetedMessage?.formatted?.content : props.input.value
    const inputProps = {
        ...props,
        input: {
            ...props.input,
            value: inputValue,
        },
    }

    const updatePlaceholdersMutation = useFetchPlaceholdersFromMessage(selectedTargetId)

    const handleUpdatePlaceholders = async (content) => {
        const hasPlaceholders = validateMessageHasPlaceholders(content)
        if (!hasPlaceholders || !selectedTargetId) {
            return props.updateFormattedTargetedMessage(
                parseInt(props.activeGroupId),
                generateTargetedMessage(currentTargetedMessage, content),
            )
        }

        return updatePlaceholdersMutation
            .mutateAsync({
                content: reinsertPlaceholders(content),
                person_id: selectedTargetId,
            })
            .then((response) => {
                props.updateFormattedTargetedMessage(parseInt(props.activeGroupId), {
                    ...currentTargetedMessage,
                    formatted: response,
                })
            })
    }

    const handleSave = () => {
        handleUpdatePlaceholders(props.input.value).finally(() => {
            props.setIsVisualizationMode((prevState) => !prevState)
        })
    }

    const showLoading = isLoading || updatePlaceholdersMutation.isLoading
    const showEditModeButton = props.isVisualizationMode && !showLoading
    const showPreviewModeButton = !props.isVisualizationMode && !showLoading

    return (
        <MessagePreview>
            <InputField {...inputProps} />
            <EditVisualizationBar>
                {showLoading && (
                    <VisualizationMode>
                        <Loader size={20} />
                    </VisualizationMode>
                )}

                {showEditModeButton && (
                    <VisualizationMode>
                        <i
                            class="fa fa-pencil"
                            aria-hidden="true"
                            onClick={() => props.setIsVisualizationMode((prevState) => !prevState)}
                        />
                        <VisualizationModeTooltip text={t("campaign.write.visualization_mode_tooltip")} />
                    </VisualizationMode>
                )}

                {showPreviewModeButton && (
                    <EditMode>
                        <SaveButton
                            color="green"
                            variant="light"
                            radius="xl"
                            size="sm"
                            compact
                            leftIcon={<i class="fa fa-check" aria-hidden="true" />}
                            data-cy="save-edit-button"
                            onClick={handleSave}
                            isLoading={updatePlaceholdersMutation.isLoading}
                            disabled={updatePlaceholdersMutation.isLoading}
                        >
                            Save
                        </SaveButton>
                        <Text color="gray" size="xs" weight={400}>
                            {t("campaign.write.edit_visualization_mode")}
                        </Text>
                    </EditMode>
                )}
            </EditVisualizationBar>
        </MessagePreview>
    )
}

const VisualizationModeTooltip = ({ text }) => {
    return (
        <Popover width={300} position="top-end" withArrow shadow="md" offset={3}>
            <Popover.Target>
                <div>
                    <i class="fa fa-info-circle" aria-hidden="true" />
                </div>
            </Popover.Target>
            <Popover.Dropdown>
                <Text color="gray" size="sm" weight={400}>
                    {text}
                </Text>
            </Popover.Dropdown>
        </Popover>
    )
}

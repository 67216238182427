import React from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom"

export const withRouter = (Component) => {
    const ComponentWithRouterProp = (props) => {
        const location = useLocation()
        const query = Object.fromEntries(new URLSearchParams(location.search))
        const params = useParams()
        const navigate = useNavigate()

        return (
            <Component
                {...props}
                navigate={navigate}
                match={{ params }}
                location={{
                    ...location,
                    query,
                }}
            />
        )
    }

    return ComponentWithRouterProp
}

import React, { useState } from "react"
import PropTypes from "prop-types"
import TextareaAutosize from "react-textarea-autosize"

import Icon from "Components/Atoms/Icon"

import * as colors from "Components/colors"
import * as S from "Components/Molecules/TextInput/style"

const TextInput = ({
    autoComplete,
    children,
    className,
    dataCy,
    defaultValue,
    description,
    disableActiveShadow,
    disabled,
    errorMessage,
    hasError,
    id,
    ref,
    inputStyle,
    isActive,
    isCentered,
    isPassword,
    isTextarea,
    label,
    labelPrefix,
    labelStyle,
    leftIcon,
    leftIconFamily,
    maxRows,
    minRows,
    onBlur,
    onChange,
    onEnter,
    onFocus,
    onKeyDown,
    placeholder,
    showPasswordStyle,
    subLabel,
    value,
    warningMessage,
    wrapperStyle,
}) => {
    const [showPassword, setShowPassword] = useState(false)

    return (
        <S.TextInput data-auto-cy="MoleculeTextInput" className={className}>
            {(label || subLabel || isPassword) && (
                <S.LabelContainer>
                    <S.Label id={`${id}-aria-label`} htmlFor={id} disabled={disabled} style={labelStyle}>
                        {labelPrefix && <S.LabelPrefix>{labelPrefix}</S.LabelPrefix>}
                        {label}
                    </S.Label>
                    {
                        isPassword
                            ? (
                                <S.ShowPassword
                                    style={showPasswordStyle}
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? "Hide" : "Show"}
                                </S.ShowPassword>
                            ) : <S.SubLabel>{subLabel}</S.SubLabel>
                    }
                </S.LabelContainer>
            )}
            <S.InputWrapper
                disableActiveShadow={disableActiveShadow}
                disabled={disabled}
                hasError={errorMessage || hasError}
                hasWarning={warningMessage}
                isActive={isActive}
                style={wrapperStyle}
            >
                {leftIcon && (
                    <Icon icon={leftIcon} iconFamily={leftIconFamily} color={value ? colors.QuorumBlue : colors.SilverChalice} />
                )}
                <S.Input
                    autoComplete={autoComplete}
                    as={isTextarea ? TextareaAutosize : "input"}
                    data-cy={dataCy}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    id={id}
                    isCentered={isCentered}
                    isPassword={isPassword && !showPassword}
                    maxRows={maxRows}
                    minRows={minRows}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={onFocus}
                    onKeyDown={
                        // enable submitting the form from the input via the enter key
                        onKeyDown ||
                        (e => e.key === "Enter" && onEnter && onEnter(e))
                    }
                    placeholder={placeholder}
                    ref={ref}
                    style={inputStyle}
                    type="text"
                    value={value}
                />
                {children}
            </S.InputWrapper>
            {(description || errorMessage || warningMessage) && (
                <S.Description
                    data-cy={`${dataCy}-${errorMessage ? "error" : "description"}`}
                    hasError={errorMessage}
                    hasWarning={warningMessage}
                >
                    {errorMessage && errorMessage.length && <Icon icon="exclamation-triangle" />}
                    {!errorMessage && warningMessage && <Icon icon="exclamation-square" />}
                    {errorMessage || warningMessage || description}
                </S.Description>
            )}
        </S.TextInput>
    )
}

TextInput.defaultProps = {
    isTextarea: false,
}

TextInput.propTypes = {
    autoComplete: PropTypes.string,
    children: PropTypes.element,
    dataCy: PropTypes.string,
    defaultValue: PropTypes.string,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
    id: PropTypes.string,
    inputStyle: PropTypes.object,
    isActive: PropTypes.bool,
    isCentered: PropTypes.bool,
    isPassword: PropTypes.bool,
    isTextarea: PropTypes.bool,
    label: PropTypes.string,
    labelPrefix: PropTypes.string,
    labelStyle: PropTypes.object,
    leftIcon: PropTypes.string,
    leftIconFamily: PropTypes.string,
    maxRows: PropTypes.number,
    minRows: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onKeyDown: PropTypes.func,
    placeholder: PropTypes.string,
    showPasswordStyle: PropTypes.object,
    subLabel: PropTypes.string,
    value: PropTypes.string,
    warningMessage: PropTypes.string,
    wrapperStyle: PropTypes.object,
}

export default TextInput

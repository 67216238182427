import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/ModalBodyNoScroll/style"

const ModalBodyNoScroll = ({
    footerButtons,
    content,
    dataCy,
    text,
}) => (
    <S.ModalBodyNoScroll data-auto-cy="MoleculeModalBodyNoScroll">
        {
            text &&
                <S.Text data-cy={dataCy}>
                    {text}
                </S.Text>
        }
        <S.Content>
            {content}
        </S.Content>
        <S.ButtonWrapper>
            {footerButtons}
        </S.ButtonWrapper>
    </S.ModalBodyNoScroll>
)

ModalBodyNoScroll.defaultProps = {}

ModalBodyNoScroll.propTypes = {
    children: PropTypes.any,
    dataCy: PropTypes.string,
    text: PropTypes.string,
}

export default ModalBodyNoScroll

import styled from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const CheckInputFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 60px;
`

export const Label = styled.span`
    ${rules.SmallMediumText}
    color: ${colors.QuorumBlue};
    margin-top: 15px;
    margin-bottom: 6px;
`

export const LabelError = styled.span`
    ${rules.SmallText}
    color: ${colors.ReduxFormErrorColor};
    margin-bottom: 7px;
    width: 140px;
    font-size: 12px;
`

export const StyledInput = styled.input`
    border-radius: 4px;
    margin-top: 5px;
    border: ${({error}) => error ? colors.ReduxFormErrorColor : "#AAAAAA"} solid 1px;
    width: ${({inputWidth}) => inputWidth};
    height: 41px;
    font-weight: 300;
    font-size: 14px;
    padding: 10px;
    font-style: italic;
`

import React, { useRef, useState } from "react"
import PropTypes from "prop-types"

import Button from "Components/Molecules/Button"
import Icon from "Components/Atoms/Icon"
import TextInput from "Components/Molecules/TextInput"

import { useOnClickOutside } from "utils/hooks"
import { normalizeActions } from "Components/Compounds/Dialog/helpers"

import * as colors from "Components/colors"
import * as S from "Components/Compounds/Dialog/style"

const Dialog = ({
    actions,
    children,
    closeOnClickOutside,
    content,
    dataCy,
    icon,
    iconColor,
    iconFamily,
    text,
    title,
    yieldResult,
    yieldResultAndClose,
}) => {
    const [state, setState] = useState({
        ...(content && { [content.attributes.id]: content.attributes.value })
    })
    const ref = useRef()
    const closeDialog = () => closeOnClickOutside && yieldResultAndClose({ source: "clickOutside", state })
    useOnClickOutside(ref, closeDialog)

    const renderContent = () => {
        switch (content.element) {
            case "TextInput":
                return (
                    <TextInput
                        dataCy={content.attributes.dataCy}
                        label={content.attributes.label}
                        value={state[content.attributes.id]}
                        onChange={e => setState({
                            ...state,
                            [content.attributes.id]: e.target.value
                        })}
                        placeholder={content.attributes.placeholder}
                    />
                )
            default:
        }
    }

    return (
        <S.DialogWrapper
            data-auto-cy="CompoundDialog"
            data-cy={dataCy}
            ref={ref}
        >
            {icon && <S.IconWrapper><Icon icon={icon} iconFamily={iconFamily} color={iconColor} /></S.IconWrapper>}
            {title && <S.TitleWrapper>{title}</S.TitleWrapper>}
            {text && <S.TextWrapper>{text}</S.TextWrapper>}
            {content && <S.ContentWrapper>{renderContent()}</S.ContentWrapper>}
            {children}
            {actions && (
                <S.ActionsWrapper>
                    {normalizeActions(actions).map(action => (
                        <Button
                            text={action.text}
                            type={action.type}
                            onClick={() => (
                                (action.closeAlert || !action.hasOwnProperty("closeAlert"))
                                    ? yieldResultAndClose({ value: action.value, state })
                                    : yieldResult({ value: action.value, state })
                            )}
                            key={action.text}
                            style={{ minWidth: "92px" }}
                        />
                    ))}
                </S.ActionsWrapper>
            )}
        </S.DialogWrapper>
    )
}

Dialog.defaultProps = {
    closeOnClickOutside: true,
    icon: "info-circle",
    iconColor: colors.QuorumBlue,
    iconFamily: "fas",
}

Dialog.propTypes = {
    actions: PropTypes.objectOf(
        PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.string,
            PropTypes.shape({
                text: PropTypes.string,
                value: PropTypes.any,
                type: PropTypes.string,
                closeAlert: PropTypes.bool,
            })
        ])
    ),
    children: PropTypes.element,
    closeOnClickOutside: PropTypes.bool,
    content: PropTypes.shape({
        element: PropTypes.string,
        attributes: PropTypes.shape({
            dataCy: PropTypes.string,
            id: PropTypes.string,
            label: PropTypes.string,
            placeholder: PropTypes.string,
            value: PropTypes.string,
        })
    }),
    dataCy: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    iconFamily: PropTypes.string,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    yieldResult: PropTypes.func,
    yieldResultAndClose: PropTypes.func,
}

export default Dialog

import React from "react"
import PropTypes from "prop-types"

import Icon from "Components/Atoms/Icon"

import * as S from "Components/Molecules/SocialUserButton/style"

const SocialUserButton = ({
    canonicalUrl,
    color,
    data,
    dataCy,
    hoverText,
    icon,
    iconFamily,
    onClick,
}) => (
    <S.Wrapper
        data-auto-cy="MoleculeSocialUserButton"
        data-cy={dataCy}
    >
        <S.SocialUserButton
            color={color}
            onClick={onClick}
            hasDetails={Boolean(data && data.length)}
        >
            {/* Link to external site slides out on hover */}
            <S.Overlay color={color}>
                {
                    hoverText || `Visit ${canonicalUrl}`
                }
                <Icon icon="external-link" iconFamily="fas" size="1x" fixedWidth={false} />
            </S.Overlay>

            <Icon icon={icon} iconFamily={iconFamily} color={color} size="1x" fixedWidth={false} />
            {data && data.map(datum =>
                <S.Datum key={datum.label}>
                    <S.Value>{datum.value || "N/A"}</S.Value>
                    <S.Label>{datum.label}</S.Label>
                </S.Datum>
            )}
        </S.SocialUserButton>
    </S.Wrapper>
)

SocialUserButton.defaultProps = {}

SocialUserButton.propTypes = {
    canonicalUrl: PropTypes.string,
    color: PropTypes.string,
    data: PropTypes.array,
    dataCy: PropTypes.string,
    hoverText: PropTypes.string,
    icon: PropTypes.string,
    iconFamily: PropTypes.string,
    onClick: PropTypes.func,
}

export default SocialUserButton

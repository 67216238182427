export const valueConverter = (loadedOptions, multi, shouldHideExtraValues, value) => {
    const loadItemsValues = loadedOptions.reduce((acc, option) => {
        acc[option.value] = option
        return acc
    }, {})

    // when a custom field with multi or single select is first created
    // the options do not have a slug - so the option's label is saved as the
    // default value instead of it's slug. When editing the custom field, loadItemsValues
    // uses the slug as the key and therefore cannot detect the default value when it
    // is the label. allLabelsMap is a dictionary with the option label as key and the option
    // slug as the value. This helps us find the corresponding slug/value pair
    const allLabelsMap = {}
    Object.keys(loadItemsValues).map((load) => (allLabelsMap[loadItemsValues[load].label] = load))

    if (multi && Array.isArray(value) && value.length) {
        return value.map((item) => {
            // if the item is a string or int contained within the options array
            if (loadItemsValues[item]) {
                return loadItemsValues[item]
                // if the item is an object whose values exist in the options array.
            } else if (
                typeof item === "object" &&
                item !== null &&
                (loadItemsValues[item.value] || loadItemsValues[item.id])
            ) {
                // we return the item rather than loadItemsValues[item.value]
                // because it works and it allows us to add custom functionality
                // in a per-value basis
                return item
            } else if (allLabelsMap.hasOwnProperty(item)) {
                // check if the defaultValue is the label
                return loadItemsValues[allLabelsMap[item]]
            }

            // if the item does not exist in the options array at all,
            // and we are passing shouldHideExtraValues, hide it in the select
            // if not, just pass the value
            return shouldHideExtraValues ? undefined : item
        })
    } else if (!Array.isArray(value)) {
        // the AlertForm and other legacy parts of the site use a weird object value
        // that somehow worked in react-select-2
        if (typeof value === "object" && value !== null) {
            return (
                loadItemsValues[value.value] ||
                loadItemsValues[value.id] ||
                (shouldHideExtraValues
                    ? // if shouldHideExtraValues is false, add values to the select
                      // even if they do not map to any known values in the options array
                      undefined
                    : value.value || value.id || value)
            )
        }

        // for single option cf where the default value has been saved as the label
        if (allLabelsMap.hasOwnProperty(value)) {
            // check if the defaultValue is the label
            return loadItemsValues[allLabelsMap[value]]
        }

        // if not an object, try to index into the options array using the value
        return (
            loadItemsValues[value] ||
            // if shouldHideExtraValues is false, add values to the select
            // even if they do not map to any known values in the options array
            (shouldHideExtraValues ? undefined : value)
        )
    }

    return value
}

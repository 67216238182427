import styled from "styled-components"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import { UnstyledButton } from "@mantine/core"
import { socialButtonBackgroundColor } from "QuorumGrassroots/widgets/styled-components/styles"

export const StyledFacebookButton = styled(StyledButton)`
    font-size: 18px;
    padding-left: 30px;
    ${socialButtonBackgroundColor({ platform: DjangIO.app.grassroots.enums.SocialMediaType.facebook.value })}

    i {
        position: relative;
        right: 14px;
    }
`

export const StyledFacebookButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    ${(props) => props.isCampaignPage && "margin-top: 0px;"}
`
export const StyledFacebookButtonText = styled.span`
    color: var(--gray-7, #495057);
    font-feature-settings:
        "clig" off,
        "liga" off;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; /* 100% */
`

export const NewStyledFacebookButton = styled(UnstyledButton)`
    display: flex;
    height: 2.5rem;
    padding: 0.75rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    align-self: stretch;
    border-radius: 0.25rem;
    border: #dee2e6;
    background: #ffffff;
    width: 100%;
    i {
        height: 20px;
        color: #3b5998;
    }
`

import styled from "styled-components"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { styleInMobileScreenSize, styleInDesktopScreenSize } from "QuorumGrassroots/styled-components/helperFunctions"

export const StyledStatCard = styleWithOrgDesign(styled.div`
    align-items: center;
    background-color: white;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    display: flex;
    height: 380px;
    width: 380px;
    max-height: 300px;
    max-width: 300px;
    ${styleInMobileScreenSize(`
        flex-direction: column;
        height: 340px;
        margin-top: 15px;
        margin-bottom: 15px;
        width: 340px;
    `)}
    ${(props) =>
        props.useCardColor
            ? `
            background-color: ${props.cardColor};
            color: white;
        `
            : "background-color: white;"}
    ${(props) => `width: ${DjangIO.app.grassroots.enums.NumberStatsLayoutType.by_value(props.layout).card_width};`}
    ${(props) => `height: ${DjangIO.app.grassroots.enums.NumberStatsLayoutType.by_value(props.layout).card_width};`}
    ${(props) => props.isFirst && "margin-left: 0px;"}
    ${(props) => props.isLast && "margin-right: 0px;"}
`)

export const StyledStat = styled.div`
    font-size: 70px;
    ${(props) =>
        styleInDesktopScreenSize(`
        ${`font-size: ${DjangIO.app.grassroots.enums.NumberStatsLayoutType.by_value(props.layout).stat_font_size};`}
        ${`line-height: ${DjangIO.app.grassroots.enums.NumberStatsLayoutType.by_value(props.layout).stat_font_size};`}
    `)}
    ${styleInMobileScreenSize(`
        line-height: 75px;
    `)}
`

export const StyledStatTitle = styled.div`
    align-self: center;
    font-size: 30px;
    ${(props) =>
        styleInDesktopScreenSize(`
        ${`font-size: ${
            DjangIO.app.grassroots.enums.NumberStatsLayoutType.by_value(props.layout).stat_title_font_size
        };`}
    `)}
`

export const StyledStatContentWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
`

export const StyledStatDescription = styled.div`
    align-self: center;
    font-size: 18px;
    ${(props) =>
        styleInDesktopScreenSize(`
        ${`font-size: ${
            DjangIO.app.grassroots.enums.NumberStatsLayoutType.by_value(props.layout).stat_description_font_size
        };`}
    `)}
`

export const StyledCardsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    ${styleInMobileScreenSize(`
        align-items: center;
        flex-direction: column;
    `)}
`

export const StyledTitle = styled.div`
    align-self: flex-start;
    margin-left: 9px;
    margin-bottom: 24px;
    font-size: 30px;
    ${(props) => props.useBackgroundColor && "color: white;"}
`

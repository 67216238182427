import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"

import { MessageGroupLayout } from "QuorumGrassroots/campaign-forms/components/ReusableForms/MessageGroupLayout"
import SingleBodyMessageLayout from "QuorumGrassroots/campaign-forms/components/ReusableForms/SingleBodyMessageLayout"
import { withLock } from "QuorumGrassroots/styled-components/components/InputWithLock"
import { MultiSelect } from "QuorumGrassroots/styled-components/components/MultiSelect"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import { StyledGroupSelector } from "QuorumGrassroots/styled-components/components/StyledGroupSelector"
import { StyledText } from "QuorumGrassroots/styled-components/components/StyledText"
import { StyledWriteForm } from "QuorumGrassroots/styled-components/components/StyledWriteForm"
import { MultiActionMessageLayout } from "QuorumGrassroots/campaign-forms/components/ReusableForms/MultiActionMessageLayout"

export const MultiSelectWithLock = withLock(MultiSelect)

const { CampaignType, DraftOptions } = DjangIO.app.grassroots.campaign.types

export const GenericWriteForm = ({ t, ...props }) => {
    const submitButtonRef = useRef(null)
    const [, setForceRerender] = useState(false)
    const [selectedGroupId, setSelectedGroupId] = useState(
        props.isMultiAction ? Object.keys(props.campaign.messages)[0] : props.activeGroupId,
    )

    const currentTargets = props.isMultiAction
        ? props.maTargets[props.campaign.campaign_type]?.[selectedGroupId]?.filter((target) => !target.is_custom)
        : props.multiSelectOptions

    // We added this to re-render the component which fixes the issue of
    // one-click submit failing when social sharing is disabled
    useEffect(() => {
        setForceRerender((prev) => !prev)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitButtonRef.current])

    const MultiSelectComponent = useMemo(
        () => (props.campaign.supporters_can_choose_targets ? MultiSelect : MultiSelectWithLock),
        [props.campaign, currentTargets?.length],
    )

    const isCombinedCampaign = props.campaign.campaign_type === CampaignType.combined_campaign.value
    const canEditCampaign = props.campaign.draft_requirements !== DraftOptions.cannot_edit.value

    const isWriteAMemberCampaign = props.campaign.campaign_type === CampaignType.write_member.value
    const shouldShowSelectorForMultiAction =
        isWriteAMemberCampaign && props.isMultiAction && Object.values(props.campaign.messages)?.length > 1
    const shouldShowGroupSelectorRedux = isWriteAMemberCampaign && props.messageGroups?.length > 1
    const shouldShowGroupSelector = shouldShowSelectorForMultiAction || shouldShowGroupSelectorRedux

    const shouldShowTargetSelectForMultiAction =
        props.isMultiAction &&
        isWriteAMemberCampaign &&
        Object.values(props.campaign.messages)?.length > 1 &&
        currentTargets?.length > 0
    const shouldShowTargetSelectRedux = props.shouldShowMultiMessageTargetTable && currentTargets?.length > 0
    const shouldShowTargetSelect = shouldShowTargetSelectForMultiAction || shouldShowTargetSelectRedux

    const onChangeGroupSelector = (groupId) => {
        if (props.isMultiAction) {
            setSelectedGroupId(groupId)
            return
        }
        props.changeMessageGroup(props.uniqueWidgetId, groupId)
    }
    const groupSelectorValue = props.isMultiAction ? selectedGroupId : props.activeGroupId
    const groupSelectorData = props.isMultiAction
        ? Object.values(props.maMessages?.[props.campaign.campaign_type]).map((group) => ({
              value: String(group?.id),
              label: group?.name,
          }))
        : props.messageGroups.map((group) => ({ value: String(group.id), label: group.name }))

    const hasMessages = props.isMultiAction
        ? Object.keys(props.maMessages[props.campaign.campaign_type]).length
        : props.hasMessages

    if (!hasMessages) {
        return <div className="no-messages-text">{t("campaign.write.no_messages_to_send")}</div>
    }

    const onChangeTargets = (officialIds) => {
        if (props.isMultiAction) {
            props.setTargets((lastTargets) => {
                const targets = lastTargets[props.campaign.campaign_type][selectedGroupId]
                const updatedTargets = targets.map((target) => {
                    if (officialIds.includes(target.value)) {
                        return { ...target, targeted: true }
                    }
                    return { ...target, targeted: false }
                })
                return {
                    ...lastTargets,
                    [props.campaign.campaign_type]: {
                        ...lastTargets[props.campaign.campaign_type],
                        [selectedGroupId]: updatedTargets,
                    },
                }
            })
        }
        props.toggleTarget(props.uniqueWidgetId, officialIds)
    }

    const submitDisabled = props.invalid || props.submitting || !Boolean(props.numberOfMessagesAfterFiltering)

    // If one click registration is enabled, emulate it by clicking the submit button for the advocate
    if (props.shouldSubmitOneClickRegistration && !submitDisabled && submitButtonRef.current) {
        props.disableOneClickRegistration()
        submitButtonRef.current.props.onClick()
    }

    const getSubmitButtonProps = () => {
        const defaultProps = {
            onClick: props.handleSubmit,
            disabled: submitDisabled,
            "data-cy": "submit",
            isCampaignPage: true,
            activateNGGTheme: true,
            ref: submitButtonRef,
        }
        if (!props.isMultiAction) return defaultProps
        return {
            ...defaultProps,
            disabled: props.isLoading,
            onClick: () => props.submitMultiActionChild(props.campaign),
        }
    }

    const renderSubmitButton = () => {
        return (
            <StyledButton {...getSubmitButtonProps()}>
                {props.submitting ? t("form.submitting") : t(props.submitButtonTextKey)}
            </StyledButton>
        )
    }

    const renderMessages = () => {
        if (props.isMultiAction) {
            return <MultiActionMessageLayout {...props} t={t} selectedGroupId={selectedGroupId} />
        }
        if (
            props.campaign.campaign_type === DjangIO.app.grassroots.campaign.types.CampaignType.combined_campaign.value
        ) {
            // if it's a combined campaign, we'll take care of the rendering in
            // CombineCampaignForm. Too much custom display logic to keep here.
            return null
        } else if (isWriteAMemberCampaign) {
            return <MessageGroupLayout {...props} t={t} />
        } else {
            return <SingleBodyMessageLayout {...props} t={t} />
        }
    }

    const hasAnyMessageInMessageGroupsAllowedEdition =
        props.campaign._extra?.message_groups.some(
            (a) => a.advocate_editing_permission !== DraftOptions.cannot_edit.value,
        ) ?? false

    const selectedTargets = currentTargets?.filter((targets) => targets.targeted).map((target) => target.value)

    return (
        <StyledWriteForm className="write-form">
            {canEditCampaign && isWriteAMemberCampaign && (
                <StyledText>
                    {hasAnyMessageInMessageGroupsAllowedEdition
                        ? t("campaign.write.edit_messages")
                        : t("campaign.write.cant_edit_messages")}
                </StyledText>
            )}
            {shouldShowGroupSelector && (
                <StyledGroupSelector
                    data-cy="group-selector"
                    value={groupSelectorValue}
                    onChange={onChangeGroupSelector}
                    data={groupSelectorData}
                />
            )}
            {shouldShowTargetSelect && (
                <MultiSelectComponent
                    clearable
                    onChange={onChangeTargets}
                    value={selectedTargets}
                    data={currentTargets}
                    label={`Officials (${selectedTargets?.length})`}
                />
            )}
            {isCombinedCampaign && renderSubmitButton()}
            {renderMessages()}
            {props.children}
            {renderSubmitButton()}
        </StyledWriteForm>
    )
}

GenericWriteForm.propTypes = {
    // from campaign container
    disableOneClickRegistration: PropTypes.func.isRequired,
    // from campaignPassThroughProps in campaign/selectors
    shouldSubmitOneClickRegistration: PropTypes.bool.isRequired,
    // from write-selectors
    shouldShowMultiMessageTargetTable: PropTypes.bool,
    submitButtonTextKey: PropTypes.string,
    // from general-selectors
    hasMessages: PropTypes.bool,
    numberOfMessagesAfterFiltering: PropTypes.number,
    activeSelectId: PropTypes.string,
    campaign: PropTypes.object,
    remainingMessageIds: PropTypes.arrayOf(PropTypes.string),
    // provided by redux form
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export const AM = "AM"
export const PM = "PM"

export const HOURS12 = Object.freeze(
    Array.from({ length: 12 })
        .map((_, index) => ({
            label: index + 1,
            value: index + 1
        }))
)

export const HOURS24 = Object.freeze(
    Array.from({ length: 24 })
        .map((_, index) => ({
            label: String(index).padStart(2, "0"),
            value: index
        }))
)

export const MINUTES60 = Object.freeze(
    Array.from({length: 60})
        .map((_, index) => ({
            label: String(index).padStart(2, "0"),
            value: index
        }))
)

export const AMPM = Object.freeze([
    { label: AM, value: false },
    { label: PM, value: true }
])

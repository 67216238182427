import LoginRegistrationWidget from "QuorumGrassroots/widgets/LoginRegistration/containers/index"
import SignUpWidget from "QuorumGrassroots/widgets/SignUp/containers/index"
import UpdateInformationWidget from "QuorumGrassroots/widgets/UpdateInformation/containers/index"
import CampaignListWidget from "QuorumGrassroots/widgets/CampaignList/containers/index"
import CampaignWidget from "QuorumGrassroots/widgets/Campaign/containers/index"
import IssueListWidget from "QuorumGrassroots/widgets/IssueList/containers/index"
import IssueWidget from "QuorumGrassroots/widgets/Issue/containers/index"
import OfficialListWidget from "QuorumGrassroots/widgets/OfficialList/containers/index"
import OfficialWidget from "QuorumGrassroots/widgets/Official/containers/index"
import TextWidget from "QuorumGrassroots/widgets/Text/containers/index"
import VideoWidget from "QuorumGrassroots/widgets/Video/containers/index"
import ImageWidget from "QuorumGrassroots/widgets/Image/containers/index"
import { LogInteractionWidget, LogRelationshipWidget } from "QuorumGrassroots/widgets/ExternalLog/containers/index"
import GamificationStatsWidget from "QuorumGrassroots/widgets/GamificationStats/containers/index"
import PublicSheetWidget from "QuorumGrassroots/widgets/PublicSheet/containers/index"
import EventWidget from "QuorumGrassroots/widgets/Event/containers/index"
import EventListWidget from "QuorumGrassroots/widgets/EventList/containers/index"
import TwitterTimelineWidget from "QuorumGrassroots/widgets/TwitterTimeline/containers/index"
import DonationFormWidget from "QuorumGrassroots/widgets/DonationForm/containers/index"
import DummyWidget from "QuorumGrassroots/widgets/Dummy/container"
import EmbedWidget from "QuorumGrassroots/widgets/Embed/containers"
import FullBleedImageWidget from "QuorumGrassroots/widgets/FullBleedImage/containers"
import ImageCollageWidget from "QuorumGrassroots/widgets/ImageCollage/containers"
import CardsSectionWidget from "QuorumGrassroots/widgets/CardsSection/containers"
import NumberStatsWidget from "QuorumGrassroots/widgets/NumberStats/containers"
import CallToActionWidget from "QuorumGrassroots/widgets/CallToAction/containers"
import ImageVideoCarouselWidget from "QuorumGrassroots/widgets/ImageVideoCarousel/containers/index"
import ContactUsWidget from "QuorumGrassroots/widgets/ContactUs/containers/index"
import CustomDataWidget from "QuorumGrassroots/widgets/CustomDataWidget/containers/index"
import RegionListWidget from "QuorumGrassroots/widgets/RegionList/containers/index"
import FlagAndRegionName from "QuorumGrassroots/widgets/FlagAndRegionName/containers/index"
import DistrictListWidget from "QuorumGrassroots/widgets/DistrictList/containers/index"
import DonationHistoryWidget from "QuorumGrassroots/widgets/DonationHistory/containers/index"

const WidgetTypes = DjangIO.app.grassroots.types.GrassrootsWidgetType
const widgetMap = {
    [WidgetTypes.campaign.value]: CampaignWidget,
    [WidgetTypes.draft_campaign.value]: CampaignWidget,
    [WidgetTypes.issue.value]: IssueWidget,
    [WidgetTypes.official.value]: DummyWidget,
    [WidgetTypes.text.value]: TextWidget,
    [WidgetTypes.video.value]: VideoWidget,
    [WidgetTypes.image.value]: ImageWidget,
    [WidgetTypes.log_interaction.value]: LogInteractionWidget,
    [WidgetTypes.log_relationship.value]: LogRelationshipWidget,
    [WidgetTypes.sign_in.value]: LoginRegistrationWidget,
    [WidgetTypes.update_info.value]: UpdateInformationWidget,
    [WidgetTypes.campaign_list.value]: CampaignListWidget,
    [WidgetTypes.issue_list.value]: IssueListWidget,
    [WidgetTypes.sign_up.value]: SignUpWidget,
    [WidgetTypes.official_list.value]: OfficialListWidget,
    [WidgetTypes.official.value]: OfficialWidget,
    [WidgetTypes.gamification_stats.value]: GamificationStatsWidget,
    [WidgetTypes.public_sheet.value]: PublicSheetWidget,
    [WidgetTypes.event.value]: EventWidget,
    [WidgetTypes.event_list.value]: EventListWidget,
    [WidgetTypes.twitter_timeline.value]: TwitterTimelineWidget,
    [WidgetTypes.donation_form.value]: DonationFormWidget,
    [WidgetTypes.embed.value]: EmbedWidget,
    [WidgetTypes.full_bleed_image.value]: FullBleedImageWidget,
    [WidgetTypes.image_collage.value]: ImageCollageWidget,
    [WidgetTypes.cards_section.value]: CardsSectionWidget,
    [WidgetTypes.number_stats.value]: NumberStatsWidget,
    [WidgetTypes.call_to_action.value]: CallToActionWidget,
    [WidgetTypes.image_video_carousel.value]: ImageVideoCarouselWidget,
    [WidgetTypes.contact_us.value]: ContactUsWidget,
    [WidgetTypes.custom_data_widget.value]: CustomDataWidget,
    [WidgetTypes.region_list.value]: RegionListWidget,
    [WidgetTypes.flag_and_region_name.value]: FlagAndRegionName,
    [WidgetTypes.district_list.value]: DistrictListWidget,
    [WidgetTypes.donor_history.value]: DonationHistoryWidget,
}

export default widgetMap

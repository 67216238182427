import React from "react"
import PropTypes from "prop-types"

import {
    StyledTierModal,
    StyledExitButton,
    StyledTierHeader,
    StyledTierImageWrapper,
    StyledTierImage,
    StyledPointsMessage,
    StyledThanksMessage,
    StyledAcknowledgeButton,
} from "QuorumGrassroots/framework/components/GamificationTierModal/style"

const GamificationTierModal = (props) => {
    return (
        <StyledTierModal className="tier-modal" show={props.tierModalShown} onHide={props.hideGamificationTierModal}>
            <StyledExitButton className="exit-button" onClick={props.hideGamificationTierModal} variant="default">
                <i className="fa fa-times" />
            </StyledExitButton>
            <StyledTierHeader className="tier-header">
                {props.imagePath ? (
                    <StyledTierImageWrapper>
                        <StyledTierImage imagePath={props.imagePath} />
                        <span>{props.currentTier}</span>
                    </StyledTierImageWrapper>
                ) : (
                    <div>
                        <i className={`fa ${props.achievementIcon}`} />
                        <span>{props.currentTier}</span>
                        <i className={`fa ${props.achievementIcon}`} />
                    </div>
                )}
            </StyledTierHeader>
            <StyledPointsMessage className="points-message">
                {props.t(
                    parseInt(props.supporterPoints) === 1
                        ? "gamification.modal.point.message"
                        : "gamification.modal.points.message",
                    { points: props.supporterPoints },
                )}
            </StyledPointsMessage>
            <StyledThanksMessage className="thanks-message">
                {props.tierCustomText || props.t("gamification.modal.thanks.message")}
            </StyledThanksMessage>
            <StyledAcknowledgeButton className="acknowledge-button" onClick={props.hideGamificationTierModal}>
                {props.t("gamification.modal.button.text")}
            </StyledAcknowledgeButton>
        </StyledTierModal>
    )
}

GamificationTierModal.propTypes = {
    achievementIcon: PropTypes.string.isRequired,
    currentTier: PropTypes.string.isRequired,
    hideGamificationTierModal: PropTypes.func.isRequired,
    imagePath: PropTypes.string,
    supporterPoints: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    tierModalShown: PropTypes.bool.isRequired,
    tierCustomText: PropTypes.string,
}

GamificationTierModal.defaultProps = {
    imagePath: "",
    tierCustomText: "",
}

export default GamificationTierModal

import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const StyledIcon = styled.div`
    border-radius: ${rules.BorderRadius};
    color: ${({iconColor}) => iconColor};
    // this size is to adjust for top/bottom padding on square icons
    font-size: 16px;
    margin-right: 10px;
    padding: 0 1px;

    // TODO: standardize interactive states in rules.js
    &:active, &:focus, &:focus-within {
        border-radius: 2px;
        box-shadow: 0 0 0 2px ${colors.QuorumBlue};
        outline: 0;
        padding: 0 1px;
    }
`

export const SelectableSubheader = styled.div`
    ${rules.text.smallHeight};
    background-color: ${colors.White};
    color: ${colors.CloudBurst};
    display: flex;

    ${props => (!props.invisible) && css`
        margin-bottom: 7px;
    `}

    ${({isCollapsedGroup}) => (
        isCollapsedGroup ? css`
        ${rules.weight.regular};
    ` : css`${rules.weight.medium};`
    )}

    ${({isGroup}) => {
        if (isGroup) {
            return css`
                align-items: center;
                cursor: pointer;
                margin: 1px 0px 2px 2px;
                padding: 2px 0 2px 2px;

                &:hover {
                    background-color: ${colors.DarkerPorcelain};
                }

                &:hover > ${StyledIcon} {
                    color: ${colors.QuorumBlue};
                }
            `
        }
    }}
`

export const StyledTitle = styled.div`
    color: ${colors.gray7};
    overflow: hidden;
    text-overflow: ellipsis;

    &::selection {
        background: ${colors.QuorumBlue};
        color: ${colors.White};
    }
`

export const TooltipIcon = styled.div`
    cursor: help;
    margin-top: 1px;
    padding-left: 5px;
`

import React from "react"
import PropTypes from "prop-types"
import * as S from "Components/Molecules/ButtonGrid/style"


const ButtonGrid = ({
        children,
        isSingleRow,
        maxColumns,
    }) => {
    const percentMinWidth = Math.floor(100/maxColumns)
    return (
        <S.Grid
            data-auto-cy="MoleculeButtonGrid"
            isSingleRow={isSingleRow}
            percentMinWidth={percentMinWidth}
        >
            {
                React.Children.map(children,
                    item => item && React.cloneElement(item, { isGridButton: true })
                )
            }
        </S.Grid>
    )
}

ButtonGrid.defaultProps = {
    isSingleRow: false,
    maxColumns: 2,
}

ButtonGrid.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),
    isSingleRow: PropTypes.bool,
    maxColumns: PropTypes.number,
}

export default ButtonGrid

import styled from "styled-components"

import * as colors from "Components/colors"

// create a string for grid-template-columns given an array of percentage values
const valuesToColumnString = (values) => values.map(v => `${v}fr`).join(" ")

export const KnobSlider = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
`

export const LabelGrid = styled.div`
    display: grid;
    width: 100%;

    grid-template-columns: ${({values}) => valuesToColumnString(values)} 1fr;
    justify-items: center;
`

export const Label = styled.div`
    flex: 1;
    color: ${({color}) => color};

    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;

    padding: 0 11px;
`

export const Handle = styled.div`
    // circle size and radius
    height: 20px;
    width: 20px;
    border-radius: 50%;

    // center handle over slider position
    transform: translate(-50%, -50%);

    // position handle along the slider
    position: absolute;
    left: ${({value}) => Math.max(0, Math.min(100, value))}%;

    // colors
    background: linear-gradient(to right, ${({colorA}) => colorA || colors.QuorumBlue} 0%, ${({colorB}) => colorB || "#738ADB"} 100%);

    // inner circle
    &:after {
        content: "";
        background-color: white;
        height: 60%;
        width: 60%;
        position: absolute;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Atoms/ColorInputsDefault/style"

export const ColorInputsDefault = props => {
    return (
        <S.ColorInputsDefault
            data-auto-cy="AtomColorInputsDefault"
        >
            <S.ColorInputs>
                { props.colorInputs }
            </S.ColorInputs>
        </S.ColorInputsDefault>
    )
}

ColorInputsDefault.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),

    // props
    colorInputs: PropTypes.arrayOf(PropTypes.element),
    dataCy: PropTypes.string,
}

export default ColorInputsDefault

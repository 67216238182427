import React from "react"
import PropTypes from "prop-types"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import { StyledImageWidget } from "QuorumGrassroots/widgets/Image/components/style"

const ImageWidget = (props) => (
    <WidgetStyleWrapper
        className="image-widget"
        md={props.colWidth}
        useWidgetStyling={props.useWidgetStyling}
        title={props.title}
    >
        <StyledImageWidget src={props.content} alt={props.title} />
    </WidgetStyleWrapper>
)

ImageWidget.propTypes = {
    colWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    content: PropTypes.string.isRequired,
    title: PropTypes.string,
    useWidgetStyling: PropTypes.bool,
}

ImageWidget.defaultProps = {
    title: "",
    useWidgetStyling: true,
}

export default ImageWidget

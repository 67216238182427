import styled from 'styled-components'

export const ResourceTableWrapper = styled.div`
    #sheet-column {
        #resource-table-wrapper {
            .sheet-wrapper-inner {
                height: ${({ height }) => height};
            }
        }
    }
`

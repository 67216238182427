import React from "react"
import PropTypes from "prop-types"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import {
    StyledStatCard,
    StyledStatDescription,
    StyledStatContentWrapper,
    StyledStatTitle,
    StyledStat,
    StyledCardsWrapper,
    StyledTitle,
} from "QuorumGrassroots/widgets/NumberStats/components/style"
import { StyledBackground } from "QuorumGrassroots/widgets/styled-components/StyledWidget"

const NumberStatsWidget = (props) => {
    const renderCard = ({ card, isFirst, isLast, layout, useCardColor, cardColor }) => (
        <StyledStatCard
            className="number-stats-card"
            cardColor={cardColor}
            layout={layout}
            useCardColor={useCardColor}
            isFirst={isFirst}
            isLast={isLast}
        >
            <StyledStatContentWrapper useCardColor={useCardColor}>
                <StyledStat layout={layout}>{card.stat}</StyledStat>
                <StyledStatTitle layout={layout}>{card.title}</StyledStatTitle>
                <StyledStatDescription layout={layout}>{card.description}</StyledStatDescription>
            </StyledStatContentWrapper>
        </StyledStatCard>
    )

    const renderCardComponents = ({ cards, layout, useCardColor, cardColor }) => {
        const numCards = DjangIO.app.grassroots.enums.NumberStatsLayoutType.by_value(layout).num_stats
        const renderedCards = []
        // only render cards in the given layout
        for (let i = 0; i < numCards; i++) {
            const isFirst = i === 0
            const isLast = i === numCards - 1
            renderedCards.push(
                renderCard({
                    card: cards[i],
                    isFirst,
                    isLast,
                    layout,
                    useCardColor,
                    cardColor,
                }),
            )
        }
        return renderedCards
    }

    return (
        <WidgetStyleWrapper className="number-stats-widget" md={props.colWidth} useWidgetStyling={false}>
            <StyledBackground
                className="number-stats-background"
                useBackgroundColor={props.useBackgroundColor}
                backgroundColor={props.backgroundColor}
                isFirst={props.isFirst}
                isLast={props.isLast}
                nextWidget={props.nextWidget}
            >
                {props.title && (
                    <StyledTitle className="number-stats-title" useBackgroundColor={props.useBackgroundColor}>
                        {props.title}
                    </StyledTitle>
                )}
                <StyledCardsWrapper>
                    {renderCardComponents({
                        cards: props.cards,
                        layout: props.layout,
                        useCardColor: props.useCardColor,
                        cardColor: props.cardColor,
                    })}
                </StyledCardsWrapper>
            </StyledBackground>
        </WidgetStyleWrapper>
    )
}

NumberStatsWidget.propTypes = {
    backgroundColor: PropTypes.string,
    cardColor: PropTypes.string,
    cards: PropTypes.object.isRequired,
    colWidth: PropTypes.number,
    isFirst: PropTypes.bool.isRequired,
    isLast: PropTypes.bool.isRequired,
    layout: PropTypes.number.isRequired,
    nextWidget: PropTypes.number,
    title: PropTypes.string,
    useBackgroundColor: PropTypes.bool.isRequired,
    useCardColor: PropTypes.bool.isRequired,
}

export default NumberStatsWidget

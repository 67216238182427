import React, { forwardRef } from "react"
import { Checkbox } from "@mantine/core"
import {
    BadgeContainer,
    BadgeImageContainer,
    BadgeCloseButton,
    BadgeTextContainer,
    ItemContainer,
    ItemTextContainer,
    StyledMultiSelect,
} from "QuorumGrassroots/styled-components/components/MultiSelect/styles"

const Badge = ({ label, onRemove, className = "", imageUrl = "", ...props }) => (
    <div {...props}>
        <BadgeContainer className={className}>
            {imageUrl && (
                <BadgeImageContainer>
                    <img src={imageUrl} alt="thumbnail" />
                </BadgeImageContainer>
            )}
            <BadgeTextContainer>{label}</BadgeTextContainer>
            {!props.disabled && (
                <BadgeCloseButton
                    className="close-button"
                    onMouseDown={onRemove}
                    variant="transparent"
                    tabIndex={-1}
                    iconSize={16}
                />
            )}
        </BadgeContainer>
    </div>
)

const Item = forwardRef(({ label, className = "", ...props }, ref) => (
    <div ref={ref} {...props}>
        <ItemContainer className={className} selected={props.selected}>
            <Checkbox checked={props.selected} />
            <ItemTextContainer>{label}</ItemTextContainer>
        </ItemContainer>
    </div>
))

export function MultiSelect({ disableSelectedItemFiltering = true, ...props }) {
    return (
        <StyledMultiSelect
            valueComponent={props.valueComponent || Badge}
            itemComponent={props.itemComponent || Item}
            disableSelectedItemFiltering={disableSelectedItemFiltering}
            {...props}
        />
    )
}

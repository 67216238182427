import styled from "styled-components"

import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"

import { constants } from "QuorumGrassroots/constants"
import {
    getDynamicColorHex,
    getFontColorForBackgroundContrast,
} from "QuorumGrassroots/styled-components/helperFunctions"

export const StyledSubmitButton = styleWithOrgDesign(styled.button`
    display: flex;
    height: 40px;
    width: 100%;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    align-self: stretch;
    min-height: 40px;
    outline: none;

    border-radius: 4px;
    border-width: 0;
    font-feature-settings:
        "clig" off,
        "liga" off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 100% */

    color: ${(props) =>
        getFontColorForBackgroundContrast(
            getDynamicColorHex(props.organizationDesign.primary_color),
            constants.freshDarkGrey,
        )};
    background-color: ${(props) => getDynamicColorHex(props.organizationDesign.primary_color)};

    &:hover,
    &:focus,
    &:active {
        transition: background-color 0.25s ease-in-out;
        ${(props) => `background-color: ${getDynamicColorHex(props.organizationDesign.primary_color, true)};`}
    }

    i {
        display: flex;
        align-items: center;
        height: 20px;
    }
`)

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/SelectSearchBar/style"

const SelectSearchBar = ({
    dataCy,
    children,
}) => (
    <S.SelectSearchBar
        data-auto-cy="MoleculeSelectSearchBar"
        data-cy={dataCy}
    >
        {children}
    </S.SelectSearchBar>
)

SelectSearchBar.defaultProps = {}

SelectSearchBar.propTypes = {
    dataCy: PropTypes.string,
    children: PropTypes.element,
}

export default SelectSearchBar

import React from "react"
import PropTypes from "prop-types"

import {
    StyledColumnWidget,
    StyledWidgetHeader,
    StyledTitleSection,
} from "QuorumGrassroots/widgets/styled-components/StyledWidget"
import {
    StyledWidgetContents,
    StyledTitleDescriptionSection,
    StyledStatSection,
    StyledIndividualStatBox,
    StyledStatBoxWrapper,
    StyledStatIconWrapper,
    StyledCurrentTierIcon,
    StyledStatIcon,
    StyledStatValue,
    StyledStatHr,
    StyledStatType,
} from "QuorumGrassroots/widgets/GamificationStats/components/style"

export const GamificationStatsWidget = (props) => {
    if (!props.gamificationEnabled) {
        return <div />
    }

    const renderIndividualStatBox = (value, type, unlocked, options = {}) => {
        const fullWidth = props.widgetWidth === 12

        return (
            <StyledIndividualStatBox className="stat-box" unlocked={unlocked}>
                <StyledStatBoxWrapper className="stat-box-wrapper" fullWidth={fullWidth}>
                    <StyledStatIconWrapper className="stat-icon-wrapper" fullWidth={fullWidth}>
                        {props.currentTierIcon && unlocked ? (
                            <StyledCurrentTierIcon
                                className="custom-tier-icon"
                                currentTierIcon={props.currentTierIcon}
                            />
                        ) : (
                            <StyledStatIcon
                                className={`fa ${unlocked ? props.achievementIcon : "fa-lock"}`}
                                fullWidth={fullWidth}
                                unlocked={unlocked}
                            />
                        )}
                    </StyledStatIconWrapper>
                    <div className="stat-value-wrapper">
                        <StyledStatValue
                            className="stat-value"
                            fullWidth={fullWidth}
                            lockedFont={!unlocked}
                            responsiveFont={options.responsiveFont}
                        >
                            {value}
                        </StyledStatValue>
                        <StyledStatHr />
                        <StyledStatType className="stat-type" fullWidth={fullWidth}>
                            {type}
                        </StyledStatType>
                    </div>
                </StyledStatBoxWrapper>
            </StyledIndividualStatBox>
        )
    }

    return (
        <StyledColumnWidget className="gamification-stats" md={props.colWidth} paddingless>
            <StyledWidgetContents className="stats-contents">
                <StyledTitleDescriptionSection
                    className="stats-description-section"
                    multipleStats={props.multipleStats}
                >
                    <StyledWidgetHeader className="stats-header">
                        <StyledTitleSection className="stats-title">
                            <div className="statistics-title" data-cy="title">
                                {props.title}
                            </div>
                        </StyledTitleSection>
                    </StyledWidgetHeader>
                    <div className="statistics-description" dangerouslySetInnerHTML={{ __html: props.content }} />
                </StyledTitleDescriptionSection>
                <StyledStatSection className="stats-section" multipleStats={props.multipleStats}>
                    {props.showGamificationTiers &&
                        renderIndividualStatBox(
                            props.currentTierText,
                            props.t("gamification.tier.label"),
                            props.tierPointsReached,
                            { responsiveFont: true },
                        )}
                    {props.showGamificationRanks &&
                        renderIndividualStatBox(
                            props.currentRankText,
                            props.t("gamification.rank.label"),
                            props.rankPointsReached,
                            { responsiveFont: true },
                        )}
                    {renderIndividualStatBox(
                        props.pointText,
                        props.t(
                            parseInt(props.pointText) === 1 ? "gamification.point.label" : "gamification.points.label",
                        ),
                        props.supporterPointsReached,
                    )}
                </StyledStatSection>
            </StyledWidgetContents>
        </StyledColumnWidget>
    )
}

GamificationStatsWidget.propTypes = {
    achievementIcon: PropTypes.string.isRequired,
    colWidth: PropTypes.number,
    content: PropTypes.string.isRequired,
    currentRankText: PropTypes.string.isRequired,
    currentTierText: PropTypes.string.isRequired,
    gamificationEnabled: PropTypes.bool.isRequired,
    multipleStats: PropTypes.bool.isRequired,
    pointText: PropTypes.string.isRequired,
    rankPointsReached: PropTypes.bool.isRequired,
    showGamificationTiers: PropTypes.bool.isRequired,
    showGamificationRanks: PropTypes.bool.isRequired,
    supporterPointsReached: PropTypes.bool.isRequired,
    tierPointsReached: PropTypes.bool.isRequired,
    title: PropTypes.string,
    t: PropTypes.func.isRequired,
    widgetWidth: PropTypes.number.isRequired,
}

GamificationStatsWidget.defaultProps = {
    colWidth: 12,
    title: "",
}

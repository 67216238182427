import styled, { css } from "styled-components"

import * as rules from "Components/rules"

import { Anchor } from "Components/Atoms/Anchor/style"
import { Input } from "Components/Atoms/Input/style"
import { ListItem } from "Components/Compounds/ListItem/style"

import StyledInput from "Components/Atoms/Input"

export const ListWidgetInput = styled(StyledInput)`
    padding: 6px 17px;

    ${props => (props.noHeader) && css`
        padding-top: 9px;
        padding-right: 77px;

        ${props => (props.isExternal) && css`
            padding-right: 47px;

            ${props => (props.externalDownloadsDisabled) && css`
                padding-right: 17px;
            `}
        `}
    `}


    ${Input} {
        ${props => (props.editing) && css`
            pointer-events: none;
        `}
    }
`

export const ListWidgetListItems = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0px 17px;
    overflow: auto;

    // the react-virtualized wrapper Components add a blue focus style
    * :focus {
        outline: none;
    }

    ${Anchor} {
        ${props => (props.editing) && css`
            pointer-events: none;
            text-decoration: unset;
        `}
    }

    ${ListItem} {
        margin-bottom: 6px;
        overflow: auto;
    }
`

export const ListWidgetLoading = styled.div`
    ${rules.MediumText}
    text-align: center;
`

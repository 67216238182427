import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/OptionsBar/style"

const OptionsBar = ({ className, fullWidthChild, leftChildren, rightChildren }) => (
    <S.OptionsBar data-auto-cy="CompoundOptionsBar" className={className}>
        { fullWidthChild &&
            <S.OptionsBarRow>
                    <S.FullWidthBar>
                        { fullWidthChild }
                    </S.FullWidthBar>
            </S.OptionsBarRow>
        }
        { (leftChildren || rightChildren) &&
            <S.OptionsBarRow>
                { leftChildren && 
                    <S.LeftOptionsBar className="options-bar-left">
                        { leftChildren }
                    </S.LeftOptionsBar>
                }
                { rightChildren && 
                    <S.RightOptionsBar className="options-bar-right">
                        { rightChildren }
                    </S.RightOptionsBar>
                }
            </S.OptionsBarRow>
        }
    </S.OptionsBar>
)

OptionsBar.propTypes = {
    fullWidthChild: PropTypes.node,
    leftChildren: PropTypes.node,
    rightChildren: PropTypes.node,
}

export default OptionsBar

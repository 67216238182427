import styled, { css } from "styled-components"

export const SelectBooleanTriplet = styled.div`
    display: flex;
    flex-direction: column;

    ${props => (props.isHorizontal) && css`
        flex-direction: row;
    `}
`

import axios from "axios"
import swal from "sweetalert"
import Immutable, { Map } from "immutable"
import { destroy } from "redux-form/immutable"

import GlobalActionTypes, * as actionGroups from "app/static/frontend/global-action-types"
import ContactFormActionTypes from "app/static/frontend/forms/routes/contactForm/action-types"
import * as djangioActionCreators from "shared/djangio/action-creators"
import { setIsEditing } from "shared/forms/LogInteraction/action-creators"

import urls, {
    profileUrlFromTypeAndId,
    getActionCenterPaths,
    getPacPaths,
} from "app/static/frontend/imports/desktopPaths"
import constants, { EMAIL_DOWNLOAD_SWAL_CSV, EMAIL_DOWNLOAD_SWAL_CSV_CONFIRM } from "shared/imports/sharedConstants"
import accountsConstants from "app/static/frontend/accounts/accountsConstants"
import { SSO_REAUTH_TOKEN_KEY } from "app/static/frontend/components/SecurityWrapper.constants"

const { QuorumUser } = DjangIO.app.userdata.models

/**
 * Convenience wrapper for going to a page and replacing the entry in the browser history.
 */
export function replace(next: { pathname: string; query: Record<string, string> } | string) {
    return segue(next, { replace: true })
}

/**
 * Navigate to the supplied URL without a page refresh.
 *
 * This method uses react-router's built in `navigate` method and internally uses
 * the DOM History API to update the URL and manage the history stack.
 *
 * See https://reactrouter.com/en/main/routers/create-browser-router#createbrowserrouter
 *
 * Supports both URL objects and a raw URL string.
 */
export function segue(
    next: { pathname: string; query: Record<string, string> } | string,
    opts: { replace: boolean } = { replace: false },
): () => void {
    if (typeof next === "object") {
        const { pathname, query } = next
        const queryString = Object.keys(query || {}).length ? `?${new URLSearchParams(query).toString()}` : ""
        const url = `${pathname}${queryString}`

        return () => {
            window.router.navigate(url, { replace: opts.replace })
        }
    }

    // If this is an absolute link to an internal or external page (eg: https://quorum.us/sheets or google.com)
    if (next.includes(".")) {
        // Internal links
        if (next.includes("quorum.us") && !next.includes("external")) {
            const url = new URL(next)
            next = url.pathname + url.search
        } else {
            // External links
            window.location.href = next
        }
    }

    return () => {
        window.router.navigate(next, { replace: opts.replace })
    }
}
window.segue = segue

/**
 * Navigate to the supplied URL with a page refresh.
 *
 * This method uses the DOM History API to update the URL and manage the history stack.
 *
 * Supports both URL objects and a raw URL string.
 */
export function segueWithRefresh(
    next: { pathname: string; query: Record<string, string> } | string,
    opts: { replace: boolean } = { replace: false },
): () => void {
    if (typeof next === "object") {
        const { pathname, query } = next
        const queryString = Object.keys(query || {}).length ? `?${new URLSearchParams(query).toString()}` : ""
        const url = `${pathname}${queryString}`

        return () => {
            if (opts.replace) {
                window.location.replace(url)
            } else {
                window.location.href = url
            }
        }
    }

    // If this is an absolute link to an internal or external page (eg: https://quorum.us/sheets or google.com)
    if (next.includes(".")) {
        // Internal links
        if (next.includes("quorum.us") && !next.includes("external")) {
            const url = new URL(next)
            next = url.pathname + url.search
        }
    }

    return () => {
        if (opts.replace) {
            window.location.replace(next)
        } else {
            window.location.href = next
        }
    }
}
// @ts-expect-error
window.segueWithRefresh = segueWithRefresh

export function goBack() {
    return () => {
        window.history.back()
    }
}

/**
 * Navigate to an internal quorum url
 * @param url - the URI (/bill/2464889/)
 */
export const segueToUrl =
    (url: string) =>
    (dispatch): void => {
        if (!url) throw new Error("Invalid segue URL")

        dispatch(pushPage())
        dispatch(segue(url))
    }

/**
 * This function segues to a specific profile page given a QDT and an itemId.
 * If extraUrlParameters are included, the function will strip any starting forward slash,
 * since a QDT profile url will always include a trailing forward slash. ex. "/political_committee/"
 *
 * @param itemId - profile id
 * @param quorumDataType - qdt of the profile
 */
export const segueToProfileWithId =
    (itemId: number, quorumDataType: number, extraUrlParameters: string) => (dispatch) => {
        const destination = profileUrlFromTypeAndId(quorumDataType, itemId)
        const parameters =
            extraUrlParameters && extraUrlParameters[0] === "/"
                ? extraUrlParameters.substr(1)
                : extraUrlParameters || ""
        dispatch(segue(`${destination}${parameters}`))
    }

/**
 * This function returns a function that decides what to push onto the browser history
 * and whether to dispatch a POP_PAGE action, based on whether the navigation is empty
 *
 * @param defaultPage - if provided, a location to default to if there is no prev page
 * @param replace - if provided, will call react-router's `navigate` with { replace: true }
 * @returns A thunk
 */
export function popPage(
    defaultPage: string = urls.home,
    replace = false,
): (dispatch: (object) => any, getState: () => any) => void {
    return (dispatch, getState) => {
        const state = getState()
        // check to see if we have have somewhere to go
        // in other words, make sure the prevPages stack is not empty
        if (state.main.get("prevPages").size > 0) {
            // unfortunately something is intercepting this prevPage when adding to the stack
            // so it might be either a Map or an object. We need to make sure we get the property
            // appropriately
            const prevPage = state.main.getIn(["prevPages", 0])
            let prevPageUrl
            if (Map.isMap(prevPage)) {
                prevPageUrl = prevPage.get("prevPageUrl")
            } else {
                prevPageUrl = prevPage.prevPageUrl // access like a regular JS object
            }
            dispatch({ type: GlobalActionTypes.POP_PAGE })
            // if for some weird reason the stack was not empty but the prevPage
            // is not truthy, make sure we check for this

            if (prevPage) {
                window.router.navigate(prevPageUrl, { replace })
            }
            // prevPage is not truthy, so just go to the default page
            else {
                window.router.navigate(prevPageUrl, { replace })
            }

            // if it's empty, default to the default page
        } else {
            window.router.navigate(defaultPage)
        }
    }
}

// push a page onto the routing stack
export const pushPage = (nextUrl?: string) => (dispatch) => {
    const prevPageUrl = window.location.pathname + window.location.search

    // nextUrl is an optional argument
    // If nextUrl is defined, we dispatch a PUSH_PAGE action with the prevPageUrl like usual
    // We would also segue to nextUrl. This is to prevent having to chain pushPage and segue function calls in the components
    if (nextUrl) {
        dispatch({ nextPage: Immutable.fromJS({ prevPageUrl }), type: GlobalActionTypes.PUSH_PAGE })
        return dispatch(segue(nextUrl))
    }

    return dispatch({ nextPage: Immutable.fromJS({ prevPageUrl }), type: GlobalActionTypes.PUSH_PAGE })
}

// this is so we don't get stuck on a page trying to cancel if we refreshed
export function searchFormCancel() {
    return (dispatch) => {
        // default to the search endpoint if we have nowhere to go
        dispatch(popPage(urls.search))
    }
}

export const segueToProfile =
    ({ id, extraUrlParameters, fromSearch }, quorumDataType) =>
    (dispatch, getState) => {
        dispatch(pushPage())
        const searchTerm = getState().search && getState().search.toJS().advancedSearch.searchTerm
        const extraParam =
            fromSearch && searchTerm ? `?searchTerm=${encodeURIComponent(searchTerm)}` : extraUrlParameters
        return dispatch(segueToProfileWithId(id, quorumDataType, extraParam))
    }

/**
 * Updates the browser URI with the updated query param
 */
export const updateURI = ({ location, query }: { location: { pathname: string }; query: Record<string, string> }) => {
    return segue({ pathname: location.pathname, query }, { replace: true })
}

/**
 * Updates the anchor hash in the URI
 * This is necessary because setting the hash to the same value
 *      does not scroll the DOM element back into focus
 */
export const updateHash = () => {
    const locationHash = window.location.hash
    window.location.hash = ""
    window.location.hash = locationHash
}

export const clearProfile = () => ({ type: GlobalActionTypes.CLEAR_PROFILE })

export const replaceToProject = (project) => (dispatch) => {
    dispatch({
        type: "LOADED_PROJECT",
        project,
    })
    dispatch(replace(`${urls.dashboard}${project.id}/`))
}

export const segueToAddTeam = (orgId) => (dispatch) => {
    dispatch(pushPage())
    window.router.navigate(`${urls.addTeam}${orgId}/`)
}

export const segueToEditOfficial = (officialId: number) => (dispatch) => {
    // first dispatch that we are pushing a new page
    dispatch(pushPage())
    if (officialId) {
        window.router.navigate(`${urls.editOfficialPrefix}${officialId}/`)
    } else {
        window.router.navigate(urls.editOfficialPrefix)
    }
}

export const segueToEditCustomOfficial = (officialId: number) => (dispatch) => {
    // first dispatch that we are pushing a new page
    dispatch(pushPage())
    if (officialId) {
        window.router.navigate(`${urls.editCustomOfficialPrefix}${officialId}/`)
    } else {
        window.router.navigate(urls.editCustomOfficialPrefix)
    }
}

export const segueToEditPressContact = (pressContactId: number) => (dispatch) => {
    // first dispatch that we are pushing a new page
    dispatch(pushPage())
    if (pressContactId) {
        window.router.navigate(`${urls.editPressContactPrefix}${pressContactId}/`)
    } else {
        window.router.navigate(urls.editPressContactPrefix)
    }
}

export const segueToEditBill = (billId: number) => (dispatch) => {
    // first dispatch that we are pushing a new page
    dispatch(pushPage())

    if (billId) {
        window.router.navigate(`${urls.editBillPrefix}${billId}/`)
    } else {
        window.router.navigate(urls.editBillPrefix)
    }
}

export const segueToEditCommittee = (committeeId: number) => (dispatch) => {
    // first dispatch that we are pushing a new page
    dispatch(pushPage())

    const path = committeeId ? `${urls.editCommitteePrefix}${committeeId}/` : urls.editCommitteePrefix
    window.router.navigate(path)
}

export const segueToEditRegulation = (regulationId: number) => (dispatch) => {
    // first dispatch that we are pushing a new page
    dispatch(pushPage())

    if (regulationId) {
        window.router.navigate(`${urls.editRegulationPrefix}${regulationId}/`)
    } else {
        window.router.navigate(urls.editRegulationPrefix)
    }
}

/*
    (We might just want to switch this to accepting an object to make changes like this simpler)
    This functions accepts:
        (1) nothing if straight up seguing to ContactForm when creating a new contact
        (2) a contact's id if seguing to ContactForm to edit a contact
        (3) an object with 'form' and 'field' if seguing to ContactForm from within a redux-form
            that wants to be updated if a new contact gets made
 */
export const segueToEditContact = (params) => (dispatch, getState) => {
    // first dispatch that we are pushing a new page
    dispatch(pushPage())

    // seguing to ContactForm from a redux-form
    if (params && params.form && params.field) {
        const { form, field } = params
        window.router.navigate(`${urls.editContactPrefix}?form=${form}&field=${field}`)
    }
    // editing a contact
    else if (params) {
        window.router.navigate(`${urls.editContactPrefix}${params}/`)
    }
    // creating a brand new contact
    else {
        // TODO - we should replace this once PublicOrg/ContactForm are redux-form-ified
        // If the user is navigating to the edit contact form from the 'Create Contact' button on the organization profile,
        // we want the edit contact form to be pre-filled with the organization they were editing
        const { pathname } = window.location
        // Check if the current page is on an Organization profile page
        if (pathname.includes("/organization/")) {
            // Get the organization from the store
            const org = getState().organizationProfile.get("organization")

            if (org && org.get("id")) {
                // Update supporter object with current public organization
                dispatch({
                    type: ContactFormActionTypes.UPDATE_SUPPORTER,
                    payload: {
                        public_organization: DjangIO.app.person.models.PublicOrganization.foreignKey(org.get("id")),
                    },
                })
            }
        }

        window.router.navigate(urls.editContactPrefix)
    }
}

export const segueToLinkToPAC = (profileId: number) => (dispatch) => {
    // first dispatch that we are pushing a new page
    dispatch(pushPage())

    if (profileId) {
        axios.post("/api/supporter/create_supporter_profile_in_pacc/", { supporterId: profileId }).then((response) => {
            console.log("PACC Individual created: ", response.data)
            // we're going to finish the flow of this on QPE-2191
        })
    }
}

export const segueToBulkUploadContact = () => (dispatch) => {
    // first dispatch that we are pushing a new page
    dispatch(pushPage())

    window.router.navigate(urls.bulkUploadForm)
}

/** internally segues to the page that lets you edit an event */
export const segueToEditEvent = (eventId: number) => (dispatch) => {
    // first dispatch that we are pushing a new page
    dispatch(pushPage())

    if (eventId) {
        window.router.navigate(`${urls.editEventPrefix}${eventId}/`)
    } else {
        window.router.navigate(urls.editEventPrefix)
    }
}

/* internally segues to the page that lets you edit a note */
export const segueToEditNote = (noteId: number) => (dispatch) => {
    // first dispatch that we are pushing a new page
    dispatch(pushPage())

    if (noteId) {
        window.router.navigate(`${urls.editNotePrefix}${noteId}/`)
    } else {
        window.router.navigate(urls.editNotePrefix)
    }
}

export const segueToEditOrganization = (params?) => (dispatch) => {
    // first dispatch that we are pushing a new page
    dispatch(pushPage())

    // seguing to ContactForm from a redux-form
    if (params && params.form && params.field) {
        const { form, field } = params
        window.router.navigate(`${urls.editOrganizationPrefix}?form=${form}&field=${field}`)
    } else if (params) {
        window.router.navigate(`${urls.editOrganizationPrefix}${params}/`)
    } else {
        window.router.navigate(urls.editOrganizationPrefix)
    }
}

export const segueToModifyOrganization = (params) => (dispatch) => {
    // first dispatch that we are pushing a new page
    dispatch(pushPage())

    if (params) {
        window.router.navigate(`${urls.modifyOrganizationPrefix}${params}/`)
    } else {
        window.router.navigate(urls.modifyOrganizationPrefix)
    }
}

export const segueToEditCampaign =
    ({ id, actionCenterId }) =>
    (dispatch) => {
        dispatch(pushPage())

        if (id) {
            window.router.navigate(getActionCenterPaths(actionCenterId, id)._actionCenterCampaignDetail)
        }
    }

export const segueToEditProject = (projectId: number) => (dispatch) => {
    dispatch(pushPage())

    if (projectId) {
        window.router.navigate(`${urls.dashboard}${projectId}/edit/`)
    } else {
        window.router.navigate(urls.dashboard)
    }
}

export const segueToNewIssueManagement = (issueId: number) => (dispatch) => {
    dispatch(pushPage())

    if (issueId) {
        window.router.navigate(`${urls.issues}new/?parent=${issueId}`)
    } else {
        window.router.navigate(urls.issues)
    }
}

export const segueToEditIssueManagement = (issueId: number) => (dispatch) => {
    dispatch(pushPage())

    if (issueId) {
        window.router.navigate(`${urls.issues}${issueId}/edit/`)
    } else {
        window.router.navigate(urls.issues)
    }
}

export const segueToEditTrackingDashboard = (dashboardId: number) => (dispatch) => {
    const segueUrl = dashboardId
        ? `${DjangIO.app.models.QuorumDataType.tracking_dashboard.profile}${dashboardId}/edit/`
        : urls.trackingDashboards
    dispatch(pushPage(segueUrl))
}

// TODO: ALEX: this function signature changed, must replace all existing instances in the code base
// Call me out on this TODO if PRing into master/release
// TODO: I change the function signature again so it might actually be fine - need to double check
export const segueToEditAlert = (model, datumId, isSavedSearch, fromList) => (dispatch) => {
    dispatch(pushPage())
    const qdt = DjangIO.app.models.QuorumDataType.by_value(model)
    isSavedSearch
        ? dispatch(segue(`/alert/search/${datumId}/${fromList ? "list/" : ""}`))
        : dispatch(segue(`/alert${qdt.profile}${datumId}/${fromList ? "list/" : ""}`))
}

export const segueToEditInteraction = (datumId: number) => (dispatch) => {
    dispatch(pushPage())
    dispatch(setIsEditing())
    datumId
        ? window.router.navigate(`${urls.logInteractionPrefix}${datumId}/`)
        : window.router.navigate(urls.logInteractionPrefix)
}

export const segueToEditDonation = (datumId: number) => (dispatch) => {
    dispatch(pushPage())
    dispatch(setIsEditing())
    datumId
        ? window.router.navigate(`${urls.logDonationPrefix}${datumId}/`)
        : window.router.navigate(urls.logDonationPrefix)
}

export const segueToEditRelationship = (datumId: number) => (dispatch) => {
    dispatch(pushPage())
    dispatch(setIsEditing())
    datumId
        ? window.router.navigate(`${urls.logRelationshipPrefix}${datumId}/`)
        : window.router.navigate(urls.logRelationshipPrefix)
}

export const segueToEditComment = (datumId: number) => (dispatch) => {
    dispatch(pushPage())
    dispatch(setIsEditing())
    datumId
        ? window.router.navigate(`${urls.logCommentPrefix}${datumId}/`)
        : window.router.navigate(urls.logCommentPrefix)
}

export const segueToObjectProfile = (noteLink) => (dispatch) => {
    dispatch(pushPage())
    window.router.navigate(noteLink.href.replace(window.location.origin, ""))
}

export const segueToEditCustomEvent = (datumId: number) => (dispatch) => {
    dispatch(destroyReduxForm(constants.EVENT_FORM_KEY))
    dispatch(pushPage())
    datumId
        ? window.router.navigate(`${urls.editCustomEventPrefix}${datumId}/`)
        : window.router.navigate(urls.editCustomEventPrefix)
}

export const segueToEditCustomRegion = (datumId: number) => (dispatch) => {
    dispatch(pushPage())
    datumId
        ? window.router.navigate(`${urls.customRegionFormPrefix}${datumId}/`)
        : window.router.navigate(urls.customRegionFormPrefix)
}

export const segueToEditGrassrootsSupporterAction = () => (dispatch) => {
    dispatch(pushPage())
    window.router.navigate(urls.editGrassrootsSupporterAction)
}

export const segueToEditBulkSMS = (bulkSMSId: number) => (dispatch) => {
    // first dispatch that we are pushing a new page
    dispatch(pushPage())
    if (bulkSMSId) {
        window.router.navigate(`${urls.textingCompose.replace(":id?/", bulkSMSId.toString())}/`)
    } else {
        window.router.navigate(urls.textingCompose)
    }
}

export const segueToEditPoliticalCommittee = (politicalCommitteeId, ledgerSettingsId) => (dispatch) => {
    dispatch(pushPage())

    const pacPaths = getPacPaths({
        ledgerSettingsId,
        politicalCommitteeId,
    })

    if (politicalCommitteeId) {
        window.router.navigate(pacPaths._pacPoliticalCommitteesEdit)
    } else {
        window.router.navigate(pacPaths._pacPoliticalCommitteesNew)
    }
}

/* internally segues to the page that lets you compose an outbox email */
export const segueToOutboxCompose = (officialId: number) => (dispatch) => {
    // first dispatch that we are pushing a new page
    dispatch(pushPage())

    if (officialId) {
        window.router.navigate(`${urls.outboxCompose}?toPeople=[${officialId}]`)
    } else {
        window.router.navigate(urls.outboxCompose)
    }
}

/* internally segues to the page that lets you compose an outbox email to supporters */
export const segueToOutboxComposeSupporter = (officialId: number) => (dispatch) => {
    // first dispatch that we are pushing a new page
    dispatch(pushPage())

    if (officialId) {
        window.router.navigate(`${urls.outboxCompose}?toSupporters=[${officialId}]`)
    } else {
        window.router.navigate(urls.outboxCompose)
    }
}

export const segueToManageCustomFields = (tagOwnerValue: number) => (dispatch) => {
    dispatch(pushPage())
    dispatch(
        segue(
            `${urls.customFields}${
                DjangIO.app.models.QuorumDataType.by_value(tagOwnerValue).custom_fields_url_pattern
            }`,
        ),
    )
}

export const patchUserdata = (kwargs) =>
    djangioActionCreators.resourcePatchDetail(
        DjangIO.app.userdata.models.UserInfo,
        actionGroups.patchUserInfoTypes,
        Userdata.user_info_id,
        kwargs,
    )

export const loadPeople = (officialIds) =>
    djangioActionCreators.resourceGetList(DjangIO.app.person.models.Person, actionGroups.getPeopleTypes, {
        id__in: officialIds,
    })

export const verifyUser = () => (dispatch) => {
    dispatch({
        type: GlobalActionTypes.VERIFY_START,
    })
    return swal(accountsConstants.identityCheck).then((inputValue) => {
        if (inputValue === constants.FORGOT_PASSWORD) {
            // Sad legacy page
            window.location.href = "/recover/"
        } else if (!inputValue) {
            window.history.back()
        } else {
            return QuorumUser.objects
                .action("verify_user")
                .update({ inputValue })
                .PATCH()
                .then((response) => {
                    dispatch({
                        type: GlobalActionTypes.VERIFY_TIME,
                        time: response.verifyTime,
                    })
                    swal.stopLoading()
                    swal.close()
                })
                .catch((response) => {
                    if (response.response.data.responseText !== "Incorrect Password") {
                        swal(response.response.data.responseText || "An Error Occurred", "", "error").then(() => {
                            window.location.href = "/login/"
                        })
                    } else {
                        swal("Incorrect Password", "", "error").then(() => window.history.back())
                    }
                })
        }
    })
}

export const deleteReauthTokenSearchParam = () => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.delete(SSO_REAUTH_TOKEN_KEY)
    const url = `${window.location.pathname}?${searchParams.toString()}`
    window.history.replaceState(null, "", url)
}

export const validateSSOReauthToken = (token, setIsLoading) => async (dispatch) => {
    setIsLoading(true)

    try {
        const response = await fetch("/login/sso/token/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-csrftoken": Userdata.csrftoken,
            },
            body: JSON.stringify({ token }),
        })

        if (!response.ok) {
            throw new Error()
        }

        deleteReauthTokenSearchParam()

        const data = await response.json()
        dispatch({
            type: GlobalActionTypes.VERIFY_TIME,
            time: data.reauth_time,
        })
    } catch {
        // history.back() should be a sso url, so just send them back to the homepage
        swal("Failed to authenticate", "", "error").then(() => window.location.replace("/home"))
    } finally {
        setIsLoading(false)
    }
}

export const loadSupporters = (supporterIds) =>
    djangioActionCreators.resourceGetList(DjangIO.app.grassroots.models.Supporter, actionGroups.getSupporterTypes, {
        id__in: supporterIds,
    })

// ------- BEGIN UPLOAD IMAGE ACTION CREATORS --------

export function uploadImageFailed(error) {
    return {
        type: actionGroups.uploadImage.fail,
        error,
    }
}

export function uploadImageSuccess(response) {
    return {
        type: actionGroups.uploadImage.success,
        response,
    }
}

export function uploadImage(formData, updatingFn, designTarget) {
    return (dispatch) => {
        dispatch({ type: actionGroups.uploadImage.start }) // start upload
        axios
            .post(urls.uploadAttachment, formData, {
                headers: {
                    Accept: "application/json",
                    "Accept-Language": "en-US,en;q=0.8",
                    "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
                },
                params: {}, // pass an empty object for params so axios interceptor doesn't complain
            })
            .then((response) => {
                // update the store with the url of the processed image
                const image_url = response[0].url

                // need to handle the case where the update function
                // also needs an id field
                // not great because logic is not decoupled
                if (designTarget) {
                    updatingFn({ image_url }, designTarget)
                } else {
                    updatingFn({ image_url })
                }

                dispatch(uploadImageSuccess(response))
            })
            .catch((error) => {
                dispatch(uploadImageFailed(error))
            })
    }
}

// ------- END UPLOAD IMAGE ACTION CREATORS --------

export const destroyReduxForm = (formKey) => destroy(formKey)

/**
 * Navigate to custom fields management page with the associated 'custom_fields_url_pattern' on the QDT
 */
export const navigateToCustomFieldsManagementPage = (quorumDataType: object) => (dispatch) => {
    dispatch(pushPage())
    // @ts-expect-error QuorumDataType typing is not complete and custom_fields_url_pattern is not recognized
    return dispatch(segue(`${urls.customFields}${quorumDataType.custom_fields_url_pattern}`))
}

/**
 * Pop to previous page. If there is no previous page on the stack, navigate to profile detail page
 * @param url - Function that is given an id and loads the object
 * @param profileId - The key value of the QuorumDataType enum (ex: DjangIO.app.models.QuorumDataType.person.key)
 * @returns a thunk
 */
export const popPageToProfile = (url: string, profileId: number) => (dispatch) =>
    // If they reloaded or navigated straight to this edit page,
    // Pop to official profile page on cancel/submit
    dispatch(popPage(`${url.replace(":profileId", profileId.toString())}`))

/**
 * downloadCsv - downloads a csv with the specified filter for the API
 */
export const downloadCsv = async ({
    initialSwalConfig = EMAIL_DOWNLOAD_SWAL_CSV,
    confirmSwalConfig = EMAIL_DOWNLOAD_SWAL_CSV_CONFIRM,
    filter,
    qdt,
}: {
    initialSwalConfig: object
    confirmSwalConfig: object
    filter: object
    qdt: object
}) => {
    const inputValue = await swal(initialSwalConfig)
    if (inputValue) {
        swal(confirmSwalConfig)
        window.redirectCSVDownload({ filter, qdt }, inputValue)
    }
}

/**
 * @name openQuorumAlert
 *
 * Places the alert config in the store and tells it to render.
 * Should only be called from the QuorumAlert component, and not manually.
 *
 * @param config - Fields to set in the alert
 * @param yieldResult - Promise that resolves with the alert result
 * @param  yieldResultAndClose - Promise that resolves with the alert result and closes the modal
 */
export const openQuorumAlert = (config: object, yieldResult: unknown, yieldResultAndClose: unknown) => (dispatch) =>
    dispatch({
        type: actionGroups.OPEN_QUORUM_ALERT,
        props: {
            config,
            yieldResult,
            yieldResultAndClose,
        },
    })

/**
 * Tells the alert to stop rendering.
 * Should only be called from the QuorumAlert component, and not manually.
 */
export const closeQuorumAlert = () => (dispatch) =>
    dispatch({
        type: actionGroups.CLOSE_QUORUM_ALERT,
    })

/**
 * Clears the remaining config state out of the store, after the Alert closes.
 * Should only be called from the QuorumAlert component, and not manually.
 */
export const cleanupQuorumAlert = () => (dispatch) =>
    dispatch({
        type: actionGroups.CLEANUP_QUORUM_ALERT,
    })

export const togglePayrollModal = () => (dispatch) =>
    dispatch({
        type: actionGroups.TOGGLE_PAYROLL_MODAL,
    })

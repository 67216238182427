import React, { useEffect, useMemo, useState } from "react"

import DiscreteSelect from "app/static/frontend/selects/components/DiscreteSelect"
import { CallFormSection } from "QuorumGrassroots/campaign-forms/components/CallFormSection"
import { ReduxFormFieldWrapper as UncastedFieldWrapper } from "app/static/frontend/forms/components/ReduxFormFieldWrapper"
import { StyledContrastText } from "QuorumGrassroots/styled-components/components/StyledContrastText"
import { StyledButton as UncastedStyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import { StyledSpacing } from "QuorumGrassroots/styled-components/components/StyledSpacing"
import { Campaign } from "@/types/djangio"
import { SubmitCallActionProps, submitCallAction } from "QuorumGrassroots/campaign-forms/components/MultiAction"
import { updateMessagesAfterSend } from "QuorumGrassroots/campaign-forms/action-creators"
import { useStepperContext } from "QuorumGrassroots/widgets/Stepper/context"

interface CallFormProps {
    activeSelectId: string // the id of the recipient of the call
    activeGrassrootsActionId: string
    campaign: Campaign
    changeTarget: (...args: any) => any
    dispatch: (...args: any) => any
    form: string
    getCampaignMessages: (args: object) => any
    handleSubmit: (...args: any) => void
    initialValues: object
    isLoading: boolean
    isMultiAction: boolean
    messagesLoaded: boolean
    numberOfMessagesAfterFiltering: number
    selectOptions: any[]
    shouldShowSubmitButton: boolean
    submitMultiActionChild: (args: SubmitCallActionProps) => any
    submitting: boolean
    t: (name: string) => string
    targets: any[]
    uniqueWidgetId: string
}

const ReduxFormFieldWrapper = UncastedFieldWrapper as React.FC<any>
const StyledButton = UncastedStyledButton as React.FC<any>

export const CallForm = ({ uniqueWidgetId, messagesLoaded, ...props }: CallFormProps) => {
    const { currentStep, submitStep } = useStepperContext()

    const campaignId = useMemo(() => props.campaign.id, [props.campaign])
    const [feedbackText, setFeedbackText] = useState("")

    useEffect(() => {
        // Reload messages if Multi-Action to avoid using potentially stale messages
        // This occurs if a WAL Campaign comes before a Call a Number Campaign in Multi-Action
        if (!messagesLoaded || props.isMultiAction) {
            props.getCampaignMessages({
                campaignId,
                uniqueWidgetId: uniqueWidgetId,
                getKwargs: {},
                getIdFromMessage: (message) => message.target.id.toString(),
                enabled: Boolean(messagesLoaded),
            })
        }
        //this should be safe since we only need to fetch it once after the component mounts
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignId, uniqueWidgetId])

    const changeTarget = (option) => props.changeTarget(uniqueWidgetId, option.value)

    const submitSingleMessage = props.handleSubmit((values, dispatch, props) => {
        const formSlice = values.get(props.activeSelectId)
        return props.onSubmit(formSlice, dispatch, props)
    })

    const getSubmitButtonProps = () => {
        const defaultProps = {
            activateNGGTheme: true,
            applyMarginTop: true,
            dataCy: "submit",
            disabled: props.submitting,
            isCampaignPage: true,
            onClick: submitSingleMessage,
        }
        if (!props.isMultiAction) return defaultProps
        // For Multi-Action "Okay" button
        if (!props.numberOfMessagesAfterFiltering) {
            return {
                ...defaultProps,
                onClick: () => submitStep(currentStep.name),
            }
        }
        return {
            ...defaultProps,
            disabled: props.isLoading,
            onClick: () =>
                props.numberOfMessagesAfterFiltering > 1
                    ? submitCallAction({
                          grassrootsSupporterActionId: props.activeGrassrootsActionId,
                          feedbackText: feedbackText,
                      }).then(() =>
                          // Use redux handler to update messages after send if there is more than one left
                          props.dispatch(
                              updateMessagesAfterSend(uniqueWidgetId, props.campaign.id, [props.activeSelectId]),
                          ),
                      )
                    : props.submitMultiActionChild({
                          grassrootsSupporterActionId: props.activeGrassrootsActionId,
                          feedbackText: feedbackText,
                      }),
        }
    }

    if (!messagesLoaded) return <StyledContrastText isCampaignPage>{props.t("loading")}</StyledContrastText>
    if (!props.numberOfMessagesAfterFiltering) {
        return (
            <>
                <StyledContrastText isCampaignPage>{props.t("campaign.call.nobody")}</StyledContrastText>
                {props.isMultiAction && (
                    // Allows Multi-Action Campaign Supporters to move to the next step if there is no one to call
                    <StyledButton {...getSubmitButtonProps()}>{props.t("gamification.modal.button.text")}</StyledButton>
                )}
            </>
        )
    }

    const submitButtonText =
        props.numberOfMessagesAfterFiltering > 1 ? props.t("campaign.call.next") : props.t("form.submit_form")

    return (
        <div className="call-form">
            {props.targets.length > 1 && (
                <>
                    <ReduxFormFieldWrapper label="Official" className="pie-select">
                        <DiscreteSelect
                            value={props.activeSelectId}
                            onChange={changeTarget}
                            getItems={() => props.selectOptions}
                            clearRenderer={() => null}
                        />
                    </ReduxFormFieldWrapper>
                    <StyledSpacing />
                </>
            )}
            <CallFormSection
                activeId={props.activeSelectId}
                campaignId={props.isMultiAction ? props.campaign.multi_action_parent_id : props.campaign.id}
                feedbackText={feedbackText}
                formName={props.form}
                isCampaignPage
                isMultiAction={props.isMultiAction}
                key={props.activeSelectId}
                setFeedbackText={setFeedbackText}
                t={props.t}
                uniqueWidgetId={uniqueWidgetId}
            />
            {props.shouldShowSubmitButton && (
                <StyledButton {...getSubmitButtonProps()}>
                    {props.submitting ? props.t("form.submitting") : submitButtonText}
                </StyledButton>
            )}
        </div>
    )
}

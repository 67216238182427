import React from "react"
import PropTypes from "prop-types"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import {
    ImageColumnWrapper,
    ImageRowWrapper,
    StyledTitle,
    StyledImageContainer,
} from "QuorumGrassroots/widgets/ImageCollage/components/style"
import { StyledBackground } from "QuorumGrassroots/widgets/styled-components/StyledWidget"

const ImageCollageWidget = (props) => {
    const getImageComponents = () => {
        const numImages = DjangIO.app.grassroots.enums.ImageCollageLayoutType.by_value(props.layout).num_images
        const imageComponents = []
        for (let i = 0; i < numImages; i++) {
            imageComponents.push(
                <StyledImageContainer
                    className="image-collage-image"
                    // users who created image collages before imageContain functionality was added may not have imageContainValues defined
                    imageContain={props.imageContainValues && props.imageContainValues[i]}
                    imagePath={props.imageUrls[i]}
                    layout={props.layout}
                />,
            )
        }
        return imageComponents
    }

    const displayImages = (layout, imageComponents) => {
        const layoutEnum = DjangIO.app.grassroots.enums.ImageCollageLayoutType.by_value(layout)
        if (layoutEnum.num_rows === 2) {
            return (
                <ImageColumnWrapper>
                    <ImageRowWrapper>{imageComponents.slice(0, 2)}</ImageRowWrapper>
                    <ImageRowWrapper>{imageComponents.slice(2)}</ImageRowWrapper>
                </ImageColumnWrapper>
            )
        }
        return <ImageRowWrapper>{imageComponents}</ImageRowWrapper>
    }

    return (
        <WidgetStyleWrapper className="image-collage-widget" md={props.colWidth} useWidgetStyling={false}>
            <StyledBackground
                className="image-collage-background"
                useBackgroundColor={props.useBackgroundColor}
                backgroundColor={props.backgroundColor}
                isFirst={props.isFirst}
                isLast={props.isLast}
                nextWidget={props.nextWidget}
            >
                {props.title && (
                    <StyledTitle className="image-collage-title" useBackgroundColor={props.useBackgroundColor}>
                        {props.title}
                    </StyledTitle>
                )}
                {displayImages(props.layout, getImageComponents(props.images, props.layout))}
            </StyledBackground>
        </WidgetStyleWrapper>
    )
}

ImageCollageWidget.propTypes = {
    backgroundColor: PropTypes.string,
    colWidth: PropTypes.number,
    isFirst: PropTypes.bool.isRequired,
    images: PropTypes.object.isRequired,
    isLast: PropTypes.bool.isRequired,
    layout: PropTypes.number.isRequired,
    nextWidget: PropTypes.number,
    title: PropTypes.string,
    useBackgroundColor: PropTypes.bool.isRequired,
}

export default ImageCollageWidget

import styled from "styled-components"
import { Select } from "@mantine/core"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { getFontColorForBackgroundContrast } from "QuorumGrassroots/styled-components/helperFunctions"

export const StyledSelect = styleWithOrgDesign(styled(Select)`
    .mantine-Select-input {
        font-family: inherit;
    }

    .mantine-Select-item {
        &[data-selected] {
            background-color: ${(props) => props.organizationDesign.primary_color};
            color: ${(props) => getFontColorForBackgroundContrast(props.organizationDesign.primary_color)};
        }
        font-family: inherit;
    }
`)

import { connect } from "react-redux"

import { PetitionForm } from "QuorumGrassroots/campaign-forms/components/PetitionForm/index"
import { petitionFormConnection } from "QuorumGrassroots/campaign-forms/selectors/petition-selectors"
import { completeRequiredVideo } from "QuorumGrassroots/widgets/action-creators"
import { postGrassrootsSupporterAction, completeCampaign } from "QuorumGrassroots/campaign-forms/action-creators"
import { disableOneClickRegistration } from "QuorumGrassroots/framework/action-creators"

const actions = {
    completeCampaign,
    completeRequiredVideo,
    disableOneClickRegistration,
    postGrassrootsSupporterAction,
}

export default connect(petitionFormConnection, actions)(PetitionForm)

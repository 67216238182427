import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`

export const TopBarContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 26px;
`

export const TitleSpan = styled.div`
    ${rules.GiantTextMediumWeight}
    color: ${colors.gray9};
    font-size: 1.25rem;
    line-height: 1.5;
`

export const Expand = styled.span`
    flex-shrink: 0;
    align-self: center;
    ${rules.SmallTextRegularWeight}
    color: ${colors.MidPurple};
    user-select: none;
    margin-right: ${({atBottom}) => atBottom ? 'auto' : 0};
    margin-left: ${({atBottom}) => atBottom ? '103px' : 'auto'};

    &:hover {
        cursor: pointer;
        background-color: ${colors.AquaHaze};
    }
`

export const Timeline = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const Entry = styled.div`
    display: flex;
    position: relative;

    align-items: center;
`

export const CircleWrapper = styled.div`
    margin: 0px 10px 0px 10px;
`

export const Dot = styled.div`
    position: absolute;
    left: 152px;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: ${colors.QuorumBlue};
    width: 11px;
    height: 11px;
    border-radius: 50%;
    transform: translate(-50%, ${({isUserIE11}) => isUserIE11 ? "-50%" : "0px"}) translateX(-30px);
`

export const DateTextSpan = styled.span`
    color: ${colors.gray7};
    ${rules.LargeTextMediumWeight}
    font-size: 1rem;
    transform: translateY(-2px);
    max-width: 95px;
    width: 95px;
`

export const DateRangeSpan = styled.span`
    color: ${colors.QuorumBlue};
    ${rules.LargeTextMediumWeight}
    ${({isUserIE11}) => isUserIE11 ? rules.SmallTextMediumWeight : rules.LargeTextMediumWeight};
    word-break: break-all;
    max-width: 85px;
`
export const EmptyDateTextSpan = styled.span`
    max-width: 95px;
    width: 95px;
`

export const EventTextSpan = styled.span`
    color: ${colors.Black};
    background-color: ${colors.unnamedF8F9F9};
    border: ${rules.Line} ${colors.QuorumGrey2};
    border-radius: ${rules.BorderRadius};
    margin-top: 2px;
    margin-bottom: 2px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
    // we want to cap the width of the textboxes at 50% of the viewport width
    max-width: 50vw;
    ${rules.MediumTextRegularWeightSpaced}
`

export const Line = styled.div`
    position: absolute;
    width: 1px;
    // we want push the line down a bit so the line doesn't start before the first circle.
    top: ${({firstTextEventHeight}) => `${(firstTextEventHeight/2)}px`};
    left: 121px;
    background-color: ${colors.QuorumGrey4};
    // if firstTextEventHeight is 0, then it means no events exist, so no line.
    height: ${({lastEntryHeight, firstTextEventHeight, onlyOneEntry}) => firstTextEventHeight !== 0 && !onlyOneEntry ? `calc(100% - ${lastEntryHeight/2 + firstTextEventHeight/2}px)` : '0px'};
`

export const EmptyStateText = styled.div`
    ${rules.MediumTextLightWeight}
    color: ${colors.DarkGrey};
    font-style: italic;
`

export const NumberOfEventsDiv = styled.div`
    position: absolute;
    left: 162px;
    font-size: large;
`

import styled from 'styled-components'

export const Banner = styled.div`
    position: relative;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : "unset"};
    background-image: url(${props => props.backgroundImage});
    background-size: cover;
    background-position: 50% 50%;
    height: ${props => props.height ? `${props.height}px` : "auto"};
    width: 100%;
`

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/StatusBar/style"

import StatusIndicator from "Components/Molecules/StatusIndicator"

const StatusBar = ({
    labels,
    numCompleted,
}) => {
    // If one event is completed, none of the bar should be filled
    // If two are completed, the first section should be filled, etc.
    const sectionsFilled = Math.max(0, numCompleted - 1)

    // The number of bar segments that we could potentially want to fill is
    // (number of labels) - 1
    // Event 1         Event 2         Event 3         Event 4
    //   O===============O===============O---------------O
    // Above: 4 events (labels), we have 3 bar sections, 2 are filled
    // We should therefore calculate the percentage-of-bar filled in
    // increments of 33% for a bar with 4 events (not increments of 25%)
    const percent = labels && labels.length > 1
        ? Math.min(100, 100 * sectionsFilled / (labels.length - 1))
        : labels.length === 1
            ? 100
            : 0

    return (
        <S.StatusBar data-auto-cy="CompoundStatusBar">
            <S.Line percent={percent}/>
            {
                labels && labels.length > 0 && (
                    labels.map((item, idx) => (
                        <StatusIndicator
                            key={item}
                            text={item}
                            isActive={idx < numCompleted}
                        />
                    ))
                )
            }
        </S.StatusBar>
    )
}

StatusBar.defaultProps = {
    numCompleted: 0,
}

StatusBar.propTypes = {
    // array of text labels to put over each step on the status bar
    labels: PropTypes.arrayOf(PropTypes.string),
    // integer number of completed steps displayed as completed
    // (should be less than the number of labels)
    numCompleted: PropTypes.number,
}

export default StatusBar

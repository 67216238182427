import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { Map } from "app/static/frontend/widgets/components/visualization/Map"

const Wrapper = styled.div`
    height: ${(props) => props.height}px;
`

const CustomDataMapView = ({
    data,
    height,
    geoShapeRegion,
    geoShapeType,
    geoShapeId,
    loading,
    mapVisualizationRegion,
    pinColor,
    timestamp,
}) => (
    <Wrapper
        // Height of the map
        height={height}
    >
        {loading ? (
            <span>Loading...</span>
        ) : (
            <Map
                data={data}
                // Type of map visualization (ie: not a heatmap)
                frequencyTupleField={DjangIO.app.widgets.types.FrequencyTupleFields.point.value}
                // Prevents linking out to Search; among other undesired behaviors
                isExternal
                // Pin color - would be nice if we could pass in a second color for the outline...
                colors={{ 0: pinColor }}
                // Background color - Not implemented yet for point map visualizations, but must be defined
                mapVisualizationCustomColors={{}}
                // Base map to render
                mapVisualizationRegion={mapVisualizationRegion}
                // GeoShape we will fetch and then render
                geoShapeRegion={geoShapeRegion}
                // How we should partition the map within the requested region
                geoShapeType={geoShapeType}
                // A specific GeoShape that we will zoom into
                geoShapeId={geoShapeId}
                // Time when the network request resolved. Used as a basic memoization key
                timestamp={timestamp}
            />
        )}
    </Wrapper>
)

CustomDataMapView.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number,
            name: PropTypes.string,
        }),
    ),
    geoShapeRegion: PropTypes.number,
    geoShapeType: PropTypes.number,
    geoShapeId: PropTypes.number,
    height: PropTypes.number,
    loading: PropTypes.bool,
    // Base map to render - only supports USA for now
    mapVisualizationRegion: PropTypes.number,
    // Pin color
    pinColor: PropTypes.string,
    // Time when the network request resolved. Used as a basic memoization key
    timestamp: PropTypes.number,
}

export default CustomDataMapView

import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const DropdownListItem = styled.div`
    color: ${colors.gray7};
    font-size: .875rem;
    overflow: hidden;
    gap: 12px;
    font-weight: 400;
    padding: 12px 16px;
    transition: color .3s ease-in-out, background-color .3s ease-in-out;

    && {
            border-radius: 0;
        }

    ${props => (props.isActive) && css`
        background-color: ${colors.violet1};
        color: ${colors.violet8};
    `}

    ${props => (props.isOverflow) && css`
        flex-shrink: 0;
    `}

    ${
        props => {
            switch (props.type) {
                case "primary":
                    return css`
                        height: 30px;
                    `
                case "secondary":
                    return css`
                        height: 26px;
                        margin: 2px 0;
                    `
                case "tertiary":
                    return css`
                        height: 24px;
                        margin: 2px 0;
                    `
                case "info":
                    return css`
                        border-bottom: 1px solid ${colors.gray2};

                        &:hover {
                            cursor: auto;
                            background-color: ${colors.White};
                            color: ${colors.gray7};
                        }
                    `
                case "warning":
                    return css`
                        border-top: 1px solid ${colors.gray2};
                        color: ${colors.red9};

                        &:hover {
                            color: ${colors.red9};
                        }
                    `
                default:
                    return css`
                        height: 24px;
                        margin: 2px 0;
                    `
            }
        }
    }

    // flexible container
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    height: auto;

    &:hover {
        cursor: pointer;
        text-decoration: none;
        background-color: ${colors.violet1};
        color: ${colors.violet8};
    }

    &:first-of-type {
        border-radius: ${rules.BorderRadius} 0;
    }

    &:last-of-type {
        border-radius: 0 ${rules.BorderRadius};
    }

`

export const DropdownIconSpan = styled.span`
    display: flex;
    align-items: center;
    font-size: ${rules.IconFontSize};
    justify-content: center;
    padding-right: 0;
    color: ${colors.violet8};

    ${(props) => props.type === "warning" && css`
        color: ${colors.red9};
    `}

`

import { createSelector, createStructuredSelector } from "reselect"

import * as frameworkSelectors from "QuorumGrassroots/framework/selectors"

export const selectIssueList = createSelector(frameworkSelectors.selectIssueList, (issueList) => issueList.toJS())

export const issueListSelector = createStructuredSelector({
    issueList: selectIssueList,
    loading: frameworkSelectors.selectIssueListLoading,
})

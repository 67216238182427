import styled from "styled-components"

export const TransparentOverlay = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 1;
`

export const OverlayLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    font-size: 48px;
`

export const DescriptionText = styled.p`
    text-align: center;
    font-size: 16px;
`

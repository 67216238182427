import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/SmoothTemplateCard/style"

import Icon from "Components/Atoms/Icon"

const SmoothTemplateCard = ({ children, icon, iconFamily, title, selected, selectTemplate, dataCy }) => {
    return (
        <S.Card onClick={selectTemplate} selected={selected} data-cy={dataCy}>
            <S.IconContainer>
                <Icon icon={icon} iconFamily={iconFamily} />
            </S.IconContainer>
            <div>
                <S.Title>{title}</S.Title>
                <S.Description>{children}</S.Description>
            </div>
        </S.Card>
    )
}

SmoothTemplateCard.defaultProps = {
    size: 'small',
    selected: false,
}

SmoothTemplateCard.propTypes = {
    children: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
    iconFamily: PropTypes.string,
    selected: PropTypes.bool,
    selectTemplate: PropTypes.func.isRequired,
    size: PropTypes.oneOf(['small', 'medium']),
}

export default SmoothTemplateCard

import { connect } from "react-redux"

import AppComponent from "QuorumGrassroots/framework/components/index"

import {
    selectActionCenterId,
    selectActionCenterPubliclyAvailable,
    selectCampaignBackgroundColor,
    selectCampaignBackgroundImage,
    selectCampaignBackgroundStyle,
    selectCookielessSafari,
    selectCookielessEmbedded,
    selectCustomActionCenterJS,
    selectEmailRelatedPerson,
    selectFooterDisclaimerText,
    selectFooterHtml,
    selectFoundationalRoutes,
    selectHasGrassroots,
    selectIndexRoute,
    selectIsCampaignPage,
    selectNavBarStyleIsNone,
    selectNavigationBarIsTransparent,
    selectPages,
    selectRedirectPaths,
    selectShouldUseFooterHtml,
    selectUserLoggedIn,
    selectWidgetRouteTypes,
} from "QuorumGrassroots/framework/selectors"

import { preloadData, trackGrassrootsVisit } from "QuorumGrassroots/framework/action-creators"
import { withRouter } from "QuorumGrassroots/withRouter"

const mapStateToProps = (state, props) => ({
    actionCenterId: selectActionCenterId(state, props),
    hasGrassroots: selectHasGrassroots(state, props),
    indexRoute: selectIndexRoute(state, props),
    isPublic: selectActionCenterPubliclyAvailable(state, props),
    footerHtml: selectFooterHtml(state, props),
    shouldUseFooterHtml: selectShouldUseFooterHtml(state, props),
    pages: selectPages(state, props),
    userLoggedIn: selectUserLoggedIn(state, props),
    foundationalRoutes: selectFoundationalRoutes(state, props),
    widgetRouteTypes: selectWidgetRouteTypes(state, props),
    redirectPaths: selectRedirectPaths(state, props),
    cookielessSafari: selectCookielessSafari(state, props),
    cookielessEmbedded: selectCookielessEmbedded(state, props),
    emailRelatedPerson: selectEmailRelatedPerson(state, props),
    customJS: selectCustomActionCenterJS(state, props),
    shouldNotShowNavBar: selectNavBarStyleIsNone(state, props),
    backgroundStyleType: selectCampaignBackgroundStyle(state, props),
    backgroundImage: selectCampaignBackgroundImage(state, props),
    backgroundColor: selectCampaignBackgroundColor(state, props),
    disclaimerText: selectFooterDisclaimerText(state, props),
    isTransparent: selectNavigationBarIsTransparent(state, props),
    isCampaignPage: selectIsCampaignPage(state, props),
})

const actions = {
    preloadData,
    trackGrassrootsVisit,
}

export default withRouter(connect(mapStateToProps, actions)(AppComponent))

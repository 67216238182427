import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/DTVGrid/style"
import * as helperFunctions from "utils/helperFunctions"

import DTVSectionHeader from "Components/Molecules/DTVSectionHeader"

class DTVGrid extends React.Component {
    state = {
        expanded: false,
    }

    toggleExpanded = () =>
        this.setState((state, props) => ({expanded: !state.expanded}))

    render() {
        const {
            children,
            collapsible,
            collapsedGridRows,
            gridWidth,
            headerButtons,
            headerComponent,
            headerText,
            icon,
        } = this.props

        // make sure we always have 1 row and 1 column if the necessary props are absent
        const numColumns = gridWidth || 1
        const allRows = Math.ceil((React.Children.count(children) || 1) / numColumns)
        const numRows = (this.state.expanded || !collapsible) ? allRows : Math.min(allRows, collapsedGridRows)
        const maxChildIndex = numColumns * numRows

        return (
            <S.Container data-auto-cy="CompoundDTVGrid">
                <DTVSectionHeader
                    collapsible={collapsible && (numRows < allRows || this.state.expanded)}
                    expanded={this.state.expanded}
                    headerButtons={headerButtons}
                    headerComponent={headerComponent}
                    headerText={headerText}
                    icon={icon}
                    toggleExpanded={this.toggleExpanded}
                />
                <S.Grid
                    cols={numColumns}
                    collapsedRows={numRows}
                    collapsed={collapsible && !this.state.expanded}
                    isUserIE11={helperFunctions.isUserIE11()}
                >
                    {
                        React.Children.map(children,
                            (item ,idx) =>
                                item && idx < maxChildIndex ? React.cloneElement(item, { gridWidth }) : undefined
                        )
                    }
                </S.Grid>
            </S.Container>
        )
    }
}

DTVGrid.defaultProps = {
    collapsedGridRows: 2,
    gridWidth: 12,
}

DTVGrid.propTypes = {
    // whether the see more / see less funcitonality should be used
    collapsible: PropTypes.bool,
    // maximum number of rows to show when collapsed
    collapsedGridRows: PropTypes.number,
    // number of CSS columns in the DTV grid; this is distinct from the
    // number of columns of elements that will be seen, which will vary
    // based on the styling of the children
    gridWidth: PropTypes.number,
    // optional component as the header
    headerComponent: PropTypes.element,
    // buttons to show in grid header
    headerButtons: PropTypes.arrayOf(PropTypes.element),
    // title of the DTV grid
    headerText: PropTypes.string,
    // icon rendered to left of headerText
    icon: PropTypes.string,
}

export default DTVGrid

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Atoms/Anchor/style"

const Anchor = ({
    children,

    href,
    onClick,
    target,
}) => (
    <S.Anchor
        data-auto-cy="AtomAnchor"
        href={href}
        target={target}
        onClick={(e) => {
            if (onClick) {
                e.preventDefault()
                onClick()
            }
        }}
    >
        { children }
    </S.Anchor>
)

Anchor.propTypes = {
    children: PropTypes.node,

    href: PropTypes.string,
    onClick: PropTypes.func,
    target: PropTypes.string,
}

export default Anchor

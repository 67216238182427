import React from "react"
import { connect } from "react-redux"
import { useNavigate, useLocation, useParams } from "react-router-dom"

// higher order components
import { reduxForm } from "redux-form/immutable"
import { withNamespaces } from "react-i18next"

import { createStandardThankableWidget } from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/ThankableWrapper"

import UpdateInformationWidget from "QuorumGrassroots/widgets/UpdateInformation/components/index"

import {
    selectUpdateInfoProps,
    selectUpdateInfoThanksProps,
} from "QuorumGrassroots/widgets/UpdateInformation/selectors"
import { updateInformation } from "QuorumGrassroots/widgets/action-creators"

import { withRouter } from "QuorumGrassroots/withRouter"

const submit = (values, dispatch, props) => {
    return dispatch(updateInformation(values, props))
}

const ReduxUpdateInfoForm = reduxForm({
    form: "updateInfo",
    onSubmit: submit,
    touchOnChange: true,
})(UpdateInformationWidget)

const connectedWidget = withRouter(connect(selectUpdateInfoProps, null)(ReduxUpdateInfoForm))

const thankableWidget = createStandardThankableWidget(connectedWidget, selectUpdateInfoThanksProps)

// this guy is the update information form, without any thank yous or frills. Use
// this if you only need the form and do not need and thank yous.
export const NonThankableUpdateInfoWidget = withNamespaces()(connectedWidget)

// this guy is the fully connected thankable widget.
export default withNamespaces()(thankableWidget)

import moment from "moment"
import DOMPurify from "dompurify"
import interactionFormConstants from "shared/forms/LogInteraction/constants"
import { parseTimezoneOffsetFromValue } from "shared/imports/sharedHelperFunctions"
import { userHasAccessToOOBCustomEventForm } from "app/static/frontend/profiles-new/containers/event/helpers"

/**
 * Given the values of a Redux form and the field to check, if the field is not a vlaid
 * moment object, add the appropriate error message to that field
 * @name checkValuesForValidMomentDateTime
 * @function
 * @param {Object} values - The values of the Redux Form (Must not be an immutable object)
 * @param {Number} errors - The current errors of the Form. Will be mutated
 * @param {String} fieldName - The name of the field to check. Will index the errors object at this field name
 * @param {Boolean} checkTimeMeridiem - If the function should check fir 'AM' or 'PM' in the time string
 * @returns {Object} errors - This function must mutate the original errors object
 */
export const checkValuesForValidMomentDateTime = ({
    values = {},
    errors = {},
    fieldName = "",
    checkTimeMeridiem = true,
}) => {
    if (values[fieldName] && values[fieldName]._isAMomentObject !== true) {
        const dateTime = values[fieldName]
        if (typeof dateTime === "string") {
            if (
                checkTimeMeridiem &&
                dateTime.toLowerCase().split("am").length === 1 &&
                dateTime.toLowerCase().split("pm").length === 1
            ) {
                errors[fieldName] = interactionFormConstants.datetimeHasNoAMPMWarning
            } else {
                errors[fieldName] = interactionFormConstants.datetimeTypedInvalid
            }
        } else {
            errors[fieldName] = interactionFormConstants.datetimeInvalid
        }
    }

    return errors
}

/**
 * Given a date Moment object (or string) and Time moment object (or string), return a string with correct date and time
 * If date or time are undefined or invalid, return null
 * Only the date (M/D/YYYY) from the date moment object or string is returned in the formatted string
 * Only the time (h:mm A) from the time moment object or string is returned in the formatted string
 * Append the timezone abbreviation (ex: 'EST') to the formatted string
 * Return the moment object of the formatted string
 * @name mergeDateAndTimeFields
 * @function
 * @param {string} date - Either a string or Moment object with the correct date
 * @param {string} time - Either a string or Moment object with the corret time
 * @param {number} timezone - Timezone num value, default to eastern timezone
 * @param {boolean} adjustDst - If the time should be adjusted to match the user's daylight savings time
 * @returns {Object} string with the correct date and time
 */
export const mergeDateAndTimeFields = ({ date, time, timezone, adjustDst }) => {
    const useOOBCustomEventForm = userHasAccessToOOBCustomEventForm()
    if ((!useOOBCustomEventForm && (!date || !time)) || (!date && !time)) {
        return null
    }
    const currentMoment = moment()
    const parsedDate = date._isAMomentObject ? date : moment(date)

    // If 'time' is already a moment object, create a new moment object (if 'adjustDst' flag is true, we don't want to mutate 'time' variable)
    // If 'time' is a datetime string, create Moment object with inputted time and current date
    // If 'time' doesn't exist for OOB form, use current time
    const eventTime = time ? time : currentMoment.format("h:mm A")
    const parsedTime = eventTime._isAMomentObject
        ? moment(eventTime)
        : moment(`${currentMoment.format("MMMM d, YYYY")} ${eventTime}`)

    // Change parsed time to be adjusted to match user's daylight savings
    if (adjustDst) {
        if (!currentMoment.isDST() && parsedDate.isDST()) {
            // If the user's current time is not in daylight savings time, but the parsed date is,
            // Decrement by one hour in order to match user's daylight savings time
            parsedTime.add(-1, "hours")
        } else if (currentMoment.isDST() && !parsedDate.isDST()) {
            // If the user's current time is in daylight savings time, but the parsed date isn't,
            // increment by one hour in order to match user's daylight savings time
            parsedTime.add(1, "hours")
        }
    }

    const offset = parseTimezoneOffsetFromValue(timezone)
    return moment(`${parsedDate.format("M/D/YYYY")} ${parsedTime.format("h:mm A")} ${offset}`)
}

/**
 * Sanitize a QuorumUser-submitted Comment text string's HTML with DOMPurify,
 * https://www.npmjs.com/package/dompurify, and the whitelisting
 * configuration set up in WHITELIST_HTML_ATTRIBUTES_FOR_COMMENT_TEXT.
 * @name sanitizeUserCommentTextHtml
 * @function
 * @param {string}
 * @returns {string} sanitized string with only allowed HTML tags
 */
export const sanitizeUserCommentTextHtml = (commentText) => {
    return DOMPurify.sanitize(commentText, interactionFormConstants.WHITELIST_HTML_ATTRIBUTES_FOR_COMMENT_TEXT)
}

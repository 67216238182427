import { RegulationCommentForm } from "QuorumGrassroots/campaign-forms/components/RegulationCommentForm"
import { writeSectionConfigs } from "QuorumGrassroots/campaign-forms/write-form-constants"
import { getMessageFormWrapper } from "QuorumGrassroots/campaign-forms/wrappers/GetMessagesFormWrapper"
import { generateGrassrootsActionsWithImmutableValues } from "QuorumGrassroots/campaign-forms/helpers"
import { generateCommentOnRegulationActions } from "QuorumGrassroots/campaign-forms/grassrootsActionGenerators"

const { CampaignType } = DjangIO.app.grassroots.campaign.types

export default getMessageFormWrapper({
    formName: "comment_on_regulation",
    generateGrassrootsActions: (messageValues, globalFormValues, props) =>
        generateGrassrootsActionsWithImmutableValues(
            generateCommentOnRegulationActions,
            messageValues,
            globalFormValues,
            props,
        ),
    writeSectionConfigs: writeSectionConfigs[CampaignType.comment_on_regulation.value],
})(RegulationCommentForm)

import styled from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const Label = styled.div`
    background-color: transparent;
    display: flex;
    margin-bottom: 2px;
`

export const TitleText = styled.div`
    ${rules.MediumTextRegularWeight}
    color: ${colors.gray7};
    overflow: hidden;
`

export const TooltipIcon = styled.div`
    ${rules.text.smallHeight};
    color: ${colors.gray7};
    cursor: pointer;
    margin-top: 2px;
    padding-left: 5px;
`

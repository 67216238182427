export const constants = {
    colMdWidth: 992,
    campaignMessagePreviewReduxFormKey: "campaignMessagePreviewForm",
    contactUsFormReduxFormKey: "contactUsForm",
    defaultBackgroundGrey: "#D5DCEF",
    defaultBorderColor: "#000000",
    defaultPrimaryColor: "#000000",
    defaultLightGrey: "#CCCCCC",
    donationFormReduxFormKey: "donationForm",
    eventReduxFormKey: "eventForm",
    inputFieldBorderColor: "#ced5e7",
    freshLightGrey: "#F1F3F5",
    freshMediumGrey: "#495057",
    freshDarkGrey: "#212529",
    mobileWidth: 768,
    whiteColor: "#FFFFFF",

    colors: {
        green: [
            "#F0FDF2",
            "#DDFBE1",
            "#BDF5C6",
            "#BDF5C6",
            "#4FD967",
            "#2ACB47",
            "#1B9E32",
            "#197C2B",
            "#196227",
            "#062D0F",
        ],
    },
}

// style constants separated by theme
export const themeStyleConstants = {
    [DjangIO.app.grassroots.types.ActionCenterTheme.impact.value as number]: {
        headerHeight: 85,
        pageDesktopStyle: `
            margin-left: 80px;
            margin-right: 80px;
            margin-top: 30px;
            padding-top: 30px;
            padding-right: 0px;
            padding-left: 0px;
        `,
    },
}

import React from "react"

import * as S from "Components/Atoms/AiSearchLoadingGif/style"

const AiSearchLoadingGif = () => (
    <S.AiSearchLoadingGif>
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
            <path opacity="0.4" d="M31.7254 25.5521L28.0018 23.9996L26.4494 20.2761C26.4079 20.1929 26.3441 20.1229 26.265 20.0739C26.186 20.025 26.0949 19.9991 26.0019 19.9991C25.909 19.9991 25.8178 20.025 25.7388 20.0739C25.6597 20.1229 25.5959 20.1929 25.5544 20.2761L24.002 23.9996L20.2784 25.5521C20.1952 25.5935 20.1252 25.6574 20.0763 25.7364C20.0274 25.8155 20.0015 25.9066 20.0015 25.9995C20.0015 26.0925 20.0274 26.1836 20.0763 26.2627C20.1252 26.3417 20.1952 26.4056 20.2784 26.447L24.002 27.9995L25.5544 31.723C25.5959 31.8062 25.6597 31.8762 25.7388 31.9252C25.8178 31.9741 25.909 32 26.0019 32C26.0949 32 26.186 31.9741 26.265 31.9252C26.3441 31.8762 26.4079 31.8062 26.4494 31.723L28.0018 27.9995L31.7254 26.447C31.8086 26.4056 31.8786 26.3417 31.9275 26.2627C31.9764 26.1836 32.0024 26.0925 32.0024 25.9995C32.0024 25.9066 31.9764 25.8155 31.9275 25.7364C31.8786 25.6574 31.8086 25.5935 31.7254 25.5521Z" fill="#7259EF"/>
            <path opacity="0.2" d="M20.2788 6.4473L24.0024 8.00036L25.5548 11.7239C25.5962 11.8071 25.6601 11.8771 25.7391 11.9261C25.8182 11.975 25.9093 12.0009 26.0023 12.0009C26.0952 12.0009 26.1863 11.975 26.2654 11.9261C26.3444 11.8771 26.4083 11.8071 26.4497 11.7239L28.0022 8.00036L31.7258 6.4473C31.8088 6.40577 31.8786 6.34194 31.9274 6.26296C31.9762 6.18399 32.0021 6.09298 32.0021 6.00014C32.0021 5.90729 31.9762 5.81629 31.9274 5.73731C31.8786 5.65833 31.8088 5.5945 31.7258 5.55297L28.0022 4.00054L26.4497 0.276955C26.4083 0.193755 26.3444 0.123763 26.2654 0.0748399C26.1863 0.0259168 26.0952 0 26.0023 0C25.9093 0 25.8182 0.0259168 25.7391 0.0748399C25.6601 0.123763 25.5962 0.193755 25.5548 0.276955L24.0024 4.00054L20.2788 5.55297C20.1957 5.5945 20.1259 5.65833 20.0771 5.73731C20.0283 5.81629 20.0024 5.90729 20.0024 6.00014C20.0024 6.09298 20.0283 6.18399 20.0771 6.26296C20.1259 6.34194 20.1957 6.40577 20.2788 6.4473Z" fill="#7259EF"/>
            <path opacity="0.6" d="M24.0022 15.9775C24.0021 15.7917 23.9503 15.6096 23.8528 15.4515C23.7552 15.2934 23.6157 15.1654 23.4498 15.0819L16.4145 11.5577L12.8971 4.50987C12.5571 3.83053 11.4478 3.83053 11.1084 4.50987L7.5911 11.5577L0.555795 15.0819C0.3889 15.1646 0.248436 15.2924 0.150234 15.4507C0.0520332 15.6089 0 15.7915 0 15.9778C0 16.1641 0.0520332 16.3467 0.150234 16.5049C0.248436 16.6632 0.3889 16.791 0.555795 16.8737L7.5911 20.3979L11.1084 27.4457C11.1915 27.6118 11.3192 27.7515 11.4771 27.8491C11.6351 27.9467 11.8171 27.9984 12.0028 27.9984C12.1885 27.9984 12.3705 27.9467 12.5284 27.8491C12.6864 27.7515 12.8141 27.6118 12.8971 27.4457L16.4145 20.3979L23.4498 16.8749C23.616 16.7912 23.7556 16.663 23.8532 16.5045C23.9507 16.346 24.0023 16.1636 24.0022 15.9775Z" fill="#7259EF"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
            <path opacity="0.4" d="M31.7254 25.5521L28.0018 23.9996L26.4494 20.2761C26.4079 20.1929 26.3441 20.1229 26.265 20.0739C26.186 20.025 26.0949 19.9991 26.0019 19.9991C25.909 19.9991 25.8178 20.025 25.7388 20.0739C25.6597 20.1229 25.5959 20.1929 25.5544 20.2761L24.002 23.9996L20.2784 25.5521C20.1952 25.5935 20.1252 25.6574 20.0763 25.7364C20.0274 25.8155 20.0015 25.9066 20.0015 25.9996C20.0015 26.0925 20.0274 26.1836 20.0763 26.2627C20.1252 26.3417 20.1952 26.4056 20.2784 26.447L24.002 27.9995L25.5544 31.723C25.5959 31.8062 25.6597 31.8762 25.7388 31.9252C25.8178 31.9741 25.909 32 26.0019 32C26.0949 32 26.186 31.9741 26.265 31.9252C26.3441 31.8762 26.4079 31.8062 26.4494 31.723L28.0018 27.9995L31.7254 26.447C31.8086 26.4056 31.8786 26.3417 31.9275 26.2627C31.9764 26.1836 32.0024 26.0925 32.0024 25.9996C32.0024 25.9066 31.9764 25.8155 31.9275 25.7364C31.8786 25.6574 31.8086 25.5935 31.7254 25.5521Z" fill="#7259EF"/>
            <path opacity="0.2" d="M20.2788 6.4473L24.0024 8.00036L25.5548 11.7239C25.5962 11.8071 25.6601 11.8771 25.7391 11.9261C25.8182 11.975 25.9093 12.0009 26.0023 12.0009C26.0952 12.0009 26.1863 11.975 26.2654 11.9261C26.3444 11.8771 26.4083 11.8071 26.4497 11.7239L28.0022 8.00036L31.7258 6.4473C31.8088 6.40577 31.8786 6.34194 31.9274 6.26296C31.9762 6.18399 32.0021 6.09298 32.0021 6.00014C32.0021 5.90729 31.9762 5.81629 31.9274 5.73731C31.8786 5.65833 31.8088 5.5945 31.7258 5.55297L28.0022 4.00054L26.4497 0.276955C26.4083 0.193755 26.3444 0.123763 26.2654 0.0748399C26.1863 0.0259168 26.0952 0 26.0023 0C25.9093 0 25.8182 0.0259168 25.7391 0.0748399C25.6601 0.123763 25.5962 0.193755 25.5548 0.276955L24.0024 4.00054L20.2788 5.55297C20.1957 5.5945 20.1259 5.65833 20.0771 5.73731C20.0283 5.81629 20.0024 5.90729 20.0024 6.00014C20.0024 6.09298 20.0283 6.18399 20.0771 6.26296C20.1259 6.34194 20.1957 6.40577 20.2788 6.4473Z" fill="#7259EF"/>
            <path opacity="0.6" d="M24.0022 15.9775C24.0021 15.7917 23.9503 15.6096 23.8528 15.4515C23.7552 15.2934 23.6157 15.1654 23.4498 15.0819L16.4145 11.5577L12.8971 4.50989C12.5571 3.83055 11.4478 3.83055 11.1084 4.50989L7.5911 11.5577L0.555795 15.0819C0.3889 15.1647 0.248436 15.2924 0.150234 15.4507C0.0520332 15.609 0 15.7915 0 15.9778C0 16.1641 0.0520332 16.3467 0.150234 16.505C0.248436 16.6633 0.3889 16.791 0.555795 16.8737L7.5911 20.3979L11.1084 27.4457C11.1915 27.6118 11.3192 27.7515 11.4771 27.8491C11.6351 27.9467 11.8171 27.9984 12.0028 27.9984C12.1885 27.9984 12.3705 27.9467 12.5284 27.8491C12.6864 27.7515 12.8141 27.6118 12.8971 27.4457L16.4145 20.3979L23.4498 16.875C23.616 16.7912 23.7556 16.663 23.8532 16.5045C23.9507 16.346 24.0023 16.1636 24.0022 15.9775Z" fill="#7259EF"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
            <path d="M31.7259 25.552L28.0023 23.9996L26.4499 20.276C26.4084 20.1928 26.3446 20.1228 26.2655 20.0739C26.1865 20.0249 26.0954 19.999 26.0024 19.999C25.9094 19.999 25.8183 20.0249 25.7393 20.0739C25.6602 20.1228 25.5964 20.1928 25.5549 20.276L24.0025 23.9996L20.2789 25.552C20.1957 25.5935 20.1257 25.6573 20.0768 25.7363C20.0279 25.8154 20.002 25.9065 20.002 25.9995C20.002 26.0924 20.0279 26.1836 20.0768 26.2626C20.1257 26.3416 20.1957 26.4055 20.2789 26.447L24.0025 27.9994L25.5549 31.723C25.5964 31.8062 25.6602 31.8762 25.7393 31.9251C25.8183 31.974 25.9094 31.9999 26.0024 31.9999C26.0954 31.9999 26.1865 31.974 26.2655 31.9251C26.3446 31.8762 26.4084 31.8062 26.4499 31.723L28.0023 27.9994L31.7259 26.447C31.8091 26.4055 31.8791 26.3416 31.928 26.2626C31.9769 26.1836 32.0028 26.0924 32.0028 25.9995C32.0028 25.9065 31.9769 25.8154 31.928 25.7363C31.8791 25.6573 31.8091 25.5935 31.7259 25.552Z" fill="#7259EF"/>
            <path opacity="0.6" d="M20.2783 6.4473L24.0019 8.00036L25.5543 11.7239C25.5958 11.8071 25.6596 11.8771 25.7386 11.9261C25.8177 11.975 25.9088 12.0009 26.0018 12.0009C26.0947 12.0009 26.1859 11.975 26.2649 11.9261C26.3439 11.8771 26.4078 11.8071 26.4493 11.7239L28.0017 8.00036L31.7253 6.4473C31.8083 6.40577 31.8781 6.34194 31.9269 6.26296C31.9757 6.18399 32.0016 6.09298 32.0016 6.00014C32.0016 5.90729 31.9757 5.81629 31.9269 5.73731C31.8781 5.65833 31.8083 5.5945 31.7253 5.55297L28.0017 4.00054L26.4493 0.276955C26.4078 0.193755 26.3439 0.123763 26.2649 0.0748399C26.1859 0.0259168 26.0947 0 26.0018 0C25.9088 0 25.8177 0.0259168 25.7386 0.0748399C25.6596 0.123763 25.5958 0.193755 25.5543 0.276955L24.0019 4.00054L20.2783 5.55297C20.1952 5.5945 20.1254 5.65833 20.0766 5.73731C20.0278 5.81629 20.002 5.90729 20.002 6.00014C20.002 6.09298 20.0278 6.18399 20.0766 6.26296C20.1254 6.34194 20.1952 6.40577 20.2783 6.4473Z" fill="#7259EF"/>
            <path opacity="0.8" d="M24.0022 15.9776C24.0021 15.7918 23.9503 15.6097 23.8528 15.4516C23.7552 15.2935 23.6157 15.1655 23.4498 15.082L16.4145 11.5578L12.8971 4.51C12.5571 3.83065 11.4478 3.83065 11.1084 4.51L7.5911 11.5578L0.555795 15.082C0.3889 15.1648 0.248436 15.2925 0.150234 15.4508C0.0520332 15.6091 0 15.7916 0 15.9779C0 16.1642 0.0520332 16.3468 0.150234 16.5051C0.248436 16.6634 0.3889 16.7911 0.555795 16.8738L7.5911 20.398L11.1084 27.4458C11.1915 27.6119 11.3192 27.7516 11.4771 27.8492C11.6351 27.9468 11.8171 27.9985 12.0028 27.9985C12.1885 27.9985 12.3705 27.9468 12.5284 27.8492C12.6864 27.7516 12.8141 27.6119 12.8971 27.4458L16.4145 20.398L23.4498 16.8751C23.616 16.7913 23.7556 16.6631 23.8532 16.5046C23.9507 16.3462 24.0023 16.1637 24.0022 15.9776Z" fill="#7259EF"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
            <path opacity="0.4" d="M31.7259 25.552L28.0023 23.9996L26.4499 20.276C26.4084 20.1928 26.3446 20.1228 26.2655 20.0739C26.1865 20.0249 26.0954 19.999 26.0024 19.999C25.9094 19.999 25.8183 20.0249 25.7393 20.0739C25.6602 20.1228 25.5964 20.1928 25.5549 20.276L24.0025 23.9996L20.2789 25.552C20.1957 25.5935 20.1257 25.6573 20.0768 25.7363C20.0279 25.8154 20.002 25.9065 20.002 25.9995C20.002 26.0924 20.0279 26.1836 20.0768 26.2626C20.1257 26.3416 20.1957 26.4055 20.2789 26.447L24.0025 27.9994L25.5549 31.723C25.5964 31.8062 25.6602 31.8762 25.7393 31.9251C25.8183 31.974 25.9094 31.9999 26.0024 31.9999C26.0954 31.9999 26.1865 31.974 26.2655 31.9251C26.3446 31.8762 26.4084 31.8062 26.4499 31.723L28.0023 27.9994L31.7259 26.447C31.8091 26.4055 31.8791 26.3416 31.928 26.2626C31.9769 26.1836 32.0028 26.0924 32.0028 25.9995C32.0028 25.9065 31.9769 25.8154 31.928 25.7363C31.8791 25.6573 31.8091 25.5935 31.7259 25.552Z" fill="#7259EF"/>
            <path opacity="0.8" d="M20.2783 6.4473L24.0019 8.00036L25.5543 11.7239C25.5958 11.8071 25.6596 11.8771 25.7386 11.9261C25.8177 11.975 25.9088 12.0009 26.0018 12.0009C26.0947 12.0009 26.1859 11.975 26.2649 11.9261C26.3439 11.8771 26.4078 11.8071 26.4493 11.7239L28.0017 8.00036L31.7253 6.4473C31.8083 6.40577 31.8781 6.34194 31.9269 6.26296C31.9757 6.18399 32.0016 6.09298 32.0016 6.00014C32.0016 5.90729 31.9757 5.81629 31.9269 5.73731C31.8781 5.65833 31.8083 5.5945 31.7253 5.55297L28.0017 4.00054L26.4493 0.276955C26.4078 0.193755 26.3439 0.123763 26.2649 0.0748399C26.1859 0.0259168 26.0947 0 26.0018 0C25.9088 0 25.8177 0.0259168 25.7386 0.0748399C25.6596 0.123763 25.5958 0.193755 25.5543 0.276955L24.0019 4.00054L20.2783 5.55297C20.1952 5.5945 20.1254 5.65833 20.0766 5.73731C20.0278 5.81629 20.002 5.90729 20.002 6.00014C20.002 6.09298 20.0278 6.18399 20.0766 6.26296C20.1254 6.34194 20.1952 6.40577 20.2783 6.4473Z" fill="#7259EF"/>
            <path opacity="0.1" d="M24.0022 15.9776C24.0021 15.7918 23.9503 15.6097 23.8528 15.4516C23.7552 15.2935 23.6157 15.1655 23.4498 15.082L16.4145 11.5578L12.8971 4.51C12.5571 3.83065 11.4478 3.83065 11.1084 4.51L7.5911 11.5578L0.555795 15.082C0.3889 15.1648 0.248436 15.2925 0.150234 15.4508C0.0520332 15.6091 0 15.7916 0 15.9779C0 16.1642 0.0520332 16.3468 0.150234 16.5051C0.248436 16.6634 0.3889 16.7911 0.555795 16.8738L7.5911 20.398L11.1084 27.4458C11.1915 27.6119 11.3192 27.7516 11.4771 27.8492C11.6351 27.9468 11.8171 27.9985 12.0028 27.9985C12.1885 27.9985 12.3705 27.9468 12.5284 27.8492C12.6864 27.7516 12.8141 27.6119 12.8971 27.4458L16.4145 20.398L23.4498 16.8751C23.616 16.7913 23.7556 16.6631 23.8532 16.5046C23.9507 16.3462 24.0023 16.1637 24.0022 15.9776Z" fill="#7259EF"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
            <path opacity="0.8" d="M31.7259 25.552L28.0023 23.9996L26.4499 20.276C26.4084 20.1928 26.3446 20.1228 26.2655 20.0739C26.1865 20.0249 26.0954 19.999 26.0024 19.999C25.9094 19.999 25.8183 20.0249 25.7393 20.0739C25.6602 20.1228 25.5964 20.1928 25.5549 20.276L24.0025 23.9996L20.2789 25.552C20.1957 25.5935 20.1257 25.6573 20.0768 25.7363C20.0279 25.8154 20.002 25.9065 20.002 25.9995C20.002 26.0924 20.0279 26.1836 20.0768 26.2626C20.1257 26.3416 20.1957 26.4055 20.2789 26.447L24.0025 27.9994L25.5549 31.723C25.5964 31.8062 25.6602 31.8762 25.7393 31.9251C25.8183 31.974 25.9094 31.9999 26.0024 31.9999C26.0954 31.9999 26.1865 31.974 26.2655 31.9251C26.3446 31.8762 26.4084 31.8062 26.4499 31.723L28.0023 27.9994L31.7259 26.447C31.8091 26.4055 31.8791 26.3416 31.928 26.2626C31.9769 26.1836 32.0028 26.0924 32.0028 25.9995C32.0028 25.9065 31.9769 25.8154 31.928 25.7363C31.8791 25.6573 31.8091 25.5935 31.7259 25.552Z" fill="#7259EF"/>
            <path opacity="0.2" d="M20.2783 6.4473L24.0019 8.00036L25.5543 11.7239C25.5958 11.8071 25.6596 11.8771 25.7386 11.9261C25.8177 11.975 25.9088 12.0009 26.0018 12.0009C26.0947 12.0009 26.1859 11.975 26.2649 11.9261C26.3439 11.8771 26.4078 11.8071 26.4493 11.7239L28.0017 8.00036L31.7253 6.4473C31.8083 6.40577 31.8781 6.34194 31.9269 6.26296C31.9757 6.18399 32.0016 6.09298 32.0016 6.00014C32.0016 5.90729 31.9757 5.81629 31.9269 5.73731C31.8781 5.65833 31.8083 5.5945 31.7253 5.55297L28.0017 4.00054L26.4493 0.276955C26.4078 0.193755 26.3439 0.123763 26.2649 0.0748399C26.1859 0.0259168 26.0947 0 26.0018 0C25.9088 0 25.8177 0.0259168 25.7386 0.0748399C25.6596 0.123763 25.5958 0.193755 25.5543 0.276955L24.0019 4.00054L20.2783 5.55297C20.1952 5.5945 20.1254 5.65833 20.0766 5.73731C20.0278 5.81629 20.002 5.90729 20.002 6.00014C20.002 6.09298 20.0278 6.18399 20.0766 6.26296C20.1254 6.34194 20.1952 6.40577 20.2783 6.4473Z" fill="#7259EF"/>
            <path opacity="0.6" d="M24.0022 15.9776C24.0021 15.7918 23.9503 15.6097 23.8528 15.4516C23.7552 15.2935 23.6157 15.1655 23.4498 15.082L16.4145 11.5578L12.8971 4.51C12.5571 3.83065 11.4478 3.83065 11.1084 4.51L7.5911 11.5578L0.555795 15.082C0.3889 15.1648 0.248436 15.2925 0.150234 15.4508C0.0520332 15.6091 0 15.7916 0 15.9779C0 16.1642 0.0520332 16.3468 0.150234 16.5051C0.248436 16.6634 0.3889 16.7911 0.555795 16.8738L7.5911 20.398L11.1084 27.4458C11.1915 27.6119 11.3192 27.7516 11.4771 27.8492C11.6351 27.9468 11.8171 27.9985 12.0028 27.9985C12.1885 27.9985 12.3705 27.9468 12.5284 27.8492C12.6864 27.7516 12.8141 27.6119 12.8971 27.4458L16.4145 20.398L23.4498 16.8751C23.616 16.7913 23.7556 16.6631 23.8532 16.5046C23.9507 16.3462 24.0023 16.1637 24.0022 15.9776Z" fill="#7259EF"/>
        </svg>
    </S.AiSearchLoadingGif>
)

export default AiSearchLoadingGif

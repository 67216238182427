// This is a Higher Order Component that is used to determine whether a particular user
// has access to the Route in our global Router configuration
import { connectedRouterRedirect } from "redux-auth-wrapper/history3/redirect"

/**
 * This function checks to see if a featureSlug is enabled
 * This function relies on Userdata. It should return false under any condition other than the case where the feature flag is enabled.
 * @name isFeatureEnabledHelper
 * @function
 * @param {String} featureSlug - a feature flag slug, of the form "ff_some_feature"
 * @returns {Boolean} - true if enabled, false if not
 */
const isFeatureEnabledHelper = (featureSlug) => {
    if (typeof Userdata === "undefined" || !Userdata || !Userdata.featureFlags) {
        return false
    }
    return Boolean(
        Userdata && Userdata.featureFlags && featureSlug in Userdata.featureFlags && Userdata.featureFlags[featureSlug]
    )
}

/**
 * This function determines if a feature is enabled for a user
 * Relies on window bound Userdata
 * @name isFeatureEnabled
 * @function
 * @param {String} featureSlug - A slug uniquely describing the feature
 * @param {String[]} [additionalFeatureSlugs] - Additional strings to be ANDed together
 *
 * @returns {Boolean} - Whether or not the feature is enabled
 */
export const isFeatureEnabled = (featureSlug, ...additionalFeatureSlugs) =>
    [featureSlug, ...additionalFeatureSlugs].reduce((acc, slug) => isFeatureEnabledHelper(slug) && acc, true)

/**
 * This function determines if any of the provided features are enabled (an OR of all feature slugs) for a user
 * Relies on window bound Userdata
 * @name isFeatureEnabled
 * @function
 * @param {String} featureSlug - A slug uniquely describing the feature
 * @param {String} additionalFeatureSlugs - Additional strings to be ORed together
 *
 * @returns {Boolean} - Whether or not the feature is enabled
 */
export const isAnyFeatureEnabled = (featureSlug, ...additionalFeatureSlugs) =>
    [featureSlug, ...additionalFeatureSlugs].reduce((acc, slug) => isFeatureEnabledHelper(slug) || acc, false)

export const routeIsFeatureToggled = connectedRouterRedirect({
    redirectPath: "/home/",
    authenticatedSelector: (_, routerProps) => isFeatureEnabled(routerProps.route.featureSlug),
    wrapperDisplayName: "UserIsFeatureToggled",
})

/**
 * This function returns a HOC connectedRouterRedirect
 * @name someName
 * @function
 * @param {String} redirectPath - an optional path to redirect to
 * @returns {Component}
 * connectedRouterRedirect is a Higher Order Component to determine
 * if a route is feature toggled
 * requires a prop featureSlug defined on the <Route /> Component
 */
export const _routeIsFeatureToggled = (redirectPath = "/home/") =>
    connectedRouterRedirect({
        redirectPath,
        authenticatedSelector: (_, routerProps) => {
            // mobile context does not have routerProps.route
            const featureSlug = routerProps.route ? routerProps.route.featureSlug : routerProps.featureSlug
            return isFeatureEnabled(featureSlug)
        },
        wrapperDisplayName: "UserIsFeatureToggled",
    })

// redirects to a predefined path if featureFlag is toggled
// requires a prop featureSlug defined on the <Route /> Component,
export const routeIsDeprecatedByFeatureFlag = (redirectPath = "/home/") =>
    connectedRouterRedirect({
        redirectPath,
        authenticatedSelector: (_, routerProps) => !isFeatureEnabled(routerProps.route.featureSlug),
        wrapperDisplayName: "UserIsFeatureToggled",
    })

// This is a special case where we do not want to show specific products specifically for
// the LeadershipConnect account.
const LEADERSHIP_CONNECT_ORG_ID = 2327
export const routeIsDeprecatedForLeadershipConnect = (redirectPath = "/home/") =>
    connectedRouterRedirect({
        redirectPath,
        authenticatedSelector: () => Userdata.organization_id !== LEADERSHIP_CONNECT_ORG_ID,
        wrapperDisplayName: "UserIsFeatureToggled",
    })

export default isFeatureEnabled

import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"

import Issue from "QuorumGrassroots/widgets/Issue/components/index"

import initializer from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/InitializeStoreWrapper"

import { issueSelectors } from "QuorumGrassroots/widgets/Issue/selectors"

const connectedIssue = connect(issueSelectors, null)(Issue)

const initializedIssue = initializer(connectedIssue)

export default withNamespaces()(initializedIssue)

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Atoms/FilterInfo/style"

/**
 * Renders a component that displays a message that will go under filter groups
 * Design spec: https://projects.invisionapp.com/d/main/#/console/21452478/453230299/build
 *
 * @param {string} dataCy
 * @param {string} message
 */
const FilterInfo = ({ message }) => (
    <S.FilterInfo data-auto-cy="AtomFilterInfo">
        {message}
    </S.FilterInfo>
)

FilterInfo.defaultProps = {}

FilterInfo.propTypes = {
    dataCy: PropTypes.string,
    message: PropTypes.string,
}

export default FilterInfo

import React from 'react'
import PropTypes from 'prop-types'

import * as S from 'Components/Molecules/ModalFooter/style'

const ModalFooter = ({
    center,
    leftSide,
    noBorder,
    rightSide
}) => (
    <S.Footer
        data-auto-cy="MoleculeModalFooter"
        noBorder={noBorder}
    >
        {
            leftSide &&
            <S.LeftSide>
                { leftSide }
            </S.LeftSide>
        }
        {
            center &&
            <S.Center>
                { center }
            </S.Center>
        }
        {
            rightSide &&
            <S.RightSide>
                { rightSide }
            </S.RightSide>
        }
    </S.Footer>
)

ModalFooter.defaultProps = {
    noBorder: false,
}

ModalFooter.propTypes = {
    center: PropTypes.element,
    leftSide: PropTypes.element,
    noBorder: PropTypes.bool,
    rightSide: PropTypes.element,
}

export default ModalFooter

import { connect } from "react-redux"
import { reduxForm } from "redux-form/immutable"
import { withNamespaces } from "react-i18next"

import { ExternalLogForm } from "QuorumGrassroots/campaign-forms/components/ExternalLogForm/index"
import { externalLogFormConnection } from "QuorumGrassroots/campaign-forms/selectors/external-log-form-selectors"

import {
    submitExternalLogForm,
    validateExternalLogForm,
    onSubmitExternalLogFormFail,
} from "QuorumGrassroots/campaign-forms/action-creators"

const campaignType = DjangIO.app.grassroots.campaign.types.CampaignType.log_interaction.value

const ReduxLogInteractionForm = reduxForm({
    form: "log-interaction",
    validate: validateExternalLogForm,
    onSubmit: submitExternalLogForm,
    onSubmitFail: onSubmitExternalLogFormFail,
})(ExternalLogForm)

const connectedForm = connect(externalLogFormConnection(campaignType), {})(ReduxLogInteractionForm)

export default withNamespaces()(connectedForm)

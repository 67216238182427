import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

const triangleWidth = "14px"
const triangleHeight = "7px"
const circleDiameter = "20px"

export const StatusIndicator = styled.div`
    // this empty div is needed for the translations to work correctly
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    transform: translateY(-50%);
`

export const Circle = styled.div`
    // the component is centered on the circle to make it easier to position
    flex-shrink: 0;
    margin-top: 2px;
    ${rules.CardStyle}
    background-color: ${({isActive}) => isActive ? colors.violet5 : colors.gray3};
    width: ${circleDiameter};
    height: ${circleDiameter};

    border: solid 3px ${colors.White};
    border-radius: 50%;

    // triangle
    &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        border-top: ${triangleHeight} solid ${({isActive}) => isActive ? colors.gray7 : colors.gray5};
        left: calc(50% - ${triangleWidth} / 2);
        border-right: calc(${triangleWidth} / 2) solid transparent;
        border-left: calc(${triangleWidth} / 2) solid transparent;
    }

    // text label
    &:after {
        content: attr(data-title);
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        bottom: 100%;
        white-space: nowrap;
        padding: 8px;
        margin-bottom: 2px;
        ${rules.SmallTextMediumWeight}
        font-weight: 400;
        color: ${({isActive}) => isActive ? colors.gray7 : colors.gray5};
        user-select: none;
    }
`

import React from "react"
import PropTypes from "prop-types"

import Icon from "Components/Atoms/Icon"
import debounce from "lodash.debounce"

import * as S from "Components/Molecules/SearchBar/style"

const DEBOUNCE_DELAY_TIME = 400

export class SearchBar extends React.Component {
    state = { searchBarText: this.props.value || "" }

    constructor(props) {
        super(props)
        this.clearSearchBarText = this.clearSearchBarText.bind(this)
        this.debounceOnChange = this.props.onChange
            ? debounce(this.props.onChange, DEBOUNCE_DELAY_TIME)
            : () => {}
        this.debounceOnKeyUp = this.props.onKeyUp
            ? debounce(this.props.onKeyUp, DEBOUNCE_DELAY_TIME)
            : () => {}
    }

    saveTextAndDebounce = (e) => {
        this.setState({ searchBarText: e.target.value })
        if (this.props.onChange) {
            if (this.props.disableDebounce) {
                this.props.onChange(e.target.value)
            } else {
                this.debounceOnChange(e.target.value)
            }
        }
    }

    onKeyUpDebounce = (e) => {
        if (this.props.onKeyUp) {
            if (this.props.disableOnKeyUpDebounce) {
                this.props.onKeyUp(e.target.value)
            } else {
                this.debounceOnKeyUp(e.target.value)
            }
        }
    }

    clearSearchBarText = () => {
        this.setState({ searchBarText: "" })
        this.props.clearOnClick()
    }

    render() {
        const {
            buttonDataCy,
            className,
            fullWidth,
            inputDataCy,
            isFilter,
            isGiant,
            onBlur,
            onSearch,
            placeholder,
            value,
        } = this.props
        return (
            <S.SearchBar
                data-auto-cy="MoleculeSearchBar"
                fullWidth={fullWidth}
                isFilter={isFilter}
                className={className}
            >
                <S.SearchBarInput
                    data-cy={inputDataCy}
                    isFilter={isFilter}
                    isGiant={isGiant}
                    onBlur={onBlur}
                    onChange={this.saveTextAndDebounce}
                    onKeyDown={
                        // enable submitting the search from the input via the enter key
                        (e) => {
                            if (e.key === "Enter") {
                                onSearch && onSearch(this.state.searchBarText)
                            }
                        }
                    }
                    onKeyUp={this.onKeyUpDebounce}
                    placeholder={placeholder}
                    type="text"
                    value={value}
                />
                {
                    isFilter && this.state.searchBarText &&
                        <S.SearchBarClear
                            data-cy="clear-input"
                            isFilter={isFilter}
                            onClick={this.clearSearchBarText}
                        >
                            <Icon
                                fixedWidth={false}
                                icon="times"
                                iconFamily="fal"
                            />
                        </S.SearchBarClear>
                }
                <S.SearchBarIcon
                    data-cy={buttonDataCy}
                    isFilter={isFilter}
                    isGiant={isGiant}
                    onClick={() => onSearch && onSearch(this.state.searchBarText)}
                >
                    <Icon
                        fixedWidth={false}
                        icon="search"
                    />
                </S.SearchBarIcon>
            </S.SearchBar>
        )
    }
}

SearchBar.defaultProps = {
    isGiant: false,
    fullWidth: false,
}

SearchBar.propTypes = {
    buttonDataCy: PropTypes.string,
    clearOnClick: PropTypes.func,
    fullWidth: PropTypes.bool,
    inputDataCy: PropTypes.string,
    isFilter: PropTypes.bool,
    isGiant: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string,
}

export default SearchBar

import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"


export const NavigationProgress = styled.div`
    ${rules.GiantTextMediumWeight}
    color: ${colors.QuorumBlue};
    display: flex;
    flex-direction: row;
`

export const Icon = styled.div`
    color: ${colors.QuorumBlue};
`

export const Title = styled.span`
    margin-left: 8px;
    margin-right: 6px;
`

export const Steps = styled.div`
    ${rules.TinyTextMediumWeight}
    color: ${colors.White};
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const Step = styled.div`
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-shrink: 0;
    margin: 0 5px;

    svg, span {
        overflow: visible;
        position: absolute;
    }
`

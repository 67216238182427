import "app/static/stylus/LongInlinePresentation.styl"

import React, { useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import queryString from "query-string"

import {
    Container,
    DistrictName,
    FlagContainer,
    RegionNameContainer,
    RegionName,
} from "QuorumGrassroots/widgets/FlagAndRegionName/components/style"

function FlagAndRegionName({ location }) {
    const [stateFlag, setStateFlag] = useState("")
    const [stateName, setStateName] = useState("")
    const [districtName, setDistrictName] = useState("")

    const districtOrdinal = useCallback((number) => {
        const suffixes = {
            1: "st",
            2: "nd",
            3: "rd",
        }
        const lastDigit = number % 10
        const lastTwoDigits = number % 100

        if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
            return "th"
        } else {
            return suffixes[lastDigit] || "th"
        }
    }, [])

    const getDistrictNumberConsideringAtLargeDistricts = useCallback((districtNumber) => {
        const AT_LARGE_DISTRICTS_NUMBERS = [0, 98]

        if (AT_LARGE_DISTRICTS_NUMBERS.includes(parseInt(districtNumber))) {
            return 1
        }

        return districtNumber
    }, [])

    useEffect(() => {
        const queryParams = queryString.parse(location.search)
        setDistrictName("")

        if (queryParams.state) {
            const states = DjangIO.app.models.Region.items()
            const stateObject = states.filter((item) => item.abbrev === queryParams.state.toLowerCase())
            setStateFlag(
                stateObject[0]?.icon_element || states.filter((state) => state.abbrev === "us")[0]?.icon_element,
            )
            setStateName(stateObject[0]?.region_name)
        }

        if (queryParams.cd) {
            const districtNumber = getDistrictNumberConsideringAtLargeDistricts(queryParams.cd)
            setDistrictName(`${districtNumber}${districtOrdinal(districtNumber)} District`)
        }
    }, [location.search])

    return (
        <Container>
            <FlagContainer
                className="icon-wrapper state-icon"
                dangerouslySetInnerHTML={{
                    __html: stateFlag,
                }}
            />
            <RegionNameContainer>
                <RegionName>{stateName}</RegionName>
                <DistrictName>{districtName}</DistrictName>
            </RegionNameContainer>
        </Container>
    )
}

FlagAndRegionName.propTypes = {
    location: PropTypes.object.isRequired,
}

export default FlagAndRegionName

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/DropdownListItem/style"
import * as rules from "Components/rules"

import Icon from "Components/Atoms/Icon"

export const DropdownListItem = ({
    dataCy,
    href,
    icon,
    iconFamily,
    isActive,
    isOverflow,
    newTab,
    onClick,
    SegueLinkComponent,
    SegueLinkProps,
    style,
    text,
    type,
}) => {
    const renderDropdownListItem = () => (
        <S.DropdownListItem
            data-auto-cy="MoleculeDropdownListItem"
            data-cy={dataCy}
            href={href}
            isActive={isActive}
            isLink={href}
            isOverflow={isOverflow}
            key={`${icon}-${text}`}
            onClick={onClick}
            rel={rules.aRel}
            style={style}
            target={newTab && rules.aTarget}
            type={type}

            // Polymorhic component
            as={href ? "a" : "div"}
        >
            {
                icon && (
                    <S.DropdownIconSpan type={type}>
                        <Icon
                            icon={icon}
                            iconFamily={iconFamily}
                        />
                    </S.DropdownIconSpan>
                )
            }
            {text}
        </S.DropdownListItem>
    )

    return SegueLinkComponent
        ? (
            <SegueLinkComponent
                to={SegueLinkProps.to}
                optionalPreSegueFunction={SegueLinkProps.optionalPreSegueFunction}
                data-cy={SegueLinkProps.dataCy}
            >
                {renderDropdownListItem()}
            </SegueLinkComponent>
        )
        : renderDropdownListItem()
}

DropdownListItem.defaultProps = {
    type: "primary",
}

DropdownListItem.propTypes = {
    dataCy: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.string,
    iconFamily: PropTypes.string,
    iconSize: PropTypes.string,
    isActive: PropTypes.bool,
    newTab: PropTypes.bool,
    onClick: PropTypes.func,
    SegueLinkComponent: PropTypes.elementType,
    SegueLinkProps: PropTypes.object,
    text: PropTypes.string,
    type: PropTypes.oneOf(["primary", "secondary", "tertiary", "borderless"]),
}

export default DropdownListItem

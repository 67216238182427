import styled from "styled-components"

export const Title = styled.div`
    font-size: 30px;
    font-weight: 700;
    font-family: "Helvetica" sans-serif;
    color: #000;
`

export const DistrictName = styled.h5`
    font-size: 16px;
    font-weight: 400;
    font-family: "Helvetica" sans-serif;
    color: #000;
    text-decoration: underline;
`

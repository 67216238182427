import { Button, InputGroup } from "react-bootstrap"
import Textarea from "react-textarea-autosize"
import styled from "styled-components"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { socialButtonBackgroundColor } from "QuorumGrassroots/widgets/styled-components/styles"

export const StyledTwitterCharacterCountError = styleWithOrgDesign(styled.div`
    margin-top: 10px;
    font-size: 14px;
    color: ${(props) => props.organizationDesign.secondary_color};
`)

export const StyledTwitterInputGroup = styled(InputGroup)`
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px !important;
`

export const StyledTwitterInputField = styled(Textarea)`
    font-size: 16px;
    padding: 6px 10px;
    border-bottom-left-radius: 4px !important;
    border-top-left-radius: 4px !important;
    width: 100%;

    &:focus {
        outline: none;
    }
`

export const StyledTwitterSendButton = styled(Button)`
    ${socialButtonBackgroundColor({ platform: DjangIO.app.grassroots.enums.SocialMediaType.twitter.value })}

    color: white !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    padding-left: 20px;
    padding-right: 20px;

    &:hover,
    &:active,
    &:focus {
        color: white !important;
    }

    display: flex;
    align-items: center;
    gap: 4px;
`

/* eslint-disable @typescript-eslint/no-empty-function */
import PropTypes from "prop-types"
import React from "react"
import { withNamespaces } from "react-i18next"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import Container from "react-bootstrap/Container"

import {
    selectCampaignLayoutType,
    selectCampaignNavigationBarStyle,
    selectLogoOverrideUrl,
    selectIsCampaignPage,
    selectNavigationBarIsTransparent,
    selectNavigationBarLinks,
} from "QuorumGrassroots/framework/selectors"

import { constants } from "QuorumGrassroots/constants"
import {
    StyledATag,
    StyledLogo,
    StyledLogoWrapper,
    StyledMoreDropDown,
    StyledMoreNavbarATag,
    StyledMoreNavbarNavLink,
    StyledNav,
    StyledNavDropdown,
    StyledNavInvisible,
    StyledNavLink,
    StyledNavLinkWrapper,
    StyledNavbar,
    StyledNavbarATag,
    StyledNavbarCollapse,
    StyledNavbarNavLink,
    StyledNavbarToggle,
} from "QuorumGrassroots/framework/components/NavigationBar/style"
import { navigationBarInit } from "QuorumGrassroots/helperFunctions"

const MAX_OVERFLOW_TAB_UPDATES = 5
const JITTER_PER_TAB = 5 // aka 5px
const MAX_LOGO_WIDTH = 250
const menubarId = "menubar-nav"

export class NavigationBar extends React.Component {
    state = { expanded: false, numOverflowTabs: 0 }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize)
        this.updateNumOverflowTabs(this.state.numOverflowTabs)

        if (document.body.offsetWidth > constants.mobileWidth) {
            // Run script to enable keyboard controls for navigation bar only on desktop screens
            navigationBarInit(menubarId)
        }

        // this fixes the navbar toggle icon because bootstrap is jank
        const iconBar1 = document.createElement("span")
        const iconBar2 = document.createElement("span")
        const iconBar3 = document.createElement("span")

        iconBar1.className = "icon-bar"
        iconBar2.className = "icon-bar"
        iconBar3.className = "icon-bar"

        document.querySelector(".navbar-toggle")?.appendChild(iconBar1)
        document.querySelector(".navbar-toggle")?.appendChild(iconBar2)
        document.querySelector(".navbar-toggle")?.appendChild(iconBar3)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            this.updateNumOverflowTabs(prevState.prevNumOverflowTabs)
        }
    }

    handleResize = () => {
        this.updateNumOverflowTabs(this.state.numOverflowTabs)
    }

    /**
     * Update the number of overflow tabs by repeatedly re-rendering the NavigationBar until the current best guess
     * for numOverflowTabs is equal to the best guess for the current state of the DOM
     *
     * @param oldNumOverflowTabs the numOverflowTabs of the previous render
     * @param stackLimit the max number of updates we should do
     */
    updateNumOverflowTabs = (prevNumOverflowTabs, stackLimit = MAX_OVERFLOW_TAB_UPDATES) => {
        if (stackLimit <= 0) {
            return
        }

        const numOverflowTabs = this.calculateNumOverflowTabsFromDom()
        if (numOverflowTabs !== prevNumOverflowTabs) {
            this.setState({ numOverflowTabs }, () => {
                this.updateNumOverflowTabs(numOverflowTabs, stackLimit - 1)
            })
        }
    }

    /**
     * Calculate the number of overflow tabs to use using DOM measurements. Since updating numOverflowTabs can change
     * the DOM this won't necessarily return the correct numOverflowTabs, but this running this function enough times
     * after updates will eventually settle on the correct amount.
     *
     * @returns {number} best guess for numOverflowTabs
     */
    calculateNumOverflowTabsFromDom = () => {
        // Select all relevant tab nodes.
        const parentNode = document.querySelector(`.navbar-collapse`)
        if (parentNode) {
            const parentWidth = parentNode.offsetWidth

            // Select all child nodes
            const tabNodes = [...document.querySelectorAll(`.navbar-collapse .navnode`)]

            // Iterate over child nodes and calculate the number of tabs
            // that do not fit within the parent component
            return tabNodes.reduce(
                (acc, node) => {
                    // Calculate the cumulative width (+ a constant amount of jitter)
                    const cumulativeWidth = acc.cumulativeWidth + node.offsetWidth + JITTER_PER_TAB
                    // Increment the number of overflowing tabs if necessary
                    const numOverflowTabs =
                        cumulativeWidth >= parentWidth ? (acc.numOverflowTabs += 1) : acc.numOverflowTabs

                    // Add the new counts to the accumulator
                    return Object.assign({}, acc, { cumulativeWidth, numOverflowTabs })
                },
                { cumulativeWidth: 0, numOverflowTabs: 0 },
            ).numOverflowTabs
        }
        return 0
    }

    collapseNavbar = () => this.setState({ expanded: false })

    toggleExpanded = () => this.setState({ expanded: !this.state.expanded })

    renderLink = (group, moreButton = false) => {
        // checks to see if the link is internal (does it start with "/")
        // or external in order to see whether or not to render a <Link> or
        // an <a> tag

        const { link, label, dataCy, login, linkProps, type } = group

        const NavbarATag = moreButton ? StyledMoreNavbarATag : StyledATag
        const NavbarNavLink = moreButton ? StyledMoreNavbarNavLink : StyledNavLink

        // If this is a 'More' menu, render wrapper with div and class 'nav-wrapper'
        // Otherwise, render a li element, so bootstrap can style navigation bar components
        return moreButton ? (
            <div className="navnode nav-wrapper ">
                {type === DjangIO.app.grassroots.types.GrassrootsNavigationBarButtonType.external.value ? (
                    <NavbarATag
                        className="external-link"
                        key={link}
                        href={link || "/"}
                        target="_blank"
                        login={login}
                        data-cy={dataCy}
                        onClick={this.collapseNavbar}
                        {...linkProps}
                    >
                        {label}
                    </NavbarATag>
                ) : (
                    <NavbarNavLink
                        className="internal-link"
                        key={link}
                        to={link || "/"}
                        state={{ prevPath: this.props.location.pathname }}
                        login={login}
                        data-cy={dataCy}
                        onClick={this.collapseNavbar}
                    >
                        {label}
                    </NavbarNavLink>
                )}
            </div>
        ) : (
            <li className="navnode">
                {type === DjangIO.app.grassroots.types.GrassrootsNavigationBarButtonType.external.value ? (
                    <NavbarATag
                        className="external-link"
                        key={link}
                        href={link || "/"}
                        target="_blank"
                        login={login}
                        data-cy={dataCy}
                        onClick={this.collapseNavbar}
                        {...linkProps}
                    >
                        {label}
                    </NavbarATag>
                ) : (
                    <NavbarNavLink
                        className="internal-link"
                        key={link}
                        to={link || "/"}
                        state={{ prevPath: this.props.location.pathname }}
                        login={login}
                        data-cy={dataCy}
                        onClick={this.collapseNavbar}
                    >
                        {label}
                    </NavbarNavLink>
                )}
            </li>
        )
    }

    renderNavLink = (group, moreButton = false) => {
        const NavDropdown = moreButton ? StyledMoreDropDown : StyledNavDropdown
        const NavbarATag = moreButton ? StyledMoreNavbarATag : StyledNavbarATag
        const NavbarNavLink = moreButton ? StyledMoreNavbarNavLink : StyledNavbarNavLink

        return group.type === DjangIO.app.grassroots.types.GrassrootsNavigationBarButtonType.dropdown_menu.value ? (
            <NavDropdown
                className={`nav-dropdown ${moreButton && "more-nav-dropdown"}`}
                title={group.label + " \u25BE"}
                id={group.label}
                data-cy={group.dataCy}
            >
                {group.dropdown_links.map((linkObj) =>
                    linkObj.type === DjangIO.app.grassroots.types.GrassrootsNavigationBarButtonType.external.value ? (
                        <NavbarATag
                            className="external-link"
                            key={linkObj.link || "/"}
                            href={linkObj.link || "/"}
                            target="_blank"
                            role="menuitem"
                            data-cy={linkObj.dataCy}
                            onClick={this.collapseNavbar}
                            {...linkObj.linkProps}
                            dropdown
                        >
                            {linkObj.label}
                        </NavbarATag>
                    ) : (
                        <NavbarNavLink
                            className="internal-link"
                            key={linkObj.link || "/"}
                            to={linkObj.link || "/"}
                            state={{ prevPath: this.props.location.pathname }}
                            role="menuitem"
                            data-cy={linkObj.dataCy}
                            onClick={this.collapseNavbar}
                            dropdown
                        >
                            {linkObj.label}
                        </NavbarNavLink>
                    ),
                )}
            </NavDropdown>
        ) : (
            this.renderLink(group, moreButton)
        )
    }

    renderLinks = () => {
        const maxTabsToRender = this.state.numOverflowTabs
            ? // Add 1 to account for the "More" tab in addition to the tabs
              // that do not fit within the navigation.
              // '- 1' to account for signup/logout button
              this.props.navBarLinks.length - this.state.numOverflowTabs
            : this.props.navBarLinks.length

        if (window.innerWidth <= constants.mobileWidth) {
            // For mobile views, render all navbar links. None collapse under a 'More' dropdown
            return this.props.navBarLinks.map((group) => this.renderNavLink(group, false))
        }

        return (
            <React.Fragment>
                {
                    // Which navbar links is shown independently
                    // 'slice(0, -1)' removes the last link (signup/logout)
                    // 'slice(0, maxTabsToRender) only renders the first N links that should be shown independently
                    this.props.navBarLinks
                        .slice(0, -1)
                        .slice(0, maxTabsToRender)
                        .map((group) => this.renderNavLink(group, false))
                }
                {
                    // If the amount of tabs rendered would overflow into the logo area, collapse links into 'More' dropdown
                    Boolean(this.state.numOverflowTabs > 1) && (
                        <StyledNav>
                            <StyledNavDropdown
                                title={this.props.t("navigation_bar.more.label") + " \u25BE"}
                                id="More"
                                data-cy="morebutton"
                            >
                                {
                                    // which navbar links are rendered under a 'More' dropdown
                                    // 'slice(0, -1)' removes the last link (signup/logout)
                                    // 'slice(maxTabsToRender) only renders the N - maxTabsToRender links that should be collapsed under the 'More' dropdown
                                    this.props.navBarLinks
                                        .slice(0, -1)
                                        .slice(maxTabsToRender)
                                        .map((group) => this.renderNavLink(group, true))
                                }
                            </StyledNavDropdown>
                        </StyledNav>
                    )
                }
                {
                    // Render the last navbar link, which is always the 'Sign Up' or 'Update info'/'Logout' dropdown
                    this.props.navBarLinks.slice(-1).map((group) => this.renderNavLink(group, false))
                }
                {
                    // Render invisible links for dimension calculation purposes
                    this.props.navBarLinks.slice(maxTabsToRender).map((group) => (
                        <StyledNavInvisible key={`${group.label}-invisible`}>
                            {this.renderLink(group)}
                        </StyledNavInvisible>
                    ))
                }
            </React.Fragment>
        )
    }

    render() {
        const isStandaloneCampaign =
            this.props.campaignLayoutType === DjangIO.app.grassroots.campaign.types.CampaignLayoutType.standalone.value

        switch (this.props.campaignNavBarStyle) {
            case DjangIO.app.grassroots.campaign.types.CampaignNavigationBarType.logo_only.value:
                return (
                    <StyledNavbar
                        collapseOnSelect
                        expanded={this.state.expanded}
                        onToggle={() => {}}
                        logoOnly
                        isCampaignPage={this.props.isCampaignPage}
                    >
                        <Container>
                            <StyledLogoWrapper
                                data-cy="logo"
                                maxLogoWidth={MAX_LOGO_WIDTH}
                                isTransparent={this.props.isTransparent}
                                isCampaignPage={this.props.isCampaignPage}
                                campaignBackgroundImage={this.props.backgroundImage}
                                logoOnly
                            >
                                {isStandaloneCampaign ? (
                                    <StyledLogo
                                        {...this.props}
                                        className="logo-image"
                                        logoOnly
                                        isCampaignPage={this.props.isCampaignPage}
                                    >
                                        {/* Include 'sr-only' for accessbility (legible to screen readers) */}
                                        <span className="logo-text sr-only">Link to Homepage</span>
                                    </StyledLogo>
                                ) : (
                                    <Link to={this.props.indexRoute} onClick={this.collapseNavbar}>
                                        <StyledLogo
                                            {...this.props}
                                            className="logo-image"
                                            logoOnly
                                            isCampaignPage={this.props.isCampaignPage}
                                        >
                                            {/* Include 'sr-only' for accessbility (legible to screen readers) */}
                                            <span className="logo-text sr-only">Link to Homepage</span>
                                        </StyledLogo>
                                    </Link>
                                )}
                            </StyledLogoWrapper>
                        </Container>
                    </StyledNavbar>
                )
            case DjangIO.app.grassroots.campaign.types.CampaignNavigationBarType.none.value:
                return null
            case DjangIO.app.grassroots.campaign.types.CampaignNavigationBarType.standard.value:
            default:
                return (
                    <StyledNavbar collapseOnSelect expanded={this.state.expanded} onToggle={() => {}}>
                        <Container>
                            <StyledLogoWrapper
                                data-cy="logo"
                                maxLogoWidth={MAX_LOGO_WIDTH}
                                isTransparent={this.props.isTransparent}
                                isCampaignPage={this.props.isCampaignPage}
                                campaignBackgroundImage={this.props.backgroundImage}
                            >
                                <Link to={this.props.indexRoute} onClick={this.collapseNavbar}>
                                    <StyledLogo className="logo-image" {...this.props}>
                                        {/* Include 'sr-only' for accessbility (legible to screen readers) */}
                                        <span className="logo-text sr-only">Link to Homepage</span>
                                    </StyledLogo>
                                </Link>
                                <StyledNavbarToggle className="navbar-toggle" onClick={this.toggleExpanded} />
                            </StyledLogoWrapper>
                            <StyledNavbarCollapse maxLogoWidth={MAX_LOGO_WIDTH}>
                                <StyledNav className="nav-wrapper">
                                    <StyledNavLinkWrapper className="nav-link-wrapper" id={menubarId} role="menubar">
                                        {this.renderLinks()}
                                    </StyledNavLinkWrapper>
                                </StyledNav>
                            </StyledNavbarCollapse>
                        </Container>
                    </StyledNavbar>
                )
        }
    }
}

NavigationBar.propTypes = {
    campaignNavBarStyle: PropTypes.number,
    isTransparent: PropTypes.bool,
    navBarLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
    isCampaignPage: PropTypes.bool,
}

const mapStateToProps = (state, props) => ({
    campaignLayoutType: selectCampaignLayoutType(state, props),
    campaignNavBarStyle: selectCampaignNavigationBarStyle(state, props),
    isTransparent: selectNavigationBarIsTransparent(state, props),
    logoOverrideUrl: selectLogoOverrideUrl(state, props),
    isCampaignPage: selectIsCampaignPage(state, props),
    navBarLinks: selectNavigationBarLinks(state, props),
})

export default withNamespaces()(connect(mapStateToProps, null)(NavigationBar))

import React from "react"
import PropTypes from "prop-types"
import { uniqueId } from "utils/helperFunctions"

import Button from "Components/Molecules/Button/index"

import * as S from "Components/Molecules/InputFileButton/style"

const InputFileButton = ({
    dataCy,
    disabled,
    isTiny,
    leftIcon,
    leftIconFamily,
    multiple,
    onChange,
    rightMargin,
    text,
    type,
    style,
}) => {
    const slug = uniqueId()

    return (
        <S.InputFileButton data-auto-cy="MoleculeInputFileButton">
            <Button
                dataCy={dataCy}
                disabled={disabled}
                isTiny={isTiny}
                labelFor={`file-input-${slug}`}
                leftIcon={leftIcon}
                leftIconFamily={leftIconFamily}
                rightMargin={rightMargin}
                text={text}
                type={type}
                style={style}
            />
            <S.Input
                disabled={disabled}
                id={`file-input-${slug}`}
                multiple={multiple}
                onChange={onChange}
            />
        </S.InputFileButton>
    )
}

InputFileButton.defaultProps = {}

InputFileButton.propTypes = {
    dataCy: PropTypes.string,
    disabled: PropTypes.bool,
    isTiny: PropTypes.bool,
    leftIcon: PropTypes.string,
    leftIconFamily: PropTypes.string,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    rightMargin: PropTypes.string,
    style: PropTypes.object,
    text: PropTypes.string,
    type: PropTypes.string,
}

export default InputFileButton

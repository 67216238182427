import styled from 'styled-components'
import * as colors from "Components/colors"

export const templateWidth = 280
export const margin = 13
export const padding = 32

export const OptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 ${padding}px;
`

export const Template = styled.div`
    flex: 1 0 ${templateWidth + margin}px;
    min-height: 100px;
    max-height: 162px;
    margin: ${margin}px;
    &:hover {
        cursor: pointer;
    }
`

export const SelectedTemplate = styled.div`
    ${Template}
    background-color: ${colors.SilverChalice};
`

export const SmoothContainer = styled.div`
    display: grid;

    grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));
    grid-auto-rows: auto;
    grid-gap: var(--mantine-spacing-xl);

    padding-inline: 2.75rem;
`

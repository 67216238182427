import React from "react"
import PropTypes from "prop-types"

import * as colors from "Components/colors"

import CircleImage from "Components/Molecules/CircleImage"

import * as S from "Components/Compounds/FormStep/style"

const FormStep = ({
    children,
    dataCy,
    stepChildren,
    stepLabel,
    stepNumber,
    hideStep,
}) => (
    <S.FormStep
        data-auto-cy="CompoundFormStep"
        data-cy={dataCy}
        hideStep={hideStep}
    >
        <S.StepLabel>
            <CircleImage
                circleColor={colors.QuorumBlue}
                diameter={"60px"}
                fill
                fillColor={colors.QuorumBlue}
                fontSize="24px"
                text={stepNumber}
            />
            <span>{stepLabel}</span>
            {stepChildren}
        </S.StepLabel >
        <S.StepContent>
            {children}
        </S.StepContent>
    </S.FormStep>
)

FormStep.defaultProps = {}

FormStep.propTypes = {
    children: PropTypes.element,
    dataCy: PropTypes.string,
    hideStep: PropTypes.bool,
    stepChildren: PropTypes.element,
    stepLabel: PropTypes.string,
    stepNumber: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
}

export default FormStep

import { connect } from "react-redux"

import PageComponent from "QuorumGrassroots/framework/components/Page/index"

import { getPageRegionFilters } from "QuorumGrassroots/framework/action-creators"

import {
    selectCampaignBackgroundColor,
    selectCampaignNavigationBarIsLogoOnly,
    selectIsCampaignPage,
    selectNavigationBarIsTransparent,
    selectPageRegionKey,
    selectPageWidgets,
    selectParamsRegion,
    selectParsedPageParams,
    selectRegionFilters,
    selectValidPageRegion,
    selectFullBleedHeaderType,
    selectCampaignBackgroundStyle,
} from "QuorumGrassroots/framework/selectors"

/**********************************************************************
 * CONNECTED PAGE
 ***********************************************************************/
const mapStateToProps = (state, props) => ({
    hasValidPageRegion: selectValidPageRegion(state, props),
    isCampaignPage: selectIsCampaignPage(state, props),
    pageWidgets: selectPageWidgets(state, props),
    params: selectParsedPageParams(state, props),
    paramsRegion: selectParamsRegion(state, props),
    regionFilters: selectRegionFilters(state, props),
    regionKey: selectPageRegionKey(state, props),
    campaignBackgroundColor: selectCampaignBackgroundColor(state, props),
    fullBleedHeaderType: selectFullBleedHeaderType(state, props),
    isTransparent: selectNavigationBarIsTransparent(state, props),
    logoOnly: selectCampaignNavigationBarIsLogoOnly(state, props),
    campaignBackgroundStyle: selectCampaignBackgroundStyle(state, props),
})

const Page = connect(mapStateToProps, { getPageRegionFilters })(PageComponent)

export default Page

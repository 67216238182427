import { Button, Modal } from "@mantine/core"
import styled from "styled-components"

export const StyledModal = styled(Modal)`
    border-radius: 16px;

    & .mantine-Modal-content {
        min-width: 360px;
    }

    & .mantine-Modal-body {
        padding: 24px;
    }
`

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
`

export const IconContainer = styled.div`
    height: 48px;
    width: 48px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 48px;
    background: #fcc9cb;
`

export const InnerIconContainer = styled.div`
    --color: #a80903;

    width: 20px;
    height: 20px;

    padding: 4px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background: transparent;

    border: 1px solid var(--color);

    color: var(--color);
    font-size: 10px;
`

export const Body = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
`

export const Title = styled.h2`
    color: #212529;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;

    margin: 0;
`

export const Description = styled.p`
    color: #495057;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;

    margin: 0;
`

export const ButtonsContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
`

export const StyledButton = styled(Button)`
    border-radius: 32px;
`

export const RemoveButton = styled(StyledButton)`
    color: #a80903;
    background: transparent;
    border: none;
    font-weight: 400;
`

export const KeepButton = styled(StyledButton)`
    background: #000000;
    color: white;

    &:hover,
    &:focus {
        background: #4b4b4b;
    }
`

import React from "react"
import PropTypes from "prop-types"

import { StyledRegulationsFooter } from "QuorumGrassroots/widgets/Campaign/components/style"

export const RegulationsFooter = ({ regulationId }) => (
    <StyledRegulationsFooter className="regulations-footer">
        <div className="regulations-footer-message">
            These links are terms of service messages provided by{" "}
            <a href="https://www.regulations.gov/" target="_blank" rel="noreferrer">
                regulations.gov
            </a>
        </div>
        <a
            className="regulations-privacy-notice"
            href="https://www.regulations.gov/privacy-notice"
            target="_blank"
            rel="noreferrer"
        >
            Regulations.gov Privacy and Security Notice
        </a>
        <a
            className="regulations-user-notice"
            href="https://www.regulations.gov/user-notice/"
            target="_blank"
            rel="noreferrer"
        >
            Regulations.gov User Notice
        </a>
        <a
            className="regulations-source"
            href={`https://www.regulations.gov/document/${regulationId}`}
            target="_blank"
            rel="noreferrer"
        >
            Source Regulation
        </a>
    </StyledRegulationsFooter>
)

RegulationsFooter.propTypes = {
    regulationId: PropTypes.string.isRequired,
}

export default RegulationsFooter

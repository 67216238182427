import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/GridToggle/style"
import Button from "Components/Molecules/Button"

const GridToggle = ({
    choices,
    enumeration,
    label,
    maxColumns,
    onClick,
    value,
}) => {
    const renderButton = choice =>
        <Button
            dataCy={`grid-choice-${choice.label}`}
            disabled={choice.disabled}
            isForm
            key={choice.value}
            leftIcon={choice.icon}
            leftIconFamily={choice.icon_family}
            leftIconSize="22px"
            onClick={() => onClick(choice.value)}
            text={choice.label}
            type={
                choice.value === value
                    ? "primary"
                    : "tertiary"
            }
        />

    return (
        <S.GridToggleContainer data-auto-cy="CompoundGridToggle">
            {
                (enumeration && enumeration.filter_groups) &&
                    // [
                    //     {
                    //         display: "list",
                    //         title: "Who",
                    //         values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
                    //     },
                    //     ...
                    // ]
                    enumeration.filter_groups.map(filterGroup => {
                        // turn
                        // [{value: 33, label: "Weeks", …}, {...}]
                        // into
                        // [33, ...]
                        const choicesValues = choices.map(choice => choice.value)

                        return (
                            // do not render a Label nor GridToggle for cases where
                            // we have no values for a certain filter_group
                            choicesValues.some(choicesValue =>
                                filterGroup.values.includes(choicesValue)
                            ) &&
                                <S.GridToggleFilterGroup>
                                    <S.Label>{filterGroup.title}</S.Label>
                                    <S.GridToggle
                                        // neccessary because we want grid-template-columns to be
                                        // repeat(1, 1fr) if we only have a single value
                                        choices={choices}
                                        // we set grid-template-columns to
                                        // repeat(maxColumns, 1fr) in all other cases
                                        maxColumns={maxColumns}
                                    >
                                    {
                                        filterGroup.values.map(filterGroupValue => {
                                            // get the original enum object of the current filterGroupValue
                                            const choice = enumeration.by_value(filterGroupValue)

                                            return (
                                                // only render the choice that is contained
                                                // within the filterGroupValue
                                                choicesValues.includes(filterGroupValue) &&
                                                    renderButton(choice)
                                            )
                                        })
                                    }
                                    </S.GridToggle>
                                </S.GridToggleFilterGroup>
                        )
                    }
                )
            }
            {
                (!enumeration || !enumeration.filter_groups) &&
                    (
                        label
                            ? (
                                <S.GridToggleFilterGroup>
                                    {label && <S.Label>{label}</S.Label>}
                                    <S.GridToggle
                                        choices={choices}
                                        maxColumns={maxColumns}
                                    >
                                        {choices.map(choice => renderButton(choice))}
                                    </S.GridToggle>
                                </S.GridToggleFilterGroup>
                            )
                            : (
                                <S.GridToggle
                                    choices={choices}
                                    maxColumns={maxColumns}
                                >
                                    {choices.map(choice => renderButton(choice))}
                                </S.GridToggle>
                            )
                    )
            }
        </S.GridToggleContainer>
    )
}

GridToggle.defaultProps = {
    maxColumns: 2,
}

GridToggle.propTypes = {
    choices: (
        PropTypes.arrayOf(
            PropTypes.shape({
                disabled: PropTypes.bool,
                icon: PropTypes.string,
                icon_family: PropTypes.string,
                label: PropTypes.string,
                value: PropTypes.number,
            })
        ).isRequired
    ),
    enumeration: PropTypes.object,
    label: PropTypes.string,
    maxColumns: PropTypes.number,
    onClick: PropTypes.func,
    value: PropTypes.number,
}

export default GridToggle

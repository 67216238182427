import styled from 'styled-components'

import * as colors from "Components/colors"

export const StatusBar = styled.div`
    width: inherit;
    height: 2px;
    margin: 40px;

    position: relative;
    display: flex;
    justify-content: space-between;

    // move the first indicator to cover the edge of the bar
    // the line is always the first child, so the first indicator
    // is always second
    & :nth-child(2) {
        transform: translateX(-50%);
    }

    // move the last indicator to cover the edge of the bar
    & > :last-child {
        transform: translateX(50%);
    }

    & > :nth-child(2):last-child {
        margin-left: auto;
    }
`

export const Line = styled.div`
    position: absolute;
    transform: translateY(-25%);
    width: ${({percent}) => percent}%;
    background-color: ${colors.gray5};
    height: 1px;
`

// https://projects.invisionapp.com/d/main/#/console/19025613/429117170/inspect

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/SearchifyFields/style"

import Icon from "Components/Atoms/Icon"

const SearchifyFields = ({
    children,
    index,
    renderRemoveButton,
    removeOnClick,
}) => (
    <S.SearchifyFields data-auto-cy="MoleculeSearchifyFields">
        <S.LabelDeleteContainer>
            <S.Label>Data {index}</S.Label>
            {
                renderRemoveButton &&
                    <S.DeleteContainer
                        data-cy={`searchify-fields-delete-button-${index}`}
                        onClick={removeOnClick}
                    >
                        <Icon
                            icon={"trash-alt"}
                            iconFamily={"fas"}
                            size={"sm"}
                        />
                        <span>Delete</span>
                    </S.DeleteContainer>
            }
        </S.LabelDeleteContainer>
        { children }
    </S.SearchifyFields>
)

SearchifyFields.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),
    index: PropTypes.number,
    renderRemoveButton: PropTypes.bool,
    removeOnClick: PropTypes.func,
}

export default SearchifyFields

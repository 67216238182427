import React from "react"
import PropTypes from "prop-types"
import { bifilter } from "utils/helperFunctions"

import * as S from "Components/Molecules/WorkflowStrip/style"

import Icon from "Components/Atoms/Icon"
import WorkflowOverlay from "Components/Molecules/WorkflowOverlay"

const WorkflowStrip = ({
    dataCy,
    hasCheckbox,
    icon,
    iconColor,
    iconFamily,
    iconRotation,
    indent,
    isChecked,
    isLoading,
    onCheckFn,
    onClickFn,
    subtext,
    text,
    textType,
    hoverActions,
    rightIcon,
    rightIconFamily,
    rightIconTitle
}) => {
    const TypedContainer = textType === "major" ? S.MajorContainer : S.MinorContainer
    const [altActions, actions] = bifilter(action => action.icon && !action.text, hoverActions)
    // For the strip's on click function, use the provided onClick
    // If there isn't one, use the onCheck, or finally, try to use
    // the first hover action's onClick
    const stripOnClickFunc = onClickFn
        || (hasCheckbox && onCheckFn)
        || (hoverActions
            && hoverActions.length
            && hoverActions[0].onClickFn
        )

    return (
        <TypedContainer data-auto-cy="MoleculeWorkflowStrip">
            <S.MainContainer data-cy={dataCy}
                checked={isChecked}
                subtext={!!subtext}
                onClick={ stripOnClickFunc }
            >
                <S.RowContainer indent={indent}>
                    <S.Row>
                    {hasCheckbox &&
                        <S.Checkbox
                            checked={isChecked}
                            onClick={(e) => {
                                // prevent the main container's onclick from firing
                                e.stopPropagation()
                                onCheckFn()
                            }}
                        >
                            { isChecked && <Icon icon="check" iconFamily="far" style={{marginLeft: "13px", color: "white"}}/> }
                        </S.Checkbox>
                    }
                    { icon &&
                        <S.Icon isChecked={isChecked} iconColor={iconColor} iconName={icon}>
                            <Icon icon={icon} iconFamily={iconFamily} rotation={iconRotation}/>
                        </S.Icon>
                    }
                        <S.TextSpan textType={textType}>
                            {text}
                        </S.TextSpan>

                    { (isLoading || rightIcon) &&
                        <S.Icon>
                            <Icon
                                icon={isLoading ? "spinner" : rightIcon}
                                iconFamily={isLoading ? "fas" : rightIconFamily}
                                spin={isLoading}
                                title={rightIconTitle}
                            />
                        </S.Icon>
                    }
                    </S.Row>
                    { subtext &&
                        <S.Row>
                            <S.TextSpan textType={"detail"}>
                                {subtext}
                            </S.TextSpan>
                        </S.Row>
                    }
                </S.RowContainer>
                {hoverActions && !isLoading &&
                    <WorkflowOverlay
                        actions={actions}
                        altActions={altActions}
                    />
                }
            </S.MainContainer>
        </TypedContainer>
    )
}

WorkflowStrip.defaultProps = {
    indent: 0,
    // Defaulting to null, so that Jest snapshots don't render titles with dynamically set IDs
    rightIconTitle: null,
}

WorkflowStrip.propTypes = {
    // data-cy tag for the entire workflow strip
    dataCy: PropTypes.string,
    // true if this strip should show a check box; hides icon
    hasCheckbox: PropTypes.bool,
    // icon to show left of the text
    icon: PropTypes.string,
    // primary or secondary color for the icon
    iconColor: PropTypes.oneOf(["primary", "secondary"]),
    // icon family for the icon left of the text
    iconFamily: PropTypes.string,
    // degrees clockwise to rotate the icon
    iconRotation: PropTypes.oneOf([90, 180, 270]),
    // integer number of indentations to move the rows in (to create sub-strips)
    indent: PropTypes.number,
    // whether the check box is checked and the checked color scheme is applied
    isChecked: PropTypes.bool,
    // whether a loading spinner should be shown on the left (replacing the check if needed)
    isLoading: PropTypes.bool,
    // callback for when the checkbox is checked (or unchecked)
    onCheckFn: PropTypes.func,
    // callback for when the strip is clicked
    onClickFn: PropTypes.func,
    // text to show in the detail text area, under the main text
    subtext: PropTypes.string,
    // the main text to show next to the icon / check box
    text: PropTypes.string,
    // size of the text (mock's "H1" is major, "H2" is minor)
    textType: PropTypes.oneOf(["major", "minor"]),
    // actions shown only when the component is being hovered over
    hoverActions: PropTypes.arrayOf(PropTypes.shape({
        // text of the clickable action
        text: PropTypes.string,
        // icon to display left of the text
        icon: PropTypes.string,
        // family of the icon
        iconFamily: PropTypes.string,
        // callback for when the clickable is clicked
        onClickFn: PropTypes.func,
    })),
    // props to display an icon on the right side of the strip
    rightIcon: PropTypes.string,
    rightIconFamily: PropTypes.string,
    rightIconTitle: PropTypes.string,
}

export default WorkflowStrip

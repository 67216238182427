import Immutable from "immutable"
import { TEXT_URL_DELIMITER } from "app/static/frontend/profiles-new/constants"

export const setDefaultValue = (object) => {
    // Depending on the tag type, set the default value from the correct field
    if (object.tag_type === DjangIO.app.person.types.TagType.boolean.value) {
        object.default_value = object.default_boolean_value
    } else if (object.tag_type === DjangIO.app.person.types.TagType.multi_options_list.value) {
        object.default_value = object.default_multi_value
    } else {
        object.default_value = object.default_string_value
    }

    return object
}

/**
 * This function checks a tagDictEntries object to see if a corresponding url slug exists for a given object's slug
 * If a url does exist in the tagDictEntries, that url string value is returned
 * @param {Object} object - The meta information for a single custom field associated with the organization's custom field settings
 * @param {Object} tagDictEntries - All of the tag_dict values associated with the QDT
 *
 * @returns {Boolean}
 */
export const getExternalUrlValue = (object, tagDictEntries) => {
    const urlSlug = `${object.slug}_url`
    if (urlSlug in tagDictEntries) {
        return tagDictEntries[urlSlug]
    }
    return undefined
}

/**
 * This function serializes the value for display in the component.
 * Changes true/false values to "Yes"/"No"
 * Keeps empty string values if custom field's diplay_null_values is true
 * Changes an array of values to comma-separated values
 * @param {Integer} key - The slug of the custom field
 * @param {Object} customField - The meta information associated with the organization's custom field settings
 * @param {Object} tagDict - The actual values associated with the QDT
 * @param {any} optionDefaultValue - The value that should be returned if there is no value
 *                                 for a single / multi option custom field. This value
 *                                 was originally supposed to be returned, so now we
 *                                 do a check to see what was returned
 * @returns {null}
 */
export const parseCustomFieldHelper = ({ tagDict, key, customField }) => {
    let parsedValue = tagDict[key]
    let parsedURL = ""
    let returnOptionDefault = false
    switch (true) {
        case parsedValue === true:
            // If custom field type is boolean and value is 'true'
            parsedValue = "Yes"
            break
        case parsedValue === false:
            // If custom field type is boolean and value is 'false'
            parsedValue = "No"
            break
        case !parsedValue && typeof parsedValue !== "number" && customField.display_null_value:
            // If the value is null and custom field specifies we should display null values
            parsedValue = ""
            break
        case customField.tag_type === DjangIO.app.person.types.TagType.multi_options_list.value:
            // For 'multi options list', the tagDict value is an array of option slugs. We want to map the slugs to option values

            if (typeof parsedValue === "string") {
                // There are some supporters with data integrity issues where the multi options tag dict is a string instead of an array
                // To get around this, convert the string to an array of length 1
                parsedValue = [parsedValue]
            }

            parsedValue =
                parsedValue &&
                parsedValue
                    .filter((slug) => customField.options_new[slug] && !customField.options_new[slug].archived) // Filter out archived options
                    .sort((slugA, slugB) => customField.options_new[slugA].order - customField.options_new[slugB].order) // Sort the slugs by specified order
                    .map((slug) => customField.options_new[slug].value) // Map the slugs to the option value
                    .join(", ") // Join the items in the array into strings separated by commas

            if (!parsedValue && !customField.display_null_value) {
                // If after filtering out the archived options results in an empty string and 'display_null_value' is false
                returnOptionDefault = true
            }
            break
        case customField.tag_type === DjangIO.app.person.types.TagType.single_option_list.value:
            // For 'single options lists' the tagDict value is the slug. We map the slug to the correct option value
            if (!customField.options_new[parsedValue] || customField.options_new[parsedValue].archived) {
                // If the tag dict has an option that no longer exists or option is archived

                if (customField.display_null_value) {
                    parsedValue = ""
                } else {
                    returnOptionDefault = true
                }
            } else {
                parsedValue = parsedValue && customField.options_new[parsedValue].value
            }

            break
        case customField.tag_type === DjangIO.app.person.types.TagType.string.value:
            parsedValue = parsedValue ?? ""
            parsedValue = parsedValue.toString()
            if (parsedValue.includes(TEXT_URL_DELIMITER)) {
                parsedURL = parsedValue.slice(
                    parsedValue.indexOf(TEXT_URL_DELIMITER) + TEXT_URL_DELIMITER.length,
                    parsedValue.length,
                )
                parsedValue = parsedValue.slice(0, parsedValue.indexOf(TEXT_URL_DELIMITER))
            }
            break
        default:
            // Default for custom fields types of number or text
            // If parsedValue = 0, convert to "0"
            parsedValue = (parsedValue || parsedValue === 0) && parsedValue.toString()
    }
    return { parsedValue, returnOptionDefault, parsedURL }
}

/**
 * This function serializes the value for display in the component.
 * Changes true/false values to "Yes"/"No"
 * Keeps empty string values if custom field's diplay_null_values is true
 * Changes an array of values to comma-separated values
 * @param {Integer} key - The slug of the custom field
 * @param {Object} customField - The meta information associated with the organization's custom field settings
 * @param {Object} tagDict - The actual values associated with the QDT
 * @returns {null}
 */
export const parseCustomFieldValues = (key, customField, tagDict) => {
    const { parsedValue, returnOptionDefault } = parseCustomFieldHelper({
        tagDict,
        key,
        customField,
    })
    // need this case here to return the default when single / multi option lists
    // don't exist, are not archived, and should not always render
    if (returnOptionDefault) {
        return null
    }

    // External URL if it exists
    const url = getExternalUrlValue(customField, tagDict)

    return {
        title: customField.name,
        value: parsedValue,
        // The alwaysRender field is for mobile, 'true' values will display null values
        alwaysRender: Boolean(parsedValue) || customField.display_null_value,
        url: url,
    }
}

/**
 * This function parses an array of custom fields
 * and creates an object with the custom fields' slug as the key
 * and the remaining custom field information as the value
 * @param {Array} customFields - array of custom fields
 * @returns {}
 */
export const createCustomFieldNameDict = (customFields) =>
    customFields.reduce(
        (curr, field) => ({
            ...curr,
            [field.slug]: field,
        }),
        {},
    )

/**
 * This function accepts the Organization's custom fields information and an object's tag dict
 * Filters the object's tag dict for any values specified (Also check if the value is false)
 * If the custom field's `display_null_value` is `true`, include custom field
 * Also filters for custom fields to be displayed on profile pages
 * Creates an array of objects, calling parseCustomFieldValues to fetch the object
 * This is primarily used for profile pages
 * @param {Object} customFieldsDict - The meta information associated with the organization's custom fields settings -
 * @param {Object} tagDict - The actual values associated with the object
 * @returns {array}
 */
export const createParsedCustomFieldsArray = (customFieldsDict, tagDict) =>
    Object.entries(customFieldsDict)
        .filter(
            ([key, value]) =>
                (tagDict[key] || tagDict[key] === false || value.display_null_value) && value.display_on_profile,
        )
        .map(([key, value]) => parseCustomFieldValues(key, value, tagDict))
        .filter((item) => item) // Filter out null values. Will have null values if options are archived

/**
 * This function takes in an options object and formats it for use in Select components
 * The difference between this function and 'parseOptionsForDefaultForm' is that these options are keyed by 'slug', and the values are coming from the api endpoint
 * Creates an array of objects, with value, label and order properties.
 * The label property is what is displayed to users
 * The value property is what is sent to the backend and saved to the tag_dict
 * The order property is only used to sort the options, it is not used in Selects
 * @param {Object} options - An object with slugs mapped to value/order
 * @returns {array}
 */
export const parseOptions = (options_new = {}) => {
    const optionChoices = Object.keys(options_new)
        .filter((slug) => !options_new[slug].archived) // Filter out the archived options
        .map((slug) => ({
            value: slug, // The value for SelectField should be the slug of the option
            label: options_new[slug].value, // The label for SelectField should be the value of the option
            order: options_new[slug].order, // This order property isn't used in SelectField, only to order the options below
        }))

    return optionChoices.sort((choiceA, choiceB) => choiceA.order - choiceB.order)
}

/**
 * This function takes in an options slice of the customFields form store and formats it for use in Select components in the DefaultForm component
 * The difference between this function and 'parseOptions' is that is these options are keyed by 'order', and the values are coming from the redux store
 * Creates an array of objects, with value, label and order properties.
 * The label property is what is displayed to users
 * The value property is what is sent to the backend and saved to the tag_dict
 * The order property is only used to sort the options, it is not used in Selects
 * @param {Object} options - An object with slugs mapped to value/order
 * @returns {array}
 */
export const parseOptionsForDefaultForm = (options) => {
    const optionsJS = options.toJS()

    return Object.keys(optionsJS)
        .map((order) => ({
            value: optionsJS[order].slug ? optionsJS[order].slug : optionsJS[order].value, // The value for SelectField should be the slug of the option
            label: optionsJS[order].value, // The label for SelectField should be the value of the option
            order, // This order property isn't used in SelectField, only to order the options below
        }))
        .sort((optionA, optionB) => optionA.order - optionB.order)
}

/**
 * This function takes in an options object and sorts the options based on the 'order' property
 * Then checks the 'archived' property. If 'archived' is false, push to an array, and join by commas
 * @param {Object} options - An object with slugs mapped to value/order
 * @returns {string}
 */
export const formatOptionsForResourceTable = (options) => {
    const values = []

    options
        .sort((optionA, optionB) => optionA.get("order") - optionB.get("order"))
        .forEach((option) => {
            if (!option.get("archived")) {
                values.push(option.get("value"))
            }
        })

    return values.join(", ")
}

export const formatOptionsForReducer = (newForm, initialForm, options_new) => {
    newForm.archivedOptions = []

    const optionSlugs = options_new ? Object.keys(options_new) : []

    if (options_new && optionSlugs.length) {
        newForm.options = optionSlugs.reduce((optionsObj, slug) => {
            const option = options_new[slug]

            const optionObj = {
                slug,
                value: option.value,
            }

            if (option.archived) {
                newForm.archivedOptions.push(optionObj)
            } else {
                optionsObj[option.order] = optionObj
            }

            return optionsObj
        }, {})
    } else {
        newForm.options = initialForm.options
    }
    return newForm
}

export const deleteOptionFromState = (state, action) => {
    // Get all currently archived options
    const archivedOptions = state.getIn(["form", "archivedOptions"])

    // Get all current unarchived options
    const oldOptions = state.getIn(["form", "options"])

    // Get the option that will be archived
    const deletedOption = oldOptions.get(action.optionKey)

    // Push the newly archived option to the list of archived options
    const newArchivedOptions = archivedOptions.push(deletedOption)

    // Merge new list of archived options to state
    const newState = state.setIn(["form", "archivedOptions"], newArchivedOptions)
    // Delete the archived option from the list of un-archived options
    const newOptions = oldOptions.toJS()
    delete newOptions[action.optionKey]

    // There will be a gap in the order due to removing the archived option
    // This will re order all of the options
    const formattedNewOptions = {}
    Object.values(newOptions).forEach((option, index) => {
        formattedNewOptions[index] = option
    })

    return newState.setIn(["form", "options"], Immutable.fromJS(formattedNewOptions))
}

/**
 * This function is run from the componentDidMount method of every Redux Form edit page of an object with custom fields on an associated data description
 * Most of these parameters are from the form's props, with loadObject and dataTypeKey being specific to the form
 * @param {Function} loadObject - Function that is given an id and loads the object
 * @param {Object} qdt - The QuorumDataType enum (ex: DjangIO.app.models.QuorumDataType.person)
 * @param {Object} params - The params given by the form's props
 * @param {Integer} profileId - current object's id
 * @param {Immutable Object} formValues - immutable object containing the form values
 * @param {Function} reset - a redux form function to reset the form and blank it
 * @param {Function} loadDataDescription - given dataTypeKey and the object's id, load the associated data description
 * @param {Integer} dataDescriptionId - the associated data description's id
 * @param {Function} loadCustomFields - function to load the organization's custom fields
 * @param {Function} loadPreviousFormValues - function to populate the form's initial values with it's previous values before segue
 * @returns {undefined}
 */
export const loadDataForCustomFieldsForm = ({
    loadObject,
    qdt,
    params,
    profileId,
    formValues,
    reset,
    loadDataDescription,
    dataDescriptionId,
    loadCustomFields,
    loadPreviousFormValues,
}) => {
    if (params.profileId) {
        let preserveForm
        let previousFormValues

        // Sometimes, users will navigate from the form to the custom fields management page and come back to the form
        // We want their previous values to be retained, so we specify `destroyOnUnmount: false` in the container
        // We check if they are viewing the same object as before, set preserveForm to true. If not, reset the form
        // 'profileId' refers to the previous object id, 'params.profileId' is the current object id
        if (profileId === parseInt(params.profileId)) {
            preserveForm = true
            previousFormValues = formValues
        } else {
            preserveForm = false
            reset()
        }
        loadObject(params.profileId).then(() => {
            loadDataDescription({
                qdt,
                id: params.profileId,
            }).then(() => {
                if (dataDescriptionId) {
                    // If there is a DataDescription object associated between the organization and the Object, just load Org's custom fields
                    loadCustomFields(Userdata.organization_id)
                } else {
                    loadCustomFields(Userdata.organization_id).then(() => {
                        if (preserveForm) {
                            // If the form has previously loaded the defaults, no need to load the defaults again and overwrite any of the changes they made
                            loadPreviousFormValues(previousFormValues)
                        }
                    })
                }
            })
        })
    } else {
        loadCustomFields(Userdata.organization_id)
    }
}

// Create a tag dict with the default values from the custom fields
export const createDefaultTagDict = (customFields, tagOwner) =>
    Object.keys(customFields).reduce((acc, key) => {
        let object = customFields[key]

        if (object.tag_owner === tagOwner) {
            object = setDefaultValue(object)
            return acc.set(key, object.default_value)
        } else {
            return acc
        }
    }, Immutable.fromJS({}))

/**
 * makeLoadDefaultCustomTagDictAction - abstracts building an action creator
 * to format and store custom tags for a given data type
 *
 * @param  {string} actionType   - an action type
 * @param  {integer} qdt         - a quorum data type integer value
 * @return {func}                - an action creator to format custom tags
 */
export const makeFormatDefaultCustomTagDictAction = (actionType, qdt) => (customFields) => ({
    type: actionType,
    defaultTagDict: createDefaultTagDict(customFields, qdt),
})

/**
 * Return a dict with custom field resource uri as keys, and custom field values
 *
 * @param  {object} customFieldNameDict - dict of all custom field slugs and custom field values
 * @return {object}                      - list of slugs for custom fields that are conditionally rendered on a parent custom field
 */
export const createResourceUriDict = (customFieldNameDict) => {
    const result = {}

    Object.keys(customFieldNameDict).forEach((fieldName) => {
        result[customFieldNameDict[fieldName].resource_uri] = customFieldNameDict[fieldName]
    })

    return result
}

/**
 * Return all slugs for custom fields that should be conditionally rendered based on their parent custom field
 *
 * @param  {object} customFieldNameDict - dict of all custom field slugs and custom field values
 * @param  {object} resourceUriDict     - dict of all custom field resource uris and custom field vlaues
 * @return {array}                      - list of slugs for custom fields that are conditionally rendered on a parent custom field
 */
export const createConditionalCustomFields = (customFieldNameDict, resourceUriDict) => {
    const result = []

    Object.keys(customFieldNameDict).forEach((fieldName) => {
        const customField = customFieldNameDict[fieldName]

        if (customField.conditional_parent_custom_field) {
            const parent = resourceUriDict[customField.conditional_parent_custom_field]

            if (parent) {
                result.push(fieldName)
            }
        }
    })

    return result
}

/**
 * For all inputted custom field values within a form,
 * return a dict of custom field slugs as keys, and inputted values
 *
 * @param  {object} customFieldNameDict - dict of all custom field slugs and custom field values
 * @param  {object} formValues     - dict of custom field slugs and inputted values
 * @return {array}                      - list of slugs for custom fields that are conditionally rendered on a parent custom field
 */
export const createDefinedCustomFieldFormValues = (customFieldNameDict, formValues) => {
    const definedValues = {}

    Object.keys(customFieldNameDict).forEach((fieldName) => {
        const value = formValues.get ? formValues.get(fieldName) : formValues[fieldName]
        if (value || value === false) {
            definedValues[fieldName] = value
        }
    })

    return definedValues
}

/**
 * Check for all custom fields that should be conditionally rendered based on their parent custom field
 * If the parent custom field has an inputted value that matches the condition specified on the child custom field,
 * The slug of the child custom field will be returned in a list
 *
 * @param  {object} customFieldNameDict - dict of all custom field slugs and custom field values
 * @param  {object} resourceUriDict     - dict of all custom field resource uris and custom field vlaues
 * @param  {object} definedValues       - dict of custom field slugs and inputted values
 * @return {array}                      - list of slugs for custom fields that should be rendered because parent custom fields fulfill conditions
 */
export const createConditionalParentValues = (customFieldNameDict, resourceUriDict, definedValues) => {
    const { TagType } = DjangIO.app.person.types
    const result = []

    Object.keys(customFieldNameDict).forEach((fieldName) => {
        const customField = customFieldNameDict[fieldName]

        // Check if custom field should be conditionally rendered on another custom field
        if (customField.conditional_parent_custom_field) {
            const parent = resourceUriDict[customField.conditional_parent_custom_field]

            // TODO In some cases, the conditional custom field is dependent on a parent
            // custom field to which the user doesn't have access, causing parent to
            // be undefined; we should one day prevent that type of relationship from forming
            const parentValue = parent && definedValues[parent.slug]

            // Check if parent custom field has a value inputted
            if (parentValue || parentValue === false) {
                // Depending on the parent custom field type,
                // check if the value on the parent fulfills the condition to render the child custom field
                if (
                    (parent.tag_type === TagType.string.value || parent.tag_type === TagType.number.value) &&
                    customField.conditional_string_value === parentValue
                ) {
                    result.push(fieldName)
                } else if (
                    parent.tag_type === TagType.boolean.value &&
                    customField.conditional_boolean_value === parentValue
                ) {
                    result.push(fieldName)
                } else if (
                    parent.tag_type === TagType.single_option_list.value &&
                    customField.conditional_list_value.includes(parentValue)
                ) {
                    result.push(fieldName)
                } else if (
                    parent.tag_type === TagType.multi_options_list.value &&
                    customField.conditional_list_value.some((value) => parentValue.includes(value))
                ) {
                    result.push(fieldName)
                }
            }
        }
    })

    return result
}

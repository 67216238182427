import styled, { css } from 'styled-components'

// not best practice to import the "private" styles of other components
// but enables a much simpler solution to a certain IE compatibility problem
import { Container as DTVCardContainer } from "Components/Molecules/DTVCard/style"

export const Container = styled.div`
    margin-bottom: 30px;
`

export const Grid = styled.div`
    ${({collapsedRows, cols, collapsed, isUserIE11}) => {
        return isUserIE11 ? css`
            display: flex;
            max-width: 1000px;
            display: flex;
            flex-wrap: wrap;

            & > * {
                // impose some quick and dirty top down styling
                // on DTV cards and elements to make them more
                // like grid items in a flex box
                flex-basis: 20%;
                flex-grow: 1;
                max-width: 50%;
                margin-left: 0px;
                margin-right: 21px;
                margin-bottom: 35px;
                margin-right: 20px;
                & > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            ${DTVCardContainer} {
                flex-basis: 40%;
            }
        ` : css`
            display: grid;
            grid-template-columns: repeat(${cols}, 1fr);
            ${collapsed && css`
                grid-template-rows: repeat(${collapsedRows}, 1fr);
            `}
            grid-column-gap: 18px;
            grid-row-gap: 18px;
        `
}}
`

export const Item = styled.div`
    ${({row, col}) => {
        return css`
            // the row and col props should be 0 indexed
            // transform them to fit with the css-grid rows and cols
            grid-column-start: ${col + 1};
            grid-column-end: span 1;
            grid-row-start: ${row + 1};
            grid-row-end: span 1;
        `
    }}
`

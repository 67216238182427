/**
 * Decode a JSON object of style key/value pairs
 * Currently only cleans out the 'px' from values
 * @param {Object} styles - styles to decode
 * @returns {Object} - decoded key/value pairs
 */
export const decodeStyles = (styles) => {
    const decodedStyles = {}
    Object.entries(styles).forEach(([key, value]) => {
        let decodedValue

        // the value is a measurement in pixels
        if (!value.includes(" ") && value.includes("px")) {
            decodedValue = parseInt(value.replace("px", ""))
        } else {
            decodedValue = value // do not change the value
        }

        decodedStyles[key] = decodedValue
    })
    return decodedStyles
}

/**
 * Encode an object of styles for the browser to understand
 * @param {Object} styles - styles to decode
 * @returns {Object} - Something
 */
export const encodeStyles = (styles) => {
    const encodedStyles = {}
    Object.entries(styles).forEach(([key, value]) => {
        let encodedValue

        // add the pixels back in
        if (typeof value === "number") {
            encodedValue = `${value}px`
        } else {
            encodedValue = value // do not change the value
        }

        encodedStyles[key] = encodedValue
    })
    return encodedStyles
}

/**
 * Compute the width of text given a font size
 * @param {string} font - a CSS font description, such as:
 *    "normal normal 300 16px Helvetica"
 * @param {string} text - text to compute width of
 * @returns {Integer} - the width of the text (multiplied by the number of lines)
 */
export const calculateTextWidth = (font, text) => {
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    ctx.font = font

    if (!text || !text.indexOf || text.indexOf("\n") === -1) {
        return ctx.measureText(text).width
    } else {
        return ctx.measureText(text).width * text.split("\n").length
    }
}

/**
 * https://stackoverflow.com/a/56266358/6201291
 * Check to see whether or not something is a valid CSS3 color
 *
 * @param {string} color - a CSS3 color value
 * @returns {boolean} - whether or not the param is a valid CSS3 color value
 */
export const isColor = (color) => {
    const s = new Option().style
    s.color = color
    return s.color !== ""
}

const { CampaignType } = DjangIO.app.grassroots.campaign.types

export const writeSectionConfigs = {
    [CampaignType.comment_on_regulation.value]: { noSubject: true },
    [CampaignType.petition.value]: { noSubject: true, noBody: true },
}

export const minimumMessagesToShow = 3

// Actually 5k, but we'll leave a small buffer
export const REGULATION_COMMENT_MAX_CHARS = 4950

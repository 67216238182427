import { TargetedMessage } from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/interfaces"

// Maps the placeholders for their final values, so that we can replace them even if they are already replaced for provisional values like "Friend"
export function mapPlaceholders(rawText: string, formattedText: string): Record<string, string> {
    const placeholders = []
    const placeholderRegex = /{{[^}]+}}/g
    let match
    while ((match = placeholderRegex.exec(rawText)) !== null) {
        placeholders.push({
            placeholder: match[0],
            start: match.index,
            end: match.index + match[0].length,
        })
    }

    let pattern = ""
    let position = 0
    let groupIndex = 1
    const placeholderGroupMap: Record<string, number> = {}

    function escapeRegExp(string: string): string {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
    }

    for (const placeholder of placeholders) {
        const textBetween = rawText.substring(position, placeholder.start)
        pattern += escapeRegExp(textBetween)

        pattern += "(.*?)"

        placeholderGroupMap[placeholder.placeholder] = groupIndex
        groupIndex++

        position = placeholder.end
    }

    const remainingText = rawText.substring(position)
    pattern += escapeRegExp(remainingText)

    const regex = new RegExp("^" + pattern + "$", "s")

    const result = regex.exec(formattedText)

    const placeholderValues: Record<string, string> = {}

    for (const [placeholder, index] of Object.entries(placeholderGroupMap)) {
        placeholderValues[placeholder] = result ? result[index] || "" : ""
    }

    return placeholderValues
}

export function replacePlaceholdersToMappedValues(text: string, placeholderMapping: Record<string, string>): string {
    return Object.keys(placeholderMapping).reduce((acc, key) => {
        const re = new RegExp(key, "g")
        return acc.replace(re, placeholderMapping[key])
    }, text)
}

export function replaceSupporterNamePlaceholders(
    rawText: string,
    formattedText: string,
    supporter: { firstname?: string; lastname?: string; address?: string; email?: string } = {},
): [string, string] {
    const { firstname, lastname, address, email } = supporter
    const rawWithSupporterReplaced = rawText
        .replace(/{{contact_first_name}}/g, firstname || "{{contact_first_name}}")
        .replace(/{{contact_last_name}}/g, lastname || "{{contact_last_name}}")
        .replace(/{{contact_full_name}}/g, firstname && lastname ? `${firstname} ${lastname}` : "{{contact_full_name}}")
        .replace(/{{contact_address}}/g, address || "{{contact_address}}")
        .replace(/{{contact_email}}/g, email || "{{contact_email}}")
    const mappedValues = mapPlaceholders(rawText, formattedText)
    if (firstname) mappedValues["{{contact_first_name}}"] = firstname
    if (lastname) mappedValues["{{contact_last_name}}"] = lastname
    if (firstname && lastname) mappedValues["{{contact_full_name}}"] = `${firstname} ${lastname}`
    if (address) mappedValues["{{contact_address}}"] = address
    if (email) mappedValues["{{contact_email}}"] = email
    const formattedWithSupporterReplaced = replacePlaceholdersToMappedValues(rawWithSupporterReplaced, mappedValues)
    return [formattedWithSupporterReplaced, rawWithSupporterReplaced]
}

export function replaceSupporterPlaceholderOnTargetedMessages(
    targetedMessages: Record<string, TargetedMessage>,
    supporter: { firstname?: string; lastname?: string; address?: string; email?: string } = {},
) {
    const newTargetedMessages = JSON.parse(JSON.stringify(targetedMessages)) as Record<string, TargetedMessage>
    Object.keys(newTargetedMessages).forEach((key) => {
        const [formattedContent, rawContent] = replaceSupporterNamePlaceholders(
            newTargetedMessages[key].raw.content,
            newTargetedMessages[key].formatted.content,
            supporter,
        )
        const [formattedPreContent, rawPreContent] = replaceSupporterNamePlaceholders(
            newTargetedMessages[key].raw.fixed_pre_message,
            newTargetedMessages[key].formatted.fixed_pre_message,
            supporter,
        )
        const [formattedPostContent, rawPostContent] = replaceSupporterNamePlaceholders(
            newTargetedMessages[key].raw.fixed_post_message,
            newTargetedMessages[key].formatted.fixed_post_message,
            supporter,
        )
        newTargetedMessages[key] = {
            raw: {
                ...newTargetedMessages[key].raw,
                content: rawContent,
                fixed_pre_message: rawPreContent,
                fixed_post_message: rawPostContent,
            },
            formatted: {
                ...newTargetedMessages[key].formatted,
                content: formattedContent,
                fixed_pre_message: formattedPreContent,
                fixed_post_message: formattedPostContent,
            },
        }
    })
    return newTargetedMessages
}

import type { QueryFunctionContext } from "@tanstack/react-query"
import { useQuery } from "@tanstack/react-query"

import { ActionCenterSettings } from "@/types/djangio"
import { api } from "@/api"

const { GrassrootsActionCenterSettings } = DjangIO.app.grassroots.models

const grassrootsActionCenterSettingsKeys = {
    all: [{ _model: "grassrootsActionCenterSettings" }] as const,
    detail: (grassrootsActionCenterSettingsId: string) =>
        [{ ...grassrootsActionCenterSettingsKeys.all[0], grassrootsActionCenterSettingsId }] as const,
}

const fetchGrassrootsActionCenterSettings = async ({
    queryKey,
}: QueryFunctionContext<ReturnType<(typeof grassrootsActionCenterSettingsKeys)["detail"]>>) => {
    const [{ grassrootsActionCenterSettingsId }] = queryKey
    api
        .get({
            resourceUri: GrassrootsActionCenterSettings.resourceUriFromId(grassrootsActionCenterSettingsId),
        })
        .then((resp) => resp.json()) as Promise<ActionCenterSettings>
}

export const useGrassrootsActionCenterSettings = (grassrootsActionCenterSettingsId: string) =>
    useQuery({
        enabled: Boolean(grassrootsActionCenterSettingsId),
        queryKey: grassrootsActionCenterSettingsKeys.detail(grassrootsActionCenterSettingsId),
        queryFn: fetchGrassrootsActionCenterSettings,
        staleTime: Infinity,
    })

import { Row } from "react-bootstrap"
import styled from "styled-components"

import { constants } from "QuorumGrassroots/constants"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"

import { errorButtonStyling } from "QuorumGrassroots/widgets/styled-components/styles"

export const StyledCampaignListWrapper = styled(Row)`
    padding-top: 20px;
    margin: 0px;

    @media (max-width: ${constants.mobileWidth}px) {
        padding-top: 0px;
    }
`

export const StyledCampaignNoneText = styleWithOrgDesign(styled.div`
    min-height: 175px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
        ${(props) => errorButtonStyling(props)}
        width: 75%;
        text-align: center;
    }
`)

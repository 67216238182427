import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"
import * as transitions from "Components/transitions"


export const Collapse = styled.div`
    display: flex;
    flex-direction: column;
`

export const AboveFold = styled.div`
    ${rules.MediumTextRegularWeight}
    color: ${({type}) => type === "secondary" ? colors.LightPurple : colors.QuorumBlue};
    &:hover {
        color: ${({type}) => type === "secondary" ? colors.MidPurple : colors.CloudBurst};
    }

    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
        font-size: 1.5em;
    }
`

export const Caret = styled.span`
    margin-left: 5px;
    svg {
        transition: transform .1s linear;
        ${props => props.isOpen &&
            css`transform: rotate(-180deg);`
        }
    }
`

export const BelowFold = styled.div`
    margin-top: 20px;
    ${props => transitions.foldDown(props)}
`

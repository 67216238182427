import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useOnClickOutside } from "utils/hooks"

import * as S from "Components/Molecules/EmptyState/style"
import Icon from "Components/Atoms/Icon"

const EmptyState = (props) => {
    const emptyStateRef = useRef()
    useOnClickOutside(emptyStateRef, (event) => {
        if (props.onClickOutside) {
            props.onClickOutside(event)
        }
    })

    return (
        <S.EmptyState data-auto-cy="MoleculeEmptyState" ref={emptyStateRef}>
            {props.icon &&
                <S.Circle>
                    <svg height="100%" viewBox="0 0 100 100">
                        <circle cx="50%" cy="50%" r="50%"/>
                    </svg>
                    <S.IconWrapper>
                        <Icon
                            icon={props.icon}
                            iconFamily={props.iconFamily}
                            // because 140px is larger than the FontAwesome 5
                            // default (.fa-1x {font-size: 1em}) *
                            // 10 ("10x" is the max value you can pass to the size prop),
                            // we make this "1x"
                            size="1x"
                            style={{
                                // 140px is the exact font size that Grace uses in her mocks
                                // https://projects.invisionapp.com/d/main#/console/19025613/396594771/preview
                                fontSize: "140px",
                            }}
                        />
                    </S.IconWrapper>
                </S.Circle>
            }
            <S.Content>
                <S.Title>{props.empty_state_title}</S.Title>
                <S.Text>{props.empty_state_paragraph}</S.Text>
                <S.Buttons suppressExtraMargin={props.suppressExtraButtonMargin}>
                    {props.children}
                </S.Buttons>
            </S.Content>
        </S.EmptyState>
    )
}

EmptyState.defaultProps = {}

EmptyState.propTypes = {
    children: PropTypes.node,
    icon: PropTypes.string,
    iconFamily: PropTypes.string,
    empty_state_title: PropTypes.string,
    empty_state_paragraph: PropTypes.string,
    /** By default, <EmptyState> adds 11px to the right of the first button. */
    suppressExtraButtonMargin: PropTypes.bool,
}

export default EmptyState

import styled from "styled-components"

export const StyledDonationHistoryWrapper = styled.div`
    border-radius: var(--xxs, 4px);
    border: 1px solid var(--Gray-4, #cbd4dc);
    border: 1px solid var(--Gray-4, color(display-p3 0.8078 0.8314 0.8549));
    display: flex;
    padding: var(--base, 16px) var(--xl, 24px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: var(--xs, 8px);
    align-self: stretch;
    height: auto;
`

export const StyledDonationHistoryTitle = styled.h1`
    font-size: 32px;
`

export const StyledDonationHistorySectionTitle = styled.div`
    color: var(--Gray-9, var(--Gray-9, #1f2529));
    color: var(--Gray-9, var(--Gray-9, color(display-p3 0.1294 0.1451 0.1608)));
    font-feature-settings:
        "clig" off,
        "liga" off;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
`

export const StyledDonationHistorySectionContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--xl, 24px);
    align-self: stretch;
`

export const StyledDonationHistoryFirstSection = styled.div`
    display: flex;
    padding-bottom: 16px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-bottom: 1px solid var(--Gray-2, #e7ecef);
    border-bottom: 1px solid var(--Gray-2, color(display-p3 0.9137 0.9255 0.9373));
`

export const StyledDonationHistoryContainer = styled.div`
    padding-right: 30px;
    padding-left: 30px;
`

export const StyledDonationHistorySecondSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: var(--xs, 8px);
    align-self: stretch;
`

export const StyledDonationHistorySectionContentHeading = styled.div`
    color: var(--Gray-8, var(--Gray-8, #313a42));
    color: var(--Gray-8, var(--Gray-8, color(display-p3 0.2039 0.2275 0.251)));
    font-feature-settings:
        "clig" off,
        "liga" off;

    /* Heading/H5/Bold */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
`

export const StyledDonationHistorySectionContentItem = styled.div`
    display: flex;
    gap: var(--xs, 8px);
`

export const StyledDonationHistorySectionContentItemHeading = styled.div`
    color: var(--Gray-8, var(--Gray-8, #313a42));
    color: var(--Gray-8, var(--Gray-8, color(display-p3 0.2039 0.2275 0.251)));
    font-feature-settings:
        "clig" off,
        "liga" off;
    /* Heading/H5/Bold */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
`

export const StyledDonationHistorySectionContentItemLabel = styled.div`
    color: var(--Gray-9, var(--Gray-9, #1f2529));
    color: var(--Gray-9, var(--Gray-9, color(display-p3 0.1294 0.1451 0.1608)));
    font-feature-settings:
        "clig" off,
        "liga" off;

    /* Paragraph/Regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
`

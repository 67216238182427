import React from "react"
import PropTypes from "prop-types"

import { isFeatureEnabled } from "shared/featureflags/helperFunctions"

import { StyledRegionDescription } from "QuorumGrassroots/widgets/RegionList/components/style"
import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"

import RegionListLoginWrapper from "QuorumGrassroots/widgets/RegionList/components/RegionList"

export const RegionListWidget = (props) => (
    <WidgetStyleWrapper
        title={
            props.title
                ? props.title
                : DjangIO.app.grassroots.types.GrassrootsWidgetType.by_value(props.widgetType).label
        }
        useWidgetStyling
    >
        {props.description && <StyledRegionDescription dangerouslySetInnerHTML={{ __html: props.description }} />}
        {isFeatureEnabled("ff_gr_region_enhancements") && <RegionListLoginWrapper {...props} />}
    </WidgetStyleWrapper>
)

RegionListWidget.propTypes = {
    widgetType: PropTypes.string.isRequired,
    description: PropTypes.string,
    cardOrListDisplay: PropTypes.bool,
    title: PropTypes.string,
}

export default RegionListWidget

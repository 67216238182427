import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Atoms/Label/style"

const Label = ({
    dataCy,
    style,
    title,
    // passing in actual tooltip element from main app
    tooltipElement,
}) => (
    <S.Label
        data-auto-cy="AtomLabel"
        data-cy={dataCy}
        style={style}
    >
        <S.TitleText>
            {title}
        </S.TitleText>
        {
            tooltipElement &&
                <S.TooltipIcon>
                    {tooltipElement}
                </S.TooltipIcon>
        }
    </S.Label>
)

Label.defaultProps = {}

Label.propTypes = {
    dataCy: PropTypes.string,
    title: PropTypes.string,
    tooltipElement: PropTypes.element,
}

export default Label

import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { Col, Row } from "react-bootstrap"

import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { styleWithOrgDesignHelper } from "QuorumGrassroots/styled-components/helperFunctions"
import { hexCodetoRGBA } from "app/static/frontend/imports/desktopHelperFunctions"

export const StyledMemberGroupRow = styled(Row)`
    margin-bottom: 5px;
    padding-right: 15px;
    padding-left: 15px;
`

const StyledMemberLinkComponent = styled(Link)`
    &:hover {
        text-decoration: none;
    }
`
export const StyledMemberLink = styleWithOrgDesignHelper(StyledMemberLinkComponent)

const StyledOfficialThumbnailWrapperComponent = styled(Col)`
    text-align: center;
    margin-bottom: 10px;
    margin-top: 20px;
    padding: 15px;
    background-color: white;
    border: 1px solid ${(props) => props.organizationDesign.primary_color};
    border-radius: 4px;

    &:hover {
        background-color: ${(props) => hexCodetoRGBA(props.organizationDesign.primary_color, 0.2)};
    }
`
export const StyledOfficialThumbnailWrapper = styleWithOrgDesignHelper(StyledOfficialThumbnailWrapperComponent)

export const StyledOfficialThumbnail = styled.img`
    width: 100%;
    height: auto;
    max-height: 125px;
    max-width: 125px;

    border-radius: 50%;
    border: 3px solid
        ${(props) => {
            const party = DjangIO.app.person.types.Party[props.party]

            return party ? party.color : "#b6c0da"
        }};
`

export const StyledMemberName = styleWithOrgDesign(styled.div`
    font-size: 20px;
    color: ${(props) => props.organizationDesign.primary_color};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 10px;
`)

export const StyledMemberRoleType = styleWithOrgDesign(styled.div`
    font-size: 20px;
    color: ${(props) => props.organizationDesign.primary_color};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 5px;
`)

export const StyledOfficialDescription = styleWithOrgDesign(styled.div``)

export const StyledTableHeader = styled(Row)`
    font-weight: 700;
    margin: 10px 0px;
    border-bottom: 1px solid #d9d9d9;
`

export const StyledTableBody = styled(Row)`
    border-bottom: 1px solid #d9d9d9;
    margin: 10px 0px;
`

export const StyledMemberNameWithThumb = styleWithOrgDesign(styled.div`
    font-size: 20px;
    color: ${(props) => props.organizationDesign.primary_color};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
`)

export const StyledMemberInfo = styleWithOrgDesign(styled.div`
    font-size: 20px;
    color: ${(props) => props.organizationDesign.primary_color};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 50px;
`)

export const StyledOfficialMiniatureThumbnail = styled.img`
    width: 100%;
    height: auto;
    max-height: 50px;
    max-width: 50px;
    margin-right: 10px;
    margin-bottom: 5px;
    border-radius: 50%;
    border: 3px solid
        ${(props) => {
            const party = DjangIO.app.person.types.Party[props.party]
            return party ? party.color : "#b6c0da"
        }};
`

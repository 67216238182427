import React from "react"

import * as S from "Components/Compounds/Header/style"

const Header = ({ children, style }) => (
    <S.Header data-auto-cy="CompoundHeader" style={style}>
        {children}
    </S.Header>
)

export default Header

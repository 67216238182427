import React, { Component } from "react"
import PropTypes from "prop-types"

import { Helmet } from "react-helmet"
import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"

import CampaignInline from "QuorumGrassroots/widgets/CampaignList/components/CampaignInline/index"
import { StyledIssueCampaignList } from "QuorumGrassroots/widgets/Issue/components/style"

export class Issue extends Component {
    static propTypes = {
        // from initializer wrapper
        loadWidgetContent: PropTypes.func.isRequired,
        content: PropTypes.object,
        hasLoadedContent: PropTypes.bool.isRequired,
        // from page
        uniqueWidgetId: PropTypes.string.isRequired,
        id: PropTypes.number,
        // from selectors
        issueCampaigns: PropTypes.arrayOf(PropTypes.object),
        hasIssueCampaigns: PropTypes.bool.isRequired,
        pageTitle: PropTypes.string.isRequired,
        widgetWidthSize: PropTypes.number.isRequired,
    }

    static defaultProps = {
        useWidgetStyling: true,
    }

    componentDidMount() {
        if (!this.props.hasLoadedContent) {
            this.props.loadWidgetContent({
                id: this.props.id,
                resource: DjangIO.app.userdata.models.GrassrootsIssue,
                uniqueWidgetId: this.props.uniqueWidgetId,
                action: "get_grassroots_issue_detail",
                kwargs: { slug: this.props.id || this.props.match.params.slug },
            })
        }
    }

    renderCampaignList() {
        return this.props.issueCampaigns.map((campaign) =>
            campaign.id ? (
                <CampaignInline key={campaign.id} campaign={campaign} />
            ) : (
                <CampaignInline key={campaign.uri} loading />
            ),
        )
    }

    render() {
        return (
            <div className="issue-widget">
                {/* Add Helmet to change browser title of page to issue name */}
                <Helmet>
                    <title>{this.props.pageTitle}</title>
                </Helmet>
                <WidgetStyleWrapper
                    xs={12}
                    sm={12}
                    md={this.props.widgetWidthSize}
                    lg={this.props.widgetWidthSize}
                    useWidgetStyling={this.props.useWidgetStyling}
                    title={this.props.content.name || this.props.t("loading")}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: this.props.hasLoaded
                                ? this.props.content.long_description
                                : this.props.t("loading"),
                        }}
                    />
                </WidgetStyleWrapper>
                {this.props.hasIssueCampaigns && (
                    <StyledIssueCampaignList
                        xs={12}
                        sm={12}
                        md={this.props.widgetWidthSize}
                        lg={this.props.widgetWidthSize}
                    >
                        {this.renderCampaignList()}
                    </StyledIssueCampaignList>
                )}
            </div>
        )
    }
}

export default Issue

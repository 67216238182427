import React from "react"
import PropTypes from "prop-types"
import { Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import { StyledHorizontalRule } from "QuorumGrassroots/styled-components/components/StyledHorizontalRule"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import DataSection from "QuorumGrassroots/widgets/Official/components/DataSection/index"
import {
    StyledDescriptionWrapper,
    StyledDescription,
    StyledButtonWrapperCol,
} from "QuorumGrassroots/widgets/Official/components/style"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"
import { downloadPDFCrossDomain } from "imports/desktopHelperFunctions"

const RTX_OFFICIALS_PDF_S3_URL =
    "https://quorum-media.s3.amazonaws.com/media/pdfs-test/2023-08-31/14f25b7ca36b78b18a086fa281d3ab00/"

export class Official extends React.Component {
    componentDidMount() {
        if (!this.props.hasLoadedContent) {
            this.props.loadWidgetContent({
                resource: DjangIO.app.person.models.GrassrootsPerson,
                uniqueWidgetId: this.props.uniqueWidgetId,
                kwargs: { id: this.props.uniqueWidgetId },
                action: "load_official_information",
            })
        }
    }

    render() {
        if (!this.props.hasLoadedContent) {
            return <div />
        }

        const officialId = this.props.match.params.id
        return (
            <div className="official-widget">
                {/* Add Helmet to change browser title of page to official's name */}
                <Helmet>
                    <title>{this.props.pageTitle}</title>
                </Helmet>
                {this.props.descriptionText ? (
                    <StyledDescriptionWrapper>
                        <StyledDescription dangerouslySetInnerHTML={{ __html: this.props.descriptionText }} />
                        <StyledHorizontalRule />
                    </StyledDescriptionWrapper>
                ) : (
                    <StyledDescriptionWrapper />
                )}
                {this.props.memberDataSections.map((dataSection) => (
                    <DataSection
                        className="member-data-section"
                        label={dataSection.label}
                        data={dataSection.data}
                        t={this.props.t}
                    />
                ))}
                {(this.props.hasExternalInteractions || this.props.hasExternalRelationship) && (
                    <div>
                        <StyledHorizontalRule />
                        <Row>
                            {this.props.hasExternalInteractions && (
                                <StyledButtonWrapperCol
                                    md={this.props.hasInteractionAndRelationship ? 6 : 12}
                                    sm={this.props.hasInteractionAndRelationship ? 6 : 12}
                                    xs={12}
                                    twoButton={this.props.hasInteractionAndRelationship}
                                >
                                    <StyledButton
                                        marginBottom={10}
                                        href={`/log/?official=${officialId}&name=${this.props.headerTitle}`}
                                        data-cy="log-interaction"
                                    >
                                        {this.props.t("campaign.log_interaction.header.text")}
                                    </StyledButton>
                                </StyledButtonWrapperCol>
                            )}
                            {this.props.hasExternalRelationship && (
                                <StyledButtonWrapperCol
                                    md={this.props.hasInteractionAndRelationship ? 6 : 12}
                                    sm={this.props.hasInteractionAndRelationship ? 6 : 12}
                                    xs={12}
                                    twoButton={this.props.hasInteractionAndRelationship}
                                >
                                    <StyledButton
                                        marginBottom={10}
                                        href={`/relationship/?official=${officialId}&name=${this.props.headerTitle}`}
                                        data-cy="log-relationship"
                                    >
                                        {this.props.t("campaign.log_relationship.header.text")}
                                    </StyledButton>
                                </StyledButtonWrapperCol>
                            )}
                        </Row>
                        {/* Ensure this only renders for RTX (formally known as Raytheon)
                        The S3 url used is Brian Kartel's Raytheon username hashed with the day the PDFs were uploaded, followed by the official's title.
                        To update an Official's PDF the PS team must activate a download from Download Center with the desired template, download the
                        zip off of S3, then re-upload the desired Official's PDF to this file path.
                            */}
                    </div>
                )}
                {isFeatureEnabled("ff_leg_profile_pdf_download") && (
                    <Row>
                        <StyledButtonWrapperCol md={12} sm={12} xs={12}>
                            <StyledButton
                                marginBottom={10}
                                onClick={() =>
                                    downloadPDFCrossDomain(
                                        `${RTX_OFFICIALS_PDF_S3_URL}${this.props.headerTitle}.pdf`,
                                        this.props.headerTitle,
                                    )
                                }
                            >
                                Download PDF
                            </StyledButton>
                        </StyledButtonWrapperCol>
                    </Row>
                )}
            </div>
        )
    }
}

Official.propTypes = {
    params: PropTypes.object.isRequired,
    hasLoadedContent: PropTypes.bool.isRequired,
    loadWidgetContent: PropTypes.func.isRequired,
    uniqueWidgetId: PropTypes.string.isRequired,
    headerTitle: PropTypes.string,
    descriptionText: PropTypes.string,
    memberDataSections: PropTypes.arrayOf(PropTypes.object).isRequired,
    pageTitle: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    hasExternalInteractions: PropTypes.bool.isRequired,
    hasExternalRelationship: PropTypes.bool.isRequired,
    hasInteractionAndRelationship: PropTypes.bool.isRequired,
}

Official.defaultProps = {
    headerTitle: "",
    descriptionText: "",
}

export const OfficialLoginWrapper = (props) => (
    <WidgetStyleWrapper
        md={props.colWidth}
        useWidgetStyling={props.useWidgetStyling}
        title={props.hasLoadedContent ? props.headerTitle : props.t("official.header.text")}
        subtitle={props.title}
        logoImgUrl={props.memberImgUrl}
        logoImgBorderColor={props.partyColor}
        headerColored
    >
        <Official {...props} />
    </WidgetStyleWrapper>
)

OfficialLoginWrapper.propTypes = {
    t: PropTypes.func.isRequired,
    hasLoadedContent: PropTypes.bool.isRequired,
    useWidgetStyling: PropTypes.bool,
    colWidth: PropTypes.number,
    registrationPageIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    headerTitle: PropTypes.string,
    title: PropTypes.string,
    memberImgUrl: PropTypes.string,
    partyColor: PropTypes.string.isRequired,
}

OfficialLoginWrapper.defaultProps = {
    colWidth: 12,
    useWidgetStyling: true,
    headerTitle: "",
    title: "",
    memberImgUrl: "",
}

export default OfficialLoginWrapper

import { connect } from "react-redux"

import { CombinedCampaignForm } from "QuorumGrassroots/campaign-forms/components/CombinedCampaignForm"
import { getMessageFormWrapper } from "QuorumGrassroots/campaign-forms/wrappers/GetMessagesFormWrapper"
import { combinedCampaignSelectors } from "QuorumGrassroots/campaign-forms/selectors/combined-campaign-selectors"
import { generateCombinedCampaignActions } from "QuorumGrassroots/campaign-forms/grassrootsActionGenerators"

const messageFormWrapper = getMessageFormWrapper({
    formName: "combined_campaign",
    getKwargs: {},
    generateGrassrootsActions: generateCombinedCampaignActions,
    getIdFromMessage: (message) => {
        const targetId = message.target ? message.target.id : ""
        return `${message.campaign_id}_${targetId}`
    },
    isCombinedCampaign: true,
})(CombinedCampaignForm)

export default connect(combinedCampaignSelectors, null)(messageFormWrapper)

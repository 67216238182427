import { createSelector, createStructuredSelector } from "reselect"
import createCachedSelector from "re-reselect"
import * as socialSelectors from "shared/social/social-user-selectors"

const selectApiCache = state => state.apiCache

export const selectCache = state => selectApiCache(state) && selectApiCache(state).get("cache")
const selectResourceUpdateTimestamps = state => selectApiCache(state).get("resourceUpdateTimestamps")

export const selectProps = (state, props) => props

const makePropSelector = key => createSelector(
    selectProps,
    props => props && props[key]
)

const selectUrl = makePropSelector("uri")
const selectResource = makePropSelector("resource")
const selectDatumId = makePropSelector("datumId")

export const readFromCache = (cache, url) => {
    // Convert https://server.com/api/person/... => /api/person/...
    const cleanUrl = (global.Config && global.Config.MOBILE_SERVER)
        ? url.replace(global.Config.MOBILE_SERVER, "")
        : url
    const cachedResponse = (cache && cache.get(cleanUrl)) || {}
    return (cachedResponse && cachedResponse.toJS && cachedResponse.toJS()) || cachedResponse
}

export const selectCachedResponse = url => createSelector(
    selectCache,
    cache => readFromCache(cache, url)
)

export const selectItem = createSelector(
    [
        selectUrl,
        selectResource,
        selectDatumId,
        selectCache,
    ],
    (url, resource, datumId, cache) => {
        if (url) {
            return readFromCache(cache, url)
        } else if (resource && datumId) {
            return readFromCache(cache, resource.foreignKey(datumId))
        } else {
            return {}
        }
    }
)

export const selectCustomData = createSelector(
    selectItem,
    (item) => {
        // PublicOrganization has tag_dict as a field, but we transitioned to use
        // DataDescription to hold the tag_dict for PublicOrganization Spring 2022.
        // Since then, the tag_dict on the model should be ignored.
        // TODO: When deprecating the tag_dict field on PublicOrganization, this check should be removed.
        if (item.tag_dict && !item.resource_uri.includes("publicorganization")) {
            return Object.entries(item.tag_dict).reduce((customDataDict, [key, value]) => {
                customDataDict[key] = value
                return customDataDict
            }, {})
        } else {
            return {}
        }
    }
)

export const selectInitialValuesForForm = (modelName, selector) => createSelector(
    selectItem,
    selectCustomData,
    socialSelectors.selectSocialInitialValues(modelName, selector),
    (item, customData, socialUsers) => ({ ...item, ...customData, socialUsers })
)

export const selectItemIsLoading = createSelector(
    selectItem,
    item => item.isLoading !== false
)

// For use outside of redux/reselect
export const getCachedResponse = url => readFromCache(selectCache(window.store.getState()), url)

export const itemConnection = createStructuredSelector({
    item: selectItem,
    itemIsLoading: selectItemIsLoading,
    datumId: selectDatumId,
})

// this is a way for ListViews to keep track of whether or not they should refresh
// data. If a user has recently updated an interaction / contact / organization,
// then the Note / Supporter / Organization ListViews should refresh if popped back to.
export const selectLastResourceUpdateTimestamp = createCachedSelector(
    [
        selectResourceUpdateTimestamps,
        (state, props) => props.resource,
    ],
    (updatedItems, resource) => updatedItems.get(resource.endpoint)
)(
    (state, props) => props.uniqueFlvId
)


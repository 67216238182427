// https://projects.invisionapp.com/d/main/#/console/18861190/444895171/inspect
// https://projects.invisionapp.com/share/H4102MKAXEYZ#/screens/444515231

import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

import { ButtonContainer } from "Components/Molecules/Button/style"

export const Popdown = styled.div`
    overflow: auto;
    // top, right, bottom, left
    padding: 0 11.5px 0 15.5px;
`

export const PopdownTopBarContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    &:first-of-type {
        margin-top: 9px;
    }

`

export const PopdownTitle = styled.span`
    ${rules.SmallTitle}
    color: ${colors.gray9};
    letter-spacing: 0;
    line-height: 1.75rem;
    font-size: 1.25rem;
`

export const PopdownGridContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`

export const PopdownGrid = styled.div`
    display: grid;
    gap: 17.5px;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 19px;

    @media (max-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }
`

export const PopDownGridItemSearch = styled.div`
    align-items: center;
    background-color: ${colors.DarkerPorcelain};
    bottom: 7px;
    box-shadow: -6px -4px 6px ${colors.DarkerPorcelain};
    // rendered on :hover in PopdownGridItem
    display: none;
    position: absolute;
    right: 10px;
`

export const PopDownGridItemSearchLabel = styled.span`
    ${rules.SmallTextRegularWeight}
    color: ${colors.QuorumBlue};
    letter-spacing: 0;
    margin-right: 2px;
`

export const PopdownGridItem = styled.div`
    ${rules.CardStyle}
    min-height: 116px;
    // top, right, bottom, left
    padding: 12px 16px;
    position: relative;
    border-radius: 4px;
    border: 1px solid ${colors.gray5};
    box-shadow: 0px 4px 8px -4px #00000029;

    &:hover {
        background-color: ${colors.DarkerPorcelain};
        cursor: pointer;

        ${PopDownGridItemSearch} {
            display: flex;
        }
    }

`

export const PopdownGridItemFirstLine = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

export const PopdownGridItemFirstLineIconLabel = styled.div`
    display: flex;
    align-items: center;
    color: ${colors.violet7};
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.4rem;
`

export const PopDownGridItemFirstLineLabel = styled.span`
    ${rules.LargeText}
    color: ${colors.violet7};
    letter-spacing: 0;
    margin-left: 12px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.4rem;
`

export const PopDownGridItemFirstLineDefault = styled.div`
    align-items: center;
    display: flex;
    font-size: .875rem;
    font-weight: 400;
`

export const PopDownGridItemFirstLineDefaultLabel = styled.span`
    ${rules.SmallTextLightWeight}
    color: ${colors.gray7};
    letter-spacing: 0;
    margin-left: 6px;
    font-size: .875rem;
    line-height: .875rem;
`

export const PopdownGridItemSecondLine = styled.div`
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    margin-top: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const PopDownGridItemSecondLineLabel = styled.span`
    ${rules.SmallTextLightWeight}
    color: ${colors.gray7};
    letter-spacing: 0;
    line-height: 1.3;
    font-size: .875rem;
    font-weight: 400;
`

export const PopdownSeeMore = styled.span`
    cursor: pointer;
    margin: 27px 0;

    &:hover {
        background-color: ${colors.AquaHaze};
    }
`

export const PopdownSeeMoreLabel = styled.span`
    ${rules.SmallTextSmallHeightRegularWeight}
    color: ${colors.violet7};
    letter-spacing: 0;
    margin-right: 6px;
    font-size: 1rem;
    line-height: 1rem;
`

export const AdvancedSearch = styled.div`
    align-items: center;
    margin-top: 20px;
    min-height: 272px;
    width: 100%;

    ${ButtonContainer} {
        margin-top: 20px;
    }
`

export const AdvancedSearchObject = styled.object`
    width: 100%;
`

export const AdvancedSearchImage = styled.img`
    width: 100%;
`

export const AdvancedSearchText = styled.p`
    ${rules.MediumTextRegularWeight}
    color: ${colors.QuorumBlue};
    margin-bottom: 18px;
`

export const AdvancedSearchCenterButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`

export const AiSearchPopdown = styled.div`
    ${rules.SmallTextRegularWeight}
    align-items: center;
    color: ${colors.QuorumBlue};
    display: flex;
    justify-content: center;
    min-height: 521px;
    white-space: pre-wrap;
`

export const AiSearchMain = styled.div`
    width: 800px;
`


export const AiSearchHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 16px 0;
    color: ${colors.gray7};
`

export const AiSearchHeaderFirstRow = styled.div`
    display: flex;
    justify-content: space-between;
`

export const AiSearchTitle = styled.div`
    ${rules.MediumTextMediumWeight}
    color: ${colors.gray9};
`

export const AiSearchBody = styled.div`
    padding: 20px 24px 0;
    background-color: ${colors.gray0};
    border-radius: 16px;
    margin-bottom: 24px;
`

export const AiSearchBodyExampleItem = styled.div`
    align-items: center;
    background-color: ${colors.White};
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 8px 6px 8px 16px;
    color: ${colors.gray7};

    transition: box-shadow .1s;

    // we use opacity to get these nice transitions
    // https://stackoverflow.com/questions/27900053/css-transition-with-visibility-not-working
    ${ButtonContainer} {
        opacity: 0;
        transition: opacity .1s;
    }

    &:hover {
        box-shadow: 0px 3px 5px ${colors.LightGray};

        ${ButtonContainer} {
            opacity: 1;
        }
    }
`

export const AiSearchExampleDescription = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`

export const AiSearchBodyInfo = styled.div`
    background-color: ${colors.violet1};
    color: ${colors.gray7};
    border-radius: 4px;
    margin: 24px 0;
    padding: 16px;
`

export const AiSearchBodyToggle = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
`

export const AiSearchAlertBody = styled.div`
    display: flex;
    gap: 48px;
`

export const AiSearchAlertImage = styled.img`
    height: 200px;
`

export const AiSearchAlertText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: ${colors.gray7};

    li {
        line-height: 200%;
    }
`

export const AiSearchAlertButtons = styled.div`
    display: flex;
    gap: 12px;
`

export const AiSearchLoadingContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const AiSearchLoadingText = styled.div`
    ${rules.SmallMediumText}
    color: ${colors.InputTextColor};
`

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/SelectableDate/style"

import Icon from "Components/Atoms/Icon"

const SelectableDate = ({
    dataCy,
    // moment object
    deserializedValue,
    initializeDatePicker,
    onBlur,
    onClick,
    onFocus,
    placeholder,
    type,
    value,
}) => {
    return (
        <S.SelectableDate
            data-auto-cy="MoleculeSelectableDate"
            data-cy={dataCy}
            deserializedValue={deserializedValue}
        >
            <S.Input
                onBlur={onBlur}
                onFocus={onFocus}
                placeholder={placeholder}
                ref={initializeDatePicker}
                type={type}
                // should not be defaultValue or readOnly
                value={value}
            />
            { deserializedValue &&
                <S.ClearIconContainer
                    onClick={onClick}
                >
                    <Icon
                        fixedWidth={false}
                        icon="times"
                        iconFamily="fal"
                    />
                </S.ClearIconContainer>
            }
        </S.SelectableDate>
    )
}

SelectableDate.defaultProps = {
    placeholder: "mm/dd/yyyy",
    type: "text",
    value: "",
}

SelectableDate.propTypes = {
    dataCy: PropTypes.string,
    deserializedValue: PropTypes.object,
    initializeDatePicker: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
}

export default SelectableDate

import React from "react"
import styled from "styled-components"

const LeftWrapper = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
`

export const NavLeft = ({ isFragment, children }) => {
    if (!isFragment) {
        return <LeftWrapper>{children}</LeftWrapper>
    }

    return children
}

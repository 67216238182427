import React from "react"

import styled from "styled-components"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"

import CustomEventDetails from "quorum/static/frontend/custom-event/components/CustomEventDetails"

export const StyledCustomEventDetailsWrapper = styleWithOrgDesign(styled.div`
    margin: 0px 15px 15px 15px;

    .add-to-calendar-hr {
        border-top: 1px solid ${(props) => props.organizationDesign.primary_color};
        margin-left: 10px;
        margin-right: 10px;
    }
`)

export const StyledCustomEventDetails = styleWithOrgDesign(
    styled((props) => <CustomEventDetails primaryColor={props.organizationDesign.primary_color} {...props} />)``, // Empty styling string. Doesn't seem to accept any styling, place style in StyledCustomEventDetailsWrapper
)

// If the form is still loading, simply set 'display: none'.
// We want to still render the formso ReduxForm's validation still functions correctly
export const StyledEventForm = styled.div`
    ${(props) => props.contentLoading && `display: none;`}
`

export const StyledCustomFieldFormSection = styled.div`
    ${(props) =>
        props.indented &&
        `
        margin-left: 20px;
        margin-right: 20px;
    `}
`

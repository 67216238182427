import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"

import EventWidget from "QuorumGrassroots/widgets/Event/components/index"

import initializer from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/InitializeStoreWrapper"

import { rsvpYesAndSubmit } from "QuorumGrassroots/widgets/Event/action-creators"
import { resetSubmittedWidget } from "QuorumGrassroots/widgets/action-creators"

import { eventWidgetConnection } from "QuorumGrassroots/widgets/Event/selectors"

const actions = {
    resetSubmittedWidget,
    rsvpYesAndSubmit,
}

const connectedEvent = connect(eventWidgetConnection, actions)(EventWidget)

const initializedCampaign = initializer(connectedEvent)

export default withNamespaces()(initializedCampaign)

import { resourceGetList } from "shared/djangio/action-creators"
import { loadAdditionalCampaignsTypes } from "QuorumGrassroots/framework/action-types"
import { selectCampaignListAmount } from "QuorumGrassroots/framework/selectors"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"

export const loadAdditionalCampaigns = () => (dispatch, getState) => {
    const campaignListAmount = selectCampaignListAmount(getState())

    const resource = DjangIO.app.grassroots.campaign.models.GrassrootsCampaign
    const limit = DjangIO.app.grassroots.types.GrassrootsContextField.campaign_list.pagination_amount

    return dispatch(
        resourceGetList(resource, loadAdditionalCampaignsTypes, {
            limit,
            offset: campaignListAmount,
            order_by: "-order",
        }),
    )
}

import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { withNamespaces } from "react-i18next"

import { selectIndexRoute } from "QuorumGrassroots/framework/selectors"

import { StyledPage } from "QuorumGrassroots/framework/components/Page/style"
import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import { Styled404BackButton } from "QuorumGrassroots/framework/components/404/style"

const Page404 = (props) => (
    <StyledPage className="page">
        <WidgetStyleWrapper className="widget" title={props.t("404.not_found")} useWidgetStyling>
            <Link to={props.indexRoute} href={props.indexRoute}>
                <Styled404BackButton>
                    <i className="fa fa-arrow-left" />
                    {props.t("campaign.thanks.back.homepage")}
                </Styled404BackButton>
            </Link>
        </WidgetStyleWrapper>
    </StyledPage>
)

const mapStateToProps = (state, props) => ({
    indexRoute: selectIndexRoute(state, props),
})

export default withNamespaces()(connect(mapStateToProps, null)(Page404))

import styled from "styled-components"

import * as rules from "Components/rules"

export const ImageArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    height: ${({diameter}) => diameter};
    width: ${({diameter}) => diameter};
    border-radius: ${({dynamicHover}) => dynamicHover ? "175px" : "50%"};
    border: 2px solid ${({circleColor}) => circleColor};
    overflow: hidden;
    opacity: ${({opacity}) => opacity !== undefined ? opacity : "1"};

    cursor: ${({cursor}) => cursor};

    background-color: ${({fillCircle, fillColor, circleColor}) => {
        if (fillColor) {
            return `${fillColor}`
        }
        else if (fillCircle) {
            return `${circleColor}`
        }
        else {
            return `transparent`
        }
    }};

    font-size: ${({fontSize}) => fontSize};
    color: ${({fontColor}) => fontColor};
    ${({boldText}) => boldText ? rules.MediumWeight : rules.RegularWeight};
    transition: 0.05s;
    &:after {
        content: ${({diameter}) => diameter};
    }
    &:hover {
        width: ${({hoverWidth}) => hoverWidth};
    }
    &:hover:after {
        content: ${({diameter}) => diameter};
    }
`

export const Img = styled.img`
    height: 100%;
    width: auto;
`

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/SelectableSubheader/style"

import Highlight from "Components/Atoms/Highlight"
import Icon from "Components/Atoms/Icon"

const SelectableSubheader = ({
    children,
    dataCy,
    icon,
    iconColor,
    iconFamily,
    isCollapsedGroup,
    isGroup,
    invisible,
    onClick,
    searchFilterTerm,
    title,
}) => (
    <S.SelectableSubheader
        data-auto-cy="MoleculeSelectableSubheader"
        data-cy={dataCy}
        isCollapsedGroup={isCollapsedGroup}
        isGroup={isGroup}
        invisible={invisible}
        onClick={onClick}
    >
        {
            isGroup && (
                <S.StyledIcon
                    data-cy="styled-icon"
                    iconColor={iconColor}
                    // for accessibility
                    aria-label={title}
                    onKeyDown={
                        e => e.key === "Enter" && onClick && onClick()
                    }
                    role="checkbox"
                    tabIndex={0}
                >
                    <Icon
                        fixedWidth={false}
                        icon={icon}
                        iconFamily={iconFamily}
                    />
                </S.StyledIcon>
            )
        }
        <S.StyledTitle>
            {
                <Highlight
                    highlight={searchFilterTerm}
                    text={title}
                />
            }
        </S.StyledTitle>
        <S.TooltipIcon>
            {children}
        </S.TooltipIcon>
    </S.SelectableSubheader>
)

SelectableSubheader.defaultProps = {
    isCollapsedGroup: false,
    isGroup: false,
}

SelectableSubheader.propTypes = {
    children: PropTypes.element,
    dataCy: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    iconFamily: PropTypes.string,
    isCollapsedGroup: PropTypes.bool,
    isGroup: PropTypes.bool,
    onClick: PropTypes.func,
    // the FilterGroup/Filter search term from app/static/frontend/search-new/components/FilterSet/index.js SearchFiltersTextareaFilter
    // passed from app/static/frontend/search-new/components/FilterGroup/index.js FilterComponent
    // through
    // app/static/frontend/search-new/components/Filter/FilterHeader.js
    // app/static/frontend/search-new/components/Filter/EnumFilter/EnumGroupHeader.js
    searchFilterTerm: PropTypes.string,
    title: PropTypes.string,
}

export default SelectableSubheader

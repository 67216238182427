const PREFIX = "@@customfields/"

const actionTypes = {
    GET_ORGANIZATION_CUSTOM_FIELDS_START: `${PREFIX}GET_ORGANIZATION_CUSTOM_FIELDS_START`,
    GET_ORGANIZATION_CUSTOM_FIELDS_SUCCESS: `${PREFIX}GET_ORGANIZATION_CUSTOM_FIELDS_SUCCESS`,
    GET_ORGANIZATION_CUSTOM_FIELDS_FAIL: `${PREFIX}GET_ORGANIZATION_CUSTOM_FIELDS_FAIL`,

    GET_SUPPORTER_CUSTOM_FIELDS_START: `${PREFIX}GET_SUPPORTER_CUSTOM_FIELDS_START`,
    GET_SUPPORTER_CUSTOM_FIELDS_SUCCESS: `${PREFIX}GET_SUPPORTER_CUSTOM_FIELDS_SUCCESS`,
    GET_SUPPORTER_CUSTOM_FIELDS_FAIL: `${PREFIX}GET_SUPPORTER_CUSTOM_FIELDS_FAIL`,

    GET_OFFICIAL_CUSTOM_FIELDS_START: `${PREFIX}GET_OFFICIAL_CUSTOM_FIELDS_START`,
    GET_OFFICIAL_CUSTOM_FIELDS_SUCCESS: `${PREFIX}GET_OFFICIAL_CUSTOM_FIELDS_SUCCESS`,
    GET_OFFICIAL_CUSTOM_FIELDS_FAIL: `${PREFIX}GET_OFFICIAL_CUSTOM_FIELDS_FAIL`,

    GET_CUSTOM_FIELDS_START: "GET_CUSTOM_FIELDS_START",
    GET_CUSTOM_FIELDS_SUCCESS: "GET_CUSTOM_FIELDS_SUCCESS",
    GET_CUSTOM_FIELDS_FAIL: "GET_CUSTOM_FIELDS_FAIL",

    UPDATE_CUSTOM_FIELD_START: "UPDATE_CUSTOM_FIELD_START",
    UPDATE_CUSTOM_FIELD_SUCCESS: "UPDATE_CUSTOM_FIELD_SUCCESS",
    UPDATE_CUSTOM_FIELD_FAIL: "UPDATE_CUSTOM_FIELD_FAIL",

    GET_CUSTOM_FIELD_START: "GET_CUSTOM_FIELD_START",
    GET_CUSTOM_FIELD_SUCCESS: "GET_CUSTOM_FIELD_SUCCESS",
    GET_CUSTOM_FIELD_FAIL: "GET_CUSTOM_FIELD_FAIL",

    POST_CUSTOM_FIELD_START: "POST_CUSTOM_FIELD_START",
    POST_CUSTOM_FIELD_SUCCESS: "POST_CUSTOM_FIELD_SUCCESS",
    POST_CUSTOM_FIELD_FAIL: "POST_CUSTOM_FIELD_FAIL",

    DELETE_CUSTOM_FIELD_START: "DELETE_CUSTOM_FIELD_START",
    DELETE_CUSTOM_FIELD_SUCCESS: "DELETE_CUSTOM_FIELD_SUCCESS",
    DELETE_CUSTOM_FIELD_FAIL: "DELETE_CUSTOM_FIELD_FAIL",

    CHANGE_CUSTOM_FIELD: "CHANGE_CUSTOM_FIELD",
    CLEAR_CUSTOM_FIELD: "CLEAR_CUSTOM_FIELD",

    UPDATE_OPTION_VALUE: "UPDATE_OPTION_VALUE",
    DELETE_OPTION_FIELD: "DELETE_OPTION_FIELD",
    REORDER_OPTION_FIELD: "REORDER_OPTION_FIELD",
    ALPHABETIZE_OPTION_FIELD: "ALPHABETIZE_OPTION_FIELD",

    CHANGE_CURRENT_TAB: "CHANGE_CURRENT_TAB",
    SHEET_REMOVE_ROW: "SHEET_REMOVE_ROW",
}

export const getOrganizationCustomFieldsTypes = {
    start: actionTypes.GET_ORGANIZATION_CUSTOM_FIELDS_START,
    success: actionTypes.GET_ORGANIZATION_CUSTOM_FIELDS_SUCCESS,
    fail: actionTypes.GET_ORGANIZATION_CUSTOM_FIELDS_FAIL,
}

export const getSupporterCustomFieldsTypes = {
    start: actionTypes.GET_SUPPORTER_CUSTOM_FIELDS_START,
    success: actionTypes.GET_SUPPORTER_CUSTOM_FIELDS_SUCCESS,
    fail: actionTypes.GET_SUPPORTER_CUSTOM_FIELDS_FAIL,
}
export const getOfficialCustomFieldsTypes = {
    start: actionTypes.GET_OFFICIAL_CUSTOM_FIELDS_START,
    success: actionTypes.GET_OFFICIAL_CUSTOM_FIELDS_SUCCESS,
    fail: actionTypes.GET_OFFICIAL_CUSTOM_FIELDS_FAIL,
}

export const getCustomFieldsTypes = {
    start: actionTypes.GET_CUSTOM_FIELDS_START,
    success: actionTypes.GET_CUSTOM_FIELDS_SUCCESS,
    fail: actionTypes.GET_CUSTOM_FIELDS_FAIL
}

export const changeCustomFields = {
    start: actionTypes.CHANGE_CUSTOM_FIELDS_START,
    success: actionTypes.CHANGE_CUSTOM_FIELDS_SUCCESS,
    fail: actionTypes.CHANGE_CUSTOM_FIELDS_FAIL
}

export const updateCustomFields = {
    start: actionTypes.UPDATE_CUSTOM_FIELD_START,
    success: actionTypes.UPDATE_CUSTOM_FIELD_SUCCESS,
    fail: actionTypes.UPDATE_CUSTOM_FIELD_FAIL
}

export const loadCustomField = {
    start: actionTypes.GET_CUSTOM_FIELD_START,
    success: actionTypes.GET_CUSTOM_FIELD_SUCCESS,
    fail: actionTypes.GET_CUSTOM_FIELD_FAIL
}

export const createCustomField = {
    start: actionTypes.POST_CUSTOM_FIELD_START,
    success: actionTypes.POST_CUSTOM_FIELD_SUCCESS,
    fail: actionTypes.POST_CUSTOM_FIELD_FAIL
}

export const deleteCustomField = {
    start: actionTypes.DELETE_CUSTOM_FIELD_START,
    success: actionTypes.DELETE_CUSTOM_FIELD_SUCCESS,
    fail: actionTypes.DELETE_CUSTOM_FIELD_FAIL
}

export default actionTypes

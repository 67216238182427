import styled from "styled-components"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import {
    styleInMobileScreenSize,
    getFontColorForBackgroundContrast,
} from "QuorumGrassroots/styled-components/helperFunctions"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"

export const StyledCard = styleWithOrgDesign(styled.div`
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px rgba(64, 64, 64, 0.1);
    display: flex;
    margin: 9px;
    ${(props) => (props.isSingle ? "flex-direction: row;" : "flex-direction: column;")}
    ${(props) =>
        !props.useOrgDesign &&
        `
        width: ${DjangIO.app.grassroots.enums.CardsSectionLayoutType.by_value(props.layout).card_width}px;

        ${styleInMobileScreenSize(`
            width: 350px;
            flex-direction: column;
            max-width: 100%;
        `)}
    `}
    ${(props) =>
        props.useOrgDesign &&
        `
        border: 1px solid ${props.organizationDesign.primary_color};
        flex: 1;
        padding: 40px 30px;

        ${styleInMobileScreenSize(`
            width: 100%;
            flex-direction: column;
        `)}
    `}
    ${(props) =>
        props.useCardColor &&
        `
        background-color: ${props.cardColor};
        color: ${getFontColorForBackgroundContrast(props.cardColor)};
    `}
    ${(props) =>
        props.imageCard &&
        !props.imageContain &&
        `
        background-image: url("${props.imagePath}");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 40vh;
    `}
    ${(props) =>
        props.hidden &&
        `
        visibility: hidden;
    `}
`)

export const StyledCardTitle = styled.div`
    font-size: 30px;
    line-height: 36px;
    ${(props) => !props.isSingle && "align-self: center;"}
`

export const StyledCardContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    ${(props) =>
        props.isSingle &&
        `
        align-items: flex-start;
        justify-content: center;
        padding-left: 24px;
        padding-right: 24px;
        width: auto;
        height: 100%;
    `}
    ${(props) =>
        props.imageCard &&
        `
        color: white;
        justify-content: center;
        position: absolute;
        height: 100%;
        width: 100%;
    `}
     ${(props) =>
        props.isClickable &&
        `
        &:hover {
            text-decoration: none;
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.1);
        };
    `}
    ${(props) =>
        !props.useOrgDesign &&
        `
        padding-top: 24px;
        padding-bottom: 24px;
        padding-left: 20px;
        padding-right: 20px;
    `}
`

export const StyledCardButton = styled(StyledButton)`
    display: flex;
    width: auto;
    ${(props) =>
        !props.isSingle &&
        `
        align-self: center;
        margin-top: auto;
    `}
`

export const StyledCardDescription = styled.div`
    margin-top: 24px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 24px;
`

export const StyledCardsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    ${styleInMobileScreenSize(`
        align-items: center;
        flex-direction: column;
    `)}
    ${(props) =>
        props.useOrgDesign &&
        `
        padding-left: 10px;
        padding-right: 10px;

        ${styleInMobileScreenSize(`
            padding-left: 20px;
            padding-right: 20px;
        `)}
    `}
`

export const StyledTitle = styled.div`
    align-self: flex-start;
    margin-bottom: 9px;
    margin-left: 9px;
    font-size: 30px;
    line-height: 36px;
    ${(props) => props.useBackgroundColor && "color: white;"}
    ${(props) =>
        props.useOrgDesign &&
        `
        margin-left: 19px;

        ${styleInMobileScreenSize(`
            margin-left: 20px;
        `)}
    `}
`

export const StyledCardImage = styled.div`
    background-image: url("${(props) => props.imagePath}");
    background-repeat: no-repeat;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: 390px;
    ${styleInMobileScreenSize(`
        width: 100%;
        height: 210px;
    `)}
    ${(props) => (props.isSingle ? "width: 50%;" : "width: 100%;")}
    ${(props) =>
        props.imageContain
            ? `
            background-size: contain;
            ${props.isSingle ? "background-position: center;" : "background-position: top;"}
        `
            : `
            background-size: cover;
            background-position: center;
        `}
`

export const StyledCardImg = styled.img`
    ${(props) =>
        props.isSingle
            ? `
            max-width: 50%;
            max-height: 100%;
        `
            : `
            max-width: 100%;
            max-height: 50%;
        `}
    ${(props) =>
        props.imageCard &&
        `
        height: auto;
        max-height: 100%;
        width: 100%;
    `}
`

export const CardInteriorWrapper = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;

    ${(props) => (props.isSingle ? "flex-direction: row;" : "flex-direction: column;")}
    ${(props) =>
        props.imageCard &&
        `
        position: relative;
    `}
`

export const StyledCardStatNumber = styleWithOrgDesign(styled.div`
    color: ${(props) => props.organizationDesign.primary_color};
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 30px;
`)

export const StyledCardStatDescription = styleWithOrgDesign(styled.div`
    color: ${(props) => props.organizationDesign.primary_color};
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
`)

export const StyledImageIcon = styled.img`
    width: 100%;
    height: auto;
    max-height: 125px;
    max-width: 125px;
`

export const StyledIcon = styled.i`
    font-size: 40px;
`

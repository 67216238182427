import React from "react"
import PropTypes from "prop-types"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { ICON_SIZES } from "utils/useFontAwesome"

const Icon = React.forwardRef(
    (
        {
            ariaHidden,
            ariaLabel,
            className,
            color,
            dataCy,
            fixedWidth,
            flip,
            icon,
            iconFamily,
            id,
            onClick,
            rotation,
            size,
            spin,
            style,
            title,
        },
        ref
    ) => (
        <FontAwesomeIcon
            // If an icon title or aria-label is passed in, we want them to be
            // visible in the accessibility tree
            aria-hidden={ariaLabel || title ? "false" : ariaHidden}
            aria-label={ariaLabel}
            data-auto-cy="AtomIcon"
            className={className}
            color={color}
            data-cy={dataCy}
            fixedWidth={typeof fixedWidth === "undefined" ? true : fixedWidth}
            flip={flip}
            icon={iconFamily ? [iconFamily, icon] : icon}
            id={id || "ds-icon"}
            onClick={onClick}
            ref={ref}
            rotation={rotation}
            size={size}
            spin={spin}
            style={style}
            title={title}
        />
    )
)

Icon.propTypes = {
    // Font Awesome icons' default is aria-hidden="true" so passing in `false`
    // can be used so screen readers can read an aria-label.
    ariaHidden: PropTypes.string,
    // visually hidden text for screen readers to read
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
    // the CSS3 color
    color: PropTypes.string,
    // the Cypress data-cy attribute that we will use in E2E tests
    dataCy: PropTypes.string,
    // whether or not the icon should use fa-fw and therefore have a fixed width
    fixedWidth: PropTypes.bool,
    // which direction the icon should be flipped
    flip: PropTypes.oneOf(["horizontal", "vertical", "both"]),
    // name of font awesome 5 icon
    icon: PropTypes.string.isRequired,
    // name of font awesome 5 icon family
    iconFamily: PropTypes.string,
    // optional id for the Icon
    id: PropTypes.string,
    // optional onClick for when the icon is clicked
    onClick: PropTypes.func,
    // degrees clockwise to rotate the icon
    rotation: PropTypes.oneOf([90, 180, 270]),
    // size of the icon
    // ["lg","xs","sm","1x","2x","3x","4x","5x","6x","7x","8x","9x","10x"]
    size: PropTypes.oneOf(ICON_SIZES),
    // whether or not the icon should spin clockwise
    spin: PropTypes.bool,
    // "Note that icon size can be controlled with the CSS font-size attribute,
    // and FontAwesomeIcon's size prop determines icon size relative to the current font-size."
    // https://fontawesome.com/how-to-use/on-the-web/styling/sizing-icons
    // this is necessary for cases where we need a font size that is larger than the
    // default (.fa-1x {font-size: 1em}) *
    // 10 ("10x" is the max value you can pass to the size prop),
    style: PropTypes.object,
    // text used to label the icon for accessibility
    title: PropTypes.string,
}

Icon.defaultProps = {
    // maintains the FontAwesomeIcon default of aria-hidden="true"
    ariaHidden: "true",
    spin: false,
    // default to null so that Jest snapshots don't render titles with dynamically set IDs
    title: null,
}

export default Icon

import {
    baseCampaignFormWrapper,
    baseCampaignFormWrapperWithoutThankable,
} from "QuorumGrassroots/campaign-forms/wrappers/BaseCampaignFormWrapper"

import CallFormWrapper from "QuorumGrassroots/campaign-forms/containers/CallForm"
import CombinedCampaignForm from "QuorumGrassroots/campaign-forms/containers/CombinedCampaignForm"
import GotvForm from "QuorumGrassroots/campaign-forms/containers/GotvForm"
import LogInteractionForm from "QuorumGrassroots/campaign-forms/containers/LogInteractionForm"
import LogRelationshipForm from "QuorumGrassroots/campaign-forms/containers/LogRelationshipForm"
import PetitionForm from "QuorumGrassroots/campaign-forms/containers/PetitionForm"
import RegulationCommentForm from "QuorumGrassroots/campaign-forms/containers/RegulationCommentForm"
import ShareLinkForm from "QuorumGrassroots/campaign-forms/containers/ShareLinkForm"
import TweetForm from "QuorumGrassroots/campaign-forms/containers/TweetForm"
import VisitOfficeForm from "QuorumGrassroots/campaign-forms/containers/VisitOfficeForm"
import WriteMemberForm from "QuorumGrassroots/campaign-forms/containers/WriteMemberForm"
import WriteWhiteHouseForm from "QuorumGrassroots/campaign-forms/containers/WriteWhiteHouseForm"
import VoterRegistrationForm from "QuorumGrassroots/campaign-forms/containers/VoterRegistrationForm"
import ElectionInfoForm from "QuorumGrassroots/campaign-forms/containers/ElectionInfoForm"
import { MultiAction } from "QuorumGrassroots/campaign-forms/components/MultiAction"
import { StoryForm } from "QuorumGrassroots/campaign-forms/components/StoryForm/StoryForm"

const { CampaignType } = DjangIO.app.grassroots.campaign.types

export const formMap = {
    [CampaignType.write_member.value]: baseCampaignFormWrapper(WriteMemberForm),
    [CampaignType.visit.value]: baseCampaignFormWrapper(VisitOfficeForm),
    [CampaignType.call.value]: baseCampaignFormWrapper(CallFormWrapper),
    [CampaignType.petition.value]: baseCampaignFormWrapper(PetitionForm),
    [CampaignType.tweet_at_member.value]: baseCampaignFormWrapper(TweetForm),
    [CampaignType.share.value]: baseCampaignFormWrapper(ShareLinkForm),
    [CampaignType.comment_on_regulation.value]: baseCampaignFormWrapper(RegulationCommentForm),
    [CampaignType.grassroots_story.value]: baseCampaignFormWrapperWithoutThankable(StoryForm),
    [CampaignType.write_white_house.value]: baseCampaignFormWrapper(WriteWhiteHouseForm),
    [CampaignType.combined_campaign.value]: baseCampaignFormWrapper(CombinedCampaignForm),
    [CampaignType.log_interaction.value]: baseCampaignFormWrapper(LogInteractionForm),
    [CampaignType.log_relationship.value]: baseCampaignFormWrapper(LogRelationshipForm),
    [CampaignType.gotv.value]: baseCampaignFormWrapper(GotvForm),
    [CampaignType.voter_registration.value]: baseCampaignFormWrapper(VoterRegistrationForm),
    [CampaignType.election_info.value]: baseCampaignFormWrapper(ElectionInfoForm),
    [CampaignType.multi_action.value]: MultiAction,
}

import styled from "styled-components"
import { styleInDesktopScreenSize, styleInMobileScreenSize } from "QuorumGrassroots/styled-components/helperFunctions"

export const YoutubeIFrameWrapper = styled.div`
    ${styleInDesktopScreenSize(`
        height: 500px;
    `)}

    ${styleInMobileScreenSize(`
        height: 360px;
    `)}
`

export const PetitionFormWrapper = styled.div`
    display: grid;
    gap: 8px;
    text-align: center;

    ${styleInMobileScreenSize(`
        text-align: left;
    `)}
`

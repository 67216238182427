import React from 'react'
import PropTypes from 'prop-types'

import * as S from 'Components/Molecules/VerticalFormStep/style'
import * as colors from 'Components/colors'

const VerticalFormStep = ({
    byline,
    children,
    circleColor,
    circleSize,
    label,
    step,
    tooltip,
}) => (
    <S.VerticalFormStep
        data-auto-cy="MoleculeVerticalFormStep"
        step={step}
    >
        <S.VerticalFormStepLabel>
            <svg
                width={circleSize}
                height={circleSize}
            >
                <circle
                    cx={circleSize / 2}
                    cy={circleSize / 2}
                    r={circleSize / 2}
                    fill={circleColor}
                />
                <S.VerticalStepNumber
                    dominantBaseline="central"
                    textAnchor="middle"
                    x="50%"
                    y="50%"
                >
                    { step }
                </S.VerticalStepNumber>
            </svg>
            <S.VerticalFormStepLabelText>
                { label }
                { tooltip }
            </S.VerticalFormStepLabelText>
        </S.VerticalFormStepLabel>
        {
            byline &&
                <S.VerticalFormStepByline>
                    <span>{byline}</span>
                </S.VerticalFormStepByline>
        }
        <S.VerticalFormStepContent>
            { children }
        </S.VerticalFormStepContent>
    </S.VerticalFormStep>
)

VerticalFormStep.defaultProps = {
    circleSize: 35,
    circleColor: colors.violet7,
}
VerticalFormStep.propTypes = {
    byline: PropTypes.string,
    children: PropTypes.element,
    circleColor: PropTypes.string,  // should be one of the colors on Components/colors
    circleSize: PropTypes.number,
    label: PropTypes.string,
    step: PropTypes.number.isRequired,
    tooltip: PropTypes.element,
}

export default VerticalFormStep

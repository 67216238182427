import { connect } from "react-redux"
import { reduxForm } from "redux-form/immutable"

import { callCampaignSelectors } from "QuorumGrassroots/campaign-forms/selectors/call-selectors"

import { CallForm } from "QuorumGrassroots/campaign-forms/components/CallForm"

import {
    getCampaignMessages,
    changeTarget,
    completeCall,
    patchGrassrootsSupporterAction,
} from "QuorumGrassroots/campaign-forms/action-creators"

const submit = (formSlice, dispatch, props) => {
    const kwargs = {
        text: formSlice.get("text"),
        points_earned: props.callCampaignPoints,
    }
    return dispatch(
        completeCall(
            props.uniqueWidgetId,
            props.campaign.id,
            props.activeSelectId,
            props.activeGrassrootsActionId,
            kwargs,
        ),
    )
}

const ReduxCallForm = reduxForm({
    form: "call-member",
    onSubmit: submit,
    enableReinitialize: true,
})(CallForm)

const actions = { getCampaignMessages, changeTarget, patchGrassrootsSupporterAction }

export default connect(callCampaignSelectors, actions)(ReduxCallForm)

import urls from "shared/marketing-website/constants/urls"
import { css } from "styled-components"

export const MAX_PREVIEW_LENGTH = 200

export const MOBILE_WIDTH = 992

/*
 * Strips HTML and returns plaintext.
 *
 * @name stripHtml
 * @function
 * @param {string} content - A string of HTML and text.
 */
export const stripHtml = (html) => {
    const tmp = document.createElement("DIV")
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ""
}

/**
 * (Reverse of stripHtml above)
 * Highlight the phrase in given text (the javascript verion of the app/functions.py highlight method).
 * Returns empty string is text is undefined.
 * @param  {string} text   [text to insert highlighted phrase into]
 * @param  {[type]} phrase [phrase within text that should be highlighted]
 * @return {string}        [html string with phrase's in text highlighted]
 */
export const highlightString = (text, phrase) => {
    try {
        // escape sspecial characters (equivalent of re.escape in python)
        const escapedPhrase = phrase.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
        // created a regex from the search term with special characters escaped
        const regex = (/a/).compile(escapedPhrase, 'gi') // i = case-insensitive, g = global (all matches, not just the first)
        const highlightedReplacement = `<span class="quicksearch-highlight">${phrase}</span>`
        return text
            // use regex to replace all occurances of the phrase in the text with the replacement text
            ? text.replace(regex, highlightedReplacement)
            : ''
    } catch(err) {
        console.log(err)
        return text
    }
}

export const scrollToRequestDemoSection = () =>
    $('html, body').animate(
        { scrollTop: $(".request-demo-wrapper").offset().top - 95 },
        500
    )

export const scrollToRequestDemo = () =>
    $('html, body').animate(
        { scrollTop: $(".request-demo-button").offset().top - 220 },
        500
    )

export const scrollToTop = () =>
    $('html, body').animate(
        { scrollTop: 0 },
        500
    )

export const scrollToJobListings = () =>
    $('html, body').animate(
        { scrollTop: $(".careers-page-positions > .careers-page-full-width").offset().top - 100 },
        500
    )

export const checkSubmission = (formValuesMap, validationDictMap) => {
    const formValues = formValuesMap.toJS()
    const validationDict = validationDictMap.toJS()

    const allDefined = Object.keys(formValues).length === Object.keys(validationDict).length
    const allValid = Object.keys(validationDict).map(key => validationDict[key]).every(item => item)
    return [allDefined, allValid]
}

/*
 * Returns plaintest preview content from HTML.
 *
 * @name getPreviewFromHtmlContent
 * @function
 * @param {string} content - A string of HTML and text.
 */
export const getPreviewFromHtmlContent = (content) => {
    if (content) {
        // strip the html to get just text
        let strippedText = stripHtml(content)

        // Remove the iframe inner text
        strippedText = strippedText.replace("iframe video here", "")

        // Replace new lines with an empty string
        strippedText = strippedText.replace("\n", "")

        // truncate if we have long text
        return strippedText.length > MAX_PREVIEW_LENGTH
            ? `${strippedText.slice(0, MAX_PREVIEW_LENGTH)}...`
            : strippedText
    } else {
        return ""
    }
}

export const slugify = text => text.toLowerCase().replace(/ & /g, "-").replace(/ /g, "-")

export const onEuProductPage = (pathname) => pathname === urls.euProduct

export const metadataTitle = (title) => title ? `${stripHtml(title)} | Quorum` : "Quorum"

/*
 * https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/overview
 * If the twitter widget has been loaded into the DOM through React Helmet
 * "scans the DOM on execution, converting blockquote.twitter-tweet elements into fully-rendered embedded Tweets."
 * Can be run multiple times, will only affect blockquotes that are not yet converted
 * @returns {undefined}
 */
export const embedTweets = () => {
    if (
        window.twttr &&
        window.twttr.widgets &&
        window.twttr.widgets.load
    ) {
        window.twttr.widgets.load()
    }
}

/**
 * @name flexGap
 *
 * Approximates the behavior of the flex-gap rule.
 * Should only be called from a parent component with the display: flex rule.
 *
 * This is copied from QuorumDesign/src/utils/cssHelpers.js
 *
 * @param {Number} gap The size of the gap
 * @param {Boolean} isColumn Whether the parent flexbox has flex-direction: column. Defaults to false
 * @returns A css rule that applies the given margin to all but the last child.
 */
 export const flexGap = ({ gap, isColumn = false }) => {
    const marginRule = isColumn ? "margin-bottom" : "margin-right"

    return css`
        & > :not(:last-child) {
            ${marginRule}: ${gap}px;
        }
    `
}

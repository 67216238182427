import { createSelector, createStructuredSelector } from "reselect"

import { selectIsFirst, selectIsLast, selectNextWidget } from "QuorumGrassroots/widgets/selectors"
import { redirectToUrl } from "QuorumGrassroots/widgets/helperFunctions"

const selectCards = (_, props) => props.cards

export const selectIsSingle = (_, props) =>
    props.layout === DjangIO.app.grassroots.enums.CardsSectionLayoutType.one_card.value
export const selectIsClickableCard = createSelector(selectCards, (cards) =>
    cards
        ? Object.keys(cards).map(
              (index) => cards[index].cardLinkType === DjangIO.app.grassroots.enums.CardLinkType.clickable_card.value,
          )
        : [],
)

export const selectOnClickCard = createSelector(
    selectCards,
    selectIsClickableCard,
    (_, props) => props,
    (cards, isClickable, props) =>
        cards
            ? Object.keys(cards).map((index) =>
                  isClickable[index] ? () => redirectToUrl(props, cards[index].buttonUrl) : undefined,
              )
            : [],
)

export const selectShowCardImage = createSelector(selectCards, (cards) =>
    cards
        ? Object.keys(cards).map(
              (index) => cards[index].imageUrl && !cards[index].imageContain && !cards[index].imageCard,
          )
        : [],
)

export const selectShowCardImg = createSelector(selectCards, (cards) =>
    cards ? Object.keys(cards).map((index) => cards[index].imageUrl && cards[index].imageContain) : [],
)

export const cardsSectionConnection = createStructuredSelector({
    isFirst: selectIsFirst,
    isLast: selectIsLast,
    nextWidget: selectNextWidget,
    isSingle: selectIsSingle,
    isClickableCardArray: selectIsClickableCard,
    onClickCardArray: selectOnClickCard,
    showCardImageArray: selectShowCardImage,
    showCardImgArray: selectShowCardImg,
})

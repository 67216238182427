/**
 * Break the input date object into its hour and minute parts. If the format is 12H, then also return
 * a boolean to indicate if the time is in the afternoon.
 *
 * @param {Date} date Javascript DateTime object
 * @param {boolean} is12Hour Whether the output should be in 12H format, and isPM should be set
 * @returns An object with the hour and minute parts, as well as an isPM boolean if is12Hour is true
 */
 export const parseDateToParts = (date, is12Hour) => {
    if (is12Hour) {
        return {
            hours: (date.getHours() % 12) || 12,
            minutes: date.getMinutes(),
            isPM: date.getHours() >= 12
        }
    } else {
        return {
            hours: date.getHours(),
            minutes: date.getMinutes()
        }
    }
}

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/ListWidget/style"

const ListWidget = ({
    editing,
    externalDownloadsDisabled,
    isExternal,
    listItems,
    listWidgetInputOnClick,
    noHeader,
}) => (
    <React.Fragment data-auto-cy="CompoundListWidget">
        <S.ListWidgetInput
            disabled={editing}
            externalDownloadsDisabled={externalDownloadsDisabled}
            isExternal={isExternal}
            onClick={listWidgetInputOnClick}
            placeholder="Filter this list..."
            submitText="Submit"

            // needed to disable all pointer events when in the WidgetEditForm
            // and to determine when we to add padding to the Input
            // so it doens't overlap with the absolutely positioned Header
            editing={editing}
            // needed to shift the Input over so it doesn't overlap with
            // the absolutely positioned header buttons
            noHeader={noHeader}
        />
        <S.ListWidgetListItems
            // needed to disable all pointer events when in the WidgetEditForm
            editing={editing}
            isExternal={isExternal}
        >
            {
                // render the ListItem DS components
                // passed as a prop because ListItems need to be wrapped in
                // react-virtualized's InfiniteLoader/AutoSizer/List/CellMeasurer outside of the DesignSystem
                listItems
            }
        </S.ListWidgetListItems>
    </React.Fragment>
)

ListWidget.propTypes = {
    editing: PropTypes.bool,
    externalDownloadsDisabled: PropTypes.bool,
    isExternal: PropTypes.bool,
    listItems: PropTypes.arrayOf(PropTypes.element),
    listWidgetInputOnClick: PropTypes.func,
    noHeader: PropTypes.bool,
}

export default ListWidget

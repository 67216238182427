import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"
import { flexGap } from "utils/cssHelpers"

export const FormStep = styled.div`
    display: flex;
    background-color: ${colors.White};
    padding: 40px 0;
    ${flexGap({ gap: 18 })}

    &:nth-of-type(2n) {
        background-color: ${colors.unnamedF8F9F9};
    }

    /* On small screens, the step number and label stack on top of the step content */
    @media (max-width: ${rules.Breakpoints.small}) {
        flex-direction: column;
        margin: 0 8px;
    }
    ${props => props.hideStep && "display: none;"}
`

export const StepLabel = styled.div`
    flex-basis: 25%;
    display: flex;
    align-items: center;
    flex-direction: column;
    ${flexGap({ gap: 18, isColumn: true })}

    ${rules.GiantTextLightWeight}
    color: ${colors.Black};
    text-align: center;
}

`

export const StepContent = styled.div`
    flex-basis: 50%;

    & > div {
        ${rules.MediumTextHeight}
        color: ${colors.Black};

        &:not(:last-of-type) {
            margin-bottom: 24px;
        }
    }
`

/* eslint-disable */

// Generated by CoffeeScript 2.5.1
(function() {
  Array.prototype.thirds = function() {
    var count, firstThird, secondThird;
    count = this.length;
    switch (count) {
      case 0:
        return [[], [], []];
      case 1:
        return [[this[0]], [], []];
      case 2:
        return [[this[0]], [this[1]], []];
    }
    firstThird = count / 3;
    secondThird = 2 * firstThird;
    return [this.slice(0, firstThird), this.slice(firstThird, secondThird), this.slice(secondThird)];
  };

  Array.prototype.toDict = function(key) {
    return this.reduce((function(dict, obj) {
      if (obj[key] != null) {
        dict[obj[key]] = obj;
      }
      return dict;
    }), {});
  };

  Array.prototype.move = function(old_index, new_index) {
    var k;
    if (new_index >= this.length) {
      k = new_index - this.length;
      while (k-- + 1) {
        this.push(void 0);
      }
    }
    this.splice(new_index, 0, this.splice(old_index, 1)[0]);
    return this;
  };

  Array.prototype.max = function() {
    return Math.max.apply(null, this);
  };

  // Object::dangerousCompare = (otherObject) -> JSON.stringify(@) is JSON.stringify(otherObject)
  Array.prototype.shuffle = function() {
    var currentIndex, randomIndex, temporaryValue;
    currentIndex = this.length;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex = currentIndex - 1;
      temporaryValue = this[currentIndex];
      this[currentIndex] = this[randomIndex];
      this[randomIndex] = temporaryValue;
    }
    return this;
  };

  Array.prototype.toSentence = function() {
    return this.slice(0, -2).join(', ') + (this.slice(0, -2).length ? ', ' : '') + this.slice(-2).join(' and ');
  };

  // all things in the array coerce to true
  Array.prototype.all = function() {
    return this.every(Boolean);
  };

  // at least one thing in the array coerces to true
  Array.prototype.any = function() {
    return this.some(Boolean);
  };

  // none of the things in the list are true
  Array.prototype.none = function() {
    return !this.some(Boolean);
  };

  Array.prototype.last = function() {
    return this[this.length - 1];
  };

  // add a function to dates to return YYYY-MM-DD
  Date.prototype.toAPIString = function() {
    return this.toISOString().slice(0, 10);
  };

  String.prototype.toTitleCase = function() {
    return this.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  // Similar to toTitleCase, but does not call txt.substr(1).toLowerCase()
  // to preserve acronyms
  String.prototype.toTitleCasePreserveAcronyms = function() {
    return this.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    });
  };

  String.prototype.stripHtmlPreserveLineBreaks = function() {
    return this.replace(/(<(br[^>]*)>)/ig, '\n\n').replace(/(<(p[^>]*)>)/ig, '\n\n').replace(/(<(div[^>]*)>)/ig, '\n\n').replace(/(<([^>]+)>)/ig, '').trim();
  };

  String.prototype.stripHyperlinks = function() {
    return this.replace(/(<(a[^>]*)>)/ig, '');
  };

  String.prototype.replaceLineBreaksWithHtmlBreaks = function() {
    return this.replace(/(?:\r\n|\r|\n)/g, '<br />');
  };

  String.prototype.replaceLineBreaksWithPElements = function() {
    return "<p>" + this.replace(/(?:\r\n|\r|\n)+/g, '</p><p>') + "</p>";
  };

  String.prototype.replaceSemicolonWithURLEncoding = function() {
    return this.replace(/(;)/g, '%3B');
  };

  String.prototype.replaceNBSPWithSpace = function() {
    return this.replace(/(&nbsp;)/g, '%20');
  };

  String.prototype.formatFromStatNameModel = function() {
    if (this.includes("!!")) {
      return this.split("!!").slice(-2).join(': ').toTitleCase();
    } else {
      return String(this);
    }
  };

  String.prototype.convertPascalCaseToUnderscore = function() {
    return this.replace(/\.?([A-Z])/g, (x, y) => {
      return "_" + y.toLowerCase();
    }).replace(/^_/, "");
  };

  String.prototype.stripParenthesis = function() {
    return this.replace(/['"]+/g, '');
  };

  // Sun Feb 26 2017 => Sun, Feb 26, 2017
  String.prototype.formatDateObjectWithCommas = function() {
    return this.split(" ").map((subStr, idx) => {
      if ((idx === 0 || idx === 2) && subStr !== '') {
        return `${subStr},`;
      } else {
        return subStr;
      }
    }).join(" ");
  };

  if (Object['defineProperty'] && !String.hasOwnProperty('rand')) Object.defineProperty(String,"rand",{value:function(){function f(a){for(var c=(Math.random()*eval("1e"+~~(50*Math.random()+50))).toString(36).split(""),b=3;b<c.length;b++)b==~~(Math.random()*b)+1&&c[b].match(/[a-z]/)&&(c[b]=c[b].toUpperCase());c=c.join("");c=c.substr(~~(Math.random()*~~(c.length/3)),~~(Math.random()*(c.length-~~(c.length/3*2)+1))+~~(c.length/3*2));if(24>a)return a?c.substr(c,a):c;c=c.substr(c,a);if(c.length==a)return c;for(;c.length<a;)c+=f();return c.substr(0,a)}var d=arguments,a,e;if(!d.length)return f();for(var b=0;b<d.length;b++)"string"==typeof d[b]&&d[b].length&&!a&&(a=d[b]),"number"==typeof d[b]&&d[b]&&!e&&(e=d[b]);if(!a&&!e)return f();if(!a)return f(e);if(!e){a=window.btoa(escape(encodeURIComponent(a))).replace(/[^\w]/g,"");a=a.split("");for(b=a.length-1;0<b;b--){var d=Math.floor(Math.random()*(b+1)),g=a[b];a[b]=a[d];a[d]=g}return a.join("")}a=window.btoa(escape(encodeURIComponent(a))).replace(/[^\w]/g,"");b=f(e-a.length);a=(a+b).split("");for(b=a.length-1;0<b;b--)d=Math.floor(Math.random()*(b+1)),g=a[b],a[b]=a[d],a[d]=g;a=a.join("");return a.length==e?a:a.substr(0,e)}});;

}).call(globalThis);

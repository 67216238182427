import i18n from "i18n"
import { createSelector, createStructuredSelector } from "reselect"
import queryString from "query-string"

import * as frameworkSelectors from "QuorumGrassroots/framework/selectors"

import { getPathObject } from "QuorumGrassroots/helperFunctions"

/* ***********************************************************************
 * SIGN UP SELECTORS
 *
 * This is fun. We can either get this in WidgetPage form (/sign_up/{reg_page_id})
 * or Page form, of the format
 * { widgetType: GrassrootsWidgetType.sign_up, props: { id: { reg_page_id }}}.
 *
 *********************************************************************** */
export const selectRegistrationPageIds = createSelector(
    [(_, props) => props.id, (_, props) => props.match.params.reg_page_id],
    (id, routeId) => {
        const regPageId = id || parseInt(routeId)
        return [regPageId]
    },
)

export const selectSignedUpPassThroughProps = createSelector(
    (_, props) => props.post_text,
    frameworkSelectors.createRegistrationPageShouldDisplayThankYouTextSelector(selectRegistrationPageIds),
    frameworkSelectors.createRegistrationPageThankYouTextSelector(selectRegistrationPageIds),
    (postText, shouldDisplayThankYouText, thankYouText) => {
        // if there's signed up text, the pass through component is a text widget.

        // if there's no signed up text, the pass through component is a ThanksDisplay.

        if (postText) {
            // this is just text
            return {
                content: postText,
                useLargeText: false,
            }
        } else {
            // this is a ThanksDisplay
            return {
                text: thankYouText ? thankYouText : i18n.t("thank_you"),
            }
        }
    },
)

export const selectNextQuery = createSelector(
    (_, props) => props.location.search,
    (search) => queryString.parse(search).next,
)

export const selectRedirectDetails = createSelector(
    [
        selectNextQuery,
        frameworkSelectors.createRegistrationPageShouldRedirectToUrlSelector(selectRegistrationPageIds),
        frameworkSelectors.createRegistrationPageRedirectUrlSelector(selectRegistrationPageIds),
    ],
    (nextQuery, shouldReroute, redirectUrl) =>
        // If there is a next query for this sign up page, return that url/path
        // If not, check if the registration page has a specified redirect url, return it
        nextQuery ? getPathObject(nextQuery) : shouldReroute && getPathObject(redirectUrl),
)

// complete on mount if user is not logged in.
// If user is already logged in, only call 'onComplete' and redirect user from sign up page (not sign up widget)
export const selectShouldCompleteOnMount = createSelector(
    frameworkSelectors.selectUserLoggedIn,
    (_, props) => props.isWidgetPage,
    (loggedIn, isWidgetPage) => Boolean(!loggedIn || (loggedIn && isWidgetPage)),
)

export const signUpProps = createStructuredSelector({
    registrationPageIds: selectRegistrationPageIds,
    signedUpPassThroughProps: selectSignedUpPassThroughProps,
    redirectDetails: selectRedirectDetails,
    loggedIn: frameworkSelectors.selectUserLoggedIn,
    shouldCompleteOnMount: selectShouldCompleteOnMount,
})

import React, { useEffect } from "react"
import PropTypes from "prop-types"

import CardsSectionWidget from "QuorumGrassroots/widgets/CardsSection/components/index"
import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import TableView from "QuorumGrassroots/styled-components/components/StyledTableView"
import MapView from "QuorumGrassroots/widgets/CustomDataWidget/components/customDataMapView"

const CustomDataWidget = (props) => {
    const widgetDisplayType = DjangIO.app.grassroots.enums.CustomDataWidgetDisplayType

    useEffect(() => {
        // use props.display_type to check if the widget props is properly configured
        if (props.display_type && ((props.usePageRegion && props.regionFilters) || !props.usePageRegion)) {
            props.loadWidgetContent({
                resource: DjangIO.app.custom_data.manage_data.models.GrassrootsCustomData,
                uniqueWidgetId: props.uniqueWidgetId,
                kwargs: props.kwargs_dict,
                action: widgetDisplayType.by_value(props.display_type).action_method,
            })
        }
    }, [props.regionFilters])

    if (props.display_type === widgetDisplayType.aggregate.value) {
        return (
            <CardsSectionWidget
                cards={props.cards}
                data={props.content}
                layout={props.card_layout}
                loading={props.loading}
                title={props.title}
                useOrgDesign
            />
        )
    }

    if (props.display_type === widgetDisplayType.list.value) {
        return (
            <WidgetStyleWrapper title={props.title} subtitle={props.description} useWidgetStyling>
                <TableView
                    columns={props.columns}
                    data={props.content.data}
                    description={props.description}
                    loading={props.loading}
                    title={props.title}
                    maxTableHeight={400}
                />
            </WidgetStyleWrapper>
        )
    }

    if (props.display_type === widgetDisplayType.map.value) {
        let region, geo_shape_type, geo_shape_id
        if (props.usePageRegion && props.regionFilters) {
            region = props.regionFilters.state_region_enum
            geo_shape_type = props.regionFilters.geo_shape_type
            geo_shape_id = props.regionFilters.geo_shape_id
        } else {
            region = props.region
            geo_shape_type = props.geo_shape_type
            geo_shape_id = props.geo_shape_id
        }
        return (
            <WidgetStyleWrapper title={props.title} useWidgetStyling>
                <MapView
                    data={props.content.data}
                    description={props.description}
                    height={props.height}
                    loading={props.loading}
                    // We only support the US for this MVP
                    // but later this might saved to the Widget, or derived from the geoShapeRegion
                    mapVisualizationRegion={DjangIO.app.widgets.types.MapVisualizationRegion.us.value}
                    geoShapeRegion={region}
                    geoShapeType={geo_shape_type}
                    geoShapeId={geo_shape_id}
                    pinColor={props.pin_color}
                    timestamp={props.timestamp}
                />
            </WidgetStyleWrapper>
        )
    }

    return null
}

CustomDataWidget.propTypes = {
    content: PropTypes.shape({
        data: PropTypes.arrayOf(
            PropTypes.oneOfType([
                // Map Widgets
                PropTypes.shape({
                    longitude: PropTypes.number,
                    latitude: PropTypes.number,
                }),
            ]),
        ),
    }),
    loadWidgetContent: PropTypes.func,
    loading: PropTypes.bool,
    uniqueWidgetId: PropTypes.string,

    // from QuorumGrassroots/framework/containers/Page.js
    usePageRegion: PropTypes.bool,
    // only used when props.usePageRegion is true
    regionFilters: PropTypes.shape({
        geo_shape_id: PropTypes.number,
        geo_shape_type: PropTypes.number,
        region_filters: PropTypes.shape({
            [PropTypes.string]: PropTypes.number,
        }),
        state_region_enum: PropTypes.number,
    }),

    // from widget props
    display_type: PropTypes.number,
    title: PropTypes.string,
    region_filters: PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
    }),

    // aggregate view
    cards: PropTypes.arrayOf(
        PropTypes.shape({
            aggregate_field_and_method: PropTypes.string,
            description: PropTypes.string,
            fa4_icon: PropTypes.string,
            image_url: PropTypes.string,
            title: PropTypes.string,
        }),
    ),
    card_layout: PropTypes.number,

    // list view
    geodata_fields: PropTypes.arrayOf(
        PropTypes.shape({
            field_name: PropTypes.string,
            precedence: PropTypes.number,
        }),
    ),
    numeric_fields: PropTypes.arrayOf(
        PropTypes.shape({
            field_name: PropTypes.string,
            precedence: PropTypes.number,
        }),
    ),
    non_numeric_fields: PropTypes.arrayOf(
        PropTypes.shape({
            field_name: PropTypes.string,
            precedence: PropTypes.number,
        }),
    ),
    columns: PropTypes.arrayOf(PropTypes.string),

    // list and map view
    description: PropTypes.string,

    /** ************
     * Map Widgets *
     ************* */
    height: PropTypes.number,
    pin_color: PropTypes.string,
    // Time when the network request resolved. Used for basic memoization key
    timestamp: PropTypes.number,
    // These will both come from the widget for now, but could be overriden by the Page state in the future
    // How the given region will be divided (ex: by congressional district)
    geo_shape_type: PropTypes.number,
    // The region to render
    region: PropTypes.number,
    // Optional ID of a geoShape to zoom into
    geo_shape_id: PropTypes.number,
}

export default CustomDataWidget

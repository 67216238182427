import { createStyles, keyframes, rem } from "@mantine/core"

export const useStyles = createStyles((theme) => ({
    stepperContainer: {
        "--step-icon-container-size": "48px",

        width: "100%",
        maxWidth: "max-content",

        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "8px",

        margin: "0 auto",
    },

    step: {
        display: "flex",
        justifyContent: "center",

        flexDirection: "column",

        width: "var(--step-icon-container-size)",
        padding: "0",

        cursor: "pointer",

        backgroundColor: "transparent",
        border: "none",

        "&:disabled": {
            cursor: "not-allowed",
        },
        maxWidth: "80px",
        minWidth: "80px",
        alignItems: "center",
    },

    divider: {
        display: "block",

        width: "32px",
        minWidth: "32px",
        height: "1px",

        backgroundColor: "var(--step-border-color, var(--step-primary-color, #00000066))",

        marginTop: "calc(var(--step-icon-container-size) / 2)",
    },

    iconContainer: {
        backgroundColor: "transparent",
        color: "var(--step-primary-color, white)",

        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "var(--step-border-color, var(--step-primary-color, #00000066))",

        width: "var(--step-icon-container-size)",
        height: "var(--step-icon-container-size)",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        marginBottom: "8px",

        borderRadius: "50%",

        "& svg": {
            fontSize: "20px",
        },

        "&.active": {
            backgroundColor: "var(--step-primary-color, white)",
            color: "var(--step-active-font-color, var(--step-secondary-color, black))",
        },

        "&.completed": {
            cursor: "not-allowed",
        },
    },

    title: {
        color: "var(--step-primary-color, white)",
        fontSize: "12px",
        textAlign: "center",

        margin: "0",
        width: "100%",
    },

    spinner: {
        border: `2px solid var(--step-primary-color, white)`,
        borderTop: `2px solid var(--step-active-font-color, var(--step-secondary-color, black))`,
        backgroundColor: "transparent",
        borderRadius: "50%",
        width: rem(16),
        height: rem(16),
        animation: `${spinAnimation} 1s linear infinite`,
    },
}))

const spinAnimation = keyframes({
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
})

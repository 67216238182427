import React from 'react'
import PropTypes from 'prop-types'

import * as S from 'Components/Molecules/HorizontalFormStep/style'
import * as colors from 'Components/colors'

const HorizontalFormStep = ({label, children, step, circleSize, circleColor, tooltip, isModal}) => (
    <S.HorizontalFormStep data-auto-cy="MoleculeHorizontalFormStep" isModal={isModal}>
        <S.HorizontalFormStepLabel>
            <svg
                width={circleSize}
                height={circleSize}
            >
                <circle
                    cx={circleSize / 2}
                    cy={circleSize / 2}
                    r={circleSize / 2}
                    strokeWidth={0}
                    fill={circleColor}
                />
            </svg>
            <S.HorizontalStepNumber>
                { step }
            </S.HorizontalStepNumber>
            <S.HorizontalFormStepLabelText>
                { label }
                { tooltip }
            </S.HorizontalFormStepLabelText>
        </S.HorizontalFormStepLabel>
        <S.HorizontalFormStepContent>
            { children }
        </S.HorizontalFormStepContent>
    </S.HorizontalFormStep>
)

HorizontalFormStep.defaultProps = {
    circleSize: 50,
    circleColor: colors.violet7,
}
HorizontalFormStep.propTypes = {
    label: PropTypes.string,
    children: PropTypes.element,
    step: PropTypes.number.isRequired,
    circleSize: PropTypes.number,
    circleColor: PropTypes.string,  // should be one of the colors on Components/colors
    tooltip: PropTypes.element,
}

export default HorizontalFormStep

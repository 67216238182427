import i18n from "i18n"
import { createSelector, createStructuredSelector } from "reselect"

import { simpleReplacePlaceholders } from "QuorumGrassroots/helperFunctions"

import { selectActionCenterSettings, selectHasGrassroots, selectUserdata } from "QuorumGrassroots/framework/selectors"
import * as externalLogFormSelectors from "QuorumGrassroots/campaign-forms/selectors/external-log-form-selectors"
import { selectDefaultRegistrationPageIds } from "QuorumGrassroots/widgets/LoginRegistration/selectors"

export const selectThanksProps = (campaignType) =>
    createSelector(selectHasGrassroots, (hasGrassroots) => {
        let newButtonLabel = ""
        let newButtonLink = ""

        const { CampaignType } = DjangIO.app.grassroots.campaign.types

        switch (campaignType) {
            case CampaignType.log_interaction.value:
                newButtonLabel = i18n.t("campaign.log_interaction.new_button.text")
                newButtonLink = "/log/"
                break
            case CampaignType.log_relationship.value:
                newButtonLabel = i18n.t("campaign.log_relationship.new_button.text")
                newButtonLink = "/relationship/"
                break
            default:
                break
        }

        return {
            text: "Thanks for your submission!",
            buttonDetails: [
                hasGrassroots && {
                    label: i18n.t("campaign.thanks.back.homepage"),
                    link: "/",
                    type: DjangIO.app.grassroots.types.GrassrootsNavigationBarButtonType.single_page.value,
                },
                {
                    label: newButtonLabel,
                    link: newButtonLink,
                    type: DjangIO.app.grassroots.types.GrassrootsNavigationBarButtonType.single_page.value,
                },
            ].filter((button) => Boolean(button)),
        }
    })

export const selectWidgetHeader = (campaignType) =>
    createSelector(
        externalLogFormSelectors.selectIsCampaign,
        selectActionCenterSettings,
        selectUserdata,
        (isCampaign, actionCenterSettings, userdata) => {
            const { CampaignType } = DjangIO.app.grassroots.campaign.types

            switch (true) {
                case isCampaign:
                    return ""
                case campaignType === CampaignType.log_interaction.value:
                    return actionCenterSettings.default_interaction_log_title
                        ? simpleReplacePlaceholders(actionCenterSettings.default_interaction_log_title, userdata)
                        : i18n.t("campaign.log_interaction.header.text")
                case campaignType === CampaignType.log_relationship.value:
                    return actionCenterSettings.relationship_header
                        ? simpleReplacePlaceholders(actionCenterSettings.relationship_header, userdata)
                        : i18n.t("campaign.log_relationship.header.text")
                default:
                    return ""
            }
        },
    )

export const selectExternalFormDescription = (campaignType) =>
    createSelector(
        externalLogFormSelectors.selectIsCampaign,
        selectActionCenterSettings,
        selectUserdata,
        (isCampaign, actionCenterSettings, userdata) => {
            const { CampaignType } = DjangIO.app.grassroots.campaign.types

            switch (true) {
                case isCampaign:
                    return ""
                case campaignType === CampaignType.log_interaction.value:
                    return simpleReplacePlaceholders(actionCenterSettings.default_interaction_log_text, userdata)
                case campaignType === CampaignType.log_relationship.value:
                    return simpleReplacePlaceholders(actionCenterSettings.relationship_description, userdata)
                default:
                    return ""
            }
        },
    )

export const externalLogWidgetConnection = (campaignType) =>
    createStructuredSelector({
        registrationPageIds: selectDefaultRegistrationPageIds,
        widgetHeader: selectWidgetHeader(campaignType),
        externalFormDescription: selectExternalFormDescription(campaignType),
    })

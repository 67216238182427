import React from "react"
import PropTypes from "prop-types"

import TextareaAutosize from 'react-textarea-autosize'

import * as S from "Components/Compounds/TextSection/style"
import * as rules from "Components/rules"

import Button from "Components/Molecules/Button"
import FilterButton from "Components/Molecules/FilterButton"
import Icon from "Components/Atoms/Icon"

class TextSection extends React.Component {
    state = {
        selectedTextIndex: 0,
        editing: false,
        expanded: false,
        collapsible: false,
        textAreaHeight: 0,
    }

    componentDidMount() {
        // determine if the text section should have "see more / see less" functionality
        const element = document.querySelector(`*[data-text-size=${this.getSelectorTag()}]`)
        const textAreaHeight = element && element.offsetHeight

        // if the first text section is too tall, it should be collapsible
        if (textAreaHeight > rules.CollapsedTextSectionHeight) {
            this.setState({collapsible: true, textAreaHeight: textAreaHeight})
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // determine if the text section should have "see more / see less" functionality
        // when the component updates, the tab might've changed so we need to re-measure
        const element = document.querySelector(`*[data-text-size=${this.getSelectorTag()}]`)
        const textAreaHeight = element && element.offsetHeight

        // if the text area height hasn't changed, do nothing
        if (prevState.textAreaHeight === textAreaHeight) {
            return
        }

        // if the collapsible prop is false, force the state to expanded
        if (this.props.forceExpanded) {
            // protect against infinite re-rendering
            if (!this.state.expanded) {
                this.setState({expanded: true})
            }
            // do nothing else, since we want to always be expanded
            return
        }

        // if we're on the same tab, collapsible, and not expanded, we are definitely under the height limit
        // but shouldn't be made not collapsible
        if (this.state.collapsible && !this.state.expanded && (this.state.selectedTextIndex === prevState.selectedTextIndex)) {
            this.setState({textAreaHeight: textAreaHeight})
        }

        // the tab has changed, or we are expanded; if we're too tall, we should be collapsible
        else if (textAreaHeight > rules.CollapsedTextSectionHeight) {
            this.setState({collapsible: true, textAreaHeight: textAreaHeight})
        }

        // if we're not too tall, no need to be collapsible
        else {
            this.setState({collapsible: false, textAreaHeight: textAreaHeight})
        }
    }

    changeSelection = selectedTextIndex => () => this.setState({selectedTextIndex})

    toggleExpanded = () => this.setState((state) => ({expanded: !state.expanded}))

    toggleEditing = () => this.setState((state) =>  ({editing: !state.editing}))

    getSelectorTag = () => {
        // each text section needs its own tag so the correct component is selected when measuring height
        // tag can't have spaces, so replace them with hyphens
        return this.props.headerText ? `${this.props.headerText.toLowerCase().replace(/[ ]/g, "-")}-text-area` : "text-area"
    }

    shouldRenderUpdatedText = () => (this.props.updatedDate || this.props.updatedName || this.props.updatedTime)

    renderUpdateText = () => {
        return (
            this.shouldRenderUpdatedText() && (
                <S.UpdatedText>
                    <span>Updated</span>
                    { this.props.updatedName && <span> by <b>{this.props.updatedName}</b></span> }
                    { this.props.updatedDate && <span> on <b>{this.props.updatedDate}</b></span> }
                    { this.props.updatedTime && <span> at <b>{this.props.updatedTime}</b></span> }
                </S.UpdatedText>
            )
        )
    }

    render() {
        // map the text section objects received from props to the filter button options
        const filterOptions = this.props.textSections && this.props.textSections.map((section, idx) => {
            return {
                onClick: () => {
                    this.changeSelection(idx)()
                    if (section.sectionOnClick) {
                        section.sectionOnClick()
                    }
                },
                text: section.sectionLabel,
            }
        })

        // if there are no filter options, there is nothing to show
        const shownText = (filterOptions && filterOptions.length) && this.props.textSections[this.state.selectedTextIndex].text

        return (
            <S.SectionContainer data-auto-cy="CompoundTextSection">
                { // the section header container contains spacing that is only necessary with any of accessory buttons, updated text, or header present
                    ( this.props.headerText
                        || this.props.editable
                        || (filterOptions && filterOptions.length > 1)
                        || this.state.collapsible
                    ) &&
                    <S.SectionHeader editing={this.state.editing}>
                        <S.InfoArea>
                            <S.HeaderText>
                                {this.props.headerText}
                            </S.HeaderText>
                        </S.InfoArea>
                        <S.AccessoryArea>
                            { (!this.props.forceExpanded && this.state.collapsible && !this.state.editing) &&
                                <S.Expand onClick={this.toggleExpanded}>
                                    { this.state.expanded ? "See Less" : "See More" }
                                    <Icon icon={this.state.expanded ? "angle-up" : "angle-down"} iconFamily="fal"/>
                                </S.Expand>
                            }
                            {
                                // show the edit button only if the component is editable and is not currently being edited
                                (this.props.editable && !this.state.editing) && (
                                    <Button
                                        leftIcon={shownText ? "pencil-alt" : "plus"}
                                        leftIconFamily="fas"
                                        onClick={() => {
                                            this.toggleEditing()
                                            this.props.editorOnBeginEdit && this.props.editorOnBeginEdit()
                                        }}
                                        text={shownText ? "Edit" : "Add"}
                                        type="outlined"
                                    />
                                )
                            }
                            {
                                // show cancel and save buttons when editing
                                this.state.editing && (
                                    <React.Fragment>
                                        <Button
                                            onClick={() => {
                                                this.toggleEditing()
                                                this.props.editorOnCancel && this.props.editorOnCancel()
                                            }}
                                            text="Cancel"
                                            type="outlined"
                                        />
                                        <Button
                                            leftIcon="save"
                                            leftIconFamily="far"
                                            onClick={() => {
                                                this.toggleEditing()
                                                this.props.editorOnSubmit && this.props.editorOnSubmit()
                                            }}
                                            text="Save"
                                            type="filled"
                                        />
                                    </React.Fragment>
                                )
                            }
                            {
                                (filterOptions && filterOptions.length > 1) && (
                                    <FilterButton
                                        selectedIndex={this.state.selectedTextIndex}
                                        menuOptions={filterOptions}
                                    />
                                )
                            }
                        </S.AccessoryArea>
                    </S.SectionHeader>
                }
                {this.renderUpdateText()}
                { this.state.editing ? (
                    <S.EditAreaWrapper>
                        <TextareaAutosize
                            // if there is no editorOnChange, just let the input field manage it's own state
                            value={this.props.editorOnChange ? this.props.editorValue : undefined}
                            placeholder={this.props.editorPlaceholder}
                            onChange={this.props.editorOnChange}
                            minRows={this.props.minEditorRows}
                            maxRows={Math.max(this.props.minEditorRows, this.props.maxEditorRows)}
                        />
                    </S.EditAreaWrapper>
                ) : (
                    <S.TextArea expanded={!this.state.collapsible || this.state.expanded} data-text-size={this.getSelectorTag()}>
                        {shownText ? shownText : <S.EmptyStateText>{this.props.emptyText}</S.EmptyStateText>}
                    </S.TextArea>
                )}
            </S.SectionContainer>
        )
    }
}

TextSection.defaultProps = {
    forceExpanded: false,
    headerText: "",
    minEditorRows: 6,
    maxEditorRows: 15,
}

TextSection.propTypes = {
    // true if this text section should show an edit button and be editable
    editable: PropTypes.bool,
    // callback for when the user hits the 'edit' button
    editorOnBeginEdit: PropTypes.func,
    // callback for when the user hits 'cancel' while editing
    editorOnCancel: PropTypes.func,
    // callback for when the value of input field changes; passed to input as onChange
    editorOnChange: PropTypes.func,
    //calback for when the user hits 'save' while editing
    editorOnSubmit: PropTypes.func,
    // placeholder value of the text section input field (when editing)
    editorPlaceholder: PropTypes.string,
    // value of the text section input field (when editing)
    editorValue: PropTypes.string,

    // text to display if the shown text section is empty
    emptyText: PropTypes.string,

    // true if the text section should be forced to full height at all times, with no collapse button
    forceExpanded: PropTypes.bool,
    // title text of the text section
    headerText: PropTypes.string,

    // minimum and maximum heights for the text edit area when in edit mode
    minEditorRows: PropTypes.number,
    maxEditorRows: PropTypes.number,

    // array of objects describing the different texts shown by this component
    // the section labels are used to populate the filter button which changes between sections
    textSections: PropTypes.arrayOf(
        PropTypes.shape({
            sectionOnClick: PropTypes.func,
            sectionIcon: PropTypes.string,
            sectionIconFamily: PropTypes.string,
            sectionLabel: PropTypes.string,
            // can be a string of text to render in the text section, or
            // an array of react elements, such as those created from
            // text marked up in HTML
            text: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.arrayOf(PropTypes.element)
            ]),
        })),

    // strings that will be formated for display next to the title text
    updatedDate: PropTypes.string,
    updatedName: PropTypes.string,
    updatedTime: PropTypes.string,
}

export default TextSection

import { Button } from "react-bootstrap"
import styled from "styled-components"

import { constants } from "QuorumGrassroots/constants"
import {
    getDynamicColorHex,
    getFontColorForBackgroundContrast,
    styleWithOrgDesignHelper,
} from "QuorumGrassroots/styled-components/helperFunctions"

const StyledButtonComponent = styled(Button)`
    width: 100%;
    font-size: 22px;
    padding: 10px 15px;
    margin-bottom: ${(props) => props.marginBottom || 0}px;
    word-wrap: break-word;
    white-space: normal;
    background-color: ${(props) => props.organizationDesign.primary_color};
    border: 1px solid ${(props) => props.organizationDesign.primary_color};
    color: white !important;

    ${(props) =>
        (props.isCampaignPage || props.shouldUseAutoColor) &&
        `color: ${getFontColorForBackgroundContrast(
            getDynamicColorHex(props.organizationDesign.primary_color),
            constants.freshDarkGrey,
        )} !important;
        background-color: ${getDynamicColorHex(props.organizationDesign.primary_color)};
        border: none !important;
        font-size: 16px;
        min-height: 40px;
        outline: none;
        ${props.applyMarginTop ? `margin-top: 24px;` : ``}
        `}

    &:hover,
    &:focus,
    &:active {
        ${(props) =>
            !props.isCampaignPage && `background-color: ${props.organizationDesign.lighter_primary} !important;`}
        outline: none;

        ${(props) =>
            props.isCampaignPage &&
            `
            background-color: ${getDynamicColorHex(props.organizationDesign.primary_color, true)} !important;
            transition: background-color 0.25s ease-in-out;
            border: none !important;
            font-size: 16px;
            min-height: 40px;
            outline: none;
            `}
    }
`

export const StyledButton = styleWithOrgDesignHelper(StyledButtonComponent)

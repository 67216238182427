import styled from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const FormProgressBar = styled.div`
    position: sticky;
    top: ${props => props.isNewDesign ? "0px" : rules.NavigationalTabHeight};
    width: 100%;
    height: 7px;
    background-color: ${props => props.color};
    border-top: 1px solid ${colors.White};
    z-index: 1;
`

export const FilledProgress = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${props => props.color};

    /* The transform rule is more efficient for the browser to animate! */
    transform: scaleX(clamp(0, calc(${props => props.progress}), 1));
    transform-origin: left;
    transition: transform .8s;
    will-change: transform;
`

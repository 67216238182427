import { createSelector, createStructuredSelector } from "reselect"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"

import i18n from "i18n"

import * as frameworkSelectors from "QuorumGrassroots/framework/selectors"

export const selectCampaignList = createSelector(frameworkSelectors.selectCampaignList, (campaignList) => {
    return campaignList
        .filter(
            (campaign) => campaign.get("status") !== DjangIO.app.grassroots.campaign.types.CampaignStatus.draft.value,
        )
        .filter((campaign) => campaign.get("visible_on_action_center"))
        .sortBy((campaign) => -campaign.get("order"))
        .toJS()
})

// props.content may be specified for CampaignList widgets on Grassroots Pages
// props.content is the default 'no campaigns' message. Display if message is specified
// Otherwise, check if user is logged in and display logged in no campaign message
// If not logged in, display other no campaign message
export const selectCampaignNoneText = createSelector(
    (_, props) => props.content,
    frameworkSelectors.selectUserLoggedIn,
    (defaultMessage, userLoggedIn) => {
        const campaignNoneText =
            defaultMessage ||
            (userLoggedIn ? i18n.t("campaignList.no_campaigns") : i18n.t("campaignList.no_campaigns_not_logged_in"))

        return campaignNoneText
    },
)

export const selectCampaignListTitle = createSelector(
    (_, props) => props.title,
    (_, props) => props.isWidgetPage,
    (title, isWidgetPage) =>
        // If 'title' is defined from widget, display title
        // Otherwise, if this is a widget page (Campaigns List widget page), display "Campaigns"
        // Else, do not display a title
        title || (isWidgetPage ? i18n.t("campaignList.title.label") : ""),
)

/**
 * Returns false if all available campaigns are loaded or
 * additional campaigns are being fetched or
 * there are fewer campaigns than the pagination limit/amount
 * @name selectShouldLoadAdditionalCampaigns
 * @function
 * @param {Boolean} allCampaignsLoaded - if all available campaigns are loaded
 * @param {Boolean} additionalCampaignsLoading - if additional campaigns are currently being fetched
 * @param {Number} campaignAmount - number of campaigns currently loaded
 * @returns {Boolean} - Boolean if CampaignList component should load additional campaigns
 */
export const selectShouldLoadAdditionalCampaigns = createSelector(
    frameworkSelectors.selectCampaignListMeta("loaded"),
    frameworkSelectors.selectCampaignListMeta("loading"),
    frameworkSelectors.selectCampaignListAmount,
    (allCampaignsLoaded, additionalCampaignsLoading, campaignAmount) =>
        Boolean(
            !allCampaignsLoaded &&
                !additionalCampaignsLoading &&
                campaignAmount >= DjangIO.app.grassroots.types.GrassrootsContextField.campaign_list.pagination_amount,
        ),
)

export const campaignListSelectors = createStructuredSelector({
    campaignList: selectCampaignList,
    campaignListLoading: frameworkSelectors.selectCampaignListMeta("loading"),
    campaignNoneText: selectCampaignNoneText,
    loading: frameworkSelectors.selectCampaignListLoading,
    shouldLoadAdditionalCampaigns: selectShouldLoadAdditionalCampaigns,
    title: selectCampaignListTitle,
})

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/MediumCard/style"

import Icon from "Components/Atoms/Icon"

const MediumCard = ({ children, icon, iconFamily }) => (
    <S.MediumCard data-auto-cy="MoleculeMediumCard">
        { icon &&
            <S.Icon>
                <Icon icon={icon} iconFamily={iconFamily} size="2x"/>
            </S.Icon>
        }
        { children &&
            <S.Text icon={!!icon}>{children}</S.Text>
        }
    </S.MediumCard>
)

MediumCard.defaultProps = {}

MediumCard.propTypes = {
    children: PropTypes.string,
    icon: PropTypes.string,
    iconFamily: PropTypes.string,
}

export default MediumCard

import styled, {css} from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"


export const Tag = styled.span`
    ${props => props.isGiant ? rules.MediumTextLightWeight : rules.SmallTextLightWeight}
    color: ${colors.QuorumBlue};

    margin: ${props => props.isGiant ? "5px": "4px"};
    padding: ${props => props.isGiant ? "3px 10px" : "1px 5px"};
    white-space: nowrap;

    background-color: ${colors.DarkerPorcelain};
    border: 1px solid transparent;
    border-radius: 2px;

    ${props => props.isSelectable &&
        css`
            &:hover {
                background-color: ${colors.InlineHoverColor};
                border: 1px solid ${colors.SilverChalice};
            }
        `
    }

    ${props => props.isSelected &&
        css`
            background-color: ${colors.White};
            border: 1px solid ${colors.SilverChalice};
            &:hover {
                background-color: ${colors.unnamedFCFCFD};
            }
        `
    }

    cursor: ${props => props.onClick ? "pointer" : "unset"};
`

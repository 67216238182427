// https://projects.invisionapp.com/d/main/#/console/21044500/444626890/inspect

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Atoms/Highlight/style"

import { escapeRegExp } from "utils/helperFunctions"

// https://stackoverflow.com/a/47803998/6201291
export const Highlight = ({
    highlight = "",
    isActive,
    text = "",
}) => {
    if (!highlight.trim()) {
        return <span>{text}</span>
    }

    const regex = new RegExp(
        `(${escapeRegExp(highlight)})`,
        "gi",
    )

    return (
        <S.HighlightContainer
            data-auto-cy="AtomHighlight"
        >
            {
                text
                    .split(regex)
                    .filter(part => part)
                    .map((part, i) => (
                        regex.test(part)
                            ? (
                                <S.Highlight
                                    isActive={isActive}
                                    key={i}
                                >
                                    {part}
                                </S.Highlight>
                            )
                            : <span key={i}>{part}</span>
                        )
                    )
            }
        </S.HighlightContainer>
    )
}

Highlight.propTypes = {
    highlight: PropTypes.string,
    isActive: PropTypes.bool,
    text: PropTypes.string,
}

export default Highlight

import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useOnClickOutside } from "utils/hooks"
import { createPortal } from "react-dom"

import * as S from "Components/Compounds/ModalContainer/style"
import Icon from "Components/Atoms/Icon"

const ModalContainer = (props) => {
    const modalContainerRef = useRef()
    useOnClickOutside(modalContainerRef, (event) => {
        if (props.optionalHandleClickOutside) {
            props.optionalHandleClickOutside(event)
        }

        if (props.onClose) {
            props.onClose(event)
        }
    })

    return (
        createPortal(
            <S.Screen data-auto-cy="CompoundModalContainer">
                <S.ModalWrapper
                    clampWidth={props.clampWidth}
                    disableMaxWidth={props.disableMaxWidth}
                    fixedHeight={props.fixedHeight}
                    fixedTop={props.fixedTop}
                    ref={modalContainerRef}
                >
                    <S.ModalContainer
                        disableBorder={props.disableBorder}
                        hasLabel={Boolean(props.label)}
                    >
                        {
                            props.label &&
                                <S.LabelHeader>
                                    <S.Label>
                                        { props.label }
                                    </S.Label>
                                    <S.Icon
                                        onClick={props.onClose}
                                    >
                                        <Icon icon="times" fixedWidth={false}/>
                                    </S.Icon>
                                </S.LabelHeader>
                        }
                        <S.Content centered={Boolean(props.label)}>
                            { props.children }
                        </S.Content>
                    </S.ModalContainer>
                </S.ModalWrapper>
            </S.Screen>,
            document.body
        )
    )
}

ModalContainer.propTypes = {
    children: PropTypes.element,
    disableBorder: PropTypes.bool,
    // If true, the modal will not expand width-wise further than some maximum width,
    // regardless of screen size
    clampWidth: PropTypes.bool,
    disableMaxWidth: PropTypes.bool,
    fixedHeight: PropTypes.bool,
    fixedTop: PropTypes.string,
    label: PropTypes.string,
    onClose: PropTypes.func,
    optionalHandleClickOutside: PropTypes.func,
}
ModalContainer.defaultProps = {
    clampWidth: true,
}

export default ModalContainer

import React from "react"
import PropTypes from "prop-types"
import { centsToCurrencyStr } from "shared/pac/helperFunctions"

import {
    StyledDonationHistoryContainer,
    StyledDonationHistoryFirstSection,
    StyledDonationHistorySecondSection,
    StyledDonationHistorySectionContent,
    StyledDonationHistorySectionContentHeading,
    StyledDonationHistorySectionContentItem,
    StyledDonationHistorySectionContentItemLabel,
    StyledDonationHistorySectionTitle,
    StyledDonationHistoryTitle,
    StyledDonationHistoryWrapper,
} from "./style"

export const DonationHistoryWidget = ({
    contributionsByYear,
    lastContributionDate,
    lastContributionMethod,
    lastContributionValue,
    lastTransactionAmount,
    lastTransactionDate,
    lastTransactionMethod,
    title,
    transactionTotalsByYear,
}) => {
    const lastDate = lastTransactionDate || lastContributionDate
    const lastMethod = lastTransactionMethod || lastContributionMethod
    const lastValue = lastTransactionAmount === 0 ? 0 : lastTransactionAmount || lastContributionValue
    const lastValueStr = centsToCurrencyStr(lastValue)
    // 0 is falsey, so we want to explictly account for it before using the `||` method.
    const totalsByYear = transactionTotalsByYear || contributionsByYear
    // Uncomment the below if you are debugging this widget.
    // console.log("DonationHistoryWidget", {
    //     contributionsByYear,
    //     lastContributionDate,
    //     lastContributionMethod,
    //     lastContributionValue,
    //     lastTransactionAmount,
    //     lastTransactionDate,
    //     lastTransactionMethod,
    //     title,
    //     transactionTotalsByYear,
    // })
    return (
        <StyledDonationHistoryContainer>
            {title && (
                <StyledDonationHistoryTitle>
                    <div data-cy="title">{title}</div>
                </StyledDonationHistoryTitle>
            )}
            <StyledDonationHistoryWrapper>
                <StyledDonationHistoryFirstSection>
                    <StyledDonationHistorySectionTitle>Last contribution</StyledDonationHistorySectionTitle>
                    <StyledDonationHistorySectionContent>
                        <StyledDonationHistorySectionContentItem>
                            <StyledDonationHistorySectionContentHeading>
                                {lastDate}
                            </StyledDonationHistorySectionContentHeading>
                            <StyledDonationHistorySectionContentItemLabel>
                                Contributed {lastValueStr} {lastValue > 0 && `via ${lastMethod}`}
                            </StyledDonationHistorySectionContentItemLabel>
                        </StyledDonationHistorySectionContentItem>
                    </StyledDonationHistorySectionContent>
                </StyledDonationHistoryFirstSection>
                <StyledDonationHistorySecondSection>
                    <StyledDonationHistorySectionTitle>Totals per year</StyledDonationHistorySectionTitle>
                    <StyledDonationHistorySectionContent>
                        {totalsByYear.map((total) => {
                            let value = centsToCurrencyStr(total.value)
                            return (
                                <StyledDonationHistorySectionContentItem>
                                    <StyledDonationHistorySectionContentHeading>
                                        {total.year}
                                    </StyledDonationHistorySectionContentHeading>
                                    <StyledDonationHistorySectionContentItemLabel>
                                        {value}
                                    </StyledDonationHistorySectionContentItemLabel>
                                </StyledDonationHistorySectionContentItem>
                            )
                        })}
                    </StyledDonationHistorySectionContent>
                </StyledDonationHistorySecondSection>
            </StyledDonationHistoryWrapper>
        </StyledDonationHistoryContainer>
    )
}

DonationHistoryWidget.propTypes = {
    contributionsByYear: PropTypes.array,
    lastContributionDate: PropTypes.string,
    lastContributionMethod: PropTypes.string,
    lastContributionValue: PropTypes.number,
    lastTransactionAmount: PropTypes.number,
    lastTransactionDate: PropTypes.string,
    lastTransactionMethod: PropTypes.string,
    title: PropTypes.string,
    transactionTotalsByYear: PropTypes.array,
}

export default DonationHistoryWidget

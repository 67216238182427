import styled from "styled-components"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { getFontColorForBackgroundContrast } from "QuorumGrassroots/styled-components/helperFunctions"

export const StyledSkeletonLoading = styleWithOrgDesign(styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: stretch;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 1rem;
    gap: 1rem;
    ${(props) => `background-color: ${getFontColorForBackgroundContrast(props.organizationDesign.primary_color)};`};

    div {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }
`) as React.FC<any>

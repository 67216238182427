import styled from "styled-components"

import {
    getFontColorForBackgroundContrast,
    styleWithOrgDesignHelper,
    styleInMobileScreenSize,
} from "QuorumGrassroots/styled-components/helperFunctions"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { constants } from "QuorumGrassroots/constants"

const progressBarHeight = "35px"

export const StyledProgressBarWrapper = styleWithOrgDesign(styled.div`
    height: ${progressBarHeight};
    background-color: white;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    margin-top: 24px;
    margin-bottom: 24px;

    ${(props) =>
        props.isCampaignPage &&
        `
        background-color: transparent;
        box-shadow: none;
        -webkit-box-shadow: none;
        display: flex;
        ${styleInMobileScreenSize(`
            height: unset;
            flex-direction: column;
        `)}
    `}
`)

const BaseProgressBar = styled.div`
    height: ${progressBarHeight};
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
`

export const StyledProgressBar = styleWithOrgDesign(styled(BaseProgressBar)`
    width: ${(props) => props.currentPercent}%;
    min-width: 20%;
    max-width: 65%;
    background-color: ${(props) => props.organizationDesign.primary_color};
    float: left;
    color: white;

    ${(props) =>
        props.isCampaignPage &&
        `
        width: 50%;
        border-radius: 4px;

        ${styleInMobileScreenSize(`
            width: 100%;
            max-width: 100%;
            text-align: center;
        `)}
    `}
`)

export const StyledProgressBarNegative = styleWithOrgDesign(styled(BaseProgressBar)`
    width: ${(props) => props.percentLeft}%;
    min-width: 35%;
    max-width: 80%;
    background-color: white;
    float: right;
    color: ${(props) => props.organizationDesign.primary_color};

    ${(props) =>
        props.isCampaignPage &&
        `
        width: 50%;
        justify-content: flex-start;

        background-color: inherit;
        color: ${constants.freshDarkGrey};

        margin-bottom: 0;
        margin-left: 8px;


        ${styleInMobileScreenSize(`
            width: 100%;
            max-width: 100%;
            justify-content: center;
            & > span {
                text-align: center;

                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
            }
        `)}
    `}
`)

export const StyledProgressBarText = styleWithOrgDesignHelper(styled.span`
    padding-left: 10px;
    padding-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    ${(props) =>
        props.isCampaignPage &&
        `
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        padding-right: 0;
        padding-left: 0;

        color: ${
            props.isNegative
                ? constants.freshDarkGrey
                : getFontColorForBackgroundContrast(props.organizationDesign.primary_color)
        };
    `}
`)

import React, { useState } from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/Timeline/style"

import * as colors from "Components/colors"
import * as helperFunctions from "utils/helperFunctions"

import Icon from "Components/Atoms/Icon"
import CircleImage from "Components/Molecules/CircleImage"

import { useOffsetHeight } from "utils/hooks"

export const TOP_ELEMENT_REF = "_topElementHeightRef"
export const BOTTOM_ELEMENT_REF = "_bottomElementHeightRef"

// Args:
//  numEvents (number): number of events for the timeline item
// Returns
//  (string):   one of "(1 Event)" / "(# Events)"
const getEventPluralization = (numEvents) => {
    return ` (${numEvents || 0} Event${numEvents === 1 ? "" : "s"})`
}

/**
 * Get the number of all the events.
 *
 * @param {array} datedEvents - array of datedEvent obj
 * @returns {number} Returns all the (nested) events. (different from DatedEvents)
 */
export const getTotalEvents = (datedEvents) => datedEvents && datedEvents.length &&
    datedEvents.reduce((acc, datedObj) => acc + (datedObj.events ? datedObj.events.length : 0), 0)

/**
 * Get the number of all events within the FIRST datedEvent
 *
 * @param {array} datedEvents - array of datedEvent obj
 * @returns {number}
 */
export const getFirstDateEvents = (datedEvents) => (datedEvents && datedEvents.length && datedEvents[0].events && datedEvents[0].events.length) || 0

/**
 * Determines if there is ONLY ONE event. (Different from datedEvent)
 *
 * @param {array} datedEvents - array of datedEvent obj
 * @returns {boolean}
 */
export const hasOnlyOneEntry = datedEvents => datedEvents
    && datedEvents.length === 1
    && datedEvents[0]
    && datedEvents[0].events
    && datedEvents[0].events.length === 1

/**
 * Determines if the current datedEvent has nested events && that the timeline is expanded or is the first datedEvent
 * This is used to determine whether or not the render the event entries.
 * @param {array} datedEvents - array of datedEvent obj
 * @returns {boolean}
 */
export const datedEventHasEvents = (idx, collapsed, datedObj) => (idx === 0 || !collapsed) &&
    datedObj.events &&
    datedObj.events.length > 0

export const shouldShowCloseButton = (collapsible, collapsed, datedEvents) => collapsible &&
    !collapsed &&
    datedEvents.length > 1

const renderDateRange = (start, end) => `${start}-${end}`

const Timeline = ({ datedEvents, emptyText, title }) => {
    const [collapsed, setCollapsed] = useState(true)

    // Measurements for handling line runoff
    // we measure the height dateRange span at the bottom
    const [lastEntryRef, lastEntryHeight] = useOffsetHeight()
    // we also measure the height firstTextEvent div at the top
    const [firstTextEvent, firstTextEventHeight] = useOffsetHeight()

    // count the total number of events, which is shown in the title
    const totalEvents = getTotalEvents(datedEvents)

    const firstDateEvents = getFirstDateEvents(datedEvents)

    const onlyOneEntry = hasOnlyOneEntry(datedEvents)


    // if there are only as many total events as events shown under the first date, we don't need to
    // have the "open all" / "close" button
    const collapsible = totalEvents !== firstDateEvents

    // if we aren't collapsible, set the state to expanded to use the expanded styling
    if (!collapsible && collapsed) {
        setCollapsed(false)
    }

    const isIE11 = helperFunctions.isUserIE11()

    return (
        <S.Container data-auto-cy="CompoundTimeline">
            <S.TopBarContainer>
                <S.TitleSpan>
                    {title} {getEventPluralization(totalEvents)}
                </S.TitleSpan>
                {collapsible && (
                    <S.Expand onClick={() => setCollapsed(!collapsed)}>
                        {collapsed ? "Open All" : "Close"}
                        <Icon icon={collapsed ? "angle-down" : "angle-up"} iconFamily="fal" />
                    </S.Expand>
                )}
            </S.TopBarContainer>
            <S.Timeline>
                <S.Line lastEntryHeight={lastEntryHeight} firstTextEventHeight={firstTextEventHeight} onlyOneEntry={onlyOneEntry} collapsed={collapsed} />
                {datedEvents && datedEvents.length > 0 ? collapsed ? (
                    <div>
                        {datedEvents[0].events && datedEvents[0].events.map((datedEvent, firstEventsIdx) => {
                            return (
                                <S.Entry key={datedEvent.text} ref={firstEventsIdx === 0 ? firstTextEvent : null} data-heightref={firstEventsIdx === 0 ? TOP_ELEMENT_REF : null}>
                                    {firstEventsIdx === 0 ? (
                                        <S.DateTextSpan> {datedEvents[0].date} </S.DateTextSpan>
                                    ) : (
                                            <S.EmptyDateTextSpan />
                                        )}
                                    <S.CircleWrapper isUserIE11={isIE11}>
                                        <CircleImage
                                            boldText
                                            // this weird font size forces the icon to round to 23px wide,
                                            // which allows it to be centered in an odd-pixel-wide cricle
                                            fontSize="18.25px"
                                            icon={datedEvent.icon}
                                            iconFamily={datedEvent.iconFamily}
                                            fillColor="white"
                                            fontColor={colors.QuorumBlue}
                                            circleColor={colors.QuorumBlue}
                                            text={datedEvent.circleText}
                                            diameter="35px"
                                        />
                                    </S.CircleWrapper>
                                    <S.EventTextSpan>{datedEvent.text}</S.EventTextSpan>
                                </S.Entry>
                            )
                        })}

                        <S.Entry>
                            {datedEvents.length > 1 && (
                                <S.DateRangeSpan ref={collapsed ? lastEntryRef : null} data-heightref={collapsed ? BOTTOM_ELEMENT_REF : null} isUserIE11={isIE11}>
                                    {`${renderDateRange(
                                        datedEvents[1].date,
                                        datedEvents[datedEvents.length - 1].date
                                    )}`}
                                </S.DateRangeSpan>
                            )}
                            <S.Dot isUserIE11={isIE11} />
                            <S.NumberOfEventsDiv>{`${Number(totalEvents) -
                                Number(datedEvents[0].events && datedEvents[0].events.length)} Events`}</S.NumberOfEventsDiv>
                        </S.Entry>
                    </div>
                ) : (
                        // NOT collapsed
                        datedEvents.map((datedObj, idx) => {
                            return (
                                <React.Fragment key={datedObj.date}>
                                    { datedEventHasEvents(idx, collapsed, datedObj) &&
                                        datedObj.events.map((event, eventIdx) => {
                                            const firstEvent = idx === 0 && eventIdx === 0
                                            const lastEvent = idx === datedEvents.length - 1 && eventIdx === datedObj.events.length - 1
                                            return (
                                                <div key={event.text}>
                                                    <S.Entry
                                                        ref={firstEvent
                                                            ? firstTextEvent : lastEvent
                                                                ? lastEntryRef : null}
                                                        data-heightref={firstEvent
                                                            ? TOP_ELEMENT_REF : lastEvent
                                                                ? BOTTOM_ELEMENT_REF : null}
                                                    >
                                                        {eventIdx === 0 ? (
                                                            <S.DateTextSpan>{datedObj.date}</S.DateTextSpan>
                                                        ) : (
                                                                <S.DateTextSpan />
                                                            )}
                                                        <S.CircleWrapper isUserIE11={isIE11}>
                                                            <CircleImage
                                                                boldText
                                                                // this weird font size forces the icon to round to 23px wide,
                                                                // which allows it to be centered in an odd-pixel-wide cricle
                                                                fontSize="18.25px"
                                                                icon={event.icon}
                                                                iconFamily={event.iconFamily}
                                                                fillColor="white"
                                                                fontColor={colors.QuorumBlue}
                                                                circleColor={colors.QuorumBlue}
                                                                text={event.circleText}
                                                                diameter="35px"
                                                            />
                                                        </S.CircleWrapper>
                                                        <S.EventTextSpan>{event.text}</S.EventTextSpan>
                                                    </S.Entry>
                                                </div>
                                            )
                                        })}
                                </React.Fragment>
                            )
                        })
                    ) : (
                        emptyText && <S.EmptyStateText>{emptyText}</S.EmptyStateText>
                    )}
                {
                    shouldShowCloseButton(collapsible, collapsed, datedEvents) &&
                    (
                        <S.Expand onClick={() => setCollapsed(!collapsed)} atBottom={true}>
                            Close
                        </S.Expand>
                    )}
            </S.Timeline>
        </S.Container>
    )
}

Timeline.defaultProps = {}

Timeline.propTypes = {
    // an array of objects describing sets of events matched with a date
    // the event sets (and events within the sets) are displayed from top to bottom
    datedEvents: PropTypes.arrayOf(
        PropTypes.shape({
            // the date that corresponds to this set of events
            date: PropTypes.string,
            // the ordered set of events displayed under the associated date
            events: PropTypes.arrayOf(
                PropTypes.shape({
                    // text to display in the circle for this event;
                    circleText: PropTypes.string,
                    // icon to display in the circle for this event
                    icon: PropTypes.string,
                    // family of the icon to show in the circle
                    iconFamily: PropTypes.string,
                    // the text for this event
                    text: PropTypes.string
                })
            )
        })
    ),
    // the title of the timeline, to be shown in its header
    title: PropTypes.string
}

export default Timeline

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/Switch/style"

const Switch = ({
    children,
    dataCy,
    disabled,
    onChange,
    value,
}) => {
    const [isChecked, setIsChecked] = useState(value)
    useEffect(() => setIsChecked(value), [value])

    const handleOnChange = (e) => {
        setIsChecked(e.target.checked)
        onChange(e)
    }

    return (
        <S.Switch
            data-auto-cy="MoleculeSwitch"
            data-cy={dataCy}
            disabled={disabled}
        >
            <S.Container isChecked={isChecked} disabled={disabled}>
                <S.CheckboxInput
                    aria-disabled={disabled}
                    disabled={disabled}
                    checked={isChecked}
                    onChange={handleOnChange}
                />
                    <S.Knob isChecked={isChecked} />
            </S.Container>
            <S.Children checked={isChecked}>
                {children}
            </S.Children>
        </S.Switch>
    )
}

Switch.defaultProps = {
    onChange: () => {}
}

Switch.propTypes = {
    children: PropTypes.element,
    dataCy: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.bool,
}

export default Switch

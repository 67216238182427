import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const TopBarSearchify = styled.div`
    align-items: center;
    background-color: ${colors.gray1};
    color: ${colors.gray7};
    display: flex;
    height: 60px;
    padding-left: 20px;

    ${(props) => props.isMobile && css`
        font-size: 0.875rem;
        gap: 1rem;
        overflow: scroll;
        padding:0 1rem;
        white-space: nowrap;
    `}
`

export const TopBarSearchifyCountWrapper = styled.div`
    display: flex;
`

export const TopBarSearchifyLabelsContainer = styled.div`
    align-items: center;
    border-right: 1px solid ${colors.QuorumGrey3};
    cursor: pointer;
    display: flex;
    height: 100%;

    ${(props) => !props.isMobile && css`
        margin-right: 20px;
        padding-right: 20px;
    `}

    ${(props) => props.isMobile && css`
        padding-right: 1rem;
    `}
`

export const TopBarSearchifyMediumTitle = styled.span`
    ${rules.MediumTextMediumWeight}
    align-items: center;
    display: flex;
    `

export const TooltipContainer = styled.div`

    position: relative;
    display: inline-block;


    & > :first-child {
        position: absolute;
        transform: translate(100%,-13%);
        min-width: 3em;
        z-index: 10000;
    }
`

export const TopBarSearchifyRegularTitle = styled.div`
    ${rules.MediumTextRegularWeight}
    align-items: center;
    display: flex;
    margin-left: 8px;

    &:not(:hover) {
        & > ${TooltipContainer} {
            > :first-child {
                opacity: 0%;
                visibility: hidden;
                transition-duration: ${rules.TransitionShort};
            }
        }
    }

    &:hover {
        & > ${TooltipContainer} {
            & > :first-child {
                opacity: 100%;
                visibility: visible;
                transition-duration: ${rules.TransitionShort};
            }
        }
    }
    transition-duration: ${rules.TransitionShort};
`

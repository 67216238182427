import React from "react"
import PropTypes from "prop-types"

import ModalContainer from "Components/Compounds/ModalContainer"
import SplashScreen from "Components/Molecules/SplashScreen"

const SplashModal = ({featureValue, label, acknowledgeSplash, helpCenterURL, splashTitle, splashParagraph, splashCards}) => (
    <ModalContainer
        data-auto-cy="CompoundSplashModal"
        label={label}
        onClose={() => acknowledgeSplash({ featureValue })}
    >
        <SplashScreen
            primaryAction={() => acknowledgeSplash({ featureValue })}
            secondaryAction={() => acknowledgeSplash({ featureValue, helpCenterURL })}
            splashTitle={splashTitle}
            splashParagraph={splashParagraph}
            splashCards={splashCards}
        />
    </ModalContainer>
)

SplashModal.defaultProps = {}

SplashModal.propTypes = {
    featureValue: PropTypes.number,
    label: PropTypes.string,
    acknowledgeSplash: PropTypes.func,
    splashTitle: PropTypes.string,
    splashParagraph: PropTypes.string,
    splashCards: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
    })),
}

export default SplashModal

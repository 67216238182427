import React, { Component } from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"
import { Helmet } from "react-helmet"
import queryString from "query-string"

import { trimPath, runUserJavascript, updateOrCreateStyleTagWithId } from "QuorumGrassroots/helperFunctions"

import Page404 from "QuorumGrassroots/framework/components/404/index"
import WidgetMap from "QuorumGrassroots/widgets/WidgetMap"
import { StyledRow, StyledPage, StyledHalfPageCol } from "QuorumGrassroots/framework/components/Page/style"

export default class Page extends Component {
    static propTypes = {
        getPageRegionFilters: PropTypes.func,
        hasValidPageRegion: PropTypes.bool,
        isCampaignPage: PropTypes.bool,
        isWidgetPage: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }),
        pageWidgets: PropTypes.arrayOf(
            PropTypes.shape({
                widgetType: PropTypes.number,
                style: PropTypes.object,
                props: PropTypes.object,
            }),
        ),
        params: PropTypes.shape({
            [PropTypes.string]: PropTypes.string,
        }),
        paramsRegion: PropTypes.shape({
            district_type: PropTypes.string,
            [PropTypes.string]: PropTypes.string,
        }),
        regionFilters: PropTypes.shape({
            geo_shape_id: PropTypes.number,
            geo_shape_type: PropTypes.number,
            region_filters: PropTypes.shape({
                [PropTypes.string]: PropTypes.number,
            }),
            state_region_enum: PropTypes.number,
        }),
        regionKey: PropTypes.string,
        usePageRegion: PropTypes.bool,
        campaignBackgroundColor: PropTypes.string,
        isFullBleedHeaderNone: PropTypes.bool,
        isTransparent: PropTypes.bool,
        logoOnly: PropTypes.bool,
        isCampaignPage: PropTypes.bool,
        campaignBackgroundStyle: PropTypes.number,
    }

    state = { frameless: queryString.parse(this.props.location.search).frameless }

    loadRegionFilters() {
        this.props.getPageRegionFilters(this.props.paramsRegion, this.props.regionKey)
    }

    initializePage() {
        if (this.props.customJs) {
            runUserJavascript(this.props.customJs)
        }
        this.updateCustomCss(this.props.customCss)
    }

    componentDidMount() {
        this.initializePage()

        if (this.props.usePageRegion && !this.props.regionFilters) {
            this.loadRegionFilters()
        }
    }

    componentWillUnmount() {
        this.updateCustomCss("")
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.initializePage()
        }

        if (this.props.usePageRegion && !this.props.regionFilters) {
            this.loadRegionFilters()
        }
    }

    updateCustomCss(css) {
        updateOrCreateStyleTagWithId("CustomPageCSS", css)
    }

    /**
     * Map over listWidgets and return the widget component
     * Sets the widget width with the 'widgetWidth' argument
     * Sets the classname based on the 'widgetCount' argument, increment there are more widgets rendered
     * @param {Array} listWidgets - list of widgets
     * @param {Number} widgetWidth - The bootstrap width a widget should be sized
     * @param {Number} widgetCount - The current count of the widget, incremented each time a widget is rendered
     * @returns {boolean}
     */
    renderListWidgets(listWidgets, widgetWidth, widgetCount) {
        /**
         * In React Router v4, this.props.match.url used to include only the parts of the path that matched the literal
         * and dynamic segments. eg: Given the path "/event/:eventId/", the URL "/event/123/thanks/" would return "event/123"
         * But in React Router v6, this is no longer available, so we use location.pathanme, which returns "event/123/thanks",
         * which doesn't match the existing widgetId slice of the store, and things break!
         *
         * From what I can tell, we don't have any paths nested deeper than `${widget_url}/${widget_url_params}/thanks`,
         * so this should be fine.
         */
        const trimmedUrl = trimPath(this.props.location.pathname).replace("/thanks", "")

        return listWidgets.map((widget) => {
            const WidgetComponent = WidgetMap[widget.widgetType]

            const widgetEnum = DjangIO.app.grassroots.types.GrassrootsWidgetType.by_value(widget.widgetType)

            const uniqueWidgetId =
                widget.props && widget.props.id ? `${trimmedUrl}_${widgetEnum.key}_${widget.props.id}` : trimmedUrl

            const pageProps = widget.props || {}
            const currentWidgetCount = widgetCount++

            return (
                // please change the key later when we start using pages.
                <div
                    className={`widget-wrapper widget-wrapper-${currentWidgetCount}`}
                    key={`${uniqueWidgetId}_${widget.widgetType}_${JSON.stringify(pageProps)}`}
                >
                    <WidgetComponent
                        {...this.props}
                        className={`widget widget-${currentWidgetCount}`}
                        widgetType={widget.widgetType}
                        uniqueWidgetId={uniqueWidgetId}
                        isEmbedded={!this.props.isWidgetPage}
                        widgetWidth={widgetWidth}
                        widgetNumber={currentWidgetCount}
                        {...widget.props}
                    />
                </div>
            )
        })
    }

    renderWidgets() {
        const { pageWidgets, widgetLayoutType } = this.props
        const { GrassrootsPageWidgetLayoutType } = DjangIO.app.grassroots.types

        // Start page and widget counts at 1. Each time a page or widget is rendered, increment count by 1
        let pageColCount = 1
        let widgetCount = 1

        const renderTwoColumnPageWidgets = (leftColumnWidgetSlice, rightColumnWidgetSlice, keySuffix = "") => [
            <StyledHalfPageCol
                className={`page-col page-col-${pageColCount++}`}
                key={`left-col-${keySuffix}`}
                md={6}
                leftCol
            >
                {this.renderListWidgets(leftColumnWidgetSlice, 6, widgetCount++)}
            </StyledHalfPageCol>,
            <StyledHalfPageCol className={`page-col page-col-${pageColCount++}`} key={`right-col-${keySuffix}`} md={6}>
                {this.renderListWidgets(rightColumnWidgetSlice, 6, widgetCount++)}
            </StyledHalfPageCol>,
        ]

        const widgets = []

        switch (widgetLayoutType) {
            case GrassrootsPageWidgetLayoutType.two_columns.value:
                // 1st value in left column, 2nd value in right column
                widgets.push(...renderTwoColumnPageWidgets(pageWidgets.slice(0, 1), pageWidgets.slice(1)))
                break

            case GrassrootsPageWidgetLayoutType.right_full_column.value:
                // 1st and 2nd value in left column, 3rd value in right column
                widgets.push(...renderTwoColumnPageWidgets(pageWidgets.slice(0, -1), pageWidgets.slice(-1)))
                break

            case GrassrootsPageWidgetLayoutType.left_full_column.value:
                // 1st value in left column, 2nd and 3rd values in right column
                widgets.push(...renderTwoColumnPageWidgets(pageWidgets.slice(0, 1), pageWidgets.slice(1)))
                break

            case GrassrootsPageWidgetLayoutType.two_rows_half_bottom.value:
                // First value in separate column
                widgets.push(
                    <Col className={`page-col page-col-${pageColCount++}`} key="top-full" md={12}>
                        {this.renderListWidgets(pageWidgets.slice(0, 1), 12, widgetCount)}
                    </Col>,
                )
                // Second value in left column, 3rd value in right column
                widgets.push(...renderTwoColumnPageWidgets(pageWidgets.slice(1, 2), pageWidgets.slice(2)))
                break

            case GrassrootsPageWidgetLayoutType.two_rows_half_top.value:
                // 1st in left column, 2nd value in right column
                widgets.push(...renderTwoColumnPageWidgets(pageWidgets.slice(0, 1), pageWidgets.slice(1, 2)))
                // 3rd value in separate column
                widgets.push(
                    <Col className={`page-col page-col-${pageColCount++}`} key="bottom-full" md={12}>
                        {this.renderListWidgets(pageWidgets.slice(2), 12, widgetCount)}
                    </Col>,
                )
                break

            case GrassrootsPageWidgetLayoutType.four_square.value:
                // 1st and 2nd values in left column, 3rd and 4th values in right column
                widgets.push(...renderTwoColumnPageWidgets(pageWidgets.slice(0, 2), pageWidgets.slice(2)))
                break

            case GrassrootsPageWidgetLayoutType.one_top_four_bottom.value:
                // First value in separate column
                widgets.push(
                    <Col className={`page-col page-col-${pageColCount++}`} key="top-full" md={12}>
                        {this.renderListWidgets(pageWidgets.slice(0, 1), 12, widgetCount)}
                    </Col>,
                )
                // 2nd and 3rd values in left column, 4th and 5th in right column
                widgets.push(...renderTwoColumnPageWidgets(pageWidgets.slice(1, 3), pageWidgets.slice(3)))
                break

            case GrassrootsPageWidgetLayoutType.three_left_two_right.value:
                // 1st-3rd values in left column, 4th and 5th in right column
                widgets.push(...renderTwoColumnPageWidgets(pageWidgets.slice(0, 3), pageWidgets.slice(3)))
                break

            case GrassrootsPageWidgetLayoutType.two_left_three_right.value:
                // 1st and 2nd values in left column, 3rd-5th values in right column
                widgets.push(...renderTwoColumnPageWidgets(pageWidgets.slice(0, 2), pageWidgets.slice(2)))
                break

            case GrassrootsPageWidgetLayoutType.six_square.value:
                // 1st-3rd values in left column, 4th-6th values in right column
                widgets.push(...renderTwoColumnPageWidgets(pageWidgets.slice(0, 3), pageWidgets.slice(3)))
                break

            default:
                widgets.push(
                    <Col className={`page-col page-col-${pageColCount++}`} key="full" md={12}>
                        {this.renderListWidgets(pageWidgets, 12, widgetCount)}
                    </Col>,
                )
                break
        }

        return (
            <StyledRow className="widget-row" frameless={this.state.frameless}>
                {widgets}
            </StyledRow>
        )
    }

    render() {
        if (this.props.usePageRegion && !this.props.hasValidPageRegion) {
            return <Page404 />
        }
        return (
            <StyledPage className="page" {...this.props} frameless={this.state.frameless}>
                <Helmet>
                    {/* Add Helmet to change browser title of page */}
                    <title>{this.props.pageTitle}</title>
                </Helmet>
                {this.renderWidgets()}
            </StyledPage>
        )
    }
}

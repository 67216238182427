import React from "react";
import PropTypes from "prop-types";

import * as S from "Components/Compounds/Badge/style"
import * as colors from "Components/colors"

const resolveBadgeValue = (value, maxValue) => {
    switch(typeof value) {
        case "string":
            // TODO: Return a substr(0, maxValue)...?
            // Return the whole string
            return value
        case "function":
            // Recursively call fn until result is a string or number
            return resolveBadgeValue(value(), maxValue)
        case "number":
            // Return the value if we can, otherwise the maxValue+
            return value <= maxValue ? value : `${maxValue}+`
        default:
            // Assume a boolean
            return Boolean(value)
    }
}

const Badge = ({ backgroundColor, color, forceShowBadge, maxValue, value, children }) => {
    const badgeValue = resolveBadgeValue(value, maxValue)

    return (
        <S.WrappedComponent
            data-auto-cy="CompoundBadge"
            hasValue={(badgeValue || forceShowBadge)}
        >
            {children}
            {(badgeValue || forceShowBadge) &&
                <S.Badge
                    backgroundColor={backgroundColor}
                    color={color}
                    small={(typeof badgeValue === "boolean")}
                >
                    {badgeValue}
                </S.Badge>
            }
        </S.WrappedComponent>
    )
}

Badge.defaultProps = {
    backgroundColor: colors.QuorumBlue,
    color: colors.White,
    forceShowBadge: false,
    maxValue: 9,
}

Badge.propTypes = {
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    forceShowBadge: PropTypes.bool,
    maxValue: PropTypes.number,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.func,
        PropTypes.bool
    ]).isRequired,
}

export default Badge

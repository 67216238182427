import styled from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const CounterMessage = styled.div`
    ${rules.text.medium}
`
export const MessageContainer = styled.div`
    color: ${colors.QuorumBlue};

    & > b {
        ${rules.weight.bold}
    }

    svg {
        margin-right: 10px;
    }
`

import React, { useEffect, useState } from "react"
import { StyledSegmentedControl } from "QuorumGrassroots/styled-components/components/StyledSegmentedControl"
import { StyledSelect } from "QuorumGrassroots/styled-components/components/StyledSelect"

export const GroupSelector = (props) => {
    const [isDesktop, setIsDesktop] = useState(window?.innerWidth >= 1280)

    useEffect(() => {
        const handleResize = () => setIsDesktop(window.innerWidth >= 1280)
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    const shouldShowDesktopTypeSelector = isDesktop || [2, 3].includes(props.data?.length)
    if (shouldShowDesktopTypeSelector) {
        return <StyledSegmentedControl {...props} />
    }
    return <StyledSelect {...props} />
}

export const StyledGroupSelector = GroupSelector

import React, { useState } from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/CollapsibleGrid/style"
import Icon from "Components/Atoms/Icon"

const CollapsibleGrid = ({children, initialRowCount, isOpen, label, numColumns}) => {
    const  [isOpenState, setIsOpenState] = useState(isOpen)

    // Calculate the number of items that should initially be visible
    const initialItemCount = initialRowCount * numColumns
    let visibleItems = []
    let hiddenItems = []

    // If there's actually enough items to need a CollapsibleGrid
    const canCollapse = children && children.length > initialItemCount

    // Split the items into those that are initially visible and those that are initially hidden
    if (canCollapse){
        visibleItems = children.slice(0, initialItemCount)
        hiddenItems = children.slice(initialItemCount)
    } else {
        visibleItems = children
    }

    return (
        <S.Wrapper data-auto-cy="CompoundCollapsibleGrid">
            <S.Label>
                <h2>{label}</h2>
                {canCollapse &&
                    <S.Caret onClick={() => setIsOpenState(!isOpenState)} isOpen={isOpenState}>
                        {isOpenState ? "See Less" : "See More"}
                        <Icon icon="angle-down" iconFamily="far" />
                    </S.Caret>
                }
            </S.Label>
            <S.Grid isVisible numColumns={numColumns}>
                {visibleItems && visibleItems.map(item => item)}
            </S.Grid>
            {canCollapse &&
                <S.Grid isVisible={isOpenState} numColumns={numColumns}>
                    {hiddenItems && hiddenItems.map(item => item)}
                </S.Grid>
            }
        </S.Wrapper>
    )
}

CollapsibleGrid.defaultProps = {
    initialRowCount: 2,
    isOpen: false,
    numColumns: 3,
}

CollapsibleGrid.propTypes = {
    initialRowCount: PropTypes.number,
    isOpen: PropTypes.bool,
    label: PropTypes.string.isRequired,
    numColumns: PropTypes.number,
}

export default CollapsibleGrid

import styled from 'styled-components'

import * as rules from "Components/rules"

import { SearchBar } from "Components/Molecules/SearchBar/style"

export const OptionsBar = styled.div`
    &:last-child {
        padding-bottom: ${rules.TopBarItemPadding};
    }
    margin-top: 4px;
`

export const OptionsBarRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const LeftOptionsBar = styled.div`
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: flex-start;

    align-items: center;
    padding-left: ${rules.TopBarItemPadding};

    // split the left and right side of the options bar evenly
    flex-basis: 50%;

    // space the children
    & > * {
        &:not(:last-child) {
            margin-right: 11px;
        }
        &:last-child {
            margin-right: 30px;
        }
    }
    `

export const RightOptionsBar = styled.div`
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: flex-end;

    align-items: center;
    padding-right: ${rules.TopBarItemPadding};

    // do not slide left if there are no left children
    margin-left: auto;

    // split the left and right side of the options bar evenly
    flex-basis: 50%;

    // constrain search bars to half the width of the right options
    ${SearchBar} {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 50%;
    }

    // space the children
    & > * {
        &:not(:first-child) {
            margin-left: 11px;
        }
        &:first-child {
            margin-left: 11px;
        }
    }
`

export const FullWidthBar = styled.div`
    align-items: center;
    padding-left: ${rules.TopBarItemPadding};
    padding-right: ${rules.TopBarItemPadding};

    // put the full width child in the center of a flexible container
    flex-grow: 1;
    display: flex;
    justify-content: center;

    // try to tell the full width child to grow and take up the container
    & :first-child {
        flex-grow: 1;
    }
`

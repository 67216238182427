import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"
import { selectOrganizationDesign } from "QuorumGrassroots/framework/selectors"

import {
    StyledPermissionDeniedPage,
    StyledPermissionDeniedText,
    StyledPermissionDeniedContentBox,
    StyledPermissionDeniedImage,
} from "QuorumGrassroots/framework/components/405/style"

import { selectIndexRoute } from "QuorumGrassroots/framework/selectors"

/**
 * Page indicating a user is attempting to access something they're not allowed to, such as a restricted page.
 */
const Page405 = ({ design, compact, errorHtml, t, shouldPreventFlash } = { compact: false }) => {
    if (shouldPreventFlash) {
        return <div />
    }

    return (
        <StyledPermissionDeniedPage backgrond={design.primary_color}>
            <StyledPermissionDeniedContentBox background={design.background_color}>
                <StyledPermissionDeniedImage color={design.primary_color}>
                    <i className="fa fa-times-circle" />
                </StyledPermissionDeniedImage>
                <StyledPermissionDeniedText color={design.secondary_color} compact={compact}>
                    {errorHtml ? (
                        <span dangerouslySetInnerHTML={{ __html: errorHtml }} />
                    ) : (
                        <p>{t("405.inaccessible")}</p>
                    )}
                </StyledPermissionDeniedText>
            </StyledPermissionDeniedContentBox>
        </StyledPermissionDeniedPage>
    )
}

Page405.propTypes = {
    /** The organization design object */
    design: PropTypes.object.isRequired,
    /** If true, use less margin/padding. This is necessary when the component is embedded as a widget in a page. */
    compact: PropTypes.bool,
    /** If provided, the error text that will be provided to the user. Otherwise, a localized text will
        be used. This is meant for PAC which requires this override and does not need localization. Text provided here
        is NOT localized. */
    errorHtml: PropTypes.string,
    /** i18n t function, provided by withNamespaces */
    t: PropTypes.func.isRequired,
    /** See function docstring */
    shouldPreventFlash: PropTypes.bool.isRequired,
}

/**
 * Normally any page that would give a 405 should instead give the login page if the user is logged out and the action
 * center is not publicly accessible. However, there's a bug where if the user logs out, prior to page reload,
 * the login page is bypassed showing the 405 briefly. As a workaround we detect the 405 is being flashed during
 * logout, so we can show different contents.
 *
 * @param state - redux state
 * @returns {boolean} - if measures need to be taken to prevent flash
 */
export const shouldPreventFlash = (state, _props) => {
    const { framework } = state
    const publiclyAccessible = framework.getIn(["actionCenterSettings", "publicly_accessible"])
    const loggedOut = framework.get("userdata").size <= 0
    return Boolean(!publiclyAccessible && loggedOut)
}

const mapStateToProps = (state, props) => ({
    indexRoute: selectIndexRoute(state, props),
    design: selectOrganizationDesign(state, props),
    shouldPreventFlash: shouldPreventFlash(state, props),
})

export default withNamespaces()(connect(mapStateToProps, null)(Page405))

// Place to store commonly used hooks
import { useState, useCallback, useRef, useEffect } from 'react'
import debounce from "lodash.debounce"
/** useOffsetWidth
 * hook to get and keep track of the width of the component the ref it returns is attached to
 * Returns:
 * [
     ref - a ref to be attached to the component which width needs to be measured (<Comp ref={ref} />)
     width - int, the width, as stored on the component using this hook's state.
 ]
 */
export const useOffsetWidth = () => {
    const [width, updateWidth] = useState(0)
    const ref = useCallback(component => {
        if (component) {
            const newWidth = component.getBoundingClientRect().width
            updateWidth(isNaN(newWidth) ? 0 : newWidth)
        }
    }, [])
    return [ref, width]
}

/** useOffsetHeight
 * Hook to get and keep track of the offset height of a component
 * Attach the ref this hook returns to the element to measure
 * Returns:
 * [
    - ref: ref to be attached to a component to measure the height of via (<Comp ref={ref} />)
    - height: int, the height of the component after the last render, measured in pixels
 * ]
 */
export const useOffsetHeight = () => {
    const ref = useRef()
    const [height, updateHeight] = useState(0)
    useEffect(() => {
        if (ref.current) {
            updateHeight(ref.current.offsetHeight)
        }
    })
    return [ref, height]
}

/** useFitsOnScreen
 * Hook to get the difference between the right edge of a component
 * and the right edge of the document
 * Attach the ref this hook returns to the element to measure
 * Returns:
 * [
    - ref: ref to be attached to a component to measure the location of via (<Comp ref={ref} />)
    - offset: int, how close the component is to the edge of the screen, measured in pixels; negative == off screen
 * ]
 */
export const useFitsOnScreen = () => {
    const ref = useRef()
    const [offset, updateOffset] = useState(undefined)
    useEffect(() => {
        if (ref.current) {
            const documentRightEdge = document.body.getBoundingClientRect().right
            const boxRightEdge = ref.current.getBoundingClientRect().right
            updateOffset(documentRightEdge - boxRightEdge)
        }
    })
    return [ref, offset]
}

/** useTruncated
 * Hook to determine if a component has truncated its text
 * Attach the ref this hook returns to the element to measure
 * Returns:
 * [
    - ref: ref to be attached to a component to measure the truncation state of via (<Comp ref={ref} />
    - isTruncated: bool holding whether or not the element is truncated
 * ]
 */
export const useTruncated = () => {
    const ref = useRef()
    const [isTruncated, updateIsTruncated] = useState(false)
    useEffect(() => {
        if (ref.current) {
            const offsetWidth = ref.current.offsetWidth
            const scrollWidth = ref.current.scrollWidth

            const scrollHeight = ref.current.scrollHeight
            const clientHeight = ref.current.clientHeight
            updateIsTruncated(
                // true if using normal css / single line truncation
                (offsetWidth < scrollWidth)

                // true if using webkit multi-line truncation
                || (clientHeight + 1 < scrollHeight)
                // The +1 here is used to fight a firefox bug where
                // the scroll height would change by 1 pixel between renders when
                // experiencing no text trunaction, possibly due to implementation
                // of -webkit-line-clamp or similar
            )
        }
    })
    return [ref, isTruncated]
}

/** usePaddingItemCount
 * when we want an equal number of items (flex items) in each row (which is often helpful for left
 * alignment) we need to keep track of how many items we have, the width of each item, and the total
 * width of the rows. This consumes getWidth and takes in the information necessary to return how many
 * extra items are needed so that each row has the same number.
 * Args:
 *  - itemWidth: the width of each item
 *  - itemCount: the number of items
 *  - padding: the overall padding around the items
 * Returns:
 *  - ref: the ref used to keep track of the total row width, attach it to row component (<Comp ref={ref} />)
 *  - paddingCount: int, the number of additional items of padding needed for an even number per row
 */
export const usePaddingItemCount = (itemWidth, itemCount, padding=0) => {
    const [ref, width] = useOffsetWidth()
    const itemsPerRow = Math.floor((width - padding) / itemWidth)
    const paddingCount = (
        itemsPerRow <= itemCount
        ? (itemsPerRow - (itemCount % itemsPerRow)) % itemsPerRow
        : itemCount % itemsPerRow
    )
    return [ref, paddingCount]
}

/** useWindowSize
 * Returns an object containing the dimensions of the browser's window.
 * Useful for rendering different components at different screen sizes.
 * Returns:
 *  - size: Object, { windowWidth: number, windowHeight: number}
 */
export const useWindowSize = () => {
    const getSize = () => ({ windowWidth: window.innerWidth, windowHeight: window.innerHeight })

    const [windowSize, setWindowSize] = useState(getSize)

    useEffect(() => {
        const handleResize = debounce(() => setWindowSize(getSize()), 300)

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowSize
}

/**
 * @name useHover
 *
 * Provides a callback ref that can be added to an element, and optionally moved to another element,
 * and adds event listeners to detect when the element is being hovered.
 *
 */
export const useHover = () => {
    const [isHovered, setHovered] = useState(false)
    const ref = useRef()

    const handleMouseOver = useCallback(() => setHovered(true), [])
    const handleMouseOut = useCallback(() => setHovered(false), [])

    const callbackRef = useCallback((node) => {
        if (ref.current) {
            ref.current.removeEventListener('mouseover', handleMouseOver)
            ref.current.removeEventListener('mouseout', handleMouseOut)
        }

        ref.current = node

        if (ref.current) {
            ref.current.addEventListener('mouseover', handleMouseOver)
            ref.current.addEventListener('mouseout', handleMouseOut)
        }
    }, [handleMouseOver, handleMouseOut])

    return [callbackRef, isHovered]
}

/**
 * @name useOnClickOutside
 *
 * Fires the provided callback function when it detects a click outside of the ref component.
 * Borrowed from: https://usehooks.com/useOnClickOutside/ under the Unlicense.
 *
 * Wrapping the handler function in a `useCallback()` before passing into this hookcan prevent
 * the `useEffect()` hook below from running more than once! :zap:
 *
 * @param {Object} ref Component to check for clicks outside of
 * @param {Function} handler Function to call when click detected
 * @param {Array} customEvents Events to listen for outside the component; "mousedown" and "touchstart" by default
 */
export const useOnClickOutside = (ref, handler, customEvents=["mousedown", "touchstart"]) => {
    useEffect(() => {
        const listener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return
            }

            handler(event)
        }

        customEvents.forEach((eventName) => {
            document.addEventListener(eventName, listener)
        })

        return () => {
            customEvents.forEach((eventName) => {
                document.removeEventListener(eventName, listener)
            })
        }
    }, [ref, handler])
}

/**
 * @name usePrevious
 *
 * Keeps track of values through re-renders. Useful for preserving the previous props of a component.
 *
 * Borrowed from the official React Docs {@link https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state}
 *
 * @param {any} value value to keep track of
 * @returns {any} The value previously set
 */
export const usePrevious = (value) => {
    const ref = useRef()

    useEffect(() => {
        ref.current = value
    })

    return ref.current
}

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/TrendsWidgetItem/style"

export const formatTrendAproximation = count => {
    const magnitude = String(count).length - 1
    if (count % (10 ** magnitude) === 0) {
        return count
    }
    return count - (count % (10 ** magnitude)) + (10 ** magnitude)
}
/**
 * Renders a trending widget line item
 *
 */
const TrendsWidgetItem = ({ dataCy, term, count, topic, key, handleClick }) => (
    <S.TrendsWidgetItem key={key} data-auto-cy="CompoundTrendsWidgetItem" onClick={handleClick}>
        <S.MainText data-cy={dataCy}>{term}</S.MainText>
        <S.SubTextContainer>
            {topic && <S.SubText>{topic}</S.SubText>}
            {topic && <S.SubText> • </S.SubText>}
            <S.SubText>~{new Intl.NumberFormat().format(formatTrendAproximation(count))} Mentions</S.SubText>
        </S.SubTextContainer>
    </S.TrendsWidgetItem>
)

TrendsWidgetItem.propTypes = {
    /** the trending term */
    term: PropTypes.string,
    /**  the number of mentions of the term */
    count: PropTypes.number,
    /** the term's broader topic */
    topic: PropTypes.string,
    /** the function fired when the item is clicked */
    handleClick: PropTypes.func
    /** a unique key used when mapping the data */
}

export default TrendsWidgetItem

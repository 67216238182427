import React from "react"
import styled from "styled-components"
import ImageGallery from "react-image-gallery"

import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { styleInMobileScreenSize } from "QuorumGrassroots/styled-components/helperFunctions"
import { overrideFullBleedImageStyling } from "QuorumGrassroots/widgets/styled-components/helperFunctions"

export const StyledFullBleedWidget = styleWithOrgDesign(styled.div`
    align-items: center;
    background-color: ${(props) => props.organizationDesign.light_grey};
    border-radius: 4px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
    min-height: 400px;
    position: relative;
    width: calc(100% - 30px);
    ${styleInMobileScreenSize(`
        margin-bottom: 20px;
        margin-left: 5px;
        margin-right: 5px;
        padding-left: 0px;
        padding-right: 0px;
        text-align: center;
    `)}
    ${(props) => overrideFullBleedImageStyling(props)}
`)

export const Wrapper = styleWithOrgDesign(styled.div`
    display: flex;
    line-height: initial;
    white-space: initial;
    gap: 36px;
    margin: 50px 80px;
    justify-content: center;
    padding: 0 45px;

    ${styleInMobileScreenSize(`
        flex-direction: column;
        align-items: center;
        margin: 25px;
        gap: 24px;
        padding: 0 80px;
    `)}
`)

export const StyledImageGallery = styleWithOrgDesign(styled(({ className, ...props }) => (
    <ImageGallery
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        additionalClass={className}
    />
))`
    /* Avoid the slide description running into the bullets */
    .image-gallery-slide {
        padding-bottom: 20px;
    }

    /* Color the bullets primary_color */
    .image-gallery-bullet {
        background: ${(props) => props.organizationDesign.dark_grey};
        border: 1px solid transparent;
        box-shadow: unset;
        padding: 6px;

        &.active {
            background: ${(props) => props.organizationDesign.primary_color};
            border: 1px solid transparent;
            transform: unset;
        }
    }

    /* Shrink the left/right arrows and only render on desktop */
    .image-gallery-icon {
        transform: translateY(-50%) scale(50%);
        color: #000;

        .image-gallery-svg {
            stroke-width: 2px;
        }
    }

    ${styleInMobileScreenSize(`
        .image-gallery-icon {
            display: none;
        }
    `)}
`)

export const WidgetTitle = styleWithOrgDesign(styled.h2`
    font-weight: 700;
    font-size: 40px;
    line-height: 46px;
    text-align: center;
    margin: 0;
    padding-top: 50px;

    ${styleInMobileScreenSize(`
        font-size: 32px;
        line-height: 37px;
        padding-top: 30px;
    `)}
`)

// CarouselItem styles
export const Image = styled.img`
    flex-basis: content;
    flex-shrink: 0;
`

export const CopyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
`

export const Title = styled.h3`
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;

    ${styleInMobileScreenSize(`
        text-align: center;
    `)}
`

export const Subtitle = styled.p`
    font-size: 24px;
    line-height: 28px;

    ${styleInMobileScreenSize(`
        text-align: center;
    `)}
`

export const Description = styled.div`
    font-size: 16px;
    line-height: 24px;
`

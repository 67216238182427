import React, { useState } from "react"
import PropTypes from "prop-types"

import Icon from "Components/Atoms/Icon"

import * as S from "Components/Molecules/Collapse/style"

const Collapse = ({
    children,
    dataCy,
    expandedTitle,
    isOpen,
    title,
    type,
}) => {
    const [isOpenState, setIsOpenState] = useState(isOpen)

    return (
        <S.Collapse data-auto-cy="MoleculeCollapse">
            <S.AboveFold
                data-cy={dataCy}
                onClick={() => setIsOpenState(!isOpenState)}
                type={type || "primary"}
            >
                {(isOpenState && expandedTitle) ? expandedTitle : title}
                <S.Caret isOpen={isOpenState}>
                    <Icon icon="angle-down" iconFamily="far" />
                </S.Caret>
            </S.AboveFold>
            <S.BelowFold
                isVisible={isOpenState}
            >
                {children}
            </S.BelowFold>
        </S.Collapse>
    )
}

Collapse.defaultProps = {
    isOpen: false,
}

Collapse.propTypes = {
    children: PropTypes.node,
    dataCy: PropTypes.string,
    expandedTitle: PropTypes.string,
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.oneOf(["primary", "secondary"]),
}

export default Collapse

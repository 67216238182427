import React from "react"
import PropTypes from "prop-types"
import { withNamespaces } from "react-i18next"
import classNames from "classnames"

import { joinPaths, simpleReplacePlaceholders } from "QuorumGrassroots/helperFunctions"

import { StyledLinkColumnWidget } from "QuorumGrassroots/widgets/styled-components/StyledWidget"
import {
    StyledInlineTable,
    StyledInlineTableRow,
    StyledInlineIconTableData,
    StyledInlineTitle,
    StyledInlineTitleText,
    StyledInlineDescription,
    StyledInlineCallToAction,
} from "QuorumGrassroots/widgets/styled-components/inline/style"

const EventInline = (props) => {
    let url = "/"
    let dataCy = "loading"
    let header = props.t("loading")
    let description = props.t("loading")
    let verb = props.t("loading")

    if (!props.loading) {
        const { id, name, public_summary, vanity_url_slug } = props.event

        url = joinPaths(DjangIO.app.grassroots.types.GrassrootsWidgetType.event.widget_url, vanity_url_slug || id)
        dataCy = id
        header = name
        description = public_summary
        verb = props.t("event.inline.cta")
    }

    return (
        <StyledLinkColumnWidget
            className={classNames("event-inline", props.className)}
            to={url}
            state={{ ...props.event }}
            inline
            data-cy={`event-inline-${dataCy}`}
            data-test="inline"
        >
            <StyledInlineTable className="event-inline-table" isFirst={props.isFirst} isLast={props.isLast}>
                <tbody>
                    <StyledInlineTableRow className="inline-table-row">
                        <td>
                            <StyledInlineTitle className="inline-title" description={description}>
                                <StyledInlineTitleText className="inline-title-text">{header}</StyledInlineTitleText>
                            </StyledInlineTitle>
                            <StyledInlineDescription className="inline-description">
                                {description}
                            </StyledInlineDescription>
                        </td>
                        <StyledInlineIconTableData className="inline-icon-table-data">
                            <StyledInlineCallToAction className="inline-cta">{verb}</StyledInlineCallToAction>
                        </StyledInlineIconTableData>
                    </StyledInlineTableRow>
                </tbody>
            </StyledInlineTable>
        </StyledLinkColumnWidget>
    )
}

EventInline.defaultProps = {
    loading: false,
}

EventInline.propTypes = {
    t: PropTypes.func.isRequired,
    organizationDesign: PropTypes.object,
    event: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        vanity_url_slug: PropTypes.string,
        public_summary: PropTypes.string,
    }),
    loading: PropTypes.bool,
}

export default withNamespaces()(EventInline)

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/SelectWithRemovableValues/style"
import colors from "Components/colors"

const SelectWithRemovableValues = ({
    clickOutsideRef,
    dataCy,
    SelectComponent,
    values,
    valueStripOnClick,
    valueStripRemove,
}) => {
    const renderValueStrips = () => (
        <S.ValueStripsContainer>
            {
                values
                && values.map(value => (
                    <S.ValueStrip
                        key={value.id}
                        dataCy="value-strip"
                    >
                        <S.ValueStripText
                            className="value-strip-display-text"
                            cannotClick={value.cannotClick}
                            onClick={() => !value.cannotClick && valueStripOnClick(value)}
                        >
                            { value.displayText }
                        </S.ValueStripText>
                        {
                            !value.cannotRemove && (
                                <S.RemoveIcon
                                    dataCy={
                                        value.id
                                            ? `value-strip-remove-${value.id}`
                                            : "value-strip-remove"
                                    }
                                    icon="times"
                                    iconFamily="far"
                                    color={colors.gray9}
                                    size="sm"
                                    onClick={() => valueStripRemove(value)}
                                />
                            )
                        }
                    </S.ValueStrip>
                ))
            }
        </S.ValueStripsContainer>
    )

    return (
        <S.SelectWithRemovableValues
            data-auto-cy="CompoundSelectWithRemovableValues"
            data-cy={dataCy}
            ref={clickOutsideRef}
        >
            { renderValueStrips() }
            { SelectComponent }
        </S.SelectWithRemovableValues>
    )
}

SelectWithRemovableValues.propTypes = {
    clickOutsideRef: PropTypes.object,
    dataCy: PropTypes.string,
    SelectComponent: PropTypes.element,
    values: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        cannotClick: PropTypes.bool,
        cannotRemove: PropTypes.bool,
        displayText: PropTypes.string,
    })),
    valueStripOnClick: PropTypes.func,
    valueStripRemove: PropTypes.func,
}

export default SelectWithRemovableValues

/**
 * @name parseInputEvent
 *
 * @param {string} type typeof value that should be returned
 * @param {Object} event Click event that contains the value selected, but in string format
 *
 * @returns {*} The value of the input, but cast to the type requested.
 */
export const parseInputEvent = (type, event) => {
    switch (type) {
        case "number":
            return parseInt(event.target.value, 10)
        case "boolean":
            return event.target.value === "true"
        case "string":
        default:
            return event.target.value
    }
}

import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"


export const SummaryStatus = styled.span`
    ${rules.MediumTextRegularWeightSpaced}
    color: ${colors.gray7};
`

export const BoldSpan = styled.span`
    ${rules.MediumWeight}
`

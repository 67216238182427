import styled, { css } from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const TabBar = styled.div`
    background-color: white;
    height: ${rules.NavigationalTabHeight};
    margin-bottom: 24px;
    color: ${colors.gray7};
    border-bottom: 1px solid ${colors.gray2};

    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;

    & > * {
        padding-left: ${({tabPadding}) => tabPadding};
        padding-right: ${({tabPadding}) => tabPadding};

        &:hover {
            cursor: pointer;
            color: ${colors.gray9};
        }
    }

    & > :first-child {
        // Extend the first tab's hover area to the left end of the bar
        // Add the 6px to space the tab slightly off the left edge
        padding-left: calc(6px + ${({tabPadding}) => tabPadding});
    }
`

export const TabContainer = styled.div`
    // this tab container is necessary for the hover state to affect
    // a different area than the selected tab border
    height: inherit;
    display: flex;
    align-items: center;
`

export const Tab = styled.div`
    flex-shrink: 0;
    color: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    white-space: nowrap;
    justify-content: space-between;

    user-select: none;

    svg:first-child {
        padding-right: 6px;
    }

    ${({isSelected}) => isSelected ?
            css`
                ${rules.MediumTextMediumWeight}
                border-bottom: ${colors.violet5} solid 3px;
                padding-bottom: 0;
            ` : css`
                ${rules.MediumTextRegularWeight}
            `
        }
    }}
`

export const LeftHoverCover = styled.div`
    position: absolute;
    width: 6px;
    height: ${rules.NavigationalTabHeight};
`

import React from "react"
import styled from "styled-components"

const CenterWrapper = styled.div`
    display: flex;
    flex: 1;
    gap: 12px;
    justify-content: center;
`

export const NavCenter = ({ isFragment, children }) => {
    if (!isFragment) {
        return <CenterWrapper>{children}</CenterWrapper>
    }

    return children
}

import { createStructuredSelector, createSelector } from "reselect"

import { selectIsWidgetContentLoading } from "QuorumGrassroots/widgets/selectors"

const widgetDisplayType = DjangIO.app.grassroots.enums.CustomDataWidgetDisplayType

const selectGeodataFields = (_, props) => props.geodata_fields || []
const selectNumericFields = (_, props) => props.numeric_fields || []
const selectNonNumericFields = (_, props) => props.non_numeric_fields || []
const selectRegionFilters = (_, props) =>
    (props.regionFilters && props.regionFilters.region_filters) || props.region_filters
const selectDisplayType = (_, props) => props.display_type
const selectCards = (_, props) => props.cards

export const selectKwargsForActionMethod = createSelector(
    selectRegionFilters,
    selectDisplayType,
    selectGeodataFields,
    selectNumericFields,
    selectNonNumericFields,
    selectCards,
    (_, props) => props,
    (region_filters, display_type, geodata_fields, numeric_fields, non_numeric_fields, cards, props) => {
        const kwargs_dict = { region_filters }
        if (!display_type) {
            return {}
        }

        if (display_type === widgetDisplayType.list.value) {
            kwargs_dict.fields = {}
            kwargs_dict.fields.geodata_fields = geodata_fields.map((field) =>
                field.field_name ? field.field_name : field,
            )
            kwargs_dict.fields.numeric_fields = numeric_fields.map((field) =>
                field.field_name ? field.field_name : field,
            )
            kwargs_dict.fields.non_numeric_fields = non_numeric_fields.map((field) => {
                return field.field_name ? field.field_name : field
            })
        }

        if (display_type === widgetDisplayType.aggregate.value) {
            kwargs_dict.aggregate_field_and_method_dict = {}

            // the shape of cards JSON mimics that of CardsSection, using a string of card index as the key
            Object.values(cards).forEach((card, index) => {
                kwargs_dict.aggregate_field_and_method_dict[`card_${index}`] = card.aggregate_field_and_method
            })
        } else {
            widgetDisplayType.by_value(display_type).action_method_kwargs.forEach((key) => {
                if (key === "region_filters" || key === "fields") {
                    return
                }
                kwargs_dict[key] = props[key]
            })
        }
        return kwargs_dict
    },
)

export const selectColumnsForListView = createSelector(
    selectGeodataFields,
    selectNumericFields,
    selectNonNumericFields,
    (geodata_fields, numeric_fields, non_numeric_fields) => {
        let selected_column_names = []
        selected_column_names = selected_column_names.concat(geodata_fields, numeric_fields, non_numeric_fields)

        if (selected_column_names.length && Object.keys(selected_column_names[0]).includes("precedence")) {
            selected_column_names.sort((a, b) => a.precedence - b.precedence)
            selected_column_names = selected_column_names.map((field) => field.field_name)
        }
        return selected_column_names
    },
)

export const customDataWidgetSelector = createStructuredSelector({
    columns: selectColumnsForListView,
    kwargs_dict: selectKwargsForActionMethod,
    loading: selectIsWidgetContentLoading,
})

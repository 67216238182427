import styled from "styled-components"

import * as colors from "Components/colors"

export const SearchifyFooter = styled.div`
    background: ${colors.White};
    border-top: 1px solid ${colors.QuorumGrey5};
    display: flex;
    justify-content: space-between;
    padding: 19px 24px 17px 24px;

    ${({shouldShowSmallButtons}) => shouldShowSmallButtons && `
        padding: 1.5rem 1rem;
    `}
`

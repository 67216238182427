import styled from "styled-components"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { Col, Row } from "react-bootstrap"

export const StyledDataSectionRow = styled(Row)`
    margin-bottom: 10px;
`

export const StyledDataSectionLabel = styleWithOrgDesign(styled(Col)`
    font-size: 24px;
    font-weight: 200;
    color: ${(props) => props.organizationDesign.primary_color};
    margin-bottom: 5px;
`)

export const StyledDatumTitle = styleWithOrgDesign(styled.div`
    font-size: 16px;
    color: ${(props) => props.organizationDesign.primary_color};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 5px;
`)

export const StyledDatumValue = styleWithOrgDesign(styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: ${(props) => props.organizationDesign.primary_color};
    border: 1px solid;
    border-color: ${(props) => props.organizationDesign.primary_color};
    border-radius: 4px;
    background-color: rgba(48, 62, 96, 0.05);
    margin-bottom: 10px;

    a,
    span {
        padding: 5px;
        font-weight: 700;
        min-height: 31px;
        font-size: 16px;
        padding-left: 8px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`)

export const StyledExternalLinkDatumValue = styleWithOrgDesign(styled.a`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${(props) => props.organizationDesign.primary_color};
    width: 100%;

    &:hover {
        text-decoration: none;
    }
`)

import { reinsertPlaceholders } from "QuorumGrassroots/helperFunctions"
import { TargetedMessage } from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/interfaces"

export const validateMessageHasPlaceholders = (message: string) => {
    const normalizedMessage = reinsertPlaceholders(message)
    const pattern = /{{\s*[\w\s]+\s*}}/g
    return pattern.test(normalizedMessage)
}

export const generateTargetedMessage = (message: TargetedMessage, value: string) => ({
    ...message,
    formatted: {
        ...message.formatted,
        content: value,
    },
})

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/BalanceCounterBox/style"

const BalanceCounterBox = ({
    clearedBalance,
    credit,
    debit,
    disbursementCount,
    firstLine,
    isSubdivided,
    secondLine,
    receiptCount,
    isCompleted,
    phoneBreakpoint,
 }) => (
    <S.BoxWrapper
        large
        flat
        data-auto-cy="CompoundBalanceCounterBox"
        isCompleted={isCompleted}
        phoneBreakpoint={phoneBreakpoint}
    >
        {
            isSubdivided
            ? (
                <React.Fragment>
                    <S.TopRow isCompleted={isCompleted}>
                        <S.FirstLine className="currencyString" isCompleted={isCompleted}>
                            {clearedBalance}
                        </S.FirstLine>
                        <S.SecondLine isCompleted={isCompleted}>
                            Cleared Balance
                        </S.SecondLine>
                    </S.TopRow>
                    <S.BottomRow>
                        <S.BottomLeft isCompleted={isCompleted}>
                            <S.FirstLine className="currencyString" isCompleted={isCompleted}>
                                {credit}
                            </S.FirstLine>
                            <S.SecondLine isCompleted={isCompleted}>
                                {disbursementCount}
                            </S.SecondLine>
                        </S.BottomLeft>
                        <S.BottomRight isCompleted={isCompleted}>
                            <S.FirstLine className="currencyString" isCompleted={isCompleted}>
                                {debit}
                            </S.FirstLine>
                            <S.SecondLine isCompleted={isCompleted}>
                                {receiptCount}
                            </S.SecondLine>
                        </S.BottomRight>
                    </S.BottomRow>
                </React.Fragment>
            ) : (
                <S.FullCard>
                    <S.FirstLine className="currencyString" isCompleted={isCompleted}>
                        {firstLine}
                    </S.FirstLine>
                    <S.SecondLine isCompleted={isCompleted}>
                        {secondLine}
                    </S.SecondLine>
                </S.FullCard>
            )
        }
    </S.BoxWrapper>
)

BalanceCounterBox.defaultProps = {
    isSubdivided: false,
    isCompleted: false,
    phoneBreakpoint: '600px',

    clearedBalance: "$ - ",
    credit: "$ - ",
    debit: "$ - ",
    disbursementCount: "0 Disbursements",
    receiptCount: "0 Receipts",

    firstLine: "$ - ",
    secondLine: "Examples",
}

BalanceCounterBox.propTypes = {
    isSubdivided: PropTypes.bool,
    isCompleted: PropTypes.bool,
    // smaller than this, use a phone layout (cards in a vertical stack)
    phoneBreakpoint: PropTypes.string,

    // Props for isSubdivided layout
    // TODO two separate components with a shared renderer?
    clearedBalance: PropTypes.node,
    credit: PropTypes.node,
    debit: PropTypes.node,
    disbursementCount: PropTypes.node,
    receiptCount: PropTypes.node,

    // Props for !isSubdivided aka "singular layout"
    firstLine: PropTypes.string,
    secondLine: PropTypes.string,
}

export default BalanceCounterBox

import styled from "styled-components"

import * as colors from "Components/colors"

import { Widget } from "Components/Compounds/Widget/style"

export const WidgetEditForm = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
`

export const WidgetEditSidebar = styled.div`
    border-left: 1px solid ${colors.gray2};
    flex-shrink: 0;
    overflow: auto;
    width: 415px;
`

export const WidgetEditContainer = styled.div`
    display: flex;
    flex-grow: 1;
    overflow: auto;
    border-top: 1px solid ${colors.gray2};

    ${Widget} {
        flex-grow: 1;
        margin: 36px;
        min-width: 0;
    }
`

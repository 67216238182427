import styled from "styled-components"
import { Checkbox } from "@mantine/core"

import { constants } from "QuorumGrassroots/constants"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { getFontColorForBackgroundContrast } from "QuorumGrassroots/styled-components/helperFunctions"

export const StyledRecordingArea = styled.div<{ hasRecordedVideo?: boolean }>`
    background-color: ${constants.whiteColor};
    border-radius: 8px;

    ${(props) => (props.hasRecordedVideo ? "padding: 16px; min-height: 190px;" : "padding: 32px;")}

    display: flex;
    align-items: center;
    justify-content: center;
`

export const StyledRecordButton = styled.button`
    margin: auto;
    display: flex;
    width: 160px;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 32px;
    background: black;
    color: white;
`

export const StyledTermsOfServiceContainer = styled.div`
    display: flex;
    align-items: center;
    gap: var(--xs, 8px);
    margin-top: 16px;
    margin-bottom: 16px;
`

export const StyledTermsOfServiceCheckbox = styled(Checkbox)`
    .mantine-Checkbox-input {
        margin: 0;
        border: none;
        &:checked {
            background-color: black !important;
        }
    }
`

export const StyledAnchor = styleWithOrgDesign(styled.a`
    text-decoration: underline;
    cursor: pointer;
    ${(props) => `color: ${getFontColorForBackgroundContrast(props.organizationDesign.primary_color)};`}
`)

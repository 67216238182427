import i18n from "i18n"
import { createSelector, createStructuredSelector } from "reselect"
import { fromJS } from "immutable"

import {
    createRegistrationPageCustomAfterRegistrationJsSelector,
    createRegistrationPageCustomCssSelector,
    createRegistrationPageCustomJavascriptSelector,
    createRegistrationPagePostSubmissionActionSelector,
    createRegistrationPageRedirectUrlSelector,
    createRegistrationPageSubmitButtonTextSelector,
    selectActionCenterPubliclyAvailable,
    selectCanLoginWithFacebook,
    selectCustomFields,
    selectDefaultRegistrationPageId,
    selectLoginPagePasswordLabel,
    selectLoginPagePostText,
    selectLoginPagePreText,
    selectLoginPageUsernameLabel,
    selectLoginRedirectUrl,
    selectIsCampaignPage,
    selectSsoUrl,
} from "QuorumGrassroots/framework/selectors"
import { selectMessageGroups } from "QuorumGrassroots/campaign-forms/selectors/campaign-message-preview-selectors"
import { selectSimpleFieldDefaults } from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/selectors"
import { setDefaultValue } from "shared/customFields/constants/helperFunctions"

/* ***********************************************************************
 * LOGIN / REGISTRATION FORM SELECTORS
 *********************************************************************** */

// they're all inextricably linked with framework, so most of the selectors
// are over in framework/selectors.

// if we're given a registration page, use that registration page.
// Otherwise, use the default registration page.
export const selectDefaultRegistrationPageIds = createSelector(selectDefaultRegistrationPageId, (defaultRegPageId) => [
    defaultRegPageId,
])

export const selectRegistrationRedirectUrl = createSelector(
    createRegistrationPagePostSubmissionActionSelector(selectDefaultRegistrationPageIds),
    createRegistrationPageRedirectUrlSelector(selectDefaultRegistrationPageIds),
    (postSubmissionAction, redirectUrl) =>
        postSubmissionAction === DjangIO.app.grassroots.types.RegistrationPostSubmissionAction.redirect_to_url.value &&
        redirectUrl,
)

export const selectCurrentRegistrationPageIds = createSelector(
    (_, props) => props.registrationPageIds,
    selectDefaultRegistrationPageIds,
    (registrationPageIds, defaultRegistrationPageIds) => registrationPageIds || defaultRegistrationPageIds,
)

export const selectRegistrationSubmitButtonText = createSelector(
    (_, props) => props.submitting,
    createRegistrationPageSubmitButtonTextSelector(selectCurrentRegistrationPageIds),
    selectIsCampaignPage,
    (submitting, submitButtonText, isCampaignPage) => {
        if (submitButtonText) {
            return submitButtonText
        } else if (submitting) {
            return i18n.t("form.submitting")
        } else if (isCampaignPage) {
            return i18n.t("navbar.sign_up")
        } else {
            return i18n.t("form.submit_form")
        }
    },
)

export const selectRegistrationCustomJavascript = createRegistrationPageCustomJavascriptSelector(
    selectCurrentRegistrationPageIds,
)
export const selectRegistrationCustomCss = createRegistrationPageCustomCssSelector(selectCurrentRegistrationPageIds)
export const selectRegistrationCustomAfterRegistrationJs = createRegistrationPageCustomAfterRegistrationJsSelector(
    selectCurrentRegistrationPageIds,
)

export const selectInitialValues = createSelector(
    [selectSimpleFieldDefaults, selectCustomFields],
    (fieldDefaults, customFields) => {
        // Some fields have "default_value" props stored on them
        // This selector returns a mapping of field types to their
        // default values
        // The form's field name corresponds to the "supporter_field"
        // of that field type, so remap the defaults to keys of the
        // form's field name for that field type
        const simpleFieldDefaults = Object.keys(fieldDefaults).reduce((acc, key) => {
            const fieldName = DjangIO.app.grassroots.types.GrassrootsRegistrationField.by_value(key).supporter_field
            acc[fieldName] = fieldDefaults[key]
            return acc
        }, {})
        // Add customField default values to the Login Registration form
        const customFieldDefaults = customFields.toJS().reduce((acc, field) => {
            // Don't modify date field defaults since the date TagType default_value already has a timestamp
            if (!(field.tag_type === DjangIO.app.person.types.TagType.date.value)) {
                acc[field.slug] = setDefaultValue(field).default_value
            }
            return acc
        }, {})
        return fromJS({ ...simpleFieldDefaults, ...customFieldDefaults })
    },
)

export const LoginRegistationConnection = createStructuredSelector({
    canLoginWithFacebook: selectCanLoginWithFacebook,
    defaultRegistrationPageIds: selectDefaultRegistrationPageIds,
    loginPagePasswordLabel: selectLoginPagePasswordLabel,
    loginPagePreText: selectLoginPagePreText,
    loginPagePostText: selectLoginPagePostText,
    loginPageUsernameLabel: selectLoginPageUsernameLabel,
    loginRedirectUrl: selectLoginRedirectUrl,
    publiclyAccessible: selectActionCenterPubliclyAvailable,
    ssoUrl: selectSsoUrl,
    registrationRedirectUrl: selectRegistrationRedirectUrl,
    registrationSubmitButtonText: selectRegistrationSubmitButtonText,
    customJS: selectRegistrationCustomJavascript,
    customCss: selectRegistrationCustomCss,
    customAfterRegistrationJs: selectRegistrationCustomAfterRegistrationJs,
})

export const RegistrationFormConnection = createStructuredSelector({
    initialValues: selectInitialValues,
    isCampaignPage: (state, props) => selectIsCampaignPage(state, props),
    messageGroups: (state, props) => selectMessageGroups(state, props),
})

import styled, { css } from "styled-components"
import * as rules from "Components/rules"
import * as colors from "Components/colors"

export const VerticalFormStep = styled.div`
    padding: 18px 15px 17px;

    ${props => (props.step % 2 === 0) && css`
        background-color: ${colors.gray0};
    `}
`

export const VerticalFormStepLabel = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 22px;
`

export const VerticalFormStepByline = styled.div`
    color: ${colors.gray7};
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
`

export const VerticalFormStepLabelText = styled.div`
    margin-left: 10px;
    ${rules.GiantTextLighterWeight}
    font-size: 1.25rem;
    font-weight: 500;
    color: ${colors.gray9};
`

export const VerticalFormStepContent = styled.div`
`

export const VerticalStepNumber = styled.text`
    ${rules.LargerText}
    fill: ${colors.White};
`

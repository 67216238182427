import React from "react"
import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import LoginUpdateOrRender from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/LoginUpdateOrRender"

import LogInteractionForm from "QuorumGrassroots/campaign-forms/containers/LogInteractionForm"
import LogRelationshipForm from "QuorumGrassroots/campaign-forms/containers/LogRelationshipForm"

export const createLoginRequiredWidget = (Component, props) => (
    <WidgetStyleWrapper {...props}>
        <LoginUpdateOrRender {...props} useWidgetStyling={false} renderedComponent={Component} renderedProps={props} />
    </WidgetStyleWrapper>
)

const createInitialProps = (props) => ({
    md: 12,
    useWidgetStyling: !props.isCampaign,
    title: props.widgetHeader,
    ...props,
})

export const LogInteractionWidgetComponent = (props) =>
    createLoginRequiredWidget(LogInteractionForm, createInitialProps(props))

export const LogRelationshipWidgetComponent = (props) =>
    createLoginRequiredWidget(LogRelationshipForm, createInitialProps(props))

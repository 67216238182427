import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"

import DistrictListWidget from "QuorumGrassroots/widgets/DistrictList/components/index"
import { initializer } from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/InitializeStoreWrapper"
import { districtsListSelector } from "QuorumGrassroots/widgets/DistrictList/selectors"

const connectedDistrictList = connect(districtsListSelector, null)(DistrictListWidget)
const initializedDistrictList = initializer(connectedDistrictList)

export default withNamespaces()(initializedDistrictList)

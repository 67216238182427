import styled from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const FilterListLabel = styled.div`
    ${rules.SmallTextRegularWeight}
    align-items: center;
    color: ${colors.gray7};
    display: flex;
    gap: 8px;
    padding: 8px 0;

    svg {
        color: ${colors.violet5};
    }
`

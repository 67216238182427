import styled, {css} from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const ContactCard = styled.div`
    display: flex;
    flex-direction: row;
    & > * {
        padding-top: 8px;
    }

    flex-wrap: wrap;
`

export const MainInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-basis: 50%;

    min-width: 390px;
    flex-grow: 1;

    // use a negative margin to hide the DetailsContainer's padding when
    // not wrapped
    margin-right: ${({rightMargin}) => rightMargin};

    // prevent negative margin from pushing us out of the contact card
    max-width: 100%;
`

export const CircleContainer = styled.div`
    flex: 0;

    display: flex;
    flex-direction: row;
    align-items: top;
    padding-right: ${({rightPadding}) => rightPadding};

    ${({isUserIE11}) => isUserIE11 && "min-width: 122px;"}
`

export const InfoList = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 8px;

    ${({isUserIE11}) => isUserIE11 && "width: 100%;"}

    & > :not(:first-child) {
        :not(:last-child) {
            margin-bottom: 7px;
        }
    }
`

export const Name = styled.span`
    ${rules.GiantTextMediumWeight}
    margin-top: -5px;
    margin-bottom: 5px;
    color: ${colors.gray9};
`

export const Subtitle = styled.span`
    ${rules.SmallTextRegularWeightSpaced}
`

export const InfoItem = styled.span`
    ${rules.MediumTextRegularWeightSpaced}
    b {
        color: ${({styling}) => styling === "secondary" ? colors.Black : colors.gray7};
        ${rules.MediumWeight}
    }

    a {
        color: ${colors.violet7};
        ${rules.MediumWeight}
    }
`

export const DetailsContainer = styled.div`
    flex-basis: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;

    // grow to take up space when wrapped under the MainInfoContainer
    flex-grow: 1;
    ${({leftPadding}) => css`
        // apply left padding to leave space under the circle area
        padding-left: ${leftPadding};
        // don't shrink too much before wrapping
        min-width: calc(50% + ${leftPadding});
    `}
`

export const DetailItem = styled.div`
    flex: 1;
    align-self: flex-start;
    flex-basis: 50%;

    // move up slightly to offset the line height's extra space
    margin-top: -3px;

    // the top two items should always split the width
    &:nth-child(1), &:nth-child(2) {
        max-width: 50%;
        // also prevent text running into the next element as a result
        overflow-wrap: anywhere;
        padding-right: 6px;
    }

    display: flex;
    flex-direction: column;

    min-height: 57px;

    // The min-height is needed for vertical spacing
    // between wrapped elements, so the last detail
    // items don't need it; now they don't expand the container
    &:nth-last-child(2), &:nth-last-child(1) {
        min-height: 0px;
    }
`

export const DetailLabel = styled.span`
    text-transform: capitalize;
    margin-bottom: 2px;
    ${rules.SmallTextMediumWeight}
`

export const DetailText = styled.span`
    ${rules.SmallTextRegularWeightSpaced}
    padding-bottom: 6px;
`

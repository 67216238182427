import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/ResourceTableWrapper/style"

const ResourceTableWrapper = ({
    children,
    dataCy,
    height,
}) => (
    <S.ResourceTableWrapper
        data-auto-cy="MoleculeResourceTableWrapper"
        data-cy={dataCy}
        height={height}
    >
        {children}
    </S.ResourceTableWrapper>
)

ResourceTableWrapper.defaultProps = {
    height: "calc(100vh - 210px)",
}

ResourceTableWrapper.propTypes = {
    // children should be a ResourceTableContainer
    childen: PropTypes.element,
    dataCy: PropTypes.string,
    height: PropTypes.string,
}

export default ResourceTableWrapper

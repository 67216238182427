// https://projects.invisionapp.com/d/main/#/console/21044500/444626890/inspect

import styled, { css } from "styled-components"

import * as colors from "Components/colors"

export const HighlightContainer = styled.span`
`

// TODO: change to styled.mark when we delete the mark element rule in
// app/static/stylus/base_css_new_3.styl
export const Highlight = styled.span`
    ${({isActive}) => {
        if (isActive) {
            return css`
                background-color: ${colors.White};
                color: ${colors.QuorumBlue};
            `
        }

        return css `
            background-color: ${colors.QuorumBlue};
            color: ${colors.White};
        `
    }}
`

import { makePieActionObjs } from "shared/imports/pieActionGenerators"

const loadBusinessCardObjs = makePieActionObjs("load", "Business_Card")
export const loadBusinessCardActionDict = loadBusinessCardObjs.pieDict

const loadSupporterObjs = makePieActionObjs("load", "Supporter")
export const loadSupporterActionDict = loadSupporterObjs.pieDict

const deleteSupporterObjs = makePieActionObjs("delete", "Supporter")
export const deleteSupporterActionDict = deleteSupporterObjs.pieDict

const patchSupporterObjs = makePieActionObjs("patch", "Supporter")
export const patchSupporterActionDict = patchSupporterObjs.pieDict

const loadSupporterTransactionSummaryObjs = makePieActionObjs("load", "supporter_transaction_summary")
export const loadSupporterTransactionSummaryActionDict = loadSupporterTransactionSummaryObjs.pieDict

const loadPotentialDuplicateObjs = makePieActionObjs("load", "Potential_Duplicates")
export const loadPotentialDuplicatesActionDict = loadPotentialDuplicateObjs.pieDict

const SupporterActionTypes = {
    ...loadBusinessCardObjs.actionTypes,
    ...loadSupporterTransactionSummaryObjs.actionTypes,
    ...loadSupporterObjs.actionTypes,
    ...deleteSupporterObjs.actionTypes,
    ...patchSupporterObjs.actionTypes,
    ...loadPotentialDuplicateObjs.actionTypes,
    REMOVE_POTENTIAL_DUPLICATE_OBJECT: "REMOVE_POTENTIAL_DUPLICATE_OBJECT",
    // need this for add custom field?
    UPDATE_SUPPORTER_TAG_DICT: "UPDATE_SUPPORTER_TAG_DICT",
    UPDATE_SUPPORTER_POINTS: "UPDATE_SUPPORTER_POINTS",
}
export default SupporterActionTypes

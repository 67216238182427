import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"


export const SectionHeader = styled.div`
    ${rules.GiantTextMediumWeight}
    color: ${colors.gray9};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

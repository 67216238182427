import React from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form/immutable"

import { GenericWriteForm } from "QuorumGrassroots/campaign-forms/components/ReusableForms/GenericWriteForm"
import { InputField } from "app/static/frontend/forms/components/InputField"
import { shouldShowOrganizationField } from "QuorumGrassroots/campaign-forms/helpers"
import { REGULATION_COMMENT_MAX_CHARS } from "QuorumGrassroots/campaign-forms/write-form-constants"

export const RegulationCommentForm = ({ t, ...props }) => (
    <GenericWriteForm {...props} maxLength={REGULATION_COMMENT_MAX_CHARS} t={t}>
        {shouldShowOrganizationField(props.campaign) && !props.isMultiAction && (
            <Field
                component={InputField}
                data-auto-cy="submittingOrgName"
                name="submitting_org_name"
                label={t("campaign.comment_on_regulation.organization")}
                isCampaignPage
            />
        )}
    </GenericWriteForm>
)

RegulationCommentForm.propTypes = {
    t: PropTypes.func.isRequired,
}

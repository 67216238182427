import { makePieActionObjs } from "shared/imports/pieActionGenerators"
import { prependAppName } from "shared/imports/sharedHelperFunctions"

const appName = "@@grassroots/"

const getActionCenterObj = makePieActionObjs("get", "action_center")
export const getActionCenter = prependAppName(appName, getActionCenterObj.pieDict)

const getActionCentersObj = makePieActionObjs("get", "action_centers")
export const getActionCenters = prependAppName(appName, getActionCentersObj.pieDict)

const alterActionCenterIssuesObj = makePieActionObjs("patch", "action_center_issues")
export const alterActionCenterIssues = prependAppName(appName, alterActionCenterIssuesObj.pieDict)

const mergeActionCenterObj = makePieActionObjs("merge", "action_center")
export const mergeActionCenter = prependAppName(appName, mergeActionCenterObj.pieDict)

const actionTypes = prependAppName(
    appName,
    {
        ...getActionCenterObj.actionTypes,
        ...getActionCentersObj.actionTypes,
        ...alterActionCenterIssuesObj.actionTypes,
        ...mergeActionCenterObj.actionTypes,
    }
)

import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const Body = styled.div`
    height: calc(100vh - 300px);
    // width: calc(100vw - 100px);
    width: 100%;
    max-width: 1336px;
    max-height: 654px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
`

export const TopBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 35px;
    padding: 0 45px;
    flex: 0 0 auto;
    position: relative;
    @media (max-width: 768px) {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        grid-template-areas:
            "text"
            "rightSideComponent";
    }
`

export const Children = styled.div`
    overflow: auto;
    overflow-y: overlay;
    flex: 1;
`

export const Text = styled.div`
    flex: 0 1 ${({ hasRightSideComponent }) => hasRightSideComponent ? '50%' : 'auto'};
    grid-area: text;
    ${rules.LargeTextLightWeight}
    @media (max-width: 768px) {
        padding-bottom: 10px;
    }
`

export const LoadingText = styled.div`
    ${rules.LargeTextLightWeight}
    width: 50%;
    margin: auto;
    text-align: center;
    font-size: 40px;
`

export const StyledLabel = styled.div`
    font-size: 16px;
    line-height: 22px;
    color: ${({ isDisabled }) => isDisabled ? "grey" : "black"};
`

export const StyledColoredValue = styled.div`
    color: ${colors.QuorumBlue};
    font-size: 24px;
    line-height: 50px;
    font-weight: 500;
`

export const StyledRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 60px;
`

export const StyledColoredLabel = styled.div`
    font-size: 16px;
    line-height: 22px;
    color: ${({ isDisabled }) => isDisabled ? "grey" : colors.QuorumBlue};
    padding-left: 7px;
    -webkit-user-select: none;
    user-select: none;
`

export const StyledCheckRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    justify-content: flex-start;
    padding-left: 40px;
    padding-right: 40px;
`

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 70px;
    justify-content: space-between;
`

export const StyledCheckContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    height: 70px;
    justify-content: space-between;
`

export const StyledIconRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
`

export const StyledInput = styled.input`
    border-radius: 4px;
    border: 1px solid #AAAAAA;
    width: 117px;
    height: 41px;
    font-weight: 300;
    font-size: 14px;
    padding: 10px;
    font-style: italic;
`

export const StyledLargeInput = styled.input`
    width: 500px;
    height: 41px;
    border-radius: 4px;
    border: 1px solid #AAAAAA;
    padding: 10px;
`
export const CheckFormTopBar = styled.div`

`

import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "react-bootstrap"

import { StyledSocialButton } from "QuorumGrassroots/widgets/styled-components/styles"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import { StyledHorizontalRule } from "QuorumGrassroots/styled-components/components/StyledHorizontalRule"

export const SocialMediaShareForm = (props) => {
    const renderSocialButton = (button) => {
        const onClick = () => {
            button.onClick()

            props.postGrassrootsSupporterAction({
                uniqueWidgetId: props.uniqueWidgetId,
                campaign: props.campaign,
                payload: {
                    social_media_type: button.socialType,
                },
            })
        }

        return (
            <Col className="social-button-col" key={button.label} md={6} sm={12}>
                <StyledSocialButton onClick={onClick} platform={button.platform}>
                    <i className={`fa ${button.icon}`} />
                    {button.label}
                </StyledSocialButton>
            </Col>
        )
    }

    const submitWidget = () => props.completeCampaign(props.campaign.id, props.uniqueWidgetId)

    return (
        <div className="share-link-form">
            <Row className="social-link-wrapper">{props.socialLinks.map((button) => renderSocialButton(button))}</Row>
            <StyledHorizontalRule isCampaignPage />
            <div>
                <StyledButton onClick={submitWidget} isCampaignPage activateNGGTheme>
                    {props.t("campaign.tweet.done")}
                </StyledButton>
            </div>
        </div>
    )
}

SocialMediaShareForm.propTypes = {
    uniqueWidgetId: PropTypes.string.isRequired,
    campaign: PropTypes.object.isRequired,
    socialLinks: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
            socialType: PropTypes.number.isRequired,
        }),
    ),
    postGrassrootsSupporterAction: PropTypes.func.isRequired,
    completeCampaign: PropTypes.func.isRequired,
}

export default SocialMediaShareForm

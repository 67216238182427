import { requiredPositiveNumberValidation } from "shared/imports/validationFunctions"

export const loadWidgetContentFromProps = (props) => {
    // Matches the param from /event/{param}/
    const id = props.match.params.id

    return props.loadWidgetContent({
        resource: DjangIO.app.custom_event.models.GrassrootsCustomEvent.objects.filter({
            dehydrate_extra: [
                "rsvp_status",
                "self_tag_dict",
                "boss_name",
                "boss_tag_dict",
                "boss_status",
                "custom_fields",
                "source_link",
            ],
        }),
        filter: requiredPositiveNumberValidation(id)
            ? // If the param in the url are all numbers, this is a regular foreign key
              { id }
            : // If the param in the url are not all numbers, this is a vanity url slug
              { vanity_url_slug: id },
        uniqueWidgetId: props.uniqueWidgetId,
        detailRequest: true,
    })
}

import { createSelector } from "reselect"
import { createDataSections, createSectionObject, toDateTimeString } from "shared/imports/sharedHelperFunctions"

// for grabbing our slice of the store
export const selectCustomDescription = (state) => state.customDescription

export const selectSpecificCustomDescriptionSlice = (customDescription, qdtKey, id) =>
    customDescription && customDescription.get(`${qdtKey}-${id}`)

// for grabbing a specific custom description based on the qdtKey and id
export const selectSpecificCustomDescription = (qdtKey) =>
    createSelector(
        selectCustomDescription,
        (state, props) =>
            props.profileId ||
            props.issueId ||
            (props.params && (props.params.profileId || props.params.issueId || props.params.entityId)),
        (customDescription, id) => customDescription && customDescription.get(`${qdtKey}-${id}`),
    )

export const makeSelectFieldFromCustomDescription = (customDescriptionSelector) => (key) =>
    createSelector(customDescriptionSelector, (customDescription) => customDescription && customDescription.get(key))

export const selectCustomDescriptionField = (qdtKey) =>
    makeSelectFieldFromCustomDescription(selectSpecificCustomDescription(qdtKey))
export const selectTextLastUpdated = (qdtKey) =>
    createSelector(
        selectCustomDescriptionField(qdtKey)("text_last_updated"),
        (updatedTime) => updatedTime && toDateTimeString(updatedTime),
    )
export const selectTextLastUpdatedUser = (qdtKey) =>
    createSelector(
        selectCustomDescriptionField(qdtKey)("last_text_updated_user"),
        (userObj) => userObj && userObj.get("name"),
    )

export const selectLastUserInfo = (qdtKey) =>
    createSelector(
        selectTextLastUpdatedUser(qdtKey),
        selectTextLastUpdated(qdtKey),
        (lastUser, updatedTime) =>
            (lastUser || updatedTime) &&
            `Updated${lastUser ? ` by ${lastUser}` : ""}${updatedTime ? ` on ${updatedTime}` : ""}`,
    )

/** @type {(qdtKey: any) => ((state: any, props: any) => any)} */
export const selectLastUserInfoObject = (qdtKey) =>
    createSelector(
        selectTextLastUpdatedUser(qdtKey),
        selectTextLastUpdated(qdtKey),
        (lastUser, updatedTime) =>
            (lastUser || updatedTime) && {
                name: lastUser,
                date: updatedTime && updatedTime.split(" at ")[0],
                time: updatedTime && updatedTime.split(" at ")[1],
            },
    )

/*

The following selectors create the data object necessary to render the Profile using
React-Native's SectionList component. The SectionList renders a ListItem component
(components/Lists/LisItem) for each of the items in the section's array.
The data is structed like:

sections = [
    // sectionObj used to render each section in the SectionList
    {
        title: sectionTitle1,
        // dataObj used to render the ListItem Component
        data: [
            { id11, title11, subtitle11, onPressFunct11, iconName11},
            { id12, title12, subtitle12, onPressFunct12, iconName12},
            { id13, title13, subtitle13, onPressFunct13, iconName13},
            ...
        ],
        id: 1
    },
    {
        title: sectionTitle2,
        // dataObj used to render the ListItem Component
        data: [
            { id21, title21, subtitle21, onPressFunct21, iconName21 },
            ...
        ],
        id: 2
    }
]

each sectionObj is then pushed to a sections object that gets returned.

*/

export const selectCustomDescriptionData = ({ textSelector, selectorFunctions, sectionComponent, title }) =>
    createSelector(
        textSelector,
        (state, props) =>
            props.profileId || props.issueId || (props.params && (props.params.profileId || props.params.issueId)),
        (customDescription, id) => {
            const customDescriptionData = [
                {
                    title,
                    value: customDescription || "Enter Custom Description...",
                    onPress:
                        selectorFunctions &&
                        selectorFunctions({ profileId: id && id.toString(), customTextTitle: title }).customDescription,
                    icon: "angle-right",
                    alwaysRender: true,
                    hideTitle: true,
                    width: 12,
                },
            ]

            const customDescriptionObj = createSectionObject({
                title,
                data: createDataSections(customDescriptionData),
                id,
                sectionComponent,
            })
            return customDescriptionObj
        },
    )

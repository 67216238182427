import { List } from "immutable"
import regexes from "shared/imports/regex"
import i18n from "i18n"
import { createNumberMask } from "redux-form-input-masks"
import { escapeRegExp } from "imports/desktopHelperFunctions"

const { CurrencyType } = DjangIO.app.ledger.types

export const numberValidation = (value) =>
    value && isNaN(Number(value)) ? i18n.t("form.required_number") || "Must be a Number" : undefined

// Convert immutable to regular JS object
export const formatMultiChoiceValues = (formValue) => (formValue && formValue.toJS ? formValue.toJS() : formValue)

// Return 'value' field of the formValue
export const normalizeSingleChoiceValue = (formValue) =>
    formValue && typeof formValue.value !== "undefined" ? formValue.value : null

// Return array of 'value' field of the objects in formValue
export const normalizeMultiChoiceValues = (formValue) =>
    formValue && formValue.map((item) => (item.value ? item.value : item))

// Check if a value is given for a required field
export const requiredFieldValidation = (formValue) => {
    const errorMessage = i18n.t("form.required_field") || "This field is required."

    if (formValue) {
        if (Array.isArray(formValue) && !formValue.length) {
            // Check if the value is an empty array. If empty, return the error message. Otherwise, do not return an error
            return errorMessage
        } else if (List.isList(formValue) && !formValue.size) {
            // Also check if value is an empty Immutable List
            return errorMessage
        } else if (formValue.trim && !formValue.trim()) {
            // Return error message if value is only empty space(s)
            return errorMessage
        }

        return undefined
    } else if (formValue === false) {
        // Checks the cases where the value is 'false'
        return undefined
    }

    return errorMessage
}

/**
 * Check if there are relative paths in formValue src and href fields that are not preceded by a '-' character.
 *
 * @param {object} formValue - The form value to check for relative paths.
 * @returns {string} - Return an error message with the first ocurrence or undefined if no errors are found.
 */
export const notRelativePathValidation = (formValue) => {
    const errorMessage = i18n.t("form.relativePathFound") || "Relative paths are not allowed, e.g. "
    const relativePathPattern = /(?<!-)(?:src|href)=["']((?:\.\.\/|\.\/|\/|[^\/][^:\"\']*[\/\\])[^"']*)["']/
    const match = relativePathPattern.exec(formValue)
    if (match) return `${errorMessage} ${match[1]}`
    return undefined
}

/**
 * If the field is truthy and not a valid email, return an appropriate message. Otherwise, return undefined.
 */
export const emailValidation = (formValue) =>
    formValue && (typeof formValue !== "string" || !formValue.match(regexes.emailValidationRegex))
        ? "This field must be a valid email"
        : undefined

/**
 * Curried function for validating a string has a length less than or equal to a max. Returns a string
 * indicating an error if value is truthy and has a length above maxLength
 *
 * @param {number} maxLength - Max length of value
 * @returns {function} - Function that takes a value to check the length of and returns
 * an error message if invalid, otherwise undefined.
 */
export const maxLengthValidationFactory = (maxLength) => (value) => {
    if (value && value.length > maxLength) {
        return `This field must be at most ${maxLength} characters.`
    }
    return undefined
}

/**
 * Curried function for validating a string has a length equal to exactLength. Returns a string
 * indicating an error if value is truthy and has a length not equal to exactLength.
 *
 * @param {number} exactLength - Length values should be
 * @return {function} - Function that takes a value to check the length of and returns
 * an error message if invalid, otherwise undefined. */
export const exactLengthValidationFactory = (exactLength) => (value) => {
    if (value && value.length !== exactLength) {
        return `This field must be exactly ${exactLength} characters`
    }
    return undefined
}

/**
 * Check if a value is a valid US zip code, consisting of 5 digits. Shouldn't be used for postal codes,
 * which can be different formats in different countries. Only checks format, not if zip corresponds
 * to real area.
 *
 * @param value - value to check
 * @return {string|undefined} - error message if invalid, otherwise undefined
 */
export const zipCodeValidation = (value) => {
    if (value && !/^\d{5}(-\d{4})?$/.test(value)) {
        return "This field must be a valid zip code"
    }
    return undefined
}

// Return a warning message string if the provided phone number
// is not of the correct format to be eligible for texting
export const phoneNumberFormatWarning = (formValue) => {
    if (!formValue) {
        return undefined
    }

    const message =
        i18n.t("form.phone_texting_format") ||
        `\
        In order to receive texts, your number must include an area code and\
        a US or Canada country code (begin with a 1).
    `
    const cleanedNumber = formValue.replace(regexes.phoneNumberReplacerRegex, "")
    if (cleanedNumber.length <= 10 || cleanedNumber[0] !== "1") {
        return message
    }
    return undefined
}

// Generate a function that will validate that the field's value
// equals what the field needs to equal in order to submit. (Mandatory "yes" in
// form fields, for instance.)
const requiredValueValidationFactory = (requiredValue, errorLabel) => (formValue) => {
    if (formValue !== requiredValue) {
        return errorLabel
    }
}

export const requiredTrueValidation = requiredValueValidationFactory(true, i18n.t("form.required_value_true"))
export const requiredFalseValidation = requiredValueValidationFactory(false, i18n.t("form.required_value_false"))

export const urlInputValidation = (value) => {
    if (value && !/^(http|https):\/\/[^ "]+$/.test(value)) {
        return "Your link must include an HTTP or HTTPS protocol."
    }
    return undefined
}

/**
 * Helper function to create the 'name' prop for a HeaderComponent rendered within ReorderableFieldItem component
 * @param  {String} name - 'name' prop of the higher order ReorderableField component that is rendering multiple ReorderableFieldItem components
 * @param  {Number} idx - The index of this specific ReorderableFieldItem within the ReorderableField
 * @param  {headerComponent} object - A dict of information pertaining to rendering a component for the header of ReorderableFieldItem
 *      ex: {
 *          component: Field,
 *          name: 'url',
 *          props: {},
 *      }
 * @return  {string} - A name for use for header components that are Redux Field components
 *      ex: "url[2].path"
 *      "url" is the name of the ReorderableField component, the value is an array of objects
 *      "[2]" denotes the second object in the array
 *      "path" is the 'path' field of the second object in the array
 */
export const createReorderableFieldItemHeaderComponentName = (name, idx, headerComponent = {}) =>
    `${name}[${idx}].${headerComponent.name}`

/**
 * Performs a global "All or Nothing" validation whereas the form is not valid unless either all or none of values
 * of each field are truthy. If not valid returns an object whose keys are the fields that have not been filled
 * out and each of the values is msg. If valid, an empty object is returned.
 *
 * @param {Array<string>} fields - an array of fields for which all or none should be filled out
 * @param {string} msg - the error displayed for empty fields that should be filled
 * @param {Immutable.Map<string, any>} values - The current form values; a field is considered filled out if
 *                                              values.get(field) is truthy
 * @returns {object} - an object representing form errors
 */
export function allOrNothingValidation(fields, msg, values) {
    const isAnyFilled = !!fields.find((field) => !!values.get(field))
    const isAllFilled = !fields.find((field) => !values.get(field))
    if (isAnyFilled && !isAllFilled) {
        return fields.reduce((acc, field) => (values.get(field) ? acc : { ...acc, [field]: msg }), {})
    } else {
        return {}
    }
}

/**
 * Validates that one field is dependent on another, ie the value of the antecedent should be truthy only if
 * the value of the consequent is truthy.
 *
 * @param {string} antecedentField
 * @param {string} consequentField
 * @param {string} msg - Validation error to be placed on the consequent field
 * @param {Immutable.Map<string, any>} values
 * @returns {object}
 */
export function fieldDependencyValidation(antecedentField, consequentField, msg, values) {
    if (values.get(antecedentField) && !values.get(consequentField)) {
        return { [consequentField]: msg }
    } else {
        return {}
    }
}

/**
 * Combines allOrNothingValidation and fieldDependencyValidation. All allOrNothing fields must be filled out
 * together or not at all. The antecedent fields should only be filled out if the allOrNothingFields are
 * filled out.
 *
 * @param {Array<string>} allOrNothingFields - Fields that are required if other fields are set
 * @param {Array<string>} antecedentFields - Fields that should only be filled if the allOrNothing fields are
 * @param {string} allOrNothingMsg - Message to be placed on allOrNothing fields not filled out if other allOrNothing
 *                                   fields are filled out
 * @param {string} antecedentMsg - Message to be placed on antecedent fields filled out if no allOrNothing fields
 *                                 are filled out
 * @param {Immutable.Map<string, any>} values - A field is considered filled out if values.get(field) is truthy
 * @returns {object} - validation object
 */
export function allOrSomeValidation(allOrNothingFields, antecedentFields, allOrNothingMsg, antecedentMsg, values) {
    const isAnyFilled = !!allOrNothingFields.find((field) => !!values.get(field))
    if (isAnyFilled) {
        return allOrNothingFields.reduce(
            (acc, field) => (values.get(field) ? acc : { ...acc, [field]: allOrNothingMsg }),
            {},
        )
    } else {
        return antecedentFields.reduce(
            (acc, field) => (values.get(field) ? { ...acc, [field]: antecedentMsg } : acc),
            {},
        )
    }
}

/**
 * Creates a custom currency mask function.
 *
 * Namely, it starts to the left of the decimal point instead of the right, due to users feeling it is more natural, even if complex.
 * When the number fills the decimal places, it will start to fill out the rest of the integer places pushing sig digits from right to left.
 *
 * Because the form transformations cannot differentiate between leading zeroes and the initial zero, the edge case of sub 1 dollar inputs should not be
 * expected behavior for now.
 *
 * @param {Object} options - The options for the custom currency mask.
 * @param {string} [options.prefix=""] - The prefix to use for the currency mask.
 * @param {number} [options.multiplier=1] - The value to multiply the input number by before formatting it.
 * @param {string} options.locale - The locale to use for formatting the number.
 * @param {Function} options.onChange - The function to call when the input value changes.
 * @param {string} [options.suffix=""] - The suffix to use for the currency mask.
 * @returns {Function} The custom currency mask function for use with Quorum inputs.
 **/
const createCustomQuorumCurrencyMask = (options) => {
    const { prefix = "", multiplier = 1, locale, onChange } = options || {}
    let { suffix = "" } = options || {}

    if (typeof multiplier !== "number") {
        throw new Error("The custom currency mask's option `multilpier` should be of type number.")
    }
    if (multiplier === 0) {
        throw new Error("The custom currency mask's option `multilpier` cannot be zero.")
    }

    // This is the function that takes the Redux form value and displays it.
    const format = (storeValue) => {
        let number = storeValue
        if (number === null || number === undefined || number === "") {
            number = "0"
        }
        number = number.toString()

        // Where the magic happens!
        number = number.replace(".", "")
        if (number?.length < 2) {
            // 1.00, 2.00, 3.00, etc.
            suffix = ".00"
        } else if (number?.length === 2) {
            number = `${number[0]}.${number[1]}` // 1.10, 2.20, 3.30, etc.
            suffix = "0"
        } else if (number?.length === 3) {
            number = `${number[0]}.${number[1]}${number[2]}` // 1.11, 2.22, 3.33, etc.
            suffix = ""
        } else if (number?.length > 3) {
            number *= 1 / multiplier
            number = number.toLocaleString(locale, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            suffix = ""
        }

        return `${prefix}${number}${suffix}`
    }

    // This is the function that stores the new value inside Redux form for sending to the backend.
    const normalize = (updatedValue, previousValue) => {
        const escapedPrefix = escapeRegExp(prefix)
        const prefixRegex = new RegExp(`^[-|+]? ?${escapedPrefix}`)

        const escapedSuffix = escapeRegExp(suffix)
        const suffixRegex = new RegExp(`${escapedSuffix}$`)

        let digits = updatedValue

        // Strip.
        if (prefix) {
            digits = digits.replace(prefixRegex, "")
        }
        if (suffix && suffixRegex) {
            digits = digits.replace(suffixRegex, "")
        }

        // Remove non digits.
        digits = digits.replace(/\D/g, "")

        // Turn into number for mathematical manipulation, but keep digits above for required string awareness.
        let number = Number(digits)

        // Where the magic happens! Basically adjust for the defaulted left shift x2.
        if (number?.toString().length === 2) {
            number /= 10 // 11 -> 1.10, 22 -> 2.20, 33 -> 3.30, etc.
            number = number.toFixed(1)
        } else if (number?.toString().length > 2) {
            number /= 100 // 111 -> 1.11, 222 -> 2.22, 333 -> 3.33, etc.
            number = number.toFixed(2)
        }

        // Move cursor position.
        const hasValueChanged = number !== previousValue
        if (onChange && hasValueChanged) {
            onChange(number)
        }

        // TODO: Uncomment if want to start playing around with rare edge case of less than $1 contributions (borderline limited due to leading 0).
        // if (digits?.startsWith("00")) {
        //     console.log("in the starting with 00")
        //     number /= 100
        // }
        // else if (digits?.startsWith("0") && digits?.endsWith("0")) {
        //     console.log("in the starting with 0")
        //     number /= 100
        //     number = number.toFixed(1)
        // }

        return number
    }

    const manageCursorPosition = (event) => {
        const { target } = event

        if (target) {
            if (event.persist) {
                event.persist()
            }
            setTimeout(() => {
                const caretPos = target.value.length - suffix.length
                event.target.setSelectionRange(caretPos, caretPos)
            })
        }
    }

    return {
        format: (storeValue) => format(storeValue),
        normalize: (updatedValue, previousValue) => normalize(updatedValue, previousValue),
        onChange: (event) => manageCursorPosition(event),
        onFocus: (event) => manageCursorPosition(event),
        autoComplete: "off",
    }
}

/**
 * Get an object representing the currency input mask for a given currency. When using redux-forms this can be
 * passed to Field elements like so,
 *      <Field
 *          name="amount"
 *          label="Amount"
 *          {...getCurrencyInputMask()}
 *      />
 *
 * @param currencyType {number} - The CurrencyType to generate the input mask for
 * @returns {Object} - an object that can be spread into a Field to create the input mask
 */
export const getCurrencyInputMask = ({ currencyType = CurrencyType.usd.value } = {}) => {
    const currencyMask = createNumberMask({
        allowNegative: true,
        decimalPlaces: 2,
        multiplier: 100,
        // prefix: "$",
        locale: "en-US",
    })
    switch (currencyType) {
        case CurrencyType.usd.value:
            return currencyMask
        default:
            throw new Error(`Unknown currency type ${currencyType}`)
    }
}

/**
 * Check if a value is a valid Youtube or Vimeo link. Regex will check all forms of valid Youtube and Vimeo links
 * ex: https://www.youtube.com/watch?v=yEF-gBCGLmQ or http://www.youtube.com/embed/yEF-gBCGLmQ/ or https://youtu.be/yEF-gBCGLmQ
 * ex: https://vimeo.com/299007338 or https://player.vimeo.com/video/299007338
 *
 * @param value - value to check
 * @return {string|undefined} - error message if invalid, otherwise undefined to indicate a valid link
 */
export const requiredYoutubeVimeoLinkValidation = (value) => {
    if (value && !regexes.youtubeVideoIdRegex.test(value) && !regexes.vimeoVideoIdRegex.test(value)) {
        return "Must be a valid Youtube or Vimeo link"
    } else if (!value) {
        return "Youtube or Vimeo link is required"
    }
}

// Reducer that simply merges, for use in useReducer.
// Discussion: https://www.reddit.com/r/reactjs/comments/t8mmc9/should_i_usereducer/hzprj69/
export const MergeReducer = (state, action) => Object.freeze({ ...state, ...action })

/**
 * Does this look like a validation error thrown by our API?
 * @return boolean
 */
export const isValidationError = (error) => !!error?.response?.data?.error

/**
 * Given a validation error thrown by an API request, attempt to decipher a human-readable message.
 * Removes unicode decorators — e.g. "u'[Error explanation from API]'".
 * If all else fails, stringify the error.
 * @return string
 */
export const decipherValidationErrorMessage = (error) => {
    let message = error?.response?.data?.message ?? error?.response?.data?.error ?? `${error}`
    if (message.startsWith("[u'") && message.endsWith("]")) {
        message = message.substring(3, message.length - 2)
    }
    return message
}

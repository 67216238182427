import React from "react"
import PropTypes from "prop-types"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import { renderButtonLink, renderStyledVideo } from "QuorumGrassroots/widgets/styled-components/helperFunctions"
import {
    StyledCallToActionWidget,
    StyledCallToActionText,
    StyledCallToActionButton,
    StyledContentBlock,
    StyledImage,
    StyledTitle,
    StyledVideoWrapper,
} from "QuorumGrassroots/widgets/CallToAction/components/style"
import { StyledBackground } from "QuorumGrassroots/widgets/styled-components/StyledWidget"

const contentBlock = ({
    buttonText,
    buttonUrl,
    hideCtaButton,
    isLeftAligned,
    mediaType,
    text,
    title,
    useBackgroundColor,
    backgroundColor,
}) => (
    <StyledContentBlock
        isLeftAligned={isLeftAligned}
        useBackgroundColor={useBackgroundColor}
        backgroundColor={backgroundColor}
        mediaType={mediaType}
    >
        <StyledTitle className="call-to-action-title" useBackgroundColor={useBackgroundColor}>
            {title}
        </StyledTitle>
        <StyledCallToActionText className="call-to-action-text" dangerouslySetInnerHTML={{ __html: text }} />
        {!hideCtaButton && (
            <StyledCallToActionButton className="call-to-action-button">
                {renderButtonLink(buttonUrl, buttonText)}
            </StyledCallToActionButton>
        )}
    </StyledContentBlock>
)

const mediaBlock = ({ mediaType, imageContain, imageUrl, videoUrl }) => {
    switch (mediaType) {
        case DjangIO.app.grassroots.enums.CallToActionMediaType.image.value:
            return <StyledImage className="call-to-action-image" imageContain={imageContain} imageUrl={imageUrl} />
        case DjangIO.app.grassroots.enums.CallToActionMediaType.video.value:
            return (
                <StyledVideoWrapper className="call-to-action-video">
                    {renderStyledVideo(videoUrl, undefined)}
                </StyledVideoWrapper>
            )
        case DjangIO.app.grassroots.enums.CallToActionMediaType.none.value:
        default:
            return null
    }
}

const CallToActionWidget = (props) => (
    <WidgetStyleWrapper className="call-to-action-widget" md={props.colWidth} useWidgetStyling={false}>
        <StyledBackground
            className="call-to-action-background"
            useBackgroundColor={props.useBackgroundColor}
            backgroundColor={props.backgroundColor}
            isFirst={props.isFirst}
            isLast={props.isLast}
            nextWidget={props.nextWidget}
        >
            <StyledCallToActionWidget isLeftAligned={props.isLeftAligned}>
                {contentBlock({
                    buttonText: props.buttonText,
                    buttonUrl: props.buttonUrl,
                    hideCtaButton: props.hideCtaButton,
                    isLeftAligned: props.isLeftAligned,
                    mediaType: props.mediaType,
                    text: props.text,
                    title: props.title,
                    useBackgroundColor: props.useBackgroundColor,
                    backgroundColor: props.backgroundColor,
                })}
                {mediaBlock({
                    mediaType: props.mediaType,
                    imageContain: props.imageContain,
                    imageUrl: props.imageUrl,
                    videoUrl: props.videoUrl,
                })}
            </StyledCallToActionWidget>
        </StyledBackground>
    </WidgetStyleWrapper>
)

CallToActionWidget.propTypes = {
    backgroundColor: PropTypes.string,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
    colWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isFirst: PropTypes.bool.isRequired,
    imageContain: PropTypes.bool.isRequired,
    imageUrl: PropTypes.string,
    isLast: PropTypes.bool.isRequired,
    isLeftAligned: PropTypes.bool.isRequired,
    mediaType: PropTypes.number.isRequired,
    nextWidget: PropTypes.number,
    text: PropTypes.string,
    title: PropTypes.string,
    useBackgroundColor: PropTypes.bool.isRequired,
    videoUrl: PropTypes.string,
}

export default CallToActionWidget

import React from 'react'
import PropTypes from 'prop-types'

import * as colors from "Components/colors"

import * as S from 'Components/Compounds/NumberWidget/style'
import Tooltip from "Components/Molecules/Tooltip"

const NumberWidget = ({
    color,
    data,
    isFraction,
    labels,
    onClicks=[],
    tooltipData,
}) => {
    let midpoint

    // the base font size is 200% (use relative percentage instead of pixels as we then dont need to worry about starting width)
    let fontSize = 200
    // the max numbers of chars we expect to fit at full size. if there are fewer chars, we keep them at 200, since that is already
    // quite large. If we wanted the max to be 100% for example, we would likely bump this to 10
    let maxChars =  5
    // how many characters does our data have?
    let dataLength = isFraction ? String(data[0]).length + String(data[1]).length : data && data.length

    // if we have more chars than the max alloted at the default font size, lets scale font size respectively
    if (dataLength > maxChars) {
        fontSize *= (maxChars / dataLength)
    }

    // finally, if its a fraction, we have a `/` that we want at the center if each side of the fraction is the same, otherwise we want to
    // shift it accordingly.
    if (isFraction) {
        const dataDiff = String(data[0]).length - String(data[1]).length
        // 50 here represents the default midpoint (50%, again, relative). We want to shit that to the right or left
        // dependent on imbalance of fraction sizes, and then scale that related to how much weve already scaled the fontsize
        // halfway point + ratio of chars per percent * ratio of diff per total
        midpoint = 50 + (fontSize / maxChars) * ( dataDiff / dataLength )
    }

    // lets apply similar logic to text size
    let textFontSize = 75
    let textMaxChars = 18
    let textDataLength = labels ? labels.filter(each => each).reduce((acc, each) => acc + each.length, 0) : 0
    if (textDataLength > textMaxChars) {
        textFontSize *= (textMaxChars / textDataLength)
    }

    const renderNumber = () => (
        <svg preserveAspectRatio="xMidYMid" viewBox="0 0 100 100">
            {
                isFraction && data
                ? (
                    <g>
                        <S.Number
                            x={`${midpoint - 5}%`}
                            y="50%"
                            fill={color || colors.QuorumGrey4}
                            textAnchor="end"
                            dominantBaseline="middle"
                            fontSize={fontSize}
                            onClick={onClicks[0]}
                        >
                            {data[0]}
                        </S.Number>
                        <S.Number
                            x={`${midpoint}%`}
                            y="50%"
                            fill={colors.QuorumGrey4}
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fontSize={fontSize}
                        >
                            /
                        </S.Number>
                        <S.Number
                            x={`${midpoint + 5}%`}
                            y="50%"
                            fill={colors.QuorumGrey4}
                            textAnchor="start"
                            dominantBaseline="middle"
                            fontSize={fontSize}
                            onClick={onClicks[1]}
                        >
                            {data[1]}
                        </S.Number>
                    </g>
                ) : (
                    <S.Number
                        x="50%"
                        y="50%"
                        fill={color || colors.QuorumGrey4}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontSize={fontSize}
                        onClick={onClicks[0]}
                    >
                        {data}
                    </S.Number>
                )
            }
            <S.LabelWrapper>
                {
                    labels && labels.filter(label => label).map(label =>
                        <S.Label
                            x="50%"
                            textAnchor="middle"
                            dominantBaseline="hanging"
                            y="65%"
                            fill={colors.QuorumGrey4}
                            fontSize={textFontSize}
                        >
                            { label }
                        </S.Label>
                    )
                }
            </S.LabelWrapper>
        </svg>
    )

    return (
        <S.NumberWidget data-auto-cy="CompoundNumberWidget">
            {
                (
                    tooltipData
                    && tooltipData !== data
                )
                    ? (
                        <React.Fragment>
                            <S.TooltipPositioner>
                                <Tooltip
                                    dataCy="NumberWidgetTooltip"
                                    key={`tooltip-${tooltipData}`}
                                    id={`tooltip-${tooltipData}`}
                                    placement="top"
                                    text={isFraction && tooltipData ? tooltipData.join("/") : tooltipData}
                                />
                            </S.TooltipPositioner>
                            {renderNumber()}
                        </React.Fragment>
                    )
                    : renderNumber()
            }
        </S.NumberWidget>
    )
}

NumberWidget.defaultProps = {
    color: colors.QuorumBlue,
    isFraction: false,
}

NumberWidget.propTypes = {
    color: PropTypes.string,
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    labels: PropTypes.arrayOf(PropTypes.string),
    isFraction: PropTypes.bool,
    onClicks: PropTypes.arrayOf(PropTypes.func),
    tooltipData: PropTypes.string,
}

export default NumberWidget

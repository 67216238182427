import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/LinkGuide/style"

import Icon from "Components/Atoms/Icon"

const renderIcon = ({guideStep, hasVideo, icon, isFeatured, isRead}) => {
    const iconProps = {
        icon,
        isFeatured,
        isRead,
        iconFamily: "fas",
        size: "1x",
    }

    if (isFeatured) {
        iconProps.iconFamily = "fad"
        iconProps.size = "2x"
    } else if (isRead) {
        iconProps.icon = "check"
    } else if (guideStep) {
        return (
            <React.Fragment>
                <S.GuideStep>{guideStep}</S.GuideStep>
                {hasVideo && <S.VideoIcon><Icon icon="video" iconFamily="far"/></S.VideoIcon>}
            </React.Fragment>
        )
    }

    return (
        <S.IconWrapper isFeatured={isFeatured}>
            <Icon
                {...iconProps}
            />
            {hasVideo && !isFeatured && <S.VideoIcon><Icon icon="video" iconFamily="far"/></S.VideoIcon>}
        </S.IconWrapper>
    )
}

const LinkGuide = ({
    guideStep,
    hasVideo,
    onClick,
    icon,
    isFeatured,
    isRead,
    text,
}) => (
    <S.LinkGuide
        data-auto-cy="MoleculeLinkGuide"
        onClick={onClick}
    >
        {renderIcon({guideStep, hasVideo, icon, isFeatured, isRead})}
        <S.Headline isFeatured={isFeatured}>
            <span>{text}</span>
            {isRead && <S.Status>Read!</S.Status>}
        </S.Headline>
    </S.LinkGuide>
)

LinkGuide.defaultProps = {
    icon: "book-reader",
}

LinkGuide.propTypes = {
    guideStep: PropTypes.string,
    hasVideo: PropTypes.bool,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    isFeatured: PropTypes.bool,
    isRead: PropTypes.bool,
    text: PropTypes.string.isRequired,
}

export default LinkGuide

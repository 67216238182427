import PropTypes from "prop-types"

import * as colors from "Components/colors"
import * as helperFunctions from "utils/helperFunctions"

// Media Query Breakpoints
export const Breakpoints = {
    small: "770px",
    medium: "900px",
    large: "921px",
}

// Font Families
export const fontFamily = {
}

// Font Weights
export const weight = {
    thin: `font-weight: 100;`,
    lighter: `font-weight: 200;`,
    light: `font-weight: 400;`,
    regular: `font-weight: 400;`,
    medium: helperFunctions.isUserIE11()
        ? `font-weight: bold;`
        : `font-weight: 500;`,
    bold: `font-weight: 600;`
}

export const style = {
    italic: `font-style: italic;`,
}

// Text Font Styles
export const text = {
    microscopic: `
        font-size: 11px;
        line-height: 12px;
    `,
    miniscule: `
        font-size: 12px;
        line-height: 14px;
    `,
    mini: `
        font-size: 13px;
        line-height: 14px;
    `,
    tiny: `
        font-size: 14px;
        line-height: 16px;
    `,
    smallHeight: `
        font-size: 14px;
        line-height: 17px;
    `,
    small: `
        font-size: 14px;
        line-height: 18px;
    `,
    smallmedium: `
        font-size: 16px;
        line-height: 18px;
    `,
    medium: `
        font-size: 16px;
        line-height: 22px;
    `,
    large: `
        font-size: 20px;
        line-height: 24px;
    `,
    larger: `
        font-size: 21px;
        line-height: 25px;
    `,
    giant: `
        font-size: 24px;
        line-height: 29px;
    `
}

export const MiniTextRegularWeight = `
    ${text.mini}
`

export const RegularWeight = `
    ${weight.regular}
`

export const BoldWeight = `
    ${weight.bold}
`

export const MicroscopicText = `
    ${text.microscopic}
`

export const MinisculeText = `
    ${text.miniscule}
`

export const TinyText = `
    ${text.tiny}
`

export const MediumWeight = `
    ${weight.medium}
`

export const TinyTextLightWeight = `
    ${text.tiny}
    ${weight.light}
`

export const TinyTextMediumWeight = `
    ${text.tiny}
    ${weight.medium}
`

export const SmallText = `
    ${text.small}
`

export const SmallTextItalic = `
    ${text.small}
    ${style.italic}
`

export const SmallTextBold = `
    ${text.small}
    ${weight.bold}
`

export const SmallTextThinWeight = `
    ${text.small}
    ${weight.thin}
`

export const SmallTextLightWeight = `
    ${text.small}
    ${weight.light}
`

export const SmallTextSmallHeightRegularWeight = `
    ${text.smallHeight}
    ${weight.regular}
`

export const SmallTextRegularWeight = `
    ${text.small}
    ${weight.regular}
`

export const SmallTextRegularWeightSpaced = `
    ${SmallTextRegularWeight}
    // change anti aliasing for this text type only
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.25px;
`

export const SmallTextMediumWeight = `
    ${text.small}
    ${weight.medium}
`

export const SmallMediumText = `
    ${text.smallmedium}
`

export const MediumText = `
    ${text.medium}
`

export const MediumTextHeight = `
    ${text.medium}
    line-height: 26px;
`

export const MediumTextLightWeight = `
    ${text.medium}
    ${weight.light}
`

export const MediumTextRegularWeight = `
    ${text.medium}
    ${weight.regular}
`

export const MediumTextRegularWeightSpaced = `
    ${MediumTextRegularWeight}
    // change anti aliasing for this text type only
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.25px;
`

export const MediumTextMediumWeight = `
    ${text.medium}
    ${weight.medium}
`

export const MediumTextBoldWeight = `
    ${text.medium}
    ${weight.bold}
`

export const LargeText = `
    ${text.large}
`

export const LargeTextLightWeight = `
    ${text.large}
    ${weight.light}
`

export const LargeTextMediumWeight = `
    ${text.large}
    ${weight.medium}
`

export const LargerText = `
    ${text.larger}
`

export const GiantText = `
    ${text.giant}
`

export const GiantTextLighterWeight = `
    ${text.giant}
    ${weight.lighter}
`

export const GiantTextLightWeight = `
    ${text.giant}
    ${weight.light}
`

export const GiantTextMediumWeight = `
    ${text.giant}
    ${weight.medium}
`

// Title Font Styles
const title = {
    small: `
        font-size: 30px;
        line-height: 36px;
    `,
    medium: `
        font-size: 35px;
        line-height: 40px;
    `,
    large: `
        font-size: 40px;
        line-height: 45px;
    `,
    giant: `
        font-size: 45px;
        line-height: 54px;
    `
}

export const SmallTitle = `
    ${title.small}
`

export const SmallTitleThinWeight = `
    ${title.small}
    ${weight.thin}
`

export const SmallTitleLightWeight = `
    ${title.small}
    ${weight.light}
`

export const SmallTitleMediumWeight = `
    ${title.small}
    ${weight.medium}
`

export const MediumTitle = `
    ${title.medium}
    ${weight.medium}
`

export const LargeTitle = `
    ${title.large}
    ${weight.medium}
`

export const GiantTitle = `
    ${title.giant}
    ${weight.medium}
`

// Other Font Styles
export const TopNavText = `
    font-size: 16px;
    line-height: 23px;
    ${weight.thin}
`

/**
 * Returns the CSS for text that should be truncated with an ellipsis after
 * a specific number of lines.
 * @param   {Number} numLines   the number of lines after which to truncate
 *
 * @returns {String}    the CSS for the element which contains the text directly
 */
export const multiLineTruncatedText = (numLines) => {
    // These webkit properties allow for using an ellipsis
    // at the end of truncated, multi-line text
    // This multi-line truncation using CSS only works in some
    // browsers, but these properties are the approach used for
    // this behavior throughout Quorum

    // We detect if the user is using IE 11 and use a much more
    // rudimentary approach of setting max height in that case
    return helperFunctions.isUserIE11()
    ? `
        overflow: hidden;
        text-overflow: ellipsis;
        // "normal" line height is 1.2em
        max-height: ${numLines * 1.2}em;
    ` : `
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: ${numLines};
        text-overflow: ellipsis;
        overflow: hidden;
    `
}

// Icons
export const FontAwesomeIconWidth = "1.25em"
export const FontAwesomeIconHeight = "1.5em"

// Other Rules
export const IconFontSize = "14px"
export const IconFontSizeV2 = ".75rem"
export const LargeIconFontSize = "19px"
export const NavigationalTabHeight = "60px"
export const NavigationalTabSpacing = 24; // must be number, measured in px
export const TitleFontSize = "30px"
export const TitleFontLineHeight = "34px"
export const TitleFontWeight = 300
export const CollapsedTextSectionHeight = 132 // measured in px
export const CollapsedTextSectionLines = 6 // corresponds with CollapsedTextSectionHeight using text section styling
export const InputHeight = "38px"
export const sidebarWidth = "60px"

// Transitions
export const TransitionShort = "0.15s"

// Buttons
export const ButtonHeight = "40px"
export const GiantButtonHeight = "50px"
export const TinyButtonHeight = "30px"
export const TinyButtonHeightV2 = "32px"
export const ButtonFontSize = "14px"
export const GiantButtonFontSize = "16px"
export const TinyButtonFontSize = "15px"
export const TinyButtonFontSizeV2 = ".875rem"
export const ButtonFontLineHeight = "22.5px"
export const GiantButtonFontLineHeight = "22px"
export const TinyButtonFontLineHeight = "20px"
export const TinyButtonFontLineHeightV2 = ".875rem"

// Layout
export const TopBarAiSearchPadding = "12px"
export const TopBarPadding = "15px"
export const TopBarPaddingV2 = "16px"
export const TopBarItemPadding = "18px"
export const TopBarItemPaddingSmall = "10px"
export const ProfileComponentPaddingTop = "11px"
export const WorkflowComponentHeight = "375px"
export const TextSectionContentWidth = "83.5%"
export const LargeAvatarCircleDiameter = "94px"

// Borders
export const ThinLine = "0.5px solid"
export const Line = "1px solid"
export const ThickerLine = "2px solid"
export const BorderRadius = "4px"
export const LargeBorderRadius = "6px"
export const BorderRadiusLeft = `${BorderRadius} 0px 0px ${BorderRadius}`
export const BorderRadiusRight = `0px ${BorderRadius} ${BorderRadius} 0px`

export const DropShadow = "0px 2px 5px 0 rgba(0, 0, 0, 0.32)"
export const InnerShadow = "inset 0 1px 3px 0 rgba(0,0,0,0.08)"
export const InputInnerShadow = "inset 0 1px 3px 0 rgba(0,0,0,0.08)"
export const BorderColorTransition = `border-color ${TransitionShort} ease-in-out,box-shadow ${TransitionShort} ease-in-out`
export const CardShadow = "0 2px 4px 0px rgba(0,0,0,0.1)"
export const PopdownBoxShadow = "0 2px 6px 0 rgba(0, 0, 0, 0.45);"
export const SearchBarShadow = "0px 2px 7px rgba(0,0,0,0.1)"
export const VioletShadow = "0px 0px 0px 2px #7259EF"

// Cards
export const CardStyle = ({flat, large, isCompleted}) => `
    ${flat ? '' : `box-shadow: ${CardShadow};`}
    border: ${flat
        ? `${ThickerLine} ${
            isCompleted ? colors.CompletedGreen : colors.QuorumBlue
        }`
        : `${Line} ${colors.SilverChalice}`};
    border-radius: ${large ? LargeBorderRadius : BorderRadius};
`

CardStyle.propTypes = {
    /** If true, don't include a box-shadow. */
    flat: PropTypes.bool,

    /** If true, make border radius slightly larger. */
    large: PropTypes.bool,

    /** If true, green instead of blue. */
    isSuccess: PropTypes.bool,

    /** If true, red instead of blue. Overrides isSuccess. */
    isError: PropTypes.bool,
}

export const ShadowyCardStyle = `
    box-shadow: ${PopdownBoxShadow};
    border: ${Line} ${colors.SilverChalice};
    border-radius: ${BorderRadius};
`

// Data table views (DTVs)
export const DTVCardsPerRow = 3
export const DTVElementsPerRow = 3
export const DTVMinimalElementsPerRow = 4

// Browser rules
// Required when using blank target:
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a
export const aRel ="noopener noreferrer"
export const aTarget = "_blank"

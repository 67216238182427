import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

import Icon from "Components/Atoms/Icon"

export const ColorPicker = styled.div`
    align-items: flex-end;
    display: flex;
    margin-top: 5px;
`

export const ColorPickerContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`

export const ColorPickerColorInputContainer = styled.div`
    // when rendering a single ColorPicker, the color input comes first
    ${props => (!props.multi) && css`
        margin-right: 7px;
    `}
`

export const ColorPickerTextInputContainer = styled.div`
    // when rendering multiple ColorPickers, the text input comes first
    ${props => (props.multi) && css`
        margin-right: 7px;
    `}
    display: flex;
    align-items: end;
    flex-grow: 1;
`

export const ColorPickerRemoveButton = styled(Icon)`
    cursor: pointer;
    font-size: 17px;
    margin-left: 16px;
    margin-bottom: 14px;
`

export const Label = styled.span`
    ${rules.SmallMediumText}
    color: ${colors.QuorumBlue};
    margin-top: 15px;
    margin-bottom: 7px;
`

/**
* Converts a set of interval objects into an array of knob positions on the slider.
* Intervals specify the relative distance between knobs; the 'value'
* of an interval is therefore not restricted to [0, 100]
*
* @name intervalsToKnobs
* @function
* @param {Array} intervals - interval objects to map to knobs
*    Interval objects have this shape:
*    { label: string, value: width }
* @returns {Array} - array of knob positions, which are pecentages on [0, 100]
*/
export const intervalsToKnobs = (intervals) => {
    let acc = 0
    const knobs = intervals.map(({value}, idx, arr) => {
        acc += Math.max(0, value)
        if (idx !== arr.length - 1) {
            return acc
        }
        return undefined
    }).filter(x => typeof x !== "undefined").map(value => Math.round(100 * (value / acc)))

    return knobs
}

/**
* Converts a set of knob positions, labels, and a scale to interval objects
* that match the format of the values prop taken by this component
*
* @name knobsToIntervals
* @function
* @param {Array} knobs - array of percentage positions of knobs
* @param {Array} labels - array of labels to associate with each interval
* @param {Number} scale - scale of the whole slider, for converting percentages
* @returns {Array} - array of interval objects ({ label: string, value: number })
*   that specify the relative distance between each knob on the slider
*/
export const knobsToIntervals = (knobs, labels, scale) => {
    const segmentWidths = knobs.map((v, idx) => v - (idx ? knobs[idx - 1] : 0))
    segmentWidths.push(100 - (knobs[knobs.length - 1] || 0))
    return segmentWidths.map((width, idx) => ({
        label: labels[idx],
        value: (width * scale) / 100,
    }))
}

/**
* Transforms onChange callbacks provided by the parent to callbacks that rc-slider can work with.
* The callback provided by the parent expects to be called with values in the same shape it
* provided for the 'values' prop (array of interval objects), but rc-slider callbacks
* will be called with an array of knob positions.
* This returns a function that transforms the latter into the former, so the returned function
* can be given to rc-slider.
*
* @name convertOnChange
* @function
* @param {function} onChange - onChange prop provided by the parent that takes
*   an array of interval objects
* @param {Array} labels - array of labels to associate with each interval
* @param {Number} scale - scale of the whole slider, for converting percentages
* @returns {Function} - callback function that can be given to rc-slider that calls
*   the provided callback with the interval objects as the argument
*/
export const convertOnChange = (onChange, labels, scale) => (knobs) => onChange(knobsToIntervals(knobs, labels, scale))

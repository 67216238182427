import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/SummaryStatus/style"

const SummaryStatus = ({
    statusObjects
}) => (
    <S.SummaryStatus data-auto-cy="MoleculeSummaryStatus">
        {
            statusObjects && statusObjects.map((status, idx) => (
                <span key={`${status.key}-${status.boldValue}-${status.value}`}>
                    {!!idx && " | "} {status.key}: <S.BoldSpan>{status.boldValue}</S.BoldSpan> {status.value}
                </span>
            ))
        }
    </S.SummaryStatus>
)

SummaryStatus.defaultProps = {}

SummaryStatus.propTypes = {
    // an array of status key/value pairs to show
    // status pairs will be separated by a vertical bar ("|")
    statusObjects: PropTypes.arrayOf(
        PropTypes.shape({
            // value to show following the key, in bold
            boldValue: PropTypes.string,
            // name of the status to show, followed by a colon
            key: PropTypes.string,
            // value to show following the bold value, in normal type
            value: PropTypes.string,
        })
    ),
}

export default SummaryStatus

import { prependAppName } from "shared/imports/sharedHelperFunctions"
const appName = "@@global/"
const _actionTypes = {
    // init
    INIT: "@@redux/INIT",

    // to enable passing things to profiles
    LOADED_MEMBER: "LOADED_MEMBER",
    LOADED_BILL: "LOADED_BILL",
    PREVIOUS_PAGE: "PREVIOUS_PAGE",
    SCROLL_TOP: "SCROLL_TOP",
    PUSH_PAGE: "PUSH_PAGE",
    POP_PAGE: "POP_PAGE",

    // When we load userdata from cookies etc.
    LOADED_USERDATA: "LOADED_USERDATA",
    CLEAR_PROFILE: "CLEAR_PROFILE",
    VERIFY_TIME: "Userdata/VERIFY_TIME",
    VERIFY_START: "Userdata/VERIFY_START",
    GET_VERIFY_TIME: "Userdata/GET_VERIFY_TIME",

    PATCH_USER_INFO_SUCCESS: "PATCH_USER_INFO_SUCCESS",
    PATCH_USER_INFO_START: "PATCH_USER_INFO_START",
    PATCH_USER_INFO_FAIL: "PATCH_USER_INFO_FAIL",

    GET_PEOPLE_START: "GET_PEOPLE_START",
    GET_PEOPLE_SUCCESS: "GET_PEOPLE_SUCCESS",
    GET_PEOPLE_FAIL: "GET_PEOPLE_FAIL",

    GET_SUPPORTERS_START: "GET_SUPPORTERS_START",
    GET_SUPPORTERS_FAIL: "GET_SUPPORTERS_FAIL",
    GET_SUPPORTERS_SUCCESS: "GET_SUPPORTERS_SUCCESS",

    // upload image
    UPLOAD_IMAGE_START: "UPLOAD_IMAGE_START",
    UPLOAD_IMAGE_FAIL: "UPLOAD_IMAGE_FAIL",
    UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",

    GET_TEAMS_START: "GET_TEAMS_START",
    GET_TEAMS_SUCCESS: "GET_TEAMS_SUCCESS",
    GET_TEAMS_FAIL: "GET_TEAMS_FAIL",

    GET_TEAM_MEMBERS_START: "GET_TEAM_MEMBERS_START",
    GET_TEAM_MEMBERS_SUCCESS: "GET_TEAM_MEMBERS_SUCCESS",
    GET_TEAM_MEMBERS_FAIL: "GET_TEAM_MEMBERS_FAIL",

    // update userdata slice of the store
    UPDATE_USERDATA: "UPDATE_USERDATA"
}

// we must do this as to not prepend the app name to LOCATION_CHANGE
const actionTypes = prependAppName(appName, _actionTypes)

export default actionTypes

export const patchUserInfoTypes = {
    success: actionTypes.PATCH_USER_INFO_SUCCESS,
    start: actionTypes.PATCH_USER_INFO_START,
    fail: actionTypes.PATCH_USER_INFO_FAIL,
}
export const getPeopleTypes = {
    start: actionTypes.GET_PEOPLE_START,
    success: actionTypes.GET_PEOPLE_SUCCESS,
    fail: actionTypes.GET_PEOPLE_FAIL,

}
export const getSupporterTypes = {
    start: actionTypes.GET_SUPPORTERS_START,
    fail: actionTypes.GET_SUPPORTERS_FAIL,
    success: actionTypes.GET_SUPPORTERS_SUCCESS,
}

export const uploadImage = {
    start: actionTypes.UPLOAD_IMAGE_START,
    success: actionTypes.UPLOAD_IMAGE_SUCCESS,
    fail: actionTypes.UPLOAD_IMAGE_FAIL
}

export const getTeamsTypes = {
    start: actionTypes.GET_TEAMS_START,
    success: actionTypes.GET_TEAMS_SUCCESS,
    fail: actionTypes.GET_TEAMS_FAIL,
}

export const registerPushToken = {
    start: "does_nothing_yet",
    success: "does_nothing_yet",
    fail: "does_nothing_yet",
}

export const getTeamMembersTypes = {
    start: actionTypes.GET_TEAM_MEMBERS_START,
    success: actionTypes.GET_TEAM_MEMBERS_SUCCESS,
    fail: actionTypes.GET_TEAM_MEMBERS_FAIL,
}

import styled from "styled-components"

import { getFontColorForBackgroundContrast } from "QuorumGrassroots/styled-components/helperFunctions"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"

export const StepperStyleWrapper = styleWithOrgDesign(styled.div`
    --step-primary-color: ${(props) => getFontColorForBackgroundContrast(props.organizationDesign.primary_color)};
    --step-secondary-color: ${(props) => props.organizationDesign.primary_color};
    --step-active-font-color: ${(props) =>
        getFontColorForBackgroundContrast(getFontColorForBackgroundContrast(props.organizationDesign.primary_color))};

    --step-border-color: ${(props) =>
        getFontColorForBackgroundContrast(props.organizationDesign.primary_color, "#00000066", "#FFFFFF66")};
`) as React.FC<any>

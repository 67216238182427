import styled, { css } from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const Tooltip = styled.div`
    display: flex;
    position: absolute;
    z-index: 1000;

    ${rules.CardStyle}
    box-shadow: ${rules.DropShadow};
    ${rules.SmallTextRegularWeight}
    color: ${colors.Black};
    background-color: ${colors.White};
    padding: 11px;

    max-width: 750px;
    transform: translateY(-100%);

    // necessary because the IE DTV Grid forces the whitespace property
    // for all its descendants, but the tooltip should be exempt
    white-space: normal !important;

    // allow breaking and wrapping text that has no spaces, etc.
    word-break: break-all;
    overflow-wrap: anywhere;

    visibility: hidden;
    opacity: 0%;

    // triangle
    &:before {
        content: '';
        position: absolute;
        top: 100%;
        border-top: 6px solid ${colors.White};
        left: calc(50% - 12px / 2);
        border-right: calc(12px / 2) solid transparent;
        border-left: calc(12px / 2) solid transparent;
    }

`

export const TooltipPositioner = styled.div`
    // A rule applied to all but the first child adds a bottom margin
    // The tooltip should be exempt since its not within the body of the element
    &&& {
        margin-bottom: 0px;
    }

    & > :first-child {
        left: 100%;
        transform: translate(-100%, -100%);
        visibility: hidden;
        opacity: 0%;
        transition-duration: ${rules.TransitionShort};
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    position: relative;

    ${({useIETooltip}) => useIETooltip && css`
        &:hover {
            // show a tooltip with a delay when hovered over
            ${Tooltip} {
                transition: opacity 1.5s;
                transition-delay: 0.5s;
                visibility: visible;
                opacity: 100%;
            }
        }
    `}

    &:hover {
        ${TooltipPositioner} {
            & > :first-child {
                visibility: visible;
                opacity: 100%;
                transition-duration: ${rules.TransitionShort};
            }
        }
    }

    // size self in a grid
    grid-column: span ${({columnSpan}) => columnSpan};

    ${({isMinimal}) => {
        if (isMinimal) {
            // The "minimal" DTV element is a slight style variation
            // with no border and less padding
            return css`
                padding-left: 0px;
                padding-right: 0px;
                & > * {
                    margin-top: 0px;
                }
                & > :not(:last-child) {
                    margin-bottom: 6px;
                }
            `
        }
        else {
            return css`
                border: 1px solid ${colors.gray3};
                border-radius: ${rules.BorderRadius};
                padding-left: 14px;
                padding-right: 14px;
                ${props => !props.collapseEmptySpace && css`min-height: 90px;`}
                & > * {
                    margin-top: 8px;
                }
                & > :last-child {
                    margin-bottom: 8px;
                }
            `
        }
    }}
`

export const Title = styled.div`
    ${rules.SmallTextMediumWeight}
    line-height: 18px;
    color: ${colors.Black};
`

export const Subtitle = styled.div`
    ${rules.SmallTextRegularWeightSpaced}
    color: ${colors.gray7};
    line-height: 18px;
`

export const Text = styled.div`
    ${rules.SmallTextRegularWeightSpaced}
    line-height: 18px;
    color: ${colors.Black};
    white-space: break-spaces;

    //IE version of overflow wrap with lower precedent
    word-wrap: break-word;
    overflow-wrap: anywhere;

    ${({truncateText}) => truncateText && css`
        ${rules.multiLineTruncatedText(2)}
        line-height: 18px;
        max-height: 38px;
    `}
`

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 2px;
`

import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import {
    overridePageBackgroundColor,
    overridePageStyle,
    styleInDesktopScreenSize,
    styleInMobileScreenSize,
} from "QuorumGrassroots/styled-components/helperFunctions"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"

const { CampaignFullBleedHeaderType } = DjangIO.app.grassroots.campaign.types

// if we're in frameless, the negative margin is to compensate for the
// default padding in bootstrap columns.
export const StyledRow = styled(Row)`
    margin-left: ${(props) => (props.frameless ? -30 : 0)}px;
    margin-right: ${(props) => (props.frameless ? -30 : 0)}px;
    ${(props) => props.frameless && "margin-bottom: -25px;"}
`

// We are dealing with margins in two separate cases:
// Frameless and framed (with header) mode.
//
// With the header, we want the page to be -45px up, so that it can artistically
// bleed into the header a little.
//
// Without the header, we want the page 10px of padding.
//
// In mobile view:
//
// With the header, we want to move the margin down 10px to accommodate the
// header taking some more space.
//
// Without the header, we just want the margin to stay in the same place: 10px from
// the top.
//
// Basically: we want the widgets to jump a little when we have a frame, and do
// not want them to jump when they are not in the frame.
// separately, overridePageStyle will dynamically override page styles based on actionCenterSettings.theme_type
export const StyledPage = styleWithOrgDesign(styled.div`
    display: flex;
    flex-direction: column;
    ${(props) =>
        !props.frameless &&
        !props.isCampaignPage &&
        `
        padding-bottom: 50px;
    `}

    ${(props) => !props.frameless && styleInMobileScreenSize("margin-top: 20px;")}
    ${(props) =>
        !props.frameless &&
        styleInDesktopScreenSize(`
        padding-right: 50px;
        padding-left: 50px;
    `)}

    ${(props) => overridePageStyle(props)}
    ${(props) => overridePageBackgroundColor(props)}
    ${(props) => !props.frameless && props.isCampaignPage && styleInDesktopScreenSize(`margin-top: 0; padding-top: 0;`)}

    ${(props) =>
        !props.frameless &&
        props.isCampaignPage &&
        props.isTransparent &&
        Number(props.fullBleedHeaderType) === CampaignFullBleedHeaderType.video.value &&
        styleInDesktopScreenSize(`
                padding: 0 16px;
                margin: 0 auto;
                width: 100%;
                max-width: 1280px;
                .page-col {
                    padding: 0;
                }
            `)}


    ${(props) =>
        !props.frameless &&
        props.isCampaignPage &&
        !props.shouldNotShowNavBar &&
        Number(props.fullBleedHeaderType) === CampaignFullBleedHeaderType.none.value &&
        styleInMobileScreenSize("margin-top: 0px; background-color: white; border-radius: 0px;")}

    ${(props) =>
        !props.frameless &&
        props.isCampaignPage &&
        !props.shouldNotShowNavBar &&
        Number(props.fullBleedHeaderType) === CampaignFullBleedHeaderType.none.value &&
        props.campaignBackgroundStyle === DjangIO.app.grassroots.enums.ActionCenterBackgroundStyle.color.value &&
        props.campaignBackgroundColor &&
        styleInMobileScreenSize(`background-color: ${props.campaignBackgroundColor};`)}
`)

export const StyledHalfPageCol = styled(Col)`
    ${(props) =>
        styleInDesktopScreenSize(`
        padding-${props.leftCol ? "right" : "left"}: 0px;
    `)}
`

import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"

import { shareLinkSelectors } from "QuorumGrassroots/campaign-forms/selectors/share-link-selectors"
import { postGrassrootsSupporterAction, completeCampaign } from "QuorumGrassroots/campaign-forms/action-creators"

import ShareLinkForm from "QuorumGrassroots/campaign-forms/components/ShareLinkForm"

const actions = { postGrassrootsSupporterAction, completeCampaign }

export default withNamespaces()(connect(shareLinkSelectors, actions)(ShareLinkForm))

import { createStructuredSelector } from "reselect"
import createCachedSelector from "re-reselect"
import { Map, List } from "immutable"

import { selectImmutableCampaignMessages } from "QuorumGrassroots/campaign-forms/selectors/general-selectors"

const selectMessageForm = (state, props) => state.form.get(props.formName) || Map()

/*
 * SELECTORS THAT LOOK AT THE REDUX-FORM SLICE
 */
export const selectMessageFormSlice = createCachedSelector(
    [selectMessageForm, (_, props) => props.activeId],
    (writeForm, id) => writeForm.getIn(["values", id]),
)((state, props) => props.activeId)

export const selectMessageTarget = createCachedSelector(selectMessageFormSlice, (slice) => {
    const target = slice ? slice.get("target", Map()) || Map() : Map()
    return target.toJS()
})((state, props) => props.activeId)

export const selectSectionHasPreBody = createCachedSelector(selectMessageFormSlice, (slice) =>
    Boolean(slice.get("prebody")),
)((state, props) => props.activeId)

export const selectSectionPreBody = createCachedSelector(selectMessageFormSlice, (slice) => slice.get("prebody"))(
    (state, props) => props.activeId,
)

export const selectSectionHasPostBody = createCachedSelector(selectMessageFormSlice, (slice) =>
    Boolean(slice.get("postbody")),
)((state, props) => props.activeId)

export const selectSectionPostBody = createCachedSelector(selectMessageFormSlice, (slice) => slice.get("postbody"))(
    (state, props) => props.activeId,
)

export const selectBodyMaxLength = createCachedSelector(
    selectSectionPreBody,
    selectSectionPostBody,
    (state, props) => props.maxLength,
    (preBody, postBody, maxLength) => {
        // Calculate the characters the user has left to send after any uneditable pre/post message
        const preCharacters = preBody ? preBody.length : 0
        const postCharacters = postBody ? postBody.length : 0
        const remainingCharacters = maxLength ? maxLength - preCharacters - postCharacters : undefined

        return remainingCharacters
    },
)((state, props) => props.activeId)

export const selectSectionHasDescription = createCachedSelector(selectMessageFormSlice, (slice) =>
    Boolean(slice.get("description")),
)((state, props) => props.activeId)

/*
 * SELECTORS THAT LOOK AT OUR EDIT SLICE
 */

export const selectActiveMessageSlice = createCachedSelector(
    [selectImmutableCampaignMessages, (_, props) => props.activeId],
    (messages, id) => messages.get(id, Map()),
)((state, props) => props.activeId)

export const selectActiveEditSlice = createCachedSelector(selectActiveMessageSlice, (slice) =>
    slice.get("edits", Map()),
)((state, props) => props.activeId)

export const selectNumberMessages = createCachedSelector(selectActiveEditSlice, (editSlice) => {
    const edits = editSlice.get("editedBodies") || List()
    return edits.size
})((state, props) => props.activeId)

export const selectHasMultipleMessages = createCachedSelector(
    selectNumberMessages,
    (number) => number > 1,
)((state, props) => props.activeId)

export const selectCurrentMessageIdx = createCachedSelector(selectActiveEditSlice, (editSlice) =>
    editSlice.get("currentBodyIdx"),
)((state, props) => props.activeId)

export const selectHasNextMessage = createCachedSelector(
    [selectCurrentMessageIdx, selectNumberMessages],
    (idx, total) => idx < total - 1,
)((state, props) => props.activeId)

export const selectHasPreviousMessage = createCachedSelector(
    selectCurrentMessageIdx,
    (idx) => idx > 0,
)((state, props) => props.activeId)

export const selectActiveMessage = createCachedSelector(
    [selectActiveEditSlice, selectCurrentMessageIdx],
    (editSlice, idx) => editSlice.getIn(["editedBodies", idx]),
)((state, props) => props.activeId)

export const selectGrassrootsActionId = createCachedSelector(selectActiveEditSlice, (editSlice) =>
    editSlice.get("grassrootsActionId"),
)((state, props) => props.activeId)

/*
 * CALL SPECIFIC SELECTORS
 */

export const selectIsCalling = createCachedSelector(selectActiveEditSlice, (editSlice) => editSlice.get("isCalling"))(
    (state, props) => props.activeId,
)

export const writeFormSectionSelector = createStructuredSelector({
    target: selectMessageTarget,
    hasDescription: selectSectionHasDescription,
    hasPreBody: selectSectionHasPreBody,
    hasPostBody: selectSectionHasPostBody,
    bodyMaxLength: selectBodyMaxLength,
    // things that are specific to the currently active member
    hasMultipleMessages: selectHasMultipleMessages,
    hasNextMessage: selectHasNextMessage,
    hasPreviousMessage: selectHasPreviousMessage,
})

export const callFormSectionSelector = createStructuredSelector({
    target: selectMessageTarget,
    grassrootsActionId: selectGrassrootsActionId,
    isCalling: selectIsCalling,
})

import { SetStateAction, Dispatch, useMemo, useState, useEffect } from "react"

const getKeyFromSearchParams = (key: string, fallback = "") =>
    new URLSearchParams(window.location.search).get(key) ?? fallback

const clearKeyFromSearchParams = (key: string) => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.delete(key)
    const search = !!searchParams.toString() ? `?${searchParams}` : ""
    window.history.replaceState({}, "", `${window.location.pathname}${search}`)
}

export const useStateInitializedFromSearchParams = <T = Record<string, string>>(
    keys: string[],
    fallback: Partial<T> = {},
): [T, Dispatch<SetStateAction<T>>] => {
    const memoizedKeys = useMemo(() => keys, [JSON.stringify(keys)])
    const initialState = useMemo(
        () =>
            keys.reduce((acc, key) => {
                const value = getKeyFromSearchParams(key)
                if (value) acc[key] = value
                return acc
            }, {} as T),
        [memoizedKeys],
    )

    useEffect(() => {
        // clear keys from search params must done after initial state is set
        // or it will re render all page components
        memoizedKeys.forEach((key) => clearKeyFromSearchParams(key))
    }, [memoizedKeys])

    return useState<T>({ ...fallback, ...initialState })
}

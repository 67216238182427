/*
 * Quorum Typeahead item - this is individual result
 */
import React from "react"
import PropTypes from "prop-types"

const propTypes = {
    onSelectItem: PropTypes.func.isRequired,
    objData: PropTypes.shape({
        text: PropTypes.any,
    }).isRequired,
    index: PropTypes.number,
}

const QuorumTypeAheadItem = props => (
    <li
        className="list-group-item"
        onClick={() => props.onSelectItem(props.objData)}
        data-cy={`list-group-item-${props.index}`}
    >
        {props.objData.text}
    </li>
)

QuorumTypeAheadItem.propTypes = propTypes

export default QuorumTypeAheadItem

import React, { useCallback } from "react"
import PropTypes from "prop-types"
import debounce from "lodash.debounce"

import * as S from "Components/Atoms/ColorInputField/style"

import Icon from "Components/Atoms/Icon"

export const ColorInputField = props => {
    const onChangeDebounced = useCallback(
        debounce(value =>
            props.input.onChange(value), 250
        ),
        []
    )

    return (
        <S.ColorInputFieldContainer data-auto-cy="AtomColorInputField">
            {
                props.label &&
                    <S.Label>{props.label}</S.Label>
            }
            {
                (props.meta.error && props.showError) &&
                    <S.LabelError>{props.meta.error}</S.LabelError>
            }
            {
                props.icon &&
                    <Icon
                        color={props.iconColor}
                        icon={props.icon}
                        iconFamily={props.iconFamily}
                        size={props.iconSize || "1x"}
                        style={{
                            position: "absolute",
                            top: "3px",
                            left: "1px",
                            pointerEvents: "none",
                            zIndex: "1",
                        }}
                    />
            }
            <S.ColorInputField
                data-cy={props.dataCy}
                error={props.meta.error}
                name={props.input.name}
                onChange={(event) => onChangeDebounced(event.target.value)}
                type="color"
                value={props.input.value}
            />
        </S.ColorInputFieldContainer>
    )
}

ColorInputField.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),

    // from redux-form Field
    // https://redux-form.com/8.3.0/docs/faq/customcomponent.md/
    input: PropTypes.shape({
        name: PropTypes.string,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onDragStart: PropTypes.func,
        onDrop: PropTypes.func,
        onFocus: PropTypes.func,
        value: PropTypes.string,
    }),
    // error state implementation based off of
    // https://redux-form.com/8.3.0/examples/syncvalidation/
    meta: PropTypes.object,

    // props
    dataCy: PropTypes.string,
    label: PropTypes.string,
}

export default ColorInputField

import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding: 16px 20px 24px;
    border: 1px solid #ececec;
    border-radius: 16px;
    box-shadow: 0 4px 12px -4px rgba(0,0,0,0.2);
`

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;

    ${rules.GiantTextMediumWeight}
    font-size: 1.25rem;
    line-height: 1.5;
`

export const Title = styled.div`
    color: ${colors.gray9};
    user-select: none;
`

export const TooltipContainer = styled.div`
    & > :first-child {
        transform: translate(calc(-50% + 15px), calc(-100% - 8px));
    }
`

export const Button = styled.div`
    margin-left: auto;
    color: ${colors.QuorumBlue};

    &:not(:hover) {
        & > ${TooltipContainer} {
            > :first-child {
                opacity: 0%;
                visibility: hidden;
                transition-duration: ${rules.TransitionShort};
            }
        }
    }

    &:hover {
        & > svg {
            cursor: pointer;
            opacity: 80%;
            transition-duration: ${rules.TransitionShort};
        }

        & > ${TooltipContainer} {
            & > :first-child {
                opacity: 100%;
                visibility: visible;
                transition-duration: ${rules.TransitionShort};
            }
        }
    }
    transition-duration: ${rules.TransitionShort};
`

export const Subtitle = styled.div`
    -webkit-font-smoothing: subpixel-antialiased;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 12px;
    color: #868E96;
    margin-top: 12px;
`

export const StackContainer = styled.div`
    position: relative;
`

export const StripContainer = styled.div`
    display: flex;
    flex-direction: ${({scrollable}) => scrollable === "reverse" ? "column-reverse" : "column"};

    max-height: ${({scrollable}) => scrollable === "none" ? "none" : rules.WorkflowComponentHeight};
    overflow: ${({scrollable}) => scrollable === "none" ? "auto" : "scroll"};
    overflow-x: hidden;
    border: 1px solid ${colors.gray2};

    ${({empty}) => {
        if (empty) {
            return `
                ${rules.MediumTextRegularWeight}
                min-height: 60px;
                color: ${colors.QuorumGrey4};
                align-items: center;
                justify-content: center;
            `}
    }}
`

export const PopUp = styled.div`
    position: absolute;
    width: 100%;

    top: 0px;
    left: 0px;
`

export const EmptyStateContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 23px;

    & > * {
        margin-top: 10px;
        margin-bottom: 4px;
    }
`

export const ChildInput = styled.div`
    position: relative;
    & textarea {
        width: 100%;
        border: ${rules.Line} ${colors.QuorumGrey4};
        border-top: none;
        border-radius: 0 0 ${rules.BorderRadius} ${rules.BorderRadius};
        resize: none;

        &:focus {
            border-color: ${colors.QuorumGrey4};
        }
    }
`

import { actionTypes } from "QuorumGrassroots/widgets/action-types"
import { actionTypes as frameworkActionTypes } from "QuorumGrassroots/framework/action-types"
import { fromJS, Map } from "immutable"
import flatCombineReducers from "shared/flatCombineReducers"
import campaignFormReducer from "QuorumGrassroots/campaign-forms/reducer"
import { vimeoVideoIdRegex, youtubeVideoIdRegex } from "shared/imports/regex"

const initializeWidgetSlice = () =>
    fromJS({
        content: Map(),
        loadingContent: false,
        loaded: false,
        submitted: false,
        isSubmitting: false,
        postSubmitData: Map(),
        videoCompletionRequired: false,
    })

export const getInitialState = () => {
    // filler for right now.
    return Map()
}

const initialState = getInitialState()

const handlers = {
    [actionTypes.INITIALIZE_WIDGET_SLICE](state, action) {
        return state.set(action.uniqueWidgetId, initializeWidgetSlice())
    },

    [actionTypes.RESET_WIDGET_SLICES](state, action) {
        return state.withMutations((store) => {
            state
                .filter((_, key) => key.includes(action.widgetIdPattern))
                .map((_, key) => store.update(key, (slice) => slice.merge(action.cleanSlate)))
        })
    },

    [actionTypes.DESTROY_WIDGET_SLICE](state, action) {
        return state.delete(action.uniqueWidgetId)
    },

    [actionTypes.DESTROY_ALL_WIDGET_SLICES]() {
        return Map()
    },

    [actionTypes.LOAD_WIDGET_CONTENT_START](state, action) {
        return state.setIn([action.uniqueWidgetId, "loadingContent"], true)
    },

    [actionTypes.LOAD_WIDGET_CONTENT_SUCCESS](state, action) {
        // It's either a response from an action (pure response), a detail request
        // (response.data), or a list request (response.data.objects). A regrettable
        // number of options. Hopefully will phase out the list request in the
        // future.
        let data

        if (!action.response.data) {
            data = action.response
        } else if (action.detailRequest && action.response.data.objects && action.response.data.objects.length === 1) {
            // If we are only expecting to receive a single object from a list request
            // set 'data' to the one object
            data = action.response.data.objects[0]
        } else if (action.response.data.objects) {
            // If we want all objects from a list request
            data = action.response.data.objects
        } else {
            data = action.response.data
        }

        // if there has been no data returned, that means
        // that the filters returned nothing (maybe they entered an invalid
        // campaign). Set the loading status to false and then exit.
        if (!data) {
            return state
                .setIn([action.uniqueWidgetId, "loadingContent"], false)
                .setIn([action.uniqueWidgetId, "loaded"], true)
        }

        // Check if campaign has valid Youtube or Vimeo link in 'required_video_link' field
        // If so, set 'videoCompletionRequired' to true
        const requiredVideoLink = data.required_video_link
        const videoCompletionRequired = Boolean(
            requiredVideoLink &&
                // Check that the link is a valid Youtube or Vimeo link, as well as that the respective API is loaded
                // Common failure state are IE browsers. The Youtube player API is not compatible with IE
                ((window.Vimeo && vimeoVideoIdRegex.test(requiredVideoLink)) ||
                    (window.YT && youtubeVideoIdRegex.test(requiredVideoLink))),
        )

        return (
            state
                .setIn([action.uniqueWidgetId, "content"], fromJS(data))
                .setIn([action.uniqueWidgetId, "loadingContent"], false)
                .setIn([action.uniqueWidgetId, "loaded"], true)
                // Used for memoizing CustomData map widgets
                .setIn([action.uniqueWidgetId, "timestamp"], Date.now())
                .setIn([action.uniqueWidgetId, "videoCompletionRequired"], videoCompletionRequired)
        )
    },

    [actionTypes.LOAD_WIDGET_CONTENT_FAIL](state, action) {
        // if there was a failure, that means that the filters returned nothing (maybe they entered
        // an invalid widget). Set the loading status to false and then exit.
        return state
            .setIn([action.uniqueWidgetId, "loadingContent"], false)
            .setIn([action.uniqueWidgetId, "loaded"], true)
    },

    [actionTypes.SUCCESSFULLY_SUBMITTED_WIDGET](state, action) {
        return state.setIn([action.uniqueWidgetId, "submitted"], true)
    },

    [actionTypes.RESET_SUBMITTED_WIDGET](state, action) {
        return state.setIn([action.uniqueWidgetId, "submitted"], false)
    },

    [actionTypes.SET_POST_SUBMIT_DATA](state, action) {
        return state.setIn([action.uniqueWidgetId, "postSubmitData"], fromJS(action.data))
    },

    [actionTypes.COMPLETE_REQUIRED_VIDEO](state, action) {
        return state.setIn([action.uniqueWidgetId, "videoCompletionRequired"], false)
    },

    [frameworkActionTypes.LOGIN_USER_SUCCESS](state) {
        // Dehydrated campaign fields can change depending on the user, so we have to invalidate any saved
        // campaign widget content
        return state.filter((_, key) => !key.startsWith("campaign/"))
    },
}

function widgetReducer(state = initialState, action) {
    const returnVal = handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state

    return returnVal
}

export default flatCombineReducers([widgetReducer, campaignFormReducer], initialState)

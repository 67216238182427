import { postGrassrootsAction, useMultiActionChildren } from "QuorumGrassroots/services"
import React, { useMemo, useState } from "react"
import swal from "sweetalert"
import { formMap } from "QuorumGrassroots/campaign-forms/CampaignFormMap"
import { useStepperContext } from "QuorumGrassroots/widgets/Stepper/context"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
import { Campaign } from "@/types/djangio"
import {
    getCSRF,
    getPointsForCampaign,
    adaptTargetedMessagesToActionsGenerator,
    adaptMessagesWithoutTargetsToActionsGenerator,
} from "QuorumGrassroots/campaign-forms/helpers"
import { ThanksDisplay } from "QuorumGrassroots/widgets/ReusableComponents/ThanksDisplay/index"
import BACKENDERROR from "app/static/frontend/imports/backenderror"
import { api } from "@/api"
import { swalConfigs } from "QuorumGrassroots/swalConfigs"
import {
    generateWriteAMemberActions,
    generateWriteWhiteHouseActions,
    generateCommentOnRegulationActions,
} from "QuorumGrassroots/campaign-forms/grassrootsActionGenerators"
import { MultiActionFormStepper } from "QuorumGrassroots/campaign-forms/components/MultiAction/FormStepper"
import { StyledContrastText } from "QuorumGrassroots/styled-components/components/StyledContrastText"
import { getPathObject, runUserJavascript } from "QuorumGrassroots/helperFunctions"
import { useGrassrootsActionCenterSettings } from "QuorumGrassroots/services/grassrootsActionCenterSettings"
import { getReduxlessThanksDisplayProps } from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/ThankableWrapper"
import { withGamificationModal } from "QuorumGrassroots/campaign-forms/wrappers/withGamificationModal"

const { CampaignType } = DjangIO.app.grassroots.campaign.types

const patchGrassrootsAction = async (actions: object) => {
    return await api.patch(
        {
            model: DjangIO.app.grassroots.models.GrassrootsSupporterAction,
            params: "decode_enums=false&count=false&exclude=%7B%22interaction_type%22%3A34%7D&archived=false",
        },
        actions,
        {
            headers: {
                "X-Csrftoken": getCSRF(),
            },
        },
    )
}

const submitSupporterAction = async (campaign: Campaign) => {
    const body = {
        campaign: DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(
            campaign.multi_action_parent_id as number,
        ),
        supporter: DjangIO.app.grassroots.models.Supporter.resourceUriFromId(window.userdata.id),
        organization: DjangIO.app.userdata.models.Organization.resourceUriFromId(window.organization.id),
        supporter_action_type: campaign.campaign_type,
        action_center: window.action_center_settings.resource_uri,
        points_earned: getPointsForCampaign(campaign),
    }
    postGrassrootsAction(body)
}

export interface SubmitCallActionProps {
    grassrootsSupporterActionId: string | number
    feedbackText: string
}

export const submitCallAction = async (values: SubmitCallActionProps) => {
    await api.patch(
        {
            model: DjangIO.app.grassroots.models.GrassrootsSupporterAction,
            primaryKey: values.grassrootsSupporterActionId,
        },
        {
            text: values.feedbackText,
        },
        {
            headers: {
                "X-Csrftoken": getCSRF(),
            },
        },
    )
}

const addRequiredValuesToActions = (actions: object[], campaign: Campaign) => {
    return actions.map((action) => ({
        ...action,
        supporter: DjangIO.app.grassroots.models.Supporter.resourceUriFromId(window.userdata.id),
        organization: DjangIO.app.userdata.models.Organization.resourceUriFromId(window.organization.id),
        action_center: window.action_center_settings.resource_uri,
        points_earned: getPointsForCampaign(campaign),
        //overrides the uri from the child campaign added on the action generator
        campaign: DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(campaign.multi_action_parent_id),
    }))
}

export const submitWriteMemberAction = async (campaign: Campaign, messages: object, targets: object) => {
    const writeAMemberMessages = messages[CampaignType.write_member.value]
    const writeAMemberTargets = targets[CampaignType.write_member.value]
    const { adaptedMessages, messageGroups } = adaptTargetedMessagesToActionsGenerator(
        writeAMemberMessages,
        writeAMemberTargets,
    )
    const actions = generateWriteAMemberActions(adaptedMessages, {}, { campaign, messageGroups })
    const actionsWithRequiredValues = addRequiredValuesToActions(actions, campaign)
    await patchGrassrootsAction({ objects: actionsWithRequiredValues })
}

export const submitWriteWhiteHouseAction = async (campaign: Campaign, messages: object) => {
    const writeWhiteHouseMessages = messages[CampaignType.write_white_house.value]
    const { adaptedMessages } = adaptMessagesWithoutTargetsToActionsGenerator(writeWhiteHouseMessages)
    const actions = generateWriteWhiteHouseActions(adaptedMessages, {}, { campaign })
    const actionsWithRequiredValues = addRequiredValuesToActions(actions, campaign)
    await patchGrassrootsAction({ objects: actionsWithRequiredValues })
}

export const submitCommentOnRegulationAction = async (campaign: Campaign, messages: object) => {
    const commentOnRegulationMessages = messages[CampaignType.comment_on_regulation.value]
    const { adaptedMessages, globalValues } = adaptMessagesWithoutTargetsToActionsGenerator(commentOnRegulationMessages)
    const actions = generateCommentOnRegulationActions(adaptedMessages, globalValues, { campaign })
    const actionsWithRequiredValues = addRequiredValuesToActions(actions, campaign)
    await patchGrassrootsAction({ objects: actionsWithRequiredValues })
}

const mutationFnMap = {
    [CampaignType.petition.value]: submitSupporterAction,
    [CampaignType.write_member.value]: submitWriteMemberAction,
    [CampaignType.comment_on_regulation.value]: submitCommentOnRegulationAction,
    [CampaignType.tweet_at_member.value]: async () => 0,
    [CampaignType.write_white_house.value]: submitWriteWhiteHouseAction,
    [CampaignType.call.value]: submitCallAction,
}

export const MultiAction = withGamificationModal((props) => {
    const [isFinished, setIsFinished] = useState(false)

    const childCampaignsQuery = useMultiActionChildren(props.campaign.id.toString())
    const childCampaigns = childCampaignsQuery.data?.objects ?? []
    const pointsEarned = childCampaigns?.reduce((acc, campaign) => acc + (getPointsForCampaign(campaign) ?? 0), 0)
    const actionCenterSettings = useGrassrootsActionCenterSettings(props.campaign.action_center_id)

    const thankableProps = getReduxlessThanksDisplayProps(
        actionCenterSettings,
        props.campaign,
        props.isEmbedded,
        pointsEarned,
    )

    return isFinished ? (
        <ThanksDisplay {...thankableProps} />
    ) : (
        <MultiActionFormStepper campaign={props.campaign}>
            <MultiActionWithoutStepper setIsFinished={setIsFinished} pointsEarned={pointsEarned} {...props} />
        </MultiActionFormStepper>
    )
})

const MultiActionWithoutStepper = (props) => {
    const { currentStep, steps, submitStep, setCurrentStepLoading } = useStepperContext()
    const navigate = useNavigate()
    const childCampaignsQuery = useMultiActionChildren(props.campaign.id.toString())
    const childCampaigns = childCampaignsQuery.data?.objects ?? []
    const selectedChildCampaign = childCampaigns.find(
        (campaign) => campaign?.campaign_type.toString() === currentStep?.name,
    )
    const [messages, setMessages] = useState(
        Object.fromEntries(childCampaigns.map((campaign) => [campaign.campaign_type, campaign.messages])) as Record<
            string,
            Record<number, MultiActionSingleMessage>
        >,
    )
    const [targets, setTargets] = useState(
        Object.fromEntries(childCampaigns.map((campaign) => [campaign.campaign_type, campaign.targets])),
    )

    const ChildComponent = useMemo(() => formMap[selectedChildCampaign?.campaign_type], [selectedChildCampaign])
    const submitMutation = useMutation<void, unknown, Campaign>({
        mutationFn: async (values) => {
            setCurrentStepLoading(true)
            await mutationFnMap[selectedChildCampaign?.campaign_type](values, messages, targets).finally(() =>
                setCurrentStepLoading(false),
            )
        },
        onError: (error) => {
            BACKENDERROR(error, swalConfigs.postGrassrootsActionError, true, false)
            throw error
        },
        onSuccess: () => handleAllCampaignSubmitted(props.pointsEarned),
    })

    const handleAllCampaignSubmitted = (pointsEarned) => {
        submitStep(currentStep.name)
        if (!steps.every((step) => step.isSubmitted)) return

        props.showGamificationLevelUpModalIfEnabled(pointsEarned)

        runUserJavascript(props.campaign.custom_thank_you_javascript)
        const redirectDetails =
            props.campaign.success_redirection_url && getPathObject(props.campaign.success_redirection_url)
        if (!props.isEmbedded && redirectDetails) {
            if (redirectDetails.isInternal) {
                navigate(redirectDetails.url)
            } else {
                swal({ icon: "success", title: "Thank you! You are now being redirected..." })
                window.location.assign(redirectDetails.url)
            }
        } else {
            props.setIsFinished(true)
        }
    }

    if (childCampaignsQuery.isLoading) return <StyledContrastText isCampaignPage>Loading...</StyledContrastText>
    if (childCampaignsQuery.isError)
        return <StyledContrastText isCampaignPage>We had a problem loading the campaigns</StyledContrastText>

    return (
        <>
            <ChildComponent
                {...props}
                campaign={selectedChildCampaign}
                isMultiAction={true}
                submitMultiActionChild={submitMutation.mutate}
                isLoading={submitMutation.isLoading}
                maMessages={messages}
                setMaMessages={setMessages}
                setTargets={setTargets}
                maTargets={targets}
                parentId={props.campaign.id.toString()}
            />
        </>
    )
}

import React from "react"

import styled from "styled-components"
import { Button } from "react-bootstrap"

export const InputCarouselWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const CarouselPagination = styled(Button)`
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    margin-right: 10px;
`

import styled from "styled-components"

import * as colors from "Components/colors"

export const SearchableDropdownButton = styled.div`
    position: relative;
`

export const SelectWrapper = styled.div`
    position: absolute;
    z-index: 2;
`

// Override react-select's default styles
export const selectStyles = {
    control: provided => ({
        ...provided,
        border: `2px solid ${colors.QuorumBlue}`,
        "&:hover": {
            border: `2px solid ${colors.QuorumBlue}`
        },
        borderRadius: "3px",
        boxShadow: "0 2px 7px 0 rgba(0,0,0,0.1)",
        marginTop: "4px",
        minWidth: "260px",
    }),
    placeholder: provided => ({
        ...provided,
        color: colors.unamed9EA9AA,
        fontSize: "14px",
        fontStyle: "italic",
        fontWeight: "400",
    }),
    input: provided => ({
        ...provided,
        fontSize: "14px",
        fontWeight: "300",
    }),
    menu: provided => ({
        ...provided,
        marginTop: "4px",
        width: "100%",
        border: `1px solid ${colors.FormControlBorderColor}`,
        borderRadius: "4px",
        backgroundColor: colors.White,
        boxShadow: "0 2px 7px 0 rgba(0,0,0,0.3)",
    }),
    menuList: provided => ({
        ...provided,
        padding: 0,
    }),
    group: provided => ({
        ...provided,
        padding: 0,
    }),
    groupHeading: provided => ({
        ...provided,
        color: colors.QuorumGrey3,
        fontSize: "14px",
        fontWeight: "400",
        marginTop: ".5em",
    }),
    option: (provided, state) => ({
        ...provided,
        color: colors.QuorumBlue,
        fontSize: "14px",
        cursor: "pointer",
        backgroundColor: state.isFocused ? colors.AquaHaze : colors.White,
    }),
    noOptionsMessage: (provided) => ({
        ...provided,
        fontSize: "14px",
        fontWeight: "400",
        color: colors.SilverChalice,
    }),
    valueContainer: provided => ({
        ...provided,
        cursor: "text",
    })
}

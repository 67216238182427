import React from "react"
import { Col } from "react-bootstrap"

import { StyledPage } from "QuorumGrassroots/framework/components/Page/style"
import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"

export const PageTemplate = (props) => {
    return (
        <StyledPage className="page">
            <Col md={12}>
                <WidgetStyleWrapper title={props.title} useWidgetStyling>
                    {props.children}
                </WidgetStyleWrapper>
            </Col>
        </StyledPage>
    )
}

export default PageTemplate

import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const SearchBarIcon = styled.button`
    height: ${props => props.isGiant ? rules.GiantButtonHeight : rules.ButtonHeight};
    width: ${props => props.isGiant ? rules.GiantButtonHeight : rules.ButtonHeight};
    display: flex;
    align-items: center;
    background-color: ${colors.QuorumGrey1};
    color: ${colors.QuorumBlue};
    padding-left: 8px;
    padding-right: 8px;
    border-radius: ${rules.BorderRadiusRight};
    border: ${rules.Line} ${colors.FormControlBorderColor};
    font-size: 22px;

    // don't want double border on the shared border with the input field
    border-left: none;

    transition: all 0.15s;
    &:hover {
        background: ${colors.Iron};
    }

    ${({ isFilter }) => isFilter && css`
        ${rules.text.tiny}
        background-color: ${colors.White};
        border: none;
        height: 38px;
        padding: 1px 7px;
        width: ${rules.TinyButtonHeight};
        color: ${colors.gray5};

        &:hover {
            color: ${colors.gray7};
            background: ${colors.White};
        }
    `}
`

export const SearchBar = styled.div`
    display: flex;
    align-items: center;
    width: ${({ fullWidth }) => fullWidth ? "100%" : "fit-content"};
    height: ${props => props.isGiant ? rules.GiantButtonHeight : rules.ButtonHeight};

    // stretch our container to be as wide as possible
    flex-grow: 1;

    // prevent the default focus highlight
    & *:focus {
        outline: none;
    }

    ${({ isFilter }) => isFilter && css`
        border: ${rules.Line} ${colors.FormControlBorderColor};
        border-radius: ${rules.BorderRadius};
        box-shadow: ${rules.SearchBarShadow};
        height: 44px;
        margin: 4px 0px;
        overflow: hidden;

        &:hover {
            border: ${rules.Line} ${colors.QuorumBlue};
        }

        &:active, &:focus-within, &:focus {
            border: ${rules.ThickerLine} ${colors.QuorumBlue};
        }
    `}
`

export const SearchBarInput = styled.input`
    padding: 8px;
    font-size: ${props => props.isGiant ? rules.GiantButtonFontSize : rules.ButtonFontSize};
    line-height: 1.5;
    background-color: ${colors.White};
    background-clip: padding-box;
    height: ${props => props.isGiant ? rules.GiantButtonHeight : rules.ButtonHeight};
    box-shadow: ${rules.InnerShadow};

    border: ${rules.Line} ${colors.FormControlBorderColor};
    border-radius: ${rules.BorderRadiusLeft};
    transition: ${rules.BorderColorTransition};

    padding-left: 11px;

    // stretch the input to take up as much of the searchbar container as possible
    flex-grow: 1;

    // change the placeholder text color
    &::placeholder {
        color: ${colors.QuorumGrey3};
    }

    // change the border when focused
    &:focus {
        border-color: ${colors.QuorumBlue};
        border-radius: ${rules.BorderRadiusLeft};
    }

    ${({ isFilter }) => isFilter && css`
        ${rules.weight.regular}
        background-color: ${colors.White};
        border: none;
        box-shadow: none;
        height: 38px;
        min-width: 130px;
        color: ${colors.gray9};

        &::placeholder {
            ${rules.style.italic}
            ${rules.weight.light}
        }

        &::selection {
            color: ${colors.White};
            background: ${colors.QuorumBlue};
        }

        &:focus {
            border: none;
        }
    `}
`

export const SearchBarClear = styled.div`
    ${({ isFilter }) => isFilter && css`
        align-items: center;
        background-color: ${colors.White};
        color: ${colors.gray5};
        display: flex;
        font-size: ${rules.LargeIconFontSize};
        height: 38px;

        &:hover {
            cursor: pointer;
            color: ${colors.gray7};
        }
    `}
`

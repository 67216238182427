/* eslint-disable prefer-destructuring */
//
import { List } from "immutable"

import {
    checkHasPermission,
    checkPermission,
    checkHasRegion,
    checkDoesNotHaveAnyRegion,
    checkHasExactRegions,
    checkHasUserPermission,
} from "shared/imports/permissionFunctions"
import searchConstants from "shared/search/constants"
import { LEADERSHIP_CONNECT_ORG_ID } from "shared/imports/sharedConstants"

import { isFeatureEnabled } from "shared/featureflags/helperFunctions"

export function filterHasValue(value) {
    // cannot be undefined, null, or "".
    if (value == null || value === "") {
        // covers undefined, null
        return false
    }

    if (typeof value === "object") {
        console.warn("Filter value must be serialized before entering store!")
        return false
    }

    return true
}

export function getPermissionedSearchItems(objects) {
    return objects.filter((f) => {
        let shouldShow = true

        const { regions, excludedRegions, permissions, onlyRegions, featureFlags, hideFeatureFlags, userPermissions } =
            f.defaultProps

        if (f.defaultProps.invisible) {
            return false
        }

        if (regions) {
            // check to see whether user is in the proper region to see filter
            shouldShow = shouldShow && checkHasRegion(regions)
        }

        if (onlyRegions) {
            // check to see whether user is in the proper region to see filter
            shouldShow = shouldShow && checkHasExactRegions(onlyRegions)
        }

        if (excludedRegions) {
            // check to see whether user has a region that is being exluded
            shouldShow = shouldShow && checkDoesNotHaveAnyRegion(excludedRegions)
        }

        if (permissions) {
            // check to see whether user has the correct permissions to see filter
            shouldShow = shouldShow && checkHasPermission(permissions)
        }

        if (userPermissions) {
            shouldShow = shouldShow && checkHasUserPermission(userPermissions)
        }

        if (featureFlags && featureFlags.length) {
            shouldShow = shouldShow && featureFlags.filter((featureFlag) => isFeatureEnabled(featureFlag)).length
        }

        if (hideFeatureFlags && hideFeatureFlags.length) {
            shouldShow = shouldShow && !hideFeatureFlags.filter((featureFlag) => isFeatureEnabled(featureFlag)).length
        }

        if (f.defaultProps.shouldShowFunc) {
            // for more complex should show criteria, make a `shouldShowFunc`
            // that evaluates to either true or false!
            shouldShow = shouldShow && f.defaultProps.shouldShowFunc()
        }

        return shouldShow
    })
}

export function isJson(str) {
    try {
        JSON.parse(str)
        return true
    } catch (e) {
        return false
    }
}

export function stringifyFilterValue(value) {
    if (Array.isArray(value) || List.isList(value)) {
        return value.join(",")
    }

    // Check if the value is a Moment object.
    // Moment objects need special handling to be converted to string
    if (moment.isMoment(value)) {
        return value.toISOString()
    }

    // Handle generic objects differently from primitive values or special objects like Moment
    // Convert the object to a JSON string representation
    if (typeof value === "object" && !isJson(value)) {
        return JSON.stringify(value)
    }

    if (value != null) {
        // have to do this != null nonsense because value=false still is valid
        return value.toString()
    }

    return null
}

/**
 * Several filters can be filtered by whether or not they were involved in a bulk upload
 * (either created, edited, or dataDescription-ed by a bulk upload). The permissioning for each
 * of those is handled by this function.
 * @param  {object} bulkUploadType [enum item from DjangIO.app.custom_data.types.BulkUploadType]
 * @return {boolean} true if filter should be displayed to user
 */
export const bulkUploadIsAvailable = (bulkUploadType) => {
    /*
    Filter on permission:
        if no parser, then exclude
        if user doesn't have permission, then exclude
        if !quorum_employees_only, then show
        otherwise only show if (logged in as user or feature flag is enabled)
    */
    const permitted = bulkUploadType.permission_enum
        .map((enumValue) => checkPermission(DjangIO.app.models.PermissionType.by_value(enumValue)))
        .reduce((acc, val) => acc || val, false)
    const quorumEmployeePermission = !bulkUploadType.quorum_employee_only || Userdata.logged_in_as_user
    const featureFlagEnabled = bulkUploadType.feature_flag && isFeatureEnabled(bulkUploadType.feature_flag)
    // if an upload type doesn't have a processor, then it can't be bulk uploaded (donations/secure uploads)
    return Boolean(bulkUploadType.processor && permitted && (quorumEmployeePermission || featureFlagEnabled))
}

/**
 * Given an an array of filters, or just a filters object, unwrap any of the filter objects that have the key for_searchify_filters
 * @name unwrapForSearchifyIfNeeded
 * @function
 * @param {Array[Object] | Object} filters - an array of objects of filters, or just an object of filters
 * @returns {Array[Object]} - An array of objects that have been cleared of any top level for_searchify_filters
 *  wrapping
 */
export const unwrapForSearchifyIfNeeded = (filters, extraFilters = {}) => {
    const normalizedFilters = filters.constructor === Object ? [filters] : filters

    return [].concat(
        ...normalizedFilters.map((filterObject) => {
            if (filterObject.for_searchify_filters) {
                // https://stackoverflow.com/a/67148404/6201291
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { filters, for_searchify_filters, ...otherFilters } = {
                    ...filterObject,
                    ...filterObject.for_searchify_filters,
                }

                return unwrapForSearchifyIfNeeded(filterObject.for_searchify_filters.filters, otherFilters)
            } else {
                return {
                    ...filterObject,
                    ...extraFilters,
                }
            }
        }),
    )
}

/**
 * Return filters wrapped in for_searchify_filters, removing double nesting and prevent further nesting of for_searchify_filters
 * (don't wrap custom_data saved searches. They behave differently than other saved searches)
 * @param {object|array} currentFilters the filters to be wrapped on for_searchify_filters
 * @param {Number} qdt the qdt that was used on searchify
 * @return {object} wrapped filters
 */
export const wrapFiltersInForSearchify = (currentFilters, qdt) => {
    // first, make sure filters are completely unwrapped from for_searchify_filters
    const unwrappedFilters = unwrapForSearchifyIfNeeded(currentFilters)
    if (qdt === DjangIO.app.models.QuorumDataType.custom_data.value) {
        return unwrappedFilters
    }
    // wrap the filters once
    return [{ for_searchify_filters: { qdt, filters: unwrappedFilters } }]
}

export const isCustomFilterGroup = (filterGroup) => Boolean(filterGroup.defaultProps.customDataType)

export const isConnectedFilterGroup = (filterGroup) => filterGroup.defaultProps.isConnectedFilterGroup

export const isArabic = (input) => /[\u0600-\u06FF\u0750-\u077F]/.test(input)

/**
 * Given an optional initial start year, return a list of enum items of years ranging from a start year to the current year + 7.
 * @name generateElectionYearEnumItems
 * @function
 * @param {object} [args]
 * @param {number} [args.initialStartYear] - optional start year for range of years
 * @param {boolean} [args.isTruncated] - whether the range should be truncated to only include even years
 * @returns {Array[Object]} - An array of objects that will fill the enum items for election years.
 */
export const generateElectionYearEnumItems = ({ initialStartYear, isTruncated }) => {
    const startYear = isTruncated
        ? initialStartYear || new Date().getFullYear()
        : // https://quorumanalytics.atlassian.net/browse/QPAC-896
          // "start with election year 2010"
          2010
    // show election years 7 years in advance since senators run on 6 year cycles, so on 2019 it should broadcast the 2026 cycle.
    // ^ this old comment conflicts with https://quorumanalytics.atlassian.net/browse/QPAC-852

    // should display the next 3 even years
    // (include current year as one of the three if we are in an even year)
    // if it is 2022, the end year should be 2026
    // if it is 2023, the end year should be 2028
    const offset = isTruncated ? 5 : 100
    const endYear = new Date().getFullYear() + offset
    const electionYears = []
    let idx = startYear
    while (idx <= endYear) {
        electionYears.push(idx)
        if (isTruncated) {
            idx += 2
        } else {
            idx += 1
        }
    }

    return electionYears.map((year) => ({
        key: year,
        value: year,
        label: `${year}`,
    }))
}

/**
 * Return a list of enum items of years ranging from [currentYear, currentYear - numYears]
 * the default range when (numYearsBack = 3, currentYear = 2021) => [2021, 2020, 2019, 2018]
 * @name generatePreviousToCurrentYearsEnumItems
 * @function
 * @param {Number} numYearsBack - number of years to load from the past
 * @returns {Array[Object]} - An array of objects that will fill the enum items for years.
 */
export const generatePreviousToCurrentYearsEnumItems = (numYearsBack = 6) => {
    const startYear = new Date().getFullYear() - numYearsBack
    const endYear = new Date().getFullYear()
    const listOfYears = Array(endYear - startYear + 1)
        .fill()
        .map((_, idx) => endYear - idx)
    return listOfYears.map((year) => ({ key: year, value: year, label: year }))
}

// given a QuorumDataType, return its corresponding x_default_search_filters
// ForeignKey object defined in app/mixins/mixins.py DefaultSearches
export const getQdtDefaultSearchFilters = (qdtObj) => {
    const qdtKey =
        qdtObj.value === DjangIO.app.models.QuorumDataType.media.value
            ? // special case:
              // QuorumDataType.media.key is "media",
              // whereas the ForeignKey on UserInfo DefaultSearches is article_default_search_filters
              // we fix this by using QuorumDataType.media.related_field, which is "article" and matches the DefaultSearches ForeignKey
              // TODO: rename article_default_search_filters to media_default_search_filters
              qdtObj.related_field
            : qdtObj.key
    // establish key
    return `${qdtKey}_default_search_filters`
}

/**
 * Used to tell if a note for a user's organization can be edited by the user whose info is in Userdata
 * @name noteCanBeEditedByUser
 * @function
 * @param {Number} creatorId - ID of user who created the note
 * @returns {Boolean} - whether the current user can edit the note with the given creator
 */
export const noteCanBeEditedByUser = (creatorId) => {
    if (Userdata.should_limit_editing_notes_to_admins_and_creators) {
        return Userdata.is_admin || Userdata.id === creatorId
    }

    return true
}

/**
 * Used to tell if a note can be edited
 * @name canEditNote
 * @function
 * @param {Object} interactionType - Enum for note's interaction type
 * @param {Number} creatorId - ID of user who created the note
 * @param {Boolean} autoCreated - whether the note was auto generated
 * @returns {Boolean} - whether the current user can access a note with the passed parameters
 */
export const canEditNote = (interactionType, creatorId, autoCreated) =>
    // if it's not a Grassroots Action
    !DjangIO.app.userdata.types.GrassrootsInteractionType.filter_groups
        .find((group) => group.title === "Grassroots")
        .values.includes(interactionType && interactionType.value) &&
    // was not auto created
    !autoCreated &&
    // and is editable
    !(interactionType && !interactionType.editable) &&
    // and this org allows it to be edited
    noteCanBeEditedByUser(creatorId)

/**
 * Filter to determine whether or not a qdt item passed in is allowed.
 * First checks if the user's org is MSA, if so the function makes sure
 * the dataset is allowed per https://quorumanalytics.atlassian.net/browse/QI-11999.
 * Otherwise, the item bypasses the filtering returns true
 *
 * @param {QuorumDataType} item
 * @returns Boolean
 */
export const msaDatasetFilter = (item) => {
    const MSA_ORG_ID = 2452
    const isMsaOrg = Userdata.organization_id === MSA_ORG_ID
    const allowedDatasets = [
        DjangIO.app.models.QuorumDataType.alert,
        DjangIO.app.models.QuorumDataType.amendment,
        DjangIO.app.models.QuorumDataType.bill,
        DjangIO.app.models.QuorumDataType.state_bill_supporting_document,
        DjangIO.app.models.QuorumDataType.document,
        DjangIO.app.models.QuorumDataType.event,
        DjangIO.app.models.QuorumDataType.regulation,
        DjangIO.app.models.QuorumDataType.tracking_dashboard,
    ]
    return isMsaOrg ? allowedDatasets.includes(item.quorumDataType) : true
}

/**
 * Filter to determine whether or not a SidebarItem passed in is allowed.
 * First checks if the user's org is KSE, if so the function makes sure
 * the sidebar item is allowed per https://quorumanalytics.atlassian.net/browse/QI-5818.
 * Otherwise, the item bypasses the filtering and returns true
 *
 * @param {QuorumDataType} item
 * @returns Boolean
 */
export const kseSidebarItemFilter = (item) => {
    const KSE_ORG_ID = 1848
    const isKseOrg = Userdata.organization_id === KSE_ORG_ID
    const allowedKseSidebar = [
        DjangIO.app.models.SidebarItem.advanced_search.value,
        DjangIO.app.models.SidebarItem.email_alerts.value,
        DjangIO.app.models.SidebarItem.issue_management.value,
        DjangIO.app.models.SidebarItem.tracking_dashboards.value,
    ]
    return isKseOrg ? allowedKseSidebar.includes(item.value) : true
}

// Ad-hoc KSE snippet. Believe this should eventually be removed -- it is the reasonable patch for the immediate nature of QI-5818.
const kseOrganizationId = 1848
const allowedKseDatasets = [
    DjangIO.app.models.QuorumDataType.alert,
    DjangIO.app.models.QuorumDataType.amendment,
    DjangIO.app.models.QuorumDataType.bill,
    DjangIO.app.models.QuorumDataType.document,
    DjangIO.app.models.QuorumDataType.event,
    DjangIO.app.models.QuorumDataType.issue_management,
    DjangIO.app.models.QuorumDataType.regulation,
    DjangIO.app.models.QuorumDataType.tracking_dashboard,
]
export const kseFilter =
    Userdata.organization_id === kseOrganizationId
        ? (item) => allowedKseDatasets.includes(item.quorumDataType)
        : (item) => item // Backwards compatible.

/**
 * Filter to determine whether or not a SidebarItem passed in is allowed.
 * First checks if the user's org is MSA, if so the function makes sure
 * the sidebar item is allowed per https://quorumanalytics.atlassian.net/browse/QI-11999.
 * Otherwise, the item bypasses the filtering and returns true
 *
 * @param {QuorumDataType} item
 * @returns Boolean
 */
export const msaSidebarItemFilter = (item) => {
    const MSA_ORG_ID = 2452
    const isMsaOrg = Userdata.organization_id === MSA_ORG_ID
    const allowedMsaSidebar = [
        DjangIO.app.models.SidebarItem.advanced_search.value,
        DjangIO.app.models.SidebarItem.email_alerts.value,
        DjangIO.app.models.SidebarItem.tracking_dashboards.value,
    ]
    return isMsaOrg ? allowedMsaSidebar.includes(item.value) : true
}

/**
 * Filter to determine whether or not a qdt item passed in is allowed for the YMCA state student org.
 * First checks if the user's org is the YMCA state student org, if so the function makes sure
 * the dataset is allowed per https://quorumanalytics.atlassian.net/browse/QI-13190.
 * Otherwise, the item bypasses the filtering returns true
 *
 * @param {QuorumDataType} item
 * @returns Boolean
 */
export const ymcaDatasetFilter = (item) => {
    const YMCA_ORG_ID = 2539
    const isYMCAOrg = Userdata.organization_id === YMCA_ORG_ID
    const allowedDatasets = [
        DjangIO.app.models.QuorumDataType.bill,
        DjangIO.app.models.QuorumDataType.person,
        DjangIO.app.models.QuorumDataType.staffer,
        DjangIO.app.models.QuorumDataType.issue_management,
        DjangIO.app.models.QuorumDataType.district,
    ]
    return isYMCAOrg ? allowedDatasets.includes(item.quorumDataType) : true
}

/**
 *  Filter to determine whether or not a SidebarItem passed in is allowed.
 * First checks if the user's org is the LC, if so the function makes sure
 * the dataset is allowed per https://quorumanalytics.atlassian.net/browse/QI-10667.
 * Otherwise, the item bypasses the filtering returns true
 *
 * @param {QuorumDataType} item
 * @returns Boolean
 */
export const lcSidebarItemFilter = (item) => {
    const isLCOrg = Userdata.organization_id === LEADERSHIP_CONNECT_ORG_ID
    const prohibitedDatasets = [
        DjangIO.app.models.SidebarItem.sheets.value,
        DjangIO.app.models.SidebarItem.tracking_dashboards.value,
        DjangIO.app.models.SidebarItem.dashboards.value,
        DjangIO.app.models.SidebarItem.issue_management.value,
        DjangIO.app.models.SidebarItem.email_alerts.value,
        DjangIO.app.models.SidebarItem.outbox.value,
        DjangIO.app.models.SidebarItem.new_downloads.value,
    ]
    return isLCOrg && prohibitedDatasets.includes(item.quorumDataType) ? false : true
}

/**
 * Filter to determine whether or not a SidebarItem passed in is allowed for the YMCA state student org.
 * First checks if the user's org is the YMCA state student org, if so the function makes sure
 * the sidebar item is allowed per https://quorumanalytics.atlassian.net/browse/QI-13190.
 * Otherwise, the item bypasses the filtering and returns true
 *
 * @param {QuorumDataType} item
 * @returns Boolean
 */
export const ymcaSidebarItemFilter = (item) => {
    const YMCA_ORG_ID = 2539

    const isYMCAOrg = Userdata.organization_id === YMCA_ORG_ID

    const allowedYMCASidebar = [
        DjangIO.app.models.SidebarItem.new_downloads.value,
        DjangIO.app.models.SidebarItem.districts.value,
        DjangIO.app.models.SidebarItem.issues.value,
        DjangIO.app.models.SidebarItem.sheets.value,
        DjangIO.app.models.SidebarItem.advanced_search.value,
    ]

    return isYMCAOrg ? allowedYMCASidebar.includes(item.value) : true
}

/**
 * This is a fallback function if a browser does not support
 * the Async Clipboard API.
 *
 * @param {String} link - the link being copied to the clipboad
 */
export const fallbackCopyLinkToClipboard = (link) => {
    const textArea = document.createElement("textarea")
    textArea.value = link
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
        document.execCommand("copy")
        swal("Copied!", searchConstants.LINK_COPY_SUCCESS_MSG, "success")
    } catch (err) {
        swal("Warning", searchConstants.LINK_COPY_FAIL_MSG, "warning")
    }
    document.body.removeChild(textArea)
}

/**
 * This copies a link to the clipboard and shows a success/fail swal alert
 * using the async clipboard API (see the Chrome 66 announcement post
 * for more info: https://web.dev/async-clipboard/). It also takes
 * into account browsers that don't yet support this API by having
 * a fallback method.
 *
 * @name copyLinkToClipboard
 * @function
 * @param {String} link - the link being copied to the clipboad
 */
export const copyLinkToClipboard = async (link) => {
    if (!navigator.clipboard) {
        fallbackCopyLinkToClipboard(link)
        return
    }

    try {
        await navigator.clipboard.writeText(link)
        swal("Copied!", searchConstants.LINK_COPY_SUCCESS_MSG, "success")
    } catch (e) {
        swal("Warning", searchConstants.LINK_COPY_FAIL_MSG, "warning")
    }
}

export const handleSearchError = (error) => {
    const isUnprocessableEntity = error && error.response && error.response.status === 422
    if (isUnprocessableEntity) {
        swal({
            icon: "error",
            title: "Oops!",
            text: error.response.data && error.response.data.msg,
            actions: { ok: "OK" },
        })
        return
    }

    // (BACKENDERROR currently only exists on web.)
    if (typeof BACKENDERROR !== "undefined") {
        BACKENDERROR(error)
    }
    console.error(error)
}

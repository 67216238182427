import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"


export const SummaryStatusBar = styled.div`
    display: flex;
    flex-direction: row;

    margin-top: 15px;
    margin-bottom: 15px;

    ${rules.MediumTextLightWeight}
    color: ${colors.QuorumBlue};

    & > :not(:first-child) {
        padding-left: 90px;
    }

`

export const SessionInfo = styled.span`
    margin-left: auto;
`

export const RightContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: auto;
`

export const BoldSpan = styled.span`
    ${rules.MediumWeight}
`

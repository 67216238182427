import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import {
    getHeaderBackgroundColorRuleMinusSemiColon,
    overrideHeaderPadding,
    overrideHeaderPaddingLogoOnly,
    overrideHeaderStyle,
    styleHeaderImageOrColor,
    styleInDesktopScreenSize,
} from "QuorumGrassroots/styled-components/helperFunctions"
import styled from "styled-components"

export const HeaderWrapper = styleWithOrgDesign(styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    ${(props) => getHeaderBackgroundColorRuleMinusSemiColon(props)};

    ${(props) =>
        styleInDesktopScreenSize(`
        height: 150px;
        ${overrideHeaderStyle(props)}
        height: ${props.isCampaignPage && props.logoOnly && "unset"};
        ${styleHeaderImageOrColor(props)}
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: center;
        background-blend-mode: multiply;

        padding-left: 80px;
        padding-right: 50px;
        position: relative;
        ${props.logoOnly ? overrideHeaderPaddingLogoOnly(props) : overrideHeaderPadding(props)}
        ${props.shouldNotDisplay ? `height: 100px;` : ""}
        ${
            props.isCampaignPage &&
            (props.logoOnly ? `padding-top: 40px; padding-bottom: 40px;` : `padding-left: 40px; padding-right 40px; `)
        }}
    `)}
    ${(props) => (props.isTransparent ? `background-color: transparent;` : "")}
    ${(props) => (props.isCampaignPage && props.navbarStyleIsNone ? `display: none;` : "")}
`)

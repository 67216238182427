// https://projects.invisionapp.com/d/main/#/console/19025613/429117170/inspect

import styled from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const SearchifyFields = styled.div`
    margin-bottom: 20px;
`

export const LabelDeleteContainer = styled.div`
    ${rules.SmallMediumText}
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;

`

export const DeleteContainer = styled.div`
    color: ${colors.MidPurple};
`

export const Label = styled.span`
    color: ${colors.QuorumBlue};
`

import styled, {css} from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

import { Tag } from "Components/Molecules/Tag/style"

export const GatewayWrapper = styled.div`
    ${rules.CardStyle}
    width: ${props => props.customWidth || '360px'};
    min-height: ${props => props.customHeight || '150px'};
    padding: ${props => props.customPadding || '10px'};
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: ${({onClick}) => onClick ? "pointer" : "unset"};
    background-color: ${colors.White};
    border: 1px solid ${colors.gray2};
    transition: background-color .1s linear;

    ${props => props.dropShadow && "box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.12);"}

    ${props => props.type === "tutorial"
        ? css`
            background-color: ${props.isCompleted ? colors.DarkerPorcelain : colors.White};
            &:hover {
                background-color: ${colors.InlineHoverColor};
            }
        `
        : css`
            &:hover {
                background-color: ${colors.violet1};
                box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.16);
                border-color: ${colors.violet1};

                ${Tag} {
                    background-color: ${colors.violet7};
                }
            }
        `
    }
`

export const Header = styled.div`
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: ${props => {
        if (props.justifyContent) {
            return props.justifyContent
        }
        return props.icon ? "center" : "flex-start"
    }};
    align-items: center;
    padding-bottom: 8px;
`

const rightAlign = "margin-left: auto; margin-right: 5px;"
const shiftMarginTopUp = "margin-top: -10px;"
export const Icon = styled.div`
    font-size: 24px;

    ${props => props.iconOnRight && shiftMarginTopUp}

    ${props => props.isCompleted ?
        css`
            margin-left: auto;
            color: ${colors.MidPurple};
        `:
        css`
            margin-right: 12px;
            color: ${colors.violet5};
        `
    }

    ${props => props.iconOnRight && rightAlign}
`

export const HeaderText = styled.div`
    ${props => props.iconOnRight && shiftMarginTopUp}
    ${props => props.icon && !props.iconOnRight ? rules.GiantText : rules.LargeText}
    color: ${colors.violet7};
    font-weight: 500;
`

export const Content = styled.div`
    ${rules.SmallTextLightWeight}
    color: ${colors.gray7};
    text-align: left;
    align-self: flex-start;
    font-weight: 400;

    /* We shouldn't rely on these legacy workarounds. */
    /* Replace when CSS Overflow Module Level 3 is implemented */
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.maxLines};
    -webkit-box-orient: vertical;
    overflow: hidden;
`

export const Actions = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-content: flex-start;
    align-items: flex-start;
    flex-grow: 1;
    flex-wrap: wrap-reverse;

    div:first-child {
        margin-left: auto;
    }
`
export const Status = styled.span`
    ${rules.SmallText}
    color: ${colors.MidPurple};
`

export const PAC_RESTRICTED_TO_ELIGIBLES_MESSAGE_DEFAULT =
    "Thank you for your interest in PAC. Your user account is not flagged as PAC Eligible at this time. Please " +
    "contact your PAC Administrator to update your PAC Eligibility so you are able to view this content."
export const PAC_RESTRICTED_TO_INELIGIBLES_MESSAGE_DEFAULT =
    "Thank you for your interest. Your user account is flagged as PAC Eligible and this page can only be viewed by " +
    "contacts who are not PAC Eligible. Please contact your PAC Administrator if you have a question about your " +
    "PAC Eligibility."
export const PAC_RESTRICTED_USER_NOT_LOGGED_IN_MESSAGE_DEFAULT =
    "Thank you for your interest. You are not logged in and this page can only be viewed by logged in contacts " +
    "who are PAC Eligible. Please log in or contact your PAC Administrator."

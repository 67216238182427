import swal from "sweetalert"
import {
    ENCRYPTED_DATA_PLACEHOLDER,
    RECONCILIATION_DETAIL_BATCH_TABLE,
    RECONCILIATION_DETAIL_TABLE,
} from "shared/pac/constants"
import { getNestedObjectKey } from "shared/imports/sharedHelperFunctions"
import { getPacPaths } from "app/static/frontend/imports/desktopPaths"
import { centsToCurrencyStr, getAllocationFieldErrors, prepareFormValuesForSubmit } from "shared/pac/helperFunctions"
import moment from "moment"
import * as djangioActionCreators from "shared/djangio/action-creators"
import { createTransactionEventActionDict } from "shared/pac/types"
import { destroy } from "redux-form/immutable"
import { SubmissionError } from "redux-form"
import { pushPage } from "global-action-creators"
import { format, parseISO } from "date-fns"
import { fromJS } from "immutable"

const { TransactionEvent, LedgerSettings } = DjangIO.app.ledger.models
const { TransactionType, TransactionTableFilterType, TransactionEventType } = DjangIO.app.ledger.types
const { PacFileFormType } = DjangIO.app.pac.types

/**
 * Permitted characters in an FEC "alphanumeric" field.
 * Same as FEC_ALPHANUMERIC_PERMITTED_REGEX in app/pac/constants.py
 */
export const FEC_ALPHANUMERIC_PERMITTED_REGEX = /[A-Za-z0-9 _\-.!?]/g

/**
 * This function returns an object containing either the querymethod to filter the Transaction
 * table or an empty object for the All Transactions table
 * @name getTransactionTypeQueryMethodForTable
 * @function
 * @param {Number} transactionType - a frontend only Enum to filter the Transaction table
 * @returns {Object}
 */
export const getTransactionTypeQueryMethodForTable = (transactionType) => {
    if (transactionType === TransactionTableFilterType.receipts_only.value) {
        return { receipt_transactions: true }
    } else if (transactionType === TransactionTableFilterType.disbursements_only.value) {
        return { disbursement_transactions: true }
    }
    return {}
}

export const getFilingsReportCounterLabel = (percentage) => {
    if (percentage === 0) {
        return "Not Started"
    } else if (percentage === 1) {
        return "Completed"
    } else {
        return "In Progress"
    }
}

/**
 * Parse a date from the API.
 * @param date either a string from the API formatted like "2022-01-31", a Date object, or falsy
 * @return a Date object, or undefined if input is falsy
 */
export const parseApiDate = (date) => {
    if (!date) return undefined
    if (date instanceof Date) return date // pass a Date straight through
    return parseISO(date)
}

/**
 * Render a date string from the API as a short string for the PAC UI.
 * @param date a string from the API like "2022-01-31", or a Date object
 * @param singleDigit if true, allow month & day to have a single digit, like "2022-1-31" or "2022-12-5"
 * @return {string} Something like "01/31/2022" or blank if input is falsy.
 */
export const formatDateShort = (date, singleDigit) =>
    date ? format(parseApiDate(date), singleDigit ? "M/d/yyyy" : "MM/dd/yyyy") : ""

/**
 * Render a date string from the API as a longer string for the PAC UI.
 * @param date a string from the API like "2022-01-31", or a Date object
 * @return {string} Something like "January 1, 2022" or blank if date is falsy.
 */
export const formatDateLong = (date) => (date ? format(parseApiDate(date), "MMMM d, yyyy") : "")

/**
 * Generate the path string to the transaction or parent transaction for use in resource tables.
 *
 * If the transaction is a memo transaction of a parent JFC transaction, the path points to the parent transaction.
 *
 * @param {Number} id
 * @param {Number} ledgerSettingsId
 * @param {Number} transactionType
 * @param {Number} transactionSpecialType
 * @param {String} referenceTransactionUri
 *
 * @returns {String}
 */
export const getTransactionPath = ({
    id,
    ledgerSettingsId,
    isMemoEntry = undefined,
    referenceTransactionUri = undefined,
}) => {
    if (isMemoEntry && referenceTransactionUri) {
        return getPacPaths({
            ledgerSettingsId,
            transactionId: DjangIO.app.ledger.models.Transaction.idFromResourceUri(referenceTransactionUri),
        })._pacTransactionEdit
    }

    return getPacPaths({
        ledgerSettingsId,
        transactionId: id,
    })._pacTransactionEdit
}

/**
 * Given checkData, generate a string the sums the total of all enabled checks
 *
 * @name calculateCheckTotalAmount
 * @function
 * @param {Object} checkData
 * @returns {String}
 */
export const calculateCheckTotalAmount = (checkData) => {
    const total = checkData
        .filter((check) => check.enabled)
        .map((check) => check.amount)
        .reduce((a, b) => a + b, 0)

    return centsToCurrencyStr(total)
}

/**
 * Given checkData, generate the total number of enabled strings
 *
 * @name calculateNumEnabledChecks
 * @function
 * @param {Object} checkData
 * @returns {String}
 */
export const calculateNumEnabledChecks = (checkData) => checkData.filter((check) => check.enabled).length

/**
 * @param {Object} state
 * @param {String} sliceName
 * @returns {Object} matches and nonmatches of a slice keyed to `originalMatches` and `originalNonmatches`
 */
export const getMatchedFromTable = ({ sliceName, state }) => {
    const table = state.sheet.getIn([sliceName])
    const tableRowData = table && table.get("row_data")
    const originalMatches = new Set()
    if (tableRowData) {
        tableRowData.forEach((row) => {
            const isOriginallyMatched = row.get("reconciliation")
            if (isOriginallyMatched) {
                originalMatches.add(row.get("id"))
            }
        })
    }
    return { originalMatches }
}

/**
 * Helper function used to format check address fields received in loadPrintChecksActionDict.
 * Each check should expect to have address fields provided by an associated supporter,
 * committee, or public organization.
 *
 * @param {Object} check
 * @returns {Object} Object with address fields
 */
export const getCheckAddressFields = (check) => {
    if (!check || !check._extra) {
        return {}
    }
    const supporterAddress = check._extra.supporter_address
    const committeeAddress = check._extra.committee_address
    const organizationAddress = check._extra.organization_address
    const address = supporterAddress || committeeAddress || organizationAddress
    if (!address) {
        return {}
    }
    return {
        address_street_1: address.street1,
        address_street_2: address.street2,
        address_city: address.city,
        address_state: address.state,
        address_zip: address.zip,
    }
}

/**
 * Once the check is ready to send, merge the provided checkData from selectCheckDataForForm and
 * the check modal state data to make a formal request object for download_checks.
 *
 * @param {Object} checkData
 */
export const generateCheckDataForRequest = (immutableFormValues, checkData) =>
    checkData
        .map((check, i) => ({
            id: check.id,
            entity: check.name,
            amount: check.amountString,
            election_name: check.electionName,
            committee: check.committee,
            organization_name: check.organizationName,
            description: check.description || "",
            is_check_enabled: check.enabled,
            address_street_1: immutableFormValues.getIn(["checkData", i, "addressStreet1"]),
            address_street_2: immutableFormValues.getIn(["checkData", i, "addressStreet2"]),
            address_city: immutableFormValues.getIn(["checkData", i, "addressCity"]),
            address_state: immutableFormValues.getIn(["checkData", i, "addressState"]),
            address_zip: immutableFormValues.getIn(["checkData", i, "addressZip"]),
            reference_number: immutableFormValues.getIn(["checkData", i, "referenceNumber"]),
        }))
        .filter((check) => check.is_check_enabled)

/**
 * This function creates an object containing check number and address field data
 * that will be used to initialize the redux form.
 *
 * @param {Object} checkData
 * @param {Object} defaultReferenceNumber - initial reference number for all checks
 */
export const generateCheckInputFields = (checkData, defaultReferenceNumber) => {
    const initializeCheckForm = checkData.map((check, index) => ({
        addressStreet1: check.addressStreet1 || "",
        addressStreet2: check.addressStreet2 || "",
        addressCity: check.addressCity || "",
        addressState: check.addressState || "",
        addressZip: check.addressZip || "",
        referenceNumber: String(defaultReferenceNumber + index),
    }))

    return fromJS(initializeCheckForm)
}

/**
 * Route for a PacFile detail view.
 *
 * @param {Number} ledgerSettingsId - id of the current ledger settings
 * @param {Number} filingsId - the id of the pacfile
 * @param {Number} formType - The type of Filing - (form 3x, form 1)
 * @param {Number} jurisdictionId - id of the jurisdiction
 */
export const getFilingDetailPath = (ledgerSettingsId, filingsId, formType, jurisdictionId) => {
    if (jurisdictionId) {
        const jurisdiction = DjangIO.app.ledger.types.ReportingJurisdictionType.by_value(jurisdictionId)

        if (jurisdiction) {
            if (jurisdiction.value !== DjangIO.app.ledger.types.ReportingJurisdictionType.federal.value) {
                // state-specific filings
                // Only return a jurisdiction specific path if we have a jurisdiction, otherwise return the generic path
                return getPacPaths({ ledgerSettingsId, filingsId, jurisdictionKey: jurisdiction.key })
                    ._pacStateFilingsReview
            }
        }
    }

    // federal form3x
    if (formType === PacFileFormType.form_f3x.value) {
        return getPacPaths({ ledgerSettingsId, filingsId })._pacFilingsDetail
    }

    // federal form1
    if (formType === PacFileFormType.form_f1.value) {
        return getPacPaths({ ledgerSettingsId, filingsId })._pacFilingsPrepareFormOneExisting
    }

    // default to federal if formType is undefined or doesn't explicitly === PacFileFormType.form_f3x
    return getPacPaths({ ledgerSettingsId, filingsId })._pacFilingsDetail
}

/**
 * Route for a PacFile edit view.
 *
 * @param {Number} ledgerSettingsId - id of the current ledger settings
 * @param {Number} filingsId - the id of the pacfile
 * @param {Number} formType - The type of Filing - (form 3x, form 1)
 * @param {Number} jurisdictionId - id of the jurisdiction
 */
export const getFilingEditPath = (ledgerSettingsId, filingsId, formType, jurisdictionId) => {
    // state-specific filings have a separate edit screen
    if (jurisdictionId && jurisdictionId !== DjangIO.app.ledger.types.ReportingJurisdictionType.federal.value) {
        const jurisdiction = DjangIO.app.ledger.types.ReportingJurisdictionType.by_value(jurisdictionId)
        return getPacPaths({ ledgerSettingsId, filingsId, jurisdictionKey: jurisdiction.key })._pacStateFilingsEdit
    }

    // for federal filings, the detail and edit screens are the same screen
    return getFilingDetailPath(ledgerSettingsId, filingsId, formType, jurisdictionId)
}

/**
 * Used to populate existing matched transactions for in-progress saved reconciliations.
 *
 * @param {Object} state
 * @returns {Set} a Set of transaction UUIDs that are matched from the backend
 */
export const getOriginalTransactionAndBatchMatchesFromFullState = (state) => {
    const { originalMatches: originalTransactionMatches } = getMatchedFromTable({
        sliceName: RECONCILIATION_DETAIL_TABLE,
        state,
    })
    const { originalMatches: originalBatchMatches } = getMatchedFromTable({
        sliceName: RECONCILIATION_DETAIL_BATCH_TABLE,
        state,
    })

    return {
        originalTransactionMatches,
        originalBatchMatches,
    }
}

/**
 * Validates a FEC committee ID. Returns undefined if valid, otherwise a message explaining the correct format.
 * Empty string is considered valid.
 *
 * @param value {string} The FEC Committee ID to test
 * @return {undefined|string} undefined if valid, otherwise a string explaining the format
 */
export const validateFecCommitteeId = (value) =>
    !value || /^(C|Q)\d{8}$/.test(value) ? undefined : "Committee ID must be formatted like C12345678"

/**
 * Get a string explaining the coverage period of the filing
 *
 * @param {string|null} startDate
 * @param {string|null} endDate
 * @return {string} a string explaining the coverage period of the filing
 */
export const getCoveragePeriodStrForFilingTable = (startDate, endDate) => {
    let formattedStartDate = startDate
    let formattedEndDate = endDate
    if (startDate) {
        formattedStartDate = moment(startDate, "YYYY-MM-DD").format("MM/DD/YYYY")
    }
    if (endDate) {
        formattedEndDate = moment(endDate, "YYYY-MM-DD").format("MM/DD/YYYY")
    }
    return startDate && endDate ? `${formattedStartDate}  to  ${formattedEndDate}` : ``
}

/**
 * Parse an errorMessage from the backend to choose what text to show in a swal
 *
 * @param {Object} errorMessage message object from the backend
 * @param {Object} data error object from the backend
 * @returns {String}
 */
export const generateErrorText = (errorMessage, data) => {
    let message = errorMessage
    if (Array.isArray(errorMessage)) {
        // Some errors are returned as lists of errors.
        message = errorMessage[0]
    }
    return data.explanatory_text || message
}

/**
 * This will be passed to the Redux form's 'onSubmit' function,
 * which is called when the user submits a valid form.
 * @param {Object} immutableFormValues - An immutable map of the Redux form values
 * @param {Function} dispatch - function to dispatch actions, not used in this action creator
 * @param {Object} props - component props
 * @returns {Undefined | Promise}
 */
export const submitTransaction = async (immutableFormValues, dispatch, props) => {
    const {
        availableBankAccounts,
        creditAccount,
        destroy,
        debitAccount,
        isCreatingRefund,
        isEditing,
        isEditingRefund,
        attributionData,
        ledgerAccounts,
        ledgerSettings,
        // TODO consider moving JFC allocations to Allocation step, and moving that up
        shouldShowJfcAllocationField,
        transactionType,
        transactionSpecialType,
        params: { transactionId },
    } = props

    const formValues = immutableFormValues.toJS()

    let eventType
    if (shouldShowJfcAllocationField) {
        eventType = isEditing
            ? TransactionEventType.modify_jfc_transaction.value
            : TransactionEventType.create_jfc_transaction.value
    } else {
        eventType =
            isEditing || isEditingRefund
                ? TransactionEventType.modify_transaction.value
                : TransactionEventType.create_transaction.value
    }

    const data = prepareFormValuesForSubmit({
        availableBankAccounts,
        creditAccount,
        debitAccount,
        formValues,
        isCreatingRefund,
        isEditing,
        attributionData,
        ledgerAccounts,
        refundTransactionId: transactionId,
        transactionId,
        transactionType,
        transactionSpecialType,
    })

    const response = dispatch(
        djangioActionCreators.sendAction(TransactionEvent, createTransactionEventActionDict, {
            action: "create_transaction_event",
            kwargs: {
                data,
                event_type: eventType,
                ledger_settings: LedgerSettings.resourceUriFromId(ledgerSettings),
                transaction: isEditing || isEditingRefund ? formValues.id : undefined,
            },
            method: "post",
            payload: {
                ledger_settings: LedgerSettings.resourceUriFromId(ledgerSettings),
                event_type: eventType,
            },
        }),
    )

    try {
        await response
    } catch (error) {
        console.warn(error)

        const responseData = getNestedObjectKey(error, ["response", "data"])

        // Include error labels by matching the response to the allocation rows.
        const allocationErrors = responseData.allocation_suberrors
        if (allocationErrors) {
            const allocationFieldErrors = getAllocationFieldErrors(
                allocationErrors,
                immutableFormValues.get("allocation"),
            )
            throw new SubmissionError({ ...error, ...allocationFieldErrors })
        }

        throw error
    }

    destroy()
}

/**
 * This will be passed to the Redux form's 'onSubmitSuccess' function,
 * which is called when the form is valid and 'onSubmit' was successful.
 * Pop to previous page (or registration page list view if no previous page)
 * And destroy form
 * @param {Object} result - The value from 'onSubmit'
 * @param {Function} dispatch - function to dispatch actions
 * @returns {undefined}
 */
export const submitTransactionSuccess = (result, dispatch, props) => {
    dispatch(destroy(props.form)) // Reset form.
    if (props.isContinuingToMakeNewTransaction) {
        window.scrollTo(0, 0) // Scroll to top of page so user is primed to know it is a fresh form (without having to refresh).
        swal({
            icon: "success",
            title: "Success!",
            text: "Transaction has been created. Ready to begin another.",
        })
    } else {
        dispatch(pushPage(props.pushAfterSubmitPath))
    }
}

/**
 * This will be passed to the Redux form's 'onSubmitFail' function,
 * which is called if the form is invalid or 'onSubmit' was unsuccessful.
 * If the form is invalid, swal an alert listing the invalid fields
 * Else, swal a generic error message
 * @param {Object} result - Value from 'onSubmit'
 * @param {Function} dispatch - function to dispatch actions
 * @param {Object?} error - error object(?)
 * @param {Object} props - component props
 * @returns {undefined}
 */
export const submitTransactionFail = (result, dispatch, error, props) => {
    let errorText
    let title = "Error"
    const genericErrorText = "There was a problem saving the Transaction. Please contact us at support@quorum.us"

    // Handle Client side validation first
    if (props.invalid) {
        throw new SubmissionError(props.syncErrors)
    }

    // In submitTransaction, it catches the error and re-throws it in a different format when there are allocations
    const arrayOfKeys = error instanceof SubmissionError ? ["errors", "response", "data"] : ["response", "data"]
    // All server side validation
    const data = getNestedObjectKey(error, arrayOfKeys)
    if (!data) {
        swal({
            icon: "error",
            title,
            text: genericErrorText,
        })
        return
    }
    const errorMessage = data.error_message

    if (errorMessage) {
        errorText = generateErrorText(errorMessage, data)
        title = data.label || "Error"
    }
    // Check to see if there are errors sent via the UserValidation system
    else if (data.message) {
        errorText = data.message
    } else {
        errorText = genericErrorText
    }
    Raven.captureException(error)
    swal({
        icon: "error",
        title,
        text: errorText,
    })

    if (data.fieldErrors) {
        throw new SubmissionError(data.fieldErrors)
    }
}

import i18n from "i18n"
import { createSelector, createStructuredSelector } from "reselect"

import * as frameworkSelectors from "QuorumGrassroots/framework/selectors"

export const selectEventList = createSelector(frameworkSelectors.selectEventList, (eventList) =>
    eventList.filter((event) => event.publicly_accessible && event.should_show_in_event_list),
)

// props.content may be specified for EventList widgets on Grassroots Pages
// props.content is the default 'no event' message. Display if message is specified
// Otherwise, check if user is logged in and display logged in no event message
// If not logged in, display other no event message
export const selectEventNoneText = createSelector(
    (_, props) => props.content,
    frameworkSelectors.selectUserLoggedIn,
    (defaultMessage, userLoggedIn) => {
        const eventNoneText =
            defaultMessage ||
            (userLoggedIn
                ? `${i18n.t("event.not_found.unavailable")}, ${i18n.t("event.not_found.loggedin_message")}`
                : `${i18n.t("event.not_found.unavailable")}, ${i18n.t("event.not_found.loggedout_message")}`)

        return eventNoneText
    },
)

export const selectEventListTitle = createSelector(
    (_, props) => props.title,
    (_, props) => props.isWidgetPage,
    (title, isWidgetPage) =>
        // If 'title' is defined from widget, display title
        // Otherwise, if this is a widget page (Events List widget page), display "Events"
        // Else, do not display a title
        title || (isWidgetPage ? i18n.t("eventList.title.label") : ""),
)

export const eventListSelectors = createStructuredSelector({
    eventList: selectEventList,
    eventNoneText: selectEventNoneText,
    loading: frameworkSelectors.selectEventListLoading,
    title: selectEventListTitle,
})

import React from "react"
import { GridToggle } from "components/GridToggle"
import { PropTypes } from "prop-types"
import classNames from "classnames"
import ReduxFormFieldWrapper from "forms/components/ReduxFormFieldWrapper"

import "app/static/stylus/FormStyles.styl"
import "frontend/forms/stylus/GridToggleField.styl"

export const GridToggleField = (props) => {
    const {
        input: { value },
        meta: { error, warning, touched } = {},
        accessibilityId,
        allowNull,
        banner,
        choices,
        className,
        dataCy,
        displayErrorWithoutTouch,
        isIndentedToggle,
        label,
        labelStyle,
        multi,
        numbered,
        pillWidth,
        selectedStyle,
        shouldRenderNewComponents,
        style,
        tooltipText,
        tooltipPlacement,
        unselectedStyle,
        showAsterisk,
        organizationDesign,
    } = props
    // provide a way to pass a custom on change method, or default to redux-form's function
    const onChange = props.customOnChange || props.input.onChange
    const handleChange = (val) => {
        if (multi || !allowNull) {
            onChange(val)
        } else {
            // Either update value or set to null
            onChange(value === val ? null : val)
        }
    }

    return (
        <ReduxFormFieldWrapper
            additionalClassNames={className}
            className="gridtoggle"
            dataCy={dataCy}
            displayErrorWithoutTouch={displayErrorWithoutTouch}
            error={error}
            label={label}
            labelStyle={labelStyle}
            style={style}
            tooltipText={tooltipText}
            touched={touched}
            warning={warning}
            accessibilityId={accessibilityId}
            banner={banner}
            showAsterisk={showAsterisk}
        >
            <GridToggle
                isIndentedToggle={isIndentedToggle}
                gridClassName={classNames({
                    "gridtoggle-error-outline": error && (displayErrorWithoutTouch || touched),
                    "gridtoggle-warning-outline": warning && (displayErrorWithoutTouch || touched),
                })}
                options={choices}
                onChange={handleChange}
                value={value}
                multi={multi}
                numbered={numbered}
                bsColWidth={pillWidth}
                selectedStyle={selectedStyle}
                unselectedStyle={unselectedStyle}
                accessibilityId={accessibilityId}
                shouldRenderNewToggles={shouldRenderNewComponents}
                tooltipPlacement={tooltipPlacement}
                organizationDesign={organizationDesign}
            />
        </ReduxFormFieldWrapper>
    )
}

GridToggleField.defaultProps = {
    accessibilityId: "",
    allowNull: true,
    className: "",
    displayErrorWithoutTouch: true,
    label: "",
    numbered: false,
    pillWidth: 4,
    style: {},
    tooltipText: "",
}

GridToggleField.propTypes = {
    accessibilityId: PropTypes.string,
    allowNull: PropTypes.bool,
    choices: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            tip: PropTypes.string,
            icon: PropTypes.string,
        }),
    ),
    className: PropTypes.string,
    dataCy: PropTypes.string,
    displayErrorWithoutTouch: PropTypes.bool,
    input: PropTypes.shape({
        value: PropTypes.any,
        onChange: PropTypes.func.isRequired,
    }),
    isIndentedToggle: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.shape({
        error: PropTypes.string,
        warning: PropTypes.string,
        touched: PropTypes.bool.isRequired,
    }),
    labelStyle: PropTypes.object,
    multi: PropTypes.bool,
    numbered: PropTypes.bool,
    pillWidth: PropTypes.number,
    selectedStyle: PropTypes.object,
    shouldRenderNewComponents: PropTypes.bool,
    style: PropTypes.object,
    tooltipText: PropTypes.string,
    unselectedStyle: PropTypes.object,
    organizationDesign: PropTypes.object,
}

export default GridToggleField

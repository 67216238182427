import i18n from "i18n"

import { createSelector, createStructuredSelector } from "reselect"

import { idFromResourceUri } from "shared/imports/sharedHelperFunctions"
import { simpleReplacePlaceholders, getPathObject, getThanksSocialButtonProps } from "QuorumGrassroots/helperFunctions"

import {
    createPageTitleSelector,
    createRegistrationPageSubmitButtonTextSelector,
    selectActionCenterSettings,
    selectCampaignListLoading,
    selectCampaignNavigationBarIsLogoOnly,
    selectCurrentTierIcon,
    selectGamificationAchievementIcon,
    selectIndexRoute,
    selectIsCampaignPage,
    selectNavigationBarIsTransparent,
    selectOneClickRegistrationCampaignId,
    selectOrganization,
    selectOrganizationDesign,
    selectPreviousPointsEarned,
    selectUserdata,
    selectUserLoggedIn,
} from "QuorumGrassroots/framework/selectors"
import { selectAugmentedWidgetContent, selectWidgetSubmitted } from "QuorumGrassroots/widgets/selectors"

const { CampaignType, CampaignDescriptionAlignment } = DjangIO.app.grassroots.campaign.types

export const selectCampaignRegistrationPageIds = createSelector(selectAugmentedWidgetContent, (campaign) => {
    const regPageUri = campaign.registration_page

    return regPageUri ? [idFromResourceUri(regPageUri)] : []
})

export const selectCampaignRegistrationSubmitButtonText = createSelector(
    (_, props) => props.submitting,
    createRegistrationPageSubmitButtonTextSelector(selectCampaignRegistrationPageIds),
    selectIsCampaignPage,
    (submitting, submitButtonText, isCampaignPage) => {
        if (submitButtonText) {
            return submitButtonText
        } else if (submitting) {
            return i18n.t("form.submitting")
        } else if (isCampaignPage) {
            return i18n.t("navbar.sign_up")
        } else {
            return i18n.t("form.submit_form")
        }
    },
)

export const selectHasAlreadyParticipated = createSelector(
    [selectUserdata, selectAugmentedWidgetContent],
    (userdata, content) => {
        const participatedCampaigns = userdata.participated_campaigns || []
        const campaignId = content.id

        return participatedCampaigns.includes(campaignId)
    },
)

export const selectCampaignId = createSelector(selectAugmentedWidgetContent, (campaign) => campaign.id)

export const selectCampaignTargetsSupporter = createSelector(selectAugmentedWidgetContent, (campaign) => {
    if (!campaign || !campaign.id) {
        return false
    } else {
        return campaign._extra.can_participate
    }
})

/**
 * Given campaign object, inspect if campaign type is whitelisted for one click registration
 * and if campaign is enabled for one click registration
 *
 * @returns {Boolean}
 */
export const selectCampaignIsOneClickRegistrationEnabled = createSelector(selectAugmentedWidgetContent, (campaign) => {
    const campaignType = CampaignType.by_value(campaign.campaign_type)
    return Boolean(
        campaignType && campaignType.allow_one_click_registration && campaign.is_one_click_registration_enabled,
    )
})

/**
 * Will return true if campaign is enabled for one click registration and user is not logged in
 *
 * @returns {Boolean}
 */
export const selectShouldDisplayCampaignMessagePreview = createSelector(
    selectCampaignIsOneClickRegistrationEnabled,
    selectUserLoggedIn,
    (isOneClickRegistrationEnabled, userLoggedIn) => Boolean(isOneClickRegistrationEnabled && !userLoggedIn),
)

/**
 * Will return true if campaign and action center is enabled for one click registration
 *
 * @returns {Boolean}
 */
export const selectShouldSubmitOneClickRegistration = createSelector(
    selectCampaignIsOneClickRegistrationEnabled,
    selectCampaignId,
    selectOneClickRegistrationCampaignId,
    (campaignEnabled, currentCampaignId, oneClickRegistrationCampaignId) =>
        Boolean(campaignEnabled && currentCampaignId === oneClickRegistrationCampaignId),
)

export const selectCanParticipate = createSelector(
    [selectAugmentedWidgetContent, selectHasAlreadyParticipated, selectCampaignTargetsSupporter],
    (content, hasAlreadyParticipated, campaignTargetsSupporter) => {
        // if it's a draft, make sure that it gets shown, regardless of whether or not
        // you can participate.
        if (content.status === DjangIO.app.grassroots.campaign.types.CampaignStatus.draft.value) {
            return true
        }

        // make sure that the campaign is within the list that the supporter
        // can access! A supporter might click a link and be directed to a campaign
        // they're not eligible to participate in!
        return campaignTargetsSupporter && (!hasAlreadyParticipated || content.multiple_actions_allowed)
    },
)

export const selectCampaignPassThroughProps = createSelector(
    [
        (_, props) => props,
        selectHasAlreadyParticipated,
        selectCanParticipate,
        selectCampaignListLoading,
        selectCampaignId,
        selectCampaignTargetsSupporter,
        selectCampaignIsOneClickRegistrationEnabled,
        selectShouldDisplayCampaignMessagePreview,
        selectShouldSubmitOneClickRegistration,
    ],
    (
        props,
        hasAlreadyParticipated,
        canParticipate,
        campaignListLoading,
        campaignId,
        campaignTargetsSupporter,
        campaignIsOneClickRegistrationEnabled,
        shouldDisplayCampaignMessagePreview,
        shouldSubmitOneClickRegistration,
    ) => ({
        uniqueWidgetId: props.uniqueWidgetId,
        isEmbedded: props.isEmbedded,
        campaign: props.content,
        campaignId,
        campaignListLoading,
        hasLoaded: props.hasLoaded,
        hasAlreadyParticipated,
        isLoadingContent: props.isLoadingContent,
        canParticipate,
        campaignTargetsSupporter,
        campaignIsOneClickRegistrationEnabled,
        shouldDisplayCampaignMessagePreview,
        shouldSubmitOneClickRegistration,
    }),
)

export const selectThanksSocialLinks = createSelector(
    [selectAugmentedWidgetContent, selectActionCenterSettings, selectOrganization, selectOrganizationDesign],
    (campaign, acs, org, orgDesign) => {
        return getThanksSocialButtonProps(campaign, acs, org, orgDesign)
    },
)

export const selectCampaignThanksProps = createSelector(
    [
        selectAugmentedWidgetContent,
        selectThanksSocialLinks,
        selectIndexRoute,
        selectActionCenterSettings,
        selectUserdata,
        selectPreviousPointsEarned,
        selectGamificationAchievementIcon,
        selectCurrentTierIcon,
        selectIsCampaignPage,
    ],
    (
        campaign,
        socialLinks,
        indexRoute,
        actionCenterSettings,
        userdata,
        pointsEarned,
        achievementIcon,
        currentTierIcon,
        isCampaignPage,
    ) => ({
        text:
            simpleReplacePlaceholders(campaign.thank_you_message, userdata) ||
            simpleReplacePlaceholders(actionCenterSettings.default_thank_you_message, userdata) ||
            i18n.t("campaign.thanks.text"),
        pointsEarned,
        achievementIcon,
        currentTierIcon,
        redirectDetails: campaign.success_redirection_url && getPathObject(campaign.success_redirection_url),
        socialDetails: campaign.allow_sharing && socialLinks,
        buttonDetails: [
            !campaign.block_action_center && {
                label: i18n.t("campaign.thanks.back.homepage"),
                link: indexRoute,
                type: DjangIO.app.grassroots.types.GrassrootsNavigationBarButtonType.single_page.value,
                dataCy: "homepage",
            },
        ].filter((button) => Boolean(button)),
        isCampaignPage,
    }),
)

export const selectCampaignWidgetHeader = createSelector(
    selectAugmentedWidgetContent,
    (content) => content.custom_call_to_action_text || i18n.t("campaign.action_text"),
)

export const selectShouldDisplayProgressBar = createSelector(selectAugmentedWidgetContent, (content) => {
    const { log_interaction, log_relationship } = DjangIO.app.grassroots.campaign.types.CampaignType

    // Check if the campaign is flagged to show number of actions
    // Never show number of actions for a log interaction/relationship campaign
    return content.show_num_actions && ![log_interaction.value, log_relationship.value].includes(content.campaign_type)
})

export const selectShouldTopAlignDescription = createSelector(
    selectAugmentedWidgetContent,
    (content) => content.description_alignment_type === CampaignDescriptionAlignment.top_left.value,
)

export const selectWidgetNumberActions = createSelector(
    selectAugmentedWidgetContent,
    (content) => content.number_actions_count,
)

export const selectWidgetUnreachableGoal = createSelector(selectWidgetNumberActions, (numActions) => {
    let start

    if (!numActions) {
        return 10
    } else if (numActions < 50) {
        return numActions + (10 - (numActions % 10))
    } else {
        start = 100
    }

    if (numActions >= 100000) {
        start = 100000
    } else if (numActions >= 10000) {
        start = 10000
    } else if (numActions >= 5000) {
        start = 5000
    } else if (numActions >= 1000) {
        start = 1000
    }

    if (numActions < start) {
        return start
    }

    const nextCutOff = numActions + (start - (numActions % start))

    if (numActions === nextCutOff) {
        return nextCutOff + start
    } else {
        return nextCutOff
    }
})

export const selectWidgetNumberActionsNeeded = createSelector(
    selectWidgetUnreachableGoal,
    selectWidgetNumberActions,
    (goal, numActions) => goal - numActions,
)

export const selectWidgetCurrentPercent = createSelector(
    selectWidgetNumberActions,
    selectWidgetUnreachableGoal,
    (numActions, goal) => Math.round((numActions / goal) * 100),
)

export const selectWidgetPercentLeft = createSelector(selectWidgetCurrentPercent, (percent) => 100 - percent)

export const selectWidgetWidthSize = createSelector(selectAugmentedWidgetContent, (content) =>
    content.page_layout_type
        ? DjangIO.app.grassroots.campaign.types.CampaignPageLayout.by_value(content.page_layout_type).column_width
        : 12,
)

export const selectCampaignTypeEnum = createSelector(selectAugmentedWidgetContent, (content) =>
    CampaignType.by_value(content.campaign_type),
)

export const selectShouldRemoveAddressRequirement = createSelector(
    selectAugmentedWidgetContent,
    selectCampaignTypeEnum,
    (content, campaignTypeEnum) => {
        const extra = content?._extra
        const hasCustomEmailTargets = extra?.message_groups?.every((group) => group.custom_email_targets?.length > 0)

        return Boolean(
            campaignTypeEnum && campaignTypeEnum.custom_target_optional_address_field && hasCustomEmailTargets,
        )
    },
)

export const selectRequiredFields = createSelector(
    selectCampaignTypeEnum,
    selectShouldRemoveAddressRequirement,
    (campaignTypeEnum, shouldRemoveAddressRequirement) => {
        if (campaignTypeEnum) {
            const requiredFields = campaignTypeEnum.required_registration_fields.filter(
                (fieldValue) =>
                    // Include all registration fields if shouldRemoveAddressRequirement=False
                    // If shouldRemoveAddressRequirement=true, filter out address field
                    !shouldRemoveAddressRequirement ||
                    fieldValue !== DjangIO.app.grassroots.types.GrassrootsRegistrationField.address.value,
            )

            return requiredFields
        } else {
            return []
        }
    },
)

export const selectCampaignDescriptionColumnWidth = createSelector(selectAugmentedWidgetContent, (content) => {
    if (content.page_layout_type) {
        // if layout is two rows and we have background image, we want width to be 6
        // this is so they can be displayed in a row
        if (
            content.page_layout_type === DjangIO.app.grassroots.campaign.types.CampaignPageLayout.two_rows.value &&
            content.background_image
        ) {
            return 6
        }
        return DjangIO.app.grassroots.campaign.types.CampaignPageLayout.by_value(content.page_layout_type).column_width
    }
    return 12
})

export const selectShouldDisplayRegulationsFooter = createSelector(
    selectAugmentedWidgetContent,
    (content) => content.campaign_type === CampaignType.comment_on_regulation.value && Boolean(content.regulation_id),
)

export const selectIsStandalone = createSelector(
    selectNavigationBarIsTransparent,
    selectCampaignNavigationBarIsLogoOnly,
    (isTransparent, isLogoOnly) => Boolean(isTransparent && isLogoOnly),
)

export const selectCampaignName = createSelector(selectAugmentedWidgetContent, (content) => content.name)

export const campaignWidgetConnection = createStructuredSelector({
    registrationPageIds: selectCampaignRegistrationPageIds,
    campaignWidgetHeader: selectCampaignWidgetHeader,
    shouldDisplayProgressBar: selectShouldDisplayProgressBar,
    shouldTopAlignDescription: selectShouldTopAlignDescription,
    shouldDisplayRegulationsFooter: selectShouldDisplayRegulationsFooter,
    numberActionsNeeded: selectWidgetNumberActionsNeeded,
    currentPercent: selectWidgetCurrentPercent,
    percentLeft: selectWidgetPercentLeft,
    widgetWidthSize: selectWidgetWidthSize,
    campaignPassThroughProps: selectCampaignPassThroughProps,
    userdata: selectUserdata,
    canParticipate: selectCanParticipate,
    shouldThank: selectWidgetSubmitted,
    requiredFields: selectRequiredFields,
    campaignDescriptionColumnWidth: selectCampaignDescriptionColumnWidth,
    pageTitle: createPageTitleSelector(selectCampaignName),
    isCampaignPage: selectIsCampaignPage,
    isStandalone: selectIsStandalone,
    registrationSubmitButtonText: selectCampaignRegistrationSubmitButtonText,
})

import { createSelector, createStructuredSelector } from "reselect"
import * as loginRegistrationSelectors from "QuorumGrassroots/widgets/LoginRegistration/selectors"
import { selectAugmentedWidgetContent, selectIsWidgetContentLoading } from "QuorumGrassroots/widgets/selectors"

export const selectRegionList = createSelector(selectAugmentedWidgetContent, (regionList) =>
    regionList && regionList.map ? regionList : [],
)

export const regionsListSelector = createStructuredSelector({
    registrationPageIds: loginRegistrationSelectors.selectDefaultRegistrationPageIds,
    regionList: selectRegionList,
    loading: selectIsWidgetContentLoading,
})

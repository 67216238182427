import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const MinorContainer = styled.div`
    // if workflow strips are put together in a container, add a border between them
    & + & {
        border-top: ${rules.Line} ${colors.DarkerPorcelain};
    }
`

export const MajorContainer = styled.div`
    // if workflow strips are put together in a container, add a border between them
    & + & {
        border-top: ${rules.Line} ${colors.DarkerPorcelain};
    }
    ${MinorContainer} + & {
        border-top: ${rules.Line} ${colors.QuorumGrey4};
    }
`

// must come after declarations of Checkbox / ClickableWrapper in order to reference them
export const MainContainer = styled.div`
    // prevent the text from wrapping and hide its overflow
    white-space: nowrap;
    overflow: hidden;
    position: relative;

    user-select: none;
    cursor: ${({onClick}) => onClick ? "pointer" : "unset"};

    flex-shrink: 0;

    display: flex;
    flex-direction: row;

    box-sizing: content-box;
    height: ${({subtext}) => subtext ? "54px" : "35px"};

    color: ${({checked}) => checked ? colors.gray9 : colors.gray7};
    background-color: ${({checked}) => checked ? colors.violet1 : colors.White};

    &:hover {
        background-color: ${({checked}) => checked ? colors.violet1 : colors.gray1};
    }

    & + & {
        border-top: 1px solid ${colors.DarkerPorcelain};
    }
`

// contained by the main container
export const RowContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    padding-top: 3px;
    padding-left: ${({indent}) => 22.5 * (indent || 0)}px;
    width: 100%;
`

// contained by the row container
export const Row = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    ${({hoverAction}) => hoverAction ? "line-height: 24px;" : ""}
`

// contained by a row in the row container
export const Checkbox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    box-sizing: border-box;
    height: 14px;
    width: 14px;

    margin: 6px;
    padding-right: 13px;

    font-size: 11px;

    &:hover {
        cursor: pointer;
    }
    border-color: ${({checked}) => checked ? colors.violet5 : colors.gray5};
    background: ${({ checked }) => checked ? colors.violet5 : "white"};
    border-radius: 2px;
`

// contained by a row
export const TextSpan = styled.div`
    align-items: center;
    margin-right:auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;

    // do not set color or weight so that it cascades from the main container
    // when it sets the color on hover

    ${({textType}) => {
        switch(textType) {
            case "major":
                return `
                    align-self: center;
                    ${rules.MediumText}
                    margin-top: 1px;
                `
            case "minor":
                return `
                    ${rules.SmallText}
                    margin-top: 1px;
                    `
            case "detail":
                return `
                    // don't care about main container hover state in this case
                    // directly set the text color
                    color: ${colors.QuorumGrey4};
                    ${rules.SmallTextRegularWeight}

                    // special spacing to place the detail text correctly
                    line-height: 0px;
                    margin-left: 28.5px;
                    margin-top: 6px;
                `
            case "clickable":
                return `
                    ${rules.SmallTextRegularWeight}
                `
            default:
                return `
                    align-self: center;
                    ${rules.MediumTextRegularWeight}
                `
        }
    }}
`

// contained by a row in the row container
export const Icon = styled.div`
    box-sizing: border-box;

    // take the width and height of an icon, even if there isn't one present
    min-width: ${rules.FontAwesomeIconWidth};
    min-height: ${rules.FontAwesomeIconHeight};

    margin-top: 2px;
    margin-right: 3px;
    margin-left: 1.5px;
    font-size: 16px;

    color: ${({isChecked}) => {
        if (isChecked) {
            return colors.gray9
        } else {
            return colors.gray7
        }
    }};

    ${({iconName}) => iconName === "thumbtack" || iconName === "level-up"
        ? // unfortunate special case for the issue adder to match the mocks
            `
                transform: translateY(-2px);
                svg {
                    height: 15px;
                }
            `
        : ""
    }
`

export const ActiveAction = styled.div`
    position: absolute;
    right: 8px;
    ${MainContainer}:hover & {
        visibility: hidden;
    }
`

import { checkHasPermission, checkHasUserPermission, checkHasRegion } from "shared/imports/permissionFunctions"
import isEqual from "lodash.isequal"

import isFeatureEnabled from "shared/featureflags/helperFunctions"

export function region_is_supported(item) {
    return item.supported_regions
        ? // If there is at least one intersection between the user's current regions,
          // and the enum item's supported_regions, then the region is supported.
          checkHasRegion(item.supported_regions)
        : // If the supported_regions is not defined on the item, then it should
          // not be filtered by region.
          true
}

export function satisfies_permissions(item) {
    return item.permission_enum
        ? checkHasPermission(item.permission_enum.map((value) => DjangIO.app.models.PermissionType.by_value(value)))
        : true
}

export function satisfies_user_permissions(item) {
    return item.permission_user ? checkHasUserPermission(item.permission_user) : true
}

export function should_show_in_frontend(item) {
    return item.hasOwnProperty("show_in_enumeration") ? item.show_in_enumeration : true
}

export function satisfies_featureflag(item) {
    return item.feature_flags && item.feature_flags.length
        ? // If there is at least one intersection between the user's enabled feature flags
          // and the enum's feature_flags attribute, then the item is supported and should be displayed.
          item.feature_flags.filter((featureFlag) => isFeatureEnabled(featureFlag)).length
        : // If the feature_flags attribute is not defined on the enum,
          // then we should just display the item
          true
}

export function hide_featureflags(item) {
    return item.hide_feature_flags && item.hide_feature_flags.length
        ? // If there is at least one intersection between the user's enabled feature flags
          // and the enum's hide_feature_flags attribute, then the item is supported and should be hidden.
          !item.hide_feature_flags.filter((featureFlag) => isFeatureEnabled(featureFlag)).length
        : // If the hide_feature_flags attribute is not defined on the enum,
          // then we should just display the item
          true
}

/**
 * regionalizeEnumItem
 *
 * This function splats the "region_specific_dict" (a dictionary of specific
 * regionalized values) directly into the enum item itself based on the
 * user's current region. This is convenient to keep indexing into enums
 * consistent throughout the codebase.
 *
 * @param  {Object} item - a JSON serialized enum item of shape:
 * {
 *     key: "head_of_government",
 *     label: "Head of Government",
 *     region_specific_dict: {
 *         australia: { title: "Prime Minister" },
 *         brazil: { title: "President"},
 *     }
 * }
 * @return {Object} item - the same item, annotated with its correct
 * international value. If the user's current region is Brazil, we'd expect
 * the output to be:
 * {
 *     key: "head_of_government",
 *     label: "Head of Government",
 * --> title: "President",
 *     region_specific_dict: {
 *         australia: { title: "Prime Minister" },
 * -->     brazil: { title: "Presidente"},
 *     }
 * }
 */
export const regionalizeEnumItem = (item) => {
    try {
        let regionKey
        if (Userdata.current_regions_keys.length === 1) {
            // If we have a single value for our current region (ie we are not
            // in a custom region) we splat its values directly into the enum object
            // for convenience and predictable behavior keyed on the name of the user's
            // current region.
            ;[regionKey] = Userdata.current_regions_keys
        } else if (Userdata.has_international_region) {
            /* If the user is in an international custom region with multiple regions,
               do some things in order of specific -> most general */

            /* 1) First, there's the possibility that all of the
            region-specific values are the exact same non-default value.
            For example, the user could be in a custom region that has all EU countries, and
            the forwarded_email enum could have a region_specific_dict that looks like this:
            region_specific_dict: {
                eu: {label: "forwards@quorumeu.com"}
                france: {label: "forwards@quorumeu.com"}
                germany: {label: "forwards@quorumeu.com"}
                italy: {label: "forwards@quorumeu.com"}
                spain: {label: "forwards@quorumeu.com"}
                united_kingdom: {label: "forwards@quorumeu.com"}
            }
            In this case, the natural solution would be to return forwards@quorum.eu instead of the default label,
            forwards@quorum.us. This is a more easily abstractable solution than checking for specific conditions
            and patterns in the custom regions, i.e. if all the regions are in Europe and need a European label.
            We also avoid declaring regionKey as a specific key because we can destructure the array and
            get the first value since all of the values are the same.
            */
            const region_names = Userdata.current_regions_keys.map((region) => item.region_specific_dict[region])
            if (region_names[0] !== undefined && region_names.map((name) => isEqual(name, region_names[0]))) {
                ;[regionKey] = Userdata.current_regions_keys
            } else {
                /* 2) Try to access the "international" key if one exists. As this
            block of code is in a try statement, if there is no international key
            the error will be caught and 3) the item will be returned as is, with the default label.
            TODO: Ideally this constant would be pulled from DjangIO, but this
            function is called as DjangIO is being instantiated. It's the one
            place in the frontend codebase where we can't access DjangIO's constants. */
                regionKey = "international"
            }
        }
        return { ...item, ...(item.region_specific_dict[regionKey] || {}) }
    } catch (e) {
        return item
    }
}

export default class Enum {
    constructor(items) {
        const processedItems = Object.entries(items).reduce(
            (acc, [key, value]) => ({
                ...acc,
                [key]:
                    // if the value cannot possibly be regionalized (either due to not having a region_specific_dict,
                    // or by having one without any values), we shouldnt even call regionalizeEnumItem
                    value.region_specific_dict && Object.keys(value.region_specific_dict).length
                        ? regionalizeEnumItem(value)
                        : value,
            }),
            {},
        )
        Object.assign(this, processedItems)
        this._items = processedItems
    }

    items() {
        if (!this._items_cache) {
            this._items_cache = Object.values(this._items).filter((value) => value.value != null)
        }
        return this._items_cache
    }

    region_supported_items() {
        return this.items()
            .filter(region_is_supported)
            .filter(satisfies_permissions)
            .filter(satisfies_user_permissions)
            .filter(satisfies_featureflag)
            .filter(hide_featureflags)
            .filter(should_show_in_frontend)
    }

    by_value(value) {
        if (!this._items_by_value) {
            this._items_by_value = {}
            this.items().forEach((item) => (this._items_by_value[item.value] = item))
        }

        // app/fields.py StringEnumField save their values as strings (rather than ints, like in the rest of Quorum),
        // so, we must support enum object lookups by either int or string

        // These fields are displayed on the frontend through
        // shared/search/containers/Filter/PacClassicPacTransactionFilters.js
        return this._items_by_value[parseInt(value, 10) || value]
    }

    /**
     * Filter the enums by the values in the set enumValues. Only return the enums that have a value
     * in enumValues
     * This function runs in Nlog32N, as Immutable sets have log32N lookup
     * @param {ImmutableSet[Integers]} enumValues - a set of integers to constrain the select
     * @returns {Array[Objects]} - An array of filtered enum objects
     */
    filter_enums_by_values(enumValues) {
        return this.items().filter((enumObj) => enumValues.has(enumObj.value))
    }
}

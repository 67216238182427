import React, { useRef, useState } from "react"
import PropTypes from "prop-types"

import { useOnClickOutside } from "utils/hooks"

import * as S from "Components/Compounds/WorkflowComponent/style"

import Button from "Components/Molecules/Button"
import Tooltip from "Components/Molecules/Tooltip"

import Icon from "Components/Atoms/Icon"

const WorkflowComponent = (props) => {
    const [isPopUpOpen, setIsPopUpOpen] = useState(false)
    const workflowComponentRef = useRef()
    useOnClickOutside(workflowComponentRef, () => setIsPopUpOpen(false))

    const togglePopUp = () => setIsPopUpOpen(!isPopUpOpen)

    const {
        childInput,
        children,
        emptyButton,
        emptyText,
        icon,
        iconFamily,
        iconOnClickFn,
        iconTooltipText,
        onScrollFn,
        popUpComponent,
        scroll,
        subtitle,
        title,
    } = props

    // show a button icon if we were given one or if we were given a popup component
    const showButtonIcon = !!(icon || popUpComponent)

        // either use the supplied icon or use one of the + / x icons
        const buttonIcon = icon || (
            isPopUpOpen ? "times" : "plus"
        )

    return (
        <S.MainContainer data-auto-cy="CompoundWorkflowComponent" ref={workflowComponentRef}>
            <S.HeaderContainer>
                <S.Title>{title}</S.Title>
                <S.Button
                    onClick={() => {
                        popUpComponent && togglePopUp()
                        iconOnClickFn && iconOnClickFn()
                    }}
                >
                    { iconTooltipText &&
                        <S.TooltipContainer>
                            <Tooltip text={iconTooltipText}/>
                        </S.TooltipContainer>
                    }
                    { showButtonIcon && <Icon icon={buttonIcon} iconFamily={iconFamily}/> }
                </S.Button>
            </S.HeaderContainer>
            <S.StackContainer>
                <S.StripContainer
                    empty={!children}
                    hasInput={!!childInput}
                    onScroll={onScrollFn}
                    scrollable={scroll}
                >
                    {children
                        ? children
                        : [
                            <S.EmptyStateContainer>
                            { emptyText }
                            { emptyButton &&
                                <Button
                                    type="secondary"
                                    text={emptyButton.text}
                                    leftIcon={emptyButton.icon}
                                    leftIconFamily={emptyButton.iconFamily}
                                    onClick={() => {
                                        emptyButton.onClickFn && emptyButton.onClickFn()
                                        emptyButton.shouldOpenPopUp && togglePopUp()
                                    }}
                                />
                            }
                            </S.EmptyStateContainer>
                        ]
                    }
                </S.StripContainer>
                <S.PopUp>
                    { isPopUpOpen &&
                        // Assume that when the value of the popup component is changed,
                        // we want to close the popup (this is true of selects normally)
                        // Wrap the pop up's onChange function to call the original onChange
                        // as well as closing the pop up
                        React.cloneElement(popUpComponent, {
                            onChange: (value) => {
                                popUpComponent.props.onChange && popUpComponent.props.onChange(value)
                                togglePopUp()
                            }
                        })
                    }
                </S.PopUp>
            </S.StackContainer>
            <S.ChildInput>
                {childInput}
            </S.ChildInput>
            {subtitle && <S.Subtitle>{ subtitle }</S.Subtitle>}
        </S.MainContainer>
    )
}

WorkflowComponent.defaultProps = {
    scroll: "none",
}

WorkflowComponent.propTypes = {
    // input component to render at the bottom of the workflow component
    childInput: PropTypes.oneOfType([PropTypes.element, PropTypes.func,]),
    // components to render in the component, like workflowstrips
    children: PropTypes.any,

    // button to show if there are no children
    emptyButton: PropTypes.shape({
        text: PropTypes.string,
        icon: PropTypes.string,
        iconFamily: PropTypes.string,
        onClickFn: PropTypes.func,
        // if true, the button will open the popup component similar to the
        // icon button if a popup component is provided
        shouldOpenPopUp: PropTypes.bool,
    }),
    // text to show if there are no children
    emptyText: PropTypes.string,
    // icon opposite the header text
    icon: PropTypes.string,
    // family of the icon next to the header text
    iconFamily: PropTypes.string,
    // callback for when the icon is clicked
    iconOnClickFn: PropTypes.func,
    // tooltip to display when the icon is hovered over
    iconTooltipText: PropTypes.string,
    // callback for when the container is scrolled
    onScrollFn: PropTypes.func,
    // component to display on top of the workflow component's container
    popUpComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func,]),

    // scroll if the component should be scrollable
    // reverse if it should map children and scroll backwards
    // none if it should grow to show all children at once
    scroll: PropTypes.oneOf(["none", "scroll", "reverse",]),

    // text to display below Component. ex: the person who recently updated the component and when
    subtitle: PropTypes.string,
    // text for the header at the top of the component
    title: PropTypes.string,


}

export default WorkflowComponent

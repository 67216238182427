import React from "react"
import PropTypes from "prop-types"

import * as colors from "Components/colors"
import * as S from "Components/Atoms/FormProgressBar/style"

const FormProgressBar = ({
    dataCy,
    primaryColor,
    progress,
    secondaryColor,
    isNewDesign
}) => (
    <S.FormProgressBar
        color={secondaryColor}
        data-auto-cy="AtomFormProgressBar"
        data-cy={dataCy}
        isNewDesign={isNewDesign}
    >
        <S.FilledProgress
            color={primaryColor}
            progress={progress}
        />
    </S.FormProgressBar>
)

FormProgressBar.defaultProps = {
    primaryColor: colors.QuorumBlue,
    secondaryColor: colors.AquaHaze,
}

FormProgressBar.propTypes = {
    dataCy: PropTypes.string,
    primaryColor: PropTypes.string,
    progress: PropTypes.number,
    secondaryColor: PropTypes.string,
    isNewDesign: PropTypes.bool,
}

export default FormProgressBar

import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "styled-components"

const MailingInstructionsContainer = styled.div`
    margin-top: 12px;
`

const UnconnectedCheckMailingInstructions = ({ managerInstructions }) => (
    <MailingInstructionsContainer dangerouslySetInnerHTML={{ __html: managerInstructions }} />
)
UnconnectedCheckMailingInstructions.propTypes = {
    managerInstructions: PropTypes.string,
}

export const CheckMailingInstructions = connect((state, props) => {
    // @ts-expect-error There are a few typescript issues with the state and props on this connector
    const donationForm = state.framework.get("donationForms")?.find((form) => form.get("id") === props.donationFormId)
    if (!donationForm) {
        return {}
    }

    return {
        managerInstructions: donationForm.get("check_mailing_instruction"),
    }
}, null)(UnconnectedCheckMailingInstructions)
// @ts-expect-error Doesn't think that propTypes exists
CheckMailingInstructions.propTypes = {
    donationFormId: PropTypes.number.isRequired,
}

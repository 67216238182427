import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/FormFooter/style"

const FormFooter = ({
    children,
    dataCy,
    isFixed,
    secondlineChildren,
}) => (
    <S.FormFooter
        data-auto-cy="CompoundFormFooter"
        isFixed={isFixed}
    >
        <S.FirstLine
            data-cy={dataCy}
        >
            {children}
        </S.FirstLine>
        {
            secondlineChildren &&
            <S.SecondLine>
                {secondlineChildren}
            </S.SecondLine>
        }
    </S.FormFooter>
)

FormFooter.defaultProps = {
    isFixed: true,
}

FormFooter.propTypes = {
    children: PropTypes.node,
    dataCy: PropTypes.string,
    isFixed: PropTypes.bool,
}

export default FormFooter

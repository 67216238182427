import actionTypes, * as actionGroups from "shared/forms/LogInteraction/action-types"
import * as djangioActionCreators from "shared/djangio/action-creators"
import constants from "shared/forms/LogInteraction/constants"

import { getProjectUris } from "shared/imports/sharedHelperFunctions"
import { checkValuesForValidMomentDateTime } from "shared/forms/helperFunctions"

export const getParticipantUris = (participantIds = [], dataTypeValue) =>
    participantIds.map((id) => DjangIO.resourceFromDataType(dataTypeValue).foreignKey(id))

export const buildUpdatedTagDict = (values, oldTagDict) => {
    const newTagDict = {}
    Object.keys(oldTagDict).forEach((slug) => {
        newTagDict[slug] = values[slug]
    })
    return newTagDict
}

/**
 * @param  {Object} values with state of interaction form
 * @param  {Object} props given to interaction form
 * @returns {Promise} returns a promise with the response
 */
export const createOrUpdateInteraction = (values, props) => {
    const officialsApiStrings = getParticipantUris(values.officials, DjangIO.app.models.QuorumDataType.person.value)
    const staffApiStrings = getParticipantUris(values.staff, DjangIO.app.models.QuorumDataType.staffer.value)
    const pressContactApiStrings = getParticipantUris(
        values.pressContacts,
        DjangIO.app.models.QuorumDataType.press_contact.value,
    )
    const teamMembersApiStrings = getParticipantUris(values.teamMembers, DjangIO.app.models.QuorumDataType.user.value)
    const contactsApiStrings = getParticipantUris(values.contacts, DjangIO.app.models.QuorumDataType.supporter.value)
    const orgsApiStrings = getParticipantUris(values.orgs, DjangIO.app.models.QuorumDataType.public_organization.value)
    const limitedToTeamsApiStrings = getParticipantUris(
        values.limited_to_teams,
        DjangIO.app.models.QuorumDataType.team.value,
    )
    const billApiStrings = getParticipantUris(values.bills, DjangIO.app.models.QuorumDataType.bill.value)

    // make the update dict
    const updateDict = {
        privacy: DjangIO.app.userdata.types.PrivacyType.public.value,
        // Send UTC to the server
        date: values.datetime.toISOString(),
        duration: values.duration,
        amount: values.amount,
        check_number: values.checkNumber,
        text: values.noteText,
        // NOTE: officials and staffers are both in the person_person table and in the officials m2m on the note model
        members: officialsApiStrings.concat(staffApiStrings, pressContactApiStrings),
        team_members: teamMembersApiStrings,
        supporters: contactsApiStrings,
        public_organizations: orgsApiStrings,
        bills: billApiStrings,
        projects: getProjectUris(values.projects),
        attachments: values.attachments.map((a) => DjangIO.app.userdata.models.Attachment.foreignKey(a.id)),
        tag_dict: buildUpdatedTagDict(values, props.customFields.customFieldDict),
        is_visible_to_partners: values.is_visible_to_partners,
        limited_to_teams: limitedToTeamsApiStrings,
    }

    // moment breaks mobile...
    if (!props.isMobile) {
        const searchable_date = `${values.datetime.format("MMMM D, YYYY, h:mm A")} ${moment
            .tz(moment.tz.guess(values.datetime))
            .zoneAbbr()}`

        // So only add a searchable_date in updateDict if moment is available (aka we're not on mobile)
        updateDict["searchable_date"] = searchable_date
    }

    if (!values.isDonation) {
        updateDict.custom_interaction_type = DjangIO.app.userdata.notes.models.CustomInteractionType.resourceUriFromId(
            values.type,
        )
    } else {
        updateDict.interaction_type = values.type
    }

    // if we are updating return after this
    if (props.isEdit && props.datumId) {
        return djangioActionCreators.resourcePatchDetail(
            DjangIO.app.userdata.notes.models.Note,
            actionGroups.updateInteractionTypes,
            props.datumId,
            updateDict,
            { cacheInvalidationParams: { endpoints: [DjangIO.app.userdata.notes.models.Note.endpoint] } },
        )
    }

    // on create we need a user
    return djangioActionCreators.resourcePostList(
        DjangIO.app.userdata.notes.models.Note,
        actionGroups.createInteractionTypes,
        {
            ...updateDict,
            user: DjangIO.app.userdata.models.QuorumUser.foreignKey(Userdata.id),
            note_type: DjangIO.app.userdata.types.NoteType.interaction.value,
        },
        {}, // payload
        { cacheInvalidationParams: { endpoints: [DjangIO.app.userdata.notes.models.Note.endpoint] } },
    )
}

export const createOrUpdateRelationship = (values, props) => {
    const officialsApiStrings = getParticipantUris(values.officials, DjangIO.app.models.QuorumDataType.person.value)
    const staffApiStrings = getParticipantUris(values.staff, DjangIO.app.models.QuorumDataType.staffer.value)
    const pressContactApiStrings = getParticipantUris(
        values.pressContacts,
        DjangIO.app.models.QuorumDataType.press_contact.value,
    )
    const teamMembersApiStrings = getParticipantUris(values.teamMembers, DjangIO.app.models.QuorumDataType.user.value)
    const contactsApiStrings = getParticipantUris(values.contacts, DjangIO.app.models.QuorumDataType.supporter.value)
    const orgsApiStrings = getParticipantUris(values.orgs, DjangIO.app.models.QuorumDataType.public_organization.value)
    const limitedToTeamsApiStrings = getParticipantUris(
        values.limited_to_teams,
        DjangIO.app.models.QuorumDataType.team.value,
    )
    const teamsApiStrings = getParticipantUris(values.teams, DjangIO.app.models.QuorumDataType.team.value)

    // make the update dict
    const updateDict = {
        relationship_type: values.type,
        relationship_status: values.status,
        text: values.noteText,
        officials: officialsApiStrings.concat(staffApiStrings, pressContactApiStrings),
        team_members: teamMembersApiStrings,
        supporters: contactsApiStrings,
        teams: teamsApiStrings,
        public_organizations: orgsApiStrings,
        attachments: values.attachments.map((a) => DjangIO.app.userdata.models.Attachment.foreignKey(a.id)),
        projects: getProjectUris(values.projects),
        tag_dict: buildUpdatedTagDict(values, props.customFields.customFieldDict),
        is_visible_to_partners: values.is_visible_to_partners,
        limited_to_teams: limitedToTeamsApiStrings,
    }

    // if we are updating return after this
    if (props.isEdit && props.datumId) {
        return djangioActionCreators.resourcePatchDetail(
            DjangIO.app.userdata.notes.models.Relationship,
            actionGroups.updateInteractionTypes,
            props.datumId,
            updateDict,
            { cacheInvalidationParams: { endpoints: [DjangIO.app.userdata.notes.models.Note.endpoint] } },
        )
    }

    // on create we need a user
    return djangioActionCreators.resourcePostList(
        DjangIO.app.userdata.notes.models.Relationship,
        actionGroups.createInteractionTypes,
        { ...updateDict, user: DjangIO.app.userdata.models.QuorumUser.foreignKey(Userdata.id) },
        {}, // payload,
        { cacheInvalidationParams: { endpoints: [DjangIO.app.userdata.notes.models.Note.endpoint] } },
    )
}

export const createOrUpdateComment = (values, props) => {
    const qdt = DjangIO.app.models.QuorumDataType.by_value(values.type)
    const limitedToTeamsApiStrings = getParticipantUris(
        values.limited_to_teams,
        DjangIO.app.models.QuorumDataType.team.value,
    )

    const { note_through_field } = qdt
    let comment_type = qdt.value
    if (comment_type === DjangIO.app.models.QuorumDataType.issue_management.value) {
        comment_type = DjangIO.app.models.QuorumDataType.project.value
    }

    // Unset all other relations so we only have the currently selected note_through_field set
    const defaultFields = {
        amendments: [],
        articles: [],
        bills: [],
        documents: [],
        members: [],
        projects: [],
        public_organizations: [],
        regulations: [],
        supporters: [],
        team_members: [],
        votes: [],
    }

    const updateDict = {
        ...defaultFields,
        comment_type,
        [note_through_field]: values.commentItems.map((itemId) =>
            DjangIO.resourceFromDataType(qdt.value).foreignKey(itemId),
        ),
        privacy: DjangIO.app.userdata.types.PrivacyType.public.value,
        date: values.datetime.format("M/D/Y/H:m Z"),
        text: values.noteText,
        attachments: values.attachments.map((attachment) =>
            DjangIO.app.userdata.models.Attachment.foreignKey(attachment.id),
        ),
        tag_dict: buildUpdatedTagDict(values, props.customFields.customFieldDict),
        // if this is a comment on projects, then commentItems will hold all the projects
        // if this is a comment about anything else, then the projects chosen are being used to tag this note
        projects:
            note_through_field === "projects"
                ? values.commentItems.map((itemId) => DjangIO.resourceFromDataType(qdt.value).foreignKey(itemId))
                : getProjectUris(values.projects),
        is_visible_to_partners: values.is_visible_to_partners,
        limited_to_teams: limitedToTeamsApiStrings,
    }

    // if comment is for a member, add it to 'members' as well as person
    if (values.type === DjangIO.app.models.QuorumDataType.person.value) {
        updateDict.members = values.commentItems.map((itemId) =>
            DjangIO.resourceFromDataType(qdt.value).foreignKey(itemId),
        )
    }

    // if we are updating return after this
    if (props.isEdit && props.datumId) {
        return djangioActionCreators.resourcePatchDetail(
            DjangIO.app.userdata.notes.models.Note,
            actionGroups.updateInteractionTypes,
            props.datumId,
            updateDict,
            { cacheInvalidationParams: { endpoints: [DjangIO.app.userdata.notes.models.Note.endpoint] } },
        )
    }
    // on create we need a user
    return djangioActionCreators.resourcePostList(
        DjangIO.app.userdata.notes.models.Note,
        actionGroups.createInteractionTypes,
        {
            ...updateDict,
            user: DjangIO.app.userdata.models.QuorumUser.foreignKey(Userdata.id),
            note_type: DjangIO.app.userdata.types.NoteType.comment.value,
        },
        {}, // payload
        { cacheInvalidationParams: { endpoints: [DjangIO.app.userdata.notes.models.Note.endpoint] } },
    )
}

/**
 * Validation for interaction and donation forms
 * @param  {[type]} values [description]
 * @return {[type]}        [description]
 */
export const validateInteraction = (values) => {
    const errors = {}
    // Default values are immutable
    let teamMembers = values.getIn(["teamMembers"]) || []
    if (teamMembers.toJS) {
        teamMembers = teamMembers.toJS()
    }
    let supporters = values.getIn(["contacts"]) || []
    if (supporters.toJS) {
        supporters = supporters.toJS()
    }

    let staffers = values.getIn(["staff"]) || []
    if (staffers.toJS) {
        staffers = staffers.toJS()
    }

    let officials = values.getIn(["officials"]) || []
    if (officials.toJS) {
        officials = officials.toJS()
    }

    let pressContacts = values.getIn(["pressContacts"]) || []
    if (pressContacts.toJS) {
        pressContacts = pressContacts.toJS()
    }

    let orgs = values.getIn(["orgs"]) || []
    if (orgs.toJS) {
        orgs = orgs.toJS()
    }

    if (values.get("isDonation")) {
        const amount = values.get("amount")
        if (isNaN(parseFloat(amount))) {
            errors.amount = constants.donationAmountEmptyError
        }
    }

    // mobile use could have uploaded business cards
    let businessCards = values.get("businessCards") || []
    if (businessCards.toJS) {
        businessCards = businessCards.toJS()
    }

    const totalLength =
        businessCards.length +
        supporters.length +
        staffers.length +
        officials.length +
        pressContacts.length +
        orgs.length +
        teamMembers.length

    if (totalLength < 2) {
        errors.officials = constants.minimumParticipantsError
    }

    checkValuesForValidMomentDateTime({ values: values.toJS(), errors, fieldName: "datetime" })

    return errors
}

export const validateRelationship = (immutableValues) => {
    const errors = {}
    const values = immutableValues.toJS()

    // Default values are immutable
    const teamMembers = values.teamMembers || []
    const supporters = values.contacts || []
    const pressContacts = values.pressContacts || []
    const staffers = values.staff || []
    const officials = values.officials || []
    const orgs = values.orgs || []

    const totalLength =
        supporters.length + staffers.length + officials.length + pressContacts.length + orgs.length + teamMembers.length
    // check for too few participants
    if (totalLength < 2) {
        errors.officials = constants.tooFewPeopleInRelationshipError
    }

    // for the 'Sit on a board' relationship, an organization must be tagged
    if (values.type === DjangIO.app.userdata.types.RelationshipType.board.value && orgs.length === 0) {
        errors.orgs = constants.noOrganizationInBoardRelationshipError
    }

    return errors
}

export const validateComment = (values) => {
    const errors = {}

    let commentItems = values.get("commentItems") || []
    if (commentItems.toJS) {
        commentItems = commentItems.toJS()
    }
    let attachments = values.get("attachments") || []
    if (attachments.toJS) {
        attachments = attachments.toJS()
    }

    // if no items chosen and no attachments then error
    if (!commentItems.length && !attachments.length) {
        errors.commentItems = constants.warnNoItemsInComment
    }

    if (!values.get("noteText")) {
        errors.text = constants.warnNoTextInComment
    }

    return errors
}

export const warnInteraction = (values) => {
    const warnings = {}

    if (values.get("isDonation") && parseFloat(values.get("amount")) < 0) {
        warnings.amount = constants.donationAmountIsNegative
    }

    return warnings
}

// currently no warnings (only has error for tooFewPeopleInRelationshipError)
export const warnRelationship = (values) => {
    // eslint-disable-line no-unused-vars
    const warnings = {}
    return warnings
}

// currently no warnings (only has error for warnNoItemsInComment)
export const warnComment = (values) => {
    // eslint-disable-line no-unused-vars
    const warnings = {}
    return warnings
}

export const loadInteraction = (id) =>
    djangioActionCreators.resourceGetDetail(
        DjangIO.app.userdata.notes.models.Note,
        actionGroups.loadInteractionTypes,
        id,
        {},
        false,
    )

export const loadComment = (id) =>
    djangioActionCreators.resourceGetDetail(
        DjangIO.app.userdata.notes.models.Note,
        actionGroups.loadCommentTypes,
        id,
        {},
        false,
    )

export const loadRelationship = (id) =>
    djangioActionCreators.resourceGetDetail(
        DjangIO.app.userdata.notes.models.Relationship,
        actionGroups.loadRelationshipTypes,
        id,
        {},
        false,
    )

export const getProjects = () =>
    djangioActionCreators.resourceGetList(
        DjangIO.app.userdata.models.IssueManagement,
        actionGroups.getProjectsTypes,
        {
            dehydrate_extra: ["parent_name"],
        },
        {},
        false,
    )

export const clearLoadedNote = () => ({
    type: actionTypes.CLEAR_LOADED_NOTE,
})

// clears the interactionLogger slice of the store, which works for all types of interaction logger
export const clearForm = () => ({
    type: actionTypes.CLEAR,
})

export const newParticipantAdded = () => ({
    type: actionTypes.CLEAR_NEW_PARTICIPANT,
})

export const setIsEditing = () => ({
    type: actionTypes.SET_NOTE_IS_EDITING,
})

export const disableIsEditing = () => ({
    type: actionTypes.DISABLE_NOTE_IS_EDITING,
})

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/WidgetEditForm/style"

const WidgetEditForm = ({
    children,

    className,
    sidebar,
    widgetEditFormHeader,
    widget,
})=> (
    <S.WidgetEditForm data-auto-cy="MoleculeWidgetEditForm">
        {widgetEditFormHeader}
        <S.WidgetEditContainer>
            {widget}
            {
                sidebar &&
                    <S.WidgetEditSidebar>
                        {sidebar}
                    </S.WidgetEditSidebar>
            }
        </S.WidgetEditContainer>
    </S.WidgetEditForm>
)

WidgetEditForm.defaultProps = {}

WidgetEditForm.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),

    className: PropTypes.string,
}

export default WidgetEditForm

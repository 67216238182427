import styled from "styled-components"
import { Table } from "react-bootstrap"
import { constants } from "QuorumGrassroots/constants"
import { styleWithOrgDesignHelper } from "QuorumGrassroots/styled-components/helperFunctions"

const StyledTableComponent = styled(Table)`
    border: 1px solid ${(props) => props.organizationDesign.primary_color};

    thead {
        tr > th {
            border: 1px solid ${(props) => props.organizationDesign.primary_color};
        }
    }

    tbody > tr > td {
        border: 1px solid ${(props) => props.organizationDesign.primary_color};
    }
`
export const StyledTable = styleWithOrgDesignHelper(StyledTableComponent)

export const StyledTableWrapper = styled.div`
    ${(props) =>
        props.maxTableHeight &&
        `
        .table-responsive {
            max-height: ${props.maxTableHeight}px;

            @media (max-width: ${constants.mobileWidth}px) {
                overflow-y: scroll;
            }
        }
    `}
`

import {
    overrideStyleBasedOnTheme,
    styleInDesktopScreenSize,
    styleInMobileScreenSize,
} from "QuorumGrassroots/styled-components/helperFunctions"
import { StyledVideoWidget } from "QuorumGrassroots/widgets/Video/components/style"
import { StyledAnchor, StyledLink } from "QuorumGrassroots/widgets/styled-components/styles"
import { hexCodetoRGBA, shadeColor } from "app/static/frontend/imports/desktopHelperFunctions"
import React from "react"
import { vimeoVideoIdRegex, youtubeVideoIdRegex } from "shared/imports/regex"
import { themeStyleConstants } from "QuorumGrassroots/constants"
/**
 * style the background of widgets based on action center theme
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overrideWidgetBackgroundColor = (props) => {
    // if organizationDesign is not defined, we don't want errors and we don't need this value
    const orgStyledBackground = props.organizationDesign && `background-color: ${props.organizationDesign.light_grey};`
    const impactThemeStyle = props.isForm ? "background-color: white;" : "background-color: transparent;"
    return overrideStyleBasedOnTheme({
        actionCenterSettings: props.actionCenterSettings,
        organizationDesign: props.organizationDesign,
        defaultThemeStyle: props.isCampaignPage ? impactThemeStyle : orgStyledBackground,
        impactThemeStyle,
        invalidThemeStyle: orgStyledBackground,
        disconnectedStyle: "",
    })
}

/**
 * style the border of widgets based on action center theme and whether widget is a form
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overrideWidgetBorderStyle = (props) => {
    // if organizationDesign is not defined, we don't want errors and we don't need this value
    const orgStyledBorder = props.organizationDesign && `border: 1px solid ${props.organizationDesign.primary_color};`
    const impactThemeStyle = props.isForm
        ? `
            ${orgStyledBorder}
            box-shadow: none;
        `
        : `
            border: 0;
            box-shadow: none;
            background: none;
        `
    return overrideStyleBasedOnTheme({
        actionCenterSettings: props.actionCenterSettings,
        organizationDesign: props.organizationDesign,
        defaultThemeStyle: props.isCampaignPage ? impactThemeStyle : orgStyledBorder,
        impactThemeStyle,
        invalidThemeStyle: orgStyledBorder,
        disconnectedStyle: "",
    })
}

/**
 * override the hover attribute of widgets based if organizationDesign is defined and the theme
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overrideHoverColor = (props) => {
    // if organizationDesign is not defined, we don't want errors and we don't need this value
    const orgStyledBackground =
        props.organizationDesign && `background-color: ${shadeColor(props.organizationDesign.light_grey, -10)};`
    const impactThemeStyle = "background-color: inherit;"
    return overrideStyleBasedOnTheme({
        actionCenterSettings: props.actionCenterSettings,
        organizationDesign: props.organizationDesign,
        defaultThemeStyle: props.isCampaignPage ? impactThemeStyle : orgStyledBackground,
        impactThemeStyle,
        invalidThemeStyle: orgStyledBackground,
        disconnectedStyle: "",
    })
}

/**
 * override the styling of StyledInlineWrapper
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overrideInlineWrapperStyle = (props) => {
    if (props.actionCenterSettings && props.organizationDesign) {
        switch (props.actionCenterSettings.theme_type) {
            case DjangIO.app.grassroots.types.ActionCenterTheme.default.value:
                return ""
            case DjangIO.app.grassroots.types.ActionCenterTheme.impact.value:
                // if theme is impact, we want to create a separator line between inline widgets, unless its the last one
                return `
                    padding-top: 0px;
                    padding-bottom: 30px;
                    display: block;
                    ${!props.isLast ? `border-bottom: 1px solid ${props.organizationDesign.primary_color};` : ""}
                    ${props.isFirst ? `${styleInDesktopScreenSize("margin-top: -20px;")}` : ""}
                `
            default:
                return ""
        }
    }
    // if props.actionCenterSettings or props.actionCenterSettings are not defined then do not override style
    return ""
}

/**
 * override the styling of StyledColumn for inline components
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overrideCampaignInlineColumnStyling = (props) => {
    const defaulThemeStyle = ""
    const impactThemeStyle = "margin-bottom: 0px;"
    return overrideStyleBasedOnTheme({
        actionCenterSettings: props.actionCenterSettings,
        organizationDesign: props.organizationDesign,
        defaultThemeStyle: props.isCampaignPage ? impactThemeStyle : defaulThemeStyle,
        impactThemeStyle,
        invalidThemeStyle: "",
        disconnectedStyle: "",
    })
}

/**
 * override the background color on hover for NoTextDecorationLink component
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overrideLinkHoverColor = (props) => {
    // if organizationDesign is not defined, we don't want errors and we don't need this value
    const orgStyledBackground =
        props.organizationDesign &&
        `background-color: ${hexCodetoRGBA(props.organizationDesign.lighter_primary, 0.25)};`
    return overrideStyleBasedOnTheme({
        actionCenterSettings: props.actionCenterSettings,
        organizationDesign: props.organizationDesign,
        defaultThemeStyle: orgStyledBackground,
        impactThemeStyle: "background-color: inherit;",
        invalidThemeStyle: orgStyledBackground,
        disconnectedStyle: "",
    })
}

/**
 * override style on StyledInlineCallToAction based on action center theme
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overrideInlineCallToActionStyle = (props) => {
    // if organizationDesign is not defined, we don't want errors and we don't need this value
    const impactThemeStyle =
        props.organizationDesign &&
        `
            &:hover {
                background-color: ${shadeColor(props.organizationDesign.primary_color, -20)};
            }
        `
    return overrideStyleBasedOnTheme({
        actionCenterSettings: props.actionCenterSettings,
        organizationDesign: props.organizationDesign,
        defaultThemeStyle: "",
        impactThemeStyle,
        invalidThemeStyle: "",
        disconnectedStyle: "",
    })
}

/**
 * override style on StyledColumn based on action center theme
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overrideColumnStyling = (props) => {
    const defaulThemeStyle = ""
    const impactThemeStyle = "margin-bottom: 20px;"
    return overrideStyleBasedOnTheme({
        actionCenterSettings: props.actionCenterSettings,
        organizationDesign: props.organizationDesign,
        defaultThemeStyle: props.isCampaignPage ? impactThemeStyle : defaulThemeStyle,
        impactThemeStyle,
        invalidThemeStyle: "",
        disconnectedStyle: "",
    })
}

/**
 * override style on FullBleedImage based on action center theme
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overrideFullBleedImageStyling = (props) => {
    if (props.isCampaignPage && props.isVideo) {
        return `
            margin: 0 auto;
            padding: 32px 0 30px 0;
            ${styleInMobileScreenSize(`
                margin: 0 auto;
                padding: 0 0 15px 0;
                text-align: center;
            `)}
        `
    }
    const isNavBarTransparent =
        props.navBarIsTransparent ||
        props.actionCenterSettings?.navigation_bar_style_type ===
            DjangIO.app.grassroots.enums.ActionCenterNavigationBarBackgroundStyle.transparent.value
    const hasNavBar = props.navBarStyle !== DjangIO.app.grassroots.campaign.types.CampaignNavigationBarType.none.value

    if (
        props.isCampaignPage ||
        (props.actionCenterSettings &&
            props.actionCenterSettings.theme_type === DjangIO.app.grassroots.types.ActionCenterTheme.impact.value)
    ) {
        return `
            margin-left: -95px;
            margin-right: -95px;
            width: auto;
            ${styleInMobileScreenSize(`
                margin-top: -20px;
                margin-left: -15px;
                margin-right: -15px;
            `)}
            ${props.isFirst && !props.isCampaignPage ? "margin-top: -60px;" : ""}
            ${
                props.isLast ||
                (props.nextWidget &&
                    DjangIO.app.grassroots.types.GrassrootsWidgetType.by_value(props.nextWidget).full_width)
                    ? "margin-bottom: 0px;"
                    : ""
            }
            ${
                // if the navbar is transparent then move the full bleed image under the navbar
                isNavBarTransparent && hasNavBar
                    ? `margin-top: -${
                          themeStyleConstants[DjangIO.app.grassroots.types.ActionCenterTheme.impact.value]
                              .headerHeight + 60
                      }px;`
                    : ""
            }
        `
    }
    return ""
}

/**
 * override style on background of full_width components based on action center theme
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overrideFullWidthBackgroundStyling = (props) => {
    return overrideStyleBasedOnTheme({
        actionCenterSettings: props.actionCenterSettings,
        organizationDesign: props.organizationDesign,
        defaultThemeStyle: "",
        impactThemeStyle: `
            border-radius: 0px;
            padding-left: 105px;
            padding-right: 105px;
            padding-top: 51px;
            padding-bottom: 51px;
            margin-left: -95px;
            margin-right: -95px;
            ${props.isFirst ? "margin-top: -60px;" : ""}
            ${props.isLast ? "margin-bottom: 0px;" : ""}
            ${
                props.nextWidget &&
                DjangIO.app.grassroots.types.GrassrootsWidgetType.by_value(props.nextWidget).full_width
                    ? "margin-bottom: 0px;"
                    : ""
            }
            ${styleInMobileScreenSize(`
                margin-left: -15px;
                margin-right: -15px;
                padding-left: 15px;
                padding-right: 15px;
                margin-top: -20px;
            `)}
        `,
        invalidThemeStyle: "",
        disconnectedStyle: "",
    })
}

/**
 * override style of the campaign components in the impact theme
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overrideCampaignWrapperStyle = (props) => {
    const impactThemeStyle = `
        border-radius: 4px;
        background-color: white;
        display: inline-block;
        padding-top: 35px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        width: 100%;
        ${
            props.backgroundStyle === DjangIO.app.grassroots.enums.ActionCenterBackgroundStyle.color.value
                ? `background-color: transparent;`
                : ""
        }
    `
    return overrideStyleBasedOnTheme({
        actionCenterSettings: props.actionCenterSettings,
        organizationDesign: props.organizationDesign,
        defaultThemeStyle: impactThemeStyle,
        impactThemeStyle,
        invalidThemeStyle: "",
        disconnectedStyle: "",
    })
}

/**
 * override style of the campaign components in the impact theme
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overrideCampaignDescriptionStyle = (props) => {
    if (
        !props.isCampaignPage &&
        props.actionCenterSettings &&
        props.actionCenterSettings.theme_type === DjangIO.app.grassroots.types.ActionCenterTheme.impact.value &&
        props.backgroundStyle === DjangIO.app.grassroots.enums.ActionCenterBackgroundStyle.color.value
    ) {
        return "color: white;"
    }
    return ""
}

export const renderButtonLink = (buttonUrl, buttonText) => {
    return buttonUrl[0] === "/" ? (
        <StyledLink to={buttonUrl}>{buttonText}</StyledLink>
    ) : (
        <StyledAnchor href={buttonUrl} target="_blank">
            {buttonText}
        </StyledAnchor>
    )
}

export const getYoutubeEmbedUrl = (videoUrl) => {
    // This regex matches the youtube video ID from various youtube links in varying formats
    // https://stackoverflow.com/questions/3717115/regular-expression-for-youtube-links
    const youtubeVideoIdMatches = youtubeVideoIdRegex.exec(videoUrl)

    if (youtubeVideoIdMatches && youtubeVideoIdMatches.length > 1) {
        // If the regex has correctly matched a youtube video ID, the ID is group1
        // (full URL is index 0, matched ID is index 1)
        return `https://www.youtube.com/embed/${youtubeVideoIdMatches[1]}/`
    }

    return ""
}

export const getVimeoEmbedUrl = (videoUrl) => {
    const vimeoVideoIdMatches = vimeoVideoIdRegex.exec(videoUrl)

    if (vimeoVideoIdMatches && vimeoVideoIdMatches.length > 4) {
        // Given this example vimeo link, https://player.vimeo.com/video/299007338
        // The resulting matches will be [
        //      "https://player.vimeo.com/video/299007338", "https", "player.", undefined, "299007338",
        //      index: 0, input: "https://player.vimeo.com/video/299007338", groups: undefined
        // ]
        // The item at index 4 is the Vimeo video ID
        return `https://player.vimeo.com/video/${vimeoVideoIdMatches[4]}/`
    }

    return ""
}

export const renderStyledVideo = (videoUrl, title) => {
    const youtubeVideoEmbedUrl = getYoutubeEmbedUrl(videoUrl)
    const vimeoVideoEmbedUrl = getVimeoEmbedUrl(videoUrl)

    return <StyledVideoWidget src={youtubeVideoEmbedUrl || vimeoVideoEmbedUrl} title={title} allowFullScreen />
}

/**
 * override border radius of a styled component in the impact theme
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const removeBorderRadiusInImpactTheme = (props) => {
    return overrideStyleBasedOnTheme({
        actionCenterSettings: props.actionCenterSettings,
        organizationDesign: props.organizationDesign,
        defaultThemeStyle: "",
        impactThemeStyle: "border-radius: 0px;",
        invalidThemeStyle: "",
        disconnectedStyle: "",
    })
}

import React from "react"
import { Col, Table } from "react-bootstrap"

import {
    StyledMemberLink,
    StyledOfficialMiniatureThumbnail,
    StyledTableHeader,
    StyledTableBody,
    StyledMemberNameWithThumb,
    StyledMemberInfo,
} from "QuorumGrassroots/widgets/OfficialList/components/style"

const OfficialsListView = ({officialList}) => {
    return (
        <Table>
            <StyledTableHeader>
                <Col md={6} >
                    <StyledMemberInfo>
                        Official
                    </StyledMemberInfo>
                </Col>
                <Col md={2} >
                    <StyledMemberInfo>
                        Title
                    </StyledMemberInfo>
                </Col>
                <Col md={4} >
                    <StyledMemberInfo>
                        Org
                    </StyledMemberInfo>
                </Col>
            </StyledTableHeader>
            { officialList.map(official =>
                <StyledMemberLink
                    to={`/official/${official.id}/`}
                    href={`/official/${official.id}/`}
                    data-cy={official.name_and_title}
                >
                    <StyledTableBody>
                        <Col md={6} >
                            <StyledMemberNameWithThumb>
                                <StyledOfficialMiniatureThumbnail
                                    md={4}
                                    src={generateStaticUrl(official.image_url)}
                                    party={official.party}
                                />
                                {official.name}
                            </StyledMemberNameWithThumb>
                        </Col>
                        <Col md={2} >
                            <StyledMemberInfo>
                                {official.title}
                            </StyledMemberInfo>
                        </Col>
                        <Col md={4} >
                            <StyledMemberInfo>
                                {official.primary_organization_name}
                            </StyledMemberInfo>
                        </Col>
                    </StyledTableBody>
                </StyledMemberLink>
            )}
        </Table>
    )
}

export default OfficialsListView

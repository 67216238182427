// https://projects.invisionapp.com/d/main/#/console/21044500/456272278/preview

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/SearchifyFooter/style"

import Button from "Components/Molecules/Button"

const SearchifyFooter = ({
    cancelButtonOnClick,
    submitButtonOnClick,
    submitButtonIsDisabled,
    submitButtonText,
    shouldShowSmallButtons,
}) => (
    <S.SearchifyFooter
        data-auto-cy="CompoundSearchifyFooter"
        shouldShowSmallButtons={shouldShowSmallButtons}
    >
        <Button
            dataCy="advanced-search-cancel-button"
            id="advanced-search-cancel-button"
            isGiant={!shouldShowSmallButtons}
            onClick={cancelButtonOnClick}
            text="Cancel"
            type="secondary"
        />
        <Button
            dataCy="advanced-search-submit-button"
            // if we have a search cap, disable submit until we figure out how many items exist
            disabled={submitButtonIsDisabled}
            id="advanced-search-submit-button"
            isGiant={!shouldShowSmallButtons}
            onClick={submitButtonOnClick}
            text={submitButtonText}
            type="primary"
        />
    </S.SearchifyFooter>
)

SearchifyFooter.propTypes = {
    cancelButtonOnClick: PropTypes.func,
    submitButtonOnClick: PropTypes.func,
    submitButtonIsDisabled: PropTypes.bool,
    submitButtonText: PropTypes.string,
}

export default SearchifyFooter

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/MediumGateway/style"

import Icon from "Components/Atoms/Icon"
import LinkCallToAction from "../../Molecules/LinkCallToAction"
import Tag from "../../Molecules/Tag"


const MediumGateway = ({
    callToActionText,
    content,
    iconColor,
    customHeight,
    customPadding,
    customWidth,
    dropShadow,
    header,
    headerString,
    icon,
    iconFamily,
    iconSize,
    iconOnRight,
    isCompleted,
    justifyContent,
    linkInHeader,
    maxLines,
    onClick,
    tags,
    type
}) => (
    <S.GatewayWrapper
        customHeight={customHeight}
        customPadding={customPadding}
        customWidth={customWidth}
        data-auto-cy="CompoundMediumGateway"
        data-cy={`MediumGateway-${headerString || header}`}
        dropShadow={dropShadow}
        isCompleted={isCompleted}
        onClick={onClick}
        type={type}
    >
        {!iconOnRight ?
            <S.Header icon={icon} justifyContent={justifyContent}>
                    {icon &&
                        <S.Icon>
                            <Icon icon={icon} iconFamily={iconFamily} size={iconSize} color={iconColor}/>
                        </S.Icon>
                    }
                    <S.HeaderText icon={icon}>
                        {header}
                        {
                            linkInHeader &&
                            <LinkCallToAction
                                customMargin="5px 0px 0px 0px"
                                onClick={onClick}
                                text={callToActionText}
                                type="lg"
                            />
                        }
                    </S.HeaderText>
                    {isCompleted &&
                        <S.Icon isCompleted={isCompleted}>
                            <Icon icon="check" iconFamily="fas" size={iconSize}/>
                        </S.Icon>
                    }
            </S.Header>
        :
            <S.Header icon={icon} justifyContent={justifyContent}>
                    {isCompleted &&
                        <S.Icon isCompleted={isCompleted}>
                            <Icon icon="check" iconFamily="fas" size={iconSize}/>
                        </S.Icon>
                    }
                    <S.HeaderText icon={icon} iconOnRight={iconOnRight}>
                        {header}
                        {
                            linkInHeader &&
                            <LinkCallToAction
                                customMargin="5px 0px 0px 0px"
                                onClick={onClick}
                                text={callToActionText}
                                type="lg"
                            />
                        }
                    </S.HeaderText>
                    {icon &&
                        <S.Icon iconOnRight={iconOnRight}>
                            <Icon icon={icon} iconFamily={iconFamily} size={iconSize}/>
                        </S.Icon>
                    }
            </S.Header>
        }
        {content &&
            <S.Content maxLines={maxLines}>{content}</S.Content>
        }
        <S.Actions>
            {tags &&
                tags.map(tag => <Tag key={tag}>{tag}</Tag>)
            }
            {isCompleted && !tags &&
                <S.Status>Completed!</S.Status>
            }
        </S.Actions>
    </S.GatewayWrapper>
)

MediumGateway.defaultProps = {
    iconFamily: "fad",
    iconSize: "1x",
    linkInHeader: false,
    maxLines: 2,
    type: "feature",
}

MediumGateway.propTypes = {
    callToActionText: PropTypes.node.isRequired,
    content: PropTypes.string,
    customHeight: PropTypes.string,
    customPadding: PropTypes.string,
    customWidth: PropTypes.string,
    header: PropTypes.node.isRequired,
    headerString: PropTypes.string, // include a string equivalent if header is not itself a string
    icon: PropTypes.string,
    iconFamily: PropTypes.string,
    isCompleted: PropTypes.bool,
    linkInHeader: PropTypes.bool,
    maxLines: PropTypes.number,
    onClick: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.oneOf(["feature", "tutorial"]),
}

export default MediumGateway

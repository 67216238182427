import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"


export const MediumCard = styled.div`
    width: 100%;
    background-color: ${colors.White};
    ${rules.CardStyle}
    border-color: ${colors.DarkerPorcelain};

    display: flex;
    align-items: center;

    ${rules.LargeTextLightWeight}
    padding: 18px;

    @media (max-height: ${rules.Breakpoints.medium}) {
        ${rules.MediumTextLightWeight}
        padding: 13px;
    }
    @media (max-height: ${rules.Breakpoints.small}) {
        ${rules.SmallTextLightWeight}
    }
`

export const Text = styled.span`
    margin-left: ${({ icon }) => icon ? "18px" : "0px"};
    @media (max-height: ${rules.Breakpoints.medium}) {
        margin-left: ${({ icon }) => icon ? "13px" : "0px"};
    }
`

export const Icon = styled.span`
    color: ${colors.violet5};
    --fa-primary-color: ${colors.violet7};
    --fa-secondary-color: ${colors.violet5};
`

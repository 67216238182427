import styled from "styled-components"
import { styleInMobileScreenSize } from "QuorumGrassroots/styled-components/helperFunctions"

export const StyledTitle = styled.div`
    margin-left: 9px;
    margin-bottom: 15px;
    font-size: 30px;
    line-height: 36px;
    ${(props) => props.useBackgroundColor && "color: white;"}
`

export const ImageColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const ImageRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    ${styleInMobileScreenSize(`
        align-items: center;
        flex-direction: column;
    `)}
`

export const StyledImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 390px;
    margin: 9px;
    ${styleInMobileScreenSize(`
        width: 100%;
        height: 210px;
    `)}
    ${(props) =>
        `height: ${DjangIO.app.grassroots.enums.ImageCollageLayoutType.by_value(props.layout).image_height}px;`}
    background-position: center;
    background-repeat: no-repeat;
    ${(props) => `background-image: url(${props.imagePath});`}
    ${(props) => (props.imageContain ? "background-size: contain;" : "background-size: cover;")}
`

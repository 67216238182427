import styled from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

import { ButtonContainer } from "Components/Molecules/Button/style"

export const GridToggleContainer = styled.div`
`

export const GridToggle = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: ${({ choices, maxColumns }) => `repeat(${choices.length > 1 ? maxColumns : 1}, 1fr)`};
    margin-top: 4px;

    ${ButtonContainer} {
        height: 40px;
    }
`

export const GridToggleFilterGroup = styled.div`
    margin-top: 15px;
`

export const Label = styled.span`
    ${rules.SmallMediumText}
    color: ${colors.QuorumBlue};
    margin-bottom: 6px;
`

import styled from 'styled-components'
import * as rules from "Components/rules"
import * as colors from "Components/colors"

export const Footer = styled.div`
    ${({ noBorder }) => noBorder ? "" : `border-top: ${rules.Line} ${colors.QuorumGrey5}; border-radius: 0px`};
    display: flex;
    flex-grow: 0;
    padding: 18px 45px;
    width: 100%;
`

export const LeftSide = styled.div`
    flex: 1;
`

export const RightSide = styled.div`
    flex: 1;
    button {
        float: right;
    }
    div {
        float: right;
    }
`

export const Center = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
`

import React from "react"
import PropTypes from "prop-types"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import { renderButtonLink } from "QuorumGrassroots/widgets/styled-components/helperFunctions"

import {
    CardInteriorWrapper,
    StyledCard,
    StyledCardButton,
    StyledCardContentWrapper,
    StyledCardDescription,
    StyledCardImage,
    StyledCardImg,
    StyledCardStatDescription,
    StyledCardStatNumber,
    StyledCardsWrapper,
    StyledCardTitle,
    StyledImageIcon,
    StyledIcon,
    StyledTitle,
} from "QuorumGrassroots/widgets/CardsSection/components/style"
import { StyledBackground } from "QuorumGrassroots/widgets/styled-components/StyledWidget"

import { formatNumberForDisplay } from "shared/imports/sharedHelperFunctions"

const { CardsSectionLayoutType } = DjangIO.app.grassroots.enums

const CardsSectionWidget = (props) => {
    const renderCard = (index, layout) => {
        const card = props.cards[index]
        const cardAggregateMethod = card.aggregate_field_and_method
            ? `${card.aggregate_field_and_method.custom_data_id}|${card.aggregate_field_and_method.field_name}|${card.aggregate_field_and_method.query_operation}`
            : null

        return (
            <StyledCard
                cardColor={props.cardColor}
                className="cards-section-card"
                imageCard={card.imageCard}
                imageContain={card.imageContain}
                imagePath={card.imageUrl}
                isClickable={props.isClickableCardArray && props.isClickableCardArray[index]}
                isSingle={props.isSingle}
                layout={layout}
                useCardColor={props.useCardColor}
                useOrgDesign={props.useOrgDesign}
            >
                <CardInteriorWrapper
                    imageCard={card.imageCard}
                    isSingle={props.isSingle}
                    onClick={props.onClickCardArray && props.onClickCardArray[index]}
                >
                    <React.Fragment>
                        {props.showCardImageArray && props.showCardImageArray[index] && (
                            <StyledCardImage
                                imageCard={card.imageCard}
                                imageContain={card.imageContain}
                                imagePath={card.imageUrl}
                                isSingle={props.isSingle}
                            />
                        )}
                        {props.showCardImgArray && props.showCardImgArray[index] && (
                            <StyledCardImg
                                imageCard={card.imageCard}
                                imageContain={card.imageContain}
                                src={card.imageUrl}
                                isSingle={props.isSingle}
                            />
                        )}
                        {!card.image_url && card.fa4_icon && <StyledIcon className={`fa ${card.fa4_icon}`} />}
                        {card.image_url && <StyledImageIcon md={4} src={card.image_url} />}
                        {props.data && props.data[cardAggregateMethod] && (
                            <StyledCardStatNumber>
                                {formatNumberForDisplay(props.data[cardAggregateMethod])}
                            </StyledCardStatNumber>
                        )}
                        <StyledCardContentWrapper
                            isSingle={props.isSingle}
                            imageCard={card.imageCard}
                            isClickable={props.isClickableCardArray && props.isClickableCardArray[index]}
                            useOrgDesign={props.useOrgDesign}
                        >
                            {card.title && <StyledCardTitle isSingle={props.isSingle}>{card.title}</StyledCardTitle>}
                            {card.description &&
                                (props.data ? (
                                    <StyledCardStatDescription>{card.description}</StyledCardStatDescription>
                                ) : (
                                    <StyledCardDescription hasImage={Boolean(card.imageUrl)}>
                                        {card.description}
                                    </StyledCardDescription>
                                ))}
                            {card.cardLinkType === DjangIO.app.grassroots.enums.CardLinkType.clickable_button.value && (
                                <StyledCardButton isSingle={props.isSingle}>
                                    {renderButtonLink(card.buttonUrl, card.buttonText)}
                                </StyledCardButton>
                            )}
                        </StyledCardContentWrapper>
                    </React.Fragment>
                </CardInteriorWrapper>
            </StyledCard>
        )
    }

    const renderHiddenCard = (layout) => (
        <StyledCard className="cards-section-card card-section-hidden-card" layout={layout} hidden />
    )

    const renderCardsComponents = () => {
        const numCards = props.layout
        const maxCardsInRow = CardsSectionLayoutType.four_cards.num_cards
        const maxCardsStaticWidthEnum = CardsSectionLayoutType.four_cards
        const layout = props.sameCardSizeWidth ? maxCardsStaticWidthEnum.value : props.layout
        let renderedCardsRows = []
        let renderedCards = []
        let numCardsInRow = 0

        for (let i = 0; i < numCards; i++) {
            renderedCards.push(renderCard(i, layout))
            numCardsInRow++
            if (numCardsInRow == maxCardsInRow) {
                renderedCardsRows.push(
                    <StyledCardsWrapper useOrgDesign={props.useOrgDesign}>{renderedCards}</StyledCardsWrapper>,
                )
                renderedCards = []
                numCardsInRow = 0
            }
        }

        if (numCardsInRow > 0) {
            // If each card's width should be set as if the widget should fit 4 cards total, add a hidden card until 4 cards total
            // This is not relevant for the custom data widget
            if (props.sameCardSizeWidth) {
                for (let i = numCardsInRow; i < maxCardsStaticWidthEnum.num_cards; i++) {
                    renderedCards.push(renderHiddenCard(layout))
                }
            }
            renderedCardsRows.push(
                <StyledCardsWrapper useOrgDesign={props.useOrgDesign}>{renderedCards}</StyledCardsWrapper>,
            )
        }

        return renderedCardsRows
    }

    if (props.loading) {
        return null
    }

    return (
        <WidgetStyleWrapper className="cards-section-widget" md={props.colWidth} useWidgetStyling={false}>
            <StyledBackground
                className="cards-section-background"
                useBackgroundColor={props.useBackgroundColor}
                backgroundColor={props.backgroundColor}
                isFirst={props.isFirst}
                isLast={props.isLast}
                nextWidget={props.nextWidget}
            >
                {props.title && (
                    <StyledTitle
                        className="cards-section-title"
                        useBackgroundColor={props.useBackgroundColor}
                        useOrgDesign={props.useOrgDesign}
                    >
                        {props.title}
                    </StyledTitle>
                )}
                {renderCardsComponents(props)}
            </StyledBackground>
        </WidgetStyleWrapper>
    )
}

CardsSectionWidget.propTypes = {
    backgroundColor: PropTypes.string,
    cardColor: PropTypes.string,
    cards: PropTypes.object.isRequired,
    colWidth: PropTypes.number,
    data: PropTypes.object,
    isClickableCardArray: PropTypes.array,
    isFirst: PropTypes.bool.isRequired,
    isLast: PropTypes.bool.isRequired,
    isSingle: PropTypes.bool,
    layout: PropTypes.number.isRequired,
    nextWidget: PropTypes.number,
    onClickCardArray: PropTypes.array,
    sameCardSizeWidth: PropTypes.bool,
    showCardImageArray: PropTypes.array,
    showCardImgArray: PropTypes.array,
    title: PropTypes.string,
    useBackgroundColor: PropTypes.bool,
    useCardColor: PropTypes.bool,
    useOrgDesign: PropTypes.bool,
}

export default CardsSectionWidget

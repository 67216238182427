//

// vendor modules
import { fromJS, Set, List, Map } from "immutable"

/** ****************************************************************************
 * COMBINE REDUCERS WITHOUT SPLITTING THE STORE
 *
 * this function is used to incorporate both the shared reducer and our specific
 * web app reducer without partioning the store state. The function is a wrapper
 * reducer, which iterates through its children reducers, giving each child reducer
 * the dispatched action. The child reducer will return a modified state, which
 * will be passed onto the next child, etc, etc. Because action types are unique,
 * each iteration through inputReducers should trigger at most one event.
 ****************************************************************************** */

export default function flatCombineReducers(inputReducers, initState) {
    return (prevState = initState, action) =>
        inputReducers.reduce((state, reducer) => reducer(state, action), prevState)
}

import React from "react"
import PropTypes from "prop-types"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import { StyledTextWidget } from "QuorumGrassroots/widgets/Text/components/style"

const TextWidget = (props) => (
    <WidgetStyleWrapper md={props.colWidth} useWidgetStyling={props.useWidgetStyling} title={props.title}>
        {props.useLargeText ? (
            <StyledTextWidget className="text-widget" dangerouslySetInnerHTML={{ __html: props.content }} />
        ) : (
            <div className="text-widget" dangerouslySetInnerHTML={{ __html: props.content }} />
        )}
    </WidgetStyleWrapper>
)

TextWidget.propTypes = {
    content: PropTypes.string.isRequired,
    title: PropTypes.string,
}

TextWidget.defaultProps = {
    useWidgetStyling: true,
    useLargeText: true,
}

export default TextWidget

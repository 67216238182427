import styled, { css } from 'styled-components'


export const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 5px;

    & > * {
        ${({ isSingleRow, percentMinWidth }) =>
            isSingleRow
                ? css`
                    margin-right: 18px;
                `
                : css`
                    flex: 1 0;
                    flex-basis: auto;
                    margin: 5.5px;
                    min-width: calc(${percentMinWidth}% - 11px);
                `
        }

        button {
            text-align: left;
            min-width: calc(${({ percentMinWidth }) => percentMinWidth}% - 10px);
            width: 100%;
        }

    }
`

/* eslint-disable */

import {
    DOMAIN_NAME,
    MEDIA_PATH,
    STATIC_PATH,
    AVAILABLE_TO_EVERYONE,
    READ_LIMITED_TO_TEAMS,
    READ_JUST_ME,
    SEGUE_TO_DOCUMENTS_TEXT,
    FEDERAL_SEAL_AVATAR_PATH,
    SEAL_AVATAR_PATH,
    MAP_TRANSACTION_SUMMARY_KEY_TO_LABEL,
    CURRENT_YEAR,
    TRANSACTION_SUMMARY_KEY_ORDER,
} from "shared/imports/sharedConstants"
import { removeHTML } from "shared/imports/regex"
import { createSelector } from "reselect"
import moment from "moment"
import momentTimezone from "moment-timezone"
import createCachedSelector from "re-reselect"
import { fromJS } from "immutable"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"
import { checkPermission, checkHasPermission } from "shared/imports/permissionFunctions"
import { parseCustomFieldHelper } from "shared/customFields/constants/helperFunctions"
import { interpolateRgbBasis } from "d3-interpolate"
import { schemePurples, schemeBlues } from "d3-scale-chromatic"
import debounce from "lodash.debounce"
/**
 *
 * This file contains a bunch of helper functions - for quick reference they are listed here:
 *
 * helper functions:
 *   - createToJsSelector
 *   - getPath
 *   - escapeRegExp
 *   - compose
 *   - pip
 *   - expand
 *   - ref_assign
 *   - deepCopy
 *   - lineBreaks
 *   - simpleLineBreak
 *   - time rounding
 *   - formatting time strings
 *   - openChatWindow and catching an error if it fails
 *   - generateStaticUrl
 *   - calculateTextWith
 *   - getErrorResponseText
 *   - humanFileSize - convert size to human readable size (53123432 to 53 MB)
 *   - getAttr -
 *   - prependAppName - Append the app name to an object of action types
 *   - formatNumber - Round a number and change it to a short form for display (1347632109 -> 1B)
 */

/**
 * Selector that remembers a toJS call
 *
 * @name createToJsSelector
 * @function
 * @param {Selector} selector - selector that returns an immutable object
 * @returns {Object} returns a JS object
 */
export const createToJsSelector = (selector, defaultVal = undefined) =>
    createSelector(selector, (selection) => (selection && selection.toJS ? selection.toJS() : defaultVal))

/**
 * Helper function to create a selector that returns a selection or its toJS version
 * (ie returns a non-immutable value). This is useful for when needing to call
 * toJS on a value before sending it as part of a POST / PATCH request
 *
 * if the selection doesn't exist, it will return the default value
 *
 * @name createToJsSelector
 * @function
 * @param {Selector} selector - selector that returns a potentially .toJS-able object
 * @param {any} defaultVal
 * @returns {Object} returns a non-immutable version of the selector
 */
export const makeJsIfExistsSelector = (selector, defaultVal = undefined) =>
    createSelector(selector, (selection) => (selection && selection.toJS ? selection.toJS() : selection || defaultVal))

export const createIdsFromResourceSelector = (selector, resource) =>
    createSelector(selector, (selection) =>
        selection ? selection.map((uri) => parseInt(resource.idFromResourceUri(uri))).toJS() : [],
    )

/**
 * Selector that selects a field from a _extra field
 *
 * @name createExtraFieldSelector
 * @function
 * @param {Selector} objectSelector - selector that returns the object that contains
 the _extra dict
 * @param {string} key - the key to get from the selector
 * @returns {any} returns the value in the _extra field
 */
export const createExtraFieldSelector = ({ key, objectSelector, defaultVal = undefined }) =>
    createSelector(objectSelector("_extra"), (extra) => (extra && extra.get(key)) || defaultVal)

/**
 * Compose function variadic
 *   ex: (f1, f2, f3, f4…) => value => f1( f2(f3(f4(value) )))
 *   the first function f4 can take in anything
 *   https://medium.com/@dtipson/creating-an-es6ish-compose-in-javascript-ac580b95104a
 *
 * @param {Array[Function]} ...fns list of functions, applied right to left
 * @returns {Function} function that takes in args and returns a single thing
 */
export const compose = (...fns) =>
    fns.reduce(
        (f, g) =>
            (...args) =>
                f(g(...args)),
    )

/**
 * pipe function variadic
 *   ex: (f1, f2, f3, f4…) => value => f4( f3(f2(f1(value) )))
 *   the first function f1 can take in anything
 *   https://medium.com/@dtipson/creating-an-es6ish-compose-in-javascript-ac580b95104a
 *
 * @param {list of functions} ...fns list of functions, applied left to right
 * @returns {Function} function that takes in args and returns a single thing
 */
export const pipe =
    (firstFn, ...fns) =>
    (...args) =>
        fns.reduce((acc, fn) => fn(acc), firstFn(...args))

// PLEASE DO NOT USE THIS FUNCTION. USE the npm module randomstring
/**
 * RandomString generator
 *
 * @param {Num} length=8 default 8 (length of string)
 * @returns {String} random string
 */
export function makeId(length = 8) {
    let text = ""
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

    for (let i = 0; i < length; i += 1) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text
}

/**
 * function to return the list of lists of matches - match all returns an iterator
 *
 * @param {RegExp} regex regex
 * @param {String} string you want to match
 * @returns {Array} returns an array of arrays
 */
export function getAllMatches(regex, string) {
    const matches = []
    // eslint-disable-next-line no-restricted-syntax
    for (const match of string.matchAll(regex)) {
        if (match) {
            matches.push(match)
        }
    }
    return matches
}

/**
 * function to return all the matching groups for a regex
 * emulates .matchAll() for regexes, need it because some npm versions below 12
 * don't support this functionality and it may break tests
 *
 * see https://stackoverflow.com/questions/6323417/regex-to-extract-all-matches-from-string-using-regexp-exec
 *
 * @param {RegExp} regex regex
 * @param {String} string you want to match
 * @returns {Array} returns an array of matches from string.match(regex)
 */
export function findAll(regexPattern, string) {
    let output = []
    let match
    // make sure the pattern has the global flag
    let regexPatternWithGlobal = RegExp(regexPattern, "g")
    while ((match = regexPatternWithGlobal.exec(string))) {
        // get rid of the string copy
        delete match.input
        // store the match data
        output.push(match)
    }
    return output
}

// rename a key in an object and delete the old key
export function renameProperty(obj, oldProperty, newProperty) {
    const newObj = obj
    // access the value
    const value = newObj[oldProperty]
    // if we have the value rename and delete, otherwise return
    if (value) {
        newObj[newProperty] = value
        delete newObj[oldProperty]
        return newObj
    } else {
        return newObj
    }
}

// helper function to take flat array and convert it to array of arrays
// with a specified number of elements in each subarray
export function expand(elementsPerSubArray, arrayToExpand) {
    function helpExpand(currentArr, nextVal) {
        // grab the array at the back of our current array
        const lastArr = currentArr.pop()

        // if it is defined (not just the empty array)
        if (lastArr) {
            // see if this array is already full
            if (lastArr.length < elementsPerSubArray) {
                // add the next value to this subarray and put it back
                // into the larger arrary
                lastArr.push(nextVal)
                currentArr.push(lastArr)
            } else {
                // if the last array is full, put it back and start the
                // next array with the nextVal
                currentArr.push(lastArr)
                currentArr.push([nextVal])
            }
        } else {
            // in this case its the first element, just add it
            currentArr.push([nextVal])
        }

        return currentArr
    }

    return arrayToExpand.reduce(helpExpand, [])
}

export function ref_assign(refs, ...kvps) {
    return Object.assign({}, refs, ...kvps)
}

export function deepCopy(obj) {
    // Immediately return for primitive values (Includes undefined and null)
    if (typeof obj !== "object" || obj === null) {
        return obj
    }

    // Recursively call deepCopy on elements in arrays
    if (obj.length >= 0) {
        return obj.reduce((currentArray, value) => {
            return currentArray.concat(deepCopy(value))
        }, [])
    }

    // Recursively call deepCopy for entries in objects
    return Object.entries(obj).reduce((currentDict, [key, value]) => {
        currentDict[key] = deepCopy(value)
        return currentDict
    }, {})
}

/**
 * function to replace new lines and carriage returns with breaks,
 * empty lines with new paragraphs,
 * and the entire block in a span
 * so that text can be rendered as HTML.
 * (used primarily in outbox)
 *
 * @param {String} text the raw html/string that contains newline chars
 * @returns {String} the constructed html or a blank string if no text was provided
 */
export function lineBreaks(text) {
    if (text) {
        let html = text
            .replace(/\r?\n([ \t]*\r?\n)+/g, "</p><p>")
            .replace(/\r\n/g, "<br />")
            .replace(/\n/g, "<br />")
        html = `<span>${html}</span>`
        return html
    }
    return ""
}

/**
 * function to replace new lines and carriage returns with breaks so that text can be rendered as HTML
 * (used primarily in search)
 *
 * @param {String} text the raw html/string that contains newline chars
 * @returns {String} the constructed html or a blank string if no text was provided
 */
export function simpleLineBreak(text) {
    if (text) {
        let html = text.replace(/\r?\n/g, "<br />")
        html = `${html}`
        return html
    }
    return ""
}

export const simpleLineBreaks = (text) => (text ? text.replace(/\r?\n/g, "<br /><br />") : "")

/**
 * Function to remove <p> tags and replace </p> tags with line breaks (<br/>);
 * used to move text out of block-level elements for styling purposes
 *
 * @param {String} text the raw html/string that contains <p> tags
 * @returns {String} the constructed html or a blank string if no text was provided
 */
export const htmlParagraphToLineBreaks = (text) =>
    text &&
    text
        .replace(/<p>/g, "")
        .replace(/<\/p>/g, "<br/><br/>")
        .replace(/<p([ ]*)\/>/g, "<br/>")

/**
 * Function to remove tables (<ul>s with <li>s) and replace them with text;
 * used to move text out of block-level elements for styling purposes
 *
 * @param {String} text the raw html/string that contains <ul> and <li> tags
 * @returns {String} the constructed html or a blank string if no text was provided
 */
export const htmlTablesToText = (text) =>
    text &&
    text
        // replace paragraph close / table start sequences to avoid over-spacing
        .replace(/((<\/p>)( )*)*(<ul>)/g, "<br/>")
        // replace table closes with line breaks
        .replace(/(<\/ul>)/g, "<br/><br/>")
        // replace line items with indented bullet points
        .replace(/<li>/g, "<br/>\t\u2022  ")
        // remove line item closing tags
        .replace(/<\/li>/g, "")

// function  roundMomentUpToHour
// takes: m, Moment.js object
// returns: Moment object that rounds up to the nearest hour. For example, 1:32 AM
// rounds to 2 AM, 6:38 PM rounds to 7 PM.
export const roundMomentUpToHour = (m) => {
    const roundRes = m.minute() || m.second() || m.millisecond() ? m.add(1, "hour").startOf("hour") : m.startOf("hour")
    return roundRes
}

// convert a base64 encoded object into a Blob url
export const b64toBlobUrl = (b64Data, contentType = "", sliceSize = 512) => {
    // decode the b64 data
    const byteCharacters = atob(b64Data)
    const byteArrays = []
    // process the data by slices of 512
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        // get the next slice of the data
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i += 1) {
            // convert the data into a UTF-16 byte value
            byteNumbers[i] = slice.charCodeAt(i)
        }
        // convert byte values into a typed byte array
        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
    }
    // create a data blob using the bytearray.
    // see: https://developer.mozilla.org/en-US/docs/Web/API/Blob
    const blob = new Blob(byteArrays, { type: contentType })
    // create a local url using the data in the blob
    const blobUrl = URL.createObjectURL(blob)

    return blobUrl
}

// determines if two arrays contain the same data
export function equivalentArrays(a, b) {
    // simple check for length
    if (a.length !== b.length) {
        return false
    }
    // check against each value
    return a.every((item, idx) => item === b[idx])
}

export const getCreatedDate = (date) => {
    const created = new Date(date)
    const day = created.getDate()
    const month = created.getMonth() + 1
    const year = created.getFullYear()
    return `${month}/${day}/${year}`
}

/**
 * Converts a date held inside of a general profile state, into a readable date string.
 * @param   {date} Info from https://javascript.info/date
        The string format should be: YYYY-MM-DDTHH:mm:ss.sssZ, where:
        YYYY-MM-DD – is the date: year-month-day.
        The character "T" is used as the delimiter.
        HH:mm:ss.sss – is the time: hours, minutes, seconds and milliseconds.
        The optional 'Z' part denotes the time zone in the format +-hh:mm. A single letter Z that would mean UTC+0.
 *
 * @returns {string} the date string is converted into a standard datetime format
        eg 2018-01-08T10:25:37.848445 => January 8th, 2018 10:25 am
 */
export const parseDateTime = (date) => {
    return toDateTimeString(date, null, false, false, false, Userdata.has_international_region, true)
}

// converts a date of format YYYYY-MM-DD to a string.
// eg 2017-01-01 => July 1, 2017
export function parseDate(rawDate) {
    return toDateTimeString(rawDate, null, true, false, false, Userdata.has_international_region)
}

// converts date format
// => M/D/YY (ex. 7/1/20) in US
// => D/M/YY everywhere else
export const parseDateToMDYY = (rawDate, isOutsideOfUs) => {
    const DATE_REGEX = /\d{4}\-\d{2}\-\d{2}/
    const EU_DATE_FORMAT = "D/M/YY"
    const US_DATE_FORMAT = "M/D/YY"

    const dateMatches = rawDate.match(DATE_REGEX)
    const dateString = dateMatches ? dateMatches[0] : rawDate
    const dateFormat = isOutsideOfUs ? EU_DATE_FORMAT : US_DATE_FORMAT

    return moment(dateString).format(dateFormat)
}

export const parseDateFromSelector = (selector) => createSelector(selector, (item) => item && parseDate(item))

/**
 * Check if the current date/time is in daylight savings. Specify the east coast because in the test environment,
 * `moment()` will default to 'UTC' time, where `isDST` is always false.
 * @returns {boolean} - Boolean specifying if it is daylight savings
 */
export const checkDaylightSavings = () => momentTimezone().tz("America/New_York").isDST()

/**
 * Given a Timezones enum value (default to Eastern), return the 'daylight_savings_offset' or 'standard_offset',
 * depending if we are currently in daylight savings or not
 * If the offset is positive, append a '+' to it
 * @param   {string} name - The 'name' of the timezone, e.g. 'US/Eastern'
 * @returns {string} - Timezone abbreviation, e.g. 'EST'
 */
export const parseTimezoneOffsetFromValue = (timezoneValue) => {
    const { Timezones } = DjangIO.app.userdata.types
    const timezoneEnum = Timezones.by_value(timezoneValue) || Timezones.eastern

    const isDaylightSavings = checkDaylightSavings()

    const offset = isDaylightSavings ? timezoneEnum.daylight_savings_offset : timezoneEnum.standard_offset

    return `${offset >= 0 ? "+" : ""}${offset}`
}

/**
 * Given a Timezones enum value, return the 'pytz' field, or timezone 'name'
 * Default to 'US/Eastern'
 * @param   {string} name - The 'name' of the timezone, e.g. 'US/Eastern'
 * @returns {string} - Timezone abbreviation, e.g. 'EST'
 */
export const parseTimezoneNameFromValue = (timezoneValue) => {
    const { Timezones } = DjangIO.app.userdata.types
    const timezoneEnum = Timezones.by_value(timezoneValue) || Timezones.eastern

    return timezoneEnum.pytz
}

/**
 * Given a timezone 'name', return the timezone abbreviation
 * Default to 'EST'
 * @param   {string} name - The 'name' of the timezone, e.g. 'US/Eastern'
 * @returns {string} - Timezone abbreviation, e.g. 'EST'
 */
export const parseTimezoneAbbrevFromName = (name = "US/Eastern") => momentTimezone().tz(name).zoneAbbr()

/**
 * Given a timezone abbreviation, return the timezone format used for moment
 * Default to "America/New_York"
 * Timezone abbreviations are provided by the Region model
 * To filter for more timezone regions, use moment.tz.names() for reference.
 * Ex. moment.tz.names().filter(time => moment().tz(time).zoneAbbr() === "EDT")
 *
 * @param   {string} abbrev - The 'abbrev' of the timezone, e.g. 'EST'
 * @returns {string} - Timezone name, e.g. "America/New_York"
 */
export const parseTimezoneNameFromAbbrev = (abbrev = "EST") => {
    const timezoneAbbrevConversion = {
        SAST: "Asia/Riyadh",
        AST: "America/Puerto_Rico",
        EST: "America/New_York",
        CST: "America/Chicago",
        MST: "America/Denver",
        PST: "America/Los_Angeles",
        AKST: "America/Anchorage",
        HST: "America/Atka",
        HAST: "America/New_York",
        SST: "US/Samoa",
        CET: "Africa/Algiers",
    }
    return timezoneAbbrevConversion[abbrev]
}

/**
 * Given an EST datetime string and desired timezone name, return a moment object in the selected timezone
 * @param   {string} timeStr - The datetime represented as a string
 * @param   {string} timeZone - The 'name' of the timezone, e.g. 'US/Eastern' or 'America/New_York'
 * @param   {string} eventTimezone - The timezone of the event in the case when the event is not EST e.g. 'America/Los_Angeles'
 * @returns {object} - A moment object in the selected timezone
 */
export const parseEstDateToTimezone = (timeStr, timeZone, eventTimezone = "America/New_York") => {
    return momentTimezone.tz(timeStr, eventTimezone).tz(timeZone)
}

/**
 * Given an EST datetime string, return a moment object in the local timezone (as specified in user's computer/browser preferences)
 * @param   {string} timeStr - The datetime represented as a string
 * @returns {object} - A moment object in the selected timezone
 */
export const parseEstDateToLocal = (timeStr) => {
    const localTimeZone = momentTimezone.tz.guess()
    return parseEstDateToTimezone(timeStr, localTimeZone)
}

// converts a string time to a human-readable time. If there is specifically no time,
// (timeStr is None), return TBD. If the event is an all day event, show only the day,
// not the time.
export const toDateTimeString = (
    timeStr,
    timezoneRegion,
    isAllDay = false,
    convertToLocalTimeZone = false,
    timeOnly = false,
    useInternationalFormat = false,
    ordinalDate = false,
) => {
    // return undefined if timeStr is undefined
    // moment(undefined) defaults to use now as the time, which is undesirable
    if (!timeStr) {
        return undefined
    }

    const d = convertToLocalTimeZone ? parseEstDateToLocal(timeStr) : moment(timeStr)

    if (d.isValid()) {
        var format_string = ""

        // only include the date if we don't want only the time
        if (!timeOnly) {
            // format the date layout correctly
            if (useInternationalFormat) {
                // we are in the EU; use the EU date format
                format_string += "D MMMM YYYY"
            } else {
                // not in EU; use US format
                if (!ordinalDate) {
                    format_string += "MMMM D, YYYY"
                } else {
                    format_string += "MMMM Do, YYYY"
                }
            }
        }

        // add the time if this event is not all day
        if (!isAllDay) {
            if (!timeOnly) {
                format_string += " [at] "
            }
            format_string += "h:mm A"
        } else if (timeOnly) {
            // if timeOnly and isAllDay, there is nothing to return
            return undefined
        }

        // add the timezone if we need it and we have it
        // can only add a timezone if we have a region
        if (timezoneRegion) {
            return `${d.format(format_string)} ${DjangIO.app.models.Region.by_value(timezoneRegion).timezone}`
        }

        return `${d.format(format_string)}`
    } else if (!timeStr) {
        // old version of this method did this
        return "TBD"
    }

    // there is a time string provided, but it isn't valid
    // can't be parsed
    return undefined
}

/**
 * Given an EST datetime string and desired timezone value, return a formatted date/time string in the selected timezone
 * @param   {string} timeStr - The datetime in EST represented as a string
 * @param   {string} timezoneValue - The value of the Timezones enum
 * @returns {string} - The datetime string at a the given timezone, ex: 'May 29, 2019 at 9:00 PM PST'
 */
export const displayTimeInChosenTimezone = (timeStr, timezoneValue) => {
    if (!timeStr) {
        return ""
    }

    // Convert EST time string to specified timezone and format, e.g. 'December 9, 2019 at 2:00 PM'
    const timezoneName = parseTimezoneNameFromValue(timezoneValue)
    const formattedTimeStr = toDateTimeString(parseEstDateToTimezone(timeStr, timezoneName))

    // Get timezone abbreviation, e.g. 'EST'
    const timezoneAbbreviation = parseTimezoneAbbrevFromName(timezoneName)

    return `${formattedTimeStr} ${timezoneAbbreviation}`
}

/**
 * Given a start and end datetime string and desired timezone value, return a formatted date/time string
 * @param   {string} start - start datetime in EST represented as a string
 * @param   {string} end - end datetime in EST represented as a string
 * @param   {string} timezoneValue - The value of the Timezones enum
 * @returns {string} - The datetime string at a the given timezone, ex: '5/29/19 9:00PM'
 */
export const displayStartAndEndTime = (start, end, timezoneValue) => {
    if (!start) {
        return ""
    }
    // Convert EST time string to specified timezone and format, e.g. 'December 9, 2019 at 2:00 PM'
    const timezoneName = parseTimezoneNameFromValue(timezoneValue)
    const formattedStartTimeStr = parseEstDateToTimezone(start, timezoneName).format("M/D/YY h:mma")
    const formattedEndTimeStr = end ? parseEstDateToTimezone(end, timezoneName).format("M/D/YY h:mma") : "TBD"

    return `${formattedStartTimeStr} - ${formattedEndTimeStr}`
}

// converts a date string from YYYY-MM-DD to the MM/DD/YYYY format used on the site
// returns undefined if dateString is not in correct form (string of YYYY-DD-MM)
// (used by DatePicker)
//
// In /sheets/ and other powersearch uses, the dateString argument can have Timezone appended to the date.
// This results into an erroneously formatted where the entire timezone argument is appended to the second block.
// Examples:
// dateString: "2023-08-14T00:00:00.000Z"
// return: "08/14T00:00:00.000Z/2023"
//
// The solution with the timezone regex matches the entire timezone block and removes it from the date.
// 2023-08-14T00:00:00.000Z turns into 2023-08-14 and then the function proceeds as expected.
export const dateStringToDisplayFormat = (dateString) => {
    if (null === dateString) {
        return undefined
    }

    const timezone_regex = /(T\d+:\d+:\d+.\d+Z)/
    let filteredDateString = dateString

    if (timezone_regex.test(filteredDateString)) {
        filteredDateString = filteredDateString.replace(timezone_regex, "")
    }

    const dateArray = filteredDateString.split("-")
    if (!dateArray || dateArray.length !== 3) {
        return undefined
    }
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`
}

// converts date string in display format (MM/DD/YYYY) into a date
// (used by DatePickerFilter in Filters)
export const displayFormatToDate = (displayedDateString) => {
    const dateArray = displayedDateString.split("-")
    return new Date(dateArray[0], dateArray[1] - 1, dateArray[2])
}

// converts a space separated phrase into titlecase
export const toTitleCase = (str) =>
    str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())

// This fakes the path - see
// https://github.com/DieterHolvoet/event-propagation-path
export const getPath = (e) => {
    if (!e) {
        return []
    } else if (e.path) {
        return e.path
    } else if (e.composedPath) {
        return e.composedPath()
    } else if (!e.target || !e.target.parentElement) {
        return []
    }
    let element = e.target
    const pathArray = [element]
    while (element.parentElement !== null) {
        element = element.parentElement
        pathArray.unshift(element)
    }
    return pathArray
}

/**
 * Given an organization, get the permissioned persona options for selects
 * This function is in a helper function file so we can set the default options when creating a new user
 * @param {Object} organzation - organization object
 * @returns {Array[Object]} - persona options that are permitted for the user
 */
export const getPersonaOptions = (organization) => {
    // get all of the hydrated Persona Enums
    let options = DjangIO.app.userdata.models.Persona.items().sort(DjangIO.valueSort)

    // filter out the options that the user's organization doesn't have access to
    return options
        .filter((option) =>
            checkPermission(DjangIO.app.models.PermissionType.by_value(option.permission_enum), {
                permissions: organization,
                regions: DjangIO.app.models.Region.items().map((region) => region.value),
            }),
        )
        .filter((option) => !option.default)
}

/**
 * Get filtered UserPersona options based on if a QuorumUser has the feature permissions
 * listed in the individual UserPersona's permissions=[] list (see app/userdata/types.py).
 * @name getUserPersonaOptions
 * @returns {Array[Object]} - filtered UserPersona options with their summary
 * included under the label key to use in selects.
 */
export const getUserPersonaOptions = () => {
    // get all User Persona Enums
    const allUserPersonas = DjangIO.app.userdata.types.UserPersona.items().sort(DjangIO.valueSort)

    const shiftedPersona = allUserPersonas.shift()

    allUserPersonas.push(shiftedPersona)

    // filter out the options that the user's organization doesn't have access to
    // check permissions based on all regions, not based on user's current region
    return allUserPersonas
        .filter((persona) => {
            const permissions = persona.permissions.map((permission) =>
                DjangIO.app.models.PermissionType.by_value(permission),
            )
            return checkHasPermission(permissions, {
                regions: DjangIO.app.models.Region.items().map((item) => item.value),
            })
        })
        .map((persona) => ({
            ...persona,
            label: persona.summary,
        }))
}

/**
 * Function that escapes regex chars
 * - this is useful if you are taking in user input to make a regex
 * - source: https://stackoverflow.com/a/6969486/4010168
 *
 * @name escapeRegExp
 * @function
 * @param {String} str - user string to be make regex safe
 * @returns {String} the regex safe string
 */

const escapeRegex = new RegExp(/[.*+?^${}()|[\]\\]/g)
export const escapeRegExp = (str) => str.replace(escapeRegex, "\\$&")

// Function that creates the updated dictionary when patching in forms.
// Used in Event Form, Organization Form, and Supporter Form action creators.
// Converts undefined values to Null values, because otherwise there cannot
// delete a foreign key / existing object.
export function updateDictUndefinedToNull(fieldArr, data) {
    return fieldArr.reduce((currentDict, fieldName) => {
        // if something is undefined, we need to change it to null or else
        // it won't get patched (JSON Stringify does not accept undefined)
        currentDict[fieldName] = data.get(fieldName) !== undefined ? data.get(fieldName) : null
        return currentDict
    }, {})
}

// Searchify returns an object of filters.
// Not all systems in Quorum appropriately serialize
// this object before sending it to the API, resulting
// in errors where strings like [object Object] are passed
// to Tastypie. This function finds all object-like values
// in filter dicts and stringifies them so they can be passed
// to the backend without error.
export function cleanFilters(filters = {}) {
    // don't pass an array to this function!
    if (Array.isArray(filters)) {
        console.error("Do not pass an array to the cleanFilters function.")
        return filters
    }

    return Object.entries(filters).reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
            // if value is an array, then use .join(',') to make it a comma
            // separated string cannot use JSON.stringify for this case, since
            // it leaves in the braces '[]'
            value = value.join(",")
        } else if (typeof value === "object" && !value.length) {
            value = JSON.stringify(value)
        }
        acc[key] = value
        return acc
    }, {})
}

/**
 * This function determines if we should exclude stripping the subdomain and replacing with our S3 path
 * Some images come from external sources, such as fullcontact, and it useful to let these urls pass through
 * @param {String} mediaUrl - the static asset
 * @returns {Boolean} - whether we should let this url be
 */
const shouldExcludeMediaUrlParsing = (mediaUrl) => {
    if (!mediaUrl) {
        return false
    }
    return mediaUrl.startsWith("https://d2ojpxxtu63wzl.cloudfront.net/static/")
}

/**
 * Generate the appropriate S3 path, given a path and a prefix
 * Used by generateStaticUrl and generateMediaUrl
 * @param {String} path - a path to a file in an S3 bucket
 * @param {String} prefix - the cloudfront or s3 path to the appropriate bucket
 * @returns {String} - formatted url, prepended by prefix
 */
export const generateUrl = (path, prefix) => {
    if (path) {
        // split the url by slashes
        const urlPieces = path.split("/")
        let newPath = ""

        // remove http:, https:, and empty strings, then,
        // remove the first element if it contains ".us" or ".com" (i.e. if it's a domain), and finally,
        // remove the /static/ at the beginning
        newPath = urlPieces
            .filter((piece) => !["http:", "https:"].includes(piece) && piece.length)
            .filter(
                (piece, index) =>
                    !(
                        index === 0 &&
                        (piece.includes(".com") ||
                            piece.includes(".us") ||
                            piece.includes(".net") ||
                            piece.includes(".eu"))
                    ),
            )
            .filter((piece, index) => !(index === 0 && piece === "static"))
            .filter((piece) => !(piece === "global")) // remove global path if it exists
            .filter((piece) => !(piece === "media")) // remove media path if it exists
            .join("/")

        // replace all double slashes with a single slash, just in case
        newPath = newPath.replace("//", "/")

        // for now, replace all instances of "img" with "images"
        newPath = newPath.replace("img/", "common/images/")

        // add in the prefix
        newPath = prefix + newPath

        // encode the URL to ensure that it is URL-safe
        newPath = encodeURI(newPath)

        return newPath
    } else {
        return ""
    }
}

/**
 * This function parses media urls and attempts to convert the urls to the new cloudfront powered S3 bucket format
 * All media urls used on the frontend should pass through this function
 * @param {String} mediaUrl - the static url, which can be formatted in the following ways:
 * @returns {String} - formatted url, prepended by the STATIC_PATH
 * See Test Cases for examples
 * Static paths cannot contain the following paths:
 * - global
 * - static
 * - media
 * - img (at least by itself. It can be included as .../some-img-location/...)
 *
 */
export const generateMediaUrl = (mediaUrl) => {
    if (shouldExcludeMediaUrlParsing(mediaUrl)) {
        return mediaUrl
    }
    return generateUrl(mediaUrl, `${MEDIA_PATH}/media/`)
}

/**
 * This function parses static urls and attempts to convert the urls to the new cloudfront powered S3 bucket format
 * All urls used on the frontend should pass through this function
 * @param {String} staticUrl - the static url, which can be formatted in the following ways:
 *                           /static/img/member-images/large/A000360.jpg
 *                           https://static.quorum.us/img/member-images/large/A000360.jpg
 *                           https://quorum-static.s3.amazonaws.com/static/img/member-images/large/A000360.jpg
 * @returns {String} - formatted url, prepended by the STATIC_PATH
 * See Test Cases for examples
 * Static paths cannot contain the following paths:
 * - global
 * - static
 * - media
 * - img (at least by itself. It can be included as .../some-img-location/...)
 */
export const generateStaticUrl = (staticUrl) => generateUrl(staticUrl, STATIC_PATH)

/*
 * This function is used to divide a group of terms into
 * {totalColumns} evenly sized lists
 * terms - The array to divide
 * totalColumns - The number of columns to divide the terms into
 * property - The property to sort by **Optional (default term.length)
 *
 * Return: Array of {totalColumns} arrays, each indicating a column
 */

export const divideTerms = (terms, totalColumns, property) => {
    const returnColumns = []
    let sorted = []
    if (property) {
        sorted = terms.sort((a, b) => b.get(property).length - a.get(property).length)
    } else {
        sorted = terms.sort((a, b) => b.length - a.length)
    }

    for (let i = 0; i < totalColumns; i += 1) {
        returnColumns.push(
            sorted.filter((val, index) => {
                // Rows alternate odd/even
                if (Math.floor(index / totalColumns) % 2 === 0) {
                    return index % totalColumns === i
                } else {
                    return index % totalColumns === totalColumns - 1 - i
                }
            }),
        )
    }
    return returnColumns
}

/**
 * This function formats custom data values for percentages
 * which intend to show only 1 decimal always.
 */
export const formatPercentageForDisplay = (rawNumber, shouldRoundCellValue = false) => {
    let number

    number = Number(rawNumber).toFixed(1)
    if (shouldRoundCellValue) {
        number = Math.round(number)
    }
    return `${number}%`
}

/**
 * This function takes in a number and abbreviates it for display.
 * (This is used by many d3 graphs to format number for axis ticks.)
 *
 * Note: Expects positive numbers less than 1 Quintillion
 */
export const formatNumberForDisplay = (
    rawNumber,
    decimalValues = 1,
    threshold = 1e4,
    addTilde = false,
    substringLength = 4,
    radix = true,
) => {
    let number = rawNumber * 1
    number = +number.toFixed(decimalValues)
    if (number < threshold) {
        return number.toLocaleString()
    }

    const roundUpLastStringDigitIfPossible = (
        num, // Add just enough to round eventual final digit up if appropriate.
    ) => num + 5 * Math.pow(10, Math.floor(Math.log10(number)) - (substringLength - 1))
    const truncateNumString = (number, symbol) => {
        let numString = number.toString().substr(0, substringLength)
        if (numString.slice(-1) === ".") {
            // To prevent edgecase of things like 100.K
            numString = numString.substr(0, substringLength - 1)
        }
        if (!radix) {
            numString = numString.replace(".", ",") // Outside US and GB, countries use , as decimals.
        }
        return `${addTilde ? "~" : ""}${numString}${symbol}`
    }

    number = roundUpLastStringDigitIfPossible(number)
    switch (true) {
        case number < 1e6:
            number /= 1e3
            return truncateNumString(number, "K")
        case number < 1e9:
            number /= 1e6
            return truncateNumString(number, "M")
        case number < 1e12:
            number /= 1e9
            return truncateNumString(number, "B")
        case number < 1e15:
            number /= 1e12
            return truncateNumString(number, "T")
        case number >= 1e15:
            number /= 1e15
            return truncateNumString(number, "Q")
    }
}

export const formatNumberForDisplayMobile = (rawNumber) => {
    let value = rawNumber * 1
    let number = value
    if (number < 10000) {
        value = number.toString().substr(0, 4)
    } else if (number < 1000000) {
        number /= 1000
        value = `${+number.toFixed(1).toString().substr(0, 4)}K`
    } else if (number < 1000000000) {
        number /= 1000000
        value = `${+number.toFixed(1).toString().substr(0, 4)}M`
    } else if (number > 1000000000) {
        number /= 1000000000
        value = `${+number.toFixed(1).toString().substr(0, 4)}B`
    }
    return value
}

export const getElementContentWidth = (elementId) => {
    /*
        A function that returns the width of an element minus its padding
        using pure javascript.
     */
    const element = document.getElementById(elementId)
    const styles = window.getComputedStyle(element)
    const padding = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight)
    return element.clientWidth - padding
}

export const truncateGraphTickLabel = (text, maxLength = 50) => {
    if (!text) {
        // if there is no text, return
        return ""
    } else if (text.length && text.length <= maxLength) {
        // if the text is shorter than the max length, return it
        return text
    } else {
        const truncatedAtColon = text.split(":")[0]
        if (truncatedAtColon.length <= maxLength) {
            return truncatedAtColon
        } else {
            return text.slice(0, maxLength)
        }
    }
}

// https://stackoverflow.com/a/15458987
export const stringContainsHTMLMarkup = (str) => /<[a-z][\s\S]*>/i.test(str)

export const stringContainsAlertMarkup = (str) =>
    /___(.*?)___/i.test(str) || /<<<(.+?)\((https:\/\/.+?)\)>>>/i.test(str)

/**
 * helper function that parses the special Alert snippet syntax
 * @param   {string} snippet the string value of the snippet
 *
 * @returns {string} a properly formatted string (`test ___word___` into `test <span class="highlighted">word</span>`)
 */
export const parseAlertSyntax = (snippet) =>
    snippet
        .replace(/___(.*?)___/g, `<span class="highlighted">$1</span>`)
        .replace(/<<<(.+?)\((https:\/\/.+?)\)>>>/g, (match, c1, c2) => {
            let extraChar = ""
            if (
                c2 &&
                // make sure we do not break external links
                c2.includes("https://www.quorum.us") &&
                // the last char is not '/' (breaks big_cache Public Organization field segue)
                c2.length &&
                c2[c2.length - 1] !== "/" &&
                // the string does not contain a query (breaks Note search segue)
                !c2.includes("?")
            ) {
                extraChar = "/"
            }
            return `<a href="${c2}${extraChar}">${c1}</a>`
        })
        .replace(/https:\/\/www.quorum.us/g, "")

/*
 * helper function shorthand for parsing alert strings that is used primarily in Inlines
 * @param   {string} input the string value of the item
 *
 * @returns {string} a properly formatted alert string
 */
export const checkParseAlertString = (input) => (stringContainsAlertMarkup(input) ? parseAlertSyntax(input) : input)

/*
 * Basic function to get the text out of HTML string. For example,
 * "<p>Hello</p>" will return "Hello." Created for the sake of checking if
 * strings are empty.
 */

export const isContentFromHTMLEmpty = (htmlString) => {
    const span = document.createElement("span")
    span.innerHTML = htmlString
    return !span.textContent
}

/*
    cleans up the HTML from a string
*/
export const cleanUpHtml = (string) => (string && typeof string === "string" ? string.replace(removeHTML, "") : "")

// replaces <br> tags with new lines for mobile
export const spaceHtmlText = (string) => string.replace(/(<br>|<br \/>)/g, "\n")

//  checks if a response is valid
export const validateResponse = (response) => (response && response.status && response.status === 200) || false

/*
 * Returns a value from the browser's cookies.
 *
 * @name readCookieByKey
 * @function
 * @param {string} cookieKey - The key used to grab the value from document.cookie
 */
export const readCookieByKey = (cookieKey, cookieString = document.cookie) => {
    // Cookie string is a semi-colon separated string with key value pairs
    // separated by an equals sign
    // ex: document.cookie = "cookieKey1=value; otherCookieKey=otherValue;"
    // convert cookie string to object
    const cookieObj = cookieString.split(";").reduce((acc, curr) => {
        // Because we have to go through response.headers['set-cookie'] on Mobile and there are multiple set-cookie headers,
        // the format of cookieString is a bit mangled. We simply split off the extra noise at the start here.
        const cookie = curr.split(", ").pop()
        const [key, val] = cookie.split("=")
        return { ...acc, [key.trim()]: val ? val.trim() : "" }
    }, {})

    // return value
    return cookieObj[cookieKey]
}

/*
 * Sets a value in the browser's cookies.
 *
 * @name createCookieByKey
 * @function
 * @param {string} key - The key that will be set in document.cookie
 * @param {string} value - The value that will be set in document.cookie
 * @param {number} minutes - The expiration date of the cookie **Optional (default undefined)
 */
export const createCookieByKey = (key, value, minutes = undefined) => {
    let expires = ""

    // If an expiration date has been set, then generate the expire string
    if (minutes) {
        const date = new Date()
        date.setTime(date.getTime() + minutes * 60 * 1000)
        expires = `; expires=${date.toGMTString()}`
    }

    // Set the cookie cookie
    document.cookie = `${key}=${value}${expires}; path=/`
}

/*
- Rules via https://en.wikipedia.org/wiki/Ordinal_indicator#English
*/
export const ordinalSuffix = (number) => {
    if (!number) {
        return ""
    }

    const mod10 = +number % 10
    const mod100 = +number % 100

    switch (true) {
        case mod10 === 1 && mod100 !== 11:
            return `${number}st`
        case mod10 === 2 && mod100 !== 12:
            return `${number}nd`
        case mod10 === 3 && mod100 !== 13:
            return `${number}rd`
        default:
            return `${number}th`
    }
}

const shouldAddEventListener = (link, urlList) => {
    const linkUrl = link.href
    // Check if the link is from the marketing site. If yes, do not attach a Segue Click handler
    const isMarketingSiteLink = Object.values(urlList).some((url) => url !== "/" && linkUrl.includes(url))
    if (isMarketingSiteLink) {
        return false
    }
    // Check if the link is to an internal page. If yes, we want to attach a Segue Click handler.
    return linkUrl.includes(DOMAIN_NAME) || linkUrl.includes("localhost:8000/")
}

export const addSegueClickHandlerToLinks = (linkSearchCriteria, segueToLink, urlList) => {
    const postLinks = document.querySelectorAll(linkSearchCriteria)

    return (
        postLinks &&
        postLinks.length &&
        postLinks.forEach(
            (link) =>
                shouldAddEventListener(link, urlList) &&
                link.addEventListener("click", (e) => {
                    // Prevents the page from reloading
                    e.preventDefault()

                    // The entire link
                    const linkUrl = link.href
                    const anchorIndex = linkUrl.indexOf("#")
                    // How many pixels we need to scroll up so the frame doesn't block any information
                    const frameHeight = 90

                    // Checks if the url includes a "#", and checks if there is an anchor with that id on the page. If so, scroll to it.
                    if (anchorIndex > -1 && $(linkUrl.slice(anchorIndex)).offset()) {
                        window.scrollTo(0, $(linkUrl.slice(anchorIndex)).offset().top - frameHeight)
                    }

                    // If the link is to a different page, segue to it
                    if (window.location.href !== linkUrl.slice(0, anchorIndex)) {
                        segueToLink(linkUrl)
                    }
                }),
        )
    )
}

export const makeShortCode = (title) =>
    // Getting a website's title like "To change, or fix, website's url!" and
    //      converting it to a well-formatted short code like "to-change-or-fix-websites"
    title
        // Replace special characters, leave only the alphanumeriic characters
        .replace(/(?!\w|\s)./g, "")
        // Get first 5 words of title, separate them with '-'
        .split(" ")
        .slice(0, 5)
        .join("-")
        .toLowerCase()

export const createCacheKey = (key) => `${key}Cache`

// Credit to https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
export const humanFileSize = (size) => {
    // determine which unit [kB, MB, GB, etc.]
    const logNum = Math.floor(Math.log(size) / Math.log(1024))
    // Divide and round to 1 decimal place
    const roundedSize = (size / 1024 ** logNum).toFixed(1)
    // Add unit
    return `${roundedSize} ${["B", "kB", "MB", "GB", "TB"][logNum]}`
}

/*
 * Returns a camelCased version of a snake_cased string.
 *
 * @name snakeToCamelCase
 * @function
 * @param {string} pythonSnakeCaseString - a string (probably from backend) that is using snake_case
 * rather than camelCase
 */
export const snakeToCamelCase = (pythonSnakeCaseString, withSpaces) => {
    const stringWithoutUppercase = pythonSnakeCaseString.replace(/(_\w)/g, (matches) =>
        withSpaces ? ` ${matches[1].toUpperCase()}` : matches[1].toUpperCase(),
    )
    return withSpaces
        ? stringWithoutUppercase[0].toUpperCase() + stringWithoutUppercase.substr(1)
        : stringWithoutUppercase
}

/*
 * Returns a snake_cased version of a string.
 *
 * @name convertToSnakeCase
 * @function
 * @param {string} rawString - a string (usually given by the user) most likely separated by spaces
 */
export const convertToSnakeCase = (rawString) =>
    rawString
        .replace(/[^\w\s]/gi, "") // Remove all special characters
        .replace(/_/g, "") // Remove any underscores, "_"
        .split(" ") // Split by the spaces
        .filter((subString) => subString) // Removes any multiple spaces from the string
        .join("_") // Join the string with underscores
        .toLowerCase() // Converts all letters to lowercase

/*
 * Returns the timeline formatted by chamber by date:
 * An array of dates (objects), each date object has an array of events grouped by chamber
 */
export const reduceTimeline = (timeline = []) =>
    timeline
        .concat([])
        .sort((a, b) => {
            const aDate = new Date(a.acted_at).getTime()
            const bDate = new Date(b.acted_at).getTime()

            if (bDate - aDate) {
                return bDate - aDate
            } else if (b.region === DjangIO.app.models.Region.federal.value) {
                return b.status - a.status
            } else if (Number.isInteger(a.display_order) && Number.isInteger(b.display_order)) {
                return b.display_order - a.display_order
            } else {
                return a.db_order - b.db_order
            }
        })
        .reduce((dates, event) => {
            const recentDate = dates[dates.length - 1]
            if (dates.length && recentDate.acted_at === event.acted_at) {
                // if the date is there, push this on events
                const recentEvent = recentDate.events[recentDate.events.length - 1]
                if (recentEvent && recentEvent.chamber === event.chamber) {
                    // If the chamber is the same, push it on the same guy
                    recentEvent.events.push(event)
                } else {
                    // Otherwise make a new chamber
                    recentDate.events.push({
                        chamber: event.chamber,
                        legislative_body: event.legislative_body,
                        events: [event],
                    })
                }
            } else {
                dates.push({
                    acted_at: event.acted_at,
                    events: [
                        {
                            chamber: event.chamber,
                            legislative_body: event.legislative_body,
                            events: [event],
                        },
                    ],
                })
            }
            return dates
        }, [])

/*
 * Returns the timeline formatted by chamber by date:
 * An array of dates (objects), each date object has an array of events grouped by chamber
 */
export const reduceRegTimeline = (timeline = []) =>
    timeline
        .concat([])
        .sort((a, b) => {
            const aDate = new Date(a.date_string).getTime()
            const bDate = new Date(b.date_string).getTime()

            if (aDate !== bDate) {
                return bDate - aDate
            }
            if (Number.isInteger(a.display_order) && Number.isInteger(b.display_order)) {
                return b.display_order - a.display_order
            }
            return 0
        })
        .reduce((dates, event) => {
            const recentDate = dates[dates.length - 1]
            if (dates.length && recentDate.acted_at === event.date_string) {
                // if the date is there, push this on events
                const recentEvent = recentDate.events[recentDate.events.length - 1]
                recentEvent.events.push(event)
            } else {
                dates.push({
                    acted_at: event.date_string,
                    events: [
                        {
                            events: [event],
                        },
                    ],
                })
            }
            return dates
        }, [])

// TODO: GET RID OF THIS SHIT
export const traverseAndMatch = (obj, match) => {
    for (let key in obj) {
        if (!obj.hasOwnProperty(key)) continue
        if (obj[key] === match && obj instanceof DjangIO.Model) return obj
        if (obj[key] && typeof obj[key] === "object") {
            const found = traverseAndMatch(obj[key], match)
            if (found) return found
        }
    }
    return undefined
}

/**
 * Decode a JSON object of style key/value pairs
 * Currently only cleans out the 'px' from values
 * @param {Object} styles - styles to decode
 * @returns {Object} - decoded key/value pairs
 */
export const decodeStyles = (styles) => {
    const decodedStyles = {}
    Object.entries(styles).forEach(([key, value]) => {
        let decodedValue

        // the value is a measurement in pixels
        if (!value.includes(" ") && value.includes("px")) {
            decodedValue = parseInt(value.replace("px", ""))
        } else {
            decodedValue = value // do not change the value
        }

        decodedStyles[key] = decodedValue
    })
    return decodedStyles
}

/**
 * Encode an object of styles for the browser to understand
 * @param {Object} styles - styles to decode
 * @returns {Object} - Something
 */
export const encodeStyles = (styles) => {
    const encodedStyles = {}
    Object.entries(styles).forEach(([key, value]) => {
        let encodedValue

        // add the pixels back in
        if (typeof value === "number") {
            encodedValue = `${value}px`
        } else {
            encodedValue = value // do not change the value
        }

        encodedStyles[key] = encodedValue
    })
    return encodedStyles
}

/**
 * Compute the width of text given a font size
 * @param {string} font - a CSS font description, such as:
 *    "normal normal 300 16px Helvetica"
 * @param {string} text - text to compute width of
 * @returns {Integer} - the width of the ext
 */
export const calculateTextWidth = (font, text) => {
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    ctx.font = font
    return ctx.measureText(text).width
}

/**
 * Pull out the response text from an error response, if available
 * @param {string} errorKey - the key in the data object of the response
 * @returns {Type} - the error string returned by the server, or undefined if t
 * does not exist
 */
export const getErrorResponseText = (error, errorKey = "responseText") =>
    error && error.response && error.response.data ? error.response.data[errorKey] : undefined

export const sortBySearchOrder = (a, b) => {
    // check the user info settings for both values

    let aIsInSettings = false
    let bIsInSettings = false

    if (Userdata.search_data_type_order) {
        aIsInSettings = Userdata.search_data_type_order.includes(a.value)
        bIsInSettings = Userdata.search_data_type_order.includes(b.value)
    }

    switch (true) {
        // if both are specified
        case aIsInSettings && bIsInSettings:
            // compare their position in the array
            return Userdata.search_data_type_order.indexOf(a.value) - Userdata.search_data_type_order.indexOf(b.value)

        // if only the position of a is specifed
        case aIsInSettings:
            // a goes first
            return -1

        // if only the position of b is specified
        case bIsInSettings:
            // b goes first
            return 1

        // if neither is specified go by search order if they're different
        case a.advanced_search_order !== b.advanced_search_order:
            return Math.sign(a.advanced_search_order - b.advanced_search_order)

        // Otherwise sort by the value
        // NOTE: Javascript's built-in sort isn't guaranteed to be stable so even if we don't care
        //       about the ordering, we still need to make sure not to return 0 in order to keep
        //       the sort deterministic, which is necessary for testing.
        default:
            return Math.sign(a.value - b.value)
    }
}

/**
 * Convert a Date object to an object with day/month/year keys
 * @param {Date} date - Date object
 * @returns {Object|null} - converted object, or null
 */
export const dateToDayMonthYear = (d) =>
    d ? { day: d.getDate(), month: d.getMonth() + 1, year: d.getFullYear() } : null

/**
 * Convert a moment object to a JavaScript object with day/month/year keys
 * @param {Moment} date - Moment date object
 * @returns {Object|null} - converted object, or null
 */
export const momentToDayMonthYear = (d) => (d ? { day: d.date(), month: d.month() + 1, year: d.year() } : null)

/**
* Function to convert an Immutable Object with day/month/year keys to a moment object
* @param {Object} datObj - An Object representing the date, of the form:
* {
    day: 3
    month: 12
    year: 2015
}
* @returns {Moment|null} - A moment object or null
*/
export const dayMonthYearToMoment = (dateObj) => (dateObj ? moment({ ...dateObj, month: dateObj.month - 1 }) : null)

export const nearestPastMinutes = (interval, someMoment = moment()) => {
    const roundedMinutes = Math.floor(someMoment.minute() / interval) * interval
    return someMoment.clone().minute(roundedMinutes).second(0)
}

/**
 * This function extracts the id from a resource uri
 * @name idFromResourceUri
 * @function
 * @param {String} uri - A resource uri of the form '/api/sheet/6148/'
 * @returns {Integer} - database id of the provided uri
 */
export const idFromResourceUri = (uri, raiseErrorEmpty = true) => {
    if (!uri) {
        if (!raiseErrorEmpty) {
            return undefined
        }

        throw new Error(`No resource Uri passed in`)
    }
    // clear out empty strings and the last element
    const id = uri
        .split("/")
        .filter((el) => el)
        .pop()
    return Number(id) || id
}

/**
 * This function cleans up a url
 * @name parseUrl
 * @function
 * @param {String} url - A potentially unformated url
 * @returns {String|Boolean} - A formatted url, or false if falsey
 */
export const parseUrl = (url) => {
    // we don't want to parse falsey urls since we'll end up with something like http://, so just return
    // we validate the url after this call anyway
    if (!url) {
        return url
    }
    // try to match against http/https
    if (!/^https?:\/\//i.test(url)) {
        return `http://${url}`
    } else {
        return url
    }
}
/*
 * In some cases, we construct a list request to fetch a single object when we know uniquely
 * constraining characteristics about that objects, but don't know that object's id. For example.
 * to fetch a LegislativeStance, we may know the user and bill id, but not the id of the LegislativeStance.
 * In this case, we want to unpack the only value that would be returned from a list request if it exists.
 *
 * @name detailFromListRequest
 * @function
 * @param {object} response - A TastyPie response
 * @returns {object|false} - An individual object or a falsey value
 */
export const detailFromListRequest = (response) =>
    response && response.objects && response.objects.length && response.objects[0]

/**
 * Takes in URL parameters, returns an object of parsed parameters.
 *
 * @name parseUrlParameters
 * @function
 * @param {string} params - A url parameter string like "?someKey=someValue&someKey2=someValue
 * @returns {object|false} - An object of shape { someKey: "someValue", someKey2: "someValue" }
 */
export const parseUrlParameters = (params) =>
    params &&
    decodeURIComponent(params)
        .substring(params.length && params[0] === "?" ? 1 : 0)
        .split("&")
        .map((param) => param.split("="))
        .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {})

// START OF NEW PROFILE FUNCTIONS

/*
    create a data object that will be used to render the DataTableItem
*/
export const createDataObject = ({
    title,
    subtitle,
    group,
    displayValue,
    onPress,
    onLongPress,
    iconName,
    hideTitle,
    hideValue,
    shouldNotTruncate,
    width,
    isLink,
    dataItem,
    tooltipText,
    permissions,
    segueId,
    segueUrl,
    ...rest
}) => ({
    id: `${title}-${subtitle}`,
    title,
    subtitle,
    group,
    displayValue,
    onPress,
    onLongPress,
    iconName,
    hideTitle,
    hideValue,
    shouldNotTruncate,
    width,
    isLink,
    dataItem,
    tooltipText,
    permissions,
    segueId,
    segueUrl,
    ...rest,
})

/*

creates a data section depending on an array of objects passed in from the selector
if a dataobj's value exists or should always render, a corresponding data object will
be created for it

*/

export const createDataSections = (dataArray) =>
    dataArray &&
    dataArray
        .filter((dataObj) => dataObj.alwaysRender || dataObj.value)
        .map((dataObj) =>
            createDataObject({
                title: dataObj.title,
                subtitle: dataObj.value ? dataObj.value.toString() : "",
                group: dataObj.group,
                group_dict: dataObj.group_dict,
                displayValue: dataObj.displayValue,
                onPress: dataObj.onPress,
                onLongPress: dataObj.onLongPress,
                iconName: dataObj.icon,
                hideTitle: dataObj.hideTitle,
                hideValue: dataObj.hideValue,
                shouldNotTruncate: dataObj.shouldNotTruncate,
                width: dataObj.width,
                isLink: dataObj.isLink,
                dataItem: dataObj.dataItem,
                tooltipText: dataObj.tooltipText,
                permissions: dataObj.permissions,
                segueId: dataObj.segueId,
                segueUrl: dataObj.segueUrl,
                isCampaign: dataObj.isCampaign,
                style: dataObj.style,
                externalHyperlinkURL: dataObj.externalHyperlinkURL,
            }),
        )

/*
    create the section object used to render the sections in the DataTableView
    (extends SectionList)
*/
export const createSectionObject = ({ title, data, id, isCustom, onPress, sectionComponent, ...rest }) => {
    // we still want to render the social media section for custom officials
    // in order to display a message for upselling the feature
    const shouldRenderSection =
        (isCustom && !data.length && title === "Social Media" && !Userdata.Permissions.qp_contacts_documents) ||
        data.length

    return (
        shouldRenderSection && {
            title,
            data,
            id,
            isCustom,
            onPress,
            sectionComponent,
            ...rest,
        }
    )
}

/*

create a component that should be rendered as a section in the DataTableView

*/
export const createSectionComponent = (title, id, renderItem) => ({
    ...createSectionObject({ title, data: [{}], id }),
    renderItem,
})

export const createCustomFieldObjects = ({ customFields, tagDict }) => {
    return tagDict && Object.keys(customFields).length > 0
        ? Object.entries(customFields)
              .filter(([key, value]) => tagDict[key] || tagDict[key] === false || value.display_null_value)
              .map(([key, value]) => {
                  const customTagTitle = value.name
                  const customTagGroup = value.group
                  const customTagGroupDict = value._extra ? value._extra.group_dict : null
                  let { parsedValue, returnOptionDefault, parsedURL } = parseCustomFieldHelper({
                      tagDict,
                      key,
                      customField: customFields[key],
                  })
                  // need this case here to return when optionDefaultValue should be returned
                  if (returnOptionDefault) {
                      return {}
                  }
                  // If the value is null and custom field specifies we should display null values
                  if (!parsedValue && customFields[key].display_null_value) {
                      parsedValue = "N/A"
                  }
                  return {
                      title: customTagTitle,
                      group: customTagGroup,
                      group_dict: customTagGroupDict,
                      value: parsedValue,
                      alwaysRender: Boolean(parsedValue) || customFields[key].display_null_value,
                      externalHyperlinkURL: parsedURL,
                  }
              })
        : []
}

/**
 * Helper function to create the standardized array of detail items to pass to the ContactCard
 * component as the "detailItems" field of the data prop
 *
 * @name createContactCardDetailItems
 * @function
 * @param {String} email  - email address
 * @param {String} phoneNumber  - phone number
 * @param {String} address  - street address
 * @param {String} location - City and State
 *
 * @returns {Array}  returns an array of objects for use as the DetailItems for the data of the ContactCard
 */
export const createContactCardDetailItems = ({ email, phoneNumber, address, location }) => [
    { label: "Email", text: email },
    { label: "Phone", text: phoneNumber },
    { label: "Verified Address", text: address },
    { label: "Location", text: location },
]

// NEW STATS FUNCTIONS
export const getStatEnumObject = (statEnum) => DjangIO.app.stats.types.NewStatsType.by_value(statEnum)

// gets the nodeType for each of the nodes in the network graph so that we can get
// the color of the nodes in AdvancedSearchNetworkGraph
export const getNodeType = (party) =>
    DjangIO.app.person.types.Party.by_value(party) && DjangIO.app.person.types.Party.by_value(party).label

/*
    creates the html needed to display the tooltip for the links in the network graph
*/
export const createGraphToolTip = ({ edgeObj, frequencyField, reduceLabelFunction }) => {
    edgeObj.forEach((edge) => {
        // map each edge in the array to a { statName: statValue } object
        const statNameToValueArray = Object.entries(edge)
            // filter out the values that are not stats (e.g. person / issue cache)
            .filter(([statEnum, statValue]) => !isNaN(statEnum))
            // append the name and value of the stat to the array
            .reduce((acc, [statEnum, statValue]) => {
                acc.push({ name: getStatEnumObject(statEnum).label, value: statValue })
                return acc
            }, [])
        // reduce the array of { statName: statValue } objects into one html string
        // that will be displayed on the links in the network graph
        edge.label = reduceLabelFunction && reduceLabelFunction(statNameToValueArray)
        // set the label for the frequencyField
        edge.frequencyField = frequencyField
        return edge
    })
    // return the object that has all of the labels on each of the edges
    return edgeObj.toJS ? edgeObj.toJS() : edgeObj
}

// get only the stat values from an api response (will have the stat enum as the key)
export const filterStatsFromResponse = (statSelector) =>
    createSelector(statSelector, (statObj) => {
        // make it a js object if it is Immutable
        const statObjJs = statObj && (statObj.toJS ? statObj.toJS() : statObj)
        return (
            statObjJs &&
            Object.entries(statObjJs)
                // remove the cache and graph data
                .filter(([key, val]) => parseInt(key))
        )
    })

/**
 * Takes 2 numbers and returns their sum.
 * @param   {function} selector the selector that returns the slice of the store
            that we want to index by id
 * @param   {string} key the top level key that the store is divided by
            (eg 'officials', 'supporters', etc)
 *
 * @returns {selector} a cachedSelector (using re-reselect) that caches the
        store by id and only returns a value if it has been updated
 */
export const createProfileInstanceSelector = ({ selector, key }) =>
    createCachedSelector(
        [selector, (state, props) => props.profileId || (props.params && props.params.profileId)],
        (slice, id) => slice && slice.getIn && slice.getIn([key, id], fromJS({})),
    )(
        (state, props) =>
            props &&
            // for mobile and desktop download center
            (props.profileId ||
                // for desktop profiles
                (props.params && props.params.profileId)),
    )

/**
 * This selector returns a selector that selects a key from the return value of the selector  ...
 * @name makeImmutableSliceSelector
 * @function
 * @param {Function} selector - a selector
 * @returns {Any} - the key from the return value of the selector if defined, otherwised either the defaultValue of the provided
 *   selector or undefineds
 */
export const makeImmutableSliceSelector =
    (selector) =>
    (key, defaultVal = undefined) =>
        createSelector(selector, (immutableSlice) =>
            immutableSlice && immutableSlice.get ? immutableSlice.get(key, defaultVal) : defaultVal,
        )

/**
 * Create a selector that returns undefined if a dependency returns undefined. Uses the same argument structure
 * as createSelector. Just like how you can access the resultFunc you passed into a selector with selector.resultFunc,
 * the resultFunc you pass in here can be accessed with selector.originalResultFunc.
 *
 * For instance,
 *      createEarlyUndefinedSelector(
 *          dep1, dep2, dep3,
 *          (val1, val2, val3) => val1 + val2 + val3,
 *      )
 * Is equivalent to,
 *      createSelector(
 *          dep1, dep2, dep3,
 *          (val1, val2, val3) => (val1 === undefined || val2 === undefined || val3 === undefined) ?
 *                                      undefined : ( val1 + val2 + val3 )
 *      )
 *
 * @param {Array<function>} ...dependencies - Selector dependencies
 * @param {function} originalResultFunc - The original result function
 * @return {function} - Output selector
 */
export const createEarlyUndefinedSelector = (...funcs) => {
    const resultFunc = funcs.pop()
    if (typeof resultFunc !== "function") throw new Error("resultFunc must be a function")
    const newResultFunc = (...args) => (args.indexOf(undefined) < 0 ? resultFunc(...args) : undefined)
    const selector = createSelector(...funcs, newResultFunc)
    selector.originalResultFunc = resultFunc
    return selector
}

export const makeObjSliceSelectorNonDefaultValue = (selector) => (key) =>
    createSelector(selector, (obj) => obj && obj[key])

export const makeObjSliceSelector =
    (selector) =>
    (key, defaultVal = undefined) =>
        createSelector(selector, (obj) => (obj && obj[key]) || defaultVal)

// creates social media objects from an array of socialUsers
export const createSocialUserObjects = ({ socialUsers, selectorFunctions, dataItems, isDlc = false, style }) => {
    if (isDlc) {
        socialUsers = socialUsers.filter(
            (socialObj) => socialObj.social_user_type === DjangIO.app.social.models.SocialUserType.twitter_user.value,
        )
    }
    // Iterate over social media types and not social users, so there is one header for each site they are on, not for each username.
    // We've deprecated instagram but it's still in the DB, so we are going to filter it out before iterating.
    const userTypeEnum = DjangIO.app.social.models.SocialUserType.items().filter(
        (type) => type.value !== DjangIO.app.social.models.SocialUserType.instagram_user.value,
    )
    return userTypeEnum
        .map((socialType) => {
            const socialUsersForThisType = socialUsers.filter(
                (socialUser) => socialUser.social_user_type === socialType.value,
            )
            if (socialUsersForThisType.length) {
                const isTwitter = socialType.value === DjangIO.app.social.models.SocialUserType.twitter_user.value
                const isYoutube = socialType.value === DjangIO.app.social.models.SocialUserType.youtube_user.value
                //The name of the social media platform
                const title = `${socialType.platform_name}`
                //The information displayed below the title but above the search segue
                const value =
                    // We will show all of the handles for each social media type (except youtube, because the usernames in the DB for youtube are ugly URLs, not pretty handles)
                    isYoutube
                        ? // if there are selectorFunctions aka it is for mobile, we
                          // currently do not seg to document and just take them to the
                          // external url. Change the label to reflect this
                          selectorFunctions
                            ? "Go to Page"
                            : SEGUE_TO_DOCUMENTS_TEXT
                        : //Connect the handles by commas, adding @ to the handle if it is for twitter
                          socialUsersForThisType
                              .map((socialUser) => `${isTwitter ? "@" : ""}${socialUser.username}`)
                              .join(", ")
                //We have slightly different behavior for twitter buttons
                if (isTwitter) {
                    return {
                        title,
                        value,
                        onPress:
                            selectorFunctions && selectorFunctions({ userUrl: socialUsersForThisType[0] }).socialUser,
                        icon: DjangIO.app.social.models.SocialUserType.twitter_user.platform_key,
                        dataItem: !isDlc && dataItems && dataItems[socialType.platform_key],
                        style,
                        isCampaign: false,
                    }
                } else {
                    return {
                        title,
                        value,
                        onPress:
                            socialUsersForThisType[0] && selectorFunctions
                                ? selectorFunctions({ userUrl: socialUsersForThisType[0] }).socialUser
                                : null,
                        icon: socialType.faIcon.replace("fa-", ""),
                        dataItem: dataItems && dataItems[socialType.platform_key],
                        style,
                        isCampaign: false,
                    }
                }
            } else {
                return null
            }
        })
        .filter((elm) => elm !== null)
}
/**
 * Takes in URL parameters, returns an object of parsed parameters.
 *
 * @name isSameDay
 * @function
 * @param {Date} date1 - A date object
 * @param {Date} date2 - A date object
 * @returns {boolean} - Whether the two dates are within the same day.
 */
export const isSameDay = (date1, date2) => {
    return typeof date1 === "object" && typeof date2 === "object"
        ? date1.getFullYear() === date2.getFullYear() &&
              date1.getMonth() === date2.getMonth() &&
              date1.getDate() === date2.getDate()
        : date1 === date2
}

/**
 * This function grabs an attr from an immutable OR regular object,
 * @name getAttr
 * @function
 * @param {Object} obj - Vanilla JS or Immutable JS object
 * @param {string} attr - Attribute to be retrieved
 * @returns {any} - value of the attribute
 */
export const getAttr = (obj, attr) => {
    if (!obj || !attr) {
        return
    }
    // use toJS as indicator of immutable, but don't call it unless needed
    return obj.toJS ? obj.get(attr) : obj[attr]
}

/**
 * This converts a number to a string with a percent sign,
 * @name percFormat
 * @function
 * @param {number} num - number
 * @returns {string} - the string of the number with a percent sign appended
 */
export const percFormat = (num) => `${(num * 100).toString()}%`

/**
 * This function takes an array of selected values and a new value.
 * If the value is already in selected values, it will deselect;
 * if the value is not in selected values, it was select.
 * @name toggleValue
 * @function
 * @param {array} selectedValues - currently selected values
 * @param {value} any - value to toggle
 */
export const toggleValue = (selectedValues, value) => {
    const isItemActive = selectedValues.includes(value)
    const deselectItem = (idNew) => selectedValues.filter((val) => val !== idNew)
    const selectItem = (idNew) => [...selectedValues, idNew]

    const newValue = isItemActive ? deselectItem(value) : selectItem(value)

    return newValue
}

/**
 * This function converts the result of Object.entries back to an object
 * @name objectFrom
 * @function
 * @param {Array[Array]} keyValueArray - An array of Arrays of key/value pairs, of the form:
 *  [[firstKey, firstValue], [secondKey, secondValue] ] where the first element in each array will be the
 * keys, and the second element in each array will be the corresponding value
 * @returns {Object} - An object with key/value pairs
 */
export const objectFrom = (keyValueArray) => {
    if (!(keyValueArray && keyValueArray.length)) {
        return {}
    }
    return Object.assign(...keyValueArray.map(([key, value]) => ({ [key]: value })))
}

/**
 * Append a slash to the prefix if it doesn't have one
 * @name appendSlashIfNecessary
 * @function
 * @param {String} prefix ("") - app prefix
 * @returns {String} - prefix with / at the end
 */
const appendSlashIfNecessary = (prefix = "") => (prefix.slice(-1) === "/" ? prefix : `${prefix}/`)

/**
 * Prepend the app name to the action types
 * @name prependAppName
 * @function
 * @param {String} prefix - The prefix to apply, such as @@api-caching
 * @returns {Object} - an object of action types, of the form {GET_TEAM_START: "GET_TEAM_START", ...}
 */
export const prependAppName = (prefix, actionTypes) =>
    objectFrom(
        Object.entries(actionTypes).map(([actionKey, actionValue]) => [
            actionKey,
            `${appendSlashIfNecessary(prefix)}${actionValue}`,
        ]),
    )

/**
 * This function gets a nested key in an object if possible. If not possible, returns undefined.
 * @name getNestedObjectKey
 * @function
 * @param {Object} obj - An object that can have nested structure
 * @param {Array} arrayOfKeys - An array of string keys to access in obj
 *
 * @returns {Any|undefined} - Anything that is in the object at the last key speciified, if available,
 * or undefined
 * If provided an empty arrayOfkeys, will return obj unchanged
 */
export const getNestedObjectKey = (obj, arrayOfKeys) => {
    try {
        if (!obj || !arrayOfKeys) {
            return undefined
        }
        // attempt to reduce down the keys
        return arrayOfKeys.reduce(
            (accumulatedObj, key) => (typeof accumulatedObj === "object" ? accumulatedObj[key] : undefined),
            obj,
        )
    } catch (error) {
        console.warn(error)
        return undefined
    }
}

export const isOfficialIssueStats = (statEnum) => {
    const { NewStatsType } = DjangIO.app.stats.types
    return [
        NewStatsType.top_issues_sponsored_or_cosponsored_enacted.value,
        NewStatsType.top_issues_sponsored_enacted.value,
        NewStatsType.top_issues_sponsored_or_cosponsored_out_of_committee.value,
        NewStatsType.top_issues_sponsored_or_cosponsored.value,
        NewStatsType.top_issues_sponsored_out_of_committee.value,
        NewStatsType.top_issues_cosponsored.value,
        NewStatsType.top_issues_sponsored.value,
    ].includes(parseInt(statEnum))
}

export const isPercentageStat = (statSlug) => {
    return (
        statSlug &&
        (statSlug.includes("percent") || statSlug === DjangIO.app.stats.types.NewStatsType.votes_with_most.key)
    )
}

export const makeSourceUrlObjs = (sourceUrls) =>
    sourceUrls
        ? sourceUrls.map((url, i) => ({
              title: `Source URL ${i + 1}`,
              value: url,
              isLink: true,
              width: 3,
          }))
        : []

/**
 * makePropSelector
 *
 * This is a helper function that we use throughout many of our
 * selectors. It allows us to easily select props passed from a parent
 * for use in selectors.
 *
 * @param  {string} key - the key of the prop to select
 * @return {[function]} - a selector that selects 'key' from a component's props
 */
export const selectProps = (state, props) => props
export const makePropSelector = (key) => createSelector(selectProps, (props) => props && props[key])

/**
 * Given a list of projects, this ensures the default project is tagged // TODO what if user explicity deselected the default?
 *
 * @param {Array} of project ids
 * @returns {Array} of project uri's
 */
export const getProjectUris = (projectIds = []) =>
    projectIds.map((id) => DjangIO.app.userdata.models.Project.foreignKey(id))

// helper function to create the limited to teams options for a given label
export const createEnableLimitedToTeamsOptions = (dataTypeLabel) => [
    {
        label: "Available to Everyone at Organization",
        tip: `This ${dataTypeLabel} will be viewable and editable by everyone in your organization regardless of what teams they are on`,
        value: AVAILABLE_TO_EVERYONE,
    },
    {
        label: "Limited to Teams",
        tip: `This ${dataTypeLabel} will be viewable and editable only by members of particular teams`,
        value: READ_LIMITED_TO_TEAMS,
    },
    {
        label: "Just Me",
        tip: `This ${dataTypeLabel} will be viewable and editable only by me`,
        value: READ_JUST_ME,
    },
]

/**
 * function that handles seguing to a tab given a segue function already connected
 * to the store
 * @param  {func} options.connectedSeg a segue function from global-action-creators
 *                                     already connected to the store
 * @param  {object} options.qdt          the data type for the profile
 * @param  {string | number} options.profileId    the profile id
 * @param  {func} options.onTabClick   the setCurrentTab function
 * @param  {string} options.pathName     the pathname to segue to
 */
export const segueToTab = ({ connectedSeg, qdt, profileId, onTabClick, pathName }) => {
    // create link to seg to
    const link = `${qdt.profile}${profileId}${pathName}`
    const onClickParameters = {
        qdt: qdt.key,
        id: profileId,
        newTab: pathName,
    }
    // set the new tab in the store
    onTabClick(onClickParameters)
    // segue to the next page
    connectedSeg(link)
}

/**
 * function that will return an array of objects de-duplicated on the property param
 * @param  {Array<Object>} objArr - the input array of Objects (such as an enum that you call .items() on)
 * @param  {String} property - the property to de-duplicate on
 */
export const deduplicateObjectArrary = (objArr, property) =>
    objArr.reduce((accumulator, current) => {
        const checkIfAlreadyExists = (currentVal) =>
            accumulator.some((item) => item[property] !== undefined && item[property] === currentVal[property])

        if (checkIfAlreadyExists(current)) {
            return accumulator
        } else {
            return [...accumulator, current]
        }
    }, [])

/**
 * function that will return an array of string values de-duplicated on the attribute param
 * @param  {Enum<Object>} enumeration - the input Enum of Objects
 * @param  {String} attribute - the attribute to de-duplicate on

 * @return {Array<String>} - an array of strings or ints representing the content of the attribute for each object in enumeration
 */
export const deduplicatedObjectArrayToString = ({ enumeration, values, attribute }) => {
    const deduplicatedEnum = deduplicateObjectArrary(enumeration, attribute)

    // this de-duplicates the enum array on a specific attribute
    // [{title: "first boi"}, {title: "second boi"}, {title: "second boi"}, {label: "third"}]
    // [{title: "first boi"}, {title: "second boi"}, {label: "third"}]

    // for each enumeration value that exists on the object...
    return (
        values
            .map((value) => {
                // iterate through the deduplicated enum array
                // and find the value that corresponds to our enumerated enum value
                const enumObject = deduplicatedEnum.find((elem) => elem.value === value)

                // the enumObject can be undefined if it was de-duplicated
                return enumObject && (enumObject[attribute] || enumObject.label)
            })
            // remove the undefined value that is returned when the enumObject is undefined
            .filter(Boolean)
            // join the objects into a comma separated list
            .join(", ")
    )
}

// helper regexes for @mentions
export const matchMentions = /\[@[^\]\[]+\|\|\|[^\]\[]+\]/g
export const matchMentionTagNames = /(\[@.*\||\]| )/g
export const atMentionSpan = '<span className="at-mention" class="at-mention">'

/**
 * Replaces any atMentions with a tag and the surrounding html for it
 * @param {String} value - the current value of the text
 * return {String, html} the mentions replaced with a tag with the proper class
 */
export const replaceAtMentionsWithTags = (value) => {
    return value
        ? value.replace(
              matchMentions,
              (mention) => atMentionSpan + "@" + mention.replace(matchMentionTagNames, "") + "</span>",
          )
        : ""
}
export const replaceFeedItemMention = (value) => {
    // ___ denotes a highlight for alert markup, which is what is stored in a FeedItem's
    return value ? value.split("___").join("") : ""
}

String.prototype.replaceAllTxt = function replaceAll(search, replace) {
    return this.split(search).join(replace)
}

export const escapeRegEx = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
}

/**
 * Replaces all occurrences of match with replace str, should function like replaceAll()
 * but added to resolve Uncaught TypeError: string.replaceAll is not a function when unit testing.
 * https://stackoverflow.com/questions/62825358/javascript-replaceall-is-not-a-function-type-error
 * @param {String} str - the original text
 * @param {String} match - substring in str to remove
 * @param {String} replacement - replacement string for match
 * return {String} Updated str text
 */
export const replaceAllText = (str, match, replacement) => {
    return str.replace(new RegExp(escapeRegEx(match), "g"), () => replacement)
}

/**
 * Helper that converts a properly formatted string into a slug
 * ex. "Schools | Total Full Time Teachers" -> "schools-total-full-time-teachers"
 */
export const convertToSlug = (text) =>
    text
        .toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-")

/**
 * function that will check to see if a string contains newlines (and convert them to their html equivalents if true) and/or html content
 * @param  {String} thirdLine - the thirdLine text being passed in

 * @returns {{newLine: string, isHtml: bool}}  - an object representing the thirdLine content
 *      (either an array of html elements or string of text) and whether or not it contains html
 */
export const checkLineBreakHtml = (input) => {
    let isHtml = false
    let newLine = input

    if (newLine) {
        if (newLine.includes("\n")) {
            newLine = simpleLineBreaks(newLine)
        }

        if (stringContainsHTMLMarkup(newLine)) {
            isHtml = true
        }
    }

    return { newLine, isHtml }
}

export const checkIfHtml = (input) => {
    let isHtml = false
    let newLine = input

    if (stringContainsHTMLMarkup(input)) {
        isHtml = true
    } else if (input.includes("\n")) {
        isHtml = true
        newLine = simpleLineBreak(input)
    }

    return { newLine, isHtml }
}

/**
 * Format a number into a abbreviated string with numerical symbols
 * @param  {Number} num - a number
 * @return {String} - the number abbreviated as a string
 */
export const abbreviateNumber = (num, placesAfterDecimal = 2, threshold = 1e5, addTilde = false) => {
    return String(formatNumberForDisplay(num, placesAfterDecimal, threshold, addTilde))
}

/**
 * Given a StatName Object format and its corresponding value,
 * return the formatted value that should be displayed.
 * Ex. format: "PR", value: 0.04 => "4%"
 * @param  {String} format - 'E' = number, 'PR' = Percentage
 * @param  {Number} value - raw number value of the StatName

 * @return {String} - formatted value as a string.
 */
export const handleCustomDataValue = (value, format, displayFormat) => {
    if (format.includes("PR")) {
        return formatPercentageForDisplay(value)
    } else if (displayFormat === "full") {
        return Number(value)
    }
    // otherwise, abbreviate number
    return formatNumberForDisplay(value, 1, 2000)
}

/**
 * Given a string, determine whether it is a proper amount.
 * The string should only have numbers, and possibly a single period
 * that is not the first or last character of the string.
 * @param  {String} amountString
 * @return {Boolean} - Whether the formatter amount is valid
 */
export const isValidAmountString = (amountString) => /^-?\$?\d*(\.\d+)?$/.test(amountString)
export const isValidAmountStringNegative = (amountString) => /^(?!.*\.\.)-?[0-9]?\.?\d*(.\d+)?$/.test(amountString)
export const isValidYearString = (dateString) => moment(dateString, "YYYY", true).isValid()

/**
 * Given a StatName object with a value and description, return an object
 * Ex. { DP05_0001E: { description: "EDUCATIONAL ATTAINMENT!!Population 25 years and over!!Percent high school graduate or higher", value: "6549352.000" } }
 * with the formatted label and value for a display component.
 * Because the format of Stat Name description is seperated by !!, we use .split('!!') to seperate the string sections
 * @param  {String} key - the StatName key inside of statsObj
 * @param  {Object} statsObj - returned object with value and description of StatName

 * @return {String, String} - the formatted label and value of StatName object.
 */
export const handleStatsObject = (key, statsObj) => {
    const labelArr = statsObj[key].description.split("!!")
    let label = labelArr.pop()

    while (labelArr.length > 1) {
        label = `${label}: ${labelArr.pop()}`
    }

    const numericalValue = parseFloat(statsObj[key].value)
    const value =
        key.includes("PE") && numericalValue <= 100
            ? `${numericalValue}%`
            : formatNumberForDisplay(numericalValue, 1, 1000)

    return { label, value }
}

/**
 * Given an object or array defined as value, determine if value has any elements.
 * Undefined objects and unrelated data types will also return false here.
 *
 * @param  {Object or Array} value
 *
 * @return {Bool} - Result of whether value is empty or undefined.
 */
export const isNonEmptyObj = (value) =>
    typeof value === "object" && !!Object.entries(value) && !!Object.entries(value).length

/**
 * Capitalize the first letter in a string.
 * Ex. "hello" => "Hello"
 */
export const capitalizeFirstLetter = (str) => str && str.charAt(0).toUpperCase() + str.slice(1)

/**
 * the goal is to create a purple -> blue spectrum of muted colors (per the d3-scale-chromatic schemes)
 * and pass that to interpolateRgbBasis, which will create a function that takes a number between
 * 0 and 1 (where 0 is the leftmost side of the spectrum, 1 is the righmost; in this case, purple
 * and blue, respectively), and returns the corresponding rgb value for that point in the range
 * @return {function} Takes in a float between 0-1 and returns rgb value
 */
export const colorFunctionForIcon = interpolateRgbBasis(
    schemePurples[9] // get the largest purple range
        .slice(3, 9) // only use the darker colors
        .reverse() // reverse it so that its lighter colors will meet blue's lighter colors
        .concat(
            schemeBlues[9] // largest blue range
                .slice(3, 9), // and again only the darker colors
        ),
)

/**
 * Get the display name of a component for debugging
 * @name getDisplayName
 * @function
 * @param {Component} Component - the Component
 * @returns {String} - name of the component, or "Component" if not available
 */
export const getDisplayName = (Component) => Component.displayName || Component.name || "Component"

/**
 * This function formats a number for display (like in a Sheet Cell or counter)
 * @name formatNumber
 * @function
 * @param {Number} rawNumber - a number to format
 * @param {Boolean} shouldRoundValue - Whether or not we should round the number
 * @returns {String} - Formatted number
 */
export const formatNumber = (rawNumber, shouldRoundValue = false) => {
    const roundArg = shouldRoundValue ? 0 : 1 // allow for rounding values
    switch (true) {
        // if its less than 0
        case rawNumber < 0:
            // format it as a positive number and stick a - in front of it
            return `-${formatNumber(-rawNumber, shouldRoundValue)}`

        // if it's two digits
        case rawNumber < 100:
            // allow the first decimal point
            return Number.isInteger(rawNumber) ? rawNumber : rawNumber.toFixed(roundArg)

        // if it's three digits
        case rawNumber < 1000:
            // truncate it
            return rawNumber.toFixed(0)

        // if it's four digits
        case rawNumber < 10000:
            // return the first four digits
            return rawNumber.toString().substr(0, 4)

        // if it's less than a million
        case rawNumber < 1000000:
            // express in thousands
            return `${(rawNumber / 1000).toString().substr(0, 4)}K`

        // if less than a billion
        case rawNumber < 1000000000:
            // express in the millions
            return `${(rawNumber / 1000000).toString().substr(0, 4)}M`

        // if greater than a billion
        case rawNumber > 1000000000:
            // express in the billions
            return `${(rawNumber / 1000000000).toString().substr(0, 4)}B`
        default:
            return rawNumber
    }
}

/**
 * Given a number, return its decimal precision.
 *
 * @param {Number} rawNumber - value to be inspected
 * @return {Number} the decimal precision of rawNumber
 *
 * @example
 *      getDecimalPrecision(1.234)
 *          returns 3
 */
export const getDecimalPrecision = (rawNumber) => {
    if (!Number.isFinite(rawNumber)) {
        return 0
    }
    var multiplier = 1,
        precision = 0
    while (Math.round(rawNumber * multiplier) / multiplier !== rawNumber) {
        multiplier *= 10
        precision++
    }
    return precision
}

/**
 * formatNumberCounter abbreviates a number to fit within a NumberCounterCell.
 * NumberCounterCell requires a separate function a strict format must be applied
 * for each unique sets of digits.
 *
 * @param {Number} rawNumber - a number to format
 * @returns {String} - Formatted number
 */
export const formatNumberCounter = (rawNumber) => {
    switch (true) {
        // if its less than 0
        case rawNumber < 0: // format it as a positive number and stick a - in front of it
            return `-${formatNumberCounter(-rawNumber)}`

        case rawNumber < 10: // if it's one digit allow the two decimal points max
            return Number(rawNumber).toFixed(Math.min(2, getDecimalPrecision(rawNumber)))

        case rawNumber < 100: // if it's two digits allow the first decimal point max
            return Number(rawNumber).toFixed(Math.min(1, getDecimalPrecision(rawNumber)))

        case rawNumber < 1000: // if it's three digits, truncate it
            return Number(rawNumber).toFixed(0)

        case rawNumber < 10000: // X,XXX return up to 2 decimal points (1.23k)
            return `${Number((rawNumber / 1000).toPrecision(3))
                .toString()
                .substr(0, 3)}k`

        case rawNumber < 100000: // XX,XXX return up to 1 decimal points (10.1k)
            return `${Number((rawNumber / 1000).toPrecision(3))
                .toString()
                .substr(0, 4)}k`

        case rawNumber < 1000000: // XXX,XXX return with no decimals
            return `${Number((rawNumber / 1000).toPrecision(3))
                .toString()
                .substr(0, 3)}k`

        case rawNumber < 10000000: // X,XXX,XXX, up to 2 decimals
            return `${Number((rawNumber / 1000000).toPrecision(3))
                .toString()
                .substr(0, 3)}m`

        case rawNumber < 100000000: // XX,XXX,XXX, up to 1 decimal
            return `${Number((rawNumber / 1000000).toPrecision(3))
                .toString()
                .substr(0, 4)}m`

        case rawNumber < 1000000000: // XXX,XXX,XXX, no decimals
            return `${Number((rawNumber / 1000000).toPrecision(3))
                .toString()
                .substr(0, 3)}m`

        case rawNumber > 1000000000: // 1,000,000,000+, up to 1 decimal
            return `${Number((rawNumber / 1000000000).toPrecision(3))
                .toString()
                .substr(0, 3)}b`
        default:
            return rawNumber
    }
}

/*
 * This function formats a number for currency display in a sheet cell with a decimal precision of 2 and dollar sign, always.
 * @name formatNumberWithCurrencyPrecision
 * @function
 * @param {Number} rawNumber - a number to format
 * @returns {String} - Formatted number
 */
export const formatNumberWithCurrencyPrecision = (rawNumber) => {
    return `${rawNumber.toLocaleString("en-US", { currency: "USD", style: "currency" })}`
}

/**
 * Helper function to create a selector for bills and amendments that adds
 * additional information based on the sponsor type to the sponsors objects
 * that would normally be selected. Intended to supersede the selectSponsors
 * selector that it consumes. Adds a key "sponsorInfo" to each sponsor object.
 *
 * @name createSelectSponsorsWithSponsorInfo
 * @function
 * @param {Selector} sponsorsSelector - selector that returns an array of sponsor objects for use
 *                                      in the Sponsors component
 * @returns {Selector}  returns a selector that returns the same array of sponsor objects, but with
 *                      an additional "sponsorInfo" field attached to each one
 */
export const createSelectSponsorsWithSponsorInfo = (sponsorsSelector) =>
    createSelector(
        sponsorsSelector,
        (sponsors) =>
            sponsors &&
            sponsors.map((sponsor) => {
                let sponsorInfoObj = {}
                switch (true) {
                    case sponsor.sponsor_type === DjangIO.app.bill.models.SponsorType.primary.value:
                        sponsorInfoObj = {
                            text: "Primary Sponsor",
                            icon: "award",
                        }
                        break
                    case sponsor.sponsor_type === DjangIO.app.bill.models.SponsorType.original_cosponsor.value:
                        sponsorInfoObj = {
                            text: "Original Cosponsor",
                            icon: "pen-alt",
                            iconFamily: "fas",
                        }
                        break
                    case sponsor.sponsor_type === DjangIO.app.bill.models.SponsorType.author.value:
                        sponsorInfoObj = {
                            text: "Author",
                            icon: "pen-alt",
                            iconFamily: "fas",
                        }
                        break
                    case sponsor.sponsor_type === DjangIO.app.bill.models.SponsorType.rapporteur.value:
                        sponsorInfoObj = {
                            text: `${DjangIO.app.bill.models.SponsorType.rapporteur.label}`,
                            icon: "pen-alt",
                            iconFamily: "far",
                        }
                        break
                    case !!sponsor.sponsor_type:
                        sponsorInfoObj = {
                            text: `${DjangIO.app.bill.models.SponsorType.by_value(sponsor.sponsor_type).label}`,
                            icon: "pen-alt",
                            iconFamily: "far",
                        }
                        break
                    case sponsor.withdrawn !== null && sponsor.withdrawn !== undefined:
                        sponsorInfoObj = {
                            text: `Cosponsor, withdrawn (${parseDate(sponsor.withdrawn)})`,
                            icon: "user-plus",
                        }
                        break
                    default:
                        sponsorInfoObj = {
                            text: `Cosponsor (${parseDate(sponsor.joined)})`,
                            icon: "user-plus",
                        }
                        break
                }

                return { ...sponsor, sponsorInfo: sponsorInfoObj }
            }),
    )

/**
 * Helper function to create a selector for bills and amendments that returns
 * the correct header string for the sponsors grid
 *
 * @name createSelectSponsorsHeaderText
 * @function
 * @param {Selector} isLoadingSelector  - selector that returns if the sponsors are loading
 * @param {Selector} numSponsorsSelector    - selector that returns the number of sponsors
 *
 * @returns {String}  returns the text for the header of the sponsors grid
 */
export const createSelectSponsorsHeaderText = (isLoadingSelector, numSponsorsSelector) =>
    createSelector(isLoadingSelector, numSponsorsSelector, (isLoading, numSponsors) => {
        const headerText = Userdata.has_international_region ? "Key Players" : "Sponsors"
        const numSponsorsText = isLoading ? "Loading..." : numSponsors
        return `${headerText} (${numSponsorsText})`
    })

/**
 * Extension of lodash debounce to allow for batching calls to the debounced
 * function, rather than the original behavior of calling the debounced function
 * with only the most recent arguments.
 *
 * Along with a function to debounce, wait period, and debounce options, a combiner
 * must be provided, which controls how the arguments of successive calls to the debounced
 * function should be combined before a real call to the function is made.
 *
 * The debounced function will ultimately be called with the most recent return
 * value from the combiner.
 *
 * https://github.com/jashkenas/underscore/issues/310
 *
 * @name debounceReduce
 * @function
 * @param {Function} func - a function to "debounce"; the target function that will be called with the
 *                          combined args after [wait]ms have passed since the last call to this function
 * @param {Number} wait - time period to wait until calling the target function, in ms
 * @param {Object} debounceOptions  - lodash debounceOptions
 * @param {Function} combiner   - function called with 2 parameters: combiner(combinedArgs, newArg)
 *                                  the combiner reduces combined arguments so far and the incoming
 *                                  argument into the next combined argument; the combined argument
 *                                  will be the argument passed to func, the target function
 *
 * @returns {Function}  a debounced version of the target function that has the arguments of successive
 *                      calls reduced for a single call to the target function
 */
export const debounceReduce = ({ func, wait, debounceOptions = {}, combiner }) => {
    // the combined arguments need to be stored
    let combinedArgs

    // debounce a wrapped version of the supplied function
    const reducedDebouncedFunction = debounce(
        () => {
            // call the function with the combined arguments
            func(combinedArgs)

            // when this function is finally called by debounce(),
            // we can reset the cached arguments so future calls
            // don't reuse them
            combinedArgs = undefined
        },
        wait,
        debounceOptions,
    )

    // function to return that wraps the caching and calling
    const result = (...args) => {
        // reduce the new arguments and the cached arguments
        // into combinedArgs to call the debounced function with
        combinedArgs = combiner(combinedArgs, ...args)

        // make a debounced function call which uses the combined arguments
        reducedDebouncedFunction()
    }

    return result
}

/**
 * Helper function to get the region abbreviation in the correct form for
 * profile header title prefixes from the region value.
 *
 * The region enum has an "abbrev" field, which is normally of the form
 * "us", "ak", "il", etc., but sometimes of the form "il_local". The prefix
 * should always use only the state abbreviation, so we take a substring of
 * the region's abbreviation.
 *
 * @name prefixFromRegion
 * @function
 * @param {Number} regionValue  - the enum value of the region to get a
 *                                  profile header title prefix for
 *
 * @returns {String}    a two character abbreviation in upper case for the region
 */
export const prefixFromRegion = (regionValue) =>
    regionValue && DjangIO.app.models.Region.by_value(regionValue).abbrev.substr(0, 2).toUpperCase()

/**
 * Creates a selector that returns the header title prefix based on the region,
 * using the prefixFromRegion helper.
 *
 * @name createRegionHeaderTitlePrefixSelector
 * @function
 * @param {Selector} selectRegion   - selector that selects a Region enum value
 *
 * @returns {String}    the appropriate header title prefix based on the selected region
 */
export const createRegionHeaderTitlePrefixSelector = (selectRegion) =>
    createSelector(selectRegion, (region) => region && prefixFromRegion(region))

export const getRegionImageUrl = (regionValue) => {
    if (!regionValue) {
        return undefined
    }

    const url =
        regionValue === DjangIO.app.models.Region.federal.value
            ? FEDERAL_SEAL_AVATAR_PATH
            : `${SEAL_AVATAR_PATH}${DjangIO.app.models.Region.by_value(regionValue).region_name.replace(" ", "")}.png`

    return generateStaticUrl(url)
}

/**
 * This function lists out the rowData inside a given transactionSummary
 * so that it works properly for HTMLTable. This function will also include
 * the key value of the object (labeled with datumLabel).
 *
 * @name createTransactionSummaryRowData
 * @function
 * @param {Object} transactionSummary
 * @param {String} datumLabel - the key value of each object in transactionSummary
 *
 * Input:
 *
 * transactionSummary: {
 *    2019: {
 *       name: "first",
 *       type: "type1",
 *       default: "true",
 *    }
 * }
 *
 * Output:
 *
 * rowData: [
 *     {
 *         year: 2019,
 *         name: "first",
 *         type: "type1",
 *         default: "true",
 *     },
 * ]
 */
export const createTransactionSummaryRowData = (transactionSummary, datumLabel) => {
    if (transactionSummary) {
        return Object.entries(transactionSummary).map(([key, object]) => ({ [datumLabel]: key, ...object }))
    }
    return []
}

/**
 * This function creates a list of objects containing the key, display pair
 * of a given transactionSummary. The display value is handled by MAP_TRANSACTION_SUMMARY_KEY_TO_LABEL
 * which maps a listed key to a proper display value for the table.
 * TRANSACTION_SUMMARY_KEY_ORDER is used to sort the included keys in a specific order for the table
 *
 * @name createTransactionSummaryColumnMapping
 * @function
 * @param {Object} transactionSummary
 * @param {String} datumLabel - the key value of each object in transactionSummary
 *
 * columnMapping: [
 *     { key: "year", display: "Year" }
 *     { key: "name", display: "Name" }
 *     { key: "type", display: "Type" }
 *     { key: "default", display: "Default" }
 * ]
 */
export const createTransactionSummaryColumnMapping = ({ transactionSummary, datumLabel, qdt, committeeType }) => {
    const { QuorumDataType } = DjangIO.app.models
    const { PoliticalCommitteeType } = DjangIO.app.pac.types
    if (transactionSummary && Object.keys(transactionSummary).length) {
        switch (qdt) {
            case QuorumDataType.supporter.value: {
                const columnKeys = [datumLabel, ...Object.keys(Object.values(transactionSummary)[0])].sort(
                    (a, b) => TRANSACTION_SUMMARY_KEY_ORDER[a] - TRANSACTION_SUMMARY_KEY_ORDER[b],
                )

                return columnKeys.map((key) => ({ key, display: MAP_TRANSACTION_SUMMARY_KEY_TO_LABEL[key] }))
            }
            case QuorumDataType.political_committee.value:
                const affiliatedDataExists = transactionSummary.some((period) =>
                    period.hasOwnProperty("affiliated_total"),
                )
                if (committeeType === PoliticalCommitteeType.candidate_committee.value) {
                    return [
                        { key: "time_period", display: "Election" },
                        { key: "limit", display: "Limit" },
                        { key: "aggregate_total", display: "Aggregate Total" },
                        ...(affiliatedDataExists ? [{ key: "affiliated_total", display: "Affiliated Total" }] : []),
                        { key: "available", display: "Available" },
                    ]
                }
                if (
                    [
                        PoliticalCommitteeType.federal_pac_ssf_and_nonconnected.value,
                        PoliticalCommitteeType.national_party_committee.value,
                    ].includes(committeeType)
                ) {
                    return [
                        { key: "time_period", display: "Year" },
                        { key: "limit", display: "Limit" },
                        { key: "aggregate_total", display: "Aggregate Total" },
                        ...(affiliatedDataExists ? [{ key: "affiliated_total", display: "Affiliated Total" }] : []),
                        { key: "available", display: "Available Balance" },
                    ]
                }
                if (
                    [
                        PoliticalCommitteeType.joint_fundraising_committee.value,
                        PoliticalCommitteeType.super_pac.value,
                    ].includes(committeeType)
                ) {
                    return [
                        { key: "time_period", display: "Year" },
                        { key: "aggregate_total", display: "Aggregate Total" },
                    ]
                }

            case QuorumDataType.public_organization.value:
                return [
                    { key: "year", display: "Year" },
                    { key: "aggregate_total", display: "Aggregate Total" },
                ]
        }
    }
    return null
}

/**
 * This function returns a list of years starting from the current year
 * @name getListOfYears
 * @function
 * @param {Number} numYearsBack - number of years to load
 * @returns - list of years - ex. [2020, 2019, 2018, ... 2011]
 */
export const getListOfYears = (numYearsBack = 10) => {
    return [...Array(numYearsBack).keys()].map((i) => CURRENT_YEAR - i)
}

/**
 * This function determines if enumValue matches the enumToCompare
 * @name someName
 * @function
 * @param {Type} argument - a description
 * @returns {Type} - Something
 */
export const isEnumEqual = (enumValue, enumToCompare) => {
    // attempt to catch a common mistake where the enum object itself is passed in
    let enumToCompare_ = enumToCompare
    if (typeof enumToCompare === "object") {
        enumToCompare_ = enumToCompare.value
    }

    return enumToCompare_ === enumValue
}

/**
 * Helper function to build an <a /> tag to the provided objects Profile.
 * @name dataObjectToProfileLink
 * @param {Object} item - Object containing at least the following fields: QDT, ID, and label
 */
export const dataObjectToProfileLink = (item) => `<a href="${item.qdt.profile}${item.id}/">${item.label}</a>`

/**
 * A function that creates a searchify filter that is filtered by a single id.
 * Used in organization-profile-selectors to segue the user to Document Search.
 *
 * @function
 * @param {string} method - method of the querymethod
 * @param {number} id - the id to filter by
 * @returns {<Object>} A single querymethod object that filters by a given id
 */
export const createSearchifyForSingleObject = (queryMethodName, id) =>
    queryMethodName &&
    id && {
        [queryMethodName]: {
            addedIds: [id],
            deletedIds: [],
            querysetList: [],
            searchifyCount: 1,
        },
    }

/**
 * A function that checks whether or not a variable is an Object or Array
 * (i.e., not a primitive Javascript type)
 *
 * @name isObject
 * @function
 * @param {Object} variable - the object or primitive variable
 *
 * @returns {Boolean}
 * https://underscorejs.org/docs/modules/isObject.html
 * https://stackoverflow.com/a/14706877/6201291
 */
export const isObject = (variable) => {
    const type = typeof variable

    return (type === "function" || type === "object") && Boolean(variable)
}

export const PRECISION_MIN_THRESHOLD = 2000

export const getDataLabelSingular = (qdt) => {
    if (qdt === DjangIO.app.models.QuorumDataType.bill.value && Userdata.has_international_region) {
        return "Item"
    }

    return DjangIO.app.models.QuorumDataType.by_value(qdt).singular
}

export const getDataLabelPlural = (qdt) => {
    if (qdt === DjangIO.app.models.QuorumDataType.bill.value && Userdata.has_international_region) {
        return "Items"
    }

    return DjangIO.app.models.QuorumDataType.by_value(qdt).plural
}

export const getPrecisionMaxThreshold = (qdt) => DjangIO.app.models.QuorumDataType.by_value(qdt).precision_max_threshold

export const delay = (ms) => new Promise((res) => setTimeout(res, ms))

export const formatPhoneNumber = (phone) =>
    phone.length === 10 ? phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") : phone

/**
 * Processes two input strings to fit within a specified total maximum character limit.
 *
 * @param {string} first - The first string to be processed.
 * @param {string} second - The second string to be processed.
 * @param {number} totalMaxChars - The total maximum number of characters allowed.
 * @returns {Object} An object containing the potentially trimmed versions of `first`
 *                  and `second` strings.
 */
export const trimTexts = (first, second, totalMaxChars = 4600) => {
    first = typeof first === "string" ? first : ""
    second = typeof second === "string" ? second : ""

    const ellipses = "..."

    // Reverse the order for processing from last to first for trimming purposes
    const texts = [second, first]
    // Concatenate texts to assess total length
    const combinedText = first + second

    if (combinedText.length <= totalMaxChars) {
        return { first, second }
    }

    // Calculate the excess length to trim
    let excess = combinedText.length - totalMaxChars + ellipses.length

    // Trim the texts from the end (second) towards the beginning (first)
    const trimmedTexts = texts.map((text) => {
        if (excess > 0 && text.length > 0) {
            const trimSize = Math.min(text.length, excess + ellipses.length)
            const trimmedText = trimSize < text.length ? text.substring(0, text.length - trimSize) + ellipses : ""
            excess -= text.length - trimmedText.length + ellipses.length
            return trimmedText
        }
        return text
    })

    // Return the trimmed texts in their original order
    return {
        first: trimmedTexts[1],
        second: trimmedTexts[0],
    }
}

export const mrtPrecedenceSorter = (roleA, roleB) => {
    const aPrecedence = roleA.get("_ph_minor_role_type")
        ? DjangIO.app.person.newtypes.MinorRoleType.by_value(roleA.get("_ph_minor_role_type")).precedence
        : Number.MAX_SAFE_INTEGER
    const bPrecedence = roleB.get("_ph_minor_role_type")
        ? DjangIO.app.person.newtypes.MinorRoleType.by_value(roleB.get("_ph_minor_role_type")).precedence
        : Number.MAX_SAFE_INTEGER
    // sort the roles by precedence where a lower precedence (more importance) comes first
    return aPrecedence - bPrecedence
}

/* Many International and EU roles don't have minor role types yet, so we will ignore this
check for those regions in favor of manually ordering using the editorial tools. See TI-1166.*/
export const editorialPrecedenceSorter = (roleA, roleB) => {
    // sort the roles by precedence where a lower precedence (more importance) comes first
    const aPrecedence = roleA.get("editorial_precedence")
    const bPrecedence = roleB.get("editorial_precedence")
    if (aPrecedence && bPrecedence) {
        return aPrecedence - bPrecedence
    } else {
        return mrtPrecedenceSorter(roleA, roleB)
    }
}
/* eslint-enable */

import React from "react"
import PropTypes from "prop-types"
import { google, ics, office365, yahoo } from "calendar-link"

import { DropdownButton } from "quorumdesign"

import { parseEstDateToTimezone, parseTimezoneNameFromAbbrev } from "shared/imports/sharedHelperFunctions"

import "customEvents/CustomEventsPage.styl"

function CustomEventAddToCalendar({
    address,
    buttonLabel,
    description,
    endTime,
    region,
    startTime,
    timezone,
    title,
    venueName,
}) {
    const eventTimezone = parseTimezoneNameFromAbbrev(region && DjangIO.app.models.Region.by_value(region).timezone)
    const calendarStartTime = parseEstDateToTimezone(startTime, timezone, eventTimezone).format()
    // If no end time is defined, set end time as start time
    const calendarEndTime = parseEstDateToTimezone(endTime || startTime, timezone, eventTimezone).format()
    const calendarLocation = address && venueName ? `${address} (${venueName})` : address || venueName

    const calendarObj = {
        title,
        description,
        location: calendarLocation,
        timezone,
        start: calendarStartTime,
        ...(calendarEndTime ? { end: calendarEndTime } : {}),
    }

    return (
        <DropdownButton
            type="secondary"
            isGridButton
            text={buttonLabel}
            leftIcon="calendar-plus"
            menuOptions={[
                {
                    icon: "google",
                    iconFamily: "fab",
                    text: "Google",
                    href: google(calendarObj),
                    newTab: true,
                },
                {
                    icon: "microsoft",
                    iconFamily: "fab",
                    text: "Office365",
                    // TODO: evert this when calendar-link launches a new release with the fix
                    href: office365(calendarObj).replace(/\/deeplink\//, "/action/"),
                    newTab: true,
                },
                {
                    icon: "yahoo",
                    iconFamily: "fab",
                    text: "Yahoo",
                    href: yahoo(calendarObj),
                    newTab: true,
                },
                {
                    icon: "apple",
                    iconFamily: "fab",
                    text: "Apple (.ics)",
                    href: ics(calendarObj),
                    newTab: true,
                },
            ]}
        />
    )
}

CustomEventAddToCalendar.propTypes = {
    address: PropTypes.string,
    buttonLabel: PropTypes.string,
    description: PropTypes.string,
    endTime: PropTypes.string,
    region: PropTypes.number,
    startTime: PropTypes.string,
    timezone: PropTypes.string,
    title: PropTypes.string,
    venueName: PropTypes.string,
}

CustomEventAddToCalendar.defaultProps = {
    address: "",
    buttonLabel: "Add To Calendar",
    description: "",
    endTime: "",
    startTime: "",
    timezone: "US/Eastern",
    title: "",
    venueName: "",
}

export default CustomEventAddToCalendar

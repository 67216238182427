import styled from "styled-components"

import * as rules from "Components/rules"

export const WrappedComponent = styled.span`
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    width: fit-content;
    height: fit-content;
    /* Move right edge of wrapped component to right edge of badge*/
    ${props => props.hasValue ? "margin-right: 10px;" : null}
`

export const Badge = styled.span`
    /* Font stuff */
    ${rules.TinyTextLightWeight}
    color: ${props => props.color};
    background-color: ${props => props.backgroundColor};

    /* Center everything and make a pretty circle/oval thing*/
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    box-sizing: border-box;
    line-height: 0;

    /* Circle is smaller if only truthy value is passed */
    min-width: ${props => props.small ? "10px" : "20px"};
    height: ${props => props.small ? "10px" : "20px"};
    padding: ${props => props.small ? "0 0" : "0 6px"};
    border-radius: ${props => props.small ? "5px" : "10px"};

    /* Move to top-right corner */
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    z-index: 1;
`

import React from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"

import { joinPaths } from "QuorumGrassroots/helperFunctions"

import {
    StyledIssueCol,
    StyledIssueLink,
    StyledIssueImageTile,
    StyledIssueLogoTile,
} from "QuorumGrassroots/widgets/IssueList/components/IssueInline/style"

export const IssueInline = (props) => {
    const slug = props.issue.grassroots_vanity_url_slug || props.issue.id

    const url = joinPaths(DjangIO.app.grassroots.types.GrassrootsWidgetType.issue.widget_url, slug)

    const IssueTileWrapper = props.issue.image_url ? StyledIssueImageTile : StyledIssueLogoTile

    const widgetFullWidth = props.parentWidth === 12

    return (
        <StyledIssueCol className="issue-col" md={widgetFullWidth ? 4 : 12} sm={widgetFullWidth ? 6 : 12} xs={12}>
            <StyledIssueLink className="issue-link" to={url} state={{ ...props.issue }}>
                <IssueTileWrapper
                    className="issue-tile-wrapper"
                    imageUrl={props.issue.image_url}
                    hasDescription={Boolean(props.issue.short_description)}
                >
                    <i className={`fa ${props.issue.icon}`} />
                    <div className="issue-content-wrapper">
                        <div className="issue-name">{props.issue.name}</div>
                        <div className="issue-short-description">{props.issue.short_description}</div>
                    </div>
                </IssueTileWrapper>
            </StyledIssueLink>
        </StyledIssueCol>
    )
}

IssueInline.propTypes = {
    issue: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
        short_description: PropTypes.string,
        grassroots_vanity_url_slug: PropTypes.string,
        image_url: PropTypes.string,
        icon: PropTypes.string,
    }),
    parentWidth: PropTypes.number,
}

export default IssueInline

import styled, { css } from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const Screen = styled.div`
    background-color: rgba(255,255,255,0.85);
    height: 100vh;
    left: 0px;
    overflow: auto;
    position: fixed;
    top: 0px;
    width: 100vw;
    // Match the Mantine Modal Overlay z-index
    z-index: 200;
`

export const ModalWrapper = styled.div`
    position: absolute;
    width: 80%;
    ${({ fixedHeight, fixedTop }) => {
        if (fixedTop) {
            return css`
                left: 0;
                margin-left: auto;
                margin-right: auto;
                right: 0;
                top: ${fixedTop};
            `
        } else {
            return css`
                height: ${fixedHeight ? '80%' : 'auto'};
                left: 50%;
                max-height: calc(100% - 4rem);
                top: 50%;
                transform: translate(-50%, -50%);
            `
        }
    }}

    ${({ clampWidth, disableMaxWidth }) => {
        if (disableMaxWidth) {
            return
        }
        else if (clampWidth) {
            return css`
                max-width: min(100rem, 100% - 4rem);
            `
        }

        return css`
            max-width: calc(100% - 4rem);
        `
    }}
`

export const ModalContainer = styled.div`
    ${props => !props.disableBorder &&
        // ew
        // https://github.com/styled-components/jest-styled-components/issues/97#issuecomment-364169127
        // https://stackoverflow.com/a/55656556/6201291
        css`
            ${rules.CardStyle}
            border: 1px solid ${colors.gray2};
            box-shadow: 0 12px 40px -12px rgba(0, 0, 0, .2);
        `
    }
    background-color: ${colors.White};
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    overflow-y: auto;
    padding: ${({ hasLabel }) => hasLabel ? '15px' : '0' };
`

export const LabelHeader = styled.div`
    text-align: center;
    position: absolute;
    width: calc(100% - 30px);
`

export const Label = styled.span`
    ${rules.SmallTextLightWeight}
    letter-spacing: 1.33px;
    text-transform: uppercase;
    text-align: center;
    @media (max-height: ${rules.Breakpoints.small}) {
        ${rules.TinyTextLightWeight}
    }
`

export const Icon = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    color: ${colors.QuorumGrey4};
    font-size: 29px;
    line-height: 0px;

    &:hover {
        cursor: pointer;
        color: ${colors.CapeCod};
    }
`

export const Content = styled.div`
    flex-grow: 1;
    margin: ${({ centered }) => centered ? '45px 0px' : '0'};

    @media (max-height: ${rules.Breakpoints.medium}) {
        margin: ${({ centered }) => centered ? '40px 0px' : '0'};
    }
    @media (max-height: ${rules.Breakpoints.small}) {
        margin: ${({ centered }) => centered ? '30px 0px' : '0'};
    }
`

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/VisualizationBarWidget/style"

import Button from "Components/Molecules/Button"

const VisualizationBarWidget = ({
    children,
    isHorizontal,
    loadAll,
    loadAllOnClick,
    noHeader,
    shouldRenderLoadAll,
}) => (
    <React.Fragment>
        {
            shouldRenderLoadAll &&
                <S.VisualizationBarWidgetLoadAllButton
                    noHeader={noHeader}
                >
                    <Button
                        dataCy="bar-visualization-load-all-button"
                        isTiny
                        onClick={loadAllOnClick}
                        style={{ padding: "8px 16px", borderRadius: "32px" }}
                        text={!loadAll ? "Load More" : "Show Less"}
                        type="secondary"
                    />
                </S.VisualizationBarWidgetLoadAllButton>
        }
        <S.VisualizationBarWidget
            data-auto-cy="CompoundVisualizationBarWidget"
            isHorizontal={isHorizontal}
        >
            { children }
        </S.VisualizationBarWidget>
    </React.Fragment>
)

VisualizationBarWidget.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),
    isHorizontal: PropTypes.bool,
    loadAll: PropTypes.bool,
    loadAllOnClick: PropTypes.func,
    noHeader: PropTypes.bool,
    shouldRenderLoadAll: PropTypes.bool,
}

export default VisualizationBarWidget

import React, { useState } from "react"
import PropTypes from "prop-types"

import { shareOnTwitter } from "QuorumGrassroots/helperFunctions"
import {
    StyledTwitterCharacterCountError,
    StyledTwitterInputGroup,
    StyledTwitterInputField,
    StyledTwitterSendButton,
} from "QuorumGrassroots/campaign-forms/components/TweetForm/style"
import { StyledLargeBoldText } from "QuorumGrassroots/widgets/styled-components/styles"
import { useSimpleGetCampaignMessages } from "QuorumGrassroots/services"
import { XTwitterFarFAIcon } from "QuorumGrassroots/campaign-forms/components/MultiAction/XTwitterFarFAIcon"
import { StyledSubmitButton } from "QuorumGrassroots/styled-components/components/StyledSubmitButton"
import { StyledContrastText } from "QuorumGrassroots/styled-components/components/StyledContrastText"

const TWITTER_MAX_CHAR = 280

interface TextAndTweetRowProps {
    personId: number | string
    sendTweet: (text: string, personId: number | string) => void
    t: (name: string) => string
    tweet?: string
}

export const TextAndTweetRow = ({ personId, sendTweet, t, tweet = "" }: TextAndTweetRowProps) => {
    const [inputText, setInputText] = useState(tweet)
    const exceededCharLimit = inputText.length > TWITTER_MAX_CHAR

    return (
        <div className="text-and-tweet-row">
            <StyledTwitterCharacterCountError className="exceeded-char-message" data-cy="tweet-too-long">
                {exceededCharLimit && t("campaign.tweet.too_long")}
            </StyledTwitterCharacterCountError>
            <StyledTwitterInputGroup>
                <StyledTwitterInputField
                    onChange={(e) => setInputText(e.target.value)}
                    value={inputText}
                    data-cy="twitter-input-field"
                />
                <StyledTwitterSendButton
                    onClick={() => {
                        shareOnTwitter({ text: inputText })
                        sendTweet(inputText, personId)
                    }}
                    disabled={exceededCharLimit}
                    data-cy="twitter-send-button"
                >
                    <XTwitterFarFAIcon size={14} />
                    {t("campaign.tweet.send_single_tweet")}
                </StyledTwitterSendButton>
            </StyledTwitterInputGroup>
        </div>
    )
}

export const TweetForm = ({ campaign, uniqueWidgetId, ...props }) => {
    const messagesQuery = useSimpleGetCampaignMessages(campaign.id)

    if (messagesQuery.isLoading) return <StyledContrastText isCampaignPage>{props.t("loading")}</StyledContrastText>

    const messages = messagesQuery.data.messages

    const submitWidget = () => {
        if (props.isMultiAction) {
            props.userCompletedCampaign(campaign.id)
            props.submitMultiActionChild()
        } else {
            props.completeCampaign(campaign.id, uniqueWidgetId)
        }
    }

    const sendTweet = (text, personId) => {
        const payload = campaign.custom_target
            ? { text }
            : { text, person: DjangIO.app.person.models.Person.resourceUriFromId(personId) }

        props.postGrassrootsSupporterAction({
            uniqueWidgetId: uniqueWidgetId,
            campaign: props.isMultiAction ? { ...campaign, id: campaign.multi_action_parent_id } : campaign,
            payload,
        })
    }

    const renderTweets = () => {
        return messages.map((message) => {
            return (
                <div className="tweet-message" key={message.target.id}>
                    <StyledLargeBoldText>
                        {campaign.custom_target
                            ? props.t("campaign.tweet.tweet_at", { handle: message.target.custom_target })
                            : message.target.name}
                    </StyledLargeBoldText>
                    {message.bodies.map((tweet) => (
                        <TextAndTweetRow
                            key={tweet}
                            tweet={tweet}
                            personId={message.target.id}
                            sendTweet={sendTweet}
                            t={props.t}
                        />
                    ))}
                </div>
            )
        })
    }

    return (
        <div className="tweet-form">
            {renderTweets()}
            <StyledSubmitButton
                className="ngg-submit-button"
                onClick={submitWidget}
                type="submit"
                data-cy="submit"
                isCampaignPage
                activateNGGTheme
            >
                {props.isMultiAction ? (
                    <>
                        {props.t("campaign.tweet.multi_action_done")}
                        <i className={"fa fa-arrow-right"} />
                    </>
                ) : (
                    props.t("campaign.tweet.done")
                )}
            </StyledSubmitButton>
        </div>
    )
}

TweetForm.propTypes = {
    uniqueWidgetId: PropTypes.string.isRequired,
    campaign: PropTypes.object.isRequired,
    completeCampaign: PropTypes.func.isRequired,
    postGrassrootsSupporterAction: PropTypes.func.isRequired,
}

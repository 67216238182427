import { createSelector, createStructuredSelector } from "reselect"
import { selectAugmentedWidgetContent, selectIsWidgetContentLoading } from "QuorumGrassroots/widgets/selectors"

export const selectDistrictsList = createSelector(selectAugmentedWidgetContent, (districtsList) =>
    districtsList && districtsList.map ? districtsList : [],
)

export const districtsListSelector = createStructuredSelector({
    districtsList: selectDistrictsList,
    loading: selectIsWidgetContentLoading,
})

import i18n from "i18n"
import { createSelector, createStructuredSelector } from "reselect"
import { fromJS } from "immutable"

import { selectWidgetContent } from "QuorumGrassroots/widgets/selectors"
import * as frameworkSelectors from "QuorumGrassroots/framework/selectors"
import { setDefaultValue } from "shared/customFields/constants/helperFunctions"

/* ***********************************************************************
 * UPDATE INFORMATION SELECTORS
 *********************************************************************** */
/*
 * Returns either the ID of a registration page specified in props, or the
 * registration pages specified by the action center settings.
 *
 * If props.registrationPageIds isn't already defined, this will return a list
 * of ids with the default registration page first, then the rest of the
 * registration pages ordered by id.
 */
export const selectRegistrationPageIds = createSelector(
    [
        (_, props) => props.registrationPageIds,
        frameworkSelectors.selectRegistrationPages,
        frameworkSelectors.selectDefaultRegistrationPageId,
    ],
    (regPageIds, registrationPages, defaultRegPageId) => {
        if (regPageIds) {
            return regPageIds
        }

        return registrationPages
            .sort((regPageA, regPageB) => {
                if (regPageA.get("id") === defaultRegPageId) {
                    return -1
                } else if (regPageB.get("id") === defaultRegPageId) {
                    return 1
                } else {
                    return Math.sign(regPageA.get("id") - regPageB.get("id"))
                }
            })
            .map((page) => page.get("id"))
            .toArray()
    },
)

export const selectDefaultRegistrationPage = createSelector(
    [frameworkSelectors.selectRegistrationPages, frameworkSelectors.selectDefaultRegistrationPageId],
    (registrationPages, defaultRegistrationPageId) =>
        registrationPages.find((page) => page.get("id") === defaultRegistrationPageId),
)

export const selectUserInitialValues = createSelector(
    [frameworkSelectors.selectUserdata, frameworkSelectors.selectCustomFields],
    (userdata, customFields) => {
        const initialValues = { ...userdata }

        // Add customField default values to the Update Information form
        customFields.toJS().forEach((field) => {
            const isInInitialValues = Object.keys(initialValues).includes(field.slug)
            const isDateField = field.tag_type === DjangIO.app.person.types.TagType.date.value
            // Don't modify date field defaults since the date TagType default_value already has a timestamp
            if (!isInInitialValues && !isDateField) {
                initialValues[field.slug] = setDefaultValue(field).default_value
            }
        })

        return fromJS({
            ...initialValues,
            // Set the initial value for texting opt-in to true unless the user
            // has already explicitly opted out
            outbound_texting_opted_in: !userdata.unsubscribed_from_texting,
        })
    },
)

// Retrieve the value of this Campaign's "Custom Call to Action Button Text" field
const selectStyledButtonText = createSelector(selectWidgetContent, (campaign) =>
    campaign ? campaign.custom_call_to_action_button_text : "",
)

const selectIsUserPacEligible = frameworkSelectors.selectIsPacEligible

export const selectPACCharityMatchInfo = createSelector(frameworkSelectors.selectDonationForms, (donationForms) => {
    if (!donationForms || !donationForms.count()) {
        return {}
    }

    return {
        label: donationForms.last().get("pac_match_select_label"),
        description: donationForms.last().get("pac_match_description"),
    }
})

export const selectUpdateInfoProps = createStructuredSelector({
    isCampaignPage: frameworkSelectors.selectIsCampaignPage,
    registrationPageIds: selectRegistrationPageIds,
    initialValues: selectUserInitialValues,
    styledButtonText: selectStyledButtonText,
    hasPacCharitableMatch: frameworkSelectors.selectHasPacCharitableMatch,
    isUserPacEligible: selectIsUserPacEligible,
    pacCharitableMatchInfo: selectPACCharityMatchInfo,
    defaultRegistrationPage: selectDefaultRegistrationPage,
    tooltipText: frameworkSelectors.selectTooltipText,
    readOnlyFields: frameworkSelectors.selectReadOnlyFormFields,
    customFields: frameworkSelectors.selectCustomFields,
})

export const selectUpdateInfoThanksProps = createSelector(
    [frameworkSelectors.selectUserdata, (_, props) => props.location, (_, props) => props.navigate],
    (userdata, location, navigate) => {
        return {
            text: i18n.t("registration.updated_information"),
            buttonDetails: [
                {
                    label: i18n.t("navbar.go_back"),
                    // if we have state, it means that we reached the update info
                    // page by clicking it in the navbar. We can redirect the user
                    // back to the page they were on before they clicked update info.
                    // If there is no state, then somehow they landed on /update_info/
                    // without clicking any buttons, and they should be directed to the
                    // homepage.
                    //
                    // I still think React Router is pretty cool.
                    onClick: () => {
                        location.state && location.state.prevPath ? navigate(location.state.prevPath) : navigate("/")
                    },
                    type: DjangIO.app.grassroots.types.GrassrootsNavigationBarButtonType.single_page.value,
                    dataCy: "homepage",
                },
            ],
        }
    },
)

import { makePieActionObjs } from "shared/imports/pieActionGenerators"

const loadContactFormObjs = makePieActionObjs("load", "Contact_Form")
export const loadContactFormActionDict = loadContactFormObjs.pieDict

const ContactFormActionTypes = {
    ...loadContactFormObjs.actionTypes,

    UPDATE_SUPPORTER: "UPDATE_SUPPORTER",
    UPDATE_SUPPORTER_VALIDATION: "UPDATE_SUPPORTER_VALIDATION",
    LOAD_SUPPORTER_DEFAULT_TAG_DICT: "LOAD_SUPPORTER_DEFAULT_TAG_DICT",
    INIT_CONTACT_FORM: "INIT_CONTACT_FORM",
    CONTACT_FORM_SET_CREATED_SUPPORTER: "CONTACT_FORM_SET_CREATED_SUPPORTER",
    CONTACT_FORM_SET_SEGUE_TARGET: "CONTACT_FORM_SET_SEGUE_TARGET",
}


export default ContactFormActionTypes

import { css } from 'styled-components'
/**
* When an component becomes visible, it will fold down from the component above it.
*
* See CollapsibleGrid or FAQ components as examples.
* @name foldDown
* @function
* @param {boolean} isVisible - Whether the content is visible or not.
* @returns {string} - CSS
*/
export const foldDown = ({isVisible}) => (css`
    /* FF and Chrome don't seem to conform to the CSSWG spec atm. Perhaps we can revisit this later. */
    ${!isVisible && "display: none"};

    /* Transition the collapsed content smoothly into view */
    /* visibility: ${isVisible ? "visible" : "collapse"}; */
    /* We need to set the height to 0, because chrome only partially implemented visibility: collapse */
    /* ${!isVisible && "height: 0px"};
    position: relative;
    top:${isVisible ? "0px" : "-15px"};
    opacity: ${isVisible ? "100%" : "50%"};
    transition: top .1s, opacity .1s; */
`)

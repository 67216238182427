import React from "react"
import PropTypes from "prop-types"
import Icon from "Components/Atoms/Icon"

import * as S from "Components/Molecules/WorkflowOverlay/style"

const WorkflowOverlay = ({
    actions,
    altActions
}) => {
    const Actions = ({actions}) => (
        <S.HoverActions>
        {actions.map(action =>
            <S.Row key={action.text + action.icon}>
                <S.ClickableWrapper
                    onClick={(e) => {
                        // prevent the main container's onclick from firing
                        e.stopPropagation()
                        action.onClickFn()
                    }}
                >
                {action.icon &&
                    <S.HoverActionIcon>
                        <Icon icon={action.icon} iconFamily={action.iconFamily} title={action.iconTitle}/>
                    </S.HoverActionIcon>
                }
                {action.text &&
                    <S.TextSpan>
                        {action.text}
                    </S.TextSpan>
                }
                </S.ClickableWrapper>
            </S.Row>
        )}
        </S.HoverActions>
    )

    const AltActions = ({actions}) => (
        <S.AltHoverActions>
            {actions.map(action =>
                <S.ClickableWrapper key={action.text + action.icon}
                    onClick={(e) => {
                        // prevent the main container's onclick from firing
                        e.stopPropagation()
                        action.onClickFn()
                    }}
                >
                    <S.AltHoverActionIcon isActive={action.isActive}>
                        <Icon icon={action.icon} iconFamily={action.iconFamily} title={action.iconTitle}/>
                    </S.AltHoverActionIcon>
                </S.ClickableWrapper>
            )}
        </S.AltHoverActions>
    )

    return (
        <S.ClickablesContainer data-auto-cy="MoleculeWorkflowOverlay">
            {altActions && <AltActions actions={altActions} />}
            {actions && <Actions actions={actions} />}
        </S.ClickablesContainer>
    )
}

WorkflowOverlay.defaultProps = {}

WorkflowOverlay.propTypes = {
    actions: PropTypes.array,
    altActions: PropTypes.array,
}

export default WorkflowOverlay

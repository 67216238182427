import React from "react"

import { useStyles } from "QuorumGrassroots/widgets/Stepper/styles"
import { StepProps, useStepperContext } from "QuorumGrassroots/widgets/Stepper/context"
import { Spinner } from "react-bootstrap"

export const Stepper = () => {
    const { steps = [], currentStep, changeStep, isCurrentStepLoading } = useStepperContext()
    const { classes } = useStyles()

    const getStepClassName = (step: StepProps) => {
        if (step.isSubmitted) return "completed"
        if (step.name === currentStep.name) return "active"
        return ""
    }

    const getStepIcon = (step: StepProps) => {
        if (isCurrentStepLoading && currentStep.name === step.name) return <div className={classes.spinner}></div>
        if (step.isSubmitted) return step.successIcon
        return step.Icon
    }

    return (
        <div className={classes.stepperContainer}>
            {steps.map((step, index) => (
                <>
                    <button
                        data-cy={`stepper-${step.name}`}
                        type="button"
                        className={classes.step}
                        key={step.name}
                        onClick={() => changeStep(step.name)}
                        disabled={isCurrentStepLoading}
                    >
                        <div className={`${getStepClassName(step)} ${classes.iconContainer}`}>{getStepIcon(step)}</div>
                        <div className={classes.title}>{step.title}</div>
                    </button>
                    {index < steps.length - 1 && <div className={classes.divider} />}
                </>
            ))}
        </div>
    )
}

import React, { Component } from "react"
import PropTypes from "prop-types"

import { Helmet } from "react-helmet"
import { simpleReplacePlaceholders } from "QuorumGrassroots/helperFunctions"

import CampaignWarning from "QuorumGrassroots/widgets/Campaign/components/CampaignWarning"
import LoginUpdateOrRender from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/LoginUpdateOrRender"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"

import {
    StyledCustomEventDetailsWrapper,
    StyledCustomEventDetails,
} from "QuorumGrassroots/widgets/Event/components/style"
import { loadWidgetContentFromProps } from "QuorumGrassroots/widgets/Event/helperFunctions"

import { StyledColumnWidget, StyledWidgetHeader } from "QuorumGrassroots/widgets/styled-components/StyledWidget"
import { StyledCampaignDescription } from "QuorumGrassroots/widgets/Campaign/components/style"
import EventForm from "QuorumGrassroots/widgets/Event/containers/EventForm"
import TextWidget from "QuorumGrassroots/widgets/Text/components/index"

export class EventWidget extends Component {
    static propTypes = {
        canPreviewEvent: PropTypes.bool.isRequired,
        content: PropTypes.object,
        eventPassThroughProps: PropTypes.object.isRequired,
        hasLoaded: PropTypes.bool.isRequired,
        hasLoadedContent: PropTypes.bool.isRequired,
        isEmbedded: PropTypes.bool,
        emailRelatedPerson: PropTypes.string,
        loadWidgetContent: PropTypes.func.isRequired,
        pageTitle: PropTypes.string.isRequired,
        privateEvent: PropTypes.bool.isRequired,
        resetSubmittedWidget: PropTypes.func.isRequired,
        rsvpYesAndSubmit: PropTypes.func.isRequired,
        shouldThank: PropTypes.bool.isRequired,
        t: PropTypes.func.isRequired,
        uniqueWidgetId: PropTypes.string.isRequired,
        widgetWidthSize: PropTypes.number,
    }

    componentDidMount() {
        if (!this.props.hasLoadedContent) {
            loadWidgetContentFromProps(this.props)
            // if shouldThank is true when we mount the component,
            // we know that the user has previously submitted an action without performing a hard reload
            // which means that we need to clear that section of the store so that they can submit another action
        } else if (this.props.shouldThank) {
            this.props.resetSubmittedWidget(this.props.uniqueWidgetId)
        }
    }

    onCompleteFunction = () => {
        if (this.props.eventPassThroughProps.shouldAutoComplete) {
            this.props.rsvpYesAndSubmit(this.props.eventPassThroughProps)
        }
    }

    renderEventDescriptionColumn() {
        const {
            custom_header_image,
            name,
            address,
            should_display_event_details,
            venue_name,
            link_to_event,
            date_time,
            end_time,
            timezone,
            publicly_available_description,
        } = this.props.content

        return (
            <StyledColumnWidget
                className="event-widget"
                xs={12}
                sm={12}
                md={this.props.widgetWidthSize}
                lg={this.props.widgetWidthSize}
                backgroundImage={custom_header_image}
            >
                {/* Add Helmet to change browser title of page to official's name */}
                <Helmet>
                    <title>{this.props.pageTitle}</title>
                </Helmet>
                {
                    <StyledWidgetHeader className="event-header">
                        {name || (this.props.hasLoaded ? "" : this.props.t("loading"))}
                    </StyledWidgetHeader>
                }
                <StyledCampaignDescription
                    className="event-description"
                    data-cy="long-description"
                    dangerouslySetInnerHTML={{
                        __html:
                            simpleReplacePlaceholders(publicly_available_description, this.props.userdata) ||
                            (this.props.hasLoaded ? "" : this.props.t("loading")),
                    }}
                    shouldThank={this.props.shouldThank}
                />
                {should_display_event_details && (
                    <StyledCustomEventDetailsWrapper className="event-details-wrapper">
                        <StyledCustomEventDetails
                            addToCalendarButtonText={this.props.t("event.details.add_to_calendar.button")}
                            headerLabel={this.props.t("event.details.header.label")}
                            locationLabel={this.props.t("event.details.location.label")}
                            addressLabel={this.props.t("event.details.address.label")}
                            startTimeLabel={this.props.t("event.details.start_time.label")}
                            endTimeLabel={this.props.t("event.details.end_time.label")}
                            linkLabel={this.props.t("event.details.link.label")}
                            eventName={name}
                            eventDescription={publicly_available_description}
                            eventVenueName={venue_name}
                            eventAddress={address}
                            eventDate={date_time}
                            eventEndTime={end_time}
                            eventTimezone={timezone}
                            eventLink={link_to_event}
                            addToCalendar
                        />
                    </StyledCustomEventDetailsWrapper>
                )}
            </StyledColumnWidget>
        )
    }

    renderEventFormColumn() {
        if (!this.props.content.id) {
            return this.props.t("loading")
        } else {
            return (
                <LoginUpdateOrRender
                    uniqueWidgetId={this.props.uniqueWidgetId}
                    // for the component that renders if we get past Login and Update.
                    // The component is conditional on whether or not all campaign data
                    // has been loaded.
                    renderedComponent={EventForm}
                    renderedProps={this.props.eventPassThroughProps}
                    // for the registration forms
                    registrationPageIds={this.props.registrationPageIds}
                    additionalSimpleFields={[]}
                    // update information fun stuff
                    showOnlyUnfilledFields
                    updateInfoText={this.props.t("campaign.need_more_information")}
                    // internationalization
                    t={this.props.t}
                    // whether or not loginupdateorrender + thankable wrapper should have box styling
                    useWidgetStyling={false}
                    // what to do upon logging in, if anything.
                    onComplete={this.onCompleteFunction}
                    shouldCompleteOnMount={false}
                    preview={this.props.canPreviewEvent}
                />
            )
        }
    }

    render() {
        if (this.props.hasLoaded && !this.props.hasLoadedContent) {
            // If the event doesn't exist
            return (
                <WidgetStyleWrapper title={this.props.t("event.private.title.label")} useWidgetStyling>
                    <CampaignWarning
                        text={this.props.t("event.private.message.text")}
                        buttonText={this.props.t("event.private.button.text")}
                        link={DjangIO.app.grassroots.types.GrassrootsWidgetType.event_list.widget_url}
                        t={this.props.t}
                    />
                </WidgetStyleWrapper>
            )
        }

        return (
            <div>
                {this.renderEventDescriptionColumn()}
                <StyledColumnWidget xs={12} sm={12} md={this.props.widgetWidthSize} lg={this.props.widgetWidthSize}>
                    {!this.props.shouldThank && (
                        <StyledWidgetHeader>
                            {this.props.loggedIn
                                ? this.props.t("event.form.rsvp.header.cta")
                                : this.props.t("event.form.rsvp.header.register_cta")}
                        </StyledWidgetHeader>
                    )}
                    {this.renderEventFormColumn()}
                </StyledColumnWidget>
            </div>
        )
    }
}

export default EventWidget

import { Modal, Button, ButtonProps } from "@mantine/core"
import React from "react"

import { Icon } from "@/components/Icon/Icon"
import classes from "@/pages/search/grassroots/ActionModal/ActionModal.module.css"

interface ActionModalProps {
    isOpen: boolean
    onClose: () => void
    title: string
    description?: string

    cancelButtonConf?: {
        text: string
        onClick: () => void
        extraClass?: string
        variant?: ButtonProps["variant"]
        isLoading?: boolean
    }
    confirmButtonConf: {
        text: string
        onClick: () => void
        isLoading?: boolean
    }
    iconConf: {
        icon: string
        color: "green" | "red" | "gray"
    }
}

export const ActionModal = (props: ActionModalProps) => {
    return (
        <Modal
            opened={props.isOpen}
            onClose={props.onClose}
            size="md"
            withCloseButton={false}
            closeOnClickOutside={!(props.cancelButtonConf?.isLoading || props.confirmButtonConf.isLoading)}
            centered
            radius="lg"
            classNames={{ body: classes.modalBody }}
        >
            <div className={classes.modalContainer}>
                <div data-bg-color={props.iconConf.color} className={classes.iconContainer}>
                    <Icon icon={props.iconConf.icon} />
                </div>
                <div className={classes.textContainer}>
                    <h2>{props.title}</h2>
                    {props.description && <p>{props.description}</p>}
                </div>
                <div className={classes.buttonContainer}>
                    <Button
                        type="button"
                        className={props.cancelButtonConf?.extraClass}
                        onClick={props.cancelButtonConf?.onClick ?? props.onClose}
                        variant={props.cancelButtonConf?.variant ?? "outline"}
                        loading={props.cancelButtonConf?.isLoading}
                        disabled={props.confirmButtonConf?.isLoading}
                    >
                        {props.cancelButtonConf?.text ?? "Cancel"}
                    </Button>
                    <Button
                        type="button"
                        onClick={props.confirmButtonConf.onClick}
                        loading={props.confirmButtonConf?.isLoading}
                        disabled={props.confirmButtonConf?.isLoading}
                    >
                        {props.confirmButtonConf.text}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

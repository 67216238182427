import { connect } from "react-redux"

import VoterRegistrationForm from "QuorumGrassroots/campaign-forms/components/VoterRegistrationForm"

import { postGrassrootsSupporterAction } from "QuorumGrassroots/campaign-forms/action-creators"
import { voterRegistrationFormConnection } from "QuorumGrassroots/campaign-forms/selectors/voter-registration-selectors"

const actions = { postGrassrootsSupporterAction }

export default connect(voterRegistrationFormConnection, actions)(VoterRegistrationForm)

import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withNamespaces } from "react-i18next"

import { selectUserLoggedIn } from "QuorumGrassroots/framework/selectors"
import { selectHasUnfilledRequiredFields } from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/selectors"

import LoginRegistrationWidget from "QuorumGrassroots/widgets/LoginRegistration/containers/index"
import { NonThankableUpdateInfoWidget } from "QuorumGrassroots/widgets/UpdateInformation/containers/index"

export class LoginUpdateOrRender extends Component {
    static propTypes = {
        // user provided props
        additionalSimpleFields: PropTypes.arrayOf(PropTypes.number).isRequired,
        campaignId: PropTypes.number,
        campaignIsOneClickRegistrationEnabled: PropTypes.bool,
        onComplete: PropTypes.func,
        preview: PropTypes.bool,
        registrationPageIds: PropTypes.arrayOf(PropTypes.number).isRequired,
        renderedComponent: PropTypes.func.isRequired,
        showOnlyFilledCustomFields: PropTypes.bool,
        // derived props, found in UpdateInfoFormSection/selectors
        simpleFieldObjs: PropTypes.arrayOf(PropTypes.object).isRequired,
        customFieldNameDict: PropTypes.object,
        hasUnfilledRequiredFields: PropTypes.bool.isRequired,
        // 'Global' props, found in framework/selectors
        loggedIn: PropTypes.bool.isRequired,
        hasAlreadyParticipated: PropTypes.bool,
    }

    static defaultProps = {
        additionalSimpleFields: [],
        campaignIsOneClickRegistrationEnabled: false,
        customFieldNameDict: {},
        hasAlreadyParticipated: false,
        preview: false,
        registrationPageIds: [],
        simpleFieldObjs: [],
    }

    componentDidMount() {
        if (
            this.props.shouldCompleteOnMount &&
            this.props.loggedIn &&
            !this.props.hasUnfilledRequiredFields &&
            this.props.onComplete
        ) {
            this.props.onComplete()
        }
    }

    componentDidUpdate(prevProps) {
        // check to see if we just filled out all fields.
        const allFieldsCompleted =
            this.props.loggedIn &&
            !this.props.hasUnfilledRequiredFields &&
            !(prevProps.loggedIn && !prevProps.hasUnfilledRequiredFields)

        if (allFieldsCompleted && this.props.onComplete) {
            this.props.onComplete()
        }
    }

    render() {
        // 1. User is previewing the component, return the component
        // 2. User is not logged in return LoginRegistration widget (RegistrationForm)
        // 3. User is registered (UpdateInfoOrRender)
        //      2a. User needs to enter more information. (UpdateInfoForm)
        //      2b. User does not need to enter more information. (renderedComponent)

        const RenderedComponent = this.props.renderedComponent
        const componentWithProps = (
            <RenderedComponent
                {...this.props.renderedProps}
                useWidgetStyling={this.props.useWidgetStyling}
                isCampaignPage={this.props.isCampaignPage}
                t={this.props.t}
            />
        )

        if (this.props.preview) {
            return componentWithProps
        } else if (!this.props.loggedIn) {
            return (
                <LoginRegistrationWidget
                    registrationPageIds={this.props.registrationPageIds}
                    additionalSimpleFields={this.props.additionalSimpleFields}
                    useWidgetStyling={this.props.useWidgetStyling}
                    campaign={this.props.campaign}
                    campaignId={this.props.campaignId}
                    campaignIsOneClickRegistrationEnabled={this.props.campaignIsOneClickRegistrationEnabled}
                    isCampaignPage={this.props.isCampaignPage}
                    location={window.location}
                />
            )
        } else if (this.props.hasUnfilledRequiredFields) {
            return (
                <NonThankableUpdateInfoWidget
                    registrationPageIds={this.props.registrationPageIds}
                    additionalSimpleFields={this.props.additionalSimpleFields}
                    showOnlyFilledCustomFields={false}
                    showOnlyUnfilledFields={this.props.showOnlyUnfilledFields}
                    useWidgetStyling={this.props.useWidgetStyling}
                    updateInfoText={this.props.updateInfoText}
                    campaignId={this.props.campaignId}
                    campaignIsOneClickRegistrationEnabled={this.props.campaignIsOneClickRegistrationEnabled}
                    isCampaignPage={this.props.isCampaignPage}
                    // used by selectStyledButtonText in UpdateInformation/selectors.js to access
                    // this widget's content in the store
                    uniqueWidgetId={this.props.uniqueWidgetId}
                    isEmbedded
                />
            )
        } else {
            return componentWithProps
        }
    }
}

const mapStateToProps = (state, props) => ({
    hasUnfilledRequiredFields: selectHasUnfilledRequiredFields(state, props),
    loggedIn: selectUserLoggedIn(state, props),
})

export default withNamespaces()(connect(mapStateToProps, null)(LoginUpdateOrRender))

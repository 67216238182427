import moment from "moment"
import { createSelector, createStructuredSelector } from "reselect"
import { selectSpecificCustomDescription, makeSelectFieldFromCustomDescription } from "shared/state_management/selectors"
import {
    createDataSections,
    createProfileInstanceSelector,
    createSectionObject,
    lineBreaks,
    makeImmutableSliceSelector,
    makeObjSliceSelector,
} from "shared/imports/sharedHelperFunctions"
import { checkHasPermission } from 'shared/imports/permissionFunctions'

export const selectArticleProfile = state => state.articleProfile

export const selectProfileInstance = createProfileInstanceSelector({
    selector: selectArticleProfile,
    key: "articles"
})

const makeArticleProfileSelect = makeImmutableSliceSelector(selectProfileInstance)

export const selectArticle = makeArticleProfileSelect("article")
export const selectIsArticleLoading = makeArticleProfileSelect("isLoadingArticle")

const makeArticleSelect = makeObjSliceSelector(selectArticle)
export const selectId = makeArticleSelect("id")

// ----------------
// Headers
// ----------------

export const selectDate = createSelector(
    makeArticleSelect("estimated_published_date"),
    date => date && moment(date).local().format("MMMM Do, YYYY, h:mm:ss a")
)
export const selectSource = makeArticleSelect("source")
export const selectSourceName = createSelector(
    selectSource,
    source => source && source.name
)
export const selectSourceOutlet = createSelector(
    selectSource,
    source => source && source.outlet
)

export const selectTitle = makeArticleSelect("title")
export const selectSubtitleString = createSelector(
    selectDate,
    selectSourceName,
    (date, name) => `${name} | ${date}`
)

export const selectSourceUrl = makeArticleSelect("lexis_url")
export const selectSources = createSelector(
    selectSourceUrl,
    source => [{ source }]
)

export const selectContent = makeArticleSelect("content")

// ----------------
// Tabs
// ----------------

export const selectArticleCustomDescription = selectSpecificCustomDescription(
    DjangIO.app.models.QuorumDataType.media.key
)
const makeArticleDescriptionSelect = makeSelectFieldFromCustomDescription(selectArticleCustomDescription)
export const selectCurrentTab = makeArticleDescriptionSelect("currentTab")
export const selectIsTruncated = makeArticleDescriptionSelect("titleIsTruncated")


// ----------------
// About
// ----------------

export const selectAuthors = createSelector(
    makeArticleSelect("author"),
    authors => authors && authors.name
)
export const selectAuthorsPressContacts = createSelector(
    makeArticleSelect("author"),
    authors => authors && authors.press_contacts
)
export const selectSourceCategoryName = createSelector(
    selectSource,
    source => {
        const category = source && DjangIO.app.media_monitoring.types.MediaSourceCategory.by_value(source.category)
        return category && category.label
    }
)
export const selectSourceTypeName = createSelector(
    selectSource,
    source => {
        const type = source && DjangIO.app.media_monitoring.types.MediaSourceType.by_value(source.media_type)
        return type && type.label
    }
)
export const selectSourceRank = createSelector(
    selectSource,
    source => {
        const rank = source && DjangIO.app.media_monitoring.types.MediaSourceRank.by_value(source.rank)
        return rank && rank.label
    }
)
export const selectIndustries = makeArticleSelect("industries")
export const selectIndustryNames = createSelector(
    selectIndustries,
    industries => industries && industries.join(", ")
)
export const selectRegion = makeArticleSelect("region")
export const selectRegionName = createSelector(
    selectRegion,
    regionId => {
        let regionName = regionId && DjangIO.app.models.Region.by_value(regionId) && DjangIO.app.models.Region.by_value(regionId).region_name
        if (regionName === "US Federal") {  // Edge case where the codebase's region_name does not quite fit a geographic location.
            regionName = "United States"
        }
        return regionName
    }
)
export const selectLanguageName = createSelector(
    makeArticleSelect("language"),
    languageId => {
        const language = languageId && DjangIO.app.document.types.Language.by_value(languageId)
        return language && language.label
    }
)

export const selectAboutData = ({ sectionComponent }) => createSelector(
    [
        selectTitle,
        selectIsTruncated,
        selectAuthors,
        selectAuthorsPressContacts,
        selectSourceName,
        selectSourceRank,
        selectSourceCategoryName,
        selectSourceTypeName,
        selectDate,
        selectRegionName,
        selectLanguageName,
        selectIndustryNames,
    ],
    (
        title,
        titleIsTruncated,
        authors,
        authorsPressContacts,
        sourceName,
        sourceRank,
        sourceCategory,
        mediaType,
        publicationDate,
        stateOrTerritory,
        language,
        sourceIndustry
    ) => {
        const aboutData = [
            {
                title: "Title",
                value: titleIsTruncated && title,
            },
            // Don't show the Authors here if we are going to show the Card below
            ...(authors && !authorsPressContacts.length)
                ? [{
                    title: "Author(s)",
                    value: authors,
                    width: 3
                }]
                : [],
            {
                title: "Source",
                value: sourceName,
                width: 3
            },
            {
                title: "Source Rank",
                value: sourceRank,
                width: 3
            },
            {
                title: "Source Category",
                value: sourceCategory,
                width: 3
            },
            {
                title: "Media Type",
                value: mediaType,
                width: 3
            },
            {
                title: "Publication Date",
                value: publicationDate,
                width: 3
            },
            {
                title: "Location",
                value: stateOrTerritory,
                width: 3
            },
            {
                title: "Language",
                value: language,
                width: 3
            },
            ...sourceIndustry ? [{
                title: "Source Industry",
                value: sourceIndustry.toTitleCase(),
                width: 3
            }] : []
        ]

        const aboutObj = createSectionObject({
            title: "About",
            data: createDataSections(aboutData),
            id: "articleAboutSection",
            sectionComponent,
        })

        return aboutObj
    }
)

export const selectSourceData = ({ sectionComponent }) => createSelector(
    selectAuthorsPressContacts,
    selectSourceOutlet,
    selectSourceName,
    selectSourceCategoryName,
    selectSourceTypeName,
    (authors, outlet, outletName, outletCategory, outletType) => {
        let sourceData = []

        if (checkHasPermission([DjangIO.app.models.PermissionType.media_relations])) {
            if (authors) {
                // Not every Article has an Author
                sourceData = authors.map(author => ({
                    qdt: DjangIO.app.models.QuorumDataType.press_contact,
                    title: author.name,
                    text: author.title,
                    url: `${DjangIO.app.models.QuorumDataType.press_contact.profile}${author.id}/`,
                    imageUrl: author.image_url,
                }))
            }


            if (outlet) {
                // Every Article should have a Source
                sourceData.push({
                    qdt: DjangIO.app.models.QuorumDataType.public_organization,
                    title: outletName,
                    text: `${outletCategory} | ${outletType}`,
                    url: `${DjangIO.app.models.QuorumDataType.public_organization.profile}${outlet.id}/`,
                    imageUrl: outlet.s3_image_url,
                })
            }
        }

        let title = "Source"

        if (authors && authors.length) {
            title = authors.lenth === 1
                ? `Author and ${title}`
                : `Authors and ${title}`
        }

        const sourceObj = createSectionObject({
            title,
            data: sourceData,
            id: "articleSourceSection",
            sectionComponent,
            gridWidth: 3,
        })

        return sourceObj
    }
)

// ----------------
// Full Text Search
// ----------------

const selectLicense = makeArticleSelect("license")
export const selectIsLicensed = createSelector(
    selectLicense,
    license => license
        && Array.isArray(license)
        && license.length > 0
        && DjangIO.app.media_monitoring.types.MediaLicense.by_value(license[0]).licensed_content
)

export const hasSubmittedSearch = makeArticleProfileSelect("hasSubmittedSearch")
export const isLoadingSnippets = makeArticleProfileSelect("isLoadingSnippets")
export const searchTerm = makeArticleProfileSelect("searchTerm")
export const snippets = makeArticleProfileSelect("snippets")

const selectXFrameOptions = makeArticleSelect("x_frame_options")

export const shareableSelectShouldShowFrame = (xFrameOptions) => {
    const xFrameOptionsObj = DjangIO.app.media_monitoring.types.XFrameOptionsType.by_value(xFrameOptions)
    const shouldShow = xFrameOptionsObj && xFrameOptionsObj.show_iframe

    if (shouldShow) {
        return true
    }
    return false
}
export const selectShouldShowFrame = createSelector(
    selectXFrameOptions,
    xFrameOptions => shareableSelectShouldShowFrame(xFrameOptions)
)

export const selectIsNotAccessible = makeArticleProfileSelect("isNotAccessible")
export const selectFullArticleData = createSelector(
    selectContent,
    content => (content ? lineBreaks(content) : "")
)

export const selectFullText = ({ sectionComponent }) => createSelector(
    selectFullArticleData,
    (articleData) => {
        const textData = [
            {
                title: "Full Content",
                value: articleData,
            }
        ]
        const summaryObj = createSectionObject({
            title: "",
            data: createDataSections(textData),
            id: "articleFullText",
            sectionComponent
        })
        return summaryObj
    }
)

export const selectNonLicensedDisclaimer = ({ sectionComponent }) => createSelector(
    selectShouldShowFrame,
    (shouldNotShowDisclaimer) => {
        const data = [
            {
                title: "nonLicensed",
                value: shouldNotShowDisclaimer ? "false" : "true",  // Would do bool, but value requires strings.
            }
        ]
        const disclaimerObj = createSectionObject({
            title: "Full Text",
            data: createDataSections(data),
            id: "articleNonLicensedDisclaimer",
            sectionComponent,
            skipSectionBreak: true,
        })
        return disclaimerObj
    }
)

export const selectUnlicensedIFrame = ({ sectionComponent }) => createSelector(
        selectSourceUrl,
        (url) => {
            const textData = [
                {
                    title: "Source",
                    value: url,
                }
            ]
            const contentObj = createSectionObject({
                title: "Source",
                data: createDataSections(textData),
                id: "articleIFrame",
                sectionComponent,
                skipSectionBreak: true,
            })
            return contentObj
        }
)

import * as actionObjects from "shared/grassroots/action-types"
import * as djangioActionCreators from "shared/djangio/action-creators"

/**
 * Grabs a single action center
 * @function
 * @param {number} - id - id of the wanted action center
*/
export const getActionCenter = id => (
    djangioActionCreators.resourceGetDetail(
        DjangIO.app.grassroots.models.GrassrootsActionCenterSettings,
        actionObjects.getActionCenter,
        id,
        { id }
    )
)

/**
 * Grabs all action centers for current organization
 * @function
*/
export const getActionCenters = () => (
    djangioActionCreators.resourceGetList(
        DjangIO.app.grassroots.models.GrassrootsActionCenterSettings,
        actionObjects.getActionCenters,
        {
            dehydrate_extra: [
                "background_image",
                "primary_color",
                "black_color",
            ],
            limit: 1000,
        }
    )
)

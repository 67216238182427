import i18n from "i18n"
import { formValueSelector } from "redux-form/immutable"
import { createSelector, createStructuredSelector } from "reselect"
import { idFromResourceUri } from "shared/imports/sharedHelperFunctions"

import {
    createPageTitleSelector,
    selectActionCenterSettings,
    selectEmailRelatedPerson,
    selectOrganization,
    selectOrganizationDesign,
    selectUserLoggedIn,
} from "QuorumGrassroots/framework/selectors"
import {
    selectWidgetSubmitted,
    selectAugmentedWidgetContent,
    selectIsWidgetContentLoading,
    selectWidgetPostSubmitData,
} from "QuorumGrassroots/widgets/selectors"
import { createCustomFieldNameDict } from "shared/customFields/constants/helperFunctions"
import { socialMediaShareMap } from "QuorumGrassroots/helperFunctions"
import { constants } from "QuorumGrassroots/constants"

const makeFormValueSelector = (field) => (state) => formValueSelector(constants.eventReduxFormKey)(state, field)

export const selectSelfRsvpStatusFormValue = makeFormValueSelector("rsvpStatus")
export const selectBossRsvpStatusFormValue = makeFormValueSelector("bossStatus")

export const selectPrimaryColor = createSelector(
    selectOrganizationDesign,
    (orgDesign) => orgDesign && orgDesign.primary_color,
)

export const selectBackgroundColor = createSelector(
    selectOrganizationDesign,
    (orgDesign) => orgDesign && orgDesign.background_color,
)

export const selectExtra = createSelector(selectAugmentedWidgetContent, (content) => content._extra || {})

export const selectRegistrationPageIds = createSelector(selectAugmentedWidgetContent, (event) => {
    const regPageUri = event.registration_page

    return regPageUri ? [idFromResourceUri(regPageUri)] : []
})

export const selectWidgetWidthSize = createSelector(selectAugmentedWidgetContent, (event) =>
    event.page_layout_type
        ? DjangIO.app.custom_event.types.CustomEventPageLayout.by_value(event.page_layout_type).column_width
        : 6,
)

export const selectShouldAllowMaybeOption = createSelector(
    selectAugmentedWidgetContent,
    (event) => event.should_allow_maybe_option,
)

export const selectEventCustomHeaderImage = createSelector(
    selectAugmentedWidgetContent,
    (event) => event.custom_header_image,
)

export const selectEventName = createSelector(selectAugmentedWidgetContent, (event) => event.name)

export const selectBossName = createSelector(selectExtra, (extra) => extra.boss_name)

export const selectSourceLink = createSelector(selectExtra, (extra) => extra.source_link)

export const selectLocationSearch = createSelector(
    (_, props) => props.location.search,
    (search) => search,
)

// If the event url contains the url param 'preview=', then the user is previewing the event
// It is okay if this check isn't robust. No additional information can be fetched through this that isn't available in the console
// The 'preview' key is deciphered on the backend to determine if private event information should be accessible
// The key cannot be deciphered from the frontend
export const selectCanPreviewEvent = createSelector(
    selectLocationSearch,
    (search) => search && search.includes("preview="),
)

export const selectCustomFieldNameDict = createSelector(selectExtra, (extra) =>
    extra.custom_fields ? createCustomFieldNameDict(extra.custom_fields) : {},
)

export const selectShouldSelfRsvp = createSelector(selectAugmentedWidgetContent, selectBossName, (content, bossName) =>
    Boolean(
        // If Supporter is not a staffer with a boss, bossName is undefined and form should display self RSVP field
        !bossName ||
            // Or if the event is not set so staffers can only RSVP for their boss, display self RSVP field
            content.staffer_rsvp_permission_type !==
                DjangIO.app.custom_event.types.StafferRsvpPermissionType.member.value,
    ),
)

export const selectShouldBossRsvp = createSelector(selectAugmentedWidgetContent, selectBossName, (content, bossName) =>
    Boolean(
        // If Supporter is a staffer with a boss, then bossName is defined. Meets first condition to display boss RSVP field
        bossName &&
            // If the event is not set so staffers can only RSVP for themselves, meets second condition to display boss RSVP field
            content.staffer_rsvp_permission_type !==
                DjangIO.app.custom_event.types.StafferRsvpPermissionType.staffer.value,
    ),
)

export const selectSelfRsvpInitialValue = createSelector(selectShouldSelfRsvp, selectExtra, (shouldSelfRsvp, extra) =>
    shouldSelfRsvp
        ? // If the user is rsvp-ing for themselves and has not rsvp'd yet, default to 'yes'
          extra.rsvp_status || DjangIO.app.custom_event.types.RSVPStatus.yes.value
        : extra.rsvp_status,
)

export const selectBossRsvpInitialValue = createSelector(selectShouldBossRsvp, selectExtra, (shouldBossRsvp, extra) =>
    shouldBossRsvp
        ? // If the user is rsvp-ing for their boss and has not rsvp'd for their boss yet, default to 'yes'
          extra.boss_status || DjangIO.app.custom_event.types.RSVPStatus.yes.value
        : extra.boss_status,
)

export const selectEventInitialValues = createSelector(
    selectSelfRsvpInitialValue,
    selectBossRsvpInitialValue,
    selectExtra,
    (rsvpStatus, bossStatus, extra) => ({
        rsvpStatus,
        selfTagDict: extra.self_tag_dict,
        bossStatus,
        bossTagDict: extra.boss_tag_dict,
    }),
)

export const selectShouldAutoComplete = createSelector(
    selectEmailRelatedPerson,
    selectCustomFieldNameDict,
    (emailRelatedPerson, customFieldNameDict) => {
        // If the Supporter does not have a related Official or Staffer, that requires them to fill out form manually
        // This is for Staffers to answer questions about if their boss is attending or not
        const onlyRsvpForSelf = Boolean(!emailRelatedPerson)

        // If the event has custom fields the participant needs to answer
        const customFields = Boolean(Object.keys(customFieldNameDict).length)

        return onlyRsvpForSelf && !customFields
    },
)

export const selectSelfAttending = createSelector(selectSelfRsvpStatusFormValue, (rsvpStatus) =>
    [
        DjangIO.app.custom_event.types.RSVPStatus.yes.value,
        DjangIO.app.custom_event.types.RSVPStatus.maybe.value,
    ].includes(rsvpStatus),
)

export const selectBossAttending = createSelector(selectBossRsvpStatusFormValue, (bossStatus) =>
    [
        DjangIO.app.custom_event.types.RSVPStatus.yes.value,
        DjangIO.app.custom_event.types.RSVPStatus.maybe.value,
    ].includes(bossStatus),
)

export const selectEventPassThroughProps = createSelector(
    [
        selectCanPreviewEvent,
        selectIsWidgetContentLoading,
        selectEventInitialValues,
        selectBossName,
        selectShouldSelfRsvp,
        selectShouldBossRsvp,
        selectShouldAllowMaybeOption,
        selectCustomFieldNameDict,
        selectShouldAutoComplete,
        selectSelfAttending,
        selectBossAttending,
        (_, props) => props,
    ],
    (
        canPreviewEvent,
        contentLoading,
        initialValues,
        bossName,
        shouldSelfRsvp,
        shouldBossRsvp,
        shouldAllowMaybeOption,
        customFieldNameDict,
        shouldAutoComplete,
        selfAttending,
        bossAttending,
        props,
    ) => ({
        canPreviewEvent,
        contentLoading,
        initialValues,
        bossName,
        shouldSelfRsvp,
        shouldBossRsvp,
        shouldAllowMaybeOption,
        customFieldNameDict,
        shouldAutoComplete,
        selfAttending,
        bossAttending,
        event: props.content,
        isEmbedded: props.isEmbedded,
        hasLoaded: props.hasLoaded,
        uniqueWidgetId: props.uniqueWidgetId,
    }),
)

export const selectEventThanksMessage = createSelector(
    selectAugmentedWidgetContent,
    selectWidgetPostSubmitData,
    (content, postSubmitData) => {
        const { RSVPStatus } = DjangIO.app.custom_event.types

        // Get contact's personal rsvp status, and if they are a staffer rsvp-ing for their boss, the boss' rsvp status
        const { rsvpStatus, bossStatus } = postSubmitData

        // With the status values, get the RSVPStatus enum
        const rsvpStatusEnum = RSVPStatus.by_value(rsvpStatus)
        const bossStatusEnum = RSVPStatus.by_value(bossStatus)

        // Given the RSVPStatus enums, we sort so the order of the array is 'Yes' -> 'Maybe' -> 'No' -> undefined (edge case)
        // Save the first enum of the sorted array
        const rsvpStatusToShow = [rsvpStatusEnum, bossStatusEnum].sort(DjangIO.orderSort)[0]

        // For the RSVPStatus enum, save the 'custom_event_field' value (the field CustomEvent related to this status)
        const fieldName = rsvpStatusToShow && rsvpStatusToShow.custom_event_field

        // If there is an RSVP status and the thank you message is defined, show the corresponding thank you message. Otherwise, show a default message
        const text = (fieldName && content[fieldName]) || i18n.t("event.form.thanks.default_message")

        return text
    },
)

export const selectOrgDesignBackgroundImage = createSelector(
    selectOrganizationDesign,
    (orgDesign) => orgDesign.background_image,
)

export const selectOrgName = createSelector(selectOrganization, (org) => org.name)

export const selectOrgLogo = createSelector(selectOrganization, (org) => org.logo)

export const selectSocialLinkImage = createSelector(
    selectEventCustomHeaderImage,
    selectOrgDesignBackgroundImage,
    selectOrgLogo,
    (eventCustomHeaderImage, orgDesignBackgroundImage, orgLogo) =>
        eventCustomHeaderImage || orgDesignBackgroundImage || orgLogo,
)

export const selectActionCenterSettingsTwitterHandle = createSelector(
    selectActionCenterSettings,
    (acs) => acs.twitter_handle,
)

export const selectThanksSocialLinks = createSelector(
    [
        selectAugmentedWidgetContent,
        selectActionCenterSettingsTwitterHandle,
        selectOrgName,
        selectSourceLink,
        selectSocialLinkImage,
    ],
    (event, acsTwitterHandle, orgName, url, media) => {
        if (!Object.keys(event).length) {
            // If event not loaded, return undefined
            return
        }

        const i18n_key = "event.thanks.share.default"

        return event.social_media_share_platforms.map((platformType) => {
            const platform = DjangIO.app.grassroots.enums.SocialMediaType.by_value(platformType)

            const submitFunc = socialMediaShareMap[platformType]

            const socialMediaName =
                platformType === DjangIO.app.grassroots.enums.SocialMediaType.twitter.value && acsTwitterHandle
                    ? `@${acsTwitterHandle}`
                    : orgName

            const text = i18n.t(i18n_key, { organization: socialMediaName })

            return {
                onClick: () => submitFunc({ url, media, text }),
                label: i18n.t("campaign.thanks.share.button", { platform: platform.label }),
                icon: platform.icon,
                color: platform.color,
                platform: platformType,
                dataCy: platform.label,
            }
        })
    },
)

export const selectEventLink = createSelector(
    selectAugmentedWidgetContent,
    (event) =>
        `${DjangIO.app.grassroots.types.GrassrootsWidgetType.event.widget_url}${event.vanity_url_slug || event.id}/`,
)

export const selectEventThanksProps = createSelector(
    selectAugmentedWidgetContent,
    selectEventLink,
    selectEventThanksMessage,
    selectThanksSocialLinks,
    selectCanPreviewEvent,
    selectLocationSearch,
    (event, eventLink, text, socialLinks, preview, locationSearch) => {
        return {
            text,
            socialDetails: event.publicly_accessible && socialLinks,
            buttonDetails: [
                {
                    label: i18n.t("event.form.thanks.all_events"),
                    link: DjangIO.app.grassroots.types.GrassrootsWidgetType.event_list.widget_url,
                },
                {
                    label: i18n.t("event.form.thanks.change_rsvp"),
                    link: preview ? `${eventLink}${locationSearch}` : eventLink,
                },
            ],
            preview,
        }
    },
)

export const selectPrivateEvent = createSelector(
    selectAugmentedWidgetContent,
    (content) => content && !content.publicly_accessible,
)

export const eventWidgetConnection = createStructuredSelector({
    backgroundColor: selectBackgroundColor,
    canPreviewEvent: selectCanPreviewEvent,
    emailRelatedPerson: selectEmailRelatedPerson,
    eventPassThroughProps: selectEventPassThroughProps,
    loggedIn: selectUserLoggedIn,
    pageTitle: createPageTitleSelector(selectEventName),
    primaryColor: selectPrimaryColor,
    privateEvent: selectPrivateEvent,
    registrationPageIds: selectRegistrationPageIds,
    shouldThank: selectWidgetSubmitted,
    widgetWidthSize: selectWidgetWidthSize,
})

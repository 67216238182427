import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"
import { reduxForm } from "redux-form/immutable"

import { recoverAccount } from "QuorumGrassroots/framework/action-creators"

import ForgotPasswordPage from "QuorumGrassroots/framework/components/Foundations/ForgotPasswordPage"

const submit = (values, dispatch, props) => {
    return dispatch(recoverAccount(values, props))
}

const form = reduxForm({
    form: "forgot_password",
    onSubmit: submit,
})(ForgotPasswordPage)

export default withNamespaces()(form)

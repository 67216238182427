import styled from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const ColorInputFieldContainer = styled.div`
    border: 2px solid rgb(17, 24, 39);
    border-radius: 50px;
    height: 24px;
    margin: 0px 10px;
    overflow: hidden;
    position: relative;
    width: 24px;
`

export const Label = styled.span`
    ${rules.SmallMediumText}
    color: ${colors.QuorumBlue};
    margin-bottom: 7px;
`

export const LabelError = styled.span`
    ${rules.SmallText}
    color: ${colors.ReduxFormErrorColor};
    margin-bottom: 7px;
`

export const ColorInputField = styled.input`
    -webkit-appearance: none;
    border: ${({error}) => error ? colors.ReduxFormErrorColor : colors.SilverChalice} solid 1px;
    
    border: 0px none;
    cursor: pointer;
    height: 200%;
    padding: 0px;
    transform: translate(-25%, -25%);
    width: 200%;

    // https://stackoverflow.com/a/11471224/6201291
    &::-webkit-color-swatch-wrapper {
        padding: 0;
    }

    &::-webkit-color-swatch {
        border-radius: 1.74px;
    }
`

/**
 * Takes an array, a comparator, and an item; returns the leftmost
 * item of the array that satisfies the comparator for the given item
 *
 * @param   {Array} breakpointArray an array of objects
 * @param   {Function} comparator   a function called with (item, breakpoint) that should return
 *                                  true iff the item satisfies the breakpoint's condition
 * @param   {Object} item           an item to find the matching breakpoint for
 *
 * @returns {Object}    the leftmost object in the breakpointArray for which the comparator is satisfied;
 *                      returns undefined if no breakpoint is satisfied
 */
export const breakpointEvaluator = ({breakpointsArray, comparator, item}) => (
    breakpointsArray.find(breakpoint => comparator(item, breakpoint))
)

/**
 * Takes a string and a breakpoint object with a "characters" fields and returns
 * true if there is no text or the length of the text is less than the breakpoint's
 * characters field
 *
 * @param   {String} text   a string to compare to the breakpoint
 * @param   {Object} breakpointObj  an object with a field named exactly "characters" to compare
 *                                  to the string
 *
 * @returns {Boolean}   true if there is no text or if the length of the text is less than the
 *                      breakpointObj.characters field
 */
export const textBreakpointComparator = (text, breakpointObj) => (
    // return the smallest breakpoint (always true) if we don't have text
    // otherwise check if we are smaller than the next breakpoint's characters
    !(text && text.length) || text.length < breakpointObj.characters
)

/**
 * Compute the width of text given a font size
 * @param {string} font - a CSS font description, such as:
 *    "normal normal 300 16px Helvetica"
 * @param {string} text - text to compute width of
 * @returns {Integer} - the width of the text (multiplied by the number of lines)
 */
export const calculateTextWidth = (font, text) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext("2d")
    ctx.font = font

    if (!text || !text.indexOf || text.indexOf("\n") === -1) {
        return ctx.measureText(text).width
    }

    else {
        return ctx.measureText(text).width * text.split("\n").length
    }
}

/**
 * Return the proper color stroke for a counter component.
 * @param {bool} isCompleted - true if counter is displaying 100% or a completed value
 * @param {string} completeColor - color to display if the counter is completed
 * @param {string} primaryColor - default color of the component
 * @param {string} secondaryColor - secondary color of the component
 * @returns {string} - the width of the text (multiplied by the number of lines)
 */
export const counterStrokeColor = ({ isCompleted, completeColor, primaryColor, secondaryColor }) => {
    if (isCompleted && completeColor) {
        return completeColor
    }
    else if (secondaryColor) {
        return secondaryColor
    }
    return primaryColor
}

/**
 * Return a number formatted with commas every third digit from the right
 * @param {Number} rawNumber
 * @returns {String} - the width of the text (multiplied by the number of lines)
 *
 * Ex. 12345678 -> "12,345,678"
 */
export const formatNumberWithCommas = (rawNumber) =>
    rawNumber && rawNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

/**
 * Determines if the browser being used is Internet Explorer 11 based on the user
 * agent string.
 *
 * @returns {Boolean}   returns true if the user's browser is Internet Explorer 11
 */
export const isUserIE11 = () => window.navigator.userAgent.indexOf("Trident/7") > -1


/**
* This function allows splitting a collection into 2 subsets based on the given predicate
* ex: const [positiveNumbers, negativeNumbers] = bifilter(x => x >= 0, [1, 5, -10, 3, -1])
* @name bifilter
* @function
* @param {Function} fn - The predicate applied to each item in the collection
* @param {Array} items - The collection to be operated on
* @returns {Array} - An array pair, where the items in the first Array are Truthy, and the second are Falsy
*/
export const bifilter = (fn, items=[]) => {
    const truthy = []
    const falsy = []
    items.forEach(value => fn(value) ? truthy.push(value) : falsy.push(value))
    return [truthy, falsy]
}

/**
* This function escapes common regex characters
* @name escapeRegExp
* @function
* @param {string} string - The string to be escaped
* @returns {string} - The escaped string
*/
// https://github.com/lodash/lodash/blob/master/escapeRegExp.js
export const escapeRegExp = (string) => {
    const reRegExpChar = /[\\^$.*+?()[\]{}|]/g
    const reHasRegExpChar = RegExp(reRegExpChar.source)

    return (
        (string && reHasRegExpChar.test(string))
            ? string.replace(reRegExpChar, "\\$&")
            : (string || "")
    )
}

/**
* This function generates a random string
* @name uniqueId
* @function
* @param {Number} length - the expected length of uniqueId
* @returns {string} - The random string
*/
export const uniqueId = (length=6) => {
    let result = "";
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

/**
 * Formats a number for display by abbreviating it with suffixes like 'K', 'M', 'B', 'T', or 'Q'.
 *
 * @name formatNumberForDisplay
 * @param {Number} rawNumber - The original number to format.
 * @param {Number} [decimalValues=1] - The number of decimal places to include.
 * @param {Number} [threshold=1e4] - The minimum value before abbreviations are applied.
 * @param {Boolean} [addTilde=false] - Whether to prepend a tilde (~) to indicate an approximate value.
 * @param {Number} [substringLength=4] - Maximum length of the truncated number string (including decimals).
 * @param {Boolean} [radix=true] - Whether to use the period as a decimal separator. If false, a comma is used instead.
 * @returns {string} - The formatted number as a string, possibly abbreviated with a suffix (K, M, B, T, Q).
 */
export const formatNumberForDisplay = (
    rawNumber,
    decimalValues = 1,
    threshold = 1e4,
    addTilde = false,
    substringLength = 4,
    radix = true,
) => {
    let number = rawNumber * 1
    number = +number.toFixed(decimalValues)
    if (number < threshold) {
        return number.toLocaleString()
    }

    const roundUpLastStringDigitIfPossible = (
        num, // Add just enough to round eventual final digit up if appropriate.
    ) => num + 5 * Math.pow(10, Math.floor(Math.log10(number)) - (substringLength - 1))
    const truncateNumString = (number, symbol) => {
        let numString = number.toString().substr(0, substringLength)
        if (numString.slice(-1) === ".") {
            // To prevent edgecase of things like 100.K
            numString = numString.substr(0, substringLength - 1)
        }
        if (!radix) {
            numString = numString.replace(".", ",") // Outside US and GB, countries use , as decimals.
        }
        return `${addTilde ? "~" : ""}${numString}${symbol}`
    }

    number = roundUpLastStringDigitIfPossible(number)
    switch (true) {
        case number < 1e6:
            number /= 1e3
            return truncateNumString(number, "K")
        case number < 1e9:
            number /= 1e6
            return truncateNumString(number, "M")
        case number < 1e12:
            number /= 1e9
            return truncateNumString(number, "B")
        case number < 1e15:
            number /= 1e12
            return truncateNumString(number, "T")
        case number >= 1e15:
            number /= 1e15
            return truncateNumString(number, "Q")
    }
}

import { fullWidthWidgetConnection } from "QuorumGrassroots/widgets/selectors"
import { createSelector, createStructuredSelector } from "reselect"
import { fromJS } from "immutable"

export const selectContributions = createSelector(() => {
    return fromJS(Userdata.donation_history)
})

export const selectTransactionTotalsByYear = createSelector(() => {
    const totalsByYear = Userdata.total_amount_by_year
    if (totalsByYear) {
        return totalsByYear
    }
    // If there are no transactions at all, just show current year with 0.
    const currentYear = new Date().getFullYear()
    return [{ year: currentYear.toString(), value: 0 }]
})

export const selectLastTransactionAmount = createSelector(() => {
    return fromJS(Userdata.last_transaction_amount) || 0
})
export const selectLastTransactionDate = createSelector(() => {
    return fromJS(Userdata.last_transaction_date) || "Never"
})
export const selectLastTransactionMethod = createSelector(() => {
    return fromJS(Userdata.last_transaction_method) || "None"
})

export const selectContributionsByYear = createSelector(selectContributions, (contributions) => {
    if (contributions.size) {
        const res = Object.values(
            contributions.reduce((final, current) => {
                const created = new Date(current.get("created")).getFullYear().toString()
                if (Object.keys(final).includes(created)) {
                    final[created].value += current.get("amount")
                } else final[created] = { year: created, value: current.get("amount") }
                return final
            }, {}),
        )
        return res
    }
    const currentYear = new Date().getFullYear()
    return [{ value: 0, year: currentYear }]
})

export const selectLastContribution = createSelector(selectContributions, (contributions) => {
    if (contributions.size) {
        return contributions.first()
    }
    return null
})
export const selectLastContributionDate = createSelector(
    selectLastContribution,
    (contribution) => contribution && contribution.get("created"),
)
export const selectLastContributionMethod = createSelector(selectLastContribution, (contribution) =>
    contribution ? contribution.get("method") : "None",
)
export const selectLastContributionValue = createSelector(
    selectLastContribution,
    (contribution) => contribution && contribution.get("amount"),
)

export const donationHistoryWidgetConnection = createStructuredSelector({
    contributionsByYear: selectContributionsByYear,
    lastContributionDate: selectLastContributionDate,
    lastContributionMethod: selectLastContributionMethod,
    lastContributionValue: selectLastContributionValue,
    lastTransactionAmount: selectLastTransactionAmount,
    lastTransactionDate: selectLastTransactionDate,
    lastTransactionMethod: selectLastTransactionMethod,
    transactionTotalsByYear: selectTransactionTotalsByYear,
    ...fullWidthWidgetConnection,
})

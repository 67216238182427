import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/DropdownSelect/style"
import Button from "Components/Molecules/Button"
import SelectWithRemovableValues from "Components/Compounds/SelectWithRemovableValues"

const DropdownSelect = ({
    dataCy,
    buttonIcon,
    buttonIconFamily,
    buttonOnClickCallback,
    buttonType,
    buttonText,
    buttonMaxTextWidth,
    clickOutsideRef,
    isPopUpOpen,
    maxWidth,
    SelectComponent,
    togglePopupIsOpen,
    values,
    valueStripOnClick,
    valueStripRemove,
}) => (
    <S.DropdownSelect
        data-auto-cy="CompoundDropdownSelect"
        data-cy={dataCy}
        maxWidth={maxWidth}
        ref={clickOutsideRef}
    >
        <Button
            leftIcon={buttonIcon}
            leftIconFamily={buttonIconFamily}
            rightIcon={
                isPopUpOpen
                    ? "angle-up"
                    : "angle-down"
            }
            rightIconFamily="far"
            rightIconFixedWidth={false}
            rightIconSize="16px"
            rightIconHorizontalOffset="4px"
            rightIconVerticalOffset="1px"
            rightPadding="11px"
            isTiny
            onClick={() => {
                togglePopupIsOpen()
                return buttonOnClickCallback && buttonOnClickCallback()
            }}
            type={buttonType}
            text={buttonText}
            textOverflow="ellipsis"
            maxTextWidth={buttonMaxTextWidth}
        />
        {
            isPopUpOpen && (
                <SelectWithRemovableValues
                    dataCy="select-with-removable-values"
                    SelectComponent={SelectComponent}
                    values={values}
                    valueStripOnClick={valueStripOnClick}
                    valueStripRemove={valueStripRemove}
                />
            )
        }
    </S.DropdownSelect>
)

DropdownSelect.defaultProps = {
    buttonType: "tertiary",
}

DropdownSelect.propTypes = {
    dataCy: PropTypes.string,
    buttonIcon: PropTypes.string,
    buttonIconFamily: PropTypes.string,
    buttonOnClickCallback: PropTypes.func,
    buttonType: PropTypes.string,
    buttonText: PropTypes.string,
    buttonMaxTextWidth: PropTypes.string,
    clickOutsideRef: PropTypes.object,
    isPopUpOpen: PropTypes.bool,
    maxWidth: PropTypes.string,
    SelectComponent: PropTypes.element,
    selectOnChange: PropTypes.func,
    selectPermanentFilter: PropTypes.object,
    values: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        displayText: PropTypes.string
    })),
    valueStripOnClick: PropTypes.func,
    valueStripRemove: PropTypes.func,
}

export default DropdownSelect

import { makePieActionObjs } from "shared/imports/pieActionGenerators"
import { prependAppName } from "shared/imports/sharedHelperFunctions"

const BulkActionsActionTypes = {
    ADD_ID: "ADD_ID",
    BULK_ACTIONS_SELECT_ALL: "BULK_ACTIONS_SELECT_ALL",
    CLEAR_BULK_ACTIONS_SLICE: "CLEAR_BULK_ACTIONS_SLICE",
    CLEAR_BULK_ACTIONS_SELECTIONS: "CLEAR_BULK_ACTIONS_SELECTIONS",
    CREATE_BULK_ACTIONS_FORM: "CREATE_BULK_ACTIONS_FORM",
    DELETE_ID: "DELETE_ID",
    DISABLE_BULK_ACTIONS_MODE: "DISABLE_BULK_ACTIONS_MODE",
    INITIALIZE_BULK_ACTIONS_SLICE: "INITIALIZE_BULK_ACTIONS_SLICE",
    SUBMIT_BULK_ACTIONS_SELECTIONS_FAIL: "SUBMIT_BULK_ACTIONS_SELECTIONS_FAIL",
    SUBMIT_BULK_ACTIONS_SELECTIONS_START: "SUBMIT_BULK_ACTIONS_SELECTIONS_START",
    SUBMIT_BULK_ACTIONS_SELECTIONS_SUCCESS: "SUBMIT_BULK_ACTIONS_SELECTIONS_SUCCESS",
    UPDATE_FILTER: "UPDATE_FILTER",
    UPDATE_SIDEBAR_BUTTON: "UPDATE_SIDEBAR_BUTTON",
}

export default BulkActionsActionTypes

// async action types
const appName = "@@searchBulkAction/"

const fetchBulkActionObjs = makePieActionObjs("fetch", "bulk_action")
const postBulkActionObjs = makePieActionObjs("post", "bulk_action")

const fetchSafedSearchObjs = makePieActionObjs("fetch", "safed_search")
const postSafedSearchObjs = makePieActionObjs("post", "safed_search")

export const fetchBulkActionActionTypes = prependAppName(
    appName,
    fetchBulkActionObjs.pieDict
)

export const postBulkActionActionTypes = prependAppName(
    appName,
    postBulkActionObjs.pieDict
)

export const fetchSafedSearchActionTypes = prependAppName(
    appName,
    fetchSafedSearchObjs.pieDict
)
export const postSafedSearchActionTypes = prependAppName(
    appName,
    postSafedSearchObjs.pieDict
)

export const actionTypes = prependAppName(
    appName,
    {
        ...fetchBulkActionObjs.actionTypes,
        ...postBulkActionObjs.actionTypes,
        ...fetchSafedSearchObjs.actionTypes,
        ...postSafedSearchObjs.actionTypes,
    }
)

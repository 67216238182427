import { createSelector, createStructuredSelector } from "reselect"

import {
    selectImmutableCampaignMessages,
    selectExtraMessageData,
    flattenMessageMap,
} from "QuorumGrassroots/campaign-forms/selectors/general-selectors"

// the point of this guy is to take the messages which are involved
// in different campaigns, and then format them so messages appear
// grouped under their respective campaign, with a label. So the format
// should be [{ label: label, messages: [] }, ...]
export const selectCombinedCampaignMessages = createSelector(
    [selectImmutableCampaignMessages, selectExtraMessageData],
    (messages, extraData) => {
        return extraData.reduce((acc, campaignData, campaignId) => {
            const campaignMessages = messages.filter((message) => message.get("campaign_id").toString() === campaignId)
            const flattenedMessages = flattenMessageMap(campaignMessages).toJS()
            const customMessageGroups = {}
            const messagesWithUniqueCustomEmails = flattenedMessages.reduce((acc, message) => {
                if (message.group) {
                    const groupId = message.group.id
                    const targetId = message.target.id //this can be a number or a string like custom-12341234 🫠
                    if (typeof targetId === "string" && targetId.includes("custom")) {
                        if (!customMessageGroups[groupId]) {
                            acc.push(message)
                            customMessageGroups[groupId] = true
                        }
                    } else {
                        acc.push(message)
                    }
                }
                return acc
            }, [])
            const messageObj = {
                label: campaignData.get("name"),
                campaignType: campaignData.get("campaign_type"),
                draftRequirements: campaignData.get("draft_requirements"),
                messages: messagesWithUniqueCustomEmails.length ? messagesWithUniqueCustomEmails : flattenedMessages, //only show one message if it's custom emails
            }

            acc.push(messageObj)

            return acc
        }, [])
    },
)

export const combinedCampaignSelectors = createStructuredSelector({
    combinedCampaignMessages: selectCombinedCampaignMessages,
})

import { makePieActionObjs } from "shared/imports/pieActionGenerators"

const loadWidgetContentActions = makePieActionObjs("load", "widget_content")

export const actionTypes = {
    INITIALIZE_WIDGET_SLICE: "INITIALIZE_WIDGET_SLICE",
    RESET_WIDGET_SLICES: "RESET_WIDGET_SLICES",
    DESTROY_WIDGET_SLICE: "DESTROY_WIDGET_SLICE",
    DESTROY_ALL_WIDGET_SLICES: "DESTROY_ALL_WIDGET_SLICES",
    SUCCESSFULLY_SUBMITTED_WIDGET: "SUCCESSFULLY_SUBMITTED_WIDGET",
    RESET_SUBMITTED_WIDGET: "RESET_SUBMITTED_WIDGET",
    SET_POST_SUBMIT_DATA: "SET_POST_SUBMIT_DATA",
    COMPLETE_REQUIRED_VIDEO: "COMPLETE_REQUIRED_VIDEO",
    ...loadWidgetContentActions.actionTypes,
}

export const loadWidgetContentActionTypes = loadWidgetContentActions.pieDict

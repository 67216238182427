import React from 'react'
import PropTypes from 'prop-types'
import { useOffsetWidth, usePaddingItemCount } from 'utils/hooks'

import * as S from 'Components/Compounds/TemplateOptions/style'
import TemplateCard from 'Components/Molecules/TemplateCard'
import SmoothTemplateCard from 'Components/Molecules/SmoothTemplateCard'


const TemplateOptions = ({ templates, selectTemplate, cardSize, templateCount, smooth = false }) => {
    // template options has a number of Templates, all of which should have the same width, and have each row be left aligned,
    // but each template needs to be aligned within its row. To achieve this, we are going to steal a thing
    const [templateRef, templateWidth] = useOffsetWidth()
    const width = templateWidth + S.margin*2
    const [ref, extraTemplates] = usePaddingItemCount(
        width,
        templateCount || templates.length,
        // just like width, as padding is applied to each side, we need to double it.
        S.padding*2
    )

    if (smooth)
        return (
            <S.SmoothContainer>
                {templates.map((template, idx) => (
                    <SmoothTemplateCard
                        key={template.id}
                        selectTemplate={() => selectTemplate(template)}
                        {...template}
                    >
                        {template.text}
                    </SmoothTemplateCard>
                ))}
            </S.SmoothContainer>
        )

    return <S.OptionsContainer
        data-auto-cy="CompoundTemplateOptions"
        ref={ref}
    >
        {
            templates.map((template, idx) => (
                <S.Template
                    key={template.id}
                    // attach ref for measuring width to the first template
                    ref={idx === 0 ? templateRef : null}
                >
                    <TemplateCard
                        size={cardSize}
                        selectTemplate={() => selectTemplate(template)}
                        {...template}
                    >
                        {template.text}
                    </TemplateCard>
                </S.Template>
            ))
        }
        {
            extraTemplates >= 1
                ? [...Array(extraTemplates)].map((t, i) => <S.Template key={i} />)
                : null
        }
    </S.OptionsContainer>
}

TemplateOptions.defaultProps = {}

TemplateOptions.propTypes = {
    cardSize: PropTypes.oneOf(['small', 'medium']),
    templates: PropTypes.array.isRequired,
    selectTemplate: PropTypes.func.isRequired,
    templateCount: PropTypes.number,
    smooth: PropTypes.bool,
}

export default TemplateOptions

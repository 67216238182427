import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #e9e9e9;
    padding: 40px 0px;
    margin-bottom: 30px;
    border-radius: 4px;
`

export const FlagContainer = styled.div`
    margin-left: 135px;
    font-size: 54px;
    width: 89px;
    height: 89px;
    color: #4d2d5f;
    line-height: 70px;
    border: 3px solid #95a5a6;
    border-radius: 100%;
    text-align: center;
`

export const RegionNameContainer = styled.div`
    margin-left: 15px;
    display: flex;
    flex-direction: column;
`

export const RegionName = styled.h1`
    font-size: 40px;
    font-weight: 700;
    color: #000000;
    font-family: Helvetica;
`

export const DistrictName = styled.h2`
    font-size: 32px;
    font-weight: 400;
    color: #000000;
    font-family: Helvetica;
    margin-top: 0px;
`

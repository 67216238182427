/* eslint-disable */
import { fromJS, Map } from "immutable"
import actionTypes, { getCacheTypes } from "shared/api-caching/action-types"

import { convertListResponseToDetailResponses, cleanCacheKeys } from "shared/api-caching/functions"

export const getInitialState = (kwargs      = {}) =>
    fromJS({
        resourceUpdateTimestamps: {},
        cache: {},
    }).merge(kwargs)

const initialState = getInitialState()

const handlers = {
    [getCacheTypes.start](
        state                    ,
        action   
                             
                       
         
    ) {
        const { endpoint, url } = action

        return state
    },
    [getCacheTypes.success](
        state                    ,
        action   
                                            
                             
                       
         
    ) {
        const { response, endpoint, url } = action
        // Cache all individual objects as detail requests by their endpoints
        let detail = response?.objects
            // Convert list response to individual detail response objects
            ? convertListResponseToDetailResponses(response.objects.map(object => ({ ...object, isLoading: false })), endpoint)
            // Add individual detail request
            : response.id ? { [endpoint]: { ...response, isLoading: false } } : {}

        /*
            Because to get stats we hit the Person or FullBillTerm endpoint, it
            is not being cached properly because the "endpoint" is the same as
            the one we hit to get the info we need to load the data on the profile.
            Thus, we need to cache the stats by the particular request it is making
            to the stats model by url.
        */
        if (url.includes("person_stats") || url.includes("issue_stats")) {
            detail = { [url]: { ...response, isLoading: false } }
        }
        // Cache list request by url
        const list = response.objects
            ? { [url]: response }
            : {}

        // Cache count
        const count = url.includes("count_only")
            ? { [url]: response }
            : {}

        // every time we get a new object, we want to replace the old object entirely.
        // don't use mergeDeep or you're going to get some weird stuff when
        // you try to merge { a: { b: [300002] } } with { a: { b: [] } } -- i.e.,
        // instead of { a: { b: [] }} as we'd want, we'll get { a: { b: [300002] } }
        // because I guess that's the default behavior for mergeDeep and empty arrays
        // and objects.
        return state.update("cache", cache =>
            cache
                .merge(cleanCacheKeys(detail))
                .merge(cleanCacheKeys(list))
                .merge(cleanCacheKeys(count))
        )
    },
    [actionTypes.INVALIDATE_CACHE_SLICE](
        state                    ,
        action   
                                     
                                    
         
    ) {
        return state.update("cache", cache =>
            cache.filterNot((value, key) => {
                const isList = Map.isMap(value) && value.has("objects")
                const isCount = typeof(value) === "number"

                let shouldClear = (isCount || isList) && action.endpoints.some(endpoint => key.startsWith(endpoint))

                if (shouldClear && action.shouldClearFn) {
                    shouldClear = action.shouldClearFn(key)
                }

                return shouldClear
            })
        )
    },
    [actionTypes.MARK_RESOURCE_AS_UPDATED](
        state                    ,
        action   
                                     
                              
         
    ) {
        const updatedItems = action.endpoints.reduce((acc, endpoint) => {
            acc[endpoint] = action.timestamp
            return acc
        }, {})

        return state.update("resourceUpdateTimestamps", items =>
            items.merge(updatedItems)
        )
    },
    [actionTypes.INVALIDATE_CACHE](
        state                    ,
        action     
    ) {
        return getInitialState()
    },
    // in the process of being phased out!!!
    [actionTypes.INVALIDATE_CACHE_SLICE_DEPRECATED](
        state                    ,
        action   
                                     
         
    ) {
        // if it is a list response (i.e., has 'objects') and the URL contains one of
        // our resource endpoints, we should remove it (because a new element was added, so the
        // whole thing should be refreshed)
        return state.update("cache", cache =>
            cache.filterNot((value, key) => {
                const isList = Map.isMap(value) && value.has("objects")
                const isCount = typeof(value) === "number"
                return (isCount || isList) && action.endpoints.some(endpoint => key.startsWith(endpoint))
            })
        )
    }
}


function reducer(state                      = initialState, action                  ) {
    const returnVal =  handlers.hasOwnProperty(action.type)
        ? handlers[action.type](state, action)
        : state

    // if (!action.type.includes('@@redux')) {
    //     console.log('ACTION:', action)
    //     console.log('NEW STATE:', returnVal.toJS())
    // }

    return returnVal
}

export default reducer

import React from "react"
import PropTypes from "prop-types"
import { useTable, useSortBy, useGlobalFilter } from "react-table"
import { StyledTable, StyledTableWrapper } from "QuorumGrassroots/styled-components/components/StyledTable"
import { StyledInput } from "QuorumGrassroots/styled-components/components/StyledInput"

export const CustomDataWidgetListView = (props) => {
    const columns = React.useMemo(
        () => props.columns.map((name) => ({ Header: name, accessor: name })),
        [props.columns],
    )
    const data = React.useMemo(() => props.data ?? [], [props.data])
    const defaultSort = React.useMemo(
        () => [
            {
                // Sorts the first non-null, non-undefined column
                id: props.columns.find(() => true),
                desc: false,
            },
        ],
        [props.columns],
    )

    const tableInstance = useTable(
        {
            data: data,
            columns,
            initialState: {
                sortBy: defaultSort,
            },
        },
        useGlobalFilter,
        useSortBy,
    )

    if (!props.data) {
        return null
    }

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = tableInstance
    const { globalFilter } = state
    return (
        <StyledTableWrapper maxTableHeight={props.maxTableHeight}>
            <StyledInput
                value={globalFilter || ""}
                onChange={(change) => setGlobalFilter(change.target.value)}
                placeholder="Search..."
            />
            <StyledTable striped bordered responsive {...props.tableStyleProps} {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    <span>{column.isSorted ? (column.isSortedDesc ? " ▲" : " ▼") : ""}</span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </StyledTable>
        </StyledTableWrapper>
    )
}

CustomDataWidgetListView.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.arrayOf(PropTypes.object),
    tableStyleProps: PropTypes.arrayOf(
        PropTypes.shape({
            hover: PropTypes.bool,
        }),
    ),
}

export default CustomDataWidgetListView

import { WriteMemberForm } from "QuorumGrassroots/campaign-forms/components/WriteMemberForm"
import { getMessageFormWrapper } from "QuorumGrassroots/campaign-forms/wrappers/GetMessagesFormWrapper"
import { generateGrassrootsActionsWithImmutableValues } from "QuorumGrassroots/campaign-forms/helpers"
import { generateWriteAMemberActions } from "QuorumGrassroots/campaign-forms/grassrootsActionGenerators"

export default getMessageFormWrapper({
    formName: "write_member",
    generateGrassrootsActions: (messageValues, globalFormValues, props) =>
        generateGrassrootsActionsWithImmutableValues(
            generateWriteAMemberActions,
            messageValues,
            globalFormValues,
            props,
        ),
    getIdFromMessage: (message) => message.target.id.toString(),
})(WriteMemberForm)

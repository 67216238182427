import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"


export const TrendsWidgetItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    width: 100%;
    border-bottom: 1px solid ${colors.QuorumGrey1};
    padding: 10px 0;
`

export const MainText = styled.div`
    ${rules.SmallTextBold}
`

export const SubText = styled.div`
    ${rules.MicroscopicText}
    font-weight: ${rules.weight.thin};
    color: ${colors.QuorumGrey3};
    margin-right: 5px;
    font-size: 13px;
    margin-top: 7px;
`

export const SubTextContainer = styled.div`
    display: flex;
`

import { fromJS } from "immutable"
import { createSelector } from "reselect"
import { makeImmutableSliceSelector } from "shared/imports/sharedHelperFunctions"
import { generateCheckInputFields } from "app/static/frontend/pac/helperFunctions"
import {
    findLedgerAccountByAccountType,
    centsToCurrencyStr,
    isReceiptTransaction,
    isDisbursementTransaction,
} from "shared/pac/helperFunctions"
import moment from "moment"

const { LedgerAccountType } = DjangIO.app.ledger.types

export const pacSelector = (state) => state.pac

export const selectTransaction = (transactionIdSelector) =>
    createSelector(pacSelector, transactionIdSelector, (state, transactionId) =>
        state.getIn(["transaction", transactionId], fromJS({})),
    )

export const selectNextFilingCoverageStart = createSelector(
    pacSelector,
    (state) =>
        state &&
        (state.getIn(["latestFiling", "coverage_end_date"]) !== undefined
            ? moment(state.getIn(["latestFiling", "coverage_end_date"]), "YYYY-MM-DD")
                  .add(1, "days")
                  .format("YYYY-MM-DD")
            : // set the coverage start as the first day of the current year when no PAC file is found
              `${moment().year().toString()}-01-01`),
)

export const selectGeneralElection = createSelector(pacSelector, (state) => {
    const generalElection = state ? state.get("generalElection", fromJS({})) : fromJS({})
    return generalElection.toJS()
})

export const selectJurisdictions = createSelector(pacSelector, (state) => {
    const jurisdictions = state ? state.get("jurisdictions", fromJS([])) : fromJS([])
    return jurisdictions.toJS()
})

export const selectLedgerSettingsList = createSelector(pacSelector, (state) =>
    state ? state.get("ledgerSettings", fromJS([])) : fromJS([]),
)

export const selectFirstLedgerSettings = createSelector(selectLedgerSettingsList, (ledgerSettingsList) =>
    ledgerSettingsList.size > 0 ? ledgerSettingsList.first() : undefined,
)

export const selectFirstLedgerSettingsId = createSelector(selectFirstLedgerSettings, (ledgerSettingsFirst) =>
    ledgerSettingsFirst?.get("id"),
)

export const selectFirstLedgerSettingsLabel = createSelector(
    selectFirstLedgerSettings,
    (ledgerSettingsFirst) => ledgerSettingsFirst?.get("pacronym") || ledgerSettingsFirst?.get("name"),
)

export const selectHasMultipleLedgerSettings = createSelector(selectLedgerSettingsList, (ledgerSettingsList) =>
    Boolean(ledgerSettingsList && ledgerSettingsList.size > 1),
)

/**
 * This selector returns all LedgerSettings ids in a list
 * @function
 * @param {List[Map]} ledgerSettingslist - a List of LedgerSettings Maps
 * @returns {Array} - List of LedgerSettings Ids
 */
export const selectLedgerSettingsListIdsImmutable = createSelector(
    selectLedgerSettingsList,
    (ledgerSettingsList) => ledgerSettingsList && ledgerSettingsList.map((ledgerSettings) => ledgerSettings.get("id")),
)

export const selectLedgerSettingsListIds = createSelector(
    selectLedgerSettingsListIdsImmutable,
    (ledgerSettingsListIds) => ledgerSettingsListIds && ledgerSettingsListIds.toJS(),
)

export const createDatumSelector = (fieldName) =>
    createSelector(
        (_, props) => props.datum,
        (datum) => datum && datum.get(fieldName),
    )

export const createParamsSelector = (fieldName) =>
    createSelector(
        (_, props) => props.params,
        (params) => params && params[fieldName],
    )

export const selectLedgerSettingsId = createSelector(
    createParamsSelector("ledgerSettingsId"),
    createDatumSelector("ledger_settings_id"),
    (ledgerSettingsIdParam, ledgerSettingsIdDatum) => parseInt(ledgerSettingsIdParam || ledgerSettingsIdDatum),
)
export const selectFilingsId = createParamsSelector("filingsId")

export const selectAccountId = createSelector(
    (_, props) => props.params,
    (params) => params && params.accountId,
)

/**
 * This selector returns a LedgerSettings representation when navigating within the PAC Command center
 * @name selectLedgerSettings
 * @function
 * @param {List[Map]} ledgerSettingsList - a List of LedgerSettings Maps
 * @param {Number} id - the id of a LedgerSettings
 * @returns {Object} - Matching LedgerSettings
 * Since there will never be more than a few LedgerSettings per client, finding in the list
 * of LedgerSettings will have no noticeable performance cost
 * If you need to select into the ledger settings list outside of the command center, you will need
 * to create your own selector that uses selectLedgerSettingsList
 */
export const selectLedgerSettings = createSelector(
    selectLedgerSettingsList,
    // TODO: selectLedgerSettingsId is undefined in search
    selectLedgerSettingsId,
    (ledgerSettingsList, ledgerSettingsId) =>
        ledgerSettingsList &&
        (ledgerSettingsList.find((l) => l.get("id") === Number(ledgerSettingsId)) ||
            // TODO: since selectLedgerSettingsId is undefined in search,
            // but ledgerSettingsList already exists,
            // since most clients will only be using the single ledgerSettingsList,
            // just grab it
            ledgerSettingsList.first()),
)

/**
 * Select the LedgerSettings, assuming there's only one. Returns undefined if not loaded yet.
 */
export const selectSingleLedgerSettings = createSelector(pacSelector, (pacState) => {
    const ledgerSettingsList = pacState.get("ledgerSettings")
    if (!ledgerSettingsList) {
        return undefined
    } else {
        if (ledgerSettingsList.size !== 1 && window.DEBUG) {
            console.error("Unexpected size %s of ledgerSettings", ledgerSettingsList.size)
        }
        return ledgerSettingsList.first()
    }
})

export const makeLedgerSettingsSelector = makeImmutableSliceSelector(selectLedgerSettings)

export const selectLedgerSettingsName = makeLedgerSettingsSelector("name")
export const selectLedgerSettingsFilingFrequency = makeLedgerSettingsSelector("filing_frequency")
export const selectLedgerSettingsAccounts = makeLedgerSettingsSelector("accounts")

export const selectLedgerSettingsDefaultBankAccount = createSelector(
    selectLedgerSettingsAccounts,
    (accounts) => (accounts && accounts.find((account) => account.get("is_default_bank_account"))) || fromJS({}),
)

export const createLoadingSelector = (loadingName) =>
    createSelector(pacSelector, (state) => Boolean(state && state.getIn(["loadingState", loadingName])))
export const selectIsLoadingLedgerSettings = createLoadingSelector("loadingLedgerSettings")
export const selectIsLoadingFiling = createLoadingSelector("loadingFiling")
export const selectIsLoadingDotFecFile = createLoadingSelector("loadingDotFecFile")

export const selectIsLoadingTransaction = createSelector(pacSelector, (state) =>
    Boolean(state.getIn(["loadingState", "loadingTransaction"])),
)

export const selectIsLoadingPrintChecks = createSelector(pacSelector, (state) =>
    Boolean(state.getIn(["loadingState", "loadingPrintChecks"])),
)

export const selectIsLoadingForTransactionForms = createSelector(
    selectIsLoadingLedgerSettings,
    selectIsLoadingTransaction,
    (loadingLedgerSettings, loadingTransaction) => Boolean(loadingLedgerSettings || loadingTransaction),
)

export const selectLedgerAccounts = makeLedgerSettingsSelector("accounts")

export const selectLedgerFecCommitteeId = makeLedgerSettingsSelector("fec_committee_id")
export const selectLedgerStreetAddress = makeLedgerSettingsSelector("street_address")
export const selectLedgerStreetAddress2 = makeLedgerSettingsSelector("street_address_2")
export const selectLedgerCity = makeLedgerSettingsSelector("city")
export const selectLedgerState = makeLedgerSettingsSelector("state")
export const selectLedgerZip = makeLedgerSettingsSelector("zip_code")

export const selectLedgerCommitteeName = makeLedgerSettingsSelector("name")
export const selectLedgerCommitteeEmail = makeLedgerSettingsSelector("email")
export const selectLedgerCommitteeWebAddress = makeLedgerSettingsSelector("web_address")
export const selectLedgerCommitteeInitialForm1FecId = makeLedgerSettingsSelector("prior_form_1_fec_id")

export const selectCurrentLedgerAccount = createSelector(
    selectAccountId,
    selectLedgerAccounts,
    (id, ledgerAccounts) => ledgerAccounts && ledgerAccounts.find((account) => account.get("id") === Number(id)),
)

export const selectAvailableBankAccounts = createSelector(selectLedgerAccounts, (ledgerAccounts) => {
    if (!ledgerAccounts) {
        return []
    }
    const bankAccounts = ledgerAccounts
        .filter((account) =>
            [LedgerAccountType.checking_account.value, LedgerAccountType.savings_account.value].includes(
                account.get("account_type"),
            ),
        )
        .toJS()

    return bankAccounts.map((a) => ({ value: a.id, label: a.nickname, accountType: a.account_type }))
})

export const selectCheckingBankAccounts = createSelector(selectAvailableBankAccounts, (accounts) => {
    return accounts.filter((item) => item.accountType == LedgerAccountType.checking_account.value)
})

export const selectDefaultBankAccountBalance = createSelector(
    selectLedgerSettingsDefaultBankAccount,
    (account) => account && account.get("current_balance") && centsToCurrencyStr(account.get("current_balance")),
)

export const selectDefaultBankAccountInitialCheckNumber = createSelector(
    selectLedgerSettingsDefaultBankAccount,
    (account) => account && account.get("initial_check_number"),
)

export const selectDefaultBankAccountId = createSelector(
    selectLedgerSettingsDefaultBankAccount,
    (account) => account && account.get("id"),
)

export const selectCheckForm = (state) => state.pac.get("checkForm")

export const selectDefaultReferenceNumber = createSelector(selectCheckForm, (checkForm) =>
    checkForm.get("defaultReferenceNumber"),
)

export const selectCheckData = createSelector(pacSelector, (state) => state.get("checkData", fromJS([])))

export const selectCheckDataForForm = createSelector(
    selectCheckData,
    selectDefaultReferenceNumber,
    (checkData, defaultReferenceNumber) =>
        checkData.map((check) => ({
            address: check.getIn(["_extra", "supporter_address"]) || check.getIn(["_extra", "committee_address"]),
            addressCity: check.get("address_city"),
            addressState: check.get("address_state"),
            addressStreet1: check.get("address_street_1"),
            addressStreet2: check.get("address_street_2"),
            addressZip: check.get("address_zip"),
            amount: check.get("raw_amount"),
            amountString: check.get("amount"),
            committee: check.get("committee"),
            description: check.get("description"),
            electionName: check.get("election_name"),
            enabled: check.get("enabled"),
            id: check.get("id"),
            index: check.get("index"),
            invalid: check.get("invalid"),
            name: check.get("entity_associated_with_transaction"),
            organizationName: check.get("organization_name"),
            referenceNumber: check.get("referenceNumber")
                ? check.get("referenceNumber")
                : String(defaultReferenceNumber),
            creditAccountId: check.get("credit_account_id"),
        })),
)

export const selectPrintChecksInitialValues = createSelector(
    selectLedgerSettingsDefaultBankAccount,
    selectCheckDataForForm,
    selectDefaultReferenceNumber,
    (defaultAccount, checkData, referenceNumber) => {
        const checkDataFromFunction = generateCheckInputFields(checkData, referenceNumber)

        return {
            bank_account: {
                accountType: defaultAccount.get("account_type"),
                label: defaultAccount.get("bank_name"),
                value: defaultAccount.get("id"),
            },
            checkData: checkDataFromFunction,
        }
    },
)

/**
 * This function determines if the PAC has multiple bank accounts for its PAC
 * @name selectHasMultipleBankAccounts
 * @function
 * @param {Array} - an Array of objects representing bank accounts
 * @returns {Boolean} -Whether or not there is more than 1 bank account for the PAC
 */
export const selectHasMultipleBankAccounts = createSelector(
    selectAvailableBankAccounts,
    (bankAccounts) => Boolean(bankAccounts && bankAccounts.length > 1), // TODO change to immutable
)

export const createIsReceiptTransactionSelector = (transactionTypeSelector) =>
    createSelector(transactionTypeSelector, (transactionType) => isReceiptTransaction(transactionType))

export const createIsDisbursementTransactionSelector = (transactionTypeSelector) =>
    createSelector(transactionTypeSelector, (transactionType) => isDisbursementTransaction(transactionType))

/**
 * This function determines the credit_account that the transaction should be associated with
 * Used for Receipt and Disbursement form selectors
 * @name createCreditAccountSelector
 * @function
 * @param {Function} transactionTypeSelector - Selector for type of Transaction - etc TransactionType.money_received
 * @param {Function} bankAccountIdSelector - Selector for entity either receiving or giving money
 * @param {Number} contributionTypeSelector - Selector for type of contribution
 * @returns {Number} - ID of the credit account
 */
export const createCreditAccountSelector = (transactionTypeSelector, bankAccountIdSelector, contributionTypeSelector) =>
    createSelector(
        createIsReceiptTransactionSelector(transactionTypeSelector),
        createIsDisbursementTransactionSelector(transactionTypeSelector),
        bankAccountIdSelector,
        contributionTypeSelector,
        selectLedgerAccounts,
        (isReceiptTransaction, isDisbursementTransaction, bankAccountId, contributionType, ledgerAccounts) => {
            if (isReceiptTransaction) {
                const creditAccount = findLedgerAccountByAccountType(ledgerAccounts, contributionType)

                return creditAccount && creditAccount.get("id")
            } else if (isDisbursementTransaction) {
                return bankAccountId
            }
        },
    )

/**
 * This function determines the credit_account that the transaction should be associated with
 * Used for Receipt and Disbursement form selectors
 * @name createDebitAccountSelector
 * @function
 * @param {Function} transactionTypeSelector - Selector for type of Transaction - etc TransactionType.money_given
 * @param {Function} bankAccountIdSelector - Selector for entity either receiving or giving money
 * @param {Number} disbursementTypeSelector - Selector for disbursement type
 * @returns {Number} - ID of debit account
 */
export const createDebitAccountSelector = (transactionTypeSelector, bankAccountIdSelector, disbursementTypeSelector) =>
    createSelector(
        createIsDisbursementTransactionSelector(transactionTypeSelector),
        createIsReceiptTransactionSelector(transactionTypeSelector),
        bankAccountIdSelector,
        disbursementTypeSelector,
        selectLedgerAccounts,
        (isDisbursementTransaction, isReceiptTransaction, bankAccountId, disbursementType, ledgerAccounts) => {
            if (isDisbursementTransaction) {
                const debitAccount = findLedgerAccountByAccountType(ledgerAccounts, disbursementType)

                return debitAccount && debitAccount.get("id")
            } else if (isReceiptTransaction) {
                return bankAccountId
            }
        },
    )

/**
 * Given a selector for the field options, create a selector that return true if there are between 1 to 6 options for the field.
 * Otherwise, return false
 * @name createDisplayFieldAsGridToggleSelector
 * @function
 * @param {Function} fieldOptionsSelector - Selector for field options
 * @returns {Function} - Selector that returns a boolean if the field should be displayed as a GrigToggleField component
 */
export const createDisplayFieldAsGridToggleSelector = (fieldOptionsSelector) =>
    createSelector(fieldOptionsSelector, (options) => options.length > 0 && options.length <= 6)

/**
 * Given a selector for the field options, create a selector that return true if there is only 1 option for the field.
 * Otherwise, return false
 * @name createHasSingularFieldOptionSelector
 * @function
 * @param {Function} fieldOptionsSelector - Selector for field options
 * @returns {Function} - Selector that returns a boolean if the field has one option
 */
export const createHasSingularFieldOptionSelector = (fieldOptionsSelector) =>
    createSelector(fieldOptionsSelector, (options) => Boolean(options && options.length === 1))

/**
 * Given a selector for the field options, create a selector that returns the first field option.
 * @name createFirstFieldOptionSelector
 * @function
 * @param {Function} fieldOptionsSelector - Selector for field options
 * @returns {Function} - Selector that returns the first field option
 */
export const createFirstFieldOptionSelector = (fieldOptionsSelector) =>
    createSelector(fieldOptionsSelector, (options) => options && options[0])

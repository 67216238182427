import styled, { css } from "styled-components"

import * as colors from "app/static/frontend/design-constants"

export const DropdownIndicatorWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    font-size: 22px;
    color: ${colors.QuorumGrey3};

    :hover {
        color: ${colors.QuorumBlue};
    }
`

export const OptionWrapper = styled.div`
    & > :not(:last-child) {
        margin-right: 8px;
    }

    padding: 4px 8px;
    cursor: pointer;

    /* Gray 7 */
    color: #495057;
    font-size: 14px;
    font-weight: 400;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    svg {
        margin-right: 8px;
        font-size: 22px;
        vertical-align: top;
    }

    ${(props) =>
        props.isSelected &&
        css`
            background-color: ${colors.QuorumBlue};

            color: #fff;

            /* Set the duotone icon colors */
            --fa-primary-color: ${colors.QuorumBlue};
            --fa-secondary-color: #fff;
            --fa-secondary-opacity: 1;
        `}

    ${(props) =>
        props.isFocused &&
        !props.isSelected &&
        css`
            background-color: ${colors.HelpCenterAquaHaze};
            color: ${colors.QuorumBlue};
        `}
`

import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const GatewayWrapper = styled.div`
    ${rules.CardStyle}
    background-color: ${colors.White};
    border: 1px solid ${colors.gray2};
    width: ${props => props.customWidth || '360px'};
    min-height: ${props => props.customHeight || '300px'};
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: ${({onClick}) => onClick ? "pointer" : "unset"};
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, .12);

    &:hover {
        & {
            transition: background-color .1s linear;
            background-color: ${colors.violet1};
            box-shadow: 0 4px 12px -4px rgba(0, 0, 0, .2);
        }

        button:not(:disabled) {
            transition: background-color .1s linear;
            background-color: ${colors.CloudBurst};
            color: ${colors.White};

            span {
                color: ${colors.White};
            }
        }
    }
`

export const Icon = styled.div`
    color: ${colors.violet5};
    padding-bottom: 18px;
`

export const Header = styled.div`
    color: ${colors.gray9};
    font-weight: 500;
    padding-bottom: 12px;
    ${props => props.customStyle || rules.GiantText}
`

export const Content = styled.div`
    color: ${colors.gray7};
    text-align: center;
    padding-bottom: 18px;
    flex-grow: 1;
    ${props => props.customStyle || rules.LargeTextLightWeight}
    font-weight: 400;
    font-size: 1rem;
`

export const Action = styled.div`
    /*
    * This doesn't actually look good.
    * The mocks with Buttons inside of LargeGateways break the rules of LargeGateways :cat-scream:
    position: relative;
    button {
        position: relative;
        top: 13px;
    }
    */
`

import styled, { css } from 'styled-components'
import * as colors from 'Components/colors'

export const Header = styled.div`
    padding-top: "20px";
    padding-left: 27px;
    width: calc(100% - 30px);
    position: relative;
`

export const Icon = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    height: 60px;
    display: flex;
    align-items: center;

    color: ${colors.QuorumGrey4};
    font-size: 29px;
    line-height: 0px;

    &:hover {
        cursor: pointer;
        color: ${colors.CapeCod};
    }
`

import React from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form/immutable"

import { loadWidgetContentFromProps } from "QuorumGrassroots/widgets/Event/helperFunctions"

import GridToggleField from "forms/components/GridToggleField"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import { StyledCustomFieldFormSection, StyledEventForm } from "QuorumGrassroots/widgets/Event/components/style"
import CustomFieldFormSection from "custom-fields/containers/CustomFieldFormSection"

export class EventForm extends React.Component {
    componentDidMount() {
        loadWidgetContentFromProps(this.props)
    }

    render() {
        const { RSVPStatus } = DjangIO.app.custom_event.types

        const rsvpChoices = RSVPStatus.items()
            .filter((item) => item.show_on_rsvp_form)
            .filter((item) => item !== RSVPStatus.maybe || this.props.shouldAllowMaybeOption)
            .sort(DjangIO.orderSort)

        // The bootstrap width to fit 3 Columns in a row
        const threeItemColWidth = 4
        // The bootstrap width to fit 2 Columns in a row
        const twoItemColWidth = 6

        const pillWidth = this.props.shouldAllowMaybeOption ? threeItemColWidth : twoItemColWidth

        const toggleChoices = [
            { label: this.props.t("form.toggle_true"), value: true },
            { label: this.props.t("form.toggle_false"), value: false },
        ]

        return (
            <div className="event-form">
                {
                    // If the widget is still loading, render only the loading message
                    // The rest of the form should still be rendered for Redux Form's validation to work
                    // However, we pass a 'contentLoading' prop to StyledEventForm to hide the rest of the form if loading
                    this.props.contentLoading && <div>{this.props.t("loading")}</div>
                }
                <StyledEventForm contentLoading={this.props.contentLoading}>
                    {this.props.shouldSelfRsvp && (
                        <Field
                            name="rsvpStatus"
                            label={this.props.t("event.form.rsvp.self.label")}
                            accessibilityId="RSVP-status"
                            dataCy="rsvpStatus"
                            component={GridToggleField}
                            choices={rsvpChoices}
                            displayErrorWithoutTouch={false}
                            allowNull={false}
                            pillWidth={pillWidth}
                        />
                    )}
                    {this.props.shouldSelfRsvp && this.props.selfAttending && (
                        <StyledCustomFieldFormSection indented={this.props.shouldSelfRsvp && this.props.shouldBossRsvp}>
                            <CustomFieldFormSection
                                customFieldNameDict={this.props.customFieldNameDict}
                                form={this.props.form}
                                toggleChoices={toggleChoices}
                                namePrefix="selfTagDict"
                                allFieldsMandatory
                                externalFacing
                            />
                        </StyledCustomFieldFormSection>
                    )}
                    {this.props.shouldBossRsvp && (
                        <Field
                            name="bossStatus"
                            label={this.props.t("event.form.rsvp.boss.label", { boss: this.props.bossName })}
                            accessibilityId="RSVP-boss-status"
                            dataCy="bossStatus"
                            component={GridToggleField}
                            choices={rsvpChoices}
                            displayErrorWithoutTouch={false}
                            allowNull={false}
                            pillWidth={pillWidth}
                        />
                    )}
                    {this.props.shouldBossRsvp && this.props.bossAttending && (
                        <StyledCustomFieldFormSection indented={this.props.shouldSelfRsvp && this.props.shouldBossRsvp}>
                            <CustomFieldFormSection
                                customFieldNameDict={this.props.customFieldNameDict}
                                form={this.props.form}
                                toggleChoices={toggleChoices}
                                namePrefix="bossTagDict"
                                allFieldsMandatory
                                externalFacing
                            />
                        </StyledCustomFieldFormSection>
                    )}
                    <StyledButton
                        onClick={this.props.handleSubmit}
                        disabled={this.props.invalid || this.props.submitting}
                        type="submit"
                    >
                        {this.props.submitting ? this.props.t("form.submitting") : this.props.t("form.submit_form")}
                    </StyledButton>
                </StyledEventForm>
            </div>
        )
    }
}

EventForm.propTypes = {
    bossAttending: PropTypes.bool.isRequired,
    bossName: PropTypes.string,
    contentLoading: PropTypes.bool.isRequired,
    customFieldNameDict: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    selfAttending: PropTypes.bool.isRequired,
    shouldAllowMaybeOption: PropTypes.bool.isRequired,
    shouldBossRsvp: PropTypes.bool.isRequired,
    shouldSelfRsvp: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
}

export default EventForm

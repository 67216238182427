import React, { useState, useEffect } from "react"
import { Field } from "redux-form/immutable"
import { createStructuredSelector } from "reselect"
import { connect } from "react-redux"
import {
    selectPACCharityMatchInfo,
    selectUserInitialValues,
} from "QuorumGrassroots/widgets/UpdateInformation/selectors.js"
import PropTypes from "prop-types"

// components
import GridToggleField from "forms/components/GridToggleField"
import InputField from "forms/components/InputField"
import { CharitySelect, charityApiToOption } from "QuorumGrassroots/widgets/DonationForm/components/CharitySelect"
import {
    PacUserInfoSectionContainer,
    PacUserInfoSectionTitle,
    InputFieldsRow,
    InputFieldContainer,
} from "QuorumGrassroots/widgets/UpdateInformation/components/style"

// helpers
import { getCharityByEin } from "@/services/charities"
import { propTypes } from "redux-form"

const GridToggleSwitchField = (props) => (
    <GridToggleField
        allowNull={false}
        displayErrorWithoutTouch={false}
        pillWidth={6}
        choices={[
            { label: "Yes", key: "yes", value: true },
            { label: "No", key: "no", value: false },
        ]}
        {...props}
    />
)

export const UnconnectedPacUserInfoSection = ({ initialValues, charitableMatchInfo, change }) => {
    const [isInitialCharityLoading, setIsInitialCharityLoading] = useState(true)
    useEffect(() => {
        const charityEin = initialValues.get("pac_charity_ein")
        const charityName = initialValues.get("pac_charity_name")
        if (charityEin) {
            getCharityByEin(charityEin).then((charity) => {
                change(
                    "charity",
                    charity
                        ? charityApiToOption(charity)
                        : {
                              label: `${charityName || "Unknown Charity"} (${charityEin})`,
                              value: charityEin,
                              invalid: true,
                          },
                )
                setIsInitialCharityLoading(false)
            })
        } else {
            setIsInitialCharityLoading(false)
        }
    }, [initialValues])

    return (
        <PacUserInfoSectionContainer>
            <PacUserInfoSectionTitle>Update donation settings</PacUserInfoSectionTitle>

            {charitableMatchInfo?.description && (
                <div>
                    <div
                        className="pac-match-description-text"
                        dangerouslySetInnerHTML={{ __html: charitableMatchInfo.description }}
                    />
                </div>
            )}

            <InputFieldsRow>
                <InputFieldContainer>
                    <Field
                        name="charity"
                        label={charitableMatchInfo?.label || "PAC Match Charity"}
                        tooltipText="Charity Selector"
                        placeholder="Enter at least 3 characters of the charity name"
                        component={CharitySelect}
                        isLoading={isInitialCharityLoading}
                        validate={validateCharity}
                        isClearable
                    />
                    <Field
                        name="pac_charity_additional_info"
                        label="Additional Information"
                        component={InputField}
                        placeholder="Enter a name for the specific program you want to receive the charity"
                    />
                    <Field
                        name="pac_charity_anonymous"
                        label="I want to remain anonymous to the charity of my choice"
                        component={GridToggleSwitchField}
                    />
                </InputFieldContainer>
            </InputFieldsRow>
        </PacUserInfoSectionContainer>
    )
}

UnconnectedPacUserInfoSection.propTypes = {
    change: PropTypes.func,
    initialValues: propTypes.func,
    charitableMatchInfo: PropTypes.object,
}

export const PacUserInfoSection = connect(
    createStructuredSelector({
        initialValues: selectUserInitialValues,
        charitableMatchInfo: selectPACCharityMatchInfo,
    }),
    null,
)(UnconnectedPacUserInfoSection)

const validateCharity = (charity) =>
    charity?.invalid
        ? "This Charity is no longer available for a match. Please choose a new charity to receive the match."
        : null

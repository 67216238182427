import {
    DemocratMainColor,
    HyperlinkColor,
    InstagramYellow,
    MediumGreen,
    QuorumBlue,
    RepublicanMainColor,
} from "app/static/frontend/design-constants"

export const dashboardCreateFormText =
    "Customize your dashboard -- either starting from scratch with a blank dashboard and individually selected widgets, or with a template dashboard where you can customize preloaded widgets to fit your needs."

export default {
    // converted each color from
    // app/static/frontend/dashboards/components/WidgetEditFormSidebars/VisualizationFormSections/MapFormSection.jsx
    // ColorPickerFields colors
    // into https://www.spycolor.com/854ea4 and chose the lightest tint for each
    mapSingleSequentialLightHues: {
        [QuorumBlue]: "#e1e5f0",
        [HyperlinkColor]: "#f0e8f4",
        [RepublicanMainColor]: "#fae4e4",
        [DemocratMainColor]: "#dfeffa",
        [MediumGreen]: "#d7fff0",
        [InstagramYellow]: "#fff9ec",
    },
    mapVisualizationPointScaleFactor: 1 / 500,
    maxTextLength: 200,
    visualizationLoadAll: 20,
    visualizationOverrideConstant: 25,
    visualizationTooltipHeight: 33,
    visualizationTooltipWidth: 40,
    visualizationTooltipWidthOverflowThreshold: 10,
    visualizationXAxisLabelLimit: 25,
    widgetLabelLimit: 40,
    widgetTemplateLimit: 22,
    widgetTitleLimit: 50,
}

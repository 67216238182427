import styled from "styled-components"
import * as rules from "Components/rules"
import * as colors from "Components/colors"

export const HorizontalFormStep = styled.div`
    display: flex;
    border-bottom: ${rules.Line} ${colors.QuorumGrey5};
    padding: ${props => props.isModal ? "40px 65px 40px 0" : "40px 145px 40px 0"}};

    &:last-of-type {
        border-bottom: 0;
    }
`

export const HorizontalFormStepLabel = styled.div`
    flex: 0 0 260px;
    min-height: 130px;
    ${rules.GiantTextLightWeight}
    position: relative;
    text-align: center;
    padding: 20px 45px;
    svg {
        left: 50%;
        top: 20px;
        position: absolute;
        transform: translate(-50%, -50%);
    }
`

export const HorizontalFormStepLabelText = styled.div`
    flex: 1;
    padding-top: 40px;
`

export const HorizontalFormStepContent = styled.div`
    flex: 1;
`

export const HorizontalStepNumber = styled.div`
    left: 50%;
    top: 20px;
    position: absolute;
    transform: translate(-50%, -50%);
    ${rules.GiantText}
    ${rules.RegularWeight}
    color: ${colors.White};
`

import React, { useMemo } from "react"
import PropTypes from "prop-types"

import WriteMessageFormSection from "QuorumGrassroots/campaign-forms/components/WriteMessageFormSection"

export const MessageGroupLayout = ({ t, ...props }) => {
    const draftOption = useMemo(
        () =>
            props.messageGroups.find((group) => Number(group.id) === Number(props.activeGroupId))
                ?.advocate_editing_permission,
        [props.activeGroupId, props.messageGroups],
    )
    return (
        <div className="message-group-layout">
            <WriteMessageFormSection
                activeId={props.activeGroupId}
                draftOption={draftOption}
                formName={props.formName}
                key={props.activeGroupId}
                maxLength={props.maxLength}
                isCampaignPage
                // Redux form prop
                submitting={props.submitting}
                t={t}
                uniqueWidgetId={props.uniqueWidgetId}
                writeSectionConfigs={props.writeSectionConfigs}
            />
        </div>
    )
}

MessageGroupLayout.propTypes = {
    activeGroupId: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    uniqueWidgetId: PropTypes.string.isRequired,
    writeSectionConfigs: PropTypes.object,
}

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import * as rules from "Components/rules"
import * as S from "Components/Molecules/SummarySection/style"

import { useOffsetHeight } from "utils/hooks"
import Icon from "Components/Atoms/Icon"

// define and export the effect callback so that we can test it
export const measureEffectCallback = ({
    collapsible,
    currentHeight,
    lastText,
    maxHeight,
    setCollapsible,
    setExpanded,
    setLastText,
    text,
}) => {
    // too tall and not collapsible before, so this is the first time we rendered
    // and we should change ourselves to be collapsible
    if (!collapsible && currentHeight > maxHeight) {
        setCollapsible(true)
        // changed state, so we will re-render
        // won't re-render after that since we check for !collapsible
        // and just set it to true
    }

    if (text !== lastText) {
        // if the text prop has changed, revert to initial state so we can remeasure
        setLastText(text)
        setCollapsible(false)
        setExpanded(false)
    }
}

const SummarySection = ({
    sectionLabel,
    text
}) => {
    const [expanded, setExpanded] = useState(false)
    const [collapsible, setCollapsible] = useState(false)
    const [lastText, setLastText] = useState(undefined)
    const [heightRef, currentHeight] = useOffsetHeight()
    const MAX_HEIGHT = rules.CollapsedTextSectionHeight + 1

    useEffect(() => measureEffectCallback({
        collapsible,
        currentHeight,
        lastText,
        maxHeight: MAX_HEIGHT,
        setCollapsible,
        setExpanded,
        setLastText,
        text,
    }))

    return (
        <S.SummarySection data-auto-cy="MoleculeSummarySection">
            { collapsible &&
                <S.AccessoryArea onClick={() => setExpanded(!expanded)}>
                    <S.Expand>
                        { expanded ? "See Less" : "See More" }
                        <Icon icon={ expanded ? "angle-up" : "angle-down" } iconFamily="fal"/>
                    </S.Expand>
                </S.AccessoryArea>
            }
            <S.TextArea
                ref={heightRef}
                shouldTruncate={collapsible && !expanded}
            >
                <S.BoldSpan>{sectionLabel && `${sectionLabel} `}</S.BoldSpan>{text}
            </S.TextArea>
        </S.SummarySection>
)}

SummarySection.propTypes = {
    // optional bold text to precede the summary in-line
    sectionLabel: PropTypes.string,
    // summary text
    text: PropTypes.string,
}

export default SummarySection

import { connect } from "react-redux"

import CampaignListWidget from "QuorumGrassroots/widgets/CampaignList/components/index"

import { campaignListSelectors } from "QuorumGrassroots/widgets/CampaignList/selectors"
import { loadAdditionalCampaigns } from "QuorumGrassroots/widgets/CampaignList/action-creators"

import { withNamespaces } from "react-i18next"

export default withNamespaces()(connect(campaignListSelectors, { loadAdditionalCampaigns })(CampaignListWidget))

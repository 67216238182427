import React from "react"
import PropTypes from "prop-types"
import {
    StyledColumnWidget,
    StyledWidgetHeader,
    StyledHeaderLogoImage,
    StyledTitleSection,
    StyledSubtitle,
} from "QuorumGrassroots/widgets/styled-components/StyledWidget"

const WidgetStyleWrapper = ({
    className,
    useWidgetStyling,
    title,
    subtitle,
    logoImgUrl,
    logoImgBorderColor,
    headerColored,
    children,
    isForm,
    xs,
    sm,
    md,
    lg,
}) =>
    !useWidgetStyling ? (
        <div>
            <div>{title}</div>
            {children}
        </div>
    ) : (
        <StyledColumnWidget className={"widget " + className || ""} isForm={isForm} xs={xs} sm={sm} md={md} lg={lg}>
            {(title || logoImgUrl || subtitle) && (
                <StyledWidgetHeader className="widget-header" headerColored={headerColored}>
                    {logoImgUrl && (
                        <StyledHeaderLogoImage src={logoImgUrl} alt="logo" logoImgBorderColor={logoImgBorderColor} />
                    )}
                    <StyledTitleSection className="widget-title-section">
                        {title && <div data-cy="title">{title}</div>}
                        {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
                    </StyledTitleSection>
                </StyledWidgetHeader>
            )}
            {children}
        </StyledColumnWidget>
    )

WidgetStyleWrapper.defaultProps = {
    title: "",
    subtitle: "",
    logoImgUrl: "",
    headerColored: false,
    logoImgBorderColor: "",
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
}

WidgetStyleWrapper.propTypes = {
    useWidgetStyling: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    logoImgUrl: PropTypes.string,
    logoImgBorderColor: PropTypes.string,
    headerColored: PropTypes.bool,
    children: PropTypes.array,
    isForm: PropTypes.bool,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
}

export default WidgetStyleWrapper

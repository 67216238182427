import actionTypes, { getItemTypes, getCacheTypes } from "shared/api-caching/action-types"
import moment from "moment"

export const startCacheResponse = (endpoint, url) => {
    window.store && window.store.dispatch && window.store.dispatch({
        type: getCacheTypes.start,
        endpoint,
        url
    })
}

export const cacheResponse = (response, endpoint, url) => {
    window.store && window.store.dispatch && window.store.dispatch({
        type: getCacheTypes.success,
        endpoint,
        url: decodeURIComponent(url),
        response,
    })
    return response
}


/**
 * Given certain parameters, invalidate certain sections of the cache.
 * Will clear only parts of the cache involving the specified resources.
 * If you wish to only clear part of a certain resource's cache, include
 * the patterns the cache's key should satisfy.
 *
 * @param  {Array} options.endpoints      the API endpoints that we want to clear.
 * @param  {Function} options.shouldClearFn    Given a url of a request within the given endpoints,
 *                                             determine whether to clear that element from the cache.
 *                                             If undefined, all cached list requests within
 *                                             options.endpoints will be cleared.
 * @return void
 */
export const invalidateCacheSlice = ({ endpoints, shouldClearFn }) => {
    // Invalidate stuff. Will potentially grow more complicated as invalidateCacheParams
    // grows more complicated. Right now, takes a resource and clears the list cache
    // of that resource. uriPatterns will be used later in order to be able to refine
    // which caches get cleared.
    window.store && window.store.dispatch && window.store.dispatch({
        type: actionTypes.INVALIDATE_CACHE_SLICE,
        endpoints,
        shouldClearFn,
    })
}

/**
 * Given an array of endpoints, store in the store that they have
 * been updated. This is for keeping track of user updates, so that
 * search's ListView can keep track of when it should refresh.
 * @param  {[type]} endpoints [description]
 * @return {[type]}           [description]
 */
export const markResourceAsUpdated = ({ endpoints }) => {
    window.store && window.store.dispatch && window.store.dispatch({
        type: actionTypes.MARK_RESOURCE_AS_UPDATED,
        endpoints,
        timestamp: moment().format(),
    })
}

/**
 * IN THE PROCESS OF BEING PHASED OUT.
 *
 * Will invalidate any cached list requests given a specific model.
 * Will invalidate the model's endpoint and the model's proxy endpoint.
 *
 * @param  {Object} model          the model that is being updated. NOT the models that need to be cleared.
 * @return void
 */
export const invalidateCachedListResponses_DEPRECATED = (model) => {
    window.store && window.store.dispatch && window.store.dispatch({
        type: actionTypes.INVALIDATE_CACHE_SLICE_DEPRECATED,
        endpoints: [
            model.endpoint,
            model.proxy_endpoint,
        ].filter(endpoint => !!endpoint)
    })
}

/**
 * IN THE PROCESS OF BEING PHASED OUT.
 *
 * Given a model, mark all associated models (whose caches were just wiped)
 * as "updated." This is for the purpose of refreshing data in the mobile app.
 * Because the mobile app doesn't unmount a FilterableListView, it is
 * not so easy to tell the ListView to refresh once data has been updated.
 * This method marks models in the store as "having been updated at X time",
 * which is something a ListView can keep track of.
 *
 * @param  {Object} model          the model that is being updated. NOT the models that need to be cleared.
 * @return void
 */
export const markResourceAsUpdated_DEPRECATED = (model) => {
    window.store && window.store.dispatch && window.store.dispatch({
        type: actionTypes.MARK_RESOURCE_AS_UPDATED,
        endpoints: [
            model.endpoint,
            model.proxy_endpoint,
        ].filter(endpoint => !!endpoint),
        timestamp: moment().format(),
    })
}

export const invalidateCache = () => ({
    type: actionTypes.INVALIDATE_CACHE,
})

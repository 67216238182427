import styled from "styled-components"

export const Alert = styled.div`
    font-size: 16px;
    color: ${props => props.primaryColor};
    background-color: ${props => props.secondaryColor};
    border: 1px solid ${props => props.borderColor};
    border-radius: 4px;

    display: flex;
    padding: 7px;
`

export const Icon = styled.div`
    margin-right: 10px;
`

export const Message = styled.div`
    display: flex;
    flex-direction: column;
`

export const Title = styled.div`
    font-weight: 500;
`

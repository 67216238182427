import React, { useState } from "react"
import PropTypes from "prop-types"

import * as S from "Components/Atoms/PageTitle/style"
import * as helperFunctions from "utils/helperFunctions"

import { useTruncated, useWindowSize } from "utils/hooks"
import Tooltip from "Components/Molecules/Tooltip"

const PageTitle = ({
    dataCy,
    image,
    imageDataCy,
    prefix,
    isTruncatedCallback,
    subtitle,
    title,
    titleBreakpoint,
    // tooltipMaxWidth is a string, e.g. "400px"
    tooltipMaxWidth,
    tooltipPlacement,
    tooltipText,
}) => {

    const [storedIsTruncated, updateStoredIsTruncated] = useState(undefined)
    const [tooltipOffset, updateOffset] = useState(undefined)
    const [truncationRef, isTruncated] = useTruncated()

    const isIE = helperFunctions.isUserIE11()
    const useIETooltip = tooltipText && isIE

    if (isTruncated !== storedIsTruncated) {
        // call the truncation callback only when our truncation state changes
        isTruncatedCallback && isTruncatedCallback(isTruncated)

        // store the new truncation state
        updateStoredIsTruncated(isTruncated)
    }

    // re-render on window resize
    useWindowSize()

    return (
        <S.PageTitle
            data-auto-cy="AtomPageTitle"
            data-cy={dataCy}
            hasImage={Boolean(image)}
            key="AtomPageTitle"
        >
            {
                image &&
                    <S.PageTitleImageContainer>
                        <S.PageTitleImage
                            dataCy={imageDataCy}
                            src={image}
                        />
                    </S.PageTitleImageContainer>
            }
            {prefix && <S.Prefix >{prefix}</S.Prefix>}

            <S.Container
                useIETooltip={useIETooltip}
            >
                {tooltipText && !isIE &&
                    <S.TooltipPositioner
                        offset={tooltipOffset}
                        tooltipPlacement={tooltipPlacement || "top"}
                    >
                        <Tooltip
                            dataCy="PageTitleMoleculesTooltip"
                            isTiny
                            key={`tooltip-${tooltipText}`}
                            id={`tooltip-${tooltipText}`}
                            maxWidth={tooltipMaxWidth}
                            offsetCallback={ offset => updateOffset(offset)}
                            placement={tooltipPlacement || "top"}
                            text={tooltipText}
                        />
                    </S.TooltipPositioner>
                }
                {tooltipText && isIE &&
                    <div>
                        <S.Tooltip
                            isTiny
                            maxWidth={tooltipMaxWidth || "750px"}
                            key={`tooltip-${tooltipText}`}
                        >
                            {tooltipText}
                        </S.Tooltip>
                    </div>
                }

                {title &&
                    <S.TitleText
                        image={image}
                        ref={truncationRef}
                        titleBreakpoint={titleBreakpoint}
                    >
                        {title}
                    </S.TitleText>
                }
            </S.Container>
        </S.PageTitle>
    )
}

PageTitle.propTypes = {
    // data-cy tag so that the text of the title can be inspected
    dataCy: PropTypes.string,
    // url to optional image to display before title
    image: PropTypes.string,
    // data-cy tag to inspect image
    imageDataCy: PropTypes.string,
    // text to render in alternate color before the title
    prefix: PropTypes.string,
    subtitle: PropTypes.string,
    // title text
    title: PropTypes.string.isRequired,
    // the media query breakpoint where we will hide the PageTitle S.TitleText
    titleBreakpoint: PropTypes.string,
    tooltipMaxWidth: PropTypes.string,
    tooltipPlacement: PropTypes.oneOf(["top", "right"]),
    tooltipText: PropTypes.string,
}

export default PageTitle

import React from "react"
import PropTypes from "prop-types"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"

import IssueInline from "QuorumGrassroots/widgets/IssueList/components/IssueInline/index"
import { StyledIssueRow } from "QuorumGrassroots/widgets/IssueList/components/style"

export const IssueList = ({ t, ...props }) => {
    return (
        <WidgetStyleWrapper
            className="issue-list-widget"
            md={props.colWidth}
            useWidgetStyling={props.useWidgetStyling}
            title={props.title || t("issue.issue_list_header")}
        >
            {props.loading ? (
                t("loading")
            ) : (
                <StyledIssueRow className="issue-row">
                    {props.issueList.map((issue) => (
                        <IssueInline
                            className="issue-inline"
                            key={issue.id}
                            issue={issue}
                            parentWidth={props.widgetWidth}
                        />
                    ))}
                </StyledIssueRow>
            )}
        </WidgetStyleWrapper>
    )
}

IssueList.propTypes = {
    widgetWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    issueList: PropTypes.arrayOf(PropTypes.object),
    useWidgetStyling: PropTypes.bool,
}

IssueList.defaultProps = {
    useWidgetStyling: true,
    widgetWidth: 12,
}

export default IssueList

import ContactUsWidget from "QuorumGrassroots/widgets/ContactUs/components/index"
import { reduxForm } from "redux-form/immutable"
import { connect } from "react-redux"
import { constants } from "QuorumGrassroots/constants"
import { createStandardThankableWidget } from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/ThankableWrapper"
import {
    selectContactUsWidgetThanksProps,
    contactUsWidgetConnection,
} from "QuorumGrassroots/widgets/ContactUs/selectors"
import { onSubmit } from "QuorumGrassroots/widgets/ContactUs/action-creators"

const ReduxContactUsWidget = reduxForm({
    form: constants.contactUsFormReduxFormKey,
    onSubmit,
})(ContactUsWidget)

const connectedWidget = connect(contactUsWidgetConnection, {})(ReduxContactUsWidget)
const thankableContactUs = createStandardThankableWidget(connectedWidget, selectContactUsWidgetThanksProps)

export default thankableContactUs

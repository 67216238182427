import GlobalActionTypes, { LOCATION_CHANGE } from "app/static/frontend/global-action-types"
import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

const segueMiddleware = (middlewareAPI) => (nextMiddleware) => (action) => {
    nextMiddleware(action)
    if (action.type === LOCATION_CHANGE) {
        if (action.payload.state && action.payload.state.PUSH_PAGE) {
            // build up prevPageUrl more carefully to prevent weird edge cases
            let prevPageUrl = ""
            if (action.previousPathname) {
                prevPageUrl += action.previousPathname
            }
            if (action.payload.state.search) {
                prevPageUrl += action.payload.state.search
            }
            // only dispatch an action if there was a valid prevPageUrl
            if (prevPageUrl) {
                middlewareAPI.dispatch({
                    type: GlobalActionTypes.PUSH_PAGE,
                    nextPage: { prevPageUrl },
                })
            }
        }
    }
}

export const segue = (pathname) => {
    const state = {
        PUSH_PAGE: true,
        search: window.location.search,
    }

    return { pathname, state }
}

export const SegueLink = ({ to, ...props }) => {
    const updateRelCanonical = () => {
        const relCanonical = document.querySelector("head > link[rel=canonical]")
        if (to && relCanonical) {
            // If they are on the homepage, set canonical link to base url. Else, set canonical to absolute url
            if (to === "/homepage/") {
                relCanonical.href = `https://www.quorum.us/`
            } else {
                relCanonical.href = `https://www.quorum.us${to}`
            }
        }
    }

    return to ? <Link to={to} onClick={updateRelCanonical} {...props} /> : <Link {...props} />
}

SegueLink.propTypes = {
    to: PropTypes.string,
}

export default segueMiddleware

import React from "react"
import PropTypes from "prop-types"

import {
    StyledFacebookButton,
    StyledFacebookButtonText,
    NewStyledFacebookButton,
} from "QuorumGrassroots/widgets/LoginRegistration/components/FacebookLoginButton/style"

/**
 * Component to render a button with the Facebook icon and colors.
 * On click, will start FB login process and call the callback function.
 * @param {Function} onLogin - Callback function to be called after login. Called with FB ID, email, first name and last name
 * @param {Boolean} disabled - Boolean specifying if button should be disabled
 * @param {String} buttonText - The inner text on the button
 * @returns {Component}
 */
export const FacebookLoginButton = (props) => {
    const facebookLogin = () => {
        window.FB.login(
            (response) => {
                if (response.status === "connected") {
                    window.FB.api("/me", { fields: "email, first_name, last_name" }, (userData) => {
                        const loginDict = {
                            fb_id: userData.id,
                            email: userData.email,
                            firstname: userData.first_name,
                            lastname: userData.last_name,
                        }

                        props.onLogin(loginDict)
                    })
                }
            },
            {
                scope: "email",
            },
        )
    }

    if (props.isCampaignPage) {
        return (
            <NewStyledFacebookButton
                className="facebook-login-button"
                onClick={facebookLogin}
                disabled={props.disabled}
            >
                <i className="fa fa-facebook-square" />
                <StyledFacebookButtonText>{props.buttonText}</StyledFacebookButtonText>
            </NewStyledFacebookButton>
        )
    }

    return (
        <StyledFacebookButton className="facebook-login-button" onClick={facebookLogin} disabled={props.disabled}>
            <i className="fa fa-facebook-square" />
            {props.buttonText}
        </StyledFacebookButton>
    )
}

FacebookLoginButton.defaultProps = {
    disabled: false,
    onLogin: () => undefined,
}

FacebookLoginButton.propTypes = {
    onLogin: PropTypes.func,
    disabled: PropTypes.bool,
    buttonText: PropTypes.string.isRequired,
    isCampaignPage: PropTypes.bool.isRequired,
}

import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"

import { OfficialListLoginWrapper } from "QuorumGrassroots/widgets/OfficialList/components/index"
import { initializer } from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/InitializeStoreWrapper"
import { officialListSelector } from "QuorumGrassroots/widgets/OfficialList/selectors"

const connectedOfficialList = connect(officialListSelector, null)(OfficialListLoginWrapper)
const initializedOfficialList = initializer(connectedOfficialList)

export default withNamespaces()(initializedOfficialList)

import actionTypes from "shared/api-caching/action-types"

import moment from "moment"

// This file contains additional action creators that are seperated from the action-creators file in this
// directory to prevent circular imports


/**
 * Given certain parameters, invalidate certain sections of the cache.
 * Will clear only parts of the cache involving the specified resources.
 * If you wish to only clear part of a certain resource's cache, include
 * the patterns the cache's key should satisfy.
 *
 * @param  {Array} options.endpoints      the API endpoints that we want to clear.
 * @param  {Function} options.shouldClearFn    Given a url of a request within the given endpoints,
 *                                             determine whether to clear that element from the cache.
 *                                             If undefined, all cached list requests within
 *                                             options.endpoints will be cleared.
 * @return void
 */
export const invalidateCacheSlice = ({ endpoints, shouldClearFn }) => {
    // Invalidate stuff. Will potentially grow more complicated as invalidateCacheParams
    // grows more complicated. Right now, takes a resource and clears the list cache
    // of that resource. uriPatterns will be used later in order to be able to refine
    // which caches get cleared.
    window.store && window.store.dispatch && window.store.dispatch({
        type: actionTypes.INVALIDATE_CACHE_SLICE,
        endpoints,
        shouldClearFn,
    })
}

/**
 * Given an array of endpoints, store in the store that they have
 * been updated. This is for keeping track of user updates, so that
 * search's ListView can keep track of when it should refresh.
 * @param  {[type]} endpoints [description]
 * @return {[type]}           [description]
 */
export const markResourceAsUpdated = ({ endpoints }) => {
    window.store && window.store.dispatch && window.store.dispatch({
        type: actionTypes.MARK_RESOURCE_AS_UPDATED,
        endpoints,
        timestamp: moment().format(),
    })
}

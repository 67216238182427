import styled from "styled-components"

export const StyledPermissionDeniedPage = styled.div`
    margin: 50px;
    text-align: center;
    background-color: ${(props) => props.background};

    @media screen and (max-width: 768px) {
        margin: 0;
    }
`

export const StyledPermissionDeniedContentBox = styled.div`
    margin: 5em;
    padding: 3em;
    background-color: ${(props) => props.background};

    @media screen and (max-width: 768px) {
        margin: 0;
        padding: 0;
    }
`

export const StyledPermissionDeniedText = styled.div`
    color: ${(props) => props.color};
    ${(props) => (props.compact ? "" : "margin: 2em 5em;")}
    font-size: 3em;

    @media screen and (max-width: 768px) {
        font-size: 1em;
    }
`

export const StyledPermissionDeniedImage = styled.div`
    color: ${(props) => props.color};
    font-size: 200px;

    @media screen and (max-width: 768px) {
        font-size: 100px;
    }
`

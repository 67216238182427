import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const SectionHeader = styled.div`
    display: flex;
    flex-direction: row;
`

export const InfoArea = styled.div`
    display: flex;
    flex-direction: row;
    align-self: flex-start;
`

export const HeaderText = styled.div`
    color: ${colors.gray9};
    ${rules.GiantTextMediumWeight}
    font-size: 1.25rem;
    line-height: 1.5;
    margin-top: 5px;
    margin-right: 24px;
`

export const UpdatedText = styled.div`
    ${rules.SmallTextLightWeight}
    font-style: italic;
    * > b {
        font-weight: 500;
    }
`

export const AccessoryArea = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: auto;
    > * {
        margin-left: 9px;
    }
`

export const Expand = styled.span`
    flex-shrink: 0;
    align-self: center;
    ${rules.SmallTextRegularWeight}
    color: ${colors.violet7};

    &:not(:only-child) {
        margin-right: 9px;
    }

    &:hover {
        cursor: pointer;
        color: ${colors.violet5};
    }
`

export const TextArea = styled.div`
    ${SectionHeader} + &, ${UpdatedText} + & {
        margin-top: 12px;
    }

    display: block;
    width: ${rules.TextSectionContentWidth};
    color: ${colors.Black};
    ${rules.MediumTextRegularWeightSpaced}

    ${({expanded}) => !expanded && `
        ${rules.multiLineTruncatedText(rules.CollapsedTextSectionLines)}
        // 1.2em because you can roughly approximate the height of X number of text lines with a CSS height of (1.2 * X)em
        max-height: ${rules.CollapsedTextSectionLines * 1.2}em;
    `}
`

export const EmptyStateText = styled.div`
    color: ${colors.DarkGrey};
    font-style: italic;
`

// use a wrapper instead of a styled.textarea because we are using an
// external react component for the text area to give it auto-growing
export const EditAreaWrapper = styled.div`
    ${SectionHeader} + &, ${UpdatedText} + & {
        margin-top: 12px;
    }
    & > textarea {
        margin-top: -5px;
        width: ${rules.TextSectionContentWidth};
        color: ${colors.Black};
        ${rules.MediumTextRegularWeightSpaced}

        padding: 4px;

        box-shadow: ${rules.InnerShadow};

        border: ${rules.Line} ${colors.FormControlBorderColor};
        border-radius: ${rules.BorderRadius};
        transition: ${rules.BorderColorTransition};

        // change the placeholder text color
        &::placeholder {
            color: ${colors.QuorumGrey3};
        }

        // change the border when focused
        &:focus {
            border-color: ${colors.QuorumBlue};
            border-radius: ${rules.BorderRadius};
            outline: none;
        }
    }
`

import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

import { ImageArea } from "Components/Molecules/CircleImage/style"

export const ListItem = styled.div`
    align-items: center;
    border: 1px solid ${colors.SilverChalice};
    border-radius: 4px;
    display: flex;
    padding: 8px 10px 7px;

    ${props => (props.iconText) && css`
        align-items: baseline;
    `}

    // if we pass a normal FontAwesome icon
    // remove the left padding since there
    // is some natural padding in the icon container itself
    ${props => (props.icon) && css`
        padding-left: 0;
    `}

    color: ${colors.Black};
    &:hover, &:focus {
        color: ${colors.Black};
    }

    // CircleImage
    ${ImageArea} {
        margin-left: 9px;
        margin-right: 9px;
    }

    ${props => (!props.editing && !props.empty && props.hasSegue) && css`
        &:hover {
            background-color: ${colors.DarkerPorcelain};
            cursor: pointer;
        }
    `}

    ${props => (props.editing) && css`
        &:hover {
            cursor: unset;
        }
    `}
`

export const Content = styled.div`
    flex-grow: 1;
    color: ${colors.gray7};
`

export const FirstLine = styled.div`
    ${rules.SmallText}
    // https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp#CSS
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;

    a:hover {
        border-bottom: ${colors.gray9} thin dotted;
    }

    ${props => (props.empty) && css`
        background-color: ${colors.Iron};
        border-radius: 6px;
        height: 15px;
        width: 70%;
    `}
`

export const SecondLine = styled.div`
    ${rules.MicroscopicText}
    margin-top: 5px;

    ${props => (props.empty) && css`
        background-color: ${colors.Iron};
        border-radius: 4px;
        height: 10px;
        margin-top: 10px;
        width: 80%;
    `}
`

export const SecondLineSpan = styled.span`
    &:not(:last-child)::after {
        content: " | ";
    }
`

export const SecondLineKey = styled.span`
    ${rules.BoldWeight}
`

export const IconText = styled.span`
    ${rules.SmallText}
    margin-right: 8px;
    white-space: nowrap;
`

export const IconComponent = styled.div`
    font-size: 20px;
    margin: 0 12px;

    ${props => (props.color) && css`
        color: ${props.color};
    `}
`

import "QuorumGrassroots/widgets/OfficialList/components/style.styl"

import React from "react"
import PropTypes from "prop-types"
import queryString from "query-string"

import { LoadingSpinner } from "components/LoadingSpinner"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"

import LoginUpdateOrRender from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/LoginUpdateOrRender"
import OfficialsListView from "QuorumGrassroots/widgets/OfficialList/components/OfficialsListView"
import OfficialsCardView from "QuorumGrassroots/widgets/OfficialList/components/OfficialsCardView"
import isFeatureEnabled from "shared/featureflags/helperFunctions"

import { StyledOfficialDescription } from "QuorumGrassroots/widgets/OfficialList/components/style"

export class OfficialList extends React.Component {
    componentDidMount() {
        const { search } = this.props.location

        this.loadContent(search)
    }

    componentDidUpdate(prevProps) {
        const { search } = this.props.location
        const { search: prevSearch } = prevProps.location

        if (prevSearch !== search) {
            this.loadContent(search)
        }
    }

    loadContent = (search) => {
        const {
            loadWidgetContent,
            selectOfficialsValue,
            selectedOfficialsList,
            uniqueWidgetId,
            allowedMinorRoleTypes,
        } = this.props

        const queryParams = queryString.parse(search)

        // NOTE: the filter property limit is innocuous. The backend already limit the number of results in 1000. Still here for safety.
        loadWidgetContent({
            resource: DjangIO.app.person.models.GrassrootsPerson,
            ...(isFeatureEnabled("ff_gr_officials_enhancements") &&
                selectOfficialsValue && {
                    action: "load_list_of_officials",
                    kwargs: { ids: selectedOfficialsList, ...queryParams },
                }),
            uniqueWidgetId,
            filter: {
                ph_minor_role_type: allowedMinorRoleTypes,
                limit: 1000,
            },
        })
    }

    render() {
        const officialList = this.props.officialList

        if (this.props.loading) {
            return <LoadingSpinner id="officials-list-loading" />
        }

        if (isFeatureEnabled("ff_gr_officials_enhancements")) {
            if (this.props.cardOrListDisplay) {
                return <OfficialsCardView officialList={officialList} />
            } else {
                return <OfficialsListView officialList={officialList} />
            }
        } else {
            return <OfficialsCardView officialList={officialList} />
        }
    }
}

OfficialList.propTypes = {
    allowedMinorRoleTypes: PropTypes.array,
    officialList: PropTypes.array.isRequired,
    selectOfficialsValue: PropTypes.bool,
    selectedOfficialsList: PropTypes.arrayOf(PropTypes.number),
    loadWidgetContent: PropTypes.func.isRequired,
    uniqueWidgetId: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
}

export const OfficialListLoginWrapper = (props) => (
    <WidgetStyleWrapper
        md={props.colWidth}
        useWidgetStyling={props.useWidgetStyling}
        title={props.title ? props.title : props.t("official.list.header.text")}
    >
        {isFeatureEnabled("ff_gr_officials_enhancements") && !props.loading && (
            <StyledOfficialDescription dangerouslySetInnerHTML={{ __html: props.description }} />
        )}
        {props.selectOfficialsValue ? (
            <OfficialList {...props} />
        ) : (
            <LoginUpdateOrRender
                {...props}
                registrationPageIds={props.registrationPageIds}
                additionalSimpleFields={[DjangIO.app.grassroots.types.GrassrootsRegistrationField.address.value]}
                useWidgetStyling={false}
                renderedComponent={OfficialList}
                renderedProps={props}
                showOnlyUnfilledFields
            />
        )}
    </WidgetStyleWrapper>
)

OfficialListLoginWrapper.propTypes = {
    registrationPageIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    useWidgetStyling: PropTypes.bool,
    colWidth: PropTypes.number,
    t: PropTypes.func.isRequired,
    description: PropTypes.string,
    title: PropTypes.string,
}

OfficialListLoginWrapper.defaultProps = {
    useWidgetStyling: true,
    colWidth: 12,
}

export default OfficialListLoginWrapper

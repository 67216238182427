import React from "react"
import styled from "styled-components"
import { SegmentedControl } from "@mantine/core"
import { constants } from "QuorumGrassroots/constants"

export const StyledSegmentedControl = styled(SegmentedControl)`
    width: 100%;
    border-radius: 32px;
    height: 32px;
    margin-bottom: 16px;
    .mantine-SegmentedControl-control {
        ${(props) => `max-width: ${100 / props.data.length}%;`}
        &:not(:first-of-type) {
            border-width: 0;
        }
    }
    .mantine-SegmentedControl-indicator {
        border-radius: 32px;
        height: 24px;
    }
    .mantine-SegmentedControl-label {
        overflow: hidden;

        margin: 0;
        padding: 3px 8px;

        font-family: inherit;
        color: ${constants.freshMediumGrey};
        text-overflow: ellipsis;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
    }
    .mantine-SegmentedControl-label {
        &[data-active] {
            color: ${constants.freshDarkGrey};
            &:hover {
                color: ${constants.freshDarkGrey};
            }
        }
    }
`

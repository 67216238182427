import React from "react"
import PropTypes from "prop-types"
// eslint-disable-next-line import/named
import { dateStringToDisplayFormat } from "imports/desktopHelperFunctions"

// components
import { SelectableDate } from "quorumdesign"

// helpers
import isFeatureEnabled from "shared/featureflags/helperFunctions"

import "eonasdan-bootstrap-datetimepicker"
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css"

export default class DatePicker extends React.Component {
    static initClass() {
        this.defaultProps = {
            defaultDate: undefined,
            useCurrentDateForUndefinedDefaultDate: true,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            publishDateChanges() {},
            clearable: false,
        }

        this.propTypes = {
            customStyle: PropTypes.object,
            defaultDate: PropTypes.string,
            noFuture: PropTypes.bool,
            publishDateChanges: PropTypes.func.isRequired,
            shouldBeInline: PropTypes.bool,
            useCurrentDateForUndefinedDefaultDate: PropTypes.bool,
            // props for DatePickers within Search Filters
            // to utilize the design system SelectableDate component
            clearFilter: PropTypes.func,
            deserializedValue: PropTypes.object,
            isSearchFilter: PropTypes.bool,
            clearable: PropTypes.bool,
            disabled: PropTypes.bool,
        }
    }
    constructor() {
        super(...arguments)
        this.initializeDatePicker = this.initializeDatePicker.bind(this)
        this.dateChanged = this.dateChanged.bind(this)
        this.handleBlur = this.handleBlur.bind(this)
        this.handleScrollCloseCalendar = this.handleScrollCloseCalendar.bind(this)
        this.setFocused = this.setFocused.bind(this)
        this.showUnderline = this.showUnderline.bind(this)
        this.hideUnderline = this.hideUnderline.bind(this)
        this.state = {
            showUnderline: true,
            isFocused: false,
            isNull: false,
        }
    }

    /*
        This gives us a reference to this particular date picker, which we use to
        programatically set the input value (since ref only called during mounting/unmounting)
     */
    initializeDatePicker(element) {
        this.picker = $(element)
    }

    /*
        When component mounts, initialize the input to be a jquery datetimepicker
        This gives us the pretty popup calendar
     */
    componentDidMount() {
        const today = new Date().toAPIString()

        if (this.picker) {
            if (this.props.isSearchFilter) {
                const defaultDate =
                    // if no defaultDate is passed in, an empty string instead
                    // of today's date will make placeholder text visible
                    this.props.noFuture && this.props.defaultDate && moment(this.props.defaultDate) > moment(today)
                        ? today
                        : this.props.defaultDate || ""

                this.picker.datetimepicker({
                    format: "MM/DD/YYYY",
                    defaultDate: defaultDate,
                    // makes dates past tomorrow unselectable in calendar
                    maxDate: this.props.noFuture && today,
                    minDate: this.props.minDate,
                    // stops maxDate from overriding the defaultDate/placeholder
                    useCurrent: false,
                })
                this.picker.on("dp.change", this.dateChanged)
                window.addEventListener("wheel", this.handleScrollCloseCalendar, false)
            } else {
                const currentDate = this.props.useCurrentDateForUndefinedDefaultDate ? today : ""

                this.picker.datetimepicker({
                    format: "MM/DD/YYYY",
                    defaultDate: this.props.defaultDate ? this.props.defaultDate : currentDate,
                    maxDate: this.props.noFuture && today,
                    minDate: this.props.noPast && today,
                })
                this.picker.on("dp.change", this.dateChanged)
            }
        }
    }

    componentWillUnmount() {
        if (this.props.isSearchFilter) {
            window.removeEventListener("wheel", this.handleScrollCloseCalendar, false)
        }
    }

    /*
        When component updates,
        (1) check if focus changed
     */
    componentDidUpdate(prevProps, prevState) {
        if (prevState.isFocused && !this.state.isFocused) {
            return this.showUnderline()
        }
    }

    handleScrollCloseCalendar() {
        this.picker.data("DateTimePicker").hide()
    }

    getDateForDisplay() {
        if (this.state.isNull) return ""

        const currentDate = this.props.useCurrentDateForUndefinedDefaultDate ? new Date().toAPIString() : ""
        const d = this.props.defaultDate ? this.props.defaultDate : currentDate

        // converts YYYY-DD-MM TO MM/DD/YYYY
        return dateStringToDisplayFormat(d)
    }

    getDateForSearchFilterDisplay() {
        return this.props.defaultDate ? dateStringToDisplayFormat(this.props.defaultDate) : ""
    }

    onClear = (e) => {
        //clear if backspace or delete key is pressed
        if (this.props.clearable && (e.keyCode === 8 || e.keyCode === 46)) {
            this.props.publishDateChanges(null)
            this.setState({ isNull: true })
        }
    }

    dateChanged(e) {
        let newDate = new Date(e.date)
        let oldDate = new Date(e.oldDate)
        if (newDate !== oldDate) {
            this.props.publishDateChanges(newDate)
            this.setState({ isNull: false })
        }
    }

    handleBlur() {
        return this.setFocused(false)
    }

    setFocused(isFocused) {
        return this.setState({
            isFocused,
        })
    }

    showUnderline() {
        if (!this.state.isFocused) {
            return this.setState({
                showUnderline: true,
            })
        }
    }

    hideUnderline() {
        if (!this.state.isFocused) {
            return this.setState({
                showUnderline: false,
            })
        }
    }

    render() {
        if (this.props.isSearchFilter) {
            const date = this.getDateForSearchFilterDisplay()

            return (
                <SelectableDate
                    deserializedValue={this.props.deserializedValue}
                    initializeDatePicker={this.initializeDatePicker}
                    onBlur={() => this.setFocused(false)}
                    onClick={this.props.clearFilter}
                    onFocus={() => this.setFocused(true)}
                    type="text"
                    value={date}
                />
            )
        }

        const date = this.getDateForDisplay()

        return !this.props.shouldBeInline ? (
            <div className="input-group date" style={{ margin: this.props.isSwal ? "auto" : "" }}>
                {this.state.showUnderline ? <div className="underline" /> : undefined}
                <input
                    ref={this.initializeDatePicker}
                    id="datetimepicker"
                    value={date}
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    // onChange={this.dateChanged}
                    onFocus={() => this.setFocused(true)}
                    onBlur={() => this.setFocused(false)}
                    onMouseEnter={this.hideUnderline}
                    onMouseLeave={this.showUnderline}
                    style={this.props.customStyle}
                    onKeyDown={this.onClear}
                    disabled={this.props.disabled}
                />
            </div>
        ) : (
            <input
                ref={this.initializeDatePicker}
                id="datetimepicker"
                value={date}
                type="text"
                className="form-control"
                autoComplete="off"
                // onChange={this.dateChanged}
                onFocus={() => this.setFocused(true)}
                onBlur={() => this.setFocused(false)}
                onMouseEnter={this.hideUnderline}
                onMouseLeave={this.showUnderline}
                style={this.props.customStyle}
                onKeyDown={this.onClear}
                disabled={this.props.disabled}
            />
        )
    }
}
DatePicker.initClass()

import React from "react"

import { StepperContextProvider } from "QuorumGrassroots/widgets/Stepper/context"
import { useMultiActionChildren } from "QuorumGrassroots/services"
import { getIsThankPage } from "QuorumGrassroots/widgets/Campaign/components/helper"
import { XTwitterFarFAIcon } from "QuorumGrassroots/campaign-forms/components/MultiAction/XTwitterFarFAIcon"
import { StyledContrastText } from "QuorumGrassroots/styled-components/components/StyledContrastText"
import { AnimationContainer, useAnimation } from "QuorumGrassroots/campaign-forms/components/MultiAction/StepAnimation"
import { Stepper } from "QuorumGrassroots/widgets/Stepper"
import { StepperStyleWrapper } from "QuorumGrassroots/campaign-forms/components/MultiAction/styles"

const { CampaignType } = DjangIO.app.grassroots.campaign.types

interface CampaignTypeProps {
    minimal_label: string
    icon: string
    new_icon: string
}

export const MultiActionFormStepper = (props) => {
    const campaignId = props.campaign?.id?.toString() ?? ""
    const { animate, shouldAnimate } = useAnimation()

    const childCampaignsQuery = useMultiActionChildren(campaignId)
    const childCampaigns = childCampaignsQuery?.data?.objects ?? []

    const getIcon = (campaignType) => {
        switch (campaignType.new_icon) {
            case "x-twitter":
                return <XTwitterFarFAIcon size={16} />
            default:
                return (
                    <>
                        <i className={`fa ${campaignType.icon}`}></i>
                    </>
                )
        }
    }

    const steps = childCampaigns.map((campaign) => {
        const campaignType = CampaignType.by_value(campaign.campaign_type) as unknown as CampaignTypeProps
        return {
            name: campaign.campaign_type.toString(),
            title: campaignType.minimal_label,
            Icon: getIcon(campaignType),
            successIcon: <i className="fa fa-check"></i>,
            isSubmitted: getIsThankPage(),
        }
    })

    if (props.campaign.campaign_type !== CampaignType.multi_action.value) return props.children
    if (childCampaignsQuery.isLoading) return <StyledContrastText isCampaignPage>Loading...</StyledContrastText>
    if (childCampaignsQuery.isError)
        return <StyledContrastText isCampaignPage>We had a problem loading the campaigns</StyledContrastText>

    return (
        <StepperContextProvider initialSteps={steps} onChangeStep={animate}>
            <StepperStyleWrapper>
                <Stepper />
            </StepperStyleWrapper>
            <AnimationContainer shouldAnimate={shouldAnimate}>{props.children}</AnimationContainer>
        </StepperContextProvider>
    )
}

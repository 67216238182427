import React, {
    useCallback,
    useEffect,
    useRef,
    useState
} from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/DropdownGeneric/style"
import * as rules from "Components/rules"

import DropdownListItem from "Components/Molecules/DropdownListItem"

import { useOnClickOutside } from "utils/hooks"

const DropdownGeneric = (props) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const [isBoxOffScreen, setIsBoxOffScreen] = useState(false)
    const [rightEdgeOffset, setRightEdgeOffset] = useState(0)

    // distance, in px, to inset the dropdown box from the edge
    // of the screen in the case that it would be shown offscreen
    // (i.e. padding to the screen edge once moved back inbounds)
    const screenEdgePadding = parseInt(rules.TopBarPaddingV2, 10)

    const uniqueId = Math.floor(Math.random() * 10000)
    const dropdownBoxKey = `dropdown-box-${props.leftIcon}-${props.text}-${props.rightIcon}-${uniqueId}`

    const ref = useRef()
    // Not necessary, but prevents a small amount of work during re-renders
    const closeDropdown = useCallback(() => setIsExpanded(false), [])
    useOnClickOutside(ref, closeDropdown)


    const checkIsBoxOnScreen = () => {
        // Try to find the dropdown box; try to avoid data tag collision with other dropdowns
        // If the box is not present, it means the dropdown is closed; nothing needs to be done
        const boxNode = document.querySelector(`*[data-measurement="${dropdownBoxKey}"]`)

        if (boxNode) {
            const documentRightEdge = document.body.getBoundingClientRect().right
            const boxRightEdge = boxNode.getBoundingClientRect().right
            const boxLeftEdge = boxNode.getBoundingClientRect().left

            if (boxRightEdge > documentRightEdge || boxLeftEdge < 0 ) {
                // the right side of the dropdown box is offscreen
                // set the state to indicate this and re-render

                if (!isBoxOffScreen) {
                    setIsBoxOffScreen(true)
                }
            }

            // we placed the box on screen; measure and store how
            //far it is from the right edge of the screen to place it
            // a fixed distance from the edge
            if (isBoxOffScreen && props.isBoxRightAlign && rightEdgeOffset === 0) {
                setRightEdgeOffset(boxLeftEdge - screenEdgePadding)
            } else if ( isBoxOffScreen && rightEdgeOffset === 0) {
                setRightEdgeOffset((boxRightEdge - documentRightEdge) + screenEdgePadding)
            }

        }

        // Do not set isBoxOffScreen to false, because it will always be false
        // once we move the box back on screen (ergo infinite recursion here)
        // Instead, reset the state to be measured again when we close the box
    }

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded)

        // if the expanded state of the menu changes, assume the
        // box's location will need to be measured again
        setIsBoxOffScreen(false)
        setRightEdgeOffset(0)
    }

    useEffect(() => {
        checkIsBoxOnScreen()
    }, [isBoxOffScreen, isExpanded])

    const ButtonComponent = props.buttonComponent

    return (
        <S.DropdownWrapper
            data-auto-cy="MoleculeDropdownGeneric"
            key={`dropdownButton-${props.leftIcon}-${props.text}-${props.rightIcon}`}
            onClick={toggleExpanded}
            ref={ref}
        >
            {/* the button is always present */}
            <ButtonComponent {...props} expanded={isExpanded}/>

            {/* conditionally render the dropdown box */}
            {(isExpanded || props.forceOpen) && (
                <S.DropdownBox
                    data-measurement={dropdownBoxKey}
                    horizontalOffset={props.dropdownBoxOffsetHorizontal}
                    key="dropdownBoxKey"
                    isOverflow={props.isOverflow}
                    overflowHeightOffset={props.overflowHeightOffset}
                    buttonDropdownFixedHeight={props.buttonDropdownFixedHeight}
                    buttonDropdownFixedWidth={props.buttonDropdownFixedWidth}
                    type={props.type}
                    verticalOffset={props.dropdownBoxOffsetVertical}

                    // if the dropdown box is offscreen, apply special styling
                    isRightAlign={props.isBoxRightAlign}
                    forceOnScreen={isBoxOffScreen}
                    rightEdgeOffset={`${rightEdgeOffset}px`}
                >
                    {
                        props.menuOptions.map((item) =>
                            item && (
                                <DropdownListItem
                                    dataCy={item.dataCy}
                                    href={item.href}
                                    icon={item.icon}
                                    iconFamily={item.iconFamily}
                                    isActive={item.isActive}
                                    isLink={Boolean(item.href)}
                                    isOverflow={props.isOverflow}
                                    key={`${item.icon}-${item.text}`}
                                    onClick={item.onClick}
                                    SegueLinkComponent={item.segueLinkComponent}
                                    SegueLinkProps={item.segueLinkProps}
                                    rel={rules.aRel}
                                    newTab={item.newTab}
                                    text={item.text}
                                    type={item.type}
                                />
                            )
                        )
                    }
                    {props.menuOptionsChildren}
                </S.DropdownBox>)
            }
        </S.DropdownWrapper>
    )
}

DropdownGeneric.propTypes = {
    // a design system component that will be passed along all props, along
    // with 'expanded', which is true if the dropdown is open and false otherwise
    buttonComponent: PropTypes.func.isRequired,
    dataCy: PropTypes.string,

    // override space between the dropdown button and the box it opens
    dropdownBoxOffsetVertical: PropTypes.string,
    dropdownBoxOffsetHorizontal: PropTypes.string,

    // true if the dropdown should be open regardless of its internal state
    forceOpen: PropTypes.bool,

    // whether or not we should limit the height of the Dropdown and force an overflow for
    // Dropdowns with many DropdownListItem children
    isOverflow: PropTypes.bool,

    // menuOptions to populate the dropdown box
    menuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            dataCy: PropTypes.string,
            href: PropTypes.string,
            icon: PropTypes.string,
            iconFamily: PropTypes.string,
            newTab: PropTypes.bool,
            onClick: PropTypes.func,
            SegueLinkComponent: PropTypes.elementType,
            SegueLinkProps: PropTypes.object,
            text: PropTypes.string,
            type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
        })
    ),
    menuOptionsChildren: PropTypes.arrayOf(
        // app/static/frontend/search-new/components/FilterableListView/SearchActionButtons.js
        // renders <DropdownButton ... />

        // app/static/frontend/search-new/components/FilterableListView/ActionButton.js
        // renders <DropdownListItem ... />

        // so, menuOptionsChildren will be an array of QuorumDesign DropdownListItem components

        // see src/Components/Molecules/DropdownListItem/index
        // DropdownListItem.propTypes
        // (these are identical to the menuOptions PropType declared above)
        PropTypes.element
    ),
    overflowHeightOffset: PropTypes.number,
    // Can be used to set a fixed px size of the dropdown box
    buttonDropdownFixedHeight: PropTypes.number,
    buttonDropdownFixedWidth: PropTypes.number,
}

export default DropdownGeneric

import React, { Component } from "react"
import PropTypes from "prop-types"

import { runUserJavascript } from "QuorumGrassroots/helperFunctions"
import LoginForm from "QuorumGrassroots/widgets/LoginRegistration/containers/LoginForm"
import RegistrationForm from "QuorumGrassroots/widgets/LoginRegistration/containers/RegistrationForm"
import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"

/*
 * Login and registration are the same widget because they
 * ultimately do the same thing -- they sign in the user.
 *
 * For now, we're starting with the simplest case: if it's a
 * publicly accessible action center, always show the registration
 * form. If it's a private action center, always show the login form.
 *
 * Eventually, this component will have more logic to toggle between
 * the two forms. For another day!
 */
class LoginRegistrationWidget extends Component {
    componentDidMount() {
        if (this.props.customJS) {
            runUserJavascript(this.props.customJS)
        }
        this.updateCustomCss(this.props.customCss)
    }

    updateCustomCss(css) {
        let style = document.getElementById("registrationPageCss")
        if (!style && !css) return
        if (!style) {
            style = document.createElement("style")
            style.id = "registrationPageCss"
            document.head.appendChild(style)
        }
        style.innerHTML = css
    }

    componentWillUnmount() {
        this.updateCustomCss("")
    }

    render() {
        const headerText = this.props.publiclyAccessible ? this.props.t("navbar.sign_up") : this.props.t("navbar.login")
        return (
            <WidgetStyleWrapper
                md={this.props.colWidth}
                useWidgetStyling={this.props.useWidgetStyling}
                title={this.props.useWidgetStyling ? headerText : null}
            >
                {this.props.publiclyAccessible ? (
                    <RegistrationForm
                        {...this.props}
                        registrationPageIds={this.props.registrationPageIds || this.props.defaultRegistrationPageIds}
                    />
                ) : (
                    // for private action centers, pass registration page ids
                    // to login form for ConfirmationEmail sending reasons
                    <LoginForm
                        {...this.props}
                        registrationPageIds={this.props.registrationPageIds || this.props.defaultRegistrationPageIds}
                    />
                )}
            </WidgetStyleWrapper>
        )
    }
}

LoginRegistrationWidget.propTypes = {
    defaultRegistrationPageIds: PropTypes.array.isRequired,
    registrationPageIds: PropTypes.array,
    useWidgetStyling: PropTypes.bool.isRequired,
    publiclyAccessible: PropTypes.bool,
    colWidth: PropTypes.number,
    t: PropTypes.func.isRequired,
}

LoginRegistrationWidget.defaultProps = {
    useWidgetStyling: true,
}

export default LoginRegistrationWidget

import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { styleInDesktopScreenSize, styleInMobileScreenSize } from "QuorumGrassroots/styled-components/helperFunctions"
import { overrideFullBleedImageStyling } from "QuorumGrassroots/widgets/styled-components/helperFunctions"
import styled from "styled-components"

export const StyledVideoWidget = styled.iframe`
    width: 100%;
    height: 350px;
`
export const VideoWrapper = styled.div`
    margin: 0;
    height: fit-content;
    width: 100%;
    position: relative;
    ${styleInDesktopScreenSize(`
        margin-bottom: 20px;
    `)}
    ${styleInMobileScreenSize(`
        margin-bottom: 8px;
    `)}
`

export const FullBleedVideo = styleWithOrgDesign(styled.iframe`
    border: none;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: calc(90vh - 85px);
    justify-content: center;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
    min-height: 400px;
    width: 100%;
    ${styleInMobileScreenSize(`
        margin-bottom: 20px;
        margin-left: 5px;
        margin-right: 5px;
        padding-left: 0px;
        padding-right: 0px;
        text-align: center;
    `)}
    ${(props) => overrideFullBleedImageStyling(props)}
`)

export const prefix = "@@api-caching/"

const actionTypes = {
    GET_ITEM_START: `${prefix}GET_ITEM_START`,
    GET_ITEM_SUCCESS: `${prefix}GET_ITEM_SUCCESS`,
    GET_ITEM_FAIL: `${prefix}GET_ITEM_FAIL`,

    SET_ITEM_START: `${prefix}SET_ITEM_START`,

    GET_CACHE_START: `${prefix}GET_CACHE_START`,
    GET_CACHE_SUCCESS: `${prefix}GET_CACHE_SUCCESS`,
    GET_CACHE_FAIL: `${prefix}GET_CACHE_FAIL`,

    INVALIDATE_CACHE: `${prefix}INVALIDATE_CACHE`,
    INVALIDATE_CACHE_SLICE: `${prefix}INVALIDATE_CACHE_SLICE`,
    INVALIDATE_CACHE_SLICE_DEPRECATED: `${prefix}INVALIDATE_CACHE_SLICE_DEPRECATED`,

    MARK_RESOURCE_AS_UPDATED: `${prefix}MARK_RESOURCE_AS_UPDATED`,
}

export const getItemTypes = {
    start: actionTypes.GET_ITEM_START,
    success: actionTypes.GET_ITEM_SUCCESS,
    fail: actionTypes.GET_ITEM_FAIL,
}

export const getCacheTypes = {
    start: actionTypes.GET_CACHE_START,
    success: actionTypes.GET_CACHE_SUCCESS,
    fail: actionTypes.GET_CACHE_FAIL,
}

export default actionTypes

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/Tag/style"

const Tag = ({children, isGiant, isSelected, onClick}) => (
    <S.Tag
        data-auto-cy="MoleculeTag"
        isGiant={isGiant}
        isSelected={isSelected}
        isSelectable={!!onClick}
        onClick={onClick}
    >
        {children}
    </S.Tag>
)

Tag.defaultProps = {
    isGiant: false,
}

Tag.propTypes = {
    children: PropTypes.string.isRequired,
    isGiant: PropTypes.bool,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
}

export default Tag

import styled from "styled-components"

import { flexGap } from "utils/cssHelpers"
import * as colors from "Components/colors"

export const DialogWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    width: 475px;

    ${flexGap({ gap: 24, isColumn: true })}
`

export const IconWrapper = styled.div`
    font-size: 48px;
`

export const TitleWrapper = styled.div`
    font-size: 24px;
    font-weight: 500;
    color: ${colors.QuorumBlue};
    text-align: center;
`

export const TextWrapper = styled.div`
    font-size: 14px;
    text-align: center;
    white-space: pre-wrap;
`

export const ContentWrapper = styled.div`
    width: 100%;
`

export const ActionsWrapper = styled.div`
    display: flex;
    ${flexGap({ gap: 18 })}
    justify-content: center;
    align-items: center;
`

import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const Comment = styled.div`
    ${rules.CardStyle};
    border-color: ${colors.QuorumGrey4};
    box-shadow: none;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 8px 10px;

    margin: 3px;

    // comments in the same container shouldn't double their margins
    & + & {
        margin-bottom: 0px;
    }
`

export const CommentInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
`

export const CommentTextContainer = styled.div`
    display: flex;
    margin-top: 5px;
    font-size: 14px;
    line-height: 19px;

    overflow: auto;
    overflow-y: hidden;
    overflow-wrap: break-word;
`

export const CircleArea = styled.div`
    align-self: center;
    margin-right: 5px;
`

export const InfoArea = styled.div`
    display: flex;
    flex-direction: column;
    ${rules.MiniTextRegularWeight}
`

export const Name = styled.span`
    color: ${colors.Black};
`

export const Date = styled.span`
    color: ${colors.QuorumGrey4};
    // adding margin or padding will move the name span or circle since it will stretch the container
    transform: translateY(2px);
`

export const TextSpan = styled.span`
    width: 100%;
`

import React from "react"
import PropTypes from "prop-types"
import i18n from "i18n"

import { Field } from "redux-form/immutable"
import { fromJS } from "immutable"

import {
    formatMultiChoiceValues,
    normalizeMultiChoiceValues,
    normalizeSingleChoiceValue,
    numberValidation,
    requiredFalseValidation,
    requiredFieldValidation,
    requiredTrueValidation,
    urlInputValidation,
} from "frontend/forms/helperFunctions"

import { parseOptions } from "shared/customFields/constants/helperFunctions"

import InputField from "frontend/forms/components/InputField"
import GridToggleField from "frontend/forms/components/GridToggleField"
import SelectField from "frontend/forms/components/SelectField"
import InputGroupField from "frontend/forms/components/InputGroupField"
import DateField from "frontend/forms/components/DateField"
import SearchifySelectField from "forms/components/SearchifySelectField"

import {
    CUSTOM_FIELD_NONE_CREATED_BLURB_PREFIX,
    CUSTOM_FIELD_NONE_CREATED_BLURB_SUFFIX,
} from "shared/imports/sharedConstants"

import styled from "styled-components"
import * as constants from "frontend/design-constants"

const StyledTextURLInputField = styled.div`
    border-left: 4px solid ${constants.HeaderColor};
    padding-left: 11px;
    width: 50%;
`

export const renderCustomField = ({
    allFieldsMandatory,
    label,
    labelStyle,
    optionFieldComponent,
    optionsNew,
    selectedStyle,
    selectLabel,
    title,
    toggleChoices,
    tooltipText,
    unselectedStyle,
    validationFuncs,
    value,

    // to render one custom field directly in a form
    change,
    fieldName,
    formKey,
    formValue,
    placeholder,
    selectComponent,
    shouldRenderNewSelect,
    showAsterisk,
    toolTipTextActionCenterSettings,
    isReadOnlyCustomFields,
    disabled,
}) => {
    const renderTooltipActionCenterSettings = isReadOnlyCustomFields ? toolTipTextActionCenterSettings : null

    switch (value.tag_type) {
        case DjangIO.app.person.types.TagType.date.value:
            return (
                <Field
                    dataCy="custom-field-form-date"
                    key={title}
                    component={DateField}
                    className={value.slug}
                    name={fieldName}
                    label={label}
                    tooltipText={tooltipText || renderTooltipActionCenterSettings}
                    validate={validationFuncs}
                    labelStyle={labelStyle}
                    displayErrorWithoutTouch={!allFieldsMandatory}
                    clearable={true}
                    disabled={disabled}
                />
            )
        case DjangIO.app.person.types.TagType.string.value:
            return (
                <>
                    <Field
                        accessibilityId={label}
                        component={InputField}
                        dataCy="custom-field-form-string"
                        displayErrorWithoutTouch={!allFieldsMandatory}
                        isTextarea
                        key={title}
                        label={label}
                        labelStyle={labelStyle}
                        minRows={1}
                        name={fieldName}
                        placeholder={placeholder}
                        tooltipText={tooltipText || renderTooltipActionCenterSettings}
                        validate={validationFuncs}
                        disabled={disabled}
                    />
                    {value.is_hyperlinkable && (
                        <StyledTextURLInputField>
                            <Field
                                name={`${fieldName}_url`}
                                component={InputField}
                                dataCy="custom-field-form-string-external-link"
                                key={`${title}_url`}
                                label={`External Link for ${label}`}
                                placeholder="http://www.quorum.us/"
                                tooltipText="Enter an optional URL to hyperlink this Custom Field's text value."
                                validate={[...validationFuncs, urlInputValidation]}
                                labelStyle={labelStyle}
                                displayErrorWithoutTouch={!allFieldsMandatory}
                                renderErrorBelowField
                                accessibilityId={label}
                                minRows={1}
                                disabled={disabled}
                            />
                        </StyledTextURLInputField>
                    )}
                </>
            )
        case DjangIO.app.person.types.TagType.number.value:
            return (
                <Field
                    accessibilityId={label}
                    component={InputField}
                    dataCy="custom-field-form-number"
                    displayErrorWithoutTouch={!allFieldsMandatory}
                    key={title}
                    label={label}
                    labelStyle={labelStyle}
                    name={fieldName}
                    placeholder={placeholder}
                    tooltipText={tooltipText || renderTooltipActionCenterSettings}
                    validate={[...validationFuncs, numberValidation]}
                    disabled={disabled}
                />
            )

        case DjangIO.app.person.types.TagType.boolean.value:
            return (
                <Field
                    accessibilityId={label}
                    choices={
                        toggleChoices || [
                            {
                                label: "Yes",
                                value: true,
                            },
                            {
                                label: "No",
                                value: false,
                            },
                        ]
                    }
                    className={value.slug}
                    component={GridToggleField}
                    dataCy="custom-field-form-boolean"
                    displayErrorWithoutTouch={!allFieldsMandatory}
                    key={title}
                    label={label}
                    labelStyle={labelStyle}
                    name={fieldName}
                    pillWidth={6}
                    selectedStyle={selectedStyle}
                    tooltipText={tooltipText || renderTooltipActionCenterSettings}
                    unselectedStyle={unselectedStyle}
                    validate={validationFuncs}
                    showAsterisk={showAsterisk}
                    disabled={disabled}
                />
            )

        case DjangIO.app.person.types.TagType.single_option_list.value:
            return (
                <Field
                    accessibilityId={label}
                    choices={parseOptions(optionsNew)}
                    component={optionFieldComponent}
                    dataCy="custom-field-form-single_option_list"
                    defaultLabel={selectLabel}
                    displayErrorWithoutTouch={!allFieldsMandatory}
                    key={title}
                    label={label}
                    labelStyle={labelStyle}
                    name={fieldName}
                    normalize={normalizeSingleChoiceValue}
                    placeholder={placeholder}
                    shouldRenderNewSelect={shouldRenderNewSelect}
                    showAsterisk={showAsterisk}
                    tooltipText={tooltipText || renderTooltipActionCenterSettings}
                    validate={validationFuncs}
                    // The components has two different names to disable it. One is isDisabled and the other is disabled
                    isDisabled={disabled}
                    disabled={disabled}
                />
            )

        case DjangIO.app.person.types.TagType.multi_options_list.value:
            return (
                <Field
                    accessibilityId={label}
                    choices={parseOptions(optionsNew || [])}
                    component={optionFieldComponent}
                    dataCy="custom-field-form-multi_option_list"
                    defaultLabel={selectLabel}
                    displayErrorWithoutTouch={!allFieldsMandatory}
                    format={formatMultiChoiceValues}
                    key={title}
                    label={label}
                    labelStyle={labelStyle}
                    multi
                    name={fieldName}
                    normalize={normalizeMultiChoiceValues}
                    placeholder={placeholder}
                    shouldRenderNewSelect={shouldRenderNewSelect}
                    showAsterisk={showAsterisk}
                    tooltipText={tooltipText || renderTooltipActionCenterSettings}
                    // initial formValue passed to function is Immutable List
                    validate={validationFuncs}
                    // The components has two different names to disable it. One is isDisabled and the other is disabled
                    disabled={disabled}
                    isDisabled={disabled}
                />
            )
        case DjangIO.app.person.types.TagType.power_search.value:
            if (!selectComponent) {
                return <div />
            }

            return (
                <Field
                    component={SearchifySelectField}
                    customProps={{
                        label: label,
                        multi: true,
                        searchify: true,
                    }}
                    key={title}
                    label={label || DjangIO.app.models.QuorumDataType.by_value(value.power_search_data_set).plural}
                    placeholder={placeholder}
                    props={{
                        value: formValue,
                        name: fieldName,
                        model: DjangIO.newResourceFromDataType(value.power_search_data_set),
                        change: change,
                        dataCy: fieldName,
                        formKey: formKey,
                        placeholder: placeholder,
                        onChange: (selected) => change(fieldName, fromJS(selected.map((p) => p.id))),
                    }}
                    selectComponent={selectComponent}
                    shouldRenderNewLabel={false}
                    shouldRenderNewSelect={shouldRenderNewSelect}
                    showAsterisk={showAsterisk}
                    tooltipText={tooltipText || renderTooltipActionCenterSettings}
                    validate={validationFuncs}
                    disabled={disabled}
                />
            )
        default:
            return <div />
    }
}

const CustomFieldFormSection = ({
    allFieldsMandatory,
    className,
    customFieldNameDict,
    dataType,
    externalFacing,
    form,
    labelStyle,
    namePrefix,
    selectLabel,
    selectedStyle,
    toggleChoices,
    unselectedStyle,
    unregisterCustomField,
    toolTipTextActionCenterSettings,
    isReadOnlyCustomFields,
    disabled,
    requiredValues = {},
    optionalFields = {},
    conditionalCustomFields = [],
    conditionalParentValues = [],
}) => {
    const renderCustomFields = () => {
        if (Object.keys(customFieldNameDict).length) {
            return Object.entries(customFieldNameDict).map(([slug, value]) => {
                const title = value.name || value.id
                const optionsNew = value.options_new

                const fieldRequired = allFieldsMandatory || value.is_required

                const label = externalFacing ? i18n.t(value.external_name) : title
                const tooltipText = externalFacing ? i18n.t(value.external_description) : value.description

                const optionDisplayType = value.option_display_type
                const optionFieldComponent =
                    optionDisplayType === DjangIO.app.grassroots.enums.SupporterTagOptionDisplayType.list_display.value
                        ? InputGroupField
                        : SelectField

                // we have a number of validation functions that we need,
                // but they appear only sometimes.
                const validationFuncs = []

                // If field is not set to be optional
                // And all fields are mandatory or this field specifically is set to be required,
                // Add 'required' validation
                if (!optionalFields[value.id] && fieldRequired) {
                    validationFuncs.push(requiredFieldValidation)
                }

                if (requiredValues[value.id]) {
                    const requiredValue = requiredValues[value.id]

                    if (requiredValue === true) {
                        validationFuncs.push(requiredTrueValidation)
                    } else if (requiredValue === false) {
                        validationFuncs.push(requiredFalseValidation)
                    }
                }

                const fieldName = namePrefix ? `${namePrefix}.${slug}` : slug

                // If the custom field is conditional upon a parent custom field
                // and the parent custom field does not have the correct value to display it's children,
                // Unregister redux form field (so required conditioanl fields are only required if conditionally rendered) and return null
                if (
                    (conditionalCustomFields.includes(fieldName) &&
                        !conditionalParentValues.includes(fieldName) &&
                        form) ||
                    (!conditionalCustomFields.includes(fieldName) && !value.display_on_create_page && !externalFacing)
                ) {
                    unregisterCustomField(form, fieldName)
                    return null
                }

                return renderCustomField({
                    allFieldsMandatory,
                    fieldName,
                    label,
                    labelStyle,
                    optionFieldComponent,
                    optionsNew,
                    showAsterisk: validationFuncs.length > 0,
                    selectedStyle,
                    selectLabel,
                    title,
                    toggleChoices,
                    tooltipText,
                    unselectedStyle,
                    validationFuncs,
                    value,
                    toolTipTextActionCenterSettings,
                    isReadOnlyCustomFields,
                    disabled,
                })
            })
        } else if (dataType) {
            return (
                <div className="form-section-description">
                    {CUSTOM_FIELD_NONE_CREATED_BLURB_PREFIX + dataType + CUSTOM_FIELD_NONE_CREATED_BLURB_SUFFIX}
                </div>
            )
        } else {
            return <div />
        }
    }

    return <div className={className}>{renderCustomFields()}</div>
}

CustomFieldFormSection.defaultProps = {
    className: "",
    externalFacing: false,
    namePrefix: "",
}

CustomFieldFormSection.propTypes = {
    allFieldsMandatory: PropTypes.bool,
    customFieldNameDict: PropTypes.object.isRequired,
    className: PropTypes.string,
    dataType: PropTypes.string,
    externalFacing: PropTypes.bool,
    form: PropTypes.string.isRequired,
    optionalFields: PropTypes.object,
    namePrefix: PropTypes.string,
    requiredValues: PropTypes.object,
    shouldRenderNewComponents: PropTypes.bool,
    selectedStyle: PropTypes.object,
    selectLabel: PropTypes.string,
    toggleChoices: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.bool })),
    unselectedStyle: PropTypes.object,
    unregisterCustomField: PropTypes.func.isRequired,
    labelStyle: PropTypes.object,
    toolTipTextActionCenterSettings: PropTypes.string,
    isReadOnlyCustomFields: PropTypes.array,
}

export default CustomFieldFormSection

import styled from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const ColorInputsDefault = styled.div`
    display: flex;
    flex-direction: column;
    // #111827
    border-left: 1px solid #7f848e;
`

export const ColorInputs = styled.div`
    display: flex;

    input[type=color] {
        -webkit-appearance: none;
        border: ${colors.SilverChalice} solid 1px;
        border-radius: 3px;
        height: 20px;
        margin: 0 5px;
        padding: 3px;
        width: 50px;

        // https://stackoverflow.com/a/11471224/6201291
        &::-webkit-color-swatch-wrapper {
            padding: 0;
        }

        &::-webkit-color-swatch {
            border-radius: 1.74px;
        }
    }
`

export const Label = styled.span`
    ${rules.SmallMediumText}
    color: ${colors.QuorumBlue};
    margin-bottom: 7px;
`

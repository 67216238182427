import { createSelector, createStructuredSelector } from "reselect"
import createCachedSelector from "re-reselect"
import { Map, List, fromJS } from "immutable"

export const selectWidgets = (state, props) => state.widgets

export const selectWidgetSlice = createSelector(
    [selectWidgets, (_, props) => props.uniqueWidgetId],
    (widgets, uniqueWidgetId) => widgets.get(uniqueWidgetId, Map()),
)

export const selectWidgetSliceSection = (key, fallback) =>
    createSelector(selectWidgetSlice, (widgetSlice) =>
        fallback != null ? widgetSlice.get(key) || fallback : widgetSlice.get(key),
    )

export const selectIsWidgetContentLoading = selectWidgetSliceSection("loadingContent", false)
export const selectHasWidgetContentLoaded = selectWidgetSliceSection("loaded", false)
export const selectImmutableWidgetContent = selectWidgetSliceSection("content", Map())
export const selectWidgetLoadedTimestamp = selectWidgetSliceSection("timestamp")
export const selectWidgetSubmitted = selectWidgetSliceSection("submitted", false)
export const selectWidgetIsSubmitting = selectWidgetSliceSection("isSubmitting")
export const selectImmutableWidgetPostSubmitData = selectWidgetSliceSection("postSubmitData", Map())
export const selectVideoCompletionRequired = selectWidgetSliceSection("videoCompletionRequired", false)

export const selectWidgetRegistered = createSelector(selectWidgetSlice, (widgetSlice) => widgetSlice.size > 0)

export const selectHasWidgetContent = createSelector(selectWidgetSlice, (widgetSlice) =>
    Boolean(widgetSlice.get("content", Map()).size),
)

export const selectWidgetContent = createSelector(selectImmutableWidgetContent, (content) => content.toJS())

export const selectWidgetPostSubmitData = createSelector(selectImmutableWidgetPostSubmitData, (postSubmitData) =>
    postSubmitData.toJS(),
)

export const selectAugmentedWidgetContent = createSelector(
    [selectImmutableWidgetContent, (_, props) => props.location.state || {}],
    (content, routeState) => {
        if (content.size) {
            return content.toJS()
        }

        return routeState
    },
)

export const selectWidgetsFromProps = (_, props) => props.widgets

const selectWidgetNumber = (_, props) => props.widgetNumber

export const selectIsFirst = createSelector(selectWidgetNumber, (widgetNumber) => widgetNumber === 1)

export const selectIsLast = createSelector(
    selectWidgetNumber,
    selectWidgetsFromProps,
    (widgetNumber, widgets) => widgets.length === widgetNumber,
)

export const selectNextWidget = createSelector(
    selectWidgetNumber,
    selectWidgetsFromProps,
    selectIsLast,
    (widgetNumber, widgets, isLast) => (isLast ? undefined : widgets[widgetNumber].widgetType),
)

export const fullWidthWidgetConnection = createStructuredSelector({
    isFirst: selectIsFirst,
    isLast: selectIsLast,
    nextWidget: selectNextWidget,
})

/**
 * This is dealing with form logic. Because we set a bunch of things in
 * a form's initialValues but only can manipulate a few, we'll
 * need a way of determining which fields are editable and which
 * ones aren't. Getting `registeredFields` from reduxForm is the way to go.
 */
export const selectFormState = (state, props) => state.form || Map()

export const selectFormSection = createSelector([selectFormState, (_, props) => props.form], (formState, formName) =>
    formState.get(formName, Map()),
)

export const selectWidgetFormRegisteredFields = createSelector(selectFormSection, (formSection) =>
    formSection.get("registeredFields", Map()).keySeq().toJS(),
)

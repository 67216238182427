import { createSelector, createStructuredSelector } from "reselect"
import { List, fromJS } from "immutable"

import {
    createCampaignWithMessagesSelector,
    selectActiveGroupId,
    selectCampaignMessageGroups,
    selectCampaignMessageList,
    selectHasMultipleMessages,
    selectImmutableCampaignMessages,
    selectNumberOfMessagesAfterFiltering,
} from "QuorumGrassroots/campaign-forms/selectors/general-selectors"

import { selectWidgetContent } from "QuorumGrassroots/widgets/selectors"
import { shouldShowOrganizationField } from "QuorumGrassroots/campaign-forms/helpers"

// For use for all campaigns that involve pre-loaded messages.
// Used by the redux-form that is created in
// campaign-forms/wrappers/GetMessagesFormWrapper.js
export const selectWriteInitialValues = createSelector(
    [selectWidgetContent, selectImmutableCampaignMessages],
    (campaign, messages) => {
        // messages is an object of {id: values}
        let initialValues = messages.map((message) => {
            return fromJS({
                description: message.get("description"),
                subject: message.get("subject"),
                prebody: message.get("fixed_pre_body"),
                // this is so that we start where a user left off in case
                // the user leaves the page and then comes back.
                body: message.getIn(["edits", "editedBodies"], List()).get(message.getIn(["edits", "currentBodyIdx"])),
                postbody: message.get("fixed_post_body"),
                target: message.get("target"),
                campaignId: message.get("campaign_id"),
                ...(shouldShowOrganizationField(campaign)
                    ? { submitting_org_name: message.get("submitting_org_name") } ?? ""
                    : {}),
            })
        })

        const groupsWithoutMessages = messages
            .map((message) => message.get("group"))
            .toSet()
            .toList()
            .filter((group) => group) //remove undefined groups

        const groups = groupsWithoutMessages.size
            ? groupsWithoutMessages.map((group) =>
                  group.set(
                      "messages",
                      messages.filter((message) => message.getIn(["group", "id"]) === group.get("id")),
                  ),
              )
            : List()

        // this sets the initial value for each group to be the first message in the group.
        groups.forEach((group) => {
            const id = group.get("id")
            const message = group.get("messages").first()
            const initialGroupMessage = fromJS({
                description: message.get("description"),
                subject: message.get("subject"),
                prebody: message.get("fixed_pre_body"),
                body: message.getIn(["edits", "editedBodies"], List()).get(message.getIn(["edits", "currentBodyIdx"])),
                postbody: message.get("fixed_post_body"),
                target: message.get("target"),
                campaignId: message.get("campaign_id"),
            })
            initialValues = initialValues.set(id.toString(), initialGroupMessage)
        })

        // set "global" values.
        const firstId = initialValues.keySeq().first()
        return initialValues.set("global", initialValues.get(firstId))
    },
)

export const selectIsCombinedCampaign = createSelector(
    selectWidgetContent,
    (campaign) => campaign.campaign_type === DjangIO.app.grassroots.campaign.types.CampaignType.combined_campaign.value,
)

/**
 * For use in GenericWriteForm. If supporters are allowed to choose
 * who they wish to target, then show based on the initial TargetTable criteria
 * plus the additional criteria that the message group does not have custom targets.
 */
export const selectShouldShowMultiMessageTargetTable = createSelector(
    [selectActiveGroupId, selectCampaignMessageGroups, selectIsCombinedCampaign],
    (activeGroupId, messageGroups, isCombinedCampaign) =>
        isCombinedCampaign ||
        !messageGroups.find((group) => Number(group.id) === Number(activeGroupId))?.targets[0].is_custom,
)

/**
 * For use in GenericWriteForm.
 *
 * Selector determining what text the "submit" button should display.
 * Surprisingly varied depending on situation.
 */
export const selectSubmitButtonTextKey = createSelector(
    [selectWidgetContent, selectHasMultipleMessages, selectNumberOfMessagesAfterFiltering],
    (campaign, hasMultipleMessages, hasMessagesAfterFiltering) => {
        // if there's only one message, don't bother with the frills. Just click
        // submit. You shouldn't be able to untarget a message with a single person.
        if (!hasMultipleMessages) {
            return "form.submit_form"
        }

        if (campaign.campaign_type === DjangIO.app.grassroots.campaign.types.CampaignType.combined_campaign.value) {
            return "campaign.write.submit_all"
        }

        // if the supporter can deselect targets at will, either render "submit selected"
        // or tell them that they can't just deselect everything. You've got to participate,
        // geez.
        if (campaign.supporters_can_choose_targets) {
            return hasMessagesAfterFiltering ? "campaign.write.submit_selected" : "campaign.write.need_selection"
        }
        // if we're going to submit all, just show submit.
        else {
            return "form.submit_form"
        }
    },
)

/**
 * For use in SingleBodyMessageLayout. Selector determining whether or not
 * explanatory text should be shown (about how the fixed pre message + fixed
 * post message text will change based on target audience). Basically, if there
 * are target specific placeholders, then the premessage and the postmessage will
 * differ across message.
 */
export const selectShouldShowSingleBodyMessage = createSelector(selectCampaignMessageList, (messages) => {
    if (!messages.length) {
        return false
    }

    const prebody = messages[0].fixed_pre_body
    const postbody = messages[0].fixed_post_body

    return !messages.every((message) => message.fixed_pre_body === prebody && message.fixed_post_body === postbody)
})

const additionalSelectors = {
    initialValues: selectWriteInitialValues,
    shouldShowMultiMessageTargetTable: selectShouldShowMultiMessageTargetTable,
    submitButtonTextKey: selectSubmitButtonTextKey,
}

export const writeCampaignSelectors = createCampaignWithMessagesSelector(additionalSelectors)

export const singleBodySelectors = createStructuredSelector({
    shouldShowMessage: selectShouldShowSingleBodyMessage,
})

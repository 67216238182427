import React from "react"
import PropTypes from "prop-types"

import * as rules from "Components/rules"
import * as S from "Components/Compounds/ButtonRow/style"

import { InternalButton } from "Components/Molecules/Button"
import DropdownButton from "Components/Molecules/DropdownButton"


const ButtonRow = ({
    buttonOptions,
    iconResponsiveBreakpoint,
    buttonDropdownFixedHeight,
    buttonDropdownFixedWidth,
    textResponsiveBreakpoint,
}) => {
    const renderInternalButton = (option, i) => (
        <S.ButtonContainer
            key={i}
            iconResponsiveBreakpoint={iconResponsiveBreakpoint}
            text={option.text}
            textResponsiveBreakpoint={textResponsiveBreakpoint}
        >
            <InternalButton
                dataCy={option.dataCy || `button-row-${option.text}`}
                disabled={option.disabled}
                forceActive={option.isActive}
                isGiant={option.isGiant}
                isTiny
                key={`button-row-${option.text}`}
                leftIcon={option.icon}
                leftIconFamily={option.icon_family || "fas"}
                leftIconSize={(option.iconSize || rules.IconFontSizeV2)}
                leftIconSpin={option.iconSpin}
                leftIconFixedWidth={false}
                onClick={event => option.onClick(event)}
                text={option.text}
                type={option.type || "secondary-outlined"}
            />
        </S.ButtonContainer>
    )

    const renderDatasetButtons = () => (
        // render different types of buttons in a row and keep a consistent style
        buttonOptions && buttonOptions
            .map((option, i) => {
                // option.component specifies the type of button that needs to be rendered
                // if not provided, will default to use InternalButton
                switch(option.component) {
                    case "dropdown":
                        const dropdownButton = (
                            <DropdownButton
                                data-cy={option.dataCy || `button-row-dropdown-${option.menuOptions[0].text}`}
                                disabled={option.disabled}
                                dropdownBoxOffsetVertical={option.dropdownBoxOffsetVertical}
                                isBoxRightAlign
                                isGridButton
                                isOverflow
                                isTiny
                                key={`buttonrow-dropdown-${option.menuOptions.length}`}
                                leftIcon={option.leftIcon}
                                leftIconFamily={option.leftIconFamily}
                                leftIconSize={option.leftIconSize}
                                menuOptions={option.menuOptions}
                                buttonDropdownFixedHeight={buttonDropdownFixedHeight}
                                buttonDropdownFixedWidth={buttonDropdownFixedWidth}
                                rightIconSize={rules.IconFontSizeV2}
                                text={option.text || ""}
                                type={option.type || "outlined"}
                            />
                        )
                        const buttons = [dropdownButton]

                        if (option.siblingElements && option.siblingElements.length) {
                            buttons.push(...option.siblingElements)
                        }

                        if (option.menuOptions && option.menuOptions.length) {
                            if (option.parentElement) {
                                return (
                                    <S.ButtonContainer
                                        iconResponsiveBreakpoint={iconResponsiveBreakpoint}
                                        text={option.text}
                                        textResponsiveBreakpoint={textResponsiveBreakpoint}
                                    >
                                        <option.parentElement>
                                            {buttons.map(button => button)}
                                        </option.parentElement>
                                    </S.ButtonContainer>
                                )
                            }
                            return dropdownButton
                        }
                    case "segue":
                        const SegueLinkComponent = option.segueLinkComponent

                        return (
                            <SegueLinkComponent
                                key={i}
                                data-cy={option.segueLinkProps.dataCy}
                                optionalPreSegueFunction={option.segueLinkProps.optionalPreSegueFunction}
                                to={option.segueLinkProps.to}
                            >
                                {renderInternalButton(option, 0)}
                            </SegueLinkComponent>
                        )
                    case "button":
                    default:
                        return renderInternalButton(option, i)
                }
            })
    )

    return renderDatasetButtons()
}

ButtonRow.propTypes = {
    // array of buttons that will be shown, in order from left to right
    buttonOptions: PropTypes.arrayOf(
        PropTypes.shape({
            // the type of button that needs to be rendered
            component: PropTypes.oneOf(["dropdown", "button"]),
            icon: PropTypes.string,
            iconSize: PropTypes.string,
            icon_family: PropTypes.string,
            // pass forceActive prop to Button if true
            isActive: PropTypes.bool,
            // render a star as rightIcon
            isStarred: PropTypes.bool,
            // dropdown items for DropdwonButton
            menuOptions: PropTypes.arrayOf(PropTypes.object),
            // callback for when the button is clicked
            onClick: PropTypes.func,
            segueLinkComponent: PropTypes.elementType,
            segueLinkProps: PropTypes.object,
            // text to show on a button
            text: PropTypes.string,
            // type of button
            type: PropTypes.string,
        })
    ),
    // the media query breakpoint where we will hide the Button S.Icon
    iconResponsiveBreakpoint: PropTypes.string,
    // the media query breakpoint where we will hide the Button S.Text
    textResponsiveBreakpoint: PropTypes.string,
    buttonDropdownFixedHeight: PropTypes.number,
    buttonDropdownFixedWidth: PropTypes.number,
}

export default ButtonRow

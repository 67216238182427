import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/CheckInputField/style"

export const CheckInputField = props => (
    <S.CheckInputFieldContainer data-auto-cy="AtomCheckInputField">
        <S.StyledInput
            data-cy={props.dataCy}
            disabled={props.disabled}
            error={props.meta.error}
            maxLength={props.maxLength}
            placeholder={props.placeholder}
            type="text"
            inputWidth={props.inputWidth}
            isAddress={props.isAddress}
            // necessary to get this working with redux-form
            // https://redux-form.com/7.4.0/docs/faq/customcomponent.md/
            {...props.input}
        />
        {
            props.meta.error &&
                <S.LabelError>{props.meta.error}</S.LabelError>
        }
    </S.CheckInputFieldContainer>
)

CheckInputField.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),

    // from redux-form Field
    input: PropTypes.object,
    // error state implementation based off of
    // https://redux-form.com/7.4.0/examples/syncvalidation/
    meta: PropTypes.object,

    // props
    dataCy: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
}

export default CheckInputField

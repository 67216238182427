import { combineReducers } from "redux"
import { reducer as formReducer } from "redux-form/immutable"

import apiCachingReducer from "shared/api-caching/reducer"
import frameworkReducer from "QuorumGrassroots/framework/reducer"
import widgetReducer from "QuorumGrassroots/widgets/reducer"

export const reducer = combineReducers({
    apiCache: apiCachingReducer,
    form: formReducer,
    framework: frameworkReducer,
    widgets: widgetReducer,
})

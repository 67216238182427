import React from "react"
import PropTypes from "prop-types"
import withImmutablePropsToJS from "with-immutable-props-to-js"

import LegacySelect from "app/static/frontend/selects/components/LegacySelect/index"
import NewSelect from "app/static/frontend/selects/components/Select/index"

/**
 * Side-effect free rendering of either the old Select component with stylus imports,
 * And the new Select with just react-select overrides and styled-components
 */
const RenderSelect = (props) => (props.shouldRenderNewSelect ? <NewSelect {...props} /> : <LegacySelect {...props} />)

RenderSelect.defaultProps = {
    shouldRenderNewSelect: false,
}

RenderSelect.propTypes = {
    shouldRenderNewSelect: PropTypes.bool,
}

export default withImmutablePropsToJS(RenderSelect)

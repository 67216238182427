//

const SearchActionTypes = {
    SEARCH_STORE_MERGE_DEEP: "SEARCH_STORE_MERGE_DEEP",
    // load default search filters
    LOADING_DEFAULT_SEARCH_FILTERS_START: "LOADING_DEFAULT_SEARCH_FILTERS_START",
    LOADED_DEFAULT_SEARCH_FILTERS: "LOADED_DEFAULT_SEARCH_FILTERS",
    STORE_PROCESSED_DEFAULT_FILTERS: "STORE_PROCESSED_DEFAULT_FILTERS",
    APPLY_DEFAULT_SEARCH_FILTERS: "APPLY_DEFAULT_SEARCH_FILTERS",
    REGISTER_PERMANENT_FILTERS: "REGISTER_PERMANENT_FILTERS",

    APPLY_EXTERNAL_FILTERS: "APPLY_EXTERNAL_FILTERS",
    // filterable list views
    REGISTER_FILTERABLE_LIST_VIEW: "REGISTER_FILTERABLE_LIST_VIEW",
    REGISTER_FLV_DATA_TYPE: "REGISTER_FLV_DATA_TYPE",
    RESET_FILTERABLE_LIST_VIEW: "RESET_FILTERABLE_LIST_VIEW",
    UNREGISTER_FILTERABLE_LIST_VIEW: "UNREGISTER_FILTERABLE_LIST_VIEW",
    CLEAR_ALL_FILTERABLE_LIST_VIEWS: "CLEAR_ALL_FILTERABLE_LIST_VIEWS",
    UPDATE_FLV_SLICE: "UPDATE_FLV_SLICE",
    STORE_LAST_USER_UPDATE: "STORE_LAST_USER_UPDATE",
    // manipulating filters
    REGISTER_FILTER: "REGISTER_FILTER",
    UPDATE_FILTER: "UPDATE_FILTER",
    UPDATE_MULTIPLE_FILTERS: "UPDATE_MULTIPLE_FILTERS",
    UPDATE_FILTER_KEY: "UPDATE_FILTER_KEY",
    RESET_FILTERS: "RESET_FILTERS",
    RESET_SELECT_FILTERS: "RESET_SELECT_FILTERS",
    SET_ALL_FILTERS_REGISTERED: "SET_ALL_FILTERS_REGISTERED",
    RESET_DEFAULT_FILTERS: "RESET_DEFAULT_FILTERS",
    REGISTER_MULTI_FILTER: "REGISTER_MULTI_FILTER",
    REGISTER_SERIALIZE_FUNCTION: "REGISTER_SERIALIZE_FUNCTION",
    REGISTER_DESERIALIZE_FUNCTION: "REGISTER_DESERIALIZE_FUNCTION",
    // permanent filter logic
    STORE_PERMANENT_FILTER: "STORE_PERMANENT_FILTER",
    REMOVE_PERMANENT_FILTERS: "REMOVE_PERMANENT_FILTERS",
    // preload data (custom fields, default searches)
    LOAD_SEARCH_PRELOAD_DATA_START: "LOAD_SEARCH_PRELOAD_DATA_START",
    LOAD_SEARCH_PRELOAD_DATA_SUCCESS: "LOAD_SEARCH_PRELOAD_DATA_SUCCESS",
    LOAD_SEARCH_PRELOAD_DATA_FAIL: "LOAD_SEARCH_PRELOAD_DATA_FAIL",
    // loading data
    LOADING_DATA_START: "LOADING_DATA_START",
    LOADING_DATA_FINISH: "LOADING_DATA_FINISH",
    LOADING_DATA_ERROR: "LOADING_DATA_ERROR",
    LOADING_COUNT_START: "LOADING_COUNT_START",
    LOADING_COUNT_FINISH: "LOADING_COUNT_FINISH",
    DATA_LOADED: "DATA_LOADED",
    COUNT_LOADED: "COUNT_LOADED",
    TOGGLE_SEARCH_COUNT_PRECISION: "TOGGLE_SEARCH_COUNT_PRECISION",
    LOADING_EXACT_COUNT_START: "LOADING_EXACT_COUNT_START",
    LOADING_EXACT_COUNT_SUCCESS: "LOADING_EXACT_COUNT_SUCCESS",
    LOADING_EXACT_COUNT_FAIL: "LOADING_EXACT_COUNT_FAIL",
    BEGIN_LOADING_MORE_DATA: "BEGIN_LOADING_MORE_DATA",
    LOADED_MORE_DATA: "LOADED_MORE_DATA",
    CANCEL_LOADING_MORE_DATA: "CANCEL_LOADING_MORE_DATA",
    // filter group logic
    TOGGLE_FILTER_GROUP: "TOGGLE_FILTER_GROUP",
    OPEN_FILTER_GROUP: "OPEN_FILTER_GROUP",
    CLOSE_ALL_FILTER_GROUPS: "CLOSE_ALL_FILTER_GROUPS",
    SET_SHOW_MANUALLY_CHANGED_IN_LIST_FILTER: "SET_SHOW_MANUALLY_CHANGED_IN_LIST_FILTER",
    // visualization and action button logic
    SET_ACTIVE_ACTION_BUTTON: "SET_ACTIVE_ACTION_BUTTON",
}

export default SearchActionTypes

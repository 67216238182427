import { useCallback, useEffect, useRef } from "react"

export function useDebouncedCallback<T extends (...args: any[]) => void>(callback: T, delay: number) {
    const timeoutRef = useRef<number | null>(null)

    const debouncedCallback = useCallback(
        async (...args: Parameters<T>) => {
            if (timeoutRef.current !== null) {
                clearTimeout(timeoutRef.current)
            }
            timeoutRef.current = window.setTimeout(() => {
                callback(...args)
            }, delay)
        },
        [callback, delay],
    )

    useEffect(() => {
        return () => {
            if (timeoutRef.current !== null) {
                clearTimeout(timeoutRef.current)
            }
        }
    }, [])

    return debouncedCallback
}

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/Counter/style"

import * as colors from "Components/colors"

import CircleImage from "Components/Molecules/CircleImage"
import Tooltip from "Components/Molecules/Tooltip"
import * as helperFunctions from "utils/helperFunctions"

const MAX_VALUE_LENGTH = 3
const SMALL_COUNTER_MAX_VALUE_LENGTH = 4
const PREFERRED_FONT_SIZE = 24
const SMALL_FONT_SIZE = 15
const LARGE_CIRCLE_DIAMETER = 60
const SMALL_CIRCLE_DIAMETER = 40
const MIN_NUMBER_FOR_DYNAMIC_HOVER = 999
const LARGE_COUNTER = "large"

const fontSizeObj = {
    small: {
        diameter: SMALL_CIRCLE_DIAMETER,
        preferredSize: SMALL_FONT_SIZE,
        maxValue: SMALL_COUNTER_MAX_VALUE_LENGTH,
    },
    large: {
        diameter: LARGE_CIRCLE_DIAMETER,
        preferredSize: PREFERRED_FONT_SIZE,
        maxValue: MAX_VALUE_LENGTH,
    }
}

const Counter = ({
    circleColor,
    cursor,
    dynamic,
    flexDirection,
    label,
    opacity,
    roundingFn,
    size,
    subtitle,
    tooltipText,
    value,
}) => {

    // If we have a rounding function, use it
    const displayValue = roundingFn ? roundingFn(value) : value

    // Avoid changing the font size when the displayValue is falsy
    const valueLength = displayValue ? String(displayValue).length : 1

    // If the string of the value we want to show is too long, it won't fit nicely in the circle
    // Do simple scaling of the font size based on the number of characters in the string (shrink the font)
    const { diameter, preferredSize, maxValue } = fontSizeObj[size]

    const fontSize = valueLength > maxValue
        ? preferredSize * (maxValue / valueLength)
        : preferredSize

    return (
        <S.Counter
            data-auto-cy="MoleculeCounter"
            id="ds-counter"
            flexDirection={flexDirection}
            pointer={Boolean(tooltipText)}
        >
            {
                tooltipText
                && (
                    <S.TooltipPositioner>
                        <Tooltip text={tooltipText}/>
                    </S.TooltipPositioner>
                )
            }
            <CircleImage
                diameter={`${diameter}px`}
                text={displayValue}
                hoverText={dynamic && helperFunctions.formatNumberWithCommas(value)}
                circleColor={circleColor || colors.QuorumBlue}
                fontColor={colors.White}
                fontSize={`${fontSize}px`}
                dynamicHover={dynamic && value > MIN_NUMBER_FOR_DYNAMIC_HOVER}
                opacity={opacity}
                fill
                cursor={cursor}
            />
            <S.LabelContainer>
                { label && <S.LabelSpan>{label}</S.LabelSpan> }
                { subtitle && <S.SubtitleSpan>{subtitle}</S.SubtitleSpan> }
            </S.LabelContainer>
        </S.Counter>
    )
}

Counter.defaultProps = {
    size: LARGE_COUNTER,
    flexDirection: "row",
}

Counter.propTypes = {
    // color of the counter circle
    circleColor: PropTypes.string,
    // the text label shown at right of the counter
    label: PropTypes.string,
    // callback to use when rounding the value
    roundingFn: PropTypes.func,
    // subtext to show beneath the label
    subtitle: PropTypes.string,
    // hover text
    tooltipText: PropTypes.string,
    // string value to show inside the counter
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Counter

import React, { Component } from "react"
import PropTypes from "prop-types"

import { ADDRESS_KEY } from "shared-web/forms/constants"

export class AddressAutocompleteInput extends Component {
    static defaultProps = {
        inputProps: {
            value: PropTypes.string.isRequired,
            onChange: PropTypes.func.isRequired,
        },
        onAddressClick: PropTypes.func.isRequired,
        options: {},
        shouldGeocode: false,
        addressKey: "input_address",
    }

    state = {
        hasInitializedGeocoding: false,
    }

    componentDidMount() {
        // in some cases, the google maps script may fail to load
        if (!window.google) {
            setTimeout(() => {
                // try once more if first timeout is not enough
                if (!window.google) {
                    setTimeout(() => {
                        // if both waits are not long enough, just give up on the autocomplete
                        if (window.google) {
                            this.init()
                        }
                    }, 15000)
                } else {
                    this.init()
                }
            }, 5000)
        } else {
            this.init()
        }
    }

    init = () => {
        // one day we might want more fields. For now, we're not going to bother.
        // If we ever need the priciness of restaurants according to Google,
        // please contact me to tell me.
        const fields = this.props.shouldGeocode
            ? ["address_components", "geometry", "place_id", "formatted_address"]
            : ["place_id", "name", "types"]

        const options = {
            ...this.props.options,
            fields,
            types: ["address"],
        }

        this.addressTypeahead = new google.maps.places.Autocomplete(this.addressInput, options)

        this.addressTypeahead.addListener("place_changed", this.onAddressSelect)
        this.setState({ hasInitializedGeocoding: true })
    }

    onAddressSelect = () => {
        // if getPlace is called with the default fields ['place_id', 'name', 'types],
        // a geocoding request will NOT be made. However, if you
        // get more specific fields such as address_components,
        // geometry, or anything, a getPlaceDetail WILL be called
        // and we will be charged money. So if we're getting that
        // information, be sure to use it!
        //
        // If you're geocoding, this will set the value on the redux form field itself,
        // so your submit form will have to take care of that.
        const { address_components, geometry, place_id, formatted_address } = this.addressTypeahead.getPlace()

        let formValue = this.props.shouldGeocode
            ? {
                  address_components,
                  geometry,
                  place_id: place_id,
                  formatted_address: formatted_address,
                  [ADDRESS_KEY]: this.addressInput.value,
              }
            : this.addressInput.value

        this.props.onAddressClick(formValue)
    }

    render() {
        return (
            <input
                ref={(input) => (this.addressInput = input)}
                {...this.props.inputProps}
                style={{ borderRadius: "4px" }}
            />
        )
    }
}

export default AddressAutocompleteInput

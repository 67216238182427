import styled from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const FormInputFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const Label = styled.span`
    ${rules.SmallMediumText}
    color: ${colors.QuorumBlue};
    margin-top: 15px;
    margin-bottom: 6px;
`

export const LabelError = styled.span`
    ${rules.SmallText}
    color: ${colors.ReduxFormErrorColor};
    margin-bottom: 7px;
`

export const FormInputField = styled.textarea`
    background-color: ${colors.White};
    border-radius: 3px;
    border: ${({error}) => error ? colors.ReduxFormErrorColor : colors.SilverChalice} solid 1px;
    flex-grow: 1;
    height: 160px;
    letter-spacing: -0.31px;
    padding-left: 11px;
    padding-top: 9px;
    width: 100%;
    ${rules.SmallTextSmallHeightRegularWeight}

    &::placeholder {
        color: ${colors.QuorumGrey3};
    }

    &:focus {
        outline: 0;
    }
`

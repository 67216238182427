// https://projects.invisionapp.com/d/main/#/console/21044500/448838460/preview

import React from "react"
import PropTypes from "prop-types"

import * as colors from "Components/colors"
import * as S from "Components/Compounds/Card/style"

import Icon from "Components/Atoms/Icon"
import Button from "Components/Molecules/Button"
import DropdownButton from "Components/Molecules/DropdownButton"
import Tooltip from "Components/Molecules/Tooltip/index"
import InputFileButton from "Components/Molecules/InputFileButton"

const Card = (props) => {
    const getIcon = () => {
        if (props.imageUrlPath) {
            return (
                <div className="url-icon-wrapper">
                    <img
                        alt="Inline Icon"
                        className="url-image"
                        src={props.imageUrlPath}
                    />
                </div>
            )
        }
        else if (props.imagePath) {
            return (
                <img
                    alt="Inline Icon"
                    className={props.imagePathClassName}
                    onError={props.imagePathOnError}
                    src={props.imagePath}
                />
            )
        }
        else if (props.stateIcon) {
            return (
                <div className="icon-wrapper state-icon">
                    <icon className={`inline-icon stateface stateface-${props.abbrev}`} />
                </div>
            )
        }
        else if (props.iconName) {
            return (
                <S.IconNameContainer className={props.iconNameContainerClassName}  >
                    <i className={props.iconNameClassName} />
                </S.IconNameContainer>
            )
        }
        else if (props.fa5IconName) {
            return (
                <S.IconNameContainer className={props.iconNameContainerClassName}  >
                    <div className="fa5-inline-icon">
                        <Icon
                            fixedWidth
                            icon={props.fa5IconName}
                            iconFamily={props.fa5IconFamily}
                            size="1x"
                        />
                    </div>
                </S.IconNameContainer>
            )
        }
    }

    const renderTimeline = () => (
        props.timelineStatuses &&
            <S.BillStatusTimeline>
            {
                props.timelineStatuses.map((timelineStatus, index) => {
                    const timelineEnumItem = props.timelineEnum.by_value(
                        timelineStatus.status
                    )

                    return (
                        <S.BillStatusTimelineItem>
                            {/* exactly one of BillStatusTimelineLabel and CondensedLabel will display based on screen size */}
                            <S.BillStatusTimelineLabel
                                achieved={timelineStatus.achieved}
                            >
                                {timelineEnumItem.short_label || timelineEnumItem.label}
                            </S.BillStatusTimelineLabel>
                            <S.CondensedLabel
                                achieved={timelineStatus.achieved}
                            />

                            {
                                index !== props.timelineStatuses.length - 1 &&
                                    <S.BillStatusTimelineDivider
                                        achieved={props.timelineStatuses[index+1].achieved}
                                    />
                            }
                        </S.BillStatusTimelineItem>
                    )
                })
            }
            </S.BillStatusTimeline>
    )

    const renderSecondLineItem = secondLineItem => (
        <S.SecondLineItem
            isLink={secondLineItem.id && secondLineItem.profile}
        >
            {
                secondLineItem.fa5Icon &&
                    <Icon
                        color={colors.QuorumBlue}
                        icon={secondLineItem.fa5Icon}
                        iconFamily={secondLineItem.fa5IconFamily}
                        key={secondLineItem.fa5Icon}
                        size={"1x"}
                    />
            }
            <S.SecondLineLabel
                fa5Icon={secondLineItem.fa5Icon}
                isLink={secondLineItem.id && secondLineItem.profile}
            >
                {
                    secondLineItem.url ? (
                        <a target="_blank" href={secondLineItem.url}>{secondLineItem.value}</a>
                    ) : (
                        secondLineItem.value
                    )
                }
            </S.SecondLineLabel>
        </S.SecondLineItem>
    )

    const renderOption = (option) => {
        let OptionComponent
        if (option.isDropdown) {
            OptionComponent = (
                <DropdownButton
                    isTiny
                    leftIcon={option.fa5_icon}
                    leftIconFamily={option.fa5_icon_family}
                    leftIconSize="14px"
                    menuOptions={option.menuOptions}
                    rightMargin="7px"
                    text={option.label}
                    type="outlined"
                    dataCy={option.dataCy}
                />
            )
        } else if (option.isFileInput) {
            OptionComponent = (
                <S.OptionsButtonContainer>
                    <InputFileButton
                        dataCy={`${option.tip_text}-button`}
                        isTiny
                        leftIcon={option.fa5_icon}
                        leftIconFamily={option.fa5_icon_family}
                        onChange={option.callback}
                        rightMargin="7px"
                        style={{
                            width: "30px",
                        }}
                        text={option.label}
                        type="outlined"
                    />
                </S.OptionsButtonContainer>
            )

        } else {
            OptionComponent = (
                <S.OptionsButtonContainer>
                    <Button
                        forceActive={option.tip_text === props.adderType}
                        href={option.href}
                        isForm
                        isTiny
                        leftIcon={option.fa5_icon}
                        leftIconFamily={option.fa5_icon_family}
                        onClick={option.callback}
                        rightMargin="7px"
                        type="outlined"
                        style={{
                            width: "30px",
                        }}
                        dataCy={`${option.tip_text}-button`}
                    />
                    {
                        (
                            props.AdderComponent &&
                            option.tip_text === props.adderType
                        ) &&
                            <S.AdderComponent>
                                { props.AdderComponent }
                            </S.AdderComponent>
                    }
                </S.OptionsButtonContainer>
            )
        }

        return option.tip_text
            ? (
                <S.TooltipContainer>
                    {OptionComponent}
                    <S.TooltipPositioner>
                        <Tooltip
                            isTiny
                            placement="right"
                            text={option.tip_text}
                        />
                    </S.TooltipPositioner>
                </S.TooltipContainer>
            )
            : OptionComponent
    }

    const RightSectionComponent = props.rightSection?.component

    return (
        <S.Card
            // TODO: delete this after we remove the old
            // {LongInlinePresentation/SquareInlinePresentation/Search}.styl
            className={props.cardClassName}
            data-auto-cy="CompoundCard"
            data-cy={props.cardDataCy}
        >
            <S.CardInner
                // TODO: delete this after we remove the old
                // {LongInlinePresentation/SquareInlinePresentation/Search}.styl
                className={props.cardInnerClassName}
                isSelected={props.isSelected}
                onClick={props.cardInnerOnClick}
            >
                <S.CardInnerAnchor
                    href={props.cardInnerAnchorHref}
                    isHighlightable={props.isHighlightable}
                >
                    <S.InlineText className="inline-text">
                        {
                            props.firstLineText &&
                                <S.FirstLine
                                    id={props.firstLineId}
                                    expanded={props.seeMoreText == "Show Less"}
                                >
                                    {
                                        props.firstLineRegion &&
                                            <S.FirstLineRegion>
                                                {props.firstLineRegion}
                                            </S.FirstLineRegion>
                                    }
                                    {props.firstLineText}
                                </S.FirstLine>
                        }
                        {props.inlineTextChildren}
                        {
                            props.secondLineDS && (
                                <React.Fragment>
                                    {
                                        props.secondLineDS.filter((secondLineItem) => !(secondLineItem.id || secondLineItem.profile) && renderSecondLineItem(secondLineItem))
                                            ? (
                                                <S.SecondLine>
                                                    {
                                                        props.secondLineDS
                                                            .map((secondLineItem) => !(secondLineItem.id || secondLineItem.profile) && renderSecondLineItem(secondLineItem))
                                                    }
                                                </S.SecondLine>
                                            )
                                            : null
                                    }
                                    {
                                        props.secondLineDS.filter(secondLineItem => secondLineItem.id || secondLineItem.profile).length
                                            ? (
                                                <S.SecondLine>
                                                    {
                                                        props.secondLineDS
                                                            .map((secondLineItem) => {
                                                                const SegueLink = props.SegueLinkComponent

                                                                return (
                                                                    (secondLineItem.id || secondLineItem.profile) && (
                                                                        <S.SecondLineSegueLinkContainer>
                                                                            <SegueLink
                                                                                onClick={(event) => { event.stopPropagation() }}
                                                                                to={`${secondLineItem.profile}${secondLineItem.id}`}
                                                                                href={`${secondLineItem.profile}${secondLineItem.bd}`}
                                                                            >
                                                                                {renderSecondLineItem(secondLineItem)}
                                                                            </SegueLink>
                                                                        </S.SecondLineSegueLinkContainer>
                                                                    )
                                                                )
                                                            })
                                                    }
                                                    {/* truncated bill for note card should follow NOTE_BILL_TRUNCATION_LIMIT in shared/search/constants */}
                                                    {props.secondLineDS.filter(secondLineItem => secondLineItem.id || secondLineItem.profile).length == 2 && props.secondLineShowEllipsis && "..."}
                                                </S.SecondLine>
                                            )
                                            : null
                                    }
                                    {
                                        props.customFields
                                            ? (
                                                <S.SecondLine>
                                                    {
                                                        props.customFields && props.customFields.map(customField =>
                                                            renderSecondLineItem({
                                                                fa5Icon: "layer-group",
                                                                fa5IconFamily: "far",
                                                                value: `${customField.title}: ${customField.value}`,
                                                                url: customField.url
                                                            })
                                                        )
                                                    }
                                                    {/* truncated custom field for note card should follow NOTE_CUSTOM_FIELDS_TRUNCATION_LIMIT in shared/search/constants */}
                                                    {props.customFields.length == 4 && props.secondLineShowEllipsis && "..."}
                                                </S.SecondLine>
                                            )
                                            : null
                                    }
                                </React.Fragment>
                            )
                        }
                        {props.feedItem}
                        {
                            props.shouldRenderThirdLine &&
                                <S.ThirdLineContainer
                                    className={props.thirdLineClassName}
                                >
                                    {
                                        props.shouldRenderThirdLineText &&
                                            (
                                                !props.dangerouslyInsertThirdLine
                                                    ? (
                                                        <S.ThirdLineText
                                                            id={props.thirdLineTextId}
                                                            className={props.thirdLineTextClassName}
                                                            dir={props.thirdLineTextDir}
                                                        >
                                                            {props.thirdLineTextSnippets}
                                                            {props.thirdLineText}
                                                            {props.thirdLinePdf}
                                                            {props.thirdLineIframe}
                                                        </S.ThirdLineText>
                                                    )
                                                    : (
                                                        <S.ThirdLineQuorumIframe
                                                            className={props.thirdLineQuorumIframeClassName}
                                                            id={props.thirdLineTextId}
                                                            onClick={props.thirdLineQuorumIframeOnClick}
                                                        >
                                                            {props.thirdLineQuorumIframe}
                                                        </S.ThirdLineQuorumIframe>
                                                    )
                                            )
                                    }
                                    {props.thirdLineAttachments}
                                    {props.thirdLineAttachmentsPdfSelectViewer}
                                </S.ThirdLineContainer>
                        }
                        {
                            props.fourthLineText &&
                                <S.FourthLine className="description">
                                    {props.fourthLineText}
                                </S.FourthLine>
                        }
                        {props.DesignSystemDataTableView}
                    </S.InlineText>

                    {RightSectionComponent ? <RightSectionComponent {...props.rightSection.props} /> : getIcon()}
                </S.CardInnerAnchor>
                {props.children}
                {
                    <S.TimelineOptionsContianer>
                        <S.OptionsAdderContainer
                            // we don't want clicking on an adder component to redirect us to a profile
                            onClick={event => event.stopPropagation()}
                        >
                            {
                                (
                                    (
                                        props.options &&
                                        !props.isHighlightable
                                    )
                                    || props.hasSeeMore
                                ) &&
                                    <S.Options>
                                    {
                                        props.hasSeeMore &&
                                            <Button
                                                color={colors.LinkPurple}
                                                href={props.seeMoreHref}
                                                isTiny
                                                rightIcon={props.seeMoreIcon}
                                                rightIconFamily={props.seeMoreIconFamily}
                                                rightIconSize="20px"
                                                onClick={props.seeMoreOnClick}
                                                rightMargin="7px"
                                                text={props.seeMoreText}
                                                type="anchor"
                                            />
                                    }
                                    {
                                        props.options &&
                                            props.options
                                                .filter(option => option.display !== false)
                                                .map(option => renderOption(option))
                                    }
                                    </S.Options>
                            }
                        </S.OptionsAdderContainer>
                        {
                            // !props.seeMoreEnabled &&
                            renderTimeline()
                        }
                    </S.TimelineOptionsContianer>
                }
            </S.CardInner>
        </S.Card>
    )
}

Card.propTypes = {
    // may be IssueAdder or ListAdder
    // used to add/remove issues and lists to the object this card represents in search
    AdderComponent: PropTypes.element,
    children: PropTypes.arrayOf(PropTypes.element),

    // WIP: these props will change once we integrate all of the old
    // app/static/stylus/SquareInlinePresentation.styl
    // styles and delete the {x}ClassName props

    // TODO: alphbetize these after the props being passed here are stable
    cardClassName: PropTypes.string,
    cardDataCy: PropTypes.string,
    cardInnerClassName: PropTypes.string,
    cardInnerOnClick: PropTypes.func,
    cardInnerAnchorHref: PropTypes.string,
    imageUrlPath: PropTypes.string,
    imagePath: PropTypes.string,
    imagePathClassName: PropTypes.string,
    imagePathOnError: PropTypes.func,
    stateIcon: PropTypes.string,
    abbrev: PropTypes.string,
    iconName: PropTypes.string,
    iconNameContainerClassName: PropTypes.string,
    iconNameClassName: PropTypes.string,
    fa5IconName: PropTypes.string,
    fa5IconFamily: PropTypes.string,
    inlineTextChildren: PropTypes.arrayOf(PropTypes.element),
    firstLineRegion: PropTypes.string,
    firstLineId: PropTypes.string,
    firstLineText: PropTypes.string,
    feedItem: PropTypes.element,
    shouldRenderThirdLine: PropTypes.bool,
    thirdLineClassName: PropTypes.string,
    shouldRenderThirdLineText: PropTypes.bool,
    dangerouslyInsertThirdLine: PropTypes.bool,
    thirdLineTextId: PropTypes.string,
    thirdLineTextClassName: PropTypes.string,
    thirdLineTextDir: PropTypes.string,
    thirdLineTextSnippets: PropTypes.element,
    thirdLineText: PropTypes.string,
    thirdLinePdf: PropTypes.element,
    thirdLineIframe: PropTypes.element,
    thirdLineQuorumIframeClassName: PropTypes.string,
    thirdLineQuorumIframeOnClick: PropTypes.func,
    thirdLineQuorumIframe: PropTypes.element,
    thirdLineAttachments: PropTypes.element,
    thirdLineAttachmentsPdfSelectViewer: PropTypes.element,
    fourthLineText: PropTypes.string,
    DesignSystemDataTableView: PropTypes.element,
    secondLineDS: PropTypes.array,
    customFields: PropTypes.arrayOf(PropTypes.object),
    SegueLinkComponent: PropTypes.elementType,
    hasSeeMore: PropTypes.bool,
    seeMoreEnabled: PropTypes.bool,
    seeMoreIcon: PropTypes.string,
    seeMoreIconFamily: PropTypes.string,
    seeMoreText: PropTypes.string,
    seeMoreHref: PropTypes.string,
    seeMoreOnClick: PropTypes.func,

    // S.BillStatusTimeline
    timelineStatuses: PropTypes.array,
    timelineEnum: PropTypes.object,

    // S.Options
    isHighlightable: PropTypes.bool,
    isSelected: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object),
}

export default Card

/*
SITE MAP:
    /homepage/
    /eu/

    /products/
        /products/federal/
        /products/states/
        /products/local/
        /products/grassroots/
        /products/stakeholder/
        /products/eu/

    /about/
        /about/story/
        /about/team/
        /about/press/

    /careers/
        /careers/jobs/
        /careers/internships/

    /resources/
        /medium/

        /data-driven-insights/
            /data-driven-insights/(:id)/(:slug)

        /productivity/
            /productivity/(:id)/(:slug)

        /whitepapers/
            /request/

        /casestudies/
*/

const urls = {
    index: "/",
    home: "/home/",
    homepage: "/homepage/",
    euHomepage: "/eu/",

    company: "/company/",

    products: "/products/",
    federalProduct: "/products/federal/",
    stateProduct: "/products/state/",
    localProduct: "/products/local/",
    euProduct: "/products/eu/",
    grassrootsProduct: "/products/grassroots/",
    stakeholderProduct: "/products/stakeholder/",
    internationalProduct: "/products/international/",

    stakeholderEngagement: "/stakeholder-engagement/",
    legislativeTracking: "/legislative-tracking/",
    grassrootsAdvocacy: "/grassroots-advocacy/",

    legislativeTrackingSoftware: "/legislative-tracking-software/",
    mobileRegistrationLanding: "/mobile-registration-landing/:activation_key?/",
    regulationsProduct: "/regulations/",

    mobileProduct: "/mobile-app/",
    mobileEuProduct: "/mobile-app-eu/",
    desktopProduct: "/desktop-app/",

    dataDrivenInsightsList: "/data-driven-insights/",
    productivityList: "/congressional-activity-tracker/",
    whitePapersList: "/whitepapers/",

    resources: "/resources/",
    resourcesWithParams: "/resources/:postType?/",

    dataDrivenInsightsDetail: "/data-driven-insights/:shortCode?/:id?/",
    productivityDetail: "/congressional-activity-tracker/:shortCode?/:id?/",
    behindTheDeskDetail: "/behind-the-desk/:shortCode?/:id?/",
    bestPracticesDetail: "/best-practices/:shortCode?/:id?/",
    publicAffairsDictionaryDetail: "/public-affairs-dictionary/:shortCode?/:id?/",
    caseStudiesDetail: "/case-studies/:shortCode?/:id?/",
    blogDetailDownload: "/resources/:shortCode?/:id?/",

    downloadsPage: "/downloads/",

    aboutUs: "/about/",
    careers: "/about/careers/",
    press: "/about/press/",
    security: "/security/",

    unsubscribe: "/unsubscribe/:bulkEmailId?/*",
    customEvent: "/event_rsvp/*",
}

export default urls

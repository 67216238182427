import React from "react"
import PropTypes from "prop-types"

import TrendsWidgetItem from "Components/Compounds/TrendsWidgetItem"
import * as S from "Components/Compounds/TrendsWidgetContent/style"


/**
 * Takes in an array of trends and their mention count and renders them
 */
const TrendsWidgetContent = ({ dataCy, data, handleSegue }) => (
    <S.TrendsWidgetContent data-auto-cy="CompoundTrendsWidgetContent">
        <span data-cy={dataCy}>
            {
                data && data.length ?
                    data.map(i =>
                        <TrendsWidgetItem
                            dataCy='trendsListItem'
                            key={i[0]}
                            term={i[0]}
                            count={i[1]}
                            handleClick={() => handleSegue(i[0])}
                        />
                    ) : <div />
            }
        </span>
    </S.TrendsWidgetContent>
)

TrendsWidgetContent.defaultProps = {}

TrendsWidgetContent.propTypes = {
    dataCy: PropTypes.string,
    data: PropTypes.array,
    handleSegue: PropTypes.func
}

export default TrendsWidgetContent

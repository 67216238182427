import styled from "styled-components"

import * as colors from "Components/colors"

export const Anchor = styled.a`
    color: ${colors.LinkPurple};

    &:hover, &:focus {
        color: ${colors.LinkPurple};
    }
`

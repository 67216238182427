import React from "react"
import hideIfTrue from "imports/hideIfTrue"
import QuorumTypeAheadSearch from "./QuorumTypeAheadSearch"
import QuorumTypeAheadList from "./QuorumTypeAheadList"
import PropTypes from "prop-types"
import "../../stylus/reusable_components/quorum_typeahead.styl"
/*
 * Improve at a later time - takes in callback function
 */

const propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    filterText: PropTypes.string,
    placeholder: PropTypes.string,
    settings: PropTypes.arrayOf(PropTypes.shape({
        // eslint-disable-next-line react/no-unused-prop-types
        groupName: PropTypes.string,
        // eslint-disable-next-line react/no-unused-prop-types
        queryFunction: PropTypes.func,
    })).isRequired,
    onUserSelection: PropTypes.func.isRequired,
    debounceRate: PropTypes.number,
}

const defaultProps = {
    placeholder: "Search...",
    debounceRate: 0,
}

export default class QuorumTypeAhead extends React.Component {

    constructor(props) {
        super(props)
        this.onSelectItem = this.onSelectItem.bind(this)
        this.clearState = this.clearState.bind(this)
        this.inputRefFunc = this.inputRefFunc.bind(this)
        this.makeQueries = this.makeQueries.bind(this)
        this.handleUserInput = this.handleUserInput.bind(this)
        const stateDict = {
            filterText: "",
        }
        this.props.settings.map(group => (stateDict[group.groupName] = []))
        this.state = stateDict
    }

    /*
     * Method to kick new object back up
     */
    onSelectItem(newOption) {
        this.props.onUserSelection(newOption, this.clearState)
    }

    // Method to reset the table
    clearState() {
        this.state.inputElt.value = ""
        this.state.inputElt.focus()
        return this.setState({
            filterText: "",
        })
    }

    inputRefFunc(input) {
        return this.setState({ inputElt: input })
    }

    makeQueries() {
        // make request
        const callbackFunc = (groupName, data) => {
            const dict = {}
            dict[groupName] = data
            return this.setState(dict)
        }

        return this.props.settings.map(group =>
            group.queryFunction(
                this.state.filterText,
                group.groupName,
                callbackFunc
            )
        )
    }

    /*
     * Method to search for object
     */
    handleUserInput() {
        const filterText = this.state.inputElt.value
        if (filterText !== "") {
            // update state
            this.setState({ filterText }, this.makeQueries)
        } else {
            this.clearState()
        }
    }

    render() {
        // bool to indicate if there are results
        let hasResults = false
        const lists = this.props.settings.map((dataGroup) => {
            if (!hasResults && this.state[dataGroup.groupName].length > 0) {
                // if we have results (the length is > 0 mark this as true
                hasResults = true
            }
            return (
                <QuorumTypeAheadList
                    key={dataGroup.groupName + this.state.filterText}
                    options={this.state[dataGroup.groupName]}
                    groupLabel={dataGroup.groupName}
                    showGroupLabel={this.props.settings.length > 1}
                    onSelectItem={this.onSelectItem}
                />
            )
        })

        const displayType = (this.state.filterText === "") ? "none" : "block"

        return (
            <div className="quorum-typeahead-div" style={hideIfTrue(this.props.hideTypeAhead)}>
                <QuorumTypeAheadSearch
                    filterText={this.state.filterText}
                    onUserInput={this.handleUserInput}
                    placeholder={this.props.placeholder}
                    debounceRate={this.props.debounceRate}
                    inputRefFunc={this.inputRefFunc}
                    initialValue={this.state.filterText}
                />
                <div
                    className="quorum-typeahead-results"
                    style={{ display: displayType }}
                >
                    { hasResults ? lists : (
                        <ul className="list-group">
                            <li className="list-group-item group-label" >
                                <i>No Results</i>
                            </li>
                        </ul>
                    )}
                </div>
            </div>
        )
    }
}

QuorumTypeAhead.propTypes = propTypes
QuorumTypeAhead.defaultProps = defaultProps

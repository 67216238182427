import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const OnboardingScreen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 48px;

    & > * + * {
        margin-top: 2rem;
    }
`

export const Circle = styled.div`
    opacity: 0.04;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    fill: ${colors.MidPurple};
    padding: 40px 0px;
`

export const Title = styled.h1`
    ${rules.GiantTitle}
    color: ${colors.MidPurple};
    text-align: center;
    width: calc(100% - 12rem);

`

export const Text = styled.p`
    color: ${colors.unnamed222222};
    ${rules.LargeTextLightWeight}
    text-align: center;
    width: calc(100% - 12rem);
    margin-bottom: 1.5rem;
`

export const PasswordPrompt = styled.div`
    width: 100%;
    max-width: 72rem;

    display: flex;
    flex-wrap: wrap;

    & > * {
        flex-grow: 1;
        flex-basis: calc((48rem - (100% - 2rem)) * 999);
        margin: calc(2rem / 2);
    }

    & > :nth-last-child(n+3),
    & > :nth-last-child(n+3) ~ * {
        flex-basis: 100%;
    }
    margin-bottom: 1.5rem;
`

export const PersonaHeader = styled.div`
    color: ${colors.MidPurple};
    ${rules.GiantTextMediumWeight}
    text-align: center;
`

export const PersonaSelect = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    width: calc(100% - 2rem);
    margin-left: calc(2rem / 2 * -1);
    margin-right: calc(2rem / 2 * -1);

    & > * {
        margin: calc(2rem / 2);
    }
`

export const Terms = styled.div`
    ${rules.SmallText}
    color: ${colors.gray7};

    label {
        font-weight: normal;
        margin-bottom: 0;
        &:hover {
            cursor: pointer;
        }

        input {
            margin-right: 5px;
        }

        a {
            color: ${colors.violet7};
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
`

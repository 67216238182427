import styled from "styled-components"
import { ReduxFormMarginSize } from "app/static/frontend/design-constants"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"

export const PacUserInfoSectionContainer = styled.div`
    padding-top: ${ReduxFormMarginSize};
`

export const PacUserInfoSectionTitle = styleWithOrgDesign(styled.div`
    color: ${(props) => props.organizationDesign.primary_color};
    font-size: 32px;
    font-weight: bold;
`)

export const InputFieldsRow = styled.div`
    display: flex;
    flex-direction: row;
`

export const InputFieldContainer = styled.div`
    width: 60%;
`

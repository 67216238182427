import styled from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const SelectSearchBar = styled.div`
    padding: 4px 0;

    .Select {
        // main input bar styling
        .Select__control {
            border-radius: ${rules.BorderRadius};
            border: ${rules.Line} ${colors.FormControlBorderColor};

            &.Select__control--is-focused {
                border: ${rules.ThickerLine} ${colors.QuorumBlue};
            }

            .Select__dropdown-indicator {
                color: ${colors.QuorumBlue};
            }

            .Select__value-container {
                align-items: center;
            }

            &.Select__control--menu-is-open {
                border: ${rules.ThickerLine} ${colors.QuorumBlue};

                // chevron direction
                .Select__dropdown-indicator {
                    transform: rotate(180deg);
                }
            }

            &:hover {
                border: ${rules.Line} ${colors.QuorumBlue};
            }

            &:active {
                border: ${rules.ThickerLine} ${colors.QuorumBlue};
            }

            &:focus-within {
                border: ${rules.ThickerLine} ${colors.QuorumBlue};
            }
        }

        .Select__placeholder {
            ${rules.style.italic}
            ${rules.weight.light}
            color: ${colors.QuorumGrey3};
        }

        // dropdown of menu items
        .Select__menu {
            border-radius: ${rules.BorderRadius};
            border: ${rules.Line} ${colors.FormControlBorderColor};
            box-shadow: ${rules.SearchBarShadow};
            margin-top: 7px;
        }

        .Select__menu-list {
            border-radius: ${rules.BorderRadius};

            // individual menu items
            .Select__option {
                background-color: ${colors.White};
                color: ${colors.gray7};
                height: ${rules.TinyButtonHeight};
                overflow: hidden;
                padding: 6px 12px 0px;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:hover {
                    background-color: ${colors.AquaHaze};
                    color: ${colors.violet9};
                }

                // applies for single select enums
                &.Select__option--is-selected {
                    background-color: ${colors.violet1};
                    color: ${colors.violet9};

                    &:hover {
                        background-color: ${colors.violet9};
                        color: ${colors.White};
                    }
                }
            }
        }

        // clear "x" and chevron icon container
        .Select__indicator {
            color: ${colors.QuorumBlue};
            cursor: pointer;
            padding: 8px 5px 8px 2px;
            margin-right: 2px;

            &:hover {
                color: ${colors.QuorumGrey3};
            }
        }

        .Select__indicator-separator {
            display: none;
        }

        // clear "x" icon
        // TODO delete !important after we drop quorum-react-select.styl
        .Select__clear-indicator {
            padding-right: 0;
            min-width: 18px !important;
        }

        // selected "pill" items
        .Select__multi-value {
            background-color: ${colors.gray0};
            border-radius: ${rules.BorderRadius};
            border: none;
            color: ${colors.gray7};
            font-size: ${rules.ButtonFontSize};
            justify-content: space-between;
            line-height: ${rules.TinyButtonFontLineHeight};
            margin-left: 0;

            .Select__multi-value__label {
                color: ${colors.gray7};
                font-size: ${rules.IconFontSize};
                line-height: ${rules.TinyButtonFontLineHeight};
                white-space: initial;
            }

            // clear "x" icon container for "pill" item
            .Select__multi-value__remove {
                cursor: pointer;
                border: none;

                &:hover {
                    color: ${colors.gray9};
                    background-color: ${colors.gray2};
                    border-radius: ${rules.BorderRadius};
                }
            }
        }
    }
`

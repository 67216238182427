import styled, { css } from 'styled-components'

import {
    ButtonContainer as InternalButtonContainer,
    Icon,
    Text,
} from "Components/Molecules/Button/style"

export const ButtonContainer = styled.div`
    ${({
        iconResponsiveBreakpoint,
        rightIcon,
        text,
        textResponsiveBreakpoint,
    }) => (text && textResponsiveBreakpoint && iconResponsiveBreakpoint) && css`
        @media (max-width: ${textResponsiveBreakpoint}) {
            // hide Button text
            ${Text} {
                display: none;
            }

            ${!rightIcon && css`
                ${InternalButtonContainer} > :first-child {
                    justify-content: center;
                    padding: 0;
                    width: 30px;
                }
            `}

            ${rightIcon && css`
                ${Icon}:not(:last-of-type) {
                    margin-right: 4px;
                }
            `}
        }

        @media (max-width: ${iconResponsiveBreakpoint}) and (min-width: ${textResponsiveBreakpoint}) {
            // hide Button icon
            ${Icon}:first-of-type {
                display: none;
            }

            ${Text} {
                margin-left: 0;
            }
        }
    `}
`

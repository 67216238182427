import React from "react"
import PropTypes from "prop-types"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import { StyledContactUsWidget } from "QuorumGrassroots/widgets/ContactUs/components/style"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import LoginUpdateOrRender from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/LoginUpdateOrRender"
import { Field } from "redux-form/immutable"
import { requiredFieldValidation } from "QuorumGrassroots/helperFunctions"
import InputField from "frontend/forms/components/InputField"

export const ContactUsWidget = (props) => (
    <StyledContactUsWidget className="contact-us-widget">
        <Field
            component={InputField}
            name={"text"}
            label={props.label || "Use this form to contact us"}
            validate={requiredFieldValidation}
            isTextarea
            displayErrorWithoutTouch={false}
        />
        <StyledButton onClick={props.handleSubmit} disabled={props.submitting}>
            {props.submitting ? "Sending Your Message" : "Send Your Message"}
        </StyledButton>
    </StyledContactUsWidget>
)

export const ContactUsLoginWrapper = (props) => (
    <WidgetStyleWrapper md={props.colWidth} useWidgetStyling={props.useWidgetStyling} title={props.title}>
        <LoginUpdateOrRender
            {...props}
            registrationPageIds={props.registrationPageIds}
            useWidgetStyling={false}
            renderedComponent={ContactUsWidget}
            renderedProps={props}
            showOnlyUnfilledFields
        />
    </WidgetStyleWrapper>
)

ContactUsLoginWrapper.propTypes = {
    title: PropTypes.string,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    registrationPageIds: PropTypes.arrayOf(PropTypes.number).isRequired,
}

ContactUsLoginWrapper.defaultProps = {
    useWidgetStyling: true,
}

export default ContactUsLoginWrapper

import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"


export const TooltipPositioner = styled.div`
    // A rule applied to all but the first child adds a bottom margin
    // The tooltip should be exempt since its not within the body of the element
    &&& {
        margin-bottom: 0px;
    }

    & > :first-child {
        left: 100%;
        top: 0;
        transform: translate(-100%, -100%);
        visibility: hidden;
        opacity: 0%;
        transition-duration: ${rules.TransitionShort};
    }
`

export const Counter = styled.div`
    align-items: center;
    display: flex;
    flex-direction: ${({flexDirection}) => flexDirection};
    position: relative;
    ${({pointer}) => pointer ? "cursor: pointer;" : null};

    &:hover {
        ${TooltipPositioner} {
            & > :first-child {
                visibility: visible;
                opacity: 100%;
                transition-duration: ${rules.TransitionShort};
            }
        }
    }
`

export const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${colors.gray7};
`

export const LabelSpan = styled.div`
    ${rules.SmallTextMediumWeight}
    color: inherit;
    margin-left: 11px;
`

export const SubtitleSpan = styled.div`
    ${rules.SmallTextLightWeight}
    color: inherit;
    margin-left: 11px;
`

import React from "react"
import PropTypes from "prop-types"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import { VoterRegistrationIframe } from "QuorumGrassroots/campaign-forms/components/VoterRegistrationForm/style"

const VoterRegistrationForm = (props) => {
    const submit = () =>
        props.postGrassrootsSupporterAction({
            uniqueWidgetId: props.uniqueWidgetId,
            campaign: props.campaign,
            shouldCompleteCampaign: true,
        })

    return (
        <div className="gotv-campaign-main">
            <VoterRegistrationIframe
                className="gotv-iframe"
                src="https://register.rockthevote.com?partner=1&source=ovrpage"
                scrolling="yes"
                frameborder="0"
            />
            <StyledButton onClick={submit} disabled={props.isSubmitting} data-cy="sign" isCampaignPage activateNGGTheme>
                {props.styledButtonText}
            </StyledButton>
        </div>
    )
}

VoterRegistrationForm.propTypes = {
    campaign: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    postGrassrootsSupporterAction: PropTypes.func.isRequired,
    styledButtonText: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    uniqueWidgetId: PropTypes.string.isRequired,
}

export default VoterRegistrationForm

import i18n from "i18n"
import moment from "moment"
import { createSelector, createStructuredSelector } from "reselect"
import * as issueListSelectors from "QuorumGrassroots/widgets/IssueList/selectors"
import { selectWidgetContent } from "QuorumGrassroots/widgets/selectors"
import {
    selectOrganizationId,
    selectUserId,
    selectUserUri,
    selectCustomFields,
    selectActionCenterSettings,
} from "QuorumGrassroots/framework/selectors"
import { createCustomFieldNameDict } from "shared/customFields/constants/helperFunctions"

export const selectIsCampaign = createSelector(selectWidgetContent, (campaign) => Boolean(campaign.id))

export const selectCustomInteractionTypes = createSelector(
    selectIsCampaign,
    selectActionCenterSettings,
    selectWidgetContent,
    (isCampaign, actionCenterSettings, campaign) =>
        isCampaign ? campaign.interaction_types : actionCenterSettings.custom_interaction_types,
)

export const selectExternalTypes = (campaignType) =>
    createSelector(
        selectIsCampaign,
        selectWidgetContent,
        selectActionCenterSettings,
        selectCustomInteractionTypes,
        (isCampaign, campaign, actionCenterSettings, customInteractionTypes) => {
            const campaignEnum = DjangIO.app.grassroots.campaign.types.CampaignType.by_value(campaignType)

            let externalTypes = isCampaign
                ? campaign[campaignEnum.campaign_field_key]
                : actionCenterSettings[campaignEnum.action_center_settings_field_key]

            if (typeof externalTypes === "string") {
                externalTypes = JSON.parse(externalTypes)
            }

            const { CampaignType } = DjangIO.app.grassroots.campaign.types

            let enumType
            switch (campaignType) {
                case CampaignType.log_interaction.value:
                    enumType = DjangIO.app.userdata.types.InteractionType
                    externalTypes = customInteractionTypes
                    break
                case CampaignType.log_relationship.value:
                    enumType = DjangIO.app.userdata.types.RelationshipType
                    break
                default:
                    break
            }

            return enumType.items().filter((type) => externalTypes.includes(type.value))
        },
    )

export const selectInitialValues = (campaignType) =>
    createSelector(
        selectExternalTypes(campaignType),
        (_, props) => props.location,
        (externalTypes, location) => {
            const campaignEnum = DjangIO.app.grassroots.campaign.types.CampaignType.by_value(campaignType)

            const initialValues = {
                [campaignEnum.note_field_key]: externalTypes && externalTypes.length && externalTypes[0].value,
                projects: [],
            }

            const { CampaignType } = DjangIO.app.grassroots.campaign.types

            switch (campaignType) {
                case CampaignType.log_interaction.value:
                    initialValues.duration = DjangIO.app.userdata.types.PossibleDurations.one_hour.value
                    initialValues.date = moment().format("MM/D/YYYY")
                    initialValues.time = moment().format("h:mm a")
                    break
                default:
                    break
            }

            if (location && location.search) {
                const officialRegExp = new RegExp(/(\?|&)official=([^&]+)/)
                const officialMatches = location.search.match(officialRegExp)

                const nameRegExp = new RegExp(/(\?|&)name=([^&]+)/)
                const nameMatches = location.search.match(nameRegExp)

                if (officialMatches && nameMatches) {
                    const officialId = officialMatches[2]
                    const name = decodeURIComponent(nameMatches[2])

                    initialValues.officials = [
                        {
                            resource_uri: DjangIO.app.person.models.Person.resourceUriFromId(officialId),
                            name,
                        },
                    ]
                }
            }

            return initialValues
        },
    )

export const selectInitialOfficialsValue = (campaignType) =>
    createSelector(
        selectInitialValues(campaignType),
        (initialValues) => (initialValues && initialValues.officials) || [],
    )

export const selectIssueList = createSelector(issueListSelectors.selectIssueList, (issueList) =>
    issueList && issueList.map
        ? issueList.map((issue) => ({
              value: DjangIO.app.userdata.models.Project.resourceUriFromId(issue.id),
              label: issue.name,
          }))
        : [],
)

export const selectCustomFieldNameDict = (campaignType) =>
    createSelector(
        selectIsCampaign,
        selectWidgetContent,
        selectCustomFields,
        selectActionCenterSettings,
        (isCampaign, campaign, customFields, actionCenterSettings) => {
            let relevantCustomFieldsUriList = []

            const { CampaignType } = DjangIO.app.grassroots.campaign.types

            switch (true) {
                case isCampaign:
                    relevantCustomFieldsUriList = campaign.custom_fields
                    break
                case campaignType === CampaignType.log_interaction.value:
                    relevantCustomFieldsUriList = actionCenterSettings.interaction_custom_fields
                    break
                case campaignType === CampaignType.log_relationship.value:
                    relevantCustomFieldsUriList = actionCenterSettings.relationship_custom_fields
                    break
                default:
                    break
            }

            const customFieldIds = relevantCustomFieldsUriList.map((resourceUri) =>
                DjangIO.app.grassroots.models.CustomTag.idFromResourceUri(resourceUri),
            )

            const filteredCustomFields = customFields
                .toJS()
                .filter((customField) => customFieldIds.includes(customField.id))

            return createCustomFieldNameDict(filteredCustomFields)
        },
    )

export const selectOfficialsLabel = (campaignType) => () => {
    const { CampaignType } = DjangIO.app.grassroots.campaign.types

    switch (campaignType) {
        case CampaignType.log_interaction.value:
            return i18n.t("campaign.log_interaction.officials.label")
        case CampaignType.log_relationship.value:
            return i18n.t("campaign.log_relationship.officials.label")
        default:
            return ""
    }
}

export const selectTextPlaceholder = (campaignType) => () => {
    const { CampaignType } = DjangIO.app.grassroots.campaign.types

    switch (campaignType) {
        case CampaignType.log_interaction.value:
            return i18n.t("campaign.log_interaction.text.placeholder")
        case CampaignType.log_relationship.value:
            return i18n.t("campaign.log_relationship.text.placeholder")
        default:
            return ""
    }
}

export const selectShouldRequireOfficial = (campaignType) =>
    createSelector(selectActionCenterSettings, (actionCenterSettings) => {
        const { CampaignType } = DjangIO.app.grassroots.campaign.types

        switch (campaignType) {
            case CampaignType.log_interaction.value:
                return actionCenterSettings.should_require_interaction_official
            default:
                return true
        }
    })

export const externalLogFormConnection = (campaignType) =>
    createStructuredSelector({
        externalTypes: selectExternalTypes(campaignType),
        initialValues: selectInitialValues(campaignType),
        isCampaign: selectIsCampaign,
        issueList: selectIssueList,
        campaignType: () => campaignType,
        supporterId: selectUserId,
        userUri: selectUserUri,
        organizationId: selectOrganizationId,
        customFieldNameDict: selectCustomFieldNameDict(campaignType),
        officialsLabel: selectOfficialsLabel(campaignType),
        textPlaceholder: selectTextPlaceholder(campaignType),
        shouldRequireOfficial: selectShouldRequireOfficial(campaignType),
        initialOfficialsValue: selectInitialOfficialsValue(campaignType),
    })

import styled from "styled-components"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { constants } from "QuorumGrassroots/constants"
import {
    overrideInlineWrapperStyle,
    overrideInlineCallToActionStyle,
} from "QuorumGrassroots/widgets/styled-components/helperFunctions"

export const StyledInlineTable = styleWithOrgDesign(styled.table`
    width: 100%;
    ${(props) => overrideInlineWrapperStyle(props)}
`)

export const StyledInlineTableRow = styled.tr`
    width: 100%;
`

export const StyledInlineIconTableData = styled.td`
    width: 5%;
    text-align: center;
    padding-left: 15px;

    @media (max-width: ${constants.mobileWidth}px) {
        width: 0%;
        padding-left: 0px;
    }
`

export const StyledInlineIcon = styleWithOrgDesign(styled.i`
    color: ${(props) => props.organizationDesign.primary_color};
    font-size: 40px;
    border: 3px solid ${(props) => props.organizationDesign.primary_color};
    border-radius: 100%;
    padding: 20px;
    width: 85px;

    @media (max-width: ${constants.mobileWidth}px) {
        display: none;
    }
`)

export const StyledInlineTitle = styleWithOrgDesign(styled.h3`
    font-size: 24px;
    padding-top: 0px;
    margin-top: 0px;
    border-bottom: none;
    font-weight: 300;
    color: ${(props) => props.organizationDesign.secondary_color};
    line-height: 1.3;
    ${(props) => !props.description && `margin-bottom: 0px;`}
`)

// I specify both inline-block and unset because IE does not support unset,
// so display should be inline-block as backup
export const StyledInlineTitleText = styled.span`
    display: inline-block;
    display: unset;
    margin-right: 15px;
`

export const StyledInlinePoints = styleWithOrgDesign(styled.span`
    background-color: ${(props) => props.organizationDesign.secondary_color};
    font-size: 15px;
    color: white;
    padding: 3px 5px;
    padding-bottom: 4px;
    border-radius: 4px;
    white-space: nowrap;
    position: relative;
    bottom: 3px;
`)

export const StyledInlineDescription = styleWithOrgDesign(styled.span`
    font-size: 18px;
    color: ${(props) => props.organizationDesign.primary_color};
`)

export const StyledInlineCallToAction = styleWithOrgDesign(styled.span`
    // Needed to add these to fix bug when call to action text is multiple words
    display: inline-block;
    white-space: nowrap;

    // For Call to action text that is too small
    min-width: 125px;

    font-size: 18px;
    background-color: ${(props) => props.organizationDesign.primary_color};
    color: white;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 4px;

    @media (max-width: ${constants.mobileWidth}px) {
        display: none;
    }

    ${(props) => overrideInlineCallToActionStyle(props)}
`)

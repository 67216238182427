/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import i18n from "i18n"

import { StyledAlert } from "QuorumGrassroots/styled-components/components/StyledAlert"
import { Styled404BackButton, UnstyledAnchor } from "QuorumGrassroots/framework/components/404/style"
import { StyledSubmitButton } from "QuorumGrassroots/styled-components/components/StyledSubmitButton.js"

export const CampaignWarning = ({ t, ...props }) => {
    const navigate = useNavigate()
    const navigationLink = props.link || DjangIO.app.grassroots.types.GrassrootsWidgetType.campaign_list.widget_url

    const BackButton = useMemo(
        () => (props.isCampaignPage ? StyledSubmitButton : Styled404BackButton),
        [props.isCampaignPage],
    )

    return (
        <div className="campaign-warning">
            {props.text && (
                <StyledAlert data-cy={props.dataCy} isCampaignPage={props.isCampaignPage} activateNGGTheme>
                    {props.isCampaignPage && <i className="fa fa-info-circle" />}
                    {props.text}
                </StyledAlert>
            )}
            {
                // If there is no explict specified link and there are more than one page in the browser history
                // Then navigate back to previous page upon button click
                !props.link && window.history.length > 1 ? (
                    <UnstyledAnchor key="back-button" onClick={() => navigate(-1)}>
                        <BackButton data-cy="back-to-campaign">
                            <i className="fa fa-arrow-left" />
                            {t("campaign.thanks.back.previousPage")}
                        </BackButton>
                    </UnstyledAnchor>
                ) : (
                    <Link key="back-button" to={navigationLink}>
                        <BackButton data-cy="back-to-campaign">
                            <i className="fa fa-arrow-left" />
                            {props.buttonText}
                        </BackButton>
                    </Link>
                )
            }
        </div>
    )
}

CampaignWarning.propTypes = {
    buttonText: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
    dataCy: PropTypes.string,
}

CampaignWarning.defaultProps = {
    buttonText: i18n.t("campaign.thanks.back.campaignList"),
    text: "",
    dataCy: "",
}

export default CampaignWarning

import { Address } from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/interfaces"

interface AddressInfo {
    addressDict: Address | null
    addressText: string
    isInvalidAddress: boolean
}

export function parseAddress(address: string | Address): AddressInfo {
    const addressDict = typeof address === "string" ? null : address
    const addressText = typeof address === "string" ? address : address.INPUT_ADDRESS
    const isInvalidAddress = !addressText || addressText.trim().length === 0

    return {
        addressDict,
        addressText,
        isInvalidAddress,
    }
}

import * as constants from "shared/imports/sharedConstants"

export const searchNamesMap = {
    alert_default_search_filters: "alertDefaultSearchFilters",
    amendment_default_search_filters: "amendmentDefaultSearchFilters",
    article_default_search_filters: "articleDefaultSearchFilters",
    attachment_default_search_filters: "attachmentDefaultSearchFilters",
    bill_default_search_filters: "billDefaultSearchFilters",
    document_default_search_filters: "documentDefaultSearchFilters",
    event_default_search_filters: "eventDefaultSearchFilters",
    legislator_default_search_filters: "legislatorDefaultSearchFilters",
    note_default_search_filters: "noteDefaultSearchFilters",
    person_default_search_filters: "officialDefaultSearchFilters",
    press_contact_default_search_filters: "pressContactDefaultSearchFilters",
    public_organization_default_search_filters: "publicOrganizationDefaultSearchFilters",
    staffer_default_search_filters: "stafferDefaultSearchFilters",
    supporter_default_search_filters: "supporterDefaultSearchFilters",
    vote_default_search_filters: "voteDefaultSearchFilters",
}

// these are needed because onChange handlers rely on the availability of certain keys
// on the Persona enum
export const additionalPersonaSelectKeys = [
    "default",
    "sidebar_settings_list",
    "search_data_type_order",
    "default_search_data_type",
]

const tooltips = {
    userTableTooltip: `This table displays team members. Click on a team member's name to edit their basic info and change their settings.`,
    userTableDatumTooltip: `Click here to edit this team member's settings`,
    allApplicableUserTypesTooltip: `Quorum uses this list of user types to help us determine
    what help content to send to each user and helps us to personalize their overall experience.`,
}

const helpTexts = {
    defaultLimitedToTeamsSectionHelpText: `Choose the default Teams the things you create in Quorum should be tagged to`,
    shouldRouteErrorMessage: "If you are using a quorum domain, it must route through our system",
    emailDomainErrorMessage: 'Please enter a properly formatted domain. (Eg. "quorum.us", "gmail.com")',
    bounceDomainErrorMessage: 'Please enter a properly formatted bounce domain. (Eg. "quorum.us", "gmail.com")',
    submitErrorMessage: "Please make sure all your input values are valid or contact us at support@quorum.us",
}

export const forgotPassValue = { button_click: "FORGOT_PASSWORD" }

const swalConfigs = {
    confirmChange: {
        title: "Confirm Change",
        text: "Type your password to confirm this change. If you just changed your password use your old password.",
        content: {
            element: "input",
            attributes: {
                placeholder: "Password...",
                type: "password",
            },
        },
        buttons: {
            cancel: true,
            forgotPassword: {
                text: "Forgot Password",
                value: forgotPassValue,
            },
            confirm: {
                closeModal: false,
            },
        },
    },
    activateOrg: {
        fail: {
            title: "Error",
            text: "There was a problem activating this organization.",
            icon: "error",
        },
        success: {
            title: "Change Complete",
            text: "The organization has been activated. Please activate any individual users now",
            icon: "success",
        },
    },
    activateUser: {
        fail: {
            title: "Error",
            text: "There was a problem activating this user.",
            icon: "error",
        },
        pending: {
            title: "Activating User",
            text: "Activating this user...",
            icon: "info",
            buttons: {},
            closeOnClickOutside: false,
            closeOnEsc: false,
        },
        success: {
            title: "Change Complete",
            text: "This user has been activated successfully!",
            icon: "success",
        },
    },
    changeFailed: {
        title: "Change Failed",
        text: "Failed to make this change",
        icon: "error",
    },
    createOrganization: {
        fail: {
            title: "Error",
            text: "There was an error creating this organization.",
            icon: "error",
        },
        success: {
            title: "Change Complete",
            text: "Successfully created this organization",
            icon: "success",
        },
    },
    deactivateOrg: {
        fail: {
            title: "Error",
            text: "There was a problem deactivating this organization.",
            icon: "error",
        },
        prompt: {
            title: "Warning",
            text: "You are about to deactivate this organization and all of its users. Type the organization name to confirm.",
            type: "warning",
            buttons: {
                cancel: true,
                confirm: {
                    closeModal: false,
                },
            },
            content: {
                element: "input",
                attributes: {
                    placeholder: "Type the organization's name to confirm",
                },
            },
        },
        orgNameDoesNotMatch: {
            text: "The name you entered does not match the organization. The organization will not be deactivated.",
            title: "Name does not match",
            icon: "warning",
        },
        success: {
            title: "Success",
            text: "This organization was successfully deactivated",
            icon: "success",
        },
    },
    deactivateUser: {
        fail: {
            title: "Error",
            text: "There was a problem deactivating this user.",
            icon: "error",
        },
        prompt: {
            title: "Warning",
            text: "You are about to deactivate the user.",
            icon: "warning",
            buttons: {
                cancel: true,
                confirm: {
                    closeModal: false,
                },
            },
        },
        success: {
            title: "Success",
            text: "This user was successfully deactivated",
            icon: "success",
        },
    },
    deleteOrgDesignSuccess: {
        title: "Success",
        text: "Successfully deleting this organization design",
        icon: "success",
    },
    editUser: {
        title: "Error",
        text: "There was an error editing this user.",
        icon: "error",
    },
    editOrganization: {
        fail: {
            title: "Error",
            text: "There was an error editing this organization.",
            icon: "error",
        },
        success: {
            title: "Change Complete",
            text: "The organization has been updated",
            icon: "success",
        },
    },
    identityCheck: {
        title: "Identity Check",
        text: `Input your password here`,
        content: {
            element: "input",
            attributes: {
                placeholder: "Password...",
                type: "password",
            },
        },
        buttons: {
            cancel: true,
            forgotPassword: {
                text: constants.FORGOT_PASSWORD_BUTTON_TEXT,
                value: constants.FORGOT_PASSWORD,
            },
            confirm: {
                closeModal: false,
            },
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
    },
    singleSignOn: {
        title: "Identity Check",
        text: `You must reauthenticate to use this feature.`,
        buttons: {
            cancel: true,
            confirm: {
                text: "Go to my SSO Provider",
                closeModal: true,
            },
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
    },
    loginFailed: {
        title: "Error",
        text: "Unable to log into this account. Either you have insufficient permissions, do not have two-factor installed, or something went wrong.",
        icon: "error",
        closeOnConfirm: true,
    },
    missingDetails: {
        title: "Missing Details",
        text: "Your submission is missing details.", // should be overriden by more specific error messages
        icon: "error",
    },
    noMatchingUserDomain: {
        title: "Error",
        text: "The user you are attempting to create does not have a whitelisted email address. Please contact your CSM to add this email domain to your Organization",
        icon: "error",
    },
    resendActivationEmail: {
        fail: {
            title: "Error",
            text: "There was a problem sending an activation email for this user.",
            icon: "error",
        },
        success: {
            title: "Success",
            text: "Sent Activation Email.",
            icon: "success",
        },
    },
    returnToAdmin: {
        start: {
            title: "Back to Admin",
            text: "We're sending you back to your account. Just a moment!",
            icon: "info",
            buttons: {},
            closeOnClickOutside: false,
            closeOnEsc: false,
        },
        fail: {
            title: "Error",
            text: "There was an error sending you back to your account. You might not have the correct permission, or you may need to enable two-factor.",
            icon: "error",
        },
    },
    updateOrgDesignSuccess: {
        buttons: ["Continue Editing", "Go Back"],
        title: "Organization Design Updated",
        text: "Your organization's design was updated successfully.",
        icon: "success",
    },
    userLimit: {
        title: "Warning",
        text: "You have reached your user limit. Please contact support for additional users.",
        icon: "warning",
    },
}

const tableNames = {
    ACTIVE_ORGS_TABLE: "activeOrganizationAccountsTable",
    INACTIVE_ORGS_TABLE: "inactiveOrganizationAccountsTable",
}

const accountsConstants = {
    ...tooltips,
    ...swalConfigs,
    ...tableNames,
    ...helpTexts,
}

export default accountsConstants

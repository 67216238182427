import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/SelectableItem/style"
import * as colors from "Components/colors"

import Highlight from "Components/Atoms/Highlight"
import Icon from "Components/Atoms/Icon"

export const getIconType = (isActive, isSquare) => {
    if (isActive && isSquare) {
        return {
            // squareActive
            icon: "check-square",
            iconColor: colors.QuorumBlue,
            iconFamily: "fas",
        }
    } else if (isActive && !isSquare) {
        return {
            // radioActive
            icon: "dot-circle",
            iconColor: colors.QuorumBlue,
            iconFamily: "far",
        }
    } else if (!isActive && !isSquare) {
        return {
            // radioInactive
            icon: "circle",
            iconColor: colors.SilverChalice,
            iconFamily: "far",
        }
    }
    return {
        // default option: squareInactive
        icon: "square",
        iconColor: colors.SilverChalice,
        iconFamily: "far",
    }
}

const SelectableItem = ({
    children,
    dataCy,
    ariaDisabled,
    icon,
    iconFamily,
    id,
    isActive,
    isSquare,
    label,
    onClick,
    searchFilterTerm,
    size,
    suppressBackground,
}) => {
    const iconObj = getIconType(isActive, isSquare)

    return (
        <S.SelectableItem
            aria-disabled={ariaDisabled}
            data-auto-cy="MoleculeSelectableItem"
            data-cy={dataCy}
            key={id}
            onClick={onClick}
            suppressBackground={suppressBackground}
            size={size}
        >
            <S.StyledIcon
                data-cy="styled-icon"
                iconColor={iconObj.iconColor}
                isActive={isActive}
                isSquare={isSquare}
                // for accessibility
                aria-checked={isActive}
                aria-disabled={ariaDisabled}
                aria-label={label}
                onKeyDown={
                    e => e.key === "Enter" && onClick && onClick()
                }
                role="checkbox"
                tabIndex={0}
            >
                <Icon
                    fixedWidth={false}
                    icon={iconObj.icon}
                    iconFamily={iconObj.iconFamily}
                />
            </S.StyledIcon>
            {
                icon &&
                    <Icon
                        color={colors.QuorumBlue}
                        fixedWidth={false}
                        icon={icon}
                        iconFamily={iconFamily}
                        size={"lg"}
                    />
            }
            <S.Label
                isActive={isActive}
                size={size}
            >
                {
                    <Highlight
                        highlight={searchFilterTerm}
                        text={label}
                    />
                }
            </S.Label>
            <S.TooltipIcon
                tooltipElement={children}
            >
                {children}
            </S.TooltipIcon>
        </S.SelectableItem>
    )
}

SelectableItem.defaultProps = {
    label: "",
    isSquare: true,
    suppressBackground: false,
    size: "small",
}

SelectableItem.propTypes = {
    dataCy: PropTypes.string,
    children: PropTypes.element,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    // current selection changes icon
    isActive: PropTypes.bool,
    // true renders square/checkbox icons; false is circle/dot-circle icons
    isSquare: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    // the FilterGroup/Filter search term from app/static/frontend/search-new/components/FilterSet/index.js SearchFiltersTextareaFilter
    // passed from app/static/frontend/search-new/components/FilterGroup/index.js FilterComponent
    // through
    // app/static/frontend/search-new/components/Filter/EnumFilter/EnumFilterItem.js
    searchFilterTerm: PropTypes.string,
    suppressBackground: PropTypes.bool,

    /** The size, corresponding to the text font styles in rules.js. Only those listed below are supported. */
    size: PropTypes.oneOf(["small", "medium", "large"]),
}

export default SelectableItem

import styled, { css } from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"


export const ContentSwitcher = styled.div`
    position: relative;

    min-width: 380px;
    width: 100%;
    height: 44px;
    padding: 3px 4px;

    background-color: white;
    outline: none;
    border: 2px solid ${colors.DarkerPorcelain};
    border-radius: 999px;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
`
export const Option = styled.input.attrs((props) => ({
    type: "radio"
}))`
    /* Disable default styling */
    appearance: none;

    &:focus {
        outline: none;
    }
`

export const OptionLabel = styled.label`
    /* Start a new stacking context, so the label renders above the Highlighter */
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: ${props => 100 / props.options.length}%;

    height: 100%;
    margin: 0;
    padding: 0 12px;

    overflow: hidden;
    white-space: nowrap;
    & > span {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    cursor: pointer;

    color: ${colors.QuorumViolet};

    ${rules.SmallText}
    ${props => props.isActive && css`
        color: white;
        font-weight: bold;
    `}
    transition: color .2s, font-weight .2s;

    &:hover {
        ${props => !props.isActive && css`
            background-color: #EAEBEF;
            border-radius: 999px;
        `}
    }
`

export const Highlighter = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    height: 36px;

    background-color: ${colors.QuorumBlue};
    border-radius: 999px;

    /* Calculate the left and right position of the highlighter based on the option selected, and the total options available*/
    ${props => {
        const optionWidth = 100 / props.options.length  // 3 Options => 33/Option
        const left = props.index * optionWidth          // 0 => 0,  1 => 33, 2 => 66
        const right = 100 - (left + optionWidth)        // 0 => 66, 1 => 33, 2 => 0

        return css`
            left: calc(${left}% + 4px);
            right: calc(${right}% + 4px);
        `
    }}

    /* Ensure the leading edge moves quickly, and the trailing edge catches up */
    ${props => props.willMoveRight
        ? css`transition: left .5s, right .2s;`
        : css`transition: left .2s, right .5s;`
    }

    ${props => props.isHovered && css`
        background-color: ${colors.CloudBurst};
    `}
`

import {
    useEffect,
    useRef,
    useState,
} from "react"

// helpers
import { debounce } from "lodash"

/**
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 * https://stackoverflow.com/a/55743157/6201291
 * https://usehooks.com/usePrevious/
 *
 * usePrevious hook that returns the previous version of a previously derived value
 * (such as the previous width and height from the useRefDimensions hook below)
 *
 * @name usePrevious
 * @function
 * @param {any} value  - any primitive value
 *
 * @returns {any}  returns the previous primitive value
 */
export const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    }, [value])
    return ref.current
}

/**
 * https://gist.github.com/gaearon/cb5add26336003ed8c0004c4ba820eae
 * https://stackoverflow.com/a/36862446/6201291
 *
 * useRefDimensions hook that returns the height and width
 * of a reference once a debounced window.resize event completes
 *
 * @name useRefDimensions
 * @function
 * @param {Array} data - any arbitrary array of data
 * @param {Element} reference - React element ref
 * @param {int} timestamp - a timestamp representing that we successfully fetched data from the widgetEngine
 *
 *
 * @returns {Object} returns the {height, width} of the reference
 */
export const useRefDimensions = ({
    reference,
    timestamp,
}) => {
    const [dimensions, setDimensions] = useState({
        height: undefined,
        width: undefined,
    })

    useEffect(() => {
        const onResize = debounce(() => {
            if (reference && reference.current) {
                reference.current.setAttribute("height", "100%")
                reference.current.setAttribute("width", "100%")

                const { height, width } = reference.current.getBoundingClientRect()
                setDimensions({
                    height,
                    width,
                })
            }
        }, 250)

        // The data is computed after mount
        // (due to the asynchronous this.props.evaluateWidget() call in VisualizationWidget.jsx),
        // which means that we should check whether or not data exists at all before
        // we try to render the visualization.

        // Theoretically, in Dashboards, this will never be invoked on mount
        // due to the async call to the widget_engine endpoint
        if (
            // though the above selector will always return an empty array initially,
            // this component could theoretically be invoked unconnected in some niche cases,
            // so we should check to see if data exists at all
            timestamp
        ) {
            // We want to ensure that we properly recompute the width and height of the svg
            // and its elements on window resize

            // This works in both the Dashboard and WidgetEditForm:
            //      - The resize Event is automatically invoked when we resize the page
            //      (this is necessary since the aspect ratio of the react-grid-layout Widgets change according to the window size)
            //      - The resize Event is also fired when we resize the individual Widget inside of the Dashboard itself
            //      (since we fire dispatchEvent(new Event("resize")) in the Dashboard onResizeStop)

            // Since it is debounced, it only fires a single time when the resize event ends.
            // Additionally, since we’re using preserveAspectRatio="xMidYMid meet",
            // this means that the resizing (which occurs between when the resize event begins and
            // when the debounce resolves and fires the listener) will fluidly scale and
            // then automatically repaint the svg to the new dimensions.
            window.addEventListener("resize", onResize)

            // we want to ensure that we have the exact width and height
            // of the svg before we render any elements inside of it
            // this wil trigger a rerender and hit the
            // or the width/height has changed
            // state in componentDidUpdate
            onResize()
        // if there is no timestamp
        // i.e., we go from [data] to []
        // (handled in app/static/frontend/widgets/components/VisualizationWidget.jsx componentDidUpdate),
        // we want to clear any extant data in the svg reference
        } else if (reference && reference.current) {
            const referenceNode = reference.current
            // https://jsperf.com/innerhtml-vs-removechild/15
            while (referenceNode.firstChild) {
                referenceNode.removeChild(referenceNode.firstChild)
            }
        }

        // componentWillUnmount
        return () => {
            window.removeEventListener("resize", onResize)
        }
    // componentDidMount
    // https://reactjs.org/docs/hooks-effect.html#tip-optimizing-performance-by-skipping-effects
    // data = [] on mount
    // data = [{index: 0, name: "name", value: 2}] after being selected when the async evaluateWidget call resolves
    // timestamp is updated every time one of these widgetEngine async calls resolve
    }, [timestamp])

    return dimensions
}

import React from "react"
import PropTypes from "prop-types"
import { withNamespaces } from "react-i18next"

import {
    StyledProgressBarWrapper,
    StyledProgressBar,
    StyledProgressBarNegative,
    StyledProgressBarText,
} from "QuorumGrassroots/framework/components/ProgressBar/style"

const ProgressBar = (props) => (
    <StyledProgressBarWrapper className="progress-bar-wrapper" isCampaignPage={props.isCampaignPage}>
        <StyledProgressBar currentPercent={props.currentPercent} isCampaignPage={props.isCampaignPage}>
            <StyledProgressBarText isCampaignPage={props.isCampaignPage}>
                {props.t("campaign.unreachable_goal.actions_taken", { number_actions: props.numberActions })}
            </StyledProgressBarText>
        </StyledProgressBar>
        <StyledProgressBarNegative percentLeft={props.percentLeft} isCampaignPage={props.isCampaignPage}>
            <StyledProgressBarText isNegative isCampaignPage={props.isCampaignPage}>
                {props.t("campaign.unreachable_goal.actions_needed", { number_actions: props.numberActionsNeeded })}
            </StyledProgressBarText>
        </StyledProgressBarNegative>
    </StyledProgressBarWrapper>
)

ProgressBar.propTypes = {
    currentPercent: PropTypes.number.isRequired,
    percentLeft: PropTypes.number.isRequired,
    numberActions: PropTypes.number.isRequired,
    numberActionsNeeded: PropTypes.number.isRequired,
    isCampaignPage: PropTypes.bool.isRequired,
}

export default withNamespaces()(ProgressBar)

import React from "react"
import PropTypes from "prop-types"

import isEmpty from "lodash.isempty"

import * as S from "Components/Molecules/LegislationSummary/style"

const LegislationSummary = ({
    sessionEndDate,
    sessionStartDate,
    statusDate,
    statusText,
}) => (
    <S.LegislationSummary data-auto-cy="MoleculeLegislationSummary">
        <span>
            Status: <S.BoldSpan>{statusText}</S.BoldSpan> { statusDate && `on ${statusDate}` }
        </span>
        {(sessionStartDate || sessionEndDate) && (
            <S.SessionInfo>
                Session Start: <S.BoldSpan>{sessionStartDate}</S.BoldSpan>
                {!isEmpty(sessionEndDate) && " | End: "}
                {!isEmpty(sessionEndDate) && <S.BoldSpan>{sessionEndDate}</S.BoldSpan>}
            </S.SessionInfo>
        )}
    </S.LegislationSummary>
)

LegislationSummary.defaultProps = {}

LegislationSummary.propTypes = {
    sessionEndDate: PropTypes.string,
    sessionStartDate: PropTypes.string,
    statusDate: PropTypes.string,
    statusText: PropTypes.string,
}

export default LegislationSummary

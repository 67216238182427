export const mapVisualizationDataReplacementKeys = {
    // our Country and CountryEnum use "United States"
    // whereas our topojson uses "United States of America"
    // TODO: we will fix this when we convert our d3 topojson request to
    // app/geography/models.py GeoShape get_formatted_geoshape to return a topojson rather than a geojson
    // (see app/widgets/types.py MapVisualizationRegion d3_url)
    "United States": "United States of America",
}

export const mapGlobalVisualizationDataReplacementKeys = {
    "US Federal": "United States of America",
    Alabama: "United States of America",
    Alaska: "United States of America",
    Arizona: "United States of America",
    Arkansas: "United States of America",
    California: "United States of America",
    Colorado: "United States of America",
    Connecticut: "United States of America",
    Delaware: "United States of America",
    "District of Columbia": "United States of America",
    Florida: "United States of America",
    Georgia: "United States of America",
    Hawaii: "United States of America",
    Idaho: "United States of America",
    Illinois: "United States of America",
    Indiana: "United States of America",
    Iowa: "United States of America",
    Kansas: "United States of America",
    Kentucky: "United States of America",
    Louisiana: "United States of America",
    Maine: "United States of America",
    Maryland: "United States of America",
    Massachusetts: "United States of America",
    Michigan: "United States of America",
    Minnesota: "United States of America",
    Mississippi: "United States of America",
    Missouri: "United States of America",
    Montana: "United States of America",
    Nebraska: "United States of America",
    Nevada: "United States of America",
    "New Hampshire": "United States of America",
    "New Jersey": "United States of America",
    "New Mexico": "United States of America",
    "New York": "United States of America",
    "North Carolina": "United States of America",
    "North Dakota": "United States of America",
    Ohio: "United States of America",
    Oklahoma: "United States of America",
    Oregon: "United States of America",
    Pennsylvania: "United States of America",
    "Rhode Island": "United States of America",
    "South Carolina": "United States of America",
    "South Dakota": "United States of America",
    Tennessee: "United States of America",
    Texas: "United States of America",
    Utah: "United States of America",
    Vermont: "United States of America",
    Virginia: "United States of America",
    Washington: "United States of America",
    "West Virginia": "United States of America",
    Wisconsin: "United States of America",
    Wyoming: "United States of America",
}

export const euNameLatinToD3 = {
    Shqipëria: "Albania",
    Österreich: "Austria",
    "Belgique/België": "Belgium",
    Danmark: "Denmark",
    Deutschland: "Germany",
    Nederland: "Netherlands",
    Polska: "Poland",
    Portugal: "Portugal",
    "United Kingdom": "United Kingdom",
    Türkiye: "Turkey",
    España: "Spain",
    Bulgaria: "Bulgaria",
    "Schweiz/Suisse/Svizzera": "Switzerland",
    Kýpros: "Cyprus",
    România: "Romania",
    Serbia: "Serbia",
    Česko: "Czechia",
    "Suomi/Finland": "Finland",
    Eesti: "Estonia",
    Magyarország: "Hungary",
    Malta: "Malta",
    Elláda: "Greece",
    Norge: "Norway",
    Hrvatska: "Croatia",
    Slovenija: "Slovenia",
    Slovensko: "Slovakia",
    Sverige: "Sweden",
    France: "France",
    "Éire/Ireland": "Ireland",
    Ísland: "Iceland",
    Liechtenstein: "Liechtenstein",
    Lietuva: "Lithuania",
    Luxembourg: "Luxembourg",
    Latvija: "Latvia",
    "Crna Gora": "Montenegro",
    "Severna Makedonija": "Macedonia",
    Italia: "Italy",
}

import styled from "styled-components"
import { Col } from "react-bootstrap"

import { constants } from "QuorumGrassroots/constants"

export const StyledDescriptionWrapper = styled.div`
    margin-top: 10px;
`

export const StyledDescription = styled.div`
    font-size: 20px;
`

export const StyledButtonWrapperCol = styled(Col)`
    @media (min-width: ${constants.mobileWidth + 1}px) {
        ${(props) =>
            props.twoButton &&
            `
                &:nth-of-type(2n) {
                    padding-left: 7.5px;
                }

                &:nth-of-type(2n + 1) {
                    padding-right: 7.5px;
                }
            `}
    }
`

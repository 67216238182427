import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"

import CampaignWidget from "QuorumGrassroots/widgets/Campaign/components/index"
import initializer from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/InitializeStoreWrapper"
import { campaignWidgetConnection } from "QuorumGrassroots/widgets/Campaign/selectors"
import { postGrassrootsSupporterAction } from "QuorumGrassroots/campaign-forms/action-creators"
import { resetSubmittedWidget } from "QuorumGrassroots/widgets/action-creators"

const actions = { postGrassrootsSupporterAction, resetSubmittedWidget }

const connectedCampaign = connect(campaignWidgetConnection, actions)(CampaignWidget)

// make the store slice!
const initializedCampaign = initializer(connectedCampaign)

export default withNamespaces()(initializedCampaign)

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/LargeGateway/style"

import Icon from "Components/Atoms/Icon"

const LargeGateway = ({
        children,
        content,
        customContentStyle,
        customHeaderStyle,
        customHeight,
        customIconSize = "3x",
        customWidth,
        dataCy,
        header,
        icon,
        iconFamily,
        isLoading,
        onClick }) => (
    isLoading
    ?
    (
        <S.GatewayWrapper
            customWidth={customWidth}
            data-auto-cy="CompoundLargeGateway"
            data-cy={dataCy}
            onClick={() => null}
        >
            <S.Icon>
                <Icon icon='circle' iconFamily='far' size={customIconSize} />
            </S.Icon>
            <S.Header>
                ______
            </S.Header>
            <S.Content>
                <p>________________</p>
                <p>_________________</p>
                <br />
                <p>______</p>
            </S.Content>
            <S.Action>
            </S.Action>
        </S.GatewayWrapper>
    )
    :
    (
        <S.GatewayWrapper
            customHeight={customHeight}
            customWidth={customWidth}
            data-auto-cy="CompoundLargeGateway"
            data-cy={dataCy}
            onClick={onClick}
        >
            <S.Icon>
                <Icon icon={icon} iconFamily={iconFamily} size={customIconSize} />
            </S.Icon>
            <S.Header customStyle={customHeaderStyle}>
                {header}
            </S.Header>
            <S.Content customStyle={customContentStyle}>
                {content}
            </S.Content>
            <S.Action>
                {children}
            </S.Action>
        </S.GatewayWrapper>
    )
)

LargeGateway.defaultProps = {
    icon: "bug",
    iconFamily: "fad"
}

LargeGateway.propTypes = {
    header: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    customHeight: PropTypes.string,
    customIconHeight: PropTypes.string,
    customWidth: PropTypes.string,
    icon: PropTypes.string.isRequired,
    iconFamily: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
}

export default LargeGateway

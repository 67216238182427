import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const Legend = styled.div`
    display: flex;
    flex-shrink: 0;
    ${props => (props.isVertical) && css`
        flex-direction: column;
    `}
    margin-left: 21.5px;
    margin-bottom: 17.5px;
    margin-right: 50px;
    overflow-x: auto;
    overflow-y: hidden;
`

export const LegendRow = styled.div`
    align-items: center;
    display: flex;
    flex-shrink: 0;
    ${props => (!props.isVertical) && css`
        margin-right: 22px;
    `}
`

export const LegendColorSquare = styled.div`
    background-color: ${props => props.colors[props.index]};
    border-radius: 4px;
    height: 15px;
    margin-right: 6px;
    width: 15px;
`

export const LegendText = styled.span`
    ${rules.SmallTextItalic}
    color: ${colors.Black};
`


import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/QuicklinkWidget/style"
import Button from "Components/Molecules/Button"
import Anchor from "Components/Atoms/Anchor"

const QuicklinkWidget = ({ editing, editForm, icon, isExternal, segueToUrl, text, type, url}) => {
    const anchorProps = {}
    const buttonProps = {}
    if (!(editing || editForm)) {
        if (isExternal) {
            anchorProps["target"] = '_blank'
            if (url.includes('http') || url.includes('//')) {
                anchorProps["href"] = url
            } else if (!url.includes('.')) {
                anchorProps["href"] = 'https://quorum.us' + url
            } else {
                anchorProps["href"] = 'http://' + url
            }
        } else {
            anchorProps["onClick"] = () => segueToUrl(url)
        }
    }

    if (type) {
        buttonProps["leftIcon"] = icon
    } else {
        buttonProps["disabled"] = true
        buttonProps["fontStyle"] = "italic"
    }

    return (
        <S.QuicklinkWidget
            data-auto-cy="CompoundQuicklinkWidget"
            editForm={editForm}
        >
            <Anchor
                {...anchorProps}
            >
                <Button
                    dataCy="quicklink-widget-button"
                    isGiant
                    text={text}
                    {...buttonProps}
                />
            </Anchor>
        </S.QuicklinkWidget>
    )
}

QuicklinkWidget.propTypes = {
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    isExternal: PropTypes.bool,
    segueToUrl: PropTypes.func,
    text: PropTypes.string,
    type: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
}

export default QuicklinkWidget

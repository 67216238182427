import styled, { css } from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const Container = styled.div`
    position: absolute;
    // ensure the z-index is greater than the search action buttons which is 1001
    z-index: 1005;
    width: fit-content;
    display: table;
`

export const Tooltip = styled.div`
    position: relative;
    right: 0;

    ${rules.CardStyle}
    box-shadow: ${rules.DropShadow};
    ${rules.SmallTextRegularWeight}
    color: ${colors.Black};
    background-color: ${colors.White};
    padding: ${({isTiny}) => isTiny ? "8px 11px" : "11px"};

    max-width: ${({maxWidth}) => maxWidth};
    width: ${({fixedWidth}) => fixedWidth};
    overflow-wrap: normal;
    text-align: center;

    // triangle "V" border
    &:before {
        content: '';
        position: absolute;
        z-index: 2;
        ${({placement, offset}) => {
            if (placement === "top" || (placement === "right" && offset > 0)) {
                return css`
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 6px solid ${colors.SilverChalice};
                    left: calc(50% - 6px);
                    top: 100%;
                `
            } else if (placement === "right") {
                return css`
                    border-bottom: 6px solid transparent;
                    border-right: 6px solid ${colors.SilverChalice};
                    border-top: 6px solid transparent;
                    left: calc(0% - 7px);
                    // ensure the position of the arrow is consistent
                    top: 12px;
                `
            } else if (placement === "bottom") {
                return css`
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-bottom: 6px solid ${colors.SilverChalice};
                    left: calc(50% - 6px);
                    top: -6px;
                `
            } else {
                // when placement is undefined, default to "top"
                return css`
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 6px solid ${colors.SilverChalice};
                    left: calc(50% - 6px);
                    top: 100%;
                `
            }
        }}
    }

    // triangle
    &:after {
        content: '';
        position: absolute;
        z-index: 3;
        // slide the arrow back to its original position if the tooltip was moved

        ${({placement, offset}) => {
            if (placement === "top" || (placement === "right" && offset > 0)) {
                return css`
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 4px solid ${colors.White};
                    left: calc(50% - 4px);
                    top: 100%;
                `
            } else if (placement === "right") {
                return css`
                    border-bottom: 6px solid transparent;
                    border-right: 6px solid ${colors.White};
                    border-top: 6px solid transparent;
                    left: calc(0% - 6px);
                    // ensure the position of the arrow is consistent
                    top: 12px;
                `
            } else if (placement === "bottom") {
                return css`
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-bottom: 6px solid ${colors.White};
                    left: calc(50% - 5px);
                    top: -6px;
                `
            } else {
                return css`
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 4px solid ${colors.White};
                    left: calc(50% - 4px);
                    top: 100%;
                `
            }
        }}
    }
`

import { createSelector, createStructuredSelector } from "reselect"
import i18n from "i18n"

import { makeObjSliceSelector } from "shared/imports/sharedHelperFunctions"

import {
    createPageTitleSelector,
    selectHasExternalInteractions,
    selectHasExternalRelationship,
    selectShowOfficialCustomDescription,
} from "QuorumGrassroots/framework/selectors"
import { selectAugmentedWidgetContent } from "QuorumGrassroots/widgets/selectors"
import * as loginRegistrationSelectors from "QuorumGrassroots/widgets/LoginRegistration/selectors"

const makeSelectMemberAttribute = makeObjSliceSelector(selectAugmentedWidgetContent)

export const selectMostRecentRoleType = makeSelectMemberAttribute("most_recent_role_type")

export const selectPrimaryOrg = makeSelectMemberAttribute("primary_organization")
export const selectPrimaryOrgName = makeSelectMemberAttribute("primary_organization_name")
export const selectMemberName = makeSelectMemberAttribute("name")
export const selectMemberImgUrl = makeSelectMemberAttribute("image_url")
export const selectTitle = makeSelectMemberAttribute("title")
export const selectAddress = makeSelectMemberAttribute("address")
export const selectPhoneNumber = makeSelectMemberAttribute("phone")
export const selectParty = makeSelectMemberAttribute("party")
export const selectSocialUserAccounts = makeSelectMemberAttribute("social_user_accounts")
export const selectMostRecentRegion = makeSelectMemberAttribute("most_recent_region")

export const selectPersonBiography = makeSelectMemberAttribute("person_biography")
const makePersonBiographyAttribute = makeObjSliceSelector(selectPersonBiography)

export const selectQuorumBiography = makePersonBiographyAttribute("quorum_biography")
export const selectKnowWhoBiography = makePersonBiographyAttribute("kw_biography")

export const selectDataDescription = makeSelectMemberAttribute("data_description")
const makeSelectDataDescriptionAttribute = makeObjSliceSelector(selectDataDescription)

export const selectCustomDescription = makeSelectDataDescriptionAttribute("text")

export const selectHeaderTitle = createSelector(
    selectMostRecentRoleType,
    selectPrimaryOrgName,
    selectMemberName,
    (state) => state,
    (roleType, primaryOrg, name) =>
        `${name}` +
        // add primary org for local people
        `${
            DjangIO.app.person.types.RoleType.filter_groups
                .find((fg) => fg.title === i18n.t("official.local_people.label"))
                .values.includes(roleType) && primaryOrg
                ? ` | ${primaryOrg}`
                : ""
        }`,
)

export const selectDescriptionText = createSelector(
    selectShowOfficialCustomDescription,
    selectCustomDescription,
    selectQuorumBiography,
    selectKnowWhoBiography,
    (showOfficialCustomDescription, customDescription, quorumBiography, knowWhoBiography) =>
        (showOfficialCustomDescription && customDescription) || quorumBiography || knowWhoBiography,
)

export const selectPartyColor = createSelector(selectParty, (party) => {
    const partyEnum = DjangIO.app.person.types.Party[party]

    return partyEnum ? partyEnum.color : "#b6c0da"
})

export const selectIsLocal = createSelector(selectMostRecentRegion, (region) =>
    DjangIO.app.models.SupportedRegion.local.child_regions.includes(region),
)

export const selectContactInformationData = createSelector(
    selectIsLocal,
    selectAddress,
    selectPhoneNumber,
    (isLocal, address, phone) =>
        !isLocal &&
        [
            address && {
                title: i18n.t("official.contact_information.address.label"),
                value: address,
                width: 6,
            },
            phone && {
                title: i18n.t("official.contact_information.phone.label"),
                value: phone,
            },
        ].filter((item) => item),
)

export const selectAboutData = createSelector(selectParty, (party) =>
    [
        party && {
            title: i18n.t("official.about_information.party.label"),
            value: party,
        },
    ].filter((item) => item),
)

export const selectSocialMediaData = createSelector(selectSocialUserAccounts, (socialUserAccounts) =>
    socialUserAccounts
        ? socialUserAccounts.map((account) => ({
              title: DjangIO.app.social.models.SocialUserType.by_value(account.social_user_type).platform_name,
              value: account.username,
              socialMediaAccountType: account.social_user_type,
          }))
        : [],
)

export const selectMemberDataSections = createSelector(
    selectContactInformationData,
    selectAboutData,
    selectSocialMediaData,
    (contactInformationData, aboutData, socialMediaData) =>
        [
            aboutData &&
                aboutData.length && {
                    label: i18n.t("official.about_information.header.text"),
                    data: aboutData,
                },
            contactInformationData &&
                contactInformationData.length && {
                    label: i18n.t("official.contact_information.header.text"),
                    data: contactInformationData,
                },
            socialMediaData &&
                socialMediaData.length && {
                    label: i18n.t("official.social_media.header.text"),
                    data: socialMediaData,
                },
        ].filter((item) => item),
)

export const selectHasInteractionAndRelationship = createSelector(
    selectHasExternalInteractions,
    selectHasExternalRelationship,
    (hasExternalInteractions, hasExternalRelationship) => hasExternalInteractions && hasExternalRelationship,
)

export const officialSelector = createStructuredSelector({
    registrationPageIds: loginRegistrationSelectors.selectDefaultRegistrationPageIds,
    headerTitle: selectHeaderTitle,
    memberImgUrl: selectMemberImgUrl,
    title: selectTitle,
    descriptionText: selectDescriptionText,
    pageTitle: createPageTitleSelector(selectHeaderTitle),
    partyColor: selectPartyColor,
    memberDataSections: selectMemberDataSections,
    hasExternalInteractions: selectHasExternalInteractions,
    hasExternalRelationship: selectHasExternalRelationship,
    hasInteractionAndRelationship: selectHasInteractionAndRelationship,
})

// vendor modules
import { createSelector } from "reselect"
import createCachedSelector from "re-reselect"
import { Map, List, fromJS } from "immutable"
import moment from "moment"

import { getPermissionedSearchItems, stringifyFilterValue } from "shared/search/helperFunctions"

import { TASTYPIE_QUERY_LIMIT } from "shared/imports/sharedConstants"
import {
    abbreviateNumber,
    getDataLabelPlural,
    getDataLabelSingular,
    PRECISION_MIN_THRESHOLD,
} from "shared/imports/sharedHelperFunctions"
import { selectIsCommsOnlyUser } from "shared/userdata/userdata-selectors"

/**
 * Select search's slice of the store.
 *
 * @name selectSearch
 * @function
 * @param {object} state - Global state object.
 * @returns {object} Search slice of the store.
 *
 * The state is an object with immutable map values.
 */
export function selectSearch(state) {
    return state.search || Map()
}

export function selectSearchFlvSlice(state, props) {
    return selectSearch(state).getIn(["filterableListViews", props.uniqueFlvId], Map())
}

// Render Searchify (Power Search) in a modal instead of a separate route!
export function selectIsSearchifyOpen(state) {
    return selectSearch(state).get("isSearchifyOpen")
}

export const selectShowManuallyChangedInListFilter = (state) => {
    return selectSearch(state).get("showManuallyChangedInListFilter")
}

export const selectIsFlvSliceRegistered = createCachedSelector(
    selectSearchFlvSlice,
    (searchSlice) => searchSlice.has("filters") && Boolean(searchSlice.get("filters").size),
    // has to check for existing filters (or something in the main search, not just size,
    // because Searchify creates the slice without some store segments.
)((state, props) => props.uniqueFlvId)

export const selectFlvResource = createCachedSelector(
    [(state, props) => props.quorumDataType, (state, props) => props.resource],
    (qdt, resource) => (qdt ? resource || DjangIO.resourceFromDataType(qdt) : resource),
)((state, props) => props.uniqueFlvId)

export function selectSearchPreloadDataLoaded(state) {
    return selectSearch(state).getIn(["appearanceValues", "searchPreloadDataLoaded"])
}

export function selectSearchPreloadDataLoading(state) {
    return selectSearch(state).getIn(["appearanceValues", "searchPreloadDataLoading"])
}

export const selectSerializeFunctions = (state) => selectSearch(state).get("serializeFunctions")

export const selectDeserializeFunctions = (state) => selectSearch(state).get("deserializeFunctions")

/**
 * Selects the user's default filters.
 *
 * @name selectDefaultFilters
 * @function
 * @returns {Map} of default filters for all data types.
 */
export function selectDefaultSearchFilters(state) {
    return selectSearch(state).get("defaultSearchFilters")
}

const selectDefaultFilters = (state, props) => selectSearchFlvSlice(state, props).get("defaultFilters", Map())

const selectProtectedFilters = (state, props) => selectSearchFlvSlice(state, props).get("protectedFilters", List())

export const selectAllFilters = (state, props) => selectSearchFlvSlice(state, props).get("filters", Map())

const selectFilterSlice = (state, props) => selectAllFilters(state, props).get(props.uniqueFilterId, Map())

export const selectAppearanceValues = (state, props) =>
    selectSearchFlvSlice(state, props).get("appearanceValues", Map())

export const selectShouldDisableDefaultFilters = createCachedSelector(
    [(state, props) => props.isProfile, (state, props) => props.disableDefaultFilters],
    (isProfile, disableDefault) => isProfile || disableDefault,
)((state, props) => props.uniqueFlvId)

/**
 * Selects whether any filters have been registered on the FLV.
 *
 * @name selectHasRegisteredFilters
 * @function
 * @returns {Map} of default filters for all data types.
 */
export const selectHasRegisteredFilters = createCachedSelector(
    selectAllFilters,
    (filters) => filters.size > 0,
)((state, props) => props.uniqueFlvId)

/**
 * Determines if a FilterGroup has any active filters.
 *
 * @name selectHasActiveFilter
 * @function
 * @param {string} uniqueFilterGroupId - String representing the FilterGroup.
 * @returns {boolean} true/false, whether or not the FilterGroup has any active filters.
 */
export const selectHasActiveFilter = createCachedSelector(
    [selectAllFilters, (state, props) => props.uniqueFilterGroupId],
    (filters, filterGroupId) =>
        filters.filter((v) => v.get("uniqueFilterGroupId") === filterGroupId).filter((v) => v.get("hasValue", false))
            .size > 0,
)((state, props) => `${props.uniqueFlvId},${props.uniqueFilterGroupId}`)

/**
 * Checks if a FilterGroup has any active filters by selecting filters
 * from list of filter components instead of selecting filters by uniqueFilterGroupId.
 *
 * This selector is needed because of the conflict of filters in the store. Sometimes filters might have
 * different `uniqueFilterGroupId`
 *
 * @name selectHasRelatedActiveFilters
 * @function
 * @returns {boolean} true/false, whether or not the FilterGroup has any active filters.
 */
export const selectHasRelatedActiveFilters = createCachedSelector(
    [
        selectAllFilters,
        (state, props) => props.filters.map((filterComponent) => filterComponent.defaultProps.uniqueFilterId),
    ],
    (filters, filterIds) => filterIds.map((id) => filters.get(id)).filter((v) => v.get("hasValue", false)).length > 0,
)((state, props) => `${props.uniqueFlvId}.${props.filters.map((f) => f.defaultProps.uniqueFilterId).join(",")}`)

/**
 * Determines if a FilterGroup is open.
 *
 * @name selectIsFilterGroupOpen
 * @function
 * @param {string} uniqueFilterGroupId - String representing the FilterGroup.
 * @returns {boolean} true/false, whether or not the FilterGroup is open.
 */
export const selectIsFilterGroupOpen = createCachedSelector(
    [
        (state, props) => selectAppearanceValues(state, props).get("openFilterGroups", List()),
        (state, props) => props.uniqueFilterGroupId,
    ],
    (openFilterGroups, filterGroupId) => {
        return openFilterGroups.filter((v) => v === filterGroupId).size > 0
    },
)((state, props) => `${props.uniqueFlvId},${props.uniqueFilterGroupId}`)

/**
 * Selects the value of a Filter.
 *
 * @name selectFilterValue
 * @function
 * @param {string} uniqueFilterId - Unique identifier for the Filter.
 * @returns {string} Returns the string value of the Filter.
 */
export const selectFilterValue = createCachedSelector([selectFilterSlice], (filterSlice) =>
    filterSlice.get("value", null),
)((state, props) => `${props.uniqueFlvId},${props.uniqueFilterId}`)

/**
 * Selects the filterKey of a Filter. (For multifilters.)
 *
 * @name selectFilterKey
 * @function
 * @param {string} uniqueFilterId - Unique identifier for the Filter.
 * @returns {string} Returns the string filterKey of the Filter.
 */
export const selectFilterKey = createCachedSelector([selectFilterSlice], (filterSlice) =>
    filterSlice.get("filterKey", null),
)((state, props) => `${props.uniqueFlvId},${props.uniqueFilterId}`)

/**
 * Selects whether the Filter is registered.
 *
 * @name selectIsRegisteredFilter
 * @function
 * @param {string} uniqueFilterId - Unique identifier for the Filter.
 * @param {string} uniqueFlvId - Unique identifier for the FLV.
 * @returns {string} Returns the string value of the Filter.
 */
export const selectIsRegisteredFilter = createCachedSelector([selectFilterSlice], (filterSlice) =>
    Boolean(filterSlice.size),
)((state, props) => `${props.uniqueFlvId},${props.uniqueFilterId}`)

/**
 * Selects the cached number of items in the current query.
 *
 * @name selectCurrentCount
 * @function
 * @returns {number} Returns the estimated count of the query.
 * @type {(state: any, props: any) => any}
 */
export const selectCurrentCount = createCachedSelector(selectSearchFlvSlice, (state) => state.get("currentCount", 0))(
    (state, props) => props.uniqueFlvId,
)
/**
 * Selects the exact current number of items in the current query.
 *
 * @name selectCurrentExactCount
 * @function
 * @returns {number} Returns the exact count of the query.
 * @type {(state: any, props?: any) => any}
 */
export const selectCurrentExactCount = createCachedSelector(selectSearchFlvSlice, (state) =>
    state.get("currentExactCount", 0),
)((_, props) => props.uniqueFlvId)

export const selectCurrentQdt = createSelector(selectSearchFlvSlice, (state) => state.get("quorumDataType"))

export const selectSearchQDTLabel = createSelector(
    selectCurrentCount,
    selectCurrentQdt,
    selectIsCommsOnlyUser,
    (count, qdt, isCommsOnlyUser) => {
        if (qdt) {
            // Comms only user/org label change for Documents Subtitle
            if (qdt === DjangIO.app.models.QuorumDataType.document.value && isCommsOnlyUser) {
                return "Social Media"
            }
            return count === 1 ? getDataLabelSingular(qdt) : getDataLabelPlural(qdt)
        }
    },
)

export const selectSearchCountTooltipLabel = createSelector(
    selectSearchQDTLabel,
    selectCurrentQdt,
    (displayLabel, qdt) => {
        if (displayLabel && qdt === DjangIO.app.models.QuorumDataType.send_email.value) {
            return displayLabel + " in the last 12 months"
        }
        return displayLabel
    },
)

/**
 * Selects the number of items in the initial query.
 *
 * @name selectInitialCount
 * @function
 * @returns {number} Returns the exact count of the query.
 */
export const selectInitialCount = createCachedSelector(selectSearchFlvSlice, (state) => state.get("initialCount", 0))(
    (_, props) => props.uniqueFlvId,
)

export const selectAbbreviatedCurrentCount = createSelector(
    selectCurrentCount,
    selectCurrentExactCount,
    (currentCount, exactCount) =>
        currentCount < PRECISION_MIN_THRESHOLD
            ? (exactCount || currentCount).toLocaleString()
            : abbreviateNumber(currentCount, 1, PRECISION_MIN_THRESHOLD, true).toLocaleString(),
)

/**
 * Selects the loading status of the count.
 *
 * @name selectIsCountLoading
 * @function
 * @returns {number} Returns whether the count is loading.
 * @type {(state: any, props: any) => any}
 */
export const selectIsCountLoading = createCachedSelector(selectAppearanceValues, (appearanceValues) =>
    appearanceValues.get("countIsLoading", false),
)((state, props) => props.uniqueFlvId)

/**
 * Select if the exact count request errored.
 *
 * @name selectIsExactCountInaccessible
 * @function
 * @returns {Boolean}
 */
export const selectIsExactCountInaccessible = createCachedSelector(selectSearchFlvSlice, (state) =>
    state.get("isExactCountInaccessible", false),
)((_, props) => props.uniqueFlvId)

/**
 * Accesses state to know whether to show abbreviated number or exact number on desktop.
 *
 * @name selectShouldShowExactCount
 * @function
 * @returns {bool} if should show exact instead of abbreviated
 */
export const selectShouldShowExactCount = createCachedSelector(selectAppearanceValues, (appearanceValues) =>
    appearanceValues.get("shouldShowExactCount", false),
)((state, props) => props.uniqueFlvId)

/**
 * Selects if a filter is permanent.
 *
 * @name selectIsPermanentFilter
 * @function
 * @param {string} uniqueFilterId - Unique identifier for the Filter.
 * @returns {boolean} Returns if filter is marked as permanent.
 */
export const selectIsPermanentFilter = createCachedSelector(
    [
        (state, props) => selectSearchFlvSlice(state, props).get("permanentFilters", List()),
        (state, props) => props.uniqueFilterId,
    ],
    (permanentFilters, uniqueFilterId) => permanentFilters.includes(uniqueFilterId),
)((state, props) => `${props.uniqueFlvId},${props.uniqueFilterId}`)

export const selectCurrentPage = createCachedSelector(selectSearchFlvSlice, (state) => state.get("currentPage", 0))(
    (state, props) => props.uniqueFlvId,
)

export const selectCurrentData = createCachedSelector(
    [selectSearchFlvSlice, (state, props) => props.filterCurrentData],
    (state, filterCurrentData) => {
        if (filterCurrentData) {
            return filterCurrentData(state.get("currentData", List()))
        }

        return state.get("currentData", List())
    },
)((state, props) => props.uniqueFlvId)

export const selectCurrentFiltersWithId = createCachedSelector(selectAllFilters, (filters) =>
    filters
        .filter((value) => value.get("hasValue") && value.get("filterKey"))
        .reduce(
            // need to incorporate filterKey as well because multiFilters can change filterKeys,
            // not just filter values.
            (filterObj, value, key) =>
                filterObj.set(key, fromJS({ filterKey: value.get("filterKey"), value: value.get("value") })),
            Map(),
        ),
)((state, props) => props.uniqueFlvId)

export const selectCurrentFilters = createCachedSelector(selectCurrentFiltersWithId, (filters) =>
    filters.reduce(
        // need to incorporate filterKey as well because multiFilters can change filterKeys,
        // not just filter values.
        (filterObj, value, _) => filterObj.set(value.get("filterKey"), value.get("value")),
        Map(),
    ),
)((state, props) => props.uniqueFlvId)

export const selectAllDeserializedFilters = createCachedSelector(
    [selectAllFilters, selectDeserializeFunctions],
    (filters, deserializeFunctions) => {
        return filters.reduce((filterObj, value, key) => {
            const serializedValue = value.get("value")
            const deserializeFunc = deserializeFunctions.get(value.get("serializationKey"), (value) => value)

            return filterObj.set(
                key,
                fromJS({ hasValue: value.get("hasValue"), filterKey: value.get("filterKey") }).set(
                    "value",
                    deserializeFunc(serializedValue),
                ),
            )
        }, Map())
    },
)((state, props) => props.uniqueFlvId)

export const selectCurrentDeserializedFiltersWithId = createCachedSelector(selectAllDeserializedFilters, (filters) =>
    filters.filter((value) => value.get("hasValue")),
)((state, props) => props.uniqueFlvId)

export const selectCurrentDeserializedFilters = createCachedSelector(
    selectCurrentDeserializedFiltersWithId,
    (filters) =>
        filters.reduce((filterObj, value, _) => {
            if (value.get("filterKey")?.includes("__gt")) {
                return filterObj.set(
                    value.get("filterKey"),
                    value.get("value") instanceof moment ? value.get("value").endOf("day") : value.get("value"),
                )
            } else if (value.get("filterKey")?.includes("__lt")) {
                return filterObj.set(
                    value.get("filterKey"),
                    value.get("value") instanceof moment ? value.get("value").startOf("day") : value.get("value"),
                )
            }

            return filterObj.set(value.get("filterKey"), value.get("value"))
        }, Map()),
)((state, props) => props.uniqueFlvId)

/**
 * Selects whether default filters exist for a particular FLV.
 *
 * @name selectDefaultFilters
 * @function
 * @returns {Map} of default filters for all data types.
 */
export const selectFlvHasDefaultFilters = createCachedSelector(
    [selectSearchFlvSlice],
    (flv) => flv.get("defaultFilters", Map()).size > 0,
)((state, props) => props.uniqueFlvId)

/**
 * Determines if all default filters are active.
 *
 * @name selectAreAllDefaultFiltersActive
 * @function
 * @param {string} uniqueFlvId - String representing the FLV.
 * @returns {boolean} true/false, whether all default filters are active.
 */
export const selectAreAllDefaultFiltersActive = createCachedSelector(
    [selectDefaultFilters, selectAllFilters],
    (defaultFilters, filters) => {
        if (!defaultFilters.size) {
            return false
        }

        return defaultFilters.every((filterInfo, key) => {
            const valuesEqual = filters.getIn([key, "value"]) === filterInfo.get("value")
            // necessary check for multifilters
            const filterKeysEqual = filters.getIn([key, "filterKey"]) === filterInfo.get("filterKey")
            return valuesEqual && filterKeysEqual
        })
    },
)((state, props) => props.uniqueFlvId)

/**
 * Determines if active filters are non-default. This includes filters whose
 * keys are in the defaultSearchFilter object, but differently valued (e.g.,
 * defaultFilters: {woo: { value: 'hoo'}} with filters: {woo: {value: 'wah'}} would return
 * true, because 'woo' is not the default value of 'hoo'.)
 *
 * Protected filters are considered inactive for the purposes of this, because
 * otherwise the "clear filters" button will always be showing (beacause it will be unable to
 * clear the protected filters.)
 *
 * @name selectAreNonDefaultFiltersActive
 * @function
 * @param {string} uniqueFlvId - String representing the FLV.
 * @returns {boolean} true/false, whether any non-default filters are active
 */
export const selectAreNonDefaultFiltersActive = createCachedSelector(
    [selectDefaultFilters, selectCurrentFiltersWithId, selectProtectedFilters],
    (defaultFilters, currentFilters, protectedFilters) => {
        return currentFilters
            .filter((val, key) => !protectedFilters.includes(key))
            .some((filter, filterId) => defaultFilters.getIn([filterId, "value"]) !== filter.get("value"))
    },
)((state, props) => props.uniqueFlvId)

export const selectPermanentAndDefaultFilters = createCachedSelector(selectSearchFlvSlice, (flvSlice) => {
    // we need to take all these filters into consideration when saving the filters,
    // since default / permanent doesn't exist on the backend!
    const defaultFilters = fromJS(DjangIO.modelAtPath(flvSlice.get("resourcePath")).default_filters)

    // this is a very specific thing to filter out -- we apply created__lte as a permanent
    // filter on the DocumentFilterableListView in order to eliminate duplicates
    // in the case data is being loaded into our database while DocumentFilterableListView
    // requests more data. But we don't want this to be saved in our search,
    // because then the list would never update!

    // will probably genericize this later. Maybe.

    const permanentFilters = flvSlice
        .get("permanentFilters")
        .filter((value, key) => key !== "created__lte")
        .filter((value, key) => key && key.includes && !key.includes("full_dehydrate"))

    return defaultFilters.merge(permanentFilters)
})((state, props) => props.uniqueFlvId)

export const selectDefaultExcludeFilters = createCachedSelector(selectSearchFlvSlice, (flvSlice) => {
    const defaultExcludeFilters =
        fromJS(DjangIO.modelAtPath(flvSlice.get("resourcePath")).default_exclude_filters) || Map()
    return defaultExcludeFilters
})((state, props) => props.uniqueFlvId)

// This includes permanent filters and the model's default filters (not to
// be confused with the user's default filters!)

// For use in: any time we make a GET request via DjangIO using the `.filter()`
// method. The .filter() needs STRINGIFIED values of both the permanent filters
// and foreground current filters.
export const selectAllActiveSerializedFilters = createCachedSelector(
    [selectCurrentFilters, selectPermanentAndDefaultFilters],
    (filters, backgroundFilters) => {
        const stringifiedBackgroundFilters = backgroundFilters.map((value) => stringifyFilterValue(value))
        return stringifiedBackgroundFilters.merge(filters)
    },
)((state, props) => props.uniqueFlvId)

export const selectAllActiveExcludeFilters = createCachedSelector(
    [selectDefaultExcludeFilters],
    (defaultExcludeFilters) => {
        const stringifiedDefaultExcludeFilters = defaultExcludeFilters.map((value) => stringifyFilterValue(value))
        return stringifiedDefaultExcludeFilters
    },
)((state, props) => props.uniqueFlvId)

// This includes permanent filters and the model's default filters (not to
// be confused with the user's default filters!)

// For use in: any time we need to create a SafedSearch, or submit something that's
// within Searchify. This is because we need the filters to be saved in the backend
// as dicts / lists / etc, NOT as a string.
export const selectAllActiveDeserializedFilters = createCachedSelector(
    [selectCurrentDeserializedFilters, selectPermanentAndDefaultFilters],
    (deserializedFilters, backgroundFilters) => backgroundFilters.merge(deserializedFilters),
)((state, props) => props.uniqueFlvId)

export const selectHasNonProtectedCurrentFilters = createCachedSelector(
    [selectCurrentFiltersWithId, selectProtectedFilters],
    (currentFilters, protectedFilters) =>
        currentFilters.filter((value, key) => !protectedFilters.includes(key)).size > 0,
)((state, props) => props.uniqueFlvId)

/**
 * Determines if any filters below the UserPersona "See More" button fold are active.
 *
 * @name selectAreBelowSeeMoreFoldFiltersActive
 * @function
 * @param {string} seeMoreFilterGroups - FilterGroups that the See More button collapses.
 * @returns {boolean} true/false, whether or not seeMoreFilterGroups has any active filters.
 */

export const selectAreBelowSeeMoreFoldFiltersActive = createCachedSelector(
    [selectAllFilters, (state, props) => props.seeMoreFilterGroups],
    (filters, seeMoreFilterGroups) => {
        const allBelowFoldFilterGroupIDs = seeMoreFilterGroups?.map((filterGroup) => {
            return filterGroup.defaultProps.uniqueFilterGroupId
        })

        const hasActiveFilter =
            filters
                .filter((filter) => allBelowFoldFilterGroupIDs?.includes(filter.get("uniqueFilterGroupId")))
                .filter((filter) => filter.get("hasValue", false)).size > 0

        return hasActiveFilter
    },
)((state, props) => `${props.uniqueFlvId},${!!props.seeMoreFilterGroups}`)

/** @type {(state: any, props?: any) => any} */
export const selectAreMobileSearchFiltersOpen = createSelector(selectSearch, (state) =>
    state.getIn(["areMobileSearchFiltersOpen"]),
)

export const selectIsLoading = createCachedSelector(selectAppearanceValues, (appearanceValues) =>
    appearanceValues.get("dataIsLoading", false),
)((state, props) => props.uniqueFlvId)

export const selectIsLoadingMoreData = createCachedSelector(selectAppearanceValues, (appearanceValues) =>
    appearanceValues.get("dataIsLoadingMore", false),
)((state, props) => props.uniqueFlvId)

export const selectLoadingDataError = createCachedSelector(selectAppearanceValues, (appearanceValues) =>
    appearanceValues.get("loadingDataError", undefined),
)((state, props) => props.uniqueFlvId)

export const selectLastLoadedUserUpdate = createCachedSelector(selectSearchFlvSlice, (state) =>
    state.get("lastUpdatedValue"),
)((state, props) => props.uniqueFlvId)

export const selectLastLoadedFilters = createCachedSelector(selectSearchFlvSlice, (state) =>
    state.get("lastLoadedFilters", Map()),
)((state, props) => props.uniqueFlvId)

export const selectHasNewFiltersOnInitialLoad = createCachedSelector(
    [selectCurrentFilters, selectLastLoadedFilters],
    (current, lastLoaded) => !current.equals(lastLoaded),
)((state, props) => props.uniqueFlvId)

/*
 Component Stuff Here
*/

export const selectPermissionedFilterComponents = createCachedSelector(
    (state, props) => props.filters,
    (filters) => getPermissionedSearchItems(filters),
)((state, props) => props.uniqueFlvId)

export const selectPermissionedFilterGroupComponents = createCachedSelector(
    (state, props) => props.filterGroups,
    (filterGroups) => getPermissionedSearchItems(filterGroups),
)((state, props) => props.uniqueFlvId)

export const selectFormattedInlineData = createCachedSelector(
    [(state, props) => props.datum, (state, props) => props.formatSelect, (state, props) => props.isProfile],
    (datum, formatSelect, isProfile) => formatSelect(datum, isProfile),
)((state, props) => `${props.uniqueFlvId}${props.datum.get("id")}${props.isMobile}`)

export const selectSearchTerm = createSelector([selectSearch], (search) =>
    search.getIn(["advancedSearch", "searchTerm"], undefined),
)

/*
 / Action Button logic here
*/

export const selectListViewDisplayValues = (state, props) =>
    selectSearchFlvSlice(state, props).get("listViewDisplay", Map())

export const selectIsDisplayOpen = createCachedSelector(
    [selectListViewDisplayValues],
    (listViewDisplay) => listViewDisplay.get("activeActionButton") != null,
)((state, props) => props.uniqueFlvId)

export const selectActiveActionButton = (state, props) =>
    selectListViewDisplayValues(state, props).get("activeActionButton")

export const selectIsActionButtonActive = createCachedSelector(
    [selectListViewDisplayValues, (state, props) => props.uniqueActionButtonId],
    (listViewDisplay, buttonId) => listViewDisplay.get("activeActionButton") === buttonId,
)((state, props) => props.uniqueFlvId)

export const selectHasMoreDataToLoad = createCachedSelector(
    [selectCurrentPage, selectCurrentCount],
    (currentPage, currentCount) => (currentPage + 1) * TASTYPIE_QUERY_LIMIT < currentCount,
)((state, props) => props.uniqueFlvId)

export const selectUniqueFlvId = createSelector(
    (state, props) => props.uniqueFlvId,
    (uniqueFlvId) => uniqueFlvId,
)

import { connect } from "react-redux"
import { reduxForm } from "redux-form/immutable"
import withImmutablePropsToJS from "with-immutable-props-to-js"

import {
    submitRegistration,
    submitRegistrationSuccess,
    submitRegistrationFail,
} from "QuorumGrassroots/widgets/action-creators"

import RegistrationForm from "QuorumGrassroots/widgets/LoginRegistration/components/RegistrationForm"
import { RegistrationFormConnection } from "QuorumGrassroots/widgets/LoginRegistration/selectors"
import { withRouter } from "QuorumGrassroots/withRouter"

const submit = (values, dispatch, props) => dispatch(submitRegistration(values, props))

const ReduxRegistrationForm = reduxForm({
    form: "registration",
    onSubmit: submit,
    onSubmitSuccess: submitRegistrationSuccess,
    onSubmitFail: submitRegistrationFail,
    touchOnChange: true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(withRouter(RegistrationForm))

const ConnectedForm = connect(RegistrationFormConnection, {})(withImmutablePropsToJS(ReduxRegistrationForm))

export default ConnectedForm

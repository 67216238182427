import { createSelector } from "reselect"
import { List, fromJS } from "immutable"

import {
    selectImmutableCampaignMessages,
    createCampaignWithMessagesSelector,
    selectActiveSelectId,
} from "QuorumGrassroots/campaign-forms/selectors/general-selectors"
import { selectParsedCampaignPoints } from "QuorumGrassroots/framework/selectors"

export const selectCallInitialValues = createSelector(selectImmutableCampaignMessages, (messages) =>
    messages.map((message) =>
        fromJS({
            script: message.getIn(["edits", "editedBodies"], List()).get(message.getIn(["edits", "currentBodyIdx"])),
            target: message.get("target"),
        }),
    ),
)

// the grassroots action ID will be set in CALL_OFFICE_SUCCESS (post request to make
// a grassroots supporter action, we get the ID of the action in the request response)
export const selectActiveGrassrootsActionId = createSelector(
    [selectImmutableCampaignMessages, selectActiveSelectId],
    (messages, id) => messages.getIn([id, "edits", "grassrootsActionId"]),
)

export const selectShouldShowSubmitButton = createSelector(selectActiveGrassrootsActionId, (action) => Boolean(action))

export const selectCallCampaignPoints = createSelector(
    selectParsedCampaignPoints,
    (points) => points[DjangIO.app.grassroots.campaign.types.CampaignType.call.value] || 0,
)

const additionalSelectors = {
    initialValues: selectCallInitialValues,
    activeGrassrootsActionId: selectActiveGrassrootsActionId,
    shouldShowSubmitButton: selectShouldShowSubmitButton,
    callCampaignPoints: selectCallCampaignPoints,
}

export const callCampaignSelectors = createCampaignWithMessagesSelector(additionalSelectors)

import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { styleInMobileScreenSize } from "QuorumGrassroots/styled-components/helperFunctions"
import styled from "styled-components"

export const StyledFooterWrapper = styleWithOrgDesign(styled.div`
    display: flex;
    justify-content: space-between;
    background-color: ${(props) => props.organizationDesign.light_grey};
    width: 100%;
    border-top: 1px solid ${(props) => props.organizationDesign.lighter_primary};
    padding-top: 15px;
    padding-left: 25px;
    padding-right: 25px;
    ${(props) =>
        props.isTiny &&
        `
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;
    `}
`)

export const StyledFooterLink = styled.a`
    align-items: center;
    display: flex;
    font-size: 15px;
    text-align: right;
`

export const FooterWrapper = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100%;

    ${(props) =>
        props.isCampaignPage &&
        `
            position: initial;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
    `}
`

export const DisclaimerFooter = styleWithOrgDesign(styled.div`
    margin: 0px 25%;
    text-align: center;
    color: #666666;
    font-size: 15px;
    border-bottom: 1px solid #666666;

    ${(props) =>
        props.isCampaignPage &&
        `
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
    `}

    ${styleInMobileScreenSize("margin: 0px 20px;")}
`)

export const InvisibleFooterSpacing = styled.div`
    ${(props) =>
        props.isCampaignPage &&
        `
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    `}

    visibility: hidden;
`

import { connect } from "react-redux"

import GotvForm from "QuorumGrassroots/campaign-forms/components/GotvForm"

import { postGrassrootsSupporterAction, completeCampaign } from "QuorumGrassroots/campaign-forms/action-creators"
import { gotvFormConnection } from "QuorumGrassroots/campaign-forms/selectors/gotv-selectors"

const actions = { postGrassrootsSupporterAction, completeCampaign }

export default connect(gotvFormConnection, actions)(GotvForm)

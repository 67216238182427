import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { withNamespaces } from "react-i18next"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { joinPaths, simpleReplacePlaceholders } from "QuorumGrassroots/helperFunctions"

import { StyledLinkColumnWidget } from "QuorumGrassroots/widgets/styled-components/StyledWidget"
import {
    StyledInlineTable,
    StyledInlineTableRow,
    StyledInlineIconTableData,
    StyledInlineIcon,
    StyledInlineTitle,
    StyledInlineTitleText,
    StyledInlinePoints,
    StyledInlineDescription,
    StyledInlineCallToAction,
} from "QuorumGrassroots/widgets/styled-components/inline/style"

import { selectUserdata, selectGamificationPointsOfCampaign } from "QuorumGrassroots/framework/selectors"

const CampaignInline = (props) => {
    // TODO: This derived data needs to live in a selector!

    // Instantiate with default, loading values
    let url = "/"
    let campaignIcon = "fa-spinner"
    let campaignHeader = props.t("loading")
    let campaignDescription = props.t("loading")
    let campaignVerb = props.t("loading")
    let campaignPoints = 0

    if (!props.loading) {
        const slug = props.campaign.vanity_url_slug || props.campaign.id
        const campaignType = DjangIO.app.grassroots.campaign.types.CampaignType.by_value(props.campaign.campaign_type)

        url = joinPaths(DjangIO.app.grassroots.types.GrassrootsWidgetType.campaign.widget_url, slug)
        campaignIcon = campaignType.icon
        campaignHeader = props.campaign.name
        campaignDescription = simpleReplacePlaceholders(props.campaign.short_description, props.userdata)
        campaignPoints = props.gamificationPoints

        campaignVerb =
            (props.campaign.campaign_type === DjangIO.app.grassroots.campaign.types.CampaignType.petition.value &&
                props.campaign.custom_call_to_action_button_text) ||
            props.t(campaignType.i18n_verb_key)
    }

    return (
        <StyledLinkColumnWidget
            className={classNames("campaign-inline", props.className)}
            to={url}
            state={{ ...props.campaign }}
            inline
            data-cy={`campaign-inline-${props.loading ? "loading" : props.campaign.id}`}
            data-test="inline"
        >
            <StyledInlineTable className="inline-table" isFirst={props.isFirst} isLast={props.isLast}>
                <tbody>
                    <StyledInlineTableRow className="inline-table-row">
                        {
                            // Coment out icons for now. May conditionally render if flagged on the campaign/action center
                            // <StyledInlineIconTableData>
                            //     <StyledInlineIcon
                            //         className={`fa ${campaignIcon}`}
                            //     />
                            // </StyledInlineIconTableData>
                        }
                        <td>
                            <StyledInlineTitle className="inline-title" description={campaignDescription}>
                                <StyledInlineTitleText className="inline-title-text">
                                    {campaignHeader}
                                </StyledInlineTitleText>
                                {Boolean(campaignPoints) && (
                                    <StyledInlinePoints className="inline-points">
                                        {`${campaignPoints} ${
                                            parseInt(campaignPoints) === 1
                                                ? props.t("gamification.point.label")
                                                : props.t("gamification.points.label")
                                        }`}
                                    </StyledInlinePoints>
                                )}
                            </StyledInlineTitle>
                            <StyledInlineDescription className="inline-description">
                                {campaignDescription}
                            </StyledInlineDescription>
                        </td>
                        <StyledInlineIconTableData className="inline-icon-table-data">
                            <StyledInlineCallToAction className="inline-cta">{campaignVerb}</StyledInlineCallToAction>
                        </StyledInlineIconTableData>
                    </StyledInlineTableRow>
                </tbody>
            </StyledInlineTable>
        </StyledLinkColumnWidget>
    )
}

CampaignInline.defaultProps = {
    loading: false,
}

CampaignInline.propTypes = {
    t: PropTypes.func.isRequired,
    organizationDesign: PropTypes.object,
    campaign: PropTypes.shape({
        id: PropTypes.number.isRequired,
        campaign_type: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        short_description: PropTypes.string,
        vanity_url_slug: PropTypes.string,
    }),
    loading: PropTypes.bool,
    userdata: PropTypes.object.isRequired,
    gamificationPoints: PropTypes.number.isRequired,
}

const mapStateToProps = createStructuredSelector({
    userdata: selectUserdata,
    gamificationPoints: selectGamificationPointsOfCampaign,
})

export default withNamespaces()(connect(mapStateToProps, null)(CampaignInline))

import styled from "styled-components"
import { StyledAlert } from "QuorumGrassroots/styled-components/components/StyledAlert"
import { ReduxFormMarginSize } from "app/static/frontend/design-constants"

export const StyledUpdateInfoAlert = styled(StyledAlert)`
    margin-bottom: 0px;
    margin-top: 10px;
`

export const StyledContainer = styled.div`
    & label.control-label {
        font-size: 16px;
        margin-top: ${ReduxFormMarginSize};
    }

    & .form-input-field {
        margin-bottom: 10px;
        margin-top: ${ReduxFormMarginSize};

        label.control-label {
            margin-bottom: 0;
            margin-top: 0;
        }
    }
`

import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"

import { gamificationTierModalProps } from "QuorumGrassroots/framework/selectors"
import { hideGamificationTierModal } from "QuorumGrassroots/framework/action-creators"

import GamificationTierModal from "QuorumGrassroots/framework/components/GamificationTierModal"

export default withNamespaces()(
    connect(gamificationTierModalProps, { hideGamificationTierModal })(GamificationTierModal),
)

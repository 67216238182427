import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const StyledIcon = styled.div`
    line-height: 17px;
    font-size: 16px;
    margin-right: 10px;

    ${({isActive, iconColor}) => {
        if (isActive) {
            return css`
                ${rules.BoldWeight};
                color: ${iconColor};
            `
        } else {
            return css`
                color: ${iconColor};
            `
        }
    }}

    ${({isSquare}) => {
        // TODO: standardize interactive states in rules.js
        if (isSquare) {
            return css`
                font-size: 16px;
                // square icon has more top/bottom padding vs. radio btn icon
                padding: 0 1px;

                &:active, &:focus, &:focus-within {
                    border-radius: 2px;
                    box-shadow: 0 0 0 2px ${colors.QuorumBlue};
                    outline: 0;
                    padding: 0 1px;
                }
            `
        } else {
            return css`
                &:active, &:focus, &:focus-within {
                    border-radius: 11px;
                    box-shadow: 0 0 0 2px ${colors.QuorumBlue};
                    outline: 0;
                }
            `
        }
    }}
`

const marginFromSize = {
    small: "2px 0 2px 2px",
    medium: "5px 0 5px 2px",
    large: "6px 0 6px 2px",
}

export const SelectableItem = styled.div`
    ${rules.RegularWeight};
    align-items: center;
    cursor: pointer;
    display: flex;
    line-height: ${rules.GiantButtonFontSize};
    margin: ${({ size }) => marginFromSize[size]};
    padding: 2px 5px 2px 2px;
    overflow: hidden;
    text-overflow: ellipsis;

    ${({ suppressBackground }) => suppressBackground ? "" :
        `
        background-color: ${colors.White};
        &:hover {
            // background-color: ${colors.DarkerPorcelain};
        }
        `
    }

    &:hover > ${StyledIcon} {
        color: ${colors.QuorumBlue};
    }
`

export const Label = styled.div`
    ${( { size } )  => rules.text[size]};
    color: ${colors.gray7};
    margin-left: 5px;

    ${({isActive}) => {
        if (isActive) {
            return css`
                ${rules.BoldWeight};
                font-weight: 500;
            `
        }
    }}

    &::selection {
        background: ${colors.QuorumBlue};
        color: ${colors.White};
    }
`

export const TooltipIcon = styled.div`
    cursor: help;
    padding-left: 5px;

    ${({tooltipElement}) => {
        if (!tooltipElement) {
            return css`
                display: none;
            `
        }
    }}
`

import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { styleInDesktopScreenSize, styleAppBackground } from "QuorumGrassroots/styled-components/helperFunctions"
import styled from "styled-components"

const StyledApp = styleWithOrgDesign(styled.div`
    height: 100%;
    position: relative;
    ${(props) => !props.frameless && "min-height: 100vh;"}
    ${(props) => styleAppBackground(props)}

    ${(props) =>
        props.organizationDesign?.font_family
            ? `font-family: ${props.organizationDesign.font_family};`
            : `font-family: inherit;`}
    ${(props) => props.organizationDesign?.font_link && `${props.organizationDesign.font_link};`}
`)

export const StyledMainContainer = styled.div`
    ${(props) =>
        props.isCampaignPage &&
        `
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: 100vh;
    ${styleInDesktopScreenSize(`
        gap: 2.5rem;
    `)}
    `}
`

export const StyledMain = styled.main`
    ${(props) =>
        props.isCampaignPage &&
        `
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-grow: 1;
    `}
`

export const StyledRedirectMessage = styled.div`
    display: flex;
    font-size: ${(props) => props.fontSize || 16}px;
    align-items: center;
    justify-content: center;
    padding: 5px;
`

export const StyledRedirectButton = styled(StyledButton)`
    width: auto;
    margin-left: 15px;
`

export const StyledSkipToMainContentLink = styled.a`
    &:focus {
        position: inherit;
    }
`

export default StyledApp

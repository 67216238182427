const SearchifyActionTypes = {
    UPDATE_SEARCHIFY_PROPERTY: "UPDATE_SEARCHIFY_PROPERTY",

    IS_COUNT_LOADING: "IS_COUNT_LOADING",

    SELECT_SUBMIT_SEARCHIFY: "SELECT_SUBMIT_SEARCHIFY",

    INITIALIZE_SEARCHIFY_SLICE: "INITIALIZE_SEARCHIFY_SLICE",
    PUSH_SEARCHIFY_LABEL: "PUSH_SEARCHIFY_LABEL",
    SEARCHIFY_START: "NEW_SEARCHIFY_START",
    SEARCHIFY_SUBMIT: "SEARCHIFY_SUBMIT",
    SEARCHIFY_CLEAR: "SEARCHIFY_CLEAR",
    RESET_ALL_SEARCHIFY: "RESET_ALL_SEARCHIFY",

    ADD_CUSTOM_SUBMIT_SEARCHIFY: "ADD_CUSTOM_SUBMIT_SEARCHIFY",
    ADD_SIMPLE_SUBMIT_SEARCHIFY: "ADD_SIMPLE_SUBMIT_SEARCHIFY",
    STORE_SEARCHIFY_SUBMIT_PARAMS: "STORE_SEARCHIFY_SUBMIT_PARAMS",
    STORE_SEARCHIFY_ROUTE_DETAILS: "STORE_SEARCHIFY_ROUTE_DETAILS",
    STORE_SEARCHIFY_ORIGIN_DETAILS: "STORE_SEARCHIFY_ORIGIN_DETAILS",

    STORE_SEARCHIFY_FLV_INITIAL_FILTER: "STORE_SEARCHIFY_FLV_INITIAL_FILTER",
    STORE_PERMANENT_FILTER: "ADD_PERMANENT_FILTER",
    REMOVE_PERMANENT_FILTERS: "REMOVE_PERMANENT_FILTERS",

    STORE_SEARCHIFY_SELECTED_IDS_FROM_QUERY: "STORE_SEARCHIFY_SELECTED_IDS_FROM_QUERY",
    SEARCHIFY_ADD_IDS: "SEARCHIFY_ADD_IDS",
    SEARCHIFY_DELETE_ID: "SEARCHIFY_DELETE_ID",
    CLEAR_SEARCHIFY_SELECTIONS: "CLEAR_SEARCHIFY_SELECTIONS",
    SEARCHIFY_CLEAR_FILTERS: "SEARCHIFY_CLEAR_FILTERS",
    SEARCHIFY_APPLY_FILTER: "SEARCHIFY_APPLY_FILTER",
    SEARCHIFY_INITIAL_FILTERS: "SEARCHIFY_INITIAL_FILTERS",
    SEARCHIFY_CAN_ADD_AND_DELETE_IDS_OVERLAY: "SEARCHIFY_CAN_ADD_AND_DELETE_IDS_OVERLAY",

    SEARCHIFY_INLINE_FILTER_TYPE: "SEARCHIFY_INLINE_FILTER_TYPE",

    TOGGLE_SEARCHIFY_SHOW_CURRENT_SELECTION: "TOGGLE_SEARCHIFY_SHOW_CURRENT_SELECTION",
    STORE_FILTER_SLICE: "STORE_FILTER_SLICE",
    APPLY_FILTER_SLICE: "APPLY_FILTER_SLICE",
    CLEAR_FILTER_SLICE: "CLEAR_FILTER_SLICE",

    UPDATE_SEARCHIFY_COUNT: "UPDATE_SEARCHIFY_COUNT",
    UPDATE_MAX_SEARCHIFY_SELECTIONS: "UPDATE_MAX_SEARCHIFY_SELECTIONS",
    SINGLE_SELECT_SEARCHIFY: "SINGLE_SELECT_SEARCHIFY",
    DISABLE_SELECT_ALL_SEARCHIFY: "DISABLE_SELECT_ALL_SEARCHIFY",
    CURRENT_SEARCHIFY_ITEM_ID: "CURRENT_SEARCHIFY_ITEM_ID",

    SEARCHIFY_HEADER_NAME: "SEARCHIFY_HEADER_NAME",
    SEARCHIFY_SUBMIT_LANGUAGE: "SEARCHIFY_SUBMIT_LANGUAGE",

    NEED_SEARCHIFY_COUNT: "NEED_SEARCHIFY_COUNT",
    LOADING_SEARCHIFY_COUNT_START: "LOADING_SEARCHIFY_COUNT_START",

    SEARCHIFY_BACK_ONE_LAYER: "SEARCHIFY_BACK_ONE_LAYER",

    CLEAR_DEFAULT_SEARCH_FILTERS: "CLEAR_DEFAULT_SEARCH_FILTERS",
    LOADED_DEFAULT_SEARCH_FILTERS: "LOADED_DEFAULT_SEARCH_FILTERS",

    POST_ASSIGNMENTS_SEARCHIFY: "POST_ASSIGNMENTS_SEARCHIFY",
    POST_LIST_ITEMS_SEARCHIFY: "POST_LIST_ITEMS_SEARCHIFY",

    OPEN_SEARCHIFY_MODAL: "OPEN_SEARCHIFY_MODAL",
    CLOSE_SEARCHIFY_MODAL: "CLOSE_SEARCHIFY_MODAL",
}

export default SearchifyActionTypes

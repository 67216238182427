import styled from "styled-components"
import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const TooltipPositioner = styled.div`
    & > :first-child {
        left: 50%;
        visibility: hidden;
        opacity: 0;
        transition-duration: ${rules.TransitionShort}
        transform: translate(-50%, 0);
    }
`

export const NumberWidget = styled.div`
    align-items: center;
    color: ${colors.QuorumGrey4};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;

    &:hover {
        ${TooltipPositioner} {
            & > :first-child {
                visibility: visible;
                opacity: 100%;
                transition-duration: ${rules.TransitionShort}
            }
        }
    }
`

export const Number = styled.text`
    ${rules.GiantTitle}
    font-size: ${({ fontSize }) => fontSize}%;
    padding: 5px;
    cursor: ${({ onClick }) => onClick ? 'pointer' : 'auto'};
`
export const Label = styled.text`
    ${rules.SmallTitle}
    font-size: ${({ fontSize }) => fontSize}%;
`

export const LabelWrapper = styled.g`
    padding-top: 20px;
`

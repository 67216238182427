import React from "react"
import { Col } from "react-bootstrap"

import {
    StyledMemberGroupRow,
    StyledMemberRoleType,
    StyledOfficialThumbnailWrapper,
    StyledMemberLink,
    StyledOfficialThumbnail,
    StyledMemberName,
} from "QuorumGrassroots/widgets/OfficialList/components/style"
import { generateStaticUrl } from "imports/desktopHelperFunctions"

const OfficialsCardView = ({ officialList }) => {
    return (
        <StyledMemberGroupRow className="official-list-widget">
            {officialList.map((official) => (
                <Col md={4} sm={6} xs={12}>
                    <StyledMemberLink
                        to={`/official/${official.id}/`}
                        href={`/official/${official.id}/`}
                        data-cy={official.name_and_title}
                    >
                        <StyledOfficialThumbnailWrapper>
                            <StyledOfficialThumbnail
                                md={4}
                                src={generateStaticUrl(official.image_url)}
                                party={official.party}
                            />
                            <StyledMemberName>{official.name_and_title}</StyledMemberName>
                            {official.most_recent_role_label && (
                                <StyledMemberRoleType>{official.most_recent_role_label}</StyledMemberRoleType>
                            )}
                        </StyledOfficialThumbnailWrapper>
                    </StyledMemberLink>
                </Col>
            ))}
        </StyledMemberGroupRow>
    )
}

export default OfficialsCardView

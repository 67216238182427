import React from "react"
import PropTypes from "prop-types"

import Icon from "Components/Atoms/Icon"

import * as S from "Components/Molecules/Checkbox/style"
import * as colors from "Components/colors"

export const renderIcon = (variation, isChecked) => {
    switch (variation) {
        case "radioCheckbox":
            return (
                <Icon
                    icon={isChecked ? "dot-circle" : "circle"}
                    iconFamily={isChecked ? "far" : "far"}
                    color={isChecked ? colors.violet5 : colors.SilverChalice}
                />
            )
        default:
            return (
                <Icon
                    icon={isChecked ? "check-square" : "square"}
                    iconFamily={isChecked ? "fad" : "fal"}
                    color={isChecked ? colors.violet5 : colors.SilverChalice}
                />
            )
    }
}

const Checkbox = ({
    dataCy,
    disabled,
    errorMessage,
    hideIndividualErrorMessage,
    icon,
    iconFamily,
    isChecked,
    onChange,
    text,
    tooltipElement,
    variation,
    warningMessage,
}) => (
    <S.Wrapper data-auto-cy="MoleculeCheckbox">
        <S.CheckboxLabel data-cy={dataCy}>
            <S.CheckboxIcon
                hasError={Boolean(errorMessage)}
                hasWarning={Boolean(warningMessage)}
                disabled={disabled}
            >
                {renderIcon(variation, isChecked)}
            </S.CheckboxIcon>
            {/* optional secondary icon */}
            <Icon
                icon={icon}
                iconFamily={iconFamily || (isChecked ? "fas" : "far")}
                color={disabled ? colors.SilverChalice : colors.violet5}
            />
            <S.Text
                disabled={disabled}
                isChecked={isChecked}
            >
                {text}
            </S.Text>
            <S.Checkbox
                aria-disabled={disabled}
                checked={isChecked}
                value={isChecked}
                onChange={e => !disabled && onChange(e)}
            />
            {tooltipElement && tooltipElement}
        </S.CheckboxLabel>
        { !hideIndividualErrorMessage && (
            (errorMessage || warningMessage) && (
                <S.Error
                    hasError={Boolean(errorMessage)}
                    hasWarning={Boolean(warningMessage)}
                >
                    {
                        (errorMessage && <Icon icon="exclamation-triangle" />)
                        || (warningMessage && <Icon icon="exclamation-square" />)
                    }
                    {errorMessage || warningMessage}
                </S.Error>
            )
        )}
    </S.Wrapper>
)

Checkbox.defaultProps = {
    variation: "checkbox"
}

Checkbox.propTypes = {
    dataCy: PropTypes.string,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
    // option to hide individual messages when using grouped error handling
    hideIndividualErrorMessage: PropTypes.bool,
    icon: PropTypes.string,
    // optional iconFamily for secondary icon
    iconFamily: PropTypes.string,
    isChecked: PropTypes.bool,
    onChange: PropTypes.func,
    text: PropTypes.string.isRequired,
    // passing in actual tooltip element from main app
    tooltipElement: PropTypes.element,
    // radioCheckbox renders a radio button icon that is a input of type
    // "checkbox" so individual inputs are deselectable (i.e. NOT type "radio")
    variation: PropTypes.oneOf(["checkbox", "radioCheckbox"]),
    warningMessage: PropTypes.string,
}

export default Checkbox

import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { initializeWidgetSlice, loadWidgetContent, destroyWidgetSlice } from "QuorumGrassroots/widgets/action-creators"

import {
    selectAugmentedWidgetContent,
    selectHasWidgetContent,
    selectHasWidgetContentLoaded,
    selectIsWidgetContentLoading,
    selectWidgetRegistered,
    selectWidgetSubmitted,
    selectWidgetLoadedTimestamp,
} from "QuorumGrassroots/widgets/selectors"

/********************************************************************
 * If your widget needs to register itself with the store,
 * this is higher order component to use! It will also pass the
 * widget relevant props, such as `loadWidgetContent` and `content`
 * to the widget.
 *
 * If you want to pass additional connected items, you can do so!
 * This is so we only need to call connect once.
 ********************************************************************/

export class InitializerWrapper extends Component {
    static propTypes = {
        hasLoaded: PropTypes.bool.isRequired,
        hasLoadedContent: PropTypes.bool.isRequired,
        content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        isRegistered: PropTypes.bool.isRequired,
        initializeWidgetSlice: PropTypes.func.isRequired,
        widget: PropTypes.func.isRequired,
    }

    componentDidMount() {
        if (!this.props.isRegistered) {
            this.props.initializeWidgetSlice(this.props.uniqueWidgetId)
        }
    }

    componentDidUpdate(oldProps) {
        const routeChanged = oldProps.uniqueWidgetId !== this.props.uniqueWidgetId

        // If the widget we're trying to initialize becomes unregistered, which will happen if
        // the widget changes or its content gets reset, re-initialize the widget
        if (!this.props.isRegistered && (routeChanged || oldProps.isRegistered)) {
            this.props.initializeWidgetSlice(this.props.uniqueWidgetId)
        }
    }

    render() {
        const Widget = this.props.widget

        // do not render until the slice of the store is registered!
        // you will be in nested, children-first componentDidMount hell if you do!
        return this.props.isRegistered && <Widget {...this.props} />
    }
}

/**
 * Given a widget, returns a component that will register the widget with the store
 * on mount. Will also give it access to certain selectors that might be necessary.
 * @param  {Function} Widget The React component to render.
 */
export const initializer = (Widget) => {
    const mapStateToProps = (state, props) => ({
        content: selectAugmentedWidgetContent(state, props),
        hasLoaded: selectHasWidgetContentLoaded(state, props),
        hasLoadedContent: selectHasWidgetContent(state, props),
        isLoadingContent: selectIsWidgetContentLoading(state, props),
        isRegistered: selectWidgetRegistered(state, props),
        timestamp: selectWidgetLoadedTimestamp(state, props),
    })

    const actions = {
        initializeWidgetSlice,
        loadWidgetContent,
        destroyWidgetSlice,
    }

    const ConnectedInitialize = connect(mapStateToProps, actions)(InitializerWrapper)

    const wrapper = (props) => <ConnectedInitialize {...props} widget={Widget} />

    return wrapper
}

export default initializer

import i18n from "i18n"

export const swalConfigs = {
    loginError: {
        icon: "error",
        title: i18n.t("error.login.unsuccessful"),
        text: i18n.t("error.login.username_password_invalid"),
    },
    registrationError: {
        icon: "error",
        title: i18n.t("error.registration.unsuccessful"),
        text: i18n.t("error.registration.failed"),
    },
    updateInfoError: {
        icon: "error",
        title: i18n.t("error.update_info.unsuccessful"),
        text: i18n.t("error.update_info.failed"),
    },
    loadContentError: {
        icon: "error",
        title: i18n.t("error.data.loading"),
        text: i18n.t("error.data.loading_content"),
    },
    postGrassrootsActionError: {
        icon: "error",
        title: i18n.t("error.data.submitting"),
        text: i18n.t("error.data.submitting_content"),
    },
    recoverAccountSuccess: {
        icon: "success",
        title: i18n.t("registration.password.forgot.success.title"),
        text: i18n.t("registration.password.forgot.success.text"),
    },
    resetPasswordSuccess: {
        icon: "success",
        title: i18n.t("registration.password.reset.success.title"),
        text: i18n.t("registration.password.reset.success.text"),
    },
}

export const donationSwalConfigs = {
    // TODO "ff_pac": i18n for these swals
    donationSuccess: {
        icon: "success",
        title: "Donation Successful",
        text: "Thank you for your donation.",
    },
    internalPaymentError: {
        icon: "error",
        title: "Payment failed",
        text: "Something went wrong.",
    },
    stripeProcessingError: {
        icon: "error",
        title: "Payment failed",
        text: "Something went wrong with processing the payment.",
    },
    stripeValidationError: {
        icon: "warning",
        title: "Problem with the Payment",
        text: "Something went wrong with validating the payment.",
    },
}

export const getBackendValidationSwalConfig = ({ error, defaultConfig, formValues }) => {
    let swalConfig = defaultConfig

    if (error.response && error.response.data && typeof error.response.data === "object") {
        swalConfig = {
            icon: "error",
            title: i18n.t("error.form.input"),
            text: Object.keys(error.response.data)
                .map((key) => i18n.t(`error.form.${key}`, formValues))
                .join("\n"),
        }
    }

    return swalConfig
}

// https://projects.invisionapp.com/d/main/#/console/18861190/444895171/inspect
// https://projects.invisionapp.com/share/H4102MKAXEYZ#/screens/444515231
// https://projects.invisionapp.com/d/main/#/console/21044500/456272284/preview

import React from "react"
import PropTypes from "prop-types"

import * as colors from "Components/colors"
import * as S from "Components/Layouts/TopNav/style"

import Icon from "Components/Atoms/Icon"

import Button from "Components/Molecules/Button"
import DropdownButton from "Components/Molecules/DropdownButton"
import SearchTextarea from "Components/Molecules/SearchTextarea"
import Tooltip from "Components/Molecules/Tooltip"

import { NavCenter } from "Components/Layouts/TopNav/NavCenter"
import { NavLeft } from "Components/Layouts/TopNav/NavLeft"
import { NavRight } from "Components/Layouts/TopNav/NavRight"

const getRegionIconColor = ({ popdownIsOpen, isInRegion, loggedInAsUser }) => {
    if (isInRegion) {
        return colors.White
    }

    if (popdownIsOpen) {
        return colors.gray9
    }

    if (loggedInAsUser) {
        return colors.violet9
    }

    return colors.White
}

const RegionButton = ({ currentRegionName, isInRegion, loggedInAsUser, popdownIsOpen, regionButtonOnClick }) => (
    <S.RegionButton
        type="button"
        onClick={regionButtonOnClick}
        isInRegion={isInRegion}
        popdownIsOpen={popdownIsOpen}
        loggedInAsUser={loggedInAsUser}
    >
        <Icon
            icon="globe"
            iconFamily="far"
            style={{ fontSize: ".875rem"}}
            color={getRegionIconColor({ popdownIsOpen, isInRegion, loggedInAsUser })}
        />
        <div className="region-button--content-wrapper">
            <div className="region-button--label">{popdownIsOpen ? "Searching within" : "My Region"}</div>
            <div className="region-button--name">{currentRegionName}</div>
        </div>
    </S.RegionButton>
)


const CloseButton = ({ isSearchify, isPopdownOpen, onClick, isMobile }) => {
    if (isSearchify) {
        return (
            <Button
                dataCy="LayoutTopNavCloseButton"
                onClick={onClick}
                rightIcon="times"
                rightIconFamily="fal"
                rightIconSize="20px"
                rightIconStyle={{
                    "marginTop": "2px",
                    "width": "15px",
                }}
                text={!isMobile && "Close"}
                type="tertiary"
            />
        )
    }

    if (!isPopdownOpen) {
        return null
    }

    return (
        <Button
            dataCy="LayoutTopNavCloseButton"
            onClick={onClick}
            rightIcon="times"
            rightIconFamily="far"
            rightIconStyle={{ "font-size": "1rem" }}
            type= "ghost"
        />
    )
}

const TopNav = ({
    children,

    className,
    dataCy,

    // TopNav
    aiSearch,
    aiSearchButtonIsVisible,
    aiSearchToggle,
    booleanButtonOnClick,
    clearButtonOnClick,
    closeButtonOnClick,
    currentRegionName,
    favoriteButtonDisabled,
    favoriteButtonHidden,
    favoriteButtonOnClick,
    favoriteButtonTooltip,
    filtersSelected,
    // app/static/frontend/middleware/SegueLink.jsx SegueLink
    homeButtonOnClick,
    homeButtonSegueUrl,
    isInRegion,
    isInSearch,
    isMobile,
    isSearchify,
    loggedInAsUser,
    regionButtonOnClick,
    regionButtonSegueUrl,
    searchButtonOnClick,
    searchDisabled,
    searchifyTitle,
    searchInputDefaultValue,
    searchInputOnChange,
    searchInputOnClick,
    searchInputOnInput,
    searchInputOnKeyDown,
    searchInputPlaceholder,
    searchInputValue,
    searchTextareaRef,
    SegueLinkComponent,

    // dropdown buttons on the right
    accountOptions,
    accountText,
    helpOptions,
    quickActionsOptions,

    // PopdownOverlay
    popdown,
    popdownIsOpen,
}) => (
    <S.TopNavPopdown
        data-auto-cy="LayoutTopNav"
        isSearchify={isSearchify}
        popdownIsOpen={popdownIsOpen}
    >
        <S.TopNav
            // allow the TopNav div style to be interpolated inside of the Popdown style
            // https://styled-components.com/docs/advanced#caveat
            className={className}
            data-cy={dataCy}
            isSearchify={isSearchify}
            loggedInAsUser={loggedInAsUser}
            popdownIsOpen={popdownIsOpen}
        >
            <NavLeft isFragment={isSearchify}>
                {
                    !isSearchify &&
                        <SegueLinkComponent
                            to={homeButtonSegueUrl}
                        >
                            <S.QuorumHomeButton
                                data-cy="LayoutTopNavQuorumHomeButton"
                                loggedInAsUser={loggedInAsUser}
                                popdownIsOpen={popdownIsOpen}
                            >
                                <Icon
                                    color={
                                        popdownIsOpen
                                            ? colors.Ultraviolet
                                            : loggedInAsUser ? colors.violet9 : colors.White
                                    }
                                    icon="quorum-logo"
                                    iconFamily="q"
                                    onClick={homeButtonOnClick}
                                    size="2x"
                                    style={{ width: "40px", height: "40px" }}
                                />
                            </S.QuorumHomeButton>
                        </SegueLinkComponent>
                }
                {
                    (
                        currentRegionName &&
                        !isSearchify
                    ) && (
                        isInRegion
                            ?         <RegionButton
                            currentRegionName={currentRegionName}
                            isInRegion={isInRegion}
                            loggedInAsUser={loggedInAsUser}
                            popdownIsOpen={popdownIsOpen}
                            regionButtonOnClick={regionButtonOnClick}
                        />
                            : (
                                <SegueLinkComponent
                                    to={regionButtonSegueUrl}
                                    state={{ previousUrl: window.location.pathname }}
                                >
                                    {
                                        <RegionButton
                                            currentRegionName={currentRegionName}
                                            isInRegion={isInRegion}
                                            loggedInAsUser={loggedInAsUser}
                                            popdownIsOpen={popdownIsOpen}
                                            regionButtonOnClick={regionButtonOnClick}
                                        />
                                    }
                                </SegueLinkComponent>
                            )
                    )
                }
            </NavLeft>

            {
                (
                    isSearchify &&
                    searchifyTitle
                ) &&
                    <S.TopNavSearchifyTitle>
                        {searchifyTitle}
                    </S.TopNavSearchifyTitle>
            }

            <NavCenter isFragment={isSearchify}>
                {
                    !isMobile && (
                        <SearchTextarea
                            theme="primary"
                            loggedInAsUser={loggedInAsUser}
                            dataCy={
                                isSearchify
                                    ? "LayoutTopNavSearchBarSearchify"
                                    : "LayoutTopNavSearchBar"
                            }
                            // SearchTextareaContainer
                            isPrimary={popdownIsOpen}

                            // SearchTextarea
                            aiSearch={aiSearch}
                            aiSearchButtonIsVisible={aiSearchButtonIsVisible}
                            aiSearchToggle={aiSearchToggle}
                            isSearchify={isSearchify}
                            searchDisabled={searchDisabled}
                            searchInputDefaultValue={searchInputDefaultValue}
                            searchInputOnChange={searchInputOnChange}
                            searchInputOnClick={searchInputOnClick}
                            searchInputOnInput={searchInputOnInput}
                            searchInputOnKeyDown={searchInputOnKeyDown}
                            searchTextareaRef={searchTextareaRef}
                            searchInputPlaceholder={searchInputPlaceholder}
                            searchInputValue={searchInputValue}

                            // S.SearchTextareaClear
                            clearButtonOnClick={clearButtonOnClick}

                            // Button favorite
                            favoriteButtonDisabled={favoriteButtonDisabled}
                            favoriteButtonHidden={favoriteButtonHidden}
                            favoriteButtonOnClick={favoriteButtonOnClick}
                            favoriteButtonTooltip={favoriteButtonTooltip}
                            filtersSelected={filtersSelected}

                            // Button search
                            searchButtonOnClick={searchButtonOnClick}
                        />
                    )
                }

                <CloseButton
                    isMobile={isMobile}
                    isPopdownOpen={popdownIsOpen}
                    isSearchify={isSearchify}
                    onClick={closeButtonOnClick}
                />
                {/*
                // created based on search design mock
                // TODO: will be enabled after the saved search function is implemented
                {
                    (isInSearch && !popdownIsOpen) &&
                        <Button
                            dataCy="LayoutTopNavSearchStarButton"
                            type="secondary"
                            leftIcon="star"
                            leftIconFamily="far"
                            leftIconStyle={{
                                "fontSize": "20px",
                                "paddingTop": "2px",
                                "paddingRight": "4px",
                            }}
                            style={{
                                "height": "41px",
                                "width": "41px",
                            }}
                        />
                } */}
                {
                    (
                        isInSearch &&
                        !popdownIsOpen &&
                        !isSearchify
                    ) &&
                        <S.TooltipContainer>
                            <S.TooltipPositioner>
                                <Tooltip
                                    isTiny
                                    placement="bottom"
                                    text="Advanced Search Tips & Boolean"
                                />
                            </S.TooltipPositioner>
                            <Button
                                dataCy="LayoutTopNavSearchBooleanButton"
                                type={loggedInAsUser ? "top-nav-as-user" : "top-nav"}
                                leftIcon="boolean"
                                leftIconFamily="q"
                                leftIconStyle={null}
                                onClick={booleanButtonOnClick}
                                style={ { padding: "10px" }}
                            />
                        </S.TooltipContainer>
                }
            </NavCenter>

            {
                (!popdownIsOpen && !isSearchify) &&
                    <NavRight>
                        <DropdownButton
                            text= "New"
                            dataCy="LoggerDropdown"
                            dropdownBoxOffsetVertical="4px"
                            isBoxRightAlign
                            leftIcon="plus"
                            leftIconFamily="far"
                            leftIconSize=".875rem"
                            leftIconStyle={{ width: "16px", height: "16px" }}
                            menuOptions={quickActionsOptions}
                            rightIconHorizontalOffset="7px"
                            rightIconSize="24px"
                            type={loggedInAsUser
                                ? "top-nav-as-user"
                                : "top-nav" }
                            rightMargin="0"
                        />
                        <DropdownButton
                            leftMargin="12px"
                            dataCy="HelpDropdown"
                            dropdownBoxOffsetVertical="4px"
                            isBoxRightAlign
                            leftIcon="question-circle"
                            leftIconFamily="far"
                            leftIconSize="1rem"
                            menuOptions={helpOptions}
                            rightIconSize="24px"
                            type={loggedInAsUser
                                ? "top-nav-round-as-user"
                                : "top-nav-round"
                            }
                        />
                        <DropdownButton
                            dataCy="AccountDropdown"
                            dropdownBoxOffsetVertical="4px"
                            isBoxRightAlign
                            leftIcon="user"
                            leftIconFamily="far"
                            leftIconSize="1rem"
                            menuOptions={accountOptions}
                            rightIconSize="24px"
                            type={loggedInAsUser
                                ? "top-nav-round-as-user"
                                : "top-nav-round"}
                        />
                    </NavRight>
            }
            { children }
        </S.TopNav>
        {
            // Components/Layouts/PopdownOverlay/index.js
            // rendered from app/static/frontend/containers/Frame.jsx
            popdown
        }
    </S.TopNavPopdown>
)

TopNav.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),

    className: PropTypes.string,

    // TopNav
    aiSearch: PropTypes.bool,
    aiSearchButtonIsVisible: PropTypes.bool,
    aiSearchToggle: PropTypes.func,
    clearButtonOnClick: PropTypes.func,
    closeButtonOnClick: PropTypes.func,
    currentRegionIcon: PropTypes.element,
    currentRegionName: PropTypes.string,
    dataCy: PropTypes.string,
    favoriteButtonDisabled: PropTypes.bool,
    favoriteButtonHidden: PropTypes.bool,
    favoriteButtonOnClick: PropTypes.func,
    favoriteButtonTooltip: PropTypes.string,
    filtersSelected: PropTypes.bool,
    homeButtonOnClick: PropTypes.func,
    // app/static/frontend/containers/Frame.jsx SegueLinkComponent={SegueLink}
    // is a function (elementType, i.e. a component class rather than an instance)
    // which invokes React.createElement through the <SegueLink /> jsx syntactic sugar
    homeButtonSegueUrl: PropTypes.string,
    isInRegion: PropTypes.bool,
    isInSearch: PropTypes.bool,
    isSearchify: PropTypes.bool,
    loggedInAsUser: PropTypes.bool,
    quorumHomeButtonOnClick: PropTypes.func,
    regionButtonOnClick: PropTypes.func,
    regionButtonSegueUrl: PropTypes.string,
    searchButtonOnClick: PropTypes.func,
    searchDisabled: PropTypes.bool,
    searchifyTitle: PropTypes.string,
    searchInputDefaultValue: PropTypes.string,
    searchInputOnChange: PropTypes.func,
    searchInputOnClick: PropTypes.func,
    searchInputOnInput: PropTypes.func,
    searchInputOnKeyDown: PropTypes.func,
    searchInputValue: PropTypes.string,
    searchTextareaRef: PropTypes.func,
    SegueLinkComponent: PropTypes.elementType,

    // dropdown buttons on the right
    accountOptions: PropTypes.arrayOf(
        PropTypes.shape({
            dataCy: PropTypes.string,
            href: PropTypes.string,
            icon: PropTypes.string,
            iconFamily: PropTypes.string,
            onClick: PropTypes.func,
            SegueLinkComponent: PropTypes.elementType,
            SegueLinkProps: PropTypes.object,
            text: PropTypes.string,
        })
    ),
    accountText: PropTypes.string,
    helpOptions: PropTypes.arrayOf(
        PropTypes.shape({
            dataCy: PropTypes.string,
            href: PropTypes.string,
            icon: PropTypes.string,
            iconFamily: PropTypes.string,
            onClick: PropTypes.func,
            SegueLinkComponent: PropTypes.elementType,
            SegueLinkProps: PropTypes.object,
            text: PropTypes.string,
        })
    ),
    quickActionsOptions: PropTypes.arrayOf(
        PropTypes.shape({
            dataCy: PropTypes.string,
            href: PropTypes.string,
            icon: PropTypes.string,
            iconFamily: PropTypes.string,
            onClick: PropTypes.func,
            SegueLinkComponent: PropTypes.elementType,
            SegueLinkProps: PropTypes.object,
            text: PropTypes.string,
        })
    ),

    // Popdown
    popdown: PropTypes.element,
    popdownIsOpen: PropTypes.bool,
}

export default TopNav

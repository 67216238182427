import styled from "styled-components"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"

export const StyledSSOButton = styled(StyledButton)`
    font-size: 18px;
    margin-top: 20px;

    i {
        position: relative;
        right: 14px;
    }

    ${(props) => props.isCampaignPage && `font-size: 16px;`}
`

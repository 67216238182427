const actionTypes = {
    SEND_USER_ACTION_START: "SEND_USER_ACTION_START",
    SEND_USER_ACTION_SUCCESS: "SEND_USER_ACTION_SUCCESS",
    SEND_USER_ACTION_FAIL: "SEND_USER_ACTION_FAIL",
}

export default actionTypes;

export const sendActions = {
    start: actionTypes.SEND_USER_ACTION_START,
    success: actionTypes.SEND_USER_ACTION_SUCCESS,
    fail: actionTypes.SEND_USER_ACTION_FAIL,
}

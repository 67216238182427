import React, { Component } from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { StyledSocialButton } from "QuorumGrassroots/widgets/styled-components/styles"
import {
    NewStyledSocialButton,
    StyledBackButton,
    StyledCurrentTierThanksIcon,
    StyledGamificationPointDescription,
    StyledGamificationPointHeading,
    StyledGamificationPointSection,
    StyledGamificationSection,
    StyledGamificationDescription,
    StyledIconWrapper,
    StyledPointsEarnedDisplay,
    StyledSocialButtonText,
    StyledSocialButtonWrapper,
    StyledThankYouText,
    StyledThankWrapper,
} from "QuorumGrassroots/widgets/ReusableComponents/ThanksDisplay/style"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import { StyledHorizontalRule } from "QuorumGrassroots/styled-components/components/StyledHorizontalRule"
import { XTwitterFarFAIcon } from "QuorumGrassroots/campaign-forms/components/MultiAction/XTwitterFarFAIcon"

/********************************************************************
 * This is the thank you component, to display after a user does
 * something gratitude-worthy on the site.
 *
 * The thank you component can display a variety of things. All of
 * these should be customizable:
 *
 * 1. Thank you text.
 * 2. Thank you image.
 * 3. Share on social media (will pop out window).
 * 4. Any other buttons (non-links)
 *
 * FOR WIDGET PAGES ONLY:
 * 5. Buttons that are links (both internal and external links)
 * 6. Re-routing to another campaign.
 *
 * After this thanks unmounts, we'll destroy this slice of the store.
 * This is so that if the user comes back to a campaign they just
 * submitted, they can see the information (and possibly act again).
 *
 * The hierarchy for customizability is as follows:
 * 1. Anything within the `content` of the Widget slice gets precedence.
 *    This chiefly is meant to cater to campaigns.
 * 2. Anything provided within `displayProps`.
 * 3. Default values, which are stored on the action_center_settings.
 *
 * The selectors will take care of this.
 *********************************************************************/

export class ThanksDisplay extends Component {
    componentDidUpdate() {
        const isThanksPage = window.location.pathname.includes("thanks")
        const isMobileView = window.innerWidth <= 768
        const shouldScrollToThanks = isThanksPage && isMobileView
        if (shouldScrollToThanks) {
            document.querySelector('[data-cy="thank-you-text"]').scrollIntoView({ behavior: "smooth" })
        }
    }

    componentWillUnmount() {
        // allows the user to fire multiple actions even if we never unmount/remount
        // QuorumGrassroots/widgets/Campaign/components/index nor
        // QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/ThankableWrapper
        if (this.props.resetSubmittedWidget) {
            this.props.resetSubmittedWidget(this.props.uniqueWidgetId)
        }
    }

    getNGGSocialButtonIcon(socialDetail) {
        if (socialDetail.platform === DjangIO.app.grassroots.enums.SocialMediaType.twitter.value) {
            return <XTwitterFarFAIcon />
        }
        return <i className={`fa ${socialDetail.icon}`} />
    }

    getNGGSocialButtonText(socialDetail) {
        if (socialDetail.platform === DjangIO.app.grassroots.enums.SocialMediaType.twitter.value) {
            return <StyledSocialButtonText>Share X</StyledSocialButtonText>
        }
        return <StyledSocialButtonText>{socialDetail.label}</StyledSocialButtonText>
    }

    getNGGSocialDetails() {
        const socialButtons = this.props.socialDetails
            .filter(
                (socialDetail) =>
                    socialDetail.platform !== DjangIO.app.grassroots.enums.SocialMediaType.pinterest.value,
            )
            .map((button) => {
                return (
                    // the platform prop is needed for socialButtonIconColor styling
                    <NewStyledSocialButton onClick={button.onClick} platform={button.platform} data-cy={button.dataCy}>
                        {this.getNGGSocialButtonIcon(button)}
                        {this.getNGGSocialButtonText(button)}
                    </NewStyledSocialButton>
                )
            })
        return socialButtons.length > 0 && <StyledSocialButtonWrapper>{socialButtons}</StyledSocialButtonWrapper>
    }

    getSocialDetails() {
        const desiredWidth = this.props.socialDetails.length % 2 ? 12 : 6

        return this.props.socialDetails.map((button) => (
            <Col md={desiredWidth} sm={12} key={button.label}>
                <StyledSocialButton
                    key={button.platform}
                    onClick={button.onClick}
                    platform={button.platform}
                    data-cy={button.dataCy}
                >
                    <i className={`fa ${button.icon}`} />
                    {button.label}
                </StyledSocialButton>
            </Col>
        ))
    }

    getButtonDetails() {
        // For one button, bootstrap width is 12. Two buttons, width 6. 3 buttons, width 4.
        const buttonWidth = this.props.buttonDetails ? 12 / this.props.buttonDetails.length : 12

        const renderButton = (button) => {
            const props = button.link
                ? { "data-cy": button.dataCy }
                : { key: button.label, "data-cy": button.dataCy, onClick: button.onClick }
            if (this.props.isCampaignPage) {
                return (
                    <StyledButton isCampaignPage activateNGGTheme {...props}>
                        {button.label}
                    </StyledButton>
                )
            }
            return (
                <StyledBackButton data-cy={button.dataCy} {...props}>
                    <i className="fa fa-arrow-left" />
                    {button.label}
                </StyledBackButton>
            )
        }

        return (
            this.props.canRedirect &&
            this.props.buttonDetails && (
                <div className="button-details">
                    {!this.props.isCampaignPage && <StyledHorizontalRule />}
                    <Row>
                        {this.props.buttonDetails.map((button) =>
                            button.link ? (
                                <Col md={buttonWidth}>
                                    <Link key={button.label} to={button.link}>
                                        {renderButton(button)}
                                    </Link>
                                </Col>
                            ) : (
                                <Col md={buttonWidth}>{renderButton(button)}</Col>
                            ),
                        )}
                    </Row>
                </div>
            )
        )
    }

    render() {
        if (this.props.isCampaignPage) {
            return (
                <StyledThankWrapper>
                    <StyledGamificationSection>
                        <i className="fa fa-check-circle-o" />
                        <StyledGamificationDescription
                            data-cy="thank-you-text"
                            dangerouslySetInnerHTML={{ __html: this.props.text }}
                        />
                    </StyledGamificationSection>
                    {Boolean(this.props.pointsEarned) && (
                        <StyledGamificationPointSection>
                            <StyledGamificationPointHeading>
                                <i className={`fa ${this.props.achievementIcon}`} />
                                <StyledGamificationPointDescription>
                                    {i18n.t(
                                        parseInt(this.props.pointsEarned) === 1
                                            ? "gamification.thanks.singular_message"
                                            : "gamification.thanks.message",
                                        { points: this.props.pointsEarned },
                                    )}
                                </StyledGamificationPointDescription>
                            </StyledGamificationPointHeading>
                        </StyledGamificationPointSection>
                    )}
                    {this.props.socialDetails && this.getNGGSocialDetails()}
                    {this.getButtonDetails()}
                </StyledThankWrapper>
            )
        }

        return (
            <WidgetStyleWrapper className="thanks-display-widget" useWidgetStyling={this.props.useWidgetStyling} md={6}>
                <div>
                    <StyledIconWrapper>
                        {
                            // If advocate earns points and there is a currentTierIcon defined, display the icon
                            Boolean(this.props.pointsEarned) && this.props.currentTierIcon ? (
                                <StyledCurrentTierThanksIcon
                                    className="custom-tier-thanks-icon"
                                    currentTierIcon={this.props.currentTierIcon}
                                />
                            ) : (
                                <i className="thanks-icon fa fa-check-circle" />
                            )
                        }
                    </StyledIconWrapper>
                    <StyledThankYouText
                        data-cy="thank-you-text"
                        dangerouslySetInnerHTML={{ __html: this.props.text }}
                    />
                    {Boolean(this.props.pointsEarned) && (
                        <StyledPointsEarnedDisplay className="points-earned">
                            {
                                // If currentTierIcon is defined, do not display achievement icon
                                !this.props.currentTierIcon && <i className={`fa ${this.props.achievementIcon}`} />
                            }
                            <div>
                                {i18n.t(
                                    parseInt(this.props.pointsEarned) === 1
                                        ? "gamification.thanks.singular_message"
                                        : "gamification.thanks.message",
                                    { points: this.props.pointsEarned },
                                )}
                            </div>
                        </StyledPointsEarnedDisplay>
                    )}
                    {this.props.socialDetails && <Row>{this.getSocialDetails()}</Row>}
                    {this.getButtonDetails()}
                </div>
            </WidgetStyleWrapper>
        )
    }
}

ThanksDisplay.propTypes = {
    // Icon for gamification points message
    achievementIcon: PropTypes.string.isRequired,
    // social share buttons (share on FB, Twitter, etc)
    socialDetails: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            icon: PropTypes.string,
            onClick: PropTypes.func,
        }),
    ),
    // internal link buttons, can redirect to other parts of site
    buttonDetails: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.string,
        }),
    ),
    // thank you text
    text: PropTypes.string,
    // thank you image
    image: PropTypes.string,
    pointsEarned: PropTypes.number,
    isCampaignPage: PropTypes.bool,
}

ThanksDisplay.defaultProps = {
    useWidgetStyling: true,
    pointsEarned: 0,
}

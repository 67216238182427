import { createSelector, createStructuredSelector } from "reselect"
import moment from "moment"

// selectors
import {
    billTypeSelector,
    campaignFinanceDisbursementInlineSelector,
    campaignFinanceReceiptInlineSelector,
    politicalCommitteeInlineSelector,
    sendEmailInlineSelector,
    transactionInlineSelector,
} from "shared/search/inline-selectors/inline-selectors"
import { getImage } from "shared/search/inline-selectors/personInlineSelectors"

// helpers
import { stripHtml } from "quorum/static/frontend/marketing-website/constants/functions"
import { parseHtmlToReact } from "app/static/frontend/imports/desktopHelperFunctions"
import { generateMediaUrl, generateStaticUrl, stringContainsHTMLMarkup } from "shared/imports/sharedHelperFunctions"

// constants
import urls from "app/static/frontend/imports/desktopPaths"
import { BLANK_AVATAR_PATH, FEDERAL_SEAL_AVATAR_PATH } from "shared/imports/sharedConstants"

const selectListItem = (_, props) => props.item
const selectDataType = (_, props) => props.dataType

export const selectText = createSelector(
    selectDataType,
    selectListItem,
    (state, props) => props.isWidget,
    (state, props) => props.isExternal,
    (dataType, item, isWidget, isExternal) => {
        let text = ""

        if (!item) {
            return text
        }

        if (
            [
                DjangIO.app.models.QuorumDataType.note.value,
                DjangIO.app.models.QuorumDataType.grassroots_action.value,
            ].includes(dataType)
        ) {
            text =
                item.getIn(["cache_json", "interaction_type"]) || item.get("custom_interaction_type")
                    ? item.get("humanized_text") || item.get("text")
                    : item.get("text") || item.get("humanized_text")
        } else if (dataType === DjangIO.app.models.QuorumDataType.send_email.value) {
            text = sendEmailInlineSelector(item).firstLineData
        } else if (dataType === DjangIO.app.models.QuorumDataType.political_committee.value) {
            text = politicalCommitteeInlineSelector(item).firstLineData
        } else if (dataType === DjangIO.app.models.QuorumDataType.transaction.value) {
            text = transactionInlineSelector(item).firstLineData
        } else if (dataType === DjangIO.app.models.QuorumDataType.receipt.value) {
            text = campaignFinanceReceiptInlineSelector(item).firstLineData
        } else if (dataType === DjangIO.app.models.QuorumDataType.disbursement.value) {
            text = campaignFinanceDisbursementInlineSelector(item).firstLineData
        } else {
            text = item.get(DjangIO.app.models.QuorumDataType.by_value(dataType).display_key)
        }

        if (isWidget) {
            return stringContainsHTMLMarkup(text) ? parseHtmlToReact(text, item.get("id"), isExternal) : text
        }

        return stripHtml(text)
    },
)

export const selectDate = createSelector(selectDataType, selectListItem, (dataType, item) => {
    if (!item) {
        return ""
    }

    switch (dataType) {
        case DjangIO.app.models.QuorumDataType.bill.value:
            return item.get("current_status_date")
        case DjangIO.app.models.QuorumDataType.amendment.value:
            return item.get("status_date")
        case DjangIO.app.models.QuorumDataType.person.value:
        case DjangIO.app.models.QuorumDataType.supporter.value:
            return item.get("updated")
        case DjangIO.app.models.QuorumDataType.vote.value:
            return item.get("created")
        case DjangIO.app.models.QuorumDataType.regulation.value:
            return item.get("detailed_status_date")
        case DjangIO.app.models.QuorumDataType.media.value:
            return item.get("estimated_published_date")
        case DjangIO.app.models.QuorumDataType.event.value:
            return item.get("when")
        case DjangIO.app.models.QuorumDataType.send_email.value:
            return item.get("sent_datetime") || item.get("created")
        default:
            return item.get("date")
    }
})

export const formatDate = (date) => {
    const momentDate = moment(date)
    return momentDate.isValid() ? momentDate.format("MMM Do, YYYY") : ""
}

export const selectSubtext = createSelector(selectDataType, selectListItem, selectDate, (dataType, item, date) => {
    if (!item) {
        return []
    }
    const subtext = []
    switch (dataType) {
        case DjangIO.app.models.QuorumDataType.bill.value:
            // if it's a state bill
            if (
                item.get("region") !== DjangIO.app.models.Region.federal.value &&
                DjangIO.app.models.Region.by_value(item.get("region"))
            ) {
                // add the state it comes from
                subtext.push(["State", DjangIO.app.models.Region.by_value(item.get("region")).abbrev.toUpperCase()])
            }
            if (billTypeSelector(item) !== DjangIO.app.bill.models.BillType.nomination.value) {
                subtext.push([
                    "Sponsor",
                    // dehydrate_extra_sponsors
                    item.getIn(["_extra", "sponsors"]) || item.get("sponsor_name"),
                ])
            }
            subtext.push(["Status", item.get("current_status_text")])
            subtext.push(["Last Action", formatDate(date)])
            break
        case DjangIO.app.models.QuorumDataType.person.value:
        case DjangIO.app.models.QuorumDataType.staffer.value:
        case DjangIO.app.models.QuorumDataType.press_contact.value:
            if (DjangIO.app.person.types.PersonType.by_value(item.get("most_recent_person_type"))) {
                subtext.push([
                    "Type",
                    item.get("title") ||
                        DjangIO.app.person.types.PersonType.by_value(item.get("most_recent_person_type")).label,
                ])
            } else {
                subtext.push(["Type", item.get("title")])
            }
            if (item.get("public_organization_descriptor")) {
                subtext.push(["Organization", item.get("public_organization_descriptor")])
            }
            break
        case DjangIO.app.models.QuorumDataType.document.value:
            if (DjangIO.app.document.models.DocumentType.by_value(item.get("document_type"))) {
                subtext.push([
                    "Type",
                    DjangIO.app.document.models.DocumentType.by_value(item.get("document_type")).label,
                ])
            }
            subtext.push(["Source", item.get("source_name")])
            subtext.push(["Date", formatDate(date)])
            break
        case DjangIO.app.models.QuorumDataType.vote.value:
            subtext.push(["Result", item.get("result")])
            break
        case DjangIO.app.models.QuorumDataType.amendment.value:
            subtext.push(["Status", DjangIO.app.bill.models.AmendmentStatus.by_value(item.get("status")).full_name])
            subtext.push(["Sponsor", item.get("sponsor_name")])
            break
        case DjangIO.app.models.QuorumDataType.note.value:
        case DjangIO.app.models.QuorumDataType.grassroots_action.value:
            subtext.push(["Date", formatDate(date)])
            subtext.push(["Created by", item.getIn(["cache_json", "user", "username"])])
            break
        case DjangIO.app.models.QuorumDataType.supporter.value:
            if (item.get("public_organization_name")) {
                subtext.push(["Org", item.get("public_organization_name")])
            }
            if (item.get("state_name")) {
                subtext.push(["State", item.get("state_name")])
            }
            if (item.get("email")) {
                subtext.push(["Email", item.get("email")])
            }
            break
        case DjangIO.app.models.QuorumDataType.public_organization.value:
            if (item.get("organization_type")) {
                subtext.push([
                    "Type",
                    DjangIO.app.person.types.PublicOrganizationType.by_value(item.get("organization_type")).label,
                ])
            }
            if (item.get("parent_organization_name")) {
                subtext.push(["Parent", item.get("parent_organization_name")])
            }
            break
        case DjangIO.app.models.QuorumDataType.event.value:
            if (item.get("when")) {
                subtext.push(["Date", formatDate(date)])
            }
            if (item.get("address")) {
                subtext.push(["Address", item.get("address")])
            }
            if (item.get("location")) {
                subtext.push(["Location", item.get("location")])
            }
            break
        case DjangIO.app.models.QuorumDataType.attachment.value:
            if (item.get("project_name")) {
                subtext.push(["Project", item.get("project_name")])
            }
            if (item.get("short_description")) {
                subtext.push(["Description", item.get("short_description")])
            }
            break
        case DjangIO.app.models.QuorumDataType.caucus.value:
        case DjangIO.app.models.QuorumDataType.committee.value:
            if (item.get("committee_type")) {
                subtext.push([
                    "Type",
                    DjangIO.app.committee.models.CommitteeType.by_value(item.get("committee_type")).label,
                ])
            }
            break
        case DjangIO.app.models.QuorumDataType.regulation.value:
            if (item.get("region")) {
                subtext.push(["Region", DjangIO.app.models.Region.by_value(item.get("region")).region_name])
            }
            if (item.get("reg_type")) {
                subtext.push(["Type", DjangIO.app.reg.types.RegType.by_value(item.get("reg_type")).label])
            }
            if (item.get("general_status")) {
                subtext.push([
                    "Status",
                    DjangIO.app.reg.types.GeneralRegStatus.by_value(item.get("general_status")).label,
                ])
            }
            if (item.get("detailed_status_date")) {
                subtext.push(["Last Status Change", formatDate(date)])
            }
            break
        case DjangIO.app.models.QuorumDataType.media.value:
            if (item.getIn(["source", "media_type"])) {
                subtext.push([
                    "Type",
                    DjangIO.app.media_monitoring.types.MediaSourceType.by_value(item.getIn(["source", "media_type"]))
                        .label,
                ])
            }
            if (item.getIn(["source", "name"])) {
                subtext.push(["Source", item.getIn(["source", "name"])])
            }
            if (item.get("estimated_published_date")) {
                subtext.push(["Date", formatDate(date)])
            }
            break
        case DjangIO.app.models.QuorumDataType.send_email.value:
            // shared/search/inline-selectors/inline-selectors.js sendEmailInlineSelector getSecondLine()
            if (item.get("email_address")) {
                subtext.push(["Email", item.get("email_address")])
            }

            if (item.get("opens") >= 0) {
                subtext.push([`Open${item.get("opens") !== 1 ? "s" : ""}`, item.get("opens")])
            }

            if (item.get("clicks") >= 0) {
                subtext.push([`Click${item.get("clicks") !== 1 ? "s" : ""}`, item.get("clicks")])
            }

            if (date) {
                subtext.push(["Sent ", `${formatDate(date)}`])
            }

            if (item.get("first_open")) {
                subtext.push(["First Opened on ", `${formatDate(item.get("first_open"))}`])
            }
            break
        case DjangIO.app.models.QuorumDataType.political_committee.value:
            // shared/search/inline-selectors/inline-selectors.js politicalCommitteeInlineSelector getSecondLineDS()
            politicalCommitteeInlineSelector(item).secondLineDS.forEach((secondLineItem) => {
                subtext.push([secondLineItem.listLabel, secondLineItem.value])
            })
            break
        case DjangIO.app.models.QuorumDataType.transaction.value:
            // shared/search/inline-selectors/inline-selectors.js transactionInlineSelector getSecondLineDS()
            transactionInlineSelector(item).secondLineDS.forEach((secondLineItem) => {
                subtext.push([secondLineItem.listLabel, secondLineItem.value])
            })
            break
        case DjangIO.app.models.QuorumDataType.receipt.value:
            subtext.push(["Date", `${formatDate(item.get("date"))}`])
            break
        case DjangIO.app.models.QuorumDataType.disbursement.value:
            subtext.push(["Date", `${formatDate(item.get("date"))}`])
            break
        default:
            subtext.push(["Unsupported Type"])
    }

    return subtext
})

const makeListItemSelector = (attr) => createSelector(selectListItem, (item) => item && item.get(attr))

export const selectCircleColor = createSelector(selectDataType, selectListItem, (dataType, item) => {
    switch (dataType) {
        case DjangIO.app.models.QuorumDataType.person.value:
        case DjangIO.app.models.QuorumDataType.staffer.value:
            return item.get("most_recent_party")
                ? DjangIO.app.person.types.Party.by_value(item.get("most_recent_party")).color
                : DjangIO.app.person.types.Party.NoParty.color
        case DjangIO.app.models.QuorumDataType.document.value:
            return (
                item.getIn(["_extra", "official_image", "source_person__most_recent_party"]) &&
                DjangIO.app.person.types.Party.by_value(
                    item.getIn(["_extra", "official_image", "source_person__most_recent_party"]),
                ).color
            )
        default:
            return undefined
    }
})

export const selectImageUrl = createSelector(
    selectDataType,
    makeListItemSelector("image_url"),
    selectListItem,
    (dataType, imageUrl, item) => {
        switch (dataType) {
            case DjangIO.app.models.QuorumDataType.person.value:
            case DjangIO.app.models.QuorumDataType.staffer.value:
                return getImage(item, FEDERAL_SEAL_AVATAR_PATH, BLANK_AVATAR_PATH)
            case DjangIO.app.models.QuorumDataType.supporter.value:
                return item.get("image_url") && generateMediaUrl(item.get("image_url"))
            case DjangIO.app.models.QuorumDataType.document.value:
                if (item.get("document_type") !== DjangIO.app.document.models.DocumentType.tweet.value) {
                    return item.getIn(["_extra", "official_image", "source_person__image_url"])
                }

                return undefined
            case DjangIO.app.models.QuorumDataType.public_organization.value:
                return imageUrl && generateStaticUrl(imageUrl)
            default:
                return imageUrl ? generateStaticUrl(imageUrl) : undefined
        }
    },
)

export const selectFa4Icon = createSelector(selectDataType, selectListItem, (dataType, item) => {
    const getNoteGrassrootsIcon = ({ isGrassroots }) => {
        if (item.get("note_type") === DjangIO.app.userdata.types.NoteType.interaction.value) {
            if (item.get("interaction_type")) {
                if (isGrassroots) {
                    return DjangIO.app.userdata.types.GrassrootsInteractionType.by_value(item.get("interaction_type"))
                        .icon
                }
                return DjangIO.app.userdata.types.InteractionType.by_value(item.get("interaction_type")).icon
            }
            return (
                item.getIn(["cache_json", "icon"]) ||
                (isGrassroots
                    ? DjangIO.app.models.QuorumDataType.grassroots_action.icon
                    : DjangIO.app.models.QuorumDataType.note.icon)
            )
        } else if (item.get("note_type") === DjangIO.app.userdata.types.NoteType.relationship.value) {
            return DjangIO.app.userdata.types.RelationshipType.by_value(item.get("relationship_type")).icon
        }

        return (
            item.get("icon") ||
            (isGrassroots
                ? DjangIO.app.models.QuorumDataType.grassroots_action.fa5_icon
                : DjangIO.app.models.QuorumDataType.note.fa5_icon)
        )
    }

    switch (dataType) {
        case DjangIO.app.models.QuorumDataType.vote.value:
            return DjangIO.app.vote.models.VoteResultType.by_value(item.get("result_type")).icon.replace("fa-", "")
        case DjangIO.app.models.QuorumDataType.note.value:
            return getNoteGrassrootsIcon({}).replace("fa-", "")
        case DjangIO.app.models.QuorumDataType.document.value:
            return DjangIO.app.document.models.DocumentType.by_value(item.get("document_type")).icon.replace("fa-", "")
        case DjangIO.app.models.QuorumDataType.event.value:
            return DjangIO.app.events.models.EventType.by_value(item.get("event_type")).icon.replace("fa fa-", "")
        case DjangIO.app.models.QuorumDataType.grassroots_action.value:
            return getNoteGrassrootsIcon({ isGrassroots: true }).replace("fa-", "")
        case DjangIO.app.models.QuorumDataType.send_email.value:
            return DjangIO.app.models.QuorumDataType.send_email.icon.replace("fa-", "")
        case DjangIO.app.models.QuorumDataType.political_committee.value:
            return DjangIO.app.models.QuorumDataType.political_committee.icon.replace("fa-", "")
        case DjangIO.app.models.QuorumDataType.transaction.value:
            return DjangIO.app.models.QuorumDataType.transaction.icon.replace("fa-", "")
        default:
            return undefined
    }
})

export const selectIconColor = createSelector(selectDataType, selectListItem, (dataType, item) => {
    switch (dataType) {
        case DjangIO.app.models.QuorumDataType.vote.value:
            return DjangIO.app.vote.models.VoteResultType.by_value(item.get("result_type")).fa5_color
        default:
            return undefined
    }
})

export const selectIcon = createSelector(
    selectDataType,
    makeListItemSelector("icon"),
    makeListItemSelector("source"),
    (state, props) => props.isWidget,
    (qdt, icon, source, isWidget) => {
        if (
            icon &&
            // we handle this in selectFa4Icon in the listWidgetSelectors
            !isWidget
        ) {
            return icon
        }

        if (qdt === DjangIO.app.models.QuorumDataType.media.value) {
            if (source && source.get("media_type")) {
                const mediaType = DjangIO.app.media_monitoring.types.MediaSourceType.by_value(source.get("media_type"))

                return isWidget ? mediaType.icon_fa5 : mediaType.icon
            }
        }

        const dataType = DjangIO.app.models.QuorumDataType.by_value(qdt)
        return isWidget ? dataType.fa5_icon : dataType.icon
    },
)

export const selectFa5IconFamily = createSelector(
    selectDataType,
    (qdt) => DjangIO.app.models.QuorumDataType.by_value(qdt).fa5_icon_family,
)

export const selectParty = createSelector(
    makeListItemSelector("most_recent_party"),
    (party) => DjangIO.app.person.types.Party.by_value(party) && DjangIO.app.person.types.Party.by_value(party).label,
)

export const selectSearchTerm = (_, props) =>
    props.filtersForSearch &&
    props.filtersForSearch.filters &&
    props.filtersForSearch.filters.advanced_search &&
    `?searchTerm=${encodeURIComponent(props.filtersForSearch.filters.advanced_search)}`

export const selectPath = createSelector(
    selectDataType,
    makeListItemSelector("id"),
    selectSearchTerm,
    (qdt, id, searchTerm) => {
        if (qdt && searchTerm && id) {
            return `${DjangIO.app.models.QuorumDataType.by_value(qdt).profile}${id}/${searchTerm}`
        } else if (qdt && id) {
            return `${DjangIO.app.models.QuorumDataType.by_value(qdt).profile}${id}`
        }
        return ""
    },
)

export const selectSegue = (_, props) => {
    // we never want to internally segue in an external dashboard
    if (props.isExternal) {
        return undefined
    }

    switch (props.dataType) {
        case DjangIO.app.models.QuorumDataType.attachment.value:
            // window.location invokes the download to open the save dialog
            // we cannot segue because it does not trigger the download dialog
            return () => {
                window.location = `${window.location.origin}${urls.outboxDownloadPrefix}${props.item.get("id")}/`
            }
        case DjangIO.app.models.QuorumDataType.grassroots_action.value:
        case DjangIO.app.models.QuorumDataType.note.value:
            // ({ initialFilter, quorumDataType })
            return props.segueToSearch
        case DjangIO.app.models.QuorumDataType.send_email.value:
        case DjangIO.app.models.QuorumDataType.transaction.value:
            return undefined
        default:
            // ({ id, extraUrlParameters, fromSearch }, quorumDataType)
            return props.segueToProfile
    }
}
export const selectSegueArgs = (_, props) => {
    if (!props.item) {
        return
    }
    switch (props.dataType) {
        case DjangIO.app.models.QuorumDataType.note.value:
        case DjangIO.app.models.QuorumDataType.grassroots_action.value:
            // segueToSearch params
            return [
                {
                    initialFilter: { id__in: props.item.get("id") },
                    quorumDataType: props.dataType,
                },
            ]
        case DjangIO.app.models.QuorumDataType.bill.value:
        case DjangIO.app.models.QuorumDataType.regulation.value:
            // segueToProfile params
            return [
                {
                    id: props.item.get("id"),
                    extraUrlParameters:
                        props.filtersForSearch &&
                        props.filtersForSearch.filters &&
                        props.filtersForSearch.filters.advanced_search &&
                        `?searchTerm=${encodeURIComponent(props.filtersForSearch.filters.advanced_search)}`,
                },
                props.dataType,
            ]
        case DjangIO.app.models.QuorumDataType.document.value:
            // segueToProfile params
            const documentType = props.item.get("document_type")
            const committeeTranscriptType = DjangIO.app.document.models.DocumentType.committee_transcript
            const usPresidentTranscript = DjangIO.app.document.models.DocumentType.us_president_transcript
            const stateFloorTranscript = DjangIO.app.document.models.DocumentType.state_floor_transcript
            const localMeetingTranscript = DjangIO.app.document.models.DocumentType.local_meeting_transcript

            if (
                documentType &&
                [
                    committeeTranscriptType.value,
                    usPresidentTranscript.value,
                    stateFloorTranscript.value,
                    localMeetingTranscript.value,
                ].includes(documentType)
            ) {
                // segueToProfile params
                return [
                    {
                        id: props.item.get("committee_meeting_id"),
                        extraUrlParameters: `transcript/?sequence=${props.item.get("sequence")}`,
                    },
                    DjangIO.app.models.QuorumDataType.event.value,
                ]
            }

            // segueToProfile params
            return [{ id: props.item.get("id") }, props.dataType]

        default:
            // segueToProfile params
            return [{ id: props.item.get("id") }, props.dataType]
    }
}

export const selectHref = createSelector(
    selectDataType,
    selectListItem,
    (state, props) => props.isExternal,
    (dataType, item, isExternal) => {
        const qdt = DjangIO.app.models.QuorumDataType.by_value(dataType)

        if (isExternal) {
            switch (dataType) {
                case DjangIO.app.models.QuorumDataType.attachment.value:
                    return item.get("raw_s3_url")
                case DjangIO.app.models.QuorumDataType.bill.value:
                    return item.get("source_link")
                case DjangIO.app.models.QuorumDataType.committee.value:
                case DjangIO.app.models.QuorumDataType.caucus.value:
                    return item.get("url")
                case DjangIO.app.models.QuorumDataType.document.value:
                    return item.get("source_url")
                case DjangIO.app.models.QuorumDataType.event.value:
                    return item.get("source_url")
                case DjangIO.app.models.QuorumDataType.grassroots_action.value:
                case DjangIO.app.models.QuorumDataType.note.value:
                    return item.get("http_referer")
                case DjangIO.app.models.QuorumDataType.media.value:
                    return item.get("lexis_url")
                case DjangIO.app.models.QuorumDataType.person.value:
                case DjangIO.app.models.QuorumDataType.staffer.value:
                case DjangIO.app.models.QuorumDataType.press_contact.value:
                    return item.getIn(["_extra", "most_recent_role", "most_recent_role__website"])
                case DjangIO.app.models.QuorumDataType.public_organization.value:
                    return item.get("source_url")
                case DjangIO.app.models.QuorumDataType.regulation.value:
                    return item.get("source_link")
                case DjangIO.app.models.QuorumDataType.supporter.value:
                    return item.get("email")
                case DjangIO.app.models.QuorumDataType.vote.value:
                    return item.get("source_link")
                case DjangIO.app.models.QuorumDataType.amendment.value:
                case DjangIO.app.models.QuorumDataType.political_committee.value:
                case DjangIO.app.models.QuorumDataType.send_email.value:
                case DjangIO.app.models.QuorumDataType.transaction.value:
                default:
                    return undefined
            }
        } else if (qdt.profile) {
            return `${qdt.profile}${item.get("id")}/`
        }

        return undefined
    },
)

export const listItemConnection = createStructuredSelector({
    id: makeListItemSelector("id"),
    icon: selectIcon,
    party: selectParty,
    imageUrl: selectImageUrl,
    date: selectDate,
    text: selectText,
    subtext: selectSubtext,
    segue: selectSegue,
    segueArgs: selectSegueArgs,
    path: selectPath,
})

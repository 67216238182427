import { reduxForm } from "redux-form/immutable"

import { selectWidgetSubmitted } from "QuorumGrassroots/widgets/selectors"
import { resetSubmittedWidget } from "QuorumGrassroots/widgets/action-creators"
import { submitEventRsvp, validate } from "QuorumGrassroots/widgets/Event/action-creators"
import { selectEventThanksProps } from "QuorumGrassroots/widgets/Event/selectors"

import { thankable } from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/ThankableWrapper"
import initializer from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/InitializeStoreWrapper"
import EventForm from "QuorumGrassroots/widgets/Event/components/EventForm"

import { constants } from "QuorumGrassroots/constants"

const submit = (values, dispatch, props) => dispatch(submitEventRsvp(values, props))

const ReduxEventForm = reduxForm({
    form: constants.eventReduxFormKey,
    onSubmit: submit,
    touchOnChange: true,
    validate,
    enableReinitialize: true,
})(EventForm)

const initializedComponent = initializer(ReduxEventForm)

// Wrap our component in thankable, but don't pass in onThankUnmount, or we'll prematurely destroy the widget slice!
const thankableEvent = thankable({
    shouldThankSelector: selectWidgetSubmitted,
    thanksPropsSelector: selectEventThanksProps || ((state, props) => ({ text: props.t("thank_you") })),
    onRerouteThank: resetSubmittedWidget,
})(initializedComponent)

export default thankableEvent

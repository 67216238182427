import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Atoms/SectionHeader/style"

const SectionHeader = ({title}) => (
    <S.SectionHeader data-auto-cy="AtomSectionHeader">
        {title}
    </S.SectionHeader>
)

SectionHeader.defaultProps = {}

SectionHeader.propTypes = {
    title: PropTypes.string,
}

export default SectionHeader

import axios from "axios"

import {
    cacheResponse,
    startCacheResponse,
    invalidateCacheSlice,
    markResourceAsUpdated,
    // deprecated
    invalidateCachedListResponses_DEPRECATED,
    markResourceAsUpdated_DEPRECATED,
} from "shared/api-caching/action-creators"
import { getCachedResponse } from "shared/api-caching/selectors"
// eslint-disable-next-line import/no-cycle
import {
    transformMoneyballResponse,
    transformMoneyballRequest,
    handlePowerSearchRequest,
    shouldUsePowerSearch,
    shouldUseCampaignFinanceEndpoint,
    MODEL_NAME_SUPPORTER,
    MODEL_NAME_POLITICAL_COMMITTEE,
    MODEL_NAME_OFFICIAL,
    MODEL_NAME_SAFED_SEARCH,
    MODEL_WIDGET_ENGINE,
    MODEL_NAME_BULK_EMAIL,
    injectMoneyballBulkEmailParams,
    injectMoneyballSafedSearchParams,
    injectMoneyballWidgetEngineParams,
    isMoneyballRelationshipRequest,
    transformMoneyballRelationshipRequest,
} from "./moneyballHelpers"

global.axios = axios

/**
 * Return either the value as is, or the stringified value if a more complicated data type
 * @name maybeStringifiedValue
 * @function
 * @param {Any} value - some value to potentially stringify
 * @returns {Type} - Something
 */
const maybeStringifiedValue = (value) => {
    if (value === undefined || value === null) {
        return value
    }
    if (typeof value === "number" || typeof value === "string" || value.constructor === Array) {
        return value
    } else {
        return JSON.stringify(value)
    }
}

/**
 * This function takes an object of parameters and serializes them
 * @name param
 * @function
 * @param {Object} params - an object of parameters
 * @param {Boolean} stripUndefined - whether undefined or null values should be stripped from the URL params
 *  - stripUndefined is used in the very specific instance of calling `.download()`
 *    via DjangIO. Our axios configuration is set up to automatically strip out
 *   any undefined filter keys; however, .download() uses jQuery's `fileDownload`,
 *   which has nothing to do with axios.
 * @returns {String} - A string of URL params separated by an &
 */
export const param = (params, stripUndefined = false) => {
    const paramKeyValues = stripUndefined
        ? Object.entries(params).filter(([, value]) => value !== undefined && value !== null)
        : Object.entries(params)

    return paramKeyValues
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(maybeStringifiedValue(value))}`)
        .join("&")
}

axios.defaults.paramsSerializer = param

export default class Manager {
    constructor(model) {
        this.model = model
        this.primaryKey = undefined
        this.createKwargs = {}
        this.updateKwargs = {}
        this.filterKwargs = { ...model._meta.default_filters }
        this.excludeKwargs = { ...model._meta.default_exclude_filters }
        this.actionArg = undefined
    }

    map() {
        throw "Javascript-only Manager is not iterable" // eslint-disable-line no-throw-literal
    }

    reduce() {
        throw "Javascript-only Manager is not iterable" // eslint-disable-line no-throw-literal
    }

    get(pk) {
        this.primaryKey = pk

        // if we're defined a primaryKey, then we don't want the default filters (from backend)
        // to be applied, because filtering on a detail request makes no sense.
        // However, we still want to call any filters applied on the frontend, because they might
        // be dehydrate or action requests.
        if (this.model._meta.default_filters) {
            this.filterKwargs = Object.keys(this.filterKwargs)
                .filter((key) => this.filterKwargs[key] !== this.model._meta.default_filters[key])
                .reduce((acc, key) => {
                    acc[key] = this.filterKwargs[key]
                    return acc
                }, {})
        }

        this.excludeKwargs = {}

        return this
    }

    filter(kwargs = {}) {
        Object.assign(this.filterKwargs, kwargs)
        return this
    }

    exclude(kwargs = {}) {
        Object.assign(this.excludeKwargs, kwargs)
        return this
    }

    update(kwargs = {}) {
        this.updateKwargs = kwargs
        return this
    }

    create(kwargs = {}) {
        this.createKwargs = kwargs
        return this
    }

    limit(limit) {
        return this.filter({ limit })
    }

    offset(offset) {
        return this.filter({ offset })
    }

    page(page) {
        return this.filter({ page })
    }

    order_by(order_by) {
        return order_by ? this.filter({ order_by }) : this
    }

    use_action(action_name) {
        // stores action on the instance that will be added during buildendpoint()
        this.actionArg = action_name
        return this
    }

    getEndpoint() {
        let endpoint = this.model.endpoint
        if (this.primaryKey) {
            endpoint += `${this.primaryKey}/`
        }
        if (this.actionArg) {
            endpoint += `${this.actionArg}/`
        }

        return endpoint
    }

    buildEndpoint() {
        const endpoint = this.getEndpoint()

        // This constant will be defined in the mobile application,
        // but not in the desktop application.
        return global.Config && global.Config.MOBILE_SERVER ? `${global.Config.MOBILE_SERVER}${endpoint}` : endpoint
    }

    buildUrl({ stripUndefined = false, useCustomParams = false } = { stripUndefined: false, useCustomParams: false }) {
        let kwargs = this.filterKwargs

        if (useCustomParams && !kwargs.moneyball_params) {
            kwargs = this.getCustomRequestParams(kwargs)
        }
        const params = param(Object.assign(kwargs, { exclude: this.excludeKwargs }), stripUndefined)
        return `${this.buildEndpoint()}?${params}`
    }

    buildRequestConfig() {
        const params = Object.assign(this.filterKwargs, { exclude: this.excludeKwargs })
        const endpoint = this.buildEndpoint()
        const url = `${endpoint}?${param(params)}`
        return { url, endpoint, params }
    }

    getCustomRequestParams(params) {
        const externalProducts = this.model.external_products

        if (externalProducts && externalProducts.isMoneyball) {
            return transformMoneyballRequest(params, this.model)
        }

        if ([MODEL_NAME_SUPPORTER, MODEL_NAME_POLITICAL_COMMITTEE, MODEL_NAME_OFFICIAL].includes(this.model.__name__)) {
            return transformMoneyballRequest(params, this.model)
        }

        // if no relatinoship found, no mods
        if (isMoneyballRelationshipRequest(params)) {
            return transformMoneyballRelationshipRequest(params)
        }

        return params
    }

    getCustomPostRequestParams(params) {
        try {
            switch (this.model.__name__) {
                case MODEL_NAME_SAFED_SEARCH:
                    return injectMoneyballSafedSearchParams(params)
                case MODEL_WIDGET_ENGINE:
                    return injectMoneyballWidgetEngineParams(params)
                default:
                    break
            }
        } catch (error) {
            // leave error unhandled, just skip
        }
        return params
    }

    getCustomResponseParams(response, params) {
        const externalProducts = this.model.external_products

        if (externalProducts && externalProducts.isMoneyball) {
            return transformMoneyballResponse(response, params, this.model)
        }

        return response
    }

    getCustomPatchRequestParams(params) {
        if (MODEL_NAME_BULK_EMAIL === this.model.__name__) {
            try {
                return injectMoneyballBulkEmailParams(params)
            } catch (e) {
                return params
            }
        }

        return params
    }

    GET({ shouldLoadFromCache = false } = {}) {
        const { url, endpoint, params } = this.buildRequestConfig()
        const isStatsUrl = url.includes("person_stats") || url.includes("issue_stats")
        // If we have a pk, just check the endpoint (api/model/pk)
        // else check the full url
        const cachedResponse =
            shouldLoadFromCache &&
            getCachedResponse(this.primaryKey && !isStatsUrl ? endpoint : decodeURIComponent(url))

        // response is either an object (detail, list) or a number (count_only)
        const isNonEmptyCacheResponse =
            typeof cachedResponse === "number" ||
            (typeof cachedResponse === "object" && Object.keys(cachedResponse).length)

        // If we have a cached value and we should check the cache,
        // resolve immediately
        if (shouldLoadFromCache && isNonEmptyCacheResponse) {
            return Object.assign(Promise.resolve(cachedResponse), { abort: () => null })
        } else {
            startCacheResponse(endpoint, url)
            // Generate a cancellation token
            const source = axios.CancelToken.source()
            const { isPowerSearch, isModalPowerSearch, hasCurrentSearchifySelection, hasIdInParams } =
                shouldUsePowerSearch(params, endpoint)

            if (isPowerSearch || isModalPowerSearch) {
                return Object.assign(
                    handlePowerSearchRequest(params, hasCurrentSearchifySelection, hasIdInParams, endpoint).then(
                        (response) => cacheResponse(this.getCustomResponseParams(response, params), endpoint, url),
                    ),
                    { abort: source.cancel },
                )
            }

            if (shouldUseCampaignFinanceEndpoint(params, endpoint)) {
                return Object.assign(
                    axios
                        .post(endpoint, this.getCustomRequestParams(params))
                        .then((response) =>
                            cacheResponse(this.getCustomResponseParams(response, params), endpoint, url),
                        ),
                    { abort: source.cancel },
                )
            }

            return Object.assign(
                axios
                    .get(endpoint, { params: this.getCustomRequestParams(params), cancelToken: source.token })
                    .then((response) => cacheResponse(this.getCustomResponseParams(response, params), endpoint, url)),
                { abort: source.cancel },
            )
        }
    }

    POST({ cacheInvalidationParams } = {}) {
        const { url, endpoint } = this.buildRequestConfig()
        const source = axios.CancelToken.source()
        startCacheResponse(endpoint, url)
        return Object.assign(
            axios
                .post(this.buildEndpoint(), this.getCustomPostRequestParams(this.createKwargs), {
                    params: this.filterKwargs,
                    cancelToken: source.token,
                })
                .then((response) => {
                    if (cacheInvalidationParams) {
                        invalidateCacheSlice(cacheInvalidationParams)
                        markResourceAsUpdated(cacheInvalidationParams)
                    } else {
                        // we're trying to phase out of this.
                        invalidateCachedListResponses_DEPRECATED(this.model)
                        markResourceAsUpdated_DEPRECATED(this.model)
                    }

                    return cacheResponse(
                        response,
                        endpoint.replace(this.model.endpoint, response.resource_uri),
                        url.replace(this.model.endpoint, response.resource_uri),
                    )
                }),
            { abort: source.cancel },
        )
    }

    PATCH({ cacheInvalidationParams } = {}) {
        const { url, endpoint } = this.buildRequestConfig()
        startCacheResponse(endpoint, url)
        const source = axios.CancelToken.source()

        return Object.assign(
            axios
                .patch(this.buildEndpoint(), this.getCustomPatchRequestParams(this.updateKwargs), {
                    params: this.filterKwargs,
                    cancelToken: source.token,
                })
                .then((response) => {
                    if (cacheInvalidationParams) {
                        invalidateCacheSlice(cacheInvalidationParams)
                        markResourceAsUpdated(cacheInvalidationParams)
                    } else {
                        // we're trying to phase out of this.
                        invalidateCachedListResponses_DEPRECATED(this.model)
                        markResourceAsUpdated_DEPRECATED(this.model)
                    }

                    return cacheResponse(response, endpoint, url)
                }),
            { abort: source.cancel },
        )
    }

    PUT() {
        const source = axios.CancelToken.source()

        return Object.assign(
            axios.put(this.buildEndpoint(), this.updateKwargs, {
                params: this.filterKwargs,
                cancelToken: source.token,
            }),
            { abort: source.cancel },
        )
    }

    DELETE() {
        const source = axios.CancelToken.source()
        return Object.assign(
            axios.delete(this.buildEndpoint(), { param: this.filterKwargs, cancelToken: source.token }),
            { abort: source.cancel },
        )
    }
}

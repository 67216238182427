import React from "react"
import PropTypes from "prop-types"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import { StyledPublicSheetWidget } from "QuorumGrassroots/widgets/PublicSheet/components/style"

const PublicSheetWidget = (props) => (
    <WidgetStyleWrapper
        className="public-sheet-widget"
        md={props.colWidth}
        useWidgetStyling={props.useWidgetStyling}
        title={props.title}
    >
        <StyledPublicSheetWidget
            src={`https://www.quorum.us/spreadsheet/external/${props.sheet && props.sheet.public_slug}/`}
            hasTitle={Boolean(props.title)}
        />
    </WidgetStyleWrapper>
)

PublicSheetWidget.propTypes = {
    colWidth: PropTypes.number,
    sheet: PropTypes.object.isRequired,
    title: PropTypes.string,
    useWidgetStyling: PropTypes.bool,
}

PublicSheetWidget.defaultProps = {
    useWidgetStyling: true,
}

export default PublicSheetWidget

export const generateWriteAMemberActions = (messageValues, globalFormValues, props) => {
    return messageValues.reduce((acc, value) => {
        const gsaInfo = {
            subject: value.subject,
            text: [value.prebody, value.body, value.postbody].filter((text) => text).join("\n\n"),
            campaign: DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(props.campaign.id),
            supporter_action_type: props.campaign.campaign_type,
            message_group_id: props.messageGroups?.length ? value?.message_group_id : null,
        }

        if (!value.target.is_custom) {
            gsaInfo["person"] = DjangIO.app.person.models.Person.resourceUriFromId(value.target.id)
        } else {
            gsaInfo["custom_person"] = value.target.id
        }

        acc.push(gsaInfo)

        return acc
    }, [])
}

export const generateCombinedCampaignActions = (messageValues, globalFormValues, props) => {
    return messageValues.reduce((acc, value) => {
        const campaignId = value.get("campaignId")

        const gsaInfo = {
            text: [value.get("prebody"), value.get("body"), value.get("postbody")].filter((text) => text).join("\n\n"),
            subject: value.get("subject"),
            campaign: DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(campaignId),
            supporter_action_type: props.extraMessageData.getIn([campaignId.toString(), "campaign_type"]),
        }

        if (!value.getIn(["target", "is_custom"]) && value.getIn(["target", "id"])) {
            gsaInfo["person"] = DjangIO.app.person.models.Person.resourceUriFromId(value.getIn(["target", "id"]))
        }

        acc.push(gsaInfo)

        return acc
    }, [])
}

export const generateCommentOnRegulationActions = (messageValues, globalFormValues, props) => {
    return messageValues.reduce((acc, value) => {
        const submittingOrg = globalFormValues.submitting_org_name ?? value.submitting_org_name
        acc.push({
            text: [value.prebody, value.body, value.postbody].filter((text) => text).join("\n\n"),
            campaign: DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(props.campaign.id),
            supporter_action_type: props.campaign.campaign_type,
            ...(submittingOrg ? { submitting_org_name: submittingOrg } : {}),
        })
        return acc
    }, [])
}

export const generateVisitOfficeActions = (messageValues, globalFormValues, props) => {
    return messageValues.reduce((acc, value) => {
        acc.push({
            text: [value.get("prebody"), value.get("body"), value.get("postbody")].filter((text) => text).join("\n\n"),
            subject: value.get("subject"),
            person: DjangIO.app.person.models.Person.resourceUriFromId(value.getIn(["target", "id"])),
            campaign: DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(props.campaign.id),
            supporter_action_type: props.campaign.campaign_type,
        })

        return acc
    }, [])
}

export const generateWriteWhiteHouseActions = (messageValues, globalFormValues, props) => {
    return messageValues.reduce((acc, value) => {
        acc.push({
            text: [value.prebody, value.body, value.postbody].filter((text) => text).join("\n\n"),
            subject: value.subject,
            campaign: DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(props.campaign.id),
            supporter_action_type: props.campaign.campaign_type,
        })
        return acc
    }, [])
}

import styled from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const HeaderInputSpan = styled.span`
    font-size: .875rem;
    font-weight: 500;
    color: ${colors.gray7};
    display: flex;
    align-items: center;
    background-color: ${colors.gray1};
    border-radius: ${rules.BorderRadiusLeft};
    border-right: 1px solid ${colors.gray5};
    padding: 0 16px;
    line-height: 1rem;
`

export const HeaderInput = styled.input`
    border: 0;
    border-radius: ${rules.BorderRadiusRight};
    padding: 12px;
    color: ${colors.gray9};
    width: 100%;
    font-size: 1rem;
    line-height: 1rem;

    ::placeholder {
        color: ${colors.gray5};
    }

    &:focus {
        outline: 0;
    }
`

export const HeaderInputContainer = styled.div`
    background-color: ${colors.White};
    border-radius: ${rules.BorderRadius};
    border: 1px solid ${colors.gray5};
    display: flex;
    flex: 1;
    height: 40px;
    max-width: 608px;
    min-width: 250px;
    transition: border .3s ease-in-out, box-shadow .3s ease-in-out;

    &:focus-within {
        box-shadow: 0px 0px 0px 2px #7259EF7A;
        border: 1px solid ${colors.violet5};
    }

    &:hover, &:active {
        border: 1px solid ${colors.violet5};
    }

    &:active {
        box-shadow: 0px 0px 0px 1px #7259EF3D;
    }
`

/*
 * Quorum Typeahead List
 *   - takes in a groupLabel
 *   - selection handler
 *
 */
import React from "react"
import QuorumTypeAheadItem from "components/QuorumTypeAheadItem"
import PropTypes from "prop-types"

const propTypes = {
    groupLabel: PropTypes.string.isRequired,
    onSelectItem: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        // eslint-disable-next-line react/no-unused-prop-types
        id: PropTypes.any,
    })).isRequired,
    showGroupLabel: PropTypes.bool,
}

const defaultProps = {
    showGroupLabel: false,
}

export default class QuorumTypeAheadList extends React.Component {
    constructor(props) {
        super(props)
        this.onSelectItem = this.onSelectItem.bind(this)
    }

    // methods
    onSelectItem(data) {
        this.props.onSelectItem(data)
    }

    render() {
        // choose which display style to use
        if (this.props.options.length === 0) {
            return null
        }

        // make the class for the group
        let groupLabelClass = "list-group-item group-label"
        if (this.props.showGroupLabel === false) {
            groupLabelClass += " hidden"
        }

        // make a list of the list options
        const typeaheadLists = this.props.options.map((obj, index) => {
            // update the list objects
            const listObj = Object.assign({}, obj, {
                typeAheadGroup: this.props.groupLabel,
                text: obj.text || obj.name,
            })

            // make unique key
            return (
                <QuorumTypeAheadItem
                    key={`${this.props.groupLabel}${obj.id}`}
                    objData={listObj}
                    onSelectItem={this.onSelectItem}
                    index={index}
                />
            )
        })

        return (
            <ul className="list-group">
                <li className={groupLabelClass}>
                    {this.props.groupLabel}
                </li>
                {typeaheadLists}
            </ul>
        )
    }
}

QuorumTypeAheadList.propTypes = propTypes
QuorumTypeAheadList.defaultProps = defaultProps

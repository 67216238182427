import React from "react"

import styled from "styled-components"
import InputField from "frontend/forms/components/InputField"

const StyledLockContainer = styled.div`
    position: relative;
    .input-field.input-disabled.form-control {
        cursor: not-allowed !important;
    }
    & > div *:not(label):not(.form-input-header) {
        cursor: not-allowed;
    }
    .mantine-MultiSelect-wrapper:has(input:disabled) {
        pointer-events: unset;
    }
    i {
        opacity: 0.4;
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: 8px;
        margin-right: 8px;
    }
`

export const withLock = (Component) => (props) => {
    return (
        <StyledLockContainer className="form-input-field">
            <Component {...props} disabled />
            {<i className="fa fa-ban" />}
        </StyledLockContainer>
    )
}

export const InputWithLock = withLock(InputField)

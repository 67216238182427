/**
 * @file Utilities for making calls to the Quorum API
 *
 * For more details see,
 *  - https://quorumanalytics.atlassian.net/wiki/spaces/DEVTEAM/pages/1302036576/Use+fetch+not+DjangIO+for+API+requests
 *  - https://github.com/QuorumUS/quorum-site/pull/33546
 */

/**
 * Object representation of a URL, used as an alternative to passing a URL
 * directly.
 **/
export interface APIUrlOptions {
    /** The DjangIO Model, defining the first part of the URL */
    model?: { endpoint: string }
    /** If provided, will be used instead of model.endpoint (both can't be provided) */
    resourceUri?: string
    /** The primary key, which gets appended to the endpoint */
    primaryKey?: string | number
    /** The name of the action */
    action?: string
    /** URL Parameters, if set gets passed to URLSearchParameters */
    params?: ConstructorParameters<typeof URLSearchParams>[0]
}

/**
 * Build the URL for an API call from its object representation
 *
 * @param urlOptions - The object to be turned into a url (see APIUrlOptions type documentation)
 */
export const buildApiUrl = (urlOptions: APIUrlOptions) => {
    if ((!urlOptions.model && !urlOptions.resourceUri) || (urlOptions.model && urlOptions.resourceUri)) {
        throw new Error("Exactly one of model or resourceUri must be provided")
    }

    let url = urlOptions.model ? urlOptions.model.endpoint : urlOptions.resourceUri
    if (url.slice(-1) !== "/") {
        url += "/"
    }

    if (urlOptions.primaryKey) {
        url += `${urlOptions.primaryKey}/`
    }

    if (urlOptions.action) {
        url += `${urlOptions.action}/`
    }

    if (urlOptions.params) {
        const searchParams = new URLSearchParams(urlOptions.params)
        url += `?${searchParams}`
    }

    return url
}

/**
 * Exceptions caused by fetch returning an unexpected status code.
 *
 * As the name implies this will usually be used for non-OK (or non-2XX) status
 * codes, however in the rare case you expect a non-2XX status code this class
 * could be used for those as well.
 *
 * Example usage:
 *  const getSomething = async () => {
 *    const response = await fetch("/api/something")
 *    if (!response.ok) {
 *      throw ResponseNotOkError(response)
 *    }
 *    return response.json()
 *  }
 *
 * This serves the same purpose as AxiosError, but for fetch requests which by
 * default do not throw an error on non-2XX status codes.
 *
 * Note Axios and fetch response objects behave differently. For instance the
 * json() function should be used instead of the data property.
 */
export class ResponseNotOkError extends Error {
    response: Response

    constructor(response: Response, message?: string) {
        super(message || `Network request failed with status ${response.status}`)
        this.response = response
    }
}

/**
 * Possible options to the Fetch API. We use this when passing remaining options
 * to fetch to strip out options owned by us, and extraneous data.
 */
const FETCH_API_OPTION_KEYS = [
    "method",
    "headers",
    "body",
    "mode",
    "credentials",
    "omit",
    "same-origin",
    "include",
    "cache",
    "redirect",
    "referrer",
    "referrerPolicy",
    "integrity",
    "keepalive",
    "signal",
    "priority",
]

/**
 * Options for our custom fetch wrapper. This includes all the options you can
 * normally pass to fetch, plus our own custom options.
 */
export interface APIFetchOptions extends RequestInit {
    /** If true, and header not overwritten, include proper X-CSRFTOKEN header, forces same-origin (default true) */
    includeQuorumCsrfToken?: boolean
    /** If true, and header not overwritten, set Content-Type and Accept to application/json (default true) */
    includeContentTypeJson?: boolean
    /** If true, and response.ok is false, throw ResponseNotOkError (default true) */
    throwOnNotOk?: boolean
}

/** Helper function to return a promise that resolves a response if it's okay,
 * otherwise rejects with ResponseNotOkError */
const rejectPromiseIfResponseNotOk = (response: Response) => {
    if (response.ok) {
        return Promise.resolve(response)
    } else {
        return Promise.reject(new ResponseNotOkError(response))
    }
}

/** Internal helper function that handles shared logic for exposed API calls */
const apiFetchHelper = (urlLike: string | APIUrlOptions, options: APIFetchOptions): Promise<Response> => {
    const url = typeof urlLike === "string" ? urlLike : buildApiUrl(urlLike)

    const { includeQuorumCsrfToken = true, includeContentTypeJson = true, throwOnNotOk = true } = options

    // Build up the options we want to pass to fetch, initially using the
    // caller's options. It's important anything that touches fetchOptions after
    // this doesn't override values (including individual headers), since
    // caller-provided options should get priority.
    const fetchOptions: RequestInit = {}
    for (const key of FETCH_API_OPTION_KEYS) {
        if (options.hasOwnProperty(key)) {
            fetchOptions[key] = options[key]
        }
    }
    fetchOptions.headers = new Headers(fetchOptions.headers)

    if (includeQuorumCsrfToken && fetchOptions.headers.get("x-csrftoken") === null) {
        fetchOptions.headers.set("x-csrftoken", (window as any).Userdata.csrftoken)

        if (fetchOptions.mode && fetchOptions.mode !== "same-origin") {
            throw new Error(
                "To prevent leaking credentials, only same-origin requests allowed if includeQuorumCsrfToken is true",
            )
        } else {
            fetchOptions.mode = "same-origin"
        }
    }

    if (includeContentTypeJson) {
        if (fetchOptions.headers.get("Content-Type") === null) {
            fetchOptions.headers.set("Content-Type", "application/json")
        }
        if (fetchOptions.headers.get("Accept") === null) {
            fetchOptions.headers.set("Accept", "application/json")
        }
    }

    const responsePromise = fetch(url, fetchOptions)

    if (throwOnNotOk) {
        return responsePromise.then(rejectPromiseIfResponseNotOk)
    } else {
        return responsePromise
    }
}

/**
 * Make a GET request to the Quorum API.
 *
 * This function is simply a wrapper around `fetch`, but will add certain
 * headers and credentials by default (these modifications can be disabled).
 *
 * The `options` parameter accepts any option accepted by `fetch`, which will
 * override our default behavior.
 *
 * See documentation for fetch,
 *  https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 *
 * @param urlLike - The request url or an object representing the request url
 * @param options - Optional options to change the behavior of how the request is made
 */
const apiGet = (urlLike: string | APIUrlOptions, options?: APIFetchOptions): Promise<Response> => {
    return apiFetchHelper(urlLike, { method: "GET", ...options })
}

/**
 * Make a POST request to the Quorum API.
 *
 * Like `apiGet`, this is simply a wrapper around fetch. Using this function is
 * equivalent to,
 *
 *  `apiGet(urlLike, { method: "POST", body: JSON.stringify(body), ...options })`
 *
 * See documentation for fetch,
 *  https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 *
 * @param urlLike - The request url or an object representing the request url
 * @param body - The un-serialized request body, which will be serialized with
 *               `JSON.stringify`
 * @param options - Optional options to change the behavior of how the request is made
 */
const apiPost = (urlLike: string | APIUrlOptions, body: any, options?: APIFetchOptions) => {
    return apiFetchHelper(urlLike, { method: "POST", body: JSON.stringify(body), ...options })
}

/**
 * Make a PATCH request to the Quorum API.
 *
 * Like `apiGet`, this is simply a wrapper around fetch. Using this function is
 * equivalent to,
 *
 *  `apiGet(urlLike, { method: "PATCH", body: JSON.stringify(body), ...options })`
 *
 * See documentation for fetch,
 *  https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 *
 * @param urlLike - The request url or an object representing the request url
 * @param body - The un-serialized request body, which will be serialized with
 *               `JSON.stringify`
 * @param options - Optional options to change the behavior of how the request is made
 */
const apiPatch = (urlLike: string | APIUrlOptions, body: any, options?: APIFetchOptions) => {
    return apiFetchHelper(urlLike, { method: "PATCH", body: JSON.stringify(body), ...options })
}

export const api = {
    get: apiGet,
    post: apiPost,
    patch: apiPatch,
}

declare global {
    interface Window {
        /** For debugging/development only; use `import { api } from "@/api"` in production code */
        QuorumApi?: typeof api
    }
}

window.QuorumApi = api

import React from "react"

import * as S from "Components/Atoms/FilterListLabel/style"

import Icon from "Components/Atoms/Icon"


const FilterListLabel = ({
    aiSearch,
    text
}) => (
    <S.FilterListLabel>
        {
            aiSearch &&
            <Icon
                icon="sparkles"
                iconFamily="fas"
                size="12px"
                fixedWidth={false}
            />
        }
        <div>{text}</div>
    </S.FilterListLabel>
)

export default FilterListLabel

import styled, { css } from "styled-components"

import * as colors from "Components/colors"

export const Switch = styled.label`
    display: flex;
    align-items: center;
    gap: 12px;

    cursor: pointer;
    color: ${colors.gray7};

    ${props => props.disabled &&  css`
        color: ${colors.SilverChalice};
        cursor: default;
    `}
`

export const Container = styled.div`
    position: relative;
    width: 40px;
    height: 27px;

    border-radius: 40px;

    background-color: ${colors.QuorumGrey5};
    transition: background-color .4s;

    ${props => props.isChecked && !props.disabled && css`
        background-color: ${colors.violet5};
    `}
`

export const Knob = styled.div`
    position: absolute;
    height: 21px;
    width: 21px;
    left: 3px;
    bottom: 3px;

    background-color: white;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        opacity: 0;
        transition: opacity .2s;
        ${props => props.isChecked && css`
            opacity: 1;
        `}
    }

    transition: transform .4s;
    ${props => props.isChecked && css`
        transform: translateX(13px);
    `}
`

export const CheckboxInput = styled.input.attrs({
    type: "checkbox"
})`
    opacity: 0;
    width: 0;
    height: 0;

    &:focus + ${Container} {
        box-shadow: 0 0 0 2px ${colors.LinkPurple};
    }

    ${props => props.disabled && css`cursor: default;`}
`

export const Children = styled.div`
    &&, && > * {
        ${props => !props.checked && css`
            color: ${colors.gray7};
        `}
    }
`

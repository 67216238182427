// These constants are used to determine the size of a DTV pill for section
export const DLC_SM_CHAR_WIDTH = 20

export const DLC_MD_CHAR_WIDTH = 45

export const DLC_FULL_WIDTH = 2

export const DLC_HALF_WIDTH = 1

export const MAP_MODES = {
    SINGLE_MAP: "SINGLE_MAP",
    SHAPE_IN_PARENT: "SHAPE_IN_PARENT",
}

export const MCKESSON_ID = 659

export const CUSTOM_FIELDS_TITLE = "Custom Fields"

export const TEXT_URL_DELIMITER = "\u2561\u2561\u0040\u0040"

import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"


export const LegislationSummary = styled.div`
    display: flex;
    flex-direction: row;

    ${rules.MediumTextLightWeight}
    color: ${colors.gray7};
    font-weight: 400;

`

export const SessionInfo = styled.span`
    margin-left: auto;
`

export const BoldSpan = styled.span`
    ${rules.MediumWeight}
`

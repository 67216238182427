import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/LinkCallToAction/style"

import Icon from "Components/Atoms/Icon"

const LinkCallToAction = ({
    customMargin,
    dataCy,
    leftArrow,
    onClick,
    text,
    type,
}) => (
    <S.LinkWrapper
        data-auto-cy="MoleculeLinkCallToAction"
        data-cy={dataCy}
        customMargin={customMargin}
        onClick={onClick}
    >
        {leftArrow &&
            <Icon
                icon="long-arrow-left"
                iconFamily={type === "lg" ? "far" : "fal"}
                size="lg"
            />
        }

        <S.Text type={type}>
            {text}
        </S.Text>
        { !leftArrow &&
            <Icon
                icon="long-arrow-right"
                iconFamily={type === "lg" ? "far" : "fal"}
                size="lg"
            />
        }

    </S.LinkWrapper>
)

LinkCallToAction.defaultProps = {
    type: "lg",
}

LinkCallToAction.propTypes = {
    customMargin: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    text: PropTypes.node.isRequired,
    type: PropTypes.oneOf(["lg", "md"]),
    leftArrow: PropTypes.bool,
}

export default LinkCallToAction

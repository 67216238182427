// https://projects.invisionapp.com/d/main#/console/19025613/400363186/preview
// https://projects.invisionapp.com/d/main#/console/18861190/401665419/preview

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/HeaderInput/style"

const HeaderInput = ({
    onChange,
    placeholder,
    value,
}) => {
    return (
        <S.HeaderInputContainer data-auto-cy="MoleculeHeaderInput">
            <S.HeaderInputSpan>Name</S.HeaderInputSpan>
            <S.HeaderInput
                onChange={(event) => onChange(event.target.value)}
                placeholder={placeholder}
                value={value}
            />
        </S.HeaderInputContainer>
    )
}

HeaderInput.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
}

export default HeaderInput

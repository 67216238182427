import styled, { css } from "styled-components"

// we only want to set the HeaderContent to "overflow: scroll" for Bar Visualizations

// for Bar overflow mode to work correctly,
// the initial height of the svg needs to incorporate the extra pixels that macOS
// adds to the parent div when
// System Preferences.app => Show scroll bars =>
//      "Automatically based on mouse or trackpad" (only for the mouse) or
//      "Always"
// are selected.

// this is necessary because macOS overlays the scrollbars for the trackpad
// but modifies the height of the div for the mouse/"Always".
export const VisualizationBarWidget = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    ${({isHorizontal}) => isHorizontal ? "overflow-y" : "overflow-x"}: scroll;
    position: relative;
`

export const VisualizationBarWidgetLoadAllButton = styled.div`
    position: fixed;
    right: 50px;
    top: 10px;
    z-index: 1;

    ${props => (props.noHeader) && css`
        // if there is no Widget S.Header,
        // prevent the Download button from overlapping with the
        // VisualizationBarWidgetLoadAllButton
        right: 45px;
    `}
`

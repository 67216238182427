export default class Model {
    constructor(_serialized) {
        const {
            fields,
            __name__,
            endpoint,
            data_model,
            default_filters,
            default_exclude_filters,
            proxy_endpoint,
            external_products,
        } = _serialized

        this.fields = fields
        this.__name__ = __name__
        this.endpoint = endpoint
        this._serialized = _serialized
        this.default_filters = default_filters
        this.default_exclude_filters = default_exclude_filters
        this.proxy_endpoint = proxy_endpoint
        this.data_model = data_model
        this.external_products = external_products
    }
    get objects() {
        return new DjangIO.Manager(this)
    }
    get _meta() {
        const fields = this.fields
        const default_filters = this.default_filters
        const default_exclude_filters = this.default_exclude_filters
        return {
            default_filters,
            default_exclude_filters,
            fields,
            get_field(fieldName) {
                return fields.find((field) => field.name === fieldName)
            },
        }
    }
}

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/StatusIndicator/style"

const StatusIndicator = ({
    isActive,
    text,
}) => (
    <S.StatusIndicator data-auto-cy="MoleculeStatusIndicator">
        <S.Container>
            <S.Circle data-title={text} isActive={isActive}/>
        </S.Container>
    </S.StatusIndicator>
)

StatusIndicator.defaultProps = {
    isActive: false,
}

StatusIndicator.propTypes = {
    // whether this indicator is active
    isActive: PropTypes.bool,
    // the text label for this indicator
    text: PropTypes.string,
}

export default StatusIndicator

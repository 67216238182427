import styled from "styled-components"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import {
    styleInMobileScreenSize,
    getFontColorForBackgroundContrast,
} from "QuorumGrassroots/styled-components/helperFunctions"

export const StyledCallToActionWidget = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1600px;
    width: 100%;
    ${styleInMobileScreenSize("flex-direction: column;")}
    ${(props) => (props.isLeftAligned ? "flex-direction: row;" : "flex-direction: row-reverse;")}
`

export const StyledContentBlock = styled.div`
    display: flex;
    flex-direction: column;
    ${styleInMobileScreenSize(`
        margin: 10px;
        width: 90%;
    `)}
    ${(props) =>
        props.useBackgroundColor &&
        props.backgroundColor &&
        `color: ${getFontColorForBackgroundContrast(props.backgroundColor)};`}
    ${(props) => (props.isLeftAligned ? "margin-right: 24px;" : "margin-left: 24px;")}
    ${(props) =>
        props.mediaType && props.mediaType !== DjangIO.app.grassroots.enums.CallToActionMediaType.none.value
            ? "width: 50%;"
            : `
                max-width: 50%;
                margin-left: 0px;
                margin-right: 0px;
            `}
`

export const StyledImage = styled.div`
    background-image: url("${(props) => props.imageUrl}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 390px;
    width: 50%;
    ${styleInMobileScreenSize("width: 90%;")}
    ${(props) => (props.imageContain ? "background-size: contain;" : "background-size: cover;")}
`

export const StyledCallToActionText = styled.div`
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
`

export const StyledCallToActionButton = styled(StyledButton)`
    align-self: flex-start;
    display: flex;
    width: auto;
    ${styleInMobileScreenSize("align-self: center;")}
`

export const StyledTitle = styleWithOrgDesign(styled.div`
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 24px;
    ${(props) => !props.useBackgroundColor && `color: ${props.organizationDesign.primary_color}`}
`)

export const StyledVideoWrapper = styled.div`
    width: 50%;
    ${styleInMobileScreenSize("width: 90%;")}
`

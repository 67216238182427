import { createSelector, createStructuredSelector } from "reselect"
import { Map, List, fromJS } from "immutable"

import { selectWidgetSlice } from "QuorumGrassroots/widgets/selectors"

/**
 * [description]
 * @param  {[type]} messageMap [description]
 * @return {[type]}            [description]
 */
export const flattenMessageMap = (messageMap) => {
    return messageMap
        .reduce((acc, message, messageId) => {
            const flattened = message
                .set("message_id", messageId)
                .set("targeted", message.getIn(["edits", "targeted"]))
                .delete("edits")

            return acc.push(flattened)
        }, List())
        .sort((a, b) => a.get("order") - b.get("order"))
}

export const selectActiveSelectId = createSelector(selectWidgetSlice, (widgetSlice) => widgetSlice.get("activeSelect"))

export const selectActiveGroupId = createSelector(selectWidgetSlice, (widgetSlice) => widgetSlice.get("activeGroup"))

export const selectMessagesLoaded = createSelector(selectWidgetSlice, (widgetSlice) =>
    widgetSlice.get("messagesLoaded"),
)

export const selectAreMessagesLoading = createSelector(selectWidgetSlice, (widgetSlice) =>
    widgetSlice.get("messagesLoading"),
)

export const selectImmutableCampaignMessages = createSelector(selectWidgetSlice, (widgetSlice) =>
    widgetSlice.get("messages", Map()),
)

export const selectHasMessages = createSelector(selectImmutableCampaignMessages, (messages) => Boolean(messages.size))

export const selectSimpleImmutableCampaignMessages = createSelector(selectWidgetSlice, (widgetSlice) =>
    widgetSlice.get("messages", List()),
)

// We need to flatten the object message array. This will
// be array we pass to any components that need to render messages.
// Use selectImmutableCampaignMessages, non-list edition inside selectors.
export const selectImmutableCampaignMessageList = createSelector(selectImmutableCampaignMessages, (messages) =>
    flattenMessageMap(messages),
)

export const selectSimpleCampaignMessageList = createSelector(selectSimpleImmutableCampaignMessages, (messages) =>
    messages.toJS(),
)

export const selectCampaignMessageList = createSelector(selectImmutableCampaignMessageList, (messages) =>
    messages.toJS(),
)

export const selectCampaignMessageGroups = createSelector(selectImmutableCampaignMessageList, (messages) => {
    const groups = messages
        .map((message) => message?.get("group"))
        .toSet()
        .toList()
        .filter((group) => group) //remove undefined groups

    return groups.size
        ? groups
              .map((group) =>
                  group.set(
                      "messages",
                      messages.filter((message) => message.getIn(["group", "id"]) === group.get("id")),
                  ),
              )
              .map((group) =>
                  group.set(
                      "targets",
                      //since each message is meant for a target, we can map the messages to get the targets
                      group.get("messages").map((message) =>
                          fromJS({
                              value: message.getIn(["target", "id"]).toString(),
                              label: message.getIn(["target", "name"]),
                              imageUrl: message.getIn(["target", "image_url"]),
                              targeted: message.get("targeted"),
                              is_custom: message.getIn(["target", "is_custom"], false),
                          }),
                      ),
                  ),
              )
              .toJS()
        : []
})

export const selectMultiSelectOptions = createSelector(
    selectImmutableCampaignMessageList,
    selectCampaignMessageGroups,
    selectActiveGroupId,
    selectWidgetSlice,
    (messages, messageGroups, activeGroupId, widgetSlice) => {
        //combined campaigns also might have message groups, we can differ them from write a letter if they have a campaign_id on the messages
        const isCombinedCampaign =
            widgetSlice.getIn(["content", "campaign_type"]) ===
            DjangIO.app.grassroots.campaign.types.CampaignType.combined_campaign.value
        if (messageGroups?.length && !isCombinedCampaign) {
            const targets = messageGroups.find((group) => Number(group.id) === Number(activeGroupId))?.targets
            return targets
        }
        return messages.reduce((acc, message) => {
            const targetName = message.getIn(["target", "name"])
            if (targetName) {
                acc.push({
                    value: String(message.get("message_id")),
                    label: message.getIn(["target", "name"]),
                    imageUrl: message.getIn(["target", "image_url"]),
                    targeted: message.get("targeted"),
                })
            }
            return acc
        }, [])
    },
)

export const selectNumberOfMessages = createSelector(selectImmutableCampaignMessages, (messages) => messages.size)

export const selectHasMultipleMessages = createSelector(
    selectNumberOfMessages,
    (numberOfMessages) => numberOfMessages > 1,
)

export const selectExtraMessageData = createSelector(selectWidgetSlice, (widgetSlice) =>
    widgetSlice.get("extraMessageData", Map()),
)

export const selectSentMessageIds = createSelector(selectWidgetSlice, (widgetSlice) =>
    widgetSlice.get("sentIds", List()),
)

export const selectCampaignTargetList = createSelector(selectImmutableCampaignMessageList, (messages) =>
    messages
        .map((message) => {
            return {
                id: message.get("message_id"),
                name: message.getIn(["target", "name"]),
                targeted: message.get("targeted"),
            }
        })
        .toJS(),
)

export const selectTargetedMessageIds = createSelector(selectImmutableCampaignMessageList, (messages) =>
    messages.filter((message) => message.get("targeted")).map((message) => message.get("message_id")),
)

export const selectRemainingMessageIds = createSelector(
    [selectTargetedMessageIds, selectSentMessageIds],
    (messageIds, sentIds) => messageIds.filter((id) => !sentIds.includes(id)).toJS(),
)

export const selectDropdownSelectOptions = createSelector(
    [selectImmutableCampaignMessageList, selectRemainingMessageIds],
    (messages, remainingIds) => {
        return messages
            .filter((message) => remainingIds.includes(message.get("message_id")))
            .map((message) => ({ value: message.get("message_id"), label: message.getIn(["target", "name"], "") }))
            .toJS()
    },
)

export const selectNumberOfMessagesAfterFiltering = createSelector(
    selectRemainingMessageIds,
    (messageIds) => messageIds.length,
)

export const createCampaignWithMessagesSelector = (additionalSelectors) =>
    createStructuredSelector({
        activeGroupId: selectActiveGroupId,
        activeSelectId: selectActiveSelectId,
        messagesLoaded: selectMessagesLoaded,
        messages: selectCampaignMessageList,
        messageGroups: selectCampaignMessageGroups,
        multiSelectOptions: selectMultiSelectOptions,
        targets: selectCampaignTargetList,
        hasMessages: selectHasMessages,
        numberOfMessagesAfterFiltering: selectNumberOfMessagesAfterFiltering,
        extraMessageData: selectExtraMessageData,
        selectOptions: selectDropdownSelectOptions,
        remainingMessageIds: selectRemainingMessageIds,
        // customizable
        ...additionalSelectors,
    })

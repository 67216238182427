import React from "react"
import PropTypes from "prop-types"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import { StyledSignUpDescription } from "QuorumGrassroots/widgets/SignUp/components/style"

import LoginUpdateOrRender from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/LoginUpdateOrRender"

import TextWidget from "QuorumGrassroots/widgets/Text/components/index"
import { ThanksDisplay } from "QuorumGrassroots/widgets/ReusableComponents/ThanksDisplay/index"
import { useNavigate } from "react-router-dom"

// this is a weird widget. SignUpWidget doesn't have its own slice
// of the store because it doesn't really need it -- it just looks
// in the store for the corresponding registration page and checks
// whether or not it's filled out. If it's filled out, it will
// show either text or a thanks page, and will possibly redirect
// the user afterwards.
//
// This is subtly different behavior from the ThankableWrapper
// (basically, use ThankableWrapper pre-thank state will
// never change--in this case, it does depending on how much
// the user has filled out).
//
// We might be able to finesse the details of this at a later date.

export const SignUpWidget = ({ t, ...props }) => {
    const navigate = useNavigate()

    const onComplete = () => {
        if (props.redirectDetails) {
            if (props.redirectDetails.isInternal) {
                //This is required because this onComplete function is called during the componentDidMount and componentDidUpdate
                //lifecycle methods of the LoginUpdateOrRender component. The render of the component cancels the navigation.
                //This timeout defers the navigation until after the render has completed.
                setTimeout(() => navigate(props.redirectDetails.url), 0)
            } else {
                window.location.assign(props.redirectDetails.url)
            }
        }
    }

    return (
        <WidgetStyleWrapper
            className="sign-up-widget"
            md={props.colWidth}
            useWidgetStyling={props.useWidgetStyling}
            title={props.title}
            isForm
        >
            {props.content && (
                <StyledSignUpDescription>
                    <div dangerouslySetInnerHTML={{ __html: props.content }} />
                </StyledSignUpDescription>
            )}
            <LoginUpdateOrRender
                {...props}
                registrationPageIds={props.registrationPageIds}
                useWidgetStyling={false}
                showOnlyUnfilledFields={false}
                renderedComponent={props.post_text ? TextWidget : ThanksDisplay}
                renderedProps={props.signedUpPassThroughProps}
                onComplete={onComplete}
                shouldCompleteOnMount={props.shouldCompleteOnMount}
            />
        </WidgetStyleWrapper>
    )
}

SignUpWidget.propTypes = {
    // from widget embedded in a page
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    description: PropTypes.string,
    shouldCompleteOnMount: PropTypes.bool.isRequired,
    // from react router
    params: PropTypes.object,
    // from connect
    registrationPageIds: PropTypes.arrayOf(PropTypes.number),
    signedUpPassThroughProps: PropTypes.object,
}

SignUpWidget.defaultProps = {
    useWidgetStyling: true,
}

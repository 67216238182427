import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm } from "redux-form/immutable"

import { writeCampaignSelectors } from "QuorumGrassroots/campaign-forms/selectors/write-selectors"

import {
    changeTarget,
    changeMessageGroup,
    deselectAllTargets,
    getCampaignMessages,
    loadCampaignMessages,
    selectAllTargets,
    submitWriteForm,
    toggleTarget,
} from "QuorumGrassroots/campaign-forms/action-creators"
import { disableOneClickRegistration } from "QuorumGrassroots/framework/action-creators"

import { StyledContrastText } from "QuorumGrassroots/styled-components/components/StyledContrastText"

/**
 * This is the wrapper to put around a form in the case it has
 * any type of write messages. This is for write campaigns, write white house campaigns,
 * regulation campaigns, and other things.
 */
export const getMessageFormWrapper =
    ({
        formName,
        getKwargs = {},
        generateGrassrootsActions,
        getIdFromMessage = () => "default",
        writeSectionConfigs = {},
        isCombinedCampaign = false,
    }) =>
    (WrappedComponent) => {
        class GetMessageFormComponent extends Component {
            componentDidMount() {
                const {
                    campaign,
                    getCampaignMessages,
                    initialize,
                    loadCampaignMessages,
                    messagesLoaded,
                    uniqueWidgetId,
                } = this.props

                loadCampaignMessages({
                    campaign,
                    context: this,
                    getCampaignMessagesFromProps: getCampaignMessages,
                    getIdFromMessage,
                    getKwargs,
                    initialize,
                    messagesLoaded,
                    uniqueWidgetId,
                })
            }

            render() {
                return this.props.messagesLoaded ? (
                    <WrappedComponent {...this.props} formName={formName} writeSectionConfigs={writeSectionConfigs} />
                ) : (
                    <div>
                        <StyledContrastText isCampaignPage>{this.props.t("loading")}</StyledContrastText>
                    </div>
                )
            }
        }

        const ReduxWriteForm = reduxForm({
            form: formName,
            onSubmit: submitWriteForm(generateGrassrootsActions, isCombinedCampaign),
            // allow the form to reinitialize after the campaign messages are received!
            enableReinitialize: true,
            destroyOnUnmount: true,
            keepDirtyOnReinitialize: true,
        })(GetMessageFormComponent)

        const actions = {
            changeTarget,
            changeMessageGroup,
            disableOneClickRegistration,
            getCampaignMessages,
            loadCampaignMessages,
            toggleTarget,
            selectAllTargets,
            deselectAllTargets,
        }

        return connect(writeCampaignSelectors, actions)(ReduxWriteForm)
    }

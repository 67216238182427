import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"

import {
    LogInteractionWidgetComponent,
    LogRelationshipWidgetComponent,
} from "QuorumGrassroots/widgets/ExternalLog/components/index"

import { createStandardThankableWidget } from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/ThankableWrapper"

import { externalLogWidgetConnection, selectThanksProps } from "QuorumGrassroots/widgets/ExternalLog/selectors"

const generateLogContainer = (campaignType) => {
    const { CampaignType } = DjangIO.app.grassroots.campaign.types

    let form
    switch (campaignType) {
        case CampaignType.log_interaction.value:
            form = LogInteractionWidgetComponent
            break
        case CampaignType.log_relationship.value:
            form = LogRelationshipWidgetComponent
            break
        default:
            break
    }

    const connectedForm = connect(externalLogWidgetConnection(campaignType), null)(form)

    const thankableForm = createStandardThankableWidget(connectedForm, selectThanksProps(campaignType))

    return withNamespaces()(thankableForm)
}

export const LogInteractionWidget = generateLogContainer(
    DjangIO.app.grassroots.campaign.types.CampaignType.log_interaction.value,
)
export const LogRelationshipWidget = generateLogContainer(
    DjangIO.app.grassroots.campaign.types.CampaignType.log_relationship.value,
)

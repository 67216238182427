const colors = {
    // Quorum Brand Colors
    QuorumBrass: "#A88F4D",

    // Colors
    QuorumViolet:"#503ABD",
    LightQuorumBlue: "#dde3ee",
    QuorumBlue: "#503ABD",
    LightBlue: "#e7eaf3",
    MiddleBlue: "#acb2bf",
    QuorumGrey1: "#ecf0f1",
    QuorumGrey2: "#b4bcc2",
    QuorumGrey3: "#95a5a6",
    NewGrey3: "#DEE2E6",
    NewGrey7: "#495057",
    QuorumGrey4: "#7B8A8B",
    QuorumGrey5: "#ccc",
    QuorumGrey6: "#f5f5f5",
    QuorumGrey7: "#777",
    DarkGrey: "#687374",
    PrivateGrey: "#e6e6e6",
    RainDance: "#809393",
    StandardGrey: "#666",
    SelectBorderGrey: "#b6c0da",
    HeaderBorderBlueColor: "#526aa4",
    // DarkPurple: "#3D3262",
    // MiddlePurple: "#5f2b7b",
    LightPurple: "#503ABD",
    BodyTextColor: "#0e121c",
    // HyperlinkColor: "#854EA4",
    White: "#fff",
    Black: "#000",
    Violet2: "#D9D8FC",
    Violet6: "#6149D6",
    RepublicanMainColor: "#D62728",
    RepublicanDarkColor: "#951b1c",
    RepublicanLightColor: "#ff3739",
    DemocratMainColor: "#1f77b4",
    DemocratDarkColor: "#15537d",
    DemocratLightColor: "#2caaff",
    PNPMainColor: "#203d8a",
    PPDMainColor: "#e81728",
    PIPMainColor: "#227d16",
    PPTMainColor: "#62378d",
    EPPMainColor: "#3399FF",
    SDMainColor: "#FF0000",
    ECRMainColor: "#0000FF",
    ALDEMainColor: "#FFD700",
    GUENGLMainColor: "#990000",
    GreensEFAMainColor: "#009900",
    EFDDMainColor: "#24B9B9",
    ENLMainColor: "#2B3856",
    PFEMainColor: "#1F578A",
    ESNMainColor: "#2F1C59",
    OtherColor1: "#4D2D5F",
    OtherColor2: "#2F3A54",
    OtherColor3: "#2B1F51",
    HoverColor: "#D3CFDD",
    FacebookBlue: "#3B5998",
    TwitterLightBlue: "#00aced",
    TwitterBlue: "#4099FF",
    TwitterDarkBlue: "#0084B4",
    TwitterDarkestBlue: "#0079A5",
    LinkedInBlue: "#0177b5",
    GooglePlusOrange: "#dd5044",
    PinterestRed: "#d8121d",
    MediumGreen: "#02b875",
    YoutubeRed: "#cc181e",
    InstagramPurpleViolet: "#8a3ab9",
    InstagramBlue: "#4c68d7",
    InstagramMaroon: "#cd486b",
    InstagramOrange: "#fbad50",
    InstagramYellow: "#fccc63",
    InstagramRedViolet: "#bc2a8d",
    InstagramRedOrange: "#e95950",
    ActionButtonHover: "#adadad",
    DropdownItemHover: "#ebebeb",
    SearchGraphBorder: "#DDD",
    SupporterColor: "#555",
    PCardColor: "#0088cc",
    LightBorderColor: "#ced5e7",
    StatGrey: "#6e7890",
    CokeRed: "#F40009",
    CokeDarkRed: "#900E0E",
    VotePassedColor: "#2E8B57",
    VoteFailedColor: "#FF510C",
    VotePresentColor: "#FFD700",
    VoteMissedColor: "#A020F0",
    VoteUnknownColor: "#BEBEBE",
    SidebarBorderColor: "#3c4147",
    // TopBarBorderColor: "#c6d0da",
    TopBarHoverColor: "#5f6f81",
    UnseenColor: "#D5D7DC",
    InlineHoverColor: "#fbfcfc",
    SelectDropdownColor: "#b2c1c2",
    ProfileDetailGrey: "#808080",
    PrimaryBorderColor: "#526aa4",
    FormControlBorderColor: "#dce4ec",
    RequestDemoPurple: "#8a3bb7",
    RequestDemoBorderPurple: "#563767",
    // RequestDemoHoverPurple: "#854ea4",
    EmailSignupBorder: "#dda0ff",
    ReduxFormErrorColor: "#E76B74",
    ReduxFormWarningColor: "#FACE00",
    ErrorRed: "#D81C15",
    WarningOrange: "#ED7806",
    HelpCenterPanelHover: "#dee2e3",
    // DarkerPorcelain: "#F1F3F4",

    IconBorder: "#ced4da",
    InputTextColor: "#495057",

    // Design System named colors
    AquaHaze: "#EBEAFD",
    CapeCod: "#404B4C",
    DarkerPorcelain: "#F1F3F4",
    DarkPurple: "#3D3262",
    // This is an approximation of DarkPurple at 10% alpha
    DarkPurple10: "#E6E2EB",
    Heather: "#C6D0DA",
    Iron: "#D3D7D8",
    LinkPurple: "#503ABD",
    MidPurple: "#7259EF",
    LightUltraviolet: "#EBE8FD",
    Ultraviolet: "#7259EF",
    CompletedGreen: "#5F8C2E",
    DarkViolet: "#4E30A1",
    // Tints
    AthensGray: "#EAEBEF",
    SilverChalice: "#AAAAAA",
    unnamedE5E5E5: "#E5E5E5",
    DarkGray: "#7B88A3",
    Gray: "#F8F9FA",
    LightGray: "#D3D3D3",

    // Unnamed Colors
    CloudBurst: "#2F1980",
    unnamed222222: "#222222",
    unnamedFCFCFD: "#FCFCFD",

    unnamedF8F9F9: "#F8F9F9",
    unnamedF5F7F7: "#F5F7F7",
    unamed9EA9AA: "#9EA9AA",

    // New color scheme
    // Violet
    violet0: "#FBFAFE",
    violet1: "#EBEAFD",
    violet2: "#D9D8FC",
    violet3: "#BDB8FA",
    violet4: "#9B8FF6",
    violet5: "#7259EF",
    violet6: "#6149D6",
    violet7: "#503ABD",
    violet8: "#3E2AA3",
    violet9: "#2F1980",

    //Gray
    gray0: "#FAFBFC",
    gray1: "#F1F3F5",
    gray2: "#E9ECEF",
    gray3: "#DEE2E6",
    gray4: "#CED4DA",
    gray5: "#ADB5BD",
    gray7: "#495057",
    gray9: "#212529",

    red1: "#FCC9CB",
    red7: "#C32219",
    red9: "#A80903",

    white20percent: "#FFFFFF33",
}

// Duotone Icon Color Pairs
const duotonePairs = {
    duotonePurple: `
        --fa-primary-color: ${colors.violet7};
        --fa-secondary-color: ${colors.violet5};
    `,
    duotoneWhite: `
        --fa-primary-color: ${colors.QuorumBlue};
        --fa-secondary-color: ${colors.White};
        --fa-secondary-opacity: 1;
    `,
    duotoneBlue: `
        --fa-primary-color: ${colors.White};
        --fa-secondary-color: ${colors.violet5};
        --fa-secondary-opacity: 1;
    `,
    duotoneRed: `
        --fa-primary-color: ${colors.White};
        --fa-secondary-color: ${colors.ErrorRed};
        --fa-secondary-opacity: 1;
    `,
    duotoneOrange: `
        --fa-primary-color: ${colors.White};
        --fa-secondary-color: ${colors.WarningOrange};
        --fa-secondary-opacity: 1;
    `
}

module.exports = Object.assign(colors, duotonePairs)

import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const LinkWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    color: ${colors.QuorumBlue};
    flex-shrink: 0;
    ${props => props.customMargin && `margin: ${props.customMargin};`}}

    *:first-child {
        margin-right: 2px;
    }

    svg {
        transform: translate(2px);
    }
`

export const Text = styled.span`
    ${props => props.type === "lg" ? rules.LargeText : rules.SmallText}
`

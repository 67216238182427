import styled, { css } from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const TextInput = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const LabelContainer = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: .25rem;
`

export const Label = styled.label`
    ${rules.MediumTextRegularWeight}
    line-height: revert;
    color: ${colors.Black};
    margin-bottom: 0px;

    ${props => props.disabled && css`
        color: ${colors.SilverChalice};
    `}

    ${props => props.labelStyle && css`
        ${props.labelStyle}
    `}
`

export const LabelPrefix = styled.span`
    ${rules.MediumTextMediumWeight}
    color: ${colors.QuorumBlue};
`

export const SubLabel = styled.div`
    ${rules.SmallText}
    color: ${colors.SilverChalice};
`

export const ShowPassword = styled(SubLabel)`
    user-select: none;
    cursor: pointer;
    transition: ${rules.TransitionShort};
    &:hover {
        color: ${colors.QuorumGrey4};
    }

    &:active {
        color: ${colors.QuorumGrey7};
    }

    ${props => props.showPasswordStyle && css`
        ${props.showPasswordStyle}
    `}
`

export const Input = styled.input.attrs(props => ({
    type: props.isPassword ? "password": "text",
}))`
    flex-grow: 1;
    height: ${rules.ButtonHeight};
    padding: 8px 11px;
    background-color: ${colors.White};
    border-radius: ${rules.BorderRadius};
    border: none;
    outline: none;
    min-width: 0;

    ${rules.SmallText}
    color: ${colors.Black};

    // for textarea element
    resize: none;

    ${props => props.isCentered && css`
        padding: 4px 0;
        text-align: center;
    `}

    &::placeholder {
        ${rules.SmallTextRegularWeight}
        line-height: revert;
        color: ${colors.SilverChalice};
        opacity: 1;
        font-style: italic;
    }

    &:hover, &:focus, &:focus-visible {
        border: none;
        outline: none;
    }

    &:disabled {
        border: none;
        background-color: ${colors.unnamedF8F9F9};
        color: ${colors.QuorumGrey4};
    }
`

export const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    border: 1px solid ${colors.FormControlBorderColor};
    border-radius: 4px;
    background-color: ${colors.White};

    transition: ${rules.BorderColorTransition};

    &:hover {
        border: 1px solid ${colors.QuorumBlue};
    }

    /* Nesting under these selectors to boost specificity without using !important */
    &&, &&:focus-within, &&:hover {
        ${props => props.isActive && css`
            border: 2px solid ${colors.QuorumBlue};
        `}

        ${props => props.disabled && css`
            border: 1px solid ${colors.FormControlBorderColor};
            background-color: ${colors.unnamedF8F9F9};
        `}

        ${props => (props.hasError || props.hasWarning) && css`
            border: 1px solid ${props.hasError ? colors.ErrorRed : colors.WarningOrange};
        `}
    }

    & > textarea {
        min-height: 120px;
    }

    ${props => props.style && css`
        ${props.style}
    `}

    ${props => props.isActive && !props.disableActiveShadow && css`
        /* This is the right way to do it, but Chrome's :focus outline doesn't respect border-radius */
        /* outline: 2px solid ${colors.QuorumBlue}; */
        outline: none;
        border-radius: 5px;
        box-shadow: 0 0 0 2px ${colors.QuorumBlue};
    `}
`

export const Description = styled.span`
    display: flex;
    margin-top: 8px;
    text-align: start;

    ${props => (props.hasError || props.hasWarning)
        ? css`
            ${rules.SmallTextLightWeight}
            font-weight: 400;
            color: ${props.hasError ? colors.ErrorRed : colors.WarningOrange};
        `
        : css`
            ${rules.MinisculeText}
            font-weight: 400;
            color: ${colors.QuorumGrey4};
        `
    }

    svg {
        margin-right: 5px;
    }
`

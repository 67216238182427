import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"

import Official from "QuorumGrassroots/widgets/Official/components/index"

import initializer from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/InitializeStoreWrapper"
import { officialSelector } from "QuorumGrassroots/widgets/Official/selectors"

const connectedOfficial = connect(officialSelector, null)(Official)

const initializedOfficial = initializer(connectedOfficial)

export default withNamespaces()(initializedOfficial)

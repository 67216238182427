/* eslint-disable import/namespace */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useMemo } from "react"

import * as d3v6 from "d3v6"
import moment from "moment"
import * as topojson from "topojson-client"

// renderers
import {
    hideTooltip,
    renderTooltip,
    showTooltip,
} from "app/static/frontend/widgets/components/visualization/renderers/sharedRenderers"

// selectors
import { selectShowInSearchPermanentFilters } from "app/static/frontend/widgets/selectors/listWidgetSelectors"

// helpers
import { isColor } from "app/static/frontend/imports/styleHelperFunctions"
import { euNameLatinToD3 } from "app/static/frontend/widgets/helpers/visualization/mapVisualizationWidgetHelpers"

// constants
import dashboardConstants from "app/static/frontend/dashboards/constants"
import { getSupportedRegions } from "shared/imports/regionConstants"
import { QuorumBlue } from "app/static/frontend/design-constants"

const { GeoShape } = DjangIO.app.geography.models
const { FrequencyTupleFields, MapVisualizationRegion } = DjangIO.app.widgets.types

export const getHeatColor = ({
    colors,
    data,
    frequencyTupleField,
    mapVisualizationHeatColorScale,
    mapVisualizationHeatColorType,
    mapVisualizationHeatInterpolationColors,
    mapVisualizationHeatInterpolationColorSpace,
    mapVisualizationHeatQuantizedColorStep,
}) =>
    useMemo(() => {
        if (frequencyTupleField === DjangIO.app.widgets.types.FrequencyTupleFields.heat.value) {
            const getColor = () => {
                if (Object.values(colors).length === 1) {
                    // matches app/static/frontend/visualizations/components/GenericMap.js getColorScale()
                    // per Jonathan Marks' testing feedback
                    const color =
                        // https://observablehq.com/@d3/d3-scalelinear
                        // https://github.com/d3/d3-scale#linear-scales
                        d3v6
                            .scaleLinear()
                            .domain(d3v6.extent(Array.from(Object.values(data).map((datum) => datum.agg))))
                    const heatColor = isColor(colors[0]) ? colors[0] : QuorumBlue
                    color
                        .range([
                            // https://github.com/d3/d3-color#color_brighter
                            // there's just no good programmatic way to
                            // uniformly brighten dissimilar default colors
                            dashboardConstants.mapSingleSequentialLightHues[heatColor] ||
                                d3v6.color(heatColor).brighter(2),
                            heatColor,
                        ])
                        // https://observablehq.com/@d3/d3-scalelinear#interpolate
                        .interpolate(
                            // https://github.com/d3/d3-interpolate#color-spaces
                            d3v6.interpolateHcl,
                        )

                    return color
                }

                // TODO: Jonathan/product asked us to remove this pre-launch
                // in my opinion, we should eventually add it back

                // it is currently not possible for this logic to run since
                // colors.length === 1 is currently guaranteed in
                // app/static/frontend/dashboards/components/WidgetEditFormSidebars/VisualizationFormSections/MapFormSection.jsx
                const mapVisualizationHeatColorScaleEnum =
                    DjangIO.app.widgets.types.MapVisualizationHeatColorScale.by_value(mapVisualizationHeatColorScale)
                const mapVisualizationHeatInterpolationColorSpaceEnum =
                    DjangIO.app.widgets.types.MapVisualizationHeatInterpolationColorSpace.by_value(
                        mapVisualizationHeatInterpolationColorSpace,
                    )
                const mapVisualizationHeatInterpolationColorsEnum =
                    DjangIO.app.widgets.types.MapVisualizationHeatInterpolationColors.by_value(
                        mapVisualizationHeatInterpolationColors,
                    )
                const color = d3v6[mapVisualizationHeatColorScaleEnum.d3_key]().domain(
                    d3v6.extent(Array.from(Object.values(data).map((datum) => datum.agg))),
                )

                const getSequentialColor = () => {
                    switch (mapVisualizationHeatColorType) {
                        case DjangIO.app.widgets.types.MapVisualizationHeatColorType.default.value:
                            color
                                // https://github.com/d3/d3-scale-chromatic
                                .interpolator(d3v6[mapVisualizationHeatInterpolationColorsEnum.d3_sequential_key])
                            break
                        case DjangIO.app.widgets.types.MapVisualizationHeatColorType.custom.value:
                            color
                                // http://using-d3js.com/04_05_sequential_scales.html
                                .interpolator(
                                    // https://github.com/d3/d3-interpolate#color-spaces
                                    d3v6[mapVisualizationHeatInterpolationColorSpaceEnum.d3_key](
                                        ...Object.values(colors),
                                    ),
                                )
                            break
                        default:
                            break
                    }
                }

                const getQuantizedColor = () => {
                    switch (mapVisualizationHeatColorType) {
                        case DjangIO.app.widgets.types.MapVisualizationHeatColorType.default.value:
                            color.range(
                                mapVisualizationHeatInterpolationColorsEnum.d3_quantize_key
                                    ? d3v6[mapVisualizationHeatInterpolationColorsEnum.d3_quantize_key][
                                          mapVisualizationHeatQuantizedColorStep
                                      ]
                                    : // https://github.com/d3/d3-scale/issues/61#issuecomment-224120575
                                      d3v6.quantize(
                                          d3v6[mapVisualizationHeatInterpolationColorsEnum.d3_sequential_key],
                                          mapVisualizationHeatQuantizedColorStep,
                                      ),
                            )
                            break
                        case DjangIO.app.widgets.types.MapVisualizationHeatColorType.custom.value:
                            color.range(Object.values(colors))
                            break
                        default:
                            break
                    }
                }

                switch (mapVisualizationHeatColorScale) {
                    case DjangIO.app.widgets.types.MapVisualizationHeatColorScale.sequential.value:
                        getSequentialColor()
                        break
                    case DjangIO.app.widgets.types.MapVisualizationHeatColorScale.quantize.value:
                        getQuantizedColor()
                        break
                    default:
                        color.range(d3v6.schemeBuGn[mapVisualizationHeatQuantizedColorStep])
                        break
                }

                return color
            }

            const color = getColor()

            color.unknown("lightgray").nice()

            return {
                heatColor: color,
                heatColorTimestamp: moment.now(),
            }
        }

        // return {} when frequencyTupleField is point or custom
        return {}
    }, [
        frequencyTupleField,
        mapVisualizationHeatColorScale,
        mapVisualizationHeatColorType,
        mapVisualizationHeatInterpolationColors,
        mapVisualizationHeatInterpolationColorSpace,
        mapVisualizationHeatQuantizedColorStep,
        // .join, .toString(), JSON.stingify()
        Object.values(colors).toString(),
    ])

export const renderMap = ({
    change,
    colors,
    data,
    dataSources,
    editForm,
    editing,
    frequencyTupleField,
    geoShapeRegion,
    geoShapeType,
    geoShapeId,
    heatColor,
    heatColorTimestamp,
    height,
    isExternal,
    mapVisualizationCustomColors,
    mapVisualizationRegion,
    reference,
    referenceLegend,
    showInSearch,
    timestamp,
    width,
}) =>
    useEffect(() => {
        const isGeoJSON = geoShapeType && geoShapeRegion

        const render = async () => {
            if (height && width) {
                d3v6.select(reference.current).select(".map").remove()

                d3v6.select(reference.current).select(".stroke").remove()

                // delete all of the previously projected points from the point map
                d3v6.select(reference.current).select(".points").remove()

                if (!heatColorTimestamp) {
                    // legendRenderer removes the previously rendered legend from the heat map,
                    // but since we do not call legendRenderer for point and custom maps,
                    // the legend is never removed from the DOM in those cases
                    d3v6.select(referenceLegend.current).select(".legend").remove()
                }

                const tooltip = renderTooltip({ reference })
                const mapVisualizationRegionEnum = MapVisualizationRegion.by_value(mapVisualizationRegion)

                const data_url = isGeoJSON
                    ? // Fetch geoJSON object from the Quorum API (Grassroots)
                      `${GeoShape.endpoint}get_geojson/?` +
                      `geo_shape_type=${geoShapeType}` +
                      `&region=${geoShapeRegion}`
                    : // Fetch topoJSON object from a public CDN (Dashboards)
                      mapVisualizationRegionEnum.d3_url

                // https://github.com/d3/d3-fetch#json
                const topology = await d3v6.json(data_url)

                const topojsonFeature = isGeoJSON
                    ? // No objects key in geoJSON
                      // We already selected the features (geo_shape_type) in the network request
                      topology
                    : topojson.feature(topology, topology.objects[mapVisualizationRegionEnum.d3_topology_key])

                // Now we can optionally focus on a single GeoShape of the given GeoShapeType
                if (geoShapeId) {
                    topojsonFeature.features = topojsonFeature.features.filter(
                        (feature) => Number(feature.properties.pk) === geoShapeId,
                    )
                }

                // https://github.com/topojson/topojson-client/blob/master/README.md#feature
                const getTopojsonFeatureFitSize = () => {
                    if (mapVisualizationRegionEnum.value === MapVisualizationRegion.eu.value) {
                        return {
                            ...topojsonFeature,
                            features: topojsonFeature.features.filter(
                                (feature) =>
                                    // France and Spain have international colonies which
                                    // ruin the projection by zooming out to display almost the entire world
                                    !["France", "España"].includes(feature.properties.NAME_LATN),
                            ),
                        }
                    }

                    return topojsonFeature
                }

                let projection
                if (
                    [DjangIO.app.models.Region.alaska.value, DjangIO.app.models.Region.hawaii.value].includes(
                        geoShapeRegion,
                    )
                ) {
                    // Alaska and Hawaii must use AlbertsUSA in order to render properly.
                    projection = d3v6.geoAlbersUsa()
                } else if (isGeoJSON) {
                    // Use a more familiar projection when looking at a region in isolation
                    projection = d3v6.geoMercator()
                } else {
                    // Other shapes can use the Projection defined in their enum
                    projection = d3v6[mapVisualizationRegionEnum.d3_projection]()
                }
                // https://github.com/d3/d3-geo#projection_fitSize
                projection = projection.fitSize([width, height], getTopojsonFeatureFitSize())

                const path = d3v6
                    // https://github.com/d3/d3-geo#geoPath
                    .geoPath()
                    // https://github.com/d3/d3-geo#path_projection
                    .projection(projection)

                const getMapKey = (d) =>
                    // us, world
                    d.properties.name ||
                    // eu
                    euNameLatinToD3[d.properties.NAME_LATN] ||
                    d.properties.NAME_LATN

                d3v6.select(reference.current)
                    // insert the Map group before the Tooltip element due to svg rendering priority
                    .insert("g", () => tooltip.node())

                    .attr("class", "map")
                    .attr("data-cy", "map")

                    // render the actual map topology
                    .selectAll("path")
                    .data(topojsonFeature.features)
                    .join("path")
                    .attr("fill", (d) => {
                        const key = getMapKey(d)

                        switch (frequencyTupleField) {
                            case FrequencyTupleFields.heat.value:
                                return heatColor(data[key] && data[key].agg)
                            case FrequencyTupleFields.custom.value:
                                return mapVisualizationCustomColors[key] || "lightgray"
                            default:
                                return "lightgray"
                        }
                    })
                    .style(
                        "cursor",
                        () =>
                            ((editForm && change && frequencyTupleField === FrequencyTupleFields.custom.value) ||
                                (!editing &&
                                    !editForm &&
                                    !isExternal &&
                                    frequencyTupleField === FrequencyTupleFields.heat.value &&
                                    timestamp)) &&
                            "pointer",
                    )
                    .attr("d", (d) => path(d.geometry))
                    .on("click", (event, d) => {
                        if (frequencyTupleField === FrequencyTupleFields.heat.value) {
                            if (!editing && !editForm && !isExternal) {
                                const supportedRegions = getSupportedRegions()
                                const frequencyTupleFieldEnum = FrequencyTupleFields.by_value(frequencyTupleField)
                                const filterKey =
                                    frequencyTupleFieldEnum.filter_keys[supportedRegions[0]][
                                        dataSources[0].safed_search.data_type
                                    ]
                                const key = getMapKey(d)
                                const filterValue = data[key] && data[key].originalKey
                                const additional_filters = dataSources[0].additional_filters
                                    ? dataSources[0].additional_filters
                                    : {}

                                showInSearch(
                                    selectShowInSearchPermanentFilters({
                                        ...((filterValue || additional_filters) && {
                                            filters: [
                                                {
                                                    ...additional_filters,
                                                    [filterKey]: filterValue,
                                                },
                                            ],
                                        }),
                                        safedSearch: dataSources[0].safed_search,
                                    }),
                                )
                            }
                        }

                        if (frequencyTupleField === FrequencyTupleFields.custom.value) {
                            // redux-form props.change inherited from
                            // app/static/frontend/dashboards/components/WidgetEditForm.jsx
                            // because of this rare case where we need to connect the Map Visualization Widget
                            // to the WidgetEditForm MapFormSection sidebar form,
                            // we do not check !editForm in the if conditional

                            // if this Map Visualization Widget is being rendered inside of
                            // app/static/frontend/dashboards/components/WidgetEditForm.jsx
                            if (!editing && !isExternal && editForm && change) {
                                const key = getMapKey(d)
                                change("map_visualization_custom_colors_key", key)
                            }
                        }
                    })
                    .on("mouseover", (event, d) => {
                        if (
                            // heat, custom
                            frequencyTupleField !== FrequencyTupleFields.point.value ||
                            // Grassroots CustomData Map Widget
                            (geoShapeType && geoShapeRegion)
                        ) {
                            d3v6.select(event.target).style("opacity", "0.8")

                            const key = getMapKey(d)
                            const [x, y] = path.centroid(d)
                            const tooltipY = () => {
                                // if y is placed outside of the bottom of the svg content (y >= height / 2),
                                // place it at the very bottom of the svg
                                if (y >= height / 2) {
                                    // bounding height of tooltip element is 33, as declared in
                                    // app/static/frontend/widgets/components/visualization/renderers/sharedRenderers.js
                                    return y - tooltip.node().getBoundingClientRect().height
                                }

                                // if y is placed outside of the top of the svg content (y < 0),
                                // just make it 0
                                if (y < 0) {
                                    return 0
                                }

                                return y
                            }
                            const getValue = () => {
                                const value = []
                                value.push(key)

                                if (data[key]) {
                                    value.push(data[key].agg)
                                }

                                return value.join(": ")
                            }

                            showTooltip({
                                tooltip,
                                value: getValue(),
                                widthOverflowThreshold: dashboardConstants.visualizationTooltipWidthOverflowThreshold,
                                x,
                                y: tooltipY(),
                            })
                        }
                    })
                    .on("mouseout", (event) => {
                        // heat, custom
                        if (
                            // heat, custom
                            frequencyTupleField !== FrequencyTupleFields.point.value ||
                            // Grassroots CustomData Map Widget
                            (geoShapeType && geoShapeRegion)
                        ) {
                            d3v6.select(event.target).style("opacity", "1")
                            hideTooltip({ tooltip })
                        }
                    })

                // white background stroke between states
                d3v6.select(reference.current)
                    // insert the Map stroke path before the Tooltip element due to svg rendering priority
                    .insert("path", () => tooltip.node())
                    .attr("class", "stroke")
                    .attr("data-cy", "stroke")
                    .datum(
                        isGeoJSON
                            ? topology.features.filter(
                                  (feature) =>
                                      // If we have a geoShapeId, we only want to draw its borders, not its neighbors
                                      geoShapeId === undefined || Number(feature.properties.pk) === geoShapeId,
                              )
                            : // https://github.com/topojson/topojson-client/blob/master/README.md#mesh
                              topojson.mesh(
                                  topology,
                                  topology.objects[mapVisualizationRegionEnum.d3_topology_key],
                                  (a, b) => a !== b,
                              ),
                    )
                    .attr("fill", "none")
                    .attr("stroke", "white")
                    .attr("stroke-linejoin", "round")
                    .attr("d", path)

                if (frequencyTupleField === FrequencyTupleFields.point.value) {
                    // Only draw the points that are inside of the given shape
                    const filteredData = data.filter((datum) =>
                        d3v6.geoContains(topojsonFeature, [datum.longitude, datum.latitude]),
                    )

                    // from point_frequency_tuples
                    const getPointLatLong = (d) => projection([d.longitude, d.latitude])

                    d3v6.select(reference.current)
                        // insert the Map points group before the Tooltip element due to svg rendering priority
                        .insert("g", () => tooltip.node())
                        .attr("class", "points")
                        .attr("data-cy", "points")
                        .selectAll("points")
                        .data(filteredData)
                        .enter()
                        .append("circle")
                        .attr("cx", (d) => {
                            // ignore lat/long pairs which will render outside the scope of the current projection
                            // (for example, international contacts in MapVisualizationRegion.us)
                            const point = getPointLatLong(d)

                            if (point) {
                                return point[0]
                            }

                            return undefined
                        })
                        .attr("cy", (d) => {
                            // ignore lat/long pairs which will render outside the scope of the current projection
                            // (for example, international contacts in MapVisualizationRegion.us)
                            const point = getPointLatLong(d)

                            if (point) {
                                return point[1]
                            }

                            return undefined
                        })
                        // normalize
                        // (make the point scale along with the width of the Map)
                        .attr("r", width * dashboardConstants.mapVisualizationPointScaleFactor)
                        .style("fill", colors[0] || "red")
                        .attr("stroke", colors[0] || "red")
                        // normalize
                        // (make the point scale along with the width of the Map)
                        .attr("stroke-width", width * dashboardConstants.mapVisualizationPointScaleFactor)
                        .style(
                            "cursor",
                            () =>
                                !editing &&
                                !editForm &&
                                !isExternal &&
                                frequencyTupleField === FrequencyTupleFields.point.value &&
                                timestamp &&
                                "pointer",
                        )
                        .on("click", (event, d) => {
                            if (!editing && !editForm && !isExternal) {
                                const filterKey =
                                    DjangIO.app.widgets.types.FrequencyTupleFields.by_value(frequencyTupleField)
                                        .filter_keys[dataSources[0].safed_search.data_type]
                                const additional_filters = dataSources[0].additional_filters
                                    ? dataSources[0].additional_filters
                                    : {}

                                showInSearch(
                                    selectShowInSearchPermanentFilters({
                                        ...((filterKey || additional_filters) && {
                                            filters: [
                                                {
                                                    ...additional_filters,
                                                    [filterKey]: d.id,
                                                },
                                            ],
                                        }),
                                        safedSearch: dataSources[0].safed_search,
                                    }),
                                )
                            }
                        })
                        .on("mouseover", (event, d) => {
                            if (d.name) {
                                d3v6.select(event.target).attr("stroke", "black")

                                const point = getPointLatLong(d)
                                if (!point) {
                                    return
                                }
                                const [x, y] = point

                                showTooltip({
                                    tooltip,
                                    // from point_frequency_tuples
                                    // Jonathan asked us to hide the address
                                    // value: `${d.name}: ${d.address}`,
                                    value: d.name,
                                    x,
                                    y,
                                })
                            }
                        })
                        .on("mouseout", (event, d) => {
                            if (d.name) {
                                d3v6.select(event.target).attr("stroke", colors[0] || "red")

                                hideTooltip({ tooltip })
                            }
                        })
                }
            }
        }

        render()
    }, [
        height,
        width,

        // widget.meta
        Object.values(colors).toString(),

        // widget.dataSources
        frequencyTupleField,

        // getHeatColor returns heatColor as a function, which we can't easily diff
        heatColorTimestamp,

        // .join, .toString(), JSON.stingify()
        Object.values(mapVisualizationCustomColors).toString(),
        mapVisualizationRegion,

        // custom data update
        Object.values(data || {}).toString(),
    ])

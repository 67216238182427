/**
 * Converts the response of a list request to an object mapped to the individual
 * responses to detail requests.
 *
 * @param {array} objects - a list of objects in an API list response
 *  - Sample Input: the response.objects of a request to /api/person/?firstName=Lamar
 *
 * @param {string} endpoint - an API endpoint
 *  - Sample Input: an endpoint "/api/person/", NOT a full URL like /api/person/?firstName=Lamar
 *
 * @returns {Object} returns an object with each API response mapped to its detail uri
 *   {
 *      "/api/person/300002" : { firstname: "Lamar", lastname: "Alexander", ... }
 *      "/api/person/300003" : { firstname: "Other", lastname: "Person", ... }
 *      ...
 *   }
 */
export const convertListResponseToDetailResponses = (objects, endpoint) =>
    objects.reduce((acc, curr) =>
        ({ ...acc, [`${endpoint}${curr.id}/`]: curr}), {}
    )

/**
 * Converts the keys of a cached response from server prefixed urls to endpoints
 * for the mobile app. On the mobile app, we fetch from the API at a url like
 * https://www.quorum.us/api/person/300002 instead of just /api/person/300002.
 *
 * Sample Input:
 *  {
 *      "https://www.quorum.us/api/person/300002" : { firstname: "Lamar", lastname: "Alexander", ... }
 *      "https://www.quorum.us/api/person/show_in_quicksearch=true": { meta: {...}, objects: [...] }
 *  }
 *
 * Sample Output:
 *  {
 *      "/api/person/300002" : { firstname: "Lamar", lastname: "Alexander", ... }
 *      "/api/person/show_in_quicksearch=true": { meta: {...}, objects: [...] }
 *  }
 *
*/
export const cleanCacheKeys = cache => (
    global.Config && global.Config.MOBILE_SERVER
        ? Object.entries(cache).reduce((acc, [key, value]) =>
            ({ ...acc, [key.replace(global.Config.MOBILE_SERVER, "")]: value}), {}
        )
        : cache
)

import { CloseButton as MantineCloseButton } from "@mantine/core"
import styled from "styled-components"
import { constants } from "QuorumGrassroots/constants"

export const VideoContainer = styled.div`
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
`

export const Video = styled.video`
    min-width: 100%;
    height: 100%;
    object-fit: cover;

    border: none;
    outline: none;

    padding: 0;
    margin: 0;

    & video {
        width: 100%;
        height: 100%;
    }
`

export const CloseButton = styled(MantineCloseButton)`
    color: white;

    position: absolute;
    right: 0.5rem;
    top: 0.5rem;

    width: 3rem;
    height: 3rem;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 2;

    border-radius: 32px;
    transition: color 0.25s ease-in-out;
    transition: background-color 0.25s ease-in-out;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.7));

    &:hover {
        color: ${constants.freshMediumGrey};
    }
`

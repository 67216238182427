import actionTypes, * as globalActionGroups from "shared/imports/global-action-types"

export const LOCATION_CHANGE = "@@router/LOCATION_CHANGE"

const _actionTypes = { ...actionTypes, LOCATION_CHANGE }

export default _actionTypes
export const { patchUserInfoTypes, getPeopleTypes, getSupporterTypes, uploadImage, getTeamsTypes } = globalActionGroups

export const OPEN_QUORUM_ALERT = "OPEN_QUORUM_ALERT"
export const CLOSE_QUORUM_ALERT = "CLOSE_QUORUM_ALERT"
export const CLEANUP_QUORUM_ALERT = "CLEANUP_QUORUM_ALERT"

export const TOGGLE_PAYROLL_MODAL = "TOGGLE_PAYROLL_MODAL"

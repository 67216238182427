import axios from "axios"
import { param } from "shared/djangio/Manager"
import { getItemTypes } from "shared/api-caching/action-types"

import { invalidateCacheSlice, markResourceAsUpdated } from "shared/api-caching/action-creators-additional"

/**
 * This resolves the various types of requests, and passes on the cancel token
 * so that our axios requests can be cancelled.
 *
 * @param {Promise} requestPromise the promise the contains the request to the api
 * @param {Object} actionTypes objects with keys start, success and fail. These map to action types
 * @param {Function} dispatch the dispatch function
 * @param {Object} payload={} additional redux data to pass to actions (under all)
 * @param {Object|Boolean} errorSwal Either a valid swal configuration object, or a Boolean to show a swal on failure. This feature is not yet ready.
 * @returns {Promise} returns the promise with the response or the error, of if errorSwal is supplied
 */
const resolveRequestPromise = (requestPromise, actionTypes, dispatch, payload, errorSwal = undefined) => {
    const promise = requestPromise
        .then((response) => {
            dispatch({
                type: actionTypes.success,
                response,
                ...payload,
            })
            return response
        })
        .catch((error) => {
            if (axios.isCancel(error)) {
                console.log("canceling request")
            } else {
                dispatch({
                    type: actionTypes.fail,
                    error,
                    ...payload,
                })
                // default to throwing the error if no swal config was passed
                throw error
            }
        })

    // we need to pass the abort function to our newly created promise,
    // so that anything that relies on a function from djangio/action-creators
    // that calls this method can be cancelled.
    return Object.assign(promise, { abort: requestPromise.abort })
}

/**
 * This resolves the various types of requests
 *
 * @param {Promise} requestPromise the promise the contains the request to the api
 * @param {Object} actionTypes objects with keys start, success and fail. These map to action types
 * @param {Function} dispatch the dispatch function
 * @param {Object} payload={} additional data to be passed
 * @returns {Promise} returns the promise with the response or the error
 */
const resolveFileDownloadRequestPromise = (requestPromise, actionTypes, dispatch, payload) =>
    requestPromise
        .done((response) => {
            dispatch({ type: actionTypes.success, response, ...payload })
            return response
        })
        .fail((error) => {
            if (axios.isCancel(error)) {
                console.log("canceling request")
            } else {
                // throw error
                dispatch({ type: actionTypes.fail, error, ...payload })
                console.log("error", error)
                throw error
            }
        })

/**
 * Converts a DjangIO object to a queryset
 *
 * @param {Model|Manager} resource a ModelResource or a Manager
 * @returns {Manager} returns a manager
 */
export const convertResourceToQueryset = (resource) => {
    if (resource.constructor === DjangIO.Model) {
        return resource.objects
    }

    if (resource.constructor === DjangIO.Manager) {
        return resource
    }

    const errorText = `DjangIO action creator must be called with resource or queryset. Instead was called with ${resource.constructor}`

    throw errorText
}

/**
 * This action creator is for downloading a file
 * This function takes in 1 argument, an object with the following shape:
 * @param {Object} resource DjangIO[Model] or DjangIO[Model].objects. Additional filters
 * applied via calling .filters() are allowed. Applying the correct file type filter is required, such as .csv() or .pdf()
 * @param {Object} actionTypes objects with keys start, success and fail. These map to action types
 * @param {Number} (optional) primaryKey the id of the object to get
 * @param {Object} requestArgs={} Request arguments to pass to the server
 * @param {Object} payload={} additional data to be passed in redux
 * @returns {Promise} Promise with the resulting response
 */
export const downloadFile =
    ({ resource, actionTypes, primaryKey, requestArgs = {}, payload = {} }) =>
    (dispatch) => {
        dispatch({ type: actionTypes.start, ...payload })
        const queryset = convertResourceToQueryset(resource)

        // if a primary key is provided, make sure the generated request url will have it
        // apply the file format to request
        const intermediateManager = primaryKey ? queryset.get(primaryKey) : queryset

        // make the request
        const request = intermediateManager.download(requestArgs)

        // pass request to promise resolver
        return resolveFileDownloadRequestPromise(request, actionTypes, dispatch, payload)
    }

/**
 * This action creator is for getting an object
 *
 * @param {Object} resource DjangIO[Model] or DjangIO[Model].objects
 * @param {Object} actionTypes objects with keys start, success and fail. These map to action types
 * @param {Number} primaryKey the id of the object to get
 * @param {Object} payload={} additional data to be passed
 * @param {Boolean} shouldLoadFromCache=false whether the cache should be checked, avoiding making a network request
 * @returns {Promise} Promise with the resulting response
 */
export const resourceGetDetail =
    (resource, actionTypes, primaryKey, payload = {}, shouldLoadFromCache = false) =>
    (dispatch) => {
        dispatch({ type: actionTypes.start, ...payload })
        const queryset = convertResourceToQueryset(resource)
        const request = queryset.get(primaryKey).GET({ shouldLoadFromCache })
        return resolveRequestPromise(request, actionTypes, dispatch, payload)
    }

/**
 * This action creator is for getting several objects
 *
 * @param {Object} resource DjangIO[Model] or DjangIO[Model].objects
 * @param {Object} actionTypes objects with keys start, success and fail. These map to action types
 * @param {Object} filter the filters to be applied
 * @param {Object} payload={} additional data to be passed
 * @returns {Promise} Promise with the resulting response
 */
export const resourceGetList =
    (resource, actionTypes, filter = {}, payload = {}, shouldLoadFromCache = false) =>
    (dispatch) => {
        dispatch({ type: actionTypes.start, ...payload })
        const queryset = convertResourceToQueryset(resource)
        const request = queryset.filter(filter).GET({ shouldLoadFromCache })
        return resolveRequestPromise(request, actionTypes, dispatch, payload)
    }

/**
 * This action creator is for updating a single object
 *
 * @param {object} resource DjangIO[Model] or DjangIO[Model].objects
 * @param {Object} actionTypes objects with keys start, success and fail. These map to action types
 * @param {Number} primaryKey id of the object
 * @param {Object} kwargs object of args to be passed
 * @param {Object} payload={} additional data to be passed
 * @param {Object} filter the filters to be applied
 * @returns {Promise} Promise with the resulting response
 */
export const resourcePatchDetail =
    (resource, actionTypes, primaryKey, kwargs, payload = {}, { cacheInvalidationParams, filter } = {}) =>
    (dispatch) => {
        dispatch({ type: actionTypes.start, ...payload })
        const queryset = convertResourceToQueryset(resource)
        if (filter) {
            const request = queryset.get(primaryKey).update(kwargs).filter(filter).PATCH({ cacheInvalidationParams })
            return resolveRequestPromise(request, actionTypes, dispatch, payload)
        } else {
            // if the filter is undefined we made sure not to include it to avoid the risk of regression
            const request = queryset.get(primaryKey).update(kwargs).PATCH({ cacheInvalidationParams })
            return resolveRequestPromise(request, actionTypes, dispatch, payload)
        }
    }

/**
 * This action creator is for handling Tastypie Actions
 *
 * @param {object} resource DjangIO[Model] or DjangIO[Model].objects
 * @param {Object} actionTypes objects with keys start, success and fail. These map to action types
 * @param {Number} primaryKey id of the object
 * @param {Object} kwargs object of args to be passed
 * @param {Object} payload={} Fields to pass to the Redux action
 * @returns {Promise} Promise with the resulting response
 * The payload argument is confusing here and in the rest of the functions. An effort will soon be made to standardize this,
 * So that arguments to pass to the backend and arguments to pass around in redux world will be clearly deliniated
 */
export const resourcePatchAction =
    (resource, actionTypes, primaryKey, kwargs, payload = {}, { cacheInvalidationParams } = {}) =>
    async (dispatch) => {
        // dispatch that we are starting
        dispatch({ type: actionTypes.start, ...payload })
        if (!kwargs.actionName) {
            const errorReason = "No Tastypie Resource Action Provided"
            // if no action name was provided, fail immediately
            const error = new Error(errorReason)
            dispatch({ type: actionTypes.fail, ...payload, error })
            await BACKENDERROR(error)
        }

        const queryset = convertResourceToQueryset(resource)

        // for now, pass in args in body as well as in url params to maintain backwards compatibility
        const request = queryset
            .get(primaryKey)
            .update(kwargs)
            .action(kwargs.actionName, payload)
            .PATCH({ cacheInvalidationParams })
        return resolveRequestPromise(request, actionTypes, dispatch, payload)
    }

export const resourcePatchActionObj = ({
    resource,
    actionTypes,
    primaryKey,
    additionalArgs,
    reduxAndBodyArgs, // this is the exception to the action creators, they are also passed to backend
    cacheInvalidationParams,
}) =>
    resourcePatchAction(resource, actionTypes, primaryKey, additionalArgs, reduxAndBodyArgs, {
        cacheInvalidationParams,
    })

/**
 * This action creator is for handling any request an action can handle (GET/POST/PATCH)
 *
 * @param {object} resource DjangIO[Model] or DjangIO[Model].objects
 * @param {Object} actionTypes objects with keys start, success and fail. These map to action types
 * @param {Object} extra.kwargs object of args to be passed
 * @param {String} extra.action string of the action endpoint to be hit
 * @param {Object} extra.payload={} Fields to pass to the Redux action
 * @returns {Promise} Promise with the resulting response
 */
export const sendAction =
    (
        resource,
        actionTypes,
        { action, kwargs, payload = {}, getParams, method = "patch", cacheInvalidationParams, actionPk },
    ) =>
    // we shouldn't use the async / await promise syntax here because it doesn't
    // work correctly for the mobile app
    (dispatch) => {
        dispatch({ type: actionTypes.start, ...payload })

        const queryset = convertResourceToQueryset(resource)

        const getActionParams = () => {
            if (method === "get" && (getParams || kwargs)) {
                return `?${param(getParams || kwargs)}`
            } else if (actionPk) {
                return `${actionPk}/`
            }

            return ""
        }
        const url = `${queryset.buildEndpoint()}${action}/${getActionParams()}`

        // because we're not going through the DjangIO system, we need to generate
        // a cancel token.
        const source = axios.CancelToken.source()

        const request = Object.assign(axios[method](url, kwargs, { cancelToken: source.token }), {
            abort: source.cancel,
        })

        // invalidate cache here. Have to do it manually because we aren't
        // using the DjangIO system to create a request.
        return resolveRequestPromise(request, actionTypes, dispatch, payload).then((response) => {
            if (cacheInvalidationParams) {
                invalidateCacheSlice(cacheInvalidationParams)
                markResourceAsUpdated(cacheInvalidationParams)
            }
            return response
        })
    }

/**
 * This action creator is for updating multiple objects
 *
 * @param {Object} resource DjangIO[Model] or DjangIO[Model].objects
 * @param {Object} actionTypes objects with keys start, success and fail. These map to action types
 * @param {Array} objects list of uris
 * @param {Object} payload={} additional data to be passed
 * @returns {Promise} Promise with the resulting response
 */
export const resourcePatchList =
    (resource, actionTypes, objects, payload = {}, { cacheInvalidationParams } = {}) =>
    (dispatch) => {
        dispatch({ type: actionTypes.start, ...payload })
        const queryset = convertResourceToQueryset(resource)
        const request = queryset.bulk_update({ objects }).PATCH({ cacheInvalidationParams })
        return resolveRequestPromise(request, actionTypes, dispatch, payload)
    }

export const resourcePatchListObj = ({
    resource,
    actionTypes,
    objects,
    reduxArgs, // passed around only on the frontend
    cacheInvalidationParams,
}) => resourcePatchList(resource, actionTypes, objects, reduxArgs, { cacheInvalidationParams })

/**
 * This action creator is for making new objects
 *
 * @param {Object} resource DjangIO[Model] or DjangIO[Model].objects
 * @param {Object} actionTypes objects with keys start, success and fail. These map to action types
 * @param {Object} kwargs object of args to be passed
 * @param {Object} payload={} additional data to be passed
 * @returns {Promise} Promise with the resulting response
 */
export const resourcePostList =
    (resource, actionTypes, kwargs, payload = {}, { cacheInvalidationParams } = {}) =>
    (dispatch) => {
        dispatch({ type: actionTypes.start, ...payload })
        const queryset = convertResourceToQueryset(resource)
        const request = queryset.create(kwargs).POST({ cacheInvalidationParams })
        return resolveRequestPromise(request, actionTypes, dispatch, payload)
    }

// we do not support DELETE requests on prod: https://quorumanalytics.slack.com/archives/CP6ESL80Z/p1603758813048500?thread_ts=1603754843.040100&cid=CP6ESL80Z
export const resourceDeleteList =
    (resource, actionTypes, objectIds, payload = {}, { cacheInvalidationParams } = {}) =>
    (dispatch) => {
        dispatch({ type: actionTypes.start, ...payload })
        const queryset = convertResourceToQueryset(resource)
        const request = queryset.delete(objectIds).DELETE({ cacheInvalidationParams })
        return resolveRequestPromise(request, actionTypes, dispatch, payload)
    }

/**
 * This action creator is for downloading several objects
 *
 * @param {Object} resource DjangIO[Model] or DjangIO[Model].objects
 * @param {Object} actionTypes objects with keys start, success and fail. These map to action types
 * @param {Object} filter the filters to be applied
 * @param {Object} payload={} additional data to be passed
 * @returns {Promise} Promise with the resulting response
 */
export const resourceDownloadList =
    (resource, actionTypes, filter = {}, payload = {}) =>
    async (dispatch) => {
        dispatch({ type: actionTypes.start, ...payload })
        const queryset = convertResourceToQueryset(resource)
        try {
            await queryset.filter(filter).download()
            dispatch({ type: actionTypes.success, ...payload })
        } catch (error) {
            dispatch({ type: actionTypes.fail, error, ...payload })
        }
    }

/**
 * Helper method. Takes in qdt, returns proper DjangIO model (call .objects to get manager and so on)
 * @param {number} dataType quorumDataType for the resource
 * @returns {obj} - model representing that resource
 */
export const getResourceForDataType = (dataType) => {
    const qdt = DjangIO.app.models.QuorumDataType.by_value(dataType)
    const model = qdt.proxy_resource_data_model || qdt.data_model
    return DjangIO.modelAtPath(model)
}

/**
 * This GETs the resource and datumId from the backend
 * and puts it in the store under the cache
 *
 * @param {Resource} resource the model resource: Ex: DjangIO.app.person.models.Person
 * @param {number} datumId the id of the object
 * @param {boolean} getFromCache true if the object can come from the cache, false if it must be fetched
 */
export const getItem = (resource, datumId, getFromCache) =>
    resourceGetDetail(resource, getItemTypes, datumId, { resource, datumId }, getFromCache)

/** shallow wrapper for widgetEngine evaluation. Since resourcePostList is not necessarily
 *  a great description of what is being done (unlike when we post to create), this is simply
 *  here to make code a little more readable
 *
 * @param {obj} widget obj of the instructions necessary for widget engine to generate results
 * @param {obj} actionTypes objects with keys start, success and fail.
 * @param {obj} payload={} additional data to be passed
 */
export const evaluateWidget = (widget, actionTypes, payload = {}) =>
    resourcePostList(DjangIO.app.widgets.models.WidgetEngine, actionTypes, widget, payload)

/** although not technically an action-creator, it does work similar to sendAction,
 *  in which it creates an action url for a get request, and instead of pushing it
 * through the usual djangio action flow, calls jquery's fileDownload transform response
 * into a download
 *
 * @param {obj} resourse resource DjangIO[Model] or DjangIO[Model].objects
 * @param {string} action the action name to be  called at the resource
 * @param {obj} kwargs={} additional data to be passed
 */
export const resourceDownloadAction = (resource, action, kwargs = {}) => {
    // wrap on this, as it should fail if jquery is not defined and this fucntion is called,
    // but should not give a top level undefined error if jquery when this function wouldnt
    // be called (as would be the case for mobile)
    const jquery = typeof $ === "undefined" ? {} : $
    const queryset = convertResourceToQueryset(resource)
    const url = `${queryset.buildEndpoint()}${action}/?${param(kwargs)}`
    return jquery.fileDownload(url)
}

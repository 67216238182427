import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

const DISPLAY_TIMELINE_LABEL_BREAKPOINT = 1300

export const Card = styled.div`
    width: 100%;
`
export const CardInner = styled.div`
    // TODO delete !important after we drop the old .styl files
    background-color: ${colors.White} !important;
    padding-left: 11.5px !important;

    ${props => props.isSelected && css`
        // TODO delete !important after we remediate LongInlinePresentation.styl
        border: thin solid ${colors.QuorumBlue} !important;
        outline: thin solid ${colors.QuorumBlue};
    `}

    &:hover {
        background-color: ${colors.violet0} !important;
    }
`

export const CardInnerAnchor = styled.a`
    ${props => (props.isHighlightable) && css`
        pointer-events: none;
        user-select: none;
    `}
`

export const InlineText = styled.div`
`

export const FirstLine = styled.h2`
    ${props => (props.expanded)
        ? css`
            color: ${colors.gray9};
            display: block;
            font-size: 22px;
            line-height: normal;
            margin-bottom: 1px;
            // TODO delete !important after we remediate LongInlinePresentation.styl
            margin-top: 18px !important;
            padding-left: 0px;
            padding-right: 5px;
        `
        : css`
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            color: ${colors.gray9};
            display: -webkit-box;
            font-size: 22px;
            line-height: normal;
            margin-bottom: 1px;
            // TODO delete !important after we remediate LongInlinePresentation.styl
            margin-top: 18px !important;
            max-height: 55px;
            overflow: hidden;
            padding-left: 0px;
            padding-right: 5px;
            text-overflow: ellipsis;
        `
    }
`

export const FirstLineRegion = styled.span`
    color: ${colors.LinkPurple};
`

export const SecondLine = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &:first-of-type {
        margin-top: 1px;
    }

    &:last-of-type {
        margin-bottom: 11px;
    }
`
export const SecondLineItem = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;

    &:not(:last-child) {
        margin-right: 20px;
    }

    ${props => (props.isLink) && css`
        ${rules.CardStyle}
        padding: 4px 7px 5px 3px;
    `}
`
export const SecondLineLabel = styled.span`
    border-bottom: thin solid transparent;
    color: ${colors.gray7};
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;

    ${props => (props.fa5Icon) && css`
        margin-left: 6px;
    `}

    ${props => (props.isLink) && css`
        ${rules.weight.medium}

        &:hover {
            border-bottom: thin dotted black;
        }
    `}
`
export const SecondLineSegueLinkContainer = styled.object`
    &:not(:last-child) {
        margin-right: 20px;
    }
`

export const ThirdLineContainer = styled.div`

`
export const ThirdLineText = styled.div`

`
export const ThirdLineQuorumIframe = styled.div`

`
export const FourthLine = styled.div`
`

export const IconNameContainer = styled.div`
    width: 3rem;
    height: 3rem;
    border: 0;
    padding: 15px;
    border-radius: 100%;
    margin-right: 11px;
    margin-top: 11px;
    background-color: ${colors.violet1};
    display: flex;
    align-items: center;
    justify-content: center;
`

export const TimelineOptionsContianer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // top, right, bottom, left
    margin: 0 11.5px 11px 0;
`

export const Options = styled.div`
    display: flex;
`

export const OptionsButtonContainer = styled.div`
`

export const BillStatusTimeline = styled.div`
    display: flex;
    margin-top: 10px;
`

export const BillStatusTimelineItem = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
`

export const BillStatusTimelineLabel = styled.span`
    ${rules.MinisculeText}

    ${props => {
        if (props.achieved) {
            return css`
                color: ${colors.gray7};
                font-weight: bold;
            `
        }

        return css`
            color: ${colors.gray5};
        `
    }}

    // don't show label if it's going to push the timeline onto a new line
    @media (max-width: ${DISPLAY_TIMELINE_LABEL_BREAKPOINT}px) {
        display: none;
    }
`

export const CondensedLabel = styled.div`
    border-radius: 4px;
    border: 1px solid ${colors.gray7};
    height: 6px;
    width: 23px;

    ${props => {
        if (props.achieved) {
            return css`
                background-color: ${colors.gray7};
            `
        }
    }}

    @media (min-width: ${DISPLAY_TIMELINE_LABEL_BREAKPOINT + 1}px) {
        display: none;
    }
`

export const BillStatusTimelineDivider = styled.div`
    border-radius: 4px;
    margin: 0 8px;
    width: 18px;

    ${props => {
        if (props.achieved) {
            return css`
                background-color: ${colors.gray7};
                height: 3px;
            `
        }

        return css`
            background-color: ${colors.gray5};
            height: 1px;
        `
    }}

    // responsiveness for divider
    // decrease the width and the margins when screen is shrinking
    @media (max-width: 1375px) {
        width: 10px;
        margin: 0 4px;
    }

    // at this width we are no longer showing the timeline labels
    // so increase the width of divider but make it thin
    @media (max-width: ${DISPLAY_TIMELINE_LABEL_BREAKPOINT}px) {
        height: 1px;
        width: 35px;
    }

    @media (max-width: 1130px) {
        height: 1px;
        width: 15px;
    }
`

export const OptionsAdderContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const AdderComponent = styled.div`
    position: absolute;
    margin-top: 3px;
    min-width: 200px;
    z-index: 1;
`

export const TooltipPositioner = styled.div`
    & > :first-child {
        top: 0;
        left: calc(100% + 5px);
        min-width: 100px;
    }
`

export const TooltipContainer = styled.div`
    position: relative;

    :not(&:hover) {
        ${TooltipPositioner} {
            > :first-child {
                opacity: 0%;
                visibility: hidden;
                transition-duration: ${rules.TransitionShort};
            }
        }
    }

    &:hover {
        ${TooltipPositioner} {
            > :first-child {
                opacity: 100%;
                visibility: visible;
                transition-duration: ${rules.TransitionShort};
            }
        }
    }
    transition-duration: ${rules.TransitionShort};
`

import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"
import CustomDataWidget from "QuorumGrassroots/widgets/CustomDataWidget/components/index"

import initializer from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/InitializeStoreWrapper"
import { customDataWidgetSelector } from "QuorumGrassroots/widgets/CustomDataWidget/selectors"

const connectedCustomDataWidget = connect(customDataWidgetSelector, null)(CustomDataWidget)

const initializedCustomDataWidget = initializer(connectedCustomDataWidget)

export default withNamespaces()(initializedCustomDataWidget)

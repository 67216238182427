// technical design document
// https://docs.google.com/document/d/1WwIExwnTzSm2soy4t-9zEzY8yVjK9sgFp_jmqv07rM0/
// product design mocks
// https://projects.invisionapp.com/d/main#/console/19025613/400363209/preview
// first attempt
// https://github.com/QuorumUS/quorum-site/pull/18116/

// https://observablehq.com/@d3/gallery#maps

// heat us
// https://observablehq.com/@d3/choropleth
// https://observablehq.com/@d3/state-choropleth

// heat eu
// https://observablehq.com/@mkadunc/europe-sub-national-14d-incidence

// heat world
// https://observablehq.com/@d3/world-choropleth

// point us/eu/world
// https://observablehq.com/@d3/bubble-map
// https://observablehq.com/@d3/spike-map
// https://www.d3-graph-gallery.com/graph/bubblemap_basic.html
// http://bl.ocks.org/phil-pedruco/7745589

// topojson us
// https://github.com/topojson/us-atlas
// https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json

// topojson eu
// https://gisco-services.ec.europa.eu/distribution/v1/nuts-2021.html
// https://gisco-services.ec.europa.eu/distribution/v2/nuts/nuts-2021-files.html
// https://gisco-services.ec.europa.eu/distribution/v2/nuts/topojson/NUTS_RG_10M_2021_4326_LEVL_0.json

// topojson world
// https://github.com/topojson/world-atlas
// https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json

import React, { useRef } from "react"
import PropTypes from "prop-types"

// components
// import { Legend } from "QuorumDesign"

// d3 renderers
import { useRenderSvg } from "app/static/frontend/widgets/components/visualization/renderers/sharedRenderers"
import { renderLegend } from "app/static/frontend/widgets/components/visualization/renderers/legendRenderer"
import {
    getHeatColor,
    renderMap,
} from "app/static/frontend/widgets/components/visualization/renderers/map/mapRenderers"

// hooks
import { useRefDimensions } from "app/static/frontend/widgets/components/visualization/hooks/visualizationHooks"

export const Map = (props) => {
    const map = useRef(null)
    const legend = useRef(null)

    const { height, width } = useRefDimensions({
        reference: map,
        timestamp: props.timestamp,
    })
    const [heightMargin, widthMargin] = [
        height - (props.margin.top + props.margin.bottom),
        width - (props.margin.left + props.margin.right),
    ]

    // setting the number of ticks on the legend here
    let min = 0
    let max = 0

    for (const data in props.data) {
        const agg = props.data[data]?.agg
        if (agg) {
            min = Math.min(agg, min)
            max = Math.max(agg, max)
        }
    }

    // will default numTicks to `width / 64` to match default set in
    // app/static/frontend/widgets/components/visualization/renderers/legendRenderer.js
    // numTicks evaluates to `count + 1` (https://devdocs.io/d3~6/d3-array#ticks)
    const numTicks = max || min ? Math.min(width / 64, max - min) : width / 64

    const renderMapNodes = () => {
        const { heatColor, heatColorTimestamp } = getHeatColor({
            colors: props.colors,
            data: props.data,
            frequencyTupleField: props.frequencyTupleField,
            mapVisualizationHeatColorScale: props.mapVisualizationHeatColorScale,
            mapVisualizationHeatColorType: props.mapVisualizationHeatColorType,
            mapVisualizationHeatInterpolationColors: props.mapVisualizationHeatInterpolationColors,
            mapVisualizationHeatInterpolationColorSpace: props.mapVisualizationHeatInterpolationColorSpace,
            mapVisualizationHeatQuantizedColorStep: props.mapVisualizationHeatQuantizedColorStep,
        })

        renderLegend({
            color: heatColor,
            colorTimestamp: heatColorTimestamp,
            marginLeft: 10,
            marginRight: 10,
            referenceLegend: legend,
            tickFormat: (d) => {
                // the below code adds decimal points to the ticks on the legend
                // commenting this out to address this ticket, in case we need this again:
                // https://quorumanalytics.atlassian.net/browse/CRM-3175

                // if (!Number.isInteger(d)) {
                //     // we can't use d3v6.format(".2f")(d) because
                //     // it converts 3 and 3.5 to 3.00 and 3.50, respectively

                //     // the Math.round logic below rounds to at most two decimal places
                //     // while ensuring that we do not append extra decimals to whole numbers (3, 3.0)
                //     // nor numbers with decimals to tenths precision (3.5)
                //     // https://stackoverflow.com/a/11832950
                //     return Math.round((d + Number.EPSILON) * 100) / 100
                // }

                return Number.isInteger(d) ? d : null
            },
            ticks: numTicks,
            width,
        })

        renderMap({
            // needed to update values in the store when we click on a specific
            // state or country in a FrequencyTupleFields.custom Map
            // rendered by app/static/frontend/dashboards/components/WidgetEditForm.jsx
            change: props.change,
            colors: props.colors,
            data: props.data,
            dataSources: props.dataSources,
            editForm: props.editForm,
            editing: props.editing,
            frequencyTupleField: props.frequencyTupleField,
            geoShapeRegion: props.geoShapeRegion,
            geoShapeType: props.geoShapeType,
            geoShapeId: props.geoShapeId,
            heatColor,
            heatColorTimestamp,
            height: heightMargin,
            isExternal: props.isExternal,
            mapVisualizationCustomColors: props.mapVisualizationCustomColors,
            mapVisualizationRegion: props.mapVisualizationRegion,
            reference: map,
            referenceLegend: legend,
            showInSearch: props.showInSearch,
            timestamp: props.timestamp,
            width: widthMargin,
        })
    }

    useRenderSvg({
        height: heightMargin,
        reference: map,
        width: widthMargin,
    })

    renderMapNodes()

    return (
        <React.Fragment>
            <svg
                data-cy="map-visualization-widget"
                ref={map}
                // ensure that the Map svg stretches to the height of the parent div
                // this is important because we want the onResize to set correct values
                // immediately after mount
                style={{
                    flexGrow: 1,
                }}
            />
            <svg
                ref={legend}
                // app/static/frontend/widgets/components/visualization/renderers/legendRenderer.js
                // tickSize + height
                height="50"
            />
        </React.Fragment>
    )
}

Map.defaultProps = {
    margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
}
Map.propTypes = {
    colors: PropTypes.objectOf(PropTypes.string),
    // the WidgetResource widget_engine (app/widgets/api.py) returns:
    // an object if there is a single result
    // an array if there are multiple results
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    dataSources: PropTypes.array,
    editing: PropTypes.bool,
    editForm: PropTypes.bool,

    // The following two props are used in Grassroots to fetch a geoJSON from the Quorum API
    geoShapeRegion: PropTypes.number,
    geoShapeType: PropTypes.number,
    // Optionally, render a single geoshape from the geoJSON collection referenced above
    geoShapeId: PropTypes.number,

    isExternal: PropTypes.bool,
    margin: PropTypes.object,
    // the timestamp is used for memoization in place of props.data
    // because it is less computationally intensive to compare
    timestamp: PropTypes.number,
    widget: PropTypes.object,

    // store
    frequencyTupleField: PropTypes.number,
    mapVisualizationCustomColors: PropTypes.object,
    mapVisualizationCustomColorsKey: PropTypes.string,
    mapVisualizationHeatColorScale: PropTypes.number,
    mapVisualizationHeatColorType: PropTypes.number,
    mapVisualizationHeatInterpolationColors: PropTypes.number,
    mapVisualizationHeatInterpolationColorSpace: PropTypes.number,
    mapVisualizationHeatQuantizedColorStep: PropTypes.string,
    mapVisualizationRegion: PropTypes.number,

    // action creators
    showInSearch: PropTypes.func,

    // from redux-form (see https://redux-form.com/8.1.0/docs/api/props.md/)
    // through app/static/frontend/dashboards/components/WidgetEditForm.jsx
    change: PropTypes.func,
}

export default Map

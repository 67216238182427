import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/TopBar/style"

import Back from "Components/Atoms/Back"
import Button from "Components/Molecules/Button"
import PageTitle from "Components/Atoms/PageTitle"

const TopBar = ({
    backDataCy,
    children,
    hasBorderBottom,
    hasGreyBackground,
    isAiSearchApplied,
    noLine,
    onClickBack,
    shouldTruncateTitle,
    subtitle,
    title,
    titleDataCy,
    titleImage,
    titleImageDataCy,
    titlePrefix,
}) => {
    return (
        <S.TopBar
            data-auto-cy="CompoundTopBar"
            noLine={noLine}
            hasBorderBottom={hasBorderBottom}
            hasGreyBackground={hasGreyBackground}
            isAiSearchApplied={isAiSearchApplied}
        >
            {
                onClickBack && (
                    <Back
                        onClick={onClickBack}
                        dataCy={backDataCy}
                        size="1x"
                        iconFamily="far"
                    />
                )
            }
            {
                // Allow a PageTitle (or other component) to be supplied through the title prop
                // If a string or a subtitle is given, create a PageTitle; otherwise, render the title component
                typeof(title) === "string" || subtitle
                    ? (
                        <PageTitle
                            dataCy={titleDataCy}
                            image={titleImage}
                            imageDataCy={titleImageDataCy}
                            prefix={titlePrefix}
                            subtitle={subtitle}
                            title={title}
                        />
                    )
                    : (
                        <S.TitleComponent
                            shouldTruncateTitle={shouldTruncateTitle}
                        >
                            {title}
                            {
                                isAiSearchApplied &&
                                <S.ButtonContainer>
                                    <Button
                                        id="ai-search-thumbs-up"
                                        leftIcon="thumbs-up"
                                        leftIconFamily="far"
                                        type="ghost-blue"
                                    />
                                    <Button
                                        id="ai-search-thumbs-down"
                                        leftIcon="thumbs-down"
                                        leftIconFamily="far"
                                        type="ghost-blue"
                                    />
                                </S.ButtonContainer>
                            }
                        </S.TitleComponent>
                    )
            }
            {
                children &&
                    <S.OptionsSection>
                        {children}
                    </S.OptionsSection>
            }
        </S.TopBar>
    )
}

TopBar.defaultProps = {
    hasBorderBottom: true,
    hasGreyBackground: false,
    noLine: false,
    shouldTruncateTitle: true,
}

TopBar.propTypes = {
    // data-cy tag for the back button
    backDataCy: PropTypes.string,
    // design system components to nest under the top bar
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    // whether we use a non-white bg color for the header
    hasGreyBackground: PropTypes.bool,
    // whether the border-bottom style is set
    hasBorderBottom: PropTypes.bool,
    // if this is an ai search, we want to render additional components to the TopBar
    isAiSearchApplied: PropTypes.bool,
    // whether there's a bottom line on the TopBar and right border on the back arrow
    noLine: PropTypes.bool,
    // callback for the back button
    onClickBack: PropTypes.func,
    // necessary for removing min-width if we should NOT truncate the title
    shouldTruncateTitle: PropTypes.bool,
    // text to display before the title, in alternate color, if string title is provided
    titlePrefix: PropTypes.string,
    // title text as a string, or a PageTitle component to render instead
    title: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
    ]).isRequired,
    // data-cy tag for the title text
    titleDataCy: PropTypes.string,
    // url to optional image to display before title
    titleImage: PropTypes.string,
    // data-cy tag to inspect image
    titleImageDataCy: PropTypes.string,
}

export default TopBar

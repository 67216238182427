// https://projects.invisionapp.com/d/main#/console/19025613/400363205/preview

// https://stackoverflow.com/a/42392083/6201291
// https://bl.ocks.org/jkeohan/b8a3a9510036e40d3a4e

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/Legend/style"

const Legend = ({
    colors,
    data,
    isVertical,
}) => (
    <S.Legend
        data-auto-cy="CompoundLegend"
        isVertical={isVertical}
    >
        {
            data && data.map((datum, i) => (
                <S.LegendRow
                    isVertical={isVertical}
                    key={`${datum} - ${colors[i]} - ${i}`}
                >
                    <S.LegendColorSquare
                        colors={colors}
                        data-cy={`legend-color-square-${i + 1}`}
                        index={i}
                    />
                    <S.LegendText
                        data-cy={`legend-text-${i + 1}`}
                    >
                        {datum}
                    </S.LegendText>
                </S.LegendRow>
            ))
        }
    </S.Legend>
)

Legend.defaultProps = {
    isVertical: false,
}

Legend.propTypes = {
    // the colors object
    // {
    //     0: "#A95AA1 ",
    //     1: "#503ABD",
    //     2: "#85C0F9",
    // },
    colors: PropTypes.objectOf(PropTypes.string),
    data: PropTypes.array,
    isVertical: PropTypes.bool,
}

export default Legend

import Immutable from "immutable"
import { createSelector } from "reselect"

export const selectSocialUser = state => state.socialUser

export const selectSocialUserForModel = modelName => id => createSelector(
    selectSocialUser,
    state => state.get(`${modelName}-${id}`) || Immutable.fromJS([])
)

export const selectValidSocialUsers = modelName => id => createSelector(
    selectSocialUserForModel(modelName)(id),
    socialUsers => socialUsers.filter(socialUser => socialUser.get("is_valid"))
)

export const selectValidatedSocialUsers = createSelector(
    selectSocialUser,
    state => state.get("validated")
)

export const selectSocialUsersFromModel = (modelName, selector) =>
    (state, props) => {
        const datumObject = selector(state, props)
        return datumObject
            ? selectValidSocialUsers(modelName)(datumObject.get("id"))(state)
            : Immutable.fromJS([])
    }

export const selectSocialInitialValues = (modelName, selector) => createSelector(
    selectSocialUsersFromModel(modelName, selector),
    (socialUsers) => {
        const socialUsersObj = socialUsers.map((socialObjImmutable) => {
            const socialObj = socialObjImmutable.toJS()
            const socialType = DjangIO.app.social.models.SocialUserType.by_value(socialObj.social_user_type)
            return { id: socialObj.id, type: socialType.platform_key, username: socialObj.username }
        })
        return socialUsersObj.toJS()
    }
)

export const selectSocialUsersForOrganization = organizationId => createSelector(
    selectSocialUser,
    state => state.get(`${DjangIO.app.person.models.PublicOrganization.__name__}-${organizationId}`) || Immutable.fromJS([])
)

export const selectSocialUsersFromCurrentOrganization = orgSelector => (state) => {
    const organization = orgSelector(state)
    if (organization) {
        const socialUsers = selectSocialUsersForOrganization(organization.get("id"))(state)
        return socialUsers
    }
}

export const selectSocialUsersForSupporter = supporterId => createSelector(
    selectSocialUser,
    state => state.get(`${DjangIO.app.grassroots.models.Supporter.__name__}-${supporterId}`) || Immutable.fromJS([])
)

import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 12px;
    }
`

export const CheckboxLabel = styled.label`
    display: flex;
    align-items: baseline;
    margin-bottom: 0;

    svg {
        margin-right: 4px;
        font-size: 14px;
    }
`

export const CheckboxIcon = styled.div`
    margin-right: 12px;

    svg {
        margin: 0;
        font-size: 20px;
    }

    /* Set the duotone icon colors */
    ${(props) => {
        if (props.hasError) {
            return css`
                color: ${colors.ErrorRed};
                ${colors.duotoneRed};
            `
        } else if (props.hasWarning) {
            return css`
                color: ${colors.WarningOrange};
                ${colors.duotoneOrange};
            `
        } else if (props.disabled) {
            return css`
                color: ${colors.SilverChalice};
            `
        }

        return css`
            color: ${colors.violet5};
            ${colors.duotoneBlue};
        `
    }}
`

export const Text = styled.span`
    ${(props) => css`
        color: ${props.disabled ? colors.gray5 : colors.gray7};
    `}

    ${rules.MediumText}

    ${(props) =>
        props.isChecked
            ? css`
                  ${rules.BoldWeight};
                  font-weight: 500;
              `
            : css`
                  ${rules.RegularWeight}
              `}

    margin-right: 5px;
`

export const Checkbox = styled.input.attrs((props) => ({
    type: "checkbox",
}))`
    /* Suppress default appearance */
    appearance: none;
`

export const Error = styled.div`
    ${rules.SmallTextLightWeight}

    ${(props) => {
        if (props.hasError) {
            return css`
                color: ${colors.ErrorRed};
            `
        } else if (props.hasWarning) {
            return css`
                color: ${colors.WarningOrange};
            `
        }
    }}

    svg {
        margin-left: 2px;
        margin-right: 5px;
    }
`

import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

const transactionColor = (isCompleted, defaultColor) =>
    isCompleted ? colors.CompletedGreen : defaultColor || colors.QuorumBlue

const boxColor = ({isCompleted}) => transactionColor(isCompleted)

const internalBorder = ({isCompleted}) =>
    `solid ${transactionColor(isCompleted, '#AAAAAA')}`

export const BoxWrapper = styled.div`
    ${rules.CardStyle}
    width: 100%;
    text-align: center;
    color: ${boxColor};
    background-color: ${colors.White};
    // on a phone, single-column layout
    @media (min-width: ${({phoneBreakpoint}) => phoneBreakpoint}) {
        // larger than phone, multi-column layout
        width: ${props => props.customWidth || '324px'};
        height: ${props => props.customHeight || '155px'};
    }
    cursor: ${({onClick}) => onClick ? "pointer" : "unset"};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;
`

export const TopRow = styled(Container)`
    border: ${internalBorder};
    border-width: 0 0 1px 0;
    height: 50%;
    width: 100%;
`

export const BottomRow = styled(Container)`
    flex-direction: row;
    height: 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const BottomLeft = styled(Container)`
    border: ${internalBorder};
    border-width: 0 1px 0 0;
    height: 100%;
    width: 50%;
`
export const BottomRight = styled(Container)`
    height: 100%;
    width: 50%;
`

export const FullCard = styled(Container)`
    height: 100%;
`

export const FirstLine = styled.div`
    ${rules.GiantText}
    font-weight: 510;
    width: 100%;
    padding-top: 5px;
`

export const SecondLine = styled.div`
    ${rules.LargeTextLightWeight}
    font-weight: 440;
    width: 100%;
    padding-bottom: 5px;
`

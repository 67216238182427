// https://fontawesome.com/how-to-use/on-the-web/setup/upgrading-from-version-4#name-changes
// https://github.com/estebandelaf/scripts/blob/master/fontawesome4to5.sh
export const fa4to5 = {
    "500px": {
        icon: "500px",
        family: "fab",
        unicode: "f26e",
    },
    "address-book-o": {
        icon: "address-book",
        family: "far",
        unicode: "f2b9",
    },
    "address-card-o": {
        icon: "address-card",
        family: "far",
        unicode: "f2bb",
    },
    "adn": {
        icon: "adn",
        family: "fab",
        unicode: "f170",
    },
    "amazon": {
        icon: "amazon",
        family: "fab",
        unicode: "f270",
    },
    "android": {
        icon: "android",
        family: "fab",
        unicode: "f17b",
    },
    "angellist": {
        icon: "angellist",
        family: "fab",
        unicode: "f209",
    },
    "apple": {
        icon: "apple",
        family: "fab",
        unicode: "f179",
    },
    "area-chart": {
        icon: "chart-area",
        family: "fas",
        unicode: "f1fe",
    },
    "arrow-circle-o-down": {
        icon: "arrow-alt-circle-down",
        family: "far",
        unicode: "f358",
    },
    "arrow-circle-o-left": {
        icon: "arrow-alt-circle-left",
        family: "far",
        unicode: "f359",
    },
    "arrow-circle-o-right": {
        icon: "arrow-alt-circle-right",
        family: "far",
        unicode: "f35a",
    },
    "arrow-circle-o-up": {
        icon: "arrow-alt-circle-up",
        family: "far",
        unicode: "f35b",
    },
    "arrows": {
        icon: "arrows-alt",
        family: "fas",
        unicode: "f0b2",
    },
    "arrows-alt": {
        icon: "expand-arrows-alt",
        family: "fas",
        unicode: "f31e",
    },
    "arrows-h": {
        icon: "arrows-alt-h",
        family: "fas",
        unicode: "f337",
    },
    "arrows-v": {
        icon: "arrows-alt-v",
        family: "fas",
        unicode: "f338",
    },
    "asl-interpreting": {
        icon: "american-sign-language-interpreting",
        family: "fas",
        unicode: "f2a3",
    },
    "automobile": {
        icon: "car",
        family: "fas",
        unicode: "f1b9",
    },
    "ball-pile": {
        icon: "ball-pile",
        family: "fas",
        unicode: "f77e",
    },
    "bandcamp": {
        icon: "bandcamp",
        family: "fab",
        unicode: "f2d5",
    },
    "bank": {
        icon: "university",
        family: "fas",
        unicode: "f19c",
    },
    "bar-chart": {
        icon: "chart-bar",
        family: "far",
        unicode: "f080",
    },
    "bar-chart-o": {
        icon: "chart-bar",
        family: "far",
        unicode: "f080",
    },
    "bathtub": {
        icon: "bath",
        family: "fas",
        unicode: "f2cd",
    },
    "battery": {
        icon: "battery-full",
        family: "fas",
        unicode: "f240",
    },
    "battery-0": {
        icon: "battery-empty",
        family: "fas",
        unicode: "f244",
    },
    "battery-1": {
        icon: "battery-quarter",
        family: "fas",
        unicode: "f243",
    },
    "battery-2": {
        icon: "battery-half",
        family: "fas",
        unicode: "f242",
    },
    "battery-3": {
        icon: "battery-three-quarters",
        family: "fas",
        unicode: "f241",
    },
    "battery-4": {
        icon: "battery-full",
        family: "fas",
        unicode: "f240",
    },
    "behance": {
        icon: "behance",
        family: "fab",
        unicode: "f1b4",
    },
    "behance-square": {
        icon: "behance-square",
        family: "fab",
        unicode: "f1b5",
    },
    "bell-o": {
        icon: "bell",
        family: "far",
        unicode: "f0f3",
    },
    "bell-slash-o": {
        icon: "bell-slash",
        family: "far",
        unicode: "f1f6",
    },
    "bitbucket": {
        icon: "bitbucket",
        family: "fab",
        unicode: "f171",
    },
    "bitbucket-square": {
        icon: "bitbucket",
        family: "fab",
        unicode: "f171",
    },
    "bitcoin": {
        icon: "btc",
        family: "fab",
        unicode: "f15a",
    },
    "black-tie": {
        icon: "black-tie",
        family: "fab",
        unicode: "f27e",
    },
    "bluetooth": {
        icon: "bluetooth",
        family: "fab",
        unicode: "f293",
    },
    "bluetooth-b": {
        icon: "bluetooth-b",
        family: "fab",
        unicode: "f294",
    },
    "bookmark-o": {
        icon: "bookmark",
        family: "far",
        unicode: "f02e",
    },
    "btc": {
        icon: "btc",
        family: "fab",
        unicode: "f15a",
    },
    "building-o": {
        icon: "building",
        family: "far",
        unicode: "f1ad",
    },
    "buysellads": {
        icon: "buysellads",
        family: "fab",
        unicode: "f20d",
    },
    "cab": {
        icon: "taxi",
        family: "fas",
        unicode: "f1ba",
    },
    "calendar": {
        icon: "calendar-alt",
        family: "fas",
        unicode: "f073",
    },
    "calendar-check-o": {
        icon: "calendar-check",
        family: "far",
        unicode: "f274",
    },
    "calendar-minus-o": {
        icon: "calendar-minus",
        family: "far",
        unicode: "f272",
    },
    "calendar-o": {
        icon: "calendar",
        family: "far",
        unicode: "f133",
    },
    "calendar-plus-o": {
        icon: "calendar-plus",
        family: "far",
        unicode: "f271",
    },
    "calendar-times-o": {
        icon: "calendar-times",
        family: "far",
        unicode: "f273",
    },
    "caret-square-o-down": {
        icon: "caret-square-down",
        family: "far",
        unicode: "f150",
    },
    "caret-square-o-left": {
        icon: "caret-square-left",
        family: "far",
        unicode: "f191",
    },
    "caret-square-o-right": {
        icon: "caret-square-right",
        family: "far",
        unicode: "f152",
    },
    "caret-square-o-up": {
        icon: "caret-square-up",
        family: "far",
        unicode: "f151",
    },
    "cc": {
        icon: "closed-captioning",
        family: "far",
        unicode: "f20a",
    },
    "cc-amex": {
        icon: "cc-amex",
        family: "fab",
        unicode: "f1f3",
    },
    "cc-diners-club": {
        icon: "cc-diners-club",
        family: "fab",
        unicode: "f24c",
    },
    "cc-discover": {
        icon: "cc-discover",
        family: "fab",
        unicode: "f1f2",
    },
    "cc-jcb": {
        icon: "cc-jcb",
        family: "fab",
        unicode: "f24b",
    },
    "cc-mastercard": {
        icon: "cc-mastercard",
        family: "fab",
        unicode: "f1f1",
    },
    "cc-paypal": {
        icon: "cc-paypal",
        family: "fab",
        unicode: "f1f4",
    },
    "cc-stripe": {
        icon: "cc-stripe",
        family: "fab",
        unicode: "f1f5",
    },
    "cc-visa": {
        icon: "cc-visa",
        family: "fab",
        unicode: "f1f0",
    },
    "chain": {
        icon: "link",
        family: "fas",
        unicode: "f0c1",
    },
    "chain-broken": {
        icon: "unlink",
        family: "fas",
        unicode: "f127",
    },
    "check-circle-o": {
        icon: "check-circle",
        family: "far",
        unicode: "f058",
    },
    "check-square-o": {
        icon: "check-square",
        family: "far",
        unicode: "f14a",
    },
    "chrome": {
        icon: "chrome",
        family: "fab",
        unicode: "f268",
    },
    "circle-o": {
        icon: "circle",
        family: "far",
        unicode: "f111",
    },
    "circle-o-notch": {
        icon: "circle-notch",
        family: "fas",
        unicode: "f1ce",
    },
    "circle-thin": {
        icon: "circle",
        family: "far",
        unicode: "f111",
    },
    "clipboard": {
        icon: "clipboard",
        family: "far",
        unicode: "f328",
    },
    "clock-o": {
        icon: "clock",
        family: "far",
        unicode: "f017",
    },
    "clone": {
        icon: "clone",
        family: "far",
        unicode: "f24d",
    },
    "close": {
        icon: "times",
        family: "fas",
        unicode: "f00d",
    },
    "cloud-download": {
        icon: "cloud-download-alt",
        family: "fas",
        unicode: "f381",
    },
    "cloud-upload": {
        icon: "cloud-upload-alt",
        family: "fas",
        unicode: "f382",
    },
    "cny": {
        icon: "yen-sign",
        family: "fas",
        unicode: "f157",
    },
    "code-fork": {
        icon: "code-branch",
        family: "fas",
        unicode: "f126",
    },
    "codepen": {
        icon: "codepen",
        family: "fab",
        unicode: "f1cb",
    },
    "codiepie": {
        icon: "codiepie",
        family: "fab",
        unicode: "f284",
    },
    "comment-o": {
        icon: "comment",
        family: "far",
        unicode: "f075",
    },
    "commenting": {
        icon: "comment-dots",
        family: "fas",
        unicode: "f4ad",
    },
    "commenting-o": {
        icon: "comment-dots",
        family: "far",
        unicode: "f4ad",
    },
    "comments-o": {
        icon: "comments",
        family: "far",
        unicode: "f086",
    },
    "compass": {
        icon: "compass",
        family: "far",
        unicode: "f14e",
    },
    "connectdevelop": {
        icon: "connectdevelop",
        family: "fab",
        unicode: "f20e",
    },
    "contao": {
        icon: "contao",
        family: "fab",
        unicode: "f26d",
    },
    "copyright": {
        icon: "copyright",
        family: "far",
        unicode: "f1f9",
    },
    "creative-commons": {
        icon: "creative-commons",
        family: "fab",
        unicode: "f25e",
    },
    "credit-card": {
        icon: "credit-card",
        family: "far",
        unicode: "f09d",
    },
    "credit-card-alt": {
        icon: "credit-card",
        family: "fas",
        unicode: "f09d",
    },
    "css3": {
        icon: "css3",
        family: "fab",
        unicode: "f13c",
    },
    "cutlery": {
        icon: "utensils",
        family: "fas",
        unicode: "f2e7",
    },
    "dashboard": {
        icon: "tachometer-alt",
        family: "fas",
        unicode: "f3fd",
    },
    "dashcube": {
        icon: "dashcube",
        family: "fab",
        unicode: "f210",
    },
    "deafness": {
        icon: "deaf",
        family: "fas",
        unicode: "f2a4",
    },
    "dedent": {
        icon: "outdent",
        family: "fas",
        unicode: "f03b",
    },
    "delicious": {
        icon: "delicious",
        family: "fab",
        unicode: "f1a5",
    },
    "deviantart": {
        icon: "deviantart",
        family: "fab",
        unicode: "f1bd",
    },
    "diamond": {
        icon: "gem",
        family: "far",
        unicode: "f3a5",
    },
    "digg": {
        icon: "digg",
        family: "fab",
        unicode: "f1a6",
    },
    "dollar": {
        icon: "dollar-sign",
        family: "fas",
        unicode: "f155",
    },
    "dot-circle-o": {
        icon: "dot-circle",
        family: "far",
        unicode: "f192",
    },
    "dribbble": {
        icon: "dribbble",
        family: "fab",
        unicode: "f17d",
    },
    "drivers-license": {
        icon: "id-card",
        family: "fas",
        unicode: "f2c2",
    },
    "drivers-license-o": {
        icon: "id-card",
        family: "far",
        unicode: "f2c2",
    },
    "dropbox": {
        icon: "dropbox",
        family: "fab",
        unicode: "f16b",
    },
    "drupal": {
        icon: "drupal",
        family: "fab",
        unicode: "f1a9",
    },
    "edge": {
        icon: "edge",
        family: "fab",
        unicode: "f282",
    },
    "eercast": {
        icon: "sellcast",
        family: "fab",
        unicode: "f2da",
    },
    "empire": {
        icon: "empire",
        family: "fab",
        unicode: "f1d1",
    },
    "envelope-o": {
        icon: "envelope",
        family: "far",
        unicode: "f0e0",
    },
    "envelope-open-o": {
        icon: "envelope-open",
        family: "far",
        unicode: "f2b6",
    },
    "envira": {
        icon: "envira",
        family: "fab",
        unicode: "f299",
    },
    "etsy": {
        icon: "etsy",
        family: "fab",
        unicode: "f2d7",
    },
    "eur": {
        icon: "euro-sign",
        family: "fas",
        unicode: "f153",
    },
    "euro": {
        icon: "euro-sign",
        family: "fas",
        unicode: "f153",
    },
    "exchange": {
        icon: "exchange-alt",
        family: "fas",
        unicode: "f362",
    },
    "expeditedssl": {
        icon: "expeditedssl",
        family: "fab",
        unicode: "f23e",
    },
    "external-link": {
        icon: "external-link-alt",
        family: "fas",
        unicode: "f35d",
    },
    "external-link-square": {
        icon: "external-link-square-alt",
        family: "fas",
        unicode: "f360",
    },
    "eye": {
        icon: "eye",
        family: "far",
        unicode: "f06e",
    },
    "eye-slash": {
        icon: "eye-slash",
        family: "far",
        unicode: "f070",
    },
    "eyedropper": {
        icon: "eye-dropper",
        family: "fas",
        unicode: "f1fb",
    },
    "fa": {
        icon: "font-awesome",
        family: "fab",
        unicode: "f2b4",
    },
    "facebook": {
        icon: "facebook-f",
        family: "fab",
        unicode: "f39e",
    },
    "facebook-f": {
        icon: "facebook-f",
        family: "fab",
        unicode: "f39e",
    },
    "facebook-official": {
        icon: "facebook",
        family: "fab",
        unicode: "f09a",
    },
    "facebook-square": {
        icon: "facebook-square",
        family: "fab",
        unicode: "f082",
    },
    "feed": {
        icon: "rss",
        family: "fas",
        unicode: "f09e",
    },
    "file-archive-o": {
        icon: "file-archive",
        family: "far",
        unicode: "f1c6",
    },
    "file-audio-o": {
        icon: "file-audio",
        family: "far",
        unicode: "f1c7",
    },
    "file-code-o": {
        icon: "file-code",
        family: "far",
        unicode: "f1c9",
    },
    "file-excel-o": {
        icon: "file-excel",
        family: "far",
        unicode: "f1c3",
    },
    "file-image-o": {
        icon: "file-image",
        family: "far",
        unicode: "f1c5",
    },
    "file-movie-o": {
        icon: "file-video",
        family: "far",
        unicode: "f1c8",
    },
    "file-o": {
        icon: "file",
        family: "far",
        unicode: "f15b",
    },
    "file-pdf-o": {
        icon: "file-pdf",
        family: "far",
        unicode: "f1c1",
    },
    "file-photo-o": {
        icon: "file-image",
        family: "far",
        unicode: "f1c5",
    },
    "file-picture-o": {
        icon: "file-image",
        family: "far",
        unicode: "f1c5",
    },
    "file-powerpoint-o": {
        icon: "file-powerpoint",
        family: "far",
        unicode: "f1c4",
    },
    "file-sound-o": {
        icon: "file-audio",
        family: "far",
        unicode: "f1c7",
    },
    "file-text": {
        icon: "file-alt",
        family: "fas",
        unicode: "f15c",
    },
    "file-text-o": {
        icon: "file-alt",
        family: "far",
        unicode: "f15c",
    },
    "file-video-o": {
        icon: "file-video",
        family: "far",
        unicode: "f1c8",
    },
    "file-word-o": {
        icon: "file-word",
        family: "far",
        unicode: "f1c2",
    },
    "file-zip-o": {
        icon: "file-archive",
        family: "far",
        unicode: "f1c6",
    },
    "files-o": {
        icon: "copy",
        family: "far",
        unicode: "f0c5",
    },
    "firefox": {
        icon: "firefox",
        family: "fab",
        unicode: "f269",
    },
    "first-order": {
        icon: "first-order",
        family: "fab",
        unicode: "f2b0",
    },
    "flag-o": {
        icon: "flag",
        family: "far",
        unicode: "f024",
    },
    "flash": {
        icon: "bolt",
        family: "fas",
        unicode: "f0e7",
    },
    "flickr": {
        icon: "flickr",
        family: "fab",
        unicode: "f16e",
    },
    "floppy-o": {
        icon: "save",
        family: "far",
        unicode: "f0c7",
    },
    "folder-o": {
        icon: "folder",
        family: "far",
        unicode: "f07b",
    },
    "folder-open-o": {
        icon: "folder-open",
        family: "far",
        unicode: "f07c",
    },
    "font-awesome": {
        icon: "font-awesome",
        family: "fab",
        unicode: "f2b4",
    },
    "fonticons": {
        icon: "fonticons",
        family: "fab",
        unicode: "f280",
    },
    "fort-awesome": {
        icon: "fort-awesome",
        family: "fab",
        unicode: "f286",
    },
    "forumbee": {
        icon: "forumbee",
        family: "fab",
        unicode: "f211",
    },
    "foursquare": {
        icon: "foursquare",
        family: "fab",
        unicode: "f180",
    },
    "free-code-camp": {
        icon: "free-code-camp",
        family: "fab",
        unicode: "f2c5",
    },
    "frown-o": {
        icon: "frown",
        family: "far",
        unicode: "f119",
    },
    "futbol-o": {
        icon: "futbol",
        family: "far",
        unicode: "f1e3",
    },
    "gbp": {
        icon: "pound-sign",
        family: "fas",
        unicode: "f154",
    },
    "ge": {
        icon: "empire",
        family: "fab",
        unicode: "f1d1",
    },
    "gear": {
        icon: "cog",
        family: "fas",
        unicode: "f013",
    },
    "gears": {
        icon: "cogs",
        family: "fas",
        unicode: "f085",
    },
    "get-pocket": {
        icon: "get-pocket",
        family: "fab",
        unicode: "f265",
    },
    "gg": {
        icon: "gg",
        family: "fab",
        unicode: "f260",
    },
    "gg-circle": {
        icon: "gg-circle",
        family: "fab",
        unicode: "f261",
    },
    "git": {
        icon: "git",
        family: "fab",
        unicode: "f1d3",
    },
    "git-square": {
        icon: "git-square",
        family: "fab",
        unicode: "f1d2",
    },
    "github": {
        icon: "github",
        family: "fab",
        unicode: "f09b",
    },
    "github-alt": {
        icon: "github-alt",
        family: "fab",
        unicode: "f113",
    },
    "github-square": {
        icon: "github-square",
        family: "fab",
        unicode: "f092",
    },
    "gitlab": {
        icon: "gitlab",
        family: "fab",
        unicode: "f296",
    },
    "gittip": {
        icon: "gratipay",
        family: "fab",
        unicode: "f184",
    },
    "glass": {
        icon: "glass-martini",
        family: "fas",
        unicode: "f000",
    },
    "glide": {
        icon: "glide",
        family: "fab",
        unicode: "f2a5",
    },
    "glide-g": {
        icon: "glide-g",
        family: "fab",
        unicode: "f2a6",
    },
    "google": {
        icon: "google",
        family: "fab",
        unicode: "f1a0",
    },
    "google-plus": {
        icon: "google-plus-g",
        family: "fab",
        unicode: "f0d5",
    },
    "google-plus-circle": {
        icon: "google-plus",
        family: "fab",
        unicode: "f2b3",
    },
    "google-plus-official": {
        icon: "google-plus",
        family: "fab",
        unicode: "f2b3",
    },
    "google-plus-square": {
        icon: "google-plus-square",
        family: "fab",
        unicode: "f0d4",
    },
    "google-wallet": {
        icon: "google-wallet",
        family: "fab",
        unicode: "f1ee",
    },
    "gratipay": {
        icon: "gratipay",
        family: "fab",
        unicode: "f184",
    },
    "grav": {
        icon: "grav",
        family: "fab",
        unicode: "f2d6",
    },
    "group": {
        icon: "users",
        family: "fas",
        unicode: "f0c0",
    },
    "hacker-news": {
        icon: "hacker-news",
        family: "fab",
        unicode: "f1d4",
    },
    "hand-grab-o": {
        icon: "hand-rock",
        family: "far",
        unicode: "f255",
    },
    "hand-lizard-o": {
        icon: "hand-lizard",
        family: "far",
        unicode: "f258",
    },
    "hand-o-down": {
        icon: "hand-point-down",
        family: "far",
        unicode: "f0a7",
    },
    "hand-o-left": {
        icon: "hand-point-left",
        family: "far",
        unicode: "f0a5",
    },
    "hand-o-right": {
        icon: "hand-point-right",
        family: "far",
        unicode: "f0a4",
    },
    "hand-o-up": {
        icon: "hand-point-up",
        family: "far",
        unicode: "f0a6",
    },
    "hand-paper-o": {
        icon: "hand-paper",
        family: "far",
        unicode: "f256",
    },
    "hand-peace-o": {
        icon: "hand-peace",
        family: "far",
        unicode: "f25b",
    },
    "hand-pointer-o": {
        icon: "hand-pointer",
        family: "far",
        unicode: "f25a",
    },
    "hand-rock-o": {
        icon: "hand-rock",
        family: "far",
        unicode: "f255",
    },
    "hand-scissors-o": {
        icon: "hand-scissors",
        family: "far",
        unicode: "f257",
    },
    "hand-spock-o": {
        icon: "hand-spock",
        family: "far",
        unicode: "f259",
    },
    "hand-stop-o": {
        icon: "hand-paper",
        family: "far",
        unicode: "f256",
    },
    "handshake-o": {
        icon: "handshake",
        family: "far",
        unicode: "f2b5",
    },
    "hard-of-hearing": {
        icon: "deaf",
        family: "fas",
        unicode: "f2a4",
    },
    "hdd-o": {
        icon: "hdd",
        family: "far",
        unicode: "f0a0",
    },
    "header": {
        icon: "heading",
        family: "fas",
        unicode: "f1dc",
    },
    "heart-o": {
        icon: "heart",
        family: "far",
        unicode: "f004",
    },
    "hospital-o": {
        icon: "hospital",
        family: "far",
        unicode: "f0f8",
    },
    "hotel": {
        icon: "bed",
        family: "fas",
        unicode: "f236",
    },
    "hourglass-1": {
        icon: "hourglass-start",
        family: "fas",
        unicode: "f251",
    },
    "hourglass-2": {
        icon: "hourglass-half",
        family: "fas",
        unicode: "f252",
    },
    "hourglass-3": {
        icon: "hourglass-end",
        family: "fas",
        unicode: "f253",
    },
    "hourglass-o": {
        icon: "hourglass",
        family: "far",
        unicode: "f254",
    },
    "houzz": {
        icon: "houzz",
        family: "fab",
        unicode: "f27c",
    },
    "html5": {
        icon: "html5",
        family: "fab",
        unicode: "f13b",
    },
    "id-badge": {
        icon: "id-badge",
        family: "far",
        unicode: "f2c1",
    },
    "id-card-o": {
        icon: "id-card",
        family: "far",
        unicode: "f2c2",
    },
    "ils": {
        icon: "shekel-sign",
        family: "fas",
        unicode: "f20b",
    },
    "image": {
        icon: "image",
        family: "far",
        unicode: "f03e",
    },
    "imdb": {
        icon: "imdb",
        family: "fab",
        unicode: "f2d8",
    },
    "inr": {
        icon: "rupee-sign",
        family: "fas",
        unicode: "f156",
    },
    "instagram": {
        icon: "instagram",
        family: "fab",
        unicode: "f16d",
    },
    "institution": {
        icon: "university",
        family: "fas",
        unicode: "f19c",
    },
    "internet-explorer": {
        icon: "internet-explorer",
        family: "fab",
        unicode: "f26b",
    },
    "intersex": {
        icon: "transgender",
        family: "fas",
        unicode: "f224",
    },
    "ioxhost": {
        icon: "ioxhost",
        family: "fab",
        unicode: "f208",
    },
    "joomla": {
        icon: "joomla",
        family: "fab",
        unicode: "f1aa",
    },
    "jpy": {
        icon: "yen-sign",
        family: "fas",
        unicode: "f157",
    },
    "jsfiddle": {
        icon: "jsfiddle",
        family: "fab",
        unicode: "f1cc",
    },
    "keyboard-o": {
        icon: "keyboard",
        family: "far",
        unicode: "f11c",
    },
    "krw": {
        icon: "won-sign",
        family: "fas",
        unicode: "f159",
    },
    "lastfm": {
        icon: "lastfm",
        family: "fab",
        unicode: "f202",
    },
    "lastfm-square": {
        icon: "lastfm-square",
        family: "fab",
        unicode: "f203",
    },
    "leanpub": {
        icon: "leanpub",
        family: "fab",
        unicode: "f212",
    },
    "legal": {
        icon: "gavel",
        family: "fas",
        unicode: "f0e3",
    },
    "lemon-o": {
        icon: "lemon",
        family: "far",
        unicode: "f094",
    },
    "level-down": {
        icon: "level-down-alt",
        family: "fas",
        unicode: "f3be",
    },
    "level-up": {
        icon: "level-up-alt",
        family: "fas",
        unicode: "f3bf",
    },
    "life-bouy": {
        icon: "life-ring",
        family: "far",
        unicode: "f1cd",
    },
    "life-buoy": {
        icon: "life-ring",
        family: "far",
        unicode: "f1cd",
    },
    "life-ring": {
        icon: "life-ring",
        family: "far",
        unicode: "f1cd",
    },
    "life-saver": {
        icon: "life-ring",
        family: "far",
        unicode: "f1cd",
    },
    "lightbulb-o": {
        icon: "lightbulb",
        family: "far",
        unicode: "f0eb",
    },
    "line-chart": {
        icon: "chart-line",
        family: "fas",
        unicode: "f201",
    },
    "linkedin": {
        icon: "linkedin-in",
        family: "fab",
        unicode: "f0e1",
    },
    "linkedin-square": {
        icon: "linkedin",
        family: "fab",
        unicode: "f08c",
    },
    "linode": {
        icon: "linode",
        family: "fab",
        unicode: "f2b8",
    },
    "linux": {
        icon: "linux",
        family: "fab",
        unicode: "f17c",
    },
    "list-alt": {
        icon: "list-alt",
        family: "far",
        unicode: "f022",
    },
    "long-arrow-down": {
        icon: "long-arrow-alt-down",
        family: "fas",
        unicode: "f309",
    },
    "long-arrow-left": {
        icon: "long-arrow-alt-left",
        family: "fas",
        unicode: "f30a",
    },
    "long-arrow-right": {
        icon: "long-arrow-alt-right",
        family: "fas",
        unicode: "f30b",
    },
    "long-arrow-up": {
        icon: "long-arrow-alt-up",
        family: "fas",
        unicode: "f30c",
    },
    "mail-forward": {
        icon: "share",
        family: "fas",
        unicode: "f064",
    },
    "mail-reply": {
        icon: "reply",
        family: "fas",
        unicode: "f3e5",
    },
    "mail-reply-all": {
        icon: "reply-all",
        family: "fas",
        unicode: "f122",
    },
    "map-marker": {
        icon: "map-marker-alt",
        family: "fas",
        unicode: "f3c5",
    },
    "map-o": {
        icon: "map",
        family: "far",
        unicode: "f279",
    },
    "maxcdn": {
        icon: "maxcdn",
        family: "fab",
        unicode: "f136",
    },
    "meanpath": {
        icon: "font-awesome",
        family: "fab",
        unicode: "f2b4",
    },
    "medium": {
        icon: "medium",
        family: "fab",
        unicode: "f23a",
    },
    "meetup": {
        icon: "meetup",
        family: "fab",
        unicode: "f2e0",
    },
    "meh-o": {
        icon: "meh",
        family: "far",
        unicode: "f11a",
    },
    "minus-square-o": {
        icon: "minus-square",
        family: "far",
        unicode: "f146",
    },
    "mixcloud": {
        icon: "mixcloud",
        family: "fab",
        unicode: "f289",
    },
    "mobile": {
        icon: "mobile-alt",
        family: "fas",
        unicode: "f3cd",
    },
    "mobile-phone": {
        icon: "mobile-alt",
        family: "fas",
        unicode: "f3cd",
    },
    "modx": {
        icon: "modx",
        family: "fab",
        unicode: "f285",
    },
    "money": {
        icon: "money-bill-alt",
        family: "far",
        unicode: "f3d1",
    },
    "moon-o": {
        icon: "moon",
        family: "far",
        unicode: "f186",
    },
    "mortar-board": {
        icon: "graduation-cap",
        family: "fas",
        unicode: "f19d",
    },
    "navicon": {
        icon: "bars",
        family: "fas",
        unicode: "f0c9",
    },
    "newspaper-o": {
        icon: "newspaper",
        family: "far",
        unicode: "f1ea",
    },
    "object-group": {
        icon: "object-group",
        family: "far",
        unicode: "f247",
    },
    "object-ungroup": {
        icon: "object-ungroup",
        family: "far",
        unicode: "f248",
    },
    "odnoklassniki": {
        icon: "odnoklassniki",
        family: "fab",
        unicode: "f263",
    },
    "odnoklassniki-square": {
        icon: "odnoklassniki-square",
        family: "fab",
        unicode: "f264",
    },
    "opencart": {
        icon: "opencart",
        family: "fab",
        unicode: "f23d",
    },
    "openid": {
        icon: "openid",
        family: "fab",
        unicode: "f19b",
    },
    "opera": {
        icon: "opera",
        family: "fab",
        unicode: "f26a",
    },
    "optin-monster": {
        icon: "optin-monster",
        family: "fab",
        unicode: "f23c",
    },
    "pagelines": {
        icon: "pagelines",
        family: "fab",
        unicode: "f18c",
    },
    "paper-plane-o": {
        icon: "paper-plane",
        family: "far",
        unicode: "f1d8",
    },
    "paste": {
        icon: "clipboard",
        family: "far",
        unicode: "f328",
    },
    "pause-circle-o": {
        icon: "pause-circle",
        family: "far",
        unicode: "f28b",
    },
    "paypal": {
        icon: "paypal",
        family: "fab",
        unicode: "f1ed",
    },
    "pencil": {
        icon: "pencil-alt",
        family: "fas",
        unicode: "f303",
    },
    "pencil-square": {
        icon: "pen-square",
        family: "fas",
        unicode: "f14b",
    },
    "pencil-square-o": {
        icon: "edit",
        family: "far",
        unicode: "f044",
    },
    "photo": {
        icon: "image",
        family: "far",
        unicode: "f03e",
    },
    "picture-o": {
        icon: "image",
        family: "far",
        unicode: "f03e",
    },
    "pie-chart": {
        icon: "chart-pie",
        family: "fas",
        unicode: "f200",
    },
    "pied-piper": {
        icon: "pied-piper",
        family: "fab",
        unicode: "f2ae",
    },
    "pied-piper-alt": {
        icon: "pied-piper-alt",
        family: "fab",
        unicode: "f1a8",
    },
    "pied-piper-pp": {
        icon: "pied-piper-pp",
        family: "fab",
        unicode: "f1a7",
    },
    "pinterest": {
        icon: "pinterest",
        family: "fab",
        unicode: "f0d2",
    },
    "pinterest-p": {
        icon: "pinterest-p",
        family: "fab",
        unicode: "f231",
    },
    "pinterest-square": {
        icon: "pinterest-square",
        family: "fab",
        unicode: "f0d3",
    },
    "play-circle-o": {
        icon: "play-circle",
        family: "far",
        unicode: "f144",
    },
    "plus-square-o": {
        icon: "plus-square",
        family: "far",
        unicode: "f0fe",
    },
    "product-hunt": {
        icon: "product-hunt",
        family: "fab",
        unicode: "f288",
    },
    "qq": {
        icon: "qq",
        family: "fab",
        unicode: "f1d6",
    },
    "question-circle-o": {
        icon: "question-circle",
        family: "far",
        unicode: "f059",
    },
    "quora": {
        icon: "quora",
        family: "fab",
        unicode: "f2c4",
    },
    "ra": {
        icon: "rebel",
        family: "fab",
        unicode: "f1d0",
    },
    "ravelry": {
        icon: "ravelry",
        family: "fab",
        unicode: "f2d9",
    },
    "rebel": {
        icon: "rebel",
        family: "fab",
        unicode: "f1d0",
    },
    "reddit": {
        icon: "reddit",
        family: "fab",
        unicode: "f1a1",
    },
    "reddit-alien": {
        icon: "reddit-alien",
        family: "fab",
        unicode: "f281",
    },
    "reddit-square": {
        icon: "reddit-square",
        family: "fab",
        unicode: "f1a2",
    },
    "refresh": {
        icon: "sync",
        family: "fas",
        unicode: "f021",
    },
    "registered": {
        icon: "registered",
        family: "far",
        unicode: "f25d",
    },
    "remove": {
        icon: "times",
        family: "fas",
        unicode: "f00d",
    },
    "renren": {
        icon: "renren",
        family: "fab",
        unicode: "f18b",
    },
    "reorder": {
        icon: "bars",
        family: "fas",
        unicode: "f0c9",
    },
    "repeat": {
        icon: "redo",
        family: "fas",
        unicode: "f01e",
    },
    "resistance": {
        icon: "rebel",
        family: "fab",
        unicode: "f1d0",
    },
    "rmb": {
        icon: "yen-sign",
        family: "fas",
        unicode: "f157",
    },
    "rotate-left": {
        icon: "undo",
        family: "fas",
        unicode: "f0e2",
    },
    "rotate-right": {
        icon: "redo",
        family: "fas",
        unicode: "f01e",
    },
    "rouble": {
        icon: "ruble-sign",
        family: "fas",
        unicode: "f158",
    },
    "rub": {
        icon: "ruble-sign",
        family: "fas",
        unicode: "f158",
    },
    "ruble": {
        icon: "ruble-sign",
        family: "fas",
        unicode: "f158",
    },
    "rupee": {
        icon: "rupee-sign",
        family: "fas",
        unicode: "f156",
    },
    "s15": {
        icon: "bath",
        family: "fas",
        unicode: "f2cd",
    },
    "safari": {
        icon: "safari",
        family: "fab",
        unicode: "f267",
    },
    "scissors": {
        icon: "cut",
        family: "fas",
        unicode: "f0c4",
    },
    "scribd": {
        icon: "scribd",
        family: "fab",
        unicode: "f28a",
    },
    "sellsy": {
        icon: "sellsy",
        family: "fab",
        unicode: "f213",
    },
    "send": {
        icon: "paper-plane",
        family: "fas",
        unicode: "f1d8",
    },
    "send-o": {
        icon: "paper-plane",
        family: "far",
        unicode: "f1d8",
    },
    "share-square-o": {
        icon: "share-square",
        family: "far",
        unicode: "f14d",
    },
    "shekel": {
        icon: "shekel-sign",
        family: "fas",
        unicode: "f20b",
    },
    "sheqel": {
        icon: "shekel-sign",
        family: "fas",
        unicode: "f20b",
    },
    "shield": {
        icon: "shield-alt",
        family: "fas",
        unicode: "f3ed",
    },
    "shirtsinbulk": {
        icon: "shirtsinbulk",
        family: "fab",
        unicode: "f214",
    },
    "sign-in": {
        icon: "sign-in-alt",
        family: "fas",
        unicode: "f2f6",
    },
    "sign-out": {
        icon: "sign-out-alt",
        family: "fas",
        unicode: "f2f5",
    },
    "signing": {
        icon: "sign-language",
        family: "fas",
        unicode: "f2a7",
    },
    "simplybuilt": {
        icon: "simplybuilt",
        family: "fab",
        unicode: "f215",
    },
    "skyatlas": {
        icon: "skyatlas",
        family: "fab",
        unicode: "f216",
    },
    "skype": {
        icon: "skype",
        family: "fab",
        unicode: "f17e",
    },
    "slack": {
        icon: "slack",
        family: "fab",
        unicode: "f198",
    },
    "sliders": {
        icon: "sliders-h",
        family: "fas",
        unicode: "f1de",
    },
    "slideshare": {
        icon: "slideshare",
        family: "fab",
        unicode: "f1e7",
    },
    "smile-o": {
        icon: "smile",
        family: "far",
        unicode: "f118",
    },
    "snapchat": {
        icon: "snapchat",
        family: "fab",
        unicode: "f2ab",
    },
    "snapchat-ghost": {
        icon: "snapchat-ghost",
        family: "fab",
        unicode: "f2ac",
    },
    "snapchat-square": {
        icon: "snapchat-square",
        family: "fab",
        unicode: "f2ad",
    },
    "snowflake-o": {
        icon: "snowflake",
        family: "far",
        unicode: "f2dc",
    },
    "soccer-ball-o": {
        icon: "futbol",
        family: "far",
        unicode: "f1e3",
    },
    "sort-alpha-asc": {
        icon: "sort-alpha-down",
        family: "fas",
        unicode: "f15d",
    },
    "sort-alpha-desc": {
        icon: "sort-alpha-up",
        family: "fas",
        unicode: "f15e",
    },
    "sort-amount-asc": {
        icon: "sort-amount-down",
        family: "fas",
        unicode: "f160",
    },
    "sort-amount-desc": {
        icon: "sort-amount-up",
        family: "fas",
        unicode: "f161",
    },
    "sort-asc": {
        icon: "sort-up",
        family: "fas",
        unicode: "f0de",
    },
    "sort-desc": {
        icon: "sort-down",
        family: "fas",
        unicode: "f0dd",
    },
    "sort-numeric-asc": {
        icon: "sort-numeric-down",
        family: "fas",
        unicode: "f162",
    },
    "sort-numeric-desc": {
        icon: "sort-numeric-up",
        family: "fas",
        unicode: "f163",
    },
    "soundcloud": {
        icon: "soundcloud",
        family: "fab",
        unicode: "f1be",
    },
    "spoon": {
        icon: "utensil-spoon",
        family: "fas",
        unicode: "f2e5",
    },
    "spotify": {
        icon: "spotify",
        family: "fab",
        unicode: "f1bc",
    },
    "square-o": {
        icon: "square",
        family: "far",
        unicode: "f0c8",
    },
    "stack-exchange": {
        icon: "stack-exchange",
        family: "fab",
        unicode: "f18d",
    },
    "stack-overflow": {
        icon: "stack-overflow",
        family: "fab",
        unicode: "f16c",
    },
    "star-half-empty": {
        icon: "star-half",
        family: "far",
        unicode: "f089",
    },
    "star-half-full": {
        icon: "star-half",
        family: "far",
        unicode: "f089",
    },
    "star-half-o": {
        icon: "star-half",
        family: "far",
        unicode: "f089",
    },
    "star-o": {
        icon: "star",
        family: "far",
        unicode: "f005",
    },
    "steam": {
        icon: "steam",
        family: "fab",
        unicode: "f1b6",
    },
    "steam-square": {
        icon: "steam-square",
        family: "fab",
        unicode: "f1b7",
    },
    "sticky-note-o": {
        icon: "sticky-note",
        family: "far",
        unicode: "f249",
    },
    "stop-circle-o": {
        icon: "stop-circle",
        family: "far",
        unicode: "f28d",
    },
    "stumbleupon": {
        icon: "stumbleupon",
        family: "fab",
        unicode: "f1a4",
    },
    "stumbleupon-circle": {
        icon: "stumbleupon-circle",
        family: "fab",
        unicode: "f1a3",
    },
    "sun-o": {
        icon: "sun",
        family: "far",
        unicode: "f185",
    },
    "superpowers": {
        icon: "superpowers",
        family: "fab",
        unicode: "f2dd",
    },
    "support": {
        icon: "life-ring",
        family: "far",
        unicode: "f1cd",
    },
    "tablet": {
        icon: "tablet-alt",
        family: "fas",
        unicode: "f3fa",
    },
    "tachometer": {
        icon: "tachometer-alt",
        family: "fas",
        unicode: "f3fd",
    },
    "telegram": {
        icon: "telegram",
        family: "fab",
        unicode: "f2c6",
    },
    "television": {
        icon: "tv",
        family: "fas",
        unicode: "f26c",
    },
    "tencent-weibo": {
        icon: "tencent-weibo",
        family: "fab",
        unicode: "f1d5",
    },
    "themeisle": {
        icon: "themeisle",
        family: "fab",
        unicode: "f2b2",
    },
    "thermometer": {
        icon: "thermometer-full",
        family: "fas",
        unicode: "f2c7",
    },
    "thermometer-0": {
        icon: "thermometer-empty",
        family: "fas",
        unicode: "f2cb",
    },
    "thermometer-1": {
        icon: "thermometer-quarter",
        family: "fas",
        unicode: "f2ca",
    },
    "thermometer-2": {
        icon: "thermometer-half",
        family: "fas",
        unicode: "f2c9",
    },
    "thermometer-3": {
        icon: "thermometer-three-quarters",
        family: "fas",
        unicode: "f2c8",
    },
    "thermometer-4": {
        icon: "thermometer-full",
        family: "fas",
        unicode: "f2c7",
    },
    "thumb-tack": {
        icon: "thumbtack",
        family: "fas",
        unicode: "f08d",
    },
    "thumbs-o-down": {
        icon: "thumbs-down",
        family: "far",
        unicode: "f165",
    },
    "thumbs-o-up": {
        icon: "thumbs-up",
        family: "far",
        unicode: "f164",
    },
    "ticket": {
        icon: "ticket-alt",
        family: "fas",
        unicode: "f3ff",
    },
    "times-circle-o": {
        icon: "times-circle",
        family: "far",
        unicode: "f057",
    },
    "times-rectangle": {
        icon: "window-close",
        family: "fas",
        unicode: "f410",
    },
    "times-rectangle-o": {
        icon: "window-close",
        family: "far",
        unicode: "f410",
    },
    "toggle-down": {
        icon: "caret-square-down",
        family: "far",
        unicode: "f150",
    },
    "toggle-left": {
        icon: "caret-square-left",
        family: "far",
        unicode: "f191",
    },
    "toggle-right": {
        icon: "caret-square-right",
        family: "far",
        unicode: "f152",
    },
    "toggle-up": {
        icon: "caret-square-up",
        family: "far",
        unicode: "f151",
    },
    "trash": {
        icon: "trash-alt",
        family: "fas",
        unicode: "f2ed",
    },
    "trash-o": {
        icon: "trash-alt",
        family: "far",
        unicode: "f2ed",
    },
    "trello": {
        icon: "trello",
        family: "fab",
        unicode: "f181",
    },
    "tripadvisor": {
        icon: "tripadvisor",
        family: "fab",
        unicode: "f262",
    },
    "try": {
        icon: "lira-sign",
        family: "fas",
        unicode: "f195",
    },
    "tumblr": {
        icon: "tumblr",
        family: "fab",
        unicode: "f173",
    },
    "tumblr-square": {
        icon: "tumblr-square",
        family: "fab",
        unicode: "f174",
    },
    "turkish-lira": {
        icon: "lira-sign",
        family: "fas",
        unicode: "f195",
    },
    "twitch": {
        icon: "twitch",
        family: "fab",
        unicode: "f1e8",
    },
    "twitter": {
        icon: "twitter",
        family: "fab",
        unicode: "f099",
    },
    "twitter-square": {
        icon: "twitter-square",
        family: "fab",
        unicode: "f081",
    },
    "unsorted": {
        icon: "sort",
        family: "fas",
        unicode: "f0dc",
    },
    "usb": {
        icon: "usb",
        family: "fab",
        unicode: "f287",
    },
    "usd": {
        icon: "dollar-sign",
        family: "fas",
        unicode: "f155",
    },
    "user-circle-o": {
        icon: "user-circle",
        family: "far",
        unicode: "f2bd",
    },
    "user-o": {
        icon: "user",
        family: "far",
        unicode: "f007",
    },
    "vcard": {
        icon: "address-card",
        family: "fas",
        unicode: "f2bb",
    },
    "vcard-o": {
        icon: "address-card",
        family: "far",
        unicode: "f2bb",
    },
    "viacoin": {
        icon: "viacoin",
        family: "fab",
        unicode: "f237",
    },
    "viadeo": {
        icon: "viadeo",
        family: "fab",
        unicode: "f2a9",
    },
    "viadeo-square": {
        icon: "viadeo-square",
        family: "fab",
        unicode: "f2aa",
    },
    "video-camera": {
        icon: "video",
        family: "fas",
        unicode: "f03d",
    },
    "vimeo": {
        icon: "vimeo-v",
        family: "fab",
        unicode: "f27d",
    },
    "vimeo-square": {
        icon: "vimeo-square",
        family: "fab",
        unicode: "f194",
    },
    "vine": {
        icon: "vine",
        family: "fab",
        unicode: "f1ca",
    },
    "vk": {
        icon: "vk",
        family: "fab",
        unicode: "f189",
    },
    "volume-control-phone": {
        icon: "phone-volume",
        family: "fas",
        unicode: "f2a0",
    },
    "warning": {
        icon: "exclamation-triangle",
        family: "fas",
        unicode: "f071",
    },
    "wechat": {
        icon: "weixin",
        family: "fab",
        unicode: "f1d7",
    },
    "weibo": {
        icon: "weibo",
        family: "fab",
        unicode: "f18a",
    },
    "weixin": {
        icon: "weixin",
        family: "fab",
        unicode: "f1d7",
    },
    "whatsapp": {
        icon: "whatsapp",
        family: "fab",
        unicode: "f232",
    },
    "wheelchair-alt": {
        icon: "accessible-icon",
        family: "fab",
        unicode: "f368",
    },
    "wikipedia-w": {
        icon: "wikipedia-w",
        family: "fab",
        unicode: "f266",
    },
    "window-close-o": {
        icon: "window-close",
        family: "far",
        unicode: "f410",
    },
    "window-maximize": {
        icon: "window-maximize",
        family: "far",
        unicode: "f2d0",
    },
    "window-restore": {
        icon: "window-restore",
        family: "far",
        unicode: "f2d2",
    },
    "windows": {
        icon: "windows",
        family: "fab",
        unicode: "f17a",
    },
    "won": {
        icon: "won-sign",
        family: "fas",
        unicode: "f159",
    },
    "wordpress": {
        icon: "wordpress",
        family: "fab",
        unicode: "f19a",
    },
    "wpbeginner": {
        icon: "wpbeginner",
        family: "fab",
        unicode: "f297",
    },
    "wpexplorer": {
        icon: "wpexplorer",
        family: "fab",
        unicode: "f2de",
    },
    "wpforms": {
        icon: "wpforms",
        family: "fab",
        unicode: "f298",
    },
    "xing": {
        icon: "xing",
        family: "fab",
        unicode: "f168",
    },
    "xing-square": {
        icon: "xing-square",
        family: "fab",
        unicode: "f169",
    },
    "y-combinator": {
        icon: "y-combinator",
        family: "fab",
        unicode: "f23b",
    },
    "y-combinator-square": {
        icon: "hacker-news",
        family: "fab",
        unicode: "f1d4",
    },
    "yahoo": {
        icon: "yahoo",
        family: "fab",
        unicode: "f19e",
    },
    "yc": {
        icon: "y-combinator",
        family: "fab",
        unicode: "f23b",
    },
    "yc-square": {
        icon: "hacker-news",
        family: "fab",
        unicode: "f1d4",
    },
    "yelp": {
        icon: "yelp",
        family: "fab",
        unicode: "f1e9",
    },
    "yen": {
        icon: "yen-sign",
        family: "fas",
        unicode: "f157",
    },
    "yoast": {
        icon: "yoast",
        family: "fab",
        unicode: "f2b1",
    },
    "youtube": {
        icon: "youtube",
        family: "fab",
        unicode: "f167",
    },
    "youtube-play": {
        icon: "youtube",
        family: "fab",
        unicode: "f167",
    },
    "youtube-square": {
        icon: "youtube-square",
        family: "fab",
        unicode: "f431",
    },
}

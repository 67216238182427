import React, { MouseEvent, forwardRef } from "react"
// new FontAwesome icons can be added via QuorumDesign/src/utils/useFontAwesome.js
// see QuorumDesign/src/Components/Atoms/Icon/index.js for additional props options
import { Icon as QuorumDesignIcon } from "quorumdesign"

export interface IconProps {
    /** Text that accurately represents the icon or its function, used by assistive tech like screen readers */
    ariaLabel?: string
    /** Class name for the icon */
    className?: string | string[]
    color?: string
    /** which direction the icon should be flipped */
    flip?: "horizontal" | "vertical" | "both"
    /** Name of font awesome 5 icon, visit the [FontAwesome 5 site](https://fontawesome.com/v5/search) for a list of available icons */
    icon: string
    /** Name of font awesome 5 icon family */
    iconFamily?: string
    /** HTML id for the Icon */
    id?: string
    /** Function to execute when click in the icon */
    onClick?: (e?: MouseEvent<HTMLElement>) => void
    /** Size of the icon */
    size?: "lg" | "xs" | "sm" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x" | "7x" | "8x" | "9x" | "10x" | number
    style?: React.CSSProperties
    shadow?: string
    dataCy?: string
}

export const Icon = forwardRef(
    ({ ariaLabel, className, color, icon, iconFamily, id, onClick, size, style, dataCy, ...rest }: IconProps, ref) => (
        <QuorumDesignIcon
            ariaLabel={ariaLabel}
            className={className}
            color={color}
            fixedWidth="true"
            icon={icon}
            // We want "far"/regular as the default here,
            // whereas QuorumDesign defaults to "fas"/solid.
            iconFamily={iconFamily || "far"}
            id={id}
            ref={ref}
            onClick={onClick}
            size={size}
            style={style}
            dataCy={dataCy}
            {...rest}
        />
    ),
)

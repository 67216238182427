import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"
import { ButtonContainer } from "Components/Molecules/Button/style"

export const EmptyState = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    align-items: center;
    background: ${colors.White};
    display: flex;
`

export const Circle = styled.div`
    height: 270px;
    flex-shrink: 0;
    fill: ${colors.violet1};
    position: relative;
`

export const IconWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: ${colors.violet5};
`

export const Content = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding-left: 53px;
    justify-content: space-evenly;
    flex-grow: 1;
    width: 440px;
`

export const Title = styled.h1`
    ${rules.SmallTitleLightWeight}
    color: ${colors.gray9};
`

export const Text = styled.p`
    ${rules.LargeTextLightWeight}
    font-size: 1rem;
    color: ${colors.gray7};
`

export const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    ${({suppressExtraMargin}) => suppressExtraMargin ? '' : `
        ${ButtonContainer}:first-child {
            margin-right: 11px;
        }
    `}
`

// https://projects.invisionapp.com/d/main/#/console/18861190/444894833/preview
// https://projects.invisionapp.com/d/main/#/console/21044500/448710055/preview

import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

import { ButtonContainer } from "Components/Molecules/Button/style"

export const SearchTextareaContainer = styled.div`
    align-items: flex-start;
    background-color: ${colors.White};
    border: 1px solid ${colors.QuorumBlue};
    border-radius: 4px;
    display: flex;
    flex-grow: 1;
    min-height: 38px;

    ${props => props.isPrimary && css`
        &:focus-within {
            box-shadow: 0px 0px 0px 1px ${colors.QuorumBlue};
        }

        &:hover {
            box-shadow: 0px 0px 0px 1px ${colors.QuorumBlue};
        }
    `}

    ${props => (!props.isPrimary && !props.isSearchFiltersTextarea) && css`
        &:hover {
            border: 1px solid ${colors.DarkerPorcelain};
        }
    `}

    ${props => props.isSearchProfileTextarea && css`
        &:hover {
            border: 1px solid ${colors.QuorumBlue};
        }

        :focus-within {
            border-color: ${colors.QuorumBlue};
            box-shadow: 0px 0px 0px 1px ${colors.QuorumBlue};
        }

        margin-top: 1px;
    `}

    ${props => props.isSearchFiltersTextarea && css`
        &:hover {
           border: 1px solid ${colors.QuorumBlue};
        }

        &:focus-within {
            border-color: ${colors.QuorumBlue};
            box-shadow: 0px 0px 0px 1px ${colors.QuorumBlue};
        }

        margin: 8px 0px 11px 0px;
    `}

    ${props => props.hasBorder && css`
        border-color: ${colors.SilverChalice};
    `}

    ${props => !props.hasBorder && css`
        margin-top: 10px;
    `}

    ${ButtonContainer} {
        margin: 4px 4px 4px 0px;
    }

    ${(props) => props.theme==="primary" && !props.isSearchify && css`
        margin: 0;
        max-width: ${props.searchInputValue ? "1100px" : "560px"};
        background-color: ${colors.white20percent};
        align-items: center;
        border: 1px solid transparent;
        transition: border .3s ease-in-out, box-shadow .3s ease-in-out;

        &:focus-within {
            box-shadow: 0 0 0 2px ${colors.violet1};
            border: 1px solid transparent;
        }

        ${props => props.loggedInAsUser && css`
            background-color: ${colors.violet1};
        `}

        ${props => props.isPrimary && css`
            border: 1px solid ${colors.violet7};
            max-width: none;
            background-color: ${colors.white20percent};

            &:focus-within {
                box-shadow: 0 0 0 2px ${colors.violet1};
                border: 1px solid ${colors.violet7};
            }
        `}

        ${props => props.aiSearchButtonIsVisible && css`
            ${ButtonContainer} {
                margin: 0;
            }
        `}
    `}
`

export const SearchTextarea = styled.textarea`
    ${rules.text.smallHeight}
    ${rules.weight.regular}
    border-radius: 3px;
    border: 0;
    color: ${colors.QuorumBlue};
    flex-grow: 1;
    height: 38px;
    letter-spacing: 0;
    outline: 0;
    // top, right, bottom, left
    padding: 10px 0px ${({ aiSearchButtonIsVisible }) => (!aiSearchButtonIsVisible) && `10px 14px`};
    ${props => props.isSearchFiltersTextarea && css`
        padding-left: 0;
        padding-right: 14px;
    `}
    resize: none;

    &::placeholder {
        color: ${colors.QuorumGrey3};
        font-style: italic;
    }

    ${(props) => props.theme === "primary" && !props.isSearchify && css`
        padding: 11px 16px 11px 8px;
        order: 2;
        background-color: transparent;
        font-size: .875rem;
        line-height: 1rem;
        border-radius: 0;
        color: ${colors.White};

        &::placeholder {
            color: ${colors.violet1};
            font-style: normal;
        }

        ${(props) => !props.isPrimary && css`
            &::placeholder {
                opacity: 0.5;
            }
        `}

        ${(props) => props.loggedInAsUser && css`
            color: ${colors.violet9};

            &::placeholder {
                color: ${colors.violet7};
            }

            ${(props) => !props.isPrimary && css`
                &::placeholder {
                    opacity: 0.5;
                }
            `}
        `}

        ${(props) => props.isPrimary && css`
            color: ${colors.gray7};

            &::placeholder {
                color: ${colors.gray5};
            }
        `}
    `}
`

export const SearchTextareaClear = styled.span`
    ${rules.SmallTextSmallHeightRegularWeight}
    color: ${colors.QuorumBlue};
    letter-spacing: 0;
    margin: 10px 8px 0px;

    &:hover {
        background-color: ${colors.AquaHaze};
        cursor: pointer;
    }

    ${(props) => props.theme === "primary" && !props.isSearchify && css`
        color: ${colors.White};
        margin: 0;
        margin-right: 8px;

        &:hover {
            background-color: transparent;
        }

        ${(props) => props.loggedInAsUser && css`
            color: ${colors.violet9};
        `}

        ${(props) => props.isPrimary && css`
            color: ${colors.gray7};
        `}

        ${(props) => props.aiSearchButtonIsVisible && css`
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0;
        `}
    `}
`

export const TooltipPositioner = styled.div`
    > :first-child {
        left: calc(50% - 100px); // half of the target's width - half of the tooltip width
        opacity: 0%;
        top: 100%;
        transition-duration: ${rules.TransitionShort};
        visibility: hidden;
    }
`

export const TooltipContainer = styled.div`
    display: ${({isHidden}) => isHidden ? "none" : "flex"};
    position: relative;

    &:not(&:hover){
        ${TooltipPositioner} {
            > :first-child {
                opacity: 0%;
                transition-duration: ${rules.TransitionShort};
                visibility: hidden;
                width: 200px; // match the Tooltip's max-width
            }
        }
    }

    &:hover {
        ${TooltipPositioner} {
            & > :first-child {
                opacity: 100%;
                transition-duration: ${rules.TransitionShort};
                visibility: visible;
                width: 200px;
            }
        }
    }
`

export const SearchButton = styled.button`
    order: 1;
    border: 0;
    padding: 8px;
    border-radius: 4px;
    margin-left: 4px;
    background-color: transparent;
`

export const BetaBadge = styled.span`
    width: auto;
    height: auto;
    padding: 2px 5px 2px 4px;
    border-radius: 2px;
    border: 1px solid currentColor;
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 1px;
    color: white;
    background-color: transparent;
    text-transform: uppercase;
    margin: 0 12px 0 16px;

    ${(props) => props.isPrimary && css`
        color: ${colors.violet5};
    `}
`

import PropTypes from "prop-types"
import React from "react"

import * as S from "Components/Molecules/Button/style"
import * as rules from "Components/rules"

import Icon from "Components/Atoms/Icon"

export const InternalButton = ({
    dataCy,
    disabled,
    id,
    isForm,
    isGiant,
    isGridButton,
    labelFor,
    isTiny,
    fontStyle,
    forceActive,
    hoverable,
    type,
    text,
    textBreakpoint,
    textOverflow,
    href,
    leftIcon,
    leftIconBreakpoint,
    leftIconCustom,
    leftIconFamily,
    leftIconFixedWidth,
    leftIconFlip,
    leftIconHorizontalOffset,
    leftIconSize,
    leftIconSpin,
    leftIconStyle,
    leftIconVerticalOffset,
    leftPadding,
    leftMargin,
    loading,
    maxTextWidth,
    newTab,
    onClick,
    rightIcon,
    rightIconFamily,
    rightIconFixedWidth,
    rightIconHorizontalOffset,
    rightIconSize,
    rightIconSpin,
    rightIconStyle,
    rightIconVerticalOffset,
    rightPadding,
    rightMargin,
    style,
    title,
}) => (
    <S.ButtonContainer
        data-auto-cy="MoleculeButton"
        id={id}
        isForm={isForm}
        isGridButton={isGridButton}
        leftIconBreakpoint={leftIconBreakpoint}
        rightIcon={rightIcon}
        textBreakpoint={textBreakpoint}
    >
        <S.Button
            id="ds-button"
            data-cy={dataCy}
            disabled={loading || disabled}
            forceActive={forceActive}
            hoverable={hoverable}
            href={href}
            isForm={isForm}
            isGiant={isGiant}
            isTiny={isTiny}
            leftPadding={leftPadding}
            leftMargin={leftMargin}
            onClick={onClick}
            rel={rules.aRel}
            rightPadding={rightPadding}
            rightMargin={rightMargin}
            style={style}
            target={newTab && rules.aTarget}
            title={title}
            type={type}

            // Polymorphism
            as={href ? "a" : labelFor ? "label" : "button"}
            for={labelFor}
        >
            {
                (leftIcon || leftIconCustom || loading) &&
                    <S.Icon
                        horizontalOffset={leftIconHorizontalOffset}
                        iconSize={leftIconSize}
                        id="ds-button-lefticon"
                        leftIconBreakpoint={leftIconBreakpoint}
                        textBreakpoint={textBreakpoint}
                        verticalOffset={leftIconVerticalOffset}
                    >
                        {
                            leftIconCustom
                                ? leftIconCustom
                                : (
                                    <Icon
                                        fixedWidth={leftIconFixedWidth}
                                        flip={leftIconFlip}
                                        icon={loading ? "spinner" : leftIcon}
                                        iconFamily={loading ? "fas" : leftIconFamily}
                                        size={leftIconSize}
                                        spin={loading || leftIconSpin}
                                        style={leftIconStyle}
                                    />
                                )
                        }
                    </S.Icon>
            }
            {
                text &&
                    <S.Text
                        fontStyle={fontStyle}
                        id="ds-button-text"
                        leftIcon={
                            loading || // there's always an icon when loading
                            Boolean(leftIcon) ||
                            Boolean(leftIconCustom)
                        }
                        maxTextWidth={maxTextWidth}
                        rightIcon={Boolean(rightIcon)}
                        textBreakpoint={textBreakpoint}
                        textOverflow={textOverflow}
                        type={type}
                    >
                        {text}
                    </S.Text>
            }
            {
                rightIcon &&
                    <S.Icon
                        horizontalOffset={rightIconHorizontalOffset}
                        iconSize={rightIconSize}
                        id="ds-button-righticon"
                        type={type}
                        verticalOffset={rightIconVerticalOffset}
                    >
                        <Icon
                            fixedWidth={rightIconFixedWidth}
                            icon={rightIcon}
                            iconFamily={rightIconFamily}
                            size={rightIconSize}
                            spin={rightIconSpin}
                            style={rightIconStyle}
                        />
                    </S.Icon>
            }
        </S.Button>
    </S.ButtonContainer>
)

InternalButton.defaultProps = {
    type: "primary",
    leftIconFixedWidth: true,
    leftIconSize: "md",
    leftIconHorizontalOffset: "0px",
    leftIconVerticalOffset: "0px",
    rightIconFixedWidth: true,
    rightIconSize: rules.IconFontSize,
    rightIconHorizontalOffset: "0px",
    rightIconVerticalOffset: "0px",
}

InternalButton.propTypes = {
    dataCy: PropTypes.string,
    text: PropTypes.string,
    textOverflow: PropTypes.oneOf(["clip", "ellipsis"]),
    textBreakpoint: PropTypes.number,
    maxTextWidth: PropTypes.string,
    type: PropTypes.oneOf([
        "primary",
        "secondary",
        "secondary-outlined",
        "tertiary",
        "borderless",
        "action-idle",
        "action-idle-dark",
        "action-idle-compressed",
        "action-idle-compressed-dark",
        "action-active",
        "resource-table-dropdown",
        "large-borderless-blue",
        "ai-suggestion",
    ]),
    leftIcon: PropTypes.string,
    leftIconCustom: PropTypes.element,
    leftIconFamily: PropTypes.string,
    loading: PropTypes.bool,
    rightIcon: PropTypes.string,
    rightIconFamily: PropTypes.string,
    leftMargin: PropTypes.string,
    onClick: PropTypes.func,
    forceActive: PropTypes.bool,
    isForm: PropTypes.bool,
    labelFor: PropTypes.string,
    leftIconBreakpoint: PropTypes.number,
    leftIconFixedWidth: PropTypes.bool,
    leftIconFlip: PropTypes.string,
    leftIconSize: PropTypes.string,
    leftIconSpin: PropTypes.bool,
    leftIconStyle: PropTypes.object,
    leftIconHorizontalOffset: PropTypes.string,
    leftIconVerticalOffset: PropTypes.string,
    leftPadding: PropTypes.string,
    rightIconFixedWidth: PropTypes.bool,
    rightIconSize: PropTypes.string,
    rightIconSpin: PropTypes.bool,
    rightIconStyle: PropTypes.object,
    rightIconHorizontalOffset: PropTypes.string,
    rightIconVerticalOffset: PropTypes.string,
    rightPadding: PropTypes.string,
    isGiant: PropTypes.bool,
    isTiny: PropTypes.bool,
    style: PropTypes.object,
    title: PropTypes.string,
}

const Button = ({
    dataCy,
    disabled,
    fontStyle,
    forceActive,
    hoverable,
    href,
    id,
    isForm,
    isGiant,
    isTiny,
    labelFor,
    leftIcon,
    leftIconBreakpoint,
    leftIconCustom,
    leftIconFamily,
    leftIconFixedWidth,
    leftIconFlip,
    leftIconSpin,
    leftIconStyle,
    leftMargin,
    loading,
    maxTextWidth,
    newTab,
    onClick,
    rightIcon,
    rightIconFamily,
    rightIconSpin,
    text,
    textBreakpoint,
    textOverflow,
    type,
    leftIconSize,
    rightIconSize,
    rightIconStyle,
    rightMargin,
    style,
    title,
}) => (
    <InternalButton
        // pass along externally accessible props
        dataCy={dataCy}
        disabled={disabled}
        fontStyle={fontStyle}
        forceActive={forceActive}
        hoverable={hoverable}
        href={href}
        id={id}
        isForm={isForm}
        isGiant={isGiant}
        isTiny={isTiny}
        labelFor={labelFor}
        leftIcon={leftIcon}
        leftIconBreakpoint={leftIconBreakpoint}
        leftIconCustom={leftIconCustom}
        leftIconFamily={leftIconFamily}
        leftIconFixedWidth={leftIconFixedWidth}
        leftIconFlip={leftIconFlip}
        leftIconSize={leftIconSize}
        leftIconSpin={leftIconSpin}
        leftIconStyle={leftIconStyle}
        leftMargin={leftMargin}
        loading={loading}
        newTab={newTab}
        maxTextWidth={maxTextWidth}
        onClick={onClick}
        rightIcon={rightIcon}
        rightIconFamily={rightIconFamily}
        rightIconSize={rightIconSize}
        rightIconSpin={rightIconSpin}
        rightIconStyle={rightIconStyle}
        rightMargin={rightMargin}
        style={style}
        text={text}
        textBreakpoint={textBreakpoint}
        textOverflow={textOverflow}
        title={title}
        type={type}
        // InternalButton props specific to Design System use are filled in as default props
    />

)

Button.defaultProps = {
    disabled: false,
    fontStyle: "normal",
    hoverable: true,
    type: "primary",
    rightMargin: "0px",
    leftIconFixedWidth: true,
}

Button.propTypes = {
    dataCy: PropTypes.string,
    disabled: PropTypes.bool,
    fontStyle: PropTypes.oneOf(["normal", "italic"]),
    forceActive: PropTypes.bool,
    id: PropTypes.string,
    isForm: PropTypes.bool,
    isGiant: PropTypes.bool,
    isTiny: PropTypes.bool,
    labelFor: PropTypes.string,
    leftIcon: PropTypes.string,
    leftIconBreakpoint: PropTypes.number,
    leftIconCustom: PropTypes.element,
    leftIconFamily: PropTypes.string,
    leftIconFixedWidth: PropTypes.bool,
    leftIconSpin: PropTypes.bool,
    leftIconStyle: PropTypes.object,
    leftMargin: PropTypes.string,
    loading: PropTypes.bool,
    maxTextWidth: PropTypes.string,
    onClick: PropTypes.func,
    rightIcon: PropTypes.string,
    rightIconFamily: PropTypes.string,
    rightIconSpin: PropTypes.bool,
    rightIconStyle: PropTypes.object,
    text: PropTypes.string,
    textBreakpoint: PropTypes.number,
    textOverflow: PropTypes.oneOf(["clip", "ellipsis"]),
    title: PropTypes.string,
    type: PropTypes.oneOf([
        "primary",
        "secondary",
        "secondary-outlined",
        "tertiary",
        "borderless",
        "large-borderless-blue",
        "resource-table-dropdown",
    ]),
    style: PropTypes.object,
}

export default Button

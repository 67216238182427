import React from "react"
import PropTypes from "prop-types"
import { components } from "react-select"

import { Icon } from "quorumdesign"

import { SEARCHIFY_PROMPT_TEXT } from "shared/imports/sharedConstants"

import * as S from "app/static/frontend/selects/components/Select/ReplacementComponents/style"
import * as colors from "app/static/frontend/design-constants"
/** *************************************************************************************
 * In this file, we declare new components to replace the default ones in React-Select. *
 ************************************************************************************** */

/**
 * Override the DropdownIndicator to allow replacing the chevron with a magnifying glass
 * that opens the Searchify
 */
export const DropdownIndicator = (props) => {
    // For certain entry points in our application (like the Unsubscribe
    // Page and other public facing bundles), we need to use our Selects,
    // but must explicitly exclude Search and Searchify from the application's
    // dependency graph. Search includes in its dependency graph whole sets of
    // functionality that will not work without globally scoped variables and
    // resources like Userdata or D3.
    const { SearchifyWrapper } = window

    if (props.advancedSearchifyConfig) {
        return (
            <S.DropdownIndicatorWrapper>
                <SearchifyWrapper tooltipLabel={SEARCHIFY_PROMPT_TEXT} searchifyConfigs={props.advancedSearchifyConfig}>
                    <i className="fa fa-search-plus launch-new-select-searchify" />
                </SearchifyWrapper>
            </S.DropdownIndicatorWrapper>
        )
    }
    return !props.selectProps.shouldSuppressDropdownIndicator ? <components.DropdownIndicator {...props} /> : null
}

DropdownIndicator.propTypes = {
    advancedSearchifyConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    selectProps: PropTypes.object,
}

/**
 * Replace the MultiValueRemove component to use a larger X icon ¯\_(ツ)_/¯
 */
export const MultiValueRemove = (props) => (
    <components.MultiValueRemove {...props}>
        <Icon icon="times" iconFamily="far" />
    </components.MultiValueRemove>
)

/**
 * Replace the Option component to render a checkbox next to the label when isMulti is true
 */
export const Option = ({ children, innerProps, innerRef, isFocused, isSelected, selectProps, ...rest }) => (
    <S.OptionWrapper ref={innerRef} isFocused={isFocused} isSelected={isSelected} {...innerProps} {...rest}>
        {selectProps.isMulti && (
            <Icon icon={isSelected ? "check-square" : "square"} iconFamily={isSelected ? "fad" : "far"} />
        )}
        {children}
    </S.OptionWrapper>
)

Option.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    innerRef: PropTypes.func,
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
    selectProps: PropTypes.object,
}

import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const SectionHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 18px;
`

export const HeaderText = styled.div`
    color: ${colors.QuorumBlue};
    ${rules.GiantTextMediumWeight}
    margin-top: 5px;
    margin-right: 24px;
`

export const HTMLTable = styled.table`

    margin-top: 0px;
    width: 100%;
    padding: 5px;
    font-size: 16px;

    tr {
        // border-top: 1px solid #cccccc;
        border-bottom: 1px dashed ${colors.QuorumGrey3};
        background-color: white;
        margin: 0;
        padding: 0;
    }

    tr:nth-child(2n) {
        // background-color: #f8f8f8;
    }

    tr th {
        font-weight: bold;
        border-bottom: 1px solid ${colors.QuorumBlue};
        text-align: left;
        margin: 0;
        padding: 10px 20px;
        padding-left: 0px;
        vertical-align: bottom;
    }

    tr td {
        text-align: left;
        margin: 0;
        padding: 10px 20px;
        padding-left: 0px;
    }

    // First Column
    tr th:first-child, tr td:first-child {
        margin-top: 0;
        width: 20em;
        min-width: 15em;
        max-width: 15em;
    }

    tr th:last-child, tr td:last-child {
        margin-bottom: 0;
    }

    // Last Row
    tr:last-child {
        border-bottom: none;
        font-weight: ${({boldLastRow}) => boldLastRow ? "bold" : "normal"};
    }
`

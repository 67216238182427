import React from "react"
import PropTypes from "prop-types"
import i18n from "i18n"

import StyledColumnWidget, { StyledWidgetHeader } from "QuorumGrassroots/widgets/styled-components/StyledWidget"
import { StyledCampaignNoneText } from "QuorumGrassroots/widgets/CampaignList/components/style"
import EventInline from "QuorumGrassroots/widgets/EventList/components/EventInline"

const EventList = (props) => {
    if (props.loading) {
        return <EventInline key="loading-event" loading />
    }

    return (
        <div className="event-list">
            {props.title && (
                <StyledColumnWidget className="event-header-widget">
                    <StyledWidgetHeader removeBottomBorder>{props.title}</StyledWidgetHeader>
                </StyledColumnWidget>
            )}
            {props.eventList.length ? (
                props.eventList.map((event, index, arr) => (
                    <EventInline key={event.id} event={event} isFirst={index === 0} isLast={index === arr.length - 1} />
                ))
            ) : (
                <StyledColumnWidget>
                    <StyledCampaignNoneText>
                        <span dangerouslySetInnerHTML={{ __html: props.eventNoneText }} />
                    </StyledCampaignNoneText>
                </StyledColumnWidget>
            )}
        </div>
    )
}

EventList.defaultProps = {
    eventList: [],
    loading: false,
}

EventList.propTypes = {
    eventList: PropTypes.array,
    eventNoneText: PropTypes.string,
    loading: PropTypes.bool,
    title: PropTypes.string.isRequired,
}

export default EventList

import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/TemplateCard/style"

import Icon from "Components/Atoms/Icon"

const TemplateCard = ({ children, icon, iconFamily, title, selected, iconSize, selectTemplate, size, dataCy }) => {
    return <S.Card
        data-auto-cy="MoleculeTemplateCard"
        data-cy={dataCy}
        size={size}
        onClick={() => selectTemplate()}
        selected={selected}
    >
        { icon &&
            <S.Icon>
                <Icon icon={icon} iconFamily={iconFamily} size={iconSize}/>
            </S.Icon>
        }
        { title &&
            <S.Title size={size}>{title}</S.Title>
        }
        { children &&
            <S.Text size={size} icon={!!icon}>{children}</S.Text>
        }
    </S.Card>
}

TemplateCard.defaultProps = {
    size: 'small',
    iconSize: '2x',
    selected: false,
}

TemplateCard.propTypes = {
    children: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
    iconSize: PropTypes.string,
    iconFamily: PropTypes.string,
    selected: PropTypes.bool,
    selectTemplate: PropTypes.func.isRequired,
    size: PropTypes.oneOf(['small', 'medium']),
}

export default TemplateCard

import styled, { css } from 'styled-components'
import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const Icon = styled.div`
    grid-area: icon;
    width: 60px;
    height: 60px;
    margin: 17px;
    color: ${colors.violet5};
    border: ${rules.Line} ${colors.QuorumGrey2};
    border-radius: ${rules.BorderRadius};
    position: relative;
    background-color: ${colors.White};
    & > svg {
        left: 50%;
        top: 50%;
        opacity: 0.8;
        position: absolute;
        transform: translate(-50%, -50%);
    }
`

export const Card = styled.span`
    height: 100%;

    border: ${rules.Line} ${colors.SilverChalice};
    border-radius: ${rules.BorderRadius};
    box-shadow: ${rules.CardShadow};

    align-items: center;

    padding: 1px;
    min-height: 100px;
    background-color: ${colors.White};
    overflow-y: hidden;

    &:hover {
        cursor: pointer;
        background-color: ${colors.violet1};
        ${Icon} {
            color: ${colors.violet5};
            border: ${rules.Line} ${colors.violet1};
        }
    }

    ${
        ({ selected }) => selected && css`
            padding: 0;
            border: ${rules.ThickerLine} ${colors.QuorumBlue};
            ${Icon} {
                color: ${colors.violet5};
                background-color: ${colors.violet2};
                border: ${rules.Line} ${colors.violet2};
            }
        `
    }

    display: grid;

    ${({ size }) => (
        size === 'medium'
        ? (

            css`
            grid-template-columns: 94px auto;
            grid-template-rows: 94px 50%;
            grid-template-areas:
                "icon title"
                "text text";
            min-height: 162px;
            `
        ) : (
            css`
            grid-template-columns: 94px auto;
            grid-template-rows: 45% 55%;
            grid-template-areas:
                "icon title"
                "icon text";
            min-height: 100px;
            `
        )
    )}
`


export const Text = styled.div`
    grid-area: text;
    ${rules.SmallTextLightWeight}
    padding: ${({ size }) => size === 'medium' ? '0 17px 17px 17px' : '0 17px 7px 0'};
    height: 100%;
    overflow-y: auto;
`

export const Title = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    grid-area: title;
    ${rules.LargeText}
    ${rules.RegularWeight}
    padding-top: ${({ size }) => size === 'small' ? '7px' : '0'};
`

export const CardBody = styled.div`
`

// Overriding the Item component from https://github.com/xiaolin/react-image-gallery/blob/master/src/Item.js
import React from "react"
import PropTypes from "prop-types"

import {
    CopyWrapper,
    Description,
    Image,
    Subtitle,
    Title,
    Wrapper,
} from "QuorumGrassroots/widgets/ImageVideoCarousel/components/style"

const CarouselItem = ({
    title,
    subtitle,
    description,
    fullscreen, // fullscreen version of img
    isFullscreen,
    onImageError,
    original,
    originalAlt,
    originalHeight,
    originalWidth,
    originalTitle,
    sizes,
    srcSet,
    loading,
}) => {
    const itemSrc = isFullscreen ? fullscreen || original : original

    return (
        <Wrapper>
            <Image
                src={itemSrc}
                alt={originalAlt}
                srcSet={srcSet}
                height={originalHeight}
                width={originalWidth}
                sizes={sizes}
                title={originalTitle}
                onError={onImageError}
                loading={loading}
            />
            <CopyWrapper>
                {title && <Title>{title}</Title>}
                {subtitle && <Subtitle>{subtitle}</Subtitle>}
                {description && <Description>{description}</Description>}
            </CopyWrapper>
        </Wrapper>
    )
}

CarouselItem.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    fullscreen: PropTypes.string, // fullscreen version of img
    isFullscreen: PropTypes.bool,
    onImageError: PropTypes.func.isRequired,
    original: PropTypes.string.isRequired,
    originalAlt: PropTypes.string,
    originalHeight: PropTypes.string,
    originalWidth: PropTypes.string,
    originalTitle: PropTypes.string,
    sizes: PropTypes.string,
    srcSet: PropTypes.string,
    loading: PropTypes.string,
}

CarouselItem.defaultProps = {
    description: "",
    fullscreen: "",
    isFullscreen: false,
    originalAlt: "",
    originalHeight: "",
    originalWidth: "",
    originalTitle: "",
    sizes: "",
    srcSet: "",
    loading: "eager",
}

export default CarouselItem

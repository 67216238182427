import React from "react"
import { Stack, TextInput, Textarea } from "@mantine/core"
import { withLock } from "QuorumGrassroots/styled-components/components/InputWithLock"
import { Campaign } from "@/types/djangio"
import { StyledSpacing } from "QuorumGrassroots/styled-components/components/StyledSpacing"
import { shouldShowOrganizationField } from "QuorumGrassroots/campaign-forms/helpers"

const { DraftOptions } = DjangIO.app.grassroots.campaign.types

const TextInputWithLock = withLock(TextInput)
const TextareaWithLock = withLock(Textarea)

interface Props {
    campaign: Campaign
    maMessages: Record<string, Record<number, MultiActionSingleMessage>>
    selectedGroupId: number
    setMaMessages: React.Dispatch<
        React.SetStateAction<{
            [k: string]: object
        }>
    >
    t: (text: string) => string
}

export const MultiActionMessageLayout = (props: Props) => {
    const messages = props.maMessages[props.campaign.campaign_type]
    const message = messages[props.selectedGroupId]
    const initialMessage = props.campaign.messages[props.selectedGroupId]
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        type: "subject" | "preBody" | "body" | "postBody" | "submitting_org_name",
    ) => {
        const subject = type === "subject" ? e.target.value : message.subject ?? ""
        const preBody = type === "preBody" ? e.target.value : message.preBody ?? ""
        const body = type === "body" ? e.target.value : message.body ?? ""
        const postBody = type === "postBody" ? e.target.value : message.postBody ?? ""
        const submitting_org_name = type === "submitting_org_name" ? e.target.value : message.submitting_org_name ?? ""
        props.setMaMessages((lastMessages) => {
            const group = lastMessages[props.campaign.campaign_type][props.selectedGroupId]
            const updatedGroup = {
                ...group,
                subject,
                preBody,
                body,
                postBody,
                ...(submitting_org_name && { submitting_org_name }),
            }
            return {
                ...lastMessages,
                [props.campaign.campaign_type]: {
                    ...lastMessages[props.campaign.campaign_type],
                    [props.selectedGroupId]: updatedGroup,
                },
            }
        })
        return
    }

    const draftOption = message.advocate_editing_permission ?? props.campaign.draft_requirements
    const noEdit = draftOption === DraftOptions.cannot_edit.value
    const SubjectInput = noEdit ? TextInputWithLock : TextInput
    const BodyInput = noEdit ? TextareaWithLock : Textarea

    return (
        <form>
            <Stack spacing="md">
                {initialMessage.subject && (
                    <SubjectInput
                        value={message.subject}
                        label="Subject"
                        onChange={(e) => handleChange(e, "subject")}
                    />
                )}
                {initialMessage.preBody && (
                    <TextInputWithLock
                        value={message.preBody}
                        label="Message"
                        onChange={(e) => handleChange(e, "preBody")}
                    />
                )}
                <BodyInput
                    value={message.body}
                    label={initialMessage.preBody ? "" : "Message"}
                    minRows={3}
                    autosize
                    onChange={(e) => handleChange(e, "body")}
                />
                {initialMessage.postBody && (
                    <TextInputWithLock value={message.postBody} onChange={(e) => handleChange(e, "postBody")} />
                )}
                {shouldShowOrganizationField(props.campaign) && (
                    <>
                        <StyledSpacing />
                        <TextInput
                            label={props.t("campaign.comment_on_regulation.organization")}
                            value={message.submitting_org_name}
                            onChange={(e) => handleChange(e, "submitting_org_name")}
                        ></TextInput>
                    </>
                )}
            </Stack>
        </form>
    )
}

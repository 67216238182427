import React from "react"
import PropTypes from "prop-types"
import { Col, Table } from "react-bootstrap"

import {
    StyledRegionMiniatureThumbnail,
    StyledRegionThumbnailMiniatureWrapper,
    StyledRegionMiniatureName,
    StyledTableHeader,
    StyledTableBody,
    StyledRegionInfo,
} from "QuorumGrassroots/widgets/RegionList/components/style"
import { Link } from "react-router-dom"

export function RegionListView({ regionList, regionPagePath }) {
    return (
        <Table>
            <StyledTableHeader>
                <Col md={6}>
                    <StyledRegionInfo>Region</StyledRegionInfo>
                </Col>
            </StyledTableHeader>
            {regionList.map((region) => (
                <Link to={`${regionPagePath}?state=${region.abbrev}`}>
                    <StyledRegionThumbnailMiniatureWrapper>
                        <StyledTableBody>
                            <Col md={1}>
                                <StyledRegionMiniatureThumbnail
                                    className="icon-wrapper state-icon"
                                    dangerouslySetInnerHTML={{
                                        __html: DjangIO.app.models.Region.by_value(region.region).icon_element
                                            ? DjangIO.app.models.Region.by_value(region.region).icon_element
                                            : DjangIO.app.models.Region.by_value(1).icon_element,
                                    }}
                                />
                            </Col>
                            <Col md={1}>
                                <StyledRegionMiniatureName>{region.name}</StyledRegionMiniatureName>
                            </Col>
                        </StyledTableBody>
                    </StyledRegionThumbnailMiniatureWrapper>
                </Link>
            ))}
        </Table>
    )
}

RegionListView.propTypes = {
    regionList: PropTypes.array,
    regionPagePath: PropTypes.string.isRequired,
}

export default RegionListView

import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const SummarySection = styled.div`
    display: flex;
    flex-direction: column;
`

export const AccessoryArea = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: auto;
    & > * {
        margin-left: 18px;
    }
`

export const Expand = styled.span`
    flex-shrink: 0;
    align-self: center;
    ${rules.SmallTextRegularWeight}
    color: ${colors.MidPurple};
    user-select: none;
    &:hover {
        cursor: pointer;
        background-color: ${colors.AquaHaze};
    }
`

export const TextArea = styled.div`
    margin-bottom: 15px;
    color: ${colors.Black};
    ${rules.MediumTextRegularWeightSpaced}

    ${({shouldTruncate}) => shouldTruncate && rules.multiLineTruncatedText(rules.CollapsedTextSectionLines)}

    white-space: break-spaces;
`

export const BoldSpan = styled.span`
    color: ${colors.QuorumBlue};
    ${rules.MediumWeight}
`

import React from "react"
import PropTypes from "prop-types"

import { usePrevious } from "utils/hooks"
import { parseInputEvent } from "Components/Molecules/ContentSwitcher/helpers"
import { useHover } from "utils/hooks"

import * as S from "Components/Molecules/ContentSwitcher/style"

const ContentSwitcher = ({
    dataCy,
    onChange,
    options,
    value,
}) => {
    const [ref, isHovered] = useHover()

    const selectedIndex = options.findIndex(option => option.value === value)
    const previousIndex = usePrevious(selectedIndex)

    const handleChange = (e) => {
        const selectedValue = parseInputEvent(typeof value, e)
        const selectedOption = options.find(option => option.value === selectedValue)
        onChange(selectedValue, selectedOption)
    }

    return (
        <S.ContentSwitcher
            data-auto-cy="MoleculeContentSwitcher"
            data-cy={dataCy}
            onChange={handleChange}
            role="radiogroup"
            tabIndex={0}
        >
            {/* Highlights the active value */}
            <S.Highlighter
                options={options}
                index={selectedIndex}
                willMoveRight={selectedIndex >= previousIndex}
                aria-hidden="true"
                isHovered={isHovered}
            />

            {options.map(option => (
                <S.OptionLabel
                    data-cy={`content-switcher-${option.label}`}
                    key={option.value}
                    options={options}
                    index={selectedIndex}
                    isActive={option.value === value}
                    tabIndex={-1}
                    ref={option.value === value ? ref : null}
                >
                    <S.Option
                        checked={option.value === value}
                        value={option.value}
                        tabIndex={0}
                    />
                    <span>{option.label}</span>
                </S.OptionLabel>
            ))}
        </S.ContentSwitcher>
    )
}

ContentSwitcher.defaultProps = {
    options: [],
    value: false,
}

ContentSwitcher.propTypes = {
    dataCy: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    })).isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
}

export default ContentSwitcher

import styled from 'styled-components'

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const SplashScreen = styled.div`
    height: 695px;
    padding: 96px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-height: ${rules.Breakpoints.medium}) {
        height: 626px;
        padding: 85px 0px;
    }
    @media (max-height: ${rules.Breakpoints.small}) {
        height: 522px;
        padding: 66px 0px;
    }
`

export const Circle = styled.div`
    opacity: 0.04;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    fill: ${colors.MidPurple};
`

export const Title = styled.h1`
    ${rules.GiantTitle}
    color: ${colors.gray9};
    text-align: center;
    margin-bottom: 49px;
    text-transform: capitalize;
    width: 55%;

    @media (max-height: ${rules.Breakpoints.medium}) {
        ${rules.LargeTitle}
        margin-bottom: 46px;
    }
    @media (max-height: ${rules.Breakpoints.small}) {
        ${rules.MediumTitle}
        margin-bottom: 41px;
    }
`

export const Text = styled.p`
    ${rules.LargeTextLightWeight}
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    margin-bottom: 35px;
    width: 55%;
    color: ${colors.gray7};

    @media (max-height: ${rules.Breakpoints.medium}) {
        ${rules.MediumTextLightWeight}
        margin-bottom: 35px;
    }
    @media (max-height: ${rules.Breakpoints.small}) {
        ${rules.SmallTextLightWeight}
        margin-bottom: 30px;
    }
`

export const Cards = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 18px;
    width: 75%;
    margin-bottom: 60px;

    @media (max-height: ${rules.Breakpoints.medium}) {
        ${rules.MediumTextLightWeight}
        margin-bottom: 50px;
    }
    @media (max-height: ${rules.Breakpoints.small}) {
        grid-gap: 14px;
        margin-bottom: 45px;
    }
`

export const Buttons = styled.div`
    display: grid;
    grid-template-columns: 50vw 50vw;
    grid-gap: 14px;
    width: 100%;
    justify-content: center;
`

export const PrimaryButton = styled.div`
    justify-self: start;
    @media (max-height: ${rules.Breakpoints.small}) {
        & > Button {
            height: ${rules.ButtonHeight};
            font-size: ${rules.ButtonFontSize};
            line-height: ${rules.ButtonFontLineHeight};
            padding-left: 11px;
            padding-right: 11px;
        }
    }
`

export const SecondaryButton = styled.div`
    justify-self: end;
    @media (max-height: ${rules.Breakpoints.small}) {
        & > Button {
            height: ${rules.ButtonHeight};
            font-size: ${rules.ButtonFontSize};
            line-height: ${rules.ButtonFontLineHeight};
            padding-left: 11px;
            padding-right: 11px;
        }
    }
`

import styled from "styled-components"
import { Link } from "react-router-dom"
import { Col, Row } from "react-bootstrap"

import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { styleWithOrgDesignHelper } from "QuorumGrassroots/styled-components/helperFunctions"
import { hexCodetoRGBA } from "app/static/frontend/imports/desktopHelperFunctions"

export const StyledMemberGroupRow = styled(Row)`
    margin-bottom: 5px;
    padding-right: 15px;
    padding-left: 15px;
`

const StyledMemberLinkComponent = styled(Link)`
    &:hover {
        text-decoration: none;
    }
`
export const StyledMemberLink = styleWithOrgDesignHelper(StyledMemberLinkComponent)

const StyledRegionThumbnailWrapperComponent = styled(Col)`
    text-align: center;
    margin-bottom: 10px;
    margin-top: 20px;
    padding: 15px;
    background-color: white;
    border: 1px solid ${(props) => props.organizationDesign.primary_color};
    border-radius: 4px;

    &:hover {
        background-color: ${(props) => hexCodetoRGBA(props.organizationDesign.primary_color, 0.2)};
    }
`
export const StyledRegionThumbnailWrapper = styleWithOrgDesignHelper(StyledRegionThumbnailWrapperComponent)

export const StyledRegionThumbnail = styled.img`
    width: 100%;
    height: auto;
    max-height: 125px;
    max-width: 125px;

    border-radius: 50%;
    border: 3px solid "#b6c0da";
`

const StyledRegionThumbnailMiniatureWrapperComponent = styled(Col)`
    text-align: center;
    margin-bottom: 5;
    margin-top: 10px;
    padding: 2px;
    background-color: white;

    &:hover {
        background-color: ${(props) => hexCodetoRGBA(props.organizationDesign.primary_color, 0.2)};
    }
`

export const StyledRegionThumbnailMiniatureWrapper = styleWithOrgDesignHelper(
    StyledRegionThumbnailMiniatureWrapperComponent,
)

export const StyledRegionName = styleWithOrgDesign(styled.div`
    font-size: 20px;
    color: ${(props) => props.organizationDesign.primary_color};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 30px;
    float: left;
`)

export const StyledRegionMiniatureName = styleWithOrgDesign(styled.div`
    font-size: 20;
    color: ${(props) => props.organizationDesign.primary_color};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 10px;
    float: left;
`)

export const StyledMemberRoleType = styleWithOrgDesign(styled.div`
    font-size: 20px;
    color: ${(props) => props.organizationDesign.primary_color};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 5px;
`)

export const StyledRegionDescription = styleWithOrgDesign(styled.div``)

export const StyledTableHeader = styled(Row)`
    font-weight: 700;
    margin: 10px 0px;
    border-bottom: 1px solid #d9d9d9;
`

export const StyledTableBody = styled(Row)`
    border-bottom: 1px solid #d9d9d9;
    margin: 0px 0px;
`

export const StyledMemberNameWithThumb = styleWithOrgDesign(styled.div`
    font-size: 20px;
    color: ${(props) => props.organizationDesign.primary_color};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center
    position: relative
`)

export const StyledRegionInfo = styleWithOrgDesign(styled.div`
    font-size: 20px;
    color: ${(props) => props.organizationDesign.primary_color};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 50px;
`)

export const StyledRegionMiniatureThumbnail = styled.div`
    font-size: 30px;
    width: 50px;
    height: 50px;
    color: #4d2d5f;
    line-height: 36px;
    border: 3px solid #95a5a6;
    border-radius: 100%;
    margin-bottom: 5px;
    margin-top: -5px;
}
`

export const StyledRegionStateThumbnail = styled.div`
    font-size: 54px;
    width: 89px;
    height: 89px;
    color: #4d2d5f;
    line-height: 70px;
    border: 3px solid #95a5a6;
    border-radius: 100%;
`

import { createSelector, createStructuredSelector } from "reselect"
import * as loginRegistrationSelectors from "QuorumGrassroots/widgets/LoginRegistration/selectors"
import { selectUserId, selectOrganizationId, selectActionCenterSettings } from "QuorumGrassroots/framework/selectors"

export const selectContactUsWidgetThanksProps = createSelector(
    (_, props) => props.thanks,
    (thanks) => {
        return {
            text: thanks || "Thank you for contacting us",
        }
    },
)

export const contactUsWidgetConnection = createStructuredSelector({
    registrationPageIds: loginRegistrationSelectors.selectDefaultRegistrationPageIds,
    supporterId: selectUserId,
    organizationId: selectOrganizationId,
    actionCenter: selectActionCenterSettings,
})

/* eslint-disable react/jsx-fragments */
import React from "react"
import PropTypes from "prop-types"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import CarouselItem from "QuorumGrassroots/widgets/ImageVideoCarousel/components/CarouselItem"
import {
    StyledFullBleedWidget,
    StyledImageGallery,
    WidgetTitle,
} from "QuorumGrassroots/widgets/ImageVideoCarousel/components/style"

import { vimeoVideoIdRegex, youtubeVideoIdRegex } from "shared/imports/regex"

const { ImageVideoCarouselWidgetType } = DjangIO.app.grassroots.enums
class ImageVideoCarouselWidget extends React.Component {
    constructor(props) {
        super(props)
        // create a ref to interact with ImageGallery and call 'pause' method
        this.carousel = React.createRef()
    }

    targets = []
    height = `${this.props.originalHeight}px`

    pauseCarousel = () => {
        this.carousel.current && this.carousel.current.pause()
    }

    playCarousel = (idx) => {
        if (idx != null) {
            this.refreshIframes(idx)
        }
        if (this.props.autoPlay) {
            this.carousel.current && this.carousel.current.play()
        }
    }

    refreshIframes = (current) => {
        for (let idx = 0; idx < this.targets.length; idx++) {
            if (idx === current) {
                continue
            }
            const renderedIframe = document.getElementById(`carousel-video-${idx}`)
            if (renderedIframe?.src) {
                renderedIframe.src = renderedIframe.src
            }
        }
    }

    renderYoutubeVideo = (videoId, idx) => {
        const id = `carousel-video-${idx}`
        const videoDiv = <div id={id} />
        this.targets[idx] = id

        const onStateChange = (event) => {
            // Listen on Youtube player events. If video has started playing, pause autoplay.
            // If video has ended, resume autoplay
            if (event.data === window.YT.PlayerState.PLAYING) {
                this.pauseCarousel()
            } else if (event.data === window.YT.PlayerState.ENDED) {
                this.playCarousel(null)
            }
        }

        if (window.YT) {
            // eslint-disable-next-line no-unused-vars
            const player = new window.YT.Player(id, {
                height: this.height,
                width: this.props.originalWidth,
                videoId,
                playerVars: {
                    fs: 1, // allow fullscreen
                    rel: 0, // remove related videos
                },
                events: { onStateChange },
            })
        }

        return videoDiv
    }

    renderVimeoVideo = (vimeoEmbedUrl, idx) => {
        const id = `carousel-video-${idx}`

        return (
            <iframe
                title={id}
                id={id}
                src={vimeoEmbedUrl}
                width={this.props.originalWidth}
                height={this.height}
                frameBorder="0"
                allow="fullscreen"
                allowFullScreen
            />
        )
    }

    parseItems = () =>
        this.props.carouselItems.map((item, idx) => {
            if (item.carouselItemType === ImageVideoCarouselWidgetType.image.value) {
                return {
                    original: item.imageUrl,
                    originalHeight: this.height,
                    originalWidth: this.props.originalWidth,
                    originalTitle: item.title,
                    description: item.description,
                    title: item.title,
                    subtitle: item.subtitle,
                }
            }

            if (item.carouselItemType === ImageVideoCarouselWidgetType.video.value) {
                const youtubeUrlMatches = youtubeVideoIdRegex.exec(item.videoUrl)
                const youtubeVideoId = youtubeUrlMatches ? youtubeUrlMatches[1] : ""

                const vimeoUrlMatches = vimeoVideoIdRegex.exec(item.videoUrl)
                const vimeoVideoId = vimeoUrlMatches ? vimeoUrlMatches[4] : ""

                if (youtubeVideoId) {
                    return {
                        embedUrl: `https://www.youtube.com/embed/${youtubeVideoId}`,
                        renderItem: () => this.renderYoutubeVideo(youtubeVideoId, idx),
                    }
                }

                const vimeoEmbedUrl = `https://player.vimeo.com/video/${vimeoVideoId}`
                return {
                    embedUrl: vimeoEmbedUrl,
                    renderItem: () => this.renderVimeoVideo(vimeoEmbedUrl, idx),
                }
            }

            return null
        })

    render() {
        const hasExtraContent = this.props.carouselItems.some((item) => item.title || item.subtitle || item.description)

        return (
            <WidgetStyleWrapper
                className="image-video-carousel-widget"
                md={this.props.colWidth}
                title={!hasExtraContent && this.props.title}
                useWidgetStyling={!hasExtraContent && this.props.useWidgetStyling}
            >
                {hasExtraContent ? (
                    <StyledFullBleedWidget
                        isFirst={this.props.isFirst}
                        isLast={this.props.isLast}
                        nextWidget={this.props.nextWidget}
                    >
                        <WidgetTitle>{this.props.title}</WidgetTitle>
                        <StyledImageGallery
                            autoPlay={this.props.autoPlay}
                            onSlide={this.playCarousel}
                            ref={this.carousel}
                            showBullets={this.props.showBullets}
                            showFullscreenButton={this.props.showFullscreenButton}
                            showIndex={this.props.showIndex}
                            showPlayButton={this.props.showPlayButton}
                            showThumbnails={this.props.showThumbnails}
                            slideInterval={parseInt(this.props.slideInterval)}
                            thumbnailPosition={this.props.thumbnailPosition}
                            items={this.parseItems()}
                            renderItem={CarouselItem}
                        />
                    </StyledFullBleedWidget>
                ) : (
                    <ImageGallery
                        autoPlay={this.props.autoPlay}
                        onSlide={this.playCarousel}
                        ref={this.carousel}
                        showBullets={this.props.showBullets}
                        showFullscreenButton={this.props.showFullscreenButton}
                        showIndex={this.props.showIndex}
                        showPlayButton={this.props.showPlayButton}
                        showThumbnails={this.props.showThumbnails}
                        slideInterval={parseInt(this.props.slideInterval)}
                        thumbnailPosition={this.props.thumbnailPosition}
                        items={this.parseItems()}
                    />
                )}
            </WidgetStyleWrapper>
        )
    }
}

ImageVideoCarouselWidget.propTypes = {
    autoPlay: PropTypes.bool,
    carouselItems: PropTypes.arrayOf(PropTypes.object),
    colWidth: PropTypes.number,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    nextWidget: PropTypes.number,
    originalHeight: PropTypes.string,
    originalWidth: PropTypes.string,
    showBullets: PropTypes.bool,
    showFullscreenButton: PropTypes.bool,
    showIndex: PropTypes.bool,
    showPlayButton: PropTypes.bool,
    showThumbnails: PropTypes.bool,
    slideInterval: PropTypes.number,
    thumbnailPosition: PropTypes.string,
    title: PropTypes.string,
    useWidgetStyling: PropTypes.bool,
}

ImageVideoCarouselWidget.defaultProps = {
    autoPlay: false,
    carouselItems: [],
    originalHeight: "500",
    originalWidth: "100%",
    showBullets: false,
    showFullscreenButton: false,
    showIndex: false,
    showPlayButton: false,
    showThumbnails: false,
    slideInterval: 3000, // 3000 milliseconds or 3 seconds per slide before autoplaying
    thumbnailPosition: "bottom",
    useWidgetStyling: true,
}

export default ImageVideoCarouselWidget

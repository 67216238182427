import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/SelectBooleanTriplet/style"

import SelectableItem from "Components/Molecules/SelectableItem/"

const renderSelectableItem = (
    onClick,
    text,
    activeValue,
    deserializedValue,
    searchFilterTerm,
) => {
    const isActive = (deserializedValue === activeValue)

    return (
        <SelectableItem
            isSquare={false}
            isActive={isActive}
            key={text}
            label={text}
            onClick={onClick}
            searchFilterTerm={searchFilterTerm}
        />
    )
}

const SelectBooleanTriplet = ({
    dataCy,
    centerText,
    deserializedValue,
    falseText,
    inverted,
    isHorizontal,
    isNullable,
    isTriplet,
    publishChange,
    searchFilterTerm,
    trueText,
}) => {
    let items
    if (!isTriplet) {
        // for booleans
        // renders vertically or horizontally based on isHorizontal prop
        const toggleBooleanButton = value => () => {
            if (deserializedValue === value && !isNullable) {
                // if deserializedValue matches the item's current value
                // and it's an item that CAN be clicked and deselected/deactivated
                // the item's appearance is updated
                return
            }

            const newValue = (deserializedValue === value)
                ? undefined
                : value

            return publishChange(newValue)
        }

        const trueButton = renderSelectableItem(
            toggleBooleanButton(true),
            trueText,
            true,
            deserializedValue,
            searchFilterTerm,
        )
        const falseButton = renderSelectableItem(
            toggleBooleanButton(false),
            falseText,
            false,
            deserializedValue,
            searchFilterTerm,
        )

        // ensures "true" values will be in the left-most or very-top position
        items = inverted
            ? [falseButton, trueButton]
            : [trueButton, falseButton]

    } else {
        // for triplets
        // vertical positioning-only, based on new DS mocks
        // renders in this order:
            // left/true/top
            // middle
            // right/false/bottom
        const left = "left"
        const center = "center"
        const right = "right"

        const toggleTripletButton = (value) => {
            if (deserializedValue === value && !isNullable) {
                return
            }

            const newValue = value === deserializedValue
                ? undefined
                : value

            publishChange(newValue)
        }

        const toggleLeft = () => toggleTripletButton(left)
        const toggleCenter = () => toggleTripletButton(center)
        const toggleRight = () => toggleTripletButton(right)

        const leftSelectableItem = renderSelectableItem(
            toggleLeft,
            trueText,
            left,
            deserializedValue,
            searchFilterTerm,
        )
        const centerSelectableItem = renderSelectableItem(
            toggleCenter,
            centerText,
            center,
            deserializedValue,
            searchFilterTerm,
        )
        const rightSelectableItem = renderSelectableItem(
            toggleRight,
            falseText,
            right,
            deserializedValue,
            searchFilterTerm,
        )

        items = [
            leftSelectableItem,
            centerSelectableItem,
            rightSelectableItem,
        ]
    }

    return (
        <S.SelectBooleanTriplet
            data-auto-cy="MoleculeSelectBooleanTriplet"
            data-cy={dataCy}
            isHorizontal={isHorizontal}
        >
            {items}
        </S.SelectBooleanTriplet>
    )
}

SelectBooleanTriplet.defaultProps = {
    centerText: "Equal",
    falseText: "False",
    isNullable: true,
    trueText: "True",
}

SelectBooleanTriplet.propTypes = {
    dataCy: PropTypes.string,

    // always the left-most or very top item text
    trueText: PropTypes.string,
    // middle item text for isTriplet
    centerText: PropTypes.string,
    // always the right-most or very bottom item text
    falseText: PropTypes.string,

    // boolean that determines which item should be selected/active
    deserializedValue: PropTypes.bool,
    // ensures "true" values will be in the left-most or very-top position
    inverted: PropTypes.bool,
    // for active items that can be clicked and deselected/deactivated
    isNullable: PropTypes.bool,
    publishChange: PropTypes.func,
    // the FilterGroup/Filter search term from app/static/frontend/search-new/components/FilterSet/index.js SearchFiltersTextareaFilter
    // passed from app/static/frontend/search-new/components/FilterGroup/index.js FilterComponent
    // through
    // app/static/frontend/search-new/components/Filter/BooleanFilter/BooleanFilter.js
    // app/static/frontend/search-new/components/Filter/TripletFilter/TripletFilter.js
    searchFilterTerm: PropTypes.string,
}

export default SelectBooleanTriplet

import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"

import { logout } from "QuorumGrassroots/framework/action-creators"
import { selectUserLoggedIn, selectIndexRoute } from "QuorumGrassroots/framework/selectors"

import LogoutPage from "QuorumGrassroots/framework/components/Foundations/LogoutPage"

const mapStateToProps = (state, props) => ({
    loggedIn: selectUserLoggedIn(state, props),
    indexRoute: selectIndexRoute(state, props),
})

export default withNamespaces()(connect(mapStateToProps, { logout })(LogoutPage))

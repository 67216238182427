import styled, { keyframes } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

const sparkles = keyframes`
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
`

export const AiSearchLoadingGif = styled.div`
    height: 2rem;
    width: 2rem;
    position: relative;

    svg {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        opacity: 0;
        animation: ${sparkles} .4s linear infinite;
    }

    svg:nth-child(2) {
        animation-delay: .2s;
    }

    svg:nth-child(3) {
        animation-delay: .4s;
    }

    svg:nth-child(4) {
        animation-delay: .6s;
        opacity: .4;
    }

    svg:nth-child(5) {
        animation-delay: .8s;
    }
`

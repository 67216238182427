import Immutable from "immutable"
import { getFormValues } from "redux-form/immutable"
import { createSelector, createStructuredSelector } from "reselect"
import { checkPermission, permissionFilter } from "shared/imports/permissionFunctions"
import {
    createCustomFieldNameDict,
    createConditionalCustomFields,
    createConditionalParentValues,
    createDefinedCustomFieldFormValues,
    createResourceUriDict,
    setDefaultValue,
} from "shared/customFields/constants/helperFunctions"
import { createToJsSelector } from "shared/imports/sharedHelperFunctions"
import isFeatureEnabled from "shared/featureflags/helperFunctions"

export const selectCustomFieldSlice = (state) => state.customFields

export const selectIsLoading = createSelector(selectCustomFieldSlice, (slice) => slice && Boolean(slice.get("loading")))

export const selectCurrentTab = createSelector(selectCustomFieldSlice, (state) => state.get("currentTab"))

export const selectCustomFieldForm = createSelector(selectCustomFieldSlice, (state) => state.get("form"))

export const selectCustomFieldFormValue = (formField) =>
    createSelector(selectCustomFieldForm, (state) => state.get(formField))

export const selectCustomFieldExtra = createSelector(selectCustomFieldFormValue("_extra"), (extra) =>
    extra ? extra.toJS() : {},
)

export const selectAllCustomFields = createSelector(
    selectCustomFieldSlice,
    (state) => state && state.get("customFields", []),
)

export const selectCustomFields = (tagOwner = DjangIO.app.models.QuorumDataType.supporter.value) =>
    createSelector(selectCustomFieldSlice, (state) => {
        if (state && state.get("customFields")) {
            return state
                .get("customFields")
                .filter((object) => object.tag_owner === tagOwner)
                .map((object) => setDefaultValue(object))
        } else {
            return []
        }
    })

// This selector grabs ALL the available custom fields
export const selectRequiredCustomFields = (tagOwner) =>
    createSelector(selectCustomFields(tagOwner), (customFields) =>
        customFields.filter((customField) => customField.is_required),
    )

export const selectCustomFieldNameDict = (tagOwner = DjangIO.app.models.QuorumDataType.supporter.value) =>
    createSelector(selectCustomFields(tagOwner), (fields) => (fields ? createCustomFieldNameDict(fields) : {}))

// This selector grabs all the custom fields we want to display on Profiles
export const selectCustomFieldsToDisplayOnProfile = (tagOwner) =>
    createSelector(
        selectCustomFields(tagOwner),
        (customFields) => customFields && customFields.filter((customField) => customField["display_on_profile"]),
    )

export const selectCustomFieldNameDictForProfile = (tagOwner = DjangIO.app.models.QuorumDataType.supporter.value) =>
    createSelector(selectCustomFieldsToDisplayOnProfile(tagOwner), (fields) =>
        fields ? createCustomFieldNameDict(fields) : {},
    )

export const selectCustomFieldTypes = () =>
    DjangIO.app.models.QuorumDataType.items()
        .filter((item) => item.custom_fields)
        .filter((item) => permissionFilter(item))
        .sort((itemA, itemB) => itemA.value - itemB.value)

export const selectDefaultTagOwner = () => {
    if (checkPermission(DjangIO.app.models.PermissionType.legislators)) {
        return DjangIO.app.models.QuorumDataType.person
    } else if (checkPermission(DjangIO.app.models.PermissionType.custom_contacts)) {
        return DjangIO.app.models.QuorumDataType.supporter
    } else {
        return DjangIO.app.models.QuorumDataType.note
    }
}

export const selectTagOwnerEnum = createSelector(selectCustomFieldFormValue("tag_owner"), (tagOwnerValue) =>
    DjangIO.app.models.QuorumDataType.by_value(tagOwnerValue),
)

export const selectHasOptions = createSelector(
    selectCustomFieldFormValue("tag_type"),
    (tagTypeValue) =>
        DjangIO.app.person.types.TagType.single_option_list.value === tagTypeValue ||
        DjangIO.app.person.types.TagType.multi_options_list.value === tagTypeValue,
)

export const selectPersonOrSupporterTagOwner = createSelector(selectTagOwnerEnum, (tagOwnerEnum) =>
    [
        DjangIO.app.models.QuorumDataType.person,
        DjangIO.app.models.QuorumDataType.staffer,
        DjangIO.app.models.QuorumDataType.supporter,
    ].includes(tagOwnerEnum),
)

export const selectSupporterTagOwner = createSelector(
    selectTagOwnerEnum,
    (tagOwnerEnum) => tagOwnerEnum === DjangIO.app.models.QuorumDataType.supporter,
)

export const selectEventTagOwner = createSelector(
    selectTagOwnerEnum,
    (tagOwnerEnum) => tagOwnerEnum === DjangIO.app.models.QuorumDataType.custom_event,
)

export const selectExternalNoteTagOwner = createSelector(selectTagOwnerEnum, (tagOwnerEnum) => {
    const { external_interactions, external_relationship } = DjangIO.app.models.PermissionType

    return (
        tagOwnerEnum === DjangIO.app.models.QuorumDataType.note &&
        (checkPermission(external_interactions) || checkPermission(external_relationship))
    )
})

export const selectPersonUnsubscribe = createSelector(
    selectTagOwnerEnum,
    selectCustomFieldFormValue("used_to_generate_outbox_email_lists"),
    (tagOwnerEnum, usedGenerateOutboxEmailLists) =>
        usedGenerateOutboxEmailLists &&
        [DjangIO.app.models.QuorumDataType.person, DjangIO.app.models.QuorumDataType.staffer].includes(tagOwnerEnum),
)

export const selectShowConditionalParentForm = isFeatureEnabled("ff_custom_fields_power_search")
    ? createSelector(selectCustomFieldFormValue("display_on_create_page"), (displayOnCreatePage) => displayOnCreatePage)
    : createSelector(
          selectEventTagOwner,
          selectCustomFieldFormValue("display_on_create_page"),
          (isCustomEventTagOwner, displayOnCreatePage) => !isCustomEventTagOwner && displayOnCreatePage,
      )

export const selectShowDataSetFormSection = createSelector(
    selectCustomFieldFormValue("tag_type"),
    (tagTypeValue) => tagTypeValue === DjangIO.app.person.types.TagType.power_search.value,
)

export const selectShowExternalNameDescriptionSection = createSelector(
    // if the Org has qp_external_interactions OR qp_external_relationship
    selectExternalNoteTagOwner,
    selectSupporterTagOwner,
    selectEventTagOwner,
    selectPersonUnsubscribe,
    (hasExternalNotePermissions, isSupporter, isEvent, isPersonUnsubscribe) =>
        hasExternalNotePermissions || isSupporter || isEvent || isPersonUnsubscribe,
)

export const selectCustomFieldsConnection = (tagOwner) =>
    createStructuredSelector({
        customFields: selectCustomFields(tagOwner),
        requiredCustomFields: selectRequiredCustomFields(tagOwner),
        customFieldDict: selectCustomFieldNameDict(tagOwner),
        isLoading: selectIsLoading,
    })

// limited to teams selectors
const selectLimitedToTeams = createSelector(
    selectCustomFieldFormValue("limited_to_teams"),
    (limitedToTeams) => limitedToTeams || Immutable.fromJS([]),
)

// Return an array of Team URIS for the CustomFieldsForm
export const selectLimitedToTeamsForCustomFieldsForm = createSelector(selectLimitedToTeams, (limitedToTeamsList) =>
    limitedToTeamsList.toJS(),
)

export const selectShowInForm = createSelector(
    selectCustomFieldFormValue("show_in_form"),
    selectCustomFieldFormValue("conditional_parent_custom_field"),
    (showInForm, conditionalParentCustomField) => showInForm || Boolean(conditionalParentCustomField),
)

export const selectConditionalParentType = createSelector(
    selectCustomFieldFormValue("conditional_parent_custom_field"),
    selectCustomFieldFormValue("conditional_parent_type"),
    selectCustomFieldExtra,
    (conditionalParentCustomField, conditionalParentType, extra) =>
        conditionalParentCustomField && (conditionalParentType || extra.conditional_parent_type),
)

export const selectConditionalParentOptions = createSelector(
    selectCustomFieldFormValue("conditional_parent_options"),
    selectCustomFieldExtra,
    (conditionalParentOptions, extra) => {
        const options =
            (conditionalParentOptions && conditionalParentOptions.toJS()) || extra.conditional_parent_options || {}

        return Object.keys(options)
            .filter((key) => !options[key].archived)
            .map((key) => ({
                value: key,
                label: options[key].value,
            }))
    },
)

export const selectHasConditionalChildren = createSelector(
    selectCustomFieldExtra,
    (extra) => extra.has_conditional_children,
)

export const selectExistsInActionCenterRegForms = createSelector(
    selectCustomFieldExtra,
    (extra) => extra.exists_in_action_center_reg_forms,
)

export const selectFormValues = createToJsSelector((state, props) => getFormValues(props.form)(state, props), {})

export const selectCustomFieldNameDictProps = createSelector(
    (_, props) => props.customFieldNameDict,
    (customFieldNameDict) => customFieldNameDict,
)

export const selectDefinedCustomFieldFormValues = createSelector(
    selectCustomFieldNameDictProps,
    selectFormValues,
    (customFieldNameDict, formValues) => createDefinedCustomFieldFormValues(customFieldNameDict, formValues),
)

export const selectResourceUriDict = createSelector(selectCustomFieldNameDictProps, (customFieldNameDict) =>
    createResourceUriDict(customFieldNameDict),
)

export const selectConditionalCustomFields = createSelector(
    selectCustomFieldNameDictProps,
    selectResourceUriDict,
    (customFieldNameDict, resourceUriDict) => createConditionalCustomFields(customFieldNameDict, resourceUriDict),
)

export const selectConditionalParentValues = createSelector(
    selectCustomFieldNameDictProps,
    selectResourceUriDict,
    selectDefinedCustomFieldFormValues,
    (customFieldNameDict, resourceUriDict, definedValues) =>
        createConditionalParentValues(customFieldNameDict, resourceUriDict, definedValues),
)

export const customFieldFormSectionConnection = createStructuredSelector({
    conditionalCustomFields: selectConditionalCustomFields,
    conditionalParentValues: selectConditionalParentValues,
})

import styled from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const EmptyStateSearch = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
`

export const Circle = styled.div`
    fill: ${colors.QuorumGrey1};
    flex-shrink: 0;
    position: relative;
    width: 50%;
`

export const IconWrapper = styled.div`
    align-items: center;
    background: ${colors.QuorumGrey1};
    border-radius: 50%;
    color: ${colors.QuorumBlue};
    display: flex;
    height: 10vw;
    justify-content: center;
    width: 10vw;
`

export const Content = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 18px;
`

export const Title = styled.span`
    ${rules.GiantTextMediumWeight}
    color: ${colors.QuorumBlue};
    text-align: center;
`

export const Tip = styled.span`
    ${rules.MediumTextMediumWeight}
    color: ${colors.QuorumBlue};
    margin-top: 40px;
    text-align: center;
`

export const Text = styled.span`
    ${rules.SmallTextRegularWeight}
    color: ${colors.QuorumBlue};
    margin-top: 10px;
    text-align: center;
`

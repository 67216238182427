import styled from "styled-components"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import {
    overrideFullBleedImageStyling,
    removeBorderRadiusInImpactTheme,
} from "QuorumGrassroots/widgets/styled-components/helperFunctions"
import { styleInMobileScreenSize } from "QuorumGrassroots/styled-components/helperFunctions"

export const StyledFullBleedImageWidget = styleWithOrgDesign(styled.div`
    align-items: center;
    background-image: url("${(props) => props.imagePath}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: calc(90vh - 85px);
    justify-content: center;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
    min-height: 400px;
    position: relative;
    width: calc(100% - 30px);
    ${styleInMobileScreenSize(`
        margin-bottom: 20px;
        margin-left: 5px;
        margin-right: 5px;
        padding-left: 0px;
        padding-right: 0px;
        text-align: center;
    `)}
    ${(props) =>
        props.imageContain &&
        `
        justify-content: flex-start;
        height: fit-content;
        background-image: none;
        position: relative;
        min-height: 0px;
    `}
    ${(props) => overrideFullBleedImageStyling(props)}
`)

export const StyledFullBleedImg = styleWithOrgDesign(styled.img`
    border-radius: 4px;
    height: auto;
    width: 100%;
    ${(props) => props.isCampaignPage && "border-radius: 0px;"}
    ${(props) => removeBorderRadiusInImpactTheme(props)}
`)

export const OpacityLayer = styled.div`
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    ${(props) => props.imageContain && "position: absolute;"}
    ${(props) => props.includeText && "background-color: rgba(0, 0, 0, 0.25);"}
    ${styleInMobileScreenSize(`
        position: relative;
    `)}
`

export const StyledFullBleedImageText = styled.div`
    color: white;
    display: flex;
    font-size: 75px;
    line-height: 86px;
    margin-bottom: 35px;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    ${(props) =>
        props.isLeftAligned
            ? `
                align-self: flex-start;
                margin-left: 115px;
                margin-right: 115px;
                ${styleInMobileScreenSize(`
                    margin-left: 10px;
                    text-align: left;
                `)}
            `
            : `
                text-align: center;
                margin-left: 115px;
                margin-right: 115px;
            `}
    ${styleInMobileScreenSize(`
        font-size: 45px;
        line-height: 50px;
        margin-bottom: 20px;
    `)}
`

export const StyledFullBleedImageButton = styled(StyledButton)`
    display: flex;
    width: auto;
    font-size: 35px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 20px;
    padding-right: 20px;
    ${styleInMobileScreenSize(`
        font-size: 25px;
    `)}
`

export const StyledButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
    ${(props) =>
        props.isLeftAligned
            ? `
                align-self: flex-start;
                margin-left: 115px;
                ${styleInMobileScreenSize(`
                    margin-left: 10px;
                `)}
            `
            : `
                margin-left: 190px;
                margin-right: 190px;
            `}
    ${(props) => props.imageContain && `position: relative;`}
`

import React from "react"
import {
    TransparentOverlay,
    OverlayLink,
    DescriptionText,
} from "QuorumGrassroots/framework/components/CookielessOverlay/style"

export const CookielessSafariOverlay = (props) => {
    return (
        <TransparentOverlay>
            <OverlayLink href={window.location.href} target="_blank">
                Click here to take action.
            </OverlayLink>
        </TransparentOverlay>
    )
}

export const CookielessEmbeddedOverlay = (props) => {
    return (
        <TransparentOverlay>
            <OverlayLink href={window.location.href} target="_blank">
                Click here to take action
                <DescriptionText>
                    Looks like you have third-party cookies disabled. Please enabled them or click here to take action.
                </DescriptionText>
            </OverlayLink>
        </TransparentOverlay>
    )
}

import React from "react"
import styled from "styled-components"
import { Col } from "react-bootstrap"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { Link } from "react-router-dom"
import { generateMediaUrl, hexCodetoRGBA } from "imports/desktopHelperFunctions"
import { removeBorderRadiusInImpactTheme } from "QuorumGrassroots/widgets/styled-components/helperFunctions"
import { styleWithOrgDesignHelper } from "QuorumGrassroots/styled-components/helperFunctions"

const transitionStyling = (seconds) => `
    -moz-transition: all ${seconds}s ease;
    -o-transition: all ${seconds}s ease;
    -webkit-transition: all ${seconds}s ease;
    transition: all ${seconds}s ease;
`

export const StyledIssueCol = styled(Col)`
    padding-left: 7.5px;
    padding-right: 7.5px;
`

const StyledIssueLinkComponent = styled(Link)`
    color: transparent !important;
`
export const StyledIssueLink = styleWithOrgDesignHelper(StyledIssueLinkComponent)

const sharedTileStyling = styleWithOrgDesign(styled.div`
    height: 370px;
    width: auto;
    background-color: ${(props) => hexCodetoRGBA(props.organizationDesign.primary_color, 0.4)};
    background-blend-mode: multiply;
    margin-top: 15px;
    margin-bottom: 0px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.organizationDesign.primary_color};
    ${(props) => removeBorderRadiusInImpactTheme(props)}
    &:hover {
        background-color: ${(props) => hexCodetoRGBA(props.organizationDesign.primary_color, 0.5)};

        ${(props) =>
            props.hasDescription &&
            `
            .issue-short-description {
                display: inline-block;
                opacity: 1;
            }

            .issue-content-wrapper {
                top: 50%;
            }
        `}
    }

    .issue-content-wrapper {
        ${transitionStyling("0.5")}
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-height: 100%;
    }

    // Allow user to scroll through tile, if hovering over a long issue name/description
    &:hover .issue-content-wrapper {
        overflow: auto;
    }

    .issue-name {
        color: white;
        font-size: 35px;
        font-weight: 300;
        overflow: hidden;
        text-align: center;
        margin-bottom: 10px;
        padding: 0px 35px;
    }

    .issue-short-description {
        ${transitionStyling("0.5")}

        /* See above where opacity and display are set on :hover */
        display: none;
        opacity: 0;

        color: white;
        font-size: 24px;
        font-weight: 300;
        height: 0px;
        line-height: 1.4;
        padding: 0px 45px;
        text-align: center;
        text-align: left;
        width: 100%;
    }
`)

export const StyledIssueImageTile = styleWithOrgDesign(styled(sharedTileStyling)`
    background-image: url("${(props) => generateMediaUrl(props.imageUrl)}");
    background-size: cover;

    i {
        display: none;
    }
`)

export const StyledIssueLogoTile = styleWithOrgDesign(styled(sharedTileStyling)`
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        font-size: 250px;
        color: white;
        opacity: 0.4;
    }
`)

import styled from "styled-components"

import { StyledErrorButton } from "QuorumGrassroots/widgets/styled-components/styles"

export const Styled404BackButton = styled(StyledErrorButton)`
    i {
        width: 30px;
    }
`

export const UnstyledAnchor = styled.a`
    text-decoration: none !important;
`

import styled from 'styled-components'

export const Card = styled.div`
    display: flex;
    flex: 1 0 0;
    align-items: center;

    width: 100%;
    max-height: 200px;

    background: #FFF;

    padding: 1.5rem;
    gap: var(--mantine-spacing-md);

    border-radius: 0.25rem;
    border: 1px solid var(--mantine-color-gray-3);
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.12);

    cursor: pointer;

    transition: all 250ms ease-in-out;

    ${props => props.selected && `
        border: 1px solid var(--mantine-color-violet-6);
    `}

    &:hover {
        background-color: var(--mantine-color-violet-1);
    }
`

export const IconContainer = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 2.5rem;
    height: 2.5rem;
    background: var(--mantine-color-violet-1);

    padding: var(--mantine-spacing-sm);

    border-radius: 50%;

    transition: all 400ms ease-in-out;

    & svg {
        color: var(--mantine-color-violet-5);
    }

    ${Card}:hover & {
        background-color: var(--mantine-color-violet-0);
    }
`

export const Title = styled.h2`
    color: var(--mantine-color-gray-9);
    font-size: 1rem;
    font-weight: 500;
    line-height: 150%;

    margin-top: 0;
    margin-bottom: 2px
`

export const Description = styled.p`
    color: var(--mantine-color-gray-7);
    font-size: 0.875rem;
    line-height: 150%;
`

import { connect } from "react-redux"

// higher order components
import { reduxForm, change } from "redux-form/immutable"
import { withNamespaces } from "react-i18next"

import { createStandardThankableWidget } from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/ThankableWrapper"

import DonationFormWidget from "QuorumGrassroots/widgets/DonationForm/components/index"

import { onSubmit, onSubmitFail, validate } from "QuorumGrassroots/widgets/DonationForm/action-creators"
import {
    selectDonationFormThanksProps,
    donationFormWidgetConnection,
} from "QuorumGrassroots/widgets/DonationForm/selectors"
import { constants } from "QuorumGrassroots/constants"

const ReduxDonationForm = reduxForm({
    form: constants.donationFormReduxFormKey,
    onSubmit,
    onSubmitFail,
    validate,
    change,
})(DonationFormWidget)

const connectedWidget = connect(donationFormWidgetConnection, {})(ReduxDonationForm)

const thankableWidget = createStandardThankableWidget(connectedWidget, selectDonationFormThanksProps)

// this guy is the update information form, without any thank yous or frills. Use
// this if you only need the form and do not need and thank yous.
export const NonThankableUpdateInfoWidget = withNamespaces()(connectedWidget)

export default withNamespaces()(thankableWidget)

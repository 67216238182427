import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Range } from "rc-slider"
import "rc-slider/assets/index.css"

import * as S from "Components/Compounds/KnobSlider/style"
import * as colors from "Components/colors"
import { formatNumberWithCommas } from "utils/helperFunctions"
import { discreteColorGradient } from "utils/cssHelpers"

import {
    convertOnChange,
    intervalsToKnobs,
} from "Components/Compounds/KnobSlider/helpers"

const MIN_COLOR = colors.QuorumBlue
const MID_COLOR = colors.HeaderBorderBlueColor
const MAX_COLOR = colors.RainDance

const KnobSlider = ({
    dataCy,
    hideCounts,
    onAfterChange,
    onBeforeChange,
    onChange,
    readOnly,
    stepSize,
    values,
}) => {
    // Extract the initial state, total slider scale, and array of labels from values prop
    const inputKnobs = intervalsToKnobs(values)
    const scale = values.reduce((acc, interval) => acc + interval.value, 0)
    const labels = values.map(interval => interval.label)

    const [stateValues, setStateValues] = useState(inputKnobs)

    // If the parent passes new values, overwrite our current state with those
    useEffect(() => setStateValues(intervalsToKnobs(values)), [values])

    // Use our own state management if no onChange was passed
    // Otherwise, let the parent control the state
    // Use convertOnChange to convert the values sent by rc-slider to the format
    // the parent expects before calling the onChange callback provided by props
    const setValues = onChange ? convertOnChange(onChange, labels, scale) : setStateValues
    const knobValues = stateValues

    // We need one color for each bar segment (space in between slider handles)
    //        Color 1        Color 2
    //    ~~~~~~~~~~~~~~O===============O
    //        Color 1        Color 2         Color 3
    //    ~~~~~~~~~~~~~~O===============O---------------
    //        Color 1        Color 2        Color 2         Color 3
    //    ~~~~~~~~~~~~~~O===============O===============O---------------
    // We have 3 preset colors, so always use the first two first
    // If there are at least 3 segments, only use the last color
    // on the last segment; all others should use the middle color

    // Calculate the number of segments that will share the middle color
    // (Every segment past the second and before the last; index 2..N-2)
    const numExcess = Math.max(0, knobValues.length - 2)
    const colorsArray = [MIN_COLOR, MID_COLOR, ...(new Array(numExcess).fill(MID_COLOR)), MAX_COLOR]

    // Generate the style of the rail (the whole line)
    const gradientString = discreteColorGradient(knobValues, colorsArray)

    // The "tracks" are segments between knobs - not counting segements between
    // a knob and the end of the rail
    // We want them all to be transparent so the rail color shows through
    const trackStyles = new Array(knobValues.length).fill({backgroundColor: "transparent"})

    // Calculate the widths (in percentage) of each segment between knobs, including
    // between a knob and the end of the rail
    // This is used for the label styling and content
    const segmentWidths = knobValues.map((v, idx) => v - (idx ? knobValues[idx - 1] : 0))
    segmentWidths.push(100 - (knobValues[knobValues.length - 1] || 0))

    // The custom handle needs access to our colors array to style each handle,
    // so this component is defined here to have colorsArray in scope
    class CustomHandle extends React.Component {
        // Stubbing this to prevent a loud, but unimportant type error
        clickFocus() {
            return
        }

        render() {
            return (
                <S.Handle
                    colorA={colorsArray[this.props.index]}
                    colorB={colorsArray[this.props.index + 1]}
                    index={this.props.index}
                    ref={this.props.ref}

                    // allows focusing / moving with arrow keys, but requires
                    // clicking a slider twice before it works
                    // tabIndex={1}

                    value={this.props.value}
                />
            )
        }
    }

    const handleRender = (props) => <CustomHandle {...props} />

    return (
        <S.KnobSlider data-auto-cy="CompoundKnobSlider" data-cy={dataCy}>
            <Range
                // this seems to be set implicitly with value
                count={knobValues.length}

                handle={handleRender}
                onAfterChange={!readOnly && onAfterChange && convertOnChange(onAfterChange, labels, scale)}
                onBeforeChange={!readOnly && onBeforeChange && convertOnChange(onBeforeChange, labels, scale)}
                onChange={!readOnly && knobValues.length && setValues}
                railStyle={{background: gradientString}}
                step={stepSize}
                trackStyle={trackStyles}
                value={knobValues}
            />
            <S.LabelGrid values={segmentWidths}>
                {
                    // For each segment (space between knobs) show a label like this
                    // Optionally skip parts of the label if we don't have enough info
                    //    Label Name
                    //  89% | 1,234,567
                    labels && segmentWidths.map((v, idx) => (
                        <S.Label color={colorsArray[idx]} key={labels[idx] || idx}>
                            {idx < labels.length && <b>{labels[idx]}</b>}
                            <span> {v}% {
                                !hideCounts
                                && Boolean(scale)
                                && `| ${formatNumberWithCommas(Math.round(v * scale / 100))}`
                            }</span>
                        </S.Label>
                    ))
                }
            </S.LabelGrid>
        </S.KnobSlider>
    )
}

KnobSlider.defaultProps = {
    labels: [],
    values: [],
}

KnobSlider.propTypes = {
    dataCy: PropTypes.string,
    // false if the counts should be shown beside the percentages
    hideCounts: PropTypes.bool,
    // function called with new values when the slider is dropped
    onAfterChange: PropTypes.func,
    // function called with old values when the slider is picked up
    onBeforeChange: PropTypes.func,
    // function called with new values as the slider is dragged (perform state updates here)
    onChange: PropTypes.func,
    // true if the slider should be static / not moveable
    readOnly: PropTypes.bool,
    // integer percentage of increments that the slider knobs will snap to
    stepSize: PropTypes.number,
    // array of objects representing intervals / segments on the slider
    values: PropTypes.arrayOf(PropTypes.shape({
        // text to display over this segment of the slider
        label: PropTypes.string,
        // non-negaitve relative width of the interval
        value: PropTypes.number,
    })).isRequired,
}

export default KnobSlider

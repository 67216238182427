import React, { Dispatch, useState } from "react"
import { connect } from "react-redux"
import { formValueSelector } from "redux-form/immutable"
import regexes from "shared/imports/regex"

import { AddressField } from "shared-web/forms/fields/AddressField"
import { fetchOfficialsByAddress } from "QuorumGrassroots/services"
import { setOfficialsPreview, setTargetedMessages } from "QuorumGrassroots/campaign-forms/action-creators"
import { useDebouncedCallback } from "QuorumGrassroots/framework/hooks/useDebouncedCallback"

import {
    Address,
    Campaign,
    Official,
    SetOfficialsPreviewAction,
    SetTargetedMessagesAction,
    TargetedMessage,
} from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/interfaces"
import { parseAddress } from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/helper"
import { replaceSupporterPlaceholderOnTargetedMessages } from "QuorumGrassroots/helpers"

interface Props {
    email: string
    campaign: Campaign
    setOfficialsPreview: (uniqueWidgetId: string, officials: Official[]) => void
    setTargetedMessages: (uniqueWidgetId: string, messages: Record<string, unknown>) => void
    name: string
    dataCy?: string
    label?: string
    placeholder?: string
    displayErrorWithoutTouch?: boolean
    validate?: (formValue: unknown) => string
    showAsterisk?: boolean
    shouldGeocode?: boolean
    accessibilityId?: string
    isCampaignPage?: boolean
    disabled?: boolean
    tooltipText?: string | null
    firstname: string
    lastname: string
}

const UnconnectedAddressFieldWithOfficials = (props: Props) => {
    const [isFetchingOfficialsByAddress, setIsFetchingOfficialsByAddress] = useState(false)

    const handleOfficialsByAddress = useDebouncedCallback((address: string | Address) => {
        const { addressDict, addressText, isInvalidAddress } = parseAddress(address)
        if (isInvalidAddress) return

        const isEmailValid = regexes.emailValidationRegex.test(props.email)
        if (!isEmailValid) return

        props.setOfficialsPreview(props.campaign.id.toString(), null)
        setIsFetchingOfficialsByAddress(true)
        fetchOfficialsByAddress(props.campaign.id, addressText, props.email, addressDict)
            .then((response) => {
                const { targets, targeted_messages } = response
                const replacedTargetedMessages = replaceSupporterPlaceholderOnTargetedMessages(targeted_messages, {
                    firstname: props.firstname,
                    lastname: props.lastname,
                    address: addressText,
                    email: props.email,
                })
                props.setTargetedMessages(props.campaign.id.toString(), replacedTargetedMessages)
                props.setOfficialsPreview(
                    props.campaign.id.toString(),
                    targets.map((official: Official) => ({
                        value: String(official.id),
                        label: official.name,
                        imageUrl: official.image_url,
                        targeted: true,
                        message_group_id: String(official.message_group_id),
                    })),
                )
            })
            .finally(() => {
                setIsFetchingOfficialsByAddress(false)
            })
    }, 800)

    return (
        <>
            <AddressField
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleOfficialsByAddress(e.target.value)
                }}
                onClickAddressSuggestion={(address: Address | string) => {
                    handleOfficialsByAddress(address)
                }}
                name={props.name}
                dataCy={props.dataCy}
                label={props.label}
                placeholder={props.placeholder}
                displayErrorWithoutTouch={props.displayErrorWithoutTouch}
                validate={props.validate}
                showAsterisk={props.showAsterisk}
                shouldGeocode={props.shouldGeocode}
                accessibilityId={props.accessibilityId}
                isCampaignPage={props.isCampaignPage}
                disabled={props.disabled || isFetchingOfficialsByAddress}
                tooltipText={props.tooltipText}
            />
        </>
    )
}

const selector = formValueSelector("registration")

const mapStateToProps = (state) => {
    return {
        email: selector(state, "email"),
        firstname: selector(state, "firstname"),
        lastname: selector(state, "lastname"),
    }
}

const mapDispatchToProps = (dispatch: Dispatch<SetOfficialsPreviewAction | SetTargetedMessagesAction>) => ({
    setTargetedMessages: (uniqueWidgetId: string, messages: Record<string, TargetedMessage>) =>
        dispatch(setTargetedMessages(uniqueWidgetId, messages)),

    setOfficialsPreview: (uniqueWidgetId: string, officials: Official[]) =>
        dispatch(setOfficialsPreview(uniqueWidgetId, officials)),
})

export const AddressFieldWithOfficials = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnconnectedAddressFieldWithOfficials)

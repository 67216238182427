import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Compounds/HTMLTable/style"

/**
 * Input:
 *
 * rowData: [
 *     {
 *         name: "first",
 *         type: "type1",
 *         default: "true",
 *     },
 *     {
 *         name: "second",
 *         type: "type2",
 *         default: "false",
 *     }
 * ]
 *
 * columnMapping: [
 *     { key: "name", display: "Name" }
 *     { key: "type", display: "Type" }
 *     { key: "default", display: "Default" }
 * ]
 *
 * Output:
 *
 * | Name | Type | Default
 * -----------------------
 *  first   type1   true
 *  second  type2   false
 */

const HTMLTable = ({ headerText, rowData, columnMapping, boldLastRow }) => (
    <div data-auto-cy="CompoundHTMLTable">
        <S.SectionHeader>
            <S.HeaderText>{headerText}</S.HeaderText>
        </S.SectionHeader>
        <S.HTMLTable
            boldLastRow={boldLastRow}
        >
            <thead>
                <tr>
                    {columnMapping.map(({ display, key }) =>
                        <th key={key}>{display}</th>
                    )}
                </tr>
            </thead>
            <tbody>
                {
                    rowData.map((row, idx) => (
                        <tr key={row.name}>
                            {columnMapping.map(({ key, idx }) =>
                                <td key={key}>
                                    {row[key] && String(row[key])}
                                </td>
                            )}
                        </tr>
                    ))
                }
            </tbody>
        </S.HTMLTable>
    </div>
)

HTMLTable.defaultProps = {
    rowData: [
        {
            name: "first",
            type: "type1",
            default: "true",
            doubleheader: "Content",
        },
        {
            name: "second",
            type: "type2",
            default: "false",
            doubleheader: "Alot of content",
        },
        {
            name: "third",
            type: "--",
            default: "true",
            doubleheader: "--",
        },
    ],
    columnMapping: [
        { key: "name", display: "Name" },
        { key: "type", display: "Type" },
        { key: "doubleheader", display: "Double Header Double Header" },
        { key: "default", display: "Default" },
    ]
}

HTMLTable.propTypes = {
    rowData: PropTypes.arrayOf(PropTypes.object),
    columnMapping: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        display: PropTypes.string.isRequired,
    }))
}

export default HTMLTable
